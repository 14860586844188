<div class="ceos-device-live-data-cell">
  <ng-container [ngSwitch]="currentData" class="text-right">
    <ng-container *ngSwitchCase="'ERROR'">
      <div class="red-text">
        <small>
          <i
            class="fa fa-exclamation-triangle"
            pTooltip="{{ 'CANNOT_REACH_API' | translate }}"
            tooltipPosition="top"
            appendTo="body"
          ></i>
          {{ currentData | translate }}
        </small>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'FETCHING'">
      <div class="grey-text">
        <small>
          <i class="fa fa-refresh fa-spin"></i>
          {{ currentData | translate }}
        </small>
      </div>
    </ng-container>

    <div *ngSwitchCase="'TIMEOUT'">
      <div class="grey-text">
        <small>
          <i
            class="fa fa-exclamation-triangle"
            pTooltip="{{
              'CANNOT_GET_DATA_FOR_X_SECONDS' | translate: { value: 30 }
            }}"
            tooltipPosition="top"
            appendTo="body"
          ></i>
          {{ currentData | translate }}
        </small>
      </div>
    </div>

    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="liveValue">
        <div
          *ngFor="let item of liveValue"
          class="badge green mr-1"
          [pTooltip]="getTooltipText(item)"
          tooltipPosition="top"
          appendTo="body"
        >
          {{ item?.formattedValue || item?.value || "-" }}{{ item?.unit || "" }}
        </div>
      </ng-container>

      <ng-container *ngIf="liveError">
        <div class="badge red">
          {{ liveError }}
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
