<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <div class="ui-g">
        <!-- BENCHMARK_TEMPLATE FIELD -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="benchmarkTemplate"
            [form]="form"
            label="{{ 'BENCHMARK_TEMPLATE' | translate }}"
            [options]="selectItems['benchmarkTemplates']"
          ></sc-dropdown>
        </div>

        <div class="ui-g-12 ui-g-nopad">
          <sc-quick-date-select
            [showOnlyDate]="true"
            (valueChange)="onSelectDate($event)"
            [displayInVertical]="true"
            [showLabel]="true"
          ></sc-quick-date-select>
        </div>
      </div>

      <p-footer>
        <div class="ui-g-6"></div>
        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            label="{{ 'CANCEL' | translate }}"
            (click)="dismissModal('cancel')"
          ></button>

          <button
            pButton
            type="button"
            class="ui-button-secondary"
            label="{{ 'RUN' | translate }}"
            [disabled]="isSubmitting || form.invalid"
            (click)="submit()"
          ></button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
