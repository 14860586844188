<ng-container *ngIf="showSpinner; then spinner; else content"></ng-container>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <sc-table
    [columns]="columns"
    [config]="config"
    [dataset]="dataset"
    [formConfig]="formConfig"
    (reloadData)="fetchDataset()"
    [title]="tableTitle"
    icon="fa fa-tasks"
  ></sc-table>
</ng-template>
