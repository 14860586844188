import * as fromSites from '../actions/sites.action';
import { Site } from '@widgets/sites/models/site';
import { state } from '@angular/animations';
import { findIndex } from 'rxjs/operators';

export interface SiteState {
  entities: { [id: number]: Site };
  tableEntities: { [id: number]: Site };
  tableLoaded: boolean;
  tableLoading: boolean;
  formData: Site;
  formDataLoading: boolean;
  formDataError: any;
  loaded: boolean;
  loading: boolean;
  selectedId: number;
  pending: boolean;
  pendingError: any;
}

export const initialState = {
  entities: null,
  tableEntities: null,
  tableLoaded: false,
  tableLoading: false,
  formData: null,
  formDataLoading: null,
  formDataError: null,
  loaded: false,
  loading: false,
  selectedId: null,
  pending: false,
  pendingError: null,
};

export function reducer(state = initialState, action: fromSites.SitesAction): SiteState {
  switch (action.type) {
    // LOAD SITE FORM
    case fromSites.LOAD_SITE: {
      return {
        ...state,
        formDataLoading: true,
      };
    }
    case fromSites.LOAD_SITE_FAIL: {
      const formDataError = action.payload;
      return {
        ...state,
        formDataLoading: false,
        formDataError,
        formData: null,
      };
    }
    case fromSites.LOAD_SITE_SUCCESS: {
      const formData = action.payload;
      const entities = {
        ...state.entities,
        [formData.id]: formData,
      };
      return {
        ...state,
        formDataLoading: false,
        formDataError: null,
        formData,
        entities,
      };
    }

    // LOAD SITES
    case fromSites.LOAD_SITES: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromSites.LOAD_SITES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromSites.LOAD_SITES_SUCCESS: {
      const sites = action.payload;
      const entities = sites.reduce((tmpEntities: { [id: number]: Site }, site: Site) => {
        return {
          ...tmpEntities,
          [site.id]: site,
        };
      }, {});
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    // LOAD SITES TABLE
    case fromSites.LOAD_SITES_TABLE: {
      return {
        ...state,
        tableLoading: true,
      };
    }
    case fromSites.LOAD_SITES_TABLE_FAIL: {
      return {
        ...state,
        tableLoaded: false,
        tableLoading: false,
      };
    }
    case fromSites.LOAD_SITES_TABLE_SUCCESS: {
      const sites = action.payload;
      const tableEntities = sites.reduce((tmpEntities: { [id: number]: Site }, site: Site) => {
        return {
          ...tmpEntities,
          [site.id]: site,
        };
      }, {});
      return {
        ...state,
        tableLoaded: true,
        tableLoading: false,
        tableEntities,
      };
    }

    // SELECT SITE
    case fromSites.SELECT_SITE: {
      const selectedId = action.payload;
      if (state.selectedId !== selectedId) {
        return {
          ...state,
          selectedId,
        };
      }
      return state;
    }

    // CREATE / UPDATE SITE
    case fromSites.CREATE_SITE:
    case fromSites.UPDATE_SITE:
    case fromSites.DELETE_SITE: {
      return {
        ...state,
        pending: true,
      };
    }

    case fromSites.CREATE_SITE_FAIL:
    case fromSites.UPDATE_SITE_FAIL:
    case fromSites.DELETE_SITE: {
      const pendingError = action.payload;
      return {
        ...state,
        pending: false,
        pendingError,
      };
    }

    case fromSites.CREATE_SITE_SUCCESS:
    case fromSites.UPDATE_SITE_SUCCESS: {
      const site = action.payload;
      const entities = {
        ...state.entities,
        [site.id]: site,
      };
      const tableEntities = {
        ...state.tableEntities,
        [site.id]: site,
      };

      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
        tableEntities,
      };
    }

    // DELETE SITE
    case fromSites.DELETE_SITE_SUCCESS: {
      const site = action.payload;
      const entities = {
        ...state.entities,
        [site.id]: {
          ...site,
          isActive: false,
          isDeleted: true,
        },
      };
      const tableEntities = {
        ...state.tableEntities,
        [site.id]: {
          ...site,
          isActive: false,
          isDeleted: true,
        },
      };
      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
        tableEntities,
      };
    }
    // RESET PENDING
    case fromSites.SITE_RESET_PENDING_STATE: {
      return {
        ...state,
        pending: false,
        pendingError: null,
        formData: null,
      };
    }
  }

  return state;
}

export const getSitesEntities = (state: SiteState) => state.entities;

export const getSitesTableEntities = (state: SiteState) => state.tableEntities;

export const getSelectedId = (state: SiteState) => state.selectedId;

export const getLoading = (state: SiteState) => state.loading;

export const getLoaded = (state: SiteState) => state.loaded;

export const getFormData = (state: SiteState) => state.formData;

export const getFormDataLoading = (state: SiteState) => state.formDataLoading;

export const getFormDataError = (state: SiteState) => state.formDataError;

export const getPending = (state: SiteState) => state.pending;

export const getPendingError = (state: SiteState) => state.pendingError;
