import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

// Components
import { SiteActionCellComponent } from './site-action-cell/site-action-cell.component';
import { SiteFormComponent } from './site-form/site-form.component';
import { SitesTableComponent } from './sites-table/sites-table.component';

// Services
import { SiteFormsService } from './services/site-forms.service';
import { SitesService } from './services/sites.service';

const components = [SiteActionCellComponent, SiteFormComponent, SitesTableComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...components],
  entryComponents: [...components],
  providers: [SiteFormsService, SitesService],
})
export class SitesModule {}
