<div class="ui-g mb-1">
  <div class="ui-g-12 ui-md-6 ui-g-nopad">
    <h2 class="m-0">
      {{ deviceName }}
    </h2>
    <p class="m-0">
      <span class="badge grey">
        ID: {{ deviceId }}
      </span>
      <small class="grey-text" *ngIf="lastSeen">
        Last seen: {{ lastSeen }}
      </small>
    </p>
  </div>

  <div class="ui-g-12 ui-md-6 text-right">
    <div class="remote-tool">
      <a class="ui-button anydesk" *ngIf="anydeskLink" [href]="anydeskLink" pTooltip="Connect via Anydesk" tooltipPosition="top" appendTo="body">
        <i class="fa fa-desktop fa-fw"></i>
        {{ 'ANYDESK' | translate }}
      </a>

      <!-- <a class="ui-button ssh-tunnel" pTooltip="Open SSH Tunnel" tooltipPosition="top" appendTo="body">
        <i class="fa fa-road fa-fw"></i>
        SSH
      </a> -->

      <!-- <a class="ui-button terminal">
        <i class="fa fa-terminal fa-fw" pTooltip="Terminal" tooltipPosition="top" appendTo="body"></i>
      </a> -->
    </div>
  </div>
</div>
