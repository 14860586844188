import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import * as fromStore from '@app/store';

import { Device } from '@widgets/devices/models/device';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { ProtocolController } from '@widgets/devices/models/protocol-controller';
import { LocationDetails } from '@widgets/locations/models/location-details';
import { LocationProperty } from '@widgets/location-properties/models/location-property';
import { LocationType } from '@widgets/locations/models/location-type';
import { Site } from '@widgets/sites/models/site';
import { ObjectLayers } from '@app/floor-planner/models/object';

import { DeviceHistoryLogsService } from '@widgets/devices/services/device-history-logs.service';
import { DeviceModelsService } from '@widgets/device-models/services/device-models.service';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { LocationHistoryLogsService } from '@widgets/locations/services/location-history-logs.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { ProtocolControllersService } from '@widgets/devices/services/protocol-controllers.service';
import { LocationPropertiesService } from '@widgets/location-properties/services/location-properties.service';
import { LocationTypesService } from '@widgets/locations/services/location-types.service';
import { SharedService } from '@shared/shared.service';

// import { DeviceDetailsModalComponent } from '@widgets/floorplan/device-details-modal/device-details-modal.component';
// import { LocationDetailsModalComponent } from '@widgets/floorplan/location-details-modal/location-details-modal.component';

@Component({
  selector: 'sc-floorplan',
  templateUrl: './floorplan.component.html',
  styleUrls: ['./floorplan.component.scss'],
})
export class FloorPlanComponent implements OnInit, OnDestroy {
  devices: Device[];
  deviceTypes: DeviceType[] = []; // share to wizard
  deviceModels: DeviceModel[] = []; // share to wizard
  deviceProtocols: DeviceProtocol[] = []; // share to wizard
  deviceEvents: any[];
  latestDhls: any[];
  latestLhls: any[];
  locationEvents: any[];
  locationProperties: LocationProperty[] = []; // share to sumamry data
  locationTypes: LocationType[] = [];
  locations: LocationDetails[];
  protocolControllers: ProtocolController[] = []; // share to wizard
  selectedFloor: LocationDetails;
  selectedLocation: LocationDetails;
  selectedSite: Site;
  widgets: any;

  private activeLayers: string[];
  private deviceEntities: { [key: string]: Device } = {};
  private deviceTypeEntities: { [key: string]: DeviceType } = {};
  private dhlEntities: { [key: string]: any } = {};
  private intervals: { [key: string]: any } = {};
  private isFetchingLatestDHL = false;
  private isFetchingLatestLHL = false;
  private lhlEntities: { [key: string]: any } = {};
  private locationEntities: { [key: string]: LocationDetails } = {};
  private locationTypeEntities: { [key: string]: LocationType } = {};
  private subscribers: { [key: string]: any } = {};
  private zoomedLocation: string;

  // private deviceDetailsModal: DynamicDialogRef;
  // private locationDetailsModal: DynamicDialogRef;

  private hiddenKey: string = 'hidden';
  private isFloorplanInit: boolean;
  private isBrowserTabActive: boolean;

  constructor(
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private deviceModelsService: DeviceModelsService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceTypesService: DeviceTypesService,
    private devicesService: DevicesService,
    private locationHistoryLogsService: LocationHistoryLogsService,
    private locationsService: LocationsService,
    private protocolControllersService: ProtocolControllersService,
    private locationPropertiesService: LocationPropertiesService,
    private locationTypesService: LocationTypesService,
    private sharedService: SharedService,
    private store: Store<fromStore.State> // private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;

    // get location details
    this.subscribers.locationDetail = this.store.select(fromStore.getLocationDetials).subscribe((result) => {
      if (result) {
        this.selectedFloor = { ...result };
        if (this.selectedFloor.floorplanStructure) {
          this.selectedFloor.floorplanStructure = JSON.parse(this.selectedFloor.floorplanStructure);
        }
      }
    });

    // get query params
    this.subscribers.queryParams = this.store.select(fromStore.getRouterStateUrlQueryParams).subscribe((result) => {
      if (result && result.location) {
        this.zoomedLocation = result.location;
      }
    });

    // TODO: get custom widget config
    this.subscribers.widgets = this.store.select(fromStore.getAllWidgets).subscribe((result) => {
      if (result) {
        // this.widgets = { ...result };
        this.widgets = [...result].reduce((acc, curr) => {
          acc[curr.key] = curr;
          return acc;
        }, {}) as any;
      }
    });

    this.fetchLocations();
    this.fetchSiteDevices();
    this.fetchDeviceTypes();
    this.fetchDeviceModels();
    this.fetchDeviceProtocols();
    this.fetchProtocolControllers();
    this.fetchLocationProperties();
    this.fetchLocationTypes();

    this.checkBrowserTabActive();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  private checkBrowserTabActive() {
    // check the tab is active or inactive
    if (this.hiddenKey in document) {
      document.addEventListener('visibilitychange', this.visibilitychange.bind(this));
    } else if ((this.hiddenKey = 'mozHidden') in document) {
      document.addEventListener('mozvisibilitychange', this.visibilitychange.bind(this));
    } else if ((this.hiddenKey = 'webkitHidden') in document) {
      document.addEventListener('webkitvisibilitychange', this.visibilitychange.bind(this));
    } else if ((this.hiddenKey = 'msHidden') in document) {
      document.addEventListener('msvisibilitychange', this.visibilitychange.bind(this));
      // } else if ('onfocusin' in document) {
      //   // IE 9 and lower:
      //   document.onfocusin = document.onfocusout = this.visibilitychange.bind(this);
    } else {
      // All others:
      window.onpageshow = window.onpagehide = window.onfocus = window.onblur = this.visibilitychange.bind(this);
    }

    // set the initial state (but only if browser supports the Page Visibility API)
    if (document[this.hiddenKey] !== undefined) {
      this.visibilitychange({ type: document[this.hiddenKey] ? 'blur' : 'focus' });
    }
  }

  private visibilitychange(event) {
    event = event || window.event;
    const evtMap = {
      focus: true,
      focusin: true,
      pageshow: true,
      blur: false,
      focusout: false,
      pagehide: false,
    };

    if (event.type in evtMap) {
      this.isBrowserTabActive = evtMap[event.type];
    } else {
      this.isBrowserTabActive = !document[this.hiddenKey];
    }

    if (this.isBrowserTabActive && this.isFloorplanInit) {
      // tab is active
      if (this.activeLayers) {
        // Layers ware already toggled
        this.onEventLayerToggled(this.activeLayers);
      } else {
        // Layers waren't toggeld yet
        this.startDHLUpdate();
        this.startLHLUpdate();
      }
    } else {
      // tab is inactive
      this.stopDHLUpdate();
      this.stopLHLUpdate();
    }
  }

  private fetchSiteDevices() {
    const options = {
      siteId: this.selectedSite.id,
      // deviceTypeKey: 'a',
      columns:
        'id,description,locationId,instructionPositionX,instructionPositionY,installationHeight,' +
        'virtualDeviceHolderId,parentIdx,customData,isActive,isDeleted',
    };
    this.subscribers.fetchSiteDevices = this.devicesService.getDevices(options).subscribe({
      next: (result: any) => {
        this.devices = result.data.filter((d) => !d.isDeleted);
        this.deviceEntities = this.devices.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}) as any;
      },
    });
  }

  private fetchLocations() {
    const options = {
      siteId: this.selectedSite.id,
    };
    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe({
      next: (result: any) => {
        this.locations = result.data.filter((d) => !d.isDeleted);
        this.locationEntities = this.locations.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}) as any;

        if (this.zoomedLocation && this.locationEntities && this.locationEntities[this.zoomedLocation]) {
          this.selectedLocation = this.locationEntities[this.zoomedLocation];
        }
      },
    });
  }

  private fetchLocationTypes() {
    this.subscribers.fetchLocationTypes = this.locationTypesService.getLocationTypes().subscribe({
      next: (result: any) => {
        this.locationTypes = result.data.filter((d) => !d.isDeleted);
        this.locationTypeEntities = this.locationTypes.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {}) as any;
      },
    });
  }

  private fetchDeviceTypes() {
    this.deviceTypes = [];
    this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data.filter((d) => !d.isDeleted);
      this.deviceTypeEntities = this.deviceTypes.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}) as any;
    });
  }

  private fetchDeviceModels() {
    this.deviceModels = [];
    this.subscribers.fetchDeviceModels = this.deviceModelsService.getDeviceModels().subscribe((result: any) => {
      this.deviceModels = result.data.filter((d) => !d.isDeleted);
    });
  }

  private fetchDeviceProtocols() {
    this.deviceProtocols = [];
    this.subscribers.fetchDeviceProtocols = this.deviceProtocolsService.getDeviceProtocols().subscribe({
      next: (result: any) => {
        this.deviceProtocols = result.data.filter((d) => !d.isDeleted);
      },
    });
  }

  private fetchProtocolControllers() {
    this.protocolControllers = [];
    this.subscribers.fetchProtocolControllers = this.protocolControllersService.getProtocolControllers().subscribe({
      next: (result: any) => {
        this.protocolControllers = result.data.filter((d) => !d.isDeleted);
      },
    });
  }

  private fetchLocationProperties() {
    this.subscribers.fetchLocationProperties = this.locationPropertiesService.getLocationProperties().subscribe({
      next: (result: any) => {
        this.locationProperties = result.data.filter((d) => !d.isDeleted);
      },
    });
  }

  private fetchLatestDHL() {
    if (this.isFetchingLatestDHL || !this.selectedSite || !this.deviceEntities) {
      return;
    }
    // TODO:
    this.isFetchingLatestDHL = true;
    const options = {
      filter: {
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id,
      },
      latestLog: true,
      limit: 5000,
    };
    this.subscribers.fetchLatestDHL = this.deviceHistoryLogsService.getHistoryLogs(options).subscribe((result: any) => {
      this.latestDhls = result;
      this.patchDHL(result);
      this.isFetchingLatestDHL = false;
    });

    // TODO:
    // const ids = [3740, 3732, 3728, 3736, 3735, 3739, 3731];
    // const dhldata = [];
    // for (let i = 0; i < 2; i++) {
    //   const n = this.sharedService.randomIntFromInterval(0, 6);
    //   const v = this.sharedService.randomIntFromInterval(24, 27) + '';
    //   dhldata.push({
    //     deviceId: ids[n],
    //     value: v,
    //     createdAt: '2020-06-24T09:08:50',
    //   });
    // }
    // this.latestDhls = dhldata;
    // this.patchDHL(dhldata);
  }

  private patchDHL(dhls: any[]) {
    if (!dhls || !dhls.length) {
      return;
    }

    const deviceEvents = [];

    for (const l of dhls) {
      const device = this.deviceEntities[l.deviceId];

      if (device) {
        const deviceType = this.deviceTypeEntities[device.deviceTypeId];

        if (deviceType) {
          const value = this.mapDHLValue(l.value, deviceType);
          const icon = this.deviceHistoryLogsService.getDeviceTypeIcon(deviceType.key, l.value, true);
          const label = this.devicesService.deviceValueLabel(deviceType.key);
          const unit = this.devicesService.deviceValueUnit(deviceType.key);
          if (!this.dhlEntities[l.deviceId]) {
            this.dhlEntities[l.deviceId] = {
              ...l,
              icon,
              label,
              unit,
              value,
              virtualDeviceHolderId: device.virtualDeviceHolderId,
            };
            deviceEvents.push(this.dhlEntities[l.deviceId]);
          } else if (this.dhlEntities[l.deviceId].value !== value) {
            // value is not the same as exists dhl, push new value to floorplan
            this.dhlEntities[l.deviceId].value = value;
            this.dhlEntities[l.deviceId].icon = icon;
            deviceEvents.push(this.dhlEntities[l.deviceId]);
          }
        }
      }
    }

    if (deviceEvents.length) {
      this.deviceEvents = deviceEvents;

      this.latestDhls = Object.keys(this.dhlEntities)
        .map((id) => this.dhlEntities[id])
        .reduce((acc, cur) => {
          if (!acc[cur.virtualDeviceHolderId]) {
            acc[cur.virtualDeviceHolderId] = [];
          }
          acc[cur.virtualDeviceHolderId].push(cur);
          return acc;
        }, {});
    }
  }

  private startDHLUpdate() {
    this.fetchLatestDHL();
    clearInterval(this.intervals.fetchDHL);
    this.intervals.fetchDHL = setInterval(() => {
      this.fetchLatestDHL();
    }, 10000);
  }

  private stopDHLUpdate() {
    clearInterval(this.intervals.fetchDHL);
    this.intervals.fetchDHL = null;
  }

  private mapDHLValue(data, deviceType) {
    let mappedValue = data;

    // find mapping value
    if (deviceType && deviceType.mappingValues && deviceType.mappingValues.length) {
      for (const mappingValue of deviceType.mappingValues) {
        if (mappingValue.key === data) {
          mappedValue = mappingValue.name;
          break;
        }
      }
    }

    // format number into 1 decimals
    if (typeof mappedValue === 'number') {
      mappedValue = this.sharedService.numberFormat(mappedValue, 1);
    }

    return mappedValue;
  }

  private fetchLatestLHL() {
    if (this.isFetchingLatestLHL) {
      return;
    }
    // TODO:
    this.isFetchingLatestLHL = true;
    const options = {
      latestLog: true,
      limit: 5000,
    };
    this.subscribers.fetchLatestLHL = this.locationHistoryLogsService
      .getHistoryLogs(options)
      .subscribe((result: any) => {
        this.patchLHL(result);
        this.isFetchingLatestLHL = false;
      });

    // TODO:
    // const ids = [322, 323, 324, 325, 327];
    // const lhldata = [];
    // for (let i = 0; i < 2; i++) {
    //   const n = this.sharedService.randomIntFromInterval(0, 4);
    //   const t = this.sharedService.randomIntFromInterval(24, 27);
    //   const h = this.sharedService.randomIntFromInterval(62, 63);
    //   const d = this.sharedService.randomIntFromInterval(0, 1);
    //   lhldata.push({
    //     acFanspeed: 'l',
    //     acFanspeedLoggedAt: '2020-03-09T07:59:31Z',
    //     acMode: 'o',
    //     acModeLoggedAt: '2020-06-26T05:05:34Z',
    //     acSetpoint: 25,
    //     acSetpointLoggedAt: '2020-06-19T07:05:50Z',
    //     acStatus: 'o',
    //     acStatusLoggedAt: '2020-06-26T05:05:34Z',
    //     brightness: 1151,
    //     brightnessLoggedAt: '2020-06-26T04:04:51Z',
    //     companyId: '1',
    //     consumption: 0,
    //     consumptionLoggedAt: '2020-06-05T07:27:57Z',
    //     createdAt: '2020-06-26T05:27:15Z',
    //     dndStatus: null,
    //     dndStatusLoggedAt: null,
    //     doorOpen: d === 1 ? 'Yes' : 'No',
    //     doorOpenLoggedAt: '2020-06-25T05:56:49Z',
    //     humidity: h,
    //     humidityLoggedAt: '2020-06-26T04:27:02Z',
    //     lightsOn: false,
    //     lightsOnLoggedAt: '2020-06-02T04:25:03Z',
    //     locationId: ids[n],
    //     makeUpStatus: null,
    //     makeUpStatusLoggedAt: null,
    //     occupied: true,
    //     occupiedLoggedAt: '2020-06-25T05:57:43Z',
    //     oooStatus: null,
    //     oooStatusLoggedAt: null,
    //     siteId: '15',
    //     syncAt: '2020-06-26T05:27:20Z',
    //     temperature: t,
    //     temperatureLoggedAt: '2020-06-26T05:27:15Z',
    //     windowOpen: null,
    //     windowOpenLoggedAt: null,
    //   });
    // }
    // this.latestLhls = lhldata;
    // this.patchLHL(lhldata);
  }

  private patchLHL(lhls: any[]) {
    if (!lhls || !lhls.length) {
      return;
    }

    const ignoreProps = ['createdAt', 'syncAt', 'locationId', 'siteId', 'companyId'];
    const loggedAtRegexp = new RegExp('LoggedAt', 'gi');

    const latestLhls = [];
    const locationEvents = [];

    for (const lhl of lhls) {
      const location = this.locationEntities[lhl.locationId];
      if (!location) {
        continue;
      }

      let changeDetected = false;

      if (!this.lhlEntities[location.id]) {
        this.lhlEntities[location.id] = { locationId: location.id };
      }

      // get mapping value
      for (const prop in lhl) {
        if (ignoreProps.indexOf(prop) >= 0 || prop.match(loggedAtRegexp) || typeof lhl[prop] === 'undefined') {
          continue;
        }

        let foundEvent = false;

        const prevData = this.lhlEntities[location.id][prop];
        const value = this.mapLHLValue(prop, lhl[prop]);
        const label = this.locationHistoryLogsService.getLocationPropertyLabel(prop, lhl[prop]);
        const unit = this.locationHistoryLogsService.getLocationPropertyUnit(prop);
        let color = this.locationHistoryLogsService.getLocationPropertyColor(prop, lhl[prop], this.selectedSite);
        color = this.sharedService.toTitleCase(color);
        const icon = this.locationHistoryLogsService.getLocationPropertyIcon(prop, lhl[prop], true);
        const showValue = !this.locationHistoryLogsService.isBooleanData(prop);

        if (!prevData && lhl[prop] !== null) {
          // first data
          this.lhlEntities[location.id][prop] = {
            locationId: location.id,
            label,
            value,
            unit,
            color,
            showValue,
            icon,
          };
          changeDetected = true;
          foundEvent = true;
        } else if (prevData && prevData.value !== value) {
          // not first data
          // update if value is not null
          this.lhlEntities[location.id][prop].label = label;
          this.lhlEntities[location.id][prop].value = value;
          this.lhlEntities[location.id][prop].unit = unit;
          this.lhlEntities[location.id][prop].color = color;
          this.lhlEntities[location.id][prop].icon = icon;
          foundEvent = true;
          changeDetected = true;
        }

        if (foundEvent) {
          if (lhl[prop] === null) {
            // delete from summary data
            delete this.lhlEntities[location.id][prop];
          } else {
            // add to location event
            locationEvents.push(this.lhlEntities[location.id][prop]);
          }
        }
      }

      if (changeDetected) {
        // update summary data
        latestLhls.push(this.lhlEntities[location.id]);
      }
    }

    if (locationEvents.length) {
      // pass events to location object
      this.locationEvents = locationEvents;
    }

    if (latestLhls.length) {
      // pass summary data to location object
      this.latestLhls = latestLhls;
    }
  }

  private startLHLUpdate() {
    this.fetchLatestLHL();
    clearInterval(this.intervals.fetchLHL);
    this.intervals.fetchLHL = setInterval(() => {
      this.fetchLatestLHL();
    }, 12000);
  }

  private stopLHLUpdate() {
    clearInterval(this.intervals.fetchLHL);
    this.intervals.fetchLHL = null;
  }

  private mapLHLValue(key, value) {
    let deviceTypeKey = this.locationHistoryLogsService.getLocationPropertyDeviceTypeKeyMapping(key);

    if (this.locationHistoryLogsService.isBooleanData(key)) {
      value = value === true ? 'YES' : 'NO';
    } else if (value && deviceTypeKey && this.deviceTypes) {
      // find device type
      const deviceType = this.deviceTypes.find((d) => d.key === deviceTypeKey);
      // find mapping value
      value = this.locationHistoryLogsService.getLocationPropertyMapValue(key, value, deviceType);
    }

    if (value && !isNaN(value)) {
      value = this.sharedService.numberFormat(value);
    }

    return value;
  }

  onAfterInit() {
    this.isFloorplanInit = true;
    if (this.isBrowserTabActive) {
      this.startDHLUpdate();
      this.startLHLUpdate();
    }
  }

  onEventLayerToggled(event: string[]) {
    this.activeLayers = event;

    this.stopDHLUpdate();
    this.stopLHLUpdate();

    if (this.activeLayers.includes(ObjectLayers.DeviceEvent)) {
      this.startDHLUpdate();
    }
    if (this.activeLayers.includes(ObjectLayers.LocationEvent)) {
      this.startLHLUpdate();
    }

    // if (this.activeLayers.includes('eventObject')) {
    //   if (this.activeLayers.includes('deviceEvent')) {
    //     this.startDHLUpdate();
    //   } else {
    //     this.stopDHLUpdate();
    //   }
    //   if (this.activeLayers.includes('locationEvent')) {
    //     this.startLHLUpdate();
    //   } else {
    //     this.stopLHLUpdate();
    //   }
    // } else {
    //   this.stopDHLUpdate();
    //   this.stopLHLUpdate();
    // }
  }

  onValueChanged(event) {
    const data = {
      ...event,
      floorplanImage: this.selectedFloor.floorplanImage,
    };

    this.locationsService.updateLocation(this.selectedFloor.id, data).subscribe({
      next: (result: any) => {
        const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
        const title = this.sharedService.getTranslation('LOCATION');
        this.sharedService.notify(title, text, 'success');
      },
      error: (error: any) => {
        const text = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('LOCATION');
        this.sharedService.notify(title, text, 'error');
      },
    });
  }

  // onViewLocationDetails(event) {
  //   const dialogOption: any = {};
  //   dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_LOCATION_DETAILS_MODAL_TITLE');
  //   dialogOption.data = {
  //     location: event,
  //     widgets: this.widgets,
  //   };
  //   this.locationDetailsModal = this.dialogService.open(LocationDetailsModalComponent, dialogOption);
  // }

  // onViewDeviceDetails(event) {
  //   const dialogOption: any = {};
  //   dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_DEVICE_DETAILS_MODAL_TITLE');
  //   dialogOption.data = {
  //     device: event,
  //   };
  //   this.deviceDetailsModal = this.dialogService.open(DeviceDetailsModalComponent, dialogOption);
  // }
}
