<div [ngSwitch]="showResult">
  <div *ngSwitchCase="1">
    <ag-grid-angular
      class="ag-theme-sc-clean"
      [gridOptions]="gridOptions"
      style="width: 100%; height: 100%"
    ></ag-grid-angular>
  </div>

  <div *ngSwitchCase="-1">
    <sc-form-error message="{{ 'BENCHMARK_NO_RESULT_FOUND' | translate }}"></sc-form-error>
  </div>

  <div *ngSwitchDefault>
    <sc-loading-indicator></sc-loading-indicator>
  </div>
</div>
