import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { DeviceTasksService } from '../services/device-task.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-task-action-cell',
  templateUrl: './device-task-action-cell.component.html',
  styleUrls: ['./device-task-action-cell.component.scss']
})
export class DeviceTaskActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceTasksService: DeviceTasksService,
    private sharedService: SharedService
  ) { }

  agInit(params: any): void {
    this.cell = params;

    // Edit form config
    this.formConfig = {
      name: 'DeviceTaskForm',
      data: this.cell.data,
      options: { modalTitle: 'DEVICE_TASK_FORM_TITLE' }
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: '_u',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this)
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this)
      }
    ];
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  updateRow(event) {
    // update row data
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.subscribers.DELETE_FILED = this.deviceTasksService
      .deleteDeviceTask(this.cell.data.id)
      .subscribe(
        (result: any) => {
          // update row data
          this.cell.context.parentComponent.updateRow({
            id: this.cell.data.id,
            is_active: false,
            is_deleted: true
          });
          const message = this.sharedService.getTranslation('UPDATE_SUCCESS');
          const title = this.sharedService.getTranslation('DEVICE_TASKS');
          this.sharedService.notify(title, message, 'success');
        },
        (error: any) => {
          const message = this.sharedService.getTranslation('UPDATE_FAIL');
          const title = this.sharedService.getTranslation('DEVICE_TASKS');
          this.sharedService.notify(title, message, 'error');
        }
      );
  }
}
