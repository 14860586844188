import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-tags-cell',
  templateUrl: 'tags-cell.component.html',
  styleUrls: ['tags-cell.component.scss']
})
export class TagsCellComponent implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }
}
