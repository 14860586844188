import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import * as moment from 'moment';

@Component({
  selector: 'sc-guest-request-status-cell',
  templateUrl: 'guest-request-status-cell.component.html'
})
export class GuestRequestStatusCellComponent
  implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }

  dateFormat(datetime) {
    if (typeof datetime !== 'undefined') {
      return moment(datetime).fromNow();
    }
    return '';
  }

  statusColor() {
    if (this.cell.data.isAccepted) {
      return 'green';
    } else if (this.cell.data.isDeclined) {
      return 'green';
    } else {
      return 'red';
    }
  }

  statusText() {
    if (this.cell.data.isAccepted) {
      return 'Acknowledged';
    } else if (this.cell.data.isDeclined) {
      return 'Declined';
    } else {
      return 'Requested ';
    }
  }
}
