import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ColorPickerModule } from 'ngx-color-picker';
import { TranslateModule } from '@ngx-translate/core';
import { SCFileManagerModule } from '../file-manager/file-manager.module';

import { InputCodeComponent } from './input-code/input-code.component';
import { SCAutocompleteInputComponent } from './autocomplete-input.component';
import { SCColorInputComponent } from './color-input.component';
import { SCCheckboxInputComponent } from './checkbox-input.component';
import { SCDatetimeInputComponent } from './datetime-input.component';
import { SCDropdownComponent } from './dropdown.component';
import { SCFormErrorComponent } from './form-error.component';
import { SCFieldErrorComponent } from './field-error.component';
import { SCFieldLabelComponent } from './field-label.component';
import { SCFileInputComponent } from './file-input/file-input.component';
import { SCLocalFileInputComponent } from './local-file-input/local-file-input.component';
import { SCMaskInputComponent } from './mask-input.component';
import { SCNumberInputComponent } from './number-input.component';
import { SCSubmitButtonComponent } from './submit-button/submit-button.component';
import { SCRadioInputComponent } from './radio-input.component';
import { SCTextEditorInputComponent } from './text-editor-input.component';
import { SCTextInputComponent } from './text-input.component';
import { SCTextareaComponent } from './textarea.component';
import { SCTimeInputComponent } from './time-input.component';
import { SCEmailInputComponent } from './email-input.component';
import { SCPasswordInputComponent } from './password-input.component';
import { TreeMultiSelectComponent } from './tree-multi-select/tree-multi-select.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { TreeSelect2Component } from './tree-select-2/tree-select.component';

const MODULES: any[] = [
  CommonModule,
  ReactiveFormsModule,
  TranslateModule.forChild({ extend: true, isolate: false }),
  AutoCompleteModule,
  ButtonModule,
  CalendarModule,
  CodemirrorModule,
  CheckboxModule,
  ColorPickerModule,
  DialogModule,
  DropdownModule,
  EditorModule,
  InputMaskModule,
  InputTextModule,
  InputTextareaModule,
  MessageModule,
  MessagesModule,
  MultiSelectModule,
  OrderListModule,
  OverlayPanelModule,
  RadioButtonModule,
  TooltipModule,
  TreeModule,
  SCFileManagerModule,
];

const COMPONENTS: any[] = [
  InputCodeComponent,
  SCAutocompleteInputComponent,
  SCColorInputComponent,
  SCCheckboxInputComponent,
  SCDatetimeInputComponent,
  SCDropdownComponent,
  SCFormErrorComponent,
  SCFieldErrorComponent,
  SCFieldLabelComponent,
  SCFileInputComponent,
  SCLocalFileInputComponent,
  SCMaskInputComponent,
  SCNumberInputComponent,
  SCSubmitButtonComponent,
  SCRadioInputComponent,
  SCTextEditorInputComponent,
  SCTextInputComponent,
  SCTextareaComponent,
  SCTimeInputComponent,
  SCEmailInputComponent,
  SCPasswordInputComponent,
  TreeMultiSelectComponent,
  TreeSelectComponent,
  TreeSelect2Component,
];

@NgModule({
  imports: [...MODULES],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [],
})
export class SCFormModule {}
