import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ModalConfig } from '@sc/modal/modal-config';
import { Device } from '@app/widgets/devices/models/device';
import { DeviceTask } from '../models/device-task';

import { DeviceTasksService } from '../services/device-task.service';
import { SharedService } from '@shared/shared.service';

import { DeviceTaskActionCellComponent } from '../device-task-action-cell/device-task-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-device-task-table',
  templateUrl: './device-task-table.component.html',
  styleUrls: ['./device-task-table.component.scss']
})
export class DeviceTaskTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: DeviceTask[];
  columns: any[];
  formConfig: ModalConfig;
  tableTitle: string;

  isFetchingDataset = false;
  isFirstTime = true;

  private selectedDevice: Device;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private deviceTasksService: DeviceTasksService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Table name
    this.tableTitle = 'DEVICE_TASK_TABLE_TITLE';
    // Table Columns
    this.columns = this.createColumns();

    // get device details
    this.subscribers.DEVICE_DETAILS = this.store
      .select(fromStore.getDeviceDetials)
      .subscribe(result => {
        if (result) {
          this.selectedDevice = result;

          // Create Form Config
          this.formConfig = {
            name: 'DeviceTaskForm',
            data: { deviceId: this.selectedDevice.id },
            options: { modalTitle: 'DEVICE_TASK_FORM_TITLE' }
          };

          this.isFirstTime = true;
          this.fetchDataset();
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.isFirstTime = false;

      const options = { device_id: this.selectedDevice.id };
      this.subscribers.GET_DATASET = this.deviceTasksService
        .getDeviceTasks(options)
        .subscribe((result: any) => {
          this.dataset = result.data.filter(d => !d.is_deleted);
          this.isFetchingDataset = false;
        });
    }
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'Name',
        field: 'name'
      },
      {
        colId: 'published_from',
        headerName: 'DEVICE_TASK_PUBLISHED_FROM',
        field: 'published_from',
        width: 150,
        valueFormatter: params => this.sharedService.dateFormat(params.value)
      },
      {
        colId: 'published_to',
        headerName: 'DEVICE_TASK_PUBLISHED_TO',
        field: 'published_to',
        width: 150,
        valueFormatter: params => this.sharedService.dateFormat(params.value)
      },
      {
        colId: 'execute_instantly',
        headerName: 'EXECUTE_INSTANTLY',
        field: 'execute_instantly',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'recurring',
        headerName: 'RECURRING',
        field: 'recurring',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'is_deleted',
        headerName: 'DELETED',
        field: 'is_deleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceTaskActionCellComponent
      }
    ];
  }

  get showSpinner() {
    return this.isFetchingDataset || this.isFirstTime;
  }
}
