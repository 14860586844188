<div class="trigger-device container">
  <p-panel header="Essential" [toggleable]="true">
    <div
      class="trigger-device-body"
      *ngIf="commandForm"
      [formGroup]="commandForm"
    >
      <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
        <div class="p-field p-col-12 p-md-6" *ngIf="isTemplate">
          <label class="input-label" for="name">
            {{ "NAME" | translate }}
          </label>

          <input type="text" pInputText inputId="name" formControlName="name" />
        </div>

        <div class="p-field p-col-12 p-md-6" *ngIf="!isTemplate">
          <label class="input-label" for="target">
            {{ "CCP_DEVICE" | translate }}
          </label>

          <sc-tree-select
            [form]="commandForm"
            field="target"
            [options]="devices"
            placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
              'CCP_DEVICE' | translate
            }}"
          ></sc-tree-select>
        </div>

        <div class="p-field p-col-12 p-md-6" *ngIf="isTemplate">
          <label class="input-label" for="target_type">
            {{ "CCP_DEVICE_TYPE" | translate }}
          </label>

          <p-dropdown
            inputId="target_type"
            [options]="selectItems?.writeableDeviceTypes"
            formControlName="target_type"
            placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
              'CCP_DEVICE_TYPE' | translate
            }}"
            [filter]="true"
            appendTo="body"
          ></p-dropdown>

          <div *ngIf="isTemplate">
            <p-checkbox
              inputId="is_target_editable"
              [binary]="true"
              [formControl]="commandForm?.get('is_target_editable')"
              [label]="'EDITABLE' | translate"
            ></p-checkbox>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-6">
          <label class="input-label" for="action">
            {{ "CCP_COMMON_ACTION" | translate }}
          </label>

          <p-dropdown
            inputId="action"
            [options]="actions"
            formControlName="action"
            placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
              'CCP_COMMON_ACTION' | translate
            }}"
            [filter]="true"
            appendTo="body"
          ></p-dropdown>
        </div>

        <div *ngIf="isTemplate" class="p-field p-col-12 p-md-6">
          <label class="input-label" for="action">
            {{ "CCP_VALUE_TYPE" | translate }}
          </label>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="value_type"
              [value]="VALUE_FIXED"
              inputId="optionFixed"
              formControlName="value_type"
            ></p-radioButton>
            <label for="optionFixed">{{ "FIXED" | translate }}</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="value_type"
              [value]="VALUE_FUNCTION"
              inputId="optionFunctionBased"
              formControlName="value_type"
            ></p-radioButton>
            <label for="optionFunctionBased">{{
              "FUNCTION_BASED" | translate
            }}</label>
          </div>
        </div>

        <div
          *ngIf="commandForm?.get('value_type').value === 'function'"
          class="p-field p-col-12 p-md-12"
        >
          <div class="function-operand container">
            <div class="function-operand-body" [formGroup]="commandForm">
              <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
                <div class="p-field p-col-12">
                  <label class="input-label" for="custom_function_idx">
                    {{ "CCP_CUSTOM_FUNCTION" | translate }}
                  </label>
                  <p-dropdown
                    inputId="custom_function_idx"
                    [options]="selectItems?.customFunctions"
                    formControlName="custom_function_idx"
                    placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
                      'CCP_CUSTOM_FUNCTION' | translate
                    }}"
                    [filter]="true"
                    appendTo="body"
                    [disabled]="!isTemplate"
                  ></p-dropdown>
                </div>

                <ng-container *ngIf="variables">
                  <div class="p-field p-col-12 p-md-12">
                    <sc-function-variables
                      [dataSources]="dataSources"
                      [selectItems]="selectItems"
                      [form]="commandForm"
                      [variables]="variables"
                      [isTemplate]="isTemplate"
                    ></sc-function-variables>
                  </div>
                </ng-container>

                <div *ngIf="isTemplate">
                  <div class="p-field p-col-12 p-md-12">
                    <label class="input-label" for="is_source_editable">
                      {{ "EDITABLE" | translate }}
                    </label>
                    <p-checkbox
                      inputId="is_source_editable"
                      [binary]="true"
                      [formControl]="commandForm?.get('is_source_editable')"
                      [label]="'YES' | translate"
                    ></p-checkbox>
                  </div>
                  <div
                    class="p-field p-col-12 p-md-12"
                    *ngIf="commandForm?.get('is_source_editable').value == true"
                  >
                    <label class="input-label" for="name">
                      {{ "NAME" | translate }}
                    </label>
                    <input
                      type="text"
                      pInputText
                      inputId="name"
                      formControlName="name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="commandForm?.get('value_type').value === 'fixed'"
          class="p-field p-col-12 p-md-3"
        >
          <label class="input-label" for="value">
            {{ "CCP_COMMON_VALUE" | translate }}
          </label>

          <div class="" [ngClass]="{ 'ui-inputgroup': valueStructure?.unit }">
            <ng-container [ngSwitch]="valueStructure?.type">
              <ng-container *ngSwitchCase="'numeric'">
                <input
                  type="number"
                  pInputText
                  inputId="value"
                  formControlName="value"
                  [min]="valueStructure?.min"
                  [max]="valueStructure?.max"
                />
              </ng-container>

              <ng-container *ngSwitchCase="'select'">
                <p-dropdown
                  inputId="value"
                  [options]="valueStructure?.options"
                  formControlName="value"
                  placeholder="Select"
                  [filter]="true"
                  appendTo="body"
                ></p-dropdown>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <input
                  type="text"
                  pInputText
                  inputId="value"
                  formControlName="value"
                />
              </ng-container>
            </ng-container>

            <span class="ui-inputgroup-addon" *ngIf="valueStructure?.unit">
              {{ valueStructure?.unit }}
            </span>
          </div>

          <div *ngIf="isTemplate">
            <p-checkbox
              inputId="is_value_editable"
              [binary]="true"
              [formControl]="commandForm?.get('is_value_editable')"
              [label]="'EDITABLE' | translate"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <sc-modifiers
      *ngIf="commandForm && modifiers && viewMode === _ruleEditorModes.Expert"
      [dataSources]="dataSources"
      [form]="commandForm"
      [modifiers]="modifiers"
      [isTemplate]="isTemplate"
      [selectItems]="selectItems"
    ></sc-modifiers>
  </p-panel>
  <p-panel header="Advanced" [toggleable]="true">
    <sc-command-options
      *ngIf="commandForm"
      [form]="commandForm"
      [isTemplate]="isTemplate"
    ></sc-command-options>
  </p-panel>
</div>
