<div class="command-options" [formGroup]="form">
  <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
    <div class="p-field p-col-12 p-md-6">
      <label class="input-label" for="delay">
        {{ "CCP_COMMON_DELAY" | translate }}
      </label>

      <div class="ui-inputgroup">
        <input
          type="number"
          pInputText
          inputId="delay"
          formControlName="delay"
          [min]="0"
        />

        <span class="ui-inputgroup-addon">
          {{ "CCP_COMMON_SECONDS" | translate }}
        </span>
      </div>

      <div *ngIf="isTemplate">
        <p-checkbox
          inputId="is_delay_editable"
          [binary]="true"
          [formControl]="form?.get('is_delay_editable')"
          [label]="'EDITABLE' | translate"
        ></p-checkbox>
      </div>
    </div>

    <div class="p-field p-col-12 p-md-6">
      <label class="input-label" for="timeout">
        {{ "CCP_COMMON_TIMEOUT" | translate }}
      </label>

      <div class="ui-inputgroup">
        <input
          type="number"
          pInputText
          inputId="timeout"
          formControlName="timeout"
          [min]="0"
        />

        <span class="ui-inputgroup-addon">
          {{ "CCP_COMMON_SECONDS" | translate }}
        </span>
      </div>

      <div *ngIf="isTemplate">
        <p-checkbox
          inputId="is_timeout_editable"
          [binary]="true"
          [formControl]="form?.get('is_timeout_editable')"
          [label]="'EDITABLE' | translate"
        ></p-checkbox>
      </div>
    </div>

    <div class="p-field p-col-12 p-md-6">
      <label class="input-label" for="repeat">
        {{ "CCP_COMMON_REPEAT" | translate }}
      </label>

      <p-checkbox
        inputId="repeat"
        [binary]="true"
        [formControl]="form?.get('repeat')"
        [label]="'CCP_COMMON_YES' | translate"
      ></p-checkbox>

      <div *ngIf="isTemplate">
        <p-checkbox
          inputId="is_repeat_editable"
          [binary]="true"
          [formControl]="form?.get('is_repeat_editable')"
          [label]="'EDITABLE' | translate"
        ></p-checkbox>
      </div>
    </div>

    <div class="p-field p-col-12 p-md-6">
      <label class="input-label" for="repetition_pause">
        {{ "CCP_RULE_REPETITION_PAUSE" | translate }}
      </label>

      <div class="ui-inputgroup">
        <input
          type="number"
          pInputText
          inputId="repetition_pause"
          formControlName="repetition_pause"
          [min]="0"
        />

        <span class="ui-inputgroup-addon">
          {{ "CCP_COMMON_SECONDS" | translate }}
        </span>
      </div>

      <div *ngIf="isTemplate">
        <p-checkbox
          inputId="is_repetition_pause_editable"
          [binary]="true"
          [formControl]="form?.get('is_repetition_pause_editable')"
          [label]="'EDITABLE' | translate"
        ></p-checkbox>
      </div>
    </div>
    <div class="p-field p-col-12 p-md-6">
      <label class="input-label" for="ignore_current_value">
        Force execution if current and target value are identical:
      </label>

      <p-checkbox
        inputId="ignore_current_value"
        [binary]="true"
        [formControl]="form?.get('ignore_current_value')"
        [label]="'CCP_COMMON_YES' | translate"
      ></p-checkbox>
    </div>
  </div>
</div>
