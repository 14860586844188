import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { AppStyle } from '../models/app-style';
import { AppStyleTemplate } from '@widgets/app-style-templates/models/app-style-template';

import { AppStyleFormsService } from '../services/app-style-forms.service';
import { AppStylesService } from '../services/app-styles.service';
import { AppStyleTemplateFormsService } from '@widgets/app-style-templates/services/app-style-template-forms.service';
import { AppStyleTemplatesService } from '@widgets/app-style-templates/services/app-style-templates.service';
import { SharedService } from '@shared/shared.service';

import { defaultsDeep } from 'lodash';
import { config } from '@app/config';

@Component({
  selector: 'sc-app-style-form',
  templateUrl: 'app-style-form.component.html',
})
export class AppStyleFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: AppStyle;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  patterns = config().regexPatterns;
  isSubmitting: boolean;
  fetching: { [key: string]: any };
  fetchingState: number;

  appStyleTemplates: AppStyleTemplate[];
  availableVars: SelectItem[];
  templates: SelectItem[];
  appStyle: AppStyle;
  variablesDisables = [];
  elementsDisables = [];
  allElementsDisables = [];

  constructor(
    private appStyleFormsService: AppStyleFormsService,
    private appStylesService: AppStylesService,
    private appStyleTemplateFormsService: AppStyleTemplateFormsService,
    private appStyleTemplatesService: AppStyleTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetching = {};
    this.fetchingState = 0;
    this.initForm();
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchAppStyle(this.data.id);
    } else {
      // ADD MODE
      this.fetchAppTemplates();
    }
  }

  ngOnDestroy() {
    for (const key in this.fetching) {
      if (this.fetching.hasOwnProperty(key) && this.fetching[key]) {
        this.fetching[key].unsubscribe();
      }
    }
  }

  fetchAppStyle(id?: number) {
    this.fetchingState++;
    this.fetching.APP_STYLE = this.appStylesService.getAppStyle(id).subscribe((result: any) => {
      result.data.variables = result.data.variables ? this.sharedService.jsonParse(result.data.variables) : [];
      result.data.elements = result.data.elements ? this.sharedService.jsonParse(result.data.elements) : [];
      this.appStyle = result.data;
      this.fetchAppTemplates(result.data.appStyleTemplateId);
      this.fetchingState--;
    });
  }

  fetchAppTemplates(id?: number) {
    this.fetchingState++;
    this.fetching.APP_TEMPLATES = this.appStyleTemplatesService.getAppStyleTemplates().subscribe((result: any) => {
      this.templates = [];

      // for EDIT MODE
      if (id) {
        const appStyleTemplate: any = result.data.find((a: any) => a.id === id);
        this.appStyleTemplates = [appStyleTemplate];

        this.templates.push({
          label: appStyleTemplate.name,
          value: appStyleTemplate.id,
        });

        delete appStyleTemplate.id;

        this.formData = appStyleTemplate;
        this.formData.id = this.appStyle.id;
        this.formData.key = this.appStyle.key;
        this.formData.name = this.appStyle.name;
        this.formData.description = this.appStyle.description;
        this.formData.variables = this.formData.variables ? this.sharedService.jsonParse(this.formData.variables) : [];
        this.formData.elements = this.formData.elements ? this.sharedService.jsonParse(this.formData.elements) : [];

        for (let i = 0; i < this.formData.elements.length; i++) {
          for (let j = 0; j < this.appStyle.elements.length; j++) {
            if (this.formData.elements[i].name === this.appStyle.elements[j].name) {
              for (let k = 0; k < this.formData.elements[i].styles.length; k++) {
                for (let l = 0; l < this.appStyle.elements[j].styles.length; l++) {
                  if (this.formData.elements[i].styles[k].property === this.appStyle.elements[j].styles[l].property) {
                    this.formData.elements[i].styles[k].value = this.appStyle.elements[j].styles[l].value;
                  }
                }
              }
            }
          }
        }

        for (let m = 0; m < this.formData.variables.length; m++) {
          for (let n = 0; n < this.formData.variables.length; n++) {
            if (
              typeof this.formData.variables[m] === 'undefined' ||
              typeof this.appStyle.variables[n] === 'undefined'
            ) {
              continue;
            }
            if (this.formData.variables[m].key === this.appStyle.variables[n].key) {
              this.formData.variables[m].value = this.appStyle.variables[n].value;
            }
          }
        }

        // APPLY APP STYLE TO APP TEMPLATE
        const style: any = defaultsDeep({}, this.formData, this.appStyle);
        this.availableVars = style.variables.map((v: any) => ({
          label: '@' + v.key,
          value: '@' + v.key,
        }));
        this.form.patchValue({
          appStyleTemplateId: this.appStyle.appStyleTemplateId,
          id: this.appStyle.id,
          key: this.appStyle.key,
          name: this.appStyle.name,
          description: this.appStyle.description,
          isActive: this.appStyle.isActive,
          isDeleted: this.appStyle.isDeleted,
        });
        this.form.get('id').disable();
        this.form.get('appStyleTemplateId').disable();
        this.updateForm(this.formData);
        this.checkDisableFormTemplate();
        this.fetchingState--;
      } else {
        this.appStyleTemplates = [];

        for (let i = 0; i < result.data.length; i++) {
          const tpl = result.data[i];
          if (tpl.isDeleted) {
            continue;
          }

          tpl.variables = tpl.variables ? this.sharedService.jsonParse(tpl.variables) : [];
          tpl.elements = tpl.elements ? this.sharedService.jsonParse(tpl.elements) : [];

          this.templates.push({
            label: tpl.name,
            value: tpl.id,
          });

          this.appStyleTemplates.push(tpl);
        }

        this.fetchingState--;
      }
    });
  }

  checkDisableFormTemplate() {
    // CHECK VARIABLES
    for (let i = 0; i < this.appStyle.variables.length; i++) {
      const tmpVariables = this.formData.variables.find((v: any) => v.key === this.appStyle.variables[i].key);
      if (tmpVariables) {
      } else {
        this.variablesDisables.push(this.appStyle.variables[i]);
      }
    }

    // CHECK ELEMENTS
    for (let i = 0; i < this.appStyle.elements.length; i++) {
      const tmpElements = this.formData.elements.find((v: any) => v.name === this.appStyle.elements[i].name);
      if (tmpElements) {
        const tmpElementsDisables = {
          name: tmpElements.name,
          isActive: tmpElements.isActive,
          styles: [],
        };
        const elements = { ...this.appStyle.elements[i] };

        for (let j = 0; j < elements.styles.length; j++) {
          const tmpProperty = tmpElements.styles.find((p: any) => p.property === elements.styles[j].property);
          if (tmpProperty) {
          } else {
            tmpElementsDisables.styles.push(elements.styles[j]);
          }
        }

        if (tmpElementsDisables.styles.length) {
          this.elementsDisables.push(tmpElementsDisables);
        }
      } else {
        this.allElementsDisables.push(this.appStyle.elements[i]);
      }
    }
  }

  elementDisable(group) {
    const advancedData = { ...group } && { ...group.value };
    const tmpElementDisable = this.elementsDisables.find((v: any) => v.name === advancedData.name);
    if (tmpElementDisable) {
      return tmpElementDisable;
    } else {
      return [];
    }
  }

  // INIT FORM
  initForm() {
    this.form = this.appStyleFormsService.initAppStyleForm();

    if (!this.editMode) {
      this.onFormValueChange();
    }
  }

  // WILL CALL WHEN FORM DATA HAS CHANGED
  onFormValueChange(): void {
    this.form.get('appStyleTemplateId').valueChanges.subscribe((val) => {
      if (val) {
        // GET APP STYLE TEMPLATE BY ID
        const template = this.appStyleTemplates.find((a: any) => a.id === val);
        if (!this.editMode) {
          // // APPLY APP STYLE TO APP TEMPLATE
          // const aStyle: any = defaultsDeep({}, this.formData, template);
          // this.availableVars = map(aStyle.variables, (v: any) => ({
          //   label: '@' + v.key,
          //   value: '@' + v.key,
          // }));
          // this.updateForm(aStyle);
          // } else {
          this.availableVars = template.variables.map((v: any) => ({
            label: '@' + v.key,
            value: '@' + v.key,
          }));
          this.updateForm(template);
        }
      }
    });
  }

  updateForm(data: any) {
    this.form.patchValue({
      key: data.key,
      name: data.name,
      description: data.description,
    });

    // SET APP STYLE TEMPLATE VARIABLES
    this.appStyleTemplateFormsService.setVariableFormArray(this.form, data.variables);

    // SET APP STYLE TEMPLATE ELEMENTS
    this.appStyleTemplateFormsService.setElementFormArray(this.form, data.elements);
  }

  get variables(): FormArray {
    return this.form.get('variables') as FormArray;
  }

  get elements(): FormArray {
    return this.form.get('elements') as FormArray;
  }

  submit() {
    if (this.form.valid) {
      this.errorMessage = null;
      this.isSubmitting = true;

      if (!this.editMode) {
        this.create(this.form.value);
      } else {
        this.update(this.formData.id, this.form.value);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(data: AppStyle) {
    this.appStylesService.createAppStyle(data).subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update(id: number, data: AppStyle) {
    this.appStylesService
      .updateAppStyle(id, data)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.appStylesService
      .deleteAppStyle(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify('App Style', result.message + ' Success!', 'success');
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
