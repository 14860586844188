import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Device } from '../models/device';

@Injectable()
export class DeviceFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initDeviceForm() {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      idx: { value: null, disabled: true },
      parentIdx: null, // similar to virtualDeviceHolderId but just use idx of vdh
      locationIdx: null,
      locationId: [null, Validators.required],
      siteId: [null, Validators.required],
      deviceTypeId: [null, Validators.required],
      deviceModelId: null,
      isVirtualDevicesHolder: 0,
      isVirtualDevice: 0,
      virtualDeviceHolderId: null,
      apiKey: null,
      pin: null,
      installationPin: null,
      automationId: null,
      gatewayId: null,
      relatedDevices: null,
      username: null,
      password: null,
      description: [null, Validators.required],
      uuid: null,
      pan: null,
      ip: null,
      port: null,
      anydeskId: null,
      brandId: null,
      modelNumber: null,
      protocolId: null,
      avcCalcWeight: null,
      considerForOccupancy: false,
      configFetchInterval: false,
      hlSenderInterval: false,
      excludeFromLHL: false,
      diffDetectionFactor: null,
      gatewayDoesNotRespondOnRequest: false,
      instructionPositionX: null,
      instructionPositionY: null,
      zwaveStatus: null,
      zwaveControllerSoftware: null,
      zwaveControllerPort: null,
      zigbeeStatus: null,
      zigbeeControllerPort: null,
      behaviourPatterns: null,
      locationsToControl: null,
      isControllableByGuest: false,
      isControllableByUser: true,
      isVisibleByGuest: false,
      isGlobalDevice: false,
      isGrouped: false,
      isDeviceAlertEnabled: false,
      isHiddenFromRoomController: false,
      deviceAlertTimeout: null,
      valueModifier: null,
      installationHeight: null,
      productKey: null,
      isPersistent: true,
      isActive: false,
      isDeleted: false,
    });
  }

  initCeosDeviceForm(device: any) {
    const fields: any = {
      id: device.id,
      idx: device.idx,
      description: [device.description, Validators.required],
      ceosId: device.ceosId,
      isActive: device.isActive,
      isPersistent: device.isPersistent,
    };
    if (device.deviceTypeKey === 'vdh') {
      fields.virtualDevices = this.initVirtualDevicesFormArray(device.virtualDevices || []);
    }
    return this.formBuilder.group(fields);
  }

  initVirtualDevicesFormArray(virtualDevices: Device[]) {
    const formGroups = [];
    for (const vd of virtualDevices) {
      formGroups.push(this.initVirtualDeviceFormGroup(vd));
    }
    return this.formBuilder.array(formGroups);
  }

  initVirtualDeviceFormGroup(virtualDevice: Device) {
    return this.formBuilder.group({
      id: virtualDevice.id,
      idx: virtualDevice.idx,
      isActive: virtualDevice.isActive,
      isPersistent: virtualDevice.isPersistent,
      diffDetectionFactor: virtualDevice.diffDetectionFactor,
      excludeFromLHL: virtualDevice.excludeFromLHL,
      isHiddenFromRoomController: virtualDevice.isHiddenFromRoomController,
    });
  }
}
