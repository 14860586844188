<div class="device-value-storage container">
  <p-panel header="Essential" [toggleable]="true">
    <div
      class="device-value-storage-body"
      *ngIf="commandForm"
      [formGroup]="commandForm"
    >
      <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
        <div class="p-field p-col-12 p-md-6" *ngIf="isTemplate">
          <label class="input-label" for="name">
            {{ "NAME" | translate }}
          </label>

          <input type="text" pInputText inputId="name" formControlName="name" />
        </div>

        <div class="p-field p-col-12 p-md-6" *ngIf="!isTemplate">
          <label class="input-label" for="target">
            {{ "CCP_DEVICE" | translate }}
          </label>

          <sc-tree-select
            [form]="commandForm"
            field="target"
            [options]="dataSources?.commandDevicesTree"
            placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
              'CCP_DEVICE' | translate
            }}"
          ></sc-tree-select>
        </div>

        <div class="p-field p-col-12 p-md-6" *ngIf="isTemplate">
          <label class="input-label" for="target_type">
            {{ "CCP_DEVICE_TYPE" | translate }}
          </label>

          <p-dropdown
            inputId="target_type"
            [options]="selectItems?.deviceTypes"
            formControlName="target_type"
            placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
              'CCP_DEVICE_TYPE' | translate
            }}"
            [filter]="true"
            appendTo="body"
          ></p-dropdown>

          <div *ngIf="isTemplate">
            <p-checkbox
              inputId="is_target_editable"
              [binary]="true"
              [formControl]="commandForm?.get('is_target_editable')"
              [label]="'EDITABLE' | translate"
            ></p-checkbox>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-6">
          <label class="input-label" for="action">
            {{ "CCP_COMMON_ACTION" | translate }}
          </label>

          <p-dropdown
            inputId="action"
            [options]="actions"
            formControlName="action"
            placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
              'ACTION' | translate
            }}"
            [filter]="true"
            appendTo="body"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </p-panel>
  <p-panel header="Advanced" [toggleable]="true">
    <sc-command-options
      *ngIf="commandForm"
      [form]="commandForm"
      [isTemplate]="isTemplate"
    ></sc-command-options>
  </p-panel>
</div>
