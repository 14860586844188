import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';

@Injectable()
export class GuestSessionFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initGuestSessionForm(): FormGroup {
    return this.formBuilder.group({
      id: [{ value: null, disabled: true }],
      companyId: [{ value: null, disabled: true }, Validators.required],
      siteId: [{ value: null, disabled: true }, Validators.required],
      locationId: [null, Validators.required],
      validFrom: [moment().toDate(), Validators.required],
      validTo: null,
      gender: null,
      firstname: null,
      lastname: null,
      locale: ['en', Validators.required],
      pin: [null, Validators.required],
      isActive: true,
      isDeleted: false,
    });
  }
}
