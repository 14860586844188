import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  AppStyleTemplateVariable,
  AppStyleTemplateElement,
  AppStyleTemplateElementStyle
} from '../models/app-style-template';
import {
  AppStyleVariable,
  AppStyleElement,
  AppStyleElementStyle
} from '../../app-styles/models/app-style';

@Injectable()
export class AppStyleTemplateFormsService {
  constructor(private formBuilder: FormBuilder) {}

  /**
   * INITIALIZE APP STYLE TEMPLATE FORM
   */
  initAppStyleTemplateForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      description: null,
      elements: this.formBuilder.array([]),
      variables: this.formBuilder.array([]),
      isActive: false,
      isDeleted: false
    });
  }

  /**
   * INITIALIZE ELEMENT FORM
   */
  initElementFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: [null, Validators.required],
      isActive: false,
      styles: this.formBuilder.array([])
    });
  }

  /**
   * SET ELEMENT FORM DATA
   * @param form
   * @param elements
   */
  setElementFormArray(
    form: FormGroup,
    elements: (AppStyleTemplateElement | AppStyleElement)[]
  ) {
    const formGroups = elements.map((e: any) => {
      const styleFormGroups = e.styles.map(s => this.formBuilder.group(s));
      // create empty element form group
      const elm = this.initElementFormGroup();
      // patch element data, because "styles" is formArray then style will not be patched
      elm.patchValue(e);
      // patch "styles" into element form group by setControl
      // with array of style form groups
      elm.setControl('styles', this.formBuilder.array(styleFormGroups));
      // return element form group
      return elm;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('elements', formArray);
  }

  /**
   * INITIALIZE ELEMENT STYLE FORM
   */
  initElementStyleFormGroup(): FormGroup {
    return this.formBuilder.group({
      property: [null, Validators.required],
      value: null
    });
  }

  /**
   * SET ELEMENT STYLE FORM DATA
   * @param form
   * @param data
   */
  setElementStyleFormArray(
    form: FormGroup,
    data: (AppStyleTemplateElementStyle | AppStyleElementStyle)[]
  ) {
    const formGroups = data.map(item => this.formBuilder.group(item));
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('styles', formArray);
  }

  /**
   * INITIALIZE VARIABLE FORM
   */
  initVariableFormGroup(): FormGroup {
    return this.formBuilder.group({
      key: [null, Validators.required],
      value: null,
      description: null,
      type: null
    });
  }

  /**
   * SET VARIABLE FORM DATA
   * @param form
   * @param variables
   */
  setVariableFormArray(
    form: FormGroup,
    variables: (AppStyleTemplateVariable | AppStyleVariable)[]
  ) {
    const formGroups = variables.map(v => this.formBuilder.group(v));
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('variables', formArray);
  }

  getElementProperties(element: string) {
    switch (element) {
      case 'react-native':
        return reactNativeProps.sort();

      default:
        return null;
    }
  }

  getVariableTypes() {
    return ['file', 'hex', 'pct', 'px', 'rgba', 'url'].sort();
  }
}

const reactNativeProps = [
  'alignItems',
  'alignSelf',
  'borderBottomWidth',
  'borderLeftWidth',
  'borderRightWidth',
  'borderTopWidth',
  'borderWidth',
  'bottom',
  'flex',
  'flexDirection',
  'flexWrap',
  'height',
  'justifyContent',
  'left',
  'margin',
  'marginBottom',
  'marginHorizontal',
  'marginLeft',
  'marginRight',
  'marginTop',
  'marginVertical',
  'maxHeight',
  'maxWidth',
  'minHeight',
  'minWidth',
  'padding',
  'paddingBottom',
  'paddingHorizontal',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'paddingVertical',
  'position',
  'right',
  'top',
  'width',
  'zIndex',
  'shadowColor',
  'shadowOffset',
  'shadowOpacity',
  'shadowRadius',
  'decomposedMatrix',
  'transform',
  'transformMatrix',
  'backfaceVisibility',
  'backgroundColor',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderColor',
  'borderRadius',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'opacity',
  'overflow',
  'resizeMode',
  'tintColor',
  'overlayColor',
  'borderBottomColor',
  'borderLeftColor',
  'borderRightColor',
  'borderStyle',
  'borderTopColor',
  'elevation',
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'textAlign',
  'textDecorationLine',
  'textShadowColor',
  'textShadowOffset',
  'textShadowRadius',
  'textAlignVertical',
  'letterSpacing',
  'textDecorationColor',
  'textDecorationStyle',
  'writingDirection',
  'autoFocus',
  'keyboardType',
  'maxLength',
  'onChangeText'
];
