import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-local-file-input',
  templateUrl: './local-file-input.component.html',
  styleUrls: ['./local-file-input.component.scss']
})
export class SCLocalFileInputComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  placeholder: string;

  inputId: string;
  selectedFile: any;

  constructor() {}

  ngOnInit() {
    this.placeholder = this.placeholder || '';
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-local-file-input_' + this.controlName + new Date().getTime();
    this.checkFormValue();
  }

  openFileBrowser() {
    const element = document.getElementById(this.inputId);
    element.click();
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.form.get(this.controlName).setValue(file);
      this.form.markAsDirty();
      this.selectedFile = file.name;
    }
  }

  deselect() {
    this.selectedFile = undefined;
    this.form.get(this.controlName).reset();
    this.form.markAsDirty();
  }

  private checkFormValue() {
    const currentValue = this.form.get(this.controlName).value;
    if (currentValue) {
      this.selectedFile = currentValue;
    }
  }
}
