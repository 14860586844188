<div class="device-holder-setting">
  <div class="device-holder-setting-header">
    <div class="device-holder-info">
      <div class="primary">
        {{ data?.modelName }}
      </div>

      <div class="secondary">
        {{ data?.model }}
      </div>
    </div>

    <div class="device-holder-image" *ngIf="productImage">
      <img [src]="productImage" width="70" />
    </div>
  </div>

  <div class="device-holder-setting-body" [formGroup]="form">
    <div class="ui-g">
      <!-- DESCRIPTION -->
      <div class="ui-g-12 ui-md-6">
        <sc-text-input
          controlName="description"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <!-- IS_ACTIVE -->
      <div class="ui-g-12 ui-md-6 mt-3">
        <sc-checkbox-input
          controlName="isActive"
          [form]="form"
          label="{{ 'ACTIVE' | translate }}"
        ></sc-checkbox-input>
      </div>
    </div>

    <div class="ui-g">
      <!-- LOCATION_ID -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="locationId"
          [form]="form"
          label="{{ 'LOCATION' | translate }}"
          [options]="selectItems?.locations"
        ></sc-dropdown>
      </div>

      <!-- AUTOMATION_ID -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="automationId"
          [form]="form"
          label="{{ 'AUTOMATION' | translate }}"
          [options]="selectItems?.automations"
        ></sc-dropdown>
      </div>
    </div>

    <div class="ui-g" *ngIf="showOmnisRequiredFields">
      <!-- INSTALLATION_HEIGHT -->
      <div class="ui-g-12 ui-md-6">
        <sc-number-input
          controlName="installationHeight"
          [form]="form"
          label="{{ 'INSTALLATION_HEIGHT' | translate }}"
        ></sc-number-input>
      </div>
    </div>
  </div>
</div>
