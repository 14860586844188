<div [formGroup]="group" class="ui-g">
  <!-- LOCALE FIELD -->
  <div class="ui-g-12">
    <sc-dropdown
      controlName="locale"
      [form]="group"
      label="{{ 'LOCALE' | translate }}"
      [options]="optionData['locales']"
    ></sc-dropdown>
  </div>

  <!-- TEXT FIELD -->
  <div class="ui-g-12">
    <sc-textarea
      controlName="text"
      [form]="group"
      label="{{ 'TEXT' | translate }}"
    ></sc-textarea>
  </div>

  <!-- URL FIELD -->
  <div class="ui-g-12">
    <sc-text-input
      controlName="url"
      [form]="group"
      label="{{ 'URL' | translate }}"
    ></sc-text-input>
  </div>

  <div class="ui-g-12">
    <button
      pButton
      type="button"
      class="ui-button-danger"
      label="{{ 'REMOVE' | translate }}"
      (click)="remove()"
    ></button>
  </div>
</div>
