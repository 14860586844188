import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { Rule } from '@widgets/rules/models/rule';
import { RuleCommand } from '@widgets/rules/models/rule-command';
import { RuleTemplate } from '@widgets/rule-templates/models/rule-template';
import { RuleTemplateCommand } from '@widgets/rule-templates/models/rule-template-command';

@Component({
  selector: 'sc-rule-command-mass-edit-form',
  templateUrl: './command-form.component.html',
  styleUrls: ['./command-form.component.scss']
})
export class RuleCommandMassEditFormComponent implements OnInit {
  @Input()
  data: any;
  @Input()
  form: FormGroup;
  @Input() isEndCommand: boolean;

  rule: Rule;
  ruleTemplate: RuleTemplate;
  // commands: RuleTemplateCommand[];

  constructor() {}

  ngOnInit() {
    // this.rule = this.form.value;
    this.ruleTemplate = this.data.selectedRuleTemplate;
    this.rule = this.data.selectedRule;
  }

  get templateCommands(): RuleTemplateCommand[] {
    return this.ruleTemplate[this.commandsFormName] || [];
  }

  get commands(): RuleCommand[] {
    return this.rule[this.commandsFormName] || [];
  }

  hasError(command) {
    if (command) {
      const errors = Object.keys(command.errors);
      if (errors.length) {
        return 'red white-text';
      }
    }
    return '';
  }

  isEmpty(command) {
    if (command && command.errors && command.errors.empty) {
      return true;
    }
    return false;
  }

  get commandsFormName() {
    return this.isEndCommand ? 'endCommands' : 'startCommands';
  }

  get commandsForm() {
    return this.form.controls[this.commandsFormName] as FormArray;
  }

  get executeOnlyOneTimeFormControl() {
    return this.isEndCommand
      ? 'executeOnlyOneTimeUntilRuleWasStart'
      : 'executeOnlyOneTimeUntilRuleWasEnd';
  }

  get executeOnlyOneTimeTooltipText() {
    return this.isEndCommand
      ? 'EXECUTE_ONLY_ONE_TIME_UNTIL_RULE_WAS_START'
      : 'EXECUTE_ONLY_ONE_TIME_UNTIL_RULE_WAS_END';
  }

  getCommandActionText(control) {
    const formData = control && control.value ? control.value : control;

    if (formData) {
      switch (formData.targetTypeId) {
        case 'd':
        case 'dvs':
          return formData.action;

        case 'l':
          if (
            typeof formData.locationPropertyAdjustValue !== 'undefined' &&
            formData.locationPropertyAdjustValue !== null
          ) {
            return 'ADJUST_VALUE';
          } else if (
            typeof formData.locationPropertyForceValue !== 'undefined' &&
            formData.locationPropertyForceValue !== null
          ) {
            return 'FORCE_VALUE';
          }
          return '(no action)';

        case 's':
          return 'NOTIFY';
      }
      // if (
      //   typeof formData.locationPropertyForceValue !== 'undefined' &&
      //   formData.locationPropertyForceValue !== null
      // ) {
      //   return 'FORCE_VALUE';
      // } else if (
      //   typeof formData.locationPropertyAdjustValue !== 'undefined' &&
      //   formData.locationPropertyAdjustValue !== null
      // ) {
      //   return 'ADJUST_VALUE';
      // } else if (
      //   typeof formData.notificationTemplateId !== 'undefined' &&
      //   formData.notificationTemplateId !== null
      // ) {
      //   return 'NOTIFIER';
      // }
      // return formData.action;
    }
    return '';
  }

  getLocationParameter(command) {
    if (typeof command.locationPropertyAdjustValue !== 'undefined') {
      return command.locationPropertyAdjustValue;
    } else if (typeof command.locationPropertyForceValue !== 'undefined') {
      return command.locationPropertyForceValue;
    }
  }

  getDeviceTypeName(deviceTypeId) {
    if (
      this.data &&
      this.data.raw &&
      this.data.raw.deviceTypes &&
      deviceTypeId
    ) {
      const deviceType = this.data.raw.deviceTypes.find(
        d => d.id === deviceTypeId
      );
      if (deviceType) {
        return deviceType.name;
      }
    }
    return '(empty)';
  }

  getLocationPropertyLabel(locationPropertyKey) {
    if (
      this.data &&
      this.data.raw &&
      this.data.raw.locationProperties &&
      locationPropertyKey
    ) {
      const locationProperty = this.data.raw.locationProperties.find(
        d => d.key === locationPropertyKey
      );
      if (locationProperty) {
        return locationProperty.label;
      }
    }
    return '(empty)';
  }

  getNotificationTemplateName(notificationTemplateId) {
    if (
      this.data &&
      this.data.raw &&
      this.data.raw.notificationTemplates &&
      notificationTemplateId
    ) {
      const notificationTemplate = this.data.raw.notificationTemplates.find(
        d => d.id === notificationTemplateId
      );
      if (notificationTemplate) {
        return notificationTemplate.name;
      }
    }
    return '(empty)';
  }
}
