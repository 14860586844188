import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-scheduler-cell',
  templateUrl: 'scheduler-cell.component.html',
  styleUrls: ['scheduler-cell.component.scss'],
})
export class SchedulerCellComponent implements ICellRendererAngularComp {
  cell: any;
  schedulesHtml: string;

  constructor(private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;

    this.createTooltipContent();
  }

  refresh(): boolean {
    return false;
  }

  createTooltipContent() {
    const cellValue = this.cell && this.cell.value;
    if (cellValue && cellValue.length) {
      this.schedulesHtml = '<div class="py-2">';
      cellValue.forEach(item => {
        const datetimeString = this.sharedService.dateFormat(item.sendNotificationAt);
        if (datetimeString) {
          this.schedulesHtml +=
            '<p class="m-0">' + '<i class="fa fa-clock-o"></i> ' + datetimeString + '</p>';
        }
      });
      this.schedulesHtml += '</div>';
      // } else {
      // this.schedulesHtml = '<div><p>no push notifications</p></div>';
    }
  }
}
