import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'sc-time-input',
  template: `
    <div>
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
        [description]="description"
        [warning]="warning"
      ></sc-field-label>

      <div [formGroup]="timeForm" class="ui-inputgroup">
        <input
          pInputText
          type="text"
          [id]="inputId"
          formControlName="hours"
          placeholder="HH"
          autocomplete="off"
        />
        <span class="ui-inputgroup-addon">:</span>
        <input
          pInputText
          type="text"
          formControlName="minutes"
          placeholder="MM"
          autocomplete="off"
        />
      </div>

      <sc-field-error
        [for]="label"
        [fieldControl]="form.controls[controlName]"
      ></sc-field-error>
    </div>
  `
})
export class SCTimeInputComponent implements OnInit, OnDestroy {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  warning: string;

  inputId: string;
  timeForm: FormGroup;

  private formSubscriber: any;
  private valueChangeTimeout: any;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.showLabel =
      typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-time-input_' + this.controlName + new Date().getTime();

    this.initForm();
  }

  ngOnDestroy() {
    this.clearTimeout();
    this.clearSubscribe();
  }

  private initForm() {
    this.timeForm = this.formBuilder.group({
      hours: null,
      minutes: null
    });

    // patch time form by src field
    const fieldValue = this.form.get(this.controlName).value;
    if (fieldValue) {
      const hours = fieldValue.slice(0, 2);
      const minutes = fieldValue.slice(2);
      this.timeForm.patchValue({ hours, minutes });
    }

    // watch time form value changes
    this.formSubscriber = this.timeForm.valueChanges.subscribe(result => {
      this.clearTimeout();
      this.valueChangeTimeout = setTimeout(() => {
        let formValue = '';
        if (result.hours) {
          formValue += this.validateValue('h', result.hours);
        }
        if (result.minutes) {
          formValue += this.validateValue('m', result.minutes);
        }
        this.form.get(this.controlName).setValue(formValue);
        this.form.get(this.controlName).markAsDirty();
      }, 200);
    });
  }

  private validateValue(key: string, value: number) {
    let validValue: any = +value;

    if (key === 'h') {
      if (validValue >= 24 || validValue < 0 || isNaN(validValue)) {
        validValue = '00';
        this.timeForm.get('hours').setValue(validValue);
      }
    } else if (key === 'm') {
      if (validValue >= 60 || validValue < 0 || isNaN(validValue)) {
        validValue = '00';
        this.timeForm.get('minutes').setValue(validValue);
      }
    }
    validValue = ((validValue + '').length === 1 ? '0' : '') + validValue;
    return validValue;
  }

  private clearTimeout() {
    if (this.valueChangeTimeout) {
      clearTimeout(this.valueChangeTimeout);
    }
    this.valueChangeTimeout = null;
  }

  private clearSubscribe() {
    if (this.formSubscriber) {
      this.formSubscriber.unsubscribe();
      this.formSubscriber = null;
    }
  }
}

