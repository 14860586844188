<div
  class="data-display-button"
  pTooltip="{{ 'FILTER' | translate }}"
  tooltipPosition="top"
  appendTo="body"
>
  <!-- pButton -->
  <!-- icon="fa fa-filter" -->
  <!-- iconPos="left" -->
  <button type="button" class="filter-button" (click)="menu.toggle($event)">
    <span class="fa fa-filter"></span>
  </button>

  <p-overlayPanel #menu appendTo="body" styleClass="data-display-overlay">
    <ul class="filter-list">
      <li class="filter-list-item" *ngFor="let f of filters">
        <a (click)="toggle(f.key)">
          <i
            class="fa fa-fw"
            [ngClass]="{
              'fa-check-square-o': f.value,
              'fa-square-o': !f.value
            }"
          ></i>
          {{ f.key | translate }}
        </a>
      </li>
    </ul>
  </p-overlayPanel>
</div>
