import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { EndUserRole } from '../interfaces/end-user-role';
import { EndUserRoleService } from '../services/end-user-role.service';
import { SharedService } from '@shared/shared.service';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { RoleActionCellComponent } from '../role-action-cell/role-action-cell.component';
import { SiteTypesService } from '@widgets/site-types/services/site-types.service';
import { SiteType } from '@app/widgets/site-types/models/site-type';

@Component({
  selector: 'sc-roles-table',
  templateUrl: './roles-table.component.html',
  styleUrls: ['./roles-table.component.scss'],
})
export class RolesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: EndUserRole[];
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string = 'END_USER_ROLE';

  private siteTypes: SiteType[] = [];
  private subscribers: { [key: string]: any } = {};

  constructor(
    private endUserRoleService: EndUserRoleService,
    private sharedService: SharedService,
    private siteTypesService: SiteTypesService
  ) {}

  ngOnInit() {
    this.initTable();
    this.fetchSiteTypes();
    this.fetchDataset();
  }

  ngOnDestroy() {
    // unsubscribe all subscribers
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.fetchDataset = this.endUserRoleService.getEndUserRoles().subscribe((result: any) => {
      this.dataset = result.data;
    });
  }

  private fetchSiteTypes() {
    this.subscribers.fetchSiteTypes = this.siteTypesService.getSiteTypes().subscribe((result: any) => {
      this.siteTypes = result.data;

      if (this.table && this.table.api) {
        this.table.api.refreshCells();
      }
    });
  }

  private initTable() {
    this.columns = [
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'siteType',
        headerName: 'SITE_TYPE',
        field: 'site_type_id',
        cellRenderer: (params) => {
          if (params.data && params.data.site_type_id && this.siteTypes && this.siteTypes.length) {
            const type = this.siteTypes.find((item) => item.id === params.data.site_type_id);
            if (type) {
              const name = this.sharedService.getLocaleWidgetName(type);
              if (name) {
                return name;
              }
            }
          }
          return params.value;
        },
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'idx',
        headerName: 'IDX',
        field: 'idx',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'is_active',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'is_deleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: RoleActionCellComponent,
      },
    ];
    this.formConfig = {
      name: 'EndUserRoleForm',
      options: { modalTitle: 'END_USER_ROLE_FORM_TITLE' },
    };
  }

  afterTableInit(table: GridOptions) {
    this.table = table;
  }
}
