export enum ObjectClasses {
  Device = 'DeviceObject',
  DeviceRelationship = 'DeviceRelationshipObject',
  DeviceScope = 'DeviceScopeObject',
  Event = 'EventObject',
  Location = 'LocationObject',
}

export enum ObjectLayers {
  Device = 'deviceObject',
  DeviceEvent = 'deviceEvent',
  DeviceRelationship = 'deviceRelationshipObject',
  DeviceScope = 'deviceScopeObject',
  // Event = 'eventObject',
  Location = 'locationObject',
  LocationEvent = 'locationEvent',
}

export enum ObjectTypes {
  Device = 'deviceObject',
  DeviceRelationship = 'deviceRelationshipObject',
  DeviceScope = 'deviceScopeObject',
  Event = 'eventObject',
  Location = 'locationObject',
}
