import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { BulkCreatorComponent } from './bulk-creator.component';
import { UnitsCreatorComponent } from './units-creator/units-creator.component';
import { FloorFormComponent } from './units-creator/floor-form/floor-form.component';
import { AreasCreatorComponent } from './areas-creator/areas-creator.component';
import { DevicesCreatorComponent } from './devices-creator/devices-creator.component';
import { AutomationsCreatorComponent } from './automations-creator/automations-creator.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    BulkCreatorComponent,
    UnitsCreatorComponent,
    FloorFormComponent,
    AreasCreatorComponent,
    DevicesCreatorComponent,
    AutomationsCreatorComponent,
  ],
  entryComponents: [BulkCreatorComponent],
})
export class BulkCreatorModule {}
