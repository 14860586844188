<a
  class="toolbar-item user-menu"
  (click)="panel.show($event)"
  [ngClass]="{ active: opened }"
>
  <span class="toolbar-item-icon">
    <span class="icon icon-user"></span>
  </span>
  <span class="toolbar-item-label">
    {{ username }}
  </span>
  <span class="toolbar-item-arrow">
    <span *ngIf="!opened" class="fa fa-fw fa-angle-down"></span>
    <span *ngIf="opened" class="fa fa-fw fa-angle-up"></span>
  </span>
</a>

<p-overlayPanel
  #panel
  appendTo="body"
  styleClass="toolbar-user-menu"
  (onShow)="opened = true"
  (onHide)="opened = false"
>
  <ul class="menu">
    <li class="menu-item" (click)="onMyAccountClicked(); panel.hide()">
      <i class="fa fa-fw fa-user"></i> {{ "MY_ACCOUNT" | translate }}
    </li>
    <li class="menu-item" (click)="onLogOutClicked()">
      <i class="fa fa-fw fa-sign-out"></i> {{ "LOGOUT" | translate }}
    </li>
  </ul>
</p-overlayPanel>
