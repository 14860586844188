import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { GridOptions } from 'ag-grid';
import { Site } from '@widgets/sites/models/site';
import { ModalConfig } from '@sc/modal/modal-config';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { RulesService } from '@widgets/rules/services/rules.service';
import { RuleHistoryLogsService } from '@widgets/rules/services/rule-history-logs.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { SharedService } from '@shared/shared.service';
import { SCTableService } from '@sc/table/table.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { RuleMonitorActionCellComponent } from '../rule-monitor-action-cell/rule-monitor-action-cell.component';

@Component({
  selector: 'sc-rule-monitor-table',
  templateUrl: 'rule-monitor-table.component.html',
})
export class RuleMonitorTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: any[];
  subscribers: { [key: string]: any };
  formConfig: ModalConfig;
  tableTitle: string;

  table: GridOptions;
  isFetchHistoryLog: boolean;
  fetchRuleHistoryInterval: any;

  selectedSite: Site;
  locations: any[];

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private ruleHistoryLogsService: RuleHistoryLogsService,
    private rulesService: RulesService,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private tableService: SCTableService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'executionMode',
        headerName: 'EXECUTION_MODE',
        field: 'executionMode',
        valueFormatter: this.getRuleExecutionMode.bind(this),
        valueGetter: this.getRuleExecutionMode.bind(this),
      },
      {
        colId: 'lastExecution',
        headerName: 'LAST_EXECUTION',
        field: 'lastExecution',
        valueFormatter: (params) => (params.value ? this.sharedService.dateFormat(params.value) : ''),
      },
      {
        colId: 'error',
        headerName: 'ERROR',
      },
      {
        colId: 'location',
        headerName: 'LOCATION',
        field: 'location',
        valueFormatter: this.getLocationName.bind(this),
        valueGetter: this.getLocationName.bind(this),
      },
      {
        colId: 'locationId',
        headerName: 'LOCATION_ID',
        field: 'locationId',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: RuleMonitorActionCellComponent,
      },
    ];
    this.tableTitle = 'RULE_MONITOR';
    this.subscribers = {};

    this.store
      .select(fromStore.getSelectedSite)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.dataset = [];
          this.locations = [];

          this.fetchDataset();
          this.fetchLocations();
        }
      });

    // this.fetchLocations();
    // this.fetchDataset();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.stopAutoUpdate();

    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  /**
   * Get Company and Site ID (integer) from Selected Site
   */
  // getCompanyAndSiteId() {
  //   const company = this.sharedService.getCompanyByCompanyId(
  //     this.selectedSite.companyId
  //   );
  //   const site = this.sharedService.getSiteBySiteId(this.selectedSite.siteId);

  //   return { companyId: company.id, siteId: site.id };
  // }

  fetchDataset() {
    if (this.selectedSite) {
      const { companyId, id } = this.selectedSite;
      // const { companyId, siteId } = this.getCompanyAndSiteId();
      const options = {
        // companyId,
        siteId: id,
        columns: 'id,name,locationId,isActive,isDeleted',
        minimize: true,
      };
      this.subscribers.GET_DATASET = this.rulesService.getRules(options).subscribe((result: any) => {
        const dataset = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);

        this.dataset = dataset.filter((d) => !d.isDeleted);

        // ____ FETCH HISTORY LOG
        this.fetchRuleHistory();
        this.startAutoUpdate();
      });
    }
  }

  fetchRuleHistory() {
    if (!this.isFetchHistoryLog && this.selectedSite) {
      this.isFetchHistoryLog = true;
      const { companyId, id } = this.selectedSite;
      // const { companyId, siteId } = this.getCompanyAndSiteId();
      const options = {
        companyId,
        siteId: id,
        onlyLatest: true,
      };
      this.subscribers.GET_RULE_HISTORY = this.ruleHistoryLogsService
        .getRuleHistoryLog(options)
        .subscribe((result: any) => {
          this.updateHistoryLogData(result);
          this.isFetchHistoryLog = false;
        });
    }
  }

  fetchLocations() {
    if (this.selectedSite) {
      const { companyId, id } = this.selectedSite;
      const options = {
        // companyId,
        siteId: id,
        columns: 'id,description',
        minimize: true,
      };
      this.subscribers.GET_LOCATIONS = this.locationsService.getLocations(options).subscribe((result: any) => {
        this.locations = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);

        if (this.dataset && this.table) {
          this.table.api.refreshCells({ columns: ['location'], force: true });
        }
      });
    }
  }

  private updateHistoryLogData(historyLog: any) {
    if (this.table) {
      const itemsToUpdate = historyLog.map((hlog) => ({
        id: +hlog.ruleId,
        lastExecution: hlog.createdAt,
        executionMode: hlog.executionMode,
      }));
      this.tableService.updateTableRow(this.table, itemsToUpdate);
    }
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
  }

  startAutoUpdate() {
    clearInterval(this.fetchRuleHistoryInterval);
    this.fetchRuleHistoryInterval = setInterval(() => this.fetchRuleHistory(), 10000);
  }

  stopAutoUpdate() {
    clearInterval(this.fetchRuleHistoryInterval);
    this.fetchRuleHistoryInterval = null;
  }

  private getRuleExecutionMode(params) {
    if (params && params.data && params.data.executionMode) {
      switch (params.data.executionMode) {
        case 'e':
          return 'End';
        case 's':
          return 'Start';
        default:
          return '';
      }
    }
    return '';
  }

  private getLocationName(params) {
    if (params && params.data && params.data.locationId && this.locations && this.locations.length) {
      const location = this.locations.find((l) => l.id === params.data.locationId);
      return location ? location.description : location.id;
    }
    return '';
  }
}
