import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromWidgets from '../reducers/widgets.reducer';

export const getWidgetState = createFeatureSelector<fromWidgets.WidgetState>(
  'widgets'
);

export const getWidgetsEntities = createSelector(
  getWidgetState,
  fromWidgets.getWidgetsEntities
);

export const getSelectedWidgets = createSelector(
  getWidgetState,
  fromWidgets.getSelectedWidgets
);

export const getAllWidgets = createSelector(
  getWidgetsEntities,
  entities => (entities ? Object.keys(entities).map(id => entities[id]) : [])
);

export const getWidgetsLoaded = createSelector(
  getWidgetState,
  fromWidgets.getWidgetsLoaded
);

export const getWidgetsLoading = createSelector(
  getWidgetState,
  fromWidgets.getWidgetsLoading
);

export const getWidgetShowForm = createSelector(
  getWidgetState,
  fromWidgets.getWidgetShowForm
);
