import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LocationProfileAvgHumDropping } from '../models/location-profile-avg-hum-drop';
import { LocationProfileAvgHumRaising } from '../models/location-profile-avg-hum-raise';
import { LocationProfileAvgTempDropping } from '../models/location-profile-avg-temp-drop';
import { LocationProfileAvgTempRaising } from '../models/location-profile-avg-temp-raise';

import { config } from '../../../config';

@Injectable()
export class LocationProfileFormsService {
  patterns = config().regexPatterns;

  constructor(private formBuilder: FormBuilder) {}

  initFormGroup(): FormGroup {
    const formControls = {
      timeFrom: null,
      timeTo: null,
      duration: null,
      indoorFrom: null,
      indoorTo: null,
      outdoorFrom: null,
      outdoorTo: null
    };

    return this.formBuilder.group(formControls);
  }

  setAvgTemperatureDroppingFormArray(
    form: FormGroup,
    data: LocationProfileAvgTempDropping[]
  ) {
    const formGroups = data.map(item => {
      const fg = this.initFormGroup();
      fg.patchValue(item);
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('avgTemperatureDropping', formArray);
  }

  setAvgTemperatureeRaisingFormArray(
    form: FormGroup,
    data: LocationProfileAvgTempRaising[]
  ) {
    const formGroups = data.map(item => {
      const fg = this.initFormGroup();
      fg.patchValue(item);
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('avgTemperatureRaising', formArray);
  }

  setAvgHumidityDroppingFormArray(
    form: FormGroup,
    data: LocationProfileAvgHumDropping[]
  ) {
    const formGroups = data.map(item => {
      const fg = this.initFormGroup();
      fg.patchValue(item);
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('avgHumidityDropping', formArray);
  }

  setAvgHumidityRaisingFormArray(
    form: FormGroup,
    data: LocationProfileAvgHumRaising[]
  ) {
    const formGroups = data.map(item => {
      const fg = this.initFormGroup();
      fg.patchValue(item);
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('avgHumidityRaising', formArray);
  }

  // ____ PARENT (ROOT)
  initLocationProfileForm(isChild?: boolean): FormGroup {
    const formControls: any = {
      name: [null, Validators.required],
      description: null,

      minTemperature: null,
      maxTemperature: null,
      comfortTemperature: null,
      temperatureMeasurementAccuracy: null,
      temperatureIgnoreDeviationPercent: null,
      displayedTemperatureModifier: null,

      minHumidity: null,
      maxHumidity: null,
      comfortHumidity: null,
      humidityMeasurementAccuracy: null,
      humidityIgnoreDeviationPercent: null,

      normalConsumption: null,
      normalConsumptionDeviation: null,
      normalLightingConsumption: null,
      normalLightingConsumptionDeviation: null,
      normalAcConsumption: null,
      normalAcConsumptionDeviation: null,
      normalApplianceConsumption: null,
      normalApplianceConsumptionDeviation: null,
      totalConsumptionIgnoreDeviation: null,

      avgTemperatureDropping: this.formBuilder.array([]),
      avgTemperatureRaising: this.formBuilder.array([]),
      avgHumidityDropping: this.formBuilder.array([]),
      avgHumidityRaising: this.formBuilder.array([]),

      isActive: false,
      isDeleted: false
    };

    if (!isChild) {
      formControls.id = { value: null, disabled: true };
    }

    return this.formBuilder.group(formControls);
  }
}
