import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { GridOptions } from 'ag-grid';
import { Translation } from '../translation.interface';
import { ModalConfig } from '@sc/modal/modal-config';

import { TranslationsService } from '../translations.service';
import { SharedService } from '@shared/shared.service';
import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';
import { TranslationActionCellComponent } from '../translation-action-cell/translation-action-cell.component';
import { TranslationValueCellComponent } from '../../_shared/translation-value-cell/translation-value-cell.component';

@Component({
  selector: 'sc-translations-table',
  templateUrl: 'translations-table.component.html',
})
export class TranslationsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: Translation[];
  formConfig: ModalConfig;
  selectedRows: any[];
  table: GridOptions;
  tableTitle: string = 'TRANSLATIONS';

  private subscribers: { [key: string]: any } = {};

  constructor(private sharedService: SharedService, private translationsService: TranslationsService) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'translationKey',
        headerName: 'KEY',
        field: 'translationKey',
        headerCheckboxSelection: true,
        checkboxSelection: (params) => !params.data.isDeleted,
      },
      {
        colId: 'translationValues',
        headerName: 'VALUES',
        field: 'translationValues',
        cellRendererFramework: TranslationValueCellComponent,
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: TranslationActionCellComponent,
      },
    ];

    this.formConfig = {
      name: 'TranslationForm',
      options: { modalTitle: 'TRANSLATION_FORM_TITLE' },
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.DATASET = this.translationsService.getTranslation().subscribe((result: any) => {
      const dataset = result.data;
      for (const item of dataset) {
        try {
          item.translationValues = JSON.parse(item.translationValues);
        } catch (error) {
          // console.log(error);
          // console.log(item);
        }
      }
      this.dataset = dataset;
    });
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.rowDeselection = true;
    this.table.groupSelectsChildren = true;
    this.table.onSelectionChanged = () => {
      this.selectedRows = this.table.api.getSelectedNodes().map((row) => row.data);
    };
  }

  get importFormConfig(): ModalConfig {
    return {
      name: 'TranslationImportForm',
      data: { rowData: this.dataset, selectedRows: this.selectedRows },
      options: { modalTitle: 'TRANSLATION' },
    };
  }
}
