import * as fromWidgetCategories from '../actions/widget-categories.action';
import { WidgetCategory } from '@widgets/widget-categories/models/widget-category';

export interface WidgetCategoriesState {
  entities: { [id: string]: WidgetCategory };
  loaded: boolean;
  loading: boolean;
}

export const initialState = {
  entities: null,
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromWidgetCategories.WidgetCategoriesAction
): WidgetCategoriesState {
  switch (action.type) {
    // ____ LOAD WIDGETS
    case fromWidgetCategories.LOAD_WIDGET_CATEGORIES: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromWidgetCategories.LOAD_WIDGET_CATEGORIES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromWidgetCategories.LOAD_WIDGET_CATEGORIES_SUCCESS: {
      const widgetCategories = action.payload;
      const entities = widgetCategories.reduce(
        (tmpEntities: { [id: number]: WidgetCategory }, widgetCategory: WidgetCategory) => {
          return {
            ...tmpEntities,
            [widgetCategory.id]: widgetCategory,
          };
        },
        {}
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }
  }

  return state;
}

export const getWidgetsEntities = (state: WidgetCategoriesState) => state.entities;

export const getWidgetsLoading = (state: WidgetCategoriesState) => state.loading;

export const getWidgetsLoaded = (state: WidgetCategoriesState) => state.loaded;
