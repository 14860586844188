import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-battery-alert-modal',
  templateUrl: './battery-alert-modal.component.html',
  styleUrls: ['./battery-alert-modal.component.scss']
})
export class BatteryAlertModalComponent implements OnInit {
  @Input() data: any;
  @Output() onClose = new EventEmitter();
  @Output() onDismiss = new EventEmitter();

  dataset: any[];

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.dataset = this.data.devices;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  getLevelStyle(data: { [key: string]: any }) {
    let color;

    switch (data.status) {
      case 'crucial':
        color = 'red';
        break;
      case 'warning':
        color = 'orange';
        break;
      case 'info':
        color = 'blue';
        break;
      default:
        break;
    }

    return { color };
  }

  getLoggedAt(data: { [key: string]: any }) {
    if (data.loggedAt) {
      return this.sharedService.dateFormat(data.loggedAt);
    }
    return '';
  }
}
