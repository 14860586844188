import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { Currency } from '@widgets/currencies/models/currency';
import { Site } from '@widgets/sites/models/site';

import { CurrenciesService } from '@widgets/currencies/services/currencies.service';
import { EnergySavingsService } from '../services/energy-savings.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-cost-saved',
  templateUrl: './cost-saved.component.html',
})
export class CostSavedComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isFetching = false;
  isFirstTime = true;
  subtitleText = '';
  titleText = 'COST_SAVED';
  squareOptions = {
    customClass: 'lightgreen',
    icon: 'fa fa-dollar',
  };
  topThreeData: { label: string; value: any }[];
  widgetName: string = '';

  private currencies: Currency[];
  private dataset: { [key: string]: any };
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};
  private intervals: { [key: string]: any } = {};

  constructor(
    private currenciesService: CurrenciesService,
    private energySavingsService: EnergySavingsService,
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.selectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
        this.intervals.datasetUpdate = setInterval(() => this.fetchDataset(), 5000);
      }
    });

    this.fetchCurrencies();
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private getCostSavedLastMonth() {
    const currency = this.getCurrencyUnit();
    let cost = 0;
    if (this.dataset && this.dataset.length) {
      for (let i = 0; i < this.dataset.length; i++) {
        if (this.dataset[i].key === 'last_month') {
          cost = this.dataset[i].costSaved.toFixed(2);
          break;
        }
      }
    }
    return cost + ' ' + currency;
  }

  private getCostSavedThisMonth() {
    const currency = this.getCurrencyUnit();
    let cost = 0;
    if (this.dataset && this.dataset.length) {
      for (let i = 0; i < this.dataset.length; i++) {
        if (this.dataset[i].key === 'this_month') {
          cost = this.dataset[i].costSaved.toFixed(2);
          break;
        }
      }
    }
    return cost + ' ' + currency;
  }

  private getCostSavedYesterday() {
    const currency = this.getCurrencyUnit();
    let cost = 0;
    if (this.dataset && this.dataset.length) {
      for (let i = 0; i < this.dataset.length; i++) {
        if (this.dataset[i].key === 'yesterday') {
          cost = this.dataset[i].costSaved.toFixed(2);
          break;
        }
      }
    }
    return cost + ' ' + currency;
  }

  private getCurrencyUnit() {
    let unit = '';
    if (this.currencies && this.currencies.length) {
      const currency = this.currencies.find((c) => c.id === this.selectedSite.currency);
      unit = currency ? currency.key : '';
    }
    return unit;
  }

  private setWidgetDataset() {
    this.topThreeData = [
      { label: 'YESTERDAY', value: this.getCostSavedYesterday() },
      { label: 'THIS_MONTH', value: this.getCostSavedThisMonth() },
      { label: 'LAST_MONTH', value: this.getCostSavedLastMonth() },
    ];
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite && this.selectedSite.id) {
      this.isFetching = true;
      this.subscribers.getDataset = this.energySavingsService.getEnergySavings(this.selectedSite.id).subscribe(
        (result: any) => {
          this.dataset = result.data;
          this.setWidgetDataset();
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  private fetchCurrencies() {
    this.subscribers.getCurrencies = this.currenciesService.getCurrencies().subscribe((result: any) => {
      this.currencies = result.data;
    });
  }
}
