import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-live-data-cell',
  templateUrl: 'device-live-data-cell.component.html'
})
export class DeviceLiveDataCellComponent implements ICellRendererAngularComp {
  cell: any;
  currentValue: any;
  diffValue: number;
  previousValue: any;

  currentState: string;

  isAG: boolean;
  agValue: any;

  constructor(private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
    this.setCurrentValue();
  }

  refresh(params: any): boolean {
    this.cell = params;
    this.currentState = this.cell.value;
    this.previousValue = this.currentValue;
    this.setCurrentValue();
    return true;
  }

  get loggedAt() {
    let logTime = '';
    let loggedAt = '';

    if (this.isAG) {
      if (this.cell.colDef.field === 'latestLogValue') {
        logTime = this.sharedService.dateFormat(
          this.cell.data.latestLogValueLoggedAt
        );
      } else if (this.cell.colDef.field === 'liveStatus') {
        logTime = this.sharedService.dateFormat(
          this.cell.data.liveStatusLoggedAt
        );
      }

      loggedAt = `logged at ${logTime}`;

      let errorMsg = '';
      if (this.agValue && this.agValue.error) {
        errorMsg = `, error: ${this.agValue.error}`;
      }

      loggedAt = `<small>${loggedAt}${errorMsg}</small>`;
    } else {
      if (
        this.cell.colDef.field === 'latestLogValue' &&
        this.cell.data &&
        this.cell.data.latestLogValueLoggedAt
      ) {
        logTime = this.sharedService.dateFormat(
          this.cell.data.latestLogValueLoggedAt
        );
        loggedAt = `<small>logged at ${logTime}</small>`;
      } else if (this.cell.colDef.field === 'liveStatus') {
        const liveData = { ...this.cell.data };
        if (liveData.avcCalcWeight) {
          logTime =
            'real value : ' +
            liveData.liveStatus +
            this.valueUnit +
            `, logged at ${this.sharedService.dateFormat(
              liveData.liveStatusLoggedAt
            )}`;
        } else {
          logTime = `logged at ${this.sharedService.dateFormat(
            liveData.liveStatusLoggedAt
          )}`;
        }
        loggedAt = `<small>${logTime}</small>`;
      }
    }

    return loggedAt;
  }

  get valueUnit() {
    switch (this.deviceTypeKey) {
      case 'bl':
      case 'hs':
        return '%';

      case 'bs':
        return 'lux';

      case 'ts':
        return '°C';

      case 'ws':
        return 'w/h';
      default:
        return '';
    }
  }

  get deviceModels() {
    if (
      this.cell &&
      this.cell.context &&
      this.cell.context.containerComponent &&
      this.cell.context.containerComponent.deviceModels
    ) {
      return this.cell.context.containerComponent.deviceModels;
    }
    return [];
  }

  get deviceModelId() {
    if (this.cell && this.cell.data && this.cell.data.deviceModelId) {
      return this.cell.data.deviceModelId;
    }
    return null;
  }

  get deviceTypeKey() {
    if (this.cell && this.cell.data && this.cell.data.deviceTypeKey) {
      return this.cell.data.deviceTypeKey;
    }
    return null;
  }

  setCurrentValue() {
    if (
      typeof this.cell.value !== 'undefined' &&
      this.cell.value !== '' &&
      this.cell.value !== null
    ) {
      if (this.deviceTypeKey === 'a' || this.deviceTypeKey === 'g') {
        this.isAG = true;
        this.agValue = this.sharedService.tryParseJSON(this.cell.value);
      } else {
        const model = this.deviceModels.find(
          dm => dm.id === this.deviceModelId
        );

        let found = false;
        if (model && model.mappingValues && model.mappingValues.length) {
          // find mapping value
          for (let i = 0; i < model.mappingValues.length; i++) {
            if (
              this.cell.colDef.field === 'liveStatus' &&
              model.mappingValues[i].value === this.cell.value
            ) {
              found = true;
              this.currentValue = model.mappingValues[i].name;
              break;
            } else if (model.mappingValues[i].key === this.cell.value) {
              found = true;
              this.currentValue = model.mappingValues[i].name;
              break;
            }
          }
        }

        // when not find any mapping value, use the raw value
        if (!found) {
          this.currentValue = this.cell.value;
        }

        // apply avcCalcWeight if exists
        if (
          this.cell.colDef.field === 'liveStatus' &&
          typeof this.currentValue === 'number' &&
          typeof this.cell.data.avcCalcWeight === 'number' &&
          this.cell.data.avcCalcWeight !== 0
        ) {
          this.currentValue = +this.cell.value * this.cell.data.avcCalcWeight;
        }

        // format number into 2 decimals
        if (typeof this.currentValue === 'number') {
          this.currentValue = this.sharedService.numberFormat(
            this.currentValue
          );
        }

        this.setDiffValue();
      }
    } else {
      this.currentValue = '';
    }
  }

  setDiffValue() {
    if (!isNaN(this.currentValue)) {
      this.diffValue =
        this.currentValue - (this.previousValue || this.currentValue);
      if (this.diffValue !== 0) {
        this.diffValue = this.sharedService.numberFormat(this.diffValue);
      }
    } else {
      this.diffValue = null;
    }
  }

  diffColor() {
    return 'grey-text';
  }

  diffIcon() {
    if (typeof this.currentValue === 'number') {
      if (this.diffValue > 0) {
        return 'fa fa-level-up';
      } else if (this.diffValue < 0) {
        return 'fa fa-level-down';
      } else {
        return '';
      }
    }
  }

  get showValue() {
    if (
      typeof this.currentValue !== undefined &&
      this.currentValue !== null &&
      this.currentValue !== ''
    ) {
      return true;
    }
    return false;
  }
}
