<ul class="rule-list">
  <li *ngFor="let rule of rules">
    <i
      *ngIf="rule?.errors"
      class="fa fa-exclamation-triangle red-text"
      pTooltip="{{
        'RULE_MASS_EDIT_COMMAND_OR_CONDITION_IS_NOT_MATCH_TEMPLATE' | translate
      }}"
    ></i>
    <a (click)="selectRule(rule)" [class.active]="rule.id === selectedRule">
      {{ rule.locationName }}
    </a>
  </li>
</ul>
