import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSceneTemplates from '../reducers/scene-templates.reducer';

export const getSceneTemplatesState = createFeatureSelector<fromSceneTemplates.SceneTemplateState>('sceneTemplates');

export const getSceneTemplatesEntities = createSelector(
  getSceneTemplatesState,
  (state: fromSceneTemplates.SceneTemplateState) => state.entities
);

export const getSceneTemplates = createSelector(getSceneTemplatesEntities, (entities) =>
  entities ? Object.keys(entities).map((id) => entities[id]) : []
);

export const getSceneTemplatesLoaded = createSelector(
  getSceneTemplatesState,
  (state: fromSceneTemplates.SceneTemplateState) => state.loaded
);

export const getSceneTemplatesLoading = createSelector(
  getSceneTemplatesState,
  (state: fromSceneTemplates.SceneTemplateState) => state.loading
);

export const getSceneTemplateFormData = createSelector(
  getSceneTemplatesState,
  (state: fromSceneTemplates.SceneTemplateState) => state.formData
);

export const getSceneTemplateFormDataLoading = createSelector(
  getSceneTemplatesState,
  (state: fromSceneTemplates.SceneTemplateState) => state.formDataLoading
);

export const getSceneTemplateFormDataError = createSelector(
  getSceneTemplatesState,
  (state: fromSceneTemplates.SceneTemplateState) => state.formDataError
);

export const getSceneTemplatePending = createSelector(
  getSceneTemplatesState,
  (state: fromSceneTemplates.SceneTemplateState) => state.pending
);

export const getSceneTemplatePendingError = createSelector(
  getSceneTemplatesState,
  (state: fromSceneTemplates.SceneTemplateState) => state.pendingError
);
