import { Component } from '@angular/core';

@Component({
  selector: 'sc-loading-indicator',
  styleUrls: ['loading-indicator.component.scss'],
  template: `
    <div class="wrapper">
      <div class="spinner">
        <img src="assets/images/logo.png" class="logo" width="100%" />
      </div>
    </div>
  `,
})
export class SCLoadingIndicatorComponent {}
