import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { NotificationDetailComponent } from './notification-detail/notification-detail.component';
import { NotificationActionCellComponent } from './notification-action-cell/notification-action-cell.component';
import { NotificationsTableComponent } from './notifications-table/notifications-table.component';

import { NotificationsService } from './notifications.service';

const components: any[] = [
  NotificationActionCellComponent,
  NotificationDetailComponent,
  NotificationsTableComponent
];

@NgModule({
  imports: [SharedModule],
  exports: [NotificationDetailComponent, NotificationsTableComponent],
  declarations: [...components],
  entryComponents: [...components],
  providers: [NotificationsService]
})
export class NotificationsModule {}
