import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { Company } from '@widgets/companies/models/company';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private apiUrl = config().apiUrl + '/_integrator/company';

  constructor(private httpClient: HttpClient) {}

  createCompany(data: Company) {
    return this.httpClient.post(this.apiUrl, data);
  }
}
