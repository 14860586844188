import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-al-device-cell',
  templateUrl: 'al-device-cell.component.html'
})
export class AutomationLogsDeviceCellComponent
  implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }

  get cellData() {
    if (this.cell && this.cell.valueFormatted) {
      return this.cell.valueFormatted;
    }
    return '';
  }

  get deviceId() {
    if (this.cell && this.cell.data && this.cell.data.deviceId) {
      return this.cell.data.deviceId;
    }
    return '';
  }
}
