import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';

@Component({
  selector: 'sc-report-variable',
  templateUrl: './report-variable.component.html',
  styleUrls: ['./report-variable.component.scss'],
})
export class ReportVariableComponent implements OnInit {
  @Input()
  control: FormGroup;

  @Input()
  key: number | string;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  constructor() {}

  ngOnInit(): void {}
}
