import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { ModalConfig } from '@sc/modal/modal-config';
import { OverviewFormData } from '../../models/overview-form-data';

@Component({
  selector: 'sc-overview-form',
  templateUrl: 'overview-form.component.html'
})
export class BenchmarkOverviewFormComponent implements OnInit, OnDestroy {
  @Input()
  analyseConfig: ModalConfig;
  @Input()
  disabled: boolean;
  @Input()
  selectItems: {
    benchmarkTemplates: SelectItem[];
    benchmarkTemplatesRaw: any[];
    locations: SelectItem[];
  };

  @Output()
  afterInit = new EventEmitter<FormGroup>();
  @Output()
  analysed = new EventEmitter<OverviewFormData>();
  @Output()
  generated = new EventEmitter<OverviewFormData>();
  @Output()
  searched = new EventEmitter<OverviewFormData>();

  form: FormGroup;
  quickDateform: FormGroup;

  benchmarkGenerateTranslateParams: { name: string };
  subscribers: { [key: string]: any };

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.subscribers = {};

    // create form
    this.form = this.formBuilder.group({
      locations: [null, Validators.required],
      benchmarkTemplate: [null, Validators.required],
      from: [null, Validators.required],
      to: [null, Validators.required]
    });

    // watch from value when empty reset date form
    this.subscribers.FROM = this.form
      .get('from')
      .valueChanges.subscribe(result => {
        if (!result) {
          this.quickDateform.reset();
        }
      });

    this.subscribers.BENCHMARK_TEMPLATE = this.form
      .get('benchmarkTemplate')
      .valueChanges.subscribe(result => {
        if (result) {
          const benchmarkTemplate = this.selectItems.benchmarkTemplatesRaw.find(
            bt => bt.id === result
          );
          if (benchmarkTemplate) {
            this.benchmarkGenerateTranslateParams = {
              name: benchmarkTemplate.name
            };
          }
        }
      });

    // emit after inti event
    this.afterInit.emit(this.form);
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  onQucikDateInit(event: FormGroup) {
    this.quickDateform = event;
  }

  onSelectDate(event) {
    this.form.patchValue({
      from: event.from,
      to: event.to
    });
  }

  generate() {
    this.generated.emit(this.form.value);
  }

  search() {
    this.searched.emit(this.form.value);
  }
}
