<div class="page-widget-modal">
  <div class="page-widget-modal__header">
    <div class="page-widget-modal__header__content">
      <div class="page-widget-modal__header__title">
        {{ "PAGE_WIDGET_MODAL_TITLE" | translate }}
      </div>
    </div>

    <div class="page-widget-modal__header__right">
      <button type="button" class="close-button" (click)="close(false)">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>

  <div class="page-widget-modal__body">
    <div class="widget-details">
      <div class="widget-details__title">
        <div class="widget-details__icon">
          <img
            *ngIf="widget?.icon"
            src="{{ iconUrlPrefix + widget?.icon }}"
            width="30"
          />
        </div>

        <div class="widget-details__name">
          {{ widget?.name }}
        </div>
      </div>

      <div class="widget-details__description">
        {{ widget?.description }}
      </div>
    </div>
  </div>

  <div class="page-widget-modal__footer">
    <button
      pButton
      type="button"
      class="ui-button-secondary mr-3"
      label="{{ 'CANCEL' | translate }}"
      (click)="close(false)"
    ></button>

    <button
      pButton
      type="button"
      [ngClass]="{
        'ui-button-danger': isRemove,
        'ui-button-success': !isRemove
      }"
      [label]="
        (isRemove
          ? 'PAGE_WIDGET_MODAL_REMOVE_BUTTON_LABEL'
          : 'PAGE_WIDGET_MODAL_ADD_BUTTON_LABEL'
        ) | translate
      "
      (click)="close(true)"
    ></button>
  </div>
</div>
