import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { LocationTemplate } from '../models/location-template';
import { ModalConfig } from '@sc/modal/modal-config';

import { LocationTemplatesService } from '../services/location-templates.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { LocationTemplateActionCellComponent } from '../location-template-action-cell/location-template-action-cell.component';

@Component({
  selector: 'sc-location-templates-table',
  templateUrl: 'location-templates-table.component.html'
})
export class LocationTemplatesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: LocationTemplate[];
  columns: any[];
  formConfig: ModalConfig;
  tableTitle: string;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private locationTemplatesService: LocationTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: LocationTemplateActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'LocationTemplateForm',
      options: { modalTitle: 'LOCATION_TEMPLATE_FORM_TITLE' }
    };
    this.tableTitle = 'LOCATION_TEMPLATES';

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.GET_LOCATION_TEMPLATES = this.locationTemplatesService
      .getLocationTemplates()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
