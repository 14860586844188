import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Field } from '../models/field.model';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class FieldsService {
  private apiUrl = config().apiUrl + '/formFields';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  private verifyData(data: any): Field {
    const newData = { ...data };

    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getField(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getFields(options?: { [key: string]: any }) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  createField(data: Field) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateField(id: number, data: Field) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteField(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
