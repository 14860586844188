import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Output, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { OmnisEditorService } from '@sc/omnis-editor/services/omnis-editor.service';
import { OmnisEditorFormsService } from '@sc/omnis-editor/services/omnis-editor-forms.service';
import { ObjectTypes } from '@sc/omnis-editor/models/object';
import { DeviceDetails } from '@app/widgets/devices/models/device-details';

@Component({
  selector: 'sc-omnis-editor',
  templateUrl: 'omnis-editor.component.html',
  styleUrls: ['omnis-editor.component.scss'],
})
export class OmnisEditorComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  lines: any[] = [];
  scopes: any[] = [];
  selectedGridItem: any;
  selectedLine: any;
  selectedObject: any;
  selectedObjectCloned: any;
  selectedScopeSquares: any[] = [];
  selectedScope: any;
  selectScopeOverlay: any;

  isAddingLineInProcess = false;
  isEditingLineInProcess = false;
  isAddingScopeInProcess = false;
  isEditingScopeInProcess = false;
  isLineLayerEnabled = true;
  isScopeLayerEnabled = true;

  objectTypes = ObjectTypes;

  @Input()
  device: DeviceDetails;

  @Output()
  saveClicked = new EventEmitter<any>();

  private canvas: any;
  private grid: any;
  private cols = 8;
  private rows = 8;
  private canvasHeight = 600;
  private canvasWidth = 600;
  private gridHeight = this.canvasHeight / this.rows;
  private gridWidth = this.canvasWidth / this.cols;

  @ViewChild('gridContainer', { static: true })
  private gridContainer;

  constructor(
    private omnisEditorService: OmnisEditorService,
    private omnisEditorFormsService: OmnisEditorFormsService
  ) {}

  ngOnInit() {
    this.omnisEditorService.addCustomSubclass();
    this.canvas = this.omnisEditorService.initCanvas(this.gridContainer.nativeElement);
    this.canvas.on('selection:created', this.onSelectionCreated.bind(this));
    this.canvas.on('selection:cleared', this.onSelectionCleared.bind(this));

    this.form = this.omnisEditorFormsService.initEditorForm();
  }

  ngAfterViewInit() {
    this.grid = this.omnisEditorService.createGrid(this.cols, this.rows, this.gridWidth, this.gridHeight);
    for (const gridItem of this.grid) {
      gridItem.on('mousedown', this.onGridItemMouseDown.bind(this));
      this.canvas.add(gridItem);
    }
    this.canvas.renderAll();

    if (this.device && this.device.customData) {
      // console.log(this.device.customData);
      let customData;
      try {
        customData = JSON.parse(this.device.customData);
      } catch (error) {
        // console.log('parse custom data error');
      }

      if (customData) {
        for (const line of customData.lines) {
          this.createLine(line);
        }

        for (const scope of customData.scopes) {
          this.createScope(scope);
        }

        this.createScopeOverlay();
        this.toggleScopeOverlay(true);

        this.canvas.renderAll();
      }
    }
  }

  private onSelectionCreated(event) {
    //console.log('onSelectionCreated', event);
    this.selectedObject = event.target;
    // this.selectedObjectCloned = JSON.parse(JSON.stringify(this.selectedObject));
  }

  private onSelectionCleared(event) {
    //console.log('onSelectionCleared', event, this.selectedObject);
    if (this.selectedObject) {
      //console.log('auto select object');
      if (this.selectedObject.type === ObjectTypes.CountingLine) {
        this.selectObject(this.selectedObject, false);
      } else {
        this.selectObject(this.selectedObject);
      }
    }
  }

  private onGridItemMouseDown(event) {
    //console.log('onGridItemMouseDown:', event);
    const gridItem = event.target;
    if (this.isAddingScopeInProcess || this.isEditingScopeInProcess) {
      const selectedSquares = this.selectedScopeSquares.map((s) => s.id);
      // select grid item for scope
      const scopeSquareIndex = selectedSquares.indexOf(gridItem.id);
      if (scopeSquareIndex >= 0) {
        this.deselectScopeSquare(gridItem, scopeSquareIndex);
      } else {
        this.selectScopeSquare(gridItem);
      }
    } else {
      this.selectGridItem(gridItem);
    }
  }

  private selectObject(object: any, cloned = true) {
    this.canvas.setActiveObject(object);
    this.canvas.renderAll();

    if (cloned) {
      this.selectedObjectCloned = JSON.parse(JSON.stringify(object));
    }
  }

  private deselectObject() {
    this.selectedObject = null;
    this.selectedObjectCloned = null;
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }

  private resetGridSelectedItem() {
    for (const gridItem of this.grid) {
      gridItem.setSelected(false);
    }
  }

  private selectGridItem(object) {
    //console.log('selectGridItem:', object);
    const selected = !object.selected;
    // clear all selected items
    this.resetGridSelectedItem();
    // set selected item
    if (selected) {
      this.selectedGridItem = object;
      this.selectedGridItem.setSelected(true);
    } else {
      this.selectedGridItem = null;
    }
    this.canvas.renderAll();
  }

  getLineName(lineId: string) {
    let lineName = '';
    if (this.lineForms) {
      const lines = this.lineForms.value;
      for (const obj of lines) {
        if (obj.id === lineId) {
          lineName = obj.name;
        }
      }
    }
    return lineName;
  }

  getScopeName(scopeId: string) {
    let scopeName = '';
    if (this.scopeForms) {
      const scopes = this.scopeForms.value;
      for (const obj of scopes) {
        if (obj.id === scopeId) {
          scopeName = obj.name;
        }
      }
    }
    return scopeName;
  }

  toggleGridItem() {
    if (!this.selectedGridItem) {
      return;
    }

    const text = this.selectedGridItem.disabled ? 'enable' : 'disable';
    const confirm = window.confirm('Are you sure to ' + text + ' the square ' + this.selectedGridItem.id + '?');
    if (!confirm) {
      return;
    }

    this.selectedGridItem.disabled = !this.selectedGridItem.disabled;

    // update enabled of squares form
    const squaresEntities = this.squaresForm.value;
    squaresEntities[this.selectedGridItem.id].enabled = this.selectedGridItem.disabled === false;
    this.squaresForm.setValue(squaresEntities);

    // calling without passing the value will only update grid color
    this.selectedGridItem.setSelected();
    this.canvas.renderAll();
  }

  toggleLayer(layer) {
    switch (layer) {
      case ObjectTypes.CountingLine:
        this.isLineLayerEnabled = !this.isLineLayerEnabled;
        for (const obj of this.getLines()) {
          obj.visible = this.isLineLayerEnabled;
        }
        break;
      case ObjectTypes.Scope:
        this.isScopeLayerEnabled = !this.isScopeLayerEnabled;
        for (const obj of this.getScopes()) {
          obj.visible = this.isScopeLayerEnabled;
        }
        break;
      default:
        break;
    }
    this.canvas.renderAll();
  }

  get lineForms(): FormArray {
    return this.form.get('lines') as FormArray;
  }

  private getCanvasObjects(): any[] {
    let objects = [];
    if (this.canvas) {
      const canvasObjects = this.canvas.getObjects();
      if (canvasObjects && canvasObjects.length) {
        objects = canvasObjects;
      }
    }
    return objects;
  }

  private getLines(id?: number): any[] {
    const objects = this.getCanvasObjects();
    let lines = objects.filter((o) => o.type === ObjectTypes.CountingLine);
    if (lines && lines.length && id) {
      lines = [lines.find((l) => l.id === id)];
    }
    return lines;
  }

  private getScopes(id?: number): any[] {
    const objects = this.getCanvasObjects();
    let scopes = objects.filter((o) => o.type === ObjectTypes.Scope);
    if (scopes && scopes.length && id) {
      scopes = [scopes.find((l) => l.id === id)];
    }
    return scopes;
  }

  allowAddingLine(): boolean {
    return this.selectedGridItem &&
      !this.selectedGridItem.line &&
      !this.isAddingLineInProcess &&
      !this.isEditingLineInProcess &&
      !this.isAddingScopeInProcess &&
      !this.isEditingScopeInProcess
      ? true
      : false;
  }

  private createCountingLine(left: number, top: number, width: number, options = {}) {
    const lineOptions: any = {
      ...options,
      left,
      top,
      width,
    };
    return this.omnisEditorService.createCountingLine(lineOptions);
  }

  addLine() {
    if (!this.allowAddingLine) {
      return;
    }

    this.isAddingLineInProcess = true;

    const form = this.omnisEditorFormsService.initLineForm();
    this.lineForms.push(form);

    // const text = 'Please select position';
    // const overlay = this.omnisEditorService.createOverlay(
    //   this.canvasWidth,
    //   this.canvasHeight,
    //   this.countingLineColor,
    //   text
    // );
    // this.canvas.add(overlay);

    // create line
    const line = this.createCountingLine(
      this.selectedGridItem.left + 5,
      this.selectedGridItem.top + 5,
      this.gridWidth * 2 - 10,
      { id: form.value.id, name: form.value.name }
    );
    // add line to canvas
    this.canvas.add(line);
    // select line
    this.selectObject(line);
  }

  private createLine(line) {
    // {"lines":[
    //   {"id":"l1598847746817","name":"new line1","fromX":78,"fromY":66,"toX":218,"toY":71,"squares":[2,3]},
    //   {"id":"l1598847769849","name":"new line2","fromX":380,"fromY":530,"toX":520,"toY":535,"squares":[62,63]}
    // ]

    const form = this.omnisEditorFormsService.initLineForm();
    form.patchValue({ ...line, new: false });
    this.lineForms.push(form);

    const width = Math.abs(line.toX - line.fromX);
    const height = Math.abs(line.toY - line.fromY);
    const lineWidth = height > 5 ? height : width;

    // create line
    const tmpLine = this.createCountingLine(line.fromX, line.fromY, lineWidth, { id: line.id, name: line.name });
    // add line to canvas
    this.canvas.add(tmpLine);

    this.updateLineInSquares(tmpLine);
  }

  onCancelLine(event, index: number) {
    //console.log('onCancelLine:', index, event);

    // remove old line
    this.canvas.remove(this.selectedObject);

    // re-create line
    const newLine = this.createCountingLine(
      this.selectedObjectCloned.left,
      this.selectedObjectCloned.top,
      this.selectedObjectCloned.width,
      {
        id: this.selectedObjectCloned.id,
        name: this.selectedObjectCloned.name,
        angle: this.selectedObjectCloned.angle,
      }
    );
    this.canvas.add(newLine);

    // clear selection
    this.deselectObject();

    this.isEditingLineInProcess = false;
  }

  onEditLine(event, index: number) {
    //console.log('onEditLine:', index, event);

    this.isEditingLineInProcess = true;
    this.selectedLine = event;

    // get line by line id
    const line = this.getLines(event.id)[0];
    // enable line edit
    this.setLineEditMode(line);
    // set line to selected object
    this.selectObject(line);
  }

  onRemoveLine(event, index: number) {
    //console.log('onRemoveLine:', index, event);

    const confirm = window.confirm('Are you sure that you want to remove the line ' + event.name + '?');
    if (!confirm) {
      return;
    }

    // remove line from form
    this.lineForms.removeAt(index);

    // remove line from canvas
    const line = this.selectedObject;

    if (line) {
      // clear grid item line by line id
      for (const item of this.grid) {
        if (item.line === line.id) {
          item.line = null;
        }
      }

      // remove line
      this.canvas.remove(line);
      // clear selection
      this.deselectObject();

      // update line in squares form
      if (line.squares && line.squares.length) {
        const squaresEntities = this.squaresForm.value;
        for (const sq of line.squares) {
          squaresEntities[sq].line = null;
        }
        this.squaresForm.setValue(squaresEntities);
      }
    }

    this.isAddingLineInProcess = false;
    this.isEditingLineInProcess = false;
  }

  onSaveLine(event, index: number) {
    //console.log('onSaveLine:', index, event);

    const line = this.selectedObject;
    const squares = [];
    for (const item of this.grid) {
      // find the grid items that counting line is above
      if (line.intersectsWithObject(item) && !item.line) {
        squares.push(item.id);
      }
    }

    this.updateLineInSquares(line);
    // // update line in squares form and
    // // update grid item line
    // const squaresEntities = this.squaresForm.value;

    // for (const item of this.grid) {
    //   if (item.line === line.id) {
    //     item.line = null;
    //     squaresEntities[item.id].line = null;
    //   }
    //   // find the grid items that counting line is above
    //   if (line.intersectsWithObject(item) && !item.line) {
    //     squares.push(item.id);
    //     // update grid item line
    //     item.line = line.id;
    //     squaresEntities[item.id].line = line.id;
    //   }
    // }

    // this.squaresForm.setValue(squaresEntities);

    // console.log('line.getBoundingRect().left:', line.getBoundingRect().left);
    // console.log('line.left:', line.left);
    // console.log('line.getBoundingRect().top:', line.getBoundingRect().top);
    // console.log('line.top:', line.top);

    // console.log('line.getBoundingRect().width:', line.getBoundingRect().width);
    // console.log('line.width:', line.width);
    // console.log('line.getBoundingRect().height:', line.getBoundingRect().height);
    // console.log('line.height:', line.height);

    // update line form
    this.lineForms.at(index).patchValue({
      fromX: line.left,
      fromY: line.top,
      squares,
      toX: line.left + line.width,
      toY: line.top + line.height,
      new: false,
    });

    // update line name
    line.name = event.name;
    // set line to view mode
    this.setLineViewMode(line);
    // clear selection
    this.deselectObject();

    this.isAddingLineInProcess = false;
    this.isEditingLineInProcess = false;
  }

  private updateLineInSquares(line) {
    // update line in squares form and
    // update grid item line
    const squaresEntities = this.squaresForm.value;

    for (const item of this.grid) {
      if (item.line === line.id) {
        item.line = null;
        squaresEntities[item.id].line = null;
      }
      // find the grid items that counting line is above
      if (line.intersectsWithObject(item) && !item.line) {
        // update grid item line
        item.line = line.id;
        squaresEntities[item.id].line = line.id;
      }
    }

    this.squaresForm.setValue(squaresEntities);
  }

  private setLineViewMode(line) {
    if (line) {
      line.hasControls = false;
      line.lockMovementX = true;
      line.lockMovementY = true;
      line.lockRotation = true;
      line.lockScalingX = true;
      line.moveable = false;
      line.selectable = false;
    }
  }

  private setLineEditMode(line) {
    if (line) {
      line.hasControls = true;
      line.lockMovementX = false;
      line.lockMovementY = false;
      line.lockRotation = false;
      line.lockScalingX = false;
      line.moveable = true;
      line.selectable = true;
    }
  }

  get squaresForm(): FormGroup {
    return this.form.get('squares') as FormGroup;
  }

  get scopeForms(): FormArray {
    return this.form.get('scopes') as FormArray;
  }

  allowAddingScope() {
    return !this.isAddingLineInProcess &&
      !this.isEditingLineInProcess &&
      !this.isAddingScopeInProcess &&
      !this.isEditingScopeInProcess
      ? true
      : false;
  }

  private createScopeOverlay() {
    this.selectScopeOverlay = this.omnisEditorService.createOverlay(
      this.canvasWidth,
      this.canvasHeight,
      'Please select squares you want to add to the new scope.'
    );
    this.canvas.add(this.selectScopeOverlay);
    this.canvas.renderAll();
  }

  private toggleScopeOverlay(forceHide = false) {
    // if (!this.isAddingScopeInProcess && !this.isEditingScopeInProcess) {
    // return;
    // }
    if (!this.selectScopeOverlay) {
      this.createScopeOverlay();
    } else if (forceHide === true) {
      this.selectScopeOverlay.visible = false;
    } else {
      if (this.selectScopeOverlay && this.selectedScopeSquares.length) {
        this.selectScopeOverlay.visible = false;
      } else {
        this.selectScopeOverlay.visible = true;
        this.selectScopeOverlay.bringToFront();
      }
    }
    this.canvas.renderAll();
  }

  private selectScopeSquare(object) {
    //console.log('selectScopeSquare::object:', object);
    // TODO: check if grid Item already has scope
    if (object.scope || object.disabled) {
      //console.log('selectScopeSquare:already has scope or disabled');
      return;
    }

    // add grid item id to selectedScopeSquares
    this.selectedScopeSquares.push(object);
    //console.log('selectScopeSquare:', this.selectedScopeSquares);

    object.selectAsScope();

    this.toggleScopeOverlay();
  }

  private deselectScopeSquare(object, index) {
    //console.log('deselectScopeSquare::object:', object);

    // remove grid item id from selectedScopeSquares
    this.selectedScopeSquares.splice(index, 1);
    //console.log('deselectScopeSquare', this.selectedScopeSquares);

    object.deselectAsScope();
    object.scope = null;

    // clear scope on squaresForm
    const squaresEntities = this.squaresForm.value;
    squaresEntities[object.id].scope = null;
    this.squaresForm.setValue(squaresEntities);

    this.toggleScopeOverlay();
  }

  addScope() {
    if (!this.allowAddingScope) {
      return;
    }

    this.isAddingScopeInProcess = true;
    this.selectedScopeSquares = [];

    const form = this.omnisEditorFormsService.initScopeForm();
    this.scopeForms.push(form);

    this.toggleScopeOverlay();
  }

  private createScope(scope) {
    // "scopes":[{"id":"s1598851760352","name":"new scope1","squares":[26,27,34,35]}]
    const form = this.omnisEditorFormsService.initScopeForm();
    form.patchValue({ ...scope, new: false });
    this.scopeForms.push(form);

    const squares = [];

    for (const square of scope.squares) {
      for (const item of this.grid) {
        if (square == item.id) {
          // item.selectAsScope();
          squares.push(item);
          break;
        }
      }
    }

    const tmpScope = this.omnisEditorService.createScope(squares, { id: scope.id, name: scope.name });
    this.canvas.add(tmpScope);

    this.updateScopeInSquares(tmpScope);
  }

  onCancelScope(event, index: number) {
    //console.log('onCancelScope:', index, event);

    // get scope by scope id
    const scope = this.getScopes(event.id)[0];

    // show scope object
    scope.visible = true;
    // reset grid style
    this.resetGridSelectedItem();
    // force hide overlay
    this.toggleScopeOverlay(true);
    // this.canvas.renderAll();

    this.isEditingScopeInProcess = false;
  }

  onEditScope(event, index: number) {
    //console.log('onEditScope:', index, event);

    // allow to select/deselct grid
    this.isEditingScopeInProcess = true;
    this.selectedScope = event;

    // reset selected scope squares
    this.selectedScopeSquares = [];

    // get scope by scope id
    const scope = this.getScopes(event.id)[0];

    // show selected squares (mark grid items as selected scope)
    for (const sq of scope.squares) {
      const gridItem = this.grid.find((g) => g.id === sq);
      this.selectedScopeSquares.push(gridItem);

      gridItem.selectAsScope();
    }

    // hide scope object (or remove select scopes object)
    scope.visible = false;
    this.canvas.renderAll();
  }

  onRemoveScope(event, index: number) {
    //console.log('onRemoveScope:', index, event);

    const confirm = window.confirm('Are you sure that you want to remove the scope ' + event.name + '?');
    if (!confirm) {
      return;
    }

    // remove scope from canvas
    const scope = this.getScopes(event.id)[0];

    // remove scope from form
    this.scopeForms.removeAt(index);

    if (scope) {
      // clear grid item line by line id
      for (const item of this.grid) {
        if (item.scope === scope.id) {
          item.scope = null;
        }
      }

      // remove scope and squares
      for (const o of scope._objects) {
        this.canvas.remove(o);
      }
      this.canvas.remove(scope);

      // update scope in squares form
      const squaresEntities = this.squaresForm.value;
      for (const sq of scope.squares) {
        squaresEntities[sq].scope = null;
      }
      this.squaresForm.setValue(squaresEntities);
    }

    // reset grid style
    this.resetGridSelectedItem();
    // force hide overlay
    this.toggleScopeOverlay(true);
    // this.canvas.renderAll();

    this.isAddingScopeInProcess = false;
    this.isEditingScopeInProcess = false;
  }

  onSaveScope(event, index: number) {
    //console.log('onSaveScope:', index, event);

    let scope = this.getScopes(event.id)[0];
    const squares = this.selectedScopeSquares;

    if (scope) {
      // remove old scope
      this.canvas.remove(scope);
    }
    // create new scope
    scope = this.omnisEditorService.createScope(squares, { id: event.id, name: event.name });
    this.canvas.add(scope);

    this.updateScopeInSquares(scope);
    // // update scope in squares form and
    // // update grid item scope
    // const squaresEntities = this.squaresForm.value;

    // for (const item of this.grid) {
    //   // reset grid scope
    //   if (item.scope === scope.id) {
    //     item.scope = null;
    //     squaresEntities[item.id].scope = null;
    //   }
    //   // check grid is part of scope
    //   if (scope.squares.indexOf(item.id) >= 0) {
    //     item.scope = scope.id;
    //     squaresEntities[item.id].scope = scope.id;
    //   }
    // }

    // this.squaresForm.setValue(squaresEntities);

    // update scope form
    this.scopeForms.at(index).patchValue({
      squares: scope.squares,
      new: false,
    });

    this.resetGridSelectedItem();
    this.canvas.renderAll();

    this.isAddingScopeInProcess = false;
    this.isEditingScopeInProcess = false;
  }

  private updateScopeInSquares(scope) {
    // console.log('updateScopeInSquares:', scope);
    // update scope in squares form and
    // update grid item scope
    const squaresEntities = this.squaresForm.value;

    for (const item of this.grid) {
      // reset grid scope
      if (item.scope === scope.id) {
        item.scope = null;
        squaresEntities[item.id].scope = null;
      }
      // check grid is part of scope
      if (scope.squares.indexOf(item.id) >= 0) {
        item.scope = scope.id;
        squaresEntities[item.id].scope = scope.id;
      }
    }

    this.squaresForm.setValue(squaresEntities);
  }

  save() {
    // const json = this.canvas.toJSON();
    // console.log('CanvasObjects:', json.objects);

    const formValue = this.form.value;
    // remove line.new
    for (const item of formValue.lines) {
      delete item.new;
    }
    // remove scope.new
    for (const item of formValue.scopes) {
      delete item.new;
    }
    // console.log('FormValue:', formValue);

    this.saveClicked.emit(formValue);
  }
}
