import { Injectable } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid';
import { CustomHeaderComponent } from './custom-header/custom-header.component';

@Injectable()
export class SCTableService {
  constructor(private sharedService: SharedService) {}

  // Default Options of AG Grid
  private baseOptions: GridOptions = {
    animateRows: false,
    enableCellChangeFlash: true,
    defaultColDef: {
      autoHeight: false,
      filterParams: { newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      icons: {
        checkboxChecked: '<i class="fa fa-fw fa-check-square-o"></i>',
        checkboxUnchecked: '<i class="fa fa-fw fa-square-o"></i>',
        checkboxIndeterminate: '<i class="fa fa-fw fa-minus-square-o"></i>',
      },
    },
    enableColResize: true,
    enableSorting: true,
    floatingFilter: true,
    frameworkComponents: {
      agColumnHeader: CustomHeaderComponent,
    },
    getRowNodeId: this.getRowNodeId,
    headerHeight: 48,
    localeTextFunc: this.localeTextFunc.bind(this),
    rowHeight: 53,
    rowSelection: 'multiple',
  };

  private loadingRenderer(params: any) {
    if (typeof params.data !== 'undefined') {
      return params.valueFormatted || params.value;
    } else {
      const loadingText = this.sharedService.getTranslation('LOADING');
      return `<span><i class="fa fa-refresh fa-spin"></i> ${loadingText}</span>`;
    }
  }

  private localeTextFunc(key: any, defaultValue: any) {
    const translateKey = ('AGGRID_' + key).toUpperCase();
    return this.sharedService.getTranslation(translateKey) || defaultValue;
  }

  private getRowNodeId(data: any) {
    return data.id;
  }

  /**
   * CREATE TABLE
   * @param columnDefs
   * @param rowData
   */
  initTable(columnDefs: (ColDef | ColGroupDef)[], rowData: { [prop: string]: any }[] = []): GridOptions {
    return { ...this.baseOptions, columnDefs, rowData };
  }

  /**
   * CREATE TABLE (LazyLoad)
   * @param columnDefs
   */
  initLazyLoadTable(columnDefs: (ColDef | ColGroupDef)[]): GridOptions {
    return {
      ...this.baseOptions,
      columnDefs,
      components: {
        loadingRenderer: this.loadingRenderer.bind(this),
      },
      enableServerSideSorting: true,
      enableServerSideFilter: true,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 2,
      maxConcurrentDatasourceRequests: 1,
      rowModelType: 'infinite',
    };
  }

  /**
   * ADD ROWS BY DATASET
   * @param gridOptions
   * @param dataset
   * @param deleteBeforeAdd
   */
  addTableRow(
    gridOptions: GridOptions,
    dataset: { [prop: string]: any }[],
    deleteBeforeAdd?: boolean,
    addIndex?: number
  ): void {
    if (deleteBeforeAdd === true) {
      gridOptions.api.setRowData([]);
    }
    const transaction: any = { add: dataset };
    if (typeof addIndex !== 'undefined') {
      transaction.addIndex = addIndex;
    }
    gridOptions.api.updateRowData(transaction);
  }

  /**
   * UPDATE ROWS BY COMPARE_KEY
   * @param gridOptions
   * @param dataset
   * @param compareKey
   */
  updateTableRow(gridOptions: GridOptions, dataset: { [prop: string]: any }[], compareKey: string = 'id'): void {
    if (gridOptions && gridOptions.api && dataset && dataset.length) {
      const dataToUpdate = [];
      const dataToAdd = [];

      for (let i = 0; i < dataset.length; i++) {
        if (!dataset[i][compareKey]) {
          continue;
        }

        let found = false;

        gridOptions.api.forEachNode((rowNode) => {
          if (
            !rowNode ||
            !rowNode.data ||
            !rowNode.data[compareKey] ||
            rowNode.data[compareKey] !== dataset[i][compareKey]
          ) {
            return;
          }

          const rowData = Object.assign(rowNode.data, dataset[i]);
          dataToUpdate.push(rowData);
          found = true;
        });

        if (!found) {
          dataToAdd.push(dataset[i]);
        }
      }
      gridOptions.api.updateRowData({ add: dataToAdd, update: dataToUpdate });
    }
  }

  /**
   * UPDATE ROWS BY ROW_NODE_ID
   * @param gridOptions
   * @param dataset
   */
  updateTableRowV2(gridOptions: GridOptions, dataset: { [key: string]: any }[], compareKey: string = 'id') {
    if (gridOptions && gridOptions.api && dataset && dataset.length) {
      for (let i = 0; i < dataset.length; i++) {
        const rowNode = gridOptions.api.getRowNode(dataset[i][compareKey]);
        if (!rowNode) {
          continue;
        }
        const rowData = Object.assign(rowNode.data, dataset[i]);
        gridOptions.api.updateRowData({ update: [rowData] });
      }
    }
  }

  /**
   * DELETE ROW BY KEY (ID, KEY OR USERNAME)
   * @param gridOptions
   * @param value
   * @param compareKey
   */
  deleteTableRow(gridOptions: GridOptions, value: any, compareKey: string = 'id'): void {
    const itemsToRemove = [];
    // ____ LOOP ROWS
    gridOptions.api.forEachNode((rowNode) => {
      const rowData = rowNode.data;
      // ____ COMPARE KEY
      if (rowData[compareKey] === value) {
        // ____ ADD ROW_DATA TO REMOVE LIST
        itemsToRemove.push(rowData);
      }
    });
    // ____ UPDATE ROWS
    gridOptions.api.updateRowData({ remove: itemsToRemove });
  }

  /**
   * DELETE ROW BY ROW_NODE_ID
   * @param gridOptions
   * @param dataset
   */
  deleteTableRowV2(gridOptions: GridOptions, id: string) {
    if (gridOptions && gridOptions.api && id) {
      const rowNode = gridOptions.api.getRowNode(id);
      if (!rowNode) {
        return;
      }
      gridOptions.api.updateRowData({ remove: [rowNode.data] });
    }
  }

  /**
   * DELETE ALL ROWS
   * @param gridOptions
   */
  clearTable(gridOptions: GridOptions): void {
    gridOptions.api.setRowData([]);
  }
}
