<ng-container *ngIf="showLoading; then spinner; else content"></ng-container>

<ng-template #content>
  <span>
    {{ cellValue }}
  </span>
</ng-template>

<ng-template #spinner>
  <span>
    <i class="fa fa-refresh fa-spin"></i>
    {{ 'LOADING' | translate }}
  </span>
</ng-template>
