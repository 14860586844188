<p-card>
  <p-header>
    <div class="ui-g">
      <h2 class="title">
        {{ data.subject }}
      </h2>
    </div>
  </p-header>

  <!--Body-->
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-6 grey-text">
        <small pTooltip="{{ 'SINCE' | translate }}" appendTo="body">
          <i class="fa fa-clock-o"></i>
          <!-- {{ timeFormat(data.createdAt) }} -->
          {{ data.createdAt }}
        </small>
      </div>
      <div class="ui-g-6 text-right">
        <span class="badge" [ngClass]="priorityColor()" pTooltip="{{ 'PRIORITY' | translate }}" appendTo="body">
          <i class="fa fa-exclamation-triangle"></i>
          {{ data.priority }}
        </span>
      </div>
    </div>

    <div class="ui-g-12">
      <div class="ui-g">
        {{ data.text }}
      </div>
    </div>

    <div *ngIf="showActionArea()" class="ui-g-12">
      <hr>
      <div [ngSwitch]="showReasonInput">
        <div *ngSwitchCase="true">
          <div class="ui-g-12">
            <div class="ui-g-8">
              <form [formGroup]="reasonForm" novalidate>
                <sc-text-input
                  controlName="reason"
                  [form]="reasonForm"
                  label="{{ 'REASON' | translate }}"
                ></sc-text-input>
              </form>
            </div>
            <div class="ui-g-4">
              <button
                pButton
                type="button"
                class="ui-button-success"
                label="{{ 'CONFIRM' | translate }}"
                (click)="unsolved()"
                [disabled]="isSubmitting"
              ></button>
            </div>
          </div>
        </div>

        <div *ngSwitchDefault>
          <div class="ui-g-12">
            <button
              pButton
              type="button"
              class="ui-button-danger"
              label="{{ 'UNSOLVABLE' | translate }}"
              (click)="unsolvable()"
              [disabled]="isSubmitting"
            ></button>

            <button
              pButton
              type="button"
              class="ui-button-success"
              label="{{ 'SOLVED' | translate }}"
              (click)="solved()"
              [disabled]="isSubmitting"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-card>
