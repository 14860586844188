import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { GridOptions } from 'ag-grid';
import { Event } from '../models/event';
import { Site } from '@widgets/sites/models/site';
import { ModalConfig } from '@sc/modal/modal-config';

import { EventsService } from '../services/events.service';
import { SharedService } from '@shared/shared.service';

import { EventActionCellComponent } from '../event-action-cell/event-action-cell.component';
import { GuestResponseCellComponent } from '../guest-response-cell/guest-response-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { MultiLanguageCellComponent } from '@widgets/_shared/multi-language-cell/multi-language-cell.component';
import { PhotoCellComponent } from '@widgets/_shared/photo-cell/photo-cell.component';
import { SchedulerCellComponent } from '@widgets/_shared/scheduler-cell/scheduler-cell.component';

@Component({
  selector: 'sc-events-table',
  templateUrl: './events-table.component.html',
})
export class EventsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: Event[];
  formConfig: ModalConfig;
  tableTitle: string;
  table: GridOptions;
  selectedSite: Site;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private eventsService: EventsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'teaserImagePath',
        headerName: 'TEASER_IMAGE_PATH',
        field: 'teaserImagePath',
        width: 80,
        cellRendererFramework: PhotoCellComponent,
      },
      {
        colId: 'publishFrom',
        headerName: 'PUBLISH_FROM',
        field: 'publishFrom',
        width: 150,
        valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      },
      {
        colId: 'publishTo',
        headerName: 'PUBLISH_TO',
        field: 'publishTo',
        width: 150,
        valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      },
      {
        colId: 'title',
        headerName: 'TITLE',
        field: 'title',
        cellRendererFramework: MultiLanguageCellComponent,
      },
      {
        colId: 'teaserText',
        headerName: 'TEASER_TEXT',
        field: 'teaserText',
        cellRendererFramework: MultiLanguageCellComponent,
      },
      {
        colId: 'content',
        headerName: 'CONTENT',
        field: 'content',
        cellRendererFramework: MultiLanguageCellComponent,
      },
      {
        colId: 'pushNotificationText',
        headerName: 'PUSH_NOTIFICATION_TEXT',
        field: 'pushNotificationText',
        cellRendererFramework: MultiLanguageCellComponent,
      },
      {
        colId: 'pushNotificationScheduler',
        headerName: 'PUSH_NOTIFICATION_SCHEDULER',
        field: 'pushNotificationScheduler',
        cellRendererFramework: SchedulerCellComponent,
      },
      {
        colId: 'guestResponses',
        headerName: 'GUEST_RESPONSE',
        field: 'guestResponses',
        cellRendererFramework: GuestResponseCellComponent,
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: EventActionCellComponent,
      },
    ];
    this.formConfig = {
      name: 'EventForm',
      options: { modalTitle: 'EVENT_FORM_TITLE', fullScreen: true },
    };
    this.tableTitle = 'EVENTS';

    // Get Selected Site
    this.subscribers.WATCH_SITE = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (this.selectedSite) {
      const { companyId, id } = this.selectedSite;
      this.subscribers.GET_DATASET = this.eventsService
        .getEvents({ companyId, siteId: id, minimize: true })
        .subscribe((result: any) => {
          let dataset;
          if (result.mappingKeys) {
            dataset = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);
          } else {
            dataset = result.data;
          }

          this.dataset = dataset.map((data) => {
            const item = { ...data };
            item.guestResponses = this.sharedService.jsonParse(item.guestResponses) || [];
            item.pushNotificationScheduler = this.sharedService.jsonParse(item.pushNotificationScheduler) || [];
            item.pushNotificationText = this.sharedService.jsonParse(item.pushNotificationText) || [];
            item.teaserText = this.sharedService.jsonParse(item.teaserText) || [];
            item.title = this.sharedService.jsonParse(item.title) || [];
            item.content = this.sharedService.jsonParse(item.content) || [];
            return item;
          });
        });
    }
  }
}
