import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-rule-command-parameter-form',
  templateUrl: './parameter-form.component.html',
})
export class CommandParameterFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() groupId: any;
  @Input() devices: any;

  constructor() {}

  ngOnInit() {}
}
