import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

// Components
import { LocationPropertyActionCellComponent } from './location-property-action-cell/location-property-action-cell.component';
import { LocationPropertyFormComponent } from './location-property-form/location-property-form.component';
import { LocationPropertiesTableComponent } from './location-properties-table/location-properties-table.component';

// Services
import { LocationPropertyFormsService } from './services/location-property-forms.service';
import { LocationPropertiesService } from './services/location-properties.service';

const COMPONENTS: any[] = [
  LocationPropertyActionCellComponent,
  LocationPropertyFormComponent,
  LocationPropertiesTableComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [LocationPropertiesService, LocationPropertyFormsService]
})
export class LocationPropertiesModule {}
