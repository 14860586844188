import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Rule } from '../models/rule';
import { RuleCommand } from '../models/rule-command';
import { RuleCommandParameter } from '../models/rule-command-parameter';
import { RuleCondition } from '../models/rule-condition';
import { RuleTemplate } from '@widgets/rule-templates/models/rule-template';

@Injectable()
export class RuleFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initRuleForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      description: null,
      disabledUntil: null,
      startMinExecutionTime: null,
      startMaxExecutionTime: null,
      startBlockingAfterExecutionTime: null,
      endMinExecutionTime: null,
      endMaxExecutionTime: null,
      endBlockingAfterExecutionTime: null,
      startCommands: this.formBuilder.array([]),
      startConditions: this.formBuilder.array([]),
      endCommands: this.formBuilder.array([]),
      endConditions: this.formBuilder.array([]),
      ruleTemplateId: [null, Validators.required],
      locationId: [null, Validators.required],
      siteId: [null, Validators.required],
      companyId: [null, Validators.required],
      startAllowRepetition: null,
      endAllowRepetition: null,
      energySavingPatternsEnd: null,
      energySavingPatternsStart: null,
      endConditionConnector: 'AND',
      startConditionConnector: 'AND',
      affectedLocations: null,
      affectedDevices: null,
      isActive: false,
      isDeleted: false,
    });
  }

  initConditionFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: null,
      position: [null, Validators.required],
      requiredValidityPeriod: [null, Validators.required],
      sourceType: [null, Validators.required],
      connector: null,
      deviceId: null,
      deviceTypeId: null,
      datetimePropertyTypeId: null,
      datetimePropertyValue: null,
      event: null,
      locationId: null,
      locationPropertyTypeId: null,
      operator: null,
      parameters: null,
      referencedType: null,
      referencedDeviceId: null,
      referencedDeviceTypeId: null,
      referencedLocationId: null,
      referencedLocationPropertyTypeId: null,
      referencedValue: null,
      wasTrueOneTimeInPastMs: null,
      ruleId: [null, Validators.required],
    });
  }

  setConditionFormArray(form: FormGroup, data: RuleCondition[], isEnd?: boolean) {
    const formGroups = data.map((item) => {
      const fg = this.initConditionFormGroup();
      fg.patchValue(item);
      // delete required data on create mode
      if (!item.id) {
        fg.controls['ruleId'].clearValidators();
        fg.removeControl('ruleId');
        fg.removeControl('id');
      }
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);

    if (isEnd) {
      form.setControl('endConditions', formArray);
    } else {
      form.setControl('startConditions', formArray);
    }
  }

  initCommandFormGroup(isService: boolean, isEnd?: boolean): FormGroup {
    const formControls: any = {
      id: null,
      targetTypeId: null,
      action: null,
      delay: null,
      position: null,
      deviceId: null,
      deviceTypeId: null,
      serviceId: null,
      serviceTypeId: null,
      notificationTemplateId: null,
      parameters: isService ? null : this.formBuilder.array([]),
      locationId: null,
      locationPropertyTypeId: null,
      locationPropertyForceValue: null,
      locationPropertyAdjustValue: null,
      ruleId: [null, Validators.required],
      executeOnlyIfDifferentFromLocal: false,
    };

    if (isEnd === true) {
      formControls.executeOnlyOneTimeUntilRuleWasStart = false;
    } else {
      formControls.executeOnlyOneTimeUntilRuleWasEnd = false;
    }

    return this.formBuilder.group(formControls);
  }

  initCommandParameterFormGroup() {
    return this.formBuilder.group({
      id: null,
      key: [null, Validators.required],
      value: null,
      ruleCommandId: [null, Validators.required],
    });
  }

  setCommandFormArray(form: FormGroup, data: RuleCommand[], isEnd?: boolean) {
    const formGroups = data.map((item) => {
      const isService = item.serviceTypeId ? true : false;
      const fg = this.initCommandFormGroup(isService, isEnd);
      const itemWithoutParams = { ...item };
      delete itemWithoutParams.parameters;

      fg.patchValue(itemWithoutParams);

      if (!isService && item.parameters) {
        this.setCommandParameterFormArray(fg, item.parameters);
      }
      // const parameterFormGroups = [];
      // if (!isService && item.parameters && item.parameters.length > 0) {
      //   item.parameters.forEach(parameter => {
      //     if (parameter) {
      //       const paramForm = this.initCommandParameterFormGroup();
      //       paramForm.patchValue(parameter);
      //       // delete required data on create mode
      //       if (!parameter.id) {
      //         paramForm.controls['ruleCommandId'].clearValidators();
      //         paramForm.removeControl('ruleCommandId');
      //         paramForm.removeControl('id');
      //       }
      //       parameterFormGroups.push(paramForm);
      //     }
      //   });
      // }

      // if (parameterFormGroups.length > 0) {
      //   fg.setControl(
      //     'parameters',
      //     this.formBuilder.array(parameterFormGroups)
      //   );
      // } else {
      //   fg.controls['parameters'].reset();
      // }

      // delete required data on create mode
      if (!itemWithoutParams.id) {
        fg.controls['ruleId'].clearValidators();
        fg.removeControl('ruleId');
        fg.removeControl('id');
      }

      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);

    if (isEnd) {
      form.setControl('endCommands', formArray);
    } else {
      form.setControl('startCommands', formArray);
    }
  }

  setCommandParameterFormArray(form: FormGroup, data: RuleCommandParameter[]) {
    const parameterFormGroups = [];
    if (data.length > 0) {
      data.forEach((parameter) => {
        if (parameter) {
          const paramForm = this.initCommandParameterFormGroup();
          paramForm.patchValue(parameter);
          // delete required data on create mode
          if (!parameter.id) {
            paramForm.controls['ruleCommandId'].clearValidators();
            paramForm.removeControl('ruleCommandId');
            paramForm.removeControl('id');
          }
          parameterFormGroups.push(paramForm);
        }
      });
    }

    if (parameterFormGroups.length > 0) {
      form.setControl('parameters', this.formBuilder.array(parameterFormGroups));
    } else {
      form.controls['parameters'].reset();
    }
  }

  /**
   * Patching RuleTemplate into Rule Form (create mode)
   * @param form
   * @param template
   */
  patchValueFromTemplate(form: FormGroup, template: RuleTemplate) {
    form.patchValue({
      name: template.name,
      description: template.description,
      energySavingPatternsEnd: template.energySavingPatternsStart,
      energySavingPatternsStart: template.energySavingPatternsStart,
      startMinExecutionTime: template.startMinExecutionTime,
      startMaxExecutionTime: template.startMaxExecutionTime,
      startBlockingAfterExecutionTime: template.startBlockingAfterExecutionTime,
      endMinExecutionTime: template.endMinExecutionTime,
      endMaxExecutionTime: template.endMaxExecutionTime,
      endBlockingAfterExecutionTime: template.endBlockingAfterExecutionTime,
      endConditionConnector: template.endConditionConnector,
      startConditionConnector: template.startConditionConnector,
    });

    if (template.startCommands && template.startCommands.length) {
      const startCommands = <RuleCommand[]>[...template.startCommands].map((cmd) => {
        delete cmd.id;
        if (cmd.parameters && cmd.parameters.length) {
          cmd.parameters = [...cmd.parameters].map((p) => ({
            key: p.key,
            value: p.value,
          }));
        }
        return cmd;
      });

      this.setCommandFormArray(form, startCommands, false);
    } else {
      this.setCommandFormArray(form, [], true);
    }

    if (template.endCommands && template.endCommands.length) {
      const endCommands = <RuleCommand[]>[...template.endCommands].map((cmd) => {
        delete cmd.id;
        if (cmd.parameters && cmd.parameters.length) {
          cmd.parameters = [...cmd.parameters].map((p) => ({
            key: p.key,
            value: p.value,
          }));
        }
        return cmd;
      });

      this.setCommandFormArray(form, endCommands, true);
    } else {
      this.setCommandFormArray(form, [], true);
    }

    if (template.startConditions && template.startConditions.length) {
      const startConditions = <RuleCondition[]>[...template.startConditions].map((cond) => {
        delete cond.id;
        return cond;
      });

      this.setConditionFormArray(form, startConditions, false);
    } else {
      this.setConditionFormArray(form, [], true);
    }

    if (template.endConditions && template.endConditions.length) {
      const endConditions = <RuleCondition[]>[...template.endConditions].map((cond) => {
        delete cond.id;
        return cond;
      });

      this.setConditionFormArray(form, endConditions, true);
    } else {
      this.setConditionFormArray(form, [], true);
    }
  }

  /**
   * Patching Rule into Rule Form (edit mode)
   * @param form
   * @param rule
   */
  patchValue(form: FormGroup, rule: Rule) {
    form.patchValue(rule);

    if (rule.startConditions) {
      this.setConditionFormArray(form, rule.startConditions, false);
    }
    if (rule.startCommands) {
      this.setCommandFormArray(form, rule.startCommands, false);
    }
    if (rule.endConditions) {
      this.setConditionFormArray(form, rule.endConditions, true);
    }
    if (rule.endCommands) {
      this.setCommandFormArray(form, rule.endCommands, true);
    }
  }
}
