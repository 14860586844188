import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { CustomApp } from '../models/custom-app';

import { AppStylesService } from '@widgets/app-styles/services/app-styles.service';
import { AppsService } from '@widgets/apps/services/apps.service';
import { CustomAppFormsService } from '../services/custom-app-forms.service';
import { CustomAppsService } from '../services/custom-apps.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-custom-app-form',
  templateUrl: './custom-app-form.component.html'
})
export class CustomAppFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  form: FormGroup;
  formData: CustomApp;
  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  isSubmitting = false;
  optionData: { [key: string]: SelectItem[] } = {};

  private subscribers: { [key: string]: any } = {};

  constructor(
    private appsService: AppsService,
    private appsStyleService: AppStylesService,
    private customAppFormsService: CustomAppFormsService,
    private customAppsService: CustomAppsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchCustomApp(this.data.id);
    }

    // get companies & sites
    this.optionData.companies = this.sharedService.selectItems.companies;
    this.optionData.sites = [];

    this.fetchAppStyles();
    this.fetchApps();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchAppStyles() {
    this.fetchingState++;
    this.subscribers.GET_APP_STYLES = this.appsStyleService
      .getAppStyles()
      .subscribe((result: any) => {
        this.optionData.appStyles = this.sharedService.createSelectItems(
          result.data.filter(d => !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  fetchApps() {
    this.fetchingState++;
    this.subscribers.GET_APPS = this.appsService
      .getApps()
      .subscribe((result: any) => {
        this.optionData.apps = this.sharedService.createSelectItems(
          result.data.filter(d => !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  fetchCustomApp(id: number) {
    this.fetchingState++;
    this.subscribers.GET_CUSTOM_APP = this.customAppsService
      .getCustomApp(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.customAppFormsService.initCustomAppForm();

    if (this.editMode === true) {
      this.form.get('appId').disable();
      // this.form.get('companyId').disable();
    }

    this.subscribers.WATCH_COMPANY = this.form
      .get('companyId')
      .valueChanges.subscribe(result => {
        if (!result) {
          this.form.get('siteId').reset();
          this.form.get('siteId').disable();
        } else {
          let sites = this.sharedService.sourceData.sites;
          sites = sites.filter(s => s.companyId === result);
          this.optionData.sites = this.sharedService.createSelectItems(
            sites,
            false
          );
        }
      });
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.form.value;

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(data: CustomApp) {
    this.customAppsService
      .createCustomApp(data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  private update(data: CustomApp) {
    this.customAppsService
      .updateCustomApp(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.customAppsService
      .deleteCustomApp(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
