import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocationDetails } from '@widgets/locations/models/location-details';
import { SharedService } from '@shared/shared.service';
import { GuestSessionsService } from '@widgets/guest-sessions/services/guest-sessions.service';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import * as fromStore from '@app/store';
import { Locale } from '@app/widgets/locales/models/locale';

@Component({
  selector: 'sc-guest-session',
  templateUrl: './guest-session.component.html',
  styleUrls: ['./guest-session.component.scss'],
})
export class GuestSessionComponent implements OnInit, OnDestroy {
  defaultLanguage: string;
  fetchingState = 0;
  guestSession: any;
  qrcodeData: string;
  showPin = false;

  private locales: { [key: string]: Locale } = {};
  private location: LocationDetails;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private guestSessionsService: GuestSessionsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit(): void {
    if (this.config && this.config.data) {
      if (this.config.data.location) {
        this.location = this.config.data.location;
        this.createQRcode();
        this.fetchGuestSession();
      }
    }

    this.subscribers.getLocalesEntities = this.store
      .select(fromStore.getLocalesEntities)
      .pipe(
        filter((entities) => (entities && Object.keys(entities).length > 0 ? true : false)),
        take(1)
      )
      .subscribe((locales) => {
        this.locales = locales;
        this.updateDefaultLanguage();
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal() {
    this.dialog.close();
  }

  private fetchGuestSession() {
    this.fetchingState++;
    this.subscribers.fetchGuestSession = this.guestSessionsService
      .getGuestSessionByLocation(this.location.idx)
      .subscribe((result: any) => {
        this.guestSession = result.data;
        this.updateDefaultLanguage();
        this.fetchingState--;
      });
  }

  private updateDefaultLanguage() {
    this.defaultLanguage = '';
    if (this.locales && this.guestSession && this.guestSession.locale) {
      if (this.locales[this.guestSession.locale]) {
        this.defaultLanguage = this.locales[this.guestSession.locale].language;
      } else {
        this.defaultLanguage = this.locales.en.language;
      }
    }
  }

  dateFormat(date: string) {
    return this.sharedService.dateFormat(date);
  }

  editSession() {
    this.dialog.close({ isNew: false, guestSession: this.guestSession });
  }

  newSession() {
    this.dialog.close({ isNew: true });
  }

  private createQRcode() {
    const prefix = ('' + Math.random()).substring(2, 10);
    const suffix = ('' + Math.random()).substring(2, 10);
    this.qrcodeData = `${prefix}.${this.location.idx}.${suffix}`;
  }
}
