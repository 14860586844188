<div class="ui-g mb-4">
  <div class="ui-g-12">
    <button
      pButton
      type="button"
      [ngClass]="changeViewBtnClass('ongoing')"
      (click)="onChangeView('ongoing')"
      label="{{'ONGOING' | translate}}"
    ></button>

    <button
      pButton
      type="button"
      [ngClass]="changeViewBtnClass('history')"
      (click)="onChangeView('history')"
      label="{{'HISTORY' | translate}}"
    ></button>
  </div>
</div>

<div class="ui-g" *ngIf="tableColumns">
  <div class="ui-g-12 ui-g-nopad" style="border:1px solid #f2f2f2; height:400px;">
    <sc-table [columns]="tableColumns" [dataset]="dataset" [hideHeader]="true">
    </sc-table>
  </div>
</div>




