import { Component, OnInit, Input } from '@angular/core';
import { ModalConfig } from '@app/sandc/modal/modal-config';
import { LocationDetails } from '@app/widgets/locations/models/location-details';
import { LocationType } from '@app/widgets/locations/models/location-type';

@Component({
  selector: 'sc-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent implements OnInit {
  @Input()
  location: LocationDetails;
  @Input()
  locationTypes: LocationType[];

  locationFormModal: ModalConfig;

  constructor() {}

  ngOnInit() {
    this.locationFormModal = {
      data: this.location,
      name: 'LocationForm',
      options: {
        modalTitle: 'LOCATION_FORM_TITLE',
        requiredPermission: 'location_u',
      },
    };

    this.updateLocationData();
  }

  onUpdateSuccess(event) {
    if (event && event.result) {
      this.location.description = event.result.description;
      this.location.locationTypeId = event.result.locationTypeId;
      this.location.isActive = event.result.isActive;
      this.updateLocationData();
    }
  }

  private updateLocationData() {
    if (this.locationTypes) {
      const locationType = this.locationTypes.find((d) => d.id === this.location.locationTypeId);
      this.location.locationTypeName = locationType.name;
    }
  }
}
