<div class="property-form" [formGroup]="form">
  <div class="ui-g">
    <div class="ui-g-12 ui-md-3">
      <sc-dropdown
        controlName="type"
        [form]="form"
        [label]="'TYPE' | translate"
        [options]="selectItems?.deviceTypes"
      ></sc-dropdown>
    </div>

    <div class="ui-g-12 ui-md-3">
      <sc-text-input
        controlName="display_name"
        [form]="form"
        [label]="'DISPLAY_NAME' | translate"
      ></sc-text-input>
    </div>

    <div class="ui-g-12 ui-md-3">
      <sc-text-input
        controlName="unique_id"
        [form]="form"
        [label]="'UNIQUE_ID' | translate"
      ></sc-text-input>
    </div>

    <div class="ui-g-12 ui-md-3" *ngIf="selectedProtocol !== 'modbus'">
      <sc-text-input
        controlName="min_diff_threshold"
        [form]="form"
        [label]="'MIN_DIFFERENCE_THRESHOLD' | translate"
      ></sc-text-input>
    </div>
  </div>

  <sc-property-modbus-form
    *ngIf="selectedProtocol === 'modbus'"
    [form]="form"
    [selectItems]="selectItems"
  >
  </sc-property-modbus-form>

  <sc-property-value-form
    [form]="form"
    [selectItems]="selectItems"
    [sourceData]="sourceData"
    [mappingValues]="mappingValues"
    [selectedProtocol]="selectedProtocol"
    [selectedLibrary]="selectedLibrary"
  ></sc-property-value-form>

  <sc-property-config-form
    [form]="form"
    [selectItems]="selectItems"
    [sourceData]="sourceData"
  ></sc-property-config-form>
</div>
