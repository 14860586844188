import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { BatteryAlertWidgetComponent } from './battery-alert-widget/battery-alert-widget.component';
import { BatteryAlertModalComponent } from './battery-alert-modal/battery-alert-modal.component';

const COMPONENTS: any[] = [
  BatteryAlertModalComponent,
  BatteryAlertWidgetComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS]
})
export class BatteryAlertModule {}
