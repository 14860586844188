import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ModalConfig } from '@sc/modal/modal-config';
import { SelectItem } from 'primeng/api';
import { LocationDetails } from '@widgets/locations/models/location-details';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'location-assign-form',
  templateUrl: './location-assign-form.component.html',
  styleUrls: ['./location-assign-form.component.scss'],
})
export class LocationAssignFormComponent implements OnInit {
  createLocationForm: ModalConfig;
  locationControl: FormControl;
  locations: LocationDetails[] = [];
  floor: LocationDetails;
  selectItems: { [prop: string]: SelectItem[] } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.config && this.config.data) {
      if (this.config.data.locations) {
        let locations = this.config.data.locations;
        this.locations = locations.filter(l => l.isActive == 1 && l.isDeleted == 0);
      }
      if (this.config.data.floor) {
        this.floor = this.config.data.floor;
      }
    }

    this.createLocationForm = {
      name: 'LocationForm',
      options: {
        modalTitle: 'LOCATION_FORM_TITLE',
        requiredPermission: 'location_c',
      },
      data: { child: true, parentData: this.floor },
    };

    this.selectItems.locations = this.sharedService.createSelectItems(
      this.locations.filter(
        (l) => l.parentId === this.floor.id || (l.locationTypeKey !== 'f' && l.floorNumber === this.floor.floorNumber)
      )
    );

    this.locationControl = new FormControl();
    // this.locationControl.valueChanges.subscribe({
    //   next: (result) => {
    //     console.log(result);
    //   },
    // });
  }

  onSelectLocation() {
    const location = this.locations.find((l) => l.id === this.locationControl.value);
    this.dialog.close(location);
  }

  onCreateClicked(response) {
    this.dialog.close(response.result);
  }
}
