<div class="device-logs-comparison">
  <div class="ui-g">
    <div class="ui-g-12 ui-md-4">
      <p-accordion [multiple]="true">
        <p-accordionTab [selected]="true">
          <p-header>
            {{ "DEVICE_PROPERTIES" | translate }}
          </p-header>

          <div class="ui-g" [formGroup]="devicePropsForm">
            <div class="ui-g-12">
              <p-dropdown
                formControlName="automation"
                [options]="selectItems?.automations"
                appendTo="body"
                [filter]="true"
              ></p-dropdown>
            </div>

            <div class="ui-g-12 ui-g-nopad">
              <div class="ui-g-12 ui-md-9">
                <p-dropdown
                  formControlName="device"
                  [options]="selectItems?.filteredDevices"
                  appendTo="body"
                  [filter]="true"
                  [group]="true"
                  [placeholder]="'SELECT' | translate"
                ></p-dropdown>
              </div>

              <div class="ui-g-12 ui-md-3">
                <button
                  pButton
                  type="button"
                  [label]="'ADD' | translate"
                  class="nopad"
                  (click)="onAddDevice()"
                  style="width: 100%"
                ></button>
              </div>
            </div>

            <!-- selected devices -->
            <ul class="selected-vd-list">
              <li
                class="selected-vd-item"
                *ngFor="let dev of selectedDevices; let i = index"
              >
                <div
                  class="selected-vd-item__label"
                  [pTooltip]="
                    ('AUTOMATION' | translate) + ' ' + dev?.automationId
                  "
                  tooltipPosition="top"
                  appendTo="body"
                >
                  {{ dev?.label }}
                </div>

                <sc-confirm-trigger
                  buttonClass="selected-vd-item__del-btn"
                  [buttonIcon]="'fa fa-times fa-fw'"
                  [message]="
                    'DEVICE_ANALYTIC_UNSELECT_DEVICE_PROPERTY_CONFIRM_MASSAGE'
                      | translate
                  "
                  (onAccept)="onRemoveDevice(dev, i)"
                ></sc-confirm-trigger>
              </li>
            </ul>
          </div>
        </p-accordionTab>

        <p-accordionTab [selected]="true">
          <p-header>
            {{ "FILTERS" | translate }}
          </p-header>

          <div class="ui-g" [formGroup]="form">
            <div class="ui-g-12 ui-g-nopad">
              <sc-quick-date-select
                [initMinDate]="fromDate"
                [initMaxDate]="toDate"
                initInterval="hour"
                [selectInterval]="true"
                [displayInVertical]="true"
                [showLabel]="true"
                (valueChange)="onDateChanged($event)"
              >
              </sc-quick-date-select>
            </div>

            <div class="ui-g-12">
              <label for="chartType" class="sc-form-label">
                {{ "CHART_TYPE" | translate }}
              </label>

              <p-dropdown
                inputId="chartType"
                formControlName="chartType"
                [options]="selectItems?.chartTypes"
                placeholder="{{ 'SELECT' | translate }} "
                [style]="{ width: '100%' }"
                appendTo="body"
              ></p-dropdown>
            </div>

            <div class="ui-g-12">
              <p-checkbox
                formControlName="isCombine"
                binary="true"
                label="{{ 'COMBINED_VIEW' | translate }}"
              ></p-checkbox>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>

      <!-- <div class="sc-horizontal-line"></div> -->

      <div class="text-center mt-3">
        <button
          pButton
          type="button"
          class="ui-button-success"
          label="{{ 'ANALYSE' | translate }}!"
          (click)="onSubmit()"
          tooltipPosition="top"
          appendTo="body"
        ></button>
      </div>
    </div>

    <div class="ui-g-12 ui-md-8">
      <ng-container *ngIf="charts?.length">
        <!-- <div class="ui-g">
            <div class="ui-g-12 ui-md-6">
              <button
                pButton
                type="button"
                [ngClass]="changeViewBtnClass('line')"
                icon="fa fa-fw fa-line-chart"
                (click)="onViewChanged('line')"
              ></button>

              <button
                pButton
                type="button"
                [ngClass]="changeViewBtnClass('bar')"
                icon="fa fa-fw fa-bar-chart"
                (click)="onViewChanged('bar')"
              ></button>
            </div>
          </div> -->
        <div class="ui-g">
          <div
            *ngFor="let c of charts; let i = index"
            class="ui-g-12 ui-g-nopad"
          >
            <plotly-plot
              [data]="c.data"
              [layout]="c.layout"
              [config]="{ displayModeBar: false }"
            ></plotly-plot>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!charts && isFetchingDeviceHistory">
        <sc-loading-indicator></sc-loading-indicator>
      </ng-container>
    </div>
  </div>
</div>
