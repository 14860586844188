import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';

import { SCQuickDateSelectComponent } from './quick-date-select.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CalendarModule, DropdownModule, TranslateModule.forChild()],
  exports: [SCQuickDateSelectComponent],
  declarations: [SCQuickDateSelectComponent],
  providers: [],
})
export class SCQuickDateSelectModule {}
