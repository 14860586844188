import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { config } from '@app/config';
import { SharedService } from '@shared/shared.service';

@Injectable()
export class SiteFormsService {
  patterns = config().regexPatterns;

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService) {}

  // site form
  initSiteForm(wizard = false): FormGroup {
    const fields: any = {
      id: { value: null, disabled: true },
      companyId: [{ value: null, disabled: true }, Validators.required],
      siteTypeId: [null, Validators.required],
      name: [null, Validators.required],
      category: null,
      subCategory: null,
      timezone: [null, Validators.required],
      currency: null,
      description: null,
      email: null,
      fax: [null, Validators.pattern(this.patterns.phone)],
      phone: [null, Validators.pattern(this.patterns.phone)],
      allowsIncomingRequests: false,
      isRaining: false,
      isActive: false,
      isDeleted: false,
      lat: null,
      lng: null,
      ip: null,
      port: null,
      localIp: null,
      localPort: null,
      website: null,
      users: null,
      currentOutdoorHumidity: null,
      currentOutdoorTemperature: null,
      maxAcceptableConsumption: null,
      maxAcceptableHumidity: null,
      maxAcceptableTemperature: null,
      gatewayDefaultTimeout: null,
      hlSenderInterval: null,
      nhlSenderInterval: null,
      ruleCommandExecutorTimeout: null,
      configFetchInterval: null,
      occupancyDelay: null,
      occupancyTimeout: null,
      isDeviceAlertEnabled: false,
      isDeviceAlertEmailEnabled: { value: null, disabled: true },
      isDeviceAlertSmsEnabled: { value: null, disabled: true },
      deviceAlertAutomationTimeout: { value: null, disabled: true },
      deviceAlertGatewayTimeout: { value: null, disabled: true },
      deviceAlertSmsReceivers: { value: null, disabled: true },
      deviceAlertEmailReceivers: { value: null, disabled: true },
      acValveAlertDiffPoint: { value: null, disabled: true },
      acValveAlertTimeout: { value: null, disabled: true },
      batteryLevelWarning: { value: null, disabled: true },
      batteryLevelCrucial: { value: null, disabled: true },
      street: null,
      district: null,
      city: null,
      country: null,
      postalCode: null,
      locale: this.sharedService.userLocale,
    };
    if (wizard === true) {
      fields.street = [null, Validators.required];
      fields.district = [null, Validators.required];
      fields.city = [null, Validators.required];
      fields.country = [null, Validators.required];
      fields.postalCode = [null, Validators.required];
      fields.locale = [this.sharedService.userLocale, Validators.required];
    }
    return this.formBuilder.group(fields);
  }

  initFloorForm(): FormGroup {
    return this.formBuilder.group({
      totalFloor: [null, [Validators.required, Validators.min(1)]],
      floors: this.formBuilder.array([], Validators.required),
    });
  }

  initFloorDetailsForm(id: number): FormGroup {
    const name =
      id === 0
        ? this.sharedService.getTranslation('GROUND_FLOOR')
        : `${this.sharedService.getTranslation('FLOOR')} ${id}`;
    return this.formBuilder.group({
      id: [id, Validators.required],
      name: [name, Validators.required],
    });
  }
}
