<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'AVERAGE_TEMPERATURE' | translate }} -->
        {{ widgetName }}
      </h4>

      <!-- <div class="sc-widget-menu"></div> -->
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div *ngIf="widgetData" class="sc-widget-body direction-row">
    <div *ngFor="let wd of widgetData" class="sc-average-content">
      <sc-circle
        [customClass]="wd.customClass"
        [customStyle]="wd.customStyle"
        [icon]="wd.icon"
        [primaryText]="wd.primaryText"
        [secondaryText]="wd.secondaryText"
        [size]="wd.size"
      ></sc-circle>
      <div *ngIf="wd.label" class="text-center mb-1 sc-average-label">
        {{ wd.label | translate: wd.labelParams }}
      </div>
    </div>
  </div>
</ng-template>
