import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-text-editor-input',
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
        [description]="description"
        [warning]="warning"
      ></sc-field-label>

      <p-editor [formControlName]="controlName" [placeholder]="placeholder"></p-editor>

      <sc-field-error [for]="label" [fieldControl]="form.controls[controlName]"></sc-field-error>
    </div>
  `,
})
export class SCTextEditorInputComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  warning: string;
  @Input()
  placeholder: string;

  constructor() {}

  ngOnInit() {
    this.placeholder = this.placeholder || '';
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
  }
}
