import { Component, OnInit, Input } from '@angular/core';

import { SceneTemplate } from '../models/scene-template';
import { ModalConfig } from '@sc/modal/modal-config';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromStore from '@app/store';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { SceneTemplateActionCellComponent } from '../scene-template-action-cell/scene-template-action-cell.component';

@Component({
  selector: 'sc-scene-templates-table',
  templateUrl: 'scene-templates-table.component.html',
})
export class SceneTemplatesTableComponent implements OnInit {
  @Input()
  config: any;

  dataset$: Observable<SceneTemplate[]>;
  columns: any[];
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: SceneTemplateActionCellComponent,
      },
    ];
    this.formConfig = {
      name: 'SceneTemplateForm',
      options: { modalTitle: 'SCENE_TEMPLATE_FORM_TITLE' },
    };
    this.tableTitle = 'SCENE_TEMPLATES';
    this.dataset$ = this.store.select(fromStore.getSceneTemplates);

    this.fetchDataset();
  }

  fetchDataset() {
    this.store.dispatch(new fromStore.LoadSceneTemplates());
  }
}
