<p-panel [toggleable]="true" styleClass="mb-2">
  <p-header>
    {{ 'RULE' | translate }}#{{ formId + 1 }} - {{ form.value?.name }}
  </p-header>

  <div class="ui-g">
    <div class="ui-g-4">
      <sc-checkbox-input
        controlName="isActive"
        [form]="form"
        label="{{ 'ACTIVE' | translate }}"
      ></sc-checkbox-input>
    </div>

    <div class="ui-g-4 ui-g-offset-4" *ngIf="applyAll">
      <p-checkbox
        [formControl]="applyAll"
        binary="true"
        label="{{ 'APPLY_ALL' | translate }}"
      ></p-checkbox>
    </div>
  </div>

  <div [formGroup]="form" class="ui-g">
    <!-- START CONDITIONS -->
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g">
        <div class="ui-g-10">
          <h3 class="m-0">{{ 'RULE_START_CONDITIONS' | translate }}</h3>
        </div>
      </div>

      <div class="ui-g">
        <!-- CONNECTOR FIELD -->
        <div class="ui-g-1">
          <h4 class="m-0">{{ 'CONNECTOR' | translate }}</h4>
        </div>

        <!-- SOURCE -->
        <div class="ui-g-3">
          <h4 class="m-0">{{ 'SOURCE' | translate }}</h4>
        </div>

        <!-- OPERATOR -->
        <div class="ui-g-1">
          <h4 class="m-0">{{ 'OPERATOR' | translate }}</h4>
        </div>

        <!-- REFERENCED -->
        <div class="ui-g-3">
          <h4 class="m-0">{{ 'REFERENCED' | translate }}</h4>
        </div>
      </div>

      <ng-container formArrayName="startConditions">
        <ng-container
          *ngFor="
            let startCondition of startConditionForms.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <sc-rule-condition-form
            [form]="startCondition"
            [formId]="i"
            [data]="data"
          ></sc-rule-condition-form>
        </ng-container>
      </ng-container>
      <!-- <sc-rule-condition-form
        [form]="form"
        [config]="{ isEndCondtion: false, title: 'START_CONDITIONS' }"
        [data]="{}"
      ></sc-rule-condition-form> -->
    </div>

    <!-- START COMMANDS -->
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g">
        <div class="ui-g-10">
          <h3 class="m-0">{{ 'RULE_START_COMMANDS' | translate }}</h3>
        </div>
      </div>

      <div class="ui-g">
        <!-- SOURCE -->
        <div class="ui-g-3">
          <h4 class="m-0">{{ 'SOURCE' | translate }}</h4>
        </div>

        <!-- ACTION -->
        <div class="ui-g-3">
          <h4 class="m-0">{{ 'ACTION' | translate }}</h4>
        </div>

        <!-- VALUE -->
        <div class="ui-g-6">
          <h4 class="m-0">{{ 'VALUE' | translate }}</h4>
        </div>
      </div>

      <ng-container formArrayName="startCommands">
        <ng-container
          *ngFor="let startCommand of startCommandForms.controls; let i = index"
          [formGroupName]="i"
        >
          <sc-rule-command-form
            [form]="startCommand"
            [formId]="i"
            [data]="data"
          ></sc-rule-command-form>
        </ng-container>
      </ng-container>
      <!-- <sc-rule-command-form
        [form]="form"
        [config]="{ isEndCommand: false, title: 'START_COMMANDS' }"
        [data]="{}"
      ></sc-rule-command-form> -->
    </div>

    <div class="sc-horizontal-line"></div>

    <!-- END CONDITIONS -->
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g">
        <div class="ui-g-10">
          <h3 class="m-0">{{ 'RULE_END_CONDITIONS' | translate }}</h3>
        </div>
      </div>

      <div class="ui-g">
        <!-- CONNECTOR FIELD -->
        <div class="ui-g-1">
          <h4 class="m-0">{{ 'CONNECTOR' | translate }}</h4>
        </div>

        <!-- SOURCE -->
        <div class="ui-g-3">
          <h4 class="m-0">{{ 'SOURCE' | translate }}</h4>
        </div>

        <!-- OPERATOR -->
        <div class="ui-g-1">
          <h4 class="m-0">{{ 'OPERATOR' | translate }}</h4>
        </div>

        <!-- REFERENCED -->
        <div class="ui-g-3">
          <h4 class="m-0">{{ 'REFERENCED' | translate }}</h4>
        </div>
      </div>

      <ng-container formArrayName="endConditions">
        <ng-container
          *ngFor="let endCondition of endConditionForms.controls; let i = index"
          [formGroupName]="i"
        >
          <sc-rule-condition-form
            [form]="endCondition"
            [formId]="i"
            [data]="data"
          ></sc-rule-condition-form>
        </ng-container>
      </ng-container>
      <!-- <sc-rule-condition-form
        [form]="form"
        [config]="{ isEndCondtion: true, title: 'END_CONDITIONS' }"
        [data]="{}"
      ></sc-rule-condition-form> -->
    </div>

    <!-- END COMMANDS -->
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g">
        <div class="ui-g-10">
          <h3 class="m-0">{{ 'RULE_END_COMMANDS' | translate }}</h3>
        </div>
      </div>

      <div class="ui-g">
        <!-- SOURCE -->
        <div class="ui-g-3">
          <h4 class="m-0">{{ 'SOURCE' | translate }}</h4>
        </div>

        <!-- ACTION -->
        <div class="ui-g-3">
          <h4 class="m-0">{{ 'ACTION' | translate }}</h4>
        </div>

        <!-- VALUE -->
        <div class="ui-g-6">
          <h4 class="m-0">{{ 'VALUE' | translate }}</h4>
        </div>
      </div>

      <ng-container formArrayName="endCommands">
        <ng-container
          *ngFor="let endCommand of endCommandForms.controls; let i = index"
          [formGroupName]="i"
        >
          <sc-rule-command-form
            [form]="endCommand"
            [formId]="i"
            [data]="data"
          ></sc-rule-command-form>
        </ng-container>
      </ng-container>
      <!-- <sc-rule-command-form
        [form]="form"
        [config]="{ isEndCommand: true, title: 'END_COMMANDS' }"
        [data]="{}"
      ></sc-rule-command-form> -->
    </div>
  </div>
</p-panel>
