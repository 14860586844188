import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { DeviceProtocolsService } from '../services/device-protocols.service';
import { SharedService } from '@shared/shared.service';

// import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
// import { DependencyWarningComponent } from '@widgets/dependency-warning/dependency-warning.component';

@Component({
  selector: 'sc-device-protocol-action-cell',
  templateUrl: './device-protocol-action-cell.component.html'
})
export class DeviceProtocolActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  private subscribers: { [key: string]: any } = {};
  // private warningDialog: DynamicDialogRef;

  constructor(
    private deviceProtocolsService: DeviceProtocolsService,
    private sharedService: SharedService,
    // private dialogService: DialogService
  ) { }

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'DeviceProtocolForm',
      data: this.cell.data,
      options: { modalTitle: 'DEVICE_PROTOCOL_FORM_TITLE' }
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: '_u',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this)
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this)
      }
    ];
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow(force = false) {
    this.subscribers.delete = this.deviceProtocolsService
      .deleteDeviceProtocol(this.cell.data.id, force)
      .subscribe({
        next: (result: any) => {
          if (result.warning) {
            // this.deleteWarningHandler(result.warning);
            // this.sharedService.deleteWarningHandler(
            //   this.cell.data,
            //   result.warning,
            //   this.deleteRow.bind(this)
            // );
            this.subscribers.delete = this.sharedService
              .deleteWarningHandler(this.cell.data, result.warning)
              .subscribe((confirm: any) => {
                if (confirm === true) {
                  this.deleteRow(true);
                }
              });
          } else {
            this.cell.context.parentComponent.updateRow({
              id: this.cell.data.id,
              isActive: false,
              isDeleted: true
            });
            const text = this.sharedService.getTranslation('DELETE_SUCCESS');
            const title = this.sharedService.getTranslation('DEVICE_PROTOCOL');
            this.sharedService.notify(title, text, 'success');
          }
        },
        error: (error: any) => {
          const text = this.sharedService.getTranslation('DELETE_FAIL');
          const title = this.sharedService.getTranslation('DEVICE_PROTOCOL');
          this.sharedService.notify(title, text, 'error');
        }
      });
  }

  // private deleteWarningHandler(dependencies) {
  //   this.warningDialog = this.dialogService.open(DependencyWarningComponent, {
  //     header: this.sharedService.getTranslation('DEPENDENCY_WARNING_TITLE'),
  //     data: { source: this.cell.data, dependencies }
  //   });

  //   this.warningDialog.onClose.subscribe((result: any) => {
  //     if (result === true) {
  //       this.deleteRow(true);
  //     }
  //   });
  // }
}
