<div
  class="confirm-trigger"
  [ngClass]="{ inline: displayInline }"
  [pTooltip]="tooltipContent"
  appendTo="body"
  [tooltipPosition]="tooltipPosition"
  [escape]="tooltipEscape"
>
  <!-- custom trigger -->
  <div #ref class="custom-trigger" (click)="clickHandler()">
    <ng-content></ng-content>
  </div>

  <!-- default trigger -->
  <ng-container *ngIf="ref?.children?.length == 0">
    <button
      pButton
      type="button"
      [class]="buttonClass"
      [icon]="buttonIcon"
      [label]="buttonLabel"
      [disabled]="disabled"
      (click)="clickHandler()"
    ></button>
  </ng-container>
</div>
