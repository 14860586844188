<div class="modal">
  <div class="modal__header">
    <div class="modal__header__label">
      <div class="modal__header__title">
        {{ modalTitle | translate }}
      </div>
      <div class="modal__header__description">
        {{ modalDescription | translate }}
      </div>
    </div>

    <div class="modal__header__action">
      <sc-close-button (clicked)="close()"></sc-close-button>
    </div>
  </div>

  <div class="modal__body">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="1">
        <sc-list-select
          [items]="commandTypes"
          (selectItem)="selectCommandType($event)"
        ></sc-list-select>
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <ng-container
          *ngTemplateOutlet="step2; context: step2Context"
        ></ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="stepError"></ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal__footer" *ngIf="step !== 1">
    <!-- *ngIf="isTemplate" -->
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="onResetClick()"
      [label]="'CCP_COMMON_RESET' | translate"
      *ngIf="isTemplate || isFromScratch"
    ></button>
    &nbsp;
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="onOkClick()"
      [label]="'CCP_COMMON_OK' | translate"
      [disabled]="form?.invalid"
    ></button>
  </div>
</div>

<ng-template #step2 let-type>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="_commandTypes.TriggerDevice">
      <sc-trigger-device
        [commandForm]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
        [isFromScratch]="isFromScratch"
        [templateCommands]="templateCommands"
      ></sc-trigger-device>
    </ng-container>

    <ng-container *ngSwitchCase="_commandTypes.DeviceValueStorage">
      <sc-device-value-storage
        [commandForm]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-device-value-storage>
    </ng-container>

    <ng-container *ngSwitchCase="_commandTypes.RuleBlocker">
      <sc-rule-blocker
        [commandForm]="form"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-rule-blocker>
    </ng-container>

    <ng-container *ngSwitchCase="_commandTypes.TaskQueue">
      <sc-task-queue
        [commandForm]="form"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-task-queue>
    </ng-container>

    <ng-container *ngSwitchCase="_commandTypes.CustomGlobal">
      <sc-custom-global
        [commandForm]="form"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-custom-global>
    </ng-container>

    <ng-container *ngSwitchCase="_commandTypes.Notifier">
      <sc-notifier-command
        [commandForm]="form"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-notifier-command>
    </ng-container>

    <ng-container *ngSwitchCase="_commandTypes.Report">
      <sc-report-command
        [commandForm]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-report-command>
    </ng-container>

    <ng-container *ngSwitchCase="_commandTypes.UserTask">
      <sc-user-task-command
        [commandForm]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-user-task-command>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #stepError>
  {{ "CCP_COMMON_SOMETHING_WENT_WRONG" | translate }}
  <a class="clickable" (click)="goBack()">{{
    "CCP_COMMON_BACK" | translate
  }}</a>
</ng-template>
