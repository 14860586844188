import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-translation-value-form',
  templateUrl: 'value-form.component.html'
})
export class ValueFormComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;

  constructor() {}

  ngOnInit() {}

  getFlag() {
    let locale = this.group.value.locale;
    switch (locale) {
      case 'en':
        locale = 'gb';
        break;
      default:
        break;
    }
    return 'flag-icon-' + locale;
  }
}
