<div [formGroup]="group" class="ui-g">
  <div class="ui-g-5">
    <p-dropdown
      [options]="optionData"
      placeholder="{{ 'SELECT' | translate }} {{ 'LOCATION' | translate }}"
      formControlName="locationId"
      filter="true"
      [style]="{'width':'100%'}"
    ></p-dropdown>
  </div>

  <div class="ui-g-5">
    <p-checkbox
      binary="true"
      label="{{ 'APPLY_TO_CHILDREN' | translate }}"
      [formControl]="group.get('applyToChildren')"
    ></p-checkbox>
  </div>

  <div class="ui-g-2">
    <button
      pButton
      type="button"
      class="ui-button-warning"
      (click)="remove()"
      icon="fa fa-trash"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'LOCATION' | translate }}">
    </button>
  </div>
</div>
