import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '@shared/shared.service';
import { NotificationsService } from '../notifications.service';
import * as moment from 'moment';

@Component({
  selector: 'notification-detail',
  templateUrl: 'notification-detail.component.html',
})
export class NotificationDetailComponent implements OnInit {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  isSubmitting: boolean;
  reasonForm: FormGroup;
  showReasonInput: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.markAsRead();
    this.reasonForm = this.formBuilder.group({
      reason: ['', Validators.required],
    });
  }

  // timeFormat(date) {
  //   console.log(date);
  //   return moment(date).fromNow();
  // }

  priorityColor() {
    if (this.data.priority >= 90) {
      return 'red';
    } else if (this.data.priority >= 70) {
      return 'orange';
    } else if (this.data.priority >= 50) {
      return 'yellow';
    } else if (this.data.priority >= 10) {
      return 'green';
    } else {
      return 'grey';
    }
  }

  markAsRead() {
    if (typeof this.data.readAt === 'undefined') {
      this.notificationsService.read(this.data.userId, this.data.id).subscribe((result) => {});
    }
  }

  showActionArea() {
    if (this.data.unsolvableAt || this.data.solvedAt) {
      return false;
    } else {
      return true;
    }
  }

  unsolvable() {
    this.showReasonInput = true;
  }

  solved() {
    this.isSubmitting = true;
    this.notificationsService
      .solved(this.data.userId, this.data.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  unsolved() {
    if (this.reasonForm.valid) {
      this.isSubmitting = true;
      this.notificationsService
        .unsolved(this.data.userId, this.data.id, this.reasonForm.value.reason)
        .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
    } else {
      this.reasonForm.get('reason').markAsTouched();
    }
  }

  apiCallSuccess(result: any) {
    // show notification && close modal
    this.onClose.emit();
    this.sharedService.notify('Notification', 'Mark success!', 'success');
  }

  apiCallError(error: any) {
    // display error message and unlock the the button
    this.sharedService.notify('Notification', 'Mark fail!', 'error');
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
