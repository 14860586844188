import * as fromCompanies from '../actions/companies.action';
import { Company } from '@widgets/companies/models/company';

export interface CompanyState {
  entities: { [id: number]: Company };
  loaded: boolean;
  loading: boolean;
  formData: Company;
  formDataLoading: boolean;
  formDataError: any;
  pending: boolean;
  pendingError: any;
}

export const initialState = {
  entities: {},
  loaded: false,
  loading: false,
  formData: null,
  formDataLoading: null,
  formDataError: null,
  pending: false,
  pendingError: null,
};

export function reducer(state = initialState, action: fromCompanies.CompaniesAction): CompanyState {
  switch (action.type) {
    // LOAD COMPANY
    case fromCompanies.LOAD_COMPANY: {
      return {
        ...state,
        formDataLoading: true,
      };
    }

    case fromCompanies.LOAD_COMPANY_FAIL: {
      const formDataError = action.payload;
      return {
        ...state,
        formDataLoading: false,
        formDataError,
        formData: null,
      };
    }

    case fromCompanies.LOAD_COMPANY_SUCCESS: {
      const formData = action.payload;
      return {
        ...state,
        formDataLoading: false,
        formDataError: null,
        formData,
      };
    }

    // LOAD COMPANIES
    case fromCompanies.LOAD_COMPANIES: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromCompanies.LOAD_COMPANIES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromCompanies.LOAD_COMPANIES_SUCCESS: {
      const companies = action.payload;
      const entities = companies.reduce((tmpEntities: { [id: number]: Company }, company: Company) => {
        return {
          ...tmpEntities,
          [company.id]: company,
        };
      }, {});
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    // CREATE / UPDATE COMPANY
    case fromCompanies.CREATE_COMPANY:
    case fromCompanies.UPDATE_COMPANY:
    case fromCompanies.DELETE_COMPANY: {
      return {
        ...state,
        pending: true,
      };
    }

    case fromCompanies.CREATE_COMPANY_FAIL:
    case fromCompanies.UPDATE_COMPANY_FAIL:
    case fromCompanies.DELETE_COMPANY: {
      const pendingError = action.payload;
      return {
        ...state,
        pending: false,
        pendingError,
      };
    }

    case fromCompanies.CREATE_COMPANY_SUCCESS:
    case fromCompanies.UPDATE_COMPANY_SUCCESS: {
      const company = action.payload;
      const entities = {
        ...state.entities,
        [company.id]: company,
      };

      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
      };
    }

    // DELETE COMPANY
    case fromCompanies.DELETE_COMPANY_SUCCESS: {
      const company = action.payload;
      const entities = {
        ...state.entities,
        [company.id]: {
          ...company,
          isActive: false,
          isDeleted: true,
        },
      };
      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
      };
    }

    // RESET PENDING
    case fromCompanies.COMPANY_RESET_PENDING_STATE: {
      return {
        ...state,
        pending: false,
        pendingError: null,
        formData: null,
      };
    }
  }
  return state;
}
