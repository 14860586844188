import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-al-message-cell',
  templateUrl: 'al-message-cell.component.html'
})
export class AutomationLogsMessageCellComponent
  implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }

  get cellData() {
    if (this.cell && this.cell.data) {
      return this.cell.data;
    }
    return {};
  }
}
