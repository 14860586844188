import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocalWeatherComponent } from './local-weather.component';
import { LocalWeatherService } from './local-weather.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocalWeatherComponent],
  entryComponents: [LocalWeatherComponent],
  providers: [LocalWeatherService]
})
export class LocalWeatherModule {}
