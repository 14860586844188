import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectItem } from 'primeng/api';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { DeviceWizard } from '../device-wizard';
import { Device as DeviceDefault } from '@widgets/devices/models/device';
import { Location } from '@widgets/locations/models/location';
import { ProtocolController } from '@widgets/devices/models/protocol-controller';
import { Site } from '@widgets/sites/models/site';

import { DeviceHistoryLogsService } from '@widgets/devices/services/device-history-logs.service';
import { DeviceFormsService } from '@widgets/devices/services/device-forms.service';
import { DeviceWizardService } from '../device-wizard.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { SharedService } from '@shared/shared.service';

import { config as appConfig } from '@app/config';

interface Device extends DeviceDefault, DeviceWizard {
  unit?: string;
  value?: any;
  valueLabel?: string;
  virtualDevices?: Device[];
}

@Component({
  selector: 'sc-update-wizard',
  templateUrl: './update-wizard.component.html',
  styleUrls: ['./update-wizard.component.scss']
})
export class UpdateWizardComponent implements OnInit, OnDestroy {
  @ViewChild('updateWizardContent', { static: true }) public updateWizardContent;

  errorMessage: null;
  form: FormArray;
  formData: Device[];
  isFetchingDHL = false;
  isSubmitting = false;
  locationDevices: Device[];
  locationsToControl: Location[];
  productImage: string;
  selectItems: { [prop: string]: SelectItem[] } = {};
  selectedAutomation: Device;
  selectedDevice: Device;
  selectedLocation: Location;
  summarySource: { [prop: string]: any };
  totalStep = 3;
  wizardStep = 0;

  private automations: Device[] = [];
  private deviceModels: DeviceModel[] = [];
  private deviceProtocols: DeviceProtocol[] = [];
  private deviceTypes: DeviceType[] = [];
  private deviceLatestHistory: any[] = [];
  private protocolControllers: ProtocolController[] = [];
  private locations: Location[] = [];
  private intervals: { [prop: string]: any } = {};
  private siteDevices: Device[] = [];
  private selectedSite: Site;
  private subscribers: { [prop: string]: any } = {};
  private sourceDevice: Device;

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private deviceFormsService: DeviceFormsService,
    private deviceWizardService: DeviceWizardService,
    private devicesService: DevicesService,
    private locationsService: LocationsService,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;
    this.selectItems.zigbeeControllerPorts = this.sharedService.selectItems.zigbeeControllerPorts;
    this.selectItems.zwaveControllerPorts = this.sharedService.selectItems.zwaveControllerPorts;

    if (this.config && this.config.data) {
      if (this.config.data.device) {
        this.sourceDevice = this.config.data.device;
        this.fetchVirtualDevice(this.sourceDevice.id);
      }

      if (this.config.data.deviceModels) {
        this.deviceModels = this.config.data.deviceModels;
      }

      if (this.config.data.deviceTypes) {
        this.deviceTypes  = this.config.data.deviceTypes;
      }

      if (this.config.data.deviceProtocols) {
        this.deviceProtocols  = this.config.data.deviceProtocols;
      }

      if (this.config.data.protocolControllers) {
        this.protocolControllers = this.config.data.protocolControllers;
        this.selectItems.zwaveControllerSoftwares = this.sharedService.createSelectItems(
          this.protocolControllers.filter(d => d.isActive && !d.isDeleted)
        );
      }
    }

    this.fetchLocations();
    this.fetchSiteDevices();
    this.fetchLatestHistory();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchVirtualDevice(id: number) {
    // Virtual Holder device + Virtual devices
    this.subscribers.fetchDevice = this.devicesService
      .getDevice(id, true)
      .subscribe({
        next: (result: any) => {
          this.selectedDevice = result.data;
          this.patchSelectedDevice();
          this.createDevicesForm();

          if (this.selectedDevice.deviceTypeKey === 'vdh') {
            this.updateSelectedAutomation(this.selectedDevice.automationId);
          } else if (this.selectedDevice.deviceTypeKey === 'a') {
            this.updateLocationsToControl(this.selectedDevice.locationsToControl);
          }
          this.updateLocation(this.selectedDevice.locationId);

          this.wizardStep = 1;
        }
      });
  }

  private fetchLocations() {
    const options = {
      siteId: this.selectedSite.id,
      columns: 'id,description,floorplanImage,isActive,isDeleted'
    };
    this.subscribers.fetchLocations = this.locationsService
      .getLocations(options)
      .subscribe({
        next: (result: any) => {
          this.locations = result.data;
          this.selectItems.locations = this.sharedService.createSelectItems(
            result.data.filter(d => !d.isDeleted)
          );
          this.selectItems.unitLocations = this.sharedService.createSelectItems(
            result.data.filter(d => !d.isDeleted && d.locationTypeKey === 'u')
          );

          if (this.selectedDevice) {
            this.updateLocation(this.selectedDevice.locationId);

            if (this.selectedDevice.deviceTypeKey === 'a') {
              this.updateLocationsToControl(this.selectedDevice.locationsToControl);
            }
          }
        }
      });
  }

  private fetchSiteDevices() {
    const options = {
      siteId: this.selectedSite.id,
      // deviceTypeKey: 'a',
      columns: 'id,description,locationId,instructionPositionX,instructionPositionY,virtualDeviceHolderId,isActive,isDeleted'
    };
    this.subscribers.getDevices = this.devicesService
      .getDevices(options)
      .subscribe({
        next: (result: any) => {
          this.automations = [];
          this.siteDevices = [];

          for (const device of result.data) {
            if (device.isDeleted || !device.isActive) {
              continue;
            }

            if (device.deviceTypeKey === 'a') {
              this.automations.push(device);
            }
            this.siteDevices.push(device);
          }

          this.selectItems.automations = this.sharedService.createSelectItems(this.automations);

          if (this.selectedDevice) {
            if (this.selectedDevice.deviceTypeKey === 'vdh') {
              this.updateSelectedAutomation(this.selectedDevice.automationId);
            }
            this.updateLocationDevices(this.selectedDevice.locationId);
          }
        }
      });
  }

  private fetchLatestHistory() {
    if (this.isFetchingDHL) {
      return;
    }
    this.isFetchingDHL = true;
    const options: any = {
      filter: {
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id
      },
      latestLog: true,
      limit: 5000
    };
    if (this.selectedLocation && this.selectedLocation.id) {
      options.filter.locationId = this.selectedLocation.id;
    }
    this.subscribers.getLatestHistory = this.deviceHistoryLogsService
      .getHistoryLogs(options)
      .subscribe((result: any) => {
        this.deviceLatestHistory = result;
        this.patchVirtualDeviceValues();
        this.isFetchingDHL = false;
      });
  }

  private startAutoUpdate() {
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = setInterval(
      () => this.fetchLatestHistory(),
      10000
    );
  }

  private stopAutoUpdate() {
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = null;
  }

  private resetScroll() {
    this.updateWizardContent.nativeElement.scrollTop = 0;
  }

  private parseDeviceValues(data: Device) {
    // convert string into object
    if (typeof data.locationsToControl === 'string') {
      data.locationsToControl = JSON.parse(data.locationsToControl);
    }

    // convert seconds into milliseconds
    if (typeof data.configFetchInterval === 'number') {
      data.configFetchInterval = this.sharedService.numberFormat(data.configFetchInterval / 1000);
    }

    // convert seconds into milliseconds
    if (typeof data.hlSenderInterval === 'number') {
      data.hlSenderInterval = this.sharedService.numberFormat(data.hlSenderInterval / 1000);
    }

    return data;
  }

  private unparseDeviceValues(data: Device) {
    // convert seconds into milliseconds
    if (typeof data.configFetchInterval === 'number') {
      data.configFetchInterval = this.sharedService.numberFormat(data.configFetchInterval * 1000);
    }

    // convert seconds into milliseconds
    if (typeof data.hlSenderInterval === 'number') {
      data.hlSenderInterval = this.sharedService.numberFormat(data.hlSenderInterval * 1000);
    }

    return data;
  }

  private patchDeviceModel(data: Device) {
    const model = this.deviceModels.find(d => d.id === data.deviceModelId);
    if (model) {
      data.modelName = model.name;
      data.model = model.model;

      if (model.productImage) {
        this.productImage = appConfig().s3Url + '/' + model.productImage;
        data.productImage = this.productImage;
      }
    }
    return data;
  }

  private patchDeviceType(data: Device) {
    const type = this.deviceTypes.find(d => d.id === data.deviceTypeId);
    if (type) {
      data.deviceTypeName = type.name;
    }
    return data;
  }

  private patchDeviceProtocol(data: Device) {
    const protocol = this.deviceProtocols.find(d => d.id === data.protocolId);
    if (protocol) {
      data.protocolName = protocol.name;
    }
    return data;
  }

  private patchSelectedDevice() {
    if (!this.selectedDevice) {
      return;
    }

    this.parseDeviceValues(this.selectedDevice);
    this.patchDeviceModel(this.selectedDevice);
    this.patchDeviceProtocol(this.selectedDevice);
    this.patchDeviceType(this.selectedDevice);

    if (this.selectedDevice.virtualDevices && this.selectedDevice.virtualDevices.length) {
      for (const vd of this.selectedDevice.virtualDevices) {
        this.parseDeviceValues(vd);
        this.patchDeviceProtocol(vd);
        this.patchDeviceType(vd);
        this.patchDeviceModel(vd);
      }
    }
  }

  private patchVirtualDeviceValues() {
    if (!this.locationDevices || !this.locationDevices.length || !this.deviceLatestHistory) {
      return;
    }

    // patch other devices
    for (const d of this.locationDevices) {
      if (!d.virtualDevices || d.deviceTypeKey === 'a') {
        continue;
      }

      for (const vd of d.virtualDevices) {
        if (!vd.virtualDeviceHolderId) {
          continue;
        }

        vd.unit = this.devicesService.deviceValueUnit(vd.deviceTypeKey);
        vd.value = '-';
        vd.valueLabel = this.devicesService.deviceValueLabel(vd.deviceTypeKey);

        const latestValue = this.deviceLatestHistory.find(lh => vd.id + '' === lh.deviceId + '');
        if (latestValue) {
          const model = this.deviceModels.find(dm => dm.id + '' === vd.deviceModelId + '');
          vd.value = this.devicesService.deviceValueMapper(latestValue.value, model);
        }
      }

      d.virtualDevices = this.sharedService.sortBy(d.virtualDevices, 'valueLabel');
    }

    // patch selected device
    if (this.selectedDevice.deviceTypeKey === 'vdh') {
      for (const vd of this.selectedDevice.virtualDevices) {
        if (!vd.virtualDeviceHolderId) {
          continue;
        }

        vd.unit = this.devicesService.deviceValueUnit(vd.deviceTypeKey);
        vd.value = '-';
        vd.valueLabel = this.devicesService.deviceValueLabel(vd.deviceTypeKey);

        const latestValue = this.deviceLatestHistory.find(lh => vd.id + '' === lh.deviceId + '');
        if (latestValue) {
          const model = this.deviceModels.find(dm => dm.id + '' === vd.deviceModelId + '');
          vd.value = this.devicesService.deviceValueMapper(latestValue.value, model);
        }
      }
      this.selectedDevice.virtualDevices = this.sharedService.sortBy(this.selectedDevice.virtualDevices, 'valueLabel');
    }
  }

  private updateLocation(locationId: number) {
    if (this.locations && this.locations.length) {
      this.selectedLocation = this.locations.find(l => l.id === locationId);
    }
    this.updateLocationDevices(locationId);
  }

  private updateLocationDevices(locationId: number) {
    if (this.siteDevices && this.siteDevices.length) {
      // this.locationDevices = this.siteDevices.filter(d =>
      //   d.locationId === locationId &&
      //   d.id !== this.selectedDevice.id &&
      //   (
      //     d.deviceTypeKey === 'vdh' ||
      //     d.deviceTypeKey === 'a'
      //   )
      // );


      // this.locationDevices = [];
      const tmpLocationDevices = {};
      const tmpVirtualDevices = [];

      for (const d of this.siteDevices) {
        if (d.locationId !== locationId || d.id === this.selectedDevice.id) {
          continue;
        }

        if (d.deviceTypeKey === 'vdh' || d.deviceTypeKey === 'a' ) {
          tmpLocationDevices[d.id] = d;

          if (d.deviceTypeKey === 'vdh') {
            tmpLocationDevices[d.id].virtualDevices = [];
          }
        } else {
          tmpVirtualDevices.push(d);
        }
      }

      for (const d of tmpVirtualDevices) {
        if (d.virtualDeviceHolderId && tmpLocationDevices[d.virtualDeviceHolderId]) {
          tmpLocationDevices[d.virtualDeviceHolderId].virtualDevices.push(d);
        }
      }

      this.locationDevices = Object.keys(tmpLocationDevices).map(id => tmpLocationDevices[id]);
      this.patchVirtualDeviceValues();
    }
  }

  private updateSelectedAutomation(deviceId: number) {
    if (this.automations && this.automations.length) {
      this.selectedAutomation = this.automations.find(a => a.id === deviceId);
    }
  }

  private updateLocationsToControl(locationIds: number[]) {
    if (this.locations && this.locations.length && locationIds && locationIds.length) {
      this.locationsToControl = this.locations.filter(l => locationIds.indexOf(l.id) >= 0);
    }
  }

  private patchVirtualDevices(key: string, value: any) {
    const controls = this.form.controls;
    if (controls.length > 1) {
      for (let i = 1; i < controls.length; i++) {
        controls[i].patchValue({ [key]: value });
      }
    }
  }

  private createDevicesForm() {
    if (!this.selectedDevice) {
      return;
    }

    const devicesFrom = [];
    const selectedDevice = {
      ...this.selectedDevice,
      zwaveControllerSoftware: +this.selectedDevice.zwaveControllerSoftware
    };
    let virtualDevices;
    if (selectedDevice.virtualDevices) {
      virtualDevices = [...selectedDevice.virtualDevices];
      delete selectedDevice.virtualDevices;
    }

    // virtual device holder form
    const vdhForm = this.deviceFormsService.initDeviceForm();
    vdhForm.get('id').enable();
    vdhForm.patchValue(selectedDevice);
    devicesFrom.push(vdhForm);

    // virtual device forms
    if (virtualDevices && virtualDevices.length) {
      for (const vd of virtualDevices) {
        const vdForm = this.deviceFormsService.initDeviceForm();
        vdForm.get('id').enable();
        vdForm.patchValue(vd);
        devicesFrom.push(vdForm);
      }
    }

    this.form = new FormArray(devicesFrom, Validators.required);

    if (selectedDevice.deviceTypeKey === 'vdh') {
      // watch automation id of VDH
      this.subscribers.automationField = this.form.controls[0].get('automationId').valueChanges.subscribe({
        next: result => {
          if (result) {
            this.patchVirtualDevices('automationId', result);
            this.updateSelectedAutomation(result);
          }
        }
      });

      // watch location id of VDH
      this.subscribers.locationField = this.form.controls[0].get('locationId').valueChanges.subscribe({
        next: result => {
          if (result) {
            this.patchVirtualDevices('locationId', result);
            this.updateLocation(result);
          }
        }
      });
    } else if (selectedDevice.deviceTypeKey === 'a') {
      // watch automation id of VDH
      this.subscribers.locationsToControlField = this.form.controls[0].get('locationsToControl').valueChanges.subscribe({
        next: result => {
          if (result) {
            this.updateLocationsToControl(result);
          }
        }
      });
    }
  }

  onPositionChanged(event) {
    const controls = this.form.controls;
    controls[0].patchValue({
      instructionPositionX: event.x,
      instructionPositionY: event.y
    });
  }

  onBackClicked() {
    this.resetScroll();
    this.wizardStep -= 1;

    if (this.wizardStep === 1) {
      this.formData = null;
      this.summarySource = null;
    }
  }

  onNextClicked() {
    this.resetScroll();
    this.wizardStep += 1;

    if (this.wizardStep === this.totalStep && this.form.valid) {
      this.formData = [];
      const formData = this.form.value;
      for (const item of formData) {
        this.formData.push(this.unparseDeviceValues(item));
      }

      this.summarySource = {
        device: this.selectedDevice,
        location: this.selectedLocation,
        automation: this.selectedAutomation,
      };

      if (this.selectedDevice.deviceTypeKey === 'a') {
        if (this.locationsToControl && this.locationsToControl.length) {
          this.summarySource.locationsToControl = this.locationsToControl.map(l => l.description);
        }
        if (this.protocolControllers && this.formData[0].zwaveControllerSoftware) {
          const controller = this.protocolControllers.find(c => c.id + '' === this.formData[0].zwaveControllerSoftware + '');
          if (controller) {
            this.summarySource.zwaveControllerSoftware = controller.name;
          }
        }
      }
    }

    if (this.wizardStep === 2) {
      this.startAutoUpdate();
    } else {
      this.stopAutoUpdate();
    }
  }

  onUpdateClicked() {
    if (this.isSubmitting === true) {
      return;
    }
    this.isSubmitting = true;
    this.subscribers.update = this.deviceWizardService
      .updateDevices(this.formData)
      .subscribe({
        next: (result: any) => {
          const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
          const title = this.sharedService.getTranslation('DEVICE');
          this.sharedService.notify(title, text, 'success');
          this.dialog.close(result.data);
          this.isSubmitting = false;
        },
        error: (error) => {
          this.errorMessage = error;
          this.isSubmitting = false;
        }
      });
  }
}
