<div [formGroup]="form" class="ui-g" [ngClass]="formBackgroundColor">
  <div class="ui-g-12 ui-md-1 text-center">
    <sc-checkbox-input
      controlName="compare_mode"
      [form]="form"
      label="{{ 'COMPARE_WITH_LAST_DHL' | translate }}"
      [showLabel]="false"
    ></sc-checkbox-input>
  </div>

  <div class="ui-g-12 ui-md-2 text-center">
    {{ 'NEW_VALUE' | translate }}
  </div>

  <div class="ui-g-12 ui-md-1 text-center">
    <sc-dropdown
      controlName="operator"
      [form]="form"
      label="{{ 'OPERATOR' | translate }}"
      [options]="selectItems['operators']"
      [showLabel]="false"
    ></sc-dropdown>
  </div>

  <div class="ui-g-12 ui-md-5 text-center">
    <span class="ui-g-4" *ngIf="compareMode === 1">
      ( {{ 'LAST_DHL_VALUE' | translate }}
    </span>

    <div
      class="ui-g-2 py-0"
      *ngIf="compareMode === 1"
      [ngClass]="{ 'ui-g-offset-4': !compareMode }"
    >
      <sc-dropdown
        controlName="operand_modifier"
        [form]="form"
        label="{{ 'OPERAND_MODIFIER' | translate }}"
        [options]="selectItems['operandModifiers']"
        [showLabel]="false"
      ></sc-dropdown>
    </div>

    <div
      class="ui-g-5 ui-g-nopad"
      [ngClass]="{ 'ui-g-offset-4': compareMode !== 1 }"
    >
      <div class="ui-g-10 ui-g-nopad">
        <sc-number-input
          controlName="operand_value"
          [form]="form"
          label="{{ 'OPERAND_VALUE' | translate }}"
          [showLabel]="false"
        ></sc-number-input>
      </div>
      <div class="ui-g-2" *ngIf="compareMode === 1">
        <span>)</span>
      </div>
    </div>
  </div>

  <div class="ui-g-12 ui-md-1 text-center">
    <sc-dropdown
      controlName="report"
      [form]="form"
      label="{{ 'REPORT' | translate }}"
      [options]="selectItems['reports']"
      [showLabel]="false"
    ></sc-dropdown>
  </div>

  <div class="ui-g-12 ui-md-1 text-center">
    <sc-checkbox-input
      controlName="ignore"
      [form]="form"
      label="{{ 'IGNORE' | translate }}"
      [showLabel]="false"
    ></sc-checkbox-input>
  </div>

  <div class="ui-g-12 ui-md-1 text-right">
    <button
      pButton
      type="button"
      class="ui-button-warning"
      (click)="removed.emit(formId)"
      pTooltip="
        {{ 'REMOVE' | translate }}
        {{ 'BEHAVIOUR_PATTERN' | translate }}
      "
      icon="fa fa-trash"
    ></button>
  </div>
</div>
