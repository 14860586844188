<a class="toolbar-item notification-panel-tigger" (click)="togglePanel()">
  <span
    class="toolbar-item-icon"
    [ngClass]="{ hasNotifications: allNotifications.length > 0 }"
  >
    <span class="icon icon-bell"></span>
  </span>
  <span *ngIf="showLabel">
    {{ "NOTIFICATIONS" | translate }}
  </span>
</a>

<p-sidebar
  [(visible)]="show"
  position="right"
  [blockScroll]="true"
  [showCloseIcon]="false"
  appendTo="body"
>
  <div class="notification-panel">
    <div class="notification-panel-header">
      <div class="header-title">
        {{ "NOTIFICATIONS" | translate }}
      </div>

      <!-- <div class="close-button" (click)="togglePanel()">
        <span class="fa fa-times"></span>
      </div> -->
    </div>

    <div class="notification-panel-body">
      <div class="no-notification" *ngIf="notifications?.length === 0">
        {{ "THERE_IS_NOTHING" | translate }}
      </div>

      <ul class="notification-list" *ngIf="notifications?.length > 0">
        <li
          *ngFor="let n of notifications"
          class="notification-item"
          (click)="openModal(n)"
        >
          <div class="detail">
            <div class="subject" [ngClass]="{ unread: n?.readAt === null }">
              {{ n?.subject }}
            </div>
            <div class="date" [ngClass]="{ unread: n?.readAt === null }">
              {{ n?.createdAt }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div *ngIf="notifications?.length > 0">
      <p-paginator
        [rows]="nbRows"
        [totalRecords]="allNotifications.length"
        (onPageChange)="paginate($event)"
        pageLinkSize="5"
      ></p-paginator>
    </div>
  </div>
</p-sidebar>
