<div class="sc-flex justify-content-end transparent" style="height: 30px;">
  <sc-modal-trigger
    buttonIcon="fa fa-exchange fa-rotate-90"
    buttonLabel="{{ 'EXPORT_IMPORT' | translate }}"
    [modalConfig]="importFormConfig"
    (onClose)="fetchDataset()"
  ></sc-modal-trigger>
</div>

<div style="height: calc(100% - 30px);">
  <sc-table
    [columns]="columns"
    [config]="config"
    [dataset]="dataset"
    [formConfig]="formConfig"
    (reloadData)="fetchDataset()"
    (afterInit)="afterInitTable($event)"
    [title]="tableTitle"
    icon="fa fa-globe"
  ></sc-table>
</div>
