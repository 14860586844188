import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { DashboardTopThreeDataset } from './dashboard-top-three';
import { ModalConfig } from '@sc/modal/modal-config';
import { ISquare } from '@sc/shapes/square/square.interface';

@Component({
  selector: 'sc-dashboard-top-three',
  templateUrl: './dashboard-top-three.component.html',
  styleUrls: ['./dashboard-top-three.component.scss']
})
export class SCDashboardTopThreeComponent implements OnInit, OnChanges {
  @Input() loading: boolean;
  @Input() subtitleText: string;
  @Input() titleText: string;
  @Input() dataset: DashboardTopThreeDataset[];
  @Input() detailsModal: ModalConfig;
  @Input() squareOptions: ISquare;

  topThreeData: DashboardTopThreeDataset[] = [];

  ngOnInit() {
    this.setTopThreeData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setTopThreeData();
  }

  private setTopThreeData() {
    if (this.dataset) {
      if (this.dataset.length > 3) {
        this.topThreeData = this.dataset.slice(0, 3);
      } else if (this.dataset.length > 0) {
        this.topThreeData = this.dataset;
      }
    }
  }
}
