<div [formGroup]="form" class="ui-g-12 ui-g-nopad">
  <h2>{{ 'OUTPUT' | translate }}</h2>

  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-5">{{ 'KEY' | translate }}</div>
    <div class="ui-g-5">{{ 'OPTION' | translate }}</div>
    <div class="ui-g-1"></div>
    <div class="ui-g-1">
      <button
        pButton
        type="button"
        class="ui-button-success"
        icon="fa fa-plus"
        (click)="add()"
        pTooltip="{{ 'ADD' | translate }}"
        appendTo="body"
      ></button>
    </div>
  </div>

  <div formArrayName="output" class="ui-g-12 ui-g-nopad">
    <div *ngFor="let outputCtrl of outputForm.controls; let i=index" [formGroupName]="i">
      <div [formGroup]="outputCtrl" class="ui-g-12 ui-g-nopad">
        <!-- KEY FIELD -->
        <div class="ui-g-5">
          <sc-dropdown
            controlName="key"
            [form]="outputCtrl"
            label="{{ 'KEY' | translate }}"
            [options]="optionData['outputKeys']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>
        <!-- /KEY FIELD -->

        <!-- OPTION FIELD -->
        <div class="ui-g-5">
          <sc-dropdown
            *ngIf="displayOptionField(outputCtrl)"
            controlName="options"
            [form]="outputCtrl"
            label="{{ 'OPTION' | translate }}"
            [options]="optionData['outputOptions']"
            [showLabel]="false"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>
        <!-- /OPTION FIELD -->

        <!-- ACTION FIELD -->
        <div class="ui-g-1 ui-g-offset-1">
          <button
            pButton
            type="button"
            class="ui-button-warning"
            icon="fa fa-times"
            (click)="remove(i)"
            pTooltip="{{ 'REMOVE' | translate }}"
            appendTo="body"
          ></button>
        </div>
        <!-- /ACTION FIELD -->
      </div>
    </div>
  </div>
</div>
