<div class="unblock-page-container">
  <img class="logo" src="assets/images/logo_full.png" />

  <div *ngIf="isUnblocking">
    <i class="fa fa-refresh fa-spin"></i>
    <!-- {{ 'UNBLOCKING_IN_PROGRESS' | translate }} -->
    Unblocking Account...
  </div>

  <div *ngIf="unblocked">
    <i class="fa fa-unlock"></i>
    <!-- {{ 'UNBLOCKING_SUCCESS' | translate }} -->
    Account is unblock. Redirecting to Login page...
  </div>

  <div *ngIf="errorMessage" class="red-text">
    <i class="fa fa-exclamation-triangle"></i> {{ errorMessage }}
  </div>
</div>
