import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeviceTaskTemplate } from '../models/device-task-template';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class DeviceTaskTemplatesService {
  private apiUrl = config().apiUrl + '/deviceTaskTemplates';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  private verifyData(data: any): DeviceTaskTemplate {
    const newData = { ...data };

    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getDeviceTaskTemplate(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getDeviceTaskTemplates(options?: { [key: string]: any }) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  createDeviceTaskTemplate(data: DeviceTaskTemplate) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateDeviceTaskTemplate(id: number, data: DeviceTaskTemplate) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteDeviceTaskTemplate(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      is_active: false,
      is_deleted: true
    });
  }
}
