import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SharedService } from '@shared/shared.service';
import { UserFormsService } from '@widgets/users/services/user-forms.service';
import { MqttService } from '@widgets/users/services/mqtt.service';
import { CeosIdentityService } from '@widgets/automations/services/ceos-identity.service';

@Component({
  selector: 'sc-mqtt-settings-form',
  templateUrl: './mqtt-settings-form.component.html',
  styleUrls: ['./mqtt-settings-form.component.scss'],
})
export class MqttSettingsFormComponent implements OnInit, OnDestroy {
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  isAutomation = false;
  isLinked = false;
  isSubmitting = false;
  isUpdating = false;
  username: string;

  private password: string;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private userFormsService: UserFormsService,
    private mqttService: MqttService,
    private sharedService: SharedService,
    private ceosIdentityService: CeosIdentityService
  ) {}

  ngOnInit(): void {
    const data = this.config && this.config.data;
    // console.log(data);
    if (data) {
      if (data.user) {
        this.username = `usr_${data.user.username}`;
        this.password = data.user.mqttPassword;
      } else if (data.automation) {
        this.isAutomation = true;
        this.username = `${data.automation.id}_${data.automation.mqtt_username}`;
        // this.password = data.automation.mqtt_password;
      }

      if (this.isAutomation) {
        this.fetchIdentity(data.automation.id);
      }
      if (this.username) {
        this.fetchUser(this.username);
        this.initForm(this.username, this.password);
      } else {
        this.errorMessage = 'SOMETHING_WENT_WRONG';
      }
    }
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal() {
    this.dialog.close();
  }

  private fetchUser(username) {
    this.fetchingState++;
    this.subscribers.create = this.mqttService.getUser(username).subscribe(
      (result: any) => {
        this.isLinked = true;
        this.fetchingState--;
      },
      (error) => {
        this.fetchingState--;
      }
    );
  }

  private fetchIdentity(id: number) {
    this.fetchingState++;
    this.subscribers.fetchIdentity = this.ceosIdentityService.getCeosIdentity(id).subscribe((result: any) => {
      this.password = result.data.mqtt_password;
      this.form.patchValue({ password: this.password });
      this.fetchingState--;
    });
  }

  private initForm(username, password = '') {
    this.form = this.userFormsService.initMqttSettingsForm();
    this.form.patchValue({ username, password });
    if (!password) {
      this.generatePassword();
    }
  }

  generatePassword() {
    this.form.patchValue({ password: this.sharedService.getRandomChars(32, true) });
  }

  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.getRawValue();

    if (this.isLinked) {
      this.delete(formData);
    } else {
      this.create(formData);
    }
  }

  private create(formData) {
    this.subscribers.create = this.mqttService.createUser(formData.username, formData.password).subscribe({
      next: (result: any) => {
        const text = this.sharedService.getTranslation('CREATE_SUCCESS');
        const title = this.sharedService.getTranslation('MQTT_SETTINGS');
        this.sharedService.notify(title, text, 'success');

        // close the form and return user info
        this.isSubmitting = false;
        this.dialog.close(formData);
      },
      error: (error) => {
        this.errorMessage = error;
        this.isSubmitting = false;
      },
    });
  }

  private delete(formData) {
    this.subscribers.delete = this.mqttService.deleteUser(formData.username).subscribe({
      next: (result: any) => {
        const text = this.sharedService.getTranslation('DELETE_SUCCESS');
        const title = this.sharedService.getTranslation('MQTT_SETTINGS');
        this.sharedService.notify(title, text, 'success');

        // close the form and return user info
        this.isSubmitting = false;
        this.dialog.close(formData);
      },
      error: (error) => {
        this.errorMessage = error;
        this.isSubmitting = false;
      },
    });
  }

  update() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    this.errorMessage = null;
    this.isUpdating = true;

    const formData = this.form.getRawValue();

    this.subscribers.update = this.mqttService.updateUser(formData.username, formData.password).subscribe({
      next: (result: any) => {
        const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
        const title = this.sharedService.getTranslation('MQTT_SETTINGS');
        this.sharedService.notify(title, text, 'success');

        // close the form and return user info
        this.isUpdating = false;
        this.dialog.close(formData);
      },
      error: (error) => {
        this.errorMessage = error;
        this.isUpdating = false;
      },
    });
  }
}
