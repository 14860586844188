<a
  class="toolbar-item language-chooser"
  (click)="panel.show($event)"
  [ngClass]="{ active: opened }"
>
  <span class="toolbar-item-icon">
    <span class="icon icon-flag"></span>
  </span>
  <span class="toolbar-item-label">
    {{ selectedLocale?.id?.toUpperCase() }}
  </span>
  <span class="toolbar-item-arrow">
    <span *ngIf="!opened" class="fa fa-fw fa-angle-down"></span>
    <span *ngIf="opened" class="fa fa-fw fa-angle-up"></span>
  </span>
</a>

<p-overlayPanel
  #panel
  appendTo="body"
  styleClass="toolbar-language-chooser"
  (onShow)="opened = true"
  (onHide)="opened = false"
>
  <ul class="menu">
    <li
      class="menu-item"
      *ngFor="let language of languages$ | async"
      (click)="updateUserLocale(language?.id); panel.hide()"
    >
      <span [ngClass]="languageFlag(language?.id)"></span>
      {{ language?.languageNative }}
    </li>
  </ul>
</p-overlayPanel>
