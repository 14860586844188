import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';
import { RulesService } from '../../services';

@Component({
  selector: 'sc-modifiers',
  templateUrl: './modifiers.component.html',
  styleUrls: ['./modifiers.component.scss'],
})
export class ModifiersComponent implements OnInit {
  modifierForm: FormArray;
  show = true;

  @Input()
  dataSources: DataSources = {};

  @Input()
  form: FormGroup;

  @Input()
  isTemplate: boolean;

  @Input()
  modifiers: { limit: number; compatible: any[] };

  @Input()
  selectItems: SelectItems = {};

  constructor(private rulesService: RulesService) {}

  ngOnInit(): void {
    if (this.form) {
      this.modifierForm = this.form.get('modifiers') as FormArray;
    }
  }

  onToggle() {
    this.show = !this.show;
  }

  onAddModifier() {
    if (this.modifierForm.length >= this.modifiers.limit) {
      return;
    }
    this.modifierForm.push(this.rulesService.initModifierForm(undefined, this.isTemplate));
  }

  onRemoveModifier(i) {
    this.modifierForm.removeAt(i);
  }
}
