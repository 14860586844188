<div class="sc-toolbar">
  <ng-container
    *ngIf="typeScreenWidth === 'lg'; then normalTemple; else smallScreenTemple"
  ></ng-container>
</div>

<ng-template #normalTemple>
  <div class="toolbar">
    <sc-site-chooser *ngIf="showSiteSelector"></sc-site-chooser>
    <sc-notification-panel
      *ngIf="showNotificationsIcon"
      [userId]="userId"
    ></sc-notification-panel>
    <sc-user-menu
      [username]="username"
      (viewMyAccount)="viewMyAccount.emit()"
      (logOut)="loggedOut.emit()"
    ></sc-user-menu>
    <sc-language-chooser></sc-language-chooser>
  </div>
</ng-template>

<ng-template #smallScreenTemple>
  <div class="logo">
    <img [src]="logoMobile" class="logo-image" (click)="openMenuPanel.emit()" />
  </div>

  <div class="toolbar">
    <a class="toolbar-item" (click)="panel.show($event)">
      <i class="fa fa-ellipsis-h fa-fw"></i>
    </a>
  </div>
</ng-template>

<p-overlayPanel #panel appendTo="body" styleClass="toolbar-overlay-menu">
  <div class="overlay-content-wrapper">
    <div class="overlay-content-item" *ngIf="user?.mainRole !== 'integrator'">
      <sc-site-chooser></sc-site-chooser>
    </div>

    <div class="overlay-content-item">
      <sc-user-menu
        [username]="username"
        (logOut)="loggedOut.emit()"
      ></sc-user-menu>
    </div>

    <div class="overlay-content-item">
      <sc-notification-panel
        *ngIf="showNotificationsIcon"
        [userId]="userId"
        [showLabel]="true"
      ></sc-notification-panel>
    </div>

    <div class="overlay-content-item">
      <sc-language-chooser></sc-language-chooser>
    </div>
  </div>
</p-overlayPanel>
