import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';

@Component({
  selector: 'sc-location-operand',
  templateUrl: './location-operand.component.html',
  styleUrls: ['./location-operand.component.scss'],
})
export class LocationOperandComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  viewMode: string;

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {
    // if (!this.isTemplate && this.form) {
    //   // prevent property field from editable
    //   this.form.get('property').disable();
    // }
  }
}
