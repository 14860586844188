<div class="sc-widget">
  <div class="sc-widget-header">
    <h4 class="sc-widget-title">
      {{ titleText | translate }}
    </h4>
    <div class="sc-widget-menu">
      <sc-modal-trigger
        *ngIf="detailsModal"
        [modalConfig]="detailsModal"
        tooltipContent="Details"
        tooltipPosition="bottom"
      >
        <a class="dashboard-action-btn">
          <i class="pi pi-eye"></i>
        </a>
      </sc-modal-trigger>
    </div>

    <!-- <div class="sc-widget-menu"></div> -->
  </div>

  <ng-container *ngIf="loading; then spinner; else content"></ng-container>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body alert-body">
    <div class="alert" [ngClass]="alertClass">
      <i *ngIf="alertIcon" class="fa fa-fw" [ngClass]="alertIcon"></i>
      <span *ngIf="displayAlertText">{{ alertText }}</span>
    </div>
    <small *ngIf="subtitleText" class="alert-subtitle">
      {{ subtitleText | translate }}
    </small>
  </div>
</ng-template>
