<div class="page-body">
  <!-- [ngStyle]="{ height: gridViewHeight$ | async }" -->
  <!-- *ngIf="formattedWidgets?.length" -->

  <ng-container *ngIf="loading; then spinner; else content"></ng-container>

  <ng-template #content>
    <gridster [options]="config">
      <gridster-item *ngFor="let widget of formattedWidgets" [item]="widget">
        <sc-widget
          [widget]="widget"
          [editMode]="editMode"
          (deleteWidget)="deleteWidget.emit(widget)"
        ></sc-widget>
      </gridster-item>
    </gridster>
  </ng-template>

  <ng-template #spinner
    ><sc-loading-indicator></sc-loading-indicator
  ></ng-template>
</div>
