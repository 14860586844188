import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NotificationTemplate } from '../models/notification-template';

import { config } from '../../../config';

@Injectable()
export class NotificationTemplatesService {
  private apiUrl = config().apiUrl + '/notificationTemplates';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): NotificationTemplate {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.notificationTemplateId !== 'undefined') {
      delete newData.notificationTemplateId;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  get(id?: number) {
    return this.httpClient.get(this.apiUrl + (id ? '/' + id : ''));
  }

  create(data: NotificationTemplate) {
    return this.httpClient.post(this.apiUrl, data);
  }

  update(id: number, data: NotificationTemplate) {
    return this.httpClient.patch(this.apiUrl + '/' + id, this.verifyData(data));
  }

  delete(id: number) {
    return this.httpClient.patch(this.apiUrl + '/' + id, {
      isActive: false,
      isDeleted: true,
    });
  }
}
