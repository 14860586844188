import { Page } from '@app/core2/models/page';
import { Section } from '@app/core2/models/page-section';
import { Widget } from '@app/core2/models/page-section-widget';
import * as fromPages from '../actions/pages.action';
import * as fromWidgets from '../actions/widgets.action';

export interface PageState {
  entities: { [id: string]: Page };
  loaded: boolean;
  loading: boolean;
  selectedPage: Page;
  selectedSection: Section;
  selectedWidgets: Widget[];
  editMode: boolean;
  showMenu: boolean;
  showOverlayMenu: boolean;
  isOverlayMenuActive: boolean;
  pending: boolean;
  pendingError: string;
  updateMenu: number;
  selectedMenu: string;
  settingsExpanded: boolean;
}

export const initialState = {
  entities: null,
  loaded: false,
  loading: false,
  selectedPage: null,
  selectedSection: null,
  selectedWidgets: null,
  editMode: false,
  showMenu: false,
  showOverlayMenu: false,
  isOverlayMenuActive: false,
  pending: false,
  pendingError: null,
  updateMenu: 0,
  selectedMenu: null,
  settingsExpanded: false,
};

export function reducer(state = initialState, action: fromPages.PagesAction | fromWidgets.WidgetsAction): PageState {
  switch (action.type) {
    case fromPages.VISUALISE_PAGE: {
      const selectedPage = action.payload;
      return {
        ...state,
        selectedPage,
      };
    }

    case fromPages.VISUALISE_SECTION: {
      const selectedSection = action.payload;
      return {
        ...state,
        selectedSection,
      };
    }

    // ____ ADD / REMOVE SECTION
    case fromPages.ADD_PAGE_SECTION: {
      const sectionToAdd = { ...action.payload };
      sectionToAdd.position = state.selectedPage.sections.length;
      const selectedPage = {
        ...state.selectedPage,
        sections: [...state.selectedPage.sections, sectionToAdd],
      };
      return {
        ...state,
        selectedPage,
      };
    }

    case fromPages.REMOVE_PAGE_SECTION: {
      const sectionToRemove = action.payload;
      const selectedPage = {
        ...state.selectedPage,
        sections: [...state.selectedPage.sections.filter((s) => s.name !== sectionToRemove.name)],
      };
      return {
        ...state,
        selectedPage,
      };
    }

    case fromPages.RESET_PAGE_SECTION: {
      const sectionToReset = { ...action.payload };
      const sections = [];
      state.selectedPage.sections.forEach((s) => {
        if (s.sectionId === sectionToReset.sectionId) {
          sections.push({ ...sectionToReset });
        } else {
          sections.push({ ...s });
        }
      });
      return {
        ...state,
        selectedPage: {
          ...state.selectedPage,
          sections,
        },
      };
    }

    case fromPages.MOVE_PAGE_SECTIONS: {
      const sections = [...action.payload];
      return {
        ...state,
        selectedPage: {
          ...state.selectedPage,
          sections,
        },
      };
    }

    // ____ ADD / REMOVE PAGE SECTION WIDGETS
    case fromPages.UPDATE_PAGE_SECTION_WIDGETS: {
      const selectedPage = { ...state.selectedPage };
      const section = { ...state.selectedSection };
      section.widgets = [...action.payload];

      selectedPage.sections = selectedPage.sections.map((s) => {
        if (s.name === section.name) {
          return section;
        }
        return s;
      });
      return {
        ...state,
        selectedPage,
      };
    }

    // ____ LOAD PAGES
    case fromPages.LOAD_PAGES: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromPages.LOAD_PAGES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromPages.LOAD_PAGES_SUCCESS: {
      const pages = action.payload;
      let entitiesBySite = {};
      const entities = pages.reduce(
        (tmpEntities: { [id: string]: Page }, page: Page) => {
          if (page.siteId) {
            if (!(page.siteId in entitiesBySite)) {
              entitiesBySite[page.siteId] = {};
            }
            entitiesBySite[page.siteId][page.key] = page;
          }

          return {
            ...tmpEntities,
            [page.key]: page,
          };
        },
        {}
        // {
        //   ...state.entities,
        // }
      );

      entities['__by_site__'] = entitiesBySite;

      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    // ____ PAGE EDIT MODE
    case fromPages.ENABLE_EDIT_MODE: {
      return {
        ...state,
        editMode: true,
      };
    }

    case fromPages.DISABLE_EDIT_MODE: {
      return {
        ...state,
        editMode: false,
      };
    }

    // ____ PAGE SHOW MENU
    case fromPages.SHOW_MENU: {
      return {
        ...state,
        showMenu: true,
      };
    }

    case fromPages.HIDE_MENU: {
      return {
        ...state,
        showMenu: false,
      };
    }

    case fromPages.ACTIVATE_OVERLAY_MENU: {
      return {
        ...state,
        isOverlayMenuActive: true,
      };
    }

    case fromPages.DEACTIVATE_OVERLAY_MENU: {
      return {
        ...state,
        isOverlayMenuActive: false,
      };
    }

    case fromPages.SHOW_OVERLAY_MENU: {
      return {
        ...state,
        showOverlayMenu: true,
      };
    }

    case fromPages.HIDE_OVERLAY_MENU: {
      return {
        ...state,
        showOverlayMenu: false,
      };
    }

    case fromPages.UPDATE_MENU: {
      return {
        ...state,
        updateMenu: (state.updateMenu || 0) + 1,
      };
    }

    case fromPages.SELECT_MENU: {
      const selectedMenu = action.payload.selectedId;
      return {
        ...state,
        selectedMenu,
      };
    }

    case fromPages.EXPAND_SETTINGS: {
      const settingsExpanded = action.payload.expanded;
      return {
        ...state,
        settingsExpanded,
      };
    }

    // ____ UPDATE PAGE
    case fromPages.UPDATE_PAGE: {
      return {
        ...state,
        pending: true,
      };
    }

    case fromPages.UPDATE_PAGE_FAIL: {
      const pendingError = action.payload;
      return {
        ...state,
        pending: false,
        pendingError,
      };
    }

    case fromPages.UPDATE_PAGE_SUCCESS: {
      const page = action.payload;
      let entities;
      if (page.siteId) {
        entities = {
          ...state.entities,
          ['__by_site__']: {
            ...state.entities['__by_site__'],
            [page.siteId]: {
              ...state.entities['__by_site__'][page.siteId],
              [page.key]: page,
            },
          },
        };
      } else {
        entities = {
          ...state.entities,
          [page.key]: page,
        };
      }

      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
      };
    }

    // ____ UPDATE PAGE WIDGET
    case fromWidgets.SAVE_CUSTOM_SETTING_SUCCESS: {
      const page = {
        ...state.selectedPage,
        sections: [...state.selectedPage.sections],
      };
      const section = {
        ...state.selectedSection,
        widgets: [...state.selectedSection.widgets],
      };
      const customWidget = {
        ...action.payload,
        // The uniqueId makes the widget identifiable and therefore delteable.
        // If we use the widgetId, we can't have multiple widgets of the same type (id).
        //uniqueId: this.generateUniqueWidgetId(action.payload.id),
      };

      // ____ UPDATE WIDGET TO SECTION
      for (let i = 0; i < section.widgets.length; i++) {
        if (section.widgets[i].widgetId === customWidget.widgetId) {
          section.widgets[i] = customWidget;
        }
      }

      // ____ UPDATE SECTION TO PAGE
      for (let i = 0; i < page.sections.length; i++) {
        if (page.sections[i].name === section.name) {
          page.sections[i] = { ...section };
        }
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [page.key]: page,
        },
        pending: false,
        pendingError: null,
      };
    }
    case fromWidgets.SAVE_CUSTOM_SETTING: {
      return {
        ...state,
        pending: true,
      };
    }

    case fromWidgets.SAVE_CUSTOM_SETTING_FAIL: {
      const pendingError = action.payload;
      return {
        ...state,
        pending: false,
        pendingError,
      };
    }
  }

  return state;
}

export const getPagesEntities = (state: PageState) => state.entities;

export const getPagesLoading = (state: PageState) => state.loading;

export const getPagesLoaded = (state: PageState) => state.loaded;

export const getSelectedPage = (state: PageState) => state.selectedPage;

export const getSelectedSection = (state: PageState) => state.selectedSection;

export const getSelectedWidgets = (state: PageState) => state.selectedWidgets;

export const getPagesPending = (state: PageState) => state.pending;

export const getPagesPendingError = (state: PageState) => state.pendingError;

export const getShowMenu = (state: PageState) => state.showMenu;

export const getIsOverlayMenuActive = (state: PageState) => state.isOverlayMenuActive;

export const getShowOverlayMenu = (state: PageState) => state.showOverlayMenu;

export const getEditMode = (state: PageState) => state.editMode;

export const getUpdateMenu = (state: PageState) => state.updateMenu;

export const getSelectedMenu = (state: PageState) => state.selectedMenu;

export const expandSettings = (state: PageState) => state.settingsExpanded;
