<div class="sc-flex justify-between">
  <table class="vdh-table">
    <tbody>
      <tr>
        <th class="text-right">
          {{ 'MODEL' | translate }}:
        </th>

        <td>
          {{ source?.device?.modelName }}
          <small>
            ({{ source?.device?.model }}, {{ source?.device?.protocolName }})
          </small>
        </td>
      </tr>

      <tr>
        <th class="text-right">
          {{ 'NAME' | translate }}:
        </th>

        <td>
          {{ data[0]?.description }}
        </td>
      </tr>

      <tr>
        <th class="text-right">
          {{ 'LOCATION' | translate }}:
        </th>

        <td>
          {{ source?.location?.description }}
        </td>
      </tr>

      <tr *ngIf="source?.automation">
        <th class="text-right">
          {{ 'AUTOMATION' | translate }}:
        </th>

        <td>
          {{ source?.automation?.description }}
        </td>
      </tr>

      <ng-container *ngIf="source?.device?.deviceTypeKey === 'a'">
        <tr>
          <th class="text-right">
            {{ 'LOCATIONS_TO_CONTROL' | translate }}:
          </th>

          <td>
            {{ source?.locationsToControl }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'INSTALLATION_PIN' | translate }}:
          </th>

          <td>
            {{ data[0]?.installationPin }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'PIN' | translate }}:
          </th>

          <td>
            {{ data[0]?.pin }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'API_KEY' | translate }}:
          </th>

          <td>
            {{ data[0]?.apiKey }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'CONFIG_FETCH_INTERVAL' | translate }}:
          </th>

          <td>
            {{ data[0]?.configFetchInterval / 1000 }} {{ 'SECONDS' | translate }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'ZWAVE' | translate }}:
          </th>

          <td>
            {{ (data[0]?.zwaveStatus ? 'YES' : 'NO') | translate }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'ZWAVE_CONTROLLER_PORT' | translate }}:
          </th>

          <td>
            {{ data[0]?.zwaveControllerPort }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'ZWAVE_CONTROLLER_SOFTWARE' | translate }}:
          </th>

          <td>
            {{ source?.zwaveControllerSoftware }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'ZIGBEE' | translate }}:
          </th>

          <td>
            {{ (data[0]?.zigbeeStatus ? 'YES' : 'NO') | translate }}
          </td>
        </tr>

        <tr>
          <th class="text-right">
            {{ 'ZIGBEE_CONTROLLER_PORT' | translate }}:
          </th>

          <td>
            {{ data[0]?.zigbeeControllerPort }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div *ngIf="productImage">
    <img [src]="productImage" width="70" />
  </div>
</div>


<table class="vd-table" *ngIf="source?.device?.deviceTypeKey !== 'a'">
  <thead class="vd-table-header">
    <tr class="vd-table-header-row">
      <th class="vd-table-header-cell">
        <div class="vd-table-header-cell-content">
          {{ 'VIRTUAL_DEVICE' | translate }}
        </div>
      </th>
      <th class="vd-table-header-cell">
        <div class="vd-table-header-cell-content">
          {{ 'EXCLUDE_FROM_LHL' | translate }}
        </div>
      </th>
      <th class="vd-table-header-cell">
        <div class="vd-table-header-cell-content">
          {{ 'GLOBAL_DEVICE' | translate }}
        </div>
      </th>
      <th class="vd-table-header-cell">
        <div class="vd-table-header-cell-content">
          {{ 'CONSIDER_FOR_OCCUPANCY' | translate }}
        </div>
      </th>
      <th class="vd-table-header-cell">
        <div class="vd-table-header-cell-content">
          {{ 'DIFF_DETECTION_FACTOR' | translate }}
        </div>
      </th>
      <th class="vd-table-header-cell">
        <div class="vd-table-header-cell-content">
          {{ 'AVERAGE_CALCULATION_WEIGHT' | translate }}
        </div>
      </th>
    </tr>
  </thead>

  <tbody class="vd-table-body">
    <ng-container *ngFor="let item of data; let i = index">
      <tr *ngIf="i > 0" class="vd-table-body-row">
        <td class="vd-table-body-cell">
          {{ item.description }}
        </td>
        <td class="vd-table-body-cell text-center">
          <span [ngClass]="getStatusIcon(item.excludeFromLHL)"></span>
        </td>
        <td class="vd-table-body-cell text-center">
          <span [ngClass]="getStatusIcon(item.isGlobalDevice)"></span>
        </td>
        <td class="vd-table-body-cell text-center">
          <span [ngClass]="getStatusIcon(item.considerForOccupancy)"></span>
        </td>
        <td class="vd-table-body-cell text-right">
          {{ item.diffDetectionFactor }}
        </td>
        <td class="vd-table-body-cell text-right">
          {{ item.avcCalcWeight }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
