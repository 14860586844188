import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-color-input',
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
      ></sc-field-label>

      <input
        pInputText
        type="text"
        [id]="inputId"
        [formControlName]="controlName"
        [colorPicker]="currentColor"
        (colorPickerSelect)="onColorChange($event)"
        [cpCancelButton]="true"
        [cpOKButton]="true"
        [cpOutputFormat]="outputFormat"
        [cpDisableInput]="disableInput"
        [cpPresetColors]="presetColors"
        [cpPosition]="'bottom'"
        autocomplete="off"
        [ngStyle]="inputStyle"
      />

      <sc-field-error
        [for]="label"
        [fieldControl]="form.controls[controlName]"
      ></sc-field-error>
    </div>
  `
})
export class SCColorInputComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  outputFormat: 'hex' | 'rgba' | 'hsla';
  @Input()
  disableInput: boolean;
  @Input()
  presetColors: string[];

  inputId: string;

  constructor() {}

  ngOnInit() {
    this.showLabel =
      typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.outputFormat = this.outputFormat || 'hex';
    this.disableInput = this.disableInput || false;
    this.presetColors = this.presetColors || [];
    this.inputId = 'sc-color-input_' + this.controlName + new Date().getTime();
  }

  get inputStyle() {
    if (this.disableInput) {
      return {
        background: this.currentColor,
        color: this.currentColor,
      };
    }

    let color = '#ffffff';
    if (
      this.currentColor &&
      (this.currentColor.toLowerCase() === '#ffffff' ||
        this.currentColor.toLowerCase() === '#fff')
    ) {
      color = '#000000';
    }

    return {
      background: this.currentColor,
      color
    };
  }

  get currentColor() {
    return this.form.controls[this.controlName].value;
  }

  onColorChange(event) {
    this.form.controls[this.controlName].setValue(event);
    this.form.markAsDirty();
  }
}
