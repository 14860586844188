<div class="custom-global container">
  <div class="custom-global-body" *ngIf="commandForm" [formGroup]="commandForm">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-3" *ngIf="isTemplate">
        <label class="input-label" for="name">
          {{ "NAME" | translate }}
        </label>

        <input type="text" pInputText inputId="name" formControlName="name" />
      </div>

      <div class="p-field p-col-12 p-md-3">
        <label class="input-label" for="target">
          {{ "CCP_COMMON_GLOBAL" | translate }}
        </label>

        <p-dropdown
          inputId="target"
          [options]="selectItems?.customGlobals"
          formControlName="target"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_COMMON_GLOBAL' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>

        <div *ngIf="isTemplate">
          <p-checkbox
            inputId="is_target_editable"
            [binary]="true"
            [formControl]="commandForm?.get('is_target_editable')"
            [label]="'EDITABLE' | translate"
          ></p-checkbox>
        </div>
      </div>

      <div class="p-field p-col-12 p-md-3">
        <label class="input-label" for="action">
          {{ "CCP_COMMON_ACTION" | translate }}
        </label>

        <p-dropdown
          inputId="action"
          [options]="actions"
          formControlName="action"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_COMMON_ACTION' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-3">
        <label class="input-label" for="value">
          {{ "CCP_COMMON_VALUE" | translate }}
        </label>

        <div class="" [ngClass]="{ 'ui-inputgroup': valueStructure?.unit }">
          <ng-container [ngSwitch]="valueStructure?.type">
            <ng-container *ngSwitchCase="'numeric'">
              <input
                type="number"
                pInputText
                inputId="value"
                formControlName="value"
                [min]="valueStructure?.min"
                [max]="valueStructure?.max"
              />
            </ng-container>

            <ng-container *ngSwitchCase="'select'">
              <p-dropdown
                inputId="value"
                [options]="valueStructure?.options"
                formControlName="value"
                placeholder="Select"
                [filter]="true"
                appendTo="body"
              ></p-dropdown>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <input
                type="text"
                pInputText
                inputId="value"
                formControlName="value"
              />
            </ng-container>
          </ng-container>

          <span class="ui-inputgroup-addon" *ngIf="valueStructure?.unit">
            {{ valueStructure?.unit }}
          </span>
        </div>

        <div *ngIf="isTemplate">
          <p-checkbox
            inputId="is_value_editable"
            [binary]="true"
            [formControl]="commandForm?.get('is_value_editable')"
            [label]="'EDITABLE' | translate"
          ></p-checkbox>
        </div>
      </div>
    </div>
  </div>

  <sc-command-options
    *ngIf="commandForm"
    [form]="commandForm"
    [isTemplate]="isTemplate"
  ></sc-command-options>
</div>
