import { NgModule } from '@angular/core';

// MODULES
import { BenchmarkTemplatesModule } from './benchmark-templates/benchmark-templates.module';
import { LocationBenchmarksModule } from './location-benchmarks/location-benchmark.module';
import { BenchmarksOverviewModule } from './benckmarks-overview/benchmarks-overview.module';

// SERVICES
import { BenchmarksService } from './services/benchmarks.service';
import { BenchmarksBatchService } from './services/benchmarks-batch.service';
import { BenchmarkTemplateFormsService } from './services/benchmark-template-forms.service';
import { BenchmarkTemplatesService } from './services/benchmark-templates.service';
import { LocationBenchmarkFormsService } from './services/location-benchmark-forms.service';
import { LocationBenchmarksService } from './services/location-benchmarks.service';

const MODULES: any[] = [
  BenchmarkTemplatesModule,
  LocationBenchmarksModule,
  BenchmarksOverviewModule
];

@NgModule({
  imports: MODULES,
  exports: MODULES,
  providers: [
    BenchmarkTemplateFormsService,
    BenchmarkTemplatesService,
    BenchmarksService,
    BenchmarksBatchService,
    LocationBenchmarkFormsService,
    LocationBenchmarksService
  ]
})
export class BenchmarksModule {}
