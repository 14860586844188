import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ColDef } from 'ag-grid';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-most-sold-rooms-modal',
  templateUrl: './most-sold-rooms-modal.component.html'
})
export class MostSoldRoomsModalComponent implements OnInit {
  @Input()
  data: any;

  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  currentView: string;
  dataset: any[];
  tableColumns: ColDef[];

  constructor (private sharedService: SharedService) {}

  ngOnInit() {
    this.createTable();
    this.onChangeView('lastweek');
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  // view control handler
  onChangeView(view: string) {
    if (this.currentView === view) {
      return;
    }
    this.currentView = view;

    switch (view) {
      case 'lastyear':
        this.dataset = this.data.lastYear;
        break;
      case 'thismonth':
        this.dataset = this.data.thisMonth;
        break;
      case 'lastmonth':
        this.dataset = this.data.lastMonth;
        break;
      case 'lastweek':
        this.dataset = this.data.lastWeek;
        break;
    }
  }

  // view button css class
  changeViewBtnClass(view: string) {
    if (view === this.currentView) {
      return 'ui-button-success';
    }
    return 'ui-button-secondary';
  }

  private createTable() {
    this.tableColumns = [
      {
        colId: 'location',
        headerName: 'LOCATION',
        field: 'location',
      },
      {
        colId: 'occupied',
        headerName: 'OCCUPIED',
        field: 'occupied',
        valueFormatter: this.occupiedFormatter.bind(this),
        filterValueGetter: this.occupiedFormatter.bind(this),
      }
    ];
  }

  private occupiedFormatter(params: any) {
    const value = params && params.data && params.data.occupied;
    return value + ' day(s)';
  }
}
