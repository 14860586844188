<div
  class="table-refresh-button"
  pTooltip="{{ 'REFRESH' | translate }}"
  tooltipPosition="top"
  appendTo="body"
>
  <button
    type="button"
    class="refresh-button"
    (click)="clickHandler()"
    [disabled]="refreshing"
  >
    <span class="fa fa-refresh" [ngClass]="{ 'fa-spin': refreshing }"></span>
  </button>
</div>
