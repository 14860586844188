import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { RuleSchedulerJob } from '../models/rule-scheduler-job';
import { ModalConfig } from '@sc/modal/modal-config';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromStore from '@app/store';
import { RuleSchedulerJobsService } from '../services/rule-scheduler-jobs.service';
import { RuleTemplatesService } from '@widgets/rule-templates/services/rule-templates.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { SharedService } from '@shared/shared.service';
import { RuleSchedulerJobActionCellComponent } from '../rule-scheduler-job-action-cell/rule-scheduler-job-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-rule-scheduler-jobs-table',
  templateUrl: 'rule-scheduler-jobs-table.component.html',
})
export class RuleSchedulerJobsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: RuleSchedulerJob[];
  formConfig: ModalConfig;
  tableTitle: string;
  table: GridOptions;
  subscribers: { [key: string]: any };
  fetchingState: number;

  selectedSite: { [key: string]: any };
  optionData: { [key: string]: any };

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private ruleSchedulerJobsService: RuleSchedulerJobsService,
    private ruleTemplatesService: RuleTemplatesService,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.tableTitle = 'RULE_SCHEDULER_JOBS';
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: RuleSchedulerJobActionCellComponent,
      },
    ];
    this.selectedSite = this.sharedService.selectedSite;
    this.optionData = {};
    this.subscribers = {};
    this.fetchingState = 0;
    this.formConfig = {
      name: 'RuleSchedulerJobForm',
      options: { modalTitle: 'RULE_SCHEDULER_JOB_FORM_TITLE' },
      data: {
        optionData: this.optionData,
      },
    };

    // Get Companies
    this.optionData.companiesSource = this.sharedService.sourceData.companies;
    this.optionData.companies = this.sharedService.selectItems.companies;

    this.store
      .select(fromStore.getSelectedSite)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.dataset = [];

          this.fetchDataset();
        }
      });

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.fetchLocations();
    this.fetchRuleTemplates();

    this.fetchingState++;
    const { companyId, id } = this.selectedSite;
    this.subscribers.DATASET = this.ruleSchedulerJobsService
      .getRuleSchedulerJobs({ companyId, siteId: id })
      .subscribe((result: any) => {
        this.dataset = result.data;
        this.fetchingState--;
      });
  }

  fetchLocations() {
    if (this.selectedSite) {
      this.fetchingState++;
      const { companyId, id } = this.selectedSite;
      const options = {
        siteId: id,
        columns: 'id,description,isActive,isDeleted',
        minimize: true,
      };
      this.subscribers.GET_LOCATIONS = this.locationsService.getLocations(options).subscribe((result: any) => {
        this.optionData.locationsSource = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);
        this.optionData.locations = this.sharedService.createSelectItems(
          this.optionData.locationsSource.filter((d) => d.isActive && !d.isDeleted),
          false
        );
        this.fetchingState--;
      });
    }
  }

  fetchRuleTemplates() {
    this.fetchingState++;
    this.subscribers.GET_RULE_TEMPLATES = this.ruleTemplatesService.getRuleTemplates().subscribe((result: any) => {
      this.optionData.ruleTemplatesSource = result.data;
      this.optionData.ruleTemplates = this.sharedService.createSelectItems(
        result.data.filter((d) => d.isActive && !d.isDeleted),
        false
      );
      this.fetchingState--;
    });
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.optionData = this.optionData;
  }
}
