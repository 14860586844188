import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GuestSession } from '../models/guest-session';
import { GuestSessionRequestOptions } from '../models/guest-session-request-options';

import { config } from '@app/config';

@Injectable()
export class GuestSessionsService {
  private apiUrl = config().apiUrl + '/guestSessions';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): GuestSession {
    const newData = { ...data };
    delete newData.id;
    delete newData.companyId;
    delete newData.siteId;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getGuestSession(id: string) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getGuestSessions(options?: GuestSessionRequestOptions) {
    let queryParams: any = [];

    for (const key in options) {
      if (options.hasOwnProperty(key) && options[key]) {
        queryParams.push(`${key}=${options[key]}`);
      }
    }

    if (queryParams.length) {
      queryParams = `?${queryParams.join('&')}`;
    } else {
      queryParams = '';
    }

    return this.httpClient.get(this.apiUrl + queryParams);
  }

  createGuestSession(data: GuestSession) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateGuestSession(id: string, data: GuestSession) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteGuestSession(id: string) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true,
    });
  }

  getGuestSessionByLocation(locationIdx: string) {
    return this.httpClient.get(`${this.apiUrl}/location/${locationIdx}`);
  }
}
