import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';
import { AuthService } from '@app/auth/services/auth.service';
import { SharedService } from '@shared/shared.service';
import { UsersService } from '../services/users.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MqttSettingsFormComponent } from '../mqtt-settings-form/mqtt-settings-form.component';

@Component({
  selector: 'sc-user-action-cell',
  templateUrl: './user-action-cell.component.html',
})
export class UserActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];
  cell: any;
  data: any;
  passwordFormConfig: ModalConfig;
  userFormConfig: ModalConfig;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private auth: AuthService,
    private sharedService: SharedService,
    private usersService: UsersService,
    private dialogService: DialogService
  ) {}

  agInit(params: any): void {
    this.cell = params;
    this.data = this.cell.data;
    this.userFormConfig = {
      name: 'UserForm',
      data: this.data,
      options: { modalTitle: 'USER_FORM_TITLE' },
    };
    this.passwordFormConfig = {
      name: 'UserPasswordForm',
      data: this.data,
      options: { modalTitle: 'USER_PASSWORD_FORM_TITLE' },
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        type: 'modal',
        modalConfig: this.userFormConfig,
        onModalClose: this.updateRow.bind(this),
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];

    if (this.allow) {
      this.actions = [
        {
          key: 'CHANGE_PASSWORD',
          icon: 'fa fa-key',
          type: 'modal',
          modalConfig: this.passwordFormConfig,
        },
        {
          key: 'USER_BLOCK_UNBLOCK',
          icon: 'fa fa-lock',
          type: 'button',
          onClick: this.toggleBlock.bind(this),
        },
        {
          key: 'MQTT_SETTINGS',
          icon: 'fa fa-satellite-dish',
          type: 'button',
          onClick: this.openMqttSettingForm.bind(this),
        },
        ...this.actions,
      ];
    }
  }

  refresh(): boolean {
    return true;
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  private deleteRow() {
    this.subscribers.deleteUser = this.usersService.deleteUser(this.data.id).subscribe(
      (response: any) => {
        // update row data
        this.cell.context.parentComponent.updateRow({
          id: this.data.id,
          isActive: false,
          isDeleted: true,
        });
        const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
        const title = this.sharedService.getTranslation('USER');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const text = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('USER');
        this.sharedService.notify(title, text, 'error');
      }
    );
  }

  get allow() {
    return this.auth.isAuthorized('admin') ? true : false;
  }

  get isDeleted() {
    if (this.data && this.data.isDeleted) {
      return true;
    }
    return false;
  }

  private toggleBlock() {
    const data = {
      isBlocked: !this.data.isBlocked,
    };
    this.subscribers.blockUser = this.usersService.updateUser(this.data.id, data).subscribe(
      (response: any) => {
        // update row data
        this.cell.context.parentComponent.updateRow({
          id: this.data.id,
          isBlocked: data.isBlocked,
        });

        // show success message
        const message = this.sharedService.getTranslation('UPDATE_SUCCESS');
        const title = this.sharedService.getTranslation('USER');
        this.sharedService.notify(title, message, 'success');
      },
      (error: any) => {
        // show error message
        const message = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('USER');
        this.sharedService.notify(title, message, 'error');
      }
    );
  }

  private openMqttSettingForm() {
    const mqttSettingForm: DynamicDialogRef = this.dialogService.open(MqttSettingsFormComponent, {
      closeOnEscape: true,
      dismissableMask: true,
      showHeader: false,
      styleClass: 'no-padding-content',
      data: {
        user: this.data,
      },
    });

    // this.subscribers.mqttSettingFormClosed = mqttSettingForm.onClose.subscribe((result) => {
    //   this.cell.context.parentComponent.updateRow({
    //     id: this.data.id,
    //     mqttPassword: result.password,
    //   });
    // });
  }
}
