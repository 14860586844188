import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DeviceBrand } from '../models/device-brand';

import { DeviceBrandsService } from '../services/device-brands.service';
import { SharedService } from '@shared/shared.service';

// import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
// import { DependencyWarningComponent } from '@widgets/dependency-warning/dependency-warning.component';

@Component({
  selector: 'sc-device-brand-form',
  templateUrl: './device-brand-form.component.html'
})
export class DeviceBrandFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: DeviceBrand;
  isSubmitting = false;

  private subscribers: { [key: string]: any } = {};
  // private warningDialog: DynamicDialogRef;

  constructor(
    private formBuilder: FormBuilder,
    private deviceBrandsService: DeviceBrandsService,
    private sharedService: SharedService,
    // private dialogService: DialogService,
  ) {}

  ngOnInit() {
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchDeviceBrand(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDeviceBrand(id: number) {
    this.fetchingState++;
    this.subscribers.getDeviceBrand = this.deviceBrandsService
      .getDeviceBrand(id)
      .subscribe({
        next: (result: any) => {
          this.formData = result.data;
          this.form.patchValue(this.formData);
          this.fetchingState--;
        }
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      isActive: false,
      isDeleted: false
    });
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      // call api
      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create() {
    this.subscribers.create = this.deviceBrandsService
      .createDeviceBrand(this.form.getRawValue())
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'CREATE_FAIL')
      });
  }

  private update() {
    this.subscribers.update = this.deviceBrandsService
      .updateDeviceBrand(this.formData.id, this.form.value)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'UPDATE_FAIL')
      });
  }

  delete(force = false) {
    this.subscribers.delete = this.deviceBrandsService
      .deleteDeviceBrand(this.formData.id, force)
      .subscribe({
        // next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        next: (result: any) => {
          if (result.warning) {
            // this.sharedService.deleteWarningHandler(
            //   this.data,
            //   result.warning,
            //   this.delete.bind(this)
            // );
            this.subscribers.delete = this.sharedService
              .deleteWarningHandler(this.data, result.warning)
              .subscribe((confirm: any) => {
                if (confirm === true) {
                  this.delete(true);
                }
              });
          } else {
            this.apiCallSuccess('DELETE_SUCCESS', result);
          }
        },
        error: this.apiCallError.bind(this, 'DELETE_FAIL')
      });
  }

  // private deleteWarningHandler(dependencies) {
  //   this.warningDialog = this.dialogService.open(DependencyWarningComponent, {
  //     header: this.sharedService.getTranslation('DEPENDENCY_WARNING_TITLE'),
  //     data: { source: this.data, dependencies }
  //   });

  //   this.warningDialog.onClose.subscribe((result: any) => {
  //     if (result === true) {
  //       this.delete(true);
  //     }
  //   });
  // }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE_BRAND');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
