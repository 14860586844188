import { Action } from '@ngrx/store';

import { PageTemplate } from '@app/core2/models/page-template';

// LOAD PAGES
export const LOAD_PAGE_TEMPLATES = '[Pages] Load Page Templates';
export const LOAD_PAGE_TEMPLATES_FAIL = '[Pages] Load Page Templates Fail';
export const LOAD_PAGE_TEMPLATES_SUCCESS =
  '[Pages] Load Page Templates Success';

export class LoadPageTemplates implements Action {
  readonly type = LOAD_PAGE_TEMPLATES;
}

export class LoadPageTemplatesFail implements Action {
  readonly type = LOAD_PAGE_TEMPLATES_FAIL;
  constructor(public payload: any) {}
}

export class LoadPageTemplatesSuccess implements Action {
  readonly type = LOAD_PAGE_TEMPLATES_SUCCESS;
  constructor(public payload: PageTemplate[]) {}
}

// ACTION TYPES
export type PageTemplatesAction =
  | LoadPageTemplates
  | LoadPageTemplatesFail
  | LoadPageTemplatesSuccess;
