import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Directive({ selector: '[scHasPermission]' })
export class HasPermissionDirective {
  private hasView = false;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set scHasPermission(permissions: string) {
    const permissionsList: string[] = (permissions && permissions.split(',')) || [];
    let isAllow = false;

    if (permissionsList.length === 0) {
      isAllow = true;
    } else {
      for (const p of permissionsList) {
        if (this.authService.allow(p)) {
          isAllow = true;
          break;
        }
      }
    }

    if (isAllow && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isAllow && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
