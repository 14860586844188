<div class="property-config-form">
  <div class="property-config-form__header">
    <div class="property-config-form__header__title">
      <strong>{{ "DEVICE_MODEL_CUSTOM_CONFIG" | translate }}</strong>
    </div>

    <div class="property-config-form__header__actions">
      <button
        pButton
        class="ui-button-text"
        [icon]="'fa fa-plus'"
        (click)="addConfig()"
      ></button>
    </div>
  </div>

  <div class="property-config-body" [formGroup]="form">
    <div formArrayName="config">
      <div class="ui-g">
        <div class="ui-g-12 ui-md-10 ui-g-nopad">
          <div class="ui-g-12 ui-md-3">
            {{ "DESCRIPTION" | translate }}
          </div>
          <div class="ui-g-12 ui-md-3">
            {{ "UNIQUE_ID" | translate }}
          </div>
          <div class="ui-g-12 ui-md-3">
            {{ "DATA_TYPE" | translate }}
          </div>
          <div class="ui-g-12 ui-md-3">
            {{ "VALUE" | translate }}
          </div>
        </div>
      </div>

      <div class="ui-g" *ngFor="let ctrl of configForm.controls; let i = index">
        <div class="ui-g-12 ui-md-10 ui-g-nopad">
          <div class="ui-g-12 ui-md-3">
            <sc-text-input
              controlName="description"
              [form]="ctrl"
              [label]="'DESCRIPTION' | translate"
              [showLabel]="false"
            ></sc-text-input>
          </div>

          <div class="ui-g-12 ui-md-3">
            <sc-text-input
              controlName="unique_id"
              [form]="ctrl"
              [label]="'UNIQUE_ID' | translate"
              [showLabel]="false"
            ></sc-text-input>
          </div>

          <div class="ui-g-12 ui-md-3">
            <sc-dropdown
              controlName="type"
              [form]="ctrl"
              [label]="'DATA_TYPE' | translate"
              [showLabel]="false"
              [options]="selectItems?.dataTypes"
              [showClear]="false"
            ></sc-dropdown>
          </div>

          <div class="ui-g-12 ui-md-3">
            <sc-text-input
              controlName="value"
              [form]="ctrl"
              [label]="'VALUE' | translate"
              [showLabel]="false"
            ></sc-text-input>
          </div>
        </div>

        <div class="ui-g-12 ui-md-2">
          <button
            pButton
            class="ui-button-text"
            [icon]="'fa fa-minus'"
            (click)="removeConfig(i)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>
