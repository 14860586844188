import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LazyloadOptions } from '../models/lazyload-options';
import { SendOptions } from '../models/send-options';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class LiveRequestsService {
  private apiUrl = config().apiUrl + '/_backend';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  /**
   * Create Live Request
   * @param options
   */
  sendLiveRequest(options: SendOptions) {
    const apiUrl = this.apiUrl + '/liveRequest';
    delete options.sessionId;
    options.user = this.sharedService.userId;
    return this.httpClient.post(apiUrl, options);
  }

  /**
   * Get Live Request data
   * @param sessionId
   */
  getLiveRequestResult(sessionId: string) {
    const apiUrl = this.apiUrl + '/liveRequest/' + sessionId;
    return this.httpClient.get(apiUrl);
  }

  /**
   * Get Live Request List (Lazyload)
   * @param options
   */
  getLiveRequestsLazyload(options: LazyloadOptions) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    const apiUrl = this.apiUrl + '/liveRequest/lazy' + queryParams;
    return this.httpClient.get(apiUrl);
  }
}
