import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem, TreeNode } from 'primeng/api';

import { RuleMassEditOptions } from '../models/rule-mass-edit-options';

@Component({
  selector: 'sc-options-form',
  templateUrl: 'options-form.component.html'
})
export class OptionsFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input()
  selectItems: { [key: string]: (SelectItem | TreeNode)[] };
  @Output()
  submitted = new EventEmitter<RuleMassEditOptions>();

  constructor() {}

  ngOnInit() {}

  onSubmit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
  }
}
