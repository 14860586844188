import { Action } from '@ngrx/store';

// INITIAL_DATA
export const LOAD_INITIAL_DATA = '[Initial Data] Load Initial Data';
export const LOAD_INITIAL_DATA_FAIL = '[Initial Data] Load Initial Data Fail';
export const LOAD_INITIAL_DATA_SUCCESS = '[Initial Data] Load Initial Data Success';

export class LoadInitalData implements Action {
  readonly type = LOAD_INITIAL_DATA;
}

export class LoadInitalDataFail implements Action {
  readonly type = LOAD_INITIAL_DATA_FAIL;
  constructor(public payload: any) { }
}

export class LoadInitalDataSuccess implements Action {
  readonly type = LOAD_INITIAL_DATA_SUCCESS;
  constructor(public payload: any) { }
}

// ACTION TYPES
export type InitialDataAction = LoadInitalData | LoadInitalDataFail | LoadInitalDataSuccess;
