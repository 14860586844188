<span
  class="badge summary-item"
  [ngClass]="itemClass"
  [pTooltip]="tooltip"
  tooltipPosition="top"
  appendTo="body"
  [escape]="false"
>
  <span *ngIf="!icon && !prefix && !value">&nbsp;</span>
  <i *ngIf="icon" class="summary-icon fa-fw" [ngClass]="icon"></i>
  <span *ngIf="value && prefix">{{ prefix }}</span>
  <span *ngIf="!isBoolean" class="summary-value">
    {{ value }}
    <span *ngIf="value && suffix" class="summary-suffix"> {{ suffix }} </span>

    <span *ngIf="diffValue" class="diff-value">
      <i [ngClass]="diffIcon"></i>
      <!-- {{ diffValue | number:'1.0-2' }} -->
    </span>
  </span>
</span>
