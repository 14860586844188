<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!--Header-->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6 ui-g-nopad">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>

          <div class="ui-g-6 text-right">
            <p-toggleButton
              formControlName="isActive"
              offIcon="fa fa-square-o"
              offLabel="{{ 'ACTIVE' | translate }}"
              onIcon="fa fa-check-square-o"
              onLabel="{{ 'ACTIVE' | translate }}"
            ></p-toggleButton>
          </div>
        </div>
      </p-header>

      <!--Body-->
      <div class="ui-g ui-g-nopad">
        <!-- TEASER_IMAGE_PATH FIELD -->
        <div class="ui-g-12">
          <sc-file-input
            controlName="teaserImagePath"
            [form]="form"
            label="{{ 'TEASER_IMAGE' | translate }}"
          ></sc-file-input>
        </div>

        <!-- PUBLISH_FROM FIELD -->
        <div class="ui-g-12">
          <sc-datetime-input
            controlName="publishFrom"
            [form]="form"
            label="{{ 'PUBLISH_FROM' | translate }}"
          ></sc-datetime-input>
        </div>

        <!-- PUBLISH_TO FIELD -->
        <div class="ui-g-12">
          <sc-datetime-input
            controlName="publishTo"
            [form]="form"
            label="{{ 'PUBLISH_TO' | translate }}"
          ></sc-datetime-input>
        </div>
      </div>

      <div class="ui-g mt-3">
        <!-- TITLE -->
        <div class="ui-g-12">
          <div class="ui-g-10 p-0">
            <h2 class="m-0">{{ 'TITLE' | translate }}</h2>
          </div>

          <div class="ui-g-2 text-right">
            <button pButton
              type="button"
              class="ui-button-success"
              icon="fa fa-plus"
              pTooltip="{{ 'ADD' | translate }} {{ 'TITLE' | translate }}"
              (click)="addMultiLanguage('title')"
            ></button>
          </div>

          <div class="ui-g-12" formArrayName="title">
            <ul class="template-menu">
              <li *ngFor="let ctrl of title.controls; let i=index"
                class="template-menu-item"
                [ngClass]="{'active': currentTitleTemplate === i}"
              >
                <a (click)="currentTitleTemplate = i" class="sc-clickable">
                  <span [ngClass]="flagIcon(ctrl.get('locale').value)"></span>
                  {{ (ctrl.get('locale').value | uppercase) || ('NEW' | translate) }}
                </a>
              </li>
            </ul>

            <div *ngFor="let ctrl of title.controls; let i=index" class="template-form">
              <multi-language-form
                *ngIf="i === currentTitleTemplate"
                [group]="ctrl"
                [groupId]="i"
                [optionData]="optionData"
                (onRemove)="removeMultiLanguage('title', i)"
              ></multi-language-form>
            </div>
          </div>
        </div>

        <!-- CONTENT -->
        <div class="ui-g-12">
          <div class="ui-g-10 p-0">
            <h2 class="m-0">{{ 'CONTENT' | translate }}</h2>
          </div>

          <div class="ui-g-2 text-right">
            <button pButton
              type="button"
              class="ui-button-success"
              icon="fa fa-plus"
              pTooltip="{{ 'ADD' | translate }} {{ 'CONTENT' | translate }}"
              (click)="addMultiLanguage('content')"
            ></button>
          </div>

          <div class="ui-g-12" formArrayName="content">
            <ul class="template-menu">
              <li *ngFor="let ctrl of content.controls; let i=index"
                class="template-menu-item"
                [ngClass]="{'active': currentContentTemplate === i}"
              >
                <a (click)="currentContentTemplate = i" class="sc-clickable">
                  <span [ngClass]="flagIcon(ctrl.get('locale').value)"></span>
                  {{ (ctrl.get('locale').value | uppercase) || ('NEW' | translate) }}
                </a>
              </li>
            </ul>

            <div *ngFor="let ctrl of content.controls; let i=index" class="template-form">
              <multi-language-form
                *ngIf="i === currentContentTemplate"
                [group]="ctrl"
                [groupId]="i"
                [optionData]="optionData"
                (onRemove)="removeMultiLanguage('content', i)"
              ></multi-language-form>
            </div>
          </div>
        </div>

        <!-- TEASER_TEXT -->
        <div class="ui-g-12">
          <div class="ui-g-10 p-0">
            <h2 class="m-0">{{ 'TEASER_TEXT' | translate }}</h2>
          </div>

          <div class="ui-g-2 text-right">
            <button pButton
              type="button"
              class="ui-button-success"
              icon="fa fa-plus"
              pTooltip="{{ 'ADD' | translate }} {{ 'TEASER_TEXT' | translate }}"
              (click)="addMultiLanguage('teaserText')"
            ></button>
          </div>

          <div class="ui-g-12" formArrayName="teaserText">
            <ul class="template-menu">
              <li *ngFor="let ctrl of teaserText.controls; let i=index"
                class="template-menu-item"
                [ngClass]="{'active': currentTeaserTemplate === i}"
              >
                <a (click)="currentTeaserTemplate = i" class="sc-clickable">
                  <span [ngClass]="flagIcon(ctrl.get('locale').value)"></span>
                  {{ (ctrl.get('locale').value | uppercase) || ('NEW' | translate) }}
                </a>
              </li>
            </ul>

            <div *ngFor="let ctrl of teaserText.controls; let i=index" class="template-form">
              <multi-language-form
                *ngIf="i === currentTeaserTemplate"
                [group]="ctrl"
                [groupId]="i"
                [optionData]="optionData"
                (onRemove)="removeMultiLanguage('teaserText', i)"
              ></multi-language-form>
            </div>
          </div>
        </div>

        <!-- PUSH_NOTIFICATION_TEXT -->
        <div class="ui-g-12">
          <div class="ui-g-10 p-0">
            <h2 class="m-0">{{ 'PUSH_NOTIFICATION_TEXT' | translate }}</h2>
          </div>

          <div class="ui-g-2 text-right">
            <button pButton
              type="button"
              class="ui-button-success"
              icon="fa fa-plus"
              pTooltip="{{ 'ADD' | translate }} {{ 'PUSH_NOTIFICATION_TEXT' |translate }}"
              (click)="addMultiLanguage('pushNotificationText')"
            ></button>
          </div>

          <div class="ui-g-12" formArrayName="pushNotificationText">
            <ul class="template-menu">
              <li *ngFor="let ctrl of pushNotificationText.controls; let i=index"
                class="template-menu-item"
                [ngClass]="{'active': currentPushNotificationTemplate === i}"
              >
                <a (click)="currentPushNotificationTemplate = i" class="sc-clickable">
                  <span [ngClass]="flagIcon(ctrl.get('locale').value)"></span>
                  {{ (ctrl.get('locale').value | uppercase) || ('NEW' | translate) }}
                </a>
              </li>
            </ul>

            <div *ngFor="let ctrl of pushNotificationText.controls; let i=index" class="template-form">
              <multi-language-form
                *ngIf="i === currentPushNotificationTemplate"
                [group]="ctrl"
                [groupId]="i"
                [optionData]="optionData"
                (onRemove)="removeMultiLanguage('pushNotificationText', i)"
              ></multi-language-form>
            </div>
          </div>
        </div>

        <!-- PUSH_NOTIFICATION_SCHEDULER -->
        <div class="ui-g-12">
          <div class="ui-g-10 p-0">
            <h2 class="m-0">{{ 'PUSH_NOTIFICATION_SCHEDULER' | translate }}</h2>
          </div>

          <div class="ui-g-2 text-right">
            <button pButton
              type="button"
              class="ui-button-success"
              pTooltip="{{ 'ADD' | translate }} {{ 'PUSH_NOTIFICATION_SCHEDULER' | translate }}"
              icon="fa fa-plus"
              (click)="addPushNotificationScheduler()"
            ></button>
          </div>

          <div class="ui-g-12" formArrayName="pushNotificationScheduler">
            <div *ngFor="let ctrl of pushNotificationScheduler.controls; let i=index" [formGroupName]="i">
              <push-notification-scheduler-form
                [group]="ctrl"
                [groupId]="i"
                (onRemove)="removePushNotificationScheduler(i)"
              ></push-notification-scheduler-form>
            </div>
          </div>
        </div>

        <!-- Error Message -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>
      </div>

      <!--Footer-->
      <p-footer>
        <div class="sc-flex">
          <div class="sc-flex grow-1">
            <sc-confirm-trigger
              *ngIf="editMode && !formData?.isDeleted"
              buttonClass="ui-button-danger"
              buttonIcon="fa fa-trash"
              buttonLabel="{{ 'DELETE' | translate }}"
              (onAccept)="delete()"
            ></sc-confirm-trigger>
          </div>

          <div class="sc-flex grow-1 justify-content-end">
            <button
              type="button"
              pButton
              class="ui-button-secondary"
              (click)="dismissModal('cancel')"
              label="{{ 'CANCEL' | translate }}"
            ></button>

            <sc-submit-button
              *ngIf="!formData?.isDeleted || formData?.isActive"
              buttonClass="ui-button-success"
              [disabled]="!form.valid || form.pristine"
              [pending]="isSubmitting"
              (submitted)="submit()"
            ></sc-submit-button>
          </div>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
