<div class="widget-header">
  <div class="sc-flex align-items-center">
    <h4 class="widget-title">
      <!-- <i class="fas fa-location-arrow fa-fw green-text"></i> -->
      {{ floor?.description }}
    </h4>
  </div>

  <div class="sc-flex justify-content-end align-items-center">
    <!-- SAVE BUTTON -->
    <button
      *ngIf="!readOnlyMode"
      type="button"
      pButton
      class="ui-button-icon-only mr-1"
      icon="fas fa-save"
      (click)="saveChanges()"
      [pTooltip]="'SAVE' | translate"
      tooltipPosition="top"
      appendTo="body"
    ></button>

    <!-- LAYER BUTTON -->
    <button
      type="button"
      pButton
      class="ui-button-icon-only layer-menu-trigger"
      icon="fas fa-layer-group"
      (click)="showLayerMenu = !showLayerMenu"
      [pTooltip]="'FLOORPLAN_LAYERS' | translate"
      tooltipPosition="top"
      appendTo="body"
    ></button>

    <!-- LAYER DROPDOWN -->
    <ul class="layer-menu" *ngIf="showLayerMenu">
      <li class="layer-menu-item" *ngFor="let layer of selectItems?.layers">
        <div class="layer-menu-item-label" (click)="toggleLayer(layer)">
          <span
            class="checkmark fa fa-fw"
            [ngClass]="layerMenuCheckMark(layer)"
          ></span>
          <span class="text">{{ layer.label }}</span>
        </div>

        <ul class="layer-sub-menu" *ngIf="layer?.children">
          <li
            class="layer-sub-menu-item"
            *ngFor="let childLayer of layer?.children"
          >
            <div
              class="layer-menu-item-label"
              (click)="toggleLayer(childLayer)"
            >
              <span
                class="checkmark fa fa-fw"
                [ngClass]="layerMenuCheckMark(childLayer)"
              ></span>
              <span class="text">{{ childLayer.label }}</span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<div class="workspace-wrapper" oncontextmenu="return false;">
  <canvas #workspace></canvas>

  <ul class="contextmenu" #addObjectMenu>
    <li class="menu-item" (click)="addObjectMenuHandler(objectTypes.Location)">
      {{ "FLOORPLAN_ADD_LOCATION" | translate }}
    </li>
  </ul>

  <ul class="contextmenu" #assignedDeviceMenu>
    <!-- <ng-container *ngIf="selectedObject?.type === objectTypes.Device"> -->
    <li
      class="menu-item"
      (click)="deviceMenuHandler(deviceContextMenu.Details)"
      *ngIf="isMenuEnabled(deviceContextMenu.Details)"
    >
      {{ "FLOORPLAN_DETAILS" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="deviceMenuHandler(deviceContextMenu.ShowManagedDevices)"
      *ngIf="isMenuEnabled(deviceContextMenu.ShowManagedDevices)"
    >
      {{ "FLOORPLAN_SHOW_MANAGED_DEVICES" | translate }}
    </li>

    <li
      class="menu-item"
      (click)="deviceMenuHandler(deviceContextMenu.Move)"
      *ngIf="isMenuEnabled(deviceContextMenu.Move)"
    >
      {{ "FLOORPLAN_MOVE" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="deviceMenuHandler(deviceContextMenu.Unassign)"
      *ngIf="isMenuEnabled(deviceContextMenu.Unassign)"
    >
      {{ "FLOORPLAN_UNASSIGN" | translate }}
    </li>
    <!-- </ng-container> -->
  </ul>

  <ul class="contextmenu" #unassignedDeviceMenu>
    <!-- <ng-container *ngIf="selectedObject?.type === objectTypes.Device"> -->
    <li
      class="menu-item"
      (click)="deviceMenuHandler(deviceContextMenu.Assign)"
      *ngIf="isMenuEnabled(deviceContextMenu.Assign)"
    >
      {{ "FLOORPLAN_ASSIGN" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="deviceMenuHandler(deviceContextMenu.Move)"
      *ngIf="isMenuEnabled(deviceContextMenu.Move)"
    >
      {{ "FLOORPLAN_MOVE" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="deviceMenuHandler(deviceContextMenu.Delete)"
      *ngIf="isMenuEnabled(deviceContextMenu.Delete)"
    >
      {{ "FLOORPLAN_DELETE" | translate }}
    </li>
    <!-- </ng-container> -->
  </ul>

  <ul class="contextmenu" #assignedLocationMenu>
    <!-- <ng-container *ngIf="selectedObject?.type === objectTypes.Location"> -->
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.Details)"
      *ngIf="isMenuEnabled(locationContextMenu.Details)"
    >
      {{ "FLOORPLAN_DETAILS" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.MoveAndResize)"
      *ngIf="isMenuEnabled(locationContextMenu.MoveAndResize)"
    >
      {{ "FLOORPLAN_MOVE_AND_RESIZE" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.Reshape)"
      *ngIf="isMenuEnabled(locationContextMenu.Reshape)"
    >
      {{ "FLOORPLAN_RESHAPE" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.Unassign)"
      *ngIf="isMenuEnabled(locationContextMenu.Unassign)"
    >
      {{ "FLOORPLAN_UNASSIGN" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="addObjectMenuHandler(objectTypes.Device)"
      *ngIf="isMenuEnabled(locationContextMenu.Details)"
    >
      {{ "FLOORPLAN_ADD_DEVICE" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="addObjectMenuHandler(objectTypes.Location)"
      *ngIf="isMenuEnabled(locationContextMenu.Details)"
    >
      {{ "FLOORPLAN_ADD_CHILD_LOCATION" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.ApplyRule)"
      *ngIf="isMenuEnabled(locationContextMenu.ApplyRule)"
    >
      {{ "FLOORPLAN_APPLY_RULE" | translate }}
    </li>
    <!-- </ng-container> -->
  </ul>

  <ul class="contextmenu" #unassignedLocationMenu>
    <!-- <ng-container *ngIf="selectedObject?.type === objectTypes.Location"> -->
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.Assign)"
      *ngIf="isMenuEnabled(locationContextMenu.Assign)"
    >
      {{ "FLOORPLAN_ASSIGN" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.MoveAndResize)"
      *ngIf="isMenuEnabled(locationContextMenu.MoveAndResize)"
    >
      {{ "FLOORPLAN_MOVE_AND_RESIZE" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.Reshape)"
      *ngIf="isMenuEnabled(locationContextMenu.Reshape)"
    >
      {{ "FLOORPLAN_RESHAPE" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.Delete)"
      *ngIf="isMenuEnabled(locationContextMenu.Delete)"
    >
      {{ "FLOORPLAN_DELETE" | translate }}
    </li>
    <!-- </ng-container> -->
  </ul>

  <ul class="contextmenu" #locationEditMenu>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.AddPoint)"
    >
      {{ "FLOORPLAN_ADD_POINT" | translate }}
    </li>
    <li
      class="menu-item"
      (click)="locationMenuHandler(locationContextMenu.RemovePoint)"
    >
      {{ "FLOORPLAN_REMOVE_POINT" | translate }}
    </li>
  </ul>
</div>
