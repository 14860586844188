import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { DoorActivityComponent } from './door-activity.component';

const COMPONENTS: any[] = [DoorActivityComponent];

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: []
})
export class DoorActivityModule {}
