import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RuleCategoriesTableComponent } from './rule-categories-table/rule-categories-table.component';
import { RuleCategoryActionCellComponent } from './rule-category-action-cell/rule-category-action-cell.component';
import { RuleCategoryFormComponent } from './rule-category-form/rule-category-form.component';
import { RuleCategoriesService } from './services/rule-categories.service';
import { RuleCategoryFormService } from './services/rule-category-form.service';

@NgModule({
  imports: [SharedModule],
  declarations: [RuleCategoriesTableComponent, RuleCategoryActionCellComponent, RuleCategoryFormComponent],
  entryComponents: [RuleCategoriesTableComponent, RuleCategoryActionCellComponent, RuleCategoryFormComponent],
  providers: [RuleCategoriesService, RuleCategoryFormService],
})
export class RuleCategoriesModule {}
