import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { DeviceDetails } from '@widgets/devices/models/device-details';
import { ModalConfig } from '@sc/modal/modal-config';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-details',
  templateUrl: 'device-details.component.html',
  styleUrls: ['device-details.component.scss'],
})
export class DeviceDetailsComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  detailFormConfig: ModalConfig;
  isFetchingDataset = true;
  deviceDetails: { key: string; value: any }[] = [];
  selectedDevice: DeviceDetails;

  private subscribers: { [key: string]: any } = {};

  constructor(private store: Store<fromStore.State>, private sharedService: SharedService) {}

  ngOnInit() {
    // get device details
    this.subscribers.deviceDetails = this.store.select(fromStore.getDeviceDetials).subscribe((result) => {
      if (result) {
        this.selectedDevice = result;

        this.detailFormConfig = {
          name: 'DeviceForm',
          data: this.selectedDevice,
          options: { modalTitle: 'DEVICE_FORM_TITLE', fullScreen: true },
        };

        this.isFetchingDataset = false;
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  onUpdateDetailsSuccess(data: { result: any }) {
    this.selectedDevice = { ...this.selectedDevice, ...data.result };
  }

  fetchDetails() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.store.dispatch(new fromStore.LoadDevice(this.selectedDevice.id));
    }
  }

  gotoLocation() {
    const path = ['locations', this.selectedDevice.locationId];
    this.store.dispatch(new fromStore.Go({ path }));
  }
}
