import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class CeosIdentityService {
  private apiUrl = config().apiUrl + '/_integrator/identity';

  constructor(private httpClient: HttpClient) {}

  assignSite(ceosId, siteId) {
    return this.httpClient.post(`${this.apiUrl}/assignSite`, { ceosId, siteId });
  }

  unassignSite(ceosId) {
    return this.httpClient.post(`${this.apiUrl}/unassignSite`, { ceosId });
  }

  linkAutomation(ceosId, automationId) {
    return this.httpClient.post(`${this.apiUrl}/linkAutomation`, { ceosId, automationId });
  }

  unlinkAutomation(ceosId) {
    return this.httpClient.post(`${this.apiUrl}/unlinkAutomation`, { ceosId });
  }
}
