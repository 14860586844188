import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'sc-field-label',
  template: `
    <label [for]="for" class="sc-form-label" [ngClass]="{ 'sc-mandatory': isRequired }">
      {{ title }}
      <span *ngIf="isRequired" class="red-text">*</span>
      <span *ngIf="subtitle" class="red-text">{{ subtitle }}</span>
      <i *ngIf="description"
        class="fa fa-info-circle grey-text"
        [pTooltip]="description"
        tooltipPosition="top"
        appendTo="body"
      ></i>
      <i *ngIf="warning"
        class="fa fa-exclamation-triangle yellow-text"
        [pTooltip]="warning"
        tooltipPosition="top"
        appendTo="body"
      ></i>
    </label>
  `
})
export class SCFieldLabelComponent {
  @Input()
  for: string;
  @Input()
  fieldControl: FormControl;
  @Input()
  subtitle: string;
  @Input()
  title: string;
  @Input()
  description: string;
  @Input()
  warning: string;

  get isRequired() {
    const control = this.fieldControl;
    if (control.validator) {
      const tester: any = '';
      const validator = control.validator(tester);
      return validator && validator.required;
    }
    return false;
  }
}
