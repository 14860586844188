import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { Location } from '../models/location';
import { Site } from '@widgets/sites/models/site';
import { DeviceType } from '@app/widgets/device-types/models/device-type';
import { DeviceModel } from '@app/widgets/device-models/models/device-model';

import { LocationsService } from '../services/locations.service';
import { LocationHistoryLogsService } from '../services/location-history-logs.service';
import { SCTableService } from '@sc/table/table.service';
import { LocationPropertiesService } from '@widgets/location-properties/services/location-properties.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { DeviceTypesService } from '@app/widgets/device-types/services/device-types.service';
import { DeviceModelsService } from '@app/widgets/device-models/services/device-models.service';
import { SharedService } from '@shared/shared.service';
import { CompaniesService } from '@widgets/companies/services/companies.service';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { LocationActionCellComponent } from '../location-action-cell/location-action-cell.component';
import { LocationSummaryCellComponent } from '../location-summary-cell/location-summary-cell.component';

@Component({
  selector: 'sc-locations-table',
  templateUrl: './locations-table.component.html',
})
export class LocationsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  companies: any;
  customSummaryModalConfig: ModalConfig;
  dataset: Location[];
  devices: any[];
  deviceList: any[];
  deviceTypes: DeviceType[];
  deviceModels: DeviceModel[];
  formConfig: ModalConfig;
  historyLogs: any[];
  isFetchingDataset: boolean;
  isFetchingLatestHistory: boolean;
  locationProperties: any[];
  locationTypes: any;
  selectedLocations: Location[];
  selectedSite: Site;
  summaryConfig: any;
  table: GridOptions;
  tableTitle: string;
  backendVariant: string;

  private isAdmin: any;
  private intervals: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private locationsService: LocationsService,
    private locationHistoryLogsService: LocationHistoryLogsService,
    private locationPropertiesService: LocationPropertiesService,
    private companiesService: CompaniesService,
    private sharedService: SharedService,
    private devicesService: DevicesService,
    private deviceTypesService: DeviceTypesService,
    private deviceModelsService: DeviceModelsService,
    private tableService: SCTableService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    // table title
    this.tableTitle = 'LOCATIONS';
    // table columns
    this.columns = this.createColumns();
    // create form config
    this.formConfig = {
      name: 'LocationForm',
      options: {
        modalTitle: 'LOCATION_FORM_TITLE',
        requiredPermission: 'location_c',
      },
    };

    // pass custom summary modal config into Table module via config
    this.summaryConfig = this.config.customOptions.locationSummaryConfig;
    this.config.customSummaryModalConfig = {
      name: 'CustomSummaryForm',
      options: { modalTitle: 'LOCATION_SUMMARY_FORM_TITLE' },
      data: this.summaryConfig,
    };

    this.subscribers.getBackendVariant = this.store.pipe(select(fromStore.getUserVariant)).subscribe((result) => {
      this.backendVariant = result;
    });

    // Get Selected Site
    this.subscribers.getSelectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        clearTimeout(this.timeouts.GET_DATASET);
        this.timeouts.GET_DATASET = setTimeout(() => {
          this.fetchDataset();
          this.fetchDevices();
          this.selectedLocations = [];
        }, 500);
      }
    });

    this.isAdmin = this.sharedService.userRoles && this.sharedService.userRoles.includes('admin');
    if (this.isAdmin) {
      this.fetchCompanies();
    } else {
      this.fetchCompanies(this.sharedService.userCompanyId);
    }

    this.subscribers.getLocationTypes = this.store.pipe(select(fromStore.getLocationTypes)).subscribe((result) => {
      this.locationTypes = result;
    });

    this.fetchLocationProperties();
    this.fetchDeviceTypes();
    this.fetchDeviceModels();
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearTimeouts(this.timeouts);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  get enabledDeviceCompare() {
    if (this.selectedLocations && this.selectedLocations.length > 0) {
      return true;
    }
    return false;
  }

  getLocationTypesName(locationTypeId: string) {
    const tmpCompanies = [...this.locationTypes];
    const info = tmpCompanies.find((d) => d.id === locationTypeId);
    if (info) {
      return info.name;
    } else {
      return '';
    }
  }

  fetchDevices() {
    if (this.selectedSite) {
      this.subscribers.GET_DEVICES = this.devicesService
        .getDevices({ siteId: this.selectedSite.id })
        .subscribe((result: any) => {
          this.devices = result.data;
        });
    }
  }

  fetchDeviceTypes() {
    this.subscribers.GET_DEVICE_TYPES = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data;
    });
  }

  private fetchCompanies(companyId?: number) {
    this.subscribers.fetchCompanies = this.companiesService.getCompanies(companyId).subscribe((result: any) => {
      this.companies = companyId ? [result.data] : result.data;
    });
  }

  private fetchDeviceModels() {
    this.subscribers.fetchDeviceModels = this.deviceModelsService.getDeviceModels().subscribe((result: any) => {
      this.deviceModels = result.data;
    });
  }

  fetchLocationProperties() {
    this.subscribers.GET_LOCATION_PROPS = this.locationPropertiesService
      .getLocationProperties()
      .subscribe((result: any) => {
        this.locationProperties = result.data;
      });
  }

  fetchDataset() {
    if (!this.isFetchingDataset && this.selectedSite) {
      this.isFetchingDataset = true;
      if (this.table && this.table.api) {
        this.table.api.showLoadingOverlay();
      }
      this.stopAutoUpdate();

      const options = {
        siteId: this.selectedSite.id,
        columns: [
          ...this.config.customOptions.visibleColumns,
          'id',
          'locationTypeId',
          'isDoNotDisturbModeActive',
          'isMakeUpMyRoomRequested',
          'isOutOfOrder',
          'maxPeople',
          'isActive',
          'isDeleted',
        ].join(),
        tree: true,
        minimize: true,
      };
      this.subscribers.GET_DATASET = this.locationsService.getLocations(options).subscribe((result: any) => {
        if (result.mappingKeys) {
          this.dataset = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);
        } else {
          this.dataset = result.data;
        }

        this.isFetchingDataset = false;
        if (this.table && this.table.api) {
          this.table.api.hideOverlay();
        }
        this.startAutoUpdate();
      });
    }
  }

  fetchLatestHistory() {
    if (!this.isFetchingDataset && !this.isFetchingLatestHistory && this.selectedSite) {
      this.isFetchingLatestHistory = true;
      const options = {
        latestLog: true,
        limit: 5000,
      };
      this.subscribers.GET_LATEST_HISTORY = this.locationHistoryLogsService
        .getHistoryLogs(options)
        .subscribe((result: any) => {
          this.isFetchingLatestHistory = false;
          this.historyLogs = result;
          this.updateLatestHistory();
        });
    }
  }

  private updateLatestHistory() {
    if (this.table && !this.isFetchingDataset && this.historyLogs && this.historyLogs.length) {
      const key = this.isCeosVariant ? 'idx' : 'id';
      const itemsToUpdate = this.historyLogs.map((history) => {
        const location = history;
        location[key] = this.isCeosVariant ? location.locationId : +location.locationId;
        delete location.locationId;

        if (typeof history.occupied === 'undefined') {
          location.occupied = null;
        }
        if (typeof history.acStatus === 'undefined') {
          location.acStatus = null;
        }

        // const bool = false;
        // location.occupied = bool;
        // location.doorLocked = bool;
        // location.doorOpen = bool;
        // location.windowOpen = bool;
        // location.numRules = 0;
        // location.activeRules = [];
        // location.humidity = 66.9;
        // location.temperature = 27.3;
        // location.consumption = 274;
        // location.avgParticulateMatter = 23.7;
        // location.numPeople = 6;
        // location.numPeopleTooMany = 2;
        // location.numSocialDistanceViolations = 3;
        // location.acMode = 'o';
        // location.acSetpoint = 23.5;
        // location.acFanspeed = 'l';
        // location.brightness = 230;
        // location.curtainsOpen = bool;
        // location.lightsOn = bool;

        return location;
      });
      this.tableService.updateTableRowV2(this.table, itemsToUpdate, key);
    }
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.getRowNodeId = (data) => {
      if (this.isCeosVariant) {
        return data.idx;
      } else {
        return data.id;
      }
    };
    this.table.onSelectionChanged = () => {
      if (this.table.api) {
        const selected = this.table.api.getSelectedNodes();
        if (selected && selected.length) {
          this.selectedLocations = selected.map((row) => row.data.id);
          this.getSelectedLocationsDevices();
        } else {
          this.selectedLocations = [];
          this.deviceList = [];
        }
      }
    };
    this.table.context.containerComponent = this;
    this.updateLatestHistory();
  }

  getSelectedLocationsDevices() {
    if (this.selectedSite) {
      this.deviceList = [];
      if (this.devices && this.devices.length) {
        this.devices.forEach((device) => {
          if (
            this.selectedLocations.find((st) => st === device.locationId) &&
            !device.isDeleted &&
            device.deviceTypeKey !== 'a' &&
            device.deviceTypeKey !== 'g'
          ) {
            this.deviceList.push(device);
          }
        });
      }
    }
  }

  get deviceComparisonModalConfig(): ModalConfig {
    return {
      name: 'DeviceLogsComparison',
      data: {
        deviceModels: this.deviceModels,
        deviceTypes: this.deviceTypes,
        devices: this.deviceList,
      },
      options: {
        modalTitle: 'DEVICE_LOGS_COMPARISON_TITLE',
        fullScreen: true,
      },
    };
  }

  // Statistics
  get statisticsFormConfig(): ModalConfig {
    return {
      name: 'LocationStatistics',
      options: {
        modalTitle: 'STATISTICS_OF_SITE',
        modalTitleParams: {
          value: this.selectedSite.name,
        },
        fullScreen: true,
      },
    };
  }

  get bulkModificationFormConfig(): ModalConfig {
    return {
      name: 'BulkModificationForm',
      data: {
        table: 'locations',
        selected: this.selectedLocations,
      },
      options: {
        modalTitle: 'BULK_MODIFICATION_FORM_TITLE',
        fullScreen: true,
      },
    };
  }

  get locationGeneratorModalConfig(): ModalConfig {
    return {
      name: 'LocationGeneratorForm',
      options: {
        modalTitle: 'LOCATION_GENERATOR_FORM_TITLE',
        fullScreen: true,
      },
    };
  }

  // get ruleMassEditFormConfig() {
  //   return {
  //     name: 'RuleMassEditForm',
  //     data: {
  //       selectedLocations: this.selectedLocations,
  //       deviceTypes: this.deviceTypes,
  //       locationProperties: this.locationProperties
  //     },
  //     options: {
  //       modalTitle: 'RULE_MASS_EDIT_FORM_TITLE',
  //       fullScreen: true
  //     }
  //   };
  // }

  private startAutoUpdate() {
    this.isFetchingLatestHistory = false;
    this.fetchLatestHistory();
    clearInterval(this.intervals.GET_LATEST_HISTORY);
    this.intervals.GET_LATEST_HISTORY = setInterval(() => this.fetchLatestHistory(), 10000);
  }

  private stopAutoUpdate() {
    clearInterval(this.intervals.GET_LATEST_HISTORY);
    this.intervals.GET_LATEST_HISTORY = null;
    this.isFetchingLatestHistory = false;
  }

  updateCustomSummary(event) {
    this.summaryConfig = event;
    this.config.customSummaryModalConfig.data = this.summaryConfig;
  }

  // private getMappingValue(params, deviceTypeKey) {
  //   let currentValue = params.data[params.colDef.colId];
  //   if (currentValue && this.deviceTypes) {
  //     // find device type
  //     const model = this.deviceTypes.find((d) => d.key === deviceTypeKey);
  //     if (model && model.mappingValues && model.mappingValues.length) {
  //       // find mapping value
  //       for (let i = 0; i < model.mappingValues.length; i++) {
  //         if (model.mappingValues[i].key === currentValue) {
  //           currentValue = model.mappingValues[i].name;
  //           break;
  //         }
  //       }
  //     }
  //   }
  //   return currentValue;
  // }

  private createColumns() {
    return [
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { suppressCount: true },
        checkboxSelection: (params) => !params.data.isDelete,
      },

      {
        colId: 'summary',
        headerName: 'SUMMARY',
        cellRendererFramework: LocationSummaryCellComponent,
        suppressSorting: true,
        suppressFilter: true,
      },
      {
        colId: 'locationTypeName',
        headerName: 'LOCATION_TYPE',
        valueFormatter: (params) => this.getLocationTypesName(params.data.locationTypeId),
        valueGetter: (params) => this.getLocationTypesName(params.data.locationTypeId),
      },
      // {
      //   colId: 'numRules',
      //   headerName: 'NUM_RULES',
      //   field: 'numRules',
      //   hide: true,
      // },
      // {
      //   colId: 'acFanspeed',
      //   headerName: 'AC_FANSPEED',
      //   field: 'acFanspeed',
      //   valueFormatter: (params) => this.getMappingValue(params, 'acf'),
      //   valueGetter: (params) => this.getMappingValue(params, 'acf'),
      //   hide: true,
      // },
      // {
      //   colId: 'acFanspeedLoggedAt',
      //   headerName: 'AcFanspeedLoggedAt',
      //   field: 'acFanspeedLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'acMode',
      //   headerName: 'AC_MODE',
      //   field: 'acMode',
      //   valueFormatter: (params) => this.getMappingValue(params, 'acm'),
      //   valueGetter: (params) => this.getMappingValue(params, 'acm'),
      //   hide: true,
      // },
      // {
      //   colId: 'acModeLoggedAt',
      //   headerName: 'AcModeLoggedAt',
      //   field: 'acModeLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'acSetpoint',
      //   headerName: 'AC_SETPOINT',
      //   field: 'acSetpoint',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'acSetpointLoggedAt',
      //   headerName: 'AcSetpointLoggedAt',
      //   field: 'acSetpointLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'acStatus',
      //   headerName: 'AC_STATUS',
      //   field: 'acStatus',
      //   valueFormatter: (params) => this.getMappingValue(params, 'acs'),
      //   valueGetter: (params) => this.getMappingValue(params, 'acs'),
      //   hide: true,
      // },
      // {
      //   colId: 'acStatusLoggedAt',
      //   headerName: 'AcStatusLoggedAt',
      //   field: 'acStatusLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'airQuality',
      //   headerName: 'AIR_QUALITY',
      //   field: 'airQuality',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + 'pm2.5' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'airQualityLoggedAt',
      //   headerName: 'AirQualityLoggedAt',
      //   field: 'airQualityLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'brightness',
      //   headerName: 'BRIGHTNESS',
      //   field: 'brightness',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + 'lux' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'brightnessLoggedAt',
      //   headerName: 'BrightnessLoggedAt',
      //   field: 'brightnessLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'consumption',
      //   headerName: 'CONSUMPTION',
      //   field: 'consumption',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + 'W' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'consumptionLoggedAt',
      //   headerName: 'ConsumptionLoggedAt',
      //   field: 'consumptionLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'consumptionTotal',
      //   headerName: 'CONSUMPTION_TOTAL',
      //   field: 'consumptionTotal',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + 'W' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'consumptionTotalLoggedAt',
      //   headerName: 'ConsumptionTotalLoggedAt',
      //   field: 'consumptionTotalLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'curtainsOpen',
      //   headerName: 'CURTAINS_OPEN',
      //   field: 'curtainsOpen',
      //   valueFormatter: (params) => this.getMappingValue(params, 'cm'),
      //   valueGetter: (params) => this.getMappingValue(params, 'cm'),
      //   hide: true,
      // },
      // {
      //   colId: 'curtainsOpenLoggedAt',
      //   headerName: 'CurtainsOpenLoggedAt',
      //   field: 'curtainsOpenLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'dndStatus',
      //   headerName: 'DND_STATUS',
      //   field: 'dndStatus',
      //   hide: true,
      // },
      // {
      //   colId: 'dndStatusLoggedAt',
      //   headerName: 'DndStatusLoggedAt',
      //   field: 'dndStatusLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'doorLocked',
      //   headerName: 'DOOR_LOCKED',
      //   field: 'doorLocked',
      //   valueFormatter: (params) => this.getMappingValue(params, 'sdl'),
      //   valueGetter: (params) => this.getMappingValue(params, 'sdl'),
      //   hide: true,
      // },
      // {
      //   colId: 'doorLockedLoggedAt',
      //   headerName: 'DoorLockedLoggedAt',
      //   field: 'doorLockedLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'doorOpen',
      //   headerName: 'DOOR_OPEN',
      //   field: 'doorOpen',
      //   valueFormatter: (params) => this.getMappingValue(params, 'osd'),
      //   valueGetter: (params) => this.getMappingValue(params, 'osd'),
      //   hide: true,
      // },
      // {
      //   colId: 'doorOpenLoggedAt',
      //   headerName: 'DoorOpenLoggedAt',
      //   field: 'doorOpenLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'humidity',
      //   headerName: 'HUMIDITY',
      //   field: 'humidity',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + '%' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'humidityLoggedAt',
      //   headerName: 'HumidityLoggedAt',
      //   field: 'humidityLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'isBookedAndCheckInDone',
      //   headerName: 'IS_BOOKED_AND_CHECK_IN_DONE',
      //   field: 'isBookedAndCheckInDone',
      //   hide: true,
      // },
      // {
      //   colId: 'isBookedAndCheckInDoneLoggedAt',
      //   headerName: 'IsBookedAndCheckInDoneLoggedAt',
      //   field: 'isBookedAndCheckInDoneLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'isBookedButNoCheckInDone',
      //   headerName: 'IS_BOOKED_BUT_NO_CHECK_IN_DONE',
      //   field: 'isBookedButNoCheckInDone',
      //   hide: true,
      // },
      // {
      //   colId: 'isBookedButNoCheckInDoneLoggedAt',
      //   headerName: 'IsBookedButNoCheckInDoneLoggedAt',
      //   field: 'isBookedButNoCheckInDoneLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'isCheckInInProgress',
      //   headerName: 'IS_CHECK_IN_IN_PROGRESS',
      //   field: 'isCheckInInProgress',
      //   hide: true,
      // },
      // {
      //   colId: 'isCheckInInProgressLoggedAt',
      //   headerName: 'IsCheckInInProgressLoggedAt',
      //   field: 'isCheckInInProgressLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'isCheckOutInProgress',
      //   headerName: 'IS_CHECK_OUT_IN_PROGRESS',
      //   field: 'isCheckOutInProgress',
      //   hide: true,
      // },
      // {
      //   colId: 'isCheckOutInProgressLoggedAt',
      //   headerName: 'IsCheckOutInProgressLoggedAt',
      //   field: 'isCheckOutInProgressLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'isRaining',
      //   headerName: 'IS_RAINING',
      //   field: 'isRaining',
      //   hide: true,
      // },
      // {
      //   colId: 'isRainingLoggedAt',
      //   headerName: 'IsRainingLoggedAt',
      //   field: 'isRainingLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'lightsOn',
      //   headerName: 'LIGHTS_ON',
      //   field: 'lightsOn',
      //   hide: true,
      // },
      // {
      //   colId: 'lightsOnLoggedAt',
      //   headerName: 'LightsOnLoggedAt',
      //   field: 'lightsOnLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'makeUpStatus',
      //   headerName: 'MAKE_UP_STATUS',
      //   field: 'makeUpStatus',
      //   hide: true,
      // },
      // {
      //   colId: 'makeUpStatusLoggedAt',
      //   headerName: 'MakeUpStatusLoggedAt',
      //   field: 'makeUpStatusLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'occupied',
      //   headerName: 'OCCUPIED',
      //   field: 'occupied',
      //   hide: true,
      // },
      // {
      //   colId: 'occupiedLoggedAt',
      //   headerName: 'OccupiedLoggedAt',
      //   field: 'occupiedLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'oooStatus',
      //   headerName: 'OOO_STATUS',
      //   field: 'oooStatus',
      //   hide: true,
      // },
      // {
      //   colId: 'oooStatusLoggedAt',
      //   headerName: 'OooStatusLoggedAt',
      //   field: 'oooStatusLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'outdoorHumidity',
      //   headerName: 'OUTDOOR_HUMIDITY',
      //   field: 'outdoorHumidity',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + '%' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'outdoorHumidityLoggedAt',
      //   headerName: 'OutdoorHumidityLoggedAt',
      //   field: 'outdoorHumidityLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'outdoorTemperature',
      //   headerName: 'OUTDOOR_TEMPERATURE',
      //   field: 'outdoorTemperature',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + '°C' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'outdoorTemperatureLoggedAt',
      //   headerName: 'OutdoorTemperatureLoggedAt',
      //   field: 'outdoorTemperatureLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'temperature',
      //   headerName: 'TEMPERATURE',
      //   field: 'temperature',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + '°C' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'temperatureLoggedAt',
      //   headerName: 'TemperatureLoggedAt',
      //   field: 'temperatureLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'windSpeed',
      //   headerName: 'WIND_SPEED',
      //   field: 'windSpeed',
      //   valueFormatter: (params) => {
      //     const value = this.sharedService.numberFormat(params.value);
      //     return value ? value + 'km/h' : '';
      //   },
      //   hide: true,
      // },
      // {
      //   colId: 'windSpeedLoggedAt',
      //   headerName: 'WindSpeedLoggedAt',
      //   field: 'windSpeedLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      // {
      //   colId: 'windowOpen',
      //   headerName: 'WINDOW_OPEN',
      //   field: 'windowOpen',
      //   valueFormatter: (params) => this.getMappingValue(params, 'osw'),
      //   valueGetter: (params) => this.getMappingValue(params, 'osw'),
      //   hide: true,
      // },
      // {
      //   colId: 'windowOpenLoggedAt',
      //   headerName: 'WindowOpenLoggedAt',
      //   field: 'windowOpenLoggedAt',
      //   valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      //   hide: true,
      // },
      {
        colId: 'locationId',
        headerName: 'LOCATION_ID',
        field: 'id',
        hide: true,
      },
      {
        colId: 'idx',
        headerName: 'IDX',
        field: 'idx',
        hide: true,
      },
      // {
      //   colId: 'locationTypeId',
      //   headerName: 'LOCATION_TYPE_ID',
      //   field: 'locationTypeId',
      // },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        filterParams: { newRowsAction: 'keep' },
        suppressFilter: true,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        filterParams: { newRowsAction: 'keep' },
        suppressFilter: true,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        width: 50,
        cellRendererFramework: LocationActionCellComponent,
        suppressSorting: true,
        suppressFilter: true,
      },
    ];
  }

  get isCeosVariant(): boolean {
    return this.backendVariant === 'ceos' ? true : false;
  }
}
