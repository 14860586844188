<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <!--Header-->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <p-toggleButton
          formControlName="isActive"
          offIcon="fa fa-square-o"
          offLabel="{{ 'ACTIVE' | translate }}"
          onIcon="fa fa-check-square-o"
          onLabel="{{ 'ACTIVE' | translate }}"
        ></p-toggleButton>
      </div>
    </div>

    <!--Body-->
    <div class="ui-g">
      <!-- DEVICE_TYPE -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="deviceTypeId"
          [form]="form"
          label="{{ 'DEVICE_TYPE' | translate }}"
          [options]="selectItems['deviceTypes']"
        ></sc-dropdown>
      </div>

      <!-- DEVICE_PROTOCOL -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="deviceProtocolId"
          [form]="form"
          label="{{ 'DEVICE_PROTOCOL' | translate }}"
          [options]="selectItems['deviceProtocols']"
        ></sc-dropdown>
      </div>

      <!-- PROTOCOL_CONTROLLER -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="protocolControllerId"
          [form]="form"
          label="{{ 'PROTOCOL_CONTROLLER' | translate }}"
          [options]="selectItems['protocolControllers']"
        ></sc-dropdown>
      </div>

      <!-- DEVICE_BRAND -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="deviceBrandId"
          [form]="form"
          label="{{ 'DEVICE_BRAND' | translate }}"
          [options]="selectItems['deviceBrands']"
        ></sc-dropdown>
      </div>

      <!-- COMMAND -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="command"
          [form]="form"
          label="{{ 'COMMAND' | translate }}"
        ></sc-text-input>
      </div>

      <!-- NAME FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="key"
          [form]="form"
          label="{{ 'KEY' | translate }}"
        ></sc-text-input>
      </div>

      <!-- SYNTAX FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="syntax"
          [form]="form"
          label="{{ 'SYNTAX' | translate }}"
        ></sc-text-input>
      </div>

      <!-- DELAY FIELD -->
      <div class="ui-g-12">
        <sc-number-input
          controlName="delay"
          [form]="form"
          label="{{ 'DELAY' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
