<div [formGroup]="group">
  <div class="ui-g-12 ui-g-nopad">
    <!-- DURATION FIELD -->
    <div class="ui-g-2">
      <sc-number-input
        controlName="duration"
        [form]="group"
        label="{{ 'DURATION' | translate }}"
      ></sc-number-input>
    </div>

    <div class="ui-g-3 ui-g-nopad">
      <div class="ui-g">
        <!-- TIME_FROM FIELD -->
        <div class="ui-g-6">
          <sc-datetime-input
            controlName="timeFrom"
            [form]="group"
            label="{{ 'FROM' | translate }}"
          ></sc-datetime-input>
        </div>
        <!-- TIME_TO FIELD -->
        <div class="ui-g-6">
          <sc-datetime-input
            controlName="timeTo"
            [form]="group"
            label="{{ 'TO' | translate }}"
          ></sc-datetime-input>
        </div>
      </div>
    </div>

    <div class="ui-g-3 ui-g-nopad">
      <div class="ui-g">
        <!-- INDOOR_TEMPERATURE_FROM FIELD -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="indoorFrom"
            [form]="group"
            label="{{ 'FROM' | translate }}"
          ></sc-number-input>
        </div>

        <!-- INDOOR_TEMPERATURE_TO FIELD -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="indoorTo"
            [form]="group"
            label="{{ 'TO' | translate }}"
          ></sc-number-input>
        </div>
      </div>
    </div>

    <div class="ui-g-3 ui-g-nopad">
      <div class="ui-g">
        <!-- OUTDOOR_TEMPERATURE_FROM FIELD -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="outdoorFrom"
            [form]="group"
            label="{{ 'FROM' | translate }}"
          ></sc-number-input>
        </div>

        <!-- OUTDOOR_TEMPERATURE_TO FIELD -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="outdoorTo"
            [form]="group"
            label="{{ 'TO' | translate }}"
          ></sc-number-input>
        </div>
      </div>
    </div>

    <div class="ui-g-1">
      <button
        pButton
        type="button"
        class="ui-button-warning"
        icon="fa fa-trash"
        (click)="remove()"
      ></button>
    </div>
  </div>
</div>
