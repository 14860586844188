<sc-action-button
  *ngIf="actions.length > 1"
  [actions]="actions"
  [buttonIcon]="buttonIcon"
></sc-action-button>

<div class="integrator-action-cell" *ngIf="actions.length == 1">
  <a class="details-button" (click)="openDetailsModal()">
    <i class="fa fa-eye"></i>
  </a>
</div>
