import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'location-form',
  templateUrl: 'location-form.component.html'
})
export class LocationFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() groupId: any;
  @Input() optionData: any;
  @Output() onRemove = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  remove() {
    this.onRemove.emit();
  }
}
