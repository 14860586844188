<div *ngIf="fetchingState === 0; else loadingIndicator">
  <div [formGroup]="form">
    <div class="ui-g mb-3">
      <div class="ui-g-6 ui-g-nopad">
        <sc-text-input
          controlName="description"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <sc-activate-button [form]="form" field="isActive"></sc-activate-button>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-g-nopad">
        <p-accordion [multiple]="true">
          <p-accordionTab [selected]="true">
            <p-header>
              {{ "DETAILS" | translate }}
            </p-header>

            <div class="p-3">
              <div class="ui-g">
                <div class="ui-g-12 ui-md-4 py-1">IDX:</div>
                <div class="ui-g-12 ui-md-8 py-1">{{ vdh?.idx }}</div>
              </div>

              <div class="ui-g">
                <div class="ui-g-12 ui-md-4 py-1">
                  {{ "PROTOCOL" | translate }}:
                </div>
                <div class="ui-g-12 ui-md-8 py-1">{{ vdh?.protocolName }}</div>
              </div>

              <div class="ui-g">
                <div class="ui-g-12 ui-md-4 py-1">
                  {{ "NODE_ID" | translate }}:
                </div>
                <div class="ui-g-12 ui-md-8 py-1">{{ vdh?.uuid }}</div>
              </div>

              <div class="ui-g" *ngIf="isAdmin && vdh?.deviceTypeKey === 'a'">
                <div class="ui-g-12 ui-md-4 py-1">
                  {{ "CEOS_ID" | translate }}:
                </div>
                <div class="ui-g-12 ui-md-4 py-1">
                  <sc-dropdown
                    controlName="ceosId"
                    [form]="form"
                    label="{{ 'CEOS_ID' | translate }}"
                    [showLabel]="false"
                    [options]="ceosIdentitySelectItems"
                  ></sc-dropdown>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-12 ui-md-4 py-1">
                  {{ "CREATED_AT" | translate }}:
                </div>
                <div class="ui-g-12 ui-md-8 py-1">
                  {{ vdh?.createdAtFormatted }}
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-12 ui-md-4 py-1">
                  {{ "LAST_CHANGED_AT" | translate }}:
                </div>
                <div class="ui-g-12 ui-md-8 py-1">
                  {{ vdh?.updatedAtFormatted }}
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-12 ui-md-4 py-1">
                  {{ "DEVICE_PERSISTENT" | translate }}:
                </div>
                <div class="ui-g-12 ui-md-8 py-1">
                  <sc-checkbox-input
                    controlName="isPersistent"
                    [form]="form"
                    label="{{ 'DEVICE_PERSISTENT' | translate }}"
                    [showLabel]="false"
                  ></sc-checkbox-input>
                </div>
              </div>
            </div>
          </p-accordionTab>

          <p-accordionTab [selected]="true" *ngIf="vdsForm">
            <p-header>
              {{ "PROPERTIES" | translate }}
            </p-header>

            <!-- Loop VDs -->
            <div class="ui-g" formArrayName="virtualDevices">
              <div
                class="ui-g-12 px-4 mb-3"
                *ngFor="let ctrl of vdsForm.controls; let i = index"
                [formGroupName]="i"
                [ngClass]="{ 'mt-3': i === 0 }"
              >
                <h4 class="m-0">{{ getPropertyName(ctrl?.value?.idx) }}</h4>

                <div class="mb-1">
                  <sc-text-input
                    controlName="diffDetectionFactor"
                    [form]="ctrl"
                    [label]="'CCP_DEVICE_MIN_DIFFERENCE_THRESHOLD' | translate"
                  ></sc-text-input>
                </div>

                <div class="mb-1">
                  <sc-checkbox-input
                    controlName="excludeFromLHL"
                    [form]="ctrl"
                    [label]="
                      'EXCLUDE_FROM_LOCATION_PROPERTY_CALCULATION' | translate
                    "
                  ></sc-checkbox-input>
                </div>

                <div class="mb-1">
                  <sc-checkbox-input
                    controlName="isHiddenFromRoomController"
                    [form]="ctrl"
                    [label]="'HIDE_FROM_ROOM_CONTROLLER_UI' | translate"
                  ></sc-checkbox-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="!vdh?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal()"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!vdh?.isDeleted"
          buttonClass="ui-button-success"
          [disabled]="form.invalid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
