import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ActivateButtonComponent } from './activate-button.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({ extend: true, isolate: false }),
    ToggleButtonModule,
  ],
  declarations: [ActivateButtonComponent],
  exports: [ActivateButtonComponent],
})
export class SCActivateButtonModule {}
