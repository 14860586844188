<div class="ui-g">
  <div class="ui-g-12 ui-md-12">
    <div class="ui-g-12 ui-md-4">
      <sc-dropdown
        controlName="word_order"
        [form]="modbusSettingsForm"
        [label]="'MODBUS_WORD_ORDER' | translate"
        [options]="selectItems?.modbusOrders"
      ></sc-dropdown>
    </div>
    <div class="ui-g-12 ui-md-4">
      <sc-dropdown
        controlName="byte_order"
        [form]="modbusSettingsForm"
        [label]="'MODBUS_BYTE_ORDER' | translate"
        [options]="selectItems?.modbusOrders"
      ></sc-dropdown>
    </div>
    <div class="ui-g-12 ui-md-4">
      <sc-number-input
        controlName="timeout_ms"
        [form]="modbusSettingsForm"
        label="{{ 'MODBUS_TIMEOUT' | translate }}"
      ></sc-number-input>
    </div>
  </div>
</div>
