import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { SceneTemplate, SceneTemplateCommand, SceneTemplateCommandParameter } from '../models/scene-template';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromStore from '@app/store';
import * as fromRootStore from '@app/store';

import { SceneTemplateFormsService } from '../services/scene-template-forms.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-scene-template-form',
  templateUrl: 'scene-template-form.component.html',
})
export class SceneTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: SceneTemplate;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  fetchingState: number;
  pendingState: number;
  submitting: boolean;
  subscribers: { [key: string]: any };

  selectItems: { [key: string]: SelectItem[] };

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private formService: SceneTemplateFormsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private rootStore: Store<fromRootStore.State>
  ) {}

  ngOnInit() {
    this.selectItems = {};
    this.subscribers = {};
    this.fetchingState = 0;
    this.pendingState = 0;

    // clear form pending state
    this.store.dispatch(new fromStore.SceneTemplateResetPendingState());

    // watch form pending error
    this.store
      .select(fromStore.getSceneTemplatePendingError)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        this.errorMessage = result;
      });

    // close form when create or update success
    this.store
      .select(fromStore.getSceneTemplatePending)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        this.pendingState++;
        this.submitting = result;
        if (this.pendingState === 3) {
          if (!this.submitting && !this.errorMessage) {
            this.onClose.emit();
          }
          this.pendingState = 0;
        }
      });

    this.rootStore
      .select(fromRootStore.getDeviceTypes)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        if (result) {
          const deviceTypes = result.filter((s) => s.commandActions && s.commandActions.length > 0);
          this.selectItems.deviceTypesSrc = result;
          this.selectItems.deviceTypes = this.sharedService.createSelectItems(
            deviceTypes,
            true
            // 'deviceTypeId'
          );
        }
      });

    if (this.data) {
      // EDIT MODE
      this.editMode = true;

      // Fetch Scene Template
      this.store.dispatch(new fromStore.LoadSceneTemplate(this.data.id));
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  parseParameterValueToArrayString(params: SceneTemplateCommandParameter[]): string[] {
    if (params && params.length) {
      return params.map((item) => item.key);
    }
    return [];
  }

  parseParameterValueToArrayObject(params: string[]): SceneTemplateCommandParameter[] {
    if (params && params.length) {
      return params.map((item) => ({ key: item, value: null }));
    }
    return [];
  }

  parseCommandParameters(commands: SceneTemplateCommand[], type: 'string' | 'object'): { [key: string]: any }[] {
    if (commands && commands.length) {
      return commands.map((item) => ({
        ...item,
        parameters:
          type === 'string'
            ? this.parseParameterValueToArrayString(item.parameters)
            : this.parseParameterValueToArrayObject(<any>item.parameters),
      }));
    }
    return [];
  }

  initForm() {
    this.form = this.formService.initSceneTemplateForm();

    if (this.editMode === true) {
      // this.form.get('sceneTemplateId').disable();
      // this.form.addControl(
      //   'id',
      //   new FormControl({ value: null, disabled: true })
      // );

      this.store
        .select(fromStore.getSceneTemplateFormData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((result) => {
          if (result) {
            this.formData = { ...result };
            this.formData.commands = this.sharedService.jsonParse(this.formData.commands);
            this.formData.commands = this.parseCommandParameters(this.formData.commands, 'string');

            this.form.patchValue(this.formData);

            this.formService.setCommandFormArray(this.form, this.formData.commands);
          }
        });
    }
  }

  // ____ COMMANDS FORM ARRAY CONTROL
  get commandsFormArray() {
    return this.form.get('commands') as FormArray;
  }
  addCommand() {
    const controls = <FormArray>this.form.controls['commands'];
    controls.push(this.formService.initCommandFormGroup());
  }
  removeCommand(index: number) {
    const controls = <FormArray>this.form.controls['commands'];
    controls.removeAt(index);
  }

  submit() {
    if (this.form.valid) {
      const formData = this.form.getRawValue();
      if (formData && formData.commands && formData.commands.length) {
        formData.commands = this.parseCommandParameters(formData.commands, 'object');
      }

      if (!this.editMode) {
        delete formData.id;
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(formData) {
    this.store.dispatch(new fromStore.CreateSceneTemplate(formData));
  }

  update(formData) {
    this.store.dispatch(new fromStore.UpdateSceneTemplate(formData));
  }

  delete() {
    this.store.dispatch(new fromStore.DeleteSceneTemplate(this.formData));
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
