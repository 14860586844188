import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { EndUserProfile } from '../interfaces/end-user-profile';
import { SharedService } from '@shared/shared.service';

@Injectable({
  providedIn: 'root',
})
export class EndUserProfileService {
  private apiUrl = config().apiUrl + '/endUserProfiles';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private verifyData(data: EndUserProfile): EndUserProfile {
    const newData = { ...data };
    delete newData.id;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    return newData;
  }

  getEndUserProfile(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getEndUserProfiles(siteId?: number) {
    const queryParams = this.sharedService.urlQueryParamsCreator({ s: siteId });
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  createEndUserProfile(data: EndUserProfile) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateEndUserProfile(id: number, data: EndUserProfile) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteEndUserProfile(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      is_active: false,
      is_deleted: true,
    });
  }
}
