import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Locale } from '@widgets/locales/models/locale';
import { SiteType } from '../models/site-type';

@Injectable({
  providedIn: 'root',
})
export class SiteTypeFormService {
  constructor(private formBuilder: FormBuilder) {}

  initSiteTypeForm(locales: Locale[]): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      // name: [null, Validators.required],
      locales: this.initLocaleFormArray(locales),
      icon: null,
      widgets: [[]],
      ruleTemplates: [[]],
      isActive: false,
      isDeleted: false,
    });
  }

  initLocaleFormGroup(locale: string) {
    return this.formBuilder.group({
      locale: [locale, Validators.required],
      name: [null, locale === 'en' ? Validators.required : null],
      // description: null,
    });
  }

  initLocaleFormArray(locales: Locale[]) {
    const formGroups = [];
    formGroups.push(this.initLocaleFormGroup('en'));
    for (const locale of locales) {
      if (locale.isActive && !locale.isDeleted && locale.id !== 'en') {
        formGroups.push(this.initLocaleFormGroup(locale.id));
      }
    }
    return this.formBuilder.array(formGroups);
  }

  patchLocaleFormArray(form: FormGroup, data: SiteType) {
    const controls = (form.get('locales') as FormArray).controls;
    const name = data.name && JSON.parse(<string>data.name);
    // const description = data.description && JSON.parse(data.description);

    for (const ctrl of controls) {
      const locale = ctrl.value && ctrl.value.locale;
      const localeName = name && name[locale];
      if (localeName) {
        // const localeDescription = (description && description[locale]) || '';
        ctrl.patchValue({
          name: localeName,
          // description: localeDescription,
        });
      }
    }
  }
}
