import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgGridModule } from 'ag-grid-angular';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AuthModule } from '@app/auth/auth.module';
import { SCModalModule } from '@sc/modal/modal.module';
import { SCQuickDateSelectModule } from '@sc/quick-date-select/quick-date-select.module';

import { SCTableComponent } from './table.component';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { ColumnDisplayButtonComponent } from './column-display-button/column-display-button.component';
import { DataDisplayComponent } from './data-display-button/data-display-button.component';
import { TableRefreshButtonComponent } from './table-refresh-button/table-refresh-button.component';
import { TreeExpandButtonComponent } from './tree-expand-button/tree-expand-button.component';
import { TableSiteSelectorComponent } from './table-site-selector/table-site-selector.component';

import { SCTableService } from './table.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({ extend: true, isolate: false }),
    AuthModule,
    SCModalModule,
    SCQuickDateSelectModule,
    ButtonModule,
    OverlayPanelModule,
    TooltipModule,
    AgGridModule.withComponents([CustomHeaderComponent]),
  ],
  declarations: [
    SCTableComponent,
    CustomHeaderComponent,
    ColumnDisplayButtonComponent,
    DataDisplayComponent,
    TableRefreshButtonComponent,
    TreeExpandButtonComponent,
    TableSiteSelectorComponent,
  ],
  entryComponents: [SCTableComponent],
  exports: [SCTableComponent],
  providers: [SCTableService],
})
export class SCTableModule {}
