import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  PageTemplateSection,
  PageTemplateSectionWidget
} from '../models/page-template';

@Injectable()
export class PageTemplateFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initPageTemplateForm() {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      allowCustomSections: false,
      allowInheritance: false,
      sections: this.formBuilder.array([]),
      isActive: false,
      isDeleted: false
    });
  }

  initSectionFormGroup(): FormGroup {
    return this.formBuilder.group({
      sectionId: [null, Validators.required],
      name: [null, Validators.required],
      position: 0,
      widgets: this.formBuilder.array([]),
      selectedWidgets: null
    });
  }

  setSectionFormArray(form: FormGroup, data: PageTemplateSection[]) {
    const formGroups = data.map((item: any) => {
      const widgetFormGroups = item.widgets.map(widget => {
        const widgetFormGroup = this.initWidgetFormGroup();
        widgetFormGroup.patchValue(widget);
        return widgetFormGroup;
      });

      // create empty section form group
      const sectionFormGroup = this.initSectionFormGroup();
      // patch section data, NOTE: "widgets" is formArray then widgets will not be patched
      sectionFormGroup.patchValue(item);

      // patch "widgets" into section form group by setControl
      // with array of widget form groups
      sectionFormGroup.setControl(
        'widgets',
        this.formBuilder.array(widgetFormGroups)
      );
      // return section form group
      return sectionFormGroup;
    });

    // create section form array with section form groups
    const formArray = this.formBuilder.array(formGroups);
    // apply it into the form
    form.setControl('sections', formArray);
  }

  initWidgetFormGroup(): FormGroup {
    return this.formBuilder.group({
      widgetId: [null, Validators.required],
      cols: null,
      rows: null,
      x: null,
      y: null,
      maxItemArea: null,
      minItemArea: null,
      maxItemCols: null,
      minItemCols: null,
      maxItemRows: null,
      minItemRows: null
    });
  }

  setWidgetFormArray(form: FormGroup, data: any[]) {
    const formGroups = data.map(item => {
      const fg = this.initWidgetFormGroup();
      fg.patchValue({ ...item, widgetId: item.id });
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('widgets', formArray);
  }
}
