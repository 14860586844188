import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AGAlertComponent } from './ag-alert.component';
import { AGAlertModalComponent } from './ag-alert-modal/ag-alert-modal.component';

const COMPONENTS: any[] = [AGAlertComponent, AGAlertModalComponent];

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: []
})
export class AGAlertModule {}
