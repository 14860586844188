import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { BatteryStatusComponent } from './battery-status.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [BatteryStatusComponent],
  entryComponents: [BatteryStatusComponent],
  providers: [],
})
export class BatteryStatusModule {}
