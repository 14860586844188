import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { SelectItem } from 'primeng/api';
import { Device } from '@widgets/devices/models/device';
import { Site } from '@widgets/sites/models/site';

import { DevicesService } from '@widgets/devices/services/devices.service';
import { DeviceFormsService } from '@widgets/devices/services/device-forms.service';
import { DeviceBrandsService } from '@widgets/device-brands/services/device-brands.service';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { DeviceModelFormsService } from '@widgets/device-models/services/device-model-forms.service';
import { DeviceModelsService } from '@widgets/device-models/services/device-models.service';
import { ProtocolControllersService } from '@widgets/devices/services/protocol-controllers.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { SharedService } from '@shared/shared.service';

import { sortBy } from 'lodash';
import { config } from '@app/config';

@Component({
  selector: 'sc-device-form',
  templateUrl: './device-form.component.html',
})
export class DeviceFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: Device;
  formReadOnly: boolean;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};
  selectedSite: Site;
  backendVariant: string;

  private sourceData: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};

  constructor(
    private devicesService: DevicesService,
    private deviceFormsService: DeviceFormsService,
    private deviceBrandsService: DeviceBrandsService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceTypesService: DeviceTypesService,
    private deviceModelFormsService: DeviceModelFormsService,
    private deviceModelsService: DeviceModelsService,
    private protocolControllersService: ProtocolControllersService,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    const selectItems = config().selectItems;
    this.selectItems = {
      automations: [],
      deviceBrands: [],
      deviceProtocols: [],
      deviceTypes: [],
      deviceModels: [],
      deviceValueModifiers: selectItems.deviceValueModifiers,
      devices: [],
      gateways: [],
      zigbeeControllerPorts: selectItems.zigbeeControllerPorts,
      zigbeeStatus: selectItems.zigbeeStatus,
      zwaveControllerPorts: selectItems.zwaveControllerPorts,
      zwaveStatus: selectItems.zwaveStatus,
    };

    this.subscribers.getBackendVariant = this.store.pipe(select(fromStore.getUserVariant)).subscribe((result) => {
      this.backendVariant = result;
    });

    this.selectedSite = this.sharedService.selectedSite;
    this.fetchDeviceBrands();
    this.fetchDeviceModels();
    this.fetchDeviceProtocols();
    this.fetchProtocolControllers();
    this.fetchDeviceTypes();
    this.fetchLocations();
    this.fetchSelectDevices();

    if (this.data) {
      // EDIT MODE
      this.editMode = true;

      // READ ONLY MODE
      if (this.data.readOnly) {
        this.formReadOnly = true;
      }

      if (this.data.id) {
        this.fetchDevice(this.data.id);
      } else if (this.data.device && this.data.device.id) {
        this.fetchDevice(this.data.device.id);
      }
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDevice(id: number) {
    this.fetchingState++;
    this.subscribers.getDevice = this.devicesService.getDevice(id).subscribe({
      next: (result: any) => {
        this.formData = result.data;

        if (this.formData.locationsToControl) {
          this.formData.locationsToControl = JSON.parse(this.formData.locationsToControl);
        }

        // convert seconds into milliseconds
        if (this.formData.configFetchInterval && typeof this.formData.configFetchInterval === 'number') {
          this.formData.configFetchInterval = this.sharedService.numberFormat(this.formData.configFetchInterval / 1000);
        }

        // convert seconds into milliseconds
        if (this.formData.hlSenderInterval && typeof this.formData.hlSenderInterval === 'number') {
          this.formData.hlSenderInterval = this.sharedService.numberFormat(this.formData.hlSenderInterval / 1000);
        }

        this.form.patchValue({
          ...this.formData,
          zwaveControllerSoftware: +this.formData.zwaveControllerSoftware,
        });

        if (this.isAutomation) {
          this.deviceModelFormsService.resetBehaviourPatternsForm(this.form);
        } else {
          if (this.formData.behaviourPatterns) {
            this.deviceModelFormsService.setBehaviourPatternsFormArray(
              this.form,
              JSON.parse(this.formData.behaviourPatterns)
            );
          } else {
            this.deviceModelFormsService.initBehaviourPatternsFormArray(this.form);
          }
        }

        this.initSelectItemsDeviceModels();
        this.onFormValueChange();
        this.fetchingState--;
      },
    });
  }

  private fetchDeviceBrands() {
    this.fetchingState++;
    this.subscribers.getDeviceBrands = this.deviceBrandsService.getDeviceBrands().subscribe({
      next: (result: any) => {
        this.selectItems.deviceBrands = this.sharedService.createSelectItems(result.data.filter((d) => !d.isDeleted));
        this.fetchingState--;
      },
    });
  }

  private fetchDeviceProtocols() {
    this.fetchingState++;
    this.subscribers.getDeviceProtocols = this.deviceProtocolsService.getDeviceProtocols().subscribe({
      next: (result: any) => {
        this.selectItems.deviceProtocols = this.sharedService.createSelectItems(
          result.data.filter((d) => !d.isDeleted)
        );
        this.fetchingState--;
      },
    });
  }

  private fetchProtocolControllers() {
    this.fetchingState++;
    this.subscribers.getProtocolController = this.protocolControllersService.getProtocolControllers().subscribe({
      next: (result: any) => {
        this.selectItems.zwaveControllerSoftwares = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      },
    });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.getDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe({
      next: (result: any) => {
        this.sourceData.deviceTypes = result.data;
        this.selectItems.deviceTypes = this.sharedService.createSelectItems(result.data.filter((d) => !d.isDeleted));
        this.fetchingState--;
      },
    });
  }

  private fetchDeviceModels() {
    this.fetchingState++;
    this.subscribers.getDeviceModels = this.deviceModelsService.getDeviceModels().subscribe({
      next: (result: any) => {
        this.sourceData.deviceModels = result.data;
        this.initSelectItemsDeviceModels();
        this.fetchingState--;
      },
    });
  }

  private fetchLocations() {
    this.fetchingState++;
    const options = {
      siteId: this.selectedSite.id,
      columns: 'id,idx,description,isActive,isDeleted',
    };
    this.subscribers.getLocations = this.locationsService.getLocations(options).subscribe({
      next: (result: any) => {
        this.sourceData.locations = result.data;
        this.selectItems.locations = this.sharedService.createSelectItems(
          result.data.filter((d) => !d.isDeleted),
          false
        );
        this.selectItems.unitLocations = this.sharedService.createSelectItems(
          result.data.filter((d) => !d.isDeleted && d.locationTypeKey === 'u'),
          false
        );
        this.fetchingState--;
      },
    });
  }

  private fetchSelectDevices() {
    this.fetchingState++;
    const options = {
      siteId: this.selectedSite.id,
    };
    this.subscribers.getDevices = this.devicesService.getDevices(options).subscribe({
      next: (result: any) => {
        this.sourceData.devices = result.data;
        const tmpData = result.data;
        const data = {
          automation: [],
          gateways: [],
          devices: [],
        };

        for (const item of tmpData) {
          if (item.isDeleted) {
            continue;
          }

          switch (item.deviceTypeKey) {
            case 'a':
              data.automation.push(item);
              break;
            case 'g':
              data.gateways.push(item);
              break;
            default:
              data.devices.push(item);
              break;
          }
        }

        // Automations
        this.selectItems.automations = this.sharedService.createSelectItems(data.automation);
        // Gateways
        this.selectItems.gateways = this.sharedService.createSelectItems(data.gateways);
        // Devices
        this.selectItems.devices = this.sharedService.createSelectItems(data.devices);
        this.fetchingState--;
      },
    });
  }

  get deviceTypeId() {
    return this.form && this.form.get('deviceTypeId').value;
  }

  get deviceTypeKey() {
    if (this.deviceTypeId && this.sourceData && this.sourceData.deviceTypes) {
      const deviceType = this.sourceData.deviceTypes.find((dt) => dt.id === this.deviceTypeId);
      return deviceType && deviceType.key ? deviceType.key : null;
    }
    return null;
  }

  get isAutomation() {
    if (this.deviceTypeKey === 'a') {
      return true;
    }
    return false;
  }

  get isGateway() {
    if (this.deviceTypeKey === 'g') {
      return true;
    }
    return false;
  }

  get requiredVirtualDeviceHolder() {
    const isVirtualDevice = this.form && this.form.get('isVirtualDevice').value;
    if (isVirtualDevice) {
      return true;
    }
    return false;
  }

  get virtualDevicesHolders() {
    const locationId = this.form && this.form.get('locationId').value;
    if (locationId && this.sourceData && this.sourceData.devices) {
      const devices = this.sourceData.devices.filter((d) => {
        return !d.isDeleted && d.isVirtualDevicesHolder && d.locationId === locationId;
      });
      if (devices && devices.length) {
        return this.sharedService.createSelectItems(devices, false);
      }
      return [];
    }
    return [];
  }

  get isMissingUUID() {
    const uuid = this.form && this.form.get('uuid').value;
    const active = this.form && this.form.get('isActive').value;
    if (active && !uuid && this.deviceTypeKey !== 'a' && this.deviceTypeKey !== 'g') {
      return true;
    }
    return false;
  }

  get uuidWarningText() {
    if (this.isMissingUUID) {
      return 'WARNING_UUID_MISSING';
    }
    return '';
  }

  private initSelectItemsDeviceModels() {
    if (!this.sourceData || !this.sourceData.deviceModels) {
      this.selectItems.deviceModels = [];
      return;
    }

    const sourceData = this.sourceData.deviceModels;
    const deviceModels = [];

    for (const data of sourceData) {
      if (!data.isActive || data.isDeleted || data.deviceTypeId !== this.deviceTypeId) {
        continue;
      }

      deviceModels.push({
        label: `${data.brandName}-${data.name}(${data.model})`,
        value: data.id,
      });
    }

    this.selectItems.deviceModels = sortBy(deviceModels, 'label');

    return deviceModels;
  }

  private initForm() {
    this.form = this.deviceFormsService.initDeviceForm();

    if (this.editMode) {
      this.form.controls.deviceTypeId.disable();
      this.form.controls.locationId.disable();
    } else {
      this.form.patchValue({ siteId: this.selectedSite.id });
      this.deviceModelFormsService.initBehaviourPatternsFormArray(this.form);
      this.onFormValueChange();
    }

    if (this.formReadOnly === true) {
      this.form.disable();
    }

    if (this.backendVariant === 'ceos') {
      // set fields to read only
      this.form.get('uuid').disable();
      this.form.get('protocolId').disable();
    }
  }

  private onFormValueChange() {
    this.subscribers.deviceModelId = this.form.controls.deviceModelId.valueChanges.subscribe((value) => {
      if (value && this.sourceData && this.sourceData.deviceModels) {
        const deviceModel = this.sourceData.deviceModels.find((dm) => dm.id === value);

        this.form.patchValue({
          brandId: deviceModel.brandId,
          protocolId: deviceModel.protocolId,
        });
        this.form.controls.brandId.disable();
        this.form.controls.protocolId.disable();

        if (this.isAutomation) {
          this.form.controls.zwaveStatus.setValue(deviceModel.zwaveStatus);
          this.form.controls.zwaveControllerSoftware.setValue(deviceModel.zwaveControllerSoftware);
          this.form.controls.zwaveControllerPort.setValue(deviceModel.zwaveControllerPort);
          this.form.controls.zigbeeStatus.setValue(deviceModel.zigbeeStatus);
          this.form.controls.zigbeeControllerPort.setValue(deviceModel.zigbeeControllerPort);
        } else if (!this.isAutomation && deviceModel.behaviourPatterns) {
          this.deviceModelFormsService.setBehaviourPatternsFormArray(
            this.form,
            JSON.parse(deviceModel.behaviourPatterns)
          );
        }
      }
    });

    // Check if BehaviourPatterns is required
    this.subscribers.deviceTypeId = this.form.controls.deviceTypeId.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.zwaveStatus.reset();
        this.form.controls.zwaveControllerSoftware.reset();
        this.form.controls.zwaveControllerPort.reset();
        this.form.controls.zigbeeStatus.reset();
        this.form.controls.zigbeeControllerPort.reset();

        if (this.isAutomation) {
          this.deviceModelFormsService.resetBehaviourPatternsForm(this.form);
        } else {
          this.deviceModelFormsService.initBehaviourPatternsFormArray(this.form);
        }

        if (this.sourceData && this.sourceData.deviceModels) {
          const deviceModels = this.initSelectItemsDeviceModels();

          if (!deviceModels || deviceModels.length === 0) {
            this.form.controls.deviceModelId.reset();
            this.form.controls.deviceModelId.disable();
            this.form.controls.brandId.reset();
            this.form.controls.brandId.enable();
            this.form.controls.protocolId.reset();
            this.form.controls.protocolId.enable();
          } else {
            this.form.controls.deviceModelId.reset();
            this.form.controls.deviceModelId.enable();
          }
        }

        switch (this.deviceTypeKey) {
          case 'a':
            this.form.controls.automationId.clearValidators();
            this.form.controls.gatewayId.clearValidators();
            break;
          case 'g':
            this.form.controls.automationId.setValidators(Validators.required);
            break;
          default:
            this.form.controls.automationId.clearValidators();
            this.form.controls.gatewayId.clearValidators();
            break;
        }

        // Device Type is Motion Sensor
        if (this.deviceTypeKey === 'ms') {
          this.form.controls.considerForOccupancy.setValue(true);
        } else {
          this.form.controls.considerForOccupancy.setValue(false);
        }
      }
    });

    this.subscribers.isVirtualDevice = this.form.controls.isVirtualDevice.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.virtualDeviceHolderId.setValidators(Validators.required);
        this.form.controls.virtualDeviceHolderId.reset();
        this.form.controls.uuid.reset();
      } else {
        this.form.controls.virtualDeviceHolderId.clearValidators();
        this.form.controls.virtualDeviceHolderId.reset();
      }
    });

    this.subscribers.virtualDevicesHolder = this.form.controls.virtualDeviceHolderId.valueChanges.subscribe((value) => {
      if (value) {
        if (this.sourceData && this.sourceData.devices && this.sourceData.devices.length) {
          const device = this.sourceData.devices.find((d) => d.id === value);
          if (device) {
            if (device.automationId) {
              this.form.get('automationId').setValue(device.automationId);
            }
            if (device.gatewayId) {
              this.form.get('gatewayId').setValue(device.gatewayId);
            }

            // force update parentIdx when parentId changes
            const idx = device.idx || 'idx_' + device.id;
            this.form.get('parentIdx').setValue(idx);
          } else {
            this.form.get('parentIdx').setValue(null);
          }
        }
      } else {
        this.form.get('parentIdx').setValue(null);
      }
    });

    this.subscribers.isVirtualDevicesHolder = this.form.controls.isVirtualDevicesHolder.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.form.controls.isVirtualDevice.reset();
          this.form.controls.isVirtualDevice.disable();
          this.form.controls.virtualDeviceHolderId.reset();
          this.form.controls.virtualDeviceHolderId.disable();
        } else {
          this.form.controls.isVirtualDevice.enable();
          this.form.controls.virtualDeviceHolderId.enable();
        }
      }
    );

    // force update locationIdx when locationId changes
    this.subscribers.locationId = this.form.get('locationId').valueChanges.subscribe((value) => {
      if (value) {
        if (this.sourceData && this.sourceData.locations && this.sourceData.locations.length) {
          const location = this.sourceData.locations.find((l) => l.id === value);
          if (location) {
            const idx = location.idx || 'idx_' + location.id;
            // console.log('locationIdx=', idx);
            this.form.get('locationIdx').setValue(idx);
          }
        } else {
          this.form.get('locationIdx').setValue(null);
        }
      } else {
        this.form.get('locationIdx').setValue(null);
      }
    });
  }

  get behaviourPatterns(): FormArray {
    if (!this.isAutomation) {
      return this.form.controls.behaviourPatterns as FormArray;
    }
    return;
  }

  addBehaviourPattern() {
    const control = this.form.get('behaviourPatterns') as FormArray;
    const form = this.deviceModelFormsService.initBehaviourPatternsFormGroup();
    control.push(form);
  }

  removeBehaviourPattern(i: number) {
    const control = this.form.get('behaviourPatterns') as FormArray;
    control.removeAt(i);
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = Object.assign({}, this.form.getRawValue());
      delete formData.id;

      // convert seconds into milliseconds
      if (formData.configFetchInterval && typeof formData.configFetchInterval === 'number') {
        formData.configFetchInterval = formData.configFetchInterval * 1000;
      }

      // convert seconds into milliseconds
      if (formData.hlSenderInterval && typeof formData.hlSenderInterval === 'number') {
        formData.hlSenderInterval = formData.hlSenderInterval * 1000;
      }

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(data: Device) {
    this.subscribers.create = this.devicesService.createDevice(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(data: Device) {
    this.subscribers.update = this.devicesService.updateDevice(this.formData.id, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete(force: boolean = false) {
    this.subscribers.delete = this.devicesService.deleteDevice(this.formData.id, force).subscribe({
      next: (result: any) => {
        if (result.warning) {
          this.subscribers.delete = this.sharedService
            .deleteWarningHandler(this.data, result.warning)
            .subscribe((confirm: any) => {
              if (confirm === true) {
                this.delete(true);
              }
            });
        } else {
          this.apiCallSuccess('DELETE_SUCCESS', result);
        }
      },
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  get ceosField(): boolean {
    if (this.backendVariant === 'ceos') {
      return true;
    }
    return false;
  }
}
