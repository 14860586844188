import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RuleSchedulerJobLocation } from '../models/rule-scheduler-job';

import { SharedService } from '@shared/shared.service';

@Injectable()
export class RuleSchedulerJobFormService {
  private cronPattern = new RegExp(this.sharedService.createCronRegex());

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService) {}

  initRecurringOptsForm() {
    return this.formBuilder.group({
      intervalUnit: null,
      minuteInterval: null,
      hourInterval: null,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      timeHour: null,
      timeMinute: null,
    });
  }

  initNonRecurringOptsForm() {
    return this.formBuilder.group({
      triggerAt: null,
    });
  }

  initCustomOptsForm() {
    return this.formBuilder.group({
      cronPattern: [null, Validators.pattern(this.cronPattern)],
    });
  }

  initRuleSchedulerJobForm() {
    return this.formBuilder.group({
      id: [{ value: null, disabled: true }],
      ruleSchedulerJobId: [{ value: null, disabled: true }],
      companyId: [{ value: null, disabled: true }, Validators.required],
      siteId: [{ value: null, disabled: true }, Validators.required],
      name: [null, Validators.required],
      action: 1,
      frequency: null,
      timezone: null,
      ruleTemplates: null,
      locations: this.formBuilder.array([], Validators.required),
      recurringOpts: this.initRecurringOptsForm(),
      nonRecurringOpts: this.initNonRecurringOptsForm(),
      customOpts: this.initCustomOptsForm(),
      cronPattern: [{ value: null, disabled: true }, Validators.pattern(this.cronPattern)],
      disableJobFrom: null,
      disableJobTo: null,
      isActive: false,
      isDeleted: false,
    });
  }

  initLocationFormGroup() {
    return this.formBuilder.group({
      locationId: [null, Validators.required],
      applyToChildren: false,
    });
  }

  setLocationFormArray(form: FormGroup, data: RuleSchedulerJobLocation[]) {
    const formGroups = data.map(item => {
      const fg = this.initLocationFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('locations', formArray);
  }
}
