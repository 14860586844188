import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { Site } from '@widgets/sites/models/site';

import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

import { config as appConfig } from '@app/config';

@Component({
  selector: 'sc-door-activity',
  templateUrl: './door-activity.component.html',
  styleUrls: ['./door-activity.component.scss'],
})
export class DoorActivityComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  chartConfig: any;
  chartData: any;
  chartLayout: any;
  chartStyle: any;
  dataset: any[];
  isFetching: boolean;
  isFirstTime: boolean;
  widgetName: string = '';

  private intervals: { [key: string]: any } = {};
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    const conf = appConfig();
    const chartHeight = this.config.rows * conf.gridConfig.fixedRowHeight - 50 + 'px';
    this.chartStyle = { position: 'relative', width: '100%', height: chartHeight };
    this.chartConfig = { displayModeBar: false, responsive: true };

    // Get Selected Site
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFetching = false;
        this.isFirstTime = true;
        this.fetchDataset();
        // this.intervals.fetchDataset = setInterval(() => this.fetchDataset(), 60000);
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (this.isFetching || !this.selectedSite || !this.selectedSite.id) {
      return;
    }

    this.isFetching = true;
    this.subscribers.fetchDataset = this.widgetService.getDoorActivity(this.selectedSite.id).subscribe(
      (result: any) => {
        this.dataset = result.data;
        this.createChart();
        this.isFetching = false;
        this.isFirstTime = false;
      },
      (error: any) => {
        this.isFetching = false;
      }
    );
  }

  private createChart() {
    if (!this.dataset) {
      return;
    }

    const data = [...this.dataset];
    const chartTitle = this.sharedService.getTranslation('LAST_X_HOURS', { value: '24' });
    const yaxisTitle = this.sharedService.getTranslation('TIMES');
    const layout: any = {
      autosize: true,
      margin: { t: 40, b: 10, r: 10, l: 40, pad: 0 },
      title: chartTitle,
      xaxis: {
        showgrid: false,
        showline: false,
        type: 'date',
        // tickformat: '%d.%m.%Y %H:%M:%S',
        automargin: true,
      },
      yaxis: {
        title: yaxisTitle,
        automargin: true,
        rangemode: 'nonnegative',
        showgrid: true,
        showline: true,
      },
    };

    const trace1: any = {
      type: 'scatter',
      mode: 'lines',
      x: [],
      y: [],
      marker: { color: '#ffa726' },
    };

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const date = this.sharedService.dateFormat(item.key, 'YYYY-MM-DD HH:mm:ss');
      const value = this.sharedService.numberFormat(item.value, 1);
      trace1.x.push(date);
      trace1.y.push(value);
    }

    this.chartData = [trace1];
    this.chartLayout = layout;
  }
}
