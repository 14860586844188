import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationsOccupancyLiveComponent } from './locations-occupancy-live.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationsOccupancyLiveComponent],
  entryComponents: [LocationsOccupancyLiveComponent],
  providers: []
})
export class LocationsOccupancyLiveModule {}
