import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { StoreModule, MetaReducer, ActionReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterStateSerializer, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { reducers, effects } from './store';
import { CustomSerializer } from './store/reducers/router.reducer';
// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { config } from './config';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { Core2Module } from './core2/core2.module';
import { SCWidgetsModule } from '@widgets/widgets.module';
import { AppComponent } from './app.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, config().apiUrl + '/translate/', '');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}

// store Sync
export function storeSync(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === '[Store] Sync LocalStorage') {
      const ls = JSON.parse(localStorage.getItem('state'));
      const lsVersion = ls && ls.initialData && ls.initialData.version;
      const sVersion = state && state.initialData && state.initialData.version;
      if (!!ls && lsVersion === sVersion) {
        // ____ NOTE: Restore router state will break navigation
        delete ls.router;
        return reducer(ls, action);
      }
    } else if (action.type === '[Store] Clear Store') {
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeSync] : [storeSync];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,

    // Import translate module
    TranslateModule.forRoot({
      extend: true,
      isolate: false,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // Import ngrx modules
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    // Import ngrx devtools on dev environment
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    // Import SC Modules
    AuthModule,
    Core2Module,
    SCWidgetsModule,
  ],
  providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }],
  bootstrap: [AppComponent],
})
export class AppModule {}
