import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { SharedService } from '@shared/shared.service';
import { Enforcement } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class EnforcementService {
  private apiUrl = config().apiUrl + '/enforcements';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  getEnforcement(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getEnforcements(deviceIdx?: string, automationIdx?: string) {
    let queryParams = '';
    if (deviceIdx) {
      queryParams = this.sharedService.urlQueryParamsCreator({ device: deviceIdx });
    } else if (automationIdx) {
      queryParams = this.sharedService.urlQueryParamsCreator({ automation: automationIdx });
    }
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  createEnforcement(data: Enforcement) {
    return this.httpClient.post(this.apiUrl, { enforcements: [data] });
  }

  createEnforcements(data: Enforcement[]) {
    return this.httpClient.post(this.apiUrl, { enforcements: data });
  }

  updateEnforcement(data: Enforcement) {
    return this.httpClient.patch(this.apiUrl, { enforcements: [data] });
  }

  updateEnforcements(data: Enforcement[]) {
    return this.httpClient.patch(this.apiUrl, { enforcements: data });
  }

  deleteEnforcements(idxs?: string[], ids?: number[]) {
    const body = { body: { idxs, ids } };
    return this.httpClient.request('delete', this.apiUrl, body);
  }
}
