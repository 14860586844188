import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Locale } from '../models/locale';
import { config } from '@app/config';

@Injectable()
export class LocalesService {
  private apiUrl = config().apiUrl + '/locales';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): Locale {
    const newData = { ...data };
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  get(id?: string) {
    return this.httpClient
      .get(this.apiUrl + (id ? '/' + id : ''))
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  create(data: Locale) {
    return this.httpClient.post(this.apiUrl, data).pipe(catchError((error: any) => observableThrowError(error)));
  }

  update(id: string, data: Locale) {
    return this.httpClient
      .patch(this.apiUrl + '/' + id, this.verifyData(data))
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  delete(id: string) {
    return this.httpClient
      .patch(this.apiUrl + '/' + id, { isDeleted: true, isActive: false })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
