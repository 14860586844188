import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';

import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
  @Input()
  selectItems: { [prop: string]: SelectItem[] };

  @Output()
  valueChanged = new EventEmitter<any>();

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      search: null,
      deviceTypes: null,
      deviceProtocols: null
    });

    this.form.valueChanges.subscribe({
      next: (result: any) => {
        this.valueChanged.emit(result);
      }
    });
  }
}
