import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { DeviceDetails } from '@app/widgets/devices/models/device-details';
// import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceModel } from '@widgets/device-models-2/models/device-model';
import { DeviceType } from '@app/widgets/device-types/models/device-type';

// import { DeviceHistoryLogsService } from '@widgets/devices/services/device-history-logs.service';
// import { DeviceModelsService } from '@widgets/device-models/services/device-models.service';
import { DeviceModelService } from '@widgets/device-models-2/services/device-model.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-details-modal',
  templateUrl: './device-details-modal.component.html',
  styleUrls: ['./device-details-modal.component.scss'],
})
export class DeviceDetailsModalComponent implements OnInit, OnDestroy {
  currentMenu: string = 'info';
  holderDevice: DeviceDetails;
  virtualDevices: DeviceDetails[] = [];
  dhl: any[];
  historyLogs: any[];

  devices: DeviceDetails[];
  deviceModels: DeviceModel[];
  deviceModelEntities: { [key: string]: DeviceModel } = {};
  deviceTypes: DeviceType[];
  deviceTypeEntities: { [key: string]: DeviceType } = {};

  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private sharedService: SharedService,
    private deviceTypesService: DeviceTypesService,
    // private deviceModelsService: DeviceModelsService,
    private deviceModelService: DeviceModelService,
    private devicesService: DevicesService
  ) {}

  ngOnInit() {
    if (this.config && this.config.data) {
      if (this.config.data.vdh) {
        this.holderDevice = this.config.data.vdh;
        // console.log(this.holderDevice);
      }

      if (this.config.data.vds) {
        this.virtualDevices = this.config.data.vds;
      }

      // if (this.config.data.dhl) {
      //   this.dhl = this.config.data.dhl;
      //   // this.createDeviceLogs();
      // }

      if (this.config.data.devices) {
        this.devices = this.config.data.devices;
      }

      // if (this.config.data.deviceModels) {
      //   this.deviceModels = this.config.data.deviceModels;
      // }

      // if (this.config.data.deviceTypes) {
      //   this.deviceTypes = this.config.data.deviceTypes;
      // }
    }

    this.fetchDevice(this.holderDevice.id);
    this.fetchDeviceModels();
    this.fetchDeviceTypes();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  close() {
    this.dialog.close();
  }

  private fetchDevice(id: number) {
    this.subscribers.fetchDeviceTypes = this.devicesService.getDevice(id).subscribe((result: any) => {
      this.holderDevice.customData = result.data.customData;
    });
  }

  private fetchDeviceTypes() {
    this.deviceTypes = [];
    this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data.filter((d) => !d.isDeleted);
      this.deviceTypeEntities = this.deviceTypes.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}) as any;
    });
  }

  private fetchDeviceModels() {
    this.deviceModels = [];
    // this.subscribers.fetchDeviceModels = this.deviceModelsService.getDeviceModels().subscribe((result: any) => {
    this.subscribers.fetchDeviceModels = this.deviceModelService.getDeviceModels().subscribe((result: any) => {
      this.deviceModels = result.data.filter((d) => !d.is_deleted);
      this.deviceModelEntities = this.deviceModels.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}) as any;
    });
  }

  // private createDeviceLogs() {
  //   if (!this.virtualDevices || !this.virtualDevices.length || !this.dhl || !this.dhl.length) {
  //     return;
  //   }

  //   this.historyLogs = [];

  //   for (const device of this.virtualDevices) {
  //     for (const l of this.dhl) {
  //       if (l.deviceId + '' !== device.id + '') {
  //         continue;
  //       }

  //       this.historyLogs.push({
  //         ...device,
  //         createdAt: l.createdAt,
  //         triggeredBy: l.triggeredBy,
  //         unit: l.unit,
  //         value: l.value,
  //         label: l.label,
  //       });
  //     }
  //   }
  // }

  onEditorSave(event) {
    // console.log(this.holderDevice);
    // console.log(event);
    const data = { customData: event };
    this.subscribers.updateDevice = this.devicesService.updateDevice(this.holderDevice.id, data).subscribe({
      next: (result: any) => {
        const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
        const title = this.sharedService.getTranslation('DEVICE');
        this.sharedService.notify(title, text, 'success');
      },
      error: (error: any) => {
        const text = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('DEVICE');
        this.sharedService.notify(title, text, 'error');
      },
    });
  }
}
