import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import { Page } from '@app/core2/models/page';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { SiteService } from '@app/integrator/services';

interface Device {
  idx: string;
  name: string;
  locationName: string;
  locationIdx: string;
  fullName: string;
  min: number;
  max: number;
}

@Component({
  selector: 'sc-ceos-alert',
  templateUrl: './ceos-alert.component.html',
})
export class CeosAlertComponent implements OnInit, OnDestroy {
  // The widget with its configuration
  @Input() config: any;

  DISPLAY_VALUE = 'value';
  DISPLAY_PERCENT = 'percent';

  page: Page;
  selectedSite: Site;

  subscribers: { [key: string]: any } = {};
  intervals: { [key: string]: any } = {};

  chartData: any;
  chartLayout: any;

  isInitialized: boolean = false;
  isFetchingStatistics: boolean = false;
  isFetchingDevices: boolean = false;

  widgetName: string = '';
  defaultWidgetName: string = '';
  customWidgetName: string = '';

  // Details modal
  isDetailsModalOpen: boolean = false;

  // Config modal
  isConfigModalOpen: boolean = false;
  configForm: FormGroup;

  // Statistics
  ceosStats: any[] = [];
  legendItems: any[] = [];

  // Multisites
  selectItems: { [key: string]: SelectItem[] } = {};
  selectSitesDefaultLabel: string;
  isMultisites: boolean;

  constructor(
    private widgetService: SCWidgetService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private formBuilder: FormBuilder,
    private siteService: SiteService
  ) {
    this.chartLayout = {
      height: 190,
      width: 190,
      showlegend: false,
      margin: { t: 15, b: 15, l: 15, r: 15 },
      font: {
        family: 'barlow',
        size: 13,
      },
    };

    this.configForm = this.formBuilder.group({
      name: [''],
      display: [this.DISPLAY_VALUE],
      threshold: 12,
      sites: [],
    });

    this.selectSitesDefaultLabel = `${this.sharedService.getTranslation('CCP_COMMON_SELECT')} ${this.sharedService
      .getTranslation('SITES')
      .toLowerCase()}`;
  }

  ngOnInit() {
    this.initWidgetName();

    this.selectItems.sites = this.sharedService.selectItems.sites;

    this.fetchInitialData();
    this.intervals.fetchDataset = setInterval(() => this.fetchStatistics(), 10000);
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  initWidgetName() {
    this.defaultWidgetName = this.sharedService.getLocaleWidgetName(this.config);
    if (this.config?.customPreferences?.name) {
      this.customWidgetName = this.config.customPreferences.name;
    }
    this.widgetName = this.customWidgetName ? this.customWidgetName : this.defaultWidgetName;
  }

  fetchInitialData() {
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (site) => {
        this.selectedSite = site;
        this.subscribers.watchPage = this.store.pipe(select(fromStore.getCurrentPage)).subscribe({
          next: (page) => {
            if (page?.key === 'integrator_overview') {
              this.isMultisites = true;
            } else {
              this.isMultisites = false;
            }
            this.page = page;
            this.fetchStatistics();
            this.isInitialized = true;
          },
        });
      },
    });
  }

  getCeosAlert() {
    if (this.isMultisites) {
      return this.widgetService.getCeosAlert(this.sites);
    } else {
      return this.widgetService.getCeosAlert([this.selectedSite.id]);
    }
  }

  fetchStatistics() {
    if (this.isFetchingStatistics || (this.isMultisites && !this.sites?.length)) {
      return;
    }

    this.isFetchingStatistics = true;

    this.subscribers.fetchDataset = this.getCeosAlert().subscribe({
      next: (result: any) => {
        this.ceosStats = [];
        let ceosOk = [];
        let ceosWarning = [];
        let ceosAlert = [];
        result.data.map((ceos) => {
          const threshold = (this.config.customPreferences?.threshold || this.configForm.get('threshold').value) * 60;
          const maxSeenMinutesAgo = ceos.maxSeenMinutesAgo || 1;
          const diff = moment.utc().diff(moment.utc(ceos.lastSeenAt), 'minutes', true);
          const lastSeenAt = this.sharedService.dateFormat(ceos.lastSeenAt, 'fromNow');
          if (diff < maxSeenMinutesAgo) {
            ceosOk.push(ceos);
            this.ceosStats.push(
              Object.assign({}, ceos, {
                lastSeenAt,
                status: 'Ok',
              })
            );
          } else if (diff <= threshold) {
            ceosWarning.push(ceos);
            this.ceosStats.push(
              Object.assign({}, ceos, {
                lastSeenAt,
                status: 'Warning',
              })
            );
          } else if (diff > threshold) {
            ceosAlert.push(ceos);
            this.ceosStats.push(
              Object.assign({}, ceos, {
                lastSeenAt,
                status: 'Alert',
              })
            );
          }
        });

        const thresholds = [
          {
            index: 0,
            name: 'Ok',
            color: 'green',
            total: ceosOk.length,
          },
          {
            index: 1,
            name: 'Warning',
            color: 'orange',
            total: ceosWarning.length,
          },
          {
            index: 2,
            name: 'Alert',
            color: 'red',
            total: ceosAlert.length,
          },
        ];

        this.renderChart(thresholds);
      },
      error: (error: any) => {
        console.error(error);
        this.isFetchingStatistics = false;
      },
      complete: () => (this.isFetchingStatistics = false),
    });
  }

  openDetailsModal() {
    this.isDetailsModalOpen = true;
  }

  openConfigModal() {
    this.isConfigModalOpen = true;
    this.patchConfigForm(this.config);
  }

  resetWidgetName() {
    this.configForm.patchValue({
      name: '',
    });
  }

  patchConfigForm(config) {
    if (!config.customPreferences) {
      return;
    }

    this.configForm.patchValue({
      name: config.customPreferences.name,
      display: config.customPreferences.display,
      threshold: config.customPreferences.threshold,
      sites: config.customPreferences.sites,
    });
  }

  saveConfig() {
    let cfg = this.configForm.value;

    let customPreferences = {
      name: cfg.name,
      display: cfg.display,
      threshold: cfg.threshold,
      sites: cfg.sites,
    };

    this.isConfigModalOpen = false;
    let { page, sectionIndex } = this.widgetService.setWidgetCustomPreferences(
      this.page,
      this.config.uniqueId,
      customPreferences
    );
    this.store.dispatch(new fromStore.UpdatePage(page));
  }

  renderChart(thresholds) {
    let values = [];
    let labels = [];
    let colors = [];
    this.legendItems = [];

    for (const threshold of thresholds) {
      values.push(threshold.total);
      labels.push(threshold.name);
      colors.push(this.widgetService.getColorByKey(threshold.color).hex);

      this.legendItems.push({
        value: threshold.total,
        label: threshold.name,
        color: this.widgetService.getColorByKey(threshold.color).hex,
      });
    }

    this.chartData = [
      {
        type: 'pie',
        values: values,
        labels: labels,
        textinfo: this.config.customPreferences?.display || 'value',
        marker: {
          colors: colors,
        },
        textposition: 'inside',
        hole: 0.6,
      },
    ];
  }

  isConfigured() {
    return !this.isMultisites || (this.isMultisites && this.sites && this.sites.length > 0);
  }

  get sites() {
    return this.config?.customPreferences?.sites;
  }
}
