<div [formGroup]="form" class="ui-g">
  <div class="ui-g-6 ui-g-offset-6 ui-g-nopad">
    <sc-dropdown
      *ngIf="mappingValues"
      [controlName]="formControlName"
      [form]="form"
      label="{{ formControlLabel | translate }}"
      [options]="mappingValues"
      [showLabel]="false"
    ></sc-dropdown>

    <sc-text-input
      *ngIf="!mappingValues"
      [controlName]="formControlName"
      [form]="form"
      label="{{ formControlLabel | translate }}"
      [showLabel]="false"
    ></sc-text-input>
  </div>
</div>
