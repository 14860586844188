<div [formGroup]="group" class="ui-g-12 ui-g-nopad">
  <div class="ui-g-6">
    {{ valueMappingName }}
    ({{ valueMappingKey }})
  </div>

  <div class="ui-g-6">
    <sc-text-input
      controlName="mappedValue"
      [form]="group"
      label="{{ 'VALUE' | translate }}"
    ></sc-text-input>
  </div>
</div>
