import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { SelectItem } from 'primeng/api';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { Site } from '@widgets/sites/models/site';

import { AutomationLogsService } from '../../services/automation-logs.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { SharedService } from '@shared/shared.service';

import { AutomationLogsDeviceCellComponent } from '../al-device-cell/al-device-cell.component';
import { AutomationLogsMessageCellComponent } from '../al-message-cell/al-message-cell.component';
import { AutomationLogsTimeCellComponent } from '../al-time-cell/al-time-cell.component';

@Component({
  selector: 'sc-al-table',
  templateUrl: './al-table.component.html',
  styleUrls: ['./al-table.component.scss'],
})
export class AutomationLogsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  datasource: any;
  form: FormGroup;
  fetchingState = 0;
  isFirstTime = true;
  isFetching = false;
  selectItems: { [key: string]: SelectItem[] } = {};
  selectedDevice: DeviceDetails;
  table: GridOptions;
  toolbarItems: string[] = [];
  widgetName: string = '';

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private automationLogsService: AutomationLogsService,
    private devicesService: DevicesService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // resize content area
    this.config.widgetHeight = this.config.widgetHeight - 50;

    // Set Table Columns
    this.columns = this.createColumns();

    this.createFilterForm();

    // get selected site
    this.subscribers.selectedSite = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDevices();
        this.fetchDataset();
      }
    });

    // get device details
    this.subscribers.deviceDetials = this.store.select(fromStore.getDeviceDetials).subscribe((result) => {
      if (result) {
        this.selectedDevice = result;
        this.isFirstTime = true;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (!this.isFetching) {
      this.isFetching = true;

      this.datasource = {
        getRows: (rowParams) => {
          const options: any = {
            filter: rowParams.filterModel,
            sort: rowParams.sortModel,
            offset: rowParams.startRow,
            limit: 100,
          };
          if (this.selectedSite && this.selectedSite.id) {
            options.siteId = this.selectedSite.id;
          }
          if (this.selectedDevice && this.selectedDevice.id) {
            options.devices = this.selectedDevice.id;
          }

          const formValue = this.form.value;
          if (formValue.daterange) {
            options.daterange = formValue.daterange;
          }
          if (formValue.devices && formValue.devices.length) {
            options.devices = formValue.devices.join();
          }
          if (formValue.levels && formValue.levels.length) {
            options.levels = formValue.levels.join();
          }
          if (formValue.tags && formValue.tags.length) {
            options.tags = formValue.tags.join();
          }
          if (formValue.subtags && formValue.subtags.length) {
            options.subtags = formValue.subtags.join();
          }

          this.subscribers.automationLogs = this.automationLogsService
            .getAutomationLogsLazy(options)
            .subscribe((result: any) => {
              const rowData = result.data;
              let lastRow = -1;
              if (rowData.length < 100) {
                lastRow = rowParams.endRow - 100 + rowData.length;
              }
              rowParams.successCallback(rowData, lastRow);
              this.resizeColumns();
            });
        },
      };

      // SET DATASOURCE IF TABLE READY
      if (this.table && this.table.api) {
        this.table.api.setDatasource(this.datasource);
      }

      this.isFetching = false;
      this.isFirstTime = false;
    }
  }

  private fetchDevices() {
    this.fetchingState++;
    const options = { siteId: this.selectedSite.id };
    this.subscribers.devices = this.devicesService.getDevices(options).subscribe((result: any) => {
      const automations = result.data.filter((d) => !d.isDeletd && d.deviceTypeKey === 'a');
      this.selectItems.devices = this.sharedService.createSelectItems(automations, false);
      if (this.table && this.table.api) {
        this.table.api.redrawRows();
      }
      this.fetchingState--;
    });
  }

  private createFilterForm() {
    this.selectItems.devices = [];
    this.selectItems.levels = [
      { label: 'Success', value: 's' },
      { label: 'Info', value: 'i' },
      { label: 'Warn', value: 'w' },
      { label: 'Error', value: 'e' },
      { label: 'Fatal', value: 'f' },
    ];
    this.selectItems.tags = [
      { label: 'Rules', value: 'r' },
      { label: 'System', value: 's' },
      { label: 'Z-Wave', value: 'z' },
    ];
    this.selectItems.subtags = [
      { label: 'Command failed', value: 'rcf' },
      { label: 'System Booted', value: 'sb' },
      { label: 'Local IP changed', value: 'slipc' },
      { label: 'Anydesk ID changed', value: 'saidc' },
      { label: 'Node added', value: 'zna' },
      { label: 'Node new', value: 'znn' },
      { label: 'Node removed', value: 'znr' },
      { label: 'Network failed', value: 'znetf' },
      { label: 'Network started', value: 'znetstart' },
      { label: 'Network stopped', value: 'znetstop' },
      { label: 'Network ready', value: 'znetr' },
      { label: 'Network resetted', value: 'znetr' },
      { label: 'Network awaked', value: 'zneta' },
      { label: 'Driver removed', value: 'zdr' },
      { label: 'Driver failed', value: 'zdf' },
      { label: 'Driver ready', value: 'zdr' },
      { label: 'Failed nodes found', value: 'zfnf' },
      { label: 'No failed nodes found', value: 'znfnf' },
    ];

    this.form = this.formBuilder.group({
      daterange: null,
      devices: null,
      levels: null,
      tags: null,
      subtags: null,
    });
  }

  private createColumns() {
    return [
      {
        colId: 'time',
        headerName: 'TIME',
        field: 'createdAt',
        width: 150,
        valueFormatter: this.getTime.bind(this),
        valueGetter: this.getTime.bind(this),
        cellRendererFramework: AutomationLogsTimeCellComponent,
      },
      {
        colId: 'level',
        headerName: 'LEVEL',
        field: 'level',
        width: 100,
        valueFormatter: this.getLevel.bind(this),
        valueGetter: this.getLevel.bind(this),
      },
      {
        colId: 'deviceId',
        headerName: 'DEVICE',
        field: 'deviceId',
        width: 150,
        valueFormatter: this.getDevice.bind(this),
        valueGetter: this.getDevice.bind(this),
        cellRendererFramework: AutomationLogsDeviceCellComponent,
      },
      {
        colId: 'message',
        headerName: 'MESSAGE',
        field: 'message',
        width: 150,
        cellRendererFramework: AutomationLogsMessageCellComponent,
      },
      {
        colId: 'tag',
        headerName: 'TAGS',
        field: 'tag',
        width: 400,
        valueFormatter: this.getTag.bind(this),
        valueGetter: this.getTag.bind(this),
      },
    ];
  }

  onSelectDate(event) {
    this.form.patchValue({ daterange: { from: event.from, to: event.to } });
    this.form.markAsDirty();
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  private getDevice(params) {
    if (!params.data || !params.data.deviceId) {
      return '';
    }
    const device = this.selectItems.devices.find((d) => d.value === +params.data.deviceId);
    return device ? device.label : params.data.deviceId;
  }

  private getLevel(params) {
    if (!params.data || !params.data.level) {
      return '';
    }
    const level = this.selectItems.levels.find((lv) => lv.value === params.data.level);
    return level ? level.label : params.data.level;
  }

  private getTime(params) {
    if (!params.data || !params.data.createdAt) {
      return '';
    }
    return this.sharedService.dateFormat(params.data.createdAt);
  }

  private getTag(params) {
    if (!params.data || (!params.data.tag && !params.data.subtag)) {
      return '';
    }
    const arr = [];
    if (params.data.tag) {
      const tag = this.selectItems.tags.find((t) => t.value === params.data.tag);
      arr.push(tag ? tag.label : params.data.tag);
    }
    if (params.data.subtag) {
      const subtag = this.selectItems.subtags.find((t) => t.value === params.data.subtag);
      arr.push(subtag ? subtag.label : params.data.subtag);
    }
    return arr.join(': ');
  }

  private resizeColumns() {
    if (this.table && this.table.columnApi && this.table.columnApi.getAllColumns) {
      const allColumnIds = [];
      this.table.columnApi.getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.colId);
      });
      this.table.columnApi.autoSizeColumns(allColumnIds);
    }
  }
}
