import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDevices from '../reducers/devices.reducer';
import * as fromRouter from './router.selector';

export const getDeviceState = createFeatureSelector<fromDevices.DeviceState>('devices');

export const getDevicesEntities = createSelector(getDeviceState, fromDevices.getDeviceEntities);

export const getAllDevices = createSelector(getDevicesEntities, (entities) =>
  entities ? Object.keys(entities).map((id) => entities[id]) : []
);

export const getDevicesError = createSelector(getDeviceState, fromDevices.getDevicesError);

export const getDevicesLoaded = createSelector(getDeviceState, fromDevices.getDevicesLoaded);

export const getDevicesLoadedAt = createSelector(getDeviceState, fromDevices.getDevicesLoadedAt);

export const getDevicesLoading = createSelector(getDeviceState, fromDevices.getDevicesLoading);

export const getSelectedDeviceId = createSelector(getDeviceState, fromDevices.getSelectedDeviceId);

export const getSelectedDevice = createSelector(
  getDevicesEntities,
  getSelectedDeviceId,
  (entities, id) => entities && entities[id]
);

export const getDeviceDetials = createSelector(
  getDevicesEntities,
  fromRouter.getRouterStateUrlParams,
  (entities, params) => {
    if (!entities) {
      return;
    }

    if (params && params.pageId === 'devices' && !isNaN(params.pageParams)) {
      return entities[+params.pageParams];
    }

    return;
  }
);
