import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as SceneTemplatesActions from '../actions/scene-templates.action';

import { SceneTemplatesService } from '@widgets/scene-templates/services/scene-templates.service';
import { SharedService } from '@shared/shared.service';

@Injectable()
export class SceneTemplatesEffects {
  constructor(
    private action$: Actions,
    private sceneTemplatesService: SceneTemplatesService,
    private sharedService: SharedService
  ) {}

  @Effect()
  loadSceneTemplate$ = this.action$.pipe(
    ofType(SceneTemplatesActions.LOAD_SCENE_TEMPLATE),
    map((action: SceneTemplatesActions.LoadSceneTemplate) => action.payload),
    switchMap((id) => {
      return this.sceneTemplatesService.get(id).pipe(
        map((result: any) => new SceneTemplatesActions.LoadSceneTemplateSuccess(result.data)),
        catchError((error) => of(new SceneTemplatesActions.LoadSceneTemplateFail(error)))
      );
    })
  );

  @Effect()
  loadSceneTemplates$ = this.action$.pipe(
    ofType(SceneTemplatesActions.LOAD_SCENE_TEMPLATES),
    switchMap(() => {
      return this.sceneTemplatesService.get().pipe(
        map((result: any) => new SceneTemplatesActions.LoadSceneTemplatesSuccess(result.data)),
        catchError((error) => of(new SceneTemplatesActions.LoadSceneTemplatesFail(error)))
      );
    })
  );

  @Effect()
  createSceneTemplate$ = this.action$.pipe(
    ofType(SceneTemplatesActions.CREATE_SCENE_TEMPLATE),
    map((action: SceneTemplatesActions.CreateSceneTemplate) => action.payload),
    switchMap((sceneTemplate) => {
      return this.sceneTemplatesService.create(sceneTemplate).pipe(
        map((result: any) => {
          this.sharedService.notify('Scene Template', 'Create Success', 'success');
          return new SceneTemplatesActions.CreateSceneTemplateSuccess(result.data);
        }),
        catchError((error) => of(new SceneTemplatesActions.CreateSceneTemplateFail(error)))
      );
    })
  );

  @Effect()
  updateSceneTemplate$ = this.action$.pipe(
    ofType(SceneTemplatesActions.UPDATE_SCENE_TEMPLATE),
    map((action: SceneTemplatesActions.UpdateSceneTemplate) => action.payload),
    switchMap((sceneTemplate) => {
      return this.sceneTemplatesService.update(sceneTemplate).pipe(
        map((result: any) => {
          this.sharedService.notify('Scene Template', 'Update Success', 'success');
          return new SceneTemplatesActions.UpdateSceneTemplateSuccess(sceneTemplate);
        }),
        catchError((error) => of(new SceneTemplatesActions.UpdateSceneTemplateFail(error)))
      );
    })
  );

  @Effect()
  deleteSceneTemplate$ = this.action$.pipe(
    ofType(SceneTemplatesActions.DELETE_SCENE_TEMPLATE),
    map((action: SceneTemplatesActions.DeleteSceneTemplate) => action.payload),
    switchMap((sceneTemplate) => {
      return this.sceneTemplatesService.delete(sceneTemplate).pipe(
        map((result: any) => {
          this.sharedService.notify('Scene Template', 'Delete Success', 'success');
          return new SceneTemplatesActions.DeleteSceneTemplateSuccess(sceneTemplate);
        }),
        catchError((error) => of(new SceneTemplatesActions.DeleteSceneTemplateFail(error)))
      );
    })
  );
}
