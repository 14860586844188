<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form
    [formGroup]="form"
    novalidate
    autocomplete="off"
    style="padding: 16px 35px"
  >
    <!--Header-->
    <div class="ui-g">
      <div class="ui-g-6 ui-g-nopad">
        <sc-text-input
          *ngIf="editMode && (!ceosField || (ceosField && isAutomation))"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>

        <sc-text-input
          *ngIf="editMode && ceosField && !isAutomation"
          controlName="idx"
          [form]="form"
          label="{{ 'IDX' | translate }}"
        ></sc-text-input>
      </div>

      <div
        class="ui-g-6 text-right"
        style="display: flex; flex-direction: row-reverse"
      >
        <sc-activate-button [form]="form" field="isActive"></sc-activate-button>
      </div>
    </div>

    <!-- BODY -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6" *ngIf="!ceosField">
        <sc-checkbox-input
          controlName="isVirtualDevicesHolder"
          [form]="form"
          label="{{ 'VIRTUAL_DEVICES_HOLDER' | translate }}"
        ></sc-checkbox-input>
      </div>

      <div class="ui-g-12 ui-md-6" *ngIf="!ceosField">
        <sc-checkbox-input
          controlName="isVirtualDevice"
          [form]="form"
          label="{{ 'VIRTUAL_DEVICE' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- LEFT_COLUMN -->
      <div class="ui-g-12 ui-md-6 ui-g-nopad">
        <!-- LOCATION_ID -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-dropdown
            controlName="locationId"
            [form]="form"
            label="{{ 'LOCATION' | translate }}"
            [options]="selectItems?.locations"
          ></sc-dropdown>
        </div>

        <!-- DEVICE_TYPE_ID -->
        <div class="ui-g-12" *ngIf="!ceosField || (ceosField && isAutomation)">
          <sc-dropdown
            controlName="deviceTypeId"
            [form]="form"
            label="{{ 'DEVICE_TYPE' | translate }}"
            [options]="selectItems?.deviceTypes"
          ></sc-dropdown>
        </div>

        <!-- DEVICE_MODEL_ID -->
        <div class="ui-g-12" *ngIf="!ceosField || (ceosField && isAutomation)">
          <sc-dropdown
            controlName="deviceModelId"
            [form]="form"
            label="{{ 'DEVICE_MODEL' | translate }}"
            [options]="selectItems?.deviceModels"
          ></sc-dropdown>
        </div>

        <!-- BRAND_ID -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-dropdown
            controlName="brandId"
            [form]="form"
            label="{{ 'BRAND' | translate }}"
            [options]="selectItems?.deviceBrands"
          ></sc-dropdown>
        </div>

        <!-- API_KEY -->
        <div class="ui-g-12" *ngIf="isAutomation || isGateway">
          <sc-text-input
            controlName="apiKey"
            [form]="form"
            label="{{ 'API_KEY' | translate }}"
            warning="{{ 'WARNING_AUTOMATION_MISSING' | translate }}"
          ></sc-text-input>
        </div>

        <!-- PIN -->
        <div class="ui-g-12" *ngIf="isAutomation && !ceosField">
          <sc-text-input
            controlName="pin"
            [form]="form"
            label="{{ 'PIN' | translate }}"
            warning="{{ 'WARNING_AUTOMATION_MISSING' | translate }}"
          ></sc-text-input>
        </div>

        <!-- INSTALLATION_PIN -->
        <div class="ui-g-12" *ngIf="isAutomation && !ceosField">
          <sc-text-input
            controlName="installationPin"
            [form]="form"
            label="{{ 'INSTALLATION_PIN' | translate }}"
          ></sc-text-input>
        </div>

        <!-- AUTOMATION_ID -->
        <div class="ui-g-12" *ngIf="!isAutomation && !ceosField">
          <sc-dropdown
            controlName="automationId"
            [form]="form"
            label="{{ 'AUTOMATION' | translate }}"
            [options]="selectItems?.automations"
            warning="{{ 'WARNING_AUTOMATION_MISSING' | translate }}"
          ></sc-dropdown>
        </div>

        <!-- GATEWAY_ID -->
        <div class="ui-g-12" *ngIf="!isAutomation && !ceosField">
          <sc-dropdown
            controlName="gatewayId"
            [form]="form"
            label="{{ 'GATEWAY' | translate }}"
            [options]="selectItems?.gateways"
            warning="{{ 'WARNING_GATEWAY_MISSING' | translate }}"
          ></sc-dropdown>
        </div>

        <!-- PROTOCOL_ID -->
        <div class="ui-g-12" *ngIf="!ceosField || (ceosField && !isAutomation)">
          <sc-dropdown
            controlName="protocolId"
            [form]="form"
            label="{{ 'PROTOCOL' | translate }}"
            [options]="selectItems?.deviceProtocols"
          ></sc-dropdown>
        </div>

        <!-- DESCRIPTION -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="description"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- INSTRUCTION_POSITION_X -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-number-input
            controlName="instructionPositionX"
            [form]="form"
            label="{{ 'INSTRUCTION_POSITION_X' | translate }}"
          ></sc-number-input>
        </div>

        <!-- INSTRUCTION_POSITION_Y -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-number-input
            controlName="instructionPositionY"
            [form]="form"
            label="{{ 'INSTRUCTION_POSITION_Y' | translate }}"
          ></sc-number-input>
        </div>

        <!-- INSTALLATION_HEIGHT -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-number-input
            controlName="installationHeight"
            [form]="form"
            label="{{ 'INSTALLATION_HEIGHT' | translate }}"
          ></sc-number-input>
        </div>
      </div>

      <!-- RIGHT_COLUMN -->
      <div class="ui-g-12 ui-md-6 ui-g-nopad">
        <div class="ui-g-12" *ngIf="requiredVirtualDeviceHolder && !ceosField">
          <sc-dropdown
            controlName="virtualDeviceHolderId"
            [form]="form"
            label="{{ 'VIRTUAL_DEVICES_HOLDER' | translate }}"
            [options]="virtualDevicesHolders"
          ></sc-dropdown>
        </div>

        <!-- UUID -->
        <div class="ui-g-12" *ngIf="!ceosField || (ceosField && !isAutomation)">
          <sc-text-input
            controlName="uuid"
            [form]="form"
            label="{{ 'NODE_ID' | translate }}"
            warning="{{ uuidWarningText | translate }}"
          ></sc-text-input>
        </div>

        <!-- IP -->
        <div class="ui-g-12 ui-g-nopad" *ngIf="!ceosField">
          <div class="ui-g-8">
            <sc-text-input
              controlName="ip"
              [form]="form"
              label="{{ 'IP' | translate }}"
            ></sc-text-input>
          </div>

          <!-- PORT -->
          <div class="ui-g-4">
            <sc-number-input
              controlName="port"
              [form]="form"
              label="{{ 'PORT' | translate }}"
            ></sc-number-input>
          </div>
        </div>

        <!-- ANYDESK_ID -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-text-input
            controlName="anydeskId"
            [form]="form"
            label="{{ 'ANYDESK' | translate }}"
          ></sc-text-input>
        </div>

        <!-- USERNAME -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-text-input
            controlName="username"
            [form]="form"
            label="{{ 'USERNAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- PASSWORD -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-password-input
            controlName="password"
            [form]="form"
            label="{{ 'PASSWORD' | translate }}"
          ></sc-password-input>
        </div>

        <!-- PAN -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-text-input
            controlName="pan"
            [form]="form"
            label="{{ 'PAN' | translate }}"
          ></sc-text-input>
        </div>

        <!-- MODEL_NUMBER -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-text-input
            controlName="modelNumber"
            [form]="form"
            label="{{ 'MODEL_NUMBER' | translate }}"
          ></sc-text-input>
        </div>

        <!-- AVERAGE_CALCULATION_WEIGHT -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-number-input
            controlName="avcCalcWeight"
            [form]="form"
            label="{{ 'AVERAGE_CALCULATION_WEIGHT' | translate }}"
          ></sc-number-input>
        </div>

        <!-- DIFF_DETECTION_FACTOR -->
        <div class="ui-g-12" *ngIf="!ceosField || (ceosField && !isAutomation)">
          <sc-text-input
            controlName="diffDetectionFactor"
            [form]="form"
            label="{{
              (ceosField
                ? 'CCP_DEVICE_MIN_DIFFERENCE_THRESHOLD'
                : 'DIFF_DETECTION_FACTOR'
              ) | translate
            }}"
          ></sc-text-input>
        </div>

        <!-- DEVICE_VALUE_MODIFIER -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-dropdown
            controlName="valueModifier"
            [form]="form"
            label="{{ 'DEVICE_VALUE_MODIFIER' | translate }}"
            [options]="selectItems?.deviceValueModifiers"
          ></sc-dropdown>
        </div>

        <!-- RELATED_DEVICES -->
        <div class="ui-g-12" *ngIf="!ceosField">
          <sc-text-input
            controlName="relatedDevices"
            [form]="form"
            label="{{ 'RELATED_DEVICES' | translate }}"
          ></sc-text-input>
        </div>

        <!-- PRODUCT_KEY -->
        <!-- <div class="ui-g-12" *ngIf="ceosField">
          <sc-text-input
            controlName="productKey"
            [form]="form"
            label="{{ 'PRODUCT_KEY' | translate }}"
          ></sc-text-input>
        </div> -->
      </div>

      <div class="ui-g-12 ui-g-nopad">
        <!-- LOCATION_TO_CONTROL -->
        <div class="ui-g-12" *ngIf="isAutomation">
          <sc-dropdown
            controlName="locationsToControl"
            [form]="form"
            label="{{ 'LOCATIONS_TO_CONTROL' | translate }}"
            [options]="
              ceosField ? selectItems?.locations : selectItems?.unitLocations
            "
            [multiSelect]="true"
          ></sc-dropdown>
        </div>

        <!-- CONFIG_FETCH_INTERVAL -->
        <div class="ui-g-12 ui-md-6" *ngIf="isAutomation && !ceosField">
          <sc-number-input
            controlName="configFetchInterval"
            [form]="form"
            label="{{ 'CONFIG_FETCH_INTERVAL' | translate }}"
            suffix="{{ 'SECONDS' | translate }}"
          ></sc-number-input>
        </div>

        <!-- HL_SENDER_INTERVAL -->
        <div class="ui-g-12 ui-md-6" *ngIf="isAutomation && !ceosField">
          <sc-number-input
            controlName="hlSenderInterval"
            [form]="form"
            label="{{ 'HL_SENDER_INTERVAL' | translate }}"
            suffix="{{ 'SECONDS' | translate }}"
          ></sc-number-input>
        </div>

        <!-- CONSIDER_FOR_OCCUPANCY -->
        <div class="ui-g-12 ui-md-6" *ngIf="!ceosField">
          <sc-checkbox-input
            controlName="considerForOccupancy"
            [form]="form"
            label="{{ 'CONSIDER_FOR_OCCUPANCY' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- CONTROLLABLE_BY_GUEST -->
        <div class="ui-g-12 ui-md-6" *ngIf="!ceosField">
          <sc-checkbox-input
            controlName="isControllableByGuest"
            [form]="form"
            label="{{ 'CONTROLLABLE_BY_GUEST' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- EXCLUDE_FROM_LHL -->
        <div
          class="ui-g-12 ui-md-6"
          *ngIf="!ceosField || (ceosField && !isAutomation)"
        >
          <sc-checkbox-input
            controlName="excludeFromLHL"
            [form]="form"
            label="{{ 'EXCLUDE_FROM_LHL' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- CONTROLLABLE_BY_USER -->
        <div class="ui-g-12 ui-md-6" *ngIf="!ceosField">
          <sc-checkbox-input
            controlName="isControllableByUser"
            [form]="form"
            label="{{ 'CONTROLLABLE_BY_USER' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- IS_GLOBAL_DEVICE -->
        <div class="ui-g-12 ui-md-6" *ngIf="!ceosField">
          <sc-checkbox-input
            controlName="isGlobalDevice"
            [form]="form"
            label="{{ 'GLOBAL_DEVICE' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- IS_GROUPED -->
        <div class="ui-g-12 ui-md-6" *ngIf="!ceosField">
          <sc-checkbox-input
            controlName="isGrouped"
            [form]="form"
            label="{{ 'IS_GROUPED' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- IS_HIDDEN_FROM_ROOM_CONTROLLER -->
        <div class="ui-g-12 ui-md-6" *ngIf="ceosField && !isAutomation">
          <sc-checkbox-input
            controlName="isHiddenFromRoomController"
            [form]="form"
            label="{{ 'IS_HIDDEN_FROM_ROOM_CONTROLLER' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- GATEWAY_DOES_NOT_RESPOND_ON_REQUEST -->
        <div class="ui-g-12 ui-md-6" *ngIf="isGateway && !ceosField">
          <sc-checkbox-input
            controlName="gatewayDoesNotRespondOnRequest"
            [form]="form"
            label="{{ 'GATEWAY_DOES_NOT_RESPOND_ON_REQUEST' | translate }}"
          ></sc-checkbox-input>
        </div>

        <div class="ui-g-12 ui-md-6">
          <sc-checkbox-input
            controlName="isPersistent"
            [form]="form"
            label="{{ 'DEVICE_PERSISTENT' | translate }}"
          ></sc-checkbox-input>
        </div>
      </div>

      <!-- ZWAVE/ZIGBEE CONTROLLER -->
      <ng-container *ngIf="isAutomation && !ceosField">
        <!-- ZWAVE -->
        <h4>{{ "ZWAVE" | translate }}</h4>

        <!-- ZWAVE_STATUS -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zwaveStatus"
            [form]="form"
            label="{{ 'ZWAVE_STATUS' | translate }}"
            [options]="selectItems?.zwaveStatus"
          ></sc-dropdown>
        </div>

        <!-- ZWAVE_CONTROLLER_SOFTWARE -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zwaveControllerSoftware"
            [form]="form"
            label="{{ 'ZWAVE_CONTROLLER_SOFTWARE' | translate }}"
            [options]="selectItems?.zwaveControllerSoftwares"
          ></sc-dropdown>
        </div>

        <!-- ZWAVE_CONTROLLER_PORT -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zwaveControllerPort"
            [form]="form"
            label="{{ 'ZWAVE_CONTROLLER_PORT' | translate }}"
            [options]="selectItems?.zwaveControllerPorts"
            [editable]="true"
          ></sc-dropdown>
        </div>

        <!-- ZIGBEE -->
        <h4>{{ "ZIGBEE" | translate }}</h4>

        <!-- ZIGBEE_STATUS -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zigbeeStatus"
            [form]="form"
            label="{{ 'ZIGBEE_STATUS' | translate }}"
            [options]="selectItems?.zigbeeStatus"
          ></sc-dropdown>
        </div>

        <!-- ZIGBEE_CONTROLLER_PORT -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zigbeeControllerPort"
            [form]="form"
            label="{{ 'ZIGBEE_CONTROLLER_PORT' | translate }}"
            [options]="selectItems?.zigbeeControllerPorts"
            [editable]="true"
          ></sc-dropdown>
        </div>
      </ng-container>

      <ng-container *ngIf="!ceosField">
        <h4 class="mt-5 mb-2">{{ "DEVICE_ALERT" | translate }}</h4>

        <div class="ui-g-12">
          <sc-checkbox-input
            controlName="isDeviceAlertEnabled"
            [form]="form"
            label="{{ 'DEVICE_ALERT_ENABLED' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- DEVICE_ALERT_TIMEOUT -->
        <div class="ui-g-12">
          <sc-number-input
            controlName="deviceAlertTimeout"
            [form]="form"
            label="{{ 'DEVICE_ALERT_TIMEOUT' | translate }}"
            [min]="0"
            description="leave blank will take threshold value"
            suffix="{{ 'MINUTES' | translate }}"
          ></sc-number-input>
        </div>

        <!-- BEHAVIOUR_PATTERNS -->
        <ng-container *ngIf="!isAutomation && behaviourPatterns?.controls">
          <div class="ui-g-12 ui-g-nopad mt-5">
            <div class="ui-g-12 ui-md-8 ui-g-nopad">
              <h4 class="m-0">
                {{ "ABNORMAL_BEHAVIOR_DETECTION_PATTERNS" | translate }}
              </h4>
            </div>
            <div class="ui-g-12 ui-md-4 ui-g-nopad text-right">
              <button
                pButton
                type="button"
                class="ui-button-success"
                (click)="addBehaviourPattern()"
                icon="fa fa-plus"
                pTooltip="
                  {{ 'ADD' | translate }}
                  {{ 'ABNORMAL_BEHAVIOR_DETECTION_PATTERNS' | translate }}
                "
              ></button>
            </div>
          </div>

          <div class="ui-g-12 ui-g-nopad">
            <div class="ui-g-12 ui-md-1 text-center">
              {{ "COMPARE_WITH_LAST_DHL" | translate }}
            </div>

            <div class="ui-g-12 ui-md-2 text-center">
              {{ "OPERAND_X" | translate: { value: 1 } }}
            </div>

            <div class="ui-g-12 ui-md-1 text-center">
              {{ "OPERATOR" | translate }}
            </div>

            <div class="ui-g-12 ui-md-5 text-center">
              {{ "OPERAND_X" | translate: { value: 2 } }}
            </div>

            <div class="ui-g-12 ui-md-1 text-center">
              {{ "REPORT" | translate }}
            </div>

            <div class="ui-g-12 ui-md-1 text-center">
              {{ "IGNORE" | translate }}
            </div>

            <div class="ui-g-12 ui-md-1"></div>
          </div>

          <div class="ui-g-12 ui-g-nopad mb-5">
            <ng-container formArrayName="behaviourPatterns">
              <ng-container
                *ngFor="let ctrl of behaviourPatterns.controls; let i = index"
                [formGroupName]="i"
              >
                <sc-behaviour-patterns-form
                  [form]="ctrl"
                  [formId]="i"
                  (removed)="removeBehaviourPattern($event)"
                ></sc-behaviour-patterns-form>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <div class="ui-g" *ngIf="!formReadOnly">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div
        class="ui-g-6 text-right"
        style="display: flex; justify-content: flex-end; gap: 8px"
      >
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
