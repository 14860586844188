import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';

@Component({
  selector: 'sc-global-operand',
  templateUrl: './global-operand.component.html',
  styleUrls: ['./global-operand.component.scss'],
})
export class GlobalOperandComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {}
}
