import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { DataSources, SelectItems, SelectItem, CustomFunctionParameterTypes, ModifierTypes } from '../../models';
import { RulesService, UtilsService } from '../../services';

@Component({
  selector: 'sc-modifier-item',
  templateUrl: './modifier-item.component.html',
  styleUrls: ['./modifier-item.component.scss'],
})
export class ModifierItemComponent implements OnInit, OnDestroy {
  _customFunctionTypes = CustomFunctionParameterTypes;
  modifierTypeSelectItems: SelectItem[] = [];
  operatorSelectItems: SelectItem[] = [];
  deviceSelectItems: SelectItem[] = [];
  locationSelectItems: SelectItem[] = [];
  structure: any;
  parametersForm: FormArray;

  @Input()
  control: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  modifiers: { limit: number; compatible: any[] };

  @Input()
  isTemplate: boolean;

  @Input()
  selectItems: SelectItems = {};

  @Output()
  removed = new EventEmitter<void>();

  private compatible: any[];
  private subscribers: { [key: string]: any } = {};

  constructor(private rulesService: RulesService, private utilsService: UtilsService) {}

  ngOnInit(): void {
    if (this.modifiers && this.modifiers.compatible) {
      this.compatible = this.modifiers.compatible;
      this.modifierTypeSelectItems = this.compatible.map((c) => ({ label: c.type, value: c.type }));
    }

    if (this.control) {
      const controlValue = this.control.value;
      this.subscribeOnFormValueChanges();
      if (controlValue.type) {
        this.setOperators(controlValue.type);
        if (controlValue.parameters && controlValue.parameters.length) {
          this.setParametersForm(controlValue.parameters);
        }
      }
    }
  }

  ngOnDestroy() {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private subscribeOnFormValueChanges() {
    this.subscribers.modifierType = this.control.get('type').valueChanges.subscribe({
      next: (value) => {
        this.clearParametersForm();
        this.setOperators(value);
      },
    });

    this.subscribers.modifierValue = this.control.get('value').valueChanges.subscribe({
      next: (value) => {
        const type = this.control.get('type').value;

        // custom function parameters
        if (
          type === ModifierTypes.CustomFunction &&
          this.dataSources &&
          this.dataSources.customFunctions &&
          this.dataSources.customFunctions.length
        ) {
          const cf = this.dataSources.customFunctions.find((f) => f.idx === value);
          if (cf && cf.parameters && cf.parameters.length) {
            this.setParametersForm(cf.parameters);
          }
        }
      },
    });
  }

  private setParametersForm(parameters: any[] | string) {
    if (typeof parameters === 'string') {
      try {
        parameters = JSON.parse(parameters as string);
      } catch (error) {}
    }

    this.rulesService.setModifierParametersForm(this.control, parameters as any[], this.isTemplate);
    this.parametersForm = this.control.get('parameters') as FormArray;
    this.setSelectItems(parameters as any[]);
  }

  private clearParametersForm() {
    this.rulesService.removeModifierParametersForm(this.control);
    this.parametersForm = null;
    this.control.get('value').reset();
  }

  private setSelectItems(parameters: any[]) {
    if (!this.dataSources) {
      return;
    }

    for (const param of parameters) {
      if (
        param.type === CustomFunctionParameterTypes.LatestDhlValue &&
        this.dataSources.devices &&
        this.dataSources.devices.length
      ) {
        this.deviceSelectItems = this.dataSources.devices
          .filter((data) => /* data.isActive && !d.isDeleted && */ data.device_type === param.device_type)
          .map((data) => ({ label: data.name, value: data.idx }));
      } else if (
        param.type === CustomFunctionParameterTypes.LatestLhlValue &&
        this.dataSources.locations &&
        this.dataSources.locations.length
      ) {
        this.locationSelectItems = this.dataSources.locations
          // .filter((data) => data.isActive && !d.isDeleted)
          .map((data) => ({ label: data.name, value: data.idx }));
      }
    }
  }

  private setOperators(type: string) {
    // set allowed operators
    if (this.compatible) {
      this.structure = this.compatible.find((c) => c.type === type);
      if (this.structure && this.structure.operators && this.structure.operators.length) {
        this.operatorSelectItems = this.structure.operators.map((o) => ({ label: o, value: o }));
      } else {
        this.operatorSelectItems = [];
      }
    }
  }
}
