import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'empty-widget',
  templateUrl: 'empty-widget.component.html',
})
export class EmptyWidgetComponent implements OnInit {
  @Input()
  config: any;

  widgetName: string = '';

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);
  }
}
