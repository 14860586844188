<div class="ui-g mb-4">
  <div class="ui-g-12 ui-g-nopad">
    <button pButton type="button"
      [ngClass]="changeViewBtnClass('lastweek')"
      (click)="onChangeView('lastweek')"
      label="{{'LAST_WEEK' | translate}}"
    ></button>

    <button pButton type="button"
      [ngClass]="changeViewBtnClass('thismonth')"
      (click)="onChangeView('thismonth')"
      label="{{'THIS_MONTH' | translate}}"
    ></button>

    <button pButton type="button"
      [ngClass]="changeViewBtnClass('lastmonth')"
      (click)="onChangeView('lastmonth')"
      label="{{'LAST_MONTH' | translate}}"
    ></button>

    <button pButton type="button"
      [ngClass]="changeViewBtnClass('lastyear')"
      (click)="onChangeView('lastyear')"
      label="{{'LAST_YEAR' | translate}}"
    ></button>
  </div>
</div>


<div class="ui-g">
  <div class="ui-g-12 ui-g-nopad" style="border:1px solid #f2f2f2; height:400px;">
    <sc-table [columns]="tableColumns" [dataset]="dataset" [hideHeader]="true">
    </sc-table>
  </div>
</div>
