import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss']
})
export class DeviceFormComponent implements OnInit, OnDestroy {
  @Input()
  form: FormGroup;
  @Input()
  formId: number;
  @Input() data: {
    [key: string]: any;
    rawData: { [key: string]: any };
    selectItems: { [key: string]: SelectItem[] };
  };

  @Output() applyAllChecked = new EventEmitter<any>();

  applyAll: FormControl;

  private subscribers: { [key: string]: any } = {};

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    // create applyAll form only the first device of first location
    // if (this.data && this.data.firstLocation && this.formId === 0) {
    if (
      this.data &&
      (this.data.firstLocation || this.data.isFirstChildLocation)
    ) {
      this.applyAll = new FormControl(false);
      this.subscribers.APPLY_ALL = this.applyAll.valueChanges.subscribe(
        value => {
          // applyAll is checked, emit selected gatewayId
          if (value === true) {
            this.emitApplyAll();
          }
        }
      );

      // watch gatewayId
      this.subscribers.GATEWAY = this.form
        .get('gatewayId')
        .valueChanges.subscribe(value => {
          // if apply all is checked, re-emit the event
          if (this.applyAll.value === true) {
            this.emitApplyAll();
          }
        });

      // watch isActive
      this.subscribers.ACTIVE = this.form
        .get('isActive')
        .valueChanges.subscribe(value => {
          // if apply all is checked, re-emit the event
          if (this.applyAll.value === true) {
            this.emitApplyAll();
          }
        });
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  get deviceTypeName(): string {
    if (this.data && this.data.rawData) {
      const deviceType = this.data.rawData.deviceTypes.find(
        (d: any) => d.id === this.form.value.deviceTypeId
      );
      if (deviceType) {
        return deviceType.name;
      }
    }
    return '(empty)';
  }

  get gatewayId() {
    return this.form.get('gatewayId').value;
  }

  get isActive() {
    return this.form.get('isActive').value;
  }

  private emitApplyAll() {
    if (this.gatewayId) {
      this.applyAllChecked.emit({
        gatewayId: this.gatewayId,
        isActive: this.isActive
      });
    }
  }
}
