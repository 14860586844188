import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { RuleCategory } from '../models/rule-category';
import { RuleCategoriesService } from '../services/rule-categories.service';
import { RuleCategoryFormService } from '../services/rule-category-form.service';
import { SharedService } from '@shared/shared.service';
import { Locale } from '@widgets/locales/models/locale';

@Component({
  selector: 'sc-rule-category-form',
  templateUrl: './rule-category-form.component.html',
  styleUrls: ['./rule-category-form.component.scss'],
})
export class RuleCategoryFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: RuleCategory;
  isSubmitting = false;
  localesForm: FormArray;

  private locales: Locale[];
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private ruleCategoriesService: RuleCategoriesService,
    private ruleCategoryFormService: RuleCategoryFormService
  ) {}

  ngOnInit(): void {
    this.locales = this.sharedService.sourceData.locales;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchRuleCategory(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchRuleCategory(id: number) {
    this.fetchingState++;
    this.subscribers.fetchRuleCategory = this.ruleCategoriesService.getRuleCategory(id).subscribe((result: any) => {
      this.formData = result.data;
      this.form.patchValue(this.formData);
      this.ruleCategoryFormService.patchLocaleFormArray(this.form, this.formData);
      this.fetchingState--;
    });
  }

  private initForm() {
    this.form = this.ruleCategoryFormService.initRuleCategoryForm(this.locales);
    this.localesForm = this.form.get('locales') as FormArray;
  }

  getFlag(locale) {
    return this.sharedService.getFlagClassName(locale);
  }

  // API CALL
  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.value;
    const locales = formData.locales;
    delete formData.locales;
    formData.name = {};
    formData.description = {};

    // TODO: convert locales to name and description object
    if (locales && locales.length) {
      // locale: "en", name: "Table Widget", description: "table table table table table table"
      for (const locale of locales) {
        if (locale.locale && locale.name) {
          formData.name[locale.locale] = locale.name;
          formData.description[locale.locale] = locale.description;
        }
      }
    }

    // call api
    if (this.editMode) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  }

  private create(formData) {
    this.subscribers.create = this.ruleCategoriesService.createRuleCategory(formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(formData) {
    this.subscribers.update = this.ruleCategoriesService.updateRuleCategory(this.formData.id, formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.ruleCategoriesService.deleteRuleCategory(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('RULE_CATEGORY');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }
}
