import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

// Components
import { LocationProfilesTableComponent } from './location-profiles-table/location-profiles-table.component';
import { LocationProfileActionCellComponent } from './location-profile-action-cell/location-profile-action-cell.component';
import { LocationProfileFormComponent } from './location-profile-form/location-profile-form.component';
import { AvgTemperatureFormComponent } from './location-profile-form/avg-temperature-form/avg-temperature-form.component';
import { AvgHumidityFormComponent } from './location-profile-form/avg-humidity-form/avg-humidity-form.component';

// Services
import { LocationProfilesService } from './services/location-profile.service';
import { LocationProfileFormsService } from './services/location-profile-forms.service';

const COMPONENTS = [
  LocationProfilesTableComponent,
  LocationProfileActionCellComponent,
  LocationProfileFormComponent,
  AvgTemperatureFormComponent,
  AvgHumidityFormComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [LocationProfilesService, LocationProfileFormsService]
})
export class LocationProfilesModule {}
