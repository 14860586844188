import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { IGuestRequestPreset } from '../guest-request-preset.interface';
import { ModalConfig } from '@sc/modal/modal-config';

import { GuestRequestPresetsService } from '../guest-request-presets.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';
import { GuestRequestPresetActionCellComponent } from '../guest-request-preset-action-cell/guest-request-preset-action-cell.component';
import { MultiLanguageCellComponent } from '../../_shared/multi-language-cell/multi-language-cell.component';
import { TagsCellComponent } from '../../_shared/tags-cell/tags-cell.component';

@Component({
  selector: 'sc-guest-request-presets-table',
  templateUrl: 'guest-request-presets-table.component.html'
})
export class GuestRequestPresetsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: IGuestRequestPreset[];
  formConfig: ModalConfig;
  tableTitle = 'GUEST_REQUEST_PRESETS';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private guestRequestPresetsService: GuestRequestPresetsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.columns = this.createColumns();

    this.formConfig = {
      name: 'GuestRequestPresetForm',
      options: { modalTitle: 'GUEST_REQUEST_PRESET_FORM_TITLE' }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.dataset = this.guestRequestPresetsService
      .get()
      .subscribe((result: any) => {
        const dataset = result.data.map(d => {
          d.categories = this.sharedService.jsonParse(d.categories);
          d.description = this.sharedService.jsonParse(d.description);
          d.title = this.sharedService.jsonParse(d.title);
          return d;
        });
        this.dataset = dataset;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'title',
        headerName: 'TITLE',
        field: 'title',
        cellRendererFramework: MultiLanguageCellComponent
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
        cellRendererFramework: MultiLanguageCellComponent
      },
      {
        colId: 'categories',
        headerName: 'CATEGORIES',
        field: 'categories',
        cellRendererFramework: TagsCellComponent
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: GuestRequestPresetActionCellComponent
      }
    ];
  }
}
