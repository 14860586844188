import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { LocationTemplateFormsService } from '../../services/location-template-forms.service';

@Component({
  selector: 'sc-child-form',
  templateUrl: 'child-form.component.html',
})
export class ChildFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() groupId: any;
  @Input() optionData: any;
  @Output() removed = new EventEmitter();

  constructor(private formService: LocationTemplateFormsService) {}

  ngOnInit() {}

  get childNumber() {
    return this.groupId + 1;
  }

  // ____ DEVICES
  get devicesFormArray() {
    return this.group.get('devices') as FormArray;
  }
  addDevice() {
    const controls = <FormArray>this.group.controls['devices'];
    controls.push(this.formService.initDeviceFormGroup());
  }
  removeDevice(index: number) {
    const controls = <FormArray>this.group.controls['devices'];
    controls.removeAt(index);
  }

  // ____ RULE_TEMPLATES
  get ruleTemplatesFormArray() {
    return this.group.get('ruleTemplates') as FormArray;
  }
  addRuleTemplate() {
    const controls = <FormArray>this.group.controls['ruleTemplates'];
    controls.push(this.formService.initRuleFormGroup());
  }
  removeRuleTemplate(index: number) {
    const controls = <FormArray>this.group.controls['ruleTemplates'];
    controls.removeAt(index);
  }

  // ____ CHILDREN
  get childrenFormArray() {
    return this.group.get('children') as FormArray;
  }
  addChild() {
    const controls = <FormArray>this.group.controls['children'];
    controls.push(this.formService.initLocationTemplateForm());
  }
  removeChild(index: number) {
    const controls = <FormArray>this.group.controls['children'];
    controls.removeAt(index);
  }
}
