import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { AppStyleTemplateFormsService } from '../../services/app-style-template-forms.service';

@Component({
  selector: 'sc-variable-form-group',
  templateUrl: 'variable-form-group.component.html'
})
export class VariableFormGroupComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Output()
  removed = new EventEmitter();

  variableTypes: SelectItem[];

  constructor(
    private appStyleTemplateFormsService: AppStyleTemplateFormsService
  ) {}

  ngOnInit() {
    this.variableTypes = this.appStyleTemplateFormsService
      .getVariableTypes()
      .map(type => ({
        label: type,
        value: type
      }));
  }

  get dynamicInput() {
    switch (this.group.controls['type'].value) {
      case 'hex':
      case 'rgba':
        return 'colorInput';

      case 'pct':
      case 'px':
        return 'numberInput';

      case 'file':
        return 'fileInput';

      default:
        return 'textInput';
    }
  }

  remove() {
    this.removed.emit();
  }
}
