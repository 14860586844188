<!-- MODBUS READABLE SECTION -->
<div class="ui-g">
  <div class="ui-g-12 ui-md-12">
    <sc-checkbox-input
      controlName="is_readable"
      [form]="modbusForm"
      label="{{ 'READABLE' | translate }}"
    ></sc-checkbox-input>
  </div>
  <div class="ui-g-12 ui-md-3">
    <sc-dropdown
      controlName="read_function"
      [form]="modbusForm"
      [label]="'FUNCTION' | translate"
      [options]="selectItems?.readFunctions"
      [disabled]="!isReadable"
    ></sc-dropdown>
  </div>
  <div class="ui-g-12 ui-md-2">
    <sc-text-input
      controlName="read_address"
      [form]="modbusForm"
      [label]="'ADDRESS' | translate"
    ></sc-text-input>
  </div>
  <div class="ui-g-12 ui-md-2">
    <sc-text-input
      controlName="read_modifier"
      [form]="modbusForm"
      [label]="'MODIFIER' | translate"
    ></sc-text-input>
  </div>
  <div class="ui-g-12 ui-md-2">
    <sc-dropdown
      controlName="read_data_type"
      [form]="modbusForm"
      [label]="'DATATYPE' | translate"
      [options]="selectItems?.modbusDataTypes"
      [disabled]="!isReadable"
    ></sc-dropdown>
  </div>
  <div class="ui-g-12 ui-md-1">
    <sc-number-input
      controlName="read_decimals"
      [form]="modbusForm"
      [label]="'DECIMALS' | translate"
      [min]="0"
    ></sc-number-input>
  </div>
  <div class="ui-g-12 ui-md-1">
    <sc-number-input
      controlName="read_position"
      [form]="modbusForm"
      [label]="'POSITION' | translate"
      [min]="0"
    ></sc-number-input>
  </div>
  <div class="ui-g-12 ui-md-1">
    <sc-number-input
      controlName="read_count"
      [form]="modbusForm"
      [label]="'COUNT' | translate"
      [min]="0"
    ></sc-number-input>
  </div>

  <!-- MODBUS WRITEABLE SECTION-->
  <div class="ui-g-12 ui-md-12">
    <sc-checkbox-input
      controlName="is_writeable"
      [form]="modbusForm"
      label="{{ 'WRITEABLE' | translate }}"
    ></sc-checkbox-input>
  </div>
  <div class="ui-g-12 ui-md-3">
    <sc-dropdown
      controlName="write_function"
      [form]="modbusForm"
      [label]="'FUNCTION' | translate"
      [options]="selectItems?.writeFunctions"
      [disabled]="!isWriteable"
    ></sc-dropdown>
  </div>
  <div class="ui-g-12 ui-md-2">
    <sc-text-input
      controlName="write_address"
      [form]="modbusForm"
      [label]="'ADDRESS' | translate"
    ></sc-text-input>
  </div>
  <div class="ui-g-12 ui-md-2">
    <sc-text-input
      controlName="write_modifier"
      [form]="modbusForm"
      [label]="'MODIFIER' | translate"
    ></sc-text-input>
  </div>
  <div class="ui-g-12 ui-md-2">
    <sc-dropdown
      controlName="write_data_type"
      [form]="modbusForm"
      [label]="'DATATYPE' | translate"
      [options]="selectItems?.modbusDataTypes"
      [disabled]="!isWriteable"
    ></sc-dropdown>
  </div>
  <div class="ui-g-12 ui-md-1">
    <sc-number-input
      controlName="write_decimals"
      [form]="modbusForm"
      [label]="'DECIMALS' | translate"
      [min]="0"
    ></sc-number-input>
  </div>
  <div class="ui-g-12 ui-md-1"></div>

  <!-- POLLING SECTION -->
  <div class="ui-g-12 ui-md-12">
    <sc-checkbox-input
      controlName="is_polling_enabled"
      [form]="modbusForm"
      label="{{ 'POLLING_ENABLED' | translate }}"
    ></sc-checkbox-input>
  </div>
  <div class="ui-g-12 ui-md-1">
    <sc-number-input
      controlName="polling_pause_seconds"
      [form]="modbusForm"
      [label]="'POLLING_PAUSE_SECOND' | translate"
      [min]="0"
    ></sc-number-input>
  </div>
</div>
