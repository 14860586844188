import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ColDef } from 'ag-grid';
import { ElectricityCost } from '../models/electricity-cost.model';
import { ModalConfig } from '@sc/modal/modal-config';
import { Site } from '@widgets/sites/models/site';

import { ElectricityCostsService } from '../services/electricity-costs.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { ElectricityCostActionCellComponent } from '../cost-action-cell/cost-action-cell.component';

@Component({
  selector: 'sc-costs-table',
  templateUrl: './costs-table.component.html',
  styleUrls: ['./costs-table.component.scss'],
})
export class ElectricityCostsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: ElectricityCost[];
  columns: ColDef[];
  formConfig: ModalConfig;

  private componentDestroyed: Subject<void> = new Subject();
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private electricityCostsService: ElectricityCostsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    // Table Columns
    this.columns = this.createColumns();

    // Create Form Config
    this.formConfig = {
      name: 'ElectricityCostForm',
      options: { modalTitle: 'ELECTRICITY_COSTS_FORM_TITLE' },
    };

    // Get Selected Site
    this.store
      .select(fromStore.getSelectedSite)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;

          this.fetchDataset();
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);

    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  fetchDataset() {
    const options = {
      siteId: this.selectedSite.id,
    };
    this.subscribers.GET_FORM_FIELDS = this.electricityCostsService
      .getElectricityCosts(options)
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'dateFrom',
        headerName: 'DATE_FROM',
        field: 'dateFrom',
        width: 150,
        valueFormatter: (params) => (params.value ? this.sharedService.dateFormat(params.value, 'DD.MM.YYYY') : ''),
      },
      {
        colId: 'dateTo',
        headerName: 'DATE_TO',
        field: 'dateTo',
        width: 150,
        valueFormatter: (params) => (params.value ? this.sharedService.dateFormat(params.value, 'DD.MM.YYYY') : ''),
      },
      {
        colId: 'timeFrom',
        headerName: 'TIME_FROM',
        field: 'timeFrom',
        width: 150,
        valueFormatter: (params) => (params.value ? this.sharedService.dateFormat(params.value, 'HH:mm') : ''),
      },
      {
        colId: 'timeTo',
        headerName: 'TIME_TO',
        field: 'timeTo',
        valueFormatter: (params) => (params.value ? this.sharedService.dateFormat(params.value, 'HH:mm') : ''),
      },
      {
        colId: 'unit',
        headerName: 'ELECTRICITY_COSTS_UNIT',
        field: 'unit',
      },
      {
        colId: 'unitPrice',
        headerName: 'ELECTRICITY_COSTS_UNIT_PRICE',
        field: 'unitPrice',
      },
      {
        colId: 'currencyId',
        headerName: 'CURRENCY_ID',
        field: 'currencyId',
        hide: true,
      },
      {
        colId: 'siteId',
        headerName: 'SITE_ID',
        field: 'siteId',
        hide: true,
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: ElectricityCostActionCellComponent,
      },
    ];
  }
}
