<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'AC_OFF' | translate }} -->
        {{ widgetName }}
      </h4>

      <div class="sc-widget-menu">
        <i
          class="fa fa-refresh fa-fw sc-clickable green-text"
          [ngClass]="{ 'fa-spin': isFetching }"
          (click)="fetchDataset()"
          pTooltip="{{ 'REFRESH' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
      </div>
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body">
    <div class="sc-flex w-100 grow-1 align-items-center justify-content-center">
      <button
        pButton
        type="button"
        [ngClass]="periodButtonClass('LAST_YEAR')"
        label="{{ 'YEAR' | translate }}"
        (click)="switchPeriod('LAST_YEAR')"
      ></button>
      <button
        pButton
        type="button"
        [ngClass]="periodButtonClass('LAST_MONTH')"
        label="{{ 'MONTH' | translate }}"
        (click)="switchPeriod('LAST_MONTH')"
      ></button>
      <button
        pButton
        type="button"
        [ngClass]="periodButtonClass('LAST_WEEK')"
        label="{{ 'WEEK' | translate }}"
        (click)="switchPeriod('LAST_WEEK')"
      ></button>
      <button
        pButton
        type="button"
        [ngClass]="periodButtonClass('TODAY')"
        label="{{ 'TODAY' | translate }}"
        (click)="switchPeriod('TODAY')"
      ></button>
    </div>

    <div
      *ngIf="widgetData"
      class="sc-flex w-100 grow-1 align-items-center justify-content-center"
    >
      <div
        *ngIf="widgetData?.value >= 0"
        class="sc-flex direction-column align-items-end grow-1"
      >
        <div class="text-value" [ngClass]="widgetData?.color">
          {{ widgetData.value }}
        </div>
        <div class="text-unit">{{ "HOURS" | translate }}</div>
      </div>
      <div
        *ngIf="widgetData?.icon"
        class="sc-flex trend"
        [ngClass]="widgetData?.color"
      >
        <i class="fa fa-fw" [ngClass]="widgetData.icon"></i>
      </div>
    </div>

    <!-- <div class="sc-flex justify-content-around w-100">
      <div *ngFor="let wd of widgetData">
        <div *ngIf="wd.label" class="text-center mb-1">
          {{ wd.label | translate: wd.labelParams }}
        </div>
        <sc-circle
          [customClass]="wd?.customClass"
          [customStyle]="wd?.customStyle"
          [icon]="wd?.icon"
          [primaryText]="wd?.primaryText"
          [secondaryText]="wd?.secondaryText | translate"
          [size]="wd?.size"
        ></sc-circle>
      </div>
    </div> -->
  </div>
</ng-template>
