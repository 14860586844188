import { Injectable } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DeviceTypeCommandAction } from '../models/command-action';

@Injectable()
export class DeviceTypeFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initDeviceTypeFrom() {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      chartType: [null, Validators.required],
      icon: null,
      commandActions: this.formBuilder.array([]),
      mappingValues: this.formBuilder.array([]),
      isDeviceAlertEnabled: false,
      deviceAlertTimeout: null,
      isActive: false,
      isDeleted: false
    });
  }

  initActionFormGroup() {
    return this.formBuilder.group({
      commandActionId: ['', Validators.required],
      parameters: this.formBuilder.array([]),
      isActive: false
    });
  }

  setActionFormArray(form: FormGroup, actions: DeviceTypeCommandAction[]) {
    const formGroups = actions.map((action: any) => {
      const paramFormGroups = action.parameters.map(param =>
        this.formBuilder.group(param)
      );
      // create empty action form group
      const actionFormGroup = this.initActionFormGroup();
      // patch action data, NOTE: "params" is formArray then params will not be patched
      actionFormGroup.patchValue(action);
      // patch "params" into action form group by setControl
      // with array of param form groups
      actionFormGroup.setControl(
        'parameters',
        this.formBuilder.array(paramFormGroups)
      );
      // return action form group
      return actionFormGroup;
    });
    // create action form array with action form groups
    const formArray = this.formBuilder.array(formGroups);
    // apply it into the form
    form.setControl('commandActions', formArray);
  }

  initMappingValueFormGroup() {
    return this.formBuilder.group({
      id: null,
      key: [null, Validators.required],
      name: [null, Validators.required]
    });
  }

  setMappingValueFormArray(form: FormGroup, formData: any[]) {
    const formGroups = formData.map(item => {
      const fg = this.initMappingValueFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('mappingValues', formArray);
  }
}
