import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationsHumidityAlertComponent } from './locations-humidity-alert.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationsHumidityAlertComponent],
  entryComponents: [LocationsHumidityAlertComponent],
  providers: []
})
export class LocationsHumidityAlertModule {}
