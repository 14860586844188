import { Page } from '@app/core2/models/page';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPages from '../reducers/pages.reducer';
import * as fromRouter from '../selectors/router.selector';
import * as fromUser from '../selectors/user.selector';

export interface PageWithParams extends Page {
  pageParams?: any;
}

export const getPageState = createFeatureSelector<fromPages.PageState>('pages');

export const getPagesEntities = createSelector(getPageState, fromPages.getPagesEntities);

const checkIntegratorPage = (router: any) => {
  // console.log('checkIntegratorPage:', router);

  const isSiteDashboardPage = /\/integrator\/sites\/\d{1,}\/dashboard$/.test(router.url);
  const isMultiSitesDashboardPage = router.url.startsWith('/integrator/overview/');
  if (isSiteDashboardPage) {
    return 'integrator_site_dashboard';
  } else if (isMultiSitesDashboardPage) {
    return 'integrator_overview';
  } else if (/\/enduser\/sites\/\d{1,}\/dashboard$/.test(router.url)) {
    return 'enduser_site_dashboard';
  }

  // if (/\/integrator\/automations$/.test(router.url)) {
  //   return 'integrator_automations';
  // } else if (/\/integrator\/overview$/.test(router.url)) {
  //   return 'integrator_overview';
  // } else if (/\/integrator\/sites$/.test(router.url)) {
  //   return 'integrator_sites';
  // } else if (/\/integrator\/sites\/\d{1,}\/dashboard$/.test(router.url)) {
  //   return 'integrator_site_dashboard';
  // } else if (/\/integrator\/sites\/\d{1,}\/building$/.test(router.url)) {
  //   return 'integrator_site_building';
  // } else if (/\/integrator\/sites\/\d{1,}\/devices$/.test(router.url)) {
  //   return 'integrator_site_devices';
  // } else if (/\/integrator\/sites\/\d{1,}\/rules$/.test(router.url)) {
  //   return 'integrator_site_rules';
  // } else if (/\/integrator\/sites\/\d{1,}\/locations\/\d{1,}$/.test(router.url)) {
  //   return 'integrator_site_location_details';
  // } else if (/\/integrator\/sites\/\d{1,}\/devices\/\d{1,}$/.test(router.url)) {
  //   return 'integrator_site_device_details';
  // }

  return null;
};

export const getCurrentPage = createSelector(
  getPagesEntities,
  fromUser.getUsername,
  fromRouter.getRouterStateUrl,
  (entities, username, router, props): PageWithParams => {
    if (!router) {
      return;
    }

    const integratorPageId = checkIntegratorPage(router);
    const params = router && router.params;
    // console.log('params:', params);
    let pageId = params && params.pageId;
    const pageParams = params && params.pageParams;

    if (!entities || !username || (!pageId && !integratorPageId)) {
      return;
    }

    if (integratorPageId) {
      pageId = integratorPageId;
    } else if (pageParams) {
      switch (pageId) {
        case 'devices':
          pageId = 'device_details';
          break;
        case 'locations':
          pageId = 'location_details';
          break;
        default:
          break;
      }
    }

    // Try to ensure that the page is loaded for the selected site
    let entityToOverwrite = null;
    if (props?.siteId in entities.__by_site__) {
      if (pageId in entities.__by_site__[props.siteId]) {
        entityToOverwrite = entities.__by_site__[props.siteId][pageId];
      }
    }

    // console.log('pageId:', pageId, entities[pageId]);
    // console.log('>>', entities[pageId]);

    let page = <PageWithParams>{ ...entities[pageId] };
    if (entityToOverwrite) {
      page = <PageWithParams>{ ...entityToOverwrite };
    }

    page.pageParams = params;
    return page;
  }
);

export const getSelectedPage = createSelector(getPageState, fromPages.getSelectedPage);

export const getSelectedSection = createSelector(getPageState, fromPages.getSelectedSection);

export const getAllPages = createSelector(getPagesEntities, (entities) =>
  entities ? Object.keys(entities).map((id) => entities[id]) : []
);

export const getPagesLoaded = createSelector(getPageState, fromPages.getPagesLoaded);

export const getPagesLoading = createSelector(getPageState, fromPages.getPagesLoading);

export const getShowMenu = createSelector(getPageState, fromPages.getShowMenu);

export const getShowOverlayMenu = createSelector(getPageState, fromPages.getShowOverlayMenu);

export const getIsOverlayMenuActive = createSelector(getPageState, fromPages.getIsOverlayMenuActive);

export const getEditMode = createSelector(getPageState, fromPages.getEditMode);

export const getPagesPendingError = createSelector(getPageState, fromPages.getPagesPendingError);

export const getPagesPending = createSelector(getPageState, fromPages.getPagesPending);

export const getUpdateMenu = createSelector(getPageState, fromPages.getUpdateMenu);

export const getSelectedMenu = createSelector(getPageState, fromPages.getSelectedMenu);

export const expandSettings = createSelector(getPageState, fromPages.expandSettings);
