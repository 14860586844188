import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SceneTemplate } from '../models/scene-template';

@Injectable()
export class SceneTemplatesService {
  constructor(private httpClient: HttpClient) {}

  private apiUrl = config().apiUrl + '/sceneTemplates';

  private verifyData(data: any): SceneTemplate {
    const newData = { ...data };
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  get(id?: number) {
    return this.httpClient
      .get(`${this.apiUrl}/${id || ''}`)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  create(data: SceneTemplate) {
    return this.httpClient.post(this.apiUrl, data).pipe(catchError((error: any) => observableThrowError(error)));
  }

  update(data: SceneTemplate) {
    return this.httpClient
      .patch(`${this.apiUrl}/${data.id}`, this.verifyData(data))
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  delete(data: SceneTemplate) {
    return this.httpClient
      .patch(`${this.apiUrl}/${data.id}`, { isDeleted: true, isActive: false })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
