import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { SharedService } from '@shared/shared.service';
import { Subscription } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private apiUrl = config().apiUrl + '/subscriptions';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  getSubscription(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getSubscriptions(automationIdx?: string) {
    const queryParams = this.sharedService.urlQueryParamsCreator({ automation: automationIdx });
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  createSubscription(data: Subscription) {
    return this.httpClient.post(this.apiUrl, { subscriptions: [data] });
  }

  createSubscriptions(data: Subscription[]) {
    return this.httpClient.post(this.apiUrl, { subscriptions: data });
  }

  updateSubscription(data: Subscription) {
    return this.httpClient.patch(this.apiUrl, { subscriptions: [data] });
  }

  updateSubscriptions(data: Subscription[]) {
    return this.httpClient.patch(this.apiUrl, { subscriptions: data });
  }

  deleteSubscriptions(idxs?: string[], ids?: number[]) {
    const body = { body: { idxs, ids } };
    return this.httpClient.request('delete', this.apiUrl, body);
  }
}
