import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AdvancedFormGroupComponent } from './app-style-form/advanced-form-group/advanced-form-group.component';
import { AppStyleActionCellComponent } from './app-style-action-cell/app-style-action-cell.component';
import { AppStyleFormComponent } from './app-style-form/app-style-form.component';
import { AppStylesTableComponent } from './app-styles-table/app-styles-table.component';
import { BasicFormGroupComponent } from './app-style-form/basic-form-group/basic-form-group.component';

import { AppStyleFormsService } from './services/app-style-forms.service';
import { AppStylesService } from './services/app-styles.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    AdvancedFormGroupComponent,
    AppStyleActionCellComponent,
    AppStyleFormComponent,
    AppStylesTableComponent,
    BasicFormGroupComponent
  ],
  entryComponents: [
    AppStyleActionCellComponent,
    AppStyleFormComponent,
    AppStylesTableComponent
  ],
  providers: [AppStyleFormsService, AppStylesService]
})
export class AppStylesModule {}
