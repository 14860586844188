import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { GridOptions} from 'ag-grid';

import { DeviceHistoryLogsService } from '../services/device-history-logs.service';
import { SharedService } from '@shared/shared.service';
import { SCTableService } from '@sc/table/table.service';

import * as moment from 'moment-timezone';
import { config } from '@app/config';

@Component({
  selector: 'sc-device-logs-view',
  templateUrl: './device-logs-view.component.html'
})
export class DeviceLogsViewComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  chartData: any;
  chartOptions: any;
  dataset: any[];
  linkjson: string;
  linkcsv: string;
  fixTimeRange = false;
  isAutoUpdate = false;
  isFirstFetch = true;
  isFetchDeviceHistory = false;
  currentView = 'table';
  chartType = 'table';
  tableColumns: any[];
  formData: {
    from: string;
    to: string;
  };

  private prevTableData: any[];
  private intervals: any = {};
  private subscribers: any = {};
  private table: GridOptions;

  constructor(
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private sharedService: SharedService,
    private tableService: SCTableService
  ) { }

  ngOnInit() {
    this.fetchDeviceHistory();
    this.createTable();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  private setTimeRange() {
    if (!this.fixTimeRange) {
      this.formData = {
        from: moment().subtract(24, 'hour').utc().format('DD.MM.YYYYTHH:mm:ss'),
        to: moment().utc().format('DD.MM.YYYYTHH:mm:ss')
        // interval: 'hour'
      };
    }
  }

  fetchDeviceHistory(force?: boolean) {
    if (force !== true && this.isFetchDeviceHistory === true) {
      return;
    }

    this.isFetchDeviceHistory = true;
    this.setTimeRange();

    if (this.isAutoUpdate && this.dataset) {
      this.prevTableData = [...this.dataset];
    } else {
      this.prevTableData = null;
    }

    // dhl options
    const dhlOptions = {
      deviceId: this.data.device.id,
      fields: 'createdAt,value,triggeredBy',
      filter: this.formData,
      limit: 5000
    };

    // create dhl link
    const dhlLink =
      `${config().apiUrl}/devices/${dhlOptions.deviceId}/history` +
      `?from=${dhlOptions.filter.from}` +
      `&to=${dhlOptions.filter.to}` +
      `&limit=${dhlOptions.limit}` +
      `&fields=${dhlOptions.fields}`;
    this.linkjson = dhlLink + '&output=json';
    this.linkcsv = dhlLink + '&output=csv';

    // fetch dhl data
    this.subscribers.fetchDHL = this.deviceHistoryLogsService
      .getHistoryLogs(dhlOptions)
      .subscribe((result: any) => {
        this.dataset = result.documents;

        if (this.dataset && this.dataset.length) {
          if (this.currentView === 'table') {
            this.updateTable();
          } else {
            this.createChart();
          }
        }

        this.isFetchDeviceHistory = false;

        if (this.isFirstFetch) {
          this.isFirstFetch = false;
          this.startAutoRefresh();
        }
      });
  }

  private get deviceTypeKey() {
    if (this.data && this.data.device && this.data.device.deviceTypeKey) {
      return this.data.device.deviceTypeKey;
    }
    return;
  }

  private get deviceModel() {
    if (this.data && this.data.device && this.data.device.deviceModelId && this.data.deviceModels) {
      const model = this.data.deviceModels.find(
        dm => dm.id === this.data.device.deviceModelId
      );
      return model;
    }
    return;
  }

  private isNumberData() {
    const types = config().deviceTypeKeyHasNumberValue;
    if (types.indexOf(this.deviceTypeKey) >= 0) {
      return true;
    }
    return false;
  }

  // value unit
  private valueUnit() {
    switch (this.deviceTypeKey) {
      case 'bl':
      case 'hs':
        return '%';

      case 'bs':
        return 'lux';

      case 'ts':
        return '°C';

      case 'ws':
        return 'w/h';

      default:
        return '';
    }
  }

  onDateSelect(event) {
    this.stopAutoRefresh();
    this.fixTimeRange = true;
    this.formData.from = event.from;
    this.formData.to = event.to;
  }

  toggleAutoRefresh() {
    if (this.isAutoUpdate) {
      this.stopAutoRefresh();
    } else {
      this.dataset = null;
      this.startAutoRefresh();
    }
  }

  private startAutoRefresh() {
    this.fixTimeRange = false;
    this.isAutoUpdate = true;
    // SET INTERVAL
    this.intervals.autoUpdate = setInterval(() => {
      this.fetchDeviceHistory();
    }, 5000);
  }

  private stopAutoRefresh() {
    this.isAutoUpdate = false;
    // CLEAR INTERVAL
    clearInterval(this.intervals.autoUpdate);
    this.intervals.autoUpdate = null;
  }

  // table columns
  private createTable() {
    this.tableColumns = [
      {
        colId: 'createdAt',
        headerName: 'DATE',
        field: 'createdAt',
        valueFormatter: params => this.dateFormatter(params.data.createdAt),
        valueGetter: params => this.dateFormatter(params.data.createdAt),
        suppressSorting: true,
        width: 200
      },
      {
        colId: 'value',
        headerName: 'VALUE',
        field: 'value',
        valueFormatter: params => this.valueFormatter(params.data.value),
        valueGetter: params => this.valueFormatter(params.data.value),
        cellRenderer: params => `<div class="text-right">${params.valueFormatted}</div>`,
        suppressSorting: true,
        width: 200
      },
      {
        colId: 'triggeredBy',
        headerName: 'TRIGGERED_BY',
        field: 'triggeredBy',
        valueFormatter: params => this.triggeredByFormatter(params.data.triggeredBy),
        valueGetter: params => this.triggeredByFormatter(params.data.triggeredBy),
        cellRenderer: params => `<div class="text-center">${params.valueFormatted}</div>`,
        suppressSorting: true,
        width: 200
      }
    ];
  }

  private updateTable() {
    if (!this.table || !this.dataset) {
      return;
    }

    if (this.isAutoUpdate && this.prevTableData) {
      const diff = [];

      for (let i = 0; i < this.dataset.length; i++) {
        const d1 = this.dataset[i];
        let found = false;

        for (let ii = 0; ii < this.prevTableData.length; ii++) {
          const d2 = this.prevTableData[ii];
          if (d1.createdAt === d2.createdAt) {
            found = true;
            break;
          }
        }

        if (!found) {
          diff.push(d1);
        }
      }

      if (diff.length) {
        this.tableService.addTableRow(this.table, diff, false, 0);
      }
    } else {
      this.tableService.addTableRow(this.table, this.dataset, true);
    }
  }

  // table view: date formatter
  private dateFormatter(data: string) {
    return this.sharedService.dateFormat(data);
  }

  // table view: value formatter
  private valueFormatter(value: any) {
    let currentValue = value;
    if (this.deviceModel && this.deviceModel.mappingValues && this.deviceModel.mappingValues.length) {
      const mappingValues = this.deviceModel.mappingValues;

      // find mapping value
      for (let i = 0; i < mappingValues.length; i++) {
        if (mappingValues[i].key === currentValue) {
          currentValue = mappingValues[i].name;
          break;
        }
      }

      if (typeof currentValue === 'number') {
        // apply avcCalcWeight if exists
        if (typeof this.data.device.avcCalcWeight === 'number' && this.data.device.avcCalcWeight !== 0) {
          currentValue = +currentValue * this.data.device.avcCalcWeight;
        }

        // format number into 2 decimals
        currentValue = this.sharedService.numberFormat(currentValue);
      }
    }
    return currentValue + ' ' + this.valueUnit();
  }

  // table view: triggered by formatter
  private triggeredByFormatter(value: string = '') {
    switch (value.toUpperCase()) {
      case 'H':
        return 'Human';
      case 'R':
        return 'Rule';
      default:
        return '';
    }
  }

  // view control handler
  onChangeView(view: string) {
    if (this.currentView === view) {
      return;
    }

    this.currentView = view;
    if (view === 'bar-chart' || view === 'line-chart') {
      this.chartType = view.replace('-chart', '');
      this.createChart();
    } else if (view === 'table') {
      this.prevTableData = null;
    }
  }

  // view button css class
  changeViewBtnClass(btn: string) {
    if (btn === this.currentView) {
      return 'ui-button-success';
    }
    return 'ui-button-secondary';
  }

  dismissModal(reason: any) {
    this.stopAutoRefresh();
    this.onDismiss.emit(reason);
  }

  private chartValueFormatter(value: any) {
    switch (this.deviceTypeKey) {
      case 'acf':
        if (value === 'h') {
          return 3;
        } else if (value === 'm') {
          return 2;
        } else if (value === 'l') {
          return 1;
        }
        return 0;

      case 'acm':
        return value === 'o' ? 0 : 1;

      case 'lc':
      case 'ms':
        return value === 'n' ? 0 : 1;

      case 'os':
      case 'osd':
      case 'osw':
        return value === 'c' ? 0 : 1;

      default:
        return value;
    }
  }

  private getYTick(): {tickvals: number[], ticktext: string[]} {
    switch (this.deviceTypeKey) {
      case 'acf':
        return {
          tickvals: [0, 1, 2, 3],
          ticktext: ['Auto', 'Low', 'Medium', 'High']
        };

      case 'acm':
      case 'lc':
        return {
          tickvals: [0, 1],
          ticktext: ['OFF', 'ON']
        };

      case 'ms':
        return {
          tickvals: [0, 1],
          ticktext: ['NO', 'YES']
        };

      case 'os':
      case 'osd':
      case 'osw':
        return {
          tickvals: [0, 1],
          ticktext: ['Closed', 'Open']
        };

      default:
        return;
    }
  }

  private getYTitle() {
    let title;
    switch (this.deviceTypeKey) {
      case 'acf':
        title = 'AC_FANSPEED';
        break;

      case 'acm':
        title = 'AC_MODE';
        break;

      case 'acsp':
        title = 'AC_SETPOINT';
        break;

      case 'bl':
        title = 'BATTERY_LEVEL';
        break;

      case 'bs':
        title = 'BRIGHTNESS';
        break;

      case 'hs':
        title = 'HUMIDITY';
        break;

      case 'lc':
        title = 'LIGHTING_CIRCUIT';
        break;

      case 'ms':
        title = 'MOTION';
        break;

      case 'os':
        title = 'OPENING_SENSOR';
        break;

      case 'osd':
        title = 'DOOR';
        break;

      case 'osw':
        title = 'WINDOW';
        break;

      case 'ts':
        title = 'TEMPERATURE';
        break;

      case 'ws':
        title = 'WATTAGE';
        break;

      default:
        title = 'VALUE';
        break;
    }
    return title ? this.sharedService.getTranslation(title) : '';
  }

  private createChart() {
    if (!this.dataset) {
      return;
    }

    const yTitle = this.getYTitle();
    const unit = this.valueUnit();
    const rawData = [...this.dataset].reverse();

    const layout: any = {
      xaxis: {
        rangeslider: {
          borderwidth: 1,
          range: []
        },
        showgrid: false,
        showline: false,
        title: 'Date/Time',
        type: 'date',
      },
      yaxis: {
        rangemode: 'nonnegative',
        showline: false,
        type: 'linear',
      },
      title: unit ? yTitle + ' (' + unit + ')' : yTitle,
    };

    const trace1: any = {
      name: yTitle,
      x: [],
      y: []
    };

    // layout and style per data type
    if (this.isNumberData()) {
      layout.yaxis.tickformat = '.1f';
      layout.yaxis.ticksuffix = unit;
    } else {
      const ytick = this.getYTick();
      if (ytick) {
        layout.yaxis.tickmode = 'array';
        layout.yaxis.tickvals = ytick.tickvals;
        layout.yaxis.ticktext = ytick.ticktext;
      }
    }

    if (this.chartType === 'line') {
      trace1.type = 'scatter';
      trace1.mode = 'lines';
      if (!this.isNumberData()) {
        // plot line in step
        trace1.line = { shape: 'hv' };
      }
    } else if (this.chartType === 'bar') {
      trace1.type = 'bar';
      layout.barmode = 'group';
      layout.bargap = 0.15;
      layout.bargroupgap = 0.1;
    }

    for (let i = 0; i < rawData.length; i++) {
      const item = rawData[i];
      const date = this.sharedService.dateFormat(item.createdAt, 'YYYY-MM-DD HH:mm:ss');
      let value = item.value;
      value = isNaN(value) ? value : this.sharedService.numberFormat(value);
      value = this.chartValueFormatter(value);
      trace1.x.push(date);
      trace1.y.push(value);
    }

    this.chartData = [trace1];
    this.chartOptions = layout;
  }

  afterRelayout() {
    this.stopAutoRefresh();
  }

  afterTableInit(table: GridOptions) {
    this.table = table;

    this.updateTable();
  }
}
