import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { DeviceProtocol } from '../models/device-protocol';
import { ModalConfig } from '@sc/modal/modal-config';

import { DeviceProtocolsService } from '../services/device-protocols.service';
import { SharedService } from '@shared/shared.service';

import { DeviceProtocolActionCellComponent } from '../device-protocol-action-cell/device-protocol-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-device-protocols-table',
  templateUrl: './device-protocols-table.component.html'
})
export class DeviceProtocolsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: DeviceProtocol[];
  formConfig: ModalConfig;
  tableTitle: string;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceProtocolsService: DeviceProtocolsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.tableTitle = 'DEVICE_PROTOCOLS';
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'DeviceProtocolForm',
      options: { modalTitle: 'DEVICE_PROTOCOL_FORM_TITLE' }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.DATASET = this.deviceProtocolsService
      .getDeviceProtocols()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceProtocolActionCellComponent
      }
    ];
  }
}
