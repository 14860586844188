import { Action } from '@ngrx/store';
import { Credential } from '@app/auth/models/credential';
import { User } from '@app/auth/models/user';

export const LOGOUT = '[Auth] User Logout';
export const VERIFY_TWO_FACTOR_AUTH = '[Auth] Verify Two Factor Auth';
export const VERIFY_TWO_FACTOR_AUTH_FAIL = '[Auth] Verify Two Factor Auth Fail';
export const VERIFY_TWO_FACTOR_AUTH_SUCCESS = '[Auth] Verify Two Factor Auth Success';
export const VERIFY_USER = '[Auth] Verify User';
export const VERIFY_USER_FAIL = '[Auth] Verify User Fail';
export const VERIFY_USER_SUCCESS = '[Auth] Verify User Success';
export const VERIFY_USER_2 = '[Auth] Verify User 2';
export const VERIFY_USER_2_FAIL = '[Auth] Verify User 2 Fail';
export const VERIFY_USER_2_SUCCESS = '[Auth] Verify User 2 Success';

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class VerifyTwoFactorAuth implements Action {
  readonly type = VERIFY_TWO_FACTOR_AUTH;
  constructor(public payload: Credential) {}
}

export class VerifyTwoFactorAuthFail implements Action {
  readonly type = VERIFY_TWO_FACTOR_AUTH_FAIL;
  constructor(public payload: any) {}
}

export class VerifyTwoFactorAuthSuccess implements Action {
  readonly type = VERIFY_TWO_FACTOR_AUTH_SUCCESS;
  constructor(public payload: User) {}
}

export class VerifyUser implements Action {
  readonly type = VERIFY_USER;
  constructor(public payload: Credential) {}
}

export class VerifyUserFail implements Action {
  readonly type = VERIFY_USER_FAIL;
  constructor(public payload: any) {}
}

export class VerifyUserSuccess implements Action {
  readonly type = VERIFY_USER_SUCCESS;
  constructor(public payload: User) {}
}

export class VerifyUser2 implements Action {
  readonly type = VERIFY_USER_2;
  constructor(public payload: Credential) {}
}

export class VerifyUser2Fail implements Action {
  readonly type = VERIFY_USER_2_FAIL;
  constructor(public payload: any) {}
}

export class VerifyUser2Success implements Action {
  readonly type = VERIFY_USER_2_SUCCESS;
  constructor(public payload: User) {}
}

// action types
export type AuthAction =
  | Logout
  | VerifyTwoFactorAuth
  | VerifyTwoFactorAuthFail
  | VerifyTwoFactorAuthSuccess
  | VerifyUser
  | VerifyUserFail
  | VerifyUserSuccess
  | VerifyUser2
  | VerifyUser2Fail
  | VerifyUser2Success;
