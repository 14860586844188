import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { AppStyleTemplateFormsService } from '../../services/app-style-template-forms.service';

@Component({
  selector: 'sc-element-form-group',
  templateUrl: 'element-form-group.component.html'
})
export class ElementFormGroupComponent implements OnInit, OnChanges {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  variables: any[];
  @Output()
  removed = new EventEmitter();

  availableVars: string[];

  constructor(
    private appStyleTemplateFormsService: AppStyleTemplateFormsService
  ) {}

  ngOnInit() {
    if (this.group.get('styles').value.length <= 0) {
      this.addStyle();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.variables) {
      this.availableVars = changes.variables.currentValue.map(v => '@' + v.key);
    }
  }

  addStyle() {
    const control = <FormArray>this.group.controls['styles'];
    control.push(this.appStyleTemplateFormsService.initElementStyleFormGroup());
  }

  removeStyle(i: number) {
    const control = <FormArray>this.group.controls['styles'];
    control.removeAt(i);
  }

  get styles(): FormArray {
    return this.group.controls['styles'] as FormArray;
  }

  remove() {
    this.removed.emit();
  }
}
