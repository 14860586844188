import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ModalConfig } from '@sc/modal/modal-config';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import * as moment from 'moment';

@Component({
  selector: 'sc-locations-occupancy-history',
  templateUrl: './locations-occupancy-history.component.html',
  styleUrls: ['locations-occupancy-history.component.scss'],
})
export class LocationsOccupancyHistoryComponent implements OnInit, OnDestroy {
  @Input() config: any;

  chartData: any;
  chartLayout: any;
  dataset: { [key: string]: any };
  detailsModal: ModalConfig;
  isFetching: boolean;
  isFirstTime: boolean;
  lastThreeDaysData: any[];
  widgetName: string = '';

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private widgetService: SCWidgetService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.isFirstTime = true;
          this.fetchDataset();
        }
      },
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite) {
      this.isFetching = true;
      const { id, timezone } = this.selectedSite;
      const options = {
        timezone: this.sharedService.getTimezoneOffset(timezone),
        from: moment().utc().subtract(2, 'day').hours(3).startOf('hour').format('DD.MM.YYYYTHH:mm:ss'),
        to: moment().utc().hours(3).endOf('hour').format('DD.MM.YYYYTHH:mm:ss'),
      };
      this.subscribers.getDataset = this.widgetService.getOccupancyHistory(id, options).subscribe({
        next: (result: any) => {
          this.dataset = result.data;
          this.setLastThreeDays();
          this.isFetching = false;
          this.isFirstTime = false;
        },
        error: (error: any) => {
          this.isFetching = false;
        },
      });
    }
  }

  getDetailsModal(data) {
    return {
      name: 'LocationAlertModal',
      options: { modalTitle: 'LOCATIONS' },
      data: data.locations,
    };
  }

  private setLastThreeDays() {
    const lastThreeDays = [
      { label: 'YESTERDAY', percentage: 0, value: 0, locations: [] },
      { label: '', percentage: 0, value: 0, locations: [] },
      { label: '', percentage: 0, value: 0, locations: [] },
    ];

    if (this.dataset) {
      const totalRooms = this.dataset.totalRooms;
      const history = [...this.dataset.occupiedHistory];

      for (let i = 1; i <= 3; i++) {
        if (!history[3 - i]) {
          continue;
        }

        lastThreeDays[i - 1].locations = history[3 - i].locations;
        lastThreeDays[i - 1].value = history[3 - i].occupied;
        lastThreeDays[i - 1].percentage = this.sharedService.numberFormat((history[3 - i].occupied / totalRooms) * 100);

        // replace LAST_X_HOUR with date
        if (i > 1) {
          lastThreeDays[i - 1].label = moment.utc(history[3 - i].timestamp).format('DD/MM/YYYY');
        }
      }
    }

    this.lastThreeDaysData = lastThreeDays;

    this.chartData = [
      {
        type: 'indicator',
        // mode: 'gauge+number+delta',
        mode: 'gauge+number',
        domain: { x: [0, 1], y: [0, 1] },
        value: lastThreeDays[0].percentage,
        number: { suffix: '%' },
        // delta: { reference: lastThreeDays[1].percentage },
        gauge: {
          axis: { range: [null, 100] },
          bar: { color: '#67C987' },
          bgcolor: '#D0E8D8',
          bordercolor: '#fff',
        },
      },
    ];
    this.chartLayout = {
      margin: { t: 0, b: 0, r: 30, l: 20, pad: 0 },
      width: 200,
      height: 200,
      font: {
        family: 'barlow',
        size: 13,
        color: '#0c2e55',
      },
    };
  }

  // get percentageYesterday() {
  //   if (this.lastThreeDaysData.length) {
  //     return this.lastThreeDaysData[0].percentage;
  //   }
  //   return 0;
  // }
}
