import { Injectable } from '@angular/core';
import { ObjectClasses, ObjectTypes } from '@sc/omnis-editor/models/object';

declare let fabric: any;

@Injectable({ providedIn: 'root' })
export class OmnisEditorService {
  constructor() {}

  private createGridItemSubclass() {
    const gridItemColor = '#ddd';
    const gridItemBorderColor = '#aaa';
    const gridItemColorSelected = '#fee11a';
    const gridItemBorderColorSelected = '#e4ca17';
    const gridItemColorDisabled = '#999';
    const gridItemBorderColorDisabled = '#999';
    const gridItemScopeColorDisabled = '#0085ff';
    const gridItemScopeBorderColorDisabled = '#0085ff';
    // grid item
    fabric.GridItem = fabric.util.createClass(fabric.Rect, {
      type: ObjectTypes.GridItem,
      initialize: function (options) {
        options = options || {};

        // set default values
        // options.evented = false;
        options.excludeFromExport = true;
        options.fill = gridItemColor;
        options.hasBorders = false;
        options.hasControls = false;
        options.hoverCursor = 'pointer';
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockRotation = true;
        options.lockScalingX = true;
        options.lockScalingY = true;
        options.moveable = false;
        options.objectCaching = false;
        // options.opacity = 0.6;
        // options.originX = 'center';
        // options.originY = 'center';
        options.padding = 0;
        options.perPixelTargetFind = true;
        options.selectable = false;
        options.stroke = gridItemBorderColor;
        options.strokeWidth = 1;

        this.callSuper('initialize', options);

        this.set('id', options.id);
        this.set('disabled', options.disabled === true);
        this.set('line', options.line || null);
        this.set('scope', options.scope || null);
        this.set('fontColor', options.fontColor || '#222');
        this.set('fontFamily', options.fontFamily || 'Montserrat');
        this.set('fontSize', options.fontSize || 16);

        this.setSelected(options.selected);
      },
      toObject: function () {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          id: this.get('id'),
          disabled: this.get('disabled'),
          selected: this.get('selected'),
          line: this.get('line'),
          scope: this.get('scope'),
        });
      },
      setSelected: function (value) {
        if (typeof value !== 'undefined') {
          this.set('selected', value === true);
        }

        if (this.selected === true) {
          if (this.disabled === true) {
            // selected and disabled
            this.set('fill', gridItemColorDisabled);
            this.set('stroke', gridItemBorderColorSelected);
          } else {
            // selected
            this.set('fill', gridItemColorSelected);
            this.set('stroke', gridItemBorderColorSelected);
          }
        } else {
          if (this.disabled === true) {
            // unselect and disabled
            this.set('fill', gridItemColorDisabled);
            this.set('stroke', gridItemBorderColorDisabled);
          } else {
            // unselect
            this.set('fill', gridItemColor);
            this.set('stroke', gridItemBorderColor);
          }
        }
      },
      selectAsScope: function () {
        // select for scope
        this.set('fill', gridItemScopeColorDisabled);
        this.set('stroke', gridItemScopeBorderColorDisabled);
      },
      deselectAsScope: function () {
        // deselect for scope
        this.set('fill', gridItemColor);
        this.set('stroke', gridItemBorderColor);
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);

        let x = 0; // this is center
        const y = 0; // this is center
        let text = this.id || '';

        ctx.fillStyle = this.fontColor;
        ctx.font = 'bold ' + this.fontSize + 'px ' + this.fontFamily;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, x, y);
      },
    });
    // fabric.GridItem.fromObject = function (object, callback) {
    //   return fabric.Object._fromObject(ObjectClasses.GridItem, object, callback);
    // };
  }

  private createGridOverlaySubclass() {
    // grid overlay
    fabric.GridOverlay = fabric.util.createClass(fabric.Rect, {
      type: ObjectTypes.GridOverlay,
      initialize: function (options) {
        options = options || {};

        // set default values
        options.evented = false;
        options.excludeFromExport = true;
        options.fill = options.fill || '#0085ff';
        options.hasBorders = false;
        options.hasControls = false;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockRotation = true;
        options.lockScalingX = true;
        options.lockScalingY = true;
        options.moveable = false;
        options.objectCaching = false;
        options.opacity = options.opacity || 0.7;
        options.padding = 0;
        options.perPixelTargetFind = true;
        options.selectable = false;
        options.stroke = options.stroke || '#0085ff';
        options.strokeWidth = 1;

        this.callSuper('initialize', options);

        this.set('id', options.id);
        this.set('fontColor', options.fontColor || '#fff');
        this.set('fontFamily', options.fontFamily || 'Montserrat');
        this.set('fontSize', options.fontSize || 18);
        this.set('overlayText', options.overlayText || '');
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);

        const x = 0; // this is center
        const y = 0; // this is center
        const text = this.overlayText || '';

        ctx.fillStyle = this.fontColor;
        ctx.font = 'bold ' + this.fontSize + 'px ' + this.fontFamily;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, x, y);
      },
    });
    // fabric.GridOverlay.fromObject = function (object, callback) {
    //   return fabric.Object._fromObject(ObjectClasses.GridOverlay, object, callback);
    // };
  }

  private createCountingLineSubclass() {
    // counting line
    fabric.CountingLine = fabric.util.createClass(fabric.Rect, {
      type: ObjectTypes.CountingLine,
      initialize: function (options) {
        options = options || {};

        // set default values
        options.borderColor = '#222';
        options.centeredRotation = false;
        options.cornerColor = '#222';
        options.cornerSize = 10;
        options.cornerStrokeColor = '#222';
        options.evented = true;
        options.fill = options.fill || '#ff88bb';
        options.hasBorders = false;
        options.hasControls = true;
        options.hoverCursor = 'move';
        options.lockMovementX = false;
        options.lockMovementY = false;
        options.lockRotation = false;
        options.lockScalingFlip = true;
        options.lockScalingX = false;
        options.lockScalingY = true;
        options.moveable = true;
        options.objectCaching = false;
        options.padding = 0;
        options.perPixelTargetFind = true;
        options.selectable = false;
        options.stroke = options.stroke || '#ff88bb';
        options.strokeWidth = 1;
        options.strokeUniform = true;
        options.transparentCorners = false;

        this.callSuper('initialize', options);

        this.set('id', options.id);
        this.set('name', options.name);
        this.set('fontColor', options.fontColor || '#ff88bb');
        this.set('fontFamily', options.fontFamily || 'Montserrat');
        this.set('fontSize', options.fontSize || 16);

        this.controls = {};
        // rotate control
        const rotateControl = new fabric.Control({
          actionHandler: fabric.Object.prototype.controls.mtr.actionHandler,
          actionName: 'rotate',
          cornerSize: 20,
          cursorStyle: 'grabbing',
          offsetX: 37,
          offsetY: 0,
          x: 0.5,
          y: 0,
          render: function (ctx, left, top, styleOverride, fabricObject) {
            ctx.save();
            ctx.translate(left, top);
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));

            ctx.font = `900 20px "Font Awesome 5 Free"`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            // black circle
            ctx.fillStyle = '#444';
            ctx.fillText('\uf111', 0, 0);
            // white arrow
            ctx.font = `900 12px "Font Awesome 5 Free"`;
            ctx.fillStyle = '#fff';
            ctx.fillText('\uf2f1', 0, 0);
            ctx.restore();
          },
        });
        this.controls.rotate = rotateControl;
        // scale x control
        const scaleXControl = new fabric.Control({
          actionHandler: fabric.Object.prototype.controls.mr.actionHandler,
          actionName: 'scaleX',
          cornerSize: 20,
          cursorStyle: 'ew-resize',
          offsetX: 15,
          offsetY: 0,
          x: 0.5,
          y: 0,
          render: function (ctx, left, top, styleOverride, fabricObject) {
            ctx.save();
            ctx.translate(left, top);
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));

            ctx.font = `900 20px "Font Awesome 5 Free"`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            // black circle
            ctx.fillStyle = '#444';
            ctx.fillText('\uf111', 0, 0);
            // white arrow
            ctx.font = `900 16px "Font Awesome 5 Free"`;
            ctx.fillStyle = '#fff';
            ctx.fillText('\uf337', 0, 0);
            ctx.restore();
          },
        });
        this.controls.scaleX = scaleXControl;

        this.on('modified', this._onModified);
      },
      toObject: function () {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          id: this.get('id'),
          name: this.get('name'),
        });
      },
      _onModified: function (event) {
        if (event.transform.action === 'rotate') {
          // console.log(JSON.stringify(this.oCoords));
          // console.log(JSON.stringify(this.aCoords));
          // this.setCoords();
          // console.log(JSON.stringify(this.oCoords));
          // console.log(JSON.stringify(this.aCoords));
          // console.log(this.left + this.width, this.top + this.height, this.angle);
          // //angle between the X axis and the segment, given by the coordinates(x1, y1) -(x2, y2), it is also the direction of the vector.
          // var angle=Math.atan2(x2-x1, y2-y1);//in radians
          // var angle=180 * Math.atan2(x2-x1, y2-y1) /Math.PI;//in degrees
          // //length of the segment given by the coordinates(x1, y1) -(x2, y2), it is the length(size) of the vector
          // const radius = Math.sqrt(Math.pow(this.width, 2) + Math.pow(this.height, 2));
          // //find the Cartesian coordinates of the end point.angle is given in radians
          // var x2 = this.left + radius * Math.cos(this.angle);
          // var y2 = this.top + radius * Math.sin(this.angle);
          // console.log(x2, y2, radius);
        } else if (event.transform.action === 'scaleX') {
          this.set({
            scaleX: 1,
            width: this.width * this.scaleX,
          });
        }
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);

        const x = 0; // this is center
        const y = 0 - this.fontSize; // this is center
        const text = this.name || '';
        ctx.fillStyle = this.fontColor;
        ctx.font = 'bold ' + this.fontSize + 'px ' + this.fontFamily;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, x, y);
      },
    });
    fabric.CountingLine.fromObject = function (object, callback) {
      return fabric.Object._fromObject(ObjectClasses.CountingLine, object, callback);
    };
  }

  private createScopeSubclass() {
    // scope
    fabric.Scope = fabric.util.createClass(fabric.Group, {
      type: ObjectTypes.Scope,
      initialize: function (objects, options) {
        options = options || {};
        objects = objects || [];

        // set default values
        options.evented = false;
        options.hasBorders = false;
        options.hasControls = false;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockRotation = true;
        options.lockScalingX = true;
        options.lockScalingY = true;
        options.moveable = false;
        options.objectCaching = false;
        // options.originX = 'center';
        // options.originY = 'center';
        options.padding = 0;
        options.perPixelTargetFind = true;
        options.selectable = false;
        options.stroke = '#000';
        options.strokeWidth = 1;
        options.subTargetCheck = true;

        this.callSuper('initialize', objects, options);

        this.set('id', options.id);
        this.set('name', options.name);
        this.set('squares', options.squares);
        this.set('fontColor', options.fontColor || '#fff');
        this.set('fontFamily', options.fontFamily || 'Montserrat');
        this.set('fontSize', options.fontSize || 20);
      },
      toObject: function () {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          id: this.get('id'),
          name: this.get('name'),
          squares: this.get('squares'),
        });
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);
      },
    });
    fabric.Scope.fromObject = function (object, callback) {
      return fabric.Object._fromObject(ObjectClasses.Scope, object, callback);
    };
  }

  addCustomSubclass() {
    this.createGridItemSubclass();
    this.createGridOverlaySubclass();
    this.createCountingLineSubclass();
    this.createScopeSubclass();
  }

  initCanvas(element, width?: number, height?: number) {
    const canvas = new fabric.Canvas(element, {
      backgroundColor: '#ddd',
      defaultCursor: 'default',
      enableRetinaScaling: false,
      hoverCursor: 'default',
      // moveCursor
      // notAllowedCursor:
      rotationCursor: 'grab',
      fireRightClick: false,
      preserveObjectStacking: true,
      renderOnAddRemove: false,
      selection: false,
      stateful: false,
      stopContextMenu: true,
    });

    if (height) {
      canvas.setHeight(height);
    } else {
      canvas.setHeight(canvas.wrapperEl.parentElement.offsetHeight);
    }

    if (width) {
      canvas.setWidth(width);
    } else {
      canvas.setWidth(canvas.wrapperEl.parentElement.offsetWidth);
    }

    return canvas;
  }

  createGridItem(left: number, top: number, width: number, height: number, options = {}) {
    return new fabric.GridItem({
      left,
      top,
      width,
      height,
      ...options,
      // id: count,
      // left: rowX,
      // top: rowY,
      // width,
      // height,
    });
  }

  createGrid(columns: number, rows: number, width: number, height: number) {
    const rects = [];
    let rowX = 0;
    let rowY = 0;
    let borderWidth = 1;
    let count = 0;

    for (let i = 0; i < rows; i++) {
      if (i > 0) {
        rowX = 0;
        // rowY += height + borderWidth;
        rowY += height;
      }

      for (let ii = 0; ii < columns; ii++) {
        if (ii > 0) {
          // rowX += width + borderWidth;
          rowX += width;
        }

        count++;

        // const rect = new fabric.GridItem({
        //   id: count,
        //   left: rowX,
        //   top: rowY,
        //   width,
        //   height,
        // });
        // rects.push(rect);
        rects.push(this.createGridItem(rowX, rowY, width - borderWidth, height - borderWidth, { id: count }));
      }
    }
    return rects;

    // const gridOptions = {
    // left: 0,
    // top: 0,
    // height: height * rows,
    // width: width * columns,
    // };
    // return new fabric.Grid(rects, gridOptions);
  }

  createOverlay(width: number, height: number, text?: string) {
    const gridOptions = {
      left: 0,
      top: 0,
      height: height,
      width: width,
      overlayText: text,
    };
    return new fabric.GridOverlay(gridOptions);
  }

  createCountingLine(options = {}) {
    const gridOptions = {
      ...options,
      height: 5,
    };
    return new fabric.CountingLine(gridOptions);
  }

  createScope(squares: any[], options: any = {}) {
    const left = fabric.util.array.min(squares, 'left');
    const top = fabric.util.array.min(squares, 'top');
    const objects = [];
    const squareIds = [];

    // create square objects for scope
    for (const s of squares) {
      squareIds.push(s.id);
      objects.push(
        new fabric.Rect({
          left: s.left,
          top: s.top,
          height: s.height,
          width: s.width,
          fill: '#0085ff',
          stroke: '#0085ff',
          evented: false,
          hasBorders: false,
          hasControls: false,
          selectable: false,
          moveable: false,
          opacity: 0.8,
        })
      );
    }

    // create scope
    const scope = new fabric.Scope(objects, {
      ...options,
      left,
      top,
      squares: squareIds.sort(),
    });

    // create scope name
    const scopeLabel = new fabric.Text(options.name, {
      fill: '#fff',
      fontSize: 20,
      fontFamily: 'Montserrat',
      originX: 'center',
      originY: 'center',
      left: scope.left + scope.width / 2,
      top: scope.top + scope.height / 2,
      angle: scope.height > scope.width ? 270 : 0,
    });
    scope.addWithUpdate(scopeLabel);
    return scope;
  }
}
