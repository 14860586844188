import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ElectricityCost } from '../models/electricity-cost.model';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class ElectricityCostsService {
  private apiUrl = config().apiUrl + '/electricityCosts';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  private verifyData(data: any): ElectricityCost {
    const newData = { ...data };

    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getElectricityCost(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getElectricityCosts(options?: { [key: string]: any }) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  createElectricityCost(data: ElectricityCost) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateElectricityCost(id: number, data: ElectricityCost) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteElectricityCost(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
