<form [formGroup]="form" novalidate>
  <p-card>
    <!--Header-->
    <p-header>
      <div class="ui-g">
        <div class="ui-g-6"></div>
        <div class="ui-g-6 text-right">
          <p-toggleButton
            onLabel="{{ 'ACTIVE' | translate }}"
            formControlName="isActive"
            offLabel="{{ 'ACTIVE' | translate }}"
            onIcon="fa fa-check-square-o"
            offIcon="fa fa-square-o"
          ></p-toggleButton>
        </div>
      </div>
    </p-header>

    <!--Body-->
    <div class="ui-g">
      <!-- ID FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="id"
          [form]="form"
          label="{{ 'LOCALE' | translate }} {{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <!-- LANGUAGE FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="language"
          [form]="form"
          label="{{ 'LANGUAGE' | translate }}"
        ></sc-text-input>
      </div>

      <!-- LANGUAGE_NATIVE FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="languageNative"
          [form]="form"
          label="{{ 'LANGUAGE_NATIVE' | translate }}"
        ></sc-text-input>
      </div>

      <!-- IS_SPEECH_TO_TEXT_TRANSLATION_SUPPORTED FIELD -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="isTextToTextTranslationSupported"
          [form]="form"
          label="{{ 'IS_SPEECH_TO_TEXT_TRANSLATION_SUPPORTED' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- IS_TEXT_TO_TEXT_TRANSLATION_SUPPORTED FIELD -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="isTextToSpeechTranslationSupported"
          [form]="form"
          label="{{ 'IS_TEXT_TO_TEXT_TRANSLATION_SUPPORTED' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- IS_TEXT_TO_SPEECH_TRANSLATION_SUPPORTED FIELD -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="isSpeechToTextTranslationSupported"
          [form]="form"
          label="{{ 'IS_TEXT_TO_SPEECH_TRANSLATION_SUPPORTED' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <p-footer>
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>
        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="submitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </p-footer>
  </p-card>
</form>
