import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@widgets/locations/models/location';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { SelectItem, TreeNode } from 'primeng/api';
import { SharedService } from '@shared/shared.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { DeviceModelService } from '@widgets/device-models-2/services/device-model.service';
import { DeviceModel } from '@widgets/device-models-2/models/device-model';
import { config } from '@app/config';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { DeviceType as DeviceTypeEnum } from '@app/integrator/enums';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { DeviceBrand } from '@widgets/device-brands/models/device-brand';
import { DeviceBrandsService } from '@widgets/device-brands/services/device-brands.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-automations-creator',
  templateUrl: './automations-creator.component.html',
  styleUrls: ['./automations-creator.component.scss'],
})
export class AutomationsCreatorComponent implements OnInit, OnDestroy {
  @Input() config: any;
  @Input() isGateway: boolean;
  @Input() step: number;
  @Input() totalSteps: number;

  form: FormGroup;
  gatewaysForm: FormArray;
  selectedItems: { [key: string]: SelectItem[] | TreeNode[] } = {};
  fetchingState = 0;
  selectedDeviceType: string;
  flatLocations: any[];

  private subscribers: { [key: string]: any } = {};
  private deviceModels: DeviceModel[] = [];
  private deviceBrands: DeviceBrand[] = [];
  private deviceProtocols: DeviceProtocol[] = [];
  private deviceTypes: DeviceType[] = [];
  private selectedModel: DeviceModel;
  private selectedBrand: DeviceBrand;

  @Output() onSubmit: EventEmitter<object> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onChangeStep: EventEmitter<number> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private deviceModelService: DeviceModelService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceTypesService: DeviceTypesService,
    private deviceBrandsService: DeviceBrandsService,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.initForm();
    this.fetchLocations();
    this.fetchDeviceBrands();
    this.fetchDeviceProtocols();
    this.fetchDeviceModels();
    this.fetchDeviceTypes();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchLocations() {
    this.fetchingState++;
    const options = {
      siteId: this.sharedService.selectedSite.id,
      columns: 'id,idx,description,isActive,isDeleted,parentId',
      tree: true,
    };

    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
      const locations = result.data.filter((d) => d.isActive && !d.isDeleted);
      this.selectedItems.locations = this.sharedService.createMultiSelectItems(locations, 'children', 'id', false);
      this.selectedItems.locations.map((floor) => floor.children?.map((unit) => delete unit.children));
      this.flatLocations = this.widgetService.flattenTreeRecursively(
        result.mappingKeys ? this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys) : result.data
      );
      this.fetchingState--;
    });
  }

  private gatewaySettings(gatewayName: string) {
    const form = this.form.getRawValue();

    const idx = form.gatewaySettings
      ? form.gatewaySettings.findIndex((setting) => setting.gatewayName === gatewayName)
      : -1;
    if (idx === -1) {
      return { serialNumber: null, ip: null, port: null, restPort: null };
    } else {
      delete form.gatewaySettings[idx].gatewayName;
      return form.gatewaySettings[idx];
    }
  }

  submit(): void {
    const automations: object[] = [];
    const form = this.form.getRawValue();
    const type = this.deviceTypes.find(
      (item) => item.isActive && !item.isDeleted && item.key === DeviceTypeEnum.Automation
    );
    form.locations.map((location) => {
      const locationDetails = this.flatLocations.find(
        //An automation must be created only on a unit
        (flatLocation) => flatLocation.id === location && flatLocation.locationTypeId === 3
      );
      if (locationDetails) {
        const gatewayName = `${locationDetails.description} ${form.deviceName}`;
        automations.push({
          brandId: this.selectedBrand.id,
          description: gatewayName,
          deviceModelId: this.selectedModel.id,
          deviceTypeId: type.id,
          expectedOnline: form.expectedOnline,
          gatewaySettings: JSON.stringify({
            coolautomation: [this.gatewaySettings(gatewayName)],
          }),
          isActive: true,
          locationId: locationDetails.id,
          locationIdx: locationDetails.idx,
          maxSeenMinutesAgo: form.maxSeenMinutesAgo,
          operatingMode: this.isGateway ? 'gateway' : 'automation',
          siteId: this.sharedService.selectedSite.id,
          uniqueId: this.selectedModel.unique_id,
        });
      }
    });
    this.onSubmit.emit({
      devices: automations,
      isAutomation: true,
      linkToAllDevice: form.isLinkingAll,
      includeSubLocations: form.isIncludingAll,
    });
  }

  cancel() {
    this.onCancel.emit();
  }

  onNextClick() {
    this.step++;
    if (this.step === 2) {
      this.createGatewaysProperties();
    }
    this.onChangeStep.emit(this.step);
  }

  onBackClick() {
    if (this.step === 2) {
      this.gatewaysForm.clear();
    }
    this.step--;
    this.onChangeStep.emit(this.step);
  }

  onDeviceModelSelect(modelId) {
    this.getDeviceDetails();
    this.form.patchValue({
      deviceName: this.isGateway ? 'Gateway' : 'Automation',
    });
  }

  getPropertyName(deviceTypeId: number) {
    const type = this.deviceTypes.find((item) => item.id === deviceTypeId);
    if (type) {
      return type.name;
    }
    return '';
  }

  private createGatewaysProperties() {
    const gatewaysForm = this.form.get('gatewaySettings') as FormArray;
    const locations = this.form.getRawValue().locations;
    locations.map((location) => {
      const gatewayLocation = this.flatLocations.find(
        (flatLocation) => flatLocation.id === location && flatLocation.parentId !== null
      );
      if (gatewayLocation) {
        gatewaysForm.push(
          this.formBuilder.group({
            gatewayName: `${gatewayLocation.description} Gateway`,
            serialNumber: '',
            ip: '',
            port: '',
            restPort: '',
          })
        );
      }
    });
    this.gatewaysForm.patchValue(this.form.getRawValue().gatewaySettings);
  }

  private initForm() {
    const fields: any = {
      deviceName: [null, Validators.required],
      locations: [null, Validators.required],
      deviceModelId: [null, Validators.required],
      nbDevicesPerLocation: [1, Validators.required],
      isLinkingAll: false,
      isIncludingAll: false,
      expectedOnline: false,
      maxSeenMinutesAgo: null,
    };
    if (this.isGateway) {
      fields.gatewaySettings = this.formBuilder.array([]);
    }
    this.form = this.formBuilder.group(fields);
    this.gatewaysForm = this.form.get('gatewaySettings') as FormArray;
  }

  private fetchDeviceModels() {
    this.fetchingState++;
    this.subscribers.fetchDevices = this.deviceModelService.getDeviceModels().subscribe((result: any) => {
      this.deviceModels = result.data.filter((item) => item.is_active && !item.is_deleted);
      this.updateDeviceModelSelectItems();
      this.fetchingState--;
    });
  }

  private fetchDeviceBrands() {
    this.fetchingState++;
    this.subscribers.fetchDeviceBrands = this.deviceBrandsService.getDeviceBrands().subscribe((result: any) => {
      this.deviceBrands = result.data.filter((item) => item.isActive && !item.isDeleted);
      this.fetchingState--;
    });
  }

  private fetchDeviceProtocols() {
    this.fetchingState++;
    this.subscribers.fetchDeviceProtocols = this.deviceProtocolsService
      .getDeviceProtocols()
      .subscribe((result: any) => {
        this.deviceProtocols = result.data.filter((item) => item.isActive && !item.isDeleted);
        this.updateDeviceModelSelectItems();
        this.fetchingState--;
      });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data.filter((item) => item.isActive && !item.isDeleted);
      this.fetchingState--;
    });
  }

  private updateDeviceModelSelectItems() {
    if (!this.deviceProtocols) {
      return;
    }

    this.selectedItems.deviceModels = this.deviceModels
      .filter((item) => item.is_automation)
      .map((item) => ({
        label: this.getDeviceModelLabel(item),
        value: item.id,
      }))
      //Sort by name
      .sort((x, y) => {
        if (x.label < y.label) {
          return -1;
        }
        if (x.label > y.label) {
          return 1;
        }
        return 0;
      });
  }

  private getDeviceModelLabel(model: DeviceModel) {
    let label = model.product_name;

    if (this.selectedDeviceType === DeviceTypeEnum.Automation) {
      const frequencies = config().selectItems.zwaveFrequencies;
      const frequency = frequencies.find((item) => item.value === model.frequency);
      const protocol = this.deviceProtocols.find(
        (item) => item.isActive && !item.isDeleted && item.key === model.protocol
      );

      if (protocol) {
        label += `[${protocol.name}${frequency ? '' : ']'}`;
      }
      if (frequency) {
        label += `${protocol ? ',' : ''} ${frequency.label}]`;
      }
    }

    return label;
  }

  getDeviceDetails() {
    const formValue = this.form.getRawValue();
    this.selectedModel = this.deviceModels.find((item) => item.id === formValue.deviceModelId);
    if (this.selectedModel) {
      this.selectedBrand = this.deviceBrands.find((item) => item.key === this.selectedModel.brand);
    }
  }

  get allowNext() {
    switch (this.step) {
      case 1: {
        if (this.form.valid) {
          return true;
        }
        return false;
      }
      default:
        return true;
    }
  }
}
