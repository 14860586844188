import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sc-dependency-warning',
  templateUrl: './dependency-warning.component.html',
  styleUrls: ['./dependency-warning.component.scss']
})
export class DependencyWarningComponent implements OnInit {
  confirm = new FormControl(false);
  dependencies: any[] = [];
  reference: any;

  private source: any;

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.dependencies = this.config.data.dependencies;
    this.source = this.config.data.source;
    this.reference = this.getReference();
  }

  onConfirm() {
    this.dialog.close(this.confirm.value);
  }

  onCancel() {
    this.dialog.close(false);
  }

  private getReference() {
    if (this.source.name) {
      return this.source.name;
    } else if (this.source.description) {
      return this.source.description;
    } else if (this.source.key) {
      return this.source.key;
    } else {
      return this.source.id;
    }
  }
}
