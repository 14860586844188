import { Component, OnInit, Input } from '@angular/core';

interface Data {
  total: number;
  occupied: number;
  unoccupied: number;
  unoccupied_makeup: number;
  not_available: number;
}

@Component({
  selector: 'sc-custom-bar',
  templateUrl: './custom-bar.component.html'
  // styleUrls: ['./custom-bar.component.scss']
})
export class CustomBarComponent implements OnInit {
  @Input()
  label: any;
  @Input()
  data: Data;

  makeUpMyRoom: number;
  makeUpMyRoomPercent: number;
  unoccupied: number;
  unoccupiedPercent: number;
  total: number;

  constructor() {}

  ngOnInit() {
    this.total = this.data.total;

    this.makeUpMyRoom = this.data.unoccupied_makeup;
    this.makeUpMyRoomPercent = (this.makeUpMyRoom / this.data.total) * 100;

    this.unoccupied = this.data.unoccupied;
    this.unoccupiedPercent = (this.data.unoccupied / this.data.total) * 100;
  }
}
