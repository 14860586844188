import * as fromAppStyle from '../actions/app-style.action';

export interface AppStyleState {
  current: fromAppStyle.AppStyle;
  error: any;
  loading: boolean;
}

export const initialState = {
  current: null,
  error: null,
  loading: false
};

export function reducer(
  state = initialState,
  action: fromAppStyle.AppStyleAction
): AppStyleState {
  switch (action.type) {
    case fromAppStyle.LOAD_APP_STYLE: {
      return {
        ...state,
        loading: true
      };
    }

    case fromAppStyle.LOAD_APP_STYLE_SUCCESS: {
      const current = action.payload;
      return {
        ...state,
        current,
        error: null,
        loading: false
      };
    }

    case fromAppStyle.LOAD_APP_STYLE_FAIL: {
      const error = action.payload;
      return {
        ...state,
        error,
        loading: false
      };
    }
  }

  return state;
}

export const getCurrent = (state: AppStyleState) => state.current;
export const getLoading = (state: AppStyleState) => state.loading;
