<div *ngIf="showResultTable then content; else loadingIndicator"></div>

<ng-template #content>
  <ag-grid-angular
    class="ag-theme-sc-clean"
    [gridOptions]="gridOptions"
    style="width: 100%; height: 100%"
  ></ag-grid-angular>
</ng-template>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
