import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { ModalConfig } from '@sc/modal/modal-config';
import { Site } from '@widgets/sites/models/site';

import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-battery-alert-widget',
  templateUrl: './battery-alert-widget.component.html',
  styleUrls: ['./battery-alert-widget.component.scss'],
})
export class BatteryAlertWidgetComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  alertClass: string;
  alertIcon: string;
  detailsModal: ModalConfig;
  isFetching = false;
  isFirstTime = true;
  // titleText = 'BATTERY_ALERT';
  widgetName: string = '';

  private data: any;
  private intervals: { [key: string]: any } = {};
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.isFetching = false;
          this.isFirstTime = true;
          this.fetchDataset();
          this.intervals.fetchDataset = setInterval(() => this.fetchDataset(), 60000);
        }
      },
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (this.isFetching || !this.selectedSite) {
      return;
    }

    this.isFetching = true;
    const siteId = this.selectedSite.id;
    this.subscribers.GET_DATASET = this.widgetService.getBatteryAlert(siteId).subscribe(
      (result: any) => {
        this.data = result.data;
        this.setDetailsModal();
        this.setAlertDetails();
        this.isFetching = false;
        this.isFirstTime = false;
      },
      (error: any) => {
        this.isFetching = false;
      }
    );
  }

  private setDetailsModal() {
    this.detailsModal = {
      name: 'BatteryAlertModal',
      data: this.data,
      options: {
        modalTitle: 'BATTERY_ALERT_MODAL_TITLE',
      },
    };
  }

  private setAlertDetails() {
    let alertClass = 'lightgreen';
    let icon = 'fa-check';
    switch (this.data.status) {
      case 'crucial':
        alertClass = 'red';
        icon = 'fa-exclamation-triangle';
        break;
      case 'warning':
        alertClass = 'orange lighten-1';
        icon = 'fa-exclamation-triangle';
        break;
      case 'info':
        alertClass = 'blue';
        icon = 'fa-exclamation-triangle';
        break;
      case 'disabled':
        alertClass = 'grey lighten-2';
        icon = 'fa-times';
        break;
      default:
        break;
    }

    this.alertClass = alertClass;
    this.alertIcon = icon;
  }
}
