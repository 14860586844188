import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';

import { SCConfirmTriggerComponent } from './confirm-trigger.component';

@NgModule({
  imports: [CommonModule, ButtonModule, ConfirmDialogModule, TooltipModule],
  declarations: [SCConfirmTriggerComponent],
  exports: [SCConfirmTriggerComponent],
})
export class SCConfirmModule {}
