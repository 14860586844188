import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ModalConfig } from '@sc/modal/modal-config';

import { NotificationsService } from '../notifications.service';
import { SharedService } from '@shared/shared.service';

import * as moment from 'moment';

@Component({
  selector: 'sc-notification-action-cell',
  templateUrl: 'notification-action-cell.component.html'
})
export class NotificationActionCellComponent
  implements ICellRendererAngularComp {
  cell: any;
  modalConfig: ModalConfig;
  formConfig: ModalConfig;

  constructor(
    private notificationsService: NotificationsService,
    private sharedService: SharedService
  ) {}

  agInit(params: any): void {
    this.cell = params;

    this.formConfig = {
      name: 'NotificationDetail',
      data: this.cell.data,
      options: { modalTitle: 'NOTIFICATION_DETAIL_MODAL_TITLE' }
    };
  }

  refresh(): boolean {
    return false;
  }

  markAsRead() {
    if (!this.cell.data.readAt) {
      const userId = this.cell.data.userId;
      const notificationId = this.cell.data.id;
      this.notificationsService
        .read(userId, notificationId)
        .subscribe((result: any) => {
          this.cell.node.setDataValue('readAt', moment.utc().format());
          this.sharedService.notify(
            'Notification',
            result.message + ' Success!',
            'success'
          );
        });
    }
  }
}
