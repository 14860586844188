import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ElectricityCost } from '../models/electricity-cost.model';
import { SelectItem } from 'primeng/api';
import { Site } from '@widgets/sites/models/site';

import { CurrenciesService } from '@widgets/currencies/services/currencies.service';
import { ElectricityCostsService } from '../services/electricity-costs.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-cost-form',
  templateUrl: './cost-form.component.html',
  styleUrls: ['./cost-form.component.scss']
})
export class ElectricityCostFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: ElectricityCost;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private currenciesService: CurrenciesService,
    private electricityCostsService: ElectricityCostsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      // FETCH LOCATION
      this.fetchElectricityCost(this.data.id);
    }

    this.fetchCurrencies();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchElectricityCost(id: number) {
    this.fetchingState++;
    this.subscribers.GET_FIELD = this.electricityCostsService
      .getElectricityCost(id)
      .subscribe((result: any) => {
        this.formData = result.data;

        this.formData.dateFrom = this.sharedService.parseDateStringToDateObject(
          this.formData.dateFrom
        );
        this.formData.dateTo = this.sharedService.parseDateStringToDateObject(
          this.formData.dateTo
        );
        this.formData.timeFrom = this.sharedService.parseDateStringToDateObject(
          this.formData.timeFrom
        );
        this.formData.timeTo = this.sharedService.parseDateStringToDateObject(
          this.formData.timeTo
        );

        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  fetchCurrencies() {
    this.fetchingState++;
    this.subscribers.GET_CURRENCIES = this.currenciesService
      .getCurrencies()
      .subscribe((result: any) => {
        this.selectItems.currencies = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted),
          false
        );
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: { value: null, disabled: true },
      dateFrom: [null, Validators.required],
      dateTo: [null, Validators.required],
      timeFrom: [null, Validators.required],
      timeTo: [null, Validators.required],
      unit: [null, Validators.required],
      unitPrice: [null, Validators.min(0)],
      currencyId: [null, Validators.required],
      siteId: [null, Validators.required],
      isActive: false,
      isDeleted: false
    });

    if (this.editMode) {
      this.form.get('siteId').disable();
    } else {
      this.form.patchValue({
        siteId: this.selectedSite.id,
        currencyId: this.selectedSite.currency
      });
    }

    // TODO: find the good place to store them
    this.selectItems.units = [{ label: 'KW/H', value: 'kwh' }];
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };

      formData.dateFrom = this.sharedService.parseDateObjectToDateString(
        formData.dateFrom
      );
      formData.dateTo = this.sharedService.parseDateObjectToDateString(
        formData.dateTo
      );
      formData.timeFrom = this.sharedService.parseDateObjectToDateString(
        formData.timeFrom
      );
      formData.timeTo = this.sharedService.parseDateObjectToDateString(
        formData.timeTo
      );

      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(data: ElectricityCost) {
    this.subscribers.CREATE_FIELD = this.electricityCostsService
      .createElectricityCost(data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  update(data: ElectricityCost) {
    this.subscribers.UPDATE_FIELD = this.electricityCostsService
      .updateElectricityCost(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE_FIELD = this.electricityCostsService
      .deleteElectricityCost(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('ELECTRICITY_COST');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
