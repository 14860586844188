import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { DeviceModelsModule } from '@widgets/device-models/device-models.module';

import { DeviceActionCellComponent } from './device-action-cell/device-action-cell.component';
import { DeviceFormComponent } from './device-form/device-form.component';
import { DevicesTableComponent } from './devices-table/devices-table.component';

import { DeviceLiveDataCellComponent } from './device-live-data-cell/device-live-data-cell.component';
import { DeviceLogsViewComponent } from './device-logs-view/device-logs-view.component';
import { DeviceLogsComparisonComponent } from './device-logs-comparison/device-logs-comparison.component';
import { DeviceAddDHLFormComponent } from './device-add-dhl-form/device-add-dhl-form.component';

import { DeviceTriggerActionFormComponent } from './device-trigger-action-form/device-trigger-action-form.component';

import { DeviceCommandMappingActionCellComponent } from './device-command-mapping-action-cell/device-command-mapping-action-cell.component';
import { DeviceCommandMappingFormComponent } from './device-command-mapping-form/device-command-mapping-form.component';
import { DeviceCommandMappingTableComponent } from './device-command-mapping-table/device-command-mapping-table.component';

import { ProtocolControllerActionCellComponent } from './protocol-controller-action-cell/protocol-controller-action-cell.component';
import { ProtocolControllerFormComponent } from './protocol-controller-form/protocol-controller-form.component';
import { ProtocolControllersTableComponent } from './protocol-controllers-table/protocol-controllers-table.component';

import { CeosDeviceActionCellComponent } from './ceos-device-action-cell/ceos-device-action-cell.component';
import { CeosDeviceLiveDataCellComponent } from './ceos-device-live-data-cell/ceos-device-live-data-cell.component';
import { CeosDevicePropertiesCellComponent } from './ceos-device-properties-cell/ceos-device-properties-cell.component';
import { CeosDeviceTriggerFormComponent } from './ceos-device-trigger-form/ceos-device-trigger-form.component';
import { CeosDevicesTableComponent } from './ceos-devices-table/ceos-devices-table.component';
import { CeosAutomationTriggerFormComponent } from './ceos-automation-trigger-form/ceos-automation-trigger-form.component';
import { CeosDeviceFormComponent } from './ceos-device-form/ceos-device-form.component';
import { DeviceDetailsComponent } from './device-details/device-details.component';
import { DeviceDetailsHeaderComponent } from './device-details-header/device-details-header.component';
import { VirtualDeviceHoldersTableComponent } from './virtual-device-holders-table/virtual-device-holders-table.component';

import { DevicesService } from './services/devices.service';
import { DeviceFormsService } from './services/device-forms.service';
import { DeviceTriggerCommandsService } from './services/device-trigger-commands.service';
import { DeviceHistoryLogsService } from './services/device-history-logs.service';
import { DeviceCommandMappingService } from './services/device-command-mapping.service';
import { ProtocolControllersService } from './services/protocol-controllers.service';

const COMPONENTS: any[] = [
  CeosDeviceActionCellComponent,
  CeosDeviceLiveDataCellComponent,
  CeosDevicePropertiesCellComponent,
  CeosDeviceTriggerFormComponent,
  CeosDevicesTableComponent,
  CeosDeviceFormComponent,
  CeosAutomationTriggerFormComponent,
  DeviceActionCellComponent,
  DeviceAddDHLFormComponent,
  DeviceCommandMappingActionCellComponent,
  DeviceCommandMappingFormComponent,
  DeviceCommandMappingTableComponent,
  DeviceDetailsComponent,
  DeviceDetailsHeaderComponent,
  DeviceFormComponent,
  DeviceLiveDataCellComponent,
  DeviceLogsComparisonComponent,
  DeviceLogsViewComponent,
  DeviceTriggerActionFormComponent,
  DevicesTableComponent,
  ProtocolControllerActionCellComponent,
  ProtocolControllerFormComponent,
  ProtocolControllersTableComponent,
  VirtualDeviceHoldersTableComponent,
];

@NgModule({
  imports: [SharedModule, DeviceModelsModule],
  exports: [DeviceFormComponent, DevicesTableComponent, CeosDevicesTableComponent],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [
    DevicesService,
    DeviceFormsService,
    DeviceTriggerCommandsService,
    DeviceHistoryLogsService,
    DeviceCommandMappingService,
    ProtocolControllersService,
  ],
})
export class DevicesModule {}
