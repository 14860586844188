import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ColDef } from 'ag-grid';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-ac-valve-alert-modal',
  templateUrl: './ac-valve-alert-modal.component.html'
})
export class ACValveAlertModalComponent implements OnInit {
  @Input()
  data: any;

  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  currentView: string;
  dataset: any[];
  tableColumns: ColDef[];

  constructor (private sharedService: SharedService) {}

  ngOnInit() {
    this.dataset = this.data.locations;
    this.onChangeView('ongoing');
    this.createCurrentTable();
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private createCurrentTable() {
    this.tableColumns = [
      {
        colId: 'locationName',
        headerName: 'LOCATION',
        field: 'locationName',
      },
      {
        colId: 'duration',
        headerName: 'SINCE',
        field: 'duration',
        valueFormatter: this.durationFormatter.bind(this),
        filterValueGetter: this.durationFormatter.bind(this),
      },
      {
        colId: 'acSetpoint',
        headerName: 'AC_SETPOINT',
        field: 'acSetpoint',
        valueFormatter: this.dataFormatter.bind(this),
        valueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this),
      },
      {
        colId: 'temperature',
        headerName: 'TEMPERATURE',
        field: 'temperature',
        valueFormatter: this.dataFormatter.bind(this),
        valueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this),
      },
    ];
  }

  private createHistoryTable() {
    this.tableColumns = [
      {
        colId: 'locationName',
        headerName: 'LOCATION',
        field: 'locationName',
      },
      {
        colId: 'alertAt',
        headerName: 'ALERT_AT',
        field: 'alertAt',
        valueFormatter: params => this.sharedService.dateFormat(params.data.alertAt),
        valueGetter: params => this.sharedService.dateFormat(params.data.alertAt),
      }
    ];
  }

  private dataFormatter(params: any) {
    const field = params && params.colDef && params.colDef.field;
    let value = params && params.data && params.data[field];
    if (typeof value === 'number') {
      value = this.sharedService.numberFormat(value, 1);
      return  value;
    }
    return '';
  }

  private durationFormatter(params: any) {
    let value = params && params.data && params.data.duration;
    let unit = 'minute(s)';
    if (typeof value === 'number') {
      if (value > 60) {
        value = value / 60;
        unit = 'hour(s)';
      }
      value = this.sharedService.numberFormat(value, 1);
      return  value + ' ' + unit;
    }
    return '';
  }

  private dataCellRenderer(params: any) {
    return '<div class="text-right">' + params.valueFormatted + '</div>';
  }

  // view button css class
  changeViewBtnClass(view: string) {
    if (view === this.currentView) {
      return 'ui-button-success';
    }
    return 'ui-button-secondary';
  }

  onChangeView(view: string) {
    if (this.currentView === view) {
      return;
    }
    this.currentView = view;
    this.tableColumns = null;

    setTimeout(() => {
      switch (view) {
        case 'ongoing':
          this.createCurrentTable();
          this.dataset = this.data.locations;
          break;
        case 'history':
          this.createHistoryTable();
          this.dataset = this.data.history;
          break;
      }
    });
  }
}
