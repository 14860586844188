<p-dialog
  header="Config"
  [(visible)]="isConfigModalOpen"
  appendTo="body"
  [style]="{ width: '80vw', maxWidth: '800px' }"
>
  <form [formGroup]="configForm" class="sc-widget-config__form">
    <div>
      <div class="ui-fluid">
        <div class="p-field p-grid">
          <label for="firstname" class="p-col-12 p-md-3">{{
            "WIDGET_CUSTOM_TITLE" | translate
          }}</label>
          <div class="p-col-12 p-md-9">
            <div class="ui-inputgroup display-flex">
              <input
                type="text"
                pInputText
                [placeholder]="defaultWidgetName"
                formControlName="name"
              />
              <button
                pButton
                type="button"
                icon="pi pi-undo"
                (click)="resetWidgetName()"
              ></button>
            </div>
          </div>
        </div>
        <div class="p-field p-grid p-align-center">
          <label for="lastname" class="p-col-12 p-md-3 p-col-align-start">{{
            "DISPLAY_MODE" | translate
          }}</label>
          <div class="p-col-12 p-md-9">
            <div class="p-field-radiobutton">
              <p-radioButton
                name="selectedDisplay"
                [value]="DISPLAY_VALUE"
                inputId="optionValue"
                formControlName="display"
              ></p-radioButton>
              <label for="optionValue">{{ "VALUE" | translate }}</label>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                name="selectedDisplay"
                [value]="DISPLAY_PERCENT"
                inputId="optionPercent"
                formControlName="display"
              ></p-radioButton>
              <label for="optionPercent">{{ "PERCENTAGE" | translate }}</label>
            </div>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="threshold" class="p-col-12 p-md-3">{{
            "ALERT_THRESHOLD" | translate
          }}</label>
          <div class="p-col-12 p-md-9">
            <div
              style="column-gap: 8px; align-items: center"
              class="ui-inputgroup display-flex"
            >
              <input
                type="number"
                pInputText
                formControlName="threshold"
                min="1"
              />
              <div>{{ "HOURS" | translate }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="isMultisites" class="p-field p-grid">
          <label for="sites" class="p-col-12 p-md-3">{{
            "SITES" | translate
          }}</label>
          <div class="p-col-12 p-md-9">
            <p-multiSelect
              inputId="sites"
              [options]="selectItems?.sites"
              formControlName="sites"
              [filter]="true"
              appendTo="body"
              [defaultLabel]="selectSitesDefaultLabel"
            ></p-multiSelect>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-footer class="air-quality-live__footer">
    <button
      type="button"
      (click)="saveConfig()"
      pButton
      label="{{ 'SUBMIT' | translate }}"
      class="ui-button-success"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  header="{{ widgetName }}"
  [(visible)]="isDetailsModalOpen"
  appendTo="body"
  [style]="{ width: '60vw', maxWidth: '800px' }"
>
  <div style="padding: 0 35px 16px">
    <p-table
      #dt
      [value]="ceosStats"
      [filterDelay]="0"
      [globalFilterFields]="['siteName', 'description']"
    >
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="isMultisites" width="250">{{ "SITE" | translate }}</th>
          <th width="250">{{ "CEOS" | translate }}</th>
          <th width="200">{{ "LAST_SEEN" | translate }}</th>
          <th width="50"></th>
        </tr>
        <tr *ngIf="isMultisites">
          <th>
            <input
              pInputText
              type="text"
              (input)="dt.filter($event.target.value, 'siteName', 'contains')"
              class="p-column-filter"
            />
          </th>
          <th>
            <input
              pInputText
              type="text"
              (input)="
                dt.filter($event.target.value, 'description', 'contains')
              "
              class="p-column-filter"
            />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ceos>
        <tr *ngIf="ceos.status !== 'Ok'">
          <td *ngIf="isMultisites">{{ ceos.siteName }}</td>
          <td>{{ ceos.description }}</td>
          <td>{{ ceos.lastSeenAt }}</td>
          <td>{{ ceos.status }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body ceos-alert-body">
    <ng-container *ngIf="!isConfigured()">
      <div class="sc-flex">
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          label="{{ 'WIDGET_CONFIGURATION_REQUIRED' | translate }}"
        ></button>
      </div>
    </ng-container>
    <ng-container *ngIf="isConfigured() && chartData">
      <div class="sc-widget-legend ceos-alert-widget-legend">
        <ul>
          <li *ngFor="let legendItem of legendItems">
            <span
              class="color"
              [ngStyle]="{ 'background-color': legendItem.color }"
            ></span>
            <span class="label ceos-alert-label"
              >{{ legendItem.value }} {{ legendItem.label }}</span
            >
          </li>
        </ul>
      </div>
      <div class="ceos-alert-widget-plotly">
        <plotly-plot
          [data]="chartData"
          [layout]="chartLayout"
          [config]="{ displayModeBar: false }"
        ></plotly-plot>
      </div>
    </ng-container>
  </div>
</ng-template>

<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">{{ widgetName }}</h4>
      <div class="sc-widget-menu">
        <button
          type="button"
          (click)="openDetailsModal()"
          pButton
          icon="pi pi-eye"
          title="Details"
          [disabled]="!isInitialized"
          class="ceos-alert-action-btn"
          *ngIf="isInitialized && chartData"
        ></button>
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          title="Config"
          [disabled]="!isInitialized"
          class="air-quality-action-btn"
        ></button>
      </div>
    </div>
    <ng-container
      *ngIf="isInitialized || chartData; then content; else spinner"
    ></ng-container>
  </div>
</div>
