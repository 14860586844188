<ng-container *ngIf="userVariant !== 'ceos'">
  <!-- <div
    class="sc-flex grow-1 align-item-end justify-content-end transparent"
    style="height: 30px"
  >
    <div class="m-1">
      {{ selectedDevices?.length || 0 }} {{ "SELECTED_DEVICES" | translate }}
    </div>

    <sc-modal-trigger
      buttonIcon="fa fa-line-chart"
      buttonLabel="{{ 'GRAPH' | translate }}"
      [modalConfig]="deviceComparisonModalConfig"
    ></sc-modal-trigger>
  </div>

  <div style="height: calc(100% - 30px)">
    <sc-table
      [columns]="columns"
      [config]="config"
      [dataset]="dataset"
      (reloadData)="fetchDataset()"
      (afterInit)="afterInitTable($event)"
      title="{{ tableTitle | translate }}"
      icon="fa fa-mobile"
    ></sc-table>
  </div> -->
  <sc-devices-table
    [config]="config"
    [selectedLocation]="selectedLocation"
  ></sc-devices-table>
</ng-container>

<ng-container *ngIf="userVariant === 'ceos'">
  <sc-ceos-devices-table
    [config]="config"
    [selectedLocation]="selectedLocation"
  ></sc-ceos-devices-table>
</ng-container>
