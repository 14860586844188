import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ModalConfig } from '@sc/modal/modal-config';

@Component({
  selector: 'sc-dashboard-alert',
  templateUrl: './dashboard-alert.component.html',
  styleUrls: ['./dashboard-alert.component.scss']
})
export class SCDashboardAlertComponent implements OnInit, OnChanges {
  @Input() alertClass: string;
  @Input() alertIcon: string;
  @Input() alertText: string;
  @Input() loading: boolean;
  @Input() subtitleText: string;
  @Input() titleText: string;
  @Input() detailsModal: ModalConfig;

  displayAlertText: boolean;

  constructor() {}

  ngOnInit() {
    this.checkAlertText();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.alertText) {
      this.checkAlertText();
    }
  }

  private checkAlertText() {
    this.displayAlertText = typeof this.alertText === 'undefined' ? false : true;
  }
}
