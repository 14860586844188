import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ModalConfig } from '@sc/modal/modal-config';
import { Site } from '@widgets/sites/models/site';
import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-main-door-open',
  templateUrl: './main-door-open.component.html',
  styleUrls: ['./main-door-open.component.scss'],
})
export class MainDoorOpenComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  alertClass: string;
  alertText: string;
  detailsModal: ModalConfig;
  isFetching = false;
  isFirstTime = true;
  subtitleText = 'IN_UNOCCUPIED_UNITS';
  // titleText = 'MAIN_DOOR_OPEN';
  widgetName: string = '';

  private data: any;
  private selectedSite: Site;
  private intervals: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.getSelectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.isFetching = false;
          this.isFirstTime = true;
          this.fetchData();
          this.intervals.updateData = setInterval(() => this.fetchData(), 5000);
        }
      },
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchData() {
    if (this.isFetching) {
      return;
    }

    this.isFetching = true;
    this.subscribers.fetchData = this.widgetService.getMainDoorOpen(this.selectedSite.id).subscribe(
      (result: any) => {
        this.data = result.data;
        this.setDetailsModal(this.data.locations);
        this.setAlertDetails(this.data.count);
        this.isFetching = false;
        this.isFirstTime = false;
      },
      (error: any) => {
        this.isFetching = false;
      }
    );
  }

  private setDetailsModal(data) {
    this.detailsModal = {
      name: 'LocationAlertModal',
      options: { modalTitle: 'LOCATION_ALERT' },
      data,
    };
  }

  private setAlertDetails(data) {
    if (data > 10) {
      this.alertClass = 'danger';
    } else if (data > 0) {
      this.alertClass = 'warning';
    } else {
      this.alertClass = '';
    }
    this.alertText = data;
  }
}
