import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DataSources, SelectItems, RuleCondition, OperatorTypes, Subscribers } from '../../models';
import { RulesService, UtilsService } from '../../services';

enum ChangedAtLeastNtimesOptions {
  atPreviousRuleTrigger = 1,
  includedPast,
}

enum ChangedMaxNtimesOptions {
  atPreviousRuleTrigger = 1,
  includedPast,
}

@Component({
  selector: 'sc-operator-modal',
  templateUrl: './operator-modal.component.html',
  styleUrls: ['./operator-modal.component.scss'],
})
export class OperatorModalComponent implements OnInit, OnDestroy {
  _operatorTypes = OperatorTypes;
  condition: RuleCondition;
  dataSources: DataSources = {};
  form: FormGroup;
  operatorParams: any[];
  operatorType: any;
  selectItems: SelectItems = {};
  step = 1;
  isTemplate: boolean;
  isFromScratch: boolean;
  changedAtLeastNtimesOption = new FormControl(ChangedAtLeastNtimesOptions.atPreviousRuleTrigger);
  changedMaxNtimesOption = new FormControl(ChangedMaxNtimesOptions.atPreviousRuleTrigger);

  private subscribers: Subscribers = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private modalRef: DynamicDialogRef,
    private modalConfig: DynamicDialogConfig,
    private rulesService: RulesService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (this.modalConfig && this.modalConfig.data) {
      if (this.modalConfig.data.condition) {
        this.condition = this.modalConfig.data.condition;
        this.operatorType = this.condition.operator.type;
      }

      if (this.modalConfig.data.dataSources) {
        this.dataSources = this.modalConfig.data.dataSources;
      }

      if (this.modalConfig.data.selectItems) {
        this.selectItems = this.modalConfig.data.selectItems;
      }

      if (this.modalConfig.data.isTemplate) {
        this.isTemplate = this.modalConfig.data.isTemplate;
      }

      if (this.modalConfig.data.isFromScratch) {
        this.isFromScratch = this.modalConfig.data.isFromScratch;
      }
    }

    if ((Object.values(this._operatorTypes) as string[]).indexOf(this.operatorType) >= 0) {
      this.setOpertorParams(this.operatorType);
      this.step = 2;
      this.updateModalStyleClass();
    }

    this.initForm();
  }

  ngOnDestroy(): void {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private initForm() {
    let formValue;
    if (this.form) {
      formValue = this.form.value;
    } else {
      formValue = this.condition.operator;
    }

    this.form = this.rulesService.initOperatorForm(this.operatorType);

    if (formValue) {
      this.form.patchValue(formValue);
    }

    if (this.operatorType === OperatorTypes.ChangedAtLeastNtimes) {
      // update radio input to second option
      if (formValue && formValue.included_past) {
        this.changedAtLeastNtimesOption.setValue(ChangedAtLeastNtimesOptions.includedPast);
      } else {
        // otherwise make input disabled
        this.form.get('included_past').disable();
      }

      this.subscribers.changedAtLeastNtimesOption = this.changedAtLeastNtimesOption.valueChanges.subscribe((result) => {
        const prt = this.form.get('previous_rule_trigger_as_included_past');
        const ip = this.form.get('included_past');
        if (result === ChangedAtLeastNtimesOptions.atPreviousRuleTrigger) {
          prt.setValue(true);
          ip.reset();
          ip.disable();
        } else if (result === ChangedAtLeastNtimesOptions.includedPast) {
          prt.reset();
          ip.enable();
        }
      });
    } else if (this.operatorType === OperatorTypes.ChangedMaxNtimes) {
      // update radio input to second option
      if (formValue && formValue.included_past) {
        this.changedMaxNtimesOption.setValue(ChangedMaxNtimesOptions.includedPast);
      } else {
        // otherwise make input disabled
        this.form.get('included_past').disable();
      }

      this.subscribers.changedMaxNtimesOption = this.changedMaxNtimesOption.valueChanges.subscribe((result) => {
        const prt = this.form.get('previous_rule_trigger_as_included_past');
        const ip = this.form.get('included_past');

        if (result === ChangedMaxNtimesOptions.atPreviousRuleTrigger) {
          prt.setValue(true);
          ip.reset();
          ip.disable();
        } else if (result === ChangedMaxNtimesOptions.includedPast) {
          prt.reset();
          ip.enable();
        }
      });
    }
  }

  private setOpertorParams(type: string) {
    if (type && this.dataSources && this.dataSources.operators && this.dataSources.operators.length) {
      const operator = this.dataSources.operators.find((item) => item.id === type);
      if (operator && operator.params) {
        this.operatorParams = operator.params;
      }
    }
  }

  private updateModalStyleClass() {
    if (!this.operatorParams || !this.operatorParams.length) {
      this.modalConfig.styleClass = 'modal-width--auto';
    } else {
      this.modalConfig.styleClass = 'modal-width--responsive';
    }
    this.changeDetectorRef.detectChanges();
  }

  selectOperator(event) {
    this.operatorType = event;
    this.form.get('type').setValue(this.operatorType);
    this.setOpertorParams(this.operatorType);
    this.initForm();
    this.step++;
    this.updateModalStyleClass();
  }

  close() {
    this.modalRef.close();
  }

  onResetClick() {
    this.condition.operator = { type: null };
    this.close();
  }

  onOkClick() {
    if (this.form.valid) {
      let formValue = this.form.getRawValue();
      formValue = JSON.parse(JSON.stringify(formValue));
      this.condition.operator = formValue;
      this.close();
    }
  }
}
