import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenerateBenchmark } from '../models/generate-benchmark';
import { GenerateBenchmarkStatus } from '../models/generate-benchmark-status';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

interface RequestQueryParams {
  [key: string]: any;
  locationId?: number | string;
}

@Injectable()
export class BenchmarksBatchService {
  private apiUrl = config().apiUrl + '/benchmarks/batch';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  getBenchmark(batchId: string, options?: RequestQueryParams) {
    const queryParams = options
      ? this.sharedService.urlQueryParamsCreator(options)
      : '';
    const apiUrl = `${this.apiUrl}/${batchId}${queryParams}`;
    return this.httpClient.get(apiUrl);
  }

  /**
   * Get Benchmarks
   * @param options
   */
  getBenchmarks(options?: { [key: string]: any }) {
    // let queryParams: any = [];
    // for (const key in options) {
    //   if (options.hasOwnProperty(key)) {
    //     if (typeof options[key] === 'object') {
    //       queryParams.push(
    //         key + '=' + encodeURIComponent(JSON.stringify(options[key]))
    //       );
    //     } else {
    //       queryParams.push(key + '=' + encodeURIComponent(options[key]));
    //     }
    //   }
    // }
    // queryParams = queryParams.length ? '?' + queryParams.join('&') : '';
    const queryParams = options
      ? this.sharedService.urlQueryParamsCreator(options)
      : '';
    const apiUrl = this.apiUrl + queryParams;
    return this.httpClient.get(apiUrl);
  }

  /**
   * Generate Benchmark
   * @param data
   */
  generateBenchmark(data: GenerateBenchmark) {
    return this.httpClient.post(this.apiUrl, data);
  }

  /**
   * Generate Benchmark Status
   * @param data
   */
  generateBenchmarkStatus(batchId: string) {
    return this.httpClient.get(`${this.apiUrl}/status/${batchId}`);
  }

  /**
   * Delete Benchmark
   * @param batchId
   */
  deleteBenchmark(batchId: string, options?: RequestQueryParams) {
    const queryParams = options
      ? this.sharedService.urlQueryParamsCreator(options)
      : '';
    const apiUrl = `${this.apiUrl}/${batchId}${queryParams}`;
    return this.httpClient.delete(apiUrl);
  }
}
