<div *ngIf="fetchingState === 0; else loadingIndicator">
  <div [formGroup]="form">
    <!--Header-->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-12 ui-md-4">
        <p-toggleButton
          formControlName="isActive"
          [offIcon]="'fa fa-square-o'"
          [offLabel]="'ACTIVE' | translate"
          [onIcon]="'fa fa-check-square-o'"
          [onLabel]="'ACTIVE' | translate"
        ></p-toggleButton>
      </div>

      <div class="ui-g-12 ui-md-4">
        <!-- ICON FIELD -->
        <sc-local-file-input
          controlName="icon"
          [form]="form"
          label="{{ 'ICON' | translate }}"
        ></sc-local-file-input>
      </div>
    </div>

    <!-- Body -->
    <div class="ui-g">
      <!-- LEFT_COLUMN -->
      <div class="ui-g-12 ui-md-6">
        <!-- KEY FIELD -->
        <sc-text-input
          controlName="key"
          [form]="form"
          label="{{ 'KEY' | translate }}"
        ></sc-text-input>

        <!-- TYPE FIELD -->
        <sc-dropdown
          controlName="type"
          [form]="form"
          label="{{ 'WIDGET_TYPE' | translate }}"
          [options]="selectItems?.widgetTypes"
        ></sc-dropdown>
      </div>

      <!-- RIGHT_COLUMN -->
      <div class="ui-g-12 ui-md-6">
        <!-- CATEGORIES FIELD -->
        <sc-dropdown
          controlName="categories"
          [form]="form"
          label="{{ 'WIDGET_CATEGORIES' | translate }}"
          [options]="selectItems?.widgetCategories"
          [multiSelect]="true"
        ></sc-dropdown>

        <sc-dropdown
          controlName="availability"
          [form]="form"
          label="{{ 'WIDGET_AVAILABILITY' | translate }}"
          [options]="selectItems?.availability"
        ></sc-dropdown>
      </div>
    </div>

    <div class="ui-g">
      <!-- ROWS FIELD -->
      <div class="ui-g-4">
        <sc-number-input
          controlName="rows"
          [form]="form"
          label="{{ 'HEIGHT' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>

      <!-- MAX_ITEM_ROWS Field -->
      <div class="ui-g-4">
        <sc-number-input
          controlName="maxItemRows"
          [form]="form"
          label="{{ 'MAX_HEIGHT' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>

      <!-- MIN_ITEM_ROWS Field -->
      <div class="ui-g-4">
        <sc-number-input
          controlName="minItemRows"
          [form]="form"
          label="{{ 'MIN_HEIGHT' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>
    </div>

    <div class="ui-g">
      <!-- COLS Field -->
      <div class="ui-g-12 ui-md-4">
        <sc-number-input
          controlName="cols"
          [form]="form"
          label="{{ 'WIDTH' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>

      <!-- MAX_ITEM_COLS Field -->
      <div class="ui-g-12 ui-md-4">
        <sc-number-input
          controlName="maxItemCols"
          [form]="form"
          label="{{ 'MAX_WIDTH' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>

      <!-- minItemCols Field -->
      <div class="ui-g-12 ui-md-4">
        <sc-number-input
          controlName="minItemCols"
          [form]="form"
          label="{{ 'MIN_WIDTH' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">
        <!-- DRAG_ENABLED -->
        <div class="form-group">
          <sc-checkbox-input
            controlName="dragEnabled"
            [form]="form"
            label="{{ 'ALLOW' | translate }} {{ 'MOVE' | translate }}"
          ></sc-checkbox-input>
        </div>
      </div>

      <div class="ui-g-12 ui-md-4">
        <!-- RESIZE_ENABLED -->
        <div class="form-group">
          <sc-checkbox-input
            controlName="resizeEnabled"
            [form]="form"
            label="{{ 'ALLOW' | translate }} {{ 'RESIZE' | translate }}"
          ></sc-checkbox-input>
        </div>
      </div>

      <div class="ui-g-12 ui-md-4">
        <!-- ADMIN_ONLY -->
        <div class="form-group">
          <sc-checkbox-input
            controlName="adminOnly"
            [form]="form"
            label="{{ 'ADMIN_ONLY' | translate }}"
          ></sc-checkbox-input>
        </div>
      </div>
    </div>

    <div class="ui-g" formArrayName="locales">
      <div class="ui-g-12">
        <p-accordion [activeIndex]="0">
          <p-accordionTab
            *ngFor="let ctrl of localesForm.controls; let i = index"
            [formGroupName]="i"
          >
            <p-header>
              <span
                class="flag-icon"
                [ngClass]="getFlag(ctrl?.get('locale')?.value)"
              ></span>
              {{ ctrl?.get("locale")?.value | uppercase }}
            </p-header>

            <div class="ui-g">
              <div class="ui-g-12">
                <sc-text-input
                  [form]="ctrl"
                  controlName="name"
                  label="{{ 'NAME' | translate }}"
                ></sc-text-input>

                <sc-textarea
                  [form]="ctrl"
                  controlName="description"
                  label="{{ 'DESCRIPTION' | translate }}"
                  [rows]="5"
                ></sc-textarea>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>

    <!-- Error Message -->
    <div class="ui-g">
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="form.invalid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
