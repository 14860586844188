import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { BulkModificationFormComponent } from './bulk-modification-form/bulk-modification-form.component';
import { FieldFormComponent } from './field-form/field-form.component';

import { BulkModificationFormService } from './services/bulk-modification-form.service';
import { BulkModificationService } from './services/bulk-modification.service';

@NgModule({
  imports: [SharedModule],
  declarations: [BulkModificationFormComponent, FieldFormComponent],
  entryComponents: [BulkModificationFormComponent],
  providers: [BulkModificationFormService, BulkModificationService]
})
export class BulkModificationModule {}
