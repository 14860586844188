import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'sc-field-error',
  template: `
    <div *ngIf="hasError && errorMessage">
      <p-message
        severity="error"
        [text]="errorMessage?.key | translate : errorMessage?.params"
        style="position: relative; display: block"
      ></p-message>
    </div>
  `,
})
export class SCFieldErrorComponent {
  @Input()
  for: string;
  @Input()
  fieldControl: FormControl;
  @Input()
  checkBoxField: boolean = false;

  constructor() {}

  get hasError() {
    return this.fieldControl && this.fieldControl.invalid && (this.fieldControl.touched || this.fieldControl.dirty);
  }

  get errorMessage() {
    if (!this.fieldControl || !this.fieldControl.errors) {
      return null;
    }

    const errors = this.fieldControl.errors;

    if (errors.passwordStrength) {
      return { key: 'ERROR_PATTERN_PASSWORD' };
    } else if (errors.passwordMatch) {
      return { key: 'ERROR_MATCH_PASSWORD' };
    } else if (errors.phoneNumber) {
      return { key: 'ERROR_PATTERN_PHONE' };
    } else if (errors.key) {
      return { key: 'ERROR_PATTERN_KEY' };
    } else if (errors.emailMatch) {
      return { key: 'ERROR_MATCH_EMAIL' };
    } else if (errors.email) {
      return { key: 'ERROR_PATTERN_EMAIL' };
    } else if (errors.minlength) {
      return { key: 'ERROR_MIN_LENGTH', params: { value: errors.minlength.requiredLength } };
    } else if (errors.maxlength) {
      return { key: 'ERROR_MAX_LENGTH', params: { value: errors.maxlength.requiredLength } };
    } else if (errors.required && !this.checkBoxField) {
      return { key: 'ERROR_REQUIRED' };
    } else if (errors.required && this.checkBoxField) {
      return { key: 'ERROR_CHECK_REQUIRED' };
    } else if (errors.pattern) {
      return { key: 'ERROR_PATTERN_DEFAULT' };
    }
  }
}
