export enum LocationStateColors {
  AlertBackground = 'rgba(217,83,79,0.8)',
  AlertBorder = '#222',
  AssignedBackground = 'rgba(78, 187, 217, 0.8)', // blue
  AssignedBorder = '#4ebbd9', // '#222',
  UnassignedBackground = 'rgba(255,193,7,0.8)', // yellow
  UnassignedBorder = '#ffc107', // '#222'
}

export enum LocationStates {
  Unassigned = 0,
  Assigned,
  Alert,
}

export enum CleaningStates {
  Unknown = 0,
  Clean = 1,
  Dirty = 2,
}

export enum CleaningStateColors {
  CleanBackground = 'rgba(139,195,74,0.8)', // green
  DirtyBackground = 'rgba(217,83,79,0.8)', // red
}