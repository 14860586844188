import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { MostSoldRoomsComponent } from './most-sold-rooms.component';
import { MostSoldRoomsModalComponent } from './most-sold-rooms-modal/most-sold-rooms-modal.component';

const COMPONENTS: any[] = [MostSoldRoomsComponent, MostSoldRoomsModalComponent];

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: []
})
export class MostSoldRoomsModule {}
