import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-table-site-selector',
  templateUrl: 'table-site-selector.component.html',
  styleUrls: ['table-site-selector.component.scss'],
})
export class TableSiteSelectorComponent implements OnInit {
  options: { label: string; value: any }[];
  siteMode: string = 'of this site';

  @Output()
  siteChanged = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.options = [
      { label: 'All sites', value: 'all_site' },
      { label: 'This site', value: 'this_site' },
      { label: 'Integrators', value: 'integrator' },
      { label: 'End-users', value: 'default_user' },
    ];
  }

  selectSiteMode(option) {
    this.siteMode = option.label;
    this.siteChanged.emit(option.value);
  }
}
