<div
  class="column-display-button"
  pTooltip="{{ 'COLUMNS' | translate }}"
  tooltipPosition="top"
  appendTo="body"
>
  <button type="button" class="toggle-button" (click)="menu.toggle($event)">
    <span class="fa fa-table"></span>
  </button>

  <p-overlayPanel #menu appendTo="body" styleClass="column-display-overlay">
    <ul class="columns-control">
      <li class="columns-control-item">
        <a (click)="toggle('all')">
          <i class="fa fa-fw fa-check"></i>
          {{ "SHOW_ALL" | translate }}
        </a>
      </li>

      <li class="columns-control-item">
        <a (click)="toggle('none')">
          <i class="fa fa-fw fa-times"></i>
          {{ "HIDE_ALL" | translate }}
        </a>
      </li>

      <li class="columns-control-divider"></li>

      <li class="columns-control-item">
        <a (click)="setColumnsSizeAuto(); menu.hide()">
          <i class="fa fa-fw fa-arrows-h"></i>
          {{ "AUTO" | translate }}
        </a>
      </li>

      <li class="columns-control-item">
        <a (click)="setColumnsSizeToFit(); menu.hide()">
          <i class="fa fa-fw fa-arrows-h"></i>
          {{ "FIT" | translate }}
        </a>
      </li>

      <li class="columns-control-divider"></li>

      <li class="columns-control-item">
        <a (click)="toggle('reset')">
          <i class="fa fa-fw fa-undo"></i>
          {{ "UNDO" | translate }}
        </a>
      </li>

      <li class="columns-control-item">
        <a (click)="save(); menu.hide()">
          <i class="fa fa-fw fa-download"></i>
          {{ "SAVE" | translate }}
        </a>
      </li>

      <li class="columns-control-divider"></li>
    </ul>

    <ul class="columns-list">
      <li class="columns-list-item" *ngFor="let col of columns">
        <a (click)="toggle(col.colId)">
          <i
            class="fa fa-fw"
            [ngClass]="{
              'fa-check-square-o': col.visible,
              'fa-square-o': !col.visible
            }"
          ></i>
          {{ col.colName | translate }}
        </a>
      </li>
    </ul>
  </p-overlayPanel>
</div>
