import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import { config as appConfig } from '@app/config';

@Component({
  selector: 'sc-locations-occupancy-overview',
  templateUrl: './locations-occupancy-overview.component.html',
  styleUrls: ['./locations-occupancy-overview.component.scss'],
})
export class LocationsOccupancyOverviewComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  chartData: any;
  chartLayout: any;
  dataset: any;
  fetchInterval: any;
  isFetching: boolean;
  isFirstTime: boolean;
  widgetHeight: string;
  widgetWidth: string;
  widgetName: string = '';

  private appConfig = appConfig();
  private intervals: { [key: string]: any } = {};
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private widgetService: SCWidgetService,
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.widgetHeight = this.config.rows * this.appConfig.gridConfig.fixedRowHeight - 80 + 'px';
    this.widgetWidth = '100%';
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // get selected site
    this.subscribers.selectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
        this.intervals.fetchDataset = setInterval(() => this.fetchDataset(), 5000);
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite) {
      this.isFetching = true;
      const { id } = this.selectedSite;
      this.subscribers.getDataset = this.widgetService.getOccupancy(id).subscribe(
        (result: any) => {
          this.dataset = result.data;
          this.createChart();
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  private createChartData() {
    const labels = ['Occupied', 'Unoccupied'];
    const values = [0, 0];
    if (this.dataset && this.dataset.length) {
      for (const data of this.dataset) {
        // if (data.locationTypeKey === 'u' && data.occupied === true) {
        if (data.occupied === true) {
          values[0]++;
        } else {
          values[1]++;
        }
      }
    }
    return { labels, values };
  }

  private createChart() {
    const data = this.createChartData();

    this.chartData = [
      {
        type: 'pie',
        labels: data.labels,
        values: data.values,
        marker: { colors: ['#8bc34a', '#ededed'] },
        textinfo: 'label+percent',
        textposition: 'outside',
        // automargin: true,
      },
    ];

    this.chartLayout = {
      margin: { t: 0, b: 0, r: 0, l: 0, pad: 5 },
      font: { family: 'Barlow' },
      showlegend: false,
      // autosize: true,
    };
  }
}
