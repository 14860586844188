<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'AUTOMATION_LOGS' | translate }} -->
        {{ widgetName }}
      </h4>

      <div class="sc-widget-menu">
        <i
          class="fa fa-filter fa-fw sc-clickable green-text"
          (click)="menu.toggle($event)"
          pTooltip="{{ 'MORE' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
      </div>
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<p-overlayPanel
  #menu
  appendTo="body"
  styleClass="automation-log-table"
  [dismissable]="false"
>
  <div class="overlaypanel-header">
    <strong>{{ "FILTER" | translate }}</strong>
  </div>

  <div class="overlaypanel-body">
    <div class="ui-g" [formGroup]="form">
      <!-- TIMERANGE FIELD -->
      <sc-quick-date-select
        (valueChange)="onSelectDate($event)"
        [displayInVertical]="true"
        [showLabel]="true"
      ></sc-quick-date-select>

      <!-- LEVELS -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="levels"
          [form]="form"
          label="{{ 'LEVELS' | translate }}"
          [options]="selectItems['levels']"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>

      <!-- TAG FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="tags"
          [form]="form"
          label="{{ 'TAG' | translate }}"
          [options]="selectItems['tags']"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>

      <!-- SUBTAG FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="subtags"
          [form]="form"
          label="{{ 'SUBTAG' | translate }}"
          [options]="selectItems['subtags']"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>

      <!-- DEIVCES -->
      <div class="ui-g-12" *ngIf="!selectedDevice">
        <sc-dropdown
          controlName="devices"
          [form]="form"
          label="{{ 'DEVICES' | translate }}"
          [options]="selectItems['devices']"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>
    </div>
  </div>

  <div class="overlaypanel-footer">
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="menu.hide()"
      label="{{ 'CLOSE' | translate }}"
    ></button>

    <sc-submit-button
      buttonClass="ui-button-success"
      [disabled]="!form.valid || form.pristine"
      [pending]="isFetching"
      (submitted)="fetchDataset()"
      label="{{ 'APPLY_FILTER' | translate }}"
    ></sc-submit-button>
  </div>
</p-overlayPanel>

<ng-template #content>
  <div style="height: 100%">
    <sc-table
      [columns]="columns"
      [config]="config"
      (afterInit)="afterInitTable($event)"
      (reloadData)="fetchDataset()"
      [lazyload]="true"
      icon="fa fa-mobile"
      [toolbarItems]="toolbarItems"
      [hideHeader]="true"
      [adjustToFit]="true"
    ></sc-table>
  </div>
</ng-template>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
