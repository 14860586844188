import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CustomApp } from '../models/custom-app';

import { config } from '../../../config';

@Injectable()
export class CustomAppsService {
  private apiUrl = `${config().apiUrl}/customApps`;

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): CustomApp {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.companyId !== 'undefined') {
      delete newData.companyId;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  getCustomApp(id?: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getCustomApps() {
    return this.httpClient.get(this.apiUrl);
  }

  createCustomApp(data: CustomApp) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateCustomApp(id: number, data: CustomApp) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteCustomApp(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
