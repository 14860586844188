<nav class="text-center">
  <sc-modal-trigger
    buttonIcon="fa fa-search"
    [modalConfig]="formConfig"
    tooltipContent="{{ 'DETAIL' | translate }}"
    [disabled]="isInProgress"
  ></sc-modal-trigger>
  <button
    pButton
    type="button"
    class="ui-button-secondary"
    icon="fa fa-refresh {{ isInProgress ? 'fa-spin' : '' }}"
    (click)="rerunBenchmark()"
    pTooltip="{{ 'REFRESH' | translate }}"
    tooltipPosition="top"
    appendTo="body"
    [disabled]="isInProgress"
  ></button>
  <sc-confirm-trigger
    buttonIcon="fa fa-trash"
    (onAccept)="deleteRow()"
    tooltipContent="{{ 'DELETE' | translate }}"
    [disabled]="isInProgress"
  ></sc-confirm-trigger>
</nav>
