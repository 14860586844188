import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EditableComponent } from '@sc/omnis-editor/editable/editable.component';
import { OmnisEditorComponent } from '@sc/omnis-editor/omnis-editor.component';

import { OmnisEditorService } from '@sc/omnis-editor/services/omnis-editor.service';
import { OmnisEditorFormsService } from '@sc/omnis-editor/services/omnis-editor-forms.service';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  exports: [OmnisEditorComponent],
  declarations: [EditableComponent, OmnisEditorComponent],
  providers: [OmnisEditorService, OmnisEditorFormsService],
})
export class OmnisEditorModule {}
