import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LocationGenerator } from '../models/location-generator.model';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class LocationGeneratorService {
  private apiUrl = config().apiUrl + '/_backend/locationGenerator';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  initial(options?: { [key: string]: any }) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(this.apiUrl + '/init' + queryParams);
  }

  generate(data?: { [key: string]: any }) {
    return this.httpClient.post(this.apiUrl, data);
  }
}
