import * as fromGrids from '../actions/grids.action';

export interface GridState {
  gridHeight: string;
  minCols: number;
}

export const initialState = {
  gridHeight: '900px',
  minCols: null
};

export function reducer(
  state = initialState,
  action: fromGrids.GridsAction
): GridState {
  switch (action.type) {
    case fromGrids.SET_GRID_HEIGHT: {
      const height = action.payload;
      return {
        ...state,
        gridHeight: height
      };
    }

    case fromGrids.SET_MIN_COLUMNS: {
      const minCols = action.payload;
      return {
        ...state,
        minCols
      };
    }
  }

  return state;
}

export const getGridHeight = (state: GridState) => state.gridHeight;

export const getGridMinColumns = (state: GridState) => state.minCols;
