<div
  *ngIf="dataset && !isFetchingDataset; then content; else loadingIndicator"
></div>

<ng-template #content>
  <div class="sc-flex justify-content-end transparent">
    <sc-modal-trigger
      buttonIcon="fa fa-line-chart"
      buttonLabel="{{ 'ANALYSE' | translate }}"
      [modalConfig]="analyseFormConfig"
    ></sc-modal-trigger>
  </div>

  <sc-table
    [columns]="columns"
    [config]="config"
    [dataset]="dataset"
    [formConfig]="formConfig"
    (reloadData)="fetchDataset()"
    (afterInit)="afterInitTable($event)"
    title="{{ tableTitle | translate }}"
    icon="fa fa-dashboard"
  ></sc-table>
</ng-template>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
