<section *ngFor="let floor of floors" class="layout">
  <sc-floor-form
    [floor]="floor"
    (onAddUnit)="addUnit($event)"
    (onRemoveUnit)="removeUnit($event)"
    (onUpdateUnit)="updateUnit($event)"
  ></sc-floor-form>
</section>
<section class="footer">
  <div class="footer-left">
    <button
      type="button"
      pButton
      class="ui-button-secondary"
      (click)="cancel()"
      [label]="'CANCEL' | translate"
    ></button>
  </div>
  <div class="footer-right">
    <sc-submit-button
      buttonClass="ui-button-success"
      (submitted)="submit()"
      [label]="'SAVE' | translate"
    ></sc-submit-button>
  </div>
</section>
