import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SiteType } from '@widgets/site-types/models/site-type';
import * as SiteTypeActions from '../actions/site-type.actions';

export interface State extends EntityState<SiteType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<SiteType> = createEntityAdapter<SiteType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const siteTypeReducer = createReducer(
  initialState,
  on(SiteTypeActions.addSiteTypes, (state, { siteTypes }) => {
    return adapter.setAll(siteTypes, state);
  }),
  on(SiteTypeActions.clearSiteTypes, (state) => {
    return adapter.removeAll({ ...state });
  })
);

export function reducer(state: State | undefined, action: Action) {
  return siteTypeReducer(state, action);
}
