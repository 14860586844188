import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Currency } from '../models/currency';

import { config } from '@app/config';

@Injectable()
export class CurrenciesService {
  private apiUrl = `${config().apiUrl}/currencies`;

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): Currency {
    const newData = { ...data };

    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getCurrencies() {
    return this.httpClient.get(this.apiUrl);
  }

  getCurrency(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  createCurrency(data: Currency) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateCurrency(id: number, data: Currency) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteCurrency(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isDeleted: true,
      isActive: false
    });
  }
}
