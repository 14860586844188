export enum OperandTypes {
  Device = 'device',
  DeviceLabel = 'Device',
  LocationProperty = 'location_property',
  LocationPropertyLabel = 'Location Property',
  DateTime = 'datetime',
  DateTimeLabel = 'Date & time',
  Global = 'global',
  GlobalLabel = 'Global',
  Function = 'function',
  FunctionLabel = 'Function',
}

export enum OperandTargets {
  Value = 'value',
  ValueLabel = 'Value',
  DateTime = 'datetime',
  DateTimeLabel = 'Date & Time',
  Now = 'now',
  NowLabel = 'Now',
  Absolute = 'absolute',
  AbsoluteLabel = 'Absolute',
  Relative = 'relative',
  RelativeLabel = 'Relative',
}

export enum OperatorTypes {
  Equal = '==',
  EqualLabel = 'Equal',
  ChangedAtLeastNtimes = 'changed_at_least_n_times',
  ChangedAtLeastNtimesLabel = 'Changed at least n times',
  ChangedMaxNtimes = 'changed_max_n_times',
  ChangedMaxNtimesLabel = 'Changed max. n times',
  NotEqual = '!=',
  NotEqualLabel = 'Not equal',
  Greater = '>',
  GreaterLabel = 'Greater',
  Less = '<',
  LessLabel = 'Less',
  GreaterOrEqual = '>=',
  GreaterOrEqualLabel = 'Greater or equal',
  LessOrEqual = '<=',
  LessOrEqualLabel = 'Less or equal',
  //DidNotChange = 'did_not_change',
  //DidNotChangeLabel = 'Did not change ',
}

// mixed of Operand Type + Operand Target with underscore(_) connector
export enum LeftOperandTypes {
  DatetimeNow = 'datetime_now',
  DeviceDateTime = 'device_datetime',
  DeviceValue = 'device_value',
  FunctionValue = 'function_value',
  GlobalValue = 'global_value',
  LocationPropertyDateTime = 'location_property_datetime',
  LocationPropertyValue = 'location_property_value',
}

export enum RightOperandTypes {
  Device = 'device',
  DeviceLabel = 'Device',
  Value = 'value',
  ValueLabel = 'Value',
  DatetimeAbsolute = 'datetime_absolute',
  DatetimeAbsoluteLabel = 'Date & Time Absolute',
  DatetimeRelative = 'datetime_relative',
  DatetimeRelativeLabel = 'Date & Time Relative',
}

export enum CommandTypes {
  CustomGlobal = 'custom_global',
  CustomGlobalLabel = 'Set Custom Global',
  DeviceValueStorage = 'device_value_storage',
  DeviceValueStorageLabel = 'Device Value Storage',
  RuleBlocker = 'rule_blocker',
  RuleBlockerLabel = 'Rule Blocker',
  Notifier = 'notify',
  NotifierLabel = 'Notifier',
  Report = 'report',
  ReportLabel = 'Report',
  TaskQueue = 'task_queue',
  TaskQueueLabel = 'Task Queue',
  TriggerDevice = 'trigger_device',
  TriggerDeviceLabel = 'Trigger Device',
  UserTask = 'user_task',
  UserTaskLabel = 'User task',
}

export enum ModifierTypes {
  Numeric = 'numeric',
  CustomFunction = 'custom_function',
}

export enum RuleEditorModes {
  Advanced = 'advanced',
  AdvancedLabel = 'Advanced',
  Backend = 'backend',
  BackendLabel = 'Backend',
  Expert = 'expert',
  ExpertLabel = 'Expert',
  Normal = 'normal',
  NormalLabel = 'Normal',
  Integrator = 'integrator',
  IntegratorLabel = 'Integrator',
}
