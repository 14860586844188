<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!--Header-->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>
          <div class="ui-g-6 text-right">
            <sc-activate-button
              [form]="form"
              field="isActive"
            ></sc-activate-button>
          </div>
        </div>
      </p-header>

      <!--Body-->
      <div class="ui-g">
        <!-- LEFT COLUMN -->
        <div class="ui-g-6">
          <!-- LOCATION_ID FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="locationId"
              [form]="form"
              label="{{ 'LOCATION' | translate }}"
              [options]="selectItems?.locations"
            ></sc-dropdown>
          </div>

          <!-- VALID_FROM FIELD -->
          <div class="ui-g-12">
            <sc-datetime-input
              controlName="validFrom"
              [form]="form"
              label="{{ 'VALID_FROM' | translate }}"
            ></sc-datetime-input>
          </div>

          <!-- VALID_TO FIELD -->
          <div class="ui-g-12">
            <sc-datetime-input
              controlName="validTo"
              [form]="form"
              label="{{ 'VALID_TO' | translate }}"
            ></sc-datetime-input>
          </div>

          <!-- PIN FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="pin"
              [form]="form"
              label="{{ 'PIN' | translate }}"
            ></sc-text-input>
          </div>
        </div>

        <!-- RIGHT COLUMN -->
        <div class="ui-g-6">
          <!-- FIRSTNAME FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="firstname"
              [form]="form"
              label="{{ 'FIRSTNAME' | translate }}"
            ></sc-text-input>
          </div>

          <!-- LASTNAME FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="lastname"
              [form]="form"
              label="{{ 'LASTNAME' | translate }}"
            ></sc-text-input>
          </div>

          <!-- GENDER Field -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="gender"
              [form]="form"
              label="{{ 'GENDER' | translate }}"
              [options]="selectItems?.gender"
            ></sc-dropdown>
          </div>

          <!-- LOCALE FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="locale"
              [form]="form"
              label="{{ 'LANGUAGE' | translate }}"
              [options]="selectItems?.locales"
            ></sc-dropdown>
          </div>
        </div>

        <!-- Error Message -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage | translate"></sc-form-error>
        </div>
      </div>

      <!--Footer-->
      <p-footer>
        <div class="ui-g-6">
          <sc-confirm-trigger
            *ngIf="editMode && !formData?.isDeleted"
            buttonIcon="fa fa-trash"
            buttonClass="ui-button-danger"
            buttonLabel="{{ 'DELETE' | translate }}"
            (onAccept)="delete()"
          ></sc-confirm-trigger>
        </div>
        <div class="ui-g-6 text-right">
          <button
            type="button"
            class="ui-button-secondary"
            (click)="dismissModal('cancel')"
            pButton
            label="{{ 'CANCEL' | translate }}"
          ></button>
          <sc-submit-button
            *ngIf="!formData?.isDeleted || formData?.isActive"
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
