import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import * as fromDevices from '../actions/devices.action';
import { DevicesService } from '@widgets/devices/services/devices.service';

@Injectable()
export class DevicesEffects {
  constructor(private action$: Actions, private devicesService: DevicesService) {}

  @Effect()
  loadDevice$ = this.action$.pipe(
    ofType(fromDevices.LOAD_DEVICE),
    map((action: fromDevices.LoadDevice) => action.payload),
    switchMap((deviceId) => {
      return this.devicesService.getDeviceDetails(deviceId).pipe(
        map((result: any) => {
          if (result.data) {
            return new fromDevices.LoadDeviceSuccess(result.data);
          }
          return new fromDevices.LoadDeviceFail('device was not found');
        }),
        catchError((error) => of(new fromDevices.LoadDeviceFail(error)))
      );
    })
  );

  @Effect()
  loadDevices$ = this.action$.pipe(
    ofType(fromDevices.LOAD_DEVICES),
    map((action: fromDevices.LoadDevices) => action.payload),
    switchMap((requestOptions) => {
      return this.devicesService.getDevices(requestOptions).pipe(
        map((result: any) => new fromDevices.LoadDevicesSuccess(result.data)),
        catchError((error) => of(new fromDevices.LoadDevicesFail(error)))
      );
    })
  );
}
