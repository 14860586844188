import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { GuestRequestActionCellComponent } from './guest-request-action-cell/guest-request-action-cell.component';
import { GuestRequestStatusCellComponent } from './guest-request-status-cell/guest-request-status-cell.component';
import { GuestRequestTextCellComponent } from './guest-request-text-cell/guest-request-text-cell.component';
import { GuestRequestsTableComponent } from './guest-requests-table/guest-requests-table.component';
import { GuestRequestReactionTimeCellComponent } from './guest-request-reaction-time-cell/guest-request-reaction-time-cell.component';
import { GuestRequestsService } from './guest-requests.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    GuestRequestActionCellComponent,
    GuestRequestStatusCellComponent,
    GuestRequestTextCellComponent,
    GuestRequestReactionTimeCellComponent,
    GuestRequestsTableComponent
  ],
  entryComponents: [
    GuestRequestActionCellComponent,
    GuestRequestStatusCellComponent,
    GuestRequestTextCellComponent,
    GuestRequestReactionTimeCellComponent,
    GuestRequestsTableComponent
  ],
  providers: [GuestRequestsService]
})
export class GuestRequestsModule {}
