import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { RuleHistoryLog } from '@widgets/rules/models/rule-history-log';
import { Rule } from '@widgets/rules/models/rule';
// import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';
import { LocationDetails } from '@widgets/locations/models/location-details';

import { RuleHistoryLogsService } from '@widgets/rules/services/rule-history-logs.service';
import { RulesService } from '@widgets/rules/services/rules.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-rule-history',
  templateUrl: './location-rule-history.component.html',
  styleUrls: ['./location-rule-history.component.scss'],
})
export class LocationRuleHistoryComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  @Input()
  selectedLocation: LocationDetails;

  columns: ColDef[];
  dataset: RuleHistoryLog[];
  table: GridOptions;
  tableTitle: string;
  dateFilter: any;
  datasource: any;
  toolbarItems: string[];

  isFetchingDataset: boolean;

  private rules: Rule[];
  // private selectedLocation: Location;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};
  private visibleColumns: string[] = [];

  constructor(
    private store: Store<fromStore.State>,
    private ruleHistoryLogsService: RuleHistoryLogsService,
    private rulesService: RulesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.config.gridOptions = {
      floatingFilter: false,
      defaultColDef: {
        autoHeight: true,
        suppressFilter: true,
        suppressMenu: true,
        suppressSorting: true,
      },
    };
    // Set new height of widget
    // this.config.widgetHeight = this.config.widgetHeight - 25;
    // Set Table Name
    this.tableTitle = 'RULE_HISTORY_LOG';
    // Set Table Toolbar Items
    this.toolbarItems = ['refresh', 'columns'];
    // Set Table Columns
    this.columns = this.createColumns();
    // Set user's visible columns
    if (this.config && this.config.customOptions && this.config.customOptions.visibleColumns) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }
    // get site details
    this.selectedSite = this.sharedService.selectedSite;
    // get location details
    // this.subscribers.LOCATION_DETAIL = this.store.select(fromStore.getLocationDetials).subscribe((result) => {
    //   if (result) {
    //     this.selectedLocation = result;
    //     this.fetchDataset();
    //     this.fetchRules();
    //   }
    // });

    this.fetchDataset();
    this.fetchRules();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchRules() {
    const options = {
      companyId: this.selectedSite.companyId,
      siteId: this.selectedSite.id,
      locationId: this.selectedLocation.id,
      columns: 'id,name',
    };
    this.subscribers.GET_RULES = this.rulesService.getRules(options).subscribe((result: any) => {
      this.rules = result.data;

      // refresh name column
      if (this.table && this.table.api) {
        this.table.api.refreshCells({ columns: ['name'], force: true });
      }
    });
  }

  fetchDataset() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.datasource = {
        getRows: (rowParams) => {
          // LHL OPTIONS
          const options = {
            companyId: this.selectedSite.companyId,
            siteId: this.selectedSite.id,
            locationId: this.selectedLocation.id,
            filter: rowParams.filterModel,
            sort: rowParams.sortModel,
            offset: rowParams.startRow,
            daterange: this.dateFilter,
            limit: 100,
            fields: [...this.visibleColumns].join(),
          };

          // GET LHL
          this.subscribers.GET_LHL = this.ruleHistoryLogsService
            .getRuleHistoryLogLazyLoad(options)
            .subscribe((result: any) => {
              const rowData = result.data;
              let lastRow = -1;
              if (rowData.length < options.limit) {
                lastRow = rowParams.startRow + rowData.length;
              }
              rowParams.successCallback(rowData, lastRow);
            });
        },
      };

      // SET DATASOURCE IF TABLE READY
      if (this.table && this.table.api) {
        this.table.api.setDatasource(this.datasource);
      }

      this.isFetchingDataset = false;
    }
  }

  onDateSelect(event) {
    this.dateFilter = {
      from: event.from,
      to: event.to,
    };
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private getRuleName(params) {
    if (this.rules && params && params.data && params.data.ruleId) {
      const rule = this.rules.find((r) => r.id === +params.data.ruleId);
      return rule ? rule.name : '';
    }
    return '';
  }

  private getRuleExecutionMode(params) {
    if (params && params.data && params.data.executionMode) {
      switch (params.data.executionMode) {
        case 'e':
          return 'End';
        case 's':
          return 'Start';
        default:
          return '';
      }
    }
    return '';
  }

  private createColumns() {
    return [
      {
        colId: 'createdAt',
        headerName: 'DATE',
        field: 'createdAt',
        cellRenderer: 'loadingRenderer',
        valueFormatter: (params) => this.sharedService.dateFormat(params.value),
        width: 200,
      },
      {
        colId: 'name',
        headerName: 'NAME',
        // field: 'name',
        valueFormatter: this.getRuleName.bind(this),
        valueGetter: this.getRuleName.bind(this),
      },
      {
        colId: 'executionMode',
        headerName: 'EXECUTION_MODE',
        // field: 'executionMode',
        valueFormatter: this.getRuleExecutionMode.bind(this),
        valueGetter: this.getRuleExecutionMode.bind(this),
      },
      {
        colId: 'ruleId',
        headerName: 'ID',
        field: 'ruleId',
      },
    ];
  }
}
