<div [formGroup]="form" class="ui-g">
  <div class="ui-g-3">
    <sc-text-input
      controlName="key"
      [form]="form"
      label="{{ 'KEY' | translate }}"
    ></sc-text-input>
  </div>

  <div class="ui-g-3">
    <sc-dropdown
      controlName="type"
      [form]="form"
      label="{{ 'TYPE' | translate }}"
      [options]="selectItems['parameterTypes']"
    ></sc-dropdown>
  </div>

  <div class="ui-g-3">
    <sc-text-input
      controlName="default_value"
      [form]="form"
      label="{{ 'DEFAULT_VALUE' | translate }}"
    ></sc-text-input>
  </div>

  <div class="ui-g-3 mt-4 text-right">
    <button
      pButton
      type="button"
      class="ui-button-warning"
      icon="fa fa-minus"
      (click)="remove()"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'PARAMETER' | translate }}"
      appendTo="body"
    ></button>
  </div>
</div>
