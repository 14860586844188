import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-modbus-settings-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './modbus-settings-form.component.html',
})
export class ModbusSettingsFormComponent implements OnInit, OnChanges {
  @Input()
  form: FormGroup;

  @Input()
  selectItems: { [key: string]: SelectItem[] } = {};

  modbusSettingsForm: FormGroup;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue) {
      this.updateModbusForm();
    }
  }

  ngOnInit(): void {}

  private updateModbusForm() {
    this.modbusSettingsForm = this.form.get('modbus_settings') as FormGroup;
  }
}
