import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppStyleTemplate } from '../models/app-style-template';

import { config } from '../../../config';

@Injectable()
export class AppStyleTemplatesService {
  private apiUrl = `${config().apiUrl}/appStyleTemplates`;

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): AppStyleTemplate {
    const newData = Object.assign({}, data);

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  getAppStyleTemplate(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getAppStyleTemplates() {
    return this.httpClient.get(this.apiUrl);
  }

  createAppStyleTemplate(data: AppStyleTemplate) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateAppStyleTemplate(id: number, data: AppStyleTemplate) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteAppStyleTemplate(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
