<ng-container
  *ngIf="isFetchingDataset; then spinner; else content"
></ng-container>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <sc-table
    [columns]="columns"
    [config]="config"
    [formConfig]="formConfig"
    icon="fa fa-exclamation-triangle"
    [lazyload]="true"
    [title]="tableTitle"
    (afterInit)="afterInitTable($event)"
    (displayColumnsChange)="updateVisibleColumns($event)"
    (reloadData)="fetchDataset()"
  ></sc-table>
</ng-template>
