<div [formGroup]="form" class="ui-g">
  <div class="ui-g-5">
    {{ form?.value?.key }}
  </div>

  <div class="ui-g-7">
    <ng-container [ngSwitch]="template.type">
      <ng-container *ngSwitchCase="'file'">
        <sc-local-file-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
          [showLabel]="false"
        ></sc-local-file-input>
      </ng-container>
      <ng-container *ngSwitchCase="'integer'">
        <sc-number-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
          [showLabel]="false"
        ></sc-number-input>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <sc-text-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
          [showLabel]="false"
        ></sc-text-input>
      </ng-container>
    </ng-container>
  </div>
</div>
