import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenerateBenchmark } from '../models/generate-benchmark';
import { GenerateBenchmarkStatus } from '../models/generate-benchmark-status';

import { config } from '@app/config';

@Injectable()
export class BenchmarksService {
  private apiUrl = config().apiUrl + '/benchmarks';

  constructor(private httpClient: HttpClient) {}

  /**
   * Get Benchmarks
   * @param options
   */
  getBenchmarks(options?: { [key: string]: any }) {
    let queryParams: any = [];
    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if (typeof options[key] === 'object') {
          queryParams.push(
            key + '=' + encodeURIComponent(JSON.stringify(options[key]))
          );
        } else {
          queryParams.push(key + '=' + encodeURIComponent(options[key]));
        }
      }
    }
    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';
    const apiUrl = this.apiUrl + queryParams;
    return this.httpClient.get(apiUrl);
  }

  /**
   * Generate Benchmark
   * @param data
   */
  generateBenchmark(data: GenerateBenchmark) {
    return this.httpClient.post(this.apiUrl, data);
  }

  /**
   * Generate Benchmark Status
   * @param data
   */
  generateBenchmarkStatus(options: GenerateBenchmarkStatus) {
    let queryParams: any = [];

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if (typeof options[key] === 'object') {
          queryParams.push(
            key + '=' + encodeURIComponent(JSON.stringify(options[key]))
          );
        } else {
          queryParams.push(key + '=' + encodeURIComponent(options[key]));
        }
      }
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';
    const apiUrl = this.apiUrl + '/generateStatus' + queryParams;
    return this.httpClient.get(apiUrl);
  }

  /**
   * Delete Benchmark
   * @param id
   */
  deleteBenchmark(id: string) {
    const apiUrl = `${this.apiUrl}/${id}`;
    return this.httpClient.delete(apiUrl);
  }
}
