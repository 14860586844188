<div class="function-operand container">
  <div class="function-operand-body" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12">
        <label class="input-label" for="custom_function_idx">
          {{ "CCP_CUSTOM_FUNCTION" | translate }}
        </label>
        <p-dropdown
          inputId="custom_function_idx"
          [options]="selectItems?.customFunctions"
          formControlName="custom_function_idx"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_CUSTOM_FUNCTION' | translate
          }}"
          [filter]="true"
          appendTo="body"
          [disabled]="!isTemplate"
        ></p-dropdown>
      </div>

      <ng-container *ngIf="variables">
        <div class="p-field p-col-12 p-md-12">
          <sc-function-variables
            [dataSources]="dataSources"
            [selectItems]="selectItems"
            [form]="form"
            [variables]="variables"
            [isTemplate]="isTemplate"
          ></sc-function-variables>
        </div>
      </ng-container>

      <div *ngIf="isTemplate">
        <div class="p-field p-col-12 p-md-12">
          <label class="input-label" for="is_source_editable">
            {{ "EDITABLE" | translate }}
          </label>
          <p-checkbox
            inputId="is_source_editable"
            [binary]="true"
            [formControl]="form?.get('is_source_editable')"
            [label]="'YES' | translate"
          ></p-checkbox>
        </div>
        <div class="p-field p-col-12 p-md-12" *ngIf="form?.get('is_source_editable').value == true">
          <label class="input-label" for="name">
            {{ "NAME" | translate }}
          </label>
          <input type="text" pInputText inputId="name" formControlName="name" />
        </div>
      </div>
    </div>
  </div>
</div>
