<div [formGroup]="form" class="ui-g">
  <!-- SOURCE -->
  <div class="ui-g-3">
    <ng-container [ngSwitch]="form.value?.targetTypeId">
      <!-- DEVICE_ID FIELD -->
      <ng-container *ngSwitchCase="'d'">
        <sc-dropdown
          controlName="deviceId"
          [form]="form"
          label="{{ 'DEVICE' | translate }}"
          [options]="selectItems['devices']"
          [showLabel]="false"
        ></sc-dropdown>
      </ng-container>

      <ng-container *ngSwitchCase="'dvs'">
        <sc-dropdown
          controlName="deviceId"
          [form]="form"
          label="{{ 'DEVICE' | translate }}"
          [options]="selectItems['devices']"
          [showLabel]="false"
        ></sc-dropdown>
      </ng-container>

      <!-- LOCATION_ID FIELD -->
      <ng-container *ngSwitchCase="'l'">
        <div class="ui-g">
          <div class="ui-g-12 ui-md-6 ui-g-nopad">
            <sc-dropdown
              controlName="locationId"
              [form]="form"
              label="{{ 'LOCATION' | translate }}"
              [options]="selectItems['locations']"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <div class="ui-g-12 ui-md-6 ui-g-nopad text-center">
            {{ form.value?.locationPropertyTypeId }}
          </div>
        </div>
      </ng-container>

      <!-- SERVICE_ID FIELD -->
      <ng-container *ngSwitchCase="'s'">
        <ng-container [ngSwitch]="form.value?.serviceTypeId">
          <ng-container *ngSwitchCase="'n'">{{
            'NOTIFIER' | translate
          }}</ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!-- ACTION -->
  <div class="ui-g-3">
    <ng-container [ngSwitch]="form.value?.targetTypeId">
      <!-- DEVICE -->
      <ng-container *ngSwitchCase="'d'">
        {{ form.value?.action }}
      </ng-container>

      <!-- DEVICE_VALUE_STORAGE -->
      <ng-container *ngSwitchCase="'dvs'">
        {{ form.value?.action }}
      </ng-container>

      <!-- LOCATION -->
      <ng-container *ngSwitchCase="'l'">
        <ng-container *ngIf="form.value?.locationPropertyAdjustValue">
          {{ 'ADJUST_VALUE' | translate }}
        </ng-container>

        <ng-container *ngIf="form.value?.locationPropertyForceValue">
          {{ 'FORCE_VALUE' | translate }}
        </ng-container>
      </ng-container>

      <!-- SERVICE -->
      <ng-container *ngSwitchCase="'s'">
        <ng-container [ngSwitch]="form.value?.serviceTypeId">
          <ng-container *ngSwitchCase="'n'">
            {{ 'PUSH_NOTIFICATION' | translate }}
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!-- VALUE -->
  <div class="ui-g-6">
    <ng-container [ngSwitch]="form.value?.targetTypeId">
      <!-- DEVICE -->
      <ng-container *ngSwitchCase="'d'">
        <!-- PARAMETERS -->
        <ng-container formArrayName="parameters">
          <ng-container
            *ngFor="let parameter of parameterForms.controls; let i = index"
            [formGroupName]="i"
          >
            <sc-command-parameter-form
              [form]="parameter"
              [formId]="i"
              [data]="data"
            ></sc-command-parameter-form>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- DEVICE_VALUE_STORAGE -->
      <ng-container *ngSwitchCase="'dvs'">
        <!-- PARAMETERS -->
        <ng-container formArrayName="parameters">
          <ng-container
            *ngFor="let parameter of parameterForms.controls; let i = index"
            [formGroupName]="i"
          >
            <sc-command-parameter-form
              [form]="parameter"
              [formId]="i"
              [data]="data"
            ></sc-command-parameter-form>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- LOCATION -->
      <ng-container *ngSwitchCase="'l'">
        <ng-container *ngIf="locationPropertyMappingValues">
          <div class="ui-g-5 ui-g-nopad">
            <sc-dropdown
              [controlName]="locationPropertyModifyKey"
              [form]="form"
              label="{{ locationPropertyModifyLabel | translate }}"
              [options]="locationPropertyMappingValues"
              [showLabel]="false"
            ></sc-dropdown>
          </div>
        </ng-container>

        <ng-container *ngIf="!locationPropertyMappingValues">
          <div class="ui-g-5 ui-g-nopad">
            <sc-text-input
              [controlName]="locationPropertyModifyKey"
              [form]="form"
              label="{{ locationPropertyModifyLabel | translate }}"
              [showLabel]="false"
            ></sc-text-input>
          </div>
        </ng-container>
      </ng-container>

      <!-- SERVICE -->
      <ng-container *ngSwitchCase="'s'">
        <ng-container [ngSwitch]="form.value?.serviceTypeId">
          <ng-container *ngSwitchCase="'n'">
            {{ notificationTemplateName }}
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
