import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';

import { GuestRequestsService } from '../guest-requests.service';
import { SharedService } from '@shared/shared.service';

import * as moment from 'moment';

@Component({
  selector: 'sc-guest-request-action-cell',
  templateUrl: './guest-request-action-cell.component.html',
})
export class GuestRequestActionCellComponent implements ICellRendererAngularComp {
  cell: any;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  constructor(private guestRequestsService: GuestRequestsService, private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;

    this.actions = [
      {
        key: 'Acknowledge',
        icon: 'fa fa-check',
        // permission: '_u',
        type: 'confirm',
        onConfirm: this.markAsAccepted.bind(this, true),
      },
      {
        key: 'Reject',
        icon: 'fa fa-times',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.markAsAccepted.bind(this, false),
      },
    ];
  }

  refresh(): boolean {
    return false;
  }

  isDisplayActionButton() {
    if (typeof this.cell.data.isDeclined !== 'undefined' || typeof this.cell.data.isAccepted !== 'undefined') {
      return false;
    } else {
      return true;
    }
  }

  markAsAccepted(isAccepted: boolean) {
    const bodyData: any = {};
    if (isAccepted) {
      bodyData.isAccepted = true;
      bodyData.acceptedAt = moment.utc().format();
    } else {
      bodyData.isDeclined = true;
      bodyData.declinedAt = moment.utc().format();
    }

    this.guestRequestsService.updateGuestRequest(this.cell.data.id, bodyData).subscribe(
      (result: any) => {
        bodyData.id = this.cell.data.id;
        this.cell.context.parentComponent.updateRow(bodyData);
        const textKey = isAccepted ? 'GUEST_REQUEST_MARK_ACCEPTED_SUCCESS' : 'GUEST_REQUEST_MARK_DECLINED_SUCCESS';
        const text = this.sharedService.getTranslation(textKey);
        const title = this.sharedService.getTranslation('GUEST_REQUEST');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const textKey = isAccepted ? 'GUEST_REQUEST_MARK_ACCEPTED_FAIL' : 'GUEST_REQUEST_MARK_DECLINED_FAIL';
        const text = this.sharedService.getTranslation(textKey);
        const title = this.sharedService.getTranslation('GUEST_REQUEST');
        this.sharedService.notify(title, text, 'error');
      }
    );
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }
}
