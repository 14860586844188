import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { DeviceTaskActionCellComponent } from './device-task-action-cell/device-task-action-cell.component';
import { DeviceTaskFormComponent } from './device-task-form/device-task-form.component';
import { DeviceTaskTableComponent } from './device-task-table/device-task-table.component';
import { DeviceTaskParameterFormComponent } from './parameter-form/parameter-form.component';

import { DeviceTaskFormsService } from './services/device-task-form.service';
import { DeviceTasksService } from './services/device-task.service';

const COMPONENTS: any[] = [
  DeviceTaskActionCellComponent,
  DeviceTaskFormComponent,
  DeviceTaskTableComponent,
  DeviceTaskParameterFormComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [DeviceTaskFormsService, DeviceTasksService]
})
export class DeviceTasksModule {}
