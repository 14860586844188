<div class="file-manager">
  <div class="file-manager-header">
    <div class="file-tools-container">
      <sc-file-toolbar
        *ngIf="isAllowInCurrentMode()"
        [loading]="loading"
        [selectedFile]="selectedFile"
        (copyClicked)="onCopy()"
        (pasteClicked)="onPaste()"
        (moveClicked)="onMove()"
        (renameClicked)="onRename($event)"
        (deleteClicked)="onDelete()"
        (newFolderClicked)="onNewFolder($event)"
        (uploadClicked)="showUploadForm = true"
      ></sc-file-toolbar>
    </div>

    <div class="file-status-container">
      <ng-container *ngIf="loading">
        <span class="fa fa-refresh fa-spin"></span>
      </ng-container>
    </div>
  </div>

  <div class="file-manager-body">
    <div class="file-tree-container" *ngIf="isAllowInCurrentMode()">
      <sc-file-tree
        [files]="fileTree"
        (selected)="onSelected($event, 'tree')"
        (opened)="onOpened($event)"
      ></sc-file-tree>
    </div>

    <div class="file-list-container">
      <sc-file-path
        [selectedDir]="selectedDir"
        (opened)="onOpenedFromPath($event)"
      ></sc-file-path>

      <sc-file-list
        [files]="getDisplayFiles()"
        (selected)="onSelected($event, 'list')"
        (opened)="onOpened($event)"
      ></sc-file-list>
    </div>
  </div>
</div>

<p-dialog
  *ngIf="isAllowInCurrentMode()"
  [header]="'UPLOAD' | translate"
  [draggable]="false"
  [(visible)]="showUploadForm"
  appendTo="body"
>
  <sc-file-drop (upload)="onUpload($event)"></sc-file-drop>
</p-dialog>
