import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from '../../../config';

@Injectable()
export class TrashService {
  private apiUrl = `${config().apiUrl}/_backend/_trash`;

  constructor(private httpClient: HttpClient) {}

  getDataTypes(): Observable<any> {
    const apiUrl = `${this.apiUrl}`;
    return this.httpClient.get(apiUrl);
  }

  getData(dataType: string): Observable<any> {
    const apiUrl = `${this.apiUrl}/${dataType}`;
    return this.httpClient.get(apiUrl);
  }

  restoreData(dataType: string, dataTypeId: number): Observable<any> {
    const apiUrl = `${this.apiUrl}/${dataType}/${dataTypeId}`;
    return this.httpClient.patch(apiUrl, {});
  }

  deleteData(dataType: string, dataTypeId: number): Observable<any> {
    const apiUrl = `${this.apiUrl}/${dataType}/${dataTypeId}`;
    return this.httpClient.delete(apiUrl);
  }
}
