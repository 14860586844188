<div class="action-button">
  <button
    type="button"
    pButton
    class="ui-button-action"
    [icon]="buttonIcon"
    (click)="menu.toggle($event)"
    pTooltip="{{ 'ACTIONS' | translate }}"
    appendTo="body"
    tooltipPosition="top"
  ></button>

  <p-overlayPanel #menu appendTo="body" styleClass="action-panel">
    <ul class="action-list" *ngIf="actions">
      <li class="action-list-item" *ngFor="let a of actions">
        <ng-container [ngSwitch]="a.type">
          <ng-container *ngSwitchCase="'modal'">
            <sc-modal-trigger
              *scHasPermission="a?.permission"
              [modalConfig]="a?.modalConfig"
              (onClose)="onModalClose($event, a)"
            >
              <a (click)="menu.hide()">
                <i class="fa fa-fw" [ngClass]="a?.icon"></i>
                {{ a?.key | translate }}
              </a>
            </sc-modal-trigger>
          </ng-container>

          <ng-container *ngSwitchCase="'confirm'">
            <sc-confirm-trigger
              *scHasPermission="a?.permission"
              (onAccept)="onConfirm($event, a)"
              (onReject)="onReject($event, a)"
            >
              <a (click)="menu.hide()">
                <i class="fa fa-fw" [ngClass]="a?.icon"></i>
                {{ a?.key | translate }}
              </a>
            </sc-confirm-trigger>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <a (click)="onClick(a); menu.hide()">
              <i class="fa fa-fw" [ngClass]="a?.icon"></i>
              {{ a?.key | translate }}
            </a>
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </p-overlayPanel>
</div>
