import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { User } from '../models/user';

import { UsersService } from '../services/users.service';
import { SharedService } from '@shared/shared.service';

import { CustomValidators } from '@sc/form/custom-validators';

@Component({
  selector: 'sc-user-email-form',
  templateUrl: './user-email-form.component.html',
  styleUrls: ['./user-email-form.component.scss']
})
export class UserEmailFormComponent implements OnInit, OnDestroy {
  @Input()
  data: User;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  form: FormGroup;
  errorMessage: string;
  isSubmitting = false;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  initForm() {
    this.form = this.formBuilder.group(
      {
        current: [null, Validators.required],
        new: [
          null,
          Validators.compose([
            // is required
            Validators.required,
            //  email pattern
            Validators.email
          ])
        ],
        repeat: [null, Validators.required]
      },
      {
        // check is repeat password match new password
        validator: CustomValidators.compare('new', 'repeat', 'emailMatch')
      }
    );
  }

  submit() {
    this.isSubmitting = true;
    this.subscribers.CHANGE_EMAIL = this.usersService
      .setEmail(this.data.id, this.form.value)
      .subscribe(
        (result: any) => {
          // show notification
          const title = this.sharedService.getTranslation('CHANGE_EMAIL');
          const message = this.sharedService.getTranslation('SUCCESS');
          this.sharedService.notify(title, message, 'success');

          // close modal and return added data
          this.onClose.emit(result.data);
          // enabled the form submitting
          this.isSubmitting = false;
        },
        (error: any) => {
          // set error message
          this.errorMessage = error;
          // enabled the form submitting
          this.isSubmitting = false;
        }
      );
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
