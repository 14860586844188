import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SceneTemplateCommand } from '../models/scene-template';

@Injectable()
export class SceneTemplateFormsService {
  constructor(private formBuilder: FormBuilder) {}

  // scene template command form
  initCommandFormGroup(): FormGroup {
    const formControls = {
      deviceTypeId: [null, Validators.required],
      action: [null, Validators.required],
      parameters: null,
      delay: null,
    };

    return this.formBuilder.group(formControls);
  }

  setCommandFormArray(form: FormGroup, data: SceneTemplateCommand[]) {
    const formGroups = data.map((item) => {
      const fg = this.initCommandFormGroup();
      fg.patchValue(item);
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('commands', formArray);
  }

  // scene template form
  initSceneTemplateForm(): FormGroup {
    const formControls: any = {
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      description: null,
      commands: this.formBuilder.array([]),
      isActive: false,
      isDeleted: false,
    };
    return this.formBuilder.group(formControls);
  }
}
