<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <!-- BODY -->
    <div class="ui-g">
      <!-- TEMPLATE -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="template_id"
          [form]="form"
          label="{{ 'TEMPLATE' | translate }}"
          [options]="selectItems['taskTemplates']"
        ></sc-dropdown>
      </div>

      <!-- PUBLISHED_FROM FIELD -->
      <div class="ui-g-12">
        <sc-datetime-input
          controlName="published_from"
          [form]="form"
          label="{{ 'DEVICE_TASK_PUBLISHED_FROM' | translate }}"
        ></sc-datetime-input>
      </div>

      <!-- PUBLISHED_TO FIELD -->
      <div class="ui-g-12">
        <sc-datetime-input
          controlName="published_to"
          [form]="form"
          label="{{ 'DEVICE_TASK_PUBLISHED_TO' | translate }}"
        ></sc-datetime-input>
      </div>

      <!-- PARAMETERS -->
      <div formArrayName="parameters" class="ui-g-12">
        <h4>{{ 'PARAMETERS' | translate }}</h4>
        <div *ngFor="let ctrl of parametersForm.controls; let i=index" [formGroupName]="i">
          <sc-device-task-parameter-form
            [form]="ctrl"
            [formId]="i"
            (removed)="removeParameter(i)"
            [template]="templateParameters[i]"
          ></sc-device-task-parameter-form>
        </div>
      </div>

      <!-- ERROR MESSAGE -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6"></div>

      <div class="ui-g-12 ui-md-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.is_deleted || formData?.is_active"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</ng-template>
