import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';

@Component({
  selector: 'sc-user-task-variable',
  templateUrl: './user-task-variable.component.html',
  styleUrls: ['./user-task-variable.component.scss'],
})
export class UserTaskVariableComponent implements OnInit {
  @Input()
  control: FormGroup;

  @Input()
  key: number | string;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  constructor() {}

  ngOnInit(): void {}
}
