<div class="mqtt-setting-form">
  <div class="mqtt-setting-form__header">
    <div class="mqtt-setting-form__header__title">
      {{ "MQTT_SETTINGS_FORM_TITLE" | translate }}
    </div>
  </div>

  <ng-container *ngIf="fetchingState === 0; else loadingIndicator">
    <div class="mqtt-setting-form__content">
      <div
        class="mqtt-setting-form__content__highlight"
        *ngIf="username && isAutomation"
      >
        <p class="m-0" *ngIf="!isLinked">
          {{ "MQTT_SETTINGS_FORM_AUTOMATION_IS_NOT_LINKED" | translate }}
        </p>
        <p class="m-0" *ngIf="isLinked">
          {{ "MQTT_SETTINGS_FORM_AUTOMATION_IS_LINKED" | translate }}
        </p>
      </div>

      <div
        class="mqtt-setting-form__content__highlight"
        *ngIf="username && !isAutomation && !isLinked"
      >
        <p class="m-0" *ngIf="!isLinked">
          {{ "MQTT_SETTINGS_FORM_USER_IS_NOT_LINKED" | translate }}
        </p>
        <!-- <p class="m-0" *ngIf="isLinked">
          {{ "MQTT_SETTINGS_FORM_USER_IS_LINKED" | translate }}
        </p> -->
      </div>

      <div [formGroup]="form" *ngIf="form" class="ui-g">
        <div class="ui-g-12 ui-md-6">
          <sc-text-input
            controlName="username"
            [form]="form"
            [label]="'USERNAME' | translate"
          ></sc-text-input>
        </div>

        <div class="ui-g-12 ui-md-6">
          <sc-text-input
            controlName="password"
            [form]="form"
            [label]="'PASSWORD' | translate"
            [hasAction]="true"
            [actionIcon]="'fa fa-magic'"
            (actionClicked)="generatePassword()"
          ></sc-text-input>
        </div>
      </div>

      <div class="ui-g" *ngIf="errorMessage">
        <div class="ui-g-12">
          <sc-form-error [message]="errorMessage | translate"></sc-form-error>
        </div>
      </div>
    </div>

    <div class="mqtt-setting-form__footer" *ngIf="username">
      <sc-submit-button
        *ngIf="!isLinked"
        [label]="
          (isAutomation
            ? 'MQTT_SETTINGS_LINK_AUTOMATION_BUTTON_LABEL'
            : 'MQTT_SETTINGS_LINK_USER_BUTTON_LABEL'
          ) | translate
        "
        [disabled]="isUpdating"
        [pending]="isSubmitting"
        (submitted)="submit()"
      ></sc-submit-button>

      <sc-confirm-trigger
        *ngIf="isLinked"
        buttonClass="ui-button-danger"
        [buttonLabel]="
          (isAutomation
            ? 'MQTT_SETTINGS_UNLINK_AUTOMATION_BUTTON_LABEL'
            : 'MQTT_SETTINGS_UNLINK_USER_BUTTON_LABEL'
          ) | translate
        "
        [disabled]="isUpdating"
        (onAccept)="submit()"
      ></sc-confirm-trigger>

      <sc-submit-button
        *ngIf="isLinked"
        [label]="'CHANGE_PASSWORD' | translate"
        [disabled]="isSubmitting"
        [pending]="isUpdating"
        (submitted)="update()"
        buttonClass="ml-3 ui-button-secondary"
      ></sc-submit-button>
    </div>
  </ng-container>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
