<div *ngIf="fetchingState === 0; else loadingIndicator">

<form [formGroup]="form" novalidate>
  <p-card>
    <!--Header-->
    <p-header>
      <div class="ui-g">
        <div class="ui-g-6">
          <sc-text-input
            *ngIf="editMode"
            controlName="id"
            [form]="form"
            label="{{ 'ID' | translate }}"
          ></sc-text-input>
        </div>
        <div class="ui-g-6 text-right">
          <p-toggleButton
            formControlName="isActive"
            offIcon="fa fa-square-o"
            offLabel="{{ 'ACTIVE' | translate }}"
            onIcon="fa fa-check-square-o"
            onLabel="{{ 'ACTIVE' | translate }}"
          ></p-toggleButton>
        </div>
      </div>
    </p-header>

    <!--Body-->
    <div class="ui-g">
      <!-- ID Field -->
      <!-- <div class="ui-g-12">
        <sc-text-input
          controlName="permissionId"
          [form]="form"
          label="{{ 'PERMISSION' | translate }}"
        ></sc-text-input>
      </div> -->

      <!-- NAME Field -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <!-- DESCRIPTION Field -->
      <div class="ui-g-12">
        <sc-textarea
        controlName="description"
        [form]="form"
        label="{{ 'DESCRIPTION' | translate }}"
        ></sc-textarea>
      </div>

      <!-- ERROR MESSAGE -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <p-footer>
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>
      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>
        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </p-footer>
  </p-card>
</form>

</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
