import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CompanyActionCellComponent } from './company-action-cell/company-action-cell.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { CompaniesTableComponent } from './companies-table/companies-table.component';

import { CompaniesService } from './services/companies.service';

const components: any[] = [CompanyActionCellComponent, CompanyFormComponent, CompaniesTableComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...components],
  entryComponents: [...components],
  providers: [CompaniesService],
})
export class CompaniesModule {}
