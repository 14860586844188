import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CustomFunctionActionCellComponent } from './custom-function-action-cell/custom-function-action-cell.component';
import { CustomFunctionFormComponent } from './custom-function-form/custom-function-form.component';
import { CustomFunctionsTableComponent } from './custom-functions-table/custom-functions-table.component';
import { FunctionParameterFormComponent } from './function-parameter-form/function-parameter-form.component';
import { FunctionParametersComponent } from './function-parameters/function-parameters.component';
import { CustomFunctionFormService } from './services/custom-function-form.service';
import { CustomFunctionsService } from './services/custom-functions.service';

const components: any[] = [
  CustomFunctionActionCellComponent,
  CustomFunctionFormComponent,
  CustomFunctionsTableComponent,
  FunctionParameterFormComponent,
  FunctionParametersComponent,
];

@NgModule({
  imports: [SharedModule],
  declarations: [...components],
  entryComponents: [...components],
  providers: [CustomFunctionFormService, CustomFunctionsService],
})
export class CustomFunctionsModule {}
