import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '../widget.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-locations-humidity-alert',
  templateUrl: './locations-humidity-alert.component.html',
})
export class LocationsHumidityAlertComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isFetching: boolean;
  isFirstTime: boolean;
  widgetConfig: any;
  widgetDataset: any;
  widgetName: string = '';

  private dataset: any[];
  private info: any;
  private locations: any[];
  private selectedSite: Site;
  private intervals: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.isFetching = false;
          this.isFirstTime = true;
          this.initInfo();
          this.fetchData();
          this.intervals.updateData = setInterval(() => this.fetchData(), 5000);
        }
      },
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchData() {
    if (!this.isFetching && this.selectedSite) {
      this.isFetching = true;
      const { id } = this.selectedSite;
      this.subscribers.getDataset = this.widgetService.getHumidityAlert(id).subscribe({
        next: (result: any) => {
          this.dataset = result.data;
          this.prepareInfo();
          this.updateWidgetConfig();
          this.isFetching = false;
          this.isFirstTime = false;
        },
        error: (error: any) => {
          this.isFetching = false;
        },
      });
    }
  }

  private initInfo() {
    this.locations = [];
    this.info = {
      occupied: 0,
      locations: 0,
      unoccupied: 0,
      aboveAcceptable: 0,
    };
  }

  private prepareInfo() {
    this.initInfo();
    this.dataset.forEach((l) => {
      // Total Locations
      this.info.locations++;

      if (typeof l.humidity === 'number' && l.humidity > this.selectedSite.maxAcceptableHumidity) {
        // Total Above Acceptable Humidity
        this.info.aboveAcceptable++;
        this.locations.push(l);

        if (l.occupied === true) {
          // Above Acceptable Humidity but occupy
          this.info.occupied++;
        } else {
          // Above Acceptable Humidity but unoccupy
          this.info.unoccupied++;
        }
      }
    });

    this.widgetDataset = this.info;
  }

  private updateWidgetConfig() {
    let customClass;
    if (this.info.aboveAcceptable > 10) {
      customClass = 'danger';
    } else if (this.info.aboveAcceptable > 0) {
      customClass = 'warning';
    } else {
      customClass = '';
    }

    const subTitleText =
      this.info.aboveAcceptable + ' locations are above ' + this.selectedSite.maxAcceptableHumidity + ' %';

    this.widgetConfig = {
      titleText: this.widgetName,
      subTitleText,
      modalConfig: {
        name: 'LocationAlertModal',
        data: this.locations,
        options: {
          modalTitle: 'LOCATION_ALERT',
        },
      },
      square: {
        primaryText: '%',
        customClass,
      },
    };
  }
}
