import * as fromWidgets from '../actions/widgets.action';

import { Widget } from '@app/core2/models/page-section-widget';

export interface WidgetState {
  entities: { [id: string]: Widget };
  loaded: boolean;
  loading: boolean;
  showForm: boolean;
  selectedWidgets: Widget[];
}

export const initialState = {
  entities: null,
  loaded: false,
  loading: false,
  showForm: false,
  selectedWidgets: null
};

export function reducer(
  state = initialState,
  action: fromWidgets.WidgetsAction
): WidgetState {
  switch (action.type) {
    case fromWidgets.VISUALISE_WIDGETS: {
      const selectedWidgets = action.payload;
      return {
        ...state,
        selectedWidgets
      };
    }

    // ____ LOAD WIDGETS
    case fromWidgets.LOAD_WIDGETS: {
      return {
        ...state,
        loading: true
      };
    }

    case fromWidgets.LOAD_WIDGETS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromWidgets.LOAD_WIDGETS_SUCCESS: {
      // All widgets are loaded from the API. It's not the widgets of a section.
      const widgets = action.payload;
      const entities = widgets.reduce(
        (tmpEntities: { [id: number]: Widget }, widget: Widget) => {
          return {
            ...tmpEntities,
            [widget.id]: widget
          };
        },
        {}
        // {
        //   ...state.entities,
        // }
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }

    // SHOW / HIDE FORM
    case fromWidgets.SHOW_WIDGET_FORM: {
      return {
        ...state,
        showForm: true
      };
    }

    case fromWidgets.HIDE_WIDGET_FORM: {
      return {
        ...state,
        showForm: false
      };
    }
  }

  return state;
}

export const getWidgetsEntities = (state: WidgetState) => state.entities;

export const getSelectedWidgets = (state: WidgetState) => state.selectedWidgets;

export const getWidgetsLoading = (state: WidgetState) => state.loading;

export const getWidgetsLoaded = (state: WidgetState) => state.loaded;

export const getWidgetShowForm = (state: WidgetState) => state.showForm;
