<div class="function-variables">
  <div class="function-variables-header">
    <div class="function-variables-header__title">
      {{ "CCP_CUSTOM_FUNCTION_VARIABLES" | translate }}
    </div>
  </div>

  <div class="function-variables-body">
    <div *ngFor="let ctrl of variablesForm.controls; let i = index">
      <sc-function-variable-item
        [key]="i"
        [control]="ctrl"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-function-variable-item>
    </div>
  </div>
</div>
