import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItems } from '../../models';

@Component({
  selector: 'sc-notifier-command',
  templateUrl: './notifier-command.component.html',
  styleUrls: ['./notifier-command.component.scss'],
})
export class NotifierCommandComponent implements OnInit {
  actions: any[];

  @Input()
  commandForm: FormGroup;

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {
    this.commandForm.get('action').setValue('use_notification_template');
  }
}
