import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions } from 'ag-grid';

@Component({
  selector: 'sc-column-display-button',
  templateUrl: 'column-display-button.component.html',
  styleUrls: ['column-display-button.component.scss']
})
export class ColumnDisplayButtonComponent implements OnInit {
  @Input()
  default: string[];
  @Input()
  gridOptions: GridOptions;
  @Output()
  saved = new EventEmitter();
  @Output()
  columnsChanged = new EventEmitter();

  columns: Array<{
    colId: string;
    colName: string;
    visible: boolean;
  }>;

  constructor() {}

  ngOnInit() {
    // ____ GET COLUMNS
    const columns = this.gridOptions.columnApi.getAllColumns();
    this.columns = columns.map((col: any) => ({
      colId: col.colId,
      colName: col.colDef.headerName,
      visible: col.colDef.hide === false ? !col.colDef.hide : col.visible
    }));

    if (!this.default && this.columns) {
      this.default = [];
      this.columns.forEach((c: any) => {
        if ((c.colDef && c.colDef.hide === false) || c.visible === true) {
          this.default.push(c.colId);
        }
      });
    }

    // console.log(this.columns);
    // console.log(this.default);

    // ____ INITIAL COLUMNS
    if (this.default) {
      this.toggle('init');
    } else {
      this.toggle('all');
    }
  }

  /**
   * Select columns handler
   * @param colId
   */
  toggle(colId) {
    let columnsToUpdate = [];
    let visible;
    switch (colId) {
      case 'init':
      case 'reset':
        // ____ HIDE ALL COLUMNS
        const colIds = this.columns.map((col: any) => col.colId);
        this.setColumnsVisible(colIds, false);
        // ____ SHOW DEFAULT COLUMNS
        columnsToUpdate = this.default || colIds;
        visible = true;
        // ____ UPDATE DROPDOWN
        this.columns.forEach((col: any) => {
          if (
            this.default &&
            this.default.length > 0 &&
            this.default.indexOf(col.colId) >= 0
          ) {
            col.visible = true;
          } else {
            col.visible = false;
          }
        });
        break;

      case 'all':
        visible = true;
        this.columns.forEach((col: any) => {
          col.visible = visible;
          columnsToUpdate.push(col.colId);
        });
        break;

      case 'none':
        visible = false;
        this.columns.forEach((col: any) => {
          col.visible = visible;
          columnsToUpdate.push(col.colId);
        });
        break;

      default:
        const column = this.columns.find((c: any) => c.colId === colId);
        column.visible = !column.visible;
        visible = column.visible;
        columnsToUpdate.push(colId);
        break;
    }

    this.setColumnsVisible(columnsToUpdate, visible);
  }

  /**
   * Set columns to display
   * @param columns
   * @param isVisible
   */
  async setColumnsVisible(columns: string[], isVisible: boolean) {
    // ____ TOGGLE COLUMNS
    if (columns && columns.length > 0) {
      await this.gridOptions.columnApi.setColumnsVisible(columns, isVisible);
      const visibleColumns = this.gridOptions.columnApi
        .getAllColumns()
        .filter((col: any) => col.visible === true)
        .map((col: any) => col.colId);
      this.columnsChanged.emit(visibleColumns);
    }
  }

  /**
   * Set columns width to fit container
   */
  setColumnsSizeToFit() {
    // ____ RESIZE COLUMNS
    this.gridOptions.api.sizeColumnsToFit();
  }

  /**
   * Set columns width based on content length
   */
  setColumnsSizeAuto() {
    const allColumnIds = [];
    this.gridOptions.columnApi.getAllColumns().forEach((column: any) => {
      allColumnIds.push(column.colId);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  }

  /**
   * Save handler
   */
  save() {
    const columns = this.gridOptions.columnApi.getAllColumns();

    // ____ GET CURRENT WIDTH OF ALL COLUMNS
    const sizeColumns = columns.map((col: any) => ({
      colId: col.colId,
      width: col.actualWidth
    }));

    // ____ GET CURRENT VISIBLE COLUMNS
    const visibleColumns = columns
      .filter((col: any) => col.visible === true)
      .map((col: any) => col.colId);

    this.saved.emit({
      sizeColumns,
      visibleColumns
    });
  }
}
