<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'HOUSEKEEPING' | translate }} -->
        {{ widgetName }}
      </h4>

      <!-- <div class="sc-widget-menu"></div> -->
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div
    class="sc-widget-body justify-start align-start nowrap"
    [ngStyle]="{ 'overflow-y': 'auto' }"
  >
    <h4 class="m-0">{{ "BLOCKS" | translate }}</h4>
    <div *ngIf="blocks?.length > 0" class="w-100">
      <ng-container *ngFor="let b of blocks">
        <sc-custom-bar [label]="b.description" [data]="b.stat"></sc-custom-bar>
      </ng-container>
    </div>
    <div *ngIf="!blocks || blocks?.length <= 0" class="w-100 p-2 grey-text">
      {{ "NO_DATA_FOUND" | translate }}
    </div>

    <h4 class="m-0">{{ "FLOORS" | translate }}</h4>
    <div *ngIf="floors?.length > 0" class="w-100">
      <ng-container *ngFor="let f of floors">
        <sc-custom-bar [label]="f.description" [data]="f.stat"></sc-custom-bar>
      </ng-container>
    </div>
    <div *ngIf="!floors || floors?.length <= 0" class="w-100 p-2 grey-text">
      {{ "NO_DATA_FOUND" | translate }}
    </div>
  </div>
</ng-template>
