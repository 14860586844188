import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Directive({ selector: '[scHasRole]' })
export class HasRoleDirective {
  private hasView = false;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set scHasRole(roleName: string) {
    const allow = this.authService.isAuthorized(roleName);

    // FIXME: find the way to control "undefined"
    if ((allow && !this.hasView) || typeof roleName === 'undefined') {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!allow && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
