import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions } from 'ag-grid';

import { OverviewFormData } from '../../models/overview-form-data';

// import { BenchmarksService } from '../../services/benchmarks.service';
import { BenchmarksBatchService } from '../../services/benchmarks-batch.service';
import { SharedService } from '@shared/shared.service';

// import * as moment from 'moment';

@Component({
  selector: 'sc-benchmark-analyse-modal',
  // styleUrls: ['analyse-modal.component.scss'],
  templateUrl: 'analyse-modal.component.html'
})
export class BenchmarkAnalyseModalComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  form: FormGroup;
  formData: OverviewFormData;
  subscribers: { [key: string]: any };
  fetchingState: number;
  dataset: any[];
  gridOptions: GridOptions;
  tableTitle: string;
  toolbarItems: string[];

  avg: { [key: string]: any };
  sum: { [key: string]: any };

  constructor(
    // private benchmarksService: BenchmarksService,
    private benchmarksService: BenchmarksBatchService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.avg = {};
    this.sum = {};
    this.subscribers = {};
    this.fetchingState = 0;
    this.tableTitle = 'BENCHMARK_ANALYSE';
    this.toolbarItems = ['columns'];

    if (this.data && this.data.formData) {
      this.formData = { ...this.data.formData, analyse: true };
      this.fetchDataset();
    }
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  fetchDataset() {
    this.fetchingState++;
    this.subscribers.GET_DATASET = this.benchmarksService
      .getBenchmarks(this.formData)
      .subscribe((result: any) => {
        this.dataset = result.data;
        if (this.dataset && this.dataset.length) {
          this.createBenchmarkResultTable();
        }
        this.fetchingState--;
      });
  }

  createBenchmarkResultTable() {
    const rowData = this.getOutputData(this.dataset);

    // create columns
    const columnDefs = [
      {
        headerName: 'START_TIME',
        colId: 'start_time',
        field: 'start_time',
        valueFormatter: params => this.sharedService.dateFormat(params.value)
      },
      {
        headerName: 'END_TIME',
        colId: 'end_time',
        field: 'end_time',
        valueFormatter: params => {
          if (params.data.isAvgRow) {
            return 'Average:';
          } else if (params.data.isTotalRow) {
            return 'Total:';
          }
          return this.sharedService.dateFormat(params.value);
        },
        pinnedRowCellRenderer: params => `
          <div style="text-align:right">
            ${params.valueFormatted || ''}
          </div>
        `
      },
      {
        headerName: 'DURATION',
        colId: 'duration',
        field: 'duration',
        valueFormatter: params => {
          const tmp = { hours: 0, minutes: 0, seconds: 0 };
          tmp.hours = params.data.duration / 60 / 60;
          tmp.minutes = (tmp.hours - Math.floor(tmp.hours)) * 60;
          tmp.seconds = (tmp.minutes - Math.floor(tmp.minutes)) * 60;

          return (
            (tmp.hours < 10 ? '0' : '') +
            Math.floor(tmp.hours) +
            ':' +
            (tmp.minutes < 10 ? '0' : '') +
            Math.floor(tmp.minutes) +
            ':' +
            (tmp.seconds < 10 ? '0' : '') +
            Math.floor(tmp.seconds) +
            ' Hours'
          );
        },
        // valueGetter: params =>
        //   moment.utc(params.data.duration * 1000).format('HH:mm:ss'),
        cellRenderer: params => `
          <div style="text-align:right">
            ${params.valueFormatted || ''}
          </div>
        `,
        pinnedRowCellRenderer: params => `
          <div style="text-align:right;color:${params.color};">
            ${params.valueFormatted || ''}
          </div>
        `,
        pinnedRowCellRendererParams: { color: 'blue' }
      },
      {
        headerName: 'LOCATION_NAME',
        colId: 'locationName',
        field: 'locationName'
      }
    ];

    const excludeColumns = [
      'start_time',
      'end_time',
      'duration',
      'locationName'
    ];

    for (const key in rowData[0]) {
      if (rowData[0].hasOwnProperty(key) && excludeColumns.indexOf(key) < 0) {
        const col: any = {
          headerName: key.toUpperCase(),
          colId: key,
          field: key
        };

        const keyword = key.replace(/^(start|end)\_/i, '');

        if (
          key !== 'disabling_reason' &&
          this.sharedService.isLHLValueBoolean(keyword) === false
        ) {
          col.valueFormatter = params =>
            this.sharedService.numberFormat(params.value);
          col.cellRenderer = params => {
            let color = params.normal;
            if (params.data.isAvgRow) {
              color = params.avg;
            } else {
              if (+params.valueFormatted > this.avg[params.colDef.colId]) {
                color = params.higherAvg;
              } else if (
                +params.valueFormatted < this.avg[params.colDef.colId]
              ) {
                color = params.lowerAvg;
              }
            }

            return `
                <span style="color:${color}">
                  ${params.valueFormatted || ''}
                </span>
              `;
          };
          col.cellRendererParams = {
            avg: 'blue',
            higherAvg: 'red',
            lowerAvg: 'green',
            normal: 'black'
          };
        }

        columnDefs.push(col);
      }
    }

    this.sum.isTotalRow = true;
    this.avg.isAvgRow = true;

    this.gridOptions = {
      animateRows: false,
      enableColResize: true,
      enableSorting: true,
      floatingFilter: true,
      rowData,
      columnDefs,
      pinnedTopRowData: [this.sum, this.avg]
    };
  }

  getOutputData(benchmarkResult) {
    const infoKeys = [
      'id',
      'benchmarkId',
      'benchmarkTemplateId',
      'locationId',
      'siteId',
      'companyId',
      'createdAt',
      'createdBy',
      'startDate',
      'endDate'
    ];
    const outputData = [];
    for (let i = 0; i < benchmarkResult.length; i++) {
      const data = {};
      for (const key in benchmarkResult[i]) {
        if (
          benchmarkResult[i].hasOwnProperty(key) &&
          infoKeys.indexOf(key) < 0
        ) {
          data[key] = benchmarkResult[i][key];
          if (typeof data[key] === 'number') {
            this.sum[key] = this.sum[key]
              ? this.sum[key] + data[key]
              : 0 + data[key];
          }
        }
      }
      outputData.push(data);
    }

    // calculate average
    Object.keys(this.sum).forEach(val => {
      this.avg[val] = this.sum[val] / benchmarkResult.length;
    });
    // outputData.push(this.avg);

    return outputData;
  }

  get showResult() {
    if (this.fetchingState === 0) {
      if (!this.dataset || !this.dataset.length) {
        return -1;
      }
      return 1;
    }
    return 0;
  }
}
