import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'avg-humidity-form',
  templateUrl: 'avg-humidity-form.component.html',
})
export class AvgHumidityFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() groupId: any;
  @Input() optionData: any;
  @Output() onRemove = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  remove() {
    this.onRemove.emit();
  }
}
