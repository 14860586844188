<div *ngIf="visualisedPage" class="page-wrapper">
  <sc-page-section
    *ngIf="showSectionsMenu()"
    [allowCustom]="visualisedPage?.allowCustomSections"
    [editMode]="editMode"
    [pageTemplates]="pageTemplates"
    [widgets]="widgets"
    [sections]="visualisedPage?.sections"
    [selected]="visualisedSection"
    (addSection)="onAddSection($event)"
    (deleteSection)="onDeleteSection($event)"
    (moveSection)="onMoveSection($event)"
    (resetSection)="onResetSection($event)"
    (switchSection)="onSwitchSection($event)"
  ></sc-page-section>

  <sc-page-header
    *ngIf="visualisedWidgets"
    [page]="currentPage"
  ></sc-page-header>

  <sc-page-body
    *ngIf="visualisedWidgets"
    [config]="gridConfig"
    [editMode]="editMode"
    [widgets]="visualisedWidgets"
    (deleteWidget)="onRemoveWidget($event)"
  ></sc-page-body>

  <ng-container *scHasPermission="editDashboardPermissions">
    <sc-page-edit-menu
      *ngIf="isLastWidgetInitSuccess && currentPage?.allowCustomSections"
      [editMode]="editMode"
      (addWidget)="onEditAddWidget()"
      (cancel)="onEditCancel()"
      (undo)="onEditReset()"
      (save)="onEditSave()"
      (toggleEditMode)="onSwitchMode()"
    ></sc-page-edit-menu>

    <sc-page-widget-form
      *ngIf="
        isLastWidgetInitSuccess && currentPage?.allowCustomSections && editMode
      "
      [show]="showWidgetForm"
      [widgets]="widgets"
      [widgetCategories]="widgetCategories"
      [currentWidgets]="visualisedWidgets"
      (done)="onAddWidget($event)"
      (cancalled)="onAddWidgetCancelled()"
      [multisites]="currentPage.key === 'integrator_overview'"
    ></sc-page-widget-form>
  </ng-container>
</div>
