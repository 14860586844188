import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAppStyle from '../reducers/app-style.reducer';

export const getAppStyleState = createFeatureSelector<
  fromAppStyle.AppStyleState
>('appStyle');

export const getAppStyleCurrent = createSelector(
  getAppStyleState,
  fromAppStyle.getCurrent
);

export const getAppStyleLoading = createSelector(
  getAppStyleState,
  fromAppStyle.getLoading
);
