import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PageTemplate } from '../models/page-template';

import { PageTemplateFormsService } from '../services/page-template-forms.service';
import { PageTemplatesService } from '../services/page-templates.service';
import { SettingWidgetsService } from '@widgets/setting-widgets/services/setting-widgets.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-page-template-form',
  templateUrl: 'page-template-form.component.html',
})
export class PageTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  formData: PageTemplate;
  form: FormGroup;
  isSubmitting = false;
  subscribers: { [key: string]: any } = {};

  optionData: { [key: string]: any } = {};

  constructor(
    private pageTemplateFormsService: PageTemplateFormsService,
    private pageTemplatesService: PageTemplatesService,
    private widgetsService: SettingWidgetsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.data) {
      this.editMode = true;
      this.fetchPageTemplates(this.data.id);
    }

    this.fetchWidgets();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchPageTemplates(id: number) {
    this.fetchingState++;
    this.subscribers.fetchPageTemplates = this.pageTemplatesService.getPageTemplate(id).subscribe((result: any) => {
      this.formData = result.data;
      this.formData.sections = this.formData.sections ? this.sharedService.jsonParse(this.formData.sections) : [];

      const sections = this.formData.sections.map((s: any) => {
        s.selectedWidgets = s.widgets.map((w) => w.widgetId);
        return s;
      });

      this.form.patchValue(this.formData);
      this.pageTemplateFormsService.setSectionFormArray(this.form, sections);
      this.fetchingState--;
    });
  }

  private fetchWidgets() {
    this.fetchingState++;
    this.subscribers.fetchWidgets = this.widgetsService.getWidgets().subscribe((result: any) => {
      this.optionData.widgetsSrc = result.data;
      this.optionData.widgets = result.data
        .filter((w) => w.isActive && !w.isDeleted)
        .map((w) => ({ label: this.sharedService.getLocaleWidgetName(w), value: w.id }))
        .sort((a, b) => a.label.localeCompare(b.label));
      this.fetchingState--;
    });
  }

  private initForm() {
    this.form = this.pageTemplateFormsService.initPageTemplateForm();
  }

  // Api Call
  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.value;

    // call api
    if (this.editMode) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  }

  private create(data: PageTemplate) {
    this.subscribers.CREATE_PAGE_TEMPLATE = this.pageTemplatesService.createPageTemplate(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(data: PageTemplate) {
    this.subscribers.UPDATE_PAGE_TEMPLATE = this.pageTemplatesService
      .updatePageTemplate(this.formData.id, data)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
      });
  }

  delete() {
    this.subscribers.DELETE_PAGE_TEMPLATE = this.pageTemplatesService.deletePageTemplate(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('PAGE_TEMPLATE');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }
}
