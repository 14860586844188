import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';
import { UtilsService } from '../../services';

@Component({
  selector: 'sc-absolute-datetime-operand',
  templateUrl: './absolute-datetime-operand.component.html',
  styleUrls: ['./absolute-datetime-operand.component.scss'],
})
export class AbsoluteDatetimeOperandComponent implements OnInit, OnDestroy {
  valueStructure: any;
  datetimeFormat: string;

  @Input()
  form: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  private subscribers: { [key: string]: any } = {};

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    if (this.form) {
      const formValue = this.form.value;

      this.subscribers.pattern = this.form.get('pattern').valueChanges.subscribe({
        next: (value) => {
          this.form.get('value').reset();
          this.setStructure(value);
        },
      });

      if (formValue && formValue.pattern) {
        this.setStructure(formValue.pattern);
      }
    }
  }

  ngOnDestroy(): void {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private setStructure(pattern: string) {
    this.datetimeFormat = null;
    this.valueStructure = null;

    if (
      pattern &&
      this.dataSources &&
      this.dataSources.absoluteDatetimePatterns &&
      this.dataSources.absoluteDatetimePatterns.length
    ) {
      const datetimePattern = this.dataSources.absoluteDatetimePatterns.find((item) => item.id === pattern);
      if (datetimePattern) {
        if (datetimePattern.value) {
          this.valueStructure = datetimePattern.value;
        }
        if (datetimePattern.pattern) {
          this.datetimeFormat = datetimePattern.pattern
            .replace('YYYY', 'yy')
            .replace(/\:?ii/gi, '')
            .replace('HH', '')
            .trim();
        }
      }
    }
  }
}
