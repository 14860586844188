import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Page } from '../models/page';

import { config } from '@app/config';

@Injectable()
export class PageService {
  private pageApiUrl = config().apiUrl + '/_backend/pages';
  private pageApiUrlDefault = config().apiUrl + '/pages';
  private templateApiUrl = config().apiUrl + '/_backend/pageTemplates';

  constructor(private http: HttpClient, private title: Title, private meta: Meta) {}

  /**
   * Formatting Page data for API payload
   * @param data
   */
  private formatData(data: any): Page {
    const page = { ...data };
    // ____ FORMAT PAGE
    delete page.id;
    delete page.pageTemplateId;
    delete page.allowInheritance;
    delete page.createdAt;
    delete page.createdBy;
    delete page.updatedAt;
    delete page.updatedBy;
    delete page.pageParams;

    // ____ FORMAT WIDGETS
    const sections = [];
    page.sections.forEach((section) => {
      const widgets = [];
      section.widgets.forEach((widget) => {
        const w = { ...widget };
        delete w.id;
        delete w.customWidgetId;
        delete w.type;
        delete w.name;
        delete w.idx;
        delete w.categories;
        delete w.adminOnly;
        delete w.description;
        delete w.icon;
        delete w.key;
        delete w.widgetHeight;
        delete w.isCollapsed;
        delete w.isActive;
        delete w.isDeleted;
        delete w.createdAt;
        delete w.createdBy;
        delete w.updatedAt;
        delete w.updatedBy;
        widgets.push(w);
      });
      sections.push({ ...section, widgets });
    });
    return { ...page, sections: [...sections] };
  }

  /**
   * Get All Pages or Get One by ID
   * @param id :pageId
   */
  getPages(id: string = '') {
    return this.http.get(`${this.pageApiUrl}/${id}`).pipe(catchError((error: any) => observableThrowError(error)));
  }

  getTemplates(id: string = '') {
    return this.http.get(`${this.templateApiUrl}/${id}`).pipe(catchError((error: any) => observableThrowError(error)));
  }

  /**
   * Update One page by ID
   * @param id :pageId
   * @param data
   */
  updatePage(id: number, data: Page) {
    return this.http
      .patch(`${this.pageApiUrlDefault}/${id}`, this.formatData(data))
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  updateTitle(title: string = '') {
    title = title + ' | Smart & Connective back-end';
    this.title.setTitle(title);
  }

  updateOgUrl(url: string = '') {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  updateDescription(desc: string = '') {
    this.meta.updateTag({ name: 'description', content: desc });
  }
}
