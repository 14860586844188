import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ModalConfig } from '@sc/modal/modal-config';

import { BenchmarksBatchService } from '../../services/benchmarks-batch.service';
import { SharedService } from '@shared/shared.service';

import * as moment from 'moment';

@Component({
  selector: 'sc-location-benchmark-action-cell',
  templateUrl: 'location-benchmark-action-cell.component.html'
})
export class LocationBenchmarkActionCellComponent
  implements ICellRendererAngularComp, OnDestroy {
  formConfig: ModalConfig;

  cell: any;
  isRefreshing: boolean;
  isFetchingBenchmarkStatus: boolean;
  benchmarkStatusFetchCount: number;
  benchmarkStatusIntervals: any;
  subscribers: { [key: string]: any };

  constructor(
    private benchmarksBatchService: BenchmarksBatchService,
    private sharedService: SharedService
  ) {}

  ngOnDestroy() {}

  agInit(params: any): void {
    this.subscribers = {};
    this.cell = params;
    this.formConfig = {
      name: 'LocationBenchmarkDetail',
      data: params.data,
      options: { modalTitle: 'BENCHMARK_DETAIL_MODAL_TITLE' }
    };

    if (this.cell.data && this.cell.data.status === 'in_progress') {
      this.startGenerateStatusCheck(this.cell.data.batch_id);
    }
  }

  refresh(): boolean {
    return false;
  }

  deleteRow() {
    if (this.cell && this.cell.data) {
      const batchId = this.cell.data.batch_id;
      const options = {
        locationId: this.cell.data.locationId
      };
      this.benchmarksBatchService.deleteBenchmark(batchId, options).subscribe(
        (result: any) => {
          this.cell.context.containerComponent.deleteRow(this.cell.data.id);
          this.sharedService.notify(
            'Location Benchmark',
            'Delete success!',
            'success'
          );
        },
        (error: any) => {
          this.sharedService.notify(
            'Location Benchmark',
            'Delete fail!',
            'error'
          );
        }
      );
    }
  }

  rerunBenchmark() {
    if (this.cell && this.cell.data) {
      this.isRefreshing = true;

      const date = this.cell.data.start_time.replace(/z/gi, '');
      const options = {
        benchmarkTemplate: this.cell.data.benchmarkTemplateId,
        locations: [this.cell.data.locationId],
        from: moment(date)
          .startOf('day')
          .utc()
          .format('DD.MM.YYYYTHH:mm:ss'),
        to: moment(date)
          .endOf('day')
          .utc()
          .format('DD.MM.YYYYTHH:mm:ss')
      };

      this.benchmarksBatchService.generateBenchmark(options).subscribe(
        (result: any) => {
          this.startGenerateStatusCheck(result.data.batch_id);

          // display toast message
          this.sharedService.notify(
            'Re-create Benchmark Success',
            'Benchmark is running in background until done',
            'info'
          );
        },
        (error: any) => {
          // display error message and unlock refresh button
          this.sharedService.notify(
            'Re-create Benchmark Fail!',
            'Pleas try again later',
            'error'
          );

          this.isRefreshing = false;
        }
      );
    }
  }

  get isInProgress() {
    if (
      this.isRefreshing
      // (this.cell && this.cell.data && this.cell.data.status === 'in_progress')
    ) {
      return true;
    }
    return false;
  }

  // Check Benchmark Generating Status
  getGenerateStatus(batchId: string) {
    if (!this.isFetchingBenchmarkStatus) {
      this.isFetchingBenchmarkStatus = true;
      this.subscribers.GENERATE_BENCHMARK_STATUS = this.benchmarksBatchService
        .generateBenchmarkStatus(batchId)
        .subscribe((result: any) => {
          if (result && result.data && result.data.status === 'completed') {
            this.stopGenerateStatusCheck();
            // update batch_id
            this.cell.node.setDataValue('batch_id', batchId);
          } else {
            this.benchmarkStatusFetchCount++;
          }
          this.isFetchingBenchmarkStatus = false;
        });
    }
  }

  // Start Interval for checking benchmark generating status
  startGenerateStatusCheck(batchId: string) {
    this.isRefreshing = true;
    this.getGenerateStatus(batchId);
    clearInterval(this.benchmarkStatusIntervals);
    this.benchmarkStatusIntervals = setInterval(() => {
      this.getGenerateStatus(batchId);
      if (this.benchmarkStatusFetchCount >= 20) {
        // 60 seconds passed
        this.stopGenerateStatusCheck();
      }
    }, 3000);
  }

  // stop Interval for checking benchmark generating status
  stopGenerateStatusCheck() {
    this.isRefreshing = false;
    clearInterval(this.benchmarkStatusIntervals);
    this.benchmarkStatusIntervals = null;
  }
}
