import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from '../reducers/user.reducer';

export const getUserState = createFeatureSelector<fromUser.UserState>('user');

export const getUserLocale = createSelector(getUserState, fromUser.getLocale);

export const getCurrentUserId = createSelector(getUserState, fromUser.getUserId);

export const getUserVariant = createSelector(getUserState, fromUser.getVariant);

export const getUsername = createSelector(getUserState, fromUser.getUsername);

export const getCompanyId = createSelector(getUserState, fromUser.getCompanyId);

export const getRoles = createSelector(getUserState, fromUser.getRoles);

export const getPermissions = createSelector(getUserState, fromUser.getPermissions);

export const getUserFirstname = createSelector(getUserState, fromUser.getFirstname);

export const getUserLastname = createSelector(getUserState, fromUser.getLastname);

export const getUserFullname = createSelector(getUserFirstname, getUserLastname, (firstname, lastname) =>
  ((firstname || '') + ' ' + (lastname || '')).trim()
);

export const getUserMainRole = createSelector(getUserState, fromUser.getMainRole);
