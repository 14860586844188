import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { TopFiveColdestRoomsComponent } from './top-five-coldest-rooms.component';

@NgModule({
  imports: [SharedModule],
  declarations: [TopFiveColdestRoomsComponent],
  entryComponents: [TopFiveColdestRoomsComponent]
})
export class TopFiveColdestRoomsModule {}
