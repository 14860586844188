import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  RuleCommand,
  CommandTypes,
  RuleEditorModes,
  DataSources,
  SelectItems,
  RuleTemplateCommand,
} from '../../models';
import { RulesService } from '../../services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sc-command-modal',
  templateUrl: './command-modal.component.html',
  styleUrls: ['./command-modal.component.scss'],
})
export class CommandModalComponent implements OnInit {
  _commandTypes = CommandTypes;
  commandTypes: any[];
  form: FormGroup;
  dataSources: DataSources = {};
  modalDescription: string;
  modalTitle: string;
  selectItems: SelectItems = {};
  selectedCommandType: string;
  step = 1;
  step2Context: { $implicit: string };
  viewMode: string;
  isTemplate: boolean;
  isFromScratch: boolean;
  templateCommands: RuleTemplateCommand[];

  private command: RuleCommand;

  constructor(
    private modalRef: DynamicDialogRef,
    private modalConfig: DynamicDialogConfig,
    private rulesService: RulesService
  ) {}

  ngOnInit(): void {
    if (this.modalConfig && this.modalConfig.data) {
      if (this.modalConfig.data.command) {
        this.command = this.modalConfig.data.command;
        this.selectedCommandType = this.command.type;
      }

      if (this.modalConfig.data.dataSources) {
        this.dataSources = this.modalConfig.data.dataSources;
      }

      if (this.modalConfig.data.selectItems) {
        this.selectItems = this.modalConfig.data.selectItems;
      }

      if (this.modalConfig.data.viewMode) {
        this.viewMode = this.modalConfig.data.viewMode;
      }

      if (this.modalConfig.data.isTemplate) {
        this.isTemplate = this.modalConfig.data.isTemplate;
      }

      if (this.modalConfig.data.isFromScratch) {
        this.isFromScratch = this.modalConfig.data.isFromScratch;
      }

      if (this.modalConfig.data.templateCommands) {
        this.templateCommands = this.modalConfig.data.templateCommands;
      }
    }

    // Set available commands
    this.commandTypes = [
      // Default commands
      { label: CommandTypes.TriggerDeviceLabel, value: CommandTypes.TriggerDevice },
      { label: CommandTypes.DeviceValueStorageLabel, value: CommandTypes.DeviceValueStorage },
      { label: CommandTypes.ReportLabel, value: CommandTypes.Report },
      { label: CommandTypes.UserTaskLabel, value: CommandTypes.UserTask },
      { label: CommandTypes.RuleBlockerLabel, value: CommandTypes.RuleBlocker },
    ];

    // // Expert mode only
    // if (this.viewMode === RuleEditorModes.Expert) {
    //   // Rule blocker command only shown in expert
    //   this.commandTypes.push({ label: CommandTypes.RuleBlockerLabel, value: CommandTypes.RuleBlocker });
    //   // Task queue command only shown in expert
    //   this.commandTypes.push({ label: CommandTypes.TaskQueueLabel, value: CommandTypes.TaskQueue });
    //   // Custom global command only shown in expert
    //   this.commandTypes.push({ label: CommandTypes.CustomGlobalLabel, value: CommandTypes.CustomGlobal });
    //   // ? temporary show in expert?
    //   this.commandTypes.push({ label: CommandTypes.NotifierLabel, value: CommandTypes.Notifier });
    //   this.commandTypes.push({ label: CommandTypes.ReportLabel, value: CommandTypes.Report });
    //   this.commandTypes.push({ label: CommandTypes.UserTaskLabel, value: CommandTypes.UserTask });
    // }

    // // Backend mode only
    // if (this.viewMode === RuleEditorModes.Backend) {
    //   // Notifier command only shown in backend
    //   this.commandTypes.push({ label: CommandTypes.NotifierLabel, value: CommandTypes.Notifier });
    //   this.commandTypes.push({ label: CommandTypes.ReportLabel, value: CommandTypes.Report });
    //   this.commandTypes.push({ label: CommandTypes.UserTaskLabel, value: CommandTypes.UserTask });
    // }

    // // Normal mode only
    // if (this.viewMode === RuleEditorModes.Normal) {
    // }

    // // Advanced mode only
    // if (this.viewMode === RuleEditorModes.Advanced) {
    // }

    // if (this.viewMode === RuleEditorModes.Integrator) {
    //   this.commandTypes = [
    //     { label: CommandTypes.TriggerDeviceLabel, value: CommandTypes.TriggerDevice },
    //     { label: CommandTypes.DeviceValueStorageLabel, value: CommandTypes.DeviceValueStorage },
    //     { label: CommandTypes.ReportLabel, value: CommandTypes.Report },
    //     { label: CommandTypes.UserTaskLabel, value: CommandTypes.UserTask },
    //   ];
    // }

    this.setModalTitle();
    this.setModalDescription();

    if ((Object.values(this._commandTypes) as string[]).indexOf(this.selectedCommandType) >= 0) {
      this.selectCommandType(this.selectedCommandType);
      this.form.patchValue(this.command);

      if (this.command) {
        if (this.command.modifiers) {
          this.rulesService.setModifierForm(this.form, this.command.modifiers);
        }

        if (this.command.variables && !this.command.custom_function_idx) {
          this.rulesService.setReportVariablesForm(this.form, this.command.variables);
        }

        if (this.command.variables && this.command.custom_function_idx) {
          this.rulesService.setFunctionVariablesForm(this.form, this.command.variables);
        }
      }
    }
  }

  private setModalTitle() {
    let title = '';
    if (this.step === 1) {
      title = 'CCP_RULE_NEW_COMMAND';
    } else if (this.step === 2) {
      if (this.selectedCommandType === CommandTypes.DeviceValueStorage) {
        title = 'CCP_RULE_DEVICE_VALUE_STORAGE_COMMAND';
      } else if (this.selectedCommandType === CommandTypes.TaskQueue) {
        title = 'CCP_RULE_TASK_QUEUE_COMMAND';
      } else if (this.selectedCommandType === CommandTypes.TriggerDevice) {
        title = 'CCP_RULE_TRIGGER_DEVICE_COMMAND';
      } else if (this.selectedCommandType === CommandTypes.RuleBlocker) {
        title = 'CCP_RULE_BLOCKER_COMMAND';
      } else if (this.selectedCommandType === CommandTypes.CustomGlobal) {
        title = 'CCP_RULE_CUSTOM_GLOBAL_COMMAND';
      } else if (this.selectedCommandType === CommandTypes.Notifier) {
        title = 'CCP_RULE_NOTIFIER_COMMAND';
      } else if (this.selectedCommandType === CommandTypes.Report) {
        title = 'CCP_RULE_REPORT_COMMAND';
      } else if (this.selectedCommandType === CommandTypes.UserTask) {
        title = 'CCP_RULE_USER_TASK_COMMAND';
      }
    }
    this.modalTitle = title;
  }

  private setModalDescription() {
    let description = '';
    if (this.step === 1) {
      description = 'CCP_RULE_NEW_COMMAND_DESCRIPTION';
    }
    this.modalDescription = description;
  }

  selectCommandType(event) {
    this.selectedCommandType = event;
    this.form = this.rulesService.initRuleCommandForm(this.selectedCommandType, this.isTemplate);
    // this.form.get('type').setValue(this.selectedCommandType);
    this.step2Context = { $implicit: this.selectedCommandType };
    this.step = 2;
    this.setModalTitle();
    this.setModalDescription();
  }

  onResetClick() {
    this.close({ action: 'reset' });
  }

  onOkClick() {
    let formValue = this.form.getRawValue();
    formValue = JSON.parse(JSON.stringify(formValue));
    this.close({ action: 'save', data: formValue });
  }

  close(result?: { action: string; data?: any }) {
    this.modalRef.close(result);
  }

  goBack() {
    this.step--;
  }
}
