<sc-table
  [columns]="columns"
  [config]="config"
  [dataset]="dataset"
  [formConfig]="formConfig"
  [icon]="'fa fa-users'"
  [toolbarItems]="toolbarItems"
  [title]="tableTitle | translate"
  (reloadData)="fetchDataset()"
  (siteModeChanged)="onSiteModeChanged($event)"
></sc-table>
