import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';

import { GridsterConfig } from 'angular-gridster2';
import { Widget } from '../models/page-section-widget';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromStore from '@app/store';

import { config } from '@app/config';

@Component({
  selector: 'sc-page-body',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./page-body.component.scss'],
  templateUrl: './page-body.component.html',
})
export class PageBodyComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  config: GridsterConfig;
  @Input()
  editMode: boolean;
  @Input()
  widgets: Widget[];
  @Output()
  deleteWidget = new EventEmitter();

  formattedWidgets: any[];
  gridViewHeight$: Observable<string>;
  loading: boolean;

  calcHeightTimeout: any;

  private componentDestroyed: Subject<any> = new Subject();

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.formattedWidgets = [];
    this.gridViewHeight$ = this.store.pipe(select(fromStore.getGridHeight));
    this.store.pipe(select(fromStore.getGridMinColumns), takeUntil(this.componentDestroyed)).subscribe((result) => {
      this.gridsterHeight();
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.widgets && changes.widgets.currentValue) {
      this.loading = true;
      const widgets = changes.widgets.currentValue;
      this.formattedWidgets = await widgets.map((widget) => ({
        ...widget,
        widgetHeight: widget.rows * config().gridConfig.fixedRowHeight + 10,
      }));
      this.gridsterHeight();
    }
    this.loading = false;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();

    clearTimeout(this.calcHeightTimeout);
  }

  get isSingleTableWidget() {
    if (this.formattedWidgets && this.formattedWidgets.length === 1) {
      return true;
    }
    return false;
  }

  gridsterHeight() {
    clearTimeout(this.calcHeightTimeout);
    this.calcHeightTimeout = setTimeout(() => {
      let last;
      let maxY = 0;
      let maxRowY = 0;
      const totalWidgets = this.formattedWidgets.length;
      for (let i = 0; i < totalWidgets; i++) {
        if (this.formattedWidgets[i].y < maxY || this.formattedWidgets[i].rows < maxRowY) {
          continue;
        }
        maxY = this.formattedWidgets[i].y;
        maxRowY = this.formattedWidgets[i].row;
        last = this.formattedWidgets[i];
      }

      if (last) {
        const rowHeight = config().gridConfig.fixedRowHeight;
        const totalRow = last.y + last.rows;
        const spacing = totalRow * 15;
        const gridHeight = rowHeight * totalRow + spacing + 'px';
        this.store.dispatch(new fromStore.SetGridHeight(gridHeight));
      }
    }, 1000);
  }
}
