import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TreeNode } from 'primeng/api';

import { FileManagerService } from '@sc/file-manager/file-manager.service';
import { FilesService } from '@widgets/files/files.service';

@Component({
  selector: 'sc-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class SCFileInputComponent implements OnInit, OnDestroy {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  warning: string;
  @Input()
  placeholder: string;

  fileTree: TreeNode[] = [];
  fileLoading: boolean;
  inputId: string;
  selectedFile: any;
  showBrowser: boolean;

  private subscribers: { [prop: string]: any } = {};

  constructor(
    private fileManagerService: FileManagerService,
    private filesService: FilesService,
  ) {}

  ngOnInit() {
    this.placeholder = this.placeholder || '';
    this.showLabel =
      typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-file-input_' + this.controlName + new Date().getTime();

    // pre-set value handling (edit mode)
    this.checkIsEditMode();
    this.retrieveFiles();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  private checkIsEditMode() {
    const currentValue = this.form.get(this.controlName).value;
    if (currentValue) {
      const key = currentValue.split('/');
      if (/https?/.test(key[0]) !== true) {
        const name = key.pop();
        const path = key.join('/');
        this.selectedFile = {
          key: currentValue,
          name,
          path
        };
      }
    }
  }

  openBrowser() {
    this.showBrowser = true;
  }

  onDeselectFile() {
    this.selectedFile = undefined;
    this.form.get(this.controlName).reset();
    this.form.markAsDirty();
  }

  onSelectFile(event) {
    this.selectedFile = event.data;
    this.form.get(this.controlName).setValue(this.selectedFile.key);
    this.form.markAsDirty();
  }

  private retrieveFiles(path: string = '', parentDir?: TreeNode) {
    if (this.fileLoading && this.subscribers.getFiles) {
      this.subscribers.getFiles.unsubscribe();
    }
    this.fileLoading = true;
    this.subscribers.getFiles = this.filesService.list(path).subscribe({
      next: (result: any) => {
        const files = this.fileManagerService.createTree(result.data);
        if (parentDir) {
          parentDir.children = files;
        } else {
          this.fileTree = files;
        }
        this.fileLoading = false;
      },
      error: (error: any) => {
        this.fileLoading = false;
      }
    });
  }

  onOpenFile(event) {
    this.retrieveFiles(event.path, event.file);
  }
}
