import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Component({
  selector: 'sc-photo-cell',
  templateUrl: 'photo-cell.component.html'
})
export class PhotoCellComponent implements ICellRendererAngularComp {
  cell: any;
  photo: string;

  constructor(private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
    this.photo = this.cell.valueFormatted || this.cell.value;

    const urlRegex = new RegExp('^(https?://)');
    if (this.photo && !urlRegex.test(this.photo)) {
      this.photo = `${config().s3Url}/${this.photo}`;
    }

    // const site = this.sharedService.selectedSite;
    // const urlRegex = new RegExp('^(http[s]?://)');
    // if (this.photo && !urlRegex.test(this.photo)) {
    //   this.photo = `${config().s3Url}/companies/${site.companyId}/${this.photo}`;
    // }
  }

  refresh(): boolean {
    return false;
  }
}
