import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as fromRoot from '@app/store';
import { Store } from '@ngrx/store';
@Component({
  selector: 'sc-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  @Input() username: string;
  @Output() logOut = new EventEmitter();
  @Output() viewMyAccount = new EventEmitter();

  opened: boolean;

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {}

  onMyAccountClicked() {
    this.store.dispatch(new fromRoot.SelectMenu({ selectedId: 'my_account' }));
    this.store.dispatch(new fromRoot.ExpandSettings({ expanded: false }));
    this.viewMyAccount.emit(this.username);
  }

  onLogOutClicked() {
    this.logOut.emit(this.username);
  }
}
