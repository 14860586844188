import * as fromAuth from '../actions/auth.action';
import * as fromUser from '../actions/user.action';
import * as fromInitial from '../actions/initial.action';

export interface UserState {
  companyId: number;
  firstname: string;
  lastname: string;
  locale: string;
  mainRole: string;
  permissions: string[];
  roles: string[];
  siteIds: number[];
  teamInvitation: any;
  teams: number[];
  username: string;
  userId: number;
  updating: boolean;
  updated: boolean;
  variant: string;
}

export const initialState = {
  companyId: null,
  firstname: null,
  lastname: null,
  locale: null,
  mainRole: null,
  permissions: null,
  roles: null,
  siteIds: null,
  teamInvitation: null,
  teams: null,
  username: null,
  userId: null,
  updating: false,
  updated: false,
  variant: null,
};

export function reducer(
  state = initialState,
  action: fromUser.UserAction | fromAuth.AuthAction | fromInitial.InitialDataAction
): UserState {
  switch (action.type) {
    case fromAuth.VERIFY_TWO_FACTOR_AUTH_SUCCESS:
    case fromAuth.VERIFY_USER_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        companyId: user.companyId,
        firstname: user.firstname,
        lastname: user.lastname,
        locale: user.locale,
        mainRole: user.mainRole,
        roles: user.scope,
        siteIds: user.siteIds,
        userId: user.id,
        username: user.username,
        variant: user.variant || 'scv4',
      };
    }

    case fromAuth.VERIFY_USER_2_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        companyId: user.companyId,
        firstname: user.firstname,
        lastname: user.lastname,
        locale: user.locale,
        mainRole: user.mainRole,
        userId: user.id,
        username: user.username,
        variant: user.variant || 'scv4',
      };
    }

    // case fromInitial.LOAD_INITIAL_DATA_SUCCESS: {
    //   const initialData = action.payload;
    //   const rolesSrc = [...initialData.roles];
    //   const roles = state.roles;
    //   let permissions = [];
    //   for (let i = 0; i < roles.length; i++) {
    //     for (let j = 0; j < rolesSrc.length; j++) {
    //       if (roles[i] === rolesSrc[j].name) {
    //         permissions = [...permissions, ...rolesSrc[j].permissions];
    //         break;
    //       }
    //     }
    //   }
    //   return {
    //     ...state,
    //     permissions,
    //   };
    // }

    case fromInitial.LOAD_INITIAL_DATA_SUCCESS: {
      const initialData = action.payload;
      const permissions = [];
      const roles = [];
      for (const data of initialData.roles) {
        roles.push(data.name);
        for (const permission of data.permissions) {
          permissions.push(permission);
        }
      }
      const teams = initialData.teams;
      const company = initialData.company;
      const companyId = (company && company.id) || null;
      const teamInvitation = initialData.teamInvitation;
      return {
        ...state,
        permissions,
        roles,
        teams,
        companyId,
        teamInvitation,
      };
    }

    case fromUser.UPDATE_USER: {
      return {
        ...state,
        updating: true,
      };
    }

    case fromUser.UPDATE_USER_FAIL: {
      return {
        ...state,
        updating: false,
        updated: false,
      };
    }

    case fromUser.UPDATE_USER_SUCCESS: {
      const locale = action.payload.locale;
      return {
        ...state,
        updating: false,
        updated: true,
        locale,
      };
    }

    case fromUser.UPDATE_USER_VARIANT: {
      const variant = action.payload;
      return { ...state, variant };
    }

    case fromUser.UPDATE_USER_COMPANY: {
      const companyId = action.payload;
      return { ...state, companyId };
    }

    case fromUser.ADD_USER_TEAMS: {
      const newTeams = action.payload;
      const teams = [...state.teams, ...newTeams];
      return { ...state, teams };
    }

    case fromUser.CLEAR_TEAM_INVITATION: {
      return { ...state, teamInvitation: null };
    }
  }

  return state;
}

export const getLocale = (state: UserState) => state.locale;
export const getUsername = (state: UserState) => state.username;
export const getUserId = (state: UserState) => state.userId;
export const getCompanyId = (state: UserState) => state.companyId;
export const getRoles = (state: UserState) => state.roles;
export const getPermissions = (state: UserState) => state.permissions;
export const getVariant = (state: UserState) => state.variant;
export const getMainRole = (state: UserState) => state.mainRole;
export const getFirstname = (state: UserState) => state.firstname;
export const getLastname = (state: UserState) => state.lastname;
