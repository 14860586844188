import { Action } from '@ngrx/store';

export const SET_GRID_HEIGHT = '[Pages] Set Grid Height';
export const SET_MIN_COLUMNS = '[Pages] Set Min Columns';

export class SetGridHeight implements Action {
  readonly type = SET_GRID_HEIGHT;
  constructor(public payload: string) {}
}

export class SetMinColumns implements Action {
  readonly type = SET_MIN_COLUMNS;
  constructor(public payload: number) {}
}

export type GridsAction = SetMinColumns | SetGridHeight;
