import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CeosIdentity } from '../models/ceos-identity';
import { CeosIdentityService } from '../services/ceos-identity.service';
import { SharedService } from '@shared/shared.service';
import { ColDef, GridOptions } from 'ag-grid';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AutomationFormComponent } from '../automation-form/automation-form.component';
import { AutomationActionCellComponent } from '../automation-action-cell/automation-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { SCTableService } from '@sc/table/table.service';
import { UsersService } from '@widgets/users/services/users.service';

@Component({
  selector: 'sc-automations-table',
  templateUrl: './automations-table.component.html',
  styleUrls: ['./automations-table.component.scss'],
})
export class AutomationsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: CeosIdentity[];
  table: GridOptions;
  tableTitle: string;

  private userId: number;
  private users: any[];
  private subscribers: { [key: string]: any } = {};

  constructor(
    private dialogService: DialogService,
    private ceosIdentityService: CeosIdentityService,
    private sharedService: SharedService,
    private usersService: UsersService,
    private tableService: SCTableService
  ) {}

  ngOnInit() {
    this.userId = this.sharedService.userId;
    this.initTable();
    this.fetchDataset();
    this.fetchUsers();
  }

  ngOnDestroy() {
    // unsubscribe all subscribers
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.fetchDataset = this.ceosIdentityService.getCeosIdentities().subscribe((result: any) => {
      this.dataset = result.data;
    });
  }

  private fetchUsers() {
    this.subscribers.fetchDataset = this.usersService.getUsersOf('all').subscribe((result: any) => {
      this.users = result.data;
      this.updateTableView();
    });
  }

  private checkOwner(params) {
    const ownerId = (params && params.value) || null;
    return this.userId === ownerId;
  }

  private initTable() {
    this.tableTitle = 'CEOS_IDENTITIES';
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        colId: 'used_by',
        headerName: 'USED_BY',
        field: 'used_by',
        width: 100,
      },
      {
        colId: 'owner_id',
        headerName: 'OWNER',
        field: 'owner_id',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
        valueFormatter: this.checkOwner.bind(this),
        width: 100,
      },
      {
        colId: 'env',
        headerName: 'ENV',
        field: 'env',
        width: 100,
      },
      {
        colId: 'created_at',
        headerName: 'CREATED_AT',
        field: 'created_at',
        suppressFilter: true,
        valueFormatter: (params) => this.sharedService.dateFormat(params.data.created_at),
        filterValueGetter: (params) => this.sharedService.dateFormat(params.data.created_at),
      },
      {
        colId: 'created_by',
        headerName: 'CREATED_BY',
        field: 'created_by',
        cellRenderer: (params) => {
          if (params.data && params.data.created_by && this.users && this.users.length) {
            const user = this.users.find((item) => item.id === params.data.created_by);
            if (user) {
              const name = ((user.firstname || '') + ' ' + (user.lastname || '')).trim();
              return `${name || user.username} (${user.email})`;
            }
          }
          return '';
        },
        width: 200,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: AutomationActionCellComponent,
        width: 50,
      },
    ];
  }

  onAfterTableInit(table: GridOptions) {
    this.table = table;
  }

  private updateTableView() {
    if (this.table && this.table.api) {
      this.table.api.redrawRows();
    }
  }

  openCreateForm() {
    const createForm: DynamicDialogRef = this.dialogService.open(AutomationFormComponent, {
      dismissableMask: true,
      showHeader: false,
      styleClass: 'no-padding-content',
    });

    this.subscribers.createFormClosed = createForm.onClose.subscribe((result) => {
      if (result && result.id && this.table) {
        this.tableService.addTableRow(this.table, [result]);
      }
    });
  }
}
