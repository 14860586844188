import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { GridOptions } from 'ag-grid';
import { SelectItem } from 'primeng/api';
import { Trash } from '../models/trash';

import { SharedService } from '@shared/shared.service';
import { SCTableService } from '@sc/table/table.service';
import { TrashService } from '../services/trash.service';

@Component({
  selector: 'sc-trash-table',
  templateUrl: './trash-table.component.html'
})
export class TrashTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: Trash[];
  columns: any[];
  gridOptions: GridOptions;
  dataTypes: SelectItem[];
  currentDataType: string;
  subscribers: { [key: string]: any };
  fetchingState: number;

  constructor(
    private sharedService: SharedService,
    private tableService: SCTableService,
    private trashService: TrashService
  ) {}

  ngOnInit() {
    this.subscribers = {};
    this.fetchingState = 0;
    this.fetchDataTypes();

    this.columns = [
      {
        headerName: 'ID',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        field: 'id'
        // valueFormatter: this.idValue,
      },
      {
        headerName: 'Description',
        valueFormatter: this.descriptionValue
      }
    ];

    this.gridOptions = this.tableService.initTable(this.columns);
    this.gridOptions.onGridReady = this.onTableReady.bind(this);
    this.gridOptions.context = { parentComponent: this };
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataTypes() {
    this.fetchingState++;
    this.subscribers.GET_DATA_TYPES = this.trashService
      .getDataTypes()
      .subscribe((result: any) => {
        this.dataTypes = result.data;
        this.fetchingState--;
      });
  }

  fetchData(dataType: string) {
    this.fetchingState++;
    this.subscribers.GET_DATA = this.trashService
      .getData(dataType)
      .subscribe((result: any) => {
        this.dataset = result.data;
        // ____ REMOVE EXISTED DATA AND ADD NEW DATA INTO TABLE
        this.tableService.addTableRow(this.gridOptions, this.dataset, true);
        this.fetchingState--;
      });
  }

  onTableReady(params) {
    // ____ RESIZE COLUMN TO FIT SCREEN
    params.api.sizeColumnsToFit();
  }

  // idValue(params) {
  //   if (params.data) {
  //     if (params.data.id) {
  //       return params.data.id;
  //     } else if (params.data.key) {
  //       return params.data.key;
  //     } else if (params.data.username) {
  //       return params.data.username;
  //     } else {
  //       return '(no ID)';
  //     }
  //   } else {
  //     return null;
  //   }
  // }

  descriptionValue(params) {
    if (params.data) {
      if (params.data.name) {
        return params.data.name;
      } else if (params.data.description) {
        return params.data.description;
      } else if (params.data.language) {
        return params.data.language;
      } else if (params.data.translationKey) {
        return params.data.translationKey;
      } else if (params.data.key) {
        return params.data.key;
      } else if (params.data.command) {
        return params.data.command;
      } else if (params.data.title) {
        return params.data.title;
      } else if (params.data.username) {
        return params.data.username;
      } else {
        return '(no description)';
      }
    } else {
      return null;
    }
  }

  changeDataType(event) {
    if (event.value) {
      this.currentDataType = event.value;
      this.fetchData(this.currentDataType);
    }
  }

  onClick(action: string) {
    const selected = this.gridOptions.api.getSelectedNodes();
    if (selected.length === 0) {
      this.sharedService.notify(
        'Trash',
        'Please select at least one row',
        'warn'
      );
    } else {
      selected.forEach(row => {
        if (row.data && row.data.id) {
          if (action === 'delete') {
            this.deleteData(row.data.id);
          } else if (action === 'restore') {
            this.restoreData(row.data.id);
          }
        }
      });
    }
  }

  deleteData(dataTypeId: number) {
    this.trashService
      .deleteData(this.currentDataType, dataTypeId)
      .subscribe((result: any) => {
        // ____ UPDATE TABLE
        this.tableService.deleteTableRow(this.gridOptions, dataTypeId);
        this.sharedService.notify('Trash', 'Delete success', 'success');
      });
  }

  restoreData(dataTypeId: number) {
    this.trashService
      .restoreData(this.currentDataType, dataTypeId)
      .subscribe((result: any) => {
        // ____ UPDATE TABLE
        this.tableService.deleteTableRow(this.gridOptions, dataTypeId);
        this.sharedService.notify('Trash', 'Restore success', 'success');
      });
  }
}
