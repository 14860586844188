<div class="file-toolbar">
  <ul class="toolbar-container">
    <li
      *ngFor="let tool of displayTools"
      class="toolbar-item"
      (click)="onToolClicked(tool)"
      [ngClass]="{'disabled': !isAllowAction(tool.key)}"
    >
      <span
        class="fa fa-{{ tool.icon }} fa-fw"
        pTooltip="{{ tool.label | translate }}"
        tooltipPosition="top"
        appendTo="body"
      ></span>
    </li>
  </ul>

  <div class="overlay-form" *ngIf="showNamePanel">
    <div class="ui-inputgroup">
      <input type="text" pInputText [formControl]="name" placeholder="e.g. filename.ext" />
      <button pButton type="button" class="ui-button-success" icon="fa fa-check" (click)="onNamingConfirm()" [disabled]="name?.invalid"></button>
      <button pButton type="button" class="white" icon="fa fa-times" (click)="showNamePanel=false"></button>
    </div>

    <div *ngIf="name?.invalid && !name?.pristine" class="red-text">
      <span *ngIf="name?.errors?.pattern">Only allow alphanumeric(A-Z, 0-9), underscore(_), and dot(.)</span>
      <span *ngIf="name?.errors?.required">Please enter the name!</span>
    </div>
  </div>
</div>

