import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  EventMultiLanguage,
  EventPushNotificationScheduler
} from '../models/event';

@Injectable()
export class EventFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initEventForm() {
    return this.formBuilder.group({
      // eventId: { value: null, disabled: true },
      id: { value: null, disabled: true },
      companyId: [{ value: null, disabled: true }, Validators.required],
      siteId: [{ value: null, disabled: true }, Validators.required],
      teaserImagePath: null,
      publishFrom: [null, Validators.required],
      publishTo: [null, Validators.required],
      isActive: false,
      isDeleted: false,
      teaserText: this.formBuilder.array([]),
      title: this.formBuilder.array([]),
      content: this.formBuilder.array([]),
      pushNotificationText: this.formBuilder.array([]),
      pushNotificationScheduler: this.formBuilder.array([])
    });
  }

  initEventPushNotificationSchedulerFormGroup() {
    return this.formBuilder.group({
      sendNotificationAt: [null, Validators.required],
      sendOnlyIfLocationIsOccupied: false
    });
  }

  setEventPushNotificationSchedulerFormArray(
    form: FormGroup,
    data: EventPushNotificationScheduler[]
  ) {
    const formGroups = data.map(item => {
      const fg = this.initEventPushNotificationSchedulerFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('pushNotificationScheduler', formArray);
  }

  initEventMultiLanguageFormGroup() {
    return this.formBuilder.group({
      locale: [null, Validators.required],
      text: [null, Validators.required],
      url: null
    });
  }

  setEventMultiLanguageFormArray(
    form: FormGroup,
    data: EventMultiLanguage[],
    controlName: string
  ) {
    const formGroups = data.map(item => {
      const fg = this.initEventMultiLanguageFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl(controlName, formArray);
  }
}
