import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { Section } from '../models/page-section';

import { sortBy } from 'lodash';

@Component({
  selector: 'sc-page-section-form',
  templateUrl: './page-section-form.component.html',
  styleUrls: ['./page-section-form.component.scss']
})
export class PageSectionFormComponent implements OnInit {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  errorMessage: string;
  form: FormGroup;
  templates: SelectItem[];
  templateSections: SelectItem[];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createSelectItems();
    this.initForm();
  }

  createSelectItems() {
    // ____ SECTION TEMPLATES
    let templates = [];
    if (this.data && this.data.pageTemplates) {
      // console.log(this.data.pageTemplates);
      templates = this.data.pageTemplates
        .filter(pt => pt.isActive && !pt.isDeleted)
        .map(pt => ({
          label: pt.name,
          value: pt.sections
        }));
    }
    this.templates = [{ label: 'Blank Template', value: '' }, ...templates];
    this.templates = sortBy(this.templates, 'label');
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      template: null,
      widgets: null
    });

    this.onSectionFormValueChange();
  }

  onSectionFormValueChange(): void {
    this.form.get('template').valueChanges.subscribe(val => {
      if (val) {
        // creating templateSections select options
        const templateSections = [];
        val.forEach(item => {
          templateSections.push({
            label: item.name,
            value: item.widgets
          });
        });

        // sorting the templateSection list by label
        this.templateSections = sortBy(templateSections, 'label');

        if (templateSections.length) {
          this.form.patchValue({ widgets: templateSections[0].value });
        }
      }
    });
  }

  createSection() {
    if (this.form.valid) {
      const newSection: Section = {
        sectionId: this.form.value.name
          .replace(/\s/gi, '_')
          .replace(/[^a-zA-Z0-9]/gi, ''),
        name: this.form.value.name,
        widgets: []
      };

      if (
        this.form.value.widgets &&
        this.form.value.widgets.length &&
        this.data.widgets
      ) {
        this.form.value.widgets.forEach(item => {
          for (let i = 0; i < this.data.widgets.length; i++) {
            if (item.widgetId === this.data.widgets[i].id) {
              newSection.widgets.push(this.data.widgets[i]);
              break;
            }
          }
        });
      }

      this.onClose.emit(newSection);
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
