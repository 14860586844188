<li class="menu-item">
  <a
    class="menu-item-content"
    (click)="onClicked(data)"
    [ngClass]="{
      active: highlight,
      collapsed: !sidebarExpanded,
      setting: isChildren
    }"
  >
    <div class="menu-border"></div>
    <span
      [ngClass]="{
        'menu-icon': !hasChildren && !isChildren && sidebarExpanded,
        'menu-icon-settings': hasChildren && sidebarExpanded,
        'menu-icon-light': !sidebarExpanded
      }"
    >
      <span [ngClass]="data?.icon"></span>
    </span>
    <span
      [ngClass]="{
        'menu-label': !hasChildren && !isChildren,
        'menu-label-settings': hasChildren
      }"
      *ngIf="sidebarExpanded"
    >
      {{ data?.label | translate }}
    </span>
    <span
      [ngClass]="{
        'menu-arrow': !expanded,
        'menu-arrow-expanded': expanded
      }"
      *ngIf="hasChildren && sidebarExpanded"
    >
      <img src="assets/images/angle-up.svg" />
    </span>
  </a>

  <ng-container *ngIf="hasChildren">
    <ul class="sub-menu" *ngIf="expanded">
      <ng-container *ngFor="let item of data?.children">
        <sc-menu-item
          [data]="item"
          [selected]="selected"
          [sidebarExpanded]="sidebarExpanded"
          (clicked)="onClicked($event, true)"
        ></sc-menu-item>
      </ng-container>
    </ul>
  </ng-container>
</li>
