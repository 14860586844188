<div class="automation-form">
  <div class="automation-form__header">
    <div class="automation-form__header__title">
      <span *ngIf="viewMode">
        {{ "AUTOMATION_IDENTITY_DETAILS" | translate }}
      </span>
      <span *ngIf="!viewMode">
        {{ "AUTOMATION_IDENTITY_NEW" | translate }}
      </span>
    </div>
  </div>

  <div *ngIf="fetchingState === 0; else loadingIndicator">
    <div class="automation-form__content">
      <div class="automation-form__content__description">
        <p class="m-0">{{ "AUTOMATION_FORM_DESCRIPTION1" | translate }}</p>
        <p class="m-0">{{ "AUTOMATION_FORM_DESCRIPTION2" | translate }}</p>
      </div>

      <div [formGroup]="form" *ngIf="form" class="ui-g">
        <div class="ui-g-12 ui-md-6">
          <sc-text-input
            controlName="local_mqtt_password"
            [form]="form"
            [label]="'AUTOMATION_FORM_CEOS_MANAGER_PASSWORD' | translate"
            [hasAction]="true"
            (actionClicked)="copyText('local_mqtt_password')"
          ></sc-text-input>
        </div>

        <div class="ui-g-12 ui-md-6">
          <sc-text-input
            controlName="wpa_psk"
            [form]="form"
            [label]="'AUTOMATION_FORM_WIFI_ACCESS_POINT_PASSWORD' | translate"
            [hasAction]="true"
            (actionClicked)="copyText('wpa_psk')"
          ></sc-text-input>
        </div>
      </div>

      <div class="ui-g" *ngIf="!viewMode">
        <div class="ui-g-12">
          <button
            [label]="'AUTOMATION_FORM_REGENERATE_BUTTON_LABEL' | translate"
            type="button"
            pButton
            (click)="generateData()"
          ></button>
        </div>
      </div>

      <div class="ui-g" *ngIf="errorMessage">
        <div class="ui-g-12">
          <sc-form-error [message]="errorMessage | translate"></sc-form-error>
        </div>
      </div>
    </div>
  </div>

  <div class="automation-form__footer">
    <button
      [label]="'CANCEL' | translate"
      type="button"
      pButton
      class="mr-3"
      (click)="dismissModal()"
      [disabled]="isSubmitting"
      class="ui-button-secondary"
    ></button>

    <sc-submit-button
      *ngIf="!viewMode"
      [label]="'CREATE' | translate"
      [disabled]="form?.invalid"
      [pending]="isSubmitting"
      (submitted)="submit()"
    ></sc-submit-button>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
