<div class="device-assign">
  <div>
    <button
      type="button"
      pButton
      class="ui-button-secondary"
      (click)="onCreateClicked()"
      [label]="'CREATE' | translate"
    ></button>
  </div>

  <div class="separator">{{ "OR" | translate }}</div>

  <div *ngIf="deviceControl" class="sc-flex device-selector">
    <p-dropdown
      [formControl]="deviceControl"
      [options]="selectItems?.devices"
      placeholder="{{ 'SELECT' | translate }} {{ 'DEVICE' | translate }}"
      filter="true"
      [style]="{ width: '100%' }"
      appendTo="body"
    ></p-dropdown>

    <button
      type="button"
      pButton
      label="{{ 'ASSIGN' | translate }}"
      class="ui-button-success"
      (click)="onSelectDevice()"
    ></button>
  </div>
</div>
