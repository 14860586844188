import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeModule } from '@app/prime/prime.module';
import { AuthModule } from '@app/auth/auth.module';
import { SCModalModule } from '@sc/modal/modal.module';
import { SCConfirmModule } from '@sc/confirm/confirm.module';

import { ActionButtonComponent } from './action-button.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({ extend: true, isolate: false }),
    PrimeModule,
    AuthModule,
    SCConfirmModule,
    SCModalModule,
  ],
  declarations: [ActionButtonComponent],
  entryComponents: [ActionButtonComponent],
  exports: [ActionButtonComponent],
})
export class SCActionButtonModule {}
