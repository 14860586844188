import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { Rule } from '@app/widgets/rules/models/rule';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-form',
  templateUrl: './rule-form.component.html',
  styleUrls: ['./rule-form.component.scss']
})
export class RuleFormComponent implements OnInit, OnDestroy {
  @Input()
  form: FormGroup;
  @Input()
  formId: number;
  @Input() data: {
    [key: string]: any;
    rawData: { [key: string]: any };
    selectItems: { [key: string]: SelectItem[] };
  };

  @Output() applyAllChecked = new EventEmitter<Rule>();

  applyAll: FormControl;

  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    // console.log('RuleForm:Init:', this.data);
    // create applyAll form only the first device of first location
    // if (this.data && this.data.firstLocation) {
    if (
      this.data &&
      (this.data.firstLocation || this.data.isFirstChildLocation)
    ) {
      this.applyAll = new FormControl(false);
      // watch applyAll
      this.subscribers.APPLY_ALL = this.applyAll.valueChanges.subscribe(
        this.onFormValueChange.bind(this)
      );

      // watch isActive
      this.subscribers.ACTIVE = this.form
        .get('isActive')
        .valueChanges.subscribe(this.onFormValueChange.bind(this));

      // watch startCommands
      this.subscribers.START_COMMANDS = this.form
        .get('startCommands')
        .valueChanges.subscribe(this.onFormValueChange.bind(this));

      // watch endCommands
      this.subscribers.END_COMMANDS = this.form
        .get('endCommands')
        .valueChanges.subscribe(this.onFormValueChange.bind(this));

      // watch startConditions
      this.subscribers.START_CONDITIONS = this.form
        .get('startConditions')
        .valueChanges.subscribe(this.onFormValueChange.bind(this));

      // watch endConditions
      this.subscribers.END_CONDITIONS = this.form
        .get('endConditions')
        .valueChanges.subscribe(this.onFormValueChange.bind(this));
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearTimeouts(this.timeouts);
  }

  get endCommandForms(): FormArray {
    return this.form.get('endCommands') as FormArray;
  }

  get startCommandForms(): FormArray {
    return this.form.get('startCommands') as FormArray;
  }

  get endConditionForms(): FormArray {
    return this.form.get('endConditions') as FormArray;
  }

  get startConditionForms(): FormArray {
    return this.form.get('startConditions') as FormArray;
  }

  private emitApplyAll() {
    clearTimeout(this.timeouts.APPLY_ALL);
    this.timeouts.APPLY_ALL = setTimeout(() => {
      this.applyAllChecked.emit(this.form.value);
    }, 1000);
  }

  private onFormValueChange(value: any) {
    // if apply all is checked, re-emit the event
    if (this.applyAll.value === true) {
      this.emitApplyAll();
    }
  }
}
