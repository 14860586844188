import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { LocationDetails } from '@widgets/locations/models/location-details';
import { LocationType } from '@widgets/locations/models/location-type';
import { LocationProperty } from '@widgets/location-properties/models/location-property';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceType } from '@app/widgets/device-types/models/device-type';

import { DeviceModelsService } from '@widgets/device-models/services/device-models.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-details-modal',
  templateUrl: './location-details-modal.component.html',
  styleUrls: ['./location-details-modal.component.scss'],
})
export class LocationDetailsModalComponent implements OnInit {
  currentMenu: string = 'info';
  devices: DeviceDetails[];
  locationProperties: LocationProperty[];
  locationTypes: LocationType[];
  selectedLocation: LocationDetails;
  summaryConfig: any;

  locationHistoryConfig: any;
  locationRulesConfig: any;
  locationRulesHistoryConfig: any;

  deviceModels: DeviceModel[];
  deviceModelEntities: { [key: string]: DeviceModel } = {};
  deviceTypes: DeviceType[];
  deviceTypeEntities: { [key: string]: DeviceType } = {};

  holderDevice: DeviceDetails;
  virtualDevices: DeviceDetails[];

  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private store: Store<fromStore.State>,
    private deviceTypesService: DeviceTypesService,
    private deviceModelsService: DeviceModelsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // console.log(this.config);
    if (this.config && this.config.data) {
      if (this.config.data.location) {
        this.selectedLocation = JSON.parse(JSON.stringify(this.config.data.location));
      }

      if (this.config.data.locationProperties) {
        this.locationProperties = this.config.data.locationProperties;
      }

      if (this.config.data.locationTypes) {
        this.locationTypes = this.config.data.locationTypes;
      }

      if (this.config.data.devices) {
        this.devices = this.config.data.devices;
      }

      if (this.config.data.summaryConfig) {
        this.summaryConfig = this.config.data.summaryConfig;
      }
    }

    this.subscribers.getWidgets = this.store.select(fromStore.getAllWidgets).subscribe((result) => {
      if (result && result.length) {
        for (const w of result) {
          if (w.key === 'location_history_table') {
            this.locationHistoryConfig = { ...w };
          } else if (w.key === 'location_rules_table') {
            this.locationRulesConfig = { ...w };
          } else if (w.key === 'location_rule_history_log_table') {
            this.locationRulesHistoryConfig = { ...w };
          }

          if (this.locationHistoryConfig && this.locationRulesConfig && this.locationRulesHistoryConfig) {
            break;
          }
        }
      }
    });

    // this.fetchDeviceTypes();
    // this.fetchDeviceModels();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  // private fetchDeviceTypes() {
  //   this.deviceTypes = [];
  //   this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
  //     this.deviceTypes = result.data.filter((d) => !d.isDeleted);
  //     this.deviceTypeEntities = this.deviceTypes.reduce((acc, curr) => {
  //       acc[curr.id] = curr;
  //       return acc;
  //     }, {}) as any;
  //   });
  // }

  // private fetchDeviceModels() {
  //   this.deviceModels = [];
  //   this.subscribers.fetchDeviceModels = this.deviceModelsService.getDeviceModels().subscribe((result: any) => {
  //     this.deviceModels = result.data.filter((d) => !d.isDeleted);
  //     this.deviceModelEntities = this.deviceModels.reduce((acc, curr) => {
  //       acc[curr.id] = curr;
  //       return acc;
  //     }, {}) as any;
  //   });
  // }

  openDeviceDetail(event) {
    this.dialog.close({ deviceId: event });
  }

  onSummaryConfigSaved(event) {
    this.summaryConfig = event;
    this.dialog.close({
      summaryConfig: this.summaryConfig,
    });
  }

  onViewDeviceDetails(event) {
    if (event) {
      this.holderDevice = event.holderDevice;
      this.virtualDevices = event.virtualDevices;
      this.currentMenu = 'deviceDetails';
    }
  }
}
