<sc-modal-trigger
  *scHasPermission="'device_u'"
  [modalConfig]="deviceForm"
  [buttonIcon]="'fas fa-pencil-alt'"
  buttonClass="ui-button-secondary"
  (onClose)="onUpdateSuccess($event)"
  pTooltip="{{ 'EDIT' | translate }}"
  tooltipPosition="top"
  appendTo="body"
></sc-modal-trigger>
