import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRuleBulk from '../reducers/rule-bulk.reducer';

export const selectRuleBulkState = createFeatureSelector<fromRuleBulk.State>('ruleBulk');

export const selectRuleBulkAutomationIdxs = createSelector(selectRuleBulkState, (state) => state.automationIdxs);
export const selectRuleBulkRuleTempalateIdx = createSelector(selectRuleBulkState, (state) => state.ruleTemplateIdx);
export const selectRuleBulkAllInfo = createSelector(
  selectRuleBulkAutomationIdxs,
  selectRuleBulkRuleTempalateIdx,
  (automationIdxs, ruleTemplateIdx) => ({ automationIdxs, ruleTemplateIdx })
);
