import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserRole } from '../models/user-role';
import { UserPermission } from '../models/user-permission';

import { UserRolesService } from '../services/user-roles.service';
import { UserPermissionsService } from '../services/user-permissions.service';
import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Component({
  selector: 'sc-user-role-form',
  templateUrl: 'user-role-form.component.html'
})
export class UserRoleFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: UserRole;
  form: FormGroup;
  errorMessage: string;
  editMode: boolean;
  patterns = config().regexPatterns;
  isSubmitting: boolean;
  subscribers: { [key: string]: any };
  fetchingState: number;

  permissionsSrc: UserPermission[];
  availablePermissions: UserPermission[];
  selectedPermissions: UserPermission[];

  constructor(
    private formBuilder: FormBuilder,
    private userRolesService: UserRolesService,
    private userPermissionsService: UserPermissionsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.subscribers = {};
    this.fetchingState = 0;
    this.permissionsSrc = [];
    this.availablePermissions = [];
    this.selectedPermissions = [];

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchRole(this.data.id);
    }

    this.fetchPermissions();
    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchRole(id: number) {
    this.fetchingState++;
    this.subscribers.GET_USER_ROLE = this.userRolesService
      .get(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.formData.permissions = this.formData.permissions
          ? this.sharedService.jsonParse(this.formData.permissions)
          : [];
        this.form.patchValue(this.formData);
        this.updateAvailablePermissions();
        this.fetchingState--;
      });
  }

  fetchPermissions() {
    this.fetchingState++;
    this.subscribers.GET_PERMISSIONS = this.userPermissionsService
      .get()
      .subscribe((result: any) => {
        this.permissionsSrc = result.data.filter(
          d => d.isActive && !d.isDeleted
        );
        this.updateAvailablePermissions();
        this.fetchingState--;
      });
  }

  updateAvailablePermissions() {
    if (this.editMode && this.availablePermissions && this.formData) {
      this.availablePermissions = [...this.permissionsSrc].filter(
        d => this.formData.permissions.indexOf(d.id) < 0
      );
      this.selectedPermissions = [...this.permissionsSrc].filter(
        d => this.formData.permissions.indexOf(d.id) >= 0
      );
    } else {
      this.availablePermissions = [...this.permissionsSrc];
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      // roleId: [
      //   null,
      //   Validators.compose([
      //     Validators.required,
      //     Validators.pattern(this.patterns.id)
      //   ])
      // ],
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      description: null,
      permissions: null,
      assignableByAdminOnly: false,
      isActive: false,
      isDeleted: false
    });

    // if (this.editMode) {
    //   this.form.get('roleId').disable();
    //   this.form.get('name').disable();
    // }
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = false;

      // UPDATE PERMISSIONS INTO THE FORM
      const permissions = this.selectedPermissions.map((p: any) => p.id);
      this.form.patchValue({ permissions });

      // call api
      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create() {
    const formData = this.form.value;
    this.userRolesService
      .create(formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update() {
    this.userRolesService
      .update(this.formData.id, this.form.value)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.userRolesService
      .delete(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify(
      'User Role',
      result.message + ' Success!',
      'success'
    );
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  onItemChange(event) {
    this.form.markAsDirty();
  }
}
