import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-device-parameter-form',
  templateUrl: './device-parameter-form.component.html',
  styleUrls: ['./device-parameter-form.component.scss']
})
export class DeviceParameterFormComponent implements OnInit {
  @Input()
  data: any;
  @Input()
  form: FormGroup;

  constructor() {}

  ngOnInit() {}

  get parametersForm() {
    return this.form.controls['parameters'] as FormArray;
  }
}
