<form [formGroup]="form" novalidate>
  <p-card>
    <p-header></p-header>

    <div class="ui-g">
      <!-- Section Name Input -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <!-- Template Select -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="template"
          [form]="form"
          label="{{ 'PAGE_TEMPLATE' | translate }}"
          [options]="templates"
        ></sc-dropdown>
      </div>

      <!-- Template Section Select -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="widgets"
          [form]="form"
          label="{{ 'SECTION' | translate }}"
          [options]="templateSections"
        ></sc-dropdown>
      </div>

      <sc-form-error [message]="errorMessage"></sc-form-error>
    </div>

    <p-footer>
      <div class="ui-g-12 page-section__footer">
        <button
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          pButton
          label="{{ 'CANCEL' | translate }}"
        ></button>
        <sc-submit-button
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          (submitted)="createSection()"
        ></sc-submit-button>
      </div>
    </p-footer>
  </p-card>
</form>
