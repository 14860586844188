<nav class="text-center">
  <sc-modal-trigger
    buttonIcon="fa fa-pencil"
    [modalConfig]="formConfig"
  ></sc-modal-trigger>
  <sc-confirm-trigger
    buttonIcon="fa fa-trash"
    (onAccept)="deleteRow()"
  ></sc-confirm-trigger>
</nav>
