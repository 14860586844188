<div class="sc-widget">
  <div class="sc-widget-header">
    <h4 class="sc-widget-title">
      {{ config?.titleText | translate }}
    </h4>
    <div class="sc-widget-menu">
      <sc-modal-trigger [modalConfig]="config.modalConfig">
        <a class="dashboard-action-btn">
          <i class="pi pi-eye"></i>
        </a>
      </sc-modal-trigger>
    </div>
  </div>

  <ng-container *ngIf="loading; then spinner; else content"></ng-container>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body dashboard-body">
    <div class="sc-flex justify-content-between w-100">
      <div class="sc-flex">
        <sc-square
          [customClass]="config.square.customClass"
          [icon]="config.square.icon"
          [primaryText]="config.square.primaryText"
          [secondaryText]="config.square.secondaryText"
        ></sc-square>
      </div>

      <div class="sc-flex grow-1 justify-content-end align-items-center">
        <small *ngIf="config?.subTitleText" class="location-text">
          {{ config.subTitleText | translate }}
        </small>
      </div>
    </div>

    <div class="sc-flex direction-column text-center w-100">
      <small
        class="location-text"
        pTooltip="{{ dataset.aboveAcceptable }} above acceptable of {{
          dataset.locations
        }} locations"
        appendTo="body"
        tooltipPosition="top"
      >
        {{ value | number: "1.2-2" }} %
      </small>

      <div class="percentage-bar">
        <div
          class="percentage-bar__value"
          [ngClass]="config?.square?.customClass"
          [ngStyle]="{
            'width.%': value > 0 ? value : 0,
            display: value > 0 ? 'block' : 'none'
          }"
        ></div>
      </div>
    </div>

    <div class="sc-flex align-items-center justify-content-around w-100">
      <h3 class="text-center m-0 location-status location-text">
        <span class="badge item-standard">
          {{ dataset.occupied | number: "1.0-0" }}
        </span>
        <span>{{ "OCCUPIED" | translate }}</span>
      </h3>

      <h3 class="text-center m-0 location-status location-text">
        <span class="badge item-standard">
          {{ dataset.unoccupied | number: "1.0-0" }}
        </span>
        <span>{{ "UNOCCUPIED" | translate }}</span>
      </h3>
    </div>
  </div>
</ng-template>
