import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {
  @Output()
  clicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onClicked() {
    this.clicked.emit();
  }
}
