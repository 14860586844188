import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { SharedService } from '@shared/shared.service';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-virtual-device-holders-table',
  templateUrl: './virtual-device-holders-table.component.html',
  styleUrls: ['./virtual-device-holders-table.component.scss'],
})
export class VirtualDeviceHoldersTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: DeviceDetails[];
  table: GridOptions;
  tableTitle: string;
  toolbarItems: string[];

  isFetchingDataset = true;

  private selectedDevice: DeviceDetails;
  private subscribers: { [key: string]: any } = {};

  constructor(private store: Store<fromStore.State>, private sharedService: SharedService) {}

  ngOnInit() {
    // Set Table Name
    this.tableTitle = 'CHILDREN';
    // Set Table Toolbar Items
    this.toolbarItems = ['filter', 'refresh', 'columns'];
    // Set Table Columns
    this.columns = this.createColumns();

    // get device details
    this.subscribers.DEVICE_DETAILS = this.store.select(fromStore.getDeviceDetials).subscribe((result) => {
      if (result) {
        this.selectedDevice = result;

        if (this.selectedDevice.children) {
          this.getVirtualDeviceHolders(this.selectedDevice.children);
        }

        this.isFetchingDataset = false;
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDetails() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.store.dispatch(new fromStore.LoadDevice(this.selectedDevice.id));
    }
  }

  private getVirtualDeviceHolders(devices: DeviceDetails[]) {
    const vdh = [];
    for (let i = 0; i < devices.length; i++) {
      if (devices[i] && devices[i].deviceTypeKey === 'vdh') {
        vdh.push(devices[i]);
      }
    }
    this.dataset = vdh;
  }

  private createColumns() {
    return [
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
      },
      {
        colId: 'location',
        headerName: 'LOCATION',
        field: 'location',
      },
      {
        colId: 'deviceType',
        headerName: 'DEVICE_TYPE',
        field: 'deviceType',
      },
      {
        colId: 'deviceModel',
        headerName: 'DEVICE_MODEL',
        field: 'deviceModel',
      },
      {
        colId: 'uuid',
        headerName: 'NODE_ID',
        field: 'uuid',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
    ];
  }
}
