import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromWidgetCategories from '../reducers/widget-categories.reducer';

export const getWidgetCategoriesState = createFeatureSelector<fromWidgetCategories.WidgetCategoriesState>(
  'widgetCategories'
);

export const getWidgetCategoriesEntities = createSelector(
  getWidgetCategoriesState,
  fromWidgetCategories.getWidgetsEntities
);

export const getAllWidgetCategories = createSelector(getWidgetCategoriesEntities, (entities) =>
  entities ? Object.keys(entities).map((id) => entities[id]) : []
);

export const getWidgetCategoriesLoaded = createSelector(
  getWidgetCategoriesState,
  fromWidgetCategories.getWidgetsLoaded
);

export const getWidgetCategoriesLoading = createSelector(
  getWidgetCategoriesState,
  fromWidgetCategories.getWidgetsLoading
);
