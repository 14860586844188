import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Translation } from '../translation.interface';

import { TranslationsService } from '../translations.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-translation-form',
  templateUrl: 'translation-form.component.html'
})
export class TranslationFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: Translation;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  isSubmitting: boolean;
  fetching: { [key: string]: any };
  fetchingState: number;

  existLocales: string[];
  locales: any;

  constructor(
    private formBuilder: FormBuilder,
    private translationsService: TranslationsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetching = {};
    this.fetchingState = 0;
    this.locales = this.sharedService.sourceData.locales;

    if (this.data) {
      this.editMode = true;
      this.fetchTranslation(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.fetching) {
      if (this.fetching.hasOwnProperty(key) && this.fetching[key]) {
        this.fetching[key].unsubscribe();
      }
    }
  }

  fetchTranslation(id: number) {
    this.fetchingState++;
    this.fetching['FORM_DATA'] = this.translationsService
      .getTranslation(id)
      .subscribe((result: any) => {
        result.data.translationValues = JSON.parse(
          result.data.translationValues
        );
        this.formData = result.data;
        this.formData = this.formDataStringUnescape(this.formData);

        this.form.patchValue(this.formData);

        this.translationsService.setValueFormArray(
          this.form,
          this.formData.translationValues
        );

        this.existLocales = result.data.translationValues.map(v => v.locale);
        this.locales.forEach(item => {
          if (item.isActive) {
            if (this.existLocales.indexOf(item.id) === -1) {
              this.addValue(item.id);
            }
          }
        });
        this.fetchingState--;
      });
  }

  addExistLocales() {
    if (this.editMode !== true) {
      this.locales.forEach(item => {
        if (item.isActive) {
          this.addValue(item.id);
        }
      });
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      // translationId: [{ value: null, disabled: true }],
      id: { value: null, disabled: true },
      translationKey: [null, Validators.required],
      translationValues: this.formBuilder.array([], Validators.required),
      isActive: false,
      isDeleted: false
    });

    if (!this.editMode) {
      this.addExistLocales();
    }
  }

  // ____ LOCALE FORM ARRAY HANDLER
  get valueFormArray(): FormArray {
    return this.form.get('translationValues') as FormArray;
  }

  addValue(locale?: string) {
    const control = <FormArray>this.form.controls['translationValues'];
    control.push(this.translationsService.initValueFormGroup(locale));
  }

  formDataStringEscape(formData) {
    if (!formData.translationValues || !formData.translationValues.length) {
      return formData;
    }

    for (let i = 0; i < formData.translationValues.length; i++) {
      if (formData.translationValues[i].value) {
        formData.translationValues[i].value = this.sharedService.stringEscape(
          formData.translationValues[i].value
        );
      }
    }

    return formData;
  }

  formDataStringUnescape(formData) {
    if (!formData.translationValues || !formData.translationValues.length) {
      return formData;
    }

    for (let i = 0; i < formData.translationValues.length; i++) {
      if (formData.translationValues[i].value) {
        formData.translationValues[i].value = this.sharedService.stringUnescape(
          formData.translationValues[i].value
        );
      }
    }

    return formData;
  }

  // ____ FORM SUBMIT HANDLER
  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.formDataStringEscape(this.form.value);

      // ____ API CALL
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(formData) {
    this.translationsService
      .createTranslation(formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update(formData) {
    this.translationsService
      .updateTranslation(this.formData.id, formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.translationsService
      .deleteTranslation(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify('Success', result.message, 'success');
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
