<div [formGroup]="form">
  <sc-field-label
    *ngIf="showLabel"
    [for]="inputId"
    [fieldControl]="form.controls[controlName]"
    [title]="label"
    [description]="description"
  ></sc-field-label>

  <div *ngIf="!selectedFile" class="sc-file-unselected-wrapper">
    <input
      type="file"
      [id]="inputId"
      class="sc-file-input"
      (change)="onFileChange($event)"
      multiple="false"
    />
    <label [for]="inputId" class="sc-browser-trigger">
      <i class="fa fa-folder-open fa-fw"></i>
      {{ 'CHOOSE_FILE' | translate }}
    </label>
  </div>

  <div *ngIf="selectedFile" class="sc-file-selected-wrapper px-1">
    <div class="sc-file-name">
      <i class="fa fa-file fa-fw grey-text"></i> {{ selectedFile }}
    </div>

    <div class="sc-file-action">
      <i
        class="fa fa-times fa-fw sc-clickable red-text"
        (click)="deselect()"
      ></i>
    </div>
  </div>

  <sc-field-error
    [for]="label"
    [fieldControl]="form.controls[controlName]"
  ></sc-field-error>
</div>
