import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomFunction } from '../models/custom-function';
import { CustomFunctionFormService } from '../services/custom-function-form.service';
import { CustomFunctionsService } from '../services/custom-functions.service';
import { SharedService } from '@shared/shared.service';
import { Site } from '@app/widgets/sites/models/site';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { CustomFunctionParameterTypes } from '../enums/custom-function-parameter-type';
import { CustomFunctionParameter } from '../models/custom-function-parameter';

@Component({
  selector: 'sc-custom-function-form',
  templateUrl: './custom-function-form.component.html',
  styleUrls: ['./custom-function-form.component.scss'],
})
export class CustomFunctionFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: CustomFunction;
  isSubmitting = false;

  dataSources: any = {};
  selectItems: any = {};
  syntaxEditorOptions: any;

  private scaffold: any;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private customFunctionFormService: CustomFunctionFormService,
    private customFunctionsService: CustomFunctionsService,
    private devicesService: DevicesService,
    private locationsService: LocationsService
  ) {}

  ngOnInit(): void {
    this.selectedSite = this.sharedService.selectedSite;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchCustomFunction(this.data.id);
    }

    // this.fetchDevices();
    // this.fetchLocations();
    // this.fetchCustomGlobals();
    this.fetchCeosScaffold();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchCustomFunction(id: number) {
    this.fetchingState++;
    this.subscribers.fetchCustomFunction = this.customFunctionsService
      .getCustomFunction(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.formData.parameters = this.sharedService.tryParseJSON(this.formData.parameters as string);
        this.form.patchValue(this.formData);

        if (this.formData.parameters && this.formData.parameters.length) {
          this.customFunctionFormService.setCustomFunctionParameterForm(
            this.form,
            this.formData.parameters as CustomFunctionParameter[]
          );
        }
        this.fetchingState--;
      });
  }

  // private fetchDevices() {
  //   this.fetchingState++;
  //   const options = {
  //     siteId: this.selectedSite.id,
  //   };
  //   this.subscribers.fetchDevices = this.devicesService
  //     .getDevices(options)

  //     .subscribe((result: any) => {
  //       this.dataSources.devices = result.data;
  //       this.selectItems.devices = this.sharedService.createSelectItems(
  //         result.data.filter((item) => item.isActive && !item.isDeleted),
  //         false,
  //         'idx'
  //       );
  //       this.fetchingState--;
  //     });
  // }

  // private fetchLocations() {
  //   this.fetchingState++;
  //   const options = {
  //     companyId: this.selectedSite.companyId,
  //     siteId: this.selectedSite.id,
  //     columns: 'id,locationTypeId,description,isActive,isDeleted',
  //   };
  //   this.subscribers.getLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
  //     this.selectItems.locations = this.sharedService.createSelectItems(
  //       result.data.filter((item) => item.isActive && !item.isDeleted),
  //       false,
  //       'idx'
  //     );
  //     this.fetchingState--;
  //   });
  // }

  // private fetchCustomGlobals() {
  // this.fetchingState++;
  // this.subscribers.fetchCustomFunction = this.customFunctionsService
  //   .getCustomFunction(id)
  //   .subscribe((result: any) => {
  //     this.selectItems.customGlobals = this.sharedService.createSelectItems(
  //       result.data.filter((item) => item.isActive && !item.isDeleted),
  //       false,
  //       'idx',
  //     );
  //     this.fetchingState--;
  //   });
  // }

  private fetchCeosScaffold() {
    this.fetchingState++;
    this.subscribers.getCeosScaffold = this.sharedService.getCeosScaffold().subscribe((result: any) => {
      // console.log(result.data);
      this.scaffold = result.data;

      this.dataSources.deviceTypes = Object.keys(this.scaffold.device_types).map((key) => ({
        ...this.scaffold.device_types[key],
        key,
      }));
      // console.log('dataSources.deviceTypes:', this.dataSources.deviceTypes);
      this.selectItems.deviceTypes = this.sharedService.createSelectItems(this.dataSources.deviceTypes, false, 'key');
      // console.log('selectItems.deviceTypes:', this.selectItems.deviceTypes);

      this.dataSources.locationProperties = Object.keys(this.scaffold.location_properties).map((key) => ({
        ...this.scaffold.location_properties[key],
        key,
      }));
      // console.log('dataSources.locationProperties:', this.dataSources.locationProperties);
      this.selectItems.locationProperties = this.sharedService.createSelectItems(
        this.dataSources.locationProperties,
        false,
        'key'
      );
      // console.log('selectItems.locationProperties:', this.selectItems.locationProperties);

      this.fetchingState--;
    });
  }

  private initForm() {
    this.syntaxEditorOptions = {
      lineNumbers: true,
      mode: 'python',
      theme: 'neat',
    };

    this.selectItems = {
      customGlobals: [],
      returnTypes: this.sharedService.selectItems.returnTypes,
    };

    this.selectItems.customFunctionParameterTypes = [
      {
        label: CustomFunctionParameterTypes.CustomGlobalValueLabel,
        value: CustomFunctionParameterTypes.CustomGlobalValue,
      },
      {
        label: CustomFunctionParameterTypes.LatestDhlValueLabel,
        value: CustomFunctionParameterTypes.LatestDhlValue,
      },
      {
        label: CustomFunctionParameterTypes.LatestLhlValueLabel,
        value: CustomFunctionParameterTypes.LatestLhlValue,
      },
    ];

    this.form = this.customFunctionFormService.initCustomFunctionForm();
  }

  // API CALL
  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.value;

    // call api
    if (this.editMode) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  }

  private create(formData) {
    this.subscribers.create = this.customFunctionsService.createCustomFunction(formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(formData) {
    this.subscribers.update = this.customFunctionsService.updateCustomFunction(this.formData.id, formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.customFunctionsService.deleteCustomFunction(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('CUSTOM_FUNCTION');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }
}
