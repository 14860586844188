<span
  class="badge grey"
  pTooltip="{{ 'REQUESTED_TIME' | translate }}"
  tooltipPosition="top"
  appendTo="body"
>
  {{ dateFormat(cell.data.createdAt) }}
</span>

<span
  class="badge"
  [ngClass]="statusColor"
  pTooltip="{{ 'REACTION_TIME' | translate }}"
  tooltipPosition="top"
  appendTo="body"
  *ngIf="statusTime"
>
  {{ statusTime }}
</span>
