import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ModalConfig } from '@sc/modal/modal-config';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-energy-efficiency-rating-action-cell',
  templateUrl: 'action-cell.component.html'
})
export class EnergyEfficiencyRatingActionCellComponent
  implements ICellRendererAngularComp {
  cell: any;
  viewMoreModalConfig: ModalConfig;

  constructor(private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
    this.viewMoreModalConfig = {
      name: 'EnergyEfficiencyRatingDetailModal',
      data: this.cell.data,
      options: { modalTitle: 'ENERGY_EFFICIENCY_RATING_DETAIL_MODAL_TITLE' }
    };
  }

  refresh(): boolean {
    return false;
  }
}
