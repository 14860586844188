import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { CeosRulesService } from '../services/ceos-rules.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-automation-rule-action-cell',
  templateUrl: './automation-rule-action-cell.component.html',
})
export class AutomationRuleActionCellComponent implements ICellRendererAngularComp {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  constructor(private ceosRuleService: CeosRulesService, private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'CeosRuleForm',
      data: this.cell.data,
      // data: {
      // value: this.cell.data
      // optionData: this.cell.context.optionData
      // },
      options: { modalTitle: 'RULE_FORM_TITLE', fullScreen: true },
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: '_u',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this),
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];
  }

  refresh(): boolean {
    return false;
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.ceosRuleService.deleteCeosRule(this.cell.data.id).subscribe(
      (response: any) => {
        this.cell.context.parentComponent.updateRow({
          id: this.cell.data.id,
          is_active: false,
          is_deleted: true,
        });
        const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
        const title = this.sharedService.getTranslation('RULE');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const text = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('RULE');
        this.sharedService.notify(title, text, 'error');
      }
    );
  }

  get cellData() {
    return this.cell && this.cell.data;
  }
}
