<div class="editable" [formGroup]="form">
  <div class="data-preview" *ngIf="mode === 'view'">
    {{ value }}
  </div>

  <div class="data-edit" *ngIf="mode !== 'view'">
    <input [type]="type" [formControlName]="field" class="data-input" />
  </div>

  <div class="actions">
    <ng-container *ngIf="mode === 'view'">
      <button type="button" class="action-button" (click)="changeMode('edit')" [disabled]="disabled">
        <i class="fas fa-fw fa-pencil-alt"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="mode !== 'view'">
      <!-- Save -->
      <button type="button" class="action-button" (click)="save()" [disabled]="!canSave">
        <i class="fas fa-fw fa-check green-text"></i>
      </button>

      <!-- Cancel -->
      <button type="button" class="action-button" (click)="cancel()" *ngIf="mode === 'edit'">
        <i class="fas fa-fw fa-times"></i>
      </button>

      <!-- Delete -->
      <button type="button" class="action-button" (click)="delete()">
        <i class="fas fa-fw fa-trash red-text"></i>
      </button>
    </ng-container>
  </div>
</div>
