import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'sc-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent implements OnInit {
  @Input()
  options: TreeNode[];
  @Input()
  control: FormControl;
  @Input()
  form: FormGroup;
  @Input()
  field: string;
  @Input()
  placeholder: string;
  @Input()
  maxSelectedDisplay: number;
  @Input()
  mode: 'single' | 'multiple' | 'checkbox' = 'single';
  @Input()
  valueKey: boolean | string = 'idx';
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  warning: string;

  inputId: string;
  selected: TreeNode | TreeNode[];
  displayItems: any[];
  selectionMode: string;

  constructor() {}

  ngOnInit() {
    this.maxSelectedDisplay = this.maxSelectedDisplay || 3;
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-tree-multi-select-' + this.field + new Date().getTime();
    this.selectionMode = this.mode === 'multiple' ? 'checkbox' : this.mode;

    this.updateDisplayItems();
  }

  nodeSelect(event, overlayPanel) {
    this.displayItems = [];

    // get selected values
    let selectedData;
    let selectedValue;
    if (this.selectionMode === 'single') {
      overlayPanel.hide();
      selectedData = this.selected as TreeNode;
      if (this.valueKey !== false && selectedData.data[this.valueKey as string]) {
        selectedValue = selectedData.data[this.valueKey as string];
      } else {
        selectedValue = selectedData;
      }

      // set display items
      this.displayItems.push(selectedData.label);
    } else {
      selectedData = this.selected as TreeNode[];
      selectedValue = [];

      // set display items
      for (let i = 0; i < selectedData.length; i++) {
        const val = selectedData[i].data[this.valueKey as string];
        if (this.valueKey !== false && val) {
          selectedValue.push(val);
        } else {
          selectedValue.push(selectedData[i]);
        }

        this.displayItems.push(selectedData[i].label);
      }
    }

    // set the FormGroup or FormControl value if exists
    if (selectedValue) {
      if (this.form && this.field) {
        this.form.get(this.field).setValue(selectedValue);
      } else if (this.control) {
        this.control.setValue(selectedValue);
      }
    }
  }

  private updateDisplayItems() {
    let selectedValue;

    if (this.form && this.field) {
      selectedValue = this.form.get(this.field).value;
    } else if (this.control) {
      selectedValue = this.control.value;
    }

    if (selectedValue) {
      if (this.mode === 'single') {
        if (this.valueKey === false) {
          this.selected = selectedValue;
          this.displayItems = [selectedValue.label];
        } else {
          this.getOptionFromValue(this.options, selectedValue, (option) => {
            if (option) {
              this.selected = option;
              this.displayItems = [option.label];
            }
          });
        }
      } else {
        this.selected = [];
        this.displayItems = [];

        for (const item of selectedValue) {
          if (this.valueKey === false) {
            this.selected.push(item);
            this.displayItems.push(item.label);
          } else {
            this.getOptionFromValue(this.options, item, (option: TreeNode) => {
              if (option) {
                (this.selected as TreeNode[]).push(option);
                this.displayItems.push(option.label);
              }
            });
          }
        }
      }
    }
  }

  private getOptionFromValue(options, value, callback) {
    for (const item of options) {
      if (item.data && item.data[this.valueKey as string] === value) {
        return callback(item);
      }

      if (item.children && item.children.length) {
        this.getOptionFromValue(item.children, value, callback);
      }
    }
  }
}
