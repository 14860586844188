import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ChildrenOutletContexts } from '@angular/router';
import { SharedService } from '@app/shared/shared.service';
import { Location } from '@widgets/locations/models/location';

@Component({
  selector: 'sc-floor-form',
  templateUrl: './floor-form.component.html',
  styleUrls: ['./floor-form.component.scss'],
})
export class FloorFormComponent implements OnInit {
  @Input() floor: Location;

  floorUnits: Location[] = [];
  form: FormArray;
  nbRooms: number = null;

  @Output() onAddUnit: EventEmitter<Location> = new EventEmitter();
  @Output() onRemoveUnit: EventEmitter<Location> = new EventEmitter();
  @Output() onUpdateUnit: EventEmitter<Location> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = new FormArray([]);
  }

  addUnit(floor: Location): void {
    if (this.nbRooms !== null) {
      this.nbRooms++;
    } else {
      if (floor.children) {
        const children: Location[] = floor.children.filter((child: Location) => child.isActive && !child.isDeleted);
        children.length > 0 ? (this.nbRooms = children.length + 1) : (this.nbRooms = 1);
      } else {
        this.nbRooms = 1;
      }
    }

    const newUnit = {
      key: floor.floorNumber + '_' + new Date().getTime(),
      description: `${this.sharedService.getTranslation('ROOM')} ${floor.floorNumber}${
        this.nbRooms < 10 ? '0' + this.nbRooms : this.nbRooms
      }`,
      locationTypeId: 3,
      parentId: floor.id,
      parentIdx: floor.idx,
      isActive: true,
      isDeleted: false,
      floorNumber: floor.floorNumber,
    };
    this.form.push(this.formBuilder.group(newUnit));
    this.onAddUnit.emit(newUnit);
  }

  removeUnit(index: number) {
    this.onRemoveUnit.emit(this.form.getRawValue()[index]);
    this.form.removeAt(index);
  }

  updateUnit(index: number) {
    this.onUpdateUnit.emit(this.form.getRawValue()[index]);
  }
}
