import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { RuleTemplate } from '../models/rule-template';
import { ModalConfig } from '@sc/modal/modal-config';

import { RuleTemplatesService } from '../services/rule-templates.service';

import { RuleTemplateActionCellComponent } from '../rule-template-action-cell/rule-template-action-cell.component';
import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-rule-templates-table',
  templateUrl: 'rule-templates-table.component.html'
})
export class RuleTemplatesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: RuleTemplate[];
  subscribes: { [key: string]: any };
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(private ruleTemplatesService: RuleTemplatesService) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'Name',
        field: 'name'
      },
      {
        colId: 'purposeEnergySaving',
        headerName: 'PurposeEnergySaving',
        field: 'purposeEnergySaving',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isPublic',
        headerName: 'Public',
        field: 'isPublic',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isActive',
        headerName: 'Active',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'Deleted',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'Action',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: RuleTemplateActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'RuleTemplateForm',
      options: { modalTitle: 'Rule Template', fullScreen: true }
    };
    this.tableTitle = 'RULE_TEMPLATES';
    this.subscribes = {};

    this.fetchDataset();
  }

  ngOnDestroy() {
    for (const key in this.subscribes) {
      if (this.subscribes.hasOwnProperty(key) && this.subscribes[key]) {
        this.subscribes[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.subscribes.GET_DATASET = this.ruleTemplatesService
      .getRuleTemplates()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
