<ng-container *ngIf="cellValue?.length">
  <span *ngFor="let item of cellValue">
    <span
      [class]="flag(item.locale)"
      [pTooltip]="item.text"
      tooltipPosition="top"
      appendTo="body"
    ></span>
  </span>
</ng-container>
