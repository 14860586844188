import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { SettingWidget } from '../models/setting-widget';
import { SettingWidgetsService } from '../services/setting-widgets.service';
import { SharedService } from '@shared/shared.service';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { SettingWidgetActionCellComponent } from '../setting-widget-action-cell/setting-widget-action-cell.component';
import { config } from '@app/config';

@Component({
  selector: 'sc-setting-widgets-table',
  templateUrl: 'setting-widgets-table.component.html',
})
export class SettingWidgetsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: SettingWidget[];
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string = 'WIDGETS';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private settingWidgetsService: SettingWidgetsService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.initTable();
    this.fetchDataset();
  }

  ngOnDestroy() {
    // unsubscribe all subscribers
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.fetchDataset = this.settingWidgetsService.getWidgets().subscribe((result: any) => {
      this.dataset = result.data;
    });
  }

  private createColumns() {
    return [
      {
        colId: 'icon',
        headerName: 'ICON',
        field: 'icon',
        cellRenderer: this.iconCellRenderer.bind(this),
        suppressFilter: true,
        suppressSorting: true,
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
        cellRenderer: this.dataCellRenderer.bind(this),
        valueFormatter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'name', true),
        filterValueGetter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'name', true),
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
        cellRenderer: this.dataCellRenderer.bind(this),
        valueFormatter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'description', true),
        filterValueGetter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'description', true),
      },
      {
        colId: 'type',
        headerName: 'TYPE',
        field: 'type',
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: SettingWidgetActionCellComponent,
      },
    ];
  }

  private initTable() {
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'SettingWidgetForm',
      options: { modalTitle: 'WIDGET_FORM_TITLE' },
    };
  }

  afterTableInit(table: GridOptions) {
    this.table = table;

    this.table.getRowHeight = (params) => 35;

    // update table content when user selected language changed
    this.subscribers.languageChange = this.translateService.onLangChange.subscribe((event) => {
      if (this.table && this.table.api) {
        this.table.api.redrawRows();
      }
    });
  }

  private dataCellRenderer(params: any) {
    const value = this.sharedService.tableMultiLanguagesFormatter(params);
    return '<span>' + value + '</span>';
  }

  private iconCellRenderer(params: any) {
    if (params.value) {
      const iconUrl = `${config().s3Url}/${params.value}`;
      return `<img src="${iconUrl}" height="30" />`;
    }
    return '';
  }
}
