import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { DeviceModel } from '../models/device-model';
import { ProtocolController } from '@widgets/devices/models/protocol-controller';

import { DeviceModelFormsService } from '../services/device-model-forms.service';
import { DeviceModelsService } from '../services/device-models.service';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { DeviceBrandsService } from '@widgets/device-brands/services/device-brands.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { ProtocolControllersService } from '@widgets/devices/services/protocol-controllers.service';
import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Component({
  selector: 'sc-device-model-form',
  templateUrl: './device-model-form.component.html',
  styleUrls: ['./device-model-form.component.scss']
})
export class DeviceModelFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: DeviceModel;
  form: FormGroup;
  editMode = false;
  errorMessage: string;

  isSubmitting = false;
  fetchingState = 0;
  sourceData: { [key: string]: any } = {};
  selectItems: { [key: string]: SelectItem[] } = {};
  selectedController: ProtocolController;
  availableControllers: ProtocolController[] = [];

  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceModelFormsService: DeviceModelFormsService,
    private deviceModelsService: DeviceModelsService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceBrandsService: DeviceBrandsService,
    private deviceTypesService: DeviceTypesService,
    private protocolControllersService: ProtocolControllersService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    const selectItems = config().selectItems;
    this.selectItems = {
      zigbeeControllerPorts: selectItems.zigbeeControllerPorts,
      zigbeeStatus: selectItems.zigbeeStatus,
      zwaveControllerPorts: selectItems.zwaveControllerPorts,
      zwaveStatus: selectItems.zwaveStatus
    };

    this.fetchDeviceTypes();
    this.fetchDeviceBrands();
    this.fetchDeviceProtocols();
    this.fetchProtocolControllers();

    // EDIT MODE
    if (this.data) {
      this.editMode = true;
      this.fetchDeviceModel(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDeviceModel(id: number) {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_MODEL = this.deviceModelsService
      .getDeviceModel(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        const behaviourPatterns: any = this.formData.behaviourPatterns;
        delete this.formData.behaviourPatterns;
        this.form.patchValue(this.formData);
        this.setValueMappingData();

        if (this.isAutomation) {
          this.deviceModelFormsService.resetBehaviourPatternsForm(this.form);
        } else {
          if (behaviourPatterns) {
            this.deviceModelFormsService.setBehaviourPatternsFormArray(
              this.form,
              JSON.parse(behaviourPatterns)
            );
          } else {
            this.deviceModelFormsService.initBehaviourPatternsFormArray(
              this.form
            );
          }
        }

        this.fetchingState--;
      });
  }

  private fetchDeviceBrands() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_BRANDS = this.deviceBrandsService
      .getDeviceBrands()
      .subscribe((result: any) => {
        this.selectItems.brands = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  private fetchDeviceProtocols() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_PROTOCOLS = this.deviceProtocolsService
      .getDeviceProtocols()
      .subscribe((result: any) => {
        this.selectItems.protocols = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_TYPES = this.deviceTypesService
      .getDeviceTypes()
      .subscribe((result: any) => {
        this.sourceData.deviceTypes = result.data;
        this.selectItems.deviceTypes = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  private fetchProtocolControllers() {
    this.fetchingState++;
    this.subscribers.GET_PROTOCOL_CONTROLLER = this.protocolControllersService
      .getProtocolControllers()
      .subscribe((result: any) => {
        this.sourceData.protocolControllers = result.data.filter(
          d => d.isActive && !d.isDeleted
        );
        this.selectItems.zwaveControllerSoftwares = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.getControllers();
        this.fetchingState--;
      });
  }

  private initForm() {
    this.form = this.deviceModelFormsService.initDeviceModelForm();

    if (this.editMode) {
      this.form.controls.deviceTypeId.disable();
      this.form.controls.brandId.disable();
      this.form.controls.protocolId.disable();
    } else {
      this.deviceModelFormsService.initBehaviourPatternsFormArray(this.form);
    }

    this.subscribers.watchDeviceProtocol = this.form.controls.protocolId.valueChanges.subscribe(value => {
      if (value) {
        this.getControllers();
      }
    });

    this.subscribers.watchDeviceType = this.form.controls.deviceTypeId.valueChanges.subscribe(value => {
      if (value) {

        if (!this.editMode) {
          // Check if BehaviourPatterns is required
          this.form.controls.zwaveStatus.reset();
          this.form.controls.zwaveControllerSoftware.reset();
          this.form.controls.zwaveControllerPort.reset();
          this.form.controls.zigbeeStatus.reset();
          this.form.controls.zigbeeControllerPort.reset();

          this.getControllers();

          if (this.isAutomation) {
            this.deviceModelFormsService.resetBehaviourPatternsForm(this.form);
          } else {
            this.deviceModelFormsService.initBehaviourPatternsFormArray(this.form);
          }
        }
      }
    });
  }

  get deviceTypeId() {
    return this.form.get('deviceTypeId').value;
  }

  get deviceTypeKey() {
    if (this.deviceTypeId && this.sourceData && this.sourceData.deviceTypes) {
      const deviceType = this.sourceData.deviceTypes.find(
        dt => dt.id === this.deviceTypeId
      );
      return deviceType && deviceType.key ? deviceType.key : null;
    }
    return null;
  }

  get isAutomation() {
    if (this.deviceTypeKey === 'a') {
      return true;
    }
    return false;
  }

  private setValueMappingData() {
    this.deviceModelFormsService.resetValueMappingFormArray(this.form);

    const deviceTypeId = this.form.get('deviceTypeId').value;

    if (deviceTypeId && this.sourceData && this.sourceData.deviceTypes) {
      const deviceType = this.sourceData.deviceTypes.find(
        dt => dt.id === deviceTypeId
      );

      if (
        deviceType &&
        deviceType.mappingValues &&
        deviceType.mappingValues.length
      ) {
        this.sourceData.mappingValues = deviceType.mappingValues;
        const control = this.form.controls.valueMappings as FormArray;

        for (let i = 0; i < this.availableControllers.length; i++) {
          for (let ii = 0; ii < deviceType.mappingValues.length; ii++) {
            const form = this.deviceModelFormsService.initValueMappingFormGroup();

            form.patchValue({
              deviceTypeMappingValueId: deviceType.mappingValues[ii].id,
              protocolControllerId: this.availableControllers[i].id
            });

            if (this.editMode) {
              // Patch device model id
              form.patchValue({
                deviceModelId: this.formData.id
              });

              // patch exist value mapping
              for (
                let iii = 0;
                iii < this.formData.valueMappings.length;
                iii++
              ) {
                if (
                  this.formData.valueMappings[iii].deviceTypeMappingValueId ===
                    deviceType.mappingValues[ii].id &&
                  this.formData.valueMappings[iii].protocolControllerId ===
                    this.availableControllers[i].id
                ) {
                  form.patchValue({
                    mappedValue: this.formData.valueMappings[iii].mappedValue,
                    id: this.formData.valueMappings[iii].id
                  });
                  break;
                }
              }

              //  if value mapping id not exist
              if (!form.get('id').value) {
                form.removeControl('id');
              }
            } else {
              form.removeControl('id');
              form.removeControl('deviceModelId');
            }

            control.push(form);
          }
        }
      }
    }
  }

  get behaviourPatterns(): FormArray {
    if (!this.isAutomation) {
      return this.form.controls.behaviourPatterns as FormArray;
    }
    return;
  }

  addBehaviourPattern() {
    const control =  this.form.get('behaviourPatterns') as FormArray;
    const form = this.deviceModelFormsService.initBehaviourPatternsFormGroup();
    control.push(form);
    this.form.markAsDirty();
  }

  removeBehaviourPattern(i: number) {
    const control =  this.form.get('behaviourPatterns') as FormArray;
    control.removeAt(i);
    this.form.markAsDirty();
  }

  get valueMappings(): FormArray {
    return this.form.controls.valueMappings as FormArray;
  }

  private getControllers() {
    const protocolId = this.form.get('protocolId').value;
    if (
      protocolId &&
      this.sourceData &&
      this.sourceData.protocolControllers &&
      this.sourceData.protocolControllers.length
    ) {
      this.availableControllers = this.sourceData.protocolControllers.filter(
        ctrl => ctrl.protocolId === protocolId
      );
    } else {
      this.availableControllers = [];
    }

    if (this.availableControllers.length) {
      this.selectController(this.availableControllers[0]);
    }

    this.setValueMappingData();
  }

  selectController(controller: ProtocolController) {
    this.selectedController = controller;
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(data: DeviceModel) {
    this.subscribers.create = this.deviceModelsService
      .createDeviceModel(data)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'CREATE_FAIL')
      });
  }

  private update(data: DeviceModel) {
    this.subscribers.update = this.deviceModelsService
      .updateDeviceModel(this.formData.id, data)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'UPDATE_FAIL')
      });
  }

  delete(force = false) {
    this.subscribers.delete = this.deviceModelsService
      .deleteDeviceModel(this.formData.id, force)
      .subscribe({
        // next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        next: (result: any) => {
          if (result.warning) {
            // this.sharedService.deleteWarningHandler(
            //   this.data,
            //   result.warning,
            //   this.delete.bind(this)
            // );
            this.subscribers.delete = this.sharedService
              .deleteWarningHandler(this.data, result.warning)
              .subscribe((confirm: any) => {
                if (confirm === true) {
                  this.delete(true);
                }
              });
          } else {
            this.apiCallSuccess('DELETE_SUCCESS', result);
          }
        },
        error: this.apiCallError.bind(this, 'DELETE_FAIL')
      });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE_MODEL');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
