import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-flag-cell',
  templateUrl: 'flag-cell.component.html',
})
export class FlagCellComponent implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }

  flag() {
    let lang = this.cell.value;
    switch (lang) {
      case 'en':
        lang = 'gb';
        break;
      default:
        break;
    }
    return 'flag-icon flag-icon-' + lang;
  }
}
