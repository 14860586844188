import { Action } from '@ngrx/store';
import { Locale } from '@widgets/locales/models/locale';

// LOAD LOCALE
export const LOAD_LOCALE = '[Locales] Load Locale';
export const LOAD_LOCALE_FAIL = '[Locales] Load Locale Fail';
export const LOAD_LOCALE_SUCCESS = '[Locales] Load Locale Success';

export class LoadLocale implements Action {
  readonly type = LOAD_LOCALE;
  constructor(public payload: string) {}
}

export class LoadLocaleFail implements Action {
  readonly type = LOAD_LOCALE_FAIL;
  constructor(public payload: any) {}
}

export class LoadLocaleSuccess implements Action {
  readonly type = LOAD_LOCALE_SUCCESS;
  constructor(public payload: Locale) {}
}

// LOAD LOCALES
export const LOAD_LOCALES = '[Locales] Load Locales';
export const LOAD_LOCALES_FAIL = '[Locales] Load Locales Fail';
export const LOAD_LOCALES_SUCCESS = '[Locales] Load Locales Success';

export class LoadLocales implements Action {
  readonly type = LOAD_LOCALES;
}

export class LoadLocalesFail implements Action {
  readonly type = LOAD_LOCALES_FAIL;
  constructor(public payload: any) {}
}

export class LoadLocalesSuccess implements Action {
  readonly type = LOAD_LOCALES_SUCCESS;
  constructor(public payload: Locale[]) {}
}

// SELECT LOCALE
export const SELECT_LOCALE = '[Locales] Select Locale';

export class SelectLocale implements Action {
  readonly type = SELECT_LOCALE;
  constructor(public payload: string) {}
}

// CREATE LOCALE
export const CREATE_LOCALE = '[Locales] Create Locale';
export const CREATE_LOCALE_FAIL = '[Locales] Create Locale Fail';
export const CREATE_LOCALE_SUCCESS = '[Locales] Create Locale Success';

export class CreateLocale implements Action {
  readonly type = CREATE_LOCALE;
  constructor(public payload: Locale) {}
}

export class CreateLocaleFail implements Action {
  readonly type = CREATE_LOCALE_FAIL;
  constructor(public payload: any) {}
}

export class CreateLocaleSuccess implements Action {
  readonly type = CREATE_LOCALE_SUCCESS;
  constructor(public payload: Locale) {}
}

// UPDATE LOCALE
export const UPDATE_LOCALE = '[Locales] Update Locale';
export const UPDATE_LOCALE_FAIL = '[Locales] Update Locale Fail';
export const UPDATE_LOCALE_SUCCESS = '[Locales] Update Locale Success';

export class UpdateLocale implements Action {
  readonly type = UPDATE_LOCALE;
  constructor(public payload: Locale) {}
}

export class UpdateLocaleFail implements Action {
  readonly type = UPDATE_LOCALE_FAIL;
  constructor(public payload: any) {}
}

export class UpdateLocaleSuccess implements Action {
  readonly type = UPDATE_LOCALE_SUCCESS;
  constructor(public payload: Locale) {}
}

// DELETE LOCALE
export const DELETE_LOCALE = '[Locales] Delete Locale';
export const DELETE_LOCALE_FAIL = '[Locales] Delete Locale Fail';
export const DELETE_LOCALE_SUCCESS = '[Locales] Delete Locale Success';

export class DeleteLocale implements Action {
  readonly type = DELETE_LOCALE;
  constructor(public payload: Locale) {}
}

export class DeleteLocaleFail implements Action {
  readonly type = DELETE_LOCALE_FAIL;
  constructor(public payload: any) {}
}

export class DeleteLocaleSuccess implements Action {
  readonly type = DELETE_LOCALE_SUCCESS;
  constructor(public payload: Locale) {}
}

// Reset Pending
export const LOCALE_RESET_PENDING_STATE = '[Locales] Reset pending state';

export class LocaleResetPendingState implements Action {
  readonly type = LOCALE_RESET_PENDING_STATE;
}

// ACTION TYPES
export type LocalesAction =
  | LoadLocale
  | LoadLocaleFail
  | LoadLocaleSuccess
  | LoadLocales
  | LoadLocalesFail
  | LoadLocalesSuccess
  | SelectLocale
  | CreateLocale
  | CreateLocaleFail
  | CreateLocaleSuccess
  | UpdateLocale
  | UpdateLocaleFail
  | UpdateLocaleSuccess
  | DeleteLocale
  | DeleteLocaleFail
  | DeleteLocaleSuccess
  | LocaleResetPendingState;
