import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LocationProperty } from '../models/location-property';

import { SharedService } from '@shared/shared.service';

import { config } from '../../../config';

@Injectable()
export class LocationPropertiesService {
  private apiUrl = config().apiUrl + '/locationProperties';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  getLocationProperty(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getLocationProperties() {
    return this.httpClient.get(this.apiUrl);
  }

  createLocationProperty(data: LocationProperty) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateLocationProperty(id: number, data: LocationProperty) {
    return this.httpClient.patch(
      `${this.apiUrl}/${id}`,
      this.sharedService.cleanUpObject(data)
    );
  }

  deleteLocationProperty(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
