import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectItem } from 'primeng/api';
import { DeviceBrand } from '@widgets/device-brands/models/device-brand';
import { DeviceBrandsService } from '@widgets/device-brands/services/device-brands.service';
import { DeviceModel } from '@widgets/device-models-2/models/device-model';
import { DeviceModelService } from '@widgets/device-models-2/services/device-model.service';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { SharedService } from '@shared/shared.service';

import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { DeviceModelProperty } from '@app/widgets/device-models-2/models/device-model-property';

interface CustomDeviceModel extends DeviceModel {
  brandName?: string;
  protocolName?: string;
  [key: string]: any;
}

@Component({
  selector: 'sc-device-object-form',
  templateUrl: 'device-object-form.component.html',
  styleUrls: ['device-object-form.component.scss'],
})
export class DeviceObjectFormComponent implements OnInit, OnDestroy {
  fetchingState = 0;
  filteredModels: CustomDeviceModel[] = [];
  selectItems: { [prop: string]: SelectItem[] } = {};

  private deviceBrands: DeviceBrand[] = [];
  private deviceModels: CustomDeviceModel[] = [];
  private deviceProtocols: DeviceProtocol[] = [];
  private deviceTypes: DeviceType[] = [];
  private subscribers: { [prop: string]: any } = {};
  private timeouts: { [prop: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private deviceBrandsService: DeviceBrandsService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceTypesService: DeviceTypesService,
    private deviceModelService: DeviceModelService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchingState++;
    this.subscribers.getRequiredData = combineLatest([
      this.deviceModelService.getDeviceModels(),
      this.deviceTypesService.getDeviceTypes(),
      this.deviceProtocolsService.getDeviceProtocols(),
      this.deviceBrandsService.getDeviceBrands(),
    ])
      .pipe(
        map((data: any) => ({
          models: data[0].data,
          types: data[1].data,
          protocols: data[2].data,
          brands: data[3].data,
        })),
        filter(({ models, types, protocols, brands }) => !!models && !!types && !!protocols && !!brands),
        take(1)
      )
      .subscribe(({ models, types, protocols, brands }) => {
        this.deviceBrands = brands.filter((item) => item.isActive && !item.isDeleted);
        this.deviceProtocols = protocols.filter((item) => item.isActive && !item.isDeleted);
        this.deviceTypes = types.filter((item) => item.isActive && !item.isDeleted);

        this.selectItems.deviceProtocols = this.sharedService.createSelectItems(this.deviceProtocols, false, 'key');
        this.selectItems.deviceTypes = this.sharedService.createSelectItems(this.deviceTypes, false, 'key');

        this.deviceModels = models
          .filter((item) => !item.is_deleted)
          .map((item) => {
            const protocol = this.deviceProtocols.find((dp) => dp.key === item.protocol);
            const brand = this.deviceBrands.find((db) => db.key === item.brand);
            item.brandName = brand ? brand.name : null;
            item.protocolName = protocol ? protocol.name : null;
            if (item.properties) {
              item.properties = this.sharedService.tryParseJSON(item.properties as string);
            }
            return item;
          });
        this.filteredModels = this.sharedService.sortBy(this.deviceModels, 'product_name') as CustomDeviceModel[];
        this.fetchingState--;
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearTimeouts(this.timeouts);
  }

  private updateSearchResult(searchTerm: { [prop: string]: any }) {
    this.filteredModels = [];
    let deviceProtocols: string[];
    let deviceTypes: string[];
    let search: string;
    const filteredModels: DeviceModel[] = [];

    if (searchTerm) {
      if (searchTerm.search) {
        search = searchTerm.search;
      }
      if (searchTerm.deviceProtocols && searchTerm.deviceProtocols.length) {
        deviceProtocols = searchTerm.deviceProtocols;
      }
      if (searchTerm.deviceTypes && searchTerm.deviceTypes.length) {
        deviceTypes = searchTerm.deviceTypes;
      }
    }

    for (const model of this.deviceModels) {
      if (deviceProtocols && deviceProtocols.indexOf(model.protocol) >= 0) {
        filteredModels.push(model);
        continue;
      }

      if (model.is_automation && deviceTypes && deviceTypes.indexOf('a') >= 0) {
        filteredModels.push(model);
        continue;
      }

      if (!model.is_automation && deviceTypes && model.properties) {
        let found = false;
        for (const prop of model.properties as DeviceModelProperty[]) {
          if (deviceTypes.indexOf(prop.type) >= 0) {
            filteredModels.push(model);
            found = true;
            break;
          }
        }
        if (found) {
          continue;
        }
      }

      if (search) {
        const searchRegex = new RegExp(`${search}(.+)?`, 'gi');
        if (
          searchRegex.test(model.brand) ||
          searchRegex.test(model.brandName) ||
          searchRegex.test(model.display_name) ||
          searchRegex.test(model.protocol) ||
          searchRegex.test(model.protocolName) ||
          searchRegex.test(model.product_name)
        ) {
          filteredModels.push(model);
        }
      }
    }

    if (!deviceProtocols && !deviceTypes && !search && !filteredModels.length) {
      this.filteredModels = this.sharedService.sortBy(this.deviceModels, 'product_name') as CustomDeviceModel[];
    } else if (filteredModels.length) {
      this.filteredModels = this.sharedService.sortBy(filteredModels, 'product_name') as CustomDeviceModel[];
    }
  }

  onSearchValueChanged(event) {
    if (this.timeouts.search) {
      clearTimeout(this.timeouts.search);
    }
    this.timeouts.search = setTimeout(() => {
      this.updateSearchResult(event);
    }, 500);
  }

  onDeviceSelected(event) {
    this.dialog.close(event);
  }
}
