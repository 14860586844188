import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap, take } from 'rxjs/operators';
import * as fromAuth from '../actions/auth.action';
import * as fromLocales from '../actions/locales.action';
import * as fromRouter from '../actions/router.action';
import * as fromSites from '../actions/sites.action';
import { AuthService } from '@app/auth/services/auth.service';
import { SharedService } from '@shared/shared.service';
// import { Store, select } from '@ngrx/store';
// import { getUserState } from '@app/store/selectors/user.selector';
// import { UserState } from '@app/store/reducers/user.reducer';

@Injectable()
export class AuthEffects {
  constructor(
    private action$: Actions,
    private authService: AuthService,
    private sharedService: SharedService /*, private store: Store<UserState>*/
  ) {}

  @Effect()
  verifyUser$ = this.action$.pipe(
    ofType(fromAuth.VERIFY_USER),
    map((action: fromAuth.VerifyUser) => action.payload),
    switchMap((credential) => {
      return this.authService.login(credential).pipe(
        map((result) => new fromAuth.VerifyUserSuccess(result)),
        catchError((error) => of(new fromAuth.VerifyUserFail(error)))
      );
    })
  );

  @Effect()
  verifyUserSuccess$ = this.action$.pipe(
    ofType(fromAuth.VERIFY_USER_SUCCESS),
    switchMap((action: fromAuth.VerifyUserSuccess) => {
      const payload = action.payload;
      // if no token, do nothing!
      if (!payload.token) {
        return [];
      }
      // console.log(payload);
      const actions = [];
      actions.push(new fromLocales.SelectLocale(payload.locale));

      if (payload.mainRole && payload.mainRole.toLowerCase() === 'integrator') {
        // if (payload.companyId) {
        actions.push(new fromRouter.Go({ path: ['integrator', 'overview'] }));
        // } else {
        //   actions.push(new fromRouter.Go({ path: ['integrator', 'team', 'overview'] }));
        // }
      } else {
        // actions.push(new fromSites.SelectSite(payload.siteIds[0]));
        actions.push(new fromRouter.Go({ path: ['dashboards'] }));
      }

      return actions;
    })
  );

  @Effect()
  verifyUser2$ = this.action$.pipe(
    ofType(fromAuth.VERIFY_USER_2),
    map((action: fromAuth.VerifyUser) => action.payload),
    switchMap((credential) => {
      return this.authService.login2(credential).pipe(
        map((result) => new fromAuth.VerifyUser2Success(result)),
        catchError((error) => of(new fromAuth.VerifyUser2Fail(error)))
      );
    })
  );

  @Effect()
  verifyUser2Success$ = this.action$.pipe(
    ofType(fromAuth.VERIFY_USER_2_SUCCESS),
    switchMap((action: fromAuth.VerifyUser2Success) => {
      const user = action.payload;
      // if no token, do nothing!
      if (!user.token) {
        return [];
      }

      const actions = [];
      actions.push(new fromLocales.SelectLocale(user.locale));

      const mainRole = user.mainRole && user.mainRole.toLowerCase();
      if (mainRole === 'integrator') {
        actions.push(new fromRouter.Go({ path: ['integrator'] }));
      } else if (mainRole === 'enduser') {
        actions.push(new fromRouter.Go({ path: ['enduser'] }));
      } else if (mainRole === 'sc') {
        actions.push(new fromRouter.Go({ path: ['dashboards'] }));
      }

      return actions;
    })
  );

  @Effect()
  verifyTwoFactor$ = this.action$.pipe(
    ofType(fromAuth.VERIFY_TWO_FACTOR_AUTH),
    map((action: fromAuth.VerifyTwoFactorAuth) => action.payload),
    switchMap((credential) => {
      return this.authService.verifyTwoFactor(credential).pipe(
        map((result) => new fromAuth.VerifyTwoFactorAuthSuccess(result)),
        catchError((error) => of(new fromAuth.VerifyTwoFactorAuthFail(error)))
      );
    })
  );

  @Effect()
  verifyTwoFactorAuthSuccess$ = this.action$.pipe(
    ofType(fromAuth.VERIFY_TWO_FACTOR_AUTH_SUCCESS),
    switchMap((action: fromAuth.VerifyTwoFactorAuthSuccess) => {
      const payload = action.payload;
      // if no token, do nothing!
      if (!payload.token) {
        return [];
      }
      return [
        new fromLocales.SelectLocale(payload.locale),
        new fromSites.SelectSite(payload.siteIds[0]),
        new fromRouter.Go({ path: ['dashboards'] }),
      ];
    })
  );

  @Effect()
  logout$ = this.action$.pipe(
    ofType(fromAuth.LOGOUT),
    tap(() => {
      this.sharedService.resetData();
    }),
    switchMap(() => {
      return [new fromRouter.Go({ path: ['login'] }), { type: '[Store] Clear Store' }];
    })
    // tap(() => {
    // this.store.pipe(select(getUserState), take(1)).subscribe((user) => {
    // console.log(user);
    // if (user.mainRole === 'integrator') {
    //   this.store.dispatch(new fromRouter.Go({ path: ['integrator', 'signin'] }));
    // } else {
    // this.store.dispatch(new fromRouter.Go({ path: ['login'] }));
    // }
    // this.store.dispatch({ type: '[Store] Clear Store' });
    // });
    // })
  );
}
