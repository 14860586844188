import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as pagesAction from '../actions/pages.action';
import { PageService } from '@app/core2/page/page.service';

@Injectable()
export class PagesEffects {
  constructor(private action$: Actions, private pageService: PageService) {}

  @Effect()
  loadPages$ = this.action$.pipe(
    ofType(pagesAction.LOAD_PAGES),
    switchMap(() => {
      return this.pageService.getPages().pipe(
        map((result: any) => new pagesAction.LoadPagesSuccess(result.data)),
        catchError(error => of(new pagesAction.LoadPagesFail(error)))
      );
    })
  );

  @Effect()
  updatePage$ = this.action$.pipe(
    ofType(pagesAction.UPDATE_PAGE),
    map((action: pagesAction.UpdatePage) => action.payload),
    switchMap(page => {
      return this.pageService.updatePage(page.id, { ...page }).pipe(
        map(result => new pagesAction.UpdatePageSuccess(page)),
        catchError(error => of(new pagesAction.UpdatePageFail(error)))
      );
    })
  );
}
