<div class="layout">
  <div>{{ floor?.description }}</div>
  <div>
    <button
      type="button"
      pButton
      [icon]="'fa fa-fw fa-plus'"
      (click)="addUnit(floor)"
    ></button>
  </div>
</div>
<div *ngFor="let unit of form.controls; let i = index">
  <div class="layout">
    <sc-text-input
      class="unit-desc"
      controlName="description"
      [form]="unit"
      (actionChange)="updateUnit(i)"
    ></sc-text-input>
    <button
      class="minus-btn"
      type="button"
      pButton
      [icon]="'fa fa-fw fa-minus'"
      (click)="removeUnit(i)"
    ></button>
  </div>
</div>
