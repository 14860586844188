import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { config } from '@app/config';

@Injectable()
export class LocationStatisticsFormsService {
  constructor(private formBuilder: FormBuilder) {}

  formSelectItems(): { [key: string]: any[] } {
    const c = config();
    return {
      // graphs: c.selectItems.locationPropertyTypes,
      positions: c.selectItems.chartAxes,
      styles: c.selectItems.chartStyles,
      colors: c.colors,
    };
  }

  /**
   * Create Location Stats Form
   */
  initDatasetForm(): FormGroup {
    return this.formBuilder.group({
      from: [null, Validators.required],
      to: [null, Validators.required],
      interval: [null, Validators.required],
      isCombine: false,
      isCompare: false,
      graphs: this.formBuilder.array([], Validators.required),
      locations: [null, Validators.required],
      compareLocations: null,
      fetchingRequired: true,
    });
  }

  /**
   * Create Location Stats Graph form
   */
  initGraphForm(): FormGroup {
    return this.formBuilder.group({
      dataset: [null, Validators.required],
      axis: [null, Validators.required],
      position: 'left',
      color: [null, Validators.required],
      styles: null,
    });
  }
}
