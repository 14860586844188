import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import { Page } from '@app/core2/models/page';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { DevicesService } from '@widgets/devices/services/devices.service';

const ELEC = 'elec';
const GAZ = 'gaz';
const WATER = 'water';
const DEVICE_TYPE_KEY = { elec: 'emr_t', gaz: 'gmr_t', water: 'wmr_t' };

@Component({
  selector: 'sc-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss'],
})
export class ConsumptionComponent implements OnInit, OnDestroy {
  // The widget with its configuration
  @Input() config: any;

  page: Page;
  selectedSite: Site;

  subscribers: { [key: string]: any } = {};
  intervals: { [key: string]: any } = {};

  chartData: any;
  chartLayout: any;

  isInitialized: boolean = false;
  isFetching: object = { elec: false, gaz: false, water: false };
  lastFecth: object = { elec: false, gaz: false, water: false };
  lastLocale: string = '';
  isFetchingDevices: boolean = false;

  //multisites
  isMultisites: boolean;
  filterBy: string;
  siteIds: Number[];

  widgetName: string = '';
  defaultWidgetName: string = '';
  customWidgetName: string = '';

  // Details modal
  isDetailsModalOpen: boolean = false;

  // Config modal
  isConfigModalOpen: boolean = false;
  configForm: FormGroup;

  // Consumptions
  consumptions: object = { elec: [], gaz: [], water: [] };
  xValue: Array<string>[] = [];
  devices: object = { elec: [], gaz: [], water: [] };

  // All available Devices, loaded from API
  elecMeterDevices: any[] = [];
  selectedElecMeterDevices: any[] = [];
  gazMeterDevices: any[] = [];
  selectedGazMeterDevices: any[] = [];
  waterMeterDevices: any[] = [];
  selectedWaterMeterDevices: any[] = [];
  allDevices: any[] = [];

  // Statistics
  ceosStats: any[] = [];
  currentYear = moment().year();
  prevYear = moment().subtract(1, 'year').year();
  currMonth = moment().month();
  months: string[] = [];

  constructor(
    private widgetService: SCWidgetService,
    private sharedService: SharedService,
    private devicesService: DevicesService,
    private store: Store<fromStore.State>,
    private formBuilder: FormBuilder
  ) {
    this.chartLayout = {
      barmode: 'stack',
      height: 190,
      width: 1000,
      margin: { t: 5, b: 40, l: 30, r: 15 },
      font: {
        family: 'barlow',
        size: 13,
      },
      xaxis: {
        type: 'multicategory',
      },
    };

    this.configForm = this.formBuilder.group({
      name: [''],
      showElec: false,
      showGaz: false,
      showWater: false,
    });
  }

  ngOnInit() {
    this.setMonths();
    this.initWidgetName();
    this.fetchInitialData();
    this.intervals.fetchElecConsumptions = setInterval(() => this.fetchConsumptions(ELEC, DEVICE_TYPE_KEY[ELEC]), 1000);
    this.intervals.fetchElecConsumptions = setInterval(() => this.fetchConsumptions(GAZ, DEVICE_TYPE_KEY[GAZ]), 1000);
    this.intervals.fetchElecConsumptions = setInterval(
      () => this.fetchConsumptions(WATER, DEVICE_TYPE_KEY[WATER]),
      1000
    );
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  setMonths() {
    this.months = [
      this.sharedService.getTranslation('JANUARY'),
      this.sharedService.getTranslation('FEBRUARY'),
      this.sharedService.getTranslation('MARCH'),
      this.sharedService.getTranslation('APRIL'),
      this.sharedService.getTranslation('MAY'),
      this.sharedService.getTranslation('JUNE'),
      this.sharedService.getTranslation('JULY'),
      this.sharedService.getTranslation('AUGUST'),
      this.sharedService.getTranslation('SEPTEMBER'),
      this.sharedService.getTranslation('OCTOBER'),
      this.sharedService.getTranslation('NOVEMBER'),
      this.sharedService.getTranslation('DECEMBER'),
    ];
  }

  isConfigured() {
    const elecDevices = this.config.customPreferences?.elecDevices || [];
    const gazDevices = this.config.customPreferences?.gazDevices || [];
    const waterDevices = this.config.customPreferences?.waterDevices || [];
    const showElec = this.config.customPreferences?.showElec || false;
    const showGaz = this.config.customPreferences?.showGaz || false;
    const showWater = this.config.customPreferences?.showWater || false;

    return (elecDevices && elecDevices.length && showElec) ||
      (gazDevices && gazDevices.length && showGaz) ||
      (waterDevices && waterDevices.length && showWater)
      ? true
      : false;
  }

  initWidgetName() {
    this.defaultWidgetName = this.sharedService.getLocaleWidgetName(this.config);
    if (this.config?.customPreferences?.name) {
      this.customWidgetName = this.config.customPreferences.name;
    }
    this.widgetName = this.customWidgetName ? this.customWidgetName : this.defaultWidgetName;
  }

  private getDevices(siteIds: Number[]) {
    return (this.subscribers.devices = this.devicesService.getDevices({ siteId: siteIds }).subscribe({
      next: (result: any) => {
        const elecMeterDevices = [];
        const gazMeterDevices = [];
        const waterMeterDevices = [];
        this.allDevices = result.data;
        result.data
          ?.filter((d) => !d.isDeleted && d.isActive && d.isVirtualDevicesHolder)
          .map((meterDevice) => {
            if (
              this.allDevices.findIndex(
                (device) => device.parentIdx === meterDevice.idx && device.deviceTypeKey === DEVICE_TYPE_KEY[ELEC]
              ) !== -1
            ) {
              elecMeterDevices.push(meterDevice);
            }
            if (
              this.allDevices.findIndex(
                (device) => device.parentIdx === meterDevice.idx && device.deviceTypeKey === DEVICE_TYPE_KEY[GAZ]
              ) !== -1
            ) {
              gazMeterDevices.push(meterDevice);
            }
            if (
              this.allDevices.findIndex(
                (device) => device.parentIdx === meterDevice.idx && device.deviceTypeKey === DEVICE_TYPE_KEY[WATER]
              ) !== -1
            ) {
              waterMeterDevices.push(meterDevice);
            }
          });
        this.elecMeterDevices = elecMeterDevices;
        this.gazMeterDevices = gazMeterDevices;
        this.waterMeterDevices = waterMeterDevices;
      },
      complete: () => {
        this.fetchConsumptions(ELEC, DEVICE_TYPE_KEY[ELEC]);
        this.fetchConsumptions(GAZ, DEVICE_TYPE_KEY[GAZ]);
        this.fetchConsumptions(WATER, DEVICE_TYPE_KEY[WATER]);
      },
    }));
  }

  fetchInitialData() {
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (site) => {
        this.selectedSite = site;
        this.subscribers.watchPage = this.store.pipe(select(fromStore.getCurrentPage)).subscribe({
          next: (page) => {
            this.page = page;
            if (page?.key === 'integrator_overview') {
              this.isMultisites = true;
              this.filterBy = 'siteName,description';
              this.store.select(fromStore.getSites).subscribe({
                next: (availableSites) => {
                  this.siteIds = [];
                  availableSites?.filter((site) => {
                    if (!site.isDeleted && site.isActive) {
                      this.siteIds.push(site.id);
                    }
                  });
                  this.getDevices(this.siteIds);
                  this.isInitialized = true;
                },
              });
            } else {
              this.isMultisites = false;
              this.filterBy = 'description';
              this.getDevices([this.selectedSite.id]);
              this.isInitialized = true;
            }
          },
        });
      },
    });
  }

  setXvalues() {
    this.xValue = [];
    const xMonths = [];
    const xYears = [];
    let offset = 0;
    let year = this.currentYear;
    for (let i = 0; i < 12; i++) {
      let month = this.currMonth - i;
      if (month < 0) {
        month = 11 - offset;
        year = this.currentYear - 1;
        offset++;
      }
      xMonths.push(this.months[month]);
      xMonths.push(this.months[month]);
      xYears.push(year);
      xYears.push(year - 1);
    }
    this.xValue.push(xMonths.reverse());
    this.xValue.push(xYears.reverse());
  }

  fetchConsumptions(type: string, deviceTypeKey: string) {
    if (
      this.lastLocale === this.sharedService.userLocale &&
      (this.isFetching[type] ||
        (moment().diff(this.lastFecth[type], 'minutes') < 5 && this.consumptions[type].length > 0))
    ) {
      return;
    } else {
      if (this.isFetching[type]) {
        return;
      }
    }

    if (this.lastLocale !== this.sharedService.userLocale) {
      this.setMonths();
      this.setXvalues();
    }

    this.isFetching[type] = true;
    this.lastFecth[type] = moment();
    this.lastLocale = this.sharedService.userLocale;

    let devices = [];
    switch (type) {
      case ELEC:
        this.config?.customPreferences?.elecDevices?.map((elecDevice) => {
          const device = this.allDevices.find(
            (d) => !d.isDeleted && d.isActive && d.deviceTypeKey === deviceTypeKey && d.parentIdx === elecDevice.idx
          );
          if (device) devices.push(device.idx);
        });
        break;
      case GAZ:
        this.config?.customPreferences?.gazDevices?.map((gazDevice) => {
          const device = this.allDevices.find(
            (d) => !d.isDeleted && d.isActive && d.deviceTypeKey === deviceTypeKey && d.parentIdx === gazDevice.idx
          );
          if (device) devices.push(device.idx);
        });
        break;
      case WATER:
        this.config?.customPreferences?.waterDevices?.map((waterDevice) => {
          const device = this.allDevices.find(
            (d) => !d.isDeleted && d.isActive && d.deviceTypeKey === deviceTypeKey && d.parentIdx === waterDevice.idx
          );
          if (device) devices.push(device.idx);
        });
        break;
      default:
        devices = [];
        break;
    }

    if (devices && devices.length) {
      this.subscribers.fetchDataset = this.widgetService.getConsumptions(devices.join(',')).subscribe(
        (result: any) => {
          this.isFetching[type] = false;

          for (let i = 0; i < this.xValue[0].length; i++) {
            const month = this.months.findIndex((month) => month === this.xValue[0][i]) + 1;
            const consumption = result.data.find(
              (r) => r.date === `${(month < 10 ? '0' : '') + month}-${this.xValue[1][i]}`
            );
            this.consumptions[type].push(consumption?.value);
          }
        },
        (error: any) => {
          this.isFetching[type] = false;
        }
      );
      this.renderChart();
      this.isFetching[type] = false;
    } else {
      this.isFetching[type] = false;
    }
  }

  openDetailsModal() {
    this.isDetailsModalOpen = true;
  }

  openConfigModal() {
    this.isConfigModalOpen = true;
    this.patchConfigForm(this.config);
  }

  resetWidgetName() {
    this.configForm.patchValue({
      name: '',
    });
  }

  patchConfigForm(config) {
    if (!config.customPreferences) {
      return;
    }

    this.configForm.patchValue({
      name: config.customPreferences.name,
      showElec: config.customPreferences.showElec,
      showGaz: config.customPreferences.showGaz,
      showWater: config.customPreferences.showWater,
    });

    for (let device of config.customPreferences.elecDevices) {
      for (let elecDevice of this.elecMeterDevices) {
        if (elecDevice.idx == device.idx) {
          this.selectedElecMeterDevices.push(elecDevice);
          break;
        }
      }
    }
    this.elecMeterDevices = this.elecMeterDevices.filter((device) => !this.selectedElecMeterDevices.includes(device));

    for (let device of config.customPreferences.gazDevices) {
      for (let gazDevice of this.gazMeterDevices) {
        if (gazDevice.idx == device.idx) {
          this.selectedGazMeterDevices.push(gazDevice);
          break;
        }
      }
    }
    this.gazMeterDevices = this.gazMeterDevices.filter((device) => !this.selectedGazMeterDevices.includes(device));

    for (let device of config.customPreferences.waterDevices) {
      for (let waterDevice of this.waterMeterDevices) {
        if (waterDevice.idx == device.idx) {
          this.selectedWaterMeterDevices.push(waterDevice);
          break;
        }
      }
    }
    this.waterMeterDevices = this.waterMeterDevices.filter(
      (device) => !this.selectedWaterMeterDevices.includes(device)
    );
  }

  saveConfig() {
    let cfg = this.configForm.value;

    const elecDevices = [];
    for (let device of this.selectedElecMeterDevices) {
      elecDevices.push({
        idx: device.idx,
      });
    }

    const gazDevices = [];
    for (let device of this.selectedGazMeterDevices) {
      gazDevices.push({
        idx: device.idx,
      });
    }

    const waterDevices = [];
    for (let device of this.selectedWaterMeterDevices) {
      waterDevices.push({
        idx: device.idx,
      });
    }

    let customPreferences = {
      name: cfg.name,
      showElec: cfg.showElec,
      showGaz: cfg.showGaz,
      showWater: cfg.showWater,
      elecDevices,
      gazDevices,
      waterDevices,
    };

    this.isConfigModalOpen = false;
    let { page } = this.widgetService.setWidgetCustomPreferences(this.page, this.config.uniqueId, customPreferences);
    this.store.dispatch(new fromStore.UpdatePage(page));
  }

  renderChart() {
    const xValue = this.xValue;

    this.chartData = [];
    if (this.config?.customPreferences?.showElec && this.config?.customPreferences?.elecDevices?.length > 0) {
      this.chartData.push({
        x: xValue,
        y: this.consumptions[ELEC],
        type: 'bar',
        name: 'Elec',
        marker: { color: '#FDC543' },
        hovertemplate: '%{y} kWh',
      });
    }
    if (this.config?.customPreferences?.showGaz && this.config?.customPreferences?.gazDevices?.length > 0) {
      this.chartData.push({
        x: xValue,
        y: this.consumptions[GAZ],
        type: 'bar',
        name: 'Gaz',
        marker: { color: ' #63D491' },
        hovertemplate: '%{y} m3',
      });
    }
    if (this.config?.customPreferences?.showWater && this.config?.customPreferences?.waterDevices?.length > 0) {
      this.chartData.push({
        x: xValue,
        y: this.consumptions[WATER],
        type: 'bar',
        name: 'Eau',
        marker: { color: '#254F75' },
        hovertemplate: '%{y} m3',
      });
    }
  }
}
