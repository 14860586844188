<div *ngIf="isOtherCategory">
  <i
    class="fa fa-language"
    (click)="translate()"
    pTooltip="{{ 'TRANSLATE' | translate }}"
    tooltipPosition="top"
    appendTo="body"
  ></i>
  {{ text }}
</div>

<div *ngIf="!isOtherCategory">{{ findPresetByLocale() }}</div>
