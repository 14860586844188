import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SelectItem } from 'primeng/api';
import { Scene } from '../models/scene';
import { Site } from '@widgets/sites/models/site';
import { Location } from '@widgets/locations/models/location';

// import { Subject } from 'rxjs/Subject';

import { DevicesService } from '@widgets/devices/services/devices.service';
// import { LocationsService } from '@widgets/locations/services/locations.service';
import { SceneFormsService } from '../services/scene-forms.service';
import { ScenesService } from '../services/scenes.service';
import { SceneTemplatesService } from '@widgets/scene-templates/services/scene-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-scene-form',
  templateUrl: 'scene-form.component.html'
})
export class SceneFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: Scene;
  isSubmitting = false;

  rawData: { [key: string]: any[] } = {};
  selectItems: { [key: string]: SelectItem[] } = {};

  // locationDetails: any;
  // private componentDestroyed: Subject<void> = new Subject();

  private selectedLocation: Location;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private devicesService: DevicesService,
    private formService: SceneFormsService,
    // private locationsService: LocationsService,
    private sceneTemplatesService: SceneTemplatesService,
    private scenesService: ScenesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // get selected location info
    // this.locationsService
    //   .getSelectedLocation()
    //   // .takeUntil(this.componentDestroyed)
    //   .subscribe(result => {
    //     if (result) {
    //       this.locationDetails = result;
    //     }
    //   });

    this.selectedSite = this.sharedService.selectedSite;

    // Watch location id from route params
    this.subscribers.LOCATION_ID = this.activatedRoute.paramMap.subscribe(
      (params: any) => {
        const pp = params.params;
        if (
          pp.pageId === 'locations' &&
          pp.pageParams &&
          !isNaN(pp.pageParams)
        ) {
          this.selectedLocation = { id: +pp.pageParams };

          // this.fetchDataset();
          this.fetchDevices();
          // } else {
          //   return this.router.navigate(['locations']);
        }
      }
    );

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchScene(this.data.id);
    }

    this.fetchSceneTemplates();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchScene(id: number) {
    this.fetchingState++;

    this.subscribers.GET_SCENE = this.scenesService
      .getScene(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.formData.commands = this.sharedService.tryParseJSON(<any>(
          this.formData.commands
        ));

        this.form.patchValue(this.formData);

        this.formService.setCommandFormArray(this.form, this.formData.commands);

        this.fetchingState--;
      });
  }

  fetchDevices() {
    this.fetchingState++;

    const options = {
      companyId: this.selectedSite.companyId,
      siteId: this.selectedSite.id,
      locationId: this.selectedLocation.id
    };

    this.subscribers.GET_SITE_DEVICES = this.devicesService
      .getDevices(options)
      .subscribe((result: any) => {
        this.rawData.devices = result.data.filter(
          (d: any) =>
            d.isActive &&
            !d.isDeleted &&
            d.locationId === this.selectedLocation.id
        );

        this.selectItems.devices = this.sharedService.createSelectItems(
          this.rawData.devices,
          false
        );

        this.fetchingState--;
      });
  }

  fetchSceneTemplates() {
    this.fetchingState++;

    this.subscribers.GET_SCENE_TEMPLATES = this.sceneTemplatesService
      .get()
      .subscribe((result: any) => {
        this.rawData.sceneTemplates = result.data
          .filter(st => st.isActive && !st.isDeleted)
          .map((st: any) => {
            st.commands = this.sharedService.tryParseJSON(st.commands);
            return st;
          });

        this.selectItems.sceneTemplates = this.sharedService.createSelectItems(
          this.rawData.sceneTemplates,
          false
        );

        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formService.initSceneForm();

    if (this.editMode === true) {
      this.form.get('sceneTemplateId').disable();
    } else {
      this.subscribers.SCENE_TEMPLATE_ID = this.form
        .get('sceneTemplateId')
        .valueChanges.subscribe(value => {
          if (value) {
            this.setSceneInfo(value);
          }
        });
    }
  }

  setSceneInfo(sceneTemplateId: number) {
    if (this.rawData && this.rawData.sceneTemplates) {
      const template = this.rawData.sceneTemplates.find(
        st => st.id === sceneTemplateId
      );

      if (template) {
        this.form.patchValue({
          name: template.name,
          description: template.description
        });

        this.formService.setCommandFormArray(this.form, template.commands);
      }
    }
  }

  get sceneTemplateId() {
    return this.form.get('sceneTemplateId').value;
  }

  // ____ COMMANDS FORM ARRAY CONTROL
  get commandsFormArray() {
    return this.form.get('commands') as FormArray;
  }

  // addCommand() {
  //   // const controls = <FormArray>this.form.controls['commands'];
  //   // controls.push(this.formService.initCommandFormGroup());
  //   this.commandsFormArray.push(this.formService.initCommandFormGroup());
  // }

  // removeCommand(index: number) {
  //   // const controls = <FormArray>this.form.controls['commands'];
  //   // controls.removeAt(index);
  //   this.commandsFormArray.removeAt(index);
  // }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };

      if (!this.editMode) {
        formData.companyId = this.selectedSite.companyId;
        formData.siteId = this.selectedSite.id;
        formData.locationId = this.selectedLocation.id;
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(data: Scene) {
    this.subscribers.CREATE_SCENE = this.scenesService
      .createScene(data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  update(data: Scene) {
    this.subscribers.UPDATE_SCENE = this.scenesService
      .updateScene(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE_SCENE = this.scenesService
      .deleteScene(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('FORM_FIELD');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
