<form [formGroup]="form" novalidate>
  <!-- HEADER -->
  <div class="ui-g">
    <div class="ui-g-12">
      <h4 class="mb-0">{{ 'SELECTBOX_SETTING' | translate }}</h4>
    </div>
  </div>

  <!-- BODY -->
  <div class="ui-g">
    <!-- DATASET FIELD -->
    <div class="ui-g-12">
      <sc-dropdown
        controlName="dataset"
        [form]="form"
        label="{{ 'SELECTBOX_SETTING_DATASET' | translate }}"
        [options]="selectItems['dataset']"
      ></sc-dropdown>
    </div>

    <!-- INCLUDING_INACTIVE -->
    <div class="ui-g-12 ui-lg-6">
      <sc-checkbox-input
        controlName="includingInactive"
        [form]="form"
        label="{{ 'SELECTBOX_SETTING_INCLUDING_INACTIVE' | translate }}"
      ></sc-checkbox-input>
    </div>

    <!-- INCLUDING_DELETED -->
    <div class="ui-g-12 ui-lg-6">
      <sc-checkbox-input
        controlName="includingDeleted"
        [form]="form"
        label="{{ 'SELECTBOX_SETTING_INCLUDING_DELETED' | translate }}"
      ></sc-checkbox-input>
    </div>

    <!-- FILTERS -->
    <div class="ui-g-12">
      <div class="ui-g-10 ui-g-nopad">
        <h5 class="mb-0 mt-3">
          {{ 'SELECTBOX_SETTING_FILTER_BY' | translate }}
        </h5>
      </div>

      <div class="ui-g-2 ui-g-nopad text-right">
        <button
          pButton
          type="button"
          class="ui-button-success mt-2"
          icon="fa fa-plus"
          (click)="addFilter()"
          pTooltip="{{ 'ADD' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></button>
      </div>
    </div>

    <!-- CONNECTOR -->
    <div class="ui-g-6 ui-md-2">
      <sc-dropdown
        controlName="filterConnector"
        [form]="form"
        label="{{ 'CONNECTOR' | translate }}"
        [options]="selectItems['connectors']"
      ></sc-dropdown>
    </div>

    <div class="ui-g-12 ui-g-nopad" formArrayName="filters">
      <div
        *ngFor="let ctrl of filters.controls; let i = index"
        [formGroupName]="i"
      >
        <!-- KEY -->
        <div class="ui-g-12 ui-md-5">
          <sc-text-input
            controlName="key"
            [form]="ctrl"
            label="{{ 'key' | translate }}"
          ></sc-text-input>
        </div>

        <!-- VALUE -->
        <div class="ui-g-12 ui-md-5">
          <sc-text-input
            controlName="value"
            [form]="ctrl"
            label="{{ 'VALUE' | translate }}"
          ></sc-text-input>
        </div>

        <!-- ACTION -->
        <div class="ui-g-12 ui-md-2 text-right">
          <button
            pButton
            type="button"
            class="ui-button-warning mt-3"
            icon="fa fa-trash"
            (click)="removeFilter(i)"
            pTooltip="{{ 'REMOVE' | translate }}"
            tooltipPosition="top"
            appendTo="body"
          ></button>
        </div>
      </div>
    </div>
  </div>
</form>
