import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationBenchmarkActionCellComponent } from './location-benchmark-action-cell/location-benchmark-action-cell.component';
import { LocationBenchmarkDetailComponent } from './location-benchmark-detail/location-benchmark-detail.component';
import { LocationBenchmarkFormComponent } from './location-benchmark-form/location-benchmark-form.component';
import { LocationBenchmarksTableComponent } from './location-benchmarks-table/location-benchmarks-table.component';
import { LocationBenchmarkAnalyseFormComponent } from './analyse-form/analyse-form.component';

const EXPORT_COMPONENTS: any[] = [
  LocationBenchmarkDetailComponent,
  LocationBenchmarkFormComponent,
  LocationBenchmarkAnalyseFormComponent,
  LocationBenchmarksTableComponent
];
const COMPONENTS: any[] = [LocationBenchmarkActionCellComponent];

@NgModule({
  imports: [SharedModule],
  exports: [...EXPORT_COMPONENTS],
  declarations: [...EXPORT_COMPONENTS, ...COMPONENTS],
  entryComponents: [...EXPORT_COMPONENTS, ...COMPONENTS]
})
export class LocationBenchmarksModule {}
