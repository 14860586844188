import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { HousekeepingComponent } from './housekeeping.component';
import { CustomBarComponent } from './custom-bar/custom-bar.component';

@NgModule({
  imports: [SharedModule],
  declarations: [HousekeepingComponent, CustomBarComponent],
  entryComponents: [HousekeepingComponent]
})
export class HousekeepingModule {}
