import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-energy-efficiency-rating-rating-cell',
  templateUrl: 'rating-cell.component.html'
})
export class EnergyEfficiencyRatingRatingCellComponent
  implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }

  get rating() {
    if (this.cell && this.cell.data && this.cell.data.rating) {
      return this.cell.data.rating + ' %';
    }
    return '';
  }

  get numberOfLeaves() {
    if (this.cell && this.cell.data && this.cell.data.rating) {
      if (this.cell.data.rating >= 90) {
        return [1, 1, 1, 1, 1];
      } else if (this.cell.data.rating >= 80) {
        return [1, 1, 1, 1];
      } else if (this.cell.data.rating >= 70) {
        return [1, 1, 1];
      } else if (this.cell.data.rating >= 60) {
        return [1, 1];
      } else if (this.cell.data.rating >= 50) {
        return [1];
      }
    }
    return [];
  }
}
