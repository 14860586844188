import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationSavingTableComponent } from './location-saving-table.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationSavingTableComponent],
  entryComponents: [LocationSavingTableComponent],
  providers: []
})
export class LocationSavingTableModule {}
