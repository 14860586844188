import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NotificationTemplate } from '@app/widgets/notification-templates/models/notification-template';
import { ReportTemplate } from '@app/widgets/report-templates/interfaces';
import { RuleCategory } from '@app/widgets/rule-categories/models/rule-category';
import { UserTaskTemplate } from '@app/widgets/user-task-templates/interfaces';
import {
  DataSources,
  CustomFunction,
  CustomGlobal,
  Device,
  DeviceType,
  Location,
  LocationProperty,
  RuleTemplate,
  Rule,
  RuleEditorModes,
  SelectItem,
  SelectItems,
  Subscribers,
} from '../../models';
import { RulesService, UtilsService } from '../../services';

@Component({
  selector: 'sc-rule-template-overview',
  templateUrl: './rule-template-overview.component.html',
  styleUrls: ['./rule-template-overview.component.scss'],
})
export class RuleTemplateOverviewComponent implements OnInit, OnChanges, OnDestroy {
  dataSources: DataSources = {};
  selectItems: SelectItems = {};
  viewModes: SelectItem[];

  @Input() automationId: number;
  @Input() ruleTemplate: RuleTemplate;
  @Input() rules: Rule[];
  @Input() customFunctions: CustomFunction[];
  @Input() customGlobals: CustomGlobal[];
  @Input() deviceTypes: DeviceType[];
  @Input() locationProperties: LocationProperty[];
  @Input() notificationTemplates: NotificationTemplate[];
  @Input() ruleCategories: RuleCategory[];
  @Input() reportTemplates: ReportTemplate[];
  @Input() userTaskTemplates: UserTaskTemplate[];

  private subscribers: Subscribers = {};

  constructor(private rulesService: RulesService, private utilsService: UtilsService) {}

  ngOnInit(): void {
    if (this.ruleTemplate && typeof this.ruleTemplate.editor_view === 'undefined') {
      this.ruleTemplate.editor_view = RuleEditorModes.Normal;
    }

    this.loadFormData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customFunctions && changes.customFunctions.currentValue) {
      this.manageData('customFunctions', changes.customFunctions.currentValue);
    }
    if (changes.customGlobals && changes.customGlobals.currentValue) {
      this.manageData('customGlobals', changes.customGlobals.currentValue, 'label', 'key');
    }
    if (changes.deviceTypes && changes.deviceTypes.currentValue) {
      this.manageData('deviceTypes', changes.deviceTypes.currentValue, 'label', 'key');
    }
    if (changes.locationProperties && changes.locationProperties.currentValue) {
      this.manageData('locationProperties', changes.locationProperties.currentValue, 'label', 'key');
    }
    if (changes.rules && changes.rules.currentValue) {
      this.manageData('rules', changes.rules.currentValue);
    }
    if (changes.notificationTemplates && changes.notificationTemplates.currentValue) {
      this.manageData('notificationTemplates', changes.notificationTemplates.currentValue);
    }
    if (changes.reportTemplates && changes.reportTemplates.currentValue) {
      this.manageData('reportTemplates', changes.reportTemplates.currentValue);
    }
    if (changes.userTaskTemplates && changes.userTaskTemplates.currentValue) {
      this.manageData('userTaskTemplates', changes.userTaskTemplates.currentValue);
    }
  }

  ngOnDestroy() {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private loadFormData() {
    // set available views
    this.viewModes = [
      { label: RuleEditorModes.ExpertLabel, value: RuleEditorModes.Expert },
      { label: RuleEditorModes.AdvancedLabel, value: RuleEditorModes.Advanced },
      { label: RuleEditorModes.NormalLabel, value: RuleEditorModes.Normal },
      { label: RuleEditorModes.BackendLabel, value: RuleEditorModes.Backend },
    ];

    // from rule structure
    this.dataSources.absoluteDatetimePatterns = this.rulesService.getAbsoluteDatetimePatternsStructures();
    this.dataSources.operands = this.rulesService.getOperandStructures();
    this.dataSources.operators = this.rulesService.getOperatorStructures();

    this.selectItems.absoluteDatetimePatterns = this.dataSources.absoluteDatetimePatterns.map((item) =>
      this.utilsService.selectItemMapper(item, 'label', 'id')
    );
    this.selectItems.operators = this.dataSources.operators.map((item) =>
      this.utilsService.selectItemMapper(item, 'label', 'id')
    );
  }

  private manageData(type: string, data: any[], labelKey = 'name', valueKey = 'idx') {
    this.dataSources[type] = data;
    this.selectItems[type] = data.map((item) => this.utilsService.selectItemMapper(item, labelKey, valueKey));

    if (type === 'deviceTypes') {
      this.dataSources.writeableDeviceTypes = data.filter((item) => item.writeable);
      this.selectItems.writeableDeviceTypes = this.dataSources.writeableDeviceTypes.map((item) =>
        this.utilsService.selectItemMapper(item, 'label', 'key')
      );
    }
  }
}
