import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectItem } from 'primeng/api';
import { Device as DeviceDefault } from '@widgets/devices/models/device';
import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';
import { DeviceWizard } from '../device-wizard';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { ProtocolController } from '@widgets/devices/models/protocol-controller';

import { DeviceHistoryLogsService } from '@widgets/devices/services/device-history-logs.service';
import { DeviceFormsService } from '@widgets/devices/services/device-forms.service';
import { DeviceModelFormsService } from '@widgets/device-models/services/device-model-forms.service';
import { DeviceWizardService } from '../device-wizard.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { SharedService } from '@shared/shared.service';

import { config as appConfig } from '@app/config';

interface Device extends DeviceDefault, DeviceWizard {
  unit?: string;
  value?: any;
  valueLabel?: string;
  virtualDevices?: Device[];
}

@Component({
  selector: 'sc-create-wizard',
  templateUrl: './create-wizard.component.html',
  styleUrls: ['./create-wizard.component.scss'],
})
export class CreateWizardComponent implements OnInit, OnDestroy {
  @ViewChild('createWizardContent', { static: true })
  public createWizardContent;

  errorMessage: null;
  form: FormArray;
  formData: Device[];
  isFetchingDHL = false;
  isSubmitting = false;
  productImage: string;
  searchResult: DeviceWizard[] = [];
  wasCallFromFloorplan = false;
  selectItems: { [prop: string]: SelectItem[] } = {};
  selectedAutomation: Device;
  selectedDevice: Device;
  selectedLocation: Location;
  locationsToControl: Location[];
  locationDevices: Device[];
  summarySource: any;
  wizardStep = 1;
  totalStep = 4;

  private automations: Device[] = [];
  private devices: DeviceWizard[] = [];
  private locations: Location[] = [];
  private deviceModels: DeviceModel[] = [];
  private deviceLatestHistory: any[] = [];
  private protocolControllers: ProtocolController[] = [];
  private preSelectedLocation: number;
  private intervals: { [prop: string]: any } = {};
  private siteDevices: Device[] = [];
  private selectedSite: Site;
  private subscribers: { [prop: string]: any } = {};
  private timeouts: { [prop: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private deviceFormsService: DeviceFormsService,
    private deviceModelFormsService: DeviceModelFormsService,
    private deviceWizardService: DeviceWizardService,
    private devicesService: DevicesService,
    private locationsService: LocationsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;
    this.selectItems.zigbeeControllerPorts = this.sharedService.selectItems.zigbeeControllerPorts;
    this.selectItems.zwaveControllerPorts = this.sharedService.selectItems.zwaveControllerPorts;

    if (this.config && this.config.data) {
      if (this.config.data.deviceTypes) {
        this.selectItems.deviceTypes = this.sharedService.createSelectItems(
          this.config.data.deviceTypes.filter((d) => d.isActive && !d.isDeleted)
        );
      }

      if (this.config.data.deviceProtocols) {
        this.selectItems.deviceProtocols = this.sharedService.createSelectItems(
          this.config.data.deviceProtocols.filter((d) => d.isActive && !d.isDeleted)
        );
      }

      if (this.config.data.deviceModels) {
        this.deviceModels = this.config.data.deviceModels;
      }

      if (this.config.data.protocolControllers) {
        this.protocolControllers = this.config.data.protocolControllers;
        this.selectItems.zwaveControllerSoftwares = this.sharedService.createSelectItems(
          this.protocolControllers.filter((d) => d.isActive && !d.isDeleted)
        );
      }

      if (this.config.data.selectedLocation) {
        this.preSelectedLocation = this.config.data.selectedLocation;
      }

      if (this.config.data.selectedModel) {
        this.wasCallFromFloorplan = true;
        this.onDeviceSelected(this.config.data.selectedModel);
      }
    }

    this.fetchDeviceModels();
    this.fetchLocations();
    this.fetchSiteDevices();
    this.fetchLatestHistory();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchLocations() {
    const options = {
      siteId: this.selectedSite.id,
      columns: 'id,description,floorplanImage,isActive,isDeleted',
    };
    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe({
      next: (result: any) => {
        this.locations = result.data;
        this.selectItems.locations = this.sharedService.createSelectItems(result.data.filter((d) => !d.isDeleted));
        this.selectItems.unitLocations = this.sharedService.createSelectItems(
          result.data.filter((d) => !d.isDeleted && d.locationTypeKey === 'u')
        );
      },
    });
  }

  private fetchDeviceModels() {
    this.subscribers.search = this.deviceWizardService.getDevices().subscribe({
      next: (result: any) => {
        this.devices = result.data;
        this.searchResult = this.sharedService.sortBy(result.data, 'modelName');
      },
      error: (error) => {
        this.devices = [];
        this.searchResult = [];
      },
    });
  }

  private fetchSiteDevices() {
    const options = {
      siteId: this.selectedSite.id,
      // deviceTypeKey: 'a',
      columns:
        'id,description,locationId,instructionPositionX,instructionPositionY,virtualDeviceHolderId,isActive,isDeleted',
    };
    this.subscribers.getDevices = this.devicesService.getDevices(options).subscribe({
      next: (result: any) => {
        this.automations = [];
        this.siteDevices = [];

        for (const device of result.data) {
          if (device.isDeleted || !device.isActive) {
            continue;
          }

          if (device.deviceTypeKey === 'a') {
            this.automations.push(device);
          }
          this.siteDevices.push(device);
        }

        this.selectItems.automations = this.sharedService.createSelectItems(this.automations);
      },
    });
  }

  private fetchLatestHistory() {
    if (this.isFetchingDHL) {
      return;
    }
    this.isFetchingDHL = true;
    const options: any = {
      filter: {
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id,
      },
      latestLog: true,
      limit: 5000,
    };
    if (this.selectedLocation && this.selectedLocation.id) {
      options.filter.locationId = this.selectedLocation.id;
    }
    this.subscribers.getLatestHistory = this.deviceHistoryLogsService
      .getHistoryLogs(options)
      .subscribe((result: any) => {
        this.deviceLatestHistory = result;
        this.patchVirtualDeviceValues();
        this.isFetchingDHL = false;
      });
  }

  private startAutoUpdate() {
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = setInterval(() => this.fetchLatestHistory(), 10000);
  }

  private stopAutoUpdate() {
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = null;
  }

  onSearchValueChanged(event) {
    if (this.timeouts.search) {
      clearTimeout(this.timeouts.search);
    }
    this.timeouts.search = setTimeout(() => {
      this.updateSearchResult(event);
    }, 500);
  }

  private updateSearchResult(searchTerm: { [prop: string]: any }) {
    let deviceProtocols: number[];
    let deviceTypes: number[];
    let search: string;
    const searchResult: DeviceWizard[] = [];
    this.searchResult = [];

    if (searchTerm) {
      if (searchTerm.search) {
        search = searchTerm.search;
      }
      if (searchTerm.deviceProtocols && searchTerm.deviceProtocols.length) {
        deviceProtocols = searchTerm.deviceProtocols;
      }
      if (searchTerm.deviceTypes && searchTerm.deviceTypes.length) {
        deviceTypes = searchTerm.deviceTypes;
      }
    }

    for (const device of this.devices) {
      // check if virtual device holder match the device protocol
      if (deviceProtocols && deviceProtocols.indexOf(device.protocolId) < 0) {
        // virtual device holder does not match the device protocol
        continue;
      }

      // check if virtual device holder match the device type
      if (deviceTypes && deviceTypes.indexOf(device.deviceTypeId) < 0) {
        // check if any virtual devices match the device type
        let found = false;
        if (device.virtualDevices && device.virtualDevices.length) {
          for (const vd of device.virtualDevices) {
            if (deviceTypes.indexOf(vd.deviceTypeId) >= 0) {
              // one of virtual device match the device type
              found = true;
              break;
            }
          }
        }
        if (!found) {
          // virtual device holder and virtual devices do not match the device type
          continue;
        }
      }

      // check if virtual device holder match the search term
      if (search) {
        let found = false;
        const searchRegex = new RegExp(`${search}(.+)?`, 'gi');
        if (
          searchRegex.test(device.model) ||
          searchRegex.test(device.modelName) ||
          searchRegex.test(device.deviceTypeName) ||
          searchRegex.test(device.deviceTypeKey) ||
          searchRegex.test(device.protocolName) ||
          searchRegex.test(device.brandName)
        ) {
          // virtual device holder match search term
          found = true;
        }
        if (!found) {
          // virtual device holder does not match search term then skip
          continue;
        }
      }

      searchResult.push(device);
    }

    this.searchResult = this.sharedService.sortBy(searchResult, 'modelName');
  }

  private patchVirtualDeviceValues() {
    if (!this.locationDevices || !this.locationDevices.length || !this.deviceLatestHistory) {
      return;
    }

    // patch other devices
    for (const d of this.locationDevices) {
      if (!d.virtualDevices || d.deviceTypeKey === 'a') {
        continue;
      }

      for (const vd of d.virtualDevices) {
        if (!vd.virtualDeviceHolderId) {
          continue;
        }

        vd.unit = this.devicesService.deviceValueUnit(vd.deviceTypeKey);
        vd.value = '-';
        vd.valueLabel = this.devicesService.deviceValueLabel(vd.deviceTypeKey);

        const latestValue = this.deviceLatestHistory.find((lh) => vd.id + '' === lh.deviceId + '');
        if (latestValue) {
          const model = this.deviceModels.find((dm) => dm.id + '' === vd.deviceModelId + '');
          vd.value = this.devicesService.deviceValueMapper(latestValue.value, model);
        }
      }

      d.virtualDevices = this.sharedService.sortBy(d.virtualDevices, 'valueLabel');
    }

    // patch selected device
    this.selectedDevice.description = this.form.controls[0].get('description').value;
    let count = 1;
    if (this.selectedDevice.deviceTypeKey === 'vdh') {
      for (const vd of this.selectedDevice.virtualDevices) {
        vd.description = this.form.controls[count].get('description').value;
        vd.unit = this.devicesService.deviceValueUnit(vd.deviceTypeKey);
        vd.value = '-';
        vd.valueLabel = this.devicesService.deviceValueLabel(vd.deviceTypeKey);
        count++;
      }
      this.selectedDevice.virtualDevices = this.sharedService.sortBy(this.selectedDevice.virtualDevices, 'valueLabel');
    }
  }

  private unparseDeviceValues(data: Device) {
    // convert seconds into milliseconds
    if (typeof data.configFetchInterval === 'number') {
      data.configFetchInterval = this.sharedService.numberFormat(data.configFetchInterval * 1000);
    }

    // convert seconds into milliseconds
    if (typeof data.hlSenderInterval === 'number') {
      data.hlSenderInterval = this.sharedService.numberFormat(data.hlSenderInterval * 1000);
    }

    return data;
  }

  private createDevicesForm() {
    if (!this.selectedDevice) {
      return;
    }

    const devicesFrom = [];

    // automation or virtual device holder
    const vdhForm = this.deviceFormsService.initDeviceForm();
    const vdhPatch: any = {
      description: this.selectedDevice.modelName,
      brandId: this.selectedDevice.brandId,
      deviceTypeId: this.selectedDevice.deviceTypeId,
      deviceModelId: this.selectedDevice.modelId,
      protocolId: this.selectedDevice.protocolId,
      isActive: true,
    };
    const vhdModel: any = this.deviceModels.find((d) => d.id === this.selectedDevice.modelId);

    if (this.selectedDevice.deviceTypeKey === 'vdh') {
      vdhForm.get('automationId').setValidators(Validators.required);
      vdhPatch.isVirtualDevicesHolder = true;

      if (vhdModel.behaviourPatterns) {
        this.deviceModelFormsService.setBehaviourPatternsFormArray(vdhForm, JSON.parse(vhdModel.behaviourPatterns));
      }
    } else if (this.selectedDevice.deviceTypeKey === 'a') {
      vdhPatch.zwaveStatus = vhdModel.zwaveStatus;
      vdhPatch.zwaveControllerSoftware = +vhdModel.zwaveControllerSoftware;
      vdhPatch.zwaveControllerPort = vhdModel.zwaveControllerPort;
      vdhPatch.zigbeeStatus = vhdModel.zigbeeStatus;
      vdhPatch.zigbeeControllerPort = vhdModel.zigbeeControllerPort;
    }
    vdhForm.patchValue(vdhPatch);
    devicesFrom.push(vdhForm);

    if (this.selectedDevice.virtualDevices && this.selectedDevice.virtualDevices.length) {
      for (const vd of this.selectedDevice.virtualDevices) {
        const vdForm = this.deviceFormsService.initDeviceForm();
        const vdPatch = {
          description: vd.deviceTypeName,
          brandId: vd.brandId,
          deviceTypeId: vd.deviceTypeId,
          deviceModelId: vd.modelId,
          protocolId: vd.protocolId,
          considerForOccupancy: vd.deviceTypeKey === 'ms' ? true : false,
          isVirtualDevice: true,
          isActive: true,
        };
        const vdModel: any = this.deviceModels.find((d) => d.id === vd.modelId);

        if (vdModel.behaviourPatterns) {
          this.deviceModelFormsService.setBehaviourPatternsFormArray(vdForm, JSON.parse(vdModel.behaviourPatterns));
        }

        vdForm.get('automationId').setValidators(Validators.required);
        vdForm.patchValue(vdPatch);
        devicesFrom.push(vdForm);
      }
    }

    this.form = new FormArray(devicesFrom, Validators.required);

    // watch location id of VDH
    this.subscribers.locationField = this.form.controls[0].get('locationId').valueChanges.subscribe({
      next: (result) => {
        if (result) {
          const controls = this.form.controls;
          if (controls.length > 1) {
            for (let i = 1; i < controls.length; i++) {
              controls[i].patchValue({ locationId: result });
            }
          }

          // update data from history logs
          if (this.locations) {
            this.selectedLocation = this.locations.find((l) => l.id === result);
            if (this.selectedLocation && this.siteDevices) {
              const tmpLocationDevices = {};
              const tmpVirtualDevices = [];

              for (const d of this.siteDevices) {
                if (d.locationId !== this.selectedLocation.id) {
                  continue;
                }

                if (d.deviceTypeKey === 'vdh' || d.deviceTypeKey === 'a') {
                  tmpLocationDevices[d.id] = d;

                  if (d.deviceTypeKey === 'vdh') {
                    tmpLocationDevices[d.id].virtualDevices = [];
                  }
                } else {
                  tmpVirtualDevices.push(d);
                }
              }

              for (const d of tmpVirtualDevices) {
                if (d.virtualDeviceHolderId && tmpLocationDevices[d.virtualDeviceHolderId]) {
                  tmpLocationDevices[d.virtualDeviceHolderId].virtualDevices.push(d);
                }
              }

              this.locationDevices = Object.keys(tmpLocationDevices).map((id) => tmpLocationDevices[id]);
              this.patchVirtualDeviceValues();
            }
          }
        }
      },
    });

    if (this.selectedDevice.deviceTypeKey === 'vdh') {
      // watch automation id of VDH
      this.subscribers.automationField = this.form.controls[0].get('automationId').valueChanges.subscribe({
        next: (result) => {
          if (result) {
            const controls = this.form.controls;
            if (controls.length > 1) {
              for (let i = 1; i < controls.length; i++) {
                controls[i].patchValue({ automationId: result });
              }
            }
            if (this.automations) {
              this.selectedAutomation = this.automations.find((a) => a.id === result);
            }
          }
        },
      });
    } else if (this.selectedDevice.deviceTypeKey === 'a') {
      // watch automation id of VDH
      this.subscribers.locationsToControlField = this.form.controls[0]
        .get('locationsToControl')
        .valueChanges.subscribe({
          next: (result) => {
            if (result && this.locations) {
              this.locationsToControl = this.locations.filter((l) => result.indexOf(l.id) >= 0);
            }
          },
        });
    }

    if (this.preSelectedLocation) {
      this.form.controls[0].patchValue({
        locationId: this.preSelectedLocation,
      });
    }
  }

  private resetScroll() {
    this.createWizardContent.nativeElement.scrollTop = 0;
  }

  onDeviceSelected(event) {
    this.selectedDevice = event;
    this.createDevicesForm();

    if (this.selectedDevice.productImage) {
      this.productImage = appConfig().s3Url + '/' + this.selectedDevice.productImage;
    }
    this.resetScroll();
    this.wizardStep = 2;
  }

  onPositionChanged(event) {
    const controls = this.form.controls;
    controls[0].patchValue({
      instructionPositionX: event.x,
      instructionPositionY: event.y,
    });
  }

  onBackClicked() {
    this.resetScroll();
    this.wizardStep -= 1;

    if (this.wizardStep === 1) {
      this.productImage = null;
      this.selectedDevice = null;
      this.selectedLocation = null;
      this.selectedAutomation = null;
    } else if (this.wizardStep === 3 && this.wasCallFromFloorplan) {
      this.onBackClicked();
    }
  }

  onNextClicked() {
    this.resetScroll();
    this.wizardStep += 1;

    if (this.wizardStep === this.totalStep && this.form.valid) {
      this.formData = [];
      const formData = this.form.value;

      for (let i = 0; i < formData.length; i++) {
        const item = this.unparseDeviceValues(formData[i]);

        // index 0: it can be automation or virtual device holder then it will always create
        // index > 0: it's a virtual device and if it's inactive then it will not be created
        if (i === 0 || item.isActive) {
          this.formData.push(item);
        }
      }

      const locationId = this.form.controls[0].get('locationId').value || this.preSelectedLocation;
      const location = this.locations.find((l) => l.id === locationId);

      this.summarySource = {
        automation: this.selectedAutomation,
        device: this.selectedDevice,
        location,
      };

      if (this.selectedDevice.deviceTypeKey === 'a') {
        if (this.locationsToControl && this.locationsToControl.length) {
          this.summarySource.locationsToControl = this.locationsToControl.map((l) => l.description);
        }
        if (this.protocolControllers && this.formData[0].zwaveControllerSoftware) {
          const controller = this.protocolControllers.find(
            (c) => c.id + '' === this.formData[0].zwaveControllerSoftware + ''
          );
          if (controller) {
            this.summarySource.zwaveControllerSoftware = controller.name;
          }
        }
      }
    }

    if (this.wizardStep === 3) {
      if (this.wasCallFromFloorplan) {
        this.onNextClicked();
      } else {
        this.startAutoUpdate();
      }
    } else {
      this.stopAutoUpdate();
    }
  }

  onCreateClicked() {
    if (this.isSubmitting === true) {
      return;
    }
    this.isSubmitting = true;
    this.subscribers.create = this.deviceWizardService.createDevices(this.formData).subscribe({
      next: (result: any) => {
        const text = this.sharedService.getTranslation('CREATE_SUCCESS');
        const title = this.sharedService.getTranslation('DEVICE');
        this.sharedService.notify(title, text, 'success');
        this.dialog.close(result.data);
        this.isSubmitting = false;
      },
      error: (error) => {
        this.errorMessage = error;
        this.isSubmitting = false;
      },
    });
  }
}
