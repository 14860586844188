<div class="sc-flex justify-content-end transparent" style="height: 30px">
  <div class="p-1" *ngIf="!isCeosVariant">
    {{ selectedLocations?.length || 0 }}
    {{ "SELECTED_LOCATIONS" | translate }}
  </div>

  <!-- <sc-modal-trigger
      buttonIcon="fa fa-exclamation-triangle"
      buttonLabel="{{ 'RULE_MASS_EDIT' | translate }}"
      [modalConfig]="ruleMassEditFormConfig"
    ></sc-modal-trigger> -->

  <ng-container *ngIf="!isCeosVariant">
    <sc-modal-trigger
      *scHasPermission="'location_bulk_modification'"
      buttonIcon="fa fa-pencil"
      buttonLabel="{{ 'BULK_MODIFICATION' | translate }}"
      [modalConfig]="bulkModificationFormConfig"
      tooltipContent="{{ 'LOCATION' | translate }} {{
        'BULK_MODIFICATION' | translate
      }}"
      [disabled]="!selectedLocations?.length"
    ></sc-modal-trigger>
  </ng-container>

  <sc-modal-trigger
    buttonIcon="fa fa-line-chart"
    buttonLabel="{{ 'GRAPH' | translate }}"
    [modalConfig]="deviceComparisonModalConfig"
    tooltipContent="{{ 'DEVICE' | translate }} {{ 'GRAPH' | translate }}"
    [disabled]="!enabledDeviceCompare"
  ></sc-modal-trigger>

  <sc-modal-trigger
    buttonIcon="fa fa-line-chart"
    buttonLabel="{{ 'STATISTICS' | translate }}"
    tooltipContent="{{ 'LOCATION' | translate }} {{ 'GRAPH' | translate }}"
    [modalConfig]="statisticsFormConfig"
  ></sc-modal-trigger>

  <ng-container *ngIf="!isCeosVariant">
    <sc-modal-trigger
      *scHasPermission="'location_generator'"
      buttonIcon="fa fa-gears"
      buttonLabel="{{ 'GENERATOR' | translate }}"
      [modalConfig]="locationGeneratorModalConfig"
      (onClose)="fetchDataset()"
    ></sc-modal-trigger>
  </ng-container>
</div>

<div style="height: calc(100% - 30px)">
  <sc-table
    [columns]="columns"
    [config]="config"
    [dataset]="dataset"
    [formConfig]="formConfig"
    (reloadData)="fetchDataset()"
    (afterInit)="afterInitTable($event)"
    (customSummarySave)="updateCustomSummary($event)"
    title="{{ tableTitle | translate }}"
    icon="fa fa-location-arrow"
  ></sc-table>
</div>
