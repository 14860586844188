import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceModel } from '../models/device-model';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class DeviceModelService {
  private apiUrl = config().apiUrl + '/deviceModels2';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private verifyData(data: any): DeviceModel {
    const newData = Object.assign({}, data);
    delete newData.id;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    newData.instruction_url = newData.instructions.url;
    delete newData.instructions.url;
    return newData;
  }

  getDeviceModel(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getDeviceModels() {
    return this.httpClient.get(this.apiUrl);
  }

  createDeviceModel(data: DeviceModel) {
    const formData = this.sharedService.createFormData(this.verifyData(data), ['instruction_url']);
    return this.httpClient.post(this.apiUrl, formData);
  }

  updateDeviceModel(id: number, data: DeviceModel) {
    const formData = this.sharedService.createFormData(this.verifyData(data), ['instruction_url']);
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }

  deleteDeviceModel(id: number, force: boolean = false) {
    let apiUrl = `${this.apiUrl}/${id}`;
    if (force === true) {
      apiUrl += '?force';
    }
    const formData = this.sharedService.createFormData({
      is_active: false,
      is_deleted: true,
    });
    return this.httpClient.patch(apiUrl, formData);
  }

  filterDeviceModelsByCeosVersion(ceosVersion: string, deviceModels: DeviceModel[]): DeviceModel[] {
    switch (ceosVersion) {
      case 'sc-ceos':
        //Return all devices which are not using Z-Wave JS library
        return deviceModels.filter((deviceModel) => deviceModel.library !== 'zwavejs');
      case 'sc-ceos-v2':
        //Return all devices which are not using Open Z-Wave library
        return deviceModels.filter((deviceModel) => deviceModel.library !== 'ozw');
      default: //return all devices
        return deviceModels;
    }
  }
}
