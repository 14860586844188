<sc-table
  [columns]="columns"
  [config]="config"
  [dataset]="dataset"
  [formConfig]="formConfig"
  (reloadData)="fetchDataset()"
  (afterInit)="afterTableInit($event)"
  [title]="tableTitle | translate"
  icon="fa fa-window-maximize"
></sc-table>
