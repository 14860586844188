import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { Scene } from '../models/scene';
import { Site } from '@widgets/sites/models/site';
import { Location } from '@widgets/locations/models/location';

import { ScenesService } from '../services/scenes.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { SceneActionCellComponent } from '../scene-action-cell/scene-action-cell.component';

@Component({
  selector: 'sc-scenes-table',
  templateUrl: 'scenes-table.component.html'
})
export class ScenesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: Scene[];
  formConfig: ModalConfig;
  isFetchingDataset = false;
  tableTitle: string;

  private selectedLocation: Location;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private scenesService: ScenesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // table name
    this.tableTitle = 'SCENE';
    // table columns
    this.columns = this.createColumns();
    // create form
    this.formConfig = {
      name: 'SceneForm',
      options: { modalTitle: 'SCENE_FORM_TITLE' }
    };

    this.selectedSite = this.sharedService.selectedSite;

    // get location details
    this.subscribers.LOCATION_DETAIL = this.store
      .select(fromStore.getLocationDetials)
      .subscribe(result => {
        if (result) {
          this.selectedLocation = result;
          this.fetchDataset();
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.isFetchingDataset = true;
    const options = {
      locationId: this.selectedLocation.id,
      siteId: this.selectedSite.id
    };
    this.subscribers.GET_SCENES = this.scenesService
      .getScenes(options)
      .subscribe((result: any) => {
        this.dataset = result.data;
        this.isFetchingDataset = false;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: SceneActionCellComponent
      }
    ];
  }
}
