import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {
  @Output()
  upload = new EventEmitter<any[]>();

  fileNames: string[] = [];
  files: any[] = [];

  constructor() { }

  ngOnInit() { }

  onFileBrowsedOrDropped(files: any) {
    for (const f of files) {
      if (this.fileNames.indexOf(f.name) >= 0) {
        continue;
      }
      this.fileNames.push(f.name);
      this.files.push(f);
    }
  }

  deleteFile(index?: number) {
    if (index >= 0) {
      this.files.splice(index, 1);
      this.fileNames.splice(index, 1);
    } else {
      this.files = [];
      this.fileNames = [];
    }
  }

  uploadFile() {
    this.upload.emit(this.files);
    this.files = [];
    this.fileNames = [];
  }

  formatBytes(bytes) {
    if (bytes === 0) {
      return '';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
  }
}
