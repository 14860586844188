<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <!-- HEADER -->
    <div class="ui-g"></div>

    <!-- BODY -->
    <div class="ui-g">
      <!-- FIELDS -->
      <div class="ui-g-12 ui-g-nopad" formArrayName="fields">
        <div class="ui-g">
          <!-- FIELD FORMS -->
          <div class="ui-g-12 ui-lg-6 ui-g-nopad">
            <div class="ui-g">
              <div class="ui-g-10">
                <h2 class="m-0">{{ 'FORM_FIELDS' | translate }}</h2>
              </div>

              <div class="ui-g-2 text-right">
                <button
                  pButton
                  type="button"
                  class="ui-button-success"
                  icon="fa fa-plus"
                  (click)="addField()"
                  pTooltip="{{ 'ADD' | translate }} {{
                    'FORM_FIELD' | translate
                  }}"
                  tooltipPosition="top"
                  appendTo="body"
                ></button>
              </div>
            </div>

            <div
              *ngFor="let ctrl of fields.controls; let i = index"
              [formGroupName]="i"
            >
              <sc-field-form
                [form]="ctrl"
                [formId]="i"
                [data]="fieldData"
                (removed)="removeField(i)"
              ></sc-field-form>
            </div>
          </div>

          <!-- LIVE PREVIEW -->
          <div class="ui-g-12 ui-lg-5 ui-lg-offset-1">
            <h2 class="mt-0">{{ 'LIVE_PREVIEW' | translate }}</h2>

            <p-scrollPanel [style]="{ width: '100%', height: '50vh' }">
              <div
                *ngFor="let p of previewData; trackBy: livePreviewTrackBy"
                class="pb-3"
              >
                <h5 class="m-0">{{ p['name'] }} ({{ p['id'] }}):</h5>

                <ul>
                  <li *ngFor="let f of p['fields']">
                    <span>{{ f['key'] }}:</span>
                    <span class="grey-text"> {{ f['currentValue'] }} </span> =>
                    <strong class="green-text"> {{ f['newValue'] }} </strong>
                  </li>
                </ul>
              </div>
            </p-scrollPanel>
          </div>
        </div>
      </div>

      <!-- ERROR MESSAGE -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6"></div>

      <div class="ui-g-12 ui-md-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>

        <sc-submit-button
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</ng-template>
