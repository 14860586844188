import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeviceWizard } from '../device-wizard';

import { config } from '@app/config';

@Component({
  selector: 'sc-device-item',
  templateUrl: './device-item.component.html',
  styleUrls: ['./device-item.component.scss']
})
export class DeviceItemComponent implements OnInit {
  @Input()
  data: DeviceWizard;

  @Output()
  selected = new EventEmitter<any>();

  isOpened = false;
  productImage: string;

  constructor() { }

  ngOnInit() {
    if (this.data.productImage) {
      this.productImage = config().s3Url + '/' + this.data.productImage;
    }
  }

  onSelectDevice() {
    this.selected.emit(this.data);
  }
}
