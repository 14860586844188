import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SCLoadingIndicatorComponent } from './loading-indicator.component';

export const COMPONENTS: any[] = [SCLoadingIndicatorComponent];

@NgModule({
  imports: [CommonModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class SCLoadingIndicatorModule {}
