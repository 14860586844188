<div class="condition-item" [ngClass]="{ 'condition-item--first': firstItem }">
  <div class="condition-connector" *ngIf="!firstItem">
    {{ connectorLabel | translate }}
  </div>

  <div
    class="condition-info"
    *ngIf="condition?.left"
    (click)="onLeftOperandClick()"
  >
    {{ leftOperandLabel || "CCP_RULE_SELECT_OPERAND" | translate }}
  </div>

  <div
    class="condition-info"
    *ngIf="condition?.operator"
    (click)="onOperatorClick()"
    [ngClass]="{ 'condition-info--disabled': !isAllowOperator }"
  >
    <div *ngIf="!operatorLabel">
      {{ "CCP_RULE_SELECT_OPERATOR" | translate }}
    </div>

    <div *ngIf="operatorLabel">
      {{ operatorLabel }}

      <div *ngIf="operatorParamsLabel">
        {{ operatorParamsLabel }}
      </div>
    </div>
  </div>

  <div
    class="condition-info"
    *ngIf="condition?.right"
    (click)="onRightOperandClick()"
    [ngClass]="{ 'condition-info--disabled': !isAllowRightOperand }"
  >
    {{ rightOperandLabel || "CCP_RULE_SELECT_OPERAND" | translate }}
  </div>

  <!-- *ngIf="isTemplate" -->
  <div class="condition-actions" *ngIf="isTemplate || isFromScratch">
    <sc-action-button
      [menu]="actionMenu"
      (selected)="onActionSelected($event)"
    ></sc-action-button>
  </div>
</div>
