import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { CeosRuleHistoryLog } from '@widgets/rules/models/ceos-rule-history-log';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { Site } from '@widgets/sites/models/site';

import { CeosRuleHistoryLogsService } from '@widgets/rules/services/ceos-rule-history-logs.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-automation-rthl-table',
  templateUrl: 'automation-rthl-table.component.html',
})
export class AutomationRTHLTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: CeosRuleHistoryLog[];
  table: GridOptions;
  tableTitle: string;
  dateFilter: any;
  datasource: any;
  toolbarItems: string[];

  isFetchingDataset: boolean;

  private selectedDevice: DeviceDetails;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};
  private visibleColumns: string[] = [];

  constructor(
    private store: Store<fromStore.State>,
    private ceosRuleHistoryLogsService: CeosRuleHistoryLogsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.config.gridOptions = {
      floatingFilter: false,
      defaultColDef: {
        autoHeight: true,
        suppressFilter: true,
        suppressMenu: true,
        suppressSorting: true,
      },
    };

    // Set Table Name
    this.tableTitle = 'RULE_TRIGGER_HISTORY_LOGS';
    // Set Table Toolbar Items
    this.toolbarItems = ['refresh', 'columns'];
    // Set Table Columns
    this.columns = this.createColumns();
    // Set user's visible columns
    if (this.config && this.config.customOptions && this.config.customOptions.visibleColumns) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }
    // get site details
    this.selectedSite = this.sharedService.selectedSite;

    // get device details
    this.subscribers.selectedDevice = this.store.select(fromStore.getDeviceDetials).subscribe((result) => {
      if (result) {
        this.selectedDevice = result;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.datasource = {
        getRows: (rowParams) => {
          // RTHL OPTIONS
          const options: any = {
            filter: rowParams.filterModel,
            sort: rowParams.sortModel,
            offset: rowParams.startRow,
            limit: 100,
          };

          if (this.dateFilter) {
            options.daterange = this.dateFilter;
          }

          // if (this.visibleColumns && this.visibleColumns.length) {
          //   options.fields = [...this.visibleColumns].join();
          // }

          // GET RTHL
          this.subscribers.getRTHLs = this.ceosRuleHistoryLogsService
            .getRuleHistoryLogLazyLoad(this.selectedDevice.id, options)
            .subscribe((result: any) => {
              const rowData = result.data;
              let lastRow = -1;
              if (rowData.length < options.limit) {
                lastRow = rowParams.startRow + rowData.length;
              }
              rowParams.successCallback(rowData, lastRow);
            });
        },
      };

      // SET DATASOURCE IF TABLE READY
      if (this.table && this.table.api) {
        this.table.api.setDatasource(this.datasource);
      }

      this.isFetchingDataset = false;
    }
  }

  onDateSelect(event) {
    this.dateFilter = {
      from: event.from,
      to: event.to,
    };
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private createColumns() {
    return [
      {
        colId: 'created_at',
        headerName: 'DATE',
        field: 'created_at',
        cellRenderer: 'loadingRenderer',
        valueFormatter: (params) => this.sharedService.dateFormat(params.value),
        width: 200,
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'mode',
        headerName: 'MODE',
        field: 'mode',
      },
    ];
  }
}
