<p-dialog
  header="{{ widgetName }}"
  [(visible)]="isDetailsModalOpen"
  appendTo="body"
  [style]="{ width: '60vw' }"
>
  <div style="padding: 0 35px 16px">
    <p-accordion [multiple]="true" expandIcon="" collapseIcon="">
      <p-accordionTab
        *ngFor="let d of ecowattData"
        header="{{ d.day }}"
        [selected]="true"
        [disabled]="true"
      >
        <p-table [columns]="cols" [value]="d.data">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                style="width: 28px; text-align: center"
                *ngFor="let col of columns"
              >
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                <div
                  [ngStyle]="{ 'background-color': rowData[col.field] }"
                  style="
                    border-radius: 50px;
                    height: 20px;
                    width: 20px;
                    margin: auto;
                  "
                ></div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </p-accordion>
  </div>
</p-dialog>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body ecowatt-body">
    <ng-container>
      <div class="sc-widget-legend ecowatt-widget-legend">
        <ul>
          <li *ngFor="let legendItem of legendItems">
            <span
              class="color"
              [ngStyle]="{ 'background-color': legendItem.color }"
            ></span>
            <span class="label ecowatt-label"
              >{{ legendItem.value }} {{ legendItem.label }}</span
            >
          </li>
        </ul>
      </div>
      <div class="ecowatt-data">
        <ul class="ecowatt-list">
          <li
            [ngStyle]="{ 'background-color': d.color }"
            class="ecowatt-item"
            *ngFor="let d of ecowattData"
          >
            <div>{{ d.day }}</div>
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</ng-template>

<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">{{ widgetName }}</h4>
      <div class="sc-widget-menu">
        <button
          type="button"
          (click)="openDetailsModal()"
          pButton
          icon="pi pi-eye"
          title="Details"
          class="ceos-alert-action-btn"
        ></button>
      </div>
    </div>
    <ng-container
      *ngIf="ecowattData?.length > 0; then content; else spinner"
    ></ng-container>
  </div>
</div>
