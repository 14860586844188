<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'ROOM_UNOCCUPANCY_AVG' | translate }} -->
        {{ widgetName }}
      </h4>

      <div class="sc-widget-menu">
        <i
          class="fa fa-refresh fa-fw sc-clickable green-text"
          [ngClass]="{ 'fa-spin': isFetching }"
          (click)="fetchDataset()"
          pTooltip="{{ 'REFRESH' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
      </div>
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body">
    <strong>{{ "ROOM_UNOCCUPANCY_AVG_SUBTITLE" | translate }}</strong>
    <div class="sc-flex justify-content-around w-100">
      <div *ngFor="let wd of widgetData">
        <div *ngIf="wd.label" class="text-center mb-1">
          {{ wd.label | translate: wd.labelParams }}
        </div>
        <sc-circle
          [customClass]="wd?.customClass"
          [customStyle]="wd?.customStyle"
          [icon]="wd?.icon"
          [primaryText]="wd?.primaryText"
          [secondaryText]="wd?.secondaryText | translate"
          [size]="wd?.size"
        ></sc-circle>
      </div>
    </div>
  </div>
</ng-template>
