import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'recurring-form',
  templateUrl: 'recurring-form.component.html'
})
export class RecurringFormComponent implements OnInit {
  @Input() group: FormGroup;

  intervalList: SelectItem[];

  constructor() { }

  ngOnInit() {
    this.intervalList = [
      { label: 'Day',    value: 'day' },
      { label: 'Hour',   value: 'hour' },
      { label: 'Minute', value: 'minute' },
      { label: 'Month',  value: 'month' },
      { label: 'Week',   value: 'week' },
    ];
  }

  showTimepicker() {
    switch (this.group.get('recurringOpts.intervalUnit').value) {
      case 'day':
      case 'month':
      case 'week':
        return true;
      default:
        return false;
    };
  }
}
