<div class="device-object-form">
  <div *ngIf="fetchingState === 0; else loadingIndicator">
    <!-- NOTE: this componetn come from device wizard module -->
    <sc-search-form
      [selectItems]="selectItems"
      (valueChanged)="onSearchValueChanged($event)"
    ></sc-search-form>

    <div class="device-model-container">
      <sc-device-model-item
        *ngFor="let model of filteredModels"
        [data]="model"
        (selected)="onDeviceSelected($event)"
      ></sc-device-model-item>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
