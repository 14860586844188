import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationSummaryComponent } from './location-summary.component';
import { LocationSummaryHourlyLogsComponent } from './hourly-logs/hourly-logs.component';
import { LocationSummaryService } from './services/location-summary.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    LocationSummaryComponent,
    LocationSummaryHourlyLogsComponent
  ],
  entryComponents: [
    LocationSummaryComponent,
    LocationSummaryHourlyLogsComponent
  ],
  providers: [LocationSummaryService]
})
export class LocationSummaryModule { }
