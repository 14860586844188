import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/store';
import { Notification } from '@widgets/notifications/notification';

import { NotificationsService } from '@widgets/notifications/notifications.service';
import { SharedService } from '@shared/shared.service';

import * as moment from 'moment';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationModalComponent } from '@app/core2/notification-modal/notification-modal.component';

@Component({
  selector: 'sc-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
  @Input()
  userId: number;
  @Input()
  showLabel: boolean;

  isFecthing: boolean;
  allNotifications: Notification[] = [];
  show: boolean = false;

  private subscribers: { [key: string]: any } = {};
  private intervals: { [key: string]: any } = {};
  private startRange: number = 0;
  private endRange: number = 12;
  public nbRows: number = 12;

  constructor(
    private notificationsService: NotificationsService,
    private sharedService: SharedService,
    private store: Store<fromRoot.State>,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.fetchNotifications();
    this.intervals.autoUpdate = setInterval(() => this.fetchNotifications(), 30000);
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  private fetchNotifications() {
    if (this.isFecthing === true || !this.userId) {
      return;
    }

    this.isFecthing = true;
    this.allNotifications = [];
    this.subscribers.getNotifications = this.notificationsService.get(true).subscribe((result: any) => {
      if (result && result.data.length > 0) {
        this.allNotifications = result.data.map((d) => {
          if (d.createdAt && typeof d.createdAt === 'string') {
            d.createdAt = moment(d.createdAt).fromNow();
          }
          return d;
        });
      }
      this.isFecthing = false;
    });
  }

  togglePanel() {
    this.show = !this.show;
  }

  gotoNotifications() {
    this.show = false;
    this.store.dispatch(new fromRoot.Go({ path: ['notifications'] }));
  }

  openModal(notification: Notification) {
    const bulkEditor: DynamicDialogRef = this.dialogService.open(NotificationModalComponent, {
      header: notification.subject,
      styleClass: 'no-padding-content',
      data: { notification, userId: this.userId },
      closable: false,
    });

    this.subscribers.createFormClosed = bulkEditor.onClose.subscribe((result) => {
      this.fetchNotifications();
    });
  }

  paginate(event) {
    this.startRange = event.first;
    this.endRange = event.first + event.rows;
  }

  get notifications() {
    return this.allNotifications.slice(this.startRange, this.endRange);
  }
}
