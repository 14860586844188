<div class="modal">
  <div class="modal__header">
    <div class="modal__header__label">
      <div class="modal__header__title">
        {{ modalTitle | translate }}
      </div>
      <div class="modal__header__description">
        {{ modalDescription | translate }}
      </div>
    </div>

    <div class="modal__header__action">
      <sc-close-button (clicked)="close()"></sc-close-button>
    </div>
  </div>

  <div class="modal__body">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="1">
        <sc-list-select
          [items]="operandTypes"
          (selectItem)="selectOperandType($event)"
        ></sc-list-select>
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <ng-container *ngIf="operandTargets?.length; else stepError">
          <sc-list-select
            [items]="operandTargets"
            (selectItem)="selectOperandTarget($event)"
          ></sc-list-select>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="3">
        <ng-template [ngTemplateOutlet]="step3"></ng-template>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-template [ngTemplateOutlet]="stepError"></ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal__footer">
    <!-- *ngIf="isTemplate" -->
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="onResetClick()"
      [label]="'CCP_COMMON_RESET' | translate"
      *ngIf="isTemplate || isFromScratch"
    ></button>

    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="onOkClick()"
      [label]="'CCP_COMMON_OK' | translate"
      [disabled]="form?.invalid"
    ></button>
  </div>
</div>

<ng-template #step3>
  <ng-container [ngSwitch]="operandType">
    <ng-container *ngSwitchCase="_leftOperandTypes.DeviceValue">
      <sc-device-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [viewMode]="viewMode"
        [isTemplate]="isTemplate"
      ></sc-device-operand>
    </ng-container>

    <ng-container *ngSwitchCase="_leftOperandTypes.DeviceDateTime">
      <sc-device-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [viewMode]="viewMode"
        [isTemplate]="isTemplate"
      ></sc-device-operand>
    </ng-container>

    <ng-container *ngSwitchCase="_leftOperandTypes.LocationPropertyValue">
      <sc-location-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [viewMode]="viewMode"
        [isTemplate]="isTemplate"
      ></sc-location-operand>
    </ng-container>

    <ng-container *ngSwitchCase="_leftOperandTypes.LocationPropertyDateTime">
      <sc-location-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [viewMode]="viewMode"
        [isTemplate]="isTemplate"
      ></sc-location-operand>
    </ng-container>

    <ng-container *ngSwitchCase="_leftOperandTypes.FunctionValue">
      <sc-function-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-function-operand>
    </ng-container>

    <ng-container *ngSwitchCase="_leftOperandTypes.GlobalValue">
      <sc-global-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-global-operand>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-template [ngTemplateOutlet]="stepError"></ng-template>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #stepError>
  {{ "CCP_COMMON_SOMETHING_WENT_WRONG" | translate }}
  <a class="clickable" (click)="goBack()">{{
    "CCP_COMMON_BACK" | translate
  }}</a>
</ng-template>
