import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItems } from '../../models';

@Component({
  selector: 'sc-task-queue',
  templateUrl: './task-queue.component.html',
  styleUrls: ['./task-queue.component.scss'],
})
export class TaskQueueComponent implements OnInit {
  actions: any[];

  @Input()
  commandForm: FormGroup;

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {
    this.actions = [{ label: 'Cancel', value: 'cancel' }];

    if (this.commandForm) {
      // incase switch from trigger device
      this.commandForm.get('action').setValue(this.actions[0].value);
      this.commandForm.get('action').disable();
    }
  }
}
