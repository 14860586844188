import * as fromInitialData from '../actions/initial.action';

export interface InitialDataState {
  data: { [key: string]: any };
  error: any;
  loaded: boolean;
  loading: boolean;
  version: number;
}

export const initialState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
  version: 6,
};

export function reducer(state = initialState, action: fromInitialData.InitialDataAction): InitialDataState {
  switch (action.type) {
    case fromInitialData.LOAD_INITIAL_DATA: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    }

    case fromInitialData.LOAD_INITIAL_DATA_FAIL: {
      const error = action.payload;
      return {
        ...state,
        error,
        loading: false,
      };
    }

    case fromInitialData.LOAD_INITIAL_DATA_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        data,
        loaded: true,
        loading: false,
      };
    }
  }

  return state;
}

export const getVersion = (state: InitialDataState) => state.version;
export const getInitialData = (state: InitialDataState) => state.data;
export const getInitialDataLoaded = (state: InitialDataState) => state.loaded;
export const getInitialDataLoading = (state: InitialDataState) => state.loading;
