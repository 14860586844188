import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MassRule } from '../models/rule';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class RuleMassEditService {
  private apiUrl = config().apiUrl + '/rules/massEdit';

  private bulkAPI = config().apiUrl + '/_backend/rules/bulk';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  massEdit(data: MassRule) {
    return this.httpClient.patch(this.apiUrl, data);
  }

  bulkInit(options: any) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(this.bulkAPI + queryParams);
  }

  bulkPrepare(options: any) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(this.bulkAPI + '/prepare' + queryParams);
  }
}
