import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { MyAccountComponent } from './my-account/my-account.component';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { UserActionCellComponent } from './user-action-cell/user-action-cell.component';
import { UserEmailFormComponent } from './user-email-form/user-email-form.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UserPasswordFormComponent } from './user-password-form/user-password-form.component';
import { UserPermissionActionCellComponent } from './user-permission-action-cell/user-permission-action-cell.component';
import { UserPermissionFormComponent } from './user-permission-form/user-permission-form.component';
import { UserPermissionsTableComponent } from './user-permissions-table/user-permissions-table.component';
import { UserRoleActionCellComponent } from './user-role-action-cell/user-role-action-cell.component';
import { UserRoleFormComponent } from './user-role-form/user-role-form.component';
import { UserRolesTableComponent } from './user-roles-table/user-roles-table.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { MqttSettingsFormComponent } from './mqtt-settings-form/mqtt-settings-form.component';

import { UserPermissionsService } from './services/user-permissions.service';
import { UserRolesService } from './services/user-roles.service';
import { UsersService } from './services/users.service';
import { UserFormsService } from './services/user-forms.service';
import { MqttService } from './services/mqtt.service';

const COMPONENTS: any = [
  MyAccountComponent,
  ProfileFormComponent,
  UserActionCellComponent,
  UserEmailFormComponent,
  UserFormComponent,
  UserPasswordFormComponent,
  UserPermissionActionCellComponent,
  UserPermissionFormComponent,
  UserPermissionsTableComponent,
  UserRoleActionCellComponent,
  UserRoleFormComponent,
  UserRolesTableComponent,
  UsersTableComponent,
  MqttSettingsFormComponent,
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [UserPermissionsService, UserRolesService, UsersService, UserFormsService, MqttService],
})
export class UsersModule {}
