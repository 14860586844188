import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { ModalConfig } from '@sc/modal/modal-config';
import { BenchmarkTemplate } from '../../models/benchmark-template';

import { BenchmarkTemplatesService } from '../../services/benchmark-templates.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { BenchmarkTemplateActionCellComponent } from '../benchmark-template-action-cell/benchmark-template-action-cell.component';

@Component({
  selector: 'sc-benchmark-templates-table',
  templateUrl: 'benchmark-templates-table.component.html'
})
export class BenchmarkTemplatesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: BenchmarkTemplate[];
  formConfig: ModalConfig;
  tableTitle: string;
  subscribers: { [key: string]: any };

  constructor(private benchmarkTemplatesService: BenchmarkTemplatesService) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: BenchmarkTemplateActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'BenchmarkTemplateForm',
      options: { modalTitle: 'BENCHMARK_TEMPLATE_FORM_TITLE' }
    };
    this.tableTitle = 'BENCHMARK_TEMPLATES';
    this.subscribers = {};

    this.fetchDataset();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.subscribers.GET_DATASET = this.benchmarkTemplatesService
      .getBenchmarkTemplates()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
