import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { Location } from '@widgets/locations/models/location';
import { NotificationTemplate } from '@widgets/notification-templates/models/notification-template';
import { Site } from '@widgets/sites/models/site';

import { LocationsService } from '@widgets/locations/services/locations.service';
import { NotificationTemplatesService } from '@widgets/notification-templates/services/notification-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-tech-alert-modal',
  templateUrl: './tech-alert-modal.component.html'
})
export class TechAlertModalComponent implements OnInit {
  @Input() data: any;
  @Output() onClose = new EventEmitter();
  @Output() onDismiss = new EventEmitter();

  dataset: any[];

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.dataset = this.data;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  getCreatedAt(data: { [key: string]: any }) {
    if (data.createdAt) {
      return this.sharedService.dateFormat(data.createdAt);
    }
    return '';
  }
}
