import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { DeviceTypeActionCellComponent } from './device-type-action-cell/device-type-action-cell.component';
import { DeviceTypeActionFormComponent } from './device-type-form/action-form/action-form.component';
import { DeviceTypeActionParameterFormComponent } from './device-type-form/action-parameter-form/action-parameter-form.component';
import { DeviceTypeMappingValueFormComponent } from './device-type-form/mapping-value-form/mapping-value-form.component';
import { DeviceTypeFormComponent } from './device-type-form/device-type-form.component';
import { DeviceTypesTableComponent } from './device-types-table/device-types-table.component';
import { DeviceTypeFormsService } from './services/device-type-forms.service';
import { DeviceTypesService } from './services/device-types.service';

const COMPONENTS: any[] = [
  DeviceTypeActionCellComponent,
  DeviceTypeActionFormComponent,
  DeviceTypeActionParameterFormComponent,
  DeviceTypeMappingValueFormComponent,
  DeviceTypeFormComponent,
  DeviceTypesTableComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [DeviceTypeFormsService, DeviceTypesService]
})
export class DeviceTypesModule {}
