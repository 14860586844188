import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { RoomCorrectionComponent } from './room-correction.component';

@NgModule({
  imports: [SharedModule],
  declarations: [RoomCorrectionComponent],
  entryComponents: [RoomCorrectionComponent]
})
export class RoomCorrectionModule {}
