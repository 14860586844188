<div [ngSwitch]="currentState" class="text-right">
  <div *ngSwitchCase="'FETCHING'">
    <div class="text-right grey-text">
      <small>
        <i class="fa fa-refresh fa-spin"></i> {{ 'FETCHING' | translate }}
      </small>
    </div>
  </div>

  <div *ngSwitchCase="'ERROR'">
    <div class="text-right red-text">
      <small>
        <i
          class="fa fa-exclamation-triangle"
          pTooltip="{{ 'CANNOT_REACH_API' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
        {{ 'ERROR' | translate }}
      </small>
    </div>
  </div>

  <div *ngSwitchCase="'ERROR_SESSION'">
    <div class="text-right red-text">
      <small>
        <i
          class="fa fa-exclamation-triangle"
          pTooltip="{{ 'LIVE_REQUEST_CREATE_SESSION_FAIL' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
        {{ 'ERROR' | translate }}
      </small>
    </div>
  </div>

  <div *ngSwitchCase="'ERROR_SEND_TO_AUTOMATION'">
    <div class="text-right red-text">
      <small>
        <i
          class="fa fa-exclamation-triangle"
          pTooltip="{{ 'LIVE_REQUEST_SEND_TO_AUTOMATION_FAIL' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
        {{ 'ERROR' | translate }}
      </small>
    </div>
  </div>

  <div *ngSwitchCase="'TIMEOUT'">
    <div class="text-right grey-text">
      <small>
        <i
          class="fa fa-exclamation-triangle"
          pTooltip="{{
            'CANNOT_GET_DATA_FOR_X_SECONDS' | translate: { value: 30 }
          }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
        {{ 'TIMEOUT' | translate }}
      </small>
    </div>
  </div>

  <div *ngSwitchDefault>
    <div *ngIf="!isAG; else agTemplate">
      <strong
        *ngIf="showValue"
        [pTooltip]="loggedAt"
        [escape]="false"
        tooltipPosition="top"
        appendTo="body"
      >
        <span>{{ currentValue }}</span> <span>{{ valueUnit }}</span>
      </strong>
      <span *ngIf="diffValue" class="mr-1" [ngClass]="diffColor()">
        <i [ngClass]="diffIcon()"></i> {{ diffValue }}
      </span>
    </div>
  </div>
</div>

<ng-template #agTemplate>
  <div *ngIf="agValue?.error">
    <i
      class="fa fa-exclamation-triangle fa-fw"
      [pTooltip]="loggedAt"
      [escape]="false"
      tooltipPosition="top"
    ></i>
  </div>
  <div *ngIf="!agValue?.error">
    <i
      class="fa fa-check fa-fw"
      [pTooltip]="loggedAt"
      [escape]="false"
      tooltipPosition="top"
    ></i>
  </div>
</ng-template>
