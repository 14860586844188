<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        {{ 'ENABLED_RULES' | translate }}
      </h4>
    </div>

    <ng-container *ngIf="isFirstTime; then spinner; else content"></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <p-table [value]="rules" [scrollable]="true" [scrollHeight]="config.widgetHeight - 80 + 'px'">
    <ng-template pTemplate="header">
      <tr>
        <th>{{'ID' | translate}}</th>
        <th>{{'RULE' | translate}}</th>
        <th>{{'SINCE' | translate}}</th>
        <th>{{'LAST_MODIFIED' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr class="ui-widget-header" *ngIf="rowGroupMetadata[rowData.locationName].index === rowIndex">
        <td colspan="4">
          <span style="font-weight:bold">{{rowData.locationName}}</span>
        </td>
      </tr>
      <tr>
        <td>{{rowData.id}}</td>
        <td>{{rowData.name}}</td>
        <td>{{formatDate(rowData)}}</td>
        <td>{{formatDate(rowData, true)}}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
