import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { FormControl, Validators, AbstractControl } from '@angular/forms';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'sc-file-toolbar',
  templateUrl: './file-toolbar.component.html',
  styleUrls: ['./file-toolbar.component.scss']
})
export class FileToolbarComponent implements OnInit {
  @Input()
  tools: ('copy'|'paste'|'move'|'rename'|'delete'|'new_folder')[];
  @Input()
  loading: boolean;
  @Input()
  selectedFile: TreeNode;

  @Output()
  copyClicked = new EventEmitter<any>();
  @Output()
  deleteClicked = new EventEmitter<any>();
  @Output()
  moveClicked = new EventEmitter<any>();
  @Output()
  newFolderClicked = new EventEmitter<string>();
  @Output()
  pasteClicked = new EventEmitter<any>();
  @Output()
  renameClicked = new EventEmitter<string>();
  @Output()
  uploadClicked = new EventEmitter<any>();

  // create name input with validators
  name = new FormControl(null, [
    Validators.compose([
      Validators.required,
      (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
          return null;
        }
        return /^(\w|\.|\-)+$/g.test(control.value) ? null : { pattern: true };
      }
    ])
  ]);
  displayTools: any[] = [];
  showNamePanel = false;

  private action: string;
  private availableTools: any[] = [
    {
      key: 'copy',
      label: 'FILE_MANAGER_COPY',
      icon: 'files-o',
    },
    {
      key: 'move',
      label: 'FILE_MANAGER_MOVE',
      icon: 'arrows',
    },
    {
      key: 'paste',
      label: 'FILE_MANAGER_PASTE',
      icon: 'clipboard',
    },
    {
      key: 'delete',
      label: 'FILE_MANAGER_DELETE',
      icon: 'trash',
    },
    {
      key: 'rename',
      label: 'FILE_MANAGER_RENAME',
      icon: 'pencil',
    },
    {
      key: 'new_folder',
      label: 'FILE_MANAGER_NEW_FOLDER',
      icon: 'folder-o',
    },
    {
      key: 'upload',
      label: 'FILE_MANAGER_UPLOAD',
      icon: 'upload',
    }
  ];

  constructor() { }

  ngOnInit() {
    this.updateTools();
  }

  updateTools() {
    if (this.tools && this.tools.length) {
      const displayTools = [];
      for (const t of this.tools) {
        for (const at of this.availableTools) {
          if (t === at.key) {
            displayTools.push(at);
          }
        }
      }
      this.displayTools = displayTools;
    } else {
      this.displayTools = this.availableTools;
    }
  }

  isAllowAction(action: string) {
    if (this.loading) {
      return false;
    }
    const actions = ['copy', 'move', 'paste', 'delete', 'rename'];
    if (actions.indexOf(action) >= 0 && !this.selectedFile) {
      return false;
    }
    return true;
  }

  onToolClicked(tool) {
    this.action = tool.key;
    if (this.isAllowAction(this.action)) {
      switch (this.action) {
        case 'copy':
          this.copyClicked.emit();
          break;
        case 'paste':
          this.pasteClicked.emit();
          break;
        case 'move':
          this.moveClicked.emit();
          break;
        case 'delete':
          this.deleteClicked.emit();
          break;
        case 'rename':
          this.name.setValue(this.selectedFile.label);
          this.showNamePanel = true;
          break;
        case 'new_folder':
          this.name.reset();
          this.showNamePanel = true;
          break;
        case 'upload':
          this.uploadClicked.emit();
          break;
      }
    }
  }

  onNamingConfirm() {
    const name = this.name.value;
    if (!name) {
      return;
    }
    if (this.action === 'rename') {
      this.renameClicked.emit(name);
    } else if (this.action === 'new_folder') {
      this.newFolderClicked.emit(name);
    }
    this.showNamePanel = false;
    this.name.reset();
  }
}
