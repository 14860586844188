import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { User } from '../models/user';
import { SelectItem } from 'primeng/api';
import * as fromStore from '@app/store';
import { AuthService } from '@app/auth/services/auth.service';
import { UsersService } from '../services/users.service';
import { SharedService } from '@shared/shared.service';
import { UserFormsService } from '../services/user-forms.service';

@Component({
  selector: 'sc-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: User;
  isAdmin: boolean;
  isSubmitting: boolean;

  selectItems: { [key: string]: SelectItem[] };
  private subscribers: { [key: string]: any } = {};

  constructor(
    private auth: AuthService,
    private authStore: Store<fromStore.State>,
    private userFormsService: UserFormsService,
    private usersService: UsersService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectItems = {
      gender: this.sharedService.selectItems.gender,
      locales: this.sharedService.selectItems.locales,
      backendVariants: this.sharedService.selectItems.backendVariants,
    };

    this.fetchUser(this.data.id);

    this.initForm();

    this.isAdmin = this.auth.isAuthorized('admin');
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchUser(id: number) {
    this.fetchingState++;
    this.subscribers.GET_USER = this.usersService.getUser(id).subscribe((result: any) => {
      this.formData = result.data;
      this.form.patchValue(this.formData);
      this.fetchingState--;
    });
  }

  initForm() {
    this.form = this.userFormsService.initProfileForm();
  }

  // API CALL
  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      // disabled the form submitting
      this.isSubmitting = true;

      this.usersService.updateUser(this.formData.id, this.form.value).subscribe(
        (result: any) => {
          // show notification
          const title = this.sharedService.getTranslation('PROFILE');
          const message = this.sharedService.getTranslation('UPDATE_SUCCESS');
          this.sharedService.notify(title, message, 'success');

          // update user variant
          this.authStore.dispatch(new fromStore.UpdateUserVariant(this.form.value.backendVariant));

          // close modal and return added data
          this.onClose.emit(result.data);
          // enabled the form submitting
          this.isSubmitting = false;
        },
        (error: any) => {
          // display error message and unlock the form
          this.errorMessage = error;
          // enabled the form submitting
          this.isSubmitting = false;
        }
      );
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
