<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'MOST_SOLD_ROOMS' | translate }} -->
        {{ widgetName }}
      </h4>

      <!-- <div class="sc-widget-menu"></div> -->
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body direction-row">
    <div class="sc-flex grow-1">
      <i class="fa fa-location-arrow fa-5x green-text"></i>
    </div>

    <div class="sc-flex direction-column grow-2">
      <div class="sc-flex" *ngFor="let item of widgetData" style="height: 40px">
        <div
          class="sc-flex justify-content-end align-items-center"
          style="width: 110px"
        >
          {{ item.label | translate }}
        </div>

        <div
          class="sc-flex grow-1 align-items-end justify-content-center direction-column"
        >
          <div
            *ngIf="item.location"
            class="text-ellipsis text-right"
            style="width: 200px"
          >
            {{ item.location }}
          </div>
          <small *ngIf="item.location" class="text-right grey-text">
            {{ item.occupied }} {{ "DAYS" | translate }}
          </small>
        </div>
      </div>
    </div>
  </div>

  <div class="sc-widget-footer" *ngIf="modalConfig">
    <sc-modal-trigger [modalConfig]="modalConfig">
      <a class="sc-clickable">
        {{ "DETAILS" | translate }}
        <i class="fa fa-angle-right"></i>
      </a>
    </sc-modal-trigger>
  </div>
</ng-template>
