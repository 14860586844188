<div class="quick-date-select" [formGroup]="dateForm">
  <div class="display">
    <div [ngClass]="displayStyle">
      <label
        [for]="inputIds?.quickDatetime"
        class="sc-form-label"
        *ngIf="showLabel"
      >
        {{ "TIME_RANGE" | translate }}
      </label>

      <p-dropdown
        [inputId]="inputIds?.quickDatetime"
        formControlName="quickDatetime"
        [options]="quickDatetimes"
        placeholder="{{ 'SELECT' | translate }}"
        (onChange)="onQuickDateSelect($event)"
        filter="true"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-dropdown>
    </div>

    <div *ngIf="!quickTimeOnly" [ngClass]="displayStyle">
      <label [for]="inputIds?.from" class="sc-form-label" *ngIf="showLabel">
        {{ "FROM" | translate }}
      </label>

      <p-calendar
        [inputId]="inputIds?.from"
        formControlName="from"
        placeholder="{{ 'SELECT' | translate }} "
        dateFormat="dd/mm/yy"
        [hourFormat]="24"
        [showButtonBar]="true"
        [showTime]="!showOnlyDate"
        [maxDate]="maxDate"
        [minDate]="minDate"
        (onSelect)="onDatePickerSelect($event, 'from')"
        (onClearClick)="onDatePickerClear('from')"
        (onInput)="onDatePickerInput($event, 'from')"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-calendar>
    </div>

    <div *ngIf="!quickTimeOnly" [ngClass]="displayStyle">
      <label [for]="inputIds?.to" class="sc-form-label" *ngIf="showLabel">
        {{ "TO" | translate }}
      </label>

      <p-calendar
        [inputId]="inputIds?.to"
        formControlName="to"
        placeholder="{{ 'SELECT' | translate }} "
        dateFormat="dd/mm/yy"
        [hourFormat]="24"
        [showButtonBar]="true"
        [showTime]="!showOnlyDate"
        [maxDate]="maxDate"
        [minDate]="minDate"
        (onSelect)="onDatePickerSelect($event, 'to')"
        (onClearClick)="onDatePickerClear('to')"
        (onInput)="onDatePickerInput($event, 'to')"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-calendar>
    </div>

    <div *ngIf="selectInterval && !quickTimeOnly" [ngClass]="displayStyle">
      <label [for]="inputIds?.interval" class="sc-form-label" *ngIf="showLabel">
        {{ "INTERVAL" | translate }}
      </label>

      <p-dropdown
        [inputId]="inputIds?.interval"
        formControlName="interval"
        [options]="intervals"
        placeholder="{{ 'SELECT' | translate }} "
        filter="true"
        (onChange)="onIntervalChange()"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-dropdown>
    </div>
  </div>
</div>
