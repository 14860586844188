import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CeosIdentity } from '../models/ceos-identity';

@Injectable()
export class CeosIdentityFormService {
  constructor(private formBuilder: FormBuilder) {}

  initCeosIdentityForm(): FormGroup {
    return this.formBuilder.group({
      local_mqtt_password: [{ value: null, disabled: true }, Validators.required],
      wpa_psk: [{ value: null, disabled: true }, Validators.required],
    });
  }

  initAddCeosOwnerForm() {
    return this.formBuilder.group({
      key: '' + new Date().getTime(),
      id: [null, Validators.required],
      ownerKey: [null, Validators.required],
      valid: null,
      message: null,
    });
  }
}
