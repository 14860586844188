import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { ACValveAlertComponent } from './ac-valve-alert.component';
import { ACValveAlertModalComponent } from './ac-valve-alert-modal/ac-valve-alert-modal.component';

const COMPONENTS: any[] = [ACValveAlertComponent, ACValveAlertModalComponent];

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: []
})
export class ACValveAlertModule {}
