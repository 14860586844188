import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { config } from '../../../config';

import { LocationType } from '../models/location-type';

import { LocationTypesService } from '../services/location-types.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-type-form',
  templateUrl: 'location-type-form.component.html'
})
export class LocationTypeFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: LocationType;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  patterns = config().regexPatterns;
  isSubmitting: boolean;
  subscribers: { [key: string]: any };
  fetchingState: number;

  constructor(
    private formBuilder: FormBuilder,
    private locationTypesService: LocationTypesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchingState = 0;
    this.subscribers = {};

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchLocationType(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchLocationType(id: number) {
    this.fetchingState++;
    this.subscribers.GET_LOCATION_TYPE = this.locationTypesService
      .getLocationTypes(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      // locationTypeId: [
      //   null,
      //   Validators.compose([Validators.required, Validators.pattern(this.patterns.id)]),
      // ],
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      key: [null, Validators.required],
      isActive: false,
      isDeleted: false
    });

    // if (this.editMode === true) {
    //   this.form.get('locationTypeId').disable();
    // }
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create() {
    this.locationTypesService
      .createLocationType(this.form.value)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update() {
    this.locationTypesService
      .updateLocationType(this.formData.id, this.form.value)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.locationTypesService
      .deleteLocationType(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify(
      'Location Type',
      result.message + ' Success!',
      'success'
    );
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
