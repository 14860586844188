import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { filter, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';

import { Device } from '@widgets/devices/models/device';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { ProtocolController } from '@widgets/devices/models/protocol-controller';

import { AuthService } from '@app/auth/services/auth.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { DeviceTriggerCommandsService } from '@widgets/devices/services/device-trigger-commands.service';
import { SharedService } from '@shared/shared.service';

import { LiveRequestsService } from '@widgets/live-requests/services/live-requests.service';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UpdateWizardComponent } from '@widgets/device-wizard/update-wizard/update-wizard.component';

import * as moment from 'moment';

@Component({
  selector: 'sc-device-action-cell',
  templateUrl: './device-action-cell.component.html',
})
export class DeviceActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  data: { [key: string]: any };
  context: any;
  containerComponent: any;
  parentComponent: any;
  node: any;
  deviceModels: DeviceModel[];
  deviceProtocols: DeviceProtocol[];
  deviceTypes: DeviceType[];
  protocolControllers: ProtocolController[];
  cellDeviceType: DeviceType;
  cellDeviceModel: DeviceModel;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];
  isGettingLiveData = false;
  isGettingLiveDataPushy = false;
  userVariant: string;

  private liveDataReqOpt: any;
  private liveDataCount: { [key: string]: number } = {};
  private subscribers: { [key: string]: any } = {};
  private intervals: { [key: string]: any } = {};
  private wizardDialog: DynamicDialogRef;

  constructor(
    private authService: AuthService,
    private store: Store<fromStore.State>,
    private devicesService: DevicesService,
    private deviceTriggerCommandsService: DeviceTriggerCommandsService,
    private sharedService: SharedService,
    private dialogService: DialogService,
    private liveRequestsService: LiveRequestsService
  ) {}

  agInit(params: any): void {
    if (!params.data || !params.data.id) {
      return;
    }

    // get user variant
    this.subscribers.userVariant = this.store
      .pipe(
        select(fromStore.getUserVariant),
        filter((userVariant) => (userVariant ? true : false)),
        take(1)
      )
      .subscribe((result) => {
        this.userVariant = result;
      });

    this.cell = params;
    this.data = this.cell.data;
    this.context = this.cell.context;
    this.node = this.cell.node;
    // table component
    this.parentComponent = this.context.parentComponent;
    // devices table component
    this.containerComponent = this.context.containerComponent;
    this.deviceModels = this.containerComponent.deviceModels;
    this.deviceTypes = this.containerComponent.deviceTypes;
    this.deviceProtocols = this.containerComponent.deviceProtocols;
    this.protocolControllers = this.containerComponent.protocolControllers;
    this.cellDeviceModel = this.getCellDeviceModel();
    this.cellDeviceType = this.getCellDeviceType();

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        permission: 'device_u',
        type: 'modal',
        modalConfig: {
          name: 'DeviceForm',
          data: this.data,
          options: {
            modalTitle: 'DEVICE_FORM_TITLE',
            fullScreen: true,
          },
        },
        onModalClose: this.updateRow.bind(this),
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        permission: 'device_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];

    if (this.authService.isAuthorized('admin')) {
      if (this.isVirtualDeviceHolder() || this.isAutomation()) {
        this.actions = [
          {
            key: 'DEVICE_WIZARD_WIZARD',
            icon: 'fa fa-magic',
            type: 'button',
            onClick: this.openUpdateWizard.bind(this),
          },
          ...this.actions,
        ];
      }
    }

    if (!this.isAutomation() && !this.isVirtualDeviceHolder()) {
      // not automation and not VDH
      this.actions = [
        {
          key: 'LOGS',
          icon: 'fa fa-line-chart',
          type: 'modal',
          modalConfig: {
            name: 'DeviceLogsView',
            data: { deviceModels: this.deviceModels, device: this.data },
            options: {
              modalTitle: 'DEVICE_LOGS_TITLE',
              modalTitleParams: { value: this.data.description },
            },
          },
        },
        {
          key: 'ADD_LOG',
          icon: 'fa fa-list-alt',
          permission: 'device_u',
          type: 'modal',
          modalConfig: {
            name: 'DeviceAddDHLForm',
            data: {
              deviceModel: this.cellDeviceModel,
              deviceType: this.cellDeviceType,
              device: this.data,
            },
            options: {
              modalTitle: 'DEVICE_ADD_DHL_FORM_TITLE',
              modalTitleParams: { value: this.data.description },
            },
          },
        },
        ...this.actions,
      ];
    }

    switch (this.data.automationVersion) {
      case 'v1rm':
        this.actions = [
          {
            key: 'DEVICE_LIVE_DATA_PUSHY',
            icon: this.getLiveDataIconPushy(),
            type: 'button',
            onClick: this.sendLiveDataRequest.bind(this),
          },
          ...this.actions,
        ];
        break;

      case 'ceos':
      case 'v4':
        this.actions = [
          {
            key: 'DEVICE_LIVE_DATA_CLOUD_MQTT',
            icon: this.getLiveDataIconPushy(),
            type: 'button',
            onClick: this.sendLiveDataRequest.bind(this),
          },
          ...this.actions,
        ];
        break;

      default:
        this.actions = [
          {
            key: 'DEVICE_LIVE_DATA_FCM',
            icon: this.getLiveDataIcon(),
            type: 'button',
            buttonConfig: { data: 'fcm' },
            onClick: this.updateLiveData.bind(this),
          },
          {
            key: 'DEVICE_LIVE_DATA_PUSHY',
            icon: this.getLiveDataIconPushy(),
            type: 'button',
            buttonConfig: { data: 'pushy' },
            onClick: this.updateLiveData.bind(this),
          },
          ...this.actions,
        ];
        break;
    }

    if (this.allowTrigger()) {
      this.actions = [
        {
          key: 'TRIGGER',
          icon: 'fa fa-terminal',
          type: 'modal',
          modalConfig: {
            name: 'DeviceTriggerActionForm',
            data: this.triggerRequestOptions(),
            options: { modalTitle: 'DEVICE_TRIGGER_ACTION_FORM_TITLE' },
          },
        },
        ...this.actions,
      ];
    }

    if (this.isAutomation()) {
      this.actions = [
        {
          key: 'DETAILS',
          icon: 'fa fa-eye',
          permission: 'device_r',
          type: 'button',
          onClick: this.viewDeviceDetails.bind(this),
        },
        ...this.actions,
      ];
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {
    // ____ CLEAR SUBSCRIBES & INTERVALS
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  private updateRow(event: { result: Device }) {
    if (event.result) {
      this.parentComponent.updateRow({ ...event.result, id: this.data.id });
    }
  }

  private deleteRow(force = false) {
    this.subscribers.deleteDevice = this.devicesService.deleteDevice(this.data.id, force).subscribe({
      next: (result: any) => {
        if (result.warning) {
          this.subscribers.delete = this.sharedService
            .deleteWarningHandler(this.cell.data, result.warning)
            .subscribe((confirm: any) => {
              if (confirm === true) {
                this.deleteRow(true);
              }
            });
        } else {
          this.parentComponent.updateRow({
            id: this.data.id,
            isActive: false,
            isDeleted: true,
          });
          const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
          const title = this.sharedService.getTranslation('DEVICE');
          this.sharedService.notify(title, text, 'success');
        }
      },
      error: (error: any) => {
        const text = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('DEVICE');
        this.sharedService.notify(title, text, 'error');
      },
    });
  }

  private getCellDeviceModel() {
    if (this.data && this.data.deviceModelId && this.deviceModels) {
      return this.deviceModels.find((d) => d.id === this.data.deviceModelId);
    }
    return;
  }

  private getCellDeviceType() {
    if (this.data && this.data.deviceTypeId && this.deviceTypes) {
      return this.deviceTypes.find((d) => d.id === this.data.deviceTypeId);
    }
    return;
  }

  private createLiveDataRequestOptions() {
    const options: any = {};

    // * if the BE variant "ceos" is selected, everything linked to live data needs to send the idx and not the id.
    switch (this.data.deviceTypeKey) {
      case 'a':
        options.apiKey = this.data.apiKey;
        options.automationId = this.data.id;
        options.action = 'getAllDeviceStatusLive';
        break;

      // NOTE: this is only automationVersion = v1
      case 'g':
        options.apiKey = this.data.automationApiKey;
        options.automationId = this.data.automationId;
        options.action = 'getAllDeviceStatusLive';
        options.gatewayId = this.data.id;
        break;

      default:
        options.action = 'getOneDeviceStatusLive';
        options.apiKey = this.data.automationApiKey;
        options.automationId = this.data.automationId;
        if (this.userVariant === 'ceos') {
          options.deviceIdx = this.data.idx;
        } else {
          options.deviceId = this.data.id;
        }
        break;
    }

    return options;
  }

  private triggerRequestOptions() {
    const options: any = {
      // deviceId: this.data.id,
      deviceTypeId: this.data.deviceTypeId,
      deviceTypeKey: this.data.deviceTypeKey,
      description: this.data.description,
      commandActions: this.cellDeviceType && this.cellDeviceType.commandActions,
      automationVersion: this.data && this.data.automationVersion,
    };

    if (this.userVariant === 'ceos') {
      options.deviceIdx = this.data.idx;
    } else {
      options.deviceId = this.data.id;
    }

    switch (this.data.deviceTypeKey) {
      case 'a':
        options.apiKey = this.data.apiKey;
        options.automationId = this.data.id;
        break;

      default:
        options.apiKey = this.data.automationApiKey;
        options.automationId = this.data.automationId;
        break;
    }

    return options;
  }

  private allowTrigger() {
    if (
      this.data.isControllableByUser &&
      this.cellDeviceType &&
      this.cellDeviceType.commandActions &&
      this.cellDeviceType.commandActions.length
    ) {
      return true;
    }
    return false;
  }

  private isAutomation() {
    if (this.cellDeviceType && this.cellDeviceType.key === 'a') {
      return true;
    }
    return false;
  }

  private isVirtualDeviceHolder() {
    if (this.cellDeviceType && this.cellDeviceType.key === 'vdh') {
      return true;
    }
    return false;
  }

  private getLiveDataIcon() {
    let icon = '';
    if (this.data && this.data.deviceTypeKey) {
      switch (this.data.deviceTypeKey) {
        case 'a':
        case 'g':
          icon = 'fa-gears';
          break;

        default:
          icon = 'fa-gear';
          break;
      }
      if (this.isGettingLiveData) {
        icon += ' fa-spin';
      }
    }
    return icon;
  }

  private getLiveDataIconPushy() {
    let icon = '';
    if (this.data && this.data.deviceTypeKey) {
      switch (this.data.deviceTypeKey) {
        case 'a':
        case 'g':
          icon = 'fa-certificate';
          break;

        default:
          icon = 'fa-asterisk';
          break;
      }
      if (this.isGettingLiveDataPushy) {
        icon += ' fa-spin';
      }
    }
    return icon;
  }

  private activeLiveDataSpinner() {
    // ____ ACTIVE INDICATOR
    this.node.setDataValue('liveStatus', 'FETCHING');

    // ____ STOP INTERVAL && UNSUBSCRIBE
    if (this.subscribers.requestLiveData) {
      this.subscribers.requestLiveData.unsubscribe();
    }

    if (this.subscribers.getLiveData) {
      this.subscribers.getLiveData.unsubscribe();
    }
    if (this.intervals.getLiveData) {
      clearInterval(this.intervals.getLiveData);
      this.intervals.getLiveData = null;
    }
  }

  private updateLiveData(serviceProvider: string) {
    this.activeLiveDataSpinner();

    // create live data request options
    this.liveDataReqOpt = this.createLiveDataRequestOptions();
    this.liveDataReqOpt.sessionId = this.sharedService.username + '_' + moment.utc().valueOf();

    let service;

    if (serviceProvider === 'pushy') {
      this.isGettingLiveDataPushy = true;
      service = this.deviceTriggerCommandsService.requestLiveDataWithPushy(this.liveDataReqOpt);
    } else {
      this.isGettingLiveData = true;
      service = this.deviceTriggerCommandsService.requestLiveData(this.liveDataReqOpt);
    }

    this.subscribers.requestLiveData = service.subscribe(
      (result: any) => {
        // ____ START GET LIVE DATA INTERVAL
        this.liveDataCount[this.liveDataReqOpt.sessionId] = 0;
        this.intervals.getLiveData = setInterval(() => this.fetchLiveData(), 2000);
      },
      (error: any) => {
        this.node.setDataValue('liveStatus', 'ERROR');
        this.isGettingLiveData = false;
        this.isGettingLiveDataPushy = false;
      }
    );
  }

  private fetchLiveData() {
    this.subscribers.getLiveData = this.deviceTriggerCommandsService
      .getLiveData(this.liveDataReqOpt.automationId, this.liveDataReqOpt.sessionId)
      .subscribe((result: any) => {
        if (result && result.data) {
          // ____ STOP GET LIVE DATA INTERVAL
          if (this.intervals.getLiveData) {
            clearInterval(this.intervals.getLiveData);
            this.intervals.getLiveData = null;
          }

          // ____ STOP INDICATOR
          this.node.setDataValue('liveStatus', '');
          this.isGettingLiveData = false;
          this.isGettingLiveDataPushy = false;

          // ____ ADD GATEWAY OR AUTOMATION INTO THE LIST JUST FOR SHOW LOGGED AT
          if (this.data.deviceTypeKey === 'a' || this.data.deviceTypeKey === 'g') {
            result.data.push({
              deviceId: this.data.id + '',
              value: '{"error":""}',
            });
          }

          // ____ UDPATE LIVE_STATUS CELL
          this.containerComponent.updateLiveStatus(result);
        } else {
          this.liveDataCount[this.liveDataReqOpt.sessionId]++;
          if (this.liveDataCount[this.liveDataReqOpt.sessionId] >= 15) {
            // ____ STOP GET LIVE DATA INTERVAL
            if (this.intervals.getLiveData) {
              clearInterval(this.intervals.getLiveData);
              this.intervals.getLiveData = null;
            }

            // ____ STOP INDICATOR
            this.node.setDataValue('liveStatus', 'TIMEOUT');
            this.isGettingLiveData = false;
            this.isGettingLiveDataPushy = false;
          }
        }
      });
  }

  /**
   * send live request for automation > v1rm+
   */
  private sendLiveDataRequest() {
    this.activeLiveDataSpinner();

    // create live data request options
    this.liveDataReqOpt = this.createLiveDataRequestOptions();

    // this.subscribers.requestLiveData = this.deviceTriggerCommandsService.sendLiveRequest(this.liveDataReqOpt).subscribe(
    this.subscribers.requestLiveData = this.liveRequestsService.sendLiveRequest(this.liveDataReqOpt).subscribe(
      (result: any) => {
        if (!result.data || !result.data.sessionId) {
          this.node.setDataValue('liveStatus', 'ERROR_SESSION');
          return;
        } else if (result.data.notification && result.data.notification.error) {
          this.node.setDataValue('liveStatus', 'ERROR_SEND_TO_AUTOMATION');
          return;
        }

        // store sessionId into liveDataReqOpt
        this.liveDataReqOpt.sessionId = result.data.sessionId;
        // ____ START GET LIVE DATA INTERVAL
        this.liveDataCount[this.liveDataReqOpt.sessionId] = 0;
        this.intervals.getLiveData = setInterval(() => this.getLiveDataResult(), 2000);
      },
      (error: any) => {
        this.node.setDataValue('liveStatus', 'ERROR');
        this.isGettingLiveData = false;
        this.isGettingLiveDataPushy = false;
      }
    );
  }

  /**
   * get live request result for automation > v1rm+
   */
  private getLiveDataResult() {
    // this.subscribers.getLiveData = this.deviceTriggerCommandsService
    //   .getLiveRequestResult(this.liveDataReqOpt.sessionId)

    this.subscribers.getLiveData = this.liveRequestsService
      .getLiveRequestResult(this.liveDataReqOpt.sessionId)
      .subscribe((result: any) => {
        if (result && result.data && (result.data.error || result.data.value)) {
          const value = JSON.parse(result.data.value);
          const error = JSON.parse(result.data.error);

          // ____ STOP GET LIVE DATA INTERVAL
          if (this.intervals.getLiveData) {
            clearInterval(this.intervals.getLiveData);
            this.intervals.getLiveData = null;
          }

          // ____ STOP INDICATOR
          this.node.setDataValue('liveStatus', '');
          this.isGettingLiveData = false;
          this.isGettingLiveDataPushy = false;

          // SET LIVE DATA FOR UPDATE TABLE
          const liveDataResult = {
            data: [],
            updatedAt: result.data.updatedAt,
          };

          if (error) {
            liveDataResult.data.push({
              deviceId: result.data.deviceId,
              value: error,
            });
          } else if (value) {
            // ____ WHEN GATEWAY OR AUTOMATION ADD IT JUST FOR SHOW LOGGED AT
            if (this.data.deviceTypeKey === 'a' || this.data.deviceTypeKey === 'g') {
              // automation/gateway
              liveDataResult.data.push({
                deviceId: this.data.id + '',
                value: '{"error":""}',
              });

              // loop devices of automation/gateway
              value.forEach((v) => {
                liveDataResult.data.push({
                  deviceId: v.deviceId + '',
                  value: v.value,
                });
              });
            } else {
              liveDataResult.data.push({
                deviceId: result.data.deviceId,
                value,
              });
            }
          }

          // ____ UDPATE LIVE_STATUS CELL
          this.containerComponent.updateLiveStatus(liveDataResult);
        } else {
          this.liveDataCount[this.liveDataReqOpt.sessionId]++;
          if (this.liveDataCount[this.liveDataReqOpt.sessionId] >= 15) {
            // ____ STOP GET LIVE DATA INTERVAL
            if (this.intervals.getLiveData) {
              clearInterval(this.intervals.getLiveData);
              this.intervals.getLiveData = null;
            }

            // ____ STOP INDICATOR
            this.node.setDataValue('liveStatus', 'TIMEOUT');
            this.isGettingLiveData = false;
            this.isGettingLiveDataPushy = false;
          }
        }
      });
  }

  private viewDeviceDetails() {
    const path = ['devices', this.data.id];
    const query = { site: this.sharedService.selectedSite.id };
    this.store.dispatch(new fromStore.Go({ path, query }));
  }

  private openUpdateWizard() {
    this.containerComponent.stopAutoUpdate();
    this.wizardDialog = this.dialogService.open(UpdateWizardComponent, {
      header: this.sharedService.getTranslation('DEVICE_WIZARD_MODAL_TITLE'),
      styleClass: 'fullscreen',
      data: {
        device: this.data,
        deviceModels: this.deviceModels,
        deviceTypes: this.deviceTypes,
        deviceProtocols: this.deviceProtocols,
        protocolControllers: this.protocolControllers,
      },
    });

    this.wizardDialog.onClose.subscribe({
      next: (devices) => {
        for (const device of devices) {
          this.parentComponent.updateRow({ ...device });
        }
        this.containerComponent.startAutoUpdate();
      },
    });
  }

  get showActionCell(): boolean {
    if (this.actions && this.data && this.data.id && !this.data.isDeleted) {
      return true;
    }
    return false;
  }
}
