import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { Translation } from '../translation.interface';

import { TranslationsService } from '../translations.service';
import { LocalesService } from '@widgets/locales/services/locales.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-translation-import-form',
  templateUrl: 'translation-import-form.component.html',
})
export class TranslationImportFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  form: FormGroup;
  errorMessage: string;
  isSubmitting: boolean;
  subscribers: { [key: string]: any };
  fetchingState: number;

  selectItems: { [key: string]: SelectItem[] };
  translationsSource: Translation[];
  currentImportExportForm: string;

  constructor(
    private formBuilder: FormBuilder,
    private translationsService: TranslationsService,
    private localesService: LocalesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.subscribers = {};
    this.fetchingState = 0;
    this.selectItems = {};

    if (this.data.selectedRows && this.data.selectedRows.length) {
      this.currentImportExportForm = 'export';
    } else {
      this.currentImportExportForm = 'import';
    }

    this.fetchLocales();
    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchLocales() {
    this.fetchingState++;
    this.subscribers.GET_LOCALES = this.localesService.get().subscribe((result: any) => {
      this.selectItems.locales = this.sharedService.createSelectItems(
        result.data.filter((d) => !d.isDeleted),
        false
      );
      this.fetchingState--;
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      locale: [null, Validators.required],
      translationFile: [null, Validators.required],
    });

    this.showImportExport(this.currentImportExportForm);
  }

  // ____ FORM SUBMIT HANDLER
  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      if (this.currentImportExportForm === 'import') {
        this.importXlsx(this.form.value.translationFile);
      } else {
        this.exportXlsx(this.form.value.locale);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  exportXlsx(locale = 'cn') {
    if (!this.data || !this.data.selectedRows || !this.data.selectedRows.length) {
      this.errorMessage = 'PLEASE_SELECT_TRANSLATION_KEYS_TO_EXPORT';
      this.isSubmitting = false;
      return;
    }

    const exportData = [['id', 'en', locale]];
    for (let i = 0; i < this.data.selectedRows.length; i++) {
      const row = this.data.selectedRows[i];
      if (row.isDeleted) {
        continue;
      }

      const rowData = [row.id];
      for (let j = 0; j < row.translationValues.length; j++) {
        if (['en', locale].indexOf(row.translationValues[j].locale) < 0) {
          continue;
        }

        if (row.translationValues[j].locale === 'en') {
          rowData[1] = row.translationValues[j].value;
        } else if (row.translationValues[j].locale === locale) {
          rowData[2] = row.translationValues[j].value;
        }
      }

      if (rowData[0] && rowData[1]) {
        exportData.push(rowData);
      }
    }

    if (exportData.length > 1) {
      this.translationsService.createXlsx(exportData, locale);
      this.onClose.emit();
    }
  }

  importXlsx(file: any) {
    this.translationsService.readXlsx(file).then((importData) => {
      this.translationsService.importTranslation(importData).subscribe((result: any) => {
        this.sharedService.notify('SUCCESS', 'TRANSLATION', 'success');
        this.onClose.emit();
      });
    });
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  showImportExport(key: string) {
    this.currentImportExportForm = key;

    if (this.currentImportExportForm === 'export') {
      this.form.controls['locale'].setValidators(Validators.required);
      this.form.controls['translationFile'].clearValidators();
    } else {
      this.form.controls['locale'].clearValidators();
      this.form.controls['translationFile'].setValidators(Validators.required);
    }
  }
}
