<div class="device-model-item" [ngClass]="{ opened: isOpened }">
  <div class="device-model-item-header">
    <div class="device-model-item-header-toggler">
      <span
        class="fa fa-fw"
        [ngClass]="{ 'fa-chevron-up': isOpened, 'fa-chevron-down': !isOpened }"
        (click)="isOpened = !isOpened"
      ></span>
    </div>

    <div class="device-model-item-header-title">
      <div class="primary">
        {{ data?.product_name }}
      </div>
      <div class="secondary">
        {{ data?.display_name }}
      </div>
    </div>

    <div class="device-model-item-header-actions">
      <a class="action-item" (click)="onSelectDevice()">
        {{ "SELECT" | translate }}
      </a>
    </div>
  </div>

  <div class="device-model-item-content" *ngIf="isOpened">
    <div class="section">
      <h5 class="section-title">
        {{ "PROPERTIES" | translate }}
      </h5>
      <ul class="section-content" *ngIf="properties?.length">
        <li *ngFor="let prop of properties">
          {{ prop.display_name }}
        </li>
      </ul>
    </div>

    <div class="section">
      <h5 class="section-title">
        {{ "DEVICE_PROTOCOL" | translate }}
      </h5>
      <ul class="section-content">
        <li>{{ data?.protocolName || data?.protocol }}</li>
      </ul>
    </div>

    <div class="section">
      <h5 class="section-title">
        {{ "DEVICE_BRAND" | translate }}
      </h5>
      <ul class="section-content">
        <li>{{ data?.brandName || data?.brand }}</li>
      </ul>
    </div>

    <div class="section">
      <img [src]="productImage" width="100" />
    </div>
  </div>
</div>
