import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ReportTemplatesTableComponent } from './report-templates-table/report-templates-table.component';
import { ReportTemplateFormComponent } from './report-template-form/report-template-form.component';
import { ReportTemplateActionCellComponent } from './report-template-action-cell/report-template-action-cell.component';
import { ReportTemplateFormService } from './services/report-template-form.service';
import { ReportTemplatesService } from './services/report-templates.service';

@NgModule({
  imports: [SharedModule],
  declarations: [ReportTemplatesTableComponent, ReportTemplateActionCellComponent, ReportTemplateFormComponent],
  entryComponents: [ReportTemplatesTableComponent, ReportTemplateActionCellComponent, ReportTemplateFormComponent],
  providers: [ReportTemplateFormService, ReportTemplatesService],
})
export class ReportTemplatesModule {}
