import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { Rule } from '@widgets/rules/models/rule';
import { RuleCondition } from '@widgets/rules/models/rule-condition';
import { RuleTemplate } from '@widgets/rule-templates/models/rule-template';
import { RuleTemplateCondition } from '@widgets/rule-templates/models/rule-template-condition';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-condition-mass-edit-form',
  templateUrl: './condition-form.component.html',
  styleUrls: ['./condition-form.component.scss']
})
export class RuleConditionMassEditFormComponent implements OnInit {
  @Input()
  data: { [key: string]: any };
  @Input()
  form: FormGroup;
  @Input() isEndCondition: boolean;

  rule: Rule;
  ruleTemplate: RuleTemplate;
  // conditions: RuleTemplateCondition[];

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    // this.rule = this.form.value;
    this.ruleTemplate = this.data.selectedRuleTemplate;
    this.rule = this.data.selectedRule;
  }

  get templateConditions(): RuleTemplateCondition[] {
    return this.ruleTemplate[this.conditionsFormName] || [];
  }

  get conditions(): RuleCondition[] {
    return this.rule[this.conditionsFormName] || [];
  }

  hasError(condition) {
    if (condition) {
      const errors = Object.keys(condition.errors);
      if (errors.length) {
        return 'red white-text';
      }
    }
    return '';
  }

  isEmpty(condition) {
    if (condition && condition.errors && condition.errors.empty) {
      return true;
    }
    return false;
  }

  get conditionsFormName() {
    return this.isEndCondition ? 'endConditions' : 'startConditions';
  }

  get conditionsForm() {
    return this.form.controls[this.conditionsFormName] as FormArray;
  }

  hasMappingValues(control: FormGroup, index: number) {
    const sourceType = control.get('sourceType').value;

    switch (sourceType) {
      case 'd':
        return this.getDeviceTypeMappingValues(
          control.get('deviceTypeId').value
        );

      case 'l':
        return this.getLocationPropertyMappingValues(
          control.get('locationPropertyTypeId').value
        );

      default:
        return;
    }
  }

  getDeviceTypeMappingValues(deviceTypeId: number) {
    if (
      this.data &&
      this.data.raw &&
      this.data.raw.deviceTypes &&
      deviceTypeId
    ) {
      const deviceType = this.data.raw.deviceTypes.find(
        d => d.id === +deviceTypeId
      );
      if (
        deviceType &&
        deviceType.mappingValues &&
        deviceType.mappingValues.length
      ) {
        return this.sharedService.createSelectItems(
          deviceType.mappingValues,
          false,
          'key'
        );
      }
    }
    return;
  }

  getLocationPropertyMappingValues(locationPropertyKey: string) {
    if (
      this.data &&
      this.data.raw &&
      this.data.raw.locationProperties &&
      locationPropertyKey
    ) {
      // Get Location Property
      const locationProperty = this.data.raw.locationProperties.find(
        d => d.key === locationPropertyKey
      );

      if (locationProperty) {
        // Check Location Property
        switch (locationProperty.type) {
          case 'boolean':
            return this.data.selectItems.booleanOption;

          case 'deviceType':
            if (this.data.raw.deviceTypes) {
              // Get Device Type
              const deviceType = this.data.raw.deviceTypes.find(
                d => d.key === locationProperty.deviceType
              );

              if (
                deviceType &&
                deviceType.mappingValues &&
                deviceType.mappingValues.length
              ) {
                // Get Device Type Mapping Values
                return this.sharedService.createSelectItems(
                  deviceType.mappingValues,
                  false,
                  'key'
                );
              }
            }
            return;
        }
      }
    }
    return;
  }

  getDeviceTypeName(deviceTypeId: number) {
    if (
      this.data &&
      this.data.raw &&
      this.data.raw.deviceTypes &&
      deviceTypeId
    ) {
      const deviceType = this.data.raw.deviceTypes.find(
        d => d.id === deviceTypeId
      );
      if (deviceType) {
        return deviceType.name;
      }
    }
    return '(empty)';
  }

  getLocationPropertyLabel(locationPropertyKey: string) {
    if (
      this.data &&
      this.data.raw &&
      this.data.raw.locationProperties &&
      locationPropertyKey
    ) {
      const locationProperty = this.data.raw.locationProperties.find(
        d => d.key === locationPropertyKey
      );
      if (locationProperty) {
        return locationProperty.label;
      }
    }
    return '(empty)';
  }
}
