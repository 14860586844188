import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { LocationProperty } from '@widgets/location-properties/models/location-property';

interface SummaryItem {
  id: number;
  key: string;
  hidden: boolean;
  label?: string;
}

@Component({
  selector: 'sc-location-summary-form',
  templateUrl: './location-summary-form.component.html',
  styleUrls: ['./location-summary-form.component.scss'],
})
export class LocationSummaryFormComponent implements OnInit {
  @Input()
  summaryConfig: { enableCustomization: boolean; summaryItems: SummaryItem[] };
  @Input()
  locationProperties: LocationProperty[];

  @Output()
  saved = new EventEmitter();

  enableCustomization: boolean = false;
  summaryItems: SummaryItem[] = [];

  constructor(private config: DynamicDialogConfig, private dialog: DynamicDialogRef) {}

  ngOnInit() {
    if (this.config && this.config.data) {
      const data = this.config.data;

      if (data.summaryConfig) {
        this.enableCustomization = data.summaryConfig.enableCustomization;
        this.summaryItems = [...data.summaryConfig.summaryItems].map((item) => ({ ...item }));
      }

      if (data.locationProperties) {
        this.patchSummaryItems(data.locationProperties);
      }
    } else if (this.summaryConfig) {
      this.enableCustomization = this.summaryConfig.enableCustomization;
      this.summaryItems = [...this.summaryConfig.summaryItems].map((item) => ({ ...item }));

      if (this.locationProperties) {
        this.patchSummaryItems(this.locationProperties);
      }
    }
  }

  private patchSummaryItems(locationProperties: LocationProperty[]) {
    for (const prop of locationProperties) {
      if (prop.isDeleted || !prop.isActive) {
        continue;
      }

      let found = false;

      for (let i = 0; i < this.summaryItems.length; i++) {
        const item = this.summaryItems[i];
        if (item.id === prop.id) {
          item.label = prop.label;
          item.key = prop.key;
          found = true;
          break;
        }
      }

      // add new location prop when not exists
      if (!found) {
        this.summaryItems.push({
          label: prop.label,
          key: prop.key,
          id: prop.id,
          hidden: true,
        });
      }
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.summaryItems, event.previousIndex, event.currentIndex);
  }

  // onfinish() {
  //   this.dialog.close({
  //     enableCustomization: this.enableCustomization,
  //     summaryItems: this.summaryItems.map((item) => {
  //       delete item.label;
  //       return item;
  //     }),
  //   });
  // }

  onSave() {
    this.saved.emit({
      enableCustomization: this.enableCustomization,
      summaryItems: this.summaryItems.map((item) => {
        const tmp = { ...item };
        delete tmp.label;
        return tmp;
      }),
    });
  }
}
