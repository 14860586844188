<div *ngIf="loadInitData; then loadingIndicator; else content"></div>

<ng-template #content>
  <sc-options-form
    [form]="optionsForm"
    [selectItems]="selectItems"
    (submitted)="onOptionsChange($event)"
  ></sc-options-form>

  <div class="ui-g">
    <div class="ui-g-3">
      <sc-rule-list
        *ngIf="rules?.length"
        [rules]="rules"
        (ruleSelected)="onRuleSelected($event)"
      ></sc-rule-list>
    </div>

    <div class="ui-g-9">
      <sc-rule-mass-edit-form
        *ngIf="ruleForm"
        [form]="ruleForm"
        [data]="dataForCmdAndCondForm"
        (applyAllChanged)="onApplyAllChanged($event)"
      ></sc-rule-mass-edit-form>
    </div>
  </div>

  <div class="ui-g" *ngIf="rules?.length">
    <div class="ui-g-offset-3 ui-g-9 text-right">
      <sc-submit-button
        buttonClass="ui-button-success"
        (submitted)="submit()"
        [pending]="isSubmitting"
        [disabled]="isSubmitting"
      ></sc-submit-button>
    </div>
  </div>
</ng-template>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
