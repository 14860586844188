import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-scene-command-form',
  templateUrl: 'command-form.component.html'
})
export class CommandFormComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  optionData: any;

  devices: any[] = [];
  devicesSource: any[] = [];

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    if (this.group && this.group.value && this.group.value.deviceTypeId) {
      this.devices = this.sharedService.createSelectItems(
        this.optionData.filter(
          (d: any) => d.deviceTypeId === this.group.value.deviceTypeId
        ),
        false
      );
    }
    this.devicesSource = this.optionData;
  }

  get parameterForms(): FormArray {
    return this.group.get('parameters') as FormArray;
  }

  get action() {
    return this.group.get('action').value;
  }

  get deviceId() {
    return this.group.get('deviceId').value;
  }

  get deviceTypeId() {
    return this.group.get('deviceTypeId').value;
  }
}
