import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { Site } from '@widgets/sites/models/site';
import { Event } from '../models/event';

import { EventsService } from '../services/events.service';
import { EventFormsService } from '../services/event-forms.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  currentContentTemplate = 0;
  currentPushNotificationTemplate = 0;
  currentTeaserTemplate = 0;
  currentTitleTemplate = 0;
  editMode: boolean;
  errorMessage: string;
  formData: Event;
  fetchingState = 0;
  form: FormGroup;
  isSubmitting: boolean;
  optionData: { [key: string]: SelectItem[] };

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private eventFormsService: EventFormsService,
    private eventsService: EventsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;
    this.optionData = {
      locales: this.sharedService.selectItems.locales,
      sites: this.sharedService.selectItems.sites
    };

    if (this.data) {
      // EDIT MODE
      this.editMode = true;

      this.fetchEvent(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchEvent(id: number) {
    this.fetchingState++;
    this.subscribers.GET_EVENT = this.eventsService
      .getEvent(id)
      .subscribe((result: any) => {
        this.formData = result.data;

        // PARSE DATE STRING TO DATE OBJECT
        this.formData.publishFrom = this.sharedService.parseDateStringToDateObject(
          this.formData.publishFrom
        );
        this.formData.publishTo = this.sharedService.parseDateStringToDateObject(
          this.formData.publishTo
        );

        if (
          this.formData.pushNotificationScheduler &&
          this.formData.pushNotificationScheduler.length > 0
        ) {
          this.formData.pushNotificationScheduler = this.formData.pushNotificationScheduler.map(
            pns => ({
              ...pns,
              sendNotificationAt: this.sharedService.parseDateStringToDateObject(
                pns.sendNotificationAt
              )
            })
          );
        }

        // PATCH FORM DATA INTO FORM
        this.form.patchValue(this.formData);
        this.eventFormsService.setEventMultiLanguageFormArray(
          this.form,
          this.formData.title,
          'title'
        );
        this.eventFormsService.setEventMultiLanguageFormArray(
          this.form,
          this.formData.content,
          'content'
        );
        this.eventFormsService.setEventMultiLanguageFormArray(
          this.form,
          this.formData.teaserText,
          'teaserText'
        );
        this.eventFormsService.setEventMultiLanguageFormArray(
          this.form,
          this.formData.pushNotificationText,
          'pushNotificationText'
        );
        this.eventFormsService.setEventPushNotificationSchedulerFormArray(
          this.form,
          this.formData.pushNotificationScheduler
        );
        this.fetchingState--;
      });
  }

  private initForm() {
    this.form = this.eventFormsService.initEventForm();

    if (!this.editMode) {
      this.form.patchValue({
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id
      });

      this.addMultiLanguage('title');
      this.addMultiLanguage('content');
    }
  }

  flagIcon(locale) {
    if (locale) {
      locale = locale === 'en' ? 'gb' : locale;
      return 'flag-icon flag-icon-' + locale;
    }
    return '';
  }

  addMultiLanguage(controlName: string) {
    const control =  this.form.controls[controlName] as FormArray;
    control.push(this.eventFormsService.initEventMultiLanguageFormGroup());
    this.changeCurrentTemplate(controlName, control.length - 1);
  }

  removeMultiLanguage(controlName: string, i: number) {
    const control =  this.form.controls[controlName] as FormArray;
    control.removeAt(i);
    this.changeCurrentTemplate(controlName, 0);
  }

  private changeCurrentTemplate(name: string, index: number) {
    switch (name) {
      case 'content':
        this.currentContentTemplate = index;
        break;

      case 'pushNotificationText':
        this.currentPushNotificationTemplate = index;
        break;

      case 'teaserText':
        this.currentTeaserTemplate = index;
        break;

      case 'title':
        this.currentTitleTemplate = index;
        break;

      default:
        break;
    }
  }

  get title(): FormArray {
    return this.form.get('title') as FormArray;
  }

  get teaserText(): FormArray {
    return this.form.get('teaserText') as FormArray;
  }

  get content(): FormArray {
    return this.form.get('content') as FormArray;
  }

  get pushNotificationText(): FormArray {
    return this.form.get('pushNotificationText') as FormArray;
  }

  get pushNotificationScheduler(): FormArray {
    return this.form.get('pushNotificationScheduler') as FormArray;
  }

  addPushNotificationScheduler() {
    const control = this.form.controls.pushNotificationScheduler as FormArray;
    control.push(this.eventFormsService.initEventPushNotificationSchedulerFormGroup());
  }

  removePushNotificationScheduler(i: number) {
    const control = this.form.controls.pushNotificationScheduler as FormArray;
    control.removeAt(i);
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.form.getRawValue();

      // PARSE DATE OBJECT TO DATE STRING
      if (formData.publishFrom) {
        formData.publishFrom = this.sharedService.parseDateObjectToDateString(
          formData.publishFrom
        );
      }
      if (formData.publishTo) {
        formData.publishTo = this.sharedService.parseDateObjectToDateString(
          formData.publishTo
        );
      }
      if (
        formData.pushNotificationScheduler &&
        formData.pushNotificationScheduler.length > 0
      ) {
        formData.pushNotificationScheduler = formData.pushNotificationScheduler.map(
          pns => ({
            sendNotificationAt: this.sharedService.parseDateObjectToDateString(
              pns.sendNotificationAt
            ),
            sendOnlyIfLocationIsOccupied: pns.sendOnlyIfLocationIsOccupied
          })
        );
      }

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(formData) {
    delete formData.id;
    this.subscribers.create = this.eventsService
      .createEvent(formData)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'CREATE_FAIL')
      });
  }

  private update(formData) {
    this.subscribers.update = this.eventsService
      .updateEvent(this.formData.id, formData)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'UPDATE_FAIL')
      });
  }

  delete() {
    this.subscribers.delete = this.eventsService
      .deleteEvent(this.formData.id)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        error: this.apiCallError.bind(this, 'DELETE_FAIL')
      });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('EVENT');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
