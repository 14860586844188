import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-unlock-page',
  styleUrls: ['./unblock-page.component.scss'],
  templateUrl: './unblock-page.component.html',
})
export class UnblockPageComponent implements OnInit, OnDestroy {
  errorMessage: string;
  isUnblocking = false;
  unblocked = false;

  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      // wait 1.5s before calling api
      clearTimeout(this.timeouts.QUERY_PARAMS);
      this.timeouts.QUERY_PARAMS = setTimeout(() => {
        const key = params.key;
        const username = params.username;
        if (key && username) {
          this.unblock(username, key);
        } else {
          this.router.navigate(['/my_account']);
        }
      }, 1500);
    });
  }

  ngOnDestroy() {
    this.sharedService.clearTimeouts(this.timeouts);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private unblock(username: string, key: string) {
    this.isUnblocking = true;
    this.subscribers.UNBLOCK = this.authService.unblock(username, key).subscribe(
      (result: any) => {
        this.unblocked = true;
        this.isUnblocking = false;

        // wait 3 seconds before going to login page
        this.timeouts.GO_LOGIN = setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      },
      (error: any) => {
        this.errorMessage = error.error.message;
        this.unblocked = false;
        this.isUnblocking = false;
      }
    );
  }
}
