import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { ModalConfig } from '@sc/modal/modal-config';
import { DeviceTaskTemplate } from '../models/device-task-template';

import { DeviceTaskTemplatesService } from '../services/device-task-templates.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { DeviceTaskTemplateActionCellComponent } from '../device-task-template-action-cell/device-task-template-action-cell.component';

@Component({
  selector: 'sc-device-task-template-table',
  templateUrl: './device-task-template-table.component.html',
  styleUrls: ['./device-task-template-table.component.scss']
})
export class DeviceTaskTemplateTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: DeviceTaskTemplate[];
  columns: any[];
  formConfig: ModalConfig;
  tableTitle: string;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceTaskTemplatesService: DeviceTaskTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Table name
    this.tableTitle = 'DEVICE_TASK_TEMPLATE_TABLE_TITLE';

    // Table Columns
    this.columns = this.createColumns();

    // Create Form Config
    this.formConfig = {
      name: 'DeviceTaskTemplateForm',
      options: { modalTitle: 'DEVICE_TASK_TEMPLATE_FORM_TITLE' }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.GET_DATASET = this.deviceTaskTemplatesService
      .getDeviceTaskTemplates()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'execute_instantly',
        headerName: 'EXECUTE_INSTANTLY',
        field: 'execute_instantly',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'recurring',
        headerName: 'RECURRING',
        field: 'recurring',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'is_public',
        headerName: 'IS_PUBLIC',
        field: 'is_public',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'is_active',
        headerName: 'ACTIVE',
        field: 'is_active',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'is_deleted',
        headerName: 'DELETED',
        field: 'is_deleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceTaskTemplateActionCellComponent
      }
    ];
  }
}
