import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppLayoutComponent } from '@app/core2/app-layout/app-layout.component';
// import { LoginLayoutComponent } from '@app/core2/login-layout/login-layout.component';
import { ErrorLayoutComponent } from '@app/core2/error-layout/error-layout.component';
import { PageComponent } from '@app/core2/page/page.component';
// import { LoginPageComponent } from '@app/auth/login-page/login-page.component';
// import { UnblockPageComponent } from '@app/auth/unblock-page/unblock-page.component';

import { AuthGuard } from '@app/auth/guards/auth.guard';
import { PageGuard } from '@app/core2/page/page.guard';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./signin/signin.module').then((m) => m.SigninModule) },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule) },
  { path: 'integrator', loadChildren: () => import('./integrator/integrator.module').then((m) => m.IntegratorModule) },
  { path: 'enduser', loadChildren: () => import('./enduser/enduser.module').then((m) => m.EnduserModule) },
  // { path: 'integrator/signin', loadChildren: () => import('./signin/signin.module').then((m) => m.SigninModule) },
  // {
  // path: 'login',
  // component: LoginLayoutComponent,
  // children: [
  //   {
  //     path: '',
  //     component: LoginPageComponent,
  //   },
  //   {
  //     path: 'unblock',
  //     component: UnblockPageComponent,
  //   },
  // ],
  // },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard, PageGuard],
    children: [
      {
        path: 'settings/:pageId',
        component: PageComponent,
      },
      {
        path: ':pageId',
        component: PageComponent,
      },
      {
        path: ':pageId/:pageParams',
        component: PageComponent,
        canActivate: [PageGuard],
      },
      { path: '', redirectTo: '/my_account', pathMatch: 'full' },
    ],
  },
  { path: '**', component: ErrorLayoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
