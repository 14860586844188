<div class="update-wizard">
  <div class="update-wizard-body" #updateWizardContent>
    <ng-container [ngSwitch]="wizardStep">
      <ng-container *ngSwitchCase="0">
        Loading...
      </ng-container>

      <ng-container *ngSwitchCase="1">
        <ng-container [ngSwitch]="selectedDevice?.deviceTypeKey">
          <ng-container *ngSwitchCase="'vdh'">
            <sc-device-holder-setting
              [form]="form?.controls[0]"
              [productImage]="productImage"
              [selectItems]="selectItems"
              [data]="selectedDevice"
            ></sc-device-holder-setting>

            <ng-container *ngFor="let ctrl of form?.controls; let i = index">
              <sc-device-setting
                *ngIf="i > 0"
                [form]="ctrl"
                [formId]="i"
                [data]="selectedDevice?.virtualDevices[i - 1]"
              ></sc-device-setting>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'a'">
            <sc-automation-setting
              [form]="form?.controls[0]"
              [productImage]="productImage"
              [selectItems]="selectItems"
              [data]="selectedDevice"
            ></sc-automation-setting>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <sc-device-plan
          [devices]="locationDevices"
          [location]="selectedLocation"
          [selectedDevice]="selectedDevice"
          (positionChanged)="onPositionChanged($event)"
        ></sc-device-plan>
      </ng-container>

      <ng-container *ngSwitchCase="3">
        <sc-device-summary
          [data]="formData"
          [source]="summarySource"
          [productImage]="productImage"
        ></sc-device-summary>

        <!-- Error Message -->
        <div *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
  </div>

  <div class="update-wizard-footer" *ngIf="wizardStep > 0">
    <div class="previous-step">
      <button
        *ngIf="wizardStep > 1"
        type="button"
        pButton
        [label]="'BACK' | translate"
        class="ui-button-success"
        (click)="onBackClicked()"
      ></button>
    </div>

    <div class="current-step">
      {{ wizardStep }} / {{ totalStep }}
    </div>

    <div class="next-step" *ngIf="wizardStep !== totalStep">
      <button
        type="button"
        pButton
        [label]="'NEXT' | translate"
        class="ui-button-success"
        [disabled]="form.invalid"
        (click)="onNextClicked()"
      ></button>
    </div>

    <div class="next-step" *ngIf="wizardStep === totalStep">
      <button
        type="button"
        pButton
        [label]="'UPDATE' | translate"
        class="ui-button-success"
        [disabled]="isSubmitting"
        (click)="onUpdateClicked()"
      ></button>
    </div>
  </div>
</div>
