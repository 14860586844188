import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { sortBy } from 'lodash';

@Component({
  selector: 'sc-translation-value-cell',
  templateUrl: 'translation-value-cell.component.html'
})
export class TranslationValueCellComponent implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }

  flag(locale) {
    switch (locale) {
      case 'en':
        return 'mr-1 flag-icon flag-icon-gb';
      default:
        return 'mr-1 flag-icon flag-icon-' + locale;
    }
  }

  get cellValue() {
    if (this.cell && this.cell.value) {
      return sortBy(this.cell.value, 'locale');
    }
    return [];
  }
}
