import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';

@Component({
  selector: 'sc-device-value-storage',
  templateUrl: './device-value-storage.component.html',
  styleUrls: ['./device-value-storage.component.scss'],
})
export class DeviceValueStorageComponent implements OnInit {
  actions: any[];

  @Input()
  commandForm: FormGroup;

  @Input()
  selectItems: SelectItems = {};

  @Input()
  dataSources: DataSources = {};

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {
    this.actions = [
      { label: 'Store', value: 'store' },
      { label: 'Restore', value: 'restore' },
    ];

    if (this.commandForm) {
      // incase switch from trigger device
      this.commandForm.get('action').enable();
    }
  }
}
