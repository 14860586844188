<ng-container *ngIf="isFirstTime then spinner; else content"></ng-container>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <sc-table
    [config]="config"
    [columns]="tableColumns"
    [dataset]="dataset"
    (reloadData)="fetchDataset()"
    [title]="tableTitle"
    [toolbarItems]="toolbarItems"
  ></sc-table>
</ng-template>
