<div class="sc-flex grow-1 justify-content-between">
  <span *ngIf="numberOfLeaves?.length">
    <i *ngFor="let n of numberOfLeaves" class="fa fa-fw fa-leaf green-text"></i>
  </span>
  <span>&nbsp;</span>

  <span>
    {{ rating }}
  </span>
</div>
