import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeviceProtocol } from '../models/device-protocol';

import { config } from '@app/config';

@Injectable()
export class DeviceProtocolsService {
  private apiUrl = config().apiUrl + '/deviceProtocols';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): DeviceProtocol {
    const newData = { ...data };
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getDeviceProtocol(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getDeviceProtocols() {
    return this.httpClient.get(this.apiUrl);
  }

  createDeviceProtocol(data: DeviceProtocol) {
    return this.httpClient.post(this.apiUrl, this.verifyData(data));
  }

  updateDeviceProtocol(id: number, data: DeviceProtocol) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteDeviceProtocol(id: number, force: boolean = false) {
    let apiUrl = `${this.apiUrl}/${id}`;
    if (force === true) {
      apiUrl += '?force';
    }
    return this.httpClient.patch(apiUrl, {
      isActive: false,
      isDeleted: true
    });
  }
}
