import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LocationTemplate } from '../models/location-template';
import { LocationTemplateRule } from '../models/location-template-rule';
import { LocationTemplateDevice } from '../models/location-template-device';

import { config } from '../../../config';

@Injectable()
export class LocationTemplateFormsService {
  patterns = config().regexPatterns;

  constructor(private formBuilder: FormBuilder) {}

  // ____ DEVICES
  initDeviceFormGroup(): FormGroup {
    const formControls = {
      brandId: [null, Validators.required],
      deviceModelId: [null, Validators.required],
      deviceTypeId: [null, Validators.required],
      protocolId: [null, Validators.required],
      description: [null, Validators.required],
      avcCalcWeight: null,
      isControllableByGuest: false,
      isVisibleByGuest: false,
      isActive: false,
      isGrouped: false
    };

    return this.formBuilder.group(formControls);
  }

  setDeviceFormArray(form: FormGroup, data: LocationTemplateDevice[]) {
    const formGroups = data.map(item => {
      const fg = this.initDeviceFormGroup();
      fg.patchValue(item);
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('devices', formArray);
  }

  // ____ RULE_TEMPLATES
  initRuleFormGroup(): FormGroup {
    const formControls = {
      ruleTemplateId: [null, Validators.required],
      isActive: false
    };

    return this.formBuilder.group(formControls);
  }

  setRuleFormArray(form: FormGroup, data: LocationTemplateRule[]) {
    const formGroups = data.map(item => {
      const fg = this.initRuleFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('ruleTemplates', formArray);
  }

  // ____ PARENT (ROOT)
  initLocationTemplateForm(isChild?: boolean): FormGroup {
    const formControls: any = {
      description: [null, Validators.required],
      locationProfileId: null,
      locationTypeId: [null, Validators.required],
      ruleTemplates: this.formBuilder.array([]),
      devices: this.formBuilder.array([]),
      children: this.formBuilder.array([]),
      isActive: false,
      isDeleted: false
    };

    if (!isChild) {
      formControls.id = { value: null, disabled: true };
      formControls.name = [null, Validators.required];
    }

    return this.formBuilder.group(formControls);
  }

  // ____ CHILDREN
  setChildrenFormArray(form: FormGroup, data: LocationTemplate[]) {
    const formGroups = data.map(item => {
      const fg = this.initLocationTemplateForm(true);
      fg.patchValue(item);
      if (item.devices && item.devices.length) {
        this.setDeviceFormArray(fg, item.devices);
      }
      if (item.ruleTemplates && item.ruleTemplates.length) {
        this.setRuleFormArray(fg, item.ruleTemplates);
      }
      if (item.children && item.children.length) {
        this.setChildrenFormArray(fg, item.children);
      }
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('children', formArray);
  }
}
