<p-panel
  header="{{ 'DEVICE' | translate }} {{ deviceNumber }}"
  [toggleable]="true"
  expandIcon="fa-chevron-down"
  collapseIcon="fa-chevron-up"
>
  <div [formGroup]="group">
    <div class="ui-g ui-g-nopad">
      <!-- DEVICE_TYPE_ID FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="deviceTypeId"
          [form]="group"
          label="{{ 'DEVICE_TYPE' | translate }}"
          [options]="optionData['deviceTypes']"
        ></sc-dropdown>
      </div>

      <div class="ui-g-12">
        <sc-dropdown
          controlName="deviceModelId"
          [form]="group"
          label="{{ 'DEVICE_MODEL' | translate }}"
          [options]="deviceModels"
        ></sc-dropdown>
      </div>

      <!-- BRAND_ID FIELD -->
      <!-- <div class="ui-g-12">
        <sc-dropdown
          controlName="brandId"
          [form]="group"
          label="{{ 'DEVICE_BRAND' | translate }}"
          [options]="optionData['deviceBrands']"
        ></sc-dropdown>
      </div> -->

      <!-- PROTOCOL_ID FIELD -->
      <!-- <div class="ui-g-12">
        <sc-dropdown
          controlName="protocolId"
          [form]="group"
          label="{{ 'DEVICE_PROTOCOL' | translate }}"
          [options]="optionData['deviceProtocols']"
        ></sc-dropdown>
      </div> -->

      <!-- DESCRIPTION FIELD -->
      <div class="ui-g-12">
        <sc-textarea
          controlName="description"
          [form]="group"
          label="{{ 'DESCRIPTION' | translate }}"
        ></sc-textarea>
      </div>

      <!-- AVC_CALC_WEIGHT FIELD -->
      <div class="ui-g-12">
        <sc-number-input
          controlName="avcCalcWeight"
          [form]="group"
          label="{{ 'AVC_CALC_WEIGHT' | translate }}"
        ></sc-number-input>
      </div>

      <!-- IS_ACTIVE FIELD -->
      <div class="ui-g-6">
        <sc-checkbox-input
          controlName="isActive"
          [form]="group"
          label="{{ 'ACTIVE' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- IS_GROUPED FIELD -->
      <div class="ui-g-6">
        <sc-checkbox-input
          controlName="isGrouped"
          [form]="group"
          label="{{ 'GROUPED' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- CONTROLLABLE_BY_GUEST FIELD -->
      <div class="ui-g-6">
        <sc-checkbox-input
          controlName="isControllableByGuest"
          [form]="group"
          label="{{ 'CONTROLLABLE_BY_GUEST' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- VISIBLE_BY_GUEST FIELD -->
      <div class="ui-g-6">
        <sc-checkbox-input
          controlName="isVisibleByGuest"
          [form]="group"
          label="{{ 'VISIBLE_BY_GUEST' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- REMOVE BUTTON -->
      <div class="ui-g-3">
        <button
          pButton
          type="button"
          class="ui-button-warning"
          icon="fa fa-trash"
          label="{{ 'REMOVE' | translate }} {{ 'DEVICE' | translate }}"
          (click)="removed.emit()"
        ></button>
      </div>
    </div>
  </div>
</p-panel>
