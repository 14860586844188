import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AirQualityComponent } from './air-quality.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [AirQualityComponent],
  entryComponents: [AirQualityComponent],
  providers: []
})
export class AirQualityModule {}
