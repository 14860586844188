import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { LocationGeneratorFormComponent } from './location-generator-form/location-generator-form.component';
import { LocationFormComponent } from './location-form/location-form.component';
import { DeviceFormComponent } from './device-form/device-form.component';
import { RuleFormComponent } from './rule-form/rule-form.component';
import { RuleConditionFormComponent } from './rule-condition-form/rule-condition-form.component';
import { RuleCommandFormComponent } from './rule-command-form/rule-command-form.component';
import { CommandParameterFormComponent } from './command-parameter-form/command-parameter-form.component';

import { LocationGeneratorService } from './services/location-generator.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    LocationGeneratorFormComponent,
    LocationFormComponent,
    DeviceFormComponent,
    RuleFormComponent,
    RuleConditionFormComponent,
    RuleCommandFormComponent,
    CommandParameterFormComponent
  ],
  entryComponents: [LocationGeneratorFormComponent],
  providers: [LocationGeneratorService]
})
export class LocationGeneratorModule {}
