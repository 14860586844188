import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RuleHistoryLogsRequestOptions } from '../models/rule-history-logs-request-options';

// import { SharedService } from '@shared/shared.service';

import { config } from '../../../config';

@Injectable()
export class RuleHistoryLogsService {
  private baseUrl = config().apiUrl + '/companies/{companyId}/sites/{siteId}';

  constructor(
    private httpClient: HttpClient // private sharedService: SharedService
  ) {}

  // private get apiUrl() {
  //   const selectedSite = this.sharedService.selectedSite;
  //   return this.baseUrl
  //     .replace('{companyId}', selectedSite.companyId)
  //     .replace('{siteId}', selectedSite.siteId);
  // }

  getRuleHistoryLog(options: RuleHistoryLogsRequestOptions) {
    let apiUrl = this.baseUrl;

    if (options.companyId) {
      apiUrl = apiUrl.replace('{companyId}', options.companyId + '');
    }

    if (options.siteId) {
      apiUrl = apiUrl.replace('{siteId}', options.siteId + '');
    }

    if (options.locationId) {
      apiUrl += `/locations/${options.locationId}`;
    }

    apiUrl += '/rules/history';

    if (options.onlyLatest === true) {
      apiUrl += '/latest';
    }

    let queryParams: any = [];

    for (const key in options.filter) {
      if (options.filter.hasOwnProperty(key)) {
        queryParams.push(`${key}=${options.filter[key]}`);
      }
    }

    if (options.limit && options.limit > 0) {
      queryParams.push(`limit=${options.limit}`);
    } else {
      queryParams.push(`limit=50`);
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';

    return this.httpClient.get(apiUrl + queryParams);
  }

  getRuleHistoryLogLazyLoad(options) {
    let apiUrl = this.baseUrl;
    let queryParams: any = [];

    if (options.companyId) {
      apiUrl = apiUrl.replace('{companyId}', options.companyId + '');
    }

    if (options.siteId) {
      apiUrl = apiUrl.replace('{siteId}', options.siteId + '');
    }

    if (options.locationId) {
      apiUrl += `/locations/${options.locationId}`;
    }

    apiUrl += '/rules/history/lazy';

    for (const key in options) {
      if (options.hasOwnProperty(key) && options[key]) {
        if (['companyId', 'siteId', 'locationId'].indexOf(key) >= 0) {
        } else if (['filter', 'sort', 'daterange'].indexOf(key) >= 0) {
          queryParams.push(key + '=' + encodeURI(JSON.stringify(options[key])));
        } else {
          queryParams.push(key + '=' + options[key]);
        }
      }
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';

    return this.httpClient.get(apiUrl + queryParams);
  }
}
