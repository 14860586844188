import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Injectable()
export class BenchmarkTemplateFormsService {
  constructor(private formBuilder: FormBuilder) {}

  /**
   * Create Benchmark Template Form
   */
  initBenchmarkTemplateForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      description: null,
      enablingMarker: this.initEnablingMarkerForm(),
      disablingMarker: this.initDisablingMarkerForm(),
      output: this.formBuilder.array([]),
      isIntervalLimited: false,
      intervalLimit: [{ value: null, disabled: true }, Validators.required],
      isPublic: false,
      sites: null,
      timeRangeMode: ['a', Validators.required],
      relativeAgoUnit: [{ value: null, disabled: true }, Validators.required],
      relativeAgoValue: [{ value: null, disabled: true }, Validators.required],
      isActive: false,
      isDeleted: false
    });
  }

  /**
   * Create an Output Form Group
   */
  initOutputForm(): FormGroup {
    return this.formBuilder.group({
      key: [null, Validators.required],
      options: null
    });
  }

  /**
   * Patch value to Output Form Array
   * @param form
   * @param data
   */
  setOutputForm(form: FormGroup, data: { [key: string]: any }[]) {
    const formGroups = [];
    data.forEach(item => {
      if (item.key !== 'duration') {
        const fg = this.initOutputForm();
        fg.patchValue(item);
        formGroups.push(fg);
      }
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('output', formArray);
  }

  /**
   * Create Enabling Marker Form Group
   */
  initEnablingMarkerForm(): FormGroup {
    return this.formBuilder.group({
      conditions: this.formBuilder.array(
        [this.initConditionsForm()],
        Validators.required
      )
    });
  }

  /**
   * Create Disabling Marker Form Group
   */
  initDisablingMarkerForm(): FormGroup {
    return this.formBuilder.group({
      connector: ['OR', Validators.required],
      conditions: this.formBuilder.array(
        [this.initConditionsForm(true)],
        Validators.required
      )
    });
  }

  /**
   * Create Condition Form Group of Enabling / Disabling Marker Form
   * @param disabling
   */
  initConditionsForm(disabling: boolean = false): FormGroup {
    const control: any = {
      property: [null, Validators.required],
      operator: [null, Validators.required],
      value: null
    };
    if (disabling) {
      control.invalidating = false;
    }
    return this.formBuilder.group(control);
  }

  /**
   * Patch value to Conditions Form Array of Enabling / Disabling Marker Form
   * @param form
   * @param data
   * @param disabling
   */
  setConditionsForm(
    form: FormGroup,
    data: { conditions: any[]; [key: string]: any },
    disabling?: boolean
  ) {
    const markerForm = (disabling
      ? form.controls['disablingMarker']
      : form.controls['enablingMarker']) as FormGroup;
    const formGroups = data.conditions.map(item => {
      const fg = this.initConditionsForm(disabling);
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    markerForm.setControl('conditions', formArray);
  }

  getFormSelectItems() {
    const selectItems: any = {};

    selectItems.intervalLimit = [
      { label: 'No Limit', value: 0 },
      { label: 'Limited', value: 1 }
    ];
    selectItems.timeRangeModes = [
      { label: 'Absolute', value: 'a' },
      { label: 'Relative', value: 'r' }
    ];
    selectItems.relativeAgoUnits = [
      { label: 'Second(s)', value: 'seconds' },
      { label: 'Minute(s)', value: 'minutes' },
      { label: 'Hour(s)', value: 'hours' },
      { label: 'Day(s)', value: 'days' },
      { label: 'Month(s)', value: 'months' },
      { label: 'Week(s)', value: 'weeks' },
      { label: 'Year(s)', value: 'years' }
    ];
    selectItems.public = [
      { label: 'No', value: 0 },
      { label: 'Yes', value: 1 }
    ];
    selectItems.connectors = [
      { label: 'AND', value: 'AND' },
      { label: 'OR', value: 'OR' }
    ];
    selectItems.properties = [
      { label: 'HVAC Fanspeed', value: 'acFanspeed' },
      { label: 'HVAC Mode', value: 'acMode' },
      { label: 'HVAC Setpoint', value: 'acSetpoint' },
      { label: 'HVAC Status', value: 'acStatus' },
      { label: 'Air Quality', value: 'airQuality' },
      { label: 'Brightness', value: 'brightness' },
      { label: 'Consumption', value: 'consumption' },
      { label: 'Consumption Total', value: 'consumptionTotal' },
      { label: 'Curtains Open', value: 'curtainsOpen' },
      { label: 'Dnd Status', value: 'dndStatus' },
      { label: 'Door Locked', value: 'doorLocked' },
      { label: 'Door Open', value: 'doorOpen' },
      { label: 'Humidity', value: 'humidity' },
      { label: 'Is Booked And Check In Done', value: 'isBookedAndCheckInDone' },
      {
        label: 'Is Booked But No Check In Done',
        value: 'isBookedButNoCheckInDone'
      },
      { label: 'Is Check In InProgress', value: 'isCheckInInProgress' },
      { label: 'Is Check Out InProgress', value: 'isCheckOutInProgress' },
      { label: 'Is Raining', value: 'isRaining' },
      { label: 'Make Up Status', value: 'makeUpStatus' },
      { label: 'Occupied', value: 'occupied' },
      { label: 'Out of Order Status', value: 'oooStatus' },
      { label: 'Outdoor Humidity', value: 'outdoorHumidity' },
      { label: 'Outdoor Temperature', value: 'outdoorTemperature' },
      { label: 'Temperature', value: 'temperature' },
      { label: 'Window Open', value: 'windowOpen' },
      { label: 'Wind Speed', value: 'windSpeed' }
    ];
    selectItems.operators = [
      { label: '==', value: '==' },
      { label: '>=', value: '>=' },
      { label: '<=', value: '<=' },
      { label: '>', value: '>' },
      { label: '<', value: '<' },
      { label: '!=', value: '!=' },
      { label: 'changed', value: 'changed' }
    ];
    selectItems.outputKeys = [
      ...selectItems.properties,
      { label: 'Disabling reason', value: 'disablingReason' }
      // { label: 'Duration', value: 'duration' }
    ];
    selectItems.outputOptions = [
      { label: 'Average', value: 'avg' },
      { label: 'End', value: 'end' },
      { label: 'Maximum', value: 'max' },
      { label: 'Minimum', value: 'min' },
      { label: 'Start', value: 'start' }
    ];
    selectItems.enablingMarkerProps = [
      ...selectItems.properties,
      { label: 'Local time', value: 'localTime' }
    ];
    selectItems.disablingMarkerProps = [
      ...selectItems.properties,
      { label: 'Duration', value: 'duration' },
      { label: 'Local time', value: 'localTime' }
    ];

    return selectItems;
  }
}
