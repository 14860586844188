import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as siteActions from '../actions/sites.action';
import { SitesService } from '@widgets/sites/services/sites.service';

import { SharedService } from '@shared/shared.service';

@Injectable()
export class SitesEffects {
  constructor(private action$: Actions, private sitesService: SitesService, private sharedService: SharedService) {}

  @Effect()
  loadSite$ = this.action$.pipe(
    ofType(siteActions.LOAD_SITE),
    map((action: siteActions.LoadSite) => action.payload),
    switchMap((siteId) => {
      return this.sitesService.getSite(siteId).pipe(
        map((result: any) => new siteActions.LoadSiteSuccess(result.data)),
        catchError((error) => of(new siteActions.LoadSiteFail(error)))
      );
    })
  );

  @Effect()
  loadSites$ = this.action$.pipe(
    ofType(siteActions.LOAD_SITES),
    map((action: siteActions.LoadSites) => action.payload),
    switchMap((companyId) => {
      return this.sitesService.getSites(companyId).pipe(
        map((result: any) => new siteActions.LoadSitesSuccess(result.data)),
        catchError((error) => of(new siteActions.LoadSitesFail(error)))
      );
    })
  );

  @Effect()
  loadSitesTable$ = this.action$.pipe(
    ofType(siteActions.LOAD_SITES_TABLE),
    map((action: siteActions.LoadSitesTable) => action.payload),
    switchMap((companyId) => {
      return this.sitesService.getSites(companyId).pipe(
        map((result: any) => new siteActions.LoadSitesTableSuccess(result.data)),
        catchError((error) => of(new siteActions.LoadSitesTableFail(error)))
      );
    })
  );

  @Effect()
  createSite$ = this.action$.pipe(
    ofType(siteActions.CREATE_SITE),
    map((action: siteActions.CreateSites) => action.payload),
    switchMap(({ site /*, floors*/ }) => {
      return this.sitesService.createSite(site /*, floors*/).pipe(
        map((result: any) => {
          this.sharedService.notify('Site', 'Create Success', 'success');
          return new siteActions.CreateSitesSuccess(result.data);
        }),
        catchError((error) => of(new siteActions.CreateSitesFail(error)))
      );
    })
  );

  @Effect()
  updateSite$ = this.action$.pipe(
    ofType(siteActions.UPDATE_SITE),
    map((action: siteActions.UpdateSites) => action.payload),
    switchMap((site) => {
      return this.sitesService.updateSite(site).pipe(
        map((result: any) => {
          this.sharedService.notify('Site', 'Update Success', 'success');
          return new siteActions.UpdateSitesSuccess(site);
        }),
        catchError((error) => of(new siteActions.UpdateSitesFail(error)))
      );
    })
  );

  @Effect()
  deleteSite$ = this.action$.pipe(
    ofType(siteActions.DELETE_SITE),
    map((action: siteActions.DeleteSites) => action.payload),
    switchMap((site) => {
      return this.sitesService.deleteSite(site).pipe(
        map((result: any) => {
          this.sharedService.notify('Site', 'Delete Success', 'success');
          return new siteActions.DeleteSitesSuccess(site);
        }),
        catchError((error) => of(new siteActions.DeleteSitesFail(error)))
      );
    })
  );
}
