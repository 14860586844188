import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  DataSources,
  SelectItems,
  SelectItem,
  RuleCondition,
  RuleEditorModes,
  OperandTargets,
  OperandTypes,
  LeftOperandTypes,
} from '../../models';
import { RulesService } from '../../services';

@Component({
  selector: 'sc-left-operand-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './left-operand-modal.component.html',
  styleUrls: ['./left-operand-modal.component.scss'],
})
export class LeftOperandModalComponent implements OnInit {
  condition: RuleCondition;
  dataSources: DataSources = {};
  form: FormGroup;
  modalTitle: string;
  modalTitleParam: any;
  modalDescription: string;
  operandTargets: SelectItem[];
  operandType: string;
  operandTypes: SelectItem[];
  selectItems: SelectItems = {};
  step = 1;
  viewMode: string;
  isTemplate: boolean;
  isFromScratch: boolean;

  _operandTargets = OperandTargets;
  _operandTypes = OperandTypes;
  _leftOperandTypes = LeftOperandTypes;

  constructor(
    private modalRef: DynamicDialogRef,
    private modalConfig: DynamicDialogConfig,
    private rulesService: RulesService
  ) {}

  ngOnInit(): void {
    if (this.modalConfig && this.modalConfig.data) {
      if (this.modalConfig.data.condition) {
        this.condition = this.modalConfig.data.condition;
        this.operandType = this.condition.left.type;
      }

      if (this.modalConfig.data.dataSources) {
        this.dataSources = this.modalConfig.data.dataSources;
      }

      if (this.modalConfig.data.selectItems) {
        this.selectItems = this.modalConfig.data.selectItems;
      }

      if (this.modalConfig.data.viewMode) {
        this.viewMode = this.modalConfig.data.viewMode;
      }

      if (this.modalConfig.data.isTemplate) {
        this.isTemplate = this.modalConfig.data.isTemplate;
      }

      if (this.modalConfig.data.isFromScratch) {
        this.isFromScratch = this.modalConfig.data.isFromScratch;
      }
    }

    this.operandTypes = [
      { label: OperandTypes.DeviceLabel, value: OperandTypes.Device },
      { label: OperandTypes.LocationPropertyLabel, value: OperandTypes.LocationProperty },
      { label: OperandTypes.DateTimeLabel, value: OperandTypes.DateTime },
    ];

    // Expert mode only
    if (this.viewMode === RuleEditorModes.Expert) {
      // Custom global operand shows in Expert only
      this.operandTypes.push({ label: OperandTypes.GlobalLabel, value: OperandTypes.Global });
      // Function operand shows in Expert only
      this.operandTypes.push({ label: OperandTypes.FunctionLabel, value: OperandTypes.Function });
    }

    // Backend mode only
    if (this.viewMode === RuleEditorModes.Backend) {
    }

    // Normal mode only
    if (this.viewMode === RuleEditorModes.Normal) {
    }

    // Advanced mode only
    if (this.viewMode === RuleEditorModes.Advanced) {
    }

    if (this.viewMode === RuleEditorModes.Integrator) {
      this.operandTypes = [
        { label: OperandTypes.DeviceLabel, value: OperandTypes.Device },
        { label: OperandTypes.LocationPropertyLabel, value: OperandTypes.LocationProperty },
        { label: OperandTypes.DateTimeLabel, value: OperandTypes.DateTime },
        { label: OperandTypes.FunctionLabel, value: OperandTypes.Function },
      ];
    }

    if ((Object.values(this._leftOperandTypes) as string[]).indexOf(this.operandType) >= 0) {
      if (this.operandType === LeftOperandTypes.DatetimeNow) {
        this.selectOperandType(OperandTypes.DateTime);
      } else {
        this.step = 3;
      }
    }

    this.setModalTitle();
    this.setModalDescription();
    this.initForm();
  }

  private initForm() {
    let formValue;
    if (this.form) {
      formValue = this.form.value;
    } else {
      formValue = this.condition.left;
    }
    this.form = this.rulesService.initLeftOperandForm(this.operandType, this.isTemplate);

    if (formValue) {
      this.form.patchValue(formValue);
      if (formValue.modifiers) {
        this.rulesService.setModifierForm(this.form, formValue.modifiers);
      }

      if (formValue.variables) {
        this.rulesService.setFunctionVariablesForm(this.form, formValue.variables);
      }
    }
  }

  private setModalTitle() {
    let title = '';
    if (this.step === 1) {
      title = 'CCP_RULE_NEW_OPERAND';
    } else if (this.step === 2) {
      switch (this.operandType) {
        case OperandTypes.Device:
          title = 'CCP_RULE_NEW_OPERAND_DEVICE';
          break;
        case OperandTypes.LocationProperty:
          title = 'CCP_RULE_NEW_OPERAND_LOCATION_PROPERTY';
          break;
        case OperandTypes.DateTime:
          title = 'CCP_RULE_NEW_OPERAND_DATETIME';
          break;
        case OperandTypes.Function:
          title = 'CCP_RULE_NEW_OPERAND_FUNCTION';
          break;
        case OperandTypes.Global:
          title = 'CCP_RULE_NEW_OPERAND_GLOBAL';
          break;
      }
    } else if (this.step === 3) {
      switch (this.operandType) {
        case LeftOperandTypes.DeviceDateTime:
          title = 'CCP_RULE_DEVICE_DATETIME_OPERAND';
          break;
        case LeftOperandTypes.DeviceValue:
          title = 'CCP_RULE_DEVICE_VALUE_OPERAND';
          break;
        case LeftOperandTypes.LocationPropertyDateTime:
          title = 'CCP_RULE_LOCATION_PROPERTY_DATETIME_OPERAND';
          break;
        case LeftOperandTypes.LocationPropertyValue:
          title = 'CCP_RULE_LOCATION_PROPERTY_OPERAND';
          break;
        case LeftOperandTypes.FunctionValue:
          title = 'CCP_RULE_FUNCTION_VALUE_OPERAND';
          break;
        case LeftOperandTypes.GlobalValue:
          title = 'CCP_RULE_GLOBAL_VALUE_OPERAND';
          break;
      }
    }
    this.modalTitle = title;
  }

  private setModalDescription() {
    let description = '';
    if (this.step === 1) {
      description = 'CCP_RULE_NEW_OPERAND_DESCRIPTION';
    } else if (this.step === 2) {
      switch (this.operandType) {
        case OperandTypes.Device:
          description = 'CCP_RULE_NEW_OPERAND_DEVICE_DESCRIPTION';
          break;
        case OperandTypes.LocationProperty:
          description = 'CCP_RULE_NEW_OPERAND_LOCATION_PROPERTY_DESCRIPTION';
          break;
        case OperandTypes.DateTime:
          description = 'CCP_RULE_NEW_OPERAND_DATETIME_DESCRIPTION';
          break;
      }
    }
    this.modalDescription = description;
  }

  private setOperandTargets() {
    switch (this.operandType) {
      case OperandTypes.Device:
      case OperandTypes.LocationProperty:
        this.operandTargets = [
          { label: OperandTargets.ValueLabel, value: OperandTargets.Value },
          { label: OperandTargets.DateTimeLabel, value: OperandTargets.DateTime },
        ];
        break;
      case OperandTypes.DateTime:
        this.operandTargets = [{ label: OperandTargets.NowLabel, value: OperandTargets.Now }];
        break;
      case OperandTypes.Function:
      case OperandTypes.Global:
        this.operandTargets = [{ label: OperandTargets.ValueLabel, value: OperandTargets.Value }];
        break;
      default:
        this.operandTargets = [];
    }
  }

  selectOperandType(event) {
    this.operandType = event;

    this.setOperandTargets();
    this.step++;
    this.setModalTitle();
    this.setModalDescription();

    if (this.operandType === OperandTypes.Function || this.operandType === OperandTypes.Global) {
      // because now we have only one target so auto select it
      this.selectOperandTarget(OperandTargets.Value);
    }
  }

  selectOperandTarget(event) {
    this.operandType += `_${event}`;
    this.form.get('type').setValue(this.operandType);

    if (this.operandType === LeftOperandTypes.DatetimeNow) {
      this.onOkClick();
    } else {
      this.initForm();
      this.step++;
      this.setModalTitle();
      this.setModalDescription();
    }
  }

  close() {
    this.modalRef.close();
  }

  onResetClick() {
    this.condition.left = { type: null };
    this.close();
  }

  onOkClick() {
    if (this.form.valid) {
      const formValue = this.form.value;
      this.condition.left = { ...formValue };
      this.close();
    }
  }

  goBack() {
    this.step--;
  }
}
