import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-device-model-value-mapping-form',
  templateUrl: 'value-mapping-form.component.html'
})
export class DeviceModelValueMappingFormComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  mappingValues: any[];

  valueMappingName: string;
  valueMappingKey: string;

  constructor() {}

  ngOnInit() {
    const formValue = this.group.value;

    for (let i = 0; i < this.mappingValues.length; i++) {
      if (formValue.deviceTypeMappingValueId === this.mappingValues[i].id) {
        this.valueMappingName = this.mappingValues[i].name;
        this.valueMappingKey = this.mappingValues[i].key;
        break;
      }
    }
  }
}
