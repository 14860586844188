import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid';
import { LocationDevicesActionCellComponent } from '../location-devices-action-cell/location-devices-action-cell.component';
import { DeviceDetails } from '@widgets/devices/models/device-details';

@Component({
  selector: 'sc-location-devices',
  templateUrl: './location-devices.component.html',
  styleUrls: ['./location-devices.component.scss'],
})
export class LocationDevicesComponent implements OnInit {
  @Input()
  devices: DeviceDetails[];

  @Output()
  viewDeviceDetails = new EventEmitter();

  columns: ColDef[];
  dataset: DeviceDetails[] = [];
  table: GridOptions;
  tableTitle = 'DEVICES';
  toolbarItems: string[] = [];

  constructor() {}

  ngOnInit() {
    // Set Table Columns
    this.columns = this.createColumns();

    this.dataset = this.devices.filter((d) => d.deviceTypeKey === 'vdh');
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;
  }

  private createColumns() {
    return [
      {
        colId: 'description',
        headerName: 'NAME',
        field: 'description',
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        width: 50,
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: LocationDevicesActionCellComponent,
      },
    ];
  }

  viewDetails(id: number) {
    // const holderDevice = this.devices.find((d) => d.id === id);
    // const virtualDevices = this.devices.filter((d) => d.virtualDeviceHolderId === id);
    // this.viewDeviceDetails.emit({ holderDevice, virtualDevices });
    this.viewDeviceDetails.emit(id);
  }
}
