import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MassRule } from '../models/rule';

@Component({
  selector: 'sc-rule-list',
  templateUrl: './rule-list.component.html',
  styleUrls: ['./rule-list.component.scss']
})
export class RuleListComponent implements OnInit {
  @Input() rules: MassRule[];

  @Output() ruleSelected = new EventEmitter<MassRule>();

  selectedRule: number;

  constructor() {}

  ngOnInit() {}

  selectRule(rule: MassRule) {
    this.selectedRule = rule.id;
    this.ruleSelected.emit(rule);
  }
}
