import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'sc-custom-header',
  templateUrl: 'custom-header.component.html',
  styleUrls: ['custom-header.component.scss']
})
export class CustomHeaderComponent {
  params: any;
  sortDir = '';

  @ViewChild('menuButton', { read: ElementRef }) public menuButton;

  agInit(params): void {
    this.params = params;

    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
  }

  private onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.sortDir = 'asc';
    } else if (this.params.column.isSortDescending()) {
      this.sortDir = 'desc';
    } else {
      this.sortDir = '';
    }
  }

  onMenuClicked(event: any) {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortRequested(event: any) {
    if (!this.params || !this.params.enableSorting) {
      return;
    }

    let order = '';
    if (this.sortDir === '') {
      order = 'asc';
    } else if (this.sortDir === 'asc') {
      order = 'desc';
    } else if (this.sortDir === 'desc') {
      order = '';
    }
    this.params.setSort(order, event.shiftKey);
  }

  sortDirIcon() {
    switch (this.sortDir) {
      case 'asc':
        return 'fa-long-arrow-down';
      case 'desc':
        return 'fa-long-arrow-up';
      default:
        return '';
    }
  }
}
