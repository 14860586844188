<p-card>
  <!--Body-->
  <ul class="summary-list" cdkDropList (cdkDropListDropped)="drop($event)">
    <li
      class="summary-item"
      [ngClass]="{ 'summary-item--active': data.visible }"
      *ngFor="let data of customSummaryData"
      cdkDrag
      (dblclick)="toggle(data)"
    >
      <div class="ui-g-12 ui-md-8 summary-item__label">
        {{ data.label | translate }}
      </div>

      <div class="ui-g-12 ui-md-4 summary-item__action">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          icon="fa fa-fw {{ data.visible ? 'fa-eye' : 'fa-eye-slash' }}"
          (click)="toggle(data)"
        ></button>

        <button
          pButton
          type="button"
          class="ui-button-secondary"
          icon="fa fa-arrows-v fa-fw"
          cdkDragHandle
        ></button>
      </div>
    </li>
  </ul>

  <!--Footer-->
  <p-footer>
    <div class="ui-g-12 text-right">
      <button
        pButton
        type="button"
        class="ui-button-danger mr-3"
        label="{{ 'RESET' | translate }}"
        (click)="reset()"
      ></button>

      <button
        pButton
        type="button"
        class="ui-button-secondary mr-3"
        label="{{ 'CANCEL' | translate }}"
        (click)="cancel()"
      ></button>

      <button
        pButton
        type="button"
        class="ui-button-success"
        label="{{ 'SAVE' | translate }}"
        (click)="save()"
      ></button>
    </div>
  </p-footer>
</p-card>
