import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-command-options',
  templateUrl: './command-options.component.html',
  styleUrls: ['./command-options.component.scss'],
})
export class CommandOptionsComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {}
}
