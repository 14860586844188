import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedService } from '@shared/shared.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { DeviceService } from '@integrator/services/device.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

@Component({
  selector: 'sc-bulk-creator',
  templateUrl: './bulk-creator.component.html',
  styleUrls: ['./bulk-creator.component.scss'],
})
export class BulkCreatorComponent implements OnDestroy {
  @Input() data: any;

  fetchingState: number = 0;
  option: string = 'menu';
  step: number = 1;
  totalSteps: number = 1;
  isSubmitting = false;
  form: FormGroup;
  errorMessage: string;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private locationsService: LocationsService,
    private deviceService: DeviceService,
    private sharedService: SharedService,
    private config: DynamicDialogConfig,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.data = this.config && this.config.data;
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  onSelectOption(option: string) {
    this.option = option;
    switch (option) {
      case 'gateways':
        this.totalSteps = 2;
        break;
      default:
        this.totalSteps = 1;
        break;
    }
  }

  cancel() {
    this.option = 'menu';
  }

  submit(items: object) {
    this.errorMessage = null;
    this.isSubmitting = true;
    this.step = 1;
    this.totalSteps = 1;
    switch (this.option) {
      case 'units':
      case 'areas':
        this.createLocations(items);
        break;
      case 'automations':
      case 'devices':
      case 'gateways':
        this.createDevices(items);
        break;
      default:
        break;
    }
    this.isSubmitting = false;
    this.option = 'menu';
  }

  changeStep(step: number) {
    this.step = step;
  }

  private createLocations(data: any) {
    this.subscribers.createLocations = this.locationsService.createLocations(data).subscribe({
      next: this.apiCallSuccess.bind(this, ['LOCATIONS', 'CREATE_SUCCESS']),
      error: this.apiCallError.bind(this, ['LOCATIONS', 'CREATE_FAIL']),
    });
  }

  private createDevices(data: any) {
    this.subscribers.createDevices = this.deviceService.createDevices(data).subscribe({
      next: this.apiCallSuccess.bind(this, ['DEVICES', 'CREATE_SUCCESS']),
      error: this.apiCallError.bind(this, ['DEVICES', 'CREATE_FAIL']),
      complete: () => this.store.dispatch(new fromStore.LoadDevices({ siteId: this.sharedService.selectedSite.id })),
    });
  }

  private apiCallSuccess(message: string[], result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message[1]);
    const title = this.sharedService.getTranslation(message[0]);
    this.sharedService.notify(title, text, 'success');
  }

  private apiCallError(message: string[], error: any) {
    const text = this.sharedService.getTranslation(message[1]);
    const title = this.sharedService.getTranslation(message[0]);
    this.sharedService.notify(title, text, 'error');
  }
}
