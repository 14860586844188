import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CeosAlertComponent } from './ceos-alert.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [CeosAlertComponent],
  entryComponents: [CeosAlertComponent],
  providers: [],
})
export class CeosAlertModule {}
