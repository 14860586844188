import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class AppStyleFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initAppStyleForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      description: null,
      isActive: false,
      isDeleted: false,
      appStyleTemplateId: [null, Validators.required],
      elements: this.formBuilder.array([]),
      variables: this.formBuilder.array([]),
    });
  }
}
