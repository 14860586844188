import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromSiteType from '../reducers/site-type.reducer';

export const selectSiteTypeState = createFeatureSelector<fromSiteType.State>('siteType');

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = fromSiteType.adapter.getSelectors();
// select the array of site type ids
export const selectSiteTypeIds = createSelector(selectSiteTypeState, selectIds);
// select the dictionary of site type entities
export const selectSiteTypeEntities = createSelector(selectSiteTypeState, selectEntities);
// select the array of site types
export const selectAllSiteTypes = createSelector(selectSiteTypeState, selectAll);
// select the total site type count
export const selectSiteTypeTotal = createSelector(selectSiteTypeState, selectTotal);
