import { AbsoluteDatetimeOperandComponent } from './absolute-datetime-operand/absolute-datetime-operand.component';
import { ActionButtonComponent } from './action-button/action-button.component';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { CircleButtonComponent } from './circle-button/circle-button.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { CommandItemComponent } from './command-item/command-item.component';
import { CommandModalComponent } from './command-modal/command-modal.component';
import { CommandOptionsComponent } from './command-options/command-options.component';
import { ConditionItemComponent } from './condition-item/condition-item.component';
import { CustomGlobalComponent } from './custom-global/custom-global.component';
import { DeviceOperandComponent } from './device-operand/device-operand.component';
import { DeviceValueStorageComponent } from './device-value-storage/device-value-storage.component';
import { FunctionOperandComponent } from './function-operand/function-operand.component';
import { FunctionVariableItemComponent } from './function-variable-item/function-variable-item.component';
import { FunctionVariablesComponent } from './function-variables/function-variables.component';
import { GlobalOperandComponent } from './global-operand/global-operand.component';
import { InputLabelComponent } from './input-label/input-label.component';
import { LeftOperandModalComponent } from './left-operand-modal/left-operand-modal.component';
import { ListSelectComponent } from './list-select/list-select.component';
import { LocationOperandComponent } from './location-operand/location-operand.component';
import { ModifierItemComponent } from './modifier-item/modifier-item.component';
import { ModifiersComponent } from './modifiers/modifiers.component';
import { NotifierCommandComponent } from './notifier-command/notifier-command.component';
import { OperatorModalComponent } from './operator-modal/operator-modal.component';
import { RelativeDatetimeOperandComponent } from './relative-datetime-operand/relative-datetime-operand.component';
import { ReportCommandComponent } from './report-command/report-command.component';
import { ReportVariableComponent } from './report-variable/report-variable.component';
import { RightOperandModalComponent } from './right-operand-modal/right-operand-modal.component';
import { RuleBlockerComponent } from './rule-blocker/rule-blocker.component';
import { RuleCommandsComponent } from './rule-commands/rule-commands.component';
import { RuleConditionComponent } from './rule-condition/rule-condition.component';
import { RuleOverviewComponent } from './rule-overview/rule-overview.component';
import { RuleTemplateOverviewComponent } from './rule-template-overview/rule-template-overview.component';
import { TaskQueueComponent } from './task-queue/task-queue.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { TriggerDeviceComponent } from './trigger-device/trigger-device.component';
import { UserTaskCommandComponent } from './user-task-command/user-task-command.component';
import { UserTaskVariableComponent } from './user-task-variable/user-task-variable.component';
import { ValueOperandComponent } from './value-operand/value-operand.component';

export const components: any[] = [
  AbsoluteDatetimeOperandComponent,
  ActionButtonComponent,
  ActionMenuComponent,
  CircleButtonComponent,
  CloseButtonComponent,
  CommandItemComponent,
  CommandModalComponent,
  CommandOptionsComponent,
  ConditionItemComponent,
  CustomGlobalComponent,
  DeviceOperandComponent,
  DeviceValueStorageComponent,
  FunctionOperandComponent,
  FunctionVariableItemComponent,
  FunctionVariablesComponent,
  GlobalOperandComponent,
  InputLabelComponent,
  LeftOperandModalComponent,
  ListSelectComponent,
  LocationOperandComponent,
  ModifierItemComponent,
  ModifiersComponent,
  NotifierCommandComponent,
  OperatorModalComponent,
  RelativeDatetimeOperandComponent,
  ReportCommandComponent,
  ReportVariableComponent,
  RightOperandModalComponent,
  RuleBlockerComponent,
  RuleCommandsComponent,
  RuleConditionComponent,
  RuleOverviewComponent,
  RuleTemplateOverviewComponent,
  TaskQueueComponent,
  TreeSelectComponent,
  TriggerDeviceComponent,
  UserTaskCommandComponent,
  UserTaskVariableComponent,
  ValueOperandComponent,
];

export * from './absolute-datetime-operand/absolute-datetime-operand.component';
export * from './action-button/action-button.component';
export * from './action-menu/action-menu.component';
export * from './circle-button/circle-button.component';
export * from './close-button/close-button.component';
export * from './command-item/command-item.component';
export * from './command-modal/command-modal.component';
export * from './command-options/command-options.component';
export * from './condition-item/condition-item.component';
export * from './custom-global/custom-global.component';
export * from './device-operand/device-operand.component';
export * from './device-value-storage/device-value-storage.component';
export * from './function-operand/function-operand.component';
export * from './function-variable-item/function-variable-item.component';
export * from './function-variables/function-variables.component';
export * from './global-operand/global-operand.component';
export * from './input-label/input-label.component';
export * from './left-operand-modal/left-operand-modal.component';
export * from './list-select/list-select.component';
export * from './location-operand/location-operand.component';
export * from './modifier-item/modifier-item.component';
export * from './modifiers/modifiers.component';
export * from './notifier-command/notifier-command.component';
export * from './operator-modal/operator-modal.component';
export * from './relative-datetime-operand/relative-datetime-operand.component';
export * from './report-command/report-command.component';
export * from './report-variable/report-variable.component';
export * from './right-operand-modal/right-operand-modal.component';
export * from './rule-blocker/rule-blocker.component';
export * from './rule-commands/rule-commands.component';
export * from './rule-condition/rule-condition.component';
export * from './rule-overview/rule-overview.component';
export * from './rule-template-overview/rule-template-overview.component';
export * from './task-queue/task-queue.component';
export * from './tree-select/tree-select.component';
export * from './trigger-device/trigger-device.component';
export * from './user-task-command/user-task-command.component';
export * from './user-task-variable/user-task-variable.component';
export * from './value-operand/value-operand.component';
