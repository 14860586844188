import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions } from 'ag-grid';

@Component({
  selector: 'sc-tree-expand-button',
  templateUrl: 'tree-expand-button.component.html',
  styleUrls: ['tree-expand-button.component.scss'],
})
export class TreeExpandButtonComponent implements OnInit {
  @Input() default: boolean; // <-- starting state
  @Input() disabled: boolean; // <-- disabled button
  @Input() gridOptions: GridOptions;

  @Output() onExpand = new EventEmitter();

  currentState: boolean;

  constructor() {}

  ngOnInit() {
    this.currentState = this.default || false;
  }

  clickHandler() {
    this.currentState = !this.currentState;

    if (this.currentState) {
      this.gridOptions.api.expandAll();
    } else {
      this.gridOptions.api.collapseAll();
    }

    this.onExpand.emit(this.currentState);
  }
}
