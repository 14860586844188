<div class="absolute-datetime-operand">
  <div class="absolute-datetime-operand-body" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-6">
        <label class="input-label" for="pattern">
          {{ "CCP_RULE_ABSOLUTE_DATETIME_PATTERN" | translate }}
        </label>

        <p-dropdown
          inputId="pattern"
          [options]="selectItems?.absoluteDatetimePatterns"
          formControlName="pattern"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_RULE_ABSOLUTE_DATETIME_PATTERN' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-6" *ngIf="valueStructure">
        <label class="input-label" for="value">
          {{ "CCP_COMMON_VALUE" | translate }}
        </label>

        <div class="" [ngClass]="{ 'ui-inputgroup': valueStructure?.unit }">
          <ng-container [ngSwitch]="valueStructure?.type">
            <ng-container *ngSwitchCase="'numeric'">
              <input
                type="number"
                pInputText
                inputId="value"
                formControlName="value"
                [min]="valueStructure?.min"
                [max]="valueStructure?.max"
              />
            </ng-container>

            <ng-container *ngSwitchCase="'select'">
              <p-dropdown
                inputId="value"
                [options]="valueStructure?.options"
                formControlName="value"
                placeholder="Select"
                [filter]="true"
                appendTo="body"
              ></p-dropdown>
            </ng-container>

            <ng-container *ngSwitchCase="'datepicker'">
              <p-calendar
                inputId="value"
                formControlName="value"
                [dateFormat]="datetimeFormat"
                dataType="string"
                appendTo="body"
              ></p-calendar>
            </ng-container>

            <ng-container *ngSwitchCase="'datetimepicker'">
              <p-calendar
                inputId="value"
                formControlName="value"
                showTime="true"
                hourFormat="24"
                [dateFormat]="datetimeFormat"
                dataType="string"
                appendTo="body"
              ></p-calendar>
            </ng-container>

            <ng-container *ngSwitchCase="'timepicker'">
              <p-calendar
                inputId="value"
                formControlName="value"
                showTime="true"
                timeOnly="true"
                hourFormat="24"
                [dateFormat]="datetimeFormat"
                dataType="string"
                appendTo="body"
              ></p-calendar>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <input
                type="text"
                pInputText
                inputId="value"
                formControlName="value"
              />
            </ng-container>
          </ng-container>

          <span class="ui-inputgroup-addon" *ngIf="valueStructure?.unit">
            {{ valueStructure?.unit }}
          </span>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-12" *ngIf="isTemplate">
        <label class="input-label" for="is_source_editable">
          {{ "EDITABLE" | translate }}
        </label>
        <p-checkbox
          inputId="is_source_editable"
          [binary]="true"
          [formControl]="form?.get('is_source_editable')"
          [label]="'YES' | translate"
        ></p-checkbox>
      </div>
      <div class="p-field p-col-12 p-md-12" *ngIf="isTemplate && form?.get('is_source_editable').value == true">
        <label class="input-label" for="name">
          {{ "NAME" | translate }}
        </label>
        <input type="text" pInputText inputId="name" formControlName="name" />
      </div>
    </div>
  </div>
</div>
