import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { EnergyEfficiencyRatingComponent } from './energy-efficiency-rating.component';
import { EnergyEfficiencyRatingActionCellComponent } from './action-cell/action-cell.component';
import { EnergyEfficiencyRatingDetailModalComponent } from './detail-modal/detail-modal.component';
import { EnergyEfficiencyRatingRatingCellComponent } from './rating-cell/rating-cell.component';

const COMPONENTS: any[] = [
  EnergyEfficiencyRatingComponent,
  EnergyEfficiencyRatingActionCellComponent,
  EnergyEfficiencyRatingDetailModalComponent,
  EnergyEfficiencyRatingRatingCellComponent
];

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: []
})
export class EnergyEfficiencyRatingModule {}
