import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppStyle } from '../models/app-style';

import { config } from '../../../config';

@Injectable()
export class AppStylesService {
  private apiUrl = config().apiUrl + '/appStyles';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): AppStyle {
    const newData = Object.assign({}, data);

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    for (let i = 0; i < newData.variables.length; i++) {
      delete newData.variables[i].description;
      delete newData.variables[i].type;
    }

    return newData;
  }

  getAppStyle(id?: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getAppStyles() {
    return this.httpClient.get(this.apiUrl);
  }

  createAppStyle(data: AppStyle) {
    return this.httpClient.post(this.apiUrl, this.verifyData(data));
  }

  updateAppStyle(id: number, data: AppStyle) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteAppStyle(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
