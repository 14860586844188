import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { LocalWeatherService } from './local-weather.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-local-weather',
  templateUrl: 'local-weather.component.html',
  styleUrls: ['local-weather.component.scss'],
})
export class LocalWeatherComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: { [key: string]: any };
  isFirstTime = true;
  isFetching = false;
  widgetName: string = '';
  error: boolean = false;

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private localWeatherService: LocalWeatherService,
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.WATCH_SITE = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.isFetching = false;
        this.fetchDataset(this.selectedSite);
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  weatherIcon() {
    if (this.dataset && this.dataset.weather) {
      return 'https://openweathermap.org/img/w/' + this.dataset.weather[0].icon + '.png';
    }
    return '';
  }

  private fetchDataset(site) {
    if (this.isFetching) {
      return;
    }

    this.isFetching = true;

    this.subscribers.GET_LOCAL_WEATHER = this.localWeatherService
      .getCurrentWeather({
        zipcode: site.postalCode,
        countryCode: site.country,
      })
      .subscribe(
        (result: any) => {
          this.dataset = result;
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error) =>
          (this.subscribers.GET_LOCAL_WEATHER = this.localWeatherService
            .getCurrentWeather({
              cityName: site.city,
              countryCode: site.country,
            })
            .subscribe(
              (result: any) => {
                this.dataset = result;
                this.isFetching = false;
                this.isFirstTime = false;
              },
              (error) => (this.error = true)
            ))
      );
  }

  get weatherDescription() {
    if (this.dataset && this.dataset.weather) {
      return this.dataset.weather[0].description;
    }
    return '';
  }

  get weatherTemp() {
    if (this.dataset && this.dataset.main) {
      return this.dataset.main.temp;
    }
    return '';
  }

  get weatherHumidity() {
    if (this.dataset && this.dataset.main) {
      return this.dataset.main.humidity;
    }
    return '';
  }
}
