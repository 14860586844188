import * as fromLocales from '../actions/locales.action';
import { Locale } from '@widgets/locales/models/locale';

export interface LocaleState {
  entities: { [id: string]: Locale };
  loaded: boolean;
  loading: boolean;
  selectedId: string;
  pending: boolean;
  pendingError: any;
  loadFormData: boolean;
  loadFormDataError: any;
  formData: Locale;
}

export const initialState = {
  entities: null,
  loaded: false,
  loading: false,
  selectedId: null,
  pending: false,
  pendingError: null,
  loadFormData: false,
  loadFormDataError: null,
  formData: null,
};

export function reducer(state = initialState, action: fromLocales.LocalesAction): LocaleState {
  switch (action.type) {
    // LOAD LOCALE FORM
    case fromLocales.LOAD_LOCALE: {
      return {
        ...state,
        loadFormData: true,
      };
    }
    case fromLocales.LOAD_LOCALE_FAIL: {
      const loadFormDataError = action.payload;
      return {
        ...state,
        loadFormData: false,
        loadFormDataError,
        formData: null,
      };
    }
    case fromLocales.LOAD_LOCALE_SUCCESS: {
      const formData = action.payload;
      return {
        ...state,
        loadFormData: false,
        loadFormDataError: null,
        formData,
      };
    }

    // LOAD LOCALES
    case fromLocales.LOAD_LOCALES: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromLocales.LOAD_LOCALES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromLocales.LOAD_LOCALES_SUCCESS: {
      const locales = action.payload;
      const entities = locales.reduce((tmpEntities: { [id: string]: Locale }, locale: Locale) => {
        return {
          ...tmpEntities,
          [locale.id]: locale,
        };
      }, {});
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    // SELECT LOCALE
    case fromLocales.SELECT_LOCALE: {
      return {
        ...state,
        selectedId: action.payload,
      };
    }

    // CREATE / UPDATE LOCALE
    case fromLocales.CREATE_LOCALE:
    case fromLocales.UPDATE_LOCALE:
    case fromLocales.DELETE_LOCALE: {
      return {
        ...state,
        pending: true,
      };
    }

    case fromLocales.CREATE_LOCALE_FAIL:
    case fromLocales.UPDATE_LOCALE_FAIL:
    case fromLocales.DELETE_LOCALE: {
      const pendingError = action.payload;
      return {
        ...state,
        pending: false,
        pendingError,
      };
    }

    case fromLocales.CREATE_LOCALE_SUCCESS:
    case fromLocales.UPDATE_LOCALE_SUCCESS: {
      const locale = action.payload;
      const entities = {
        ...state.entities,
        [locale.id]: locale,
      };

      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
      };
    }

    // DELETE LOCALE
    case fromLocales.DELETE_LOCALE_SUCCESS: {
      const locale = action.payload;
      const entities = {
        ...state.entities,
        [locale.id]: {
          ...locale,
          isActive: false,
          isDeleted: true,
        },
      };
      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
      };
    }

    // RESET PENDING
    case fromLocales.LOCALE_RESET_PENDING_STATE: {
      return {
        ...state,
        pending: false,
        pendingError: null,
        formData: null,
      };
    }
  }

  return state;
}

export const getLocalesEntities = (state: LocaleState) => state.entities;

export const getSelectedId = (state: LocaleState) => state.selectedId;

export const getLocalesLoading = (state: LocaleState) => state.loading;

export const getLocalesLoaded = (state: LocaleState) => state.loaded;

export const getPending = (state: LocaleState) => state.pending;

export const getPendingError = (state: LocaleState) => state.pendingError;

export const getLocaleFormData = (state: LocaleState) => state.formData;

export const getLoadFormData = (state: LocaleState) => state.loadFormData;

export const getLoadFormDataError = (state: LocaleState) => state.loadFormDataError;
