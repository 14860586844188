import { Component, OnInit } from '@angular/core';
import { ActionMenuItem } from '../../models/action-menu-item';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sc-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
})
export class ActionMenuComponent implements OnInit {
  menus: ActionMenuItem[] = [];

  constructor(private modalRef: DynamicDialogRef, private modalConfig: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.modalConfig && this.modalConfig.data) {
      if (this.modalConfig.data.menu) {
        this.menus = this.modalConfig.data.menu;
      }
    }
  }

  onSelect(menu) {
    if (menu.disabled || menu.hidden) {
      return;
    }
    this.modalRef.close(menu.value);
  }
}
