<div class="ceos-device-properties-cell">
  <div
    *ngFor="let vd of virtualDevices"
    class="badge purple mr-1 property-item"
    [pTooltip]="getTooltipText(vd)"
    tooltipPosition="top"
    appendTo="body"
    (click)="openTriggerForm(vd)"
  >
    {{ vd?.formattedValue || vd?.value || "-" }}{{ vd?.unit || "" }}
  </div>
</div>
