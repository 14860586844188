import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-activate-button',
  templateUrl: './activate-button.component.html',
  styleUrls: ['./activate-button.component.scss'],
})
export class ActivateButtonComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  field: string;

  @Input()
  label: string = 'ACTIVE';

  @Input()
  activeIcon: string = 'fa fa-check-square-o';

  @Input()
  inactiveIcon: string = 'fa fa-square-o';

  constructor() {}

  ngOnInit() {}
}
