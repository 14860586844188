<div *ngIf="fetchingState === 0; else loadingIndicator">

<form [formGroup]="form" novalidate>
  <p-card>
    <!--Header-->
    <p-header>
      <div class="ui-g">
        <div class="ui-g-6 px-0">
          <sc-text-input
            *ngIf="editMode"
            controlName="id"
            [form]="form"
            label="{{ 'ID' | translate }}"
          ></sc-text-input>
        </div>
        <div class="ui-g-6 px-0 text-right">
          <p-toggleButton
            formControlName="isActive"
            onLabel="{{ 'ACTIVE' | translate }}"
            offLabel="{{ 'ACTIVE' | translate }}"
            onIcon="fa fa-check-square-o"
            offIcon="fa fa-square-o"
          ></p-toggleButton>
        </div>
      </div>
    </p-header>

    <!--Body-->
    <div class="ui-g mb-3">
      <div class="ui-g-12 ui-md-6 px-0">
        <!-- NAME FIELD -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- DESCRIPTION FIELD -->
        <div class="ui-g-12">
          <sc-textarea
            controlName="description"
            [form]="form"
            label="{{ 'DESCRIPTION' | translate }}"
          ></sc-textarea>
        </div>
      </div>

      <div class="ui-g-12 ui-md-6 px-0">
        <!-- PRIORITY FIELD -->
        <div class="ui-g-12">
          <sc-number-input
            controlName="priority"
            [form]="form"
            label="{{ 'PRIORITY' | translate }}"
          ></sc-number-input>
        </div>

        <!-- PLACEHOLDER FIELD -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="placeholders"
            [form]="form"
            label="{{ 'PLACEHOLDER' | translate }}"
            [options]="selectItems?.notificationTemplatePlaceholders"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>
      </div>
    </div>

    <!-- RECIPIENTS -->
    <div class="ui-g mb-3">
      <div class="ui-g-12 p-0">
        <h4 class="m-0">{{ 'RECIPIENTS' | translate }}</h4>

        <div class="ui-g-12 ui-md-6 ui-g-nopad">
          <!-- ROLE FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="roles"
              [form]="form"
              label="{{ 'ROLES' | translate }}"
              [options]="selectItems?.userRoles"
              [multiSelect]="true"
            ></sc-dropdown>
          </div>

          <!-- EXCLUSIVE_SITES FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="siteIds"
              [form]="form"
              label="{{ 'EXCLUSIVE_SITES' | translate }}"
              [options]="selectItems?.sites"
              [multiSelect]="true"
            ></sc-dropdown>
          </div>
        </div>

        <div class="ui-g-12 ui-md-6 mt-2">
          <!-- IS_PUBLIC FIELD -->
          <div class="ui-g-12">
            <sc-checkbox-input
              controlName="isPublic"
              [form]="form"
              label="{{ 'IS_PUBLIC' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- IS_ACTIVE_SEND_EMAILS -->
          <div class="ui-g-12">
            <sc-checkbox-input
              controlName="isActiveSendEmails"
              [form]="form"
              label="{{ 'ACTIVE' | translate }} {{ 'SEND_EMAIL' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- IS_ACTIVE_SEND_SMS -->
          <div class="ui-g-12">
            <sc-checkbox-input
              controlName="isActiveSendSms"
              [form]="form"
              label="{{ 'ACTIVE' | translate }} {{ 'SEND_SMS' | translate }}"
            ></sc-checkbox-input>
          </div>
        </div>
      </div>
    </div>

    <!-- GENERAL_TEMPLATES -->
    <div class="ui-g mb-3">
      <div class="ui-g-12 px-0">
        <div class="ui-g-10 px-0">
          <h4 class="m-0">{{ 'GENERAL_TEMPLATES' | translate }}</h4>
        </div>
        <div class="ui-g-2 py-0 text-right">
          <button
            pButton
            type="button"
            class="ui-button-success"
            icon="fa fa-plus"
            pTooltip="{{ 'ADD' | translate }} {{ 'GENERAL_TEMPLATE' | translate }}"
            (click)="addGeneral()"
          ></button>
        </div>

        <div class="ui-g-12 p-0" formArrayName="generalTemplates">
          <ul class="template-menu">
            <li *ngFor="let general of generalTemplates.controls; let i=index"
              class="template-menu-item"
              [ngClass]="{'active': currentGeneralTemplate === i}"
            >
              <a (click)="currentGeneralTemplate = i" class="sc-clickable">
                <span [ngClass]="flagIcon(general.get('locale').value)"></span>
                {{ (general.get('locale').value | uppercase) || ('NEW' | translate) }}
              </a>
            </li>
          </ul>

          <div *ngFor="let general of generalTemplates.controls; let i=index" class="template-form">
            <sc-general-form-group
              *ngIf="i === currentGeneralTemplate"
              [group]="general"
              [groupId]="i"
              [optionData]="selectItems"
              [placeholders]="placeholders"
              (removed)="removeGeneral(i)"
            ></sc-general-form-group>
          </div>
        </div>
      </div>
    </div>

    <!-- EMAIL_TEMPLATES -->
    <div class="ui-g mb-3">
      <div class="ui-g-12 px-0">
        <div class="ui-g-10 px-0">
          <h4 class="m-0">{{ 'EMAIL_TEMPLATES' | translate }}</h4>
        </div>

        <div class="ui-g-2 py-0 text-right">
          <button
            pButton
            type="button"
            class="ui-button-success"
            pTooltip="{{ 'ADD' | translate }} {{ 'EMAIL_TEMPLATE' | translate }}"
            icon="fa fa-plus"
            (click)="addEmail()"
          ></button>
        </div>

        <div class="ui-g-12 p-0" formArrayName="emailTemplates">
          <ul class="template-menu">
            <li *ngFor="let email of emailTemplates.controls; let i=index"
              class="template-menu-item"
              [ngClass]="{'active': currentEmailTemplate === i}"
            >
              <a (click)="currentEmailTemplate = i" class="sc-clickable">
                <span [ngClass]="flagIcon(email.get('locale').value)"></span>
                {{ (email.get('locale').value | uppercase) || ('NEW' | translate) }}
              </a>
            </li>
          </ul>

          <div *ngFor="let email of emailTemplates.controls; let i=index" class="template-form">
            <sc-email-form-group
              *ngIf="i === currentEmailTemplate"
              [group]="email"
              [groupId]="i"
              [optionData]="selectItems"
              [placeholders]="placeholders"
              (removed)="removeEmail(i)"
            ></sc-email-form-group>
          </div>
        </div>
      </div>
    </div>

    <!-- SMS_TEMPLATES -->
    <div class="ui-g mb-3">
      <div class="ui-g-12 px-0">
        <div class="ui-g-10 px-0">
          <h4 class="m-0">{{ 'SMS_TEMPLATES' | translate }}</h4>
        </div>

        <div class="ui-g-2 py-0 text-right">
          <button
            pButton
            type="button"
            class="ui-button-success"
            pTooltip="{{ 'ADD' | translate }} {{ 'SMS_TEMPLATE' | translate }}"
            icon="fa fa-plus"
            (click)="addSms()"
          ></button>
        </div>

        <div class="ui-g-12 p-0" formArrayName="smsTemplates">
          <ul class="template-menu">
            <li *ngFor="let sms of smsTemplates.controls; let i=index"
              class="template-menu-item"
              [ngClass]="{'active': currentSmsTemplate === i}"
            >
              <a (click)="currentSmsTemplate = i" class="sc-clickable">
                <span [ngClass]="flagIcon(sms.get('locale').value)"></span>
                {{ (sms.get('locale').value | uppercase) || ('NEW' | translate) }}
              </a>
            </li>
          </ul>

          <div *ngFor="let sms of smsTemplates.controls; let i=index" class="template-form">
            <sc-sms-form-group
              *ngIf="i === currentSmsTemplate"
              [group]="sms"
              [groupId]="i"
              [optionData]="selectItems"
              [placeholders]="placeholders"
              (removed)="removeSms(i)"
            ></sc-sms-form-group>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Message -->
    <div class="ui-g">
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <p-footer>
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>
      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>
        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </p-footer>
  </p-card>
</form>

</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
