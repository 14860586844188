<div [formGroup]="group">
  <div class="ui-g-12">
    <div class="ui-g-6">
      <strong class="blue-text">@{{ basicData.key }}</strong>
      <br />
      <small class="grey-text">{{ basicData.description || 'NO_DESCRIPTION' | translate }}</small>
    </div>
    <div class="ui-g-6">
      <div [ngSwitch]="dynamicInput()">
        <div *ngSwitchCase="'colorInput'">
          <sc-color-input
            controlName="value"
            [form]="group"
            label="{{ 'VALUE' | translate }}"
            [outputFormat]="group.value['type']"
          ></sc-color-input>
        </div>
        <div *ngSwitchCase="'numberInput'">
          <sc-number-input
            controlName="value"
            [form]="group"
            label="{{ 'VALUE' | translate }}"
          ></sc-number-input>
        </div>
        <div *ngSwitchCase="'fileInput'">
          <sc-file-input
            controlName="value"
            [form]="group"
            label="{{ 'VALUE' | translate }}"
          ></sc-file-input>
        </div>
        <div *ngSwitchDefault>
          <sc-text-input
            controlName="value"
            [form]="group"
            label="{{ 'VALUE' | translate }}"
          ></sc-text-input>
        </div>
      </div>
    </div>
  </div>
</div>
