<div *ngIf="isFetchingDataset; then spinner; else content"></div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <sc-table
    [columns]="columns"
    [config]="config"
    (afterInit)="afterInitTable($event)"
    (reloadData)="fetchDataset()"
    (displayColumnsChange)="updateVisibleColumns($event)"
    [title]="tableTitle"
    [lazyload]="true"
    icon="fa fa-exclamation-triangle"
    [toolbarItems]="toolbarItems"
    [showDateFilter]="true"
    (filterDateChange)="onDateSelect($event)"
  ></sc-table>
</ng-template>
