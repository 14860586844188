import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { ColDef } from 'ag-grid';
import { Location } from '@widgets/locations/models/location';

import { LocationSummaryService } from '../services/location-summary.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-summary-hourly-logs',
  templateUrl: './hourly-logs.component.html',
  styleUrls: ['./hourly-logs.component.scss']
})

export class LocationSummaryHourlyLogsComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isFetchingDataset = false;
  isFirstTime = true;
  dataset: any[];
  tableColumns: ColDef[];
  tableTitle: string;
  toolbarItems: string[];

  private selectedLocation: Location;
  private subscribers: { [key: string]: any } = {};
  private timeFields = [1, 2, 3, 4, 6, 7, 8, 9];

  constructor(
    private locationSummaryService: LocationSummaryService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
  ) { }

  ngOnInit() {
    this.tableColumns = this.createColumns();
    this.tableTitle = 'LOCATION_SUMMARY_HOURLY_LOG';
    this.toolbarItems = ['refresh', 'columns'];

    // get location details
    this.subscribers.LOCATION_DETAIL = this.store
      .pipe(select(fromStore.getLocationDetials))
      .subscribe(result => {
        if (result) {
          this.selectedLocation = result;
          this.isFirstTime = true;
          this.fetchDataset(true);
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset(force?: boolean) {
    if (!force && this.isFetchingDataset) {
      return;
    }

    this.isFetchingDataset = true;

    this.subscribers.getLocationSummaryHourly = this.locationSummaryService
      .getLocationSummaryHourly(this.selectedLocation.id)
      .subscribe((result: any) => {
        this.dataset = result.data;

        this.isFetchingDataset = false;
        this.isFirstTime = false;
      });
  }

  private dataFormatter(params: any) {
    if (!params || !params.data) {
      return '';
    }

    const fieldId = params.data.field_id;
    const value = params.data.value;
    let data = value;
    if (this.timeFields.indexOf(fieldId) >= 0) {
      if (value > 60) {
        data = value / 60;
        data = this.sharedService.numberFormat(data, 2);
      }
    }
    return data;
  }

  private dataUnitFormatter(params: any) {
    if (!params || !params.data) {
      return '';
    }

    const fieldId = params.data.field_id;
    const value = params.data.value;
    let unit = '';
    if (this.timeFields.indexOf(fieldId) >= 0) {
      unit = ' second(s)';
      if (value > 60) {
        unit = ' minute(s)';
      }
    } else {
      unit = ' time(s)';
    }
    return unit;
  }

  private dateFormatter(params: any) {
    const key = params && params.data && params.data.key;
    if (key) {
      return this.sharedService.dateFormat(key, 'DD.MM.YYYY');
    }
    return '';
  }

  private timeFormatter(params: any) {
    const time = params && params.data && params.data.start_ts;
    if (time) {
      return this.sharedService.dateFormat(time, 'HH:mm:ss', false, true);
    }
    return '';
  }

  private fieldFormatter(params: any) {
    const fieldId = params && params.data && params.data.field_id;
    let field: string;

    switch (fieldId) {
      case 1:
        field = 'LOCATION_SUMMARY_OCCUPIED';
        break;
      case 2:
        field = 'LOCATION_SUMMARY_UNOCCUPIED';
        break;
      case 3:
        field = 'LOCATION_SUMMARY_LIGHTS_ON';
        break;
      case 4:
        field = 'LOCATION_SUMMARY_LIGHTS_OFF';
        break;
      case 5:
        field = 'LOCATION_SUMMARY_SETPOINT_CHANGES_COUNT';
        break;
      case 6:
        field = 'LOCATION_SUMMARY_HVAC_MODE_OFF';
        break;
      case 7:
        field = 'LOCATION_SUMMARY_HVAC_MODE_COOLING';
        break;
      case 8:
        field = 'LOCATION_SUMMARY_HVAC_MODE_HEATING';
        break;
      case 9:
        field = 'LOCATION_SUMMARY_HVAC_MODE_VENTILATING';
        break;
      case 10:
        field = 'LOCATION_SUMMARY_DOOR_CHANGES_COUNT';
        break;
      default:
        field = fieldId;
        break;
    }

    return this.sharedService.getTranslation(field);
  }

  private dataCellRenderer(params: any) {
    return '<div class="text-right">' + params.valueFormatted + '</div>';
  }

  private createColumns() {
    return [
      {
        colId: 'key',
        headerName: 'DATE',
        field: 'key',
        valueFormatter: this.dateFormatter.bind(this),
        filterValueGetter: this.dateFormatter.bind(this)
      },
      {
        colId: 'start_ts',
        headerName: 'TIME',
        field: 'start_ts',
        valueFormatter: this.timeFormatter.bind(this),
        filterValueGetter: this.timeFormatter.bind(this)
      },
      {
        colId: 'field_id',
        headerName: 'LOCATION_SUMMARY_FIELD',
        field: 'field_id',
        valueFormatter: this.fieldFormatter.bind(this),
        valueGetter: this.fieldFormatter.bind(this)
      },
      {
        colId: 'value',
        headerName: 'VALUE',
        field: 'value',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'unit',
        headerName: 'UNIT',
        valueFormatter: this.dataUnitFormatter.bind(this),
        valueGetter: this.dataUnitFormatter.bind(this)
      }
    ];
  }
}
