import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SiteType } from '../models/site-type';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class SiteTypesService {
  private apiUrl = config().apiUrl + '/siteTypes';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  /**
   * Delete properties that are not allowed on API
   * @param data
   */
  private verifyData(data: SiteType): SiteType {
    const newData = { ...data };
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getSiteType(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getSiteTypes() {
    return this.httpClient.get(this.apiUrl);
  }

  createSiteType(data: SiteType) {
    const formData = this.sharedService.createFormData(data, ['icon']);
    return this.httpClient.post(this.apiUrl, formData);
  }

  updateSiteType(id: number, data: SiteType) {
    const formData = this.sharedService.createFormData(this.verifyData(data), ['icon']);
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }

  deleteSiteType(id: number) {
    const formData = this.sharedService.createFormData({
      isActive: false,
      isDeleted: true,
    });
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }
}
