import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ActionMenuItem, LeftOperandTypes, RightOperandTypes, DataSources, SelectItems } from '../../models';
import { RulesService, UtilsService } from '../../services';
import { LeftOperandModalComponent } from '../left-operand-modal/left-operand-modal.component';
import { RightOperandModalComponent } from '../right-operand-modal/right-operand-modal.component';
import { OperatorModalComponent } from '../operator-modal/operator-modal.component';

@Component({
  selector: 'sc-condition-item',
  templateUrl: './condition-item.component.html',
  styleUrls: ['./condition-item.component.scss'],
})
export class ConditionItemComponent implements OnInit, OnChanges {
  actionMenu: ActionMenuItem[];
  connectorLabel: string;
  leftOperandLabel: string;
  operatorLabel: string;
  operatorParamsLabel: string;
  rightOperandLabel: string;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  condition: any;

  @Input()
  connector: 'AND' | 'OR';

  @Input()
  firstItem: boolean = false;

  @Input()
  viewMode: string;

  @Input()
  isTemplate: boolean;

  @Input()
  isFromScratch: boolean;

  @Output()
  movedUp = new EventEmitter<any>();

  @Output()
  movedDown = new EventEmitter<any>();

  @Output()
  removed = new EventEmitter<any>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private utilsService: UtilsService,
    private rulesService: RulesService
  ) {}

  ngOnInit(): void {
    this.actionMenu = [
      { label: 'CCP_COMMON_MOVE_UP', value: 'move_up' },
      { label: 'CCP_COMMON_MOVE_DOWN', value: 'move_down' },
      { label: 'CCP_COMMON_REMOVE', value: 'remove' },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.connector && changes.connector.currentValue) {
      this.setConnectorLabel();
    }
    if (changes.condition && changes.condition.currentValue) {
      this.setLeftOperandLabel();
      this.setRightOperandLabel();
      this.setOperatorLabel();
      this.changeDetectorRef.detectChanges();
    }
  }

  onLeftOperandClick() {
    const modalRef = this.utilsService.modal(
      LeftOperandModalComponent,
      {
        condition: this.condition,
        dataSources: this.dataSources,
        selectItems: this.selectItems,
        viewMode: this.viewMode,
        isTemplate: this.isTemplate,
        isFromScratch: this.isFromScratch,
      },
      {
        width: '600px',
      }
    );
    modalRef.onClose.subscribe((result) => {
      this.setLeftOperandLabel();
      this.changeDetectorRef.detectChanges();
    });
  }

  onRightOperandClick() {
    if (!this.isAllowRightOperand) {
      return;
    }

    const modalRef = this.utilsService.modal(
      RightOperandModalComponent,
      {
        condition: this.condition,
        dataSources: this.dataSources,
        selectItems: this.selectItems,
        structure: this.getRightOperandStructure(),
        viewMode: this.viewMode,
        isTemplate: this.isTemplate,
        isFromScratch: this.isFromScratch,
      },
      {
        width: '600px',
      }
    );
    modalRef.onClose.subscribe((result) => {
      this.setRightOperandLabel();
      this.changeDetectorRef.detectChanges();
    });
  }

  onOperatorClick() {
    if (!this.isAllowOperator) {
      return;
    }

    const modalRef = this.utilsService.modal(
      OperatorModalComponent,
      {
        condition: this.condition,
        dataSources: this.dataSources,
        selectItems: this.selectItems,
        isTemplate: this.isTemplate,
        isFromScratch: this.isFromScratch,
      },
      {
        width: '600px',
      }
    );
    modalRef.onClose.subscribe((result) => {
      this.setOperatorLabel();
      this.changeDetectorRef.detectChanges();
    });
  }

  onActionSelected(action) {
    if (typeof action === 'undefined') {
      return;
    }

    switch (action) {
      case 'move_up':
        return this.movedUp.emit();
      case 'move_down':
        return this.movedDown.emit();
      case 'remove':
        return this.removed.emit();
    }
  }

  private setConnectorLabel() {
    this.connectorLabel = 'CCP_RULE_CONNECTOR_' + this.connector;
    this.changeDetectorRef.detectChanges();
  }

  private setLeftOperandLabel() {
    this.leftOperandLabel = '';

    if (!this.condition || !this.condition.left || !this.condition.left.type) {
      return;
    }

    const operand = this.condition.left;

    switch (operand.type) {
      case LeftOperandTypes.DeviceDateTime:
        this.leftOperandLabel = this.getDeviceLeftOperandLabel(operand);
        this.utilsService.textTranslate('CCP_COMMON_LATEST_VALUE_LOGGED_AT').subscribe((result) => {
          this.leftOperandLabel += ' ' + result;
        });
        break;

      case LeftOperandTypes.DeviceValue:
        this.leftOperandLabel = this.getDeviceLeftOperandLabel(operand);
        break;

      case LeftOperandTypes.LocationPropertyDateTime:
        this.leftOperandLabel = this.getLocationPropertyLeftOperandLabel(operand);
        this.utilsService.textTranslate('CCP_COMMON_LATEST_VALUE_LOGGED_AT').subscribe((result) => {
          this.leftOperandLabel += ' ' + result;
        });
        break;

      case LeftOperandTypes.LocationPropertyValue:
        this.leftOperandLabel = this.getLocationPropertyLeftOperandLabel(operand);
        break;

      case LeftOperandTypes.FunctionValue:
        if (operand.custom_function_idx) {
          const cf = this.getCustomFunction(operand.custom_function_idx);
          if (cf) {
            this.leftOperandLabel = 'Func: ' + cf.name;
          }
        }
        break;

      case LeftOperandTypes.GlobalValue:
        if (operand.custom_global_key) {
          const cf = this.getCustomGlobal(operand.custom_global_key);
          if (cf) {
            this.leftOperandLabel = 'Global: ' + cf.label;
          }
        }
        break;

      case LeftOperandTypes.DatetimeNow:
        this.leftOperandLabel = 'CCP_COMMON_NOW';
        break;

      default:
        this.leftOperandLabel = operand.type;
        break;
    }
  }

  private setRightOperandLabel() {
    this.rightOperandLabel = '';

    if (
      !this.condition ||
      !this.condition.right ||
      !this.condition.right.type ||
      typeof this.condition.right.value === 'undefined'
    ) {
      return;
    }

    const rightOperand = this.condition.right;
    const structure = this.getRightOperandStructure();

    this.rightOperandLabel =
      typeof rightOperand.value !== 'undefined' && rightOperand.value !== null ? rightOperand.value + '' : '';

    if (structure) {
      const valueStructure = structure.render;
      if (valueStructure) {
        if (valueStructure.type === 'select' && valueStructure.options) {
          const mapValue = valueStructure.options.find((item) => item.value === rightOperand.value);
          if (mapValue) {
            this.rightOperandLabel = mapValue.label;
          }
        } else if (valueStructure.type === 'boolean') {
          this.rightOperandLabel = rightOperand.value === true ? 'Yes' : 'No';
        }

        if (valueStructure.unit) {
          this.rightOperandLabel += ' ' + valueStructure.unit;
        }
      }

      if (rightOperand.type === RightOperandTypes.DatetimeAbsolute) {
        this.rightOperandLabel = `${structure.label} ${this.rightOperandLabel}`;
      }
    } else {
      switch (rightOperand.type) {
        case RightOperandTypes.DatetimeAbsolute:
          this.rightOperandLabel = `${rightOperand.pattern}`;
          if (rightOperand.value) {
            this.rightOperandLabel += ` ${rightOperand.value}`;
          }
          break;
        case RightOperandTypes.DatetimeRelative:
          let unit;
          switch (rightOperand.unit) {
            case 'h':
              unit = 'hours';
              break;
            case 'm':
              unit = 'minutes';
              break;
            case 's':
              unit = 'seconds';
              break;
          }
          this.rightOperandLabel = `${rightOperand.value} ${unit} ${rightOperand.mode}`;
          break;
        case RightOperandTypes.Device:
          this.rightOperandLabel = this.getDeviceRightOperandLabel(rightOperand);
          break;
        default:
          break;
      }
    }
  }

  private getRightOperandStructure() {
    const leftOperand = this.condition && this.condition.left;
    const rightOperand = this.condition && this.condition.right;
    let structure;

    // skipped
    if ((rightOperand && rightOperand.type === RightOperandTypes.Device) || !leftOperand) {
      return;
    }

    switch (leftOperand.type) {
      case LeftOperandTypes.DeviceValue:
        if (leftOperand.device_type) {
          structure = this.getDeviceType(leftOperand.device_type);
        } else if (leftOperand.device_idx) {
          const device = this.getDevice(leftOperand.device_idx);
          if (device) {
            structure = this.getDeviceType(device.device_type);
          }
        }
        break;
      case LeftOperandTypes.LocationPropertyValue:
        if (leftOperand.property) {
          structure = this.getLocationProperties(leftOperand.property);
        }
        break;
      case LeftOperandTypes.DatetimeNow:
        if (rightOperand.type === RightOperandTypes.DatetimeAbsolute && rightOperand.pattern) {
          structure = this.rulesService.getAbsoluteDatetimePatternsStructures(rightOperand.pattern);
        }
        break;
      case LeftOperandTypes.FunctionValue:
        this.dataSources.customFunctions;
        if (leftOperand.custom_function_idx) {
          const customFunction = this.getCustomFunction(leftOperand.custom_function_idx);
          if (customFunction) {
            structure = { render: { type: customFunction.return_type } };
          }
        }
        break;
      default:
        break;
    }

    return structure;
  }

  private setOperatorLabel() {
    this.operatorLabel = '';
    this.operatorParamsLabel = '';

    if (!this.condition || !this.condition.operator || !this.condition.operator.type) {
      return;
    }

    const operator = this.condition.operator;
    // const operatorParams = operator.params;
    const structure = this.rulesService.getOperatorStructures(operator.type);

    this.operatorLabel = structure.label;

    let text = ''; // e.g. in last 90s for 10s

    for (const param of structure.params) {
      // if (operatorParams[param.id] > 0) {
      if (operator[param.id] > 0) {
        if (param.id === 'included_past') {
          // text += ` in last ${operatorParams[param.id]}`;
          text += ` in last ${operator[param.id]}`;
        } else if (param.id === 'min_validity_period') {
          // text += ` for ${operatorParams[param.id]}`;
          text += ` for ${operator[param.id]}`;
        } else if (param.id === 'previous_rule_trigger_as_included_past') {
          text += ' included from rule trigger';
        }

        if (param.value && param.value.unit) {
          text += param.value.unit;
        }
      }
    }

    if (text !== '') {
      this.operatorParamsLabel = text;
    }
  }

  private getDevice(idx: string) {
    if (this.dataSources && this.dataSources.devices && this.dataSources.devices.length) {
      return this.dataSources.devices.find((d) => d.idx === idx);
    }
    return;
  }

  private getLocation(idx: string) {
    if (this.dataSources && this.dataSources.locations && this.dataSources.locations.length) {
      return this.dataSources.locations.find((d) => d.idx === idx);
    }
    return;
  }

  private getLocationProperties(key: string) {
    if (this.dataSources && this.dataSources.locationProperties && this.dataSources.locationProperties.length) {
      return this.dataSources.locationProperties.find((d) => d.key === key);
    }
    return;
  }

  private getCustomFunction(idx: string) {
    if (this.dataSources && this.dataSources.customFunctions && this.dataSources.customFunctions.length) {
      return this.dataSources.customFunctions.find((d) => d.idx === idx);
    }
    return;
  }

  private getCustomGlobal(key: string) {
    if (this.dataSources && this.dataSources.customGlobals && this.dataSources.customGlobals.length) {
      return this.dataSources.customGlobals.find((d) => d.key === key);
    }
    return;
  }

  private getDeviceType(key: string) {
    if (this.dataSources && this.dataSources.deviceTypes && this.dataSources.deviceTypes.length) {
      return this.dataSources.deviceTypes.find((d) => d.key === key);
    }
    return;
  }

  private getLocationPropertyLeftOperandLabel(operand) {
    let label = '';
    if (operand.location_idx) {
      const location = this.getLocation(operand.location_idx);
      if (location) {
        label = location.name;
      }
    }
    if (operand.property && this.isTemplate) {
      const property = this.getLocationProperties(operand.property);
      if (property) {
        label += ' ' + (property.label || property.key);
      }
    }
    return label;
  }

  private getDeviceLeftOperandLabel(operand) {
    let label = '';
    if (operand.device_idx) {
      const device = this.getDevice(operand.device_idx);
      if (device) {
        const parentDevice = this.dataSources.devices.find((item) => item.idx === device.parent_idx);
        const location = this.dataSources.locations.find((item) => item.idx === device.location_idx);
        const n1Location = this.dataSources.locations.find((item) => item.idx === location?.parent_idx);
        const n2Location = this.dataSources.locations.find((item) => item.idx === n1Location?.parent_idx);
        label = `${n2Location ? `${n2Location.name} / ` : ''}${n1Location ? `${n1Location.name} / ` : ''}${
          location ? `${location.name} / ` : ''
        }${parentDevice ? `${parentDevice.name} / ` : ''}${device.name}`;
      }
    } else if (operand.device_type && this.isTemplate) {
      const deviceType = this.getDeviceType(operand.device_type);
      if (deviceType) {
        label = deviceType.label;
      }
    }
    return label;
  }

  private getDeviceRightOperandLabel(operand) {
    let label = '';
    if (operand.value) {
      const device = this.getDevice(operand.value);
      if (device) {
        label = device.name;
      }
    } else if (operand.device_type && this.isTemplate) {
      const deviceType = this.getDeviceType(operand.device_type);
      if (deviceType) {
        label = deviceType.label;
      }
    }
    return label;
  }

  get isAllowOperator(): boolean {
    if (this.condition && this.condition.left && this.condition.left.type) {
      return true;
    }
    return false;
  }

  get isAllowRightOperand(): boolean {
    if (
      this.condition &&
      this.condition.left &&
      this.condition.left.type &&
      this.condition.operator &&
      this.condition.operator.type
    ) {
      return true;
    }
    return false;
  }
}
