import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-command-parameter-form',
  templateUrl: './command-parameter-form.component.html',
  styleUrls: ['./command-parameter-form.component.scss']
})
export class CommandParameterFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() formId: number;
  @Input() data: {
    [key: string]: any;
    rawData: { [key: string]: any };
    selectItems: { [key: string]: SelectItem[] };
  };

  selectItems: { [key: string]: SelectItem[] };

  constructor() {}

  ngOnInit() {
    this.selectItems = { ...this.data.selectItems };
  }

  get devices() {
    if (
      this.selectItems &&
      this.selectItems['param' + this.formId + 'Devices']
    ) {
      return this.selectItems['param' + this.formId + 'Devices'];
    }
    return [];
  }
}
