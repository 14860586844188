<div class="ui-g mb-1">
  <div class="ui-g-12 ui-md-3 ui-g-nopad">
    <h2 class="m-0 text-ellipsis">
      {{ selectedLocation?.description }}
    </h2>
    <small>
      <span class="badge darkgrey mr-2">
        ID: {{ selectedLocation?.id }}
      </span>
      <span
        class="badge darkgrey mr-2"
        pTooltip="{{ 'LOCATION_TYPE' | translate }}"
        appendTo="body"
        tooltipPosition="top"
      >
        {{ selectedLocation?.locationTypeName }}
      </span>
      <span
        class="badge"
        [ngClass]="locationStatusColor"
        pTooltip="{{ 'STATUS' | translate }}"
        appendTo="body"
        tooltipPosition="top"
      >
        {{ locationStatus | translate }}
      </span>
    </small>
  </div>

  <div class="ui-g-12 ui-md-6 text-center">
    <div class="ui-g" *ngIf="neighbour?.length > 1">
      <div class="ui-g-12 ui-md-5 ui-g-nopad text-ellipsis text-right">
        <a
          *ngIf="leftNeighbour"
          class="sc-clickable green-text"
          (click)="visitNeighbour(leftNeighbour)"
        >
          {{ leftNeighbour?.description }}
        </a>
      </div>

      <div class="ui-g-12 ui-md-2 ui-g-nopad text-center">
        <i class="fa fa fa-angle-left"></i>
        <i class="fa fa-map-pin fa-fw"></i>
        <i class="fa fa fa-angle-right"></i>
      </div>

      <div class="ui-g-12 ui-md-5 ui-g-nopad text-ellipsis text-left">
        <a
          *ngIf="rightNeighbour"
          class="sc-clickable green-text"
          (click)="visitNeighbour(rightNeighbour)"
        >
          {{ rightNeighbour?.description }}
        </a>
      </div>
    </div>
  </div>

  <div class="ui-g-12 ui-md-3 pl-0 pr-3 text-right">
    <a class="sc-clickable green-text" (click)="backToLocations()">
      {{ 'LOCATIONS' | translate }}
    </a>

    <i class="fa fa-angle-right"></i>

    <a class="sc-clickable green-text" (click)="op.toggle($event)">
      {{ selectedLocation?.description }}
    </a>

    <p-overlayPanel #op appendTo="body">
      <div
        *ngFor="let n of neighbour; trackBy: trackById"
        class="neighbour-list"
      >
        <button
          pButton
          type="button"
          class="ui-button-secondary item"
          [label]="n.description"
          (click)="visitNeighbour(n); op.hide()"
        ></button>
      </div>
    </p-overlayPanel>
  </div>
</div>
