<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body">
    <div class="sc-flex">
      <ng-container *ngIf="chartData">
        <plotly-plot
          [data]="chartData"
          [layout]="chartLayout"
          [config]="{ displayModeBar: false }"
        ></plotly-plot>
      </ng-container>
    </div>
  </div>
  <div class="sc-widget-footer" *ngIf="chartData">
    <a class="sc-clickable">{{ 'DETAILS' | translate }}&nbsp;<i class="fa fa-angle-right"></i></a>
  </div>
</ng-template>

<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">{{ widgetName }}</h4>
      <div class="sc-widget-menu">
        <button type="button" pButton icon="pi pi-cog" title="Config"></button>
      </div>
    </div>
    <ng-container *ngIf="isInitialized; then content; else spinner"></ng-container>
  </div>
</div>