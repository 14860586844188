import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { InitialRequest } from '../models/initial-request.model';
import { BulkUpdateData } from '../models/bulk-update-data.model';
import { BulkUpdateRequest } from '../models/bulk-update-request.model';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class BulkModificationService {
  private apiUrl = config().apiUrl + '/_backend/_bulkModification';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  initial(options: InitialRequest) {
    const table = options.table;
    delete options.table;
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(`${this.apiUrl}/${table}${queryParams}`);
  }

  bulkUpdate(options: BulkUpdateRequest, data: BulkUpdateData[]) {
    const table = options.table;
    delete options.table;
    const body = { selected: data };
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.patch(`${this.apiUrl}/${table}${queryParams}`, body);
  }
}
