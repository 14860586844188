import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkPermission(state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkPermission(state);
  }

  checkPermission(state: RouterStateSnapshot) {
    if (this.authService.isValidToken() !== true) {
      // not login yet
      this.router.navigate(['/login']);
      return false;
    }

    // settings route
    if (state.url.match(/settings/gi)) {
      const path: string[] = state.url.split('/');

      if (this.authService.isAuthorized('admin') || this.authService.allow(`${path[2]}_r`)) {
        return true;
      }

      // no permission
      this.router.navigate(['my_account']);
      return false;
    }

    // normal route
    return true;
  }
}
