import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-checkbox-input',
  template: `
    <div [formGroup]="form">
      <p-checkbox
        [inputId]="inputId"
        label="{{ showLabel ? label : '' }}"
        [binary]="binary"
        [value]="value"
        [formControl]="form.controls[controlName]"
      ></p-checkbox>

      <sc-field-error [for]="label" [fieldControl]="form.controls[controlName]" [checkBoxField]="true"></sc-field-error>
    </div>
  `,
})
export class SCCheckboxInputComponent implements OnInit {
  @Input() controlName: string;
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() showLabel: boolean;
  @Input() value: any;

  inputId: string;
  binary = true;

  constructor() {}

  ngOnInit() {
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-checkbox-input_' + this.controlName + new Date().getTime();
    if (typeof this.value !== 'undefined') {
      this.binary = false;
    }
  }
}
