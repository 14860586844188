<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <sc-dashboard-alert
    [alertClass]="alertClass"
    [alertText]="alertText"
    [loading]="isFirstTime"
    [titleText]="widgetName"
    [subtitleText]="subTitleText"
    [detailsModal]="detailsModal"
  ></sc-dashboard-alert>
</div>
