import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { RuleCategory } from '../models/rule-category';
import { RuleCategoriesService } from '../services/rule-categories.service';
import { SharedService } from '@shared/shared.service';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { RuleCategoryActionCellComponent } from '../rule-category-action-cell/rule-category-action-cell.component';
import { IconCellComponent } from '@widgets/_shared/icon-cell/icon-cell.component';
import { LocaleTextCellComponent } from '@widgets/_shared/locale-text-cell/locale-text-cell.component';

@Component({
  selector: 'sc-rule-categories-table',
  templateUrl: './rule-categories-table.component.html',
  styleUrls: ['./rule-categories-table.component.scss'],
})
export class RuleCategoriesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: RuleCategory[];
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string = 'RULE_CATEGORIES';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private ruleCategoriesService: RuleCategoriesService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.initTable();
    this.fetchDataset();
  }

  ngOnDestroy() {
    // unsubscribe all subscribers
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.fetchDataset = this.ruleCategoriesService.getRuleCategories().subscribe((result: any) => {
      this.dataset = result.data;
    });
  }

  private createColumns() {
    return [
      {
        colId: 'icon',
        headerName: 'ICON',
        field: 'icon',
        cellRendererFramework: IconCellComponent,
        suppressFilter: true,
        suppressSorting: true,
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
        cellRendererFramework: LocaleTextCellComponent,
        valueFormatter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'name', true),
        filterValueGetter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'name', true),
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
        cellRendererFramework: LocaleTextCellComponent,
        valueFormatter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'description', true),
        filterValueGetter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'description', true),
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: RuleCategoryActionCellComponent,
      },
    ];
  }

  private initTable() {
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'RuleCategoryForm',
      options: { modalTitle: 'RULE_CATEGORY_FORM_TITLE' },
    };
  }

  afterTableInit(table: GridOptions) {
    this.table = table;

    this.table.getRowHeight = (params) => 35;

    // update table content when user selected language changed
    this.subscribers.languageChange = this.translateService.onLangChange.subscribe((event) => {
      if (this.table && this.table.api) {
        this.table.api.redrawRows();
      }
    });
  }
}
