import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocationDetails } from '@widgets/locations/models/location-details';
import { SharedService } from '@shared/shared.service';
import { GuestSessionFormsService } from '@widgets/guest-sessions/services/guest-session-form.service';
import { GuestSessionsService } from '@widgets/guest-sessions/services/guest-sessions.service';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import * as fromStore from '@app/store';
import { Locale } from '@app/widgets/locales/models/locale';

@Component({
  selector: 'sc-guest-session-form',
  templateUrl: './guest-session-form.component.html',
  styleUrls: ['./guest-session-form.component.scss'],
})
export class GuestSessionFormComponent implements OnInit, OnDestroy {
  editMode = false;
  errorMessage: string;
  form: FormGroup;
  isSubmitting = false;
  locales: SelectItem[];
  accepted: FormControl;

  private guestSession: any;
  private location: LocationDetails;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private guestSessionFormsService: GuestSessionFormsService,
    private guestSessionsService: GuestSessionsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit(): void {
    if (this.config && this.config.data) {
      if (this.config.data.guestSession) {
        this.guestSession = this.config.data.guestSession;
      }

      if (this.config.data.editMode) {
        this.editMode = this.config.data.editMode;
      }

      if (this.config.data.location) {
        this.location = this.config.data.location;
      }
    }

    this.subscribers.getLocales = this.store
      .select(fromStore.getAllLocales)
      .pipe(
        filter((locales) => (locales && locales.length > 0 ? true : false)),
        take(1)
      )
      .subscribe((locales: Locale[]) => {
        this.locales = locales
          .map((locale) => ({ label: locale.language, value: locale.id }))
          .sort((a, b) => a.label.localeCompare(b.label));
      });

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal() {
    this.dialog.close();
  }

  private initForm() {
    this.accepted = new FormControl(false);
    this.form = this.guestSessionFormsService.initGuestSessionForm();

    if (this.editMode !== true && this.location) {
      this.form.patchValue({
        locationId: this.location.idx,
        siteId: this.location.siteId,
        companyId: this.location.companyId,
      });
      this.generatePin();
    } else if (this.editMode === true && this.guestSession) {
      // convert `date string` to `js date object`
      if (this.guestSession.validFrom) {
        this.guestSession.validFrom = this.sharedService.parseDateStringToDateObject(this.guestSession.validFrom);
      }
      if (this.guestSession.validTo) {
        this.guestSession.validTo = this.sharedService.parseDateStringToDateObject(this.guestSession.validTo);
      }

      this.form.patchValue(this.guestSession);
    }
  }

  generatePin() {
    this.form.patchValue({ pin: ('' + Math.random()).substring(2, 8) });
    this.form.markAsDirty();
  }

  submit() {
    if (this.form.invalid || !this.accepted.value) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.getRawValue();
    delete formData.id;

    if (formData.validFrom) {
      formData.validFrom = this.sharedService.parseDateObjectToDateString(formData.validFrom);
    }
    if (formData.validTo) {
      formData.validTo = this.sharedService.parseDateObjectToDateString(formData.validTo);
    }

    // call api
    if (!this.editMode) {
      this.create(formData);
    } else {
      this.update(formData);
    }
  }

  private create(formData) {
    this.subscribers.create = this.guestSessionsService.createGuestSession(formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(formData) {
    this.subscribers.update = this.guestSessionsService.updateGuestSession(this.guestSession.id, formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('GUEST_SESSION');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.dismissModal();
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }
}
