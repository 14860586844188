import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { config } from '@app/config';
import * as fromStore from '@app/store';
import { select, Store } from '@ngrx/store';
import { SharedService } from '@shared/shared.service';
import { Site } from '@widgets/sites/models/site';
import { User } from '@widgets/users/models/user';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UtilsService } from '../../ceos-rule-editor/services';

@Component({
  selector: 'sc-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  allMenu: { [key: string]: any }[];
  isLoggedIn: boolean = false;
  isOverlayMenuActive: boolean;
  currentMinColumns: number;
  myMenu: any[];
  showMenuPanel: boolean;
  userId: number;
  username: string;
  user: User;
  logoDefault: string;
  logoMobile: string;
  items: any[] = [];

  menuBreakPoints = config().menu.breakPoints;
  gridMdBreakPoints = config().breakPoints.md;
  gridSmBreakPoints = config().breakPoints.sm;

  private sites: Site[];
  private subscribers: { [key: string]: any } = {};

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.subscribers.requiredData = combineLatest([
      this.store.select(fromStore.getAuthVerified),
      this.store.select(fromStore.getInitialDataLoaded),
      this.store.select(fromStore.getUserState),
    ])
      .pipe(
        map(([verified, dataLoaded, user]) => ({ verified, dataLoaded, user })),
        filter(({ verified, dataLoaded, user }) => (verified && dataLoaded && user ? true : false)),
        take(1)
      )
      .subscribe(({ verified, dataLoaded, user }) => {
        this.isLoggedIn = verified;
        this.user = {
          id: user.userId,
          username: user.username,
          backendVariant: user.variant,
          mainRole: user.mainRole,
          firstname: user.firstname,
          lastname: user.lastname,
          companyId: user.companyId,
        };

        this.drawMenu();
      });

    this.subscribers.updateMenu = combineLatest([
      this.store.select(fromStore.getCompanyId),
      this.store.select(fromStore.getUpdateMenu),
    ])
      .pipe(
        map(([companyId, updateMenu]) => ({ companyId, updateMenu })),
        filter(({ companyId, updateMenu }) => (companyId && updateMenu > 0 ? true : false)),
        take(1)
      )
      .subscribe(({ companyId, updateMenu }) => {
        if (companyId) {
          this.user.companyId = companyId;
        }

        if (updateMenu > 0) {
          this.drawMenu();
        }
      });

    this.subscribers.enduserSites = this.store.select(fromStore.getAllActiveSites).subscribe((sites) => {
      this.sites = sites;
      this.drawMenu();
    });

    // app style (LOGO only)
    this.subscribers.appStyle = this.store.pipe(select(fromStore.getAppStyle)).subscribe({
      next: (result) => {
        if (result && result.variables) {
          // check default logo
          if (result.variables.site_logo && result.variables.site_logo !== '__default__') {
            this.logoDefault = config().s3Url + '/' + result.variables.site_logo;
          } else {
            this.logoDefault = null;
          }

          // check mobile logo
          if (result.variables.site_logo_mobile && result.variables.site_logo_mobile !== '__default__') {
            this.logoMobile = config().s3Url + '/' + result.variables.site_logo_mobile;
          } else {
            this.logoMobile = null;
          }
        } else {
          // no variables
          this.logoDefault = null;
          this.logoMobile = null;
        }
      },
    });

    // get menu style to display
    this.subscribers.showMenuPanel = this.store.pipe(select(fromStore.getShowMenu)).subscribe({
      next: (result) => {
        this.showMenuPanel = result;
      },
    });

    this.subscribers.overlayMenuActive = this.store.pipe(select(fromStore.getIsOverlayMenuActive)).subscribe({
      next: (result) => {
        this.isOverlayMenuActive = result;
      },
    });

    this.subscribers.minColumns = this.store.pipe(select(fromStore.getGridMinColumns)).subscribe({
      next: (result) => {
        this.currentMinColumns = result;
      },
    });

    this.onResize();
    // this.getMyMenu();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  @HostListener('window:resize') onResize(event?: any) {
    const screenWidth = (event && event.target && event.target.innerWidth) || window.innerWidth;
    // left sidebar
    if (screenWidth < this.menuBreakPoints) {
      if (!this.isOverlayMenuActive) {
        this.store.dispatch(new fromStore.ActivateOverlayMenu());
      }
    } else {
      if (this.isOverlayMenuActive) {
        this.store.dispatch(new fromStore.DeactivateOverlayMenu());
      }
    }

    // grid columns
    let minColumns;
    if (screenWidth < this.gridSmBreakPoints) {
      minColumns = 1;
    } else if (screenWidth < this.gridMdBreakPoints) {
      minColumns = 8;
    } else {
      minColumns = 12;
    }

    if (this.currentMinColumns !== minColumns) {
      this.store.dispatch(new fromStore.SetMinColumns(minColumns));
    }
  }

  private getMyMenu() {
    if (!this.isLoggedIn || !this.allMenu) {
      return;
    }

    const basic: any[] = [];
    const advanced: any[] = [];

    for (const menu of this.allMenu) {
      const permission = menu.permission || menu.id + '_r';
      if (this.authService.allow(permission)) {
        if (menu.isSetting) {
          advanced.push(menu);
        } else {
          basic.push(menu);
        }
      }
    }

    // add setting menu
    if (advanced.length && this.authService.allow('settings_r')) {
      basic.push({
        id: 'settings',
        label: 'SETTINGS',
        icon: 'icon icon-setting',
        children: advanced,
      });
    }

    this.myMenu = basic;
  }

  private drawMenu() {
    const mainRole = this.user && this.user.mainRole && this.user.mainRole.toLowerCase();

    // define all menu in BE
    if (mainRole === 'integrator') {
      this.allMenu = config().integratorMenuItems;
      if (!this.user.companyId) {
        // new user without company (first integrator), hide some menu
        this.allMenu = this.allMenu.filter((item) => item.id === 'integrator_overview' || item.id === 'my_account');
      }
    } else if (mainRole === 'enduser') {
      this.allMenu = JSON.parse(JSON.stringify(config().endUserMenuItems));
      const totalSites = this.sites && this.sites.length;
      if (totalSites === 1) {
        for (const m of this.allMenu) {
          if (m.id === 'enduser_sites') {
            m.path.push(this.sites[0].id + '');
            m.label = 'MY_SITE';
            break;
          }
        }
      }
    } else if (mainRole === 'sc') {
      this.allMenu = config().menuItems;
    }
    this.getMyMenu();
  }

  toggleMenu() {
    if (this.showMenuPanel) {
      this.store.dispatch(new fromStore.HideMenu());
    } else {
      this.store.dispatch(new fromStore.ShowMenu());
    }
  }

  private drawBreadcrumb(path) {
    this.items = [];
    for (let index = 0; index < path.length; index++) {
      if (path[index] !== 'integrator' && path[index] !== '') {
        this.utilsService.textTranslate(path[index].toUpperCase()).subscribe((result) => {
          this.items.push({ label: `${result}` });
        });
      }
    }
  }

  navigate(event: any) {
    // fired when user click on menu (left sidebar)
    let path: any[] = event.path;
    if (!path) {
      path = [event.id];
      if (event.isSetting) {
        path.unshift('settings');
      }
    }
    const route = { path };
    this.store.dispatch(new fromStore.Go(route));
    this.drawBreadcrumb(route.path);
    if (this.isOverlayMenuActive) {
      this.store.dispatch(new fromStore.HideMenu());
    }
  }

  onViewMyAccount() {
    const route = { path: ['my_account'] };
    this.store.dispatch(new fromStore.Go(route));
  }

  logOut() {
    this.store.dispatch(new fromStore.Logout());
  }
}
