import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem, TreeNode } from 'primeng/api';
import { RuleMassEditOptions } from './models/rule-mass-edit-options';
import { MassRule } from './models/rule';
import { RuleTemplate } from '@widgets/rule-templates/models/rule-template';
import { Site } from '@widgets/sites/models/site';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { RuleMassEditService } from './services/rule-mass-edit.service';
import { RuleMassEditFormsService } from './services/rule-mass-edit-forms.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-mass-edit',
  templateUrl: 'rule-mass-edit.component.html',
})
export class RuleMassEditComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isSubmitting: boolean;
  optionsForm: FormGroup;
  loadInitData: boolean;
  ruleForm: FormGroup;
  rules: MassRule[];
  selectItems: { [key: string]: (SelectItem | TreeNode)[] } = {};

  private ruleForms: { [key: string]: FormGroup } = {};
  private firstOkID: number;
  private selectedRuleTemplate: RuleTemplate;
  private selectedRule: MassRule;
  private selectedSite: Site;
  private rawData: { [key: string]: any[] } = {};
  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private ruleMassEditService: RuleMassEditService,
    private ruleMassEditFormsService: RuleMassEditFormsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    // Get Selected Site
    this.subscribers.WATCH_SITE = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;

        clearTimeout(this.timeouts.GET_DATASET);
        this.timeouts.GET_DATASET = setTimeout(() => {
          // Get all necessary data eg. locations, ruleTemplates, ...
          this.retrieveInitialData();
        }, 500);
      }
    });

    // create options form
    this.optionsForm = this.ruleMassEditFormsService.initOptionsForm();
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.selectItems);
    this.sharedService.clearTimeouts(this.timeouts);
  }

  private retrieveInitialData() {
    this.loadInitData = true;
    this.resetInitData();
    const options = { site: this.selectedSite.id };

    this.subscribers.RETRIEVE_INIT_DATA = this.ruleMassEditService.bulkInit(options).subscribe((result: any) => {
      this.rawData = result.data;

      Object.keys(result.data).forEach((key) => {
        if (key === 'locations') {
          const locations = this.sharedService.createTree(result.data[key]);
          this.selectItems[key] = this.sharedService.createMultiSelectItems(locations);
        } else if (key === 'locationProperties') {
          this.selectItems[key] = this.sharedService.createSelectItems(result.data[key], false, 'key');
        } else {
          this.selectItems[key] = this.sharedService.createSelectItems(result.data[key], false);
        }
      });

      this.loadInitData = false;
    });
  }

  private retrieveRules() {
    const options = { ...this.optionsForm.value };
    delete options.action;
    options.locations = options.locations.join(',');

    // get all rules which based on selected ruleTemplate of selected locations
    this.subscribers.RETRIEVE_RULES = this.ruleMassEditService.bulkPrepare(options).subscribe((result: any) => {
      this.rules = result.data;

      for (let i = 0; i < this.rules.length; i++) {
        this.rules[i].startConditions = this.ruleMassEditFormsService.validateConditions(
          this.rules[i].startConditions,
          this.selectedRuleTemplate.startConditions
        );

        this.rules[i].endConditions = this.ruleMassEditFormsService.validateConditions(
          this.rules[i].endConditions,
          this.selectedRuleTemplate.endConditions
        );

        this.rules[i].startCommands = this.ruleMassEditFormsService.validateCommands(
          this.rules[i].startCommands,
          this.selectedRuleTemplate.startCommands
        );

        this.rules[i].endCommands = this.ruleMassEditFormsService.validateCommands(
          this.rules[i].endCommands,
          this.selectedRuleTemplate.endCommands
        );

        const location = this.rawData.locations.find((l) => l.id === this.rules[i].locationId);

        this.rules[i].locationName = location ? location.description : '(empty)';
        this.rules[i].errors = this.ruleMassEditFormsService.ruleHasErrors(this.rules[i]);

        // create rule form
        const ruleForm = this.ruleMassEditFormsService.initRuleForm(this.rules[i], this.selectedRuleTemplate);
        this.ruleForms[this.rules[i].id] = ruleForm;

        // auto select the first OK rule
        if (!this.firstOkID && !this.rules[i].errors) {
          this.firstOkID = this.rules[i].id;
          this.onRuleSelected(this.rules[i]);
        }
      }
    });
  }

  private updateRuleCommands(rule: FormGroup, srcRule: MassRule) {
    // Dynamic get RuleCommands
    const formNames = ['startCommands', 'endCommands'];

    // loop form names
    for (let i = 0; i < formNames.length; i++) {
      // FormArray of RuleCommands
      const commandForms = rule.get(formNames[i]) as FormArray;

      // Loop FormArray
      for (let ii = 0; ii < commandForms.controls.length; ii++) {
        // FormGroup of RuleCommands
        const command = commandForms.controls[ii] as FormGroup;
        const tmpCmd = srcRule[formNames[i]][ii];

        // Update RuleCommands -> locationProperty[AdjustValue|ForceValue]
        if (tmpCmd.locationPropertyAdjustValue) {
          command.patchValue({
            locationPropertyAdjustValue: tmpCmd.locationPropertyAdjustValue,
            locationPropertyForceValue: null,
          });
        } else if (tmpCmd.locationPropertyForceValue) {
          command.patchValue({
            locationPropertyAdjustValue: null,
            locationPropertyForceValue: tmpCmd.locationPropertyForceValue,
          });
        }

        // Update RuleCommands -> parameters
        if (tmpCmd.parameters && tmpCmd.parameters.length) {
          command.get('parameters').setValue(tmpCmd.parameters);
        }
      }
    }
  }

  private updateRuleConditions(rule: FormGroup, srcRule: MassRule) {
    // Dynamic get RuleConditions
    const formNames = ['startConditions', 'endConditions'];

    // loop form names
    for (let i = 0; i < formNames.length; i++) {
      // FormArray of RuleConditions
      const conditionForms = rule.get(formNames[i]) as FormArray;

      // Loop FormArray
      for (let ii = 0; ii < conditionForms.controls.length; ii++) {
        // FormGroup of RuleConditions
        const condition = conditionForms.controls[ii] as FormGroup;
        const tmpCond = srcRule[formNames[i]][ii];

        // Update RuleConditions -> referenced[DeviceId|LocationId|Value]
        if (tmpCond.referencedDeviceId) {
          condition.patchValue({
            referencedDeviceId: tmpCond.referencedDeviceId,
            referencedLocationId: null,
            referencedValue: null,
          });
        } else if (tmpCond.referencedLocationId) {
          condition.patchValue({
            referencedDeviceId: null,
            referencedLocationId: tmpCond.referencedLocationId,
            referencedValue: null,
          });
        } else if (tmpCond.referencedValue) {
          condition.patchValue({
            referencedDeviceId: null,
            referencedLocationId: null,
            referencedValue: tmpCond.referencedValue,
          });
        }
      }
    }
  }

  private resetInitData() {
    this.rawData = {};
    this.ruleForm = null;
    this.ruleForms = {};
    this.rules = [];
    this.firstOkID = null;
    this.selectedRuleTemplate = null;
    this.selectedRule = null;
    this.selectItems = {};

    this.selectItems.actions = [
      { label: 'Add', value: 'add' },
      { label: 'Delete', value: 'delete' },
      { label: 'Disable', value: 'disable' },
      { label: 'Edit', value: 'edit' },
      { label: 'Enable', value: 'enable' },
    ];
  }

  onOptionsChange(event: RuleMassEditOptions) {
    // Set selected Rule Template
    if (event.ruleTemplate) {
      this.selectedRuleTemplate = this.rawData.ruleTemplates.find((r) => r.id === event.ruleTemplate);
    }

    this.retrieveRules();
  }

  onRuleSelected(event: MassRule) {
    if (this.selectedRule && this.selectedRule.id === event.id) {
      return;
    }

    this.selectedRule = event;
    this.ruleForm = null;

    clearTimeout(this.timeouts.CHANGE_RULE_DELAY);
    this.timeouts.CHANGE_RULE_DELAY = setTimeout(() => {
      this.ruleForm = this.ruleForms[event.id];
    }, 100);
  }

  onApplyAllChanged(event: MassRule) {
    for (let i = 0; i < this.rules.length; i++) {
      if (this.rules[i].errors) {
        // skipped, rule is not match the template
        continue;
      } else if (this.rules[i].id === event.id) {
        // skipped, self
        continue;
      }

      // update commands, conditions
      this.updateRuleCommands(this.ruleForms[this.rules[i].id], event);
      this.updateRuleConditions(this.ruleForms[this.rules[i].id], event);
    }
  }

  get dataForCmdAndCondForm() {
    return {
      isFirstOK: this.firstOkID === this.selectedRule.id ? true : false,
      selectedRuleTemplate: this.selectedRuleTemplate,
      selectedRule: this.selectedRule,
      raw: this.rawData,
      selectItems: {
        ...this.selectItems,
        booleanOption: this.sharedService.selectItems.booleanOption,
      },
    };
  }

  submit() {
    this.isSubmitting = true;

    const rules = [];

    for (let i = 0; i < this.rules.length; i++) {
      if (this.rules[i].errors) {
        continue;
      }

      rules.push(this.ruleForms[this.rules[i].id].value);
    }

    // console.log(JSON.stringify({ rules }));

    // TODO: call the api
  }
}
