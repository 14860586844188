import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CeosDeviceTriggerFormComponent } from '@widgets/devices/ceos-device-trigger-form/ceos-device-trigger-form.component';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-ceos-device-properties-cell',
  templateUrl: 'ceos-device-properties-cell.component.html',
  styleUrls: ['ceos-device-properties-cell.component.scss'],
})
export class CeosDevicePropertiesCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;

  containerComponent: any;
  data: any;
  virtualDevices: any[];

  private subscribers: { [key: string]: any } = {};
  private triggerForm: DynamicDialogRef;

  constructor(private dialogService: DialogService, private sharedService: SharedService) {}

  agInit(params: any): void {
    this.onDataChanges(params);
  }

  refresh(params: any): boolean {
    this.onDataChanges(params);
    return true;
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private onDataChanges(params: any) {
    this.cell = params;
    if (this.cell) {
      this.data = this.cell.data;
      this.virtualDevices = this.cell.value;
      this.containerComponent = this.cell.context.containerComponent;
    }
  }

  getTooltipText(virtualDevice) {
    let text = virtualDevice.deviceTypeName || virtualDevice.name;
    if (virtualDevice.value && virtualDevice.valueAt) {
      text += ',\n ' + virtualDevice.valueAt;
    } else {
      text += ',\n no data yet';
    }
    return text;
  }

  openTriggerForm(virtualDevice) {
    this.containerComponent.stopAutoUpdate();

    const deviceType = this.containerComponent.ceosDeviceTypeEntities[virtualDevice.deviceTypeKey];
    this.triggerForm = this.dialogService.open(CeosDeviceTriggerFormComponent, {
      header: this.data.description + ' - ' + virtualDevice.description,
      data: {
        deviceType,
        selectedVD: virtualDevice,
      },
    });

    this.subscribers.triggerFormDestroy = this.triggerForm.onDestroy.subscribe(() => {
      this.containerComponent.startAutoUpdate();
    });
  }
}
