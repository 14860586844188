import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SharedService } from '@shared/shared.service';
import { LiveRequestsService } from '@widgets/live-requests/services/live-requests.service';
import { DeviceHistoryLogsService } from '../services/device-history-logs.service';

import { config } from '@app/config';

@Component({
  selector: 'sc-ceos-device-trigger-form',
  templateUrl: 'ceos-device-trigger-form.component.html',
})
export class CeosDeviceTriggerFormComponent implements OnInit, OnDestroy {
  errorMessage: string;
  isFetchDeviceHistory: boolean;
  isSubmitting: boolean;
  isSubmitSuccess: boolean;
  commandAcceptedAt: any;
  device: any;
  deviceType: any;
  render: any;
  actions: SelectItem[] = [];
  latestDHLs: any[] = [];
  triggerValue = new FormControl(null, Validators.required);
  chart: any;
  getLiveDataCount: number;
  latestValue: any;

  private intervals: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private sharedService: SharedService,
    private liveRequestsService: LiveRequestsService
  ) {}

  ngOnInit() {
    // console.log(this.config.data);
    if (this.config && this.config.data) {
      if (this.config.data.selectedVD) {
        this.device = this.config.data.selectedVD;
      }

      if (this.config.data.deviceType) {
        this.deviceType = this.config.data.deviceType;

        // get action select items
        if (this.deviceType && this.deviceType.actions) {
          this.actions = Object.keys(this.deviceType.actions).map((key) => ({
            label: this.deviceType.actions[key].label,
            value: key,
          }));

          this.render = this.deviceType.render;
        }
      }

      this.autoUpdateHistoryLogs();
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  private fetchLatestDeviceHistoryLogs(limit = 30, force = false) {
    if (this.isFetchDeviceHistory === true && force !== true) {
      return;
    }

    this.isFetchDeviceHistory = true;

    // dhl options
    const dhlOptions = {
      deviceId: this.device.idx,
      fields: 'createdAt,value,triggeredBy',
      // filter: { deviceIdx: this.device.idx },
      limit: limit,
    };

    // fetch dhl data
    this.subscribers.fetchDHL = this.deviceHistoryLogsService.getHistoryLogs(dhlOptions).subscribe((result: any) => {
      this.latestDHLs = result.documents;
      this.formatDHLValue();

      this.latestValue = this.latestDHLs[0];

      // console.log(this.latestDHLs);
      this.isFetchDeviceHistory = false;
      this.createChart();
    });
  }

  deviceTrigger(isAction?: boolean) {
    if (this.triggerValue.invalid) {
      return;
    }

    // console.log('isAction:', isAction);
    // console.log('triggerValue:', this.triggerValue.value);

    let action;
    let value;

    if (isAction) {
      action = this.triggerValue.value;
    } else {
      action = this.actions[0].value;
      value = this.triggerValue.value;
    }

    const options: any = {
      action: 'triggerDeviceCommandAction',
      apiKey: this.device.automationApiKey,
      automationId: this.device.automationId,
      commandAction: action,
      deviceIdx: this.device.idx,
      // parameters: [],
    };

    if (value) {
      options.parameters = [{ key: 'param1', value }];
    }

    // if (this.formData.deviceTypeKey === 'a' && formValue.commandAction.commandActionId === 'config_update') {
    //   options.action = 'pullConfig';
    //   delete options.commandAction;
    //   delete options.parameters;
    // }
    this.sendLiveRequest(options);
  }

  private autoUpdateHistoryLogs() {
    this.fetchLatestDeviceHistoryLogs();
    this.intervals.autoUpdateHistoryLogs = setInterval(() => {
      this.fetchLatestDeviceHistoryLogs();
    }, 10000);
  }

  private sendLiveRequest(options) {
    // console.log(options);

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;
    this.isSubmitSuccess = false;
    this.commandAcceptedAt = null;

    this.subscribers.triggeringCommand = this.liveRequestsService.sendLiveRequest(options).subscribe(
      (result: any) => {
        // this.commandAcceptedAt = this.sharedService.dateFormat(new Date());
        // this.isSubmitSuccess = true;
        // this.isSubmitting = false;

        this.getLiveDataCount = 0;
        this.intervals.getLiveData = setInterval(() => {
          this.getLiveResult(result.data.sessionId);
        }, 2000);
      },
      (error: any) => {
        // display error message and unlock the form
        this.errorMessage = 'send request failed';
        this.isSubmitting = false;
        this.isSubmitSuccess = false;
      }
    );
  }

  private getLiveResult(sessionId: string) {
    this.subscribers.getLiveData = this.liveRequestsService.getLiveRequestResult(sessionId).subscribe((result: any) => {
      // console.log(result);
      if (result && result.data && (result.data.error || result.data.value)) {
        // get result or error
        if (this.intervals.getLiveData) {
          clearInterval(this.intervals.getLiveData);
          this.intervals.getLiveData = null;
        }

        this.isSubmitting = false;

        if (result.data.error) {
          this.errorMessage = result.data.error;
        } else {
          this.commandAcceptedAt = this.sharedService.dateFormat(new Date());
          this.isSubmitSuccess = true;
        }
      } else {
        this.getLiveDataCount += 1;

        if (this.getLiveDataCount >= 15) {
          // stop get live result because no reponse for too long
          if (this.intervals.getLiveData) {
            clearInterval(this.intervals.getLiveData);
            this.intervals.getLiveData = null;
          }

          this.errorMessage = 'timeout';
          this.isSubmitting = false;
        }
      }
    });
  }

  private getYTick(): { tickvals: number[]; ticktext: string[] } {
    switch (this.device.deviceTypeKey) {
      case 'acf':
        return {
          tickvals: [0, 1, 2, 3],
          ticktext: ['Auto', 'Low', 'Medium', 'High'],
        };

      case 'acm':
        return {
          tickvals: [0, 1, 2, 3],
          ticktext: ['OFF', 'Cooling', 'Heating', 'Ventilating'],
        };

      case 'lc':
        return {
          tickvals: [0, 1],
          ticktext: ['OFF', 'ON'],
        };

      case 'ms':
        return {
          tickvals: [0, 1],
          ticktext: ['NO', 'YES'],
        };

      case 'os':
      case 'osd':
      case 'osw':
        return {
          tickvals: [0, 1],
          ticktext: ['Closed', 'Open'],
        };

      default:
        return;
    }
  }

  private getYTitle() {
    let title;
    switch (this.device.deviceTypeKey) {
      case 'acf':
        title = 'AC_FANSPEED';
        break;

      case 'acm':
        title = 'AC_MODE';
        break;

      case 'acsp':
        title = 'AC_SETPOINT';
        break;

      case 'bl':
        title = 'BATTERY_LEVEL';
        break;

      case 'bs':
        title = 'BRIGHTNESS';
        break;

      case 'hs':
        title = 'HUMIDITY';
        break;

      case 'lc':
        title = 'LIGHTING_CIRCUIT';
        break;

      case 'ms':
        title = 'MOTION';
        break;

      case 'os':
        title = 'OPENING_SENSOR';
        break;

      case 'osd':
        title = 'DOOR';
        break;

      case 'osw':
        title = 'WINDOW';
        break;

      case 'ts':
        title = 'TEMPERATURE';
        break;

      case 'ws':
        title = 'WATTAGE';
        break;

      default:
        title = 'VALUE';
        break;
    }
    return title ? this.sharedService.getTranslation(title) : '';
  }

  private valueUnit() {
    switch (this.device.deviceTypeKey) {
      case 'bl':
      case 'hs':
        return '%';

      case 'bs':
        return 'lux';

      case 'ts':
        return '°C';

      case 'ws':
        return 'w/h';

      default:
        return '';
    }
  }

  private formatDHLValue() {
    for (const dhl of this.latestDHLs) {
      if (this.deviceType && this.deviceType.render) {
        if (
          this.deviceType.render.type === 'select' &&
          this.deviceType.render.options &&
          this.deviceType.render.options.length
        ) {
          const opt = this.deviceType.render.options.find((item) => item.value === dhl.value);
          dhl.formattedValue = opt ? opt.label : dhl.value;
        } else if (this.deviceType.render.type === 'numeric') {
          dhl.formattedValue = this.sharedService.numberFormat(dhl.value);
        } else {
          // dhl.formattedValue = dhl.value;
        }
        dhl.value = dhl.value;
        dhl.unit = this.deviceType.render.unit;
      }
      dhl.formattedCreatedAt = this.sharedService.dateFormat(dhl.createdAt);
      dhl.humanReadableCreatedAt = this.sharedService.dateFormat(dhl.createdAt, 'fromNow');
    }
  }

  private chartValueFormatter(value: any) {
    switch (this.device.deviceTypeKey) {
      case 'acf':
        if (value === 'h') {
          return 3;
        } else if (value === 'm') {
          return 2;
        } else if (value === 'l') {
          return 1;
        }
        return 0;

      case 'acm':
        if (value === 'v') {
          return 3;
        } else if (value === 'h') {
          return 2;
        } else if (value === 'c') {
          return 1;
        } else if (value === 'o') {
          return 0;
        }

      case 'lc':
      case 'ms':
        return value === 'n' ? 0 : 1;

      case 'os':
      case 'osd':
      case 'osw':
        return value === 'c' ? 0 : 1;

      default:
        return value;
    }
  }

  private isNumberData() {
    const types = config().deviceTypeKeyHasNumberValue;
    if (types.indexOf(this.device.deviceTypeKey) >= 0) {
      return true;
    }
    return false;
  }

  private createChart() {
    if (!this.latestDHLs) {
      return;
    }

    const yTitle = this.getYTitle();
    const unit = this.valueUnit();
    const rawData = [...this.latestDHLs].reverse();

    const layout: any = {
      xaxis: {
        rangeslider: {
          borderwidth: 1,
          range: [],
        },
        showgrid: false,
        showline: false,
        title: 'Date/Time',
        type: 'date',
      },
      yaxis: {
        rangemode: 'nonnegative',
        showline: false,
        type: 'linear',
      },
      title: unit ? yTitle + ' (' + unit + ')' : yTitle,
    };

    const trace1: any = {
      name: yTitle,
      x: [],
      y: [],
    };

    // layout and style per data type
    if (this.isNumberData()) {
      layout.yaxis.tickformat = '.1f';
      layout.yaxis.ticksuffix = unit;
    } else {
      const ytick = this.getYTick();
      if (ytick) {
        layout.yaxis.tickmode = 'array';
        layout.yaxis.tickvals = ytick.tickvals;
        layout.yaxis.ticktext = ytick.ticktext;
      }
    }

    // if (this.chartType === 'line') {
    trace1.type = 'scatter';
    trace1.mode = 'lines';
    if (!this.isNumberData()) {
      // plot line in step
      trace1.line = { shape: 'hv' };
    }
    // } else if (this.chartType === 'bar') {
    //   trace1.type = 'bar';
    //   layout.barmode = 'group';
    //   layout.bargap = 0.15;
    //   layout.bargroupgap = 0.1;
    // }

    for (let i = 0; i < rawData.length; i++) {
      const item = rawData[i];
      const date = this.sharedService.dateFormat(item.createdAt, 'YYYY-MM-DD HH:mm:ss');
      let value = item.value;
      value = isNaN(value) ? value : this.sharedService.numberFormat(value);
      value = this.chartValueFormatter(value);
      trace1.x.push(date);
      trace1.y.push(value);
    }

    this.chart = {
      data: [trace1],
      layout: layout,
    };
  }
}
