import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { GuestRequestCategoryActionCellComponent } from './guest-request-category-action-cell/guest-request-category-action-cell.component';
import { GuestRequestCategoryFormComponent } from './guest-request-category-form/guest-request-category-form.component';
import { MultiLanguageFormComponent } from './guest-request-category-form/multi-language-form/multi-language-form.component';
import { GuestRequestCategoriesTableComponent } from './guest-request-categories-table/guest-request-categories-table.component';

import { GuestRequestCategoriesService } from './guest-request-categories.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    GuestRequestCategoryActionCellComponent,
    GuestRequestCategoryFormComponent,
    GuestRequestCategoriesTableComponent,
    MultiLanguageFormComponent
  ],
  entryComponents: [
    GuestRequestCategoryActionCellComponent,
    GuestRequestCategoryFormComponent,
    GuestRequestCategoriesTableComponent
  ],
  providers: [GuestRequestCategoriesService]
})
export class GuestRequestCategoriesModule {}
