<p-sidebar
  [(visible)]="show"
  position="right"
  appendTo="body"
  blockScroll="true"
  (onHide)="cancalled.emit()"
  [showCloseIcon]="false"
>
  <div class="page-widget-form">
    <div class="page-widget-form__header">
      <div class="page-widget-form__header__content">
        <div class="page-widget-form__header__title">
          {{ "PAGE_WIDGET_FORM_TITLE" | translate }}
        </div>
      </div>

      <div class="page-widget-form__header__right">
        <button type="button" class="close-button" (click)="cancalled.emit()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>

    <div class="page-widget-form__body">
      <p-accordion [activeIndex]="0" *ngIf="categoriedWidgets?.length">
        <p-accordionTab *ngFor="let category of categoriedWidgets">
          <p-header>
            {{ category?.name | uppercase }}
          </p-header>

          <ul class="widget-list">
            <li
              *ngFor="let widget of category?.widgets"
              class="widget-list-item"
              [ngClass]="itemStyle(widget?.id)"
              (click)="addRemoveWidget(widget)"
            >
              <div class="widget-list-item__left">
                <img
                  *ngIf="widget?.icon"
                  src="{{ iconUrlPrefix + widget?.icon }}"
                  width="30"
                />
              </div>

              <div class="widget-list-item__content">
                <div class="widget-list-item__name">
                  {{ widget?.name }}
                </div>
                <div class="widget-list-item__description">
                  {{ widget?.description }}
                  &nbsp;
                </div>
              </div>

              <div class="widget-list-item__right">
                <div class="widget-list-item__status">
                  <i class="fa fa-fw fa-check-circle"></i>
                </div>
              </div>
            </li>
          </ul>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</p-sidebar>
