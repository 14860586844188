import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { ColDef } from 'ag-grid';
import { RuleTemplate } from '../models/rule-template';
import { ModalConfig } from '@sc/modal/modal-config';

import { CeosRuleTemplatesService } from '../services/ceos-rule-templates.service';
import { SharedService } from '@shared/shared.service';

import { CeosRuleTemplateActionCellComponent } from '../ceos-rule-template-action-cell/ceos-rule-template-action-cell.component';
import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-ceos-rule-templates-table',
  templateUrl: 'ceos-rule-templates-table.component.html',
})
export class CeosRuleTemplatesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: RuleTemplate[];
  formConfig: ModalConfig;
  tableTitle: string;

  private subscribes: { [key: string]: any } = {};

  constructor(private ceosRuleTemplatesService: CeosRuleTemplatesService, private sharedService: SharedService) {}

  ngOnInit() {
    // Set Table Name
    this.tableTitle = 'RULE_TEMPLATES';
    // Set Table Columns
    this.columns = this.createColumns();

    this.formConfig = {
      name: 'CeosRuleTemplateForm',
      options: { modalTitle: 'RULE_TEMPLATE', fullScreen: true },
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribes);
  }

  fetchDataset() {
    this.subscribes.getDataset = this.ceosRuleTemplatesService.getCeosRuleTemplates().subscribe((result: any) => {
      // console.log(result);
      this.dataset = result.data;
    });
  }

  private createColumns(): ColDef[] {
    return [
      {
        colId: 'name',
        headerName: 'Name',
        field: 'name',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'idx',
        headerName: 'IDX',
        field: 'idx',
      },
      {
        colId: 'isActive',
        headerName: 'Active',
        field: 'is_active',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'Deleted',
        field: 'is_deleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'Action',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: CeosRuleTemplateActionCellComponent,
      },
    ];
  }
}
