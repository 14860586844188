import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActionMenuItem } from '../../models/action-menu-item';
import { ActionMenuService } from '../../services/action-menu.service';

@Component({
  selector: 'sc-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements OnInit {
  @Input()
  menu: ActionMenuItem[];

  @Output()
  selected = new EventEmitter<any>();

  constructor(private actionMenuService: ActionMenuService) {}

  ngOnInit(): void {}

  openMenu() {
    if (!this.menu || !this.menu.length) {
      return;
    }

    const actionMenu = this.actionMenuService.open(this.menu);
    actionMenu.onClose.subscribe((data) => {
      this.selected.emit(data);
    });
  }
}
