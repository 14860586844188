import { Action } from '@ngrx/store';

import { WidgetCategory } from '@widgets/widget-categories/models/widget-category';

// LOAD WIDGETS
export const LOAD_WIDGET_CATEGORIES = '[Widget Categories] Load Widget Categories';
export const LOAD_WIDGET_CATEGORIES_FAIL = '[Widget Categories] Load Widget Categories Fail';
export const LOAD_WIDGET_CATEGORIES_SUCCESS = '[Widget Categories] Load Widget Categories Success';

export class LoadWidgetCategories implements Action {
  readonly type = LOAD_WIDGET_CATEGORIES;
}

export class LoadWidgetCategoriesFail implements Action {
  readonly type = LOAD_WIDGET_CATEGORIES_FAIL;
  constructor(public payload: any) {}
}

export class LoadWidgetCategoriesSuccess implements Action {
  readonly type = LOAD_WIDGET_CATEGORIES_SUCCESS;
  constructor(public payload: WidgetCategory[]) {}
}

// ACTION TYPES
export type WidgetCategoriesAction = LoadWidgetCategories | LoadWidgetCategoriesFail | LoadWidgetCategoriesSuccess;
