import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItem, SelectItems, CustomFunctionParameterTypes } from '../../models';

@Component({
  selector: 'sc-function-variable-item',
  templateUrl: './function-variable-item.component.html',
  styleUrls: ['./function-variable-item.component.scss'],
})
export class FunctionVariableItemComponent implements OnInit {
  @Input()
  control: FormGroup;

  @Input()
  key: number | string;

  @Input()
  dataSources: DataSources = {};

  @Input()
  isTemplate: boolean;

  @Input()
  selectItems: SelectItems = {};

  _customFunctionParameterTypes = CustomFunctionParameterTypes;
  customGlobalLabel: string = '';
  deviceSelectItems: any[] = [];
  locationPropertyLabel: string = '';
  deviceTypeLabel: string = '';

  constructor() {}

  ngOnInit(): void {
    const formValue = this.control.getRawValue();

    if (
      formValue.type === CustomFunctionParameterTypes.CustomGlobalValue &&
      formValue.custom_global_key &&
      this.dataSources &&
      this.dataSources.customGlobals &&
      this.dataSources.customGlobals.length
    ) {
      const cg = this.dataSources.customGlobals.find((item) => item.key === formValue.custom_global_key);

      if (cg) {
        this.customGlobalLabel = cg.label;
      }
    } else if (
      formValue.type === CustomFunctionParameterTypes.LatestDhlValue &&
      formValue.device_type &&
      this.dataSources
    ) {
      if (this.isTemplate) {
        if (this.dataSources.deviceTypes && this.dataSources.deviceTypes.length) {
          const type = this.dataSources.deviceTypes.find((item) => item.key === formValue.device_type);
          if (type) {
            this.deviceTypeLabel = type.label;
          }
        }
      } else {
        if (this.dataSources.devices && this.dataSources.devices.length) {
          this.updateDeviceSelectItems(formValue.device_type);
          // const filteredDevices = this.dataSources.devices.filter((item) => item.device_type === formValue.device_type);
          // if (filteredDevices && filteredDevices.length) {
          //   this.deviceSelectItems = filteredDevices.map((item) => ({ label: item.name, value: item.idx }));
          // }
        }
      }
    } else if (
      formValue.type === CustomFunctionParameterTypes.LatestLhlValue &&
      formValue.property &&
      this.dataSources &&
      this.dataSources.locationProperties &&
      this.dataSources.locationProperties.length
    ) {
      const prop = this.dataSources.locationProperties.find((item) => item.key === formValue.property);
      if (prop) {
        this.locationPropertyLabel = prop.label;
      }
    }
  }

  private updateDeviceSelectItems(deviceTypeKey: string) {
    this.deviceSelectItems = [];

    if (this.dataSources && this.dataSources.devicesTree && this.dataSources.devicesTree.length) {
      for (const dev of this.dataSources.devicesTree) {
        if (!dev.children || !dev.children.length) {
          continue;
        }

        const children = dev.children.filter((item) => item.data.device_type === deviceTypeKey);
        if (children && children.length) {
          this.deviceSelectItems.push({ ...dev, children, selectable: false });
        }
      }
    }
  }
}
