import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as appStyleAction from '../actions/app-style.action';
import * as initialDataActions from '../actions/initial.action';
import * as pagesAction from '../actions/pages.action';
import * as pageTemplatesAction from '../actions/page-templates.action';
import * as widgetCategoriesAction from '../actions/widget-categories.action';
import * as widgetsAction from '../actions/widgets.action';
import * as localesAction from '../actions/locales.action';
import * as companiesAction from '../actions/companies.action';
import * as sitesAction from '../actions/sites.action';
import * as siteTypeActions from '../actions/site-type.actions';

import { SharedService } from '@shared/shared.service';

@Injectable()
export class InitialDataEffects {
  constructor(private action$: Actions, private sharedService: SharedService) {}

  @Effect()
  loadInitialData$ = this.action$.pipe(
    ofType(initialDataActions.LOAD_INITIAL_DATA),
    switchMap(() => {
      return this.sharedService.getInitialData().pipe(
        map((result: any) => new initialDataActions.LoadInitalDataSuccess(result.data)),
        catchError((error) => of(new initialDataActions.LoadInitalDataFail(error)))
      );
    })
  );

  @Effect()
  loadInitialDataSuccess$ = this.action$.pipe(
    ofType(initialDataActions.LOAD_INITIAL_DATA_SUCCESS),
    switchMap((action: any) => {
      const payload = action.payload;
      const actions: any = [
        new appStyleAction.SetAppStyle(payload.appStyle.style),
        new companiesAction.LoadCompaniesSuccess(payload.companies),
        new localesAction.LoadLocalesSuccess(payload.locales),
        new pageTemplatesAction.LoadPageTemplatesSuccess(payload.pageTemplates),
        new pagesAction.LoadPagesSuccess(payload.pages),
        new sitesAction.LoadSitesSuccess(payload.sites),
        new widgetCategoriesAction.LoadWidgetCategoriesSuccess(payload.widgetCategories),
        new widgetsAction.LoadWidgetsSuccess(payload.widgets),
        siteTypeActions.addSiteTypes({ siteTypes: payload.siteTypes }),
      ];

      if (this.sharedService.userMainRole === 'sc') {
        const selectedSiteId = this.sharedService.selectedSite && this.sharedService.selectedSite.id;
        if (selectedSiteId) {
          actions.push(new sitesAction.SelectSite(selectedSiteId));
        } else if (payload.sites && payload.sites.length && !selectedSiteId) {
          const site = payload.sites.find((item) => item.isActive && !item.isDeleted);
          if (site) {
            actions.push(new sitesAction.SelectSite(site.id));
          }
        }
      }

      return actions;
    })
  );
}
