import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AppActionCellComponent } from './app-action-cell/app-action-cell.component';
import { AppFormComponent } from './app-form/app-form.component';
import { AppsTableComponent } from './apps-table/apps-table.component';

import { AppFormsService } from './services/app-forms.service';
import { AppsService } from './services/apps.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [AppActionCellComponent, AppFormComponent, AppsTableComponent],
  entryComponents: [
    AppActionCellComponent,
    AppFormComponent,
    AppsTableComponent
  ],
  providers: [AppFormsService, AppsService]
})
export class AppsModule {}
