import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { Location } from '@widgets/locations/models/location';

@Component({
  selector: 'sc-units-creator',
  templateUrl: './units-creator.component.html',
  styleUrls: ['./units-creator.component.scss'],
})
export class UnitsCreatorComponent implements OnInit {
  floors: Location[] = [];
  units: any[] = [];
  private isFetchingLocations: boolean;
  private subscribers: { [key: string]: any } = {};

  @Output() onSubmit: EventEmitter<Location[]> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  constructor(private locationsService: LocationsService, private sharedService: SharedService) {}

  ngOnInit() {
    this.fetchDataset();
  }

  private fetchDataset() {
    const selectedSite = this.sharedService.selectedSite;
    if (this.isFetchingLocations || !selectedSite) {
      return;
    }

    this.isFetchingLocations = true;

    const options = {
      siteId: selectedSite.id,
      columns: ['id', 'idx', 'locationTypeId', 'maxPeople', 'isActive', 'isDeleted'].join(),
      tree: true,
      minimize: true,
    };

    this.subscribers.fetchDataset = this.locationsService.getLocations(options).subscribe((result: any) => {
      if (result.mappingKeys) {
        this.floors = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);
      } else {
        this.floors = result.data;
      }
      this.floors = this.floors.filter((location) => location.isActive && !location.isDeleted);
      this.isFetchingLocations = false;
    });
  }

  addUnit(unit: any): void {
    this.units.push(unit);
  }

  removeUnit(unit: any): void {
    const index = this.findUnitIndex(unit);
    if (index !== -1) {
      this.units.splice(index, 1);
    }
  }

  updateUnit(unit: any) {
    const index = this.findUnitIndex(unit);
    if (index !== -1) {
      this.units[index].description = unit.description;
    }
  }

  submit(): void {
    const units: Location[] = [];
    this.units.map((unit) =>
      units.push({
        description: unit.description,
        locationTypeId: unit.locationTypeId,
        parentId: unit.parentId,
        parentIdx: unit.parentIdx,
        isActive: unit.isActive,
        isDeleted: unit.isDeleted,
        floorNumber: unit.floorNumber,
        siteId: this.sharedService.selectedSite.id,
      })
    );
    this.onSubmit.emit(units);
  }

  cancel() {
    this.onCancel.emit();
  }

  private findUnitIndex(unit: any) {
    return this.units.findIndex((item) => item.key === unit.key);
  }
}
