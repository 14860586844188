<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!--Header-->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>

          <div class="ui-g-6 text-right">
            <p-toggleButton
              formControlName="isActive"
              offIcon="fa fa-square-o"
              offLabel="{{ 'ACTIVE' | translate }}"
              onIcon="fa fa-check-square-o"
              onLabel="{{ 'ACTIVE' | translate }}"
            ></p-toggleButton>
          </div>
        </div>
      </p-header>

      <!--Body-->
      <div class="ui-g">
        <!-- NAME FIELD -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- DESCRIPTION FIELD -->
        <div class="ui-g-12">
          <sc-textarea
            controlName="description"
            [form]="form"
            label="{{ 'DESCRIPTION' | translate }}"
          ></sc-textarea>
        </div>

        <!-- PURPOSE_ENERGY_SAVING FIELD -->
        <div class="ui-g-12">
          <sc-checkbox-input
            controlName="purposeEnergySaving"
            [form]="form"
            label="{{ 'PURPOSE_ENERGY_SAVING' | translate }}"
          ></sc-checkbox-input>
        </div>

        <div class="ui-g-12 ui-g-nopad">
          <!-- EXCLUSIVE_SITES FIELD -->
          <div class="ui-g-6">
            <sc-dropdown
              controlName="siteIds"
              [form]="form"
              label="{{ 'EXCLUSIVE_SITES' | translate }}"
              [options]="selectItems?.sites"
              [multiSelect]="true"
            ></sc-dropdown>
          </div>

          <div class="ui-g-6">
            <!-- IS_PUBLIC FIELD -->
            <div class="mt-3">
              <sc-checkbox-input
                controlName="isPublic"
                [form]="form"
                label="{{ 'IS_PUBLIC' | translate }}"
              ></sc-checkbox-input>
            </div>
          </div>
        </div>

        <div class="ui-g-12">
          <button
            pButton
            type="button"
            class="ui-button-info"
            label="{{ 'START' | translate }}"
            (click)="showCommandAndConditionForm('start')"
            [disabled]="currentCommandAndConditionForm === 'start'"
          ></button>

          <button
            pButton
            type="button"
            class="ui-button-info"
            label="{{ 'END' | translate }}"
            (click)="showCommandAndConditionForm('end')"
            [disabled]="currentCommandAndConditionForm === 'end'"
          ></button>
        </div>

        <!-- EXECUTION -->
        <div
          [ngSwitch]="currentCommandAndConditionForm"
          class="ui-g-12 ui-g-nopad"
        >
          <div *ngSwitchCase="'end'">
            <div class="ui-g-12 ui-g-nopad">
              <div class="ui-g-4">
                <sc-number-input
                  controlName="endMinExecutionTime"
                  [form]="form"
                  label="{{ 'END_MIN_EXECUTION_TIME' | translate }}"
                  [min]="0"
                ></sc-number-input>
              </div>

              <div class="ui-g-4">
                <sc-number-input
                  controlName="endMaxExecutionTime"
                  [form]="form"
                  label="{{ 'END_MAX_EXECUTION_TIME' | translate }}"
                  [min]="0"
                ></sc-number-input>
              </div>

              <div class="ui-g-4">
                <sc-number-input
                  controlName="endBlockingAfterExecutionTime"
                  [form]="form"
                  label="{{ 'END_BLOCKING_AFTER_EXECUTION_TIME' | translate }}"
                  [min]="0"
                ></sc-number-input>
              </div>

              <div class="ui-g-4 my-3">
                <sc-checkbox-input
                  controlName="endAllowRepetition"
                  [form]="form"
                  label="{{ 'RULE_END_ALLOW_REPETITION' | translate }}"
                ></sc-checkbox-input>
              </div>
            </div>

            <div class="ui-g-12 ui-g-nopad">
              <div class="ui-g-4">
                <sc-dropdown
                  controlName="energySavingPatternsEnd"
                  [form]="form"
                  label="{{ 'ENERGY_SAVING_PATTERNS' | translate }}"
                  [options]="selectItems?.energySavingPatterns"
                  [multiSelect]="true"
                ></sc-dropdown>
              </div>
            </div>
          </div>

          <div *ngSwitchDefault>
            <div class="ui-g-12 ui-g-nopad">
              <div class="ui-g-4">
                <sc-number-input
                  controlName="startMinExecutionTime"
                  [form]="form"
                  label="{{ 'START_MIN_EXECUTION_TIME' | translate }}"
                  [min]="0"
                ></sc-number-input>
              </div>

              <div class="ui-g-4">
                <sc-number-input
                  controlName="startMaxExecutionTime"
                  [form]="form"
                  label="{{ 'START_MAX_EXECUTION_TIME' | translate }}"
                  [min]="0"
                ></sc-number-input>
              </div>

              <div class="ui-g-4">
                <sc-number-input
                  controlName="startBlockingAfterExecutionTime"
                  [form]="form"
                  label="{{
                    'START_BLOCKING_AFTER_EXECUTION_TIME' | translate
                  }}"
                  [min]="0"
                ></sc-number-input>
              </div>

              <div class="ui-g-4 my-3">
                <sc-checkbox-input
                  controlName="startAllowRepetition"
                  [form]="form"
                  label="{{ 'RULE_START_ALLOW_REPETITION' | translate }}"
                ></sc-checkbox-input>
              </div>
            </div>

            <div class="ui-g-12 ui-g-nopad">
              <div class="ui-g-4">
                <sc-dropdown
                  controlName="energySavingPatternsStart"
                  [form]="form"
                  label="{{ 'ENERGY_SAVING_PATTERNS' | translate }}"
                  [options]="selectItems?.energySavingPatterns"
                  [multiSelect]="true"
                ></sc-dropdown>
              </div>
            </div>
          </div>
        </div>
        <!-- /EXECUTION -->

        <!-- RULE_TEMPLATE_CONDITIONS FORM -->
        <div class="ui-g-12 ui-g-nopad mt-5">
          <div class="ui-g-10">
            <h2 class="m-0">
              {{ 'RULE_TEMPLATE_CONDITIONS' | translate }} ({{
                currentCommandAndConditionForm
              }})
            </h2>
          </div>

          <div class="ui-g-2 text-right">
            <button
              pButton
              type="button"
              class="ui-button-success"
              (click)="addCondition()"
              icon="fa fa-plus"
              pTooltip="{{ 'ADD' | translate }} {{ 'CONDITION' | translate }}"
            ></button>
          </div>
        </div>

        <!-- GLOBAL CONNECTOR -->
        <div [ngSwitch]="currentCommandAndConditionForm" class="ui-g-12">
          <div *ngSwitchCase="'end'">
            <sc-radio-input
              controlName="endConditionConnector"
              [form]="form"
              label="{{ 'CONNECTOR' | translate }}"
              [options]="selectItems?.connectors"
            ></sc-radio-input>
          </div>

          <div *ngSwitchDefault>
            <sc-radio-input
              controlName="startConditionConnector"
              [form]="form"
              label="{{ 'CONNECTOR' | translate }}"
              [options]="selectItems?.connectors"
            ></sc-radio-input>
          </div>
        </div>

        <div class="ui-g-12 ui-g-nopad grey lighten-2">
          <!-- <div class="ui-g-12 p-0"> -->
          <div class="ui-g-1">
            {{ 'CONNECTOR' | translate }}
          </div>

          <div class="ui-g-3">
            {{ 'SOURCE' | translate }}
          </div>

          <div class="ui-g-1">
            {{ 'OPERATOR' | translate }}
          </div>

          <div class="ui-g-3">
            {{ 'REFERENCED' | translate }}
          </div>

          <div class="ui-g-4 ui-g-nopad">
            <div class="ui-g-4">
              {{ 'PARAMETERS' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'WAS_TRUE_ONE_TIME_IN_PAST' | translate }}
            </div>
            <!-- <div class="ui-g-3">
                {{ 'EVENT' | translate }}
              </div> -->
            <div class="ui-g-3">
              {{ 'VALIDITY_PERIOD' | translate }}
            </div>
            <div class="ui-g-2">
              {{ 'ACTION' | translate }}
            </div>
          </div>
          <!-- </div> -->
        </div>

        <div
          [ngSwitch]="currentCommandAndConditionForm"
          class="ui-g-12 ui-g-nopad"
        >
          <div *ngSwitchCase="'end'">
            <div formArrayName="endConditions">
              <div
                *ngFor="
                  let endCondition of endConditions.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <sc-condition-form
                  [group]="endCondition"
                  [groupId]="i"
                  [optionData]="selectItems"
                  (removed)="removeCondition(i)"
                ></sc-condition-form>
              </div>
            </div>
          </div>
          <div *ngSwitchDefault>
            <div formArrayName="startConditions">
              <div
                *ngFor="
                  let startConditions of startConditions.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <sc-condition-form
                  [group]="startConditions"
                  [groupId]="i"
                  [optionData]="selectItems"
                  (removed)="removeCondition(i)"
                ></sc-condition-form>
              </div>
            </div>
          </div>
        </div>

        <!-- RULE_TEMPLATE_COMMANDS FORM -->
        <div class="ui-g-12 ui-g-nopad mt-5">
          <div class="ui-g-10">
            <h2 class="m-0">
              {{ 'RULE_TEMPLATE_COMMANDS' | translate }} ({{
                currentCommandAndConditionForm
              }})
            </h2>
          </div>
          <div class="ui-g-2 text-right">
            <button
              type="button"
              pButton
              class="ui-button-success"
              (click)="addCommand()"
              pTooltip="{{ 'ADD' | translate }} {{ 'COMMAND' | translate }}"
              icon="fa fa-plus"
            ></button>
          </div>
        </div>

        <div class="ui-g-12 ui-g-nopad grey lighten-2">
          <div class="ui-g-10 ui-g-nopad">
            <div
              class="ui-g-1 text-center"
              pTooltip="{{ 'EXECUTE_ONLY_ONE_TIME' | translate }}"
              appendTo="body"
            >
              1x
            </div>
            <div
              class="ui-g-1 text-center"
              pTooltip="{{
                'EXECUTE_ONLY_IF_DIFFERENT_FROM_LOCAL' | translate
              }}"
              appendTo="body"
            >
              !=
            </div>
            <div class="ui-g-2">{{ 'TARGET' | translate }}</div>
            <div class="ui-g-2">{{ 'DEVICE' | translate }}</div>
            <div class="ui-g-3">{{ 'ACTION' | translate }}</div>
            <div class="ui-g-3">{{ 'PARAMETERS' | translate }}</div>
          </div>
          <div class="ui-g-1">{{ 'DELAY' | translate }}</div>
          <div class="ui-g-1">{{ 'ACTION' | translate }}</div>
        </div>

        <div
          [ngSwitch]="currentCommandAndConditionForm"
          class="ui-g-12 ui-g-nopad"
        >
          <div *ngSwitchCase="'end'">
            <div formArrayName="endCommands">
              <div
                *ngFor="let endCommand of endCommands.controls; let i = index"
                [formGroupName]="i"
              >
                <sc-command-form
                  [group]="endCommand"
                  [groupId]="i"
                  [optionData]="selectItems"
                  (removed)="removeCommand(i)"
                ></sc-command-form>
              </div>
            </div>
          </div>
          <div *ngSwitchDefault>
            <div formArrayName="startCommands">
              <div
                *ngFor="let startCommands of startCommands.controls; let i = index"
                [formGroupName]="i"
              >
                <sc-command-form
                  [group]="startCommands"
                  [groupId]="i"
                  [optionData]="selectItems"
                  (removed)="removeCommand(i)"
                ></sc-command-form>
              </div>
            </div>
          </div>
        </div>

        <!-- Error Message -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>
      </div>

      <!--Footer-->
      <p-footer>
        <div class="ui-g">
          <div class="ui-g-6">
            <sc-confirm-trigger
              *ngIf="editMode && !formData?.isDeleted"
              buttonIcon="fa fa-trash"
              buttonClass="ui-button-danger"
              buttonLabel="{{ 'DELETE' | translate }}"
              (onAccept)="delete()"
            ></sc-confirm-trigger>
          </div>

          <div class="ui-g-6 text-right">
            <button
              pButton
              type="button"
              class="ui-button-secondary"
              (click)="dismissModal('cancel')"
              label="{{ 'CANCEL' | translate }}"
            ></button>

            <sc-submit-button
              *ngIf="!formData?.isDeleted || formData?.isActive"
              buttonClass="ui-button-success"
              [disabled]="!form.valid || form.pristine"
              [pending]="isSubmitting"
              (submitted)="submit()"
            ></sc-submit-button>
          </div>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
