<div *ngIf="fetchingState === 0; else loadingIndicator">
  <div class="location-guest-session">
    <div class="ui-g">
      <div class="ui-g-12 ui-md-3">
        <div>
          <strong>{{ "VALID_FROM" | translate }}:</strong>
        </div>
        <div *ngIf="guestSession?.validFrom">
          {{ dateFormat(guestSession.validFrom) }}
        </div>
      </div>

      <div class="ui-g-12 ui-md-3">
        <div>
          <strong>{{ "VALID_TO" | translate }}: </strong>
        </div>
        <div *ngIf="guestSession?.validTo">
          {{ dateFormat(guestSession.validTo) }}
        </div>
      </div>

      <div class="ui-g-12 ui-md-3">
        <div>
          <strong>{{ "DEFAULT_LANGUAGE" | translate }}: </strong>
        </div>
        <div *ngIf="guestSession?.locale">{{ defaultLanguage }}</div>
      </div>

      <div class="ui-g-12 ui-md-3">
        <div>
          <strong>{{ "PIN" | translate }}: </strong>
        </div>
        <div *ngIf="guestSession?.pin">
          <span class="mr-3 pin-content" *ngIf="showPin">
            {{ guestSession?.pin }}
          </span>
          <span class="mr-3 pin-content" *ngIf="!showPin">******</span>
          <span class="sc-clickable" (click)="showPin = !showPin">
            <i
              class="fa fa-fw"
              [ngClass]="{ 'fa-eye': !showPin, 'fa-eye-slash': showPin }"
            ></i>
          </span>
        </div>
      </div>
    </div>

    <div class="ui-g mt-4">
      <div class="ui-g-12 text-center">
        <h5 class="m-0">{{ "QR_CODE" | translate }}</h5>

        <qrcode [qrdata]="qrcodeData" [width]="256"></qrcode>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-6 ui-md-4 ui-md-offset-4 text-center">
        <button
          type="button"
          pButton
          class="mr-4"
          [label]="'EDIT' | translate"
          [disabled]="!guestSession"
          (click)="editSession()"
        ></button>

        <button
          type="button"
          pButton
          class="ui-button-success"
          [label]="'NEW' | translate"
          (click)="newSession()"
        ></button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
