import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromAppStyle from '../actions/app-style.action';

import { AppStyleService } from '@app/core2/app-style/app-style.service';

@Injectable()
export class AppStyleEffects {
  constructor(
    private action$: Actions,
    private appStyleService: AppStyleService
  ) {}

  @Effect()
  loadAppStyle$ = this.action$.pipe(
    ofType(fromAppStyle.LOAD_APP_STYLE),
    map((action: fromAppStyle.LoadAppStyle) => action.payload),
    switchMap(site => {
      return this.appStyleService
        .getCustomAppStyle(site.companyId, site.id)
        .pipe(
          map((result: any) => new fromAppStyle.LoadAppStyleSuccess(result.data)),
          catchError(error => of(new fromAppStyle.LoadAppStyleFail(error)))
        );
    })
  );

  @Effect()
  loadAppStyleSuccess$ = this.action$.pipe(
    ofType(fromAppStyle.LOAD_APP_STYLE_SUCCESS),
    switchMap((action: any) => {
      return [new fromAppStyle.SetAppStyle(action.payload)];
    })
  );

  @Effect({ dispatch: false })
  setAppStyle$ = this.action$.pipe(
    ofType(fromAppStyle.SET_APP_STYLE),
    map((action: fromAppStyle.SetAppStyle) => action.payload),
    tap(appStyle => {
      const variables = appStyle.variables;
      const elements = appStyle.elements;

      if (variables) {
        for (const key in variables) {
          if (variables.hasOwnProperty(key) && /^\_\_.+/.test(key)) {
            const prop = key.replace(/^\_\_/, '--');
            document.body.style.setProperty(prop, variables[key]);
          }
        }
      } else {
        // Remove existing custom style
        const styles = { ...document.body.style };
        for (const key in styles) {
          if (styles.hasOwnProperty(key)) {
            if (!/\d{1,}/.test(key)) {
              break;
            }
            document.body.style.removeProperty(styles[key]);
          }
        }
      }
    })
  );
}
