import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { ACOffComponent } from './ac-off.component';

const COMPONENTS: any[] = [ACOffComponent];

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: []
})
export class ACOffModule {}
