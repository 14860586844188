import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { AppStyleTemplate } from '../models/app-style-template';

import { AppStyleTemplateFormsService } from '../services/app-style-template-forms.service';
import { AppStyleTemplatesService } from '../services/app-style-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-app-style-template-form',
  templateUrl: 'app-style-template-form.component.html'
})
export class AppStyleTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: AppStyleTemplate;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  isSubmitting: boolean;
  fetching: { [key: string]: any };
  fetchingState: number;
  sites: SelectItem[];

  constructor(
    private appStyleTemplateFormsService: AppStyleTemplateFormsService,
    private appStyleTemplatesService: AppStyleTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchingState = 0;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchAppStyleTemplate(this.data.id);
    }

    this.sites = this.sharedService.selectItems.sites;

    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.fetching) {
      if (this.fetching.hasOwnProperty(key) && this.fetching[key]) {
        this.fetching[key].unsubscribe();
      }
    }
  }

  fetchAppStyleTemplate(id: number) {
    this.fetchingState++;
    this.appStyleTemplatesService
      .getAppStyleTemplate(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        // PARSE STRING TO JSON
        this.formData.variables = this.formData.variables
          ? this.sharedService.jsonParse(this.formData.variables)
          : [];
        this.formData.elements = this.formData.elements
          ? this.sharedService.jsonParse(this.formData.elements)
          : [];

        this.form.patchValue(this.formData);

        this.appStyleTemplateFormsService.setVariableFormArray(
          this.form,
          this.formData.variables
        );
        this.appStyleTemplateFormsService.setElementFormArray(
          this.form,
          this.formData.elements
        );

        this.fetchingState--;
      });
  }

  // INIT FORM
  initForm() {
    this.form = this.appStyleTemplateFormsService.initAppStyleTemplateForm();

    if (!this.editMode) {
      this.addAppStyleVariable();
      this.addAppStyleElement();
    }
  }

  // Element
  addAppStyleElement() {
    const control = <FormArray>this.form.controls['elements'];
    control.push(this.appStyleTemplateFormsService.initElementFormGroup());
  }
  removeAppStyleElement(i: number) {
    const control = <FormArray>this.form.controls['elements'];
    control.removeAt(i);
  }
  get elements(): FormArray {
    return this.form.controls['elements'] as FormArray;
  }

  addAppStyleVariable() {
    const control = <FormArray>this.form.controls['variables'];
    control.push(this.appStyleTemplateFormsService.initVariableFormGroup());
  }
  removeAppStyleVariable(i: number) {
    const control = <FormArray>this.form.controls['variables'];
    control.removeAt(i);
  }
  get variables(): FormArray {
    return this.form.controls['variables'] as FormArray;
  }

  // Api Call
  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      // call api
      if (!this.editMode) {
        this.create(this.form.value);
      } else {
        this.update(this.formData.id, this.form.value);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(data: AppStyleTemplate) {
    this.appStyleTemplatesService
      .createAppStyleTemplate(data)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update(id: number, data: AppStyleTemplate) {
    this.appStyleTemplatesService
      .updateAppStyleTemplate(id, data)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.appStyleTemplatesService
      .deleteAppStyleTemplate(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify(
      'App Style Template',
      result.message + ' Success!',
      'success'
    );
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
