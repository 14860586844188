import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class EndUserService {
  private apiUrl = config().apiUrl + '/_integrator';

  constructor(private httpClient: HttpClient) {}

  getEndUsers(siteId: number) {
    return this.httpClient.get(`${this.apiUrl}/sites/${siteId}/endusers`);
  }

  updateEndUser(siteId: number, data: any) {
    return this.httpClient.patch(`${this.apiUrl}/sites/${siteId}/endusers`, data);
  }

  inviteEndUser(siteId, data: any) {
    return this.httpClient.post(`${this.apiUrl}/sites/${siteId}/invite`, data);
  }

  removeEndUserFromSite(siteId: number, endUser: any) {
    if (endUser.status !== 'invited') {
      return this.httpClient.delete(`${this.apiUrl}/sites/${siteId}/uninvite/${endUser.id}`);
    } else {
      return this.httpClient.post(`${this.apiUrl}/sites/cancelInvitation`, { siteId, email: endUser.email });
    }
  }

  responseInvitation(userId, data: { inviteId: number; status: string }) {
    return this.httpClient.post(`${this.apiUrl}/endusers/${userId}/responseInvitation`, data);
  }

  getInvitations(userId: number) {
    return this.httpClient.get(`${this.apiUrl}/endusers/${userId}/invitations`);
  }
}
