import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as fromStore from '@app/store';
import { Locale } from '@widgets/locales/models/locale';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '@widgets/users/services/users.service';

@Component({
  selector: 'sc-language-chooser',
  templateUrl: './language-chooser.component.html',
  styleUrls: ['./language-chooser.component.scss'],
})
export class LanguageChooserComponent implements OnInit, OnDestroy {
  languages$: Observable<Locale[]>;
  selectedLocale: Locale;
  userId: number;
  opened: boolean;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private translate: TranslateService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    // force use `en` on first load
    this.translate.setDefaultLang('en');

    // get all locales
    this.languages$ = this.store.pipe(
      select(fromStore.getAllLocales),
      map((locales) => locales.filter((l) => l.isActive && !l.isDeleted)),
      tap((availableLocales) => {
        const languages = availableLocales.map((l) => l.id);

        this.translate.addLangs(languages);
      })
    );

    // get selected locale
    this.subscribers.selectedLocale = this.store.pipe(select(fromStore.getSelectedLocale)).subscribe((result) => {
      if (result) {
        this.selectedLocale = result;
      }
    });

    // watch user locale and update app translation
    this.subscribers.userLocale = this.store.pipe(select(fromStore.getUserLocale)).subscribe((result) => {
      if (result) {
        this.translate.use(result);
      }
    });

    // get userid
    this.subscribers.userId = this.store.pipe(select(fromStore.getCurrentUserId)).subscribe((result) => {
      this.userId = result;
    });
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  updateUserLocale(locale: string) {
    if (this.userId && locale) {
      // TODO: replace by ngrx/effect
      this.subscribers.updateUserLocale = this.usersService.updateUser(this.userId, { locale }).subscribe((result) => {
        this.store.dispatch(new fromStore.SelectLocale(locale));
        this.store.dispatch(new fromStore.UpdateUserSuccess({ locale }));
      });
    }
  }

  languageFlag(id: string) {
    switch (id) {
      case 'en':
        return 'flag-icon-gb';
      default:
        return 'flag-icon-' + id;
    }
  }
}
