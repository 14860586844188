import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@sc/form/custom-validators';
import { config } from '@app/config';

@Injectable()
export class UserFormsService {
  private patterns = config().regexPatterns;

  constructor(private formBuilder: FormBuilder) {}

  initMqttSettingsForm(): FormGroup {
    return this.formBuilder.group({
      username: [{ value: null, disabled: true }, Validators.required],
      password: [null, [Validators.required, Validators.minLength(32)]],
    });
  }

  initUserForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      email: [null, Validators.compose([Validators.required, Validators.email])],
      username: [null, Validators.compose([Validators.required, CustomValidators.pattern(this.patterns.key, 'key')])],
      password: [
        null,
        Validators.compose([
          // is required
          Validators.required,
          // minimum length of 8 characters
          Validators.minLength(8),
          // password strength
          CustomValidators.pattern(this.patterns.password, 'passwordStrength'),
        ]),
      ],
      locale: ['en', Validators.required],
      companyId: [null, Validators.required],
      firstname: null,
      lastname: null,
      phone: [null, CustomValidators.pattern(this.patterns.phone, 'phoneNumber')],
      gender: null,
      roles: null,
      twoFactorAuthMethod: null,
      backendVariant: ['ceos', Validators.required],
      mainRole: 'enduser',
      isEmailNotificationEnabled: false,
      isSmsNotificationEnabled: false,
      isActive: false,
      isBlocked: false,
      isDeleted: false,
    });
  }

  initProfileForm() {
    return this.formBuilder.group({
      firstname: null,
      lastname: null,
      gender: null,
      phone: [null, CustomValidators.pattern(this.patterns.phone, 'phoneNumber')],
      locale: [null, Validators.required],
      backendVariant: ['ceos', Validators.required],
      isEmailNotificationEnabled: false,
      isSmsNotificationEnabled: false,
    });
  }
}
