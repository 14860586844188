import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'sc-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent implements OnInit {
  @Input()
  for: string;

  @Input()
  control: FormControl;

  @Input()
  title: string = '';

  @Input()
  required: boolean;

  constructor() {}

  ngOnInit(): void {}

  get isRequired() {
    if (typeof this.required !== 'undefined') {
      return this.required;
    } else if (this.control && this.control.validator) {
      const tester: any = '';
      const validator = this.control.validator(tester);
      return validator && validator.required;
    }
    return false;
  }
}
