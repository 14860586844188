import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { WidgetCategoriesTableComponent } from './widget-categories-table/widget-categories-table.component';
import { WidgetCategoryActionCellComponent } from './widget-category-action-cell/widget-category-action-cell.component';
import { WidgetCategoryFormComponent } from './widget-category-form/widget-category-form.component';

import { WidgetCategoriesService } from './services/widget-categories.service';
import { WidgetCategoryFormService } from './services/widget-category-form.service';

@NgModule({
  imports: [SharedModule],
  declarations: [WidgetCategoriesTableComponent, WidgetCategoryActionCellComponent, WidgetCategoryFormComponent],
  entryComponents: [WidgetCategoriesTableComponent, WidgetCategoryActionCellComponent, WidgetCategoryFormComponent],
  providers: [WidgetCategoriesService, WidgetCategoryFormService],
})
export class WidgetCategoriesModule {}
