import * as fromSceneTemplates from '../actions/scene-templates.action';
import { SceneTemplate } from '@widgets/scene-templates/models/scene-template';

export interface SceneTemplateState {
  entities: { [id: number]: SceneTemplate };
  loaded: boolean;
  loading: boolean;
  formData: SceneTemplate;
  formDataLoading: boolean;
  formDataError: any;
  pending: boolean;
  pendingError: any;
}

export const initialState = {
  entities: {},
  loaded: false,
  loading: false,
  formData: null,
  formDataLoading: null,
  formDataError: null,
  pending: false,
  pendingError: null,
};

export function reducer(state = initialState, action: fromSceneTemplates.SceneTemplatesAction): SceneTemplateState {
  switch (action.type) {
    // LOAD SCENE TEMPLATE FORM
    case fromSceneTemplates.LOAD_SCENE_TEMPLATE: {
      return {
        ...state,
        formDataLoading: true,
      };
    }

    case fromSceneTemplates.LOAD_SCENE_TEMPLATE_FAIL: {
      const formDataError = action.payload;
      return {
        ...state,
        formDataLoading: false,
        formDataError,
        formData: null,
      };
    }

    case fromSceneTemplates.LOAD_SCENE_TEMPLATE_SUCCESS: {
      const formData = action.payload;
      return {
        ...state,
        formDataLoading: false,
        formDataError: null,
        formData,
      };
    }

    // LOAD SCENE TEMPLATES
    case fromSceneTemplates.LOAD_SCENE_TEMPLATES: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromSceneTemplates.LOAD_SCENE_TEMPLATES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromSceneTemplates.LOAD_SCENE_TEMPLATES_SUCCESS: {
      const sceneTemplates = action.payload;
      const entities = sceneTemplates.reduce(
        (tmpEntities: { [id: number]: SceneTemplate }, sceneTemplate: SceneTemplate) => {
          return {
            ...tmpEntities,
            [sceneTemplate.id]: sceneTemplate,
          };
        },
        {}
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    // CREATE / UPDATE Scene_TEMPLATE
    case fromSceneTemplates.CREATE_SCENE_TEMPLATE:
    case fromSceneTemplates.UPDATE_SCENE_TEMPLATE:
    case fromSceneTemplates.DELETE_SCENE_TEMPLATE: {
      return {
        ...state,
        pending: true,
      };
    }

    case fromSceneTemplates.CREATE_SCENE_TEMPLATE_FAIL:
    case fromSceneTemplates.UPDATE_SCENE_TEMPLATE_FAIL:
    case fromSceneTemplates.DELETE_SCENE_TEMPLATE: {
      const pendingError = action.payload;
      return {
        ...state,
        pending: false,
        pendingError,
      };
    }

    case fromSceneTemplates.CREATE_SCENE_TEMPLATE_SUCCESS:
    case fromSceneTemplates.UPDATE_SCENE_TEMPLATE_SUCCESS: {
      const sceneTemplate = action.payload;
      const entities = {
        ...state.entities,
        [sceneTemplate.id]: sceneTemplate,
      };

      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
      };
    }

    // DELETE Scene_TEMPLATE
    case fromSceneTemplates.DELETE_SCENE_TEMPLATE_SUCCESS: {
      const sceneTemplate = action.payload;
      const entities = {
        ...state.entities,
        [sceneTemplate.id]: {
          ...sceneTemplate,
          isActive: false,
          isDeleted: true,
        },
      };
      return {
        ...state,
        pending: false,
        pendingError: null,
        entities,
      };
    }

    // RESET PENDING
    case fromSceneTemplates.SCENE_TEMPLATE_RESET_PENDING_STATE: {
      return {
        ...state,
        pending: false,
        pendingError: null,
        formData: null,
      };
    }
  }
  return state;
}

// export const getSceneTemplatesEntities = (state: SceneTemplateState) => state.entities;

// export const getSceneTemplatesLoading = (state: SceneTemplateState) => state.loading;

// export const getSceneTemplatesLoaded = (state: SceneTemplateState) => state.loaded;

// export const getSceneTemplatePending = (state: SceneTemplateState) => state.pending;

// export const getSceneTemplatePendingError = (state: SceneTemplateState) => state.pendingError;
