<div class="sc-widget">
  <div class="sc-widget-header">
    <h4 class="sc-widget-title">
      {{ 'FILES_MANAGER' | translate }}
    </h4>

    <!-- <div class="sc-widget-menu">
      <i
        class="fa fa-refresh fa-fw sc-clickable green-text"
        [ngClass]="{'fa-spin': isFetching}"
        (click)="fetchDataset()"
        pTooltip="{{ 'REFRESH' | translate }}"
        tooltipPosition="top"
        appendTo="body"
      ></i>
    </div> -->
  </div>

  <div class="sc-widget-body align-start justify-start">
    <sc-file-manager
      [fileTree]="fileTree"
      [loading]="fileLoading"
      (copyFile)="onCopyFile($event)"
      (deleteFile)="onDeleteFile($event)"
      (moveFile)="onMoveFile($event)"
      (openFile)="onOpenFile($event)"
      (renameFile)="onRenameFile($event)"
      (selectFile)="onSelectFile($event)"
      (createFolder)="onCreateFolder($event)"
      (uploadFiles)="onUploadFiles($event)"
    ></sc-file-manager>
  </div>
</div>
