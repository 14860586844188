import * as fromPageTemplates from '../actions/page-templates.action';

import { PageTemplate } from '@app/core2/models/page-template';

export interface PageTemplateState {
  entities: { [id: string]: PageTemplate };
  loaded: boolean;
  loading: boolean;
}

export const initialState = {
  entities: null,
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: fromPageTemplates.PageTemplatesAction
): PageTemplateState {
  switch (action.type) {
    // ____ LOAD PAGE TEMPLATES
    case fromPageTemplates.LOAD_PAGE_TEMPLATES: {
      return {
        ...state,
        loading: true
      };
    }

    case fromPageTemplates.LOAD_PAGE_TEMPLATES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromPageTemplates.LOAD_PAGE_TEMPLATES_SUCCESS: {
      const pages = action.payload;
      const entities = pages.reduce(
        (tmpEntities: { [id: string]: PageTemplate }, page: PageTemplate) => {
          return {
            ...tmpEntities,
            [page.id]: page
          };
        },
        {}
        // {
        //   ...state.entities,
        // }
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        entities
      };
    }
  }

  return state;
}

export const getPageTemplatesEntities = (state: PageTemplateState) =>
  state.entities;

export const getPageTemplatesLoading = (state: PageTemplateState) =>
  state.loading;

export const getPageTemplatesLoaded = (state: PageTemplateState) =>
  state.loaded;
