import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { SelectItem } from 'primeng/api';
import { Site } from '../models/site';
import { CompaniesService } from '@widgets/companies/services/companies.service';
import { CurrenciesService } from '@widgets/currencies/services/currencies.service';
import { SiteFormsService } from '../services/site-forms.service';
import { SiteTypesService } from '@widgets/site-types/services/site-types.service';
import { SharedService } from '@shared/shared.service';
import { UsersService } from '@widgets/users/services/users.service';
import { config } from '@app/config';

@Component({
  selector: 'sc-site-form',
  templateUrl: './site-form.component.html',
})
export class SiteFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: Site;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  fetchingState: number = 0;
  pendingState: number = 0;
  submitting: boolean;
  siteIdPrefix: string;

  patterns = config().regexPatterns;

  private subscribers: { [key: string]: any } = {};
  selectItems: { [key: string]: SelectItem[] };
  hiddenUsers: any[];

  private isAdmin: boolean;

  constructor(
    private companiesService: CompaniesService,
    private currenciesService: CurrenciesService,
    private siteFormsService: SiteFormsService,
    private usersService: UsersService,
    private sharedService: SharedService,
    private siteTypesService: SiteTypesService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.selectItems = {
      companyUsers: [],
      siteUsers: [],
      timezones: this.sharedService.selectItems.timezones,
    };

    this.isAdmin = this.sharedService.userRoles && this.sharedService.userRoles.includes('admin');

    // clear form pending state
    this.store.dispatch(new fromStore.SiteResetPendingState());

    // watch form pending error
    this.subscribers.errorMessage = this.store.pipe(select(fromStore.getSitePendingError)).subscribe((result) => {
      this.errorMessage = result;
    });

    // close form when create or update success
    this.subscribers.pendingState = this.store.pipe(select(fromStore.getSitePending)).subscribe((result) => {
      this.pendingState++;
      this.submitting = result;
      if (this.pendingState === 3) {
        if (!this.submitting && !this.errorMessage) {
          this.onClose.emit();
        }
        this.pendingState = 0;
      }
    });

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      // FETCH SITE
      this.store.dispatch(new fromStore.LoadSite(this.data.id));
      this.subscribers.fetchingState = this.store.pipe(select(fromStore.getSiteFormDataLoading)).subscribe((result) => {
        if (result) {
          this.fetchingState++;
        } else {
          this.fetchingState--;
        }
      });
    }

    // Get PropertyManagementSystems
    this.subscribers.getPMS = this.store.pipe(select(fromStore.getPropertyManagementSystems)).subscribe((result) => {
      this.selectItems.pmsList = this.sharedService.createSelectItems(result, true);
    });

    if (this.isAdmin) {
      this.fetchCompanies();
    } else {
      this.fetchCompanies(this.sharedService.userCompanyId);
    }
    this.fetchCurrencies();
    this.fetchSiteTypes();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchUsers(companyId: number) {
    let group: any = 'company';
    if (this.isAdmin) {
      // companyId = undefined;
      group = 'all';
    }

    this.subscribers.getUsers = this.usersService.getUsersOf(group).subscribe((result: any) => {
      const tmpUsers = result.data;
      // COMPANY USERS
      this.selectItems.companyUsers = this.sharedService.createSelectItems(
        result.data.filter((d) => d.isActive === 1),
        false
      );
      // USERS FOR RECEIVE SMS / EMAIL ALERT
      if (this.editMode) {
        this.selectItems.siteUsers = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive === 1 && d.sites && d.sites.indexOf(this.formData.id) >= 0),
          false
        );
      }

      if (!this.isAdmin && this.hiddenUsers) {
        const users = [];
        for (let i = 0; i < this.hiddenUsers.length; i++) {
          for (let j = 0; j < tmpUsers.length; j++) {
            if (!tmpUsers[j].isActive) {
              continue;
            }

            if (tmpUsers[j].id === this.hiddenUsers[i]) {
              users.push(tmpUsers[j].id);
              this.hiddenUsers.splice(i, 1);
              break;
            }
          }
        }
        if (this.form) {
          this.form.controls['users'].setValue(users);
        }
      }
    });
  }

  private fetchCompanies(companyId?: number) {
    this.fetchingState++;
    this.subscribers.fetchCompanies = this.companiesService.getCompanies(companyId).subscribe((result: any) => {
      const companies = companyId ? [result.data] : result.data;
      this.selectItems.companies = this.sharedService.createSelectItems(companies);
      this.fetchingState--;
    });
  }

  private fetchCurrencies() {
    this.fetchingState++;
    this.subscribers.fetchCurrencies = this.currenciesService.getCurrencies().subscribe((result: any) => {
      this.selectItems.currencies = this.sharedService.createSelectItems(
        result.data.filter((item) => item.isActive && !item.isDeleted)
      );
      this.fetchingState--;
    });
  }

  private fetchSiteTypes() {
    this.fetchingState++;
    this.subscribers.fetchSiteTypes = this.siteTypesService.getSiteTypes().subscribe((result: any) => {
      // this.selectItems.siteTypes = this.sharedService.createSelectItems(
      //   result.data.filter((item) => item.isActive && !item.isDeleted)
      // );
      this.selectItems.siteTypes = result.data
        .filter((item) => item.isActive && !item.isDelted)
        .map((item) => {
          const name = JSON.parse(item.name);
          const data = {
            label: name ? name.en : item.key,
            value: item.id,
          };
          return data;
        });
      this.fetchingState--;
    });
  }

  private initForm() {
    this.form = this.siteFormsService.initSiteForm();
    this.onFormValueChange();

    if (this.editMode) {
      this.subscribers.formData = this.store.pipe(select(fromStore.getSiteFormData)).subscribe((result) => {
        if (result) {
          this.formData = this.convertMillisecondsToSeconds({ ...result });

          if (!this.isAdmin) {
            this.hiddenUsers = [...result.users];
          }

          this.formData.deviceAlertEmailReceivers = this.formData.deviceAlertEmailReceivers
            ? this.sharedService.jsonParse(this.formData.deviceAlertEmailReceivers)
            : null;

          this.formData.deviceAlertSmsReceivers = this.formData.deviceAlertSmsReceivers
            ? this.sharedService.jsonParse(this.formData.deviceAlertSmsReceivers)
            : null;

          this.form.patchValue(this.formData);
        }
      });
    } else {
      if (this.isAdmin) {
        this.form.get('companyId').enable();
        this.form.patchValue({ companyId: this.sharedService.selectedSite.companyId });
      } else {
        this.form.patchValue({ companyId: this.sharedService.userCompanyId });
      }
    }
  }

  private onFormValueChange() {
    this.subscribers.companyId = this.form.get('companyId').valueChanges.subscribe((value) => {
      if (value) {
        // Get users
        this.fetchUsers(value);
      }
    });

    this.subscribers.isDeviceAlertEnabled = this.form.get('isDeviceAlertEnabled').valueChanges.subscribe((value) => {
      const fields = [
        'isDeviceAlertEmailEnabled',
        'isDeviceAlertSmsEnabled',
        'deviceAlertAutomationTimeout',
        'deviceAlertGatewayTimeout',
        'batteryLevelWarning',
        'batteryLevelCrucial',
        'acValveAlertDiffPoint',
        'acValveAlertTimeout',
      ];

      if (value) {
        for (let i = 0; i < fields.length; i++) {
          this.form.get(fields[i]).enable();
        }
      } else {
        for (let i = 0; i < fields.length; i++) {
          this.form.get(fields[i]).reset();
          this.form.get(fields[i]).disable();
        }
      }
    });

    this.subscribers.isDeviceAlertEmailEnabled = this.form
      .get('isDeviceAlertEmailEnabled')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.form.get('deviceAlertEmailReceivers').enable();
          this.form.get('deviceAlertEmailReceivers').setValidators(Validators.required);
        } else {
          this.form.get('deviceAlertEmailReceivers').reset();
          this.form.get('deviceAlertEmailReceivers').disable();
          this.form.get('deviceAlertEmailReceivers').clearValidators();
        }
      });

    this.subscribers.isDeviceAlertSmsEnabled = this.form
      .get('isDeviceAlertSmsEnabled')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.form.get('deviceAlertSmsReceivers').enable();
          this.form.get('deviceAlertSmsReceivers').setValidators(Validators.required);
        } else {
          this.form.get('deviceAlertSmsReceivers').reset();
          this.form.get('deviceAlertSmsReceivers').disable();
          this.form.get('deviceAlertSmsReceivers').clearValidators();
        }
      });
  }

  private convertSecondsToMilliseconds(formData) {
    if (formData.configFetchInterval && typeof formData.configFetchInterval === 'number') {
      formData.configFetchInterval = this.sharedService.numberFormat(formData.configFetchInterval * 1000);
    }

    if (formData.hlSenderInterval && typeof formData.hlSenderInterval === 'number') {
      formData.hlSenderInterval = this.sharedService.numberFormat(formData.hlSenderInterval * 1000);
    }

    if (formData.nhlSenderInterval && typeof formData.nhlSenderInterval === 'number') {
      formData.nhlSenderInterval = this.sharedService.numberFormat(formData.nhlSenderInterval * 1000);
    }

    if (formData.gatewayDefaultTimeout && typeof formData.gatewayDefaultTimeout === 'number') {
      formData.gatewayDefaultTimeout = this.sharedService.numberFormat(formData.gatewayDefaultTimeout * 1000);
    }

    if (formData.ruleCommandExecutorTimeout && typeof formData.ruleCommandExecutorTimeout === 'number') {
      formData.ruleCommandExecutorTimeout = this.sharedService.numberFormat(formData.ruleCommandExecutorTimeout * 1000);
    }

    return formData;
  }

  private convertMillisecondsToSeconds(formData) {
    if (formData.configFetchInterval && typeof formData.configFetchInterval === 'number') {
      formData.configFetchInterval = this.sharedService.numberFormat(formData.configFetchInterval / 1000);
    }

    if (formData.hlSenderInterval && typeof formData.hlSenderInterval === 'number') {
      formData.hlSenderInterval = this.sharedService.numberFormat(formData.hlSenderInterval / 1000);
    }

    if (formData.nhlSenderInterval && typeof formData.nhlSenderInterval === 'number') {
      formData.nhlSenderInterval = this.sharedService.numberFormat(formData.nhlSenderInterval / 1000);
    }

    if (formData.gatewayDefaultTimeout && typeof formData.gatewayDefaultTimeout === 'number') {
      formData.gatewayDefaultTimeout = this.sharedService.numberFormat(formData.gatewayDefaultTimeout / 1000);
    }

    if (formData.ruleCommandExecutorTimeout && typeof formData.ruleCommandExecutorTimeout === 'number') {
      formData.ruleCommandExecutorTimeout = this.sharedService.numberFormat(formData.ruleCommandExecutorTimeout / 1000);
    }

    return formData;
  }

  submit() {
    if (this.form.valid) {
      const formData = this.convertSecondsToMilliseconds(this.form.getRawValue());
      if (!this.editMode) {
        delete formData.id;
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(formData) {
    this.store.dispatch(new fromStore.CreateSites({ site: formData }));
  }

  private update(formData) {
    if (this.hiddenUsers && this.hiddenUsers.length) {
      formData.users = [...formData.users, ...this.hiddenUsers];
    }
    this.store.dispatch(new fromStore.UpdateSites(formData));
  }

  delete() {
    this.store.dispatch(new fromStore.DeleteSites(this.formData));
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
