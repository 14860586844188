import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Site } from '@widgets/sites/models/site';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-housekeeping',
  templateUrl: './housekeeping.component.html',
  styleUrls: ['./housekeeping.component.scss'],
})
export class HousekeepingComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isFirstTime = true;
  isFetching = false;

  blocks: any[];
  floors: any[];
  units: any[];
  widgetName: string = '';

  private dataset: { [key: string]: any }[];
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.WATCH_SITE = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite) {
      this.isFetching = true;
      const { id } = this.selectedSite;
      this.subscribers.GET_DATASET = this.widgetService.getHousekeeping(id).subscribe(
        (result: any) => {
          this.dataset = result.data;

          this.blocks = this.dataset.filter((d) => d.typeKey === 'b');
          this.floors = this.dataset.filter((d) => d.typeKey === 'f');
          this.units = this.dataset.filter((d) => d.typeKey === 'u');

          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }
}
