<div [formGroup]="group" class="ui-g ui-g-nopad">
  <!-- RULE_TEMPLATE FIELD -->
  <div class="ui-g-7">
    <sc-dropdown
      controlName="ruleTemplateId"
      [form]="group"
      label="{{ 'RULE_TEMPLATE' | translate }}"
      [options]="optionData['ruleTemplates']"
    ></sc-dropdown>
  </div>

  <!-- IS_ACTIVE FIELD -->
  <div class="ui-g-3 mt-4">
    <sc-checkbox-input
      controlName="isActive"
      [form]="group"
      label="{{ 'ACTIVE' | translate }}"
    ></sc-checkbox-input>
  </div>

  <!-- REMOVE BUTTON -->
  <div class="ui-g-2 mt-4">
    <button
      pButton
      type="button"
      class="ui-button-warning"
      icon="fa fa-trash"
      (click)="removed.emit()"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'RULE_TEMPLATE' | translate }}"
      appendTo="body"
    ></button>
  </div>
</div>
