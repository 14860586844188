import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectItem } from 'primeng/api';
import { Device } from '@widgets/devices/models/device';
import { CeosDevice } from '@widgets/devices/models/ceos-device';
import { CeosIdentityService } from '@widgets/automations/services/ceos-identity.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { DeviceFormsService } from '@widgets/devices/services/device-forms.service';
import { SharedService } from '@shared/shared.service';
import { AuthService } from '@app/auth/services/auth.service';

@Component({
  selector: 'sc-ceos-device-form',
  templateUrl: './ceos-device-form.component.html',
})
export class CeosDeviceFormComponent implements OnInit, OnDestroy {
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  vdsForm: FormArray;
  isAdmin: boolean = false;
  isSubmitting = false;
  selectedDevice: Device;
  vdh: CeosDevice;
  ceosIdentitySelectItems: SelectItem[] = [];

  private ceosIdentities: any[];
  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private devicesService: DevicesService,
    private deviceFormsService: DeviceFormsService,
    private sharedService: SharedService,
    private ceosIdentityService: CeosIdentityService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.auth.isAuthorized('admin');

    if (this.config && this.config.data) {
      if (this.config.data.device) {
        this.selectedDevice = this.config.data.device;
        this.fetchDeviceDetails(this.selectedDevice.idx);
      }
    }

    this.fetchCeosIdentites();
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal() {
    this.dialog.close();
  }

  private fetchDeviceDetails(idx: string) {
    this.fetchingState++;
    this.subscribers.getDevice = this.devicesService.getCeosDevice(idx).subscribe({
      next: (result: any) => {
        this.vdh = result.data;
        this.vdh.createdAtFormatted = this.formatDate(this.vdh.createdAt);
        this.vdh.updatedAtFormatted = this.formatDate(this.vdh.updatedAt);
        this.initForm();
        this.fetchingState--;
      },
    });
  }

  private fetchCeosIdentites() {
    this.fetchingState++;
    this.subscribers.fetchCeosIdentites = this.ceosIdentityService.getCeosIdentities().subscribe({
      next: (result: any) => {
        this.ceosIdentities = result.data;
        this.ceosIdentitySelectItems = this.ceosIdentities
          // .filter((item) => !item.used_by)
          .map((item) => ({ label: item.id, value: item.id }));
        this.fetchingState--;
      },
    });
  }

  private initForm() {
    this.form = this.deviceFormsService.initCeosDeviceForm(this.vdh);
    this.vdsForm = this.form.get('virtualDevices') as FormArray;

    // watch `isActive` of virtual device holder
    this.subscribers.vdhIsActive = this.form.get('isActive').valueChanges.subscribe((result) => {
      // update `isActive` of virtual devices
      const controls = this.vdsForm.controls;
      for (const ctrl of controls) {
        ctrl.patchValue({ isActive: result });
      }
    });

    // watch `isPersistent` of virtual device holder
    this.subscribers.vdhIsPersistent = this.form.get('isPersistent').valueChanges.subscribe((result) => {
      // update `isPersistent` of virtual devices
      const controls = this.vdsForm.controls;
      for (const ctrl of controls) {
        ctrl.patchValue({ isPersistent: result });
      }
    });
  }

  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.value;
    // console.log(formData);
    this.update(formData);
  }

  private update(data: CeosDevice) {
    this.subscribers.update = this.devicesService.updateCeosDevice(this.selectedDevice.idx, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS', data),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete(force: boolean = false) {
    this.subscribers.delete = this.devicesService.deleteDevice(this.selectedDevice.id, force).subscribe({
      next: (result: any) => {
        if (result.warning) {
          this.subscribers.delete = this.sharedService
            .deleteWarningHandler(this.selectedDevice, result.warning)
            .subscribe((confirm: any) => {
              if (confirm === true) {
                this.delete(true);
              }
            });
        } else {
          this.selectedDevice.isDeleted = true;
          this.selectedDevice.isActive = false;
          this.apiCallSuccess('DELETE_SUCCESS', this.selectedDevice, result);
        }
      },
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, data: CeosDevice, result?: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;

    this.dialog.close(data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  private formatDate(dateString: string) {
    return this.sharedService.dateFormat(dateString);
  }

  getPropertyName(idx: string) {
    if (this.vdh && this.vdh.virtualDevices && this.vdh.virtualDevices.length) {
      const vd = this.vdh.virtualDevices.find((d) => d.idx === idx);
      if (vd) {
        return vd.deviceTypeName;
      }
    }
    return 'Unknown';
  }
}
