import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { Site } from '@widgets/sites/models/site';

import { EnergySavingsService } from '../services/energy-savings.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-energy-saved',
  templateUrl: './energy-saved.component.html',
})
export class EnergySavedComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isFetching = false;
  isFirstTime = true;
  subtitleText = '';
  // titleText = 'ENERGY_SAVED';
  squareOptions = {
    customClass: 'lightgreen',
    icon: 'fa fa-bolt',
  };
  topThreeData: { label: string; value: any }[];
  widgetName: string = '';

  private dataset: { [key: string]: any };
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};
  private intervals: { [key: string]: any } = {};

  constructor(
    private energySavingsService: EnergySavingsService,
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.selectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
        this.intervals.datasetUpdate = setInterval(() => this.fetchDataset(), 5000);
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private getKwhSavedLastMonth() {
    if (this.dataset && this.dataset.length) {
      for (let i = 0; i < this.dataset.length; i++) {
        if (this.dataset[i].key === 'last_month') {
          return this.dataset[i].kwhSaved.toFixed(3) + ' KW';
        }
      }
    }
    return 0 + ' KW';
  }

  private getKwhSavedThisMonth() {
    if (this.dataset && this.dataset.length) {
      for (let i = 0; i < this.dataset.length; i++) {
        if (this.dataset[i].key === 'this_month') {
          return this.dataset[i].kwhSaved.toFixed(3) + ' KW';
        }
      }
    }
    return 0 + ' KW';
  }

  private getKwhSavedYesterday() {
    if (this.dataset && this.dataset.length) {
      for (let i = 0; i < this.dataset.length; i++) {
        if (this.dataset[i].key === 'yesterday') {
          return this.dataset[i].kwhSaved.toFixed(3) + ' KW';
        }
      }
    }
    return 0 + ' KW';
  }

  private setWidgetDataset() {
    this.topThreeData = [
      { label: 'YESTERDAY', value: this.getKwhSavedYesterday() },
      { label: 'THIS_MONTH', value: this.getKwhSavedThisMonth() },
      { label: 'LAST_MONTH', value: this.getKwhSavedLastMonth() },
    ];
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite && this.selectedSite.id) {
      this.isFetching = true;
      this.subscribers.getDataset = this.energySavingsService.getEnergySavings(this.selectedSite.id).subscribe(
        (result: any) => {
          this.dataset = result.data;
          this.setWidgetDataset();
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }
}
