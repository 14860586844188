<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <!--Header-->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <sc-activate-button [form]="form" field="isActive"></sc-activate-button>
      </div>
    </div>

    <!--Body-->
    <div class="ui-g">
      <!-- INFORMATION -->
      <div class="ui-g-12 ui-g-nopad">
        <h3>{{ "INFORMATION" | translate }}</h3>

        <!-- LEFT COLUMN -->
        <div class="ui-g-6 ui-g-nopad">
          <!-- COMPANY_ID FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="companyId"
              [form]="form"
              label="{{ 'COMPANY' | translate }}"
              [options]="selectItems?.companies"
            ></sc-dropdown>
          </div>

          <!-- SITE_TYPE_ID FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="siteTypeId"
              [form]="form"
              label="{{ 'SITE_TYPE' | translate }}"
              [options]="selectItems?.siteTypes"
            ></sc-dropdown>
          </div>

          <!-- NAME FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="name"
              [form]="form"
              label="{{ 'NAME' | translate }}"
            ></sc-text-input>
          </div>

          <!-- TIME/ZONE FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="timezone"
              [form]="form"
              label="{{ 'TIMEZONE' | translate }}"
              [options]="selectItems?.timezones"
            ></sc-dropdown>
          </div>

          <!-- LATITUDE FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="lat"
              [form]="form"
              label="{{ 'LATITUDE' | translate }}"
            ></sc-text-input>
          </div>

          <!-- LONGITUDE FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="lng"
              [form]="form"
              label="{{ 'LONGITUDE' | translate }}"
            ></sc-text-input>
          </div>
        </div>

        <!-- RIGHT COLUMN -->
        <div class="ui-g-6 ui-g-nopad">
          <!-- DESCRIPTION FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="description"
              [form]="form"
              label="{{ 'DESCRIPTION' | translate }}"
            ></sc-text-input>
          </div>

          <!-- PHONE FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="phone"
              [form]="form"
              label="{{ 'PHONE' | translate }}"
            ></sc-text-input>
          </div>

          <!-- FAX FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="fax"
              [form]="form"
              label="{{ 'FAX' | translate }}"
            ></sc-text-input>
          </div>

          <!-- EMAIL FIELD -->
          <div class="ui-g-12">
            <sc-email-input
              controlName="email"
              [form]="form"
              label="{{ 'EMAIL' | translate }}"
            ></sc-email-input>
          </div>

          <!-- WEBSITE FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="website"
              [form]="form"
              label="{{ 'WEBSITE' | translate }}"
            ></sc-text-input>
          </div>

          <!-- CURRENCY FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="currency"
              [form]="form"
              label="{{ 'CURRENCY' | translate }}"
              [options]="selectItems?.currencies"
            ></sc-dropdown>
          </div>

          <!-- PMS FIELD DUMMY -->
          <!-- <div class="ui-g-12"> -->
          <!-- TODO: Uncomment this and remove the dummy below
              <sc-dropdown
              controlName="pms"
              [form]="form"
              label="{{ 'PMS' | translate }}"
              [options]="pmsList"
            ></sc-dropdown> -->

          <!-- <label class="sc-form-label">
              {{ 'PMS' | translate }}
            </label>
            <p-dropdown
              inputId='pms'
              [options]="selectItems['pmsList']"
              placeholder="{{ 'SELECT' | translate }} {{ 'PMS' | translate }}"
              filter="true"
            ></p-dropdown> -->
          <!-- </div> -->
        </div>
      </div>

      <!-- NETWORK -->
      <div class="ui-g-12 ui-g-nopad">
        <h3>{{ "NETWORK" | translate }}</h3>

        <!-- LOCAL_IP FIELD -->
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g-8">
            <sc-text-input
              controlName="localIp"
              [form]="form"
              label="{{ 'LOCAL_IP' | translate }}"
            ></sc-text-input>
          </div>

          <div class="ui-g-4">
            <sc-number-input
              controlName="localPort"
              [form]="form"
              label="{{ 'LOCAL_PORT' | translate }}"
              [max]="99999"
              [min]="0"
            ></sc-number-input>
          </div>
        </div>

        <!-- IP FIELD -->
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g-8">
            <sc-text-input
              controlName="ip"
              [form]="form"
              label="{{ 'IP' | translate }}"
            ></sc-text-input>
          </div>

          <div class="ui-g-4">
            <sc-number-input
              controlName="port"
              [form]="form"
              label="{{ 'PORT' | translate }}"
              [max]="99999"
              [min]="0"
            ></sc-number-input>
          </div>
        </div>

        <!-- ALLOWS_INCOMING_REQUESTS -->
        <div class="ui-g-12">
          <sc-checkbox-input
            controlName="allowsIncomingRequests"
            [form]="form"
            label="{{ 'ALLOWS_INCOMING_REQUESTS' | translate }}"
          ></sc-checkbox-input>
        </div>
      </div>

      <!-- USERS -->
      <div class="ui-g-12 ui-g-nopad">
        <h3>{{ "USERS" | translate }}</h3>

        <div class="ui-g-12">
          <sc-dropdown
            controlName="users"
            [form]="form"
            label="{{ 'USERS' | translate }}"
            [options]="selectItems?.companyUsers"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>
      </div>

      <!-- TIMING -->
      <div class="ui-g-12 ui-g-nopad">
        <h3>{{ "TIMING" | translate }}</h3>

        <!-- LEFT Column -->
        <div class="ui-g-6 ui-g-nopad">
          <div class="ui-g-12">
            <sc-number-input
              controlName="configFetchInterval"
              [form]="form"
              label="{{ 'CONFIG_FETCH_INTERVAL' | translate }}"
              [min]="0"
              suffix="{{ 'SECONDS' | translate }}"
            ></sc-number-input>
          </div>

          <div class="ui-g-12">
            <sc-number-input
              controlName="hlSenderInterval"
              [form]="form"
              label="{{ 'HL_SENDER_INTERVAL' | translate }}"
              [min]="0"
              suffix="{{ 'SECONDS' | translate }}"
            ></sc-number-input>
          </div>

          <div class="ui-g-12">
            <sc-number-input
              controlName="ruleCommandExecutorTimeout"
              [form]="form"
              label="{{ 'RULE_COMMAND_EXECUTOR_TIMEOUT' | translate }}"
              [min]="0"
              suffix="{{ 'SECONDS' | translate }}"
            ></sc-number-input>
          </div>
        </div>

        <!-- Right Column -->
        <div class="ui-g-6 ui-g-nopad">
          <div class="ui-g-12">
            <sc-number-input
              controlName="gatewayDefaultTimeout"
              [form]="form"
              label="{{ 'GATEWAY_DEFAULT_TIMEOUT' | translate }}"
              [min]="0"
              suffix="{{ 'SECONDS' | translate }}"
            ></sc-number-input>
          </div>

          <div class="ui-g-12">
            <sc-number-input
              controlName="nhlSenderInterval"
              [form]="form"
              label="{{ 'NHL_SENDER_INTERVAL' | translate }}"
              [min]="0"
              suffix="{{ 'SECONDS' | translate }}"
            ></sc-number-input>
          </div>
        </div>
      </div>

      <!-- ACCEPTABLE -->
      <div class="ui-g-12 ui-g-nopad">
        <h3>{{ "ACCEPTABLE" | translate }}</h3>

        <!-- LEFT Column -->
        <div class="ui-g-6 ui-g-nopad">
          <!-- CURRENT_OUTDOOR_HUMIDITY FIELD -->
          <div class="ui-g-12">
            <sc-number-input
              controlName="currentOutdoorHumidity"
              [form]="form"
              label="{{ 'CURRENT_OUTDOOR_HUMIDITY' | translate }}"
              [min]="0"
            ></sc-number-input>
          </div>

          <!-- CURRENT_OUTDOOR_TEMPERATURE FIELD -->
          <div class="ui-g-12">
            <sc-number-input
              controlName="currentOutdoorTemperature"
              [form]="form"
              label="{{ 'CURRENT_OUTDOOR_TEMPERATURE' | translate }}"
              [min]="0"
            ></sc-number-input>
          </div>

          <!-- OCCUPANCY_DELAY FIELD -->
          <div class="ui-g-12">
            <sc-number-input
              controlName="occupancyDelay"
              [form]="form"
              label="{{ 'OCCUPANCY_DELAY' | translate }}"
              [min]="0"
            ></sc-number-input>
          </div>

          <!-- OCCUPANCY_TIMEOUT FIELD -->
          <div class="ui-g-12">
            <sc-number-input
              controlName="occupancyTimeout"
              [form]="form"
              label="{{ 'OCCUPANCY_TIMEOUT' | translate }}"
              [min]="0"
            ></sc-number-input>
          </div>

          <!-- IS_RAINING FIELD -->
          <div class="ui-g-12">
            <sc-checkbox-input
              controlName="isRaining"
              [form]="form"
              label="{{ 'IS_RAINING' | translate }}"
            ></sc-checkbox-input>
          </div>
        </div>

        <!-- Right Column -->
        <div class="ui-g-6 ui-g-nopad">
          <!-- MAX_ACCEPTABLE_CONSUMPTION FIELD -->
          <div class="ui-g-12">
            <sc-number-input
              controlName="maxAcceptableConsumption"
              [form]="form"
              label="{{ 'MAX_ACCEPTABLE_CONSUMPTION' | translate }}"
              [min]="0"
              suffix="W"
            ></sc-number-input>
          </div>

          <!-- MAX_ACCEPTABLE_HUMIDITY FIELD -->
          <div class="ui-g-12">
            <sc-number-input
              controlName="maxAcceptableHumidity"
              [form]="form"
              label="{{ 'MAX_ACCEPTABLE_HUMIDITY' | translate }}"
              [min]="0"
              suffix="%"
            ></sc-number-input>
          </div>

          <!-- MAX_ACCEPTABLE_TEMPERATURE FIELD -->
          <div class="ui-g-12">
            <sc-number-input
              controlName="maxAcceptableTemperature"
              [form]="form"
              label="{{ 'MAX_ACCEPTABLE_TEMPERATURE' | translate }}"
              [min]="0"
              suffix="°C"
            ></sc-number-input>
          </div>
        </div>
      </div>

      <!-- DEVICE ALERT -->
      <div class="ui-g-12 ui-g-nopad" *ngIf="editMode">
        <h3>{{ "DEVICE_ALERT" | translate }}</h3>

        <!-- DEVICE_ALERT_ENABLED -->
        <div class="ui-g-12 ui-g-nopad">
          <sc-checkbox-input
            controlName="isDeviceAlertEnabled"
            [form]="form"
            label="{{ 'DEVICE_ALERT_ENABLED' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- DEVICE_ALERT_AUTOMATION_TIMEOUT -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="deviceAlertAutomationTimeout"
            [form]="form"
            label="{{ 'DEVICE_ALERT_AUTOMATION_TIMEOUT' | translate }}"
            [min]="0"
            description="{{ 'DEVICE_ALERT_LEAVE_BLANK' | translate }}"
            suffix="{{ 'MINUTES' | translate }}"
          ></sc-number-input>
        </div>

        <!-- DEVICE_ALERT_GATEWAY_TIMEOUT -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="deviceAlertGatewayTimeout"
            [form]="form"
            label="{{ 'DEVICE_ALERT_GATEWAY_TIMEOUT' | translate }}"
            [min]="0"
            description="{{ 'DEVICE_ALERT_LEAVE_BLANK' | translate }}"
            suffix="{{ 'MINUTES' | translate }}"
          ></sc-number-input>
        </div>

        <!-- BATTERY_LEVEL_WARNING -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="batteryLevelWarning"
            [form]="form"
            label="{{ 'DEVICE_ALERT_BATTERY_LEVEL_WARNING' | translate }}"
            [min]="0"
            [max]="100"
            description="{{ 'DEVICE_ALERT_LEAVE_BLANK' | translate }}"
            suffix="%"
          ></sc-number-input>
        </div>

        <!-- BATTERY_LEVEL_CRUCIAL -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="batteryLevelCrucial"
            [form]="form"
            label="{{ 'DEVICE_ALERT_BATTERY_LEVEL_CRUCIAL' | translate }}"
            [min]="0"
            [max]="100"
            description="{{ 'DEVICE_ALERT_LEAVE_BLANK' | translate }}"
            suffix="%"
          ></sc-number-input>
        </div>

        <!-- AC_VALVE_ALERT_TIMEOUT FIELD -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="acValveAlertTimeout"
            [form]="form"
            label="{{ 'AC_VALVE_ALERT_TIMEOUT' | translate }}"
            [min]="0"
            description="{{
              'AC_VALVE_ALERT_TIMEOUT_LEAVE_BLANK_TEXT' | translate
            }}"
            suffix="{{ 'MINUTES' | translate }}"
          ></sc-number-input>
        </div>

        <!-- AC_VALVE_ALERT_DIFF_POINT FIELD -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="acValveAlertDiffPoint"
            [form]="form"
            label="{{ 'AC_VALVE_ALERT_DIFF_POINT' | translate }}"
            [min]="0"
            description="{{
              'AC_VALVE_ALERT_DIFF_POINT_LEAVE_BLANK_TEXT' | translate
            }}"
          ></sc-number-input>
        </div>

        <!-- DEVICE_ALERT_EMAIL_ENABLED -->
        <div class="ui-g-12">
          <sc-checkbox-input
            controlName="isDeviceAlertEmailEnabled"
            [form]="form"
            label="{{ 'DEVICE_ALERT_EMAIL_ENABLED' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- DEVICE_ALERT_EMAIL_RECEIVERS -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="deviceAlertEmailReceivers"
            [form]="form"
            label="{{ 'DEVICE_ALERT_EMAIL_RECEIVERS' | translate }}"
            [options]="selectItems?.siteUsers"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>

        <!-- DEVICE_ALERT_SMS_ENABLED -->
        <div class="ui-g-12">
          <sc-checkbox-input
            controlName="isDeviceAlertSmsEnabled"
            [form]="form"
            label="{{ 'DEVICE_ALERT_SMS_ENABLED' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- DEVICE_ALERT_SMS_RECEIVERS -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="deviceAlertSmsReceivers"
            [form]="form"
            label="{{ 'DEVICE_ALERT_SMS_RECEIVERS' | translate }}"
            [options]="selectItems?.siteUsers"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>
      </div>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonClass="ui-button-danger"
          buttonIcon="fa fa-trash"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="submitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</ng-template>
