import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Location } from '../models/location';
import { LocationRequest } from '../models/location-request';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class LocationsService {
  private apiUrl = config().apiUrl + '/locations';
  private backendApi = config().apiUrl + '/_backend/locations';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private verifyData(data: any): Location {
    const newData = { ...data };
    delete newData.id;
    delete newData.idx;
    delete newData.siteId;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getLocation(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`).pipe(catchError((error: any) => observableThrowError(error)));
  }

  getLocations(options: LocationRequest) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(this.apiUrl + queryParams).pipe(catchError((error: any) => observableThrowError(error)));
  }

  createLocation(data: Location) {
    const formData = this.sharedService.createFormData(data, ['floorplanImage']);
    return this.httpClient.post(this.apiUrl, formData);
  }

  createLocations(data: Location[]) {
    return this.httpClient.post(`${this.apiUrl}/bulk`, data);
  }

  updateLocation(id: number, data: Location) {
    const formData = this.sharedService.createFormData(this.verifyData(data), ['floorplanImage']);
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }

  deleteLocation(id: number, force: boolean = false) {
    let apiUrl = `${this.apiUrl}/${id}`;
    if (force === true) {
      apiUrl += '?force';
    }
    const formData = this.sharedService.createFormData({
      isActive: false,
      isDeleted: true,
    });
    return this.httpClient.patch(apiUrl, formData);
  }

  // updateFloorplanStructure(id, data) {
  //   return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  // }

  createLocationGenerator(data) {
    // NOTE: DOES NOT WORK YET
    return this.httpClient.post(`${this.backendApi}/generator`, data);
  }
}
