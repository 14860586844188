import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { App } from '../models/app';
import { ModalConfig } from '@sc/modal/modal-config';

import { AppsService } from '../services/apps.service';

import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';
import { AppActionCellComponent } from '../app-action-cell/app-action-cell.component';

@Component({
  selector: 'sc-apps-table',
  templateUrl: 'apps-table.component.html'
})
export class AppsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: App[];
  subscribers: { [key: string]: any };
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(private appsService: AppsService) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'appStyleTemplateId',
        headerName: 'APP_STYLE_TEMPLATE_ID',
        field: 'appStyleTemplateId'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        cellRendererFramework: AppActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'AppForm',
      options: { modalTitle: 'APP_FORM_TITLE' }
    };
    this.tableTitle = 'APPS';
    this.subscribers = {};

    this.fetchDataset();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.subscribers.DATASET = this.appsService
      .getApps()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
