<div [formGroup]="group" class="ui-g-12">
  <div class="ui-g">
    <!-- SEND_NOTIFICATION_AT FIELD -->
    <div class="ui-g-5">
      <sc-datetime-input
        controlName="sendNotificationAt"
        [form]="group"
        label="{{ 'SEND_NOTIFICATION_AT' | translate }}"
      ></sc-datetime-input>
    </div>

    <div class="ui-g-5 pt-4">
      <p-checkbox
        label="{{ 'SEND_ONLY_IF_LOCATION_IS_OCCUPIED' | translate }}"
        binary="true"
        [formControl]="group.get('sendOnlyIfLocationIsOccupied')"
      ></p-checkbox>
    </div>

    <div class="ui-g-2 pt-4">
      <button
        pButton
        type="button"
        class="ui-button-warning"
        icon ="fa fa-trash"
        (click)="remove()">
      </button>
    </div>
  </div>
</div>
