import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationsTemperatureAlertComponent } from './locations-temperature-alert.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationsTemperatureAlertComponent],
  entryComponents: [LocationsTemperatureAlertComponent],
  providers: []
})
export class LocationsTemperatureAlertModule {}
