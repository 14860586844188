import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-list-select',
  templateUrl: './list-select.component.html',
  styleUrls: ['./list-select.component.scss'],
})
export class ListSelectComponent implements OnInit {
  @Input()
  items: { label: string; value: any }[];

  @Output()
  selectItem = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
