import { NgModule } from '@angular/core';
import { AutomatioLogsOverviewModule } from './overview/overview.module';

import { AutomationLogsService } from './services/automation-logs.service';

const MODULES: any[] = [AutomatioLogsOverviewModule];

@NgModule({
  imports: MODULES,
  exports: MODULES,
  providers: [AutomationLogsService]
})
export class AutomationLogsModule {}
