import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as widgetsAction from '../actions/widgets.action';
import { WidgetService } from '@app/core2/widget/widget.service';

@Injectable()
export class WidgetsEffects {
  constructor(private action$: Actions, private widgetService: WidgetService) {}

  @Effect()
  saveCustomSetting$ = this.action$.pipe(
    ofType(widgetsAction.SAVE_CUSTOM_SETTING),
    map((action: widgetsAction.SaveCustomSetting) => action.payload),
    switchMap(customWidget => {
      return this.widgetService.update(customWidget.id, customWidget).pipe(
        map(
          result => new widgetsAction.SaveCustomSettingSuccess(customWidget)
        ),
        catchError(error =>
          of(new widgetsAction.SaveCustomSettingFail(error))
        )
      );
    })
  );
}
