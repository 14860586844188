import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeviceType } from '../models/device-type';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class DeviceTypesService {
  private apiUrl = config().apiUrl + '/deviceTypes';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  private verifyData(data: any): DeviceType {
    const newData = Object.assign({}, data);
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getDeviceType(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getDeviceTypes() {
    return this.httpClient.get(this.apiUrl);
  }

  createDeviceType(data: DeviceType) {
    const formData = this.sharedService.createFormData(data, ['icon']);
    return this.httpClient.post(this.apiUrl, formData);
  }

  updateDeviceType(id: number, data: DeviceType) {
    const formData = this.sharedService.createFormData(
      this.verifyData(data),
      ['icon']
    );
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }

  deleteDeviceType(id: number, force: boolean = false) {
    let apiUrl = `${this.apiUrl}/${id}`;
    if (force === true) {
      apiUrl += '?force';
    }
    const formData = this.sharedService.createFormData({
      isActive: false,
      isDeleted: true
    });
    return this.httpClient.patch(apiUrl, formData);
  }
}
