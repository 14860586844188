import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { SettingWidgetActionCellComponent } from './setting-widget-action-cell/setting-widget-action-cell.component';
import { SettingWidgetFormComponent } from './setting-widget-form/setting-widget-form.component';
import { SettingWidgetsTableComponent } from './setting-widgets-table/setting-widgets-table.component';

import { SettingWidgetsService } from './services/setting-widgets.service';
import { SettingWidgetFormService } from './services/setting-widget-form.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [SettingWidgetActionCellComponent, SettingWidgetFormComponent, SettingWidgetsTableComponent],
  entryComponents: [SettingWidgetActionCellComponent, SettingWidgetFormComponent, SettingWidgetsTableComponent],
  providers: [SettingWidgetsService, SettingWidgetFormService],
})
export class SettingWidgetsModule {}
