import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomFunction } from '../models/custom-function';
import { config } from '@app/config';

@Injectable()
export class CustomFunctionsService {
  private apiUrl = config().apiUrl + '/customFunctions';

  constructor(private httpClient: HttpClient) {}

  /**
   * Delete properties that are not allowed on API
   * @param data
   */
  private verifyData(data: CustomFunction): CustomFunction {
    const newData = { ...data };
    delete newData.id;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    return newData;
  }

  getCustomFunction(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getCustomFunctions() {
    return this.httpClient.get(this.apiUrl);
  }

  getCustomFunctionsOfCompany(companyId) {
    return this.httpClient.get(`${this.apiUrl}?cid=${companyId}`);
  }

  createCustomFunction(data: CustomFunction) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateCustomFunction(id: number, data: CustomFunction) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteCustomFunction(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      is_active: false,
      is_deleted: true,
    });
  }
}
