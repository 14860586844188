import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ProtocolController } from '../models/protocol-controller';

import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { ProtocolControllersService } from '../services/protocol-controllers.service';
import { SharedService } from '@shared/shared.service';

import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-protocol-controller-form',
  templateUrl: 'protocol-controller-form.component.html'
})
export class ProtocolControllerFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: ProtocolController;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;

  isSubmitting: boolean;
  fetchingState: number;

  selectItems: { [key: string]: SelectItem[] } = {};
  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private deviceProtocolsService: DeviceProtocolsService,
    private protocolControllersService: ProtocolControllersService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.subscribers = {};
    this.fetchingState = 0;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchProtocolController(this.data.id);
    }

    this.fetchDeviceProtocols();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDeviceProtocols() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_PROTOCOLS = this.deviceProtocolsService
      .getDeviceProtocols()
      .subscribe((result: any) => {
        this.selectItems.protocols = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted),
          false
        );
        this.fetchingState--;
      });
  }

  fetchProtocolController(id: number) {
    this.fetchingState++;
    this.subscribers.GET_PROTOCOL_CTRLS = this.protocolControllersService
      .getProtocolController(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      protocolId: [null, Validators.required],
      isActive: false,
      isDeleted: false
    });
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(data: ProtocolController) {
    this.subscribers.CREATE_PROTOCOL_CTRL = this.protocolControllersService
      .createProtocolController(data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  update(data: ProtocolController) {
    this.subscribers.UPDATE_PROTOCOL_CTRL = this.protocolControllersService
      .updateProtocolController(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE_PROTOCOL_CTRL = this.protocolControllersService
      .deleteProtocolController(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('PROTOCOL_CONTROLLER');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
