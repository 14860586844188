import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

// Components
import { ScenesTableComponent } from './scenes-table/scenes-table.component';
import { SceneActionCellComponent } from './scene-action-cell/scene-action-cell.component';
import { SceneFormComponent } from './scene-form/scene-form.component';
import { CommandFormComponent } from './scene-form/command-form/command-form.component';
import { CommandParameterFormComponent } from './scene-form/parameter-form/parameter-form.component';

// Services
import { ScenesService } from './services/scenes.service';
import { SceneFormsService } from './services/scene-forms.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    CommandFormComponent,
    CommandParameterFormComponent,
    SceneActionCellComponent,
    SceneFormComponent,
    ScenesTableComponent
  ],
  entryComponents: [
    SceneActionCellComponent,
    SceneFormComponent,
    ScenesTableComponent
  ],
  providers: [ScenesService, SceneFormsService]
})
export class ScenesModule {}
