<p-panel [toggleable]="true" styleClass="my-3">
  <p-header>
    {{ "LOCATION" | translate }}#{{ formId + 1 }} -
    {{ form.value?.description }}
  </p-header>

  <!-- LOCATION -->
  <div [formGroup]="form" class="ui-g">
    <!-- DESCRIPTION FIELD -->
    <div class="ui-g-5">
      <sc-text-input
        controlName="description"
        [form]="form"
        label="{{ 'DESCRIPTION' | translate }}"
      ></sc-text-input>
    </div>

    <!-- IS_ACTIVE FIELD -->
    <div class="ui-g-5 pt-4">
      <sc-checkbox-input
        controlName="isActive"
        [form]="form"
        label="{{ 'ACTIVE' | translate }}"
      ></sc-checkbox-input>
    </div>

    <!-- DEVICES -->
    <div class="ui-g-12 ui-g-nopad my-3">
      <p-panel [toggleable]="true">
        <p-header> {{ "DEVICES" | translate }} </p-header>

        <div class="ui-g">
          <div class="ui-g-3">
            <strong> {{ "TYPE" | translate }} </strong>
          </div>
          <div class="ui-g-3">
            <strong> {{ "NAME" | translate }} </strong>
          </div>
          <div class="ui-g-3">
            <strong> {{ "ASSOCIATION" | translate }} </strong>
          </div>
          <div class="ui-g-3">
            <strong> {{ "ACTIVE" | translate }} </strong>
          </div>
        </div>

        <ng-container formArrayName="devices">
          <ng-container
            *ngFor="let device of deviceForms.controls; let i = index"
            [formGroupName]="i"
          >
            <sc-device-form
              [form]="device"
              [formId]="i"
              [data]="getDataPassThrough(i)"
              (applyAllChecked)="onDeviceApplyAllChecked($event)"
            ></sc-device-form>
          </ng-container>
        </ng-container>
      </p-panel>
    </div>

    <!-- RULES -->
    <div class="ui-g-12 ui-g-nopad my-3">
      <ng-container formArrayName="rules">
        <ng-container
          *ngFor="let rule of ruleForms.controls; let i = index"
          [formGroupName]="i"
        >
          <sc-rule-form
            [form]="rule"
            [formId]="i"
            [data]="getDataPassThrough(i)"
            (applyAllChecked)="onRuleApplyAllChecked($event)"
          ></sc-rule-form>
        </ng-container>
      </ng-container>
    </div>

    <!-- CHILDREN -->
    <div class="ui-g-12 ui-g-nopad" *ngIf="childrenForms">
      <ng-container formArrayName="children">
        <ng-container
          *ngFor="let location of childrenForms.controls; let i = index"
          [formGroupName]="i"
        >
          <sc-location-form
            [form]="location"
            [formId]="i"
            [data]="getDataPassThrough(i)"
            (applyToAllDevices)="onChildDeviceApplyAllChecked($event)"
            (applyToAllRules)="onChildRuleApplyAllChecked($event)"
          ></sc-location-form>
        </ng-container>
      </ng-container>
    </div>
  </div>
</p-panel>
