import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  IGuestRequestPreset,
  IGuestRequestPresetCategory,
  IGuestRequestPresetMultiLanguage
} from './guest-request-preset.interface';

import { config } from '@app/config';

@Injectable()
export class GuestRequestPresetsService {
  private apiUrl = config().apiUrl + '/guestRequestPresets';

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {}

  private verifyData(data: any): IGuestRequestPreset {
    const newData = { ...data };
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  // ____ CRUD
  get(id?: number, filters?: any) {
    let queryParams = '';
    let isFirstParam = true;
    if (filters) {
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          queryParams += (isFirstParam ? '?' : '&') + key + '=' + filters[key];
          if (isFirstParam) {
            isFirstParam = false;
          }
        }
      }
    }
    return this.httpClient.get(
      this.apiUrl + (id ? '/' + id : '') + queryParams
    );
  }

  create(data: IGuestRequestPreset) {
    return this.httpClient.post(this.apiUrl, data);
  }

  update(id: number, data: IGuestRequestPreset) {
    return this.httpClient.patch(this.apiUrl + '/' + id, this.verifyData(data));
  }

  delete(id: number) {
    return this.httpClient.patch(this.apiUrl + '/' + id, {
      isActive: false,
      isDeleted: true
    });
  }

  // ____ FORMS
  initGuestRequestPresetCategoryFormGroup() {
    return this.formBuilder.group({
      id: ['', Validators.required]
    });
  }

  setGuestRequestPresetCategoryFormArray(
    form: FormGroup,
    data: IGuestRequestPresetCategory[]
  ) {
    const formGroups = data.map(item => this.formBuilder.group(item));
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('categories', formArray);
  }

  initGuestRequestPresetMultiLanguageFormGroup() {
    return this.formBuilder.group({
      locale: ['', Validators.required],
      text: ['', Validators.required]
    });
  }

  setGuestRequestPresetMultiLanguageFormArray(
    form: FormGroup,
    data: IGuestRequestPresetMultiLanguage[],
    controlName: string
  ) {
    const formGroups = data.map(item => this.formBuilder.group(item));
    const formArray = this.formBuilder.array(formGroups);
    form.setControl(controlName, formArray);
  }
}
