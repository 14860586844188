<div [formGroup]="group" class="ui-g-12">
  <div class="ui-g-2 ui-md-8 ui-lg-2">
    <sc-text-input
      controlName="name"
      [form]="group"
      label="{{ 'NAME' | translate }}"
    ></sc-text-input>
  </div>

  <div class="ui-g-2 ui-md-2 ui-lg-2 mt-4">
    <p-toggleButton
      formControlName="isActive"
      offIcon="fa fa-square-o"
      offLabel="{{ 'ACTIVE' | translate }}"
      onIcon="fa fa-check-square-o"
      onLabel="{{ 'ACTIVE' | translate }}"
    ></p-toggleButton>
  </div>

  <div class="text-right ui-g-2 ui-md-2 ui-lg-2 mt-4">
    <button
      pButton
      type="button"
      class="ui-button-success"
      pTooltip="{{ 'ADD' | translate }} {{ 'ELEMENT_STYLE' | translate }}"
      icon="fa fa-plus"
      (click)="addStyle()"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-warning"
      icon="fa fa-trash"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'ELEMENT' | translate }}"
      appendTo="body"
      tooltipPosition="top"
      (click)="remove()"
    ></button>
  </div>

  <div class="ui-g-6 ui-md-12 ui-lg-6 ui-g-no-pad">
    <div formArrayName="styles" class="ui-g-12 p-0">
      <div *ngFor="let s of styles.controls; let i=index" [formGroupName]="i">
        <sc-element-style-form-group
          [group]="s"
          [groupId]="i"
          (removed)="removeStyle(i)"
          [variables]="availableVars"
        ></sc-element-style-form-group>
      </div>
    </div>
  </div>
</div>
