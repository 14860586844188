import * as fromAuth from '../actions/auth.action';

export interface AuthState {
  error: string;
  token: string;
  twoFactorAuthMethod: string;
  twoFactorAuthVerified: boolean;
  verified: boolean;
  verifying: boolean;
}

export const initialState = {
  error: null,
  token: null,
  twoFactorAuthMethod: null,
  twoFactorAuthVerified: false,
  verified: false,
  verifying: false,
};

export function reducer(state = initialState, action: fromAuth.AuthAction): AuthState {
  switch (action.type) {
    case fromAuth.VERIFY_TWO_FACTOR_AUTH: {
      return {
        ...state,
        error: null,
        verifying: true,
      };
    }

    case fromAuth.VERIFY_TWO_FACTOR_AUTH_FAIL: {
      const error = action.payload;
      return {
        ...state,
        verifying: false,
        twoFactorAuthVerified: false,
        error,
      };
    }

    case fromAuth.VERIFY_TWO_FACTOR_AUTH_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        token: user.token,
        verifying: false,
        twoFactorAuthVerified: true,
      };
    }

    case fromAuth.VERIFY_USER: {
      return {
        ...state,
        error: null,
        verifying: true,
      };
    }

    case fromAuth.VERIFY_USER_FAIL: {
      const error = action.payload;
      return {
        ...state,
        verifying: false,
        verified: false,
        error,
      };
    }

    case fromAuth.VERIFY_USER_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        token: user.token,
        twoFactorAuthMethod: user.twoFactorAuthMethod,
        verifying: false,
        verified: true,
      };
    }

    case fromAuth.VERIFY_USER_2: {
      return {
        ...state,
        error: null,
        verifying: true,
      };
    }

    case fromAuth.VERIFY_USER_2_FAIL: {
      const error = action.payload;
      return {
        ...state,
        verifying: false,
        verified: false,
        error,
      };
    }

    case fromAuth.VERIFY_USER_2_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        token: user.token,
        // twoFactorAuthMethod: user.twoFactorAuthMethod,
        verifying: false,
        verified: true,
      };
    }
  }

  return state;
}

export const getAuthError = (state: AuthState) => state.error;
export const getAuthToken = (state: AuthState) => state.token;
export const getAuthVerified = (state: AuthState) => state.verified;
export const getAuthVerifying = (state: AuthState) => state.verifying;
export const getAuthTwoFactorAuthMethod = (state: AuthState) => state.twoFactorAuthMethod;
export const getAuthTwoFactorAuthVerified = (state: AuthState) => state.twoFactorAuthVerified;
