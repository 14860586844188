import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { LocationType } from '../models/location-type';
import { ModalConfig } from '@sc/modal/modal-config';

import { LocationTypesService } from '../services/location-types.service';
import { LocationTypeActionCellComponent } from '../location-type-action-cell/location-type-action-cell.component';
import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-location-types-table',
  templateUrl: 'location-types-table.component.html'
})
export class LocationTypesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: LocationType[];
  subscribers: { [key: string]: any };
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(private locationTypesService: LocationTypesService) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: LocationTypeActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'LocationTypeForm',
      options: { modalTitle: 'LOCATION_TYPE_FORM_TITLE' }
    };
    this.tableTitle = 'LOCATION_TYPES';
    this.subscribers = {};

    this.fetchDataset();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.subscribers.GET_DATASET = this.locationTypesService
      .getLocationTypes()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
