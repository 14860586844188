import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { ReportTemplate } from '../interfaces/report-template';
import { ReportTemplatesService } from '../services/report-templates.service';
import { SharedService } from '@shared/shared.service';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { ReportTemplateActionCellComponent } from '../report-template-action-cell/report-template-action-cell.component';

@Component({
  selector: 'sc-report-templates-table',
  templateUrl: './report-templates-table.component.html',
  styleUrls: ['./report-templates-table.component.scss'],
})
export class ReportTemplatesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: ReportTemplate[];
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string;

  private subscribers: { [key: string]: any } = {};

  constructor(private reportTemplatesService: ReportTemplatesService, private sharedService: SharedService) {}

  ngOnInit() {
    this.initTable();
    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.fetchDataset = this.reportTemplatesService.getReportTemplates().subscribe((result: any) => {
      this.dataset = result.data;
    });
  }

  private createColumns() {
    return [
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'priority',
        headerName: 'PRIORITY',
        field: 'priority',
      },
      {
        colId: 'idx',
        headerName: 'IDX',
        field: 'idx',
        hide: true,
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
        hide: true,
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'is_active',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'is_deleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: ReportTemplateActionCellComponent,
      },
    ];
  }

  private initTable() {
    this.columns = this.createColumns();
    this.tableTitle = 'REPORT_TEMPLATES';
    this.formConfig = {
      name: 'ReportTemplateForm',
      options: { modalTitle: 'REPORT_TEMPLATE_FORM_TITLE' },
    };
  }

  afterTableInit(table: GridOptions) {
    this.table = table;
  }
}
