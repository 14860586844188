import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { DeviceModel } from '../models/device-model';
import { ModalConfig } from '@sc/modal/modal-config';

import { DeviceModelsService } from '../services/device-models.service';

import { SharedService } from '@shared/shared.service';

import { DeviceModelActionCellComponent } from '../device-model-action-cell/device-model-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-device-models-table',
  templateUrl: './device-models-table.component.html'
})
export class DeviceModelsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: DeviceModel[];
  formConfig: ModalConfig;
  tableTitle = 'DEVICE_MODELS';
  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceModelsService: DeviceModelsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.columns = this.createColumns();

    this.formConfig = {
      name: 'DeviceModelForm',
      options: { modalTitle: 'DEVICE_MODEL_FORM_TITLE', fullScreen: true }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.DATASET = this.deviceModelsService
      .getDeviceModels()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'model',
        headerName: 'MODEL',
        field: 'model'
      },
      {
        colId: 'specification',
        headerName: 'SPECIFICATION',
        field: 'specification'
      },
      {
        colId: 'deviceTypeName',
        headerName: 'DEVICE_TYPE',
        field: 'deviceTypeName'
      },
      {
        colId: 'brandName',
        headerName: 'BRAND',
        field: 'brandName'
      },
      {
        colId: 'protocolName',
        headerName: 'PROTOCOL',
        field: 'protocolName'
      },
      {
        colId: 'automationVersion',
        headerName: 'AUTOMATION_VERSION',
        field: 'automationVersion'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceModelActionCellComponent
      }
    ];
  }
}
