import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { config } from '@app/config';

@Component({
  selector: 'sc-icon-cell',
  templateUrl: 'icon-cell.component.html',
})
export class IconCellComponent implements ICellRendererAngularComp {
  iconUrl: string;

  private cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;

    if (params.value) {
      this.iconUrl = `${config().s3Url}/${params.value}`;
    }
  }

  refresh(): boolean {
    return false;
  }
}
