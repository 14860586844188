import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import {
  RuleSchedulerJob,
  RuleSchedulerJobRecurringOpt
} from '../models/rule-scheduler-job';
import { Site } from '../../sites/models/site';

import { RuleSchedulerJobFormService } from '../services/rule-scheduler-job-form.service';
import { RuleSchedulerJobsService } from '../services/rule-scheduler-jobs.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-scheduler-job-form',
  templateUrl: 'rule-scheduler-job-form.component.html'
})
export class RuleSchedulerJobFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: RuleSchedulerJob;
  form: FormGroup;

  editMode: boolean;
  errorMessage: string;
  isSubmitting: boolean;
  selectedSite: Site;
  subscribers: { [key: string]: any };
  fetchingState: number;
  optionData: { [key: string]: any };

  constructor(
    private ruleSchedulerJobFormService: RuleSchedulerJobFormService,
    private ruleSchedulerJobsService: RuleSchedulerJobsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchingState = 0;
    this.subscribers = {};
    this.selectedSite = this.sharedService.selectedSite;
    this.optionData = this.data.optionData || {};
    this.optionData.sites = this.sharedService.selectItems.sites;
    this.optionData.sitesSource = this.sharedService.sourceData.sites;

    if (this.data.value) {
      // EDIT MODE
      this.editMode = true;
      this.fetchRuleSchedulerJob(this.data.value.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key)) {
        if (this.subscribers[key]) {
          this.subscribers[key].unsubscribe();
        }
      }
    }
  }

  fetchRuleSchedulerJob(id: number) {
    this.fetchingState++;
    this.subscribers.GET_RULE_SCHEDULER_JOB = this.ruleSchedulerJobsService
      .getRuleSchedulerJob(id)
      .subscribe((result: any) => {
        this.formData = result.data;

        // CONVERT `DATE STRING` TO `JS DATE OBJECT`
        if (this.formData.disableJobFrom) {
          this.formData.disableJobFrom = this.sharedService.parseDateStringToDateObject(
            this.formData.disableJobFrom
          );
        }
        if (this.formData.disableJobTo) {
          this.formData.disableJobTo = this.sharedService.parseDateStringToDateObject(
            this.formData.disableJobTo
          );
        }

        if (this.formData.nonRecurringOpts) {
          this.formData.nonRecurringOpts = this.sharedService.jsonParse(
            this.formData.nonRecurringOpts
          );

          if (
            this.formData.nonRecurringOpts &&
            this.formData.nonRecurringOpts.triggerAt
          ) {
            this.formData.nonRecurringOpts.triggerAt = this.sharedService.parseDateStringToDateObject(
              this.formData.nonRecurringOpts.triggerAt
            );
          }
        }

        if (this.formData.recurringOpts) {
          this.formData.recurringOpts = this.sharedService.jsonParse(
            this.formData.recurringOpts
          );
        }

        if (this.formData.customOpts) {
          this.formData.customOpts = this.sharedService.jsonParse(
            this.formData.customOpts
          );
        }

        if (this.formData.locations) {
          this.formData.locations = this.sharedService.jsonParse(
            this.formData.locations
          );
        }

        if (this.formData.ruleTemplates) {
          this.formData.ruleTemplates = this.sharedService.jsonParse(
            this.formData.ruleTemplates
          );
          this.formData.ruleTemplates = this.formData.ruleTemplates
            .map((rt: any) => rt.ruleTemplateId)
            .filter(rt => typeof rt !== 'undefined');
        }

        this.form.patchValue(this.formData);
        this.ruleSchedulerJobFormService.setLocationFormArray(
          this.form,
          this.formData.locations
        );
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.ruleSchedulerJobFormService.initRuleSchedulerJobForm();
    this.onFormValueChange();

    if (this.editMode === true) {
      // this.form.addControl('id', new FormControl({ value: null, disabled: true }));
      // this.form.addControl('ruleSchedulerJobId', new FormControl({ value: null, disabled: true }));
    } else {
      this.form.patchValue({
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id
      });
    }
  }

  onFormValueChange() {
    this.subscribers.WATCH_FREQUENCY = this.form
      .get('frequency')
      .valueChanges.subscribe(value => {
        if (value) {
          this.form.get('customOpts').reset();
          this.form.get('nonRecurringOpts').reset();
          this.form.get('recurringOpts').reset();
        }
      });

    this.subscribers.WATCH_CRON_PATTERN = this.form
      .get('customOpts.cronPattern')
      .valueChanges.subscribe(value => {
        if (value) {
          this.updateCronPattern(value);
        }
      });

    this.subscribers.WATCH_RECURRING_OPTS = this.form
      .get('recurringOpts')
      .valueChanges.subscribe(value => {
        if (value) {
          this.updateCronPattern(this.generateCron(value));
        }
      });
  }

  // ____ GERATE CRON PATTERN
  generateCron(options: RuleSchedulerJobRecurringOpt) {
    switch (options.intervalUnit) {
      case 'minute':
        return `00 ${
          options.minuteInterval ? '*/' + options.minuteInterval : '*'
        } * * * *`;

      case 'hour':
        return `00 * ${
          options.hourInterval ? '*/' + options.hourInterval : '*'
        } * * *`;

      case 'day':
        return `00 ${options.timeMinute || '*'} ${options.timeHour ||
          '*'} * * *`;

      case 'week':
        let weekday: any = [];
        if (options.sunday === true) {
          weekday.push(0);
        }
        if (options.monday === true) {
          weekday.push(1);
        }
        if (options.tuesday === true) {
          weekday.push(2);
        }
        if (options.wednesday === true) {
          weekday.push(3);
        }
        if (options.thursday === true) {
          weekday.push(4);
        }
        if (options.friday === true) {
          weekday.push(5);
        }
        if (options.saturday === true) {
          weekday.push(6);
        }
        weekday = weekday.sort().join(',');
        return `00 ${options.timeMinute || '*'} ${options.timeHour ||
          '*'} * * ${weekday || '*'}`;

      case 'month':
        return `00 ${options.timeMinute || '*'} ${options.timeHour ||
          '*'} * * *`;

      default:
        return null;
    }
  }

  // ____ UPDATE `MAIN CRON PATTER` BY `CUSTOM CRON PATTERN`
  updateCronPattern(pattern: string) {
    this.form.patchValue({
      cronPattern: pattern
    });
  }

  // ____ UPDATE TIMEZONE BY SITE'S TIMEZONE
  updateTimezone() {
    const siteId = this.form.get('siteId').value;
    // console.log(siteId);
    if (this.optionData.sitesSource) {
      const site = this.optionData.sitesSource.find(s => s.id === siteId);
      // console.log(site);
      if (site) {
        this.form.patchValue({
          timezone: site.timezone
        });
      }
    }
  }

  // ____ LOCATIONS FORM ARRAY HANDLER
  get locationFormArray(): FormArray {
    return this.form.get('locations') as FormArray;
  }
  addLocation() {
    const control = <FormArray>this.form.controls['locations'];
    control.push(this.ruleSchedulerJobFormService.initLocationFormGroup());
  }
  removeLocation(i: number) {
    const control = <FormArray>this.form.controls['locations'];
    control.removeAt(i);
  }

  // ____ FORM SUBMIT HANDLER
  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      // ____ ADD TIMEZONE INTO FORM
      this.updateTimezone();
      const formData = this.form.getRawValue();

      // ____ DATE PARSING
      if (formData.disableJobFrom) {
        formData.disableJobFrom = this.sharedService.parseDateObjectToDateString(
          formData.disableJobFrom
        );
      }
      if (formData.disableJobTo) {
        formData.disableJobTo = this.sharedService.parseDateObjectToDateString(
          formData.disableJobTo
        );
      }
      if (formData.nonRecurringOpts && formData.nonRecurringOpts.triggerAt) {
        formData.nonRecurringOpts.triggerAt = this.sharedService.parseDateObjectToDateString(
          formData.nonRecurringOpts.triggerAt
        );
      }
      // ____ RULE TEMPLATES PARSING
      if (formData.ruleTemplates) {
        formData.ruleTemplates = formData.ruleTemplates.map(rt => ({
          ruleTemplateId: rt
        }));
      }

      // ____ API CALL
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(formData) {
    delete formData.id;
    delete formData.ruleSchedulerJobId;
    this.ruleSchedulerJobsService
      .createRuleSchedulerJob(formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update(formData) {
    this.ruleSchedulerJobsService
      .updateRuleSchedulerJob(this.formData.id, formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.ruleSchedulerJobsService
      .deleteRuleSchedulerJob(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify('Success', result.message, 'success');
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
