<ng-container [formGroup]="form">
  <p-panel header="{{ 'DATE_TIME_RANGE' | translate }}" [toggleable]="true">
    <div class="ui-g mb-2">
      <div class="ui-g-12 ui-g-nopad">
        <sc-quick-date-select
          [selectInterval]="true"
          [showLabel]="true"
          (valueChange)="onDateSelect($event)"
        ></sc-quick-date-select>
      </div>
    </div>
  </p-panel>
  <sc-graph-form [form]="form" [selectItems]="selectItems"></sc-graph-form>
</ng-container>
