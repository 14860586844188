import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'sc-tree-multi-select',
  templateUrl: './tree-multi-select.component.html',
  styleUrls: ['./tree-multi-select.component.scss'],
})
export class TreeMultiSelectComponent implements OnInit, OnChanges {
  @Input() maxSelectedDisplay: number;
  @Input() options: TreeNode[];
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() description: string;
  @Input() warning: string;
  @Input() label: string;
  @Input() showLabel: boolean;
  @Input() propagateSelectionDown: boolean = true;

  inputId: string;
  selected: TreeNode[];
  displayItems: any[];

  constructor() {}

  ngOnInit() {
    this.maxSelectedDisplay = this.maxSelectedDisplay || 3;
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-tree-multi-select-' + this.controlName + new Date().getTime();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Option Items changed
    if (changes.options && changes.options.currentValue) {
      // clear selected items
      this.selected = [];
      this.displayItems = [];

      // reset FormGroup or FormControl value if exists
      if (this.control) {
        this.control.reset();
      } else if (this.form && this.controlName) {
        this.form.controls[this.controlName].reset();
      }
    }
  }

  nodeSelect(event) {
    // get selected values
    const selectedValues = this.selected.map((item) => item.data);

    // set the FormGroup or FormControl value if exists
    if (this.form && this.controlName) {
      this.form.controls[this.controlName].setValue(selectedValues);
    } else if (this.control) {
      this.control.setValue(selectedValues);
    }

    // set display items
    this.displayItems = [];
    for (let i = 0; i < this.maxSelectedDisplay; i++) {
      if (this.selected[i]) {
        this.displayItems.push(this.selected[i].label);
      }
    }
  }
}
