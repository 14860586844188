import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

// Components
import { LocationTemplatesTableComponent } from './location-templates-table/location-templates-table.component';
import { LocationTemplateActionCellComponent } from './location-template-action-cell/location-template-action-cell.component';
import { LocationTemplateFormComponent } from './location-template-form/location-template-form.component';
import { LocationFormComponent } from './location-template-form/location-form/location-form.component';
import { RuleTemplateFormComponent } from './location-template-form/rule-template-form/rule-template-form.component';
import { DeviceFormComponent } from './location-template-form/device-form/device-form.component';
import { ChildFormComponent } from './location-template-form/child-form/child-form.component';

// Services
import { LocationTemplatesService } from './services/location-templates.service';
import { LocationTemplateFormsService } from './services/location-template-forms.service';

const COMPONENTS = [
  LocationTemplatesTableComponent,
  LocationTemplateActionCellComponent,
  LocationTemplateFormComponent,
  LocationFormComponent,
  RuleTemplateFormComponent,
  DeviceFormComponent,
  ChildFormComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [LocationTemplatesService, LocationTemplateFormsService]
})
export class LocationTemplatesModule {}
