import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Device } from '../models/device';
import { DeviceRequest } from '../models/device-request';
import { CeosDevice } from '../models/ceos-device';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Injectable()
export class DevicesService {
  private apiUrl = config().apiUrl + '/devices';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private verifyData(data: any): Device {
    const newData = { ...data };
    delete newData.id;
    delete newData.idx;
    // delete newData.deviceTypeId;
    delete newData.companyId;
    delete newData.siteId;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    if (newData.virtualDevices && newData.virtualDevices.length) {
      for (const vd of newData.virtualDevices) {
        // delete vd.deviceTypeId;
        delete vd.companyId;
        delete vd.siteId;
      }
    }
    return newData;
  }

  getCeosDevices(options?: DeviceRequest) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient
      .get(this.apiUrl + '/ceos' + queryParams)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getCeosDevice(idx: string, options: DeviceRequest = {}) {
    if (!options.siteId) {
      const site = this.sharedService.selectedSite;
      options.siteId = site.id;
    }
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient
      .get(`${this.apiUrl}/ceos/${idx}${queryParams}`)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  updateCeosDevice(idx: string, data: CeosDevice) {
    return this.httpClient.patch(`${this.apiUrl}/ceos/${idx}`, this.verifyData(data));
  }

  getDeviceDetails(id: number) {
    const site = this.sharedService.selectedSite;
    return this.httpClient
      .get(`${this.apiUrl}/${id}/details?siteId=${site.id}`)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getDevice(id: number, includeChildren = false) {
    const site = this.sharedService.selectedSite;
    let apiUrl = `${this.apiUrl}/${id}?siteId=${site.id}`;
    if (includeChildren === true) {
      apiUrl += '&children';
    }
    return this.httpClient.get(apiUrl).pipe(catchError((error: any) => observableThrowError(error)));
  }

  getDevices(options?: DeviceRequest) {
    if (!options.siteId) {
      const site = this.sharedService.selectedSite;

      if (site && site.id) {
        options.siteId = site.id;
      } else {
        return new Observable<{}>();
      }
    } else {
      if (Array.isArray(options.siteId) && options.siteId.length) {
        if (options.siteId.length === 1) {
          options.siteId = options.siteId[0] as number;
        }
      }
    }

    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(this.apiUrl + queryParams).pipe(catchError((error: any) => observableThrowError(error)));
  }

  createDevice(data: Device) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateDevice(id: number, data: Device) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteDevice(id: number, force: boolean = false) {
    let apiUrl = `${this.apiUrl}/${id}`;
    if (force === true) {
      apiUrl += '?force';
    }
    return this.httpClient.patch(apiUrl, {
      isActive: false,
      isDeleted: true,
    });
  }

  deviceValueUnit(deviceTypeKey: string) {
    switch (deviceTypeKey) {
      case 'bl':
      case 'hs':
        return '%';

      case 'bs':
        return 'lux';

      case 'ts':
        return '°C';

      case 'ws':
        return 'w/h';

      default:
        return '';
    }
  }

  deviceValueMapper(value = '', deviceModel) {
    let currentValue = value;

    if (typeof value !== 'undefined' && value !== '' && value !== null) {
      if (deviceModel && deviceModel.mappingValues && deviceModel.mappingValues.length) {
        // find mapping value
        for (const mv of deviceModel.mappingValues) {
          if (mv.key === value) {
            currentValue = mv.name;
            break;
          }
        }
      }

      // format number into 2 decimals
      if (typeof currentValue === 'number') {
        currentValue = this.sharedService.numberFormat(currentValue);
      }
    }

    return currentValue;
  }

  deviceValueLabel(deviceTypeKey: string) {
    switch (deviceTypeKey) {
      case 'acm':
        return 'Mode';
      case 'acsp':
        return 'Setpoint (Cooling)';
      case 'hvacsph':
        return 'Setpoint (heating)';
      case 'acf':
        return 'Fan Speed';
      case 'ts':
        return 'Temperature';
      case 'hs':
        return 'Humidity';
      case 'ms':
        return 'Motion';
      case 'ws':
        return 'Wattage';
      case 'osd':
        return 'Door';
      case 'osw':
        return 'Window';
      case 'lc':
        return 'Lighting';
      case 'bl':
        return 'Battery';
      case 'bs':
        return 'Brightness';
      case 'pc':
        return 'People counter';
      case 'pcg':
        return 'People counter global';
      case 'pcl':
        return 'People counter local';
      case 'pms':
        return 'Particulate matter sensor';
      case 'sdvs':
        return 'Social distance violation sensor';
    }
  }
}
