import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { Location } from '@widgets/locations/models/location';

import { EnergySavingsService } from '../services/energy-savings.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-saving-table',
  templateUrl: './location-saving-table.component.html',
  styles: ['./location-saving-table.component.scss']
})
export class LocationSavingTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: any[];
  tableTitle: string;
  table: GridOptions;
  toolbarItems: string[];

  isFetchingDataset = false;

  summaryRowData: {
    date: string;
    costSaved: number;
    kwhSaved: number;
  };

  private selectedLocation: Location;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private energySavingsService: EnergySavingsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.config.gridOptions = {
      floatingFilter: false,
      defaultColDef: {
        autoHeight: true,
        suppressFilter: true,
        suppressMenu: true,
        suppressSorting: true
      }
    };

    this.columns = [
      {
        colId: 'date',
        headerName: 'DATE',
        field: 'date',
        sort: 'desc',
        valueFormatter: params => {
          if (params.value === 'TOTAL') {
            return params.value;
          }
          return this.sharedService.dateFormat(params.value, 'DD.MM.YYYY');
        },
        pinnedRowCellRenderer: params => {
          return `
            <div class="text-right">
              <strong style="color:#222222">
                ${params.value}
              </strong>
            </div>
          `;
        }
      },
      {
        colId: 'kwhSaved',
        headerName: 'ENERGY_SAVED',
        field: 'kwhSaved',
        cellClass: 'text-right',
        valueFormatter: params =>
          this.sharedService.numberFormat(params.value, 3) + ' KW',
        valueGetter: params =>
          this.sharedService.numberFormat(params.data.kwhSaved, 3),
        pinnedRowCellRenderer: params => {
          let color = '#222222';
          if (params.data.kwhSaved > 0) {
            color = params.positiveColor;
          } else if (params.data.kwhSaved < 0) {
            color = params.negativeColor;
          }
          return `
            <strong style="color:${color}">
              ${params.valueFormatted}
            </strong>
          `;
        },
        pinnedRowCellRendererParams: {
          positiveColor: 'green',
          negativeColor: 'red'
        }
      },
      {
        colId: 'costSaved',
        headerName: 'COST_SAVED',
        field: 'costSaved',
        cellClass: 'text-right',
        valueFormatter: params =>
          this.sharedService.numberFormat(params.value) + ' THB',
        valueGetter: params =>
          this.sharedService.numberFormat(params.data.costSaved),
        pinnedRowCellRenderer: params => {
          let color = '#222222';
          if (params.data.costSaved > 0) {
            color = params.positiveColor;
          } else if (params.data.costSaved < 0) {
            color = params.negativeColor;
          }
          return `
            <strong style="color:${color}">
              ${params.valueFormatted}
            </strong>
          `;
        },
        pinnedRowCellRendererParams: {
          positiveColor: 'green',
          negativeColor: 'red'
        }
      }
    ];
    this.tableTitle = 'SAVING';
    this.toolbarItems = ['refresh', 'columns'];

    // get location details
    this.subscribers.LOCATION_DETAIL = this.store
      .select(fromStore.getLocationDetials)
      .subscribe(result => {
        if (result) {
          this.selectedLocation = result;
          this.fetchDataset();
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.isFetchingDataset = true;
    this.subscribers.GET_DATASET = this.energySavingsService
      .getLocationEnergySavings(this.selectedLocation.id)
      .subscribe((result: any) => {
        this.dataset = result.data;
        this.summaryRowData = {
          date: 'TOTAL',
          costSaved: 0,
          kwhSaved: 0
        };

        for (let i = 0; i < this.dataset.length; i++) {
          this.summaryRowData.costSaved += this.sharedService.numberFormat(
            this.dataset[i].costSaved
          );
          this.summaryRowData.kwhSaved += this.sharedService.numberFormat(
            this.dataset[i].kwhSaved
          );
        }

        this.setSummaryRow();
        this.isFetchingDataset = false;
      });
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.setSummaryRow();
  }

  setSummaryRow() {
    if (this.summaryRowData && this.table && this.table.api) {
      this.table.api.setPinnedTopRowData([this.summaryRowData]);
      this.table.api.setPinnedBottomRowData([this.summaryRowData]);
    }
  }
}
