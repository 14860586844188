import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { ReportTemplate } from '../interfaces/report-template';

@Injectable({
  providedIn: 'root',
})
export class ReportTemplatesService {
  private apiUrl = config().apiUrl + '/reportTemplates';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: ReportTemplate): ReportTemplate {
    const newData = { ...data };
    delete newData.id;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    return newData;
  }

  getReportTemplate(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getReportTemplates() {
    return this.httpClient.get(this.apiUrl);
  }

  getReportTemplatesOfCompany(companyId) {
    return this.httpClient.get(`${this.apiUrl}?cid=${companyId}`);
  }

  createReportTemplate(data: ReportTemplate) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateReportTemplate(id: number, data: ReportTemplate) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteReportTemplate(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      is_active: false,
      is_deleted: true,
    });
  }
}
