import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { Device } from '@widgets/devices/models/device';

interface CreateDevicesPayload {
  a?: Device;
  vdh?: Device;
  vds?: Device[];
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private apiUrl = config().apiUrl + '/devices';
  private integratorApi = config().apiUrl + '/_integrator/devices';

  constructor(private httpClient: HttpClient) {}

  createDevice(data: CreateDevicesPayload) {
    return this.httpClient.post(this.integratorApi, data);
  }

  createDevices(data: CreateDevicesPayload[]) {
    return this.httpClient.post(`${this.apiUrl}/bulk`, data);
  }

  linkDevices(automation: string, devices: string[]) {
    return this.httpClient.post(`${config().apiUrl}/public/devices/link`, { automation, devices });
  }

  unlinkDevices(devices: string[]) {
    return this.httpClient.post(`${config().apiUrl}/public/devices/unlink`, { devices });
  }
}
