import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { ActionItemButton, ActionItemConfirm, ActionItemModal } from '@sc/action-button/action-button.component';
// import { DevicesService } from '@widgets/devices/services/devices.service';
import { SharedService } from '@shared/shared.service';
import { MqttSettingsFormComponent } from '@widgets/users/mqtt-settings-form/mqtt-settings-form.component';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AutomationFormComponent } from '../automation-form/automation-form.component';

@Component({
  selector: 'sc-automation-action-cell',
  templateUrl: './automation-action-cell.component.html',
  styleUrls: ['./automation-action-cell.component.scss'],
})
export class AutomationActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];
  cell: any;
  data: any;
  buttonIcon: string = 'fa fa-ellipsis-v';

  private subscribers: { [key: string]: any } = {};

  constructor(
    // private devicesService: DevicesService,
    private sharedService: SharedService,
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  agInit(params: any): void {
    this.cell = params;
    this.data = this.cell.data;

    if (this.cell.buttonIcon) {
      this.buttonIcon = this.cell.buttonIcon;
    }

    this.actions = [
      {
        key: 'DETAILS',
        icon: 'fa fa-search',
        type: 'button',
        onClick: this.openDetailsModal.bind(this),
      },
    ];

    if (this.authService.isAuthorized('admin')) {
      this.actions.push({
        key: 'MQTT_SETTINGS',
        icon: 'fa fa-satellite-dish',
        type: 'button',
        onClick: this.openMqttSettingForm.bind(this),
      });
    }
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    // unsubscribe all subscribers
    this.sharedService.clearSubscribes(this.subscribers);
  }

  openDetailsModal() {
    const detailsModal: DynamicDialogRef = this.dialogService.open(AutomationFormComponent, {
      dismissableMask: true,
      showHeader: false,
      styleClass: 'no-padding-content',
      data: { automation: this.data, viewOnly: true },
    });
  }

  private openMqttSettingForm() {
    const mqttSettingForm: DynamicDialogRef = this.dialogService.open(MqttSettingsFormComponent, {
      closeOnEscape: true,
      dismissableMask: true,
      showHeader: false,
      styleClass: 'no-padding-content',
      data: {
        automation: this.data,
      },
    });

    this.subscribers.mqttSettingFormClosed = mqttSettingForm.onClose.subscribe((result) => {
      this.cell.context.parentComponent.updateRow({
        id: this.data.id,
        mqttPassword: result.password,
      });
    });
  }
}
