import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ModalConfig } from '@sc/modal/modal-config';
import { Site } from '@widgets/sites/models/site';
import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-make-up-my-room-alert',
  templateUrl: './make-up-my-room-alert.component.html',
  styleUrls: ['./make-up-my-room-alert.component.scss'],
})
export class MakeUpMyRoomAlertComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  alertClass: string;
  alertText: string;
  detailsModal: ModalConfig;
  isFetching = false;
  isFirstTime = true;
  subtitleText = 'REPORTED_AUTOMATICALLY';
  // titleText = 'MAKE_UP_MY_ROOM';
  widgetName: string = '';

  private data: any;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.getSelectedSite = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFetching = false;
        this.isFirstTime = true;
        this.fetchData();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchData() {
    if (this.isFetching) {
      return;
    }

    this.isFetching = true;
    this.subscribers.fetchData = this.widgetService.getMakeUpMyRoom(this.selectedSite.id).subscribe(
      (result: any) => {
        this.data = result.data;
        this.setDetailsModal(this.data.locations);
        this.setAlertDetails(this.data.count);
        this.isFetching = false;
        this.isFirstTime = false;
      },
      (error: any) => {
        this.isFetching = false;
      }
    );
  }

  private setDetailsModal(data) {
    this.detailsModal = {
      name: 'LocationAlertModal',
      options: { modalTitle: 'LOCATION_ALERT' },
      data,
    };
  }

  private setAlertDetails(data) {
    if (data > 10) {
      this.alertClass = 'danger';
    } else if (data > 0) {
      this.alertClass = 'warning';
    } else {
      this.alertClass = '';
    }
    this.alertText = data;
  }
}
