<div [formGroup]="group" class="ui-g" [ngClass]="formBackgroundColor">
  <!-- CONNECTOR FIELD -->
  <div class="ui-g-1">
    <span *ngIf="groupId > 0">
      {{ connector }}
    </span>
  </div>

  <!-- SOURCE -->
  <div class="ui-g-3 ui-g-nopad">
    <div
      [ngSwitch]="sourceType"
      [ngClass]="{
        'ui-g-6': sourceType === 'l',
        'ui-g-12': sourceType !== 'l'
      }"
    >
      <!-- DEVICE_ID FIELD -->
      <div *ngSwitchCase="'d'">
        <sc-dropdown
          controlName="deviceId"
          [form]="group"
          label="{{ 'DEVICE' | translate }}"
          [options]="devices"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <!-- LOCATION_ID FIELD -->
      <div *ngSwitchCase="'l'">
        <sc-dropdown
          controlName="locationId"
          [form]="group"
          label="{{ 'LOCATION' | translate }}"
          [options]="locations"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <!-- DATETIME_ID FIELD -->
      <div *ngSwitchCase="'dt'">
        {{ datetimePropertyTypeId }}
      </div>

      <div *ngSwitchDefault></div>
    </div>

    <div class="ui-g-6" *ngIf="sourceType === 'l'">
      {{ locationPropertyTypeId }}
    </div>
  </div>

  <!-- OPERATOR -->
  <div class="ui-g-1">
    {{ operator }}
  </div>

  <div class="ui-g-3 ui-g-nopad">
    <!-- REFERENCED -->
    <div class="ui-g" *ngIf="!datetimePropertyTypeId">
      <div class="ui-g-6" [ngSwitch]="referencedType">
        <!-- REFERENCED_TYPE_DEVICE FIELD -->
        <ng-container *ngSwitchCase="'d'">
          <sc-dropdown
            controlName="referencedDeviceId"
            [form]="group"
            label="{{ 'REFERENCED' | translate }} {{ 'DEVICE' | translate }}"
            [options]="refDevices"
            [showLabel]="false"
          ></sc-dropdown>
        </ng-container>

        <!-- REFERENCED_TYPE_LOCATION FIELD -->
        <ng-container *ngSwitchCase="'l'">
          <sc-dropdown
            controlName="referencedLocationId"
            [form]="group"
            label="{{ 'REFERENCED' | translate }} {{ 'LOCATION' | translate }}"
            [options]="locations"
            [showLabel]="false"
          ></sc-dropdown>
        </ng-container>

        <!-- REFERENCED_TYPE_VALUE FIELD -->
        <ng-container *ngSwitchCase="'v'">
          {{ 'SELF' | translate }}
        </ng-container>
      </div>

      <!-- REFERENCED_VALUE -->
      <div class="ui-g-6" [ngSwitch]="referencedType">
        <!-- REFERENCED_DEVICE -->
        <ng-container *ngSwitchCase="'d'">
          {{ 'DEVICE' | translate }} {{ 'VALUE' | translate }}
        </ng-container>

        <!-- REFERENCED_LOCATION -->
        <ng-container *ngSwitchCase="'l'">
          {{ referencedLocationPropertyTypeId }}
        </ng-container>

        <!-- REFERENCED_VALUE FIELD -->
        <ng-container *ngSwitchCase="'v'">
          <ng-container *ngIf="hasMappingValues">
            <sc-dropdown
              controlName="referencedValue"
              [form]="group"
              label="{{ 'REFERENCED_VALUE' | translate }}"
              [options]="mappingValue"
              [showLabel]="false"
            ></sc-dropdown>
          </ng-container>

          <ng-container *ngIf="!hasMappingValues">
            <!-- <ng-container *ngIf="isDeviceTypeRequiredNumValue">
              <sc-number-input
                controlName="referencedValue"
                [form]="group"
                label="{{ 'REFERENCED_VALUE' | translate }}"
                [showLabel]="false"
                [step]="0.1"
                [min]="-999.9"
                [max]="999.9"
              ></sc-number-input>
            </ng-container> -->

            <!-- <ng-container *ngIf="!isDeviceTypeRequiredNumValue"> -->
              <sc-text-input
                controlName="referencedValue"
                [form]="group"
                label="{{ 'REFERENCED_VALUE' | translate }}"
                [showLabel]="false"
              ></sc-text-input>
            <!-- </ng-container> -->
          </ng-container>
        </ng-container>

        <!-- REFERENCED_VALUE_CHANGES FIELD -->
        <div *ngSwitchCase="'vc'">
          <sc-number-input
            controlName="referencedValue"
            [form]="group"
            label="{{ 'REFERENCED_VALUE' | translate }}"
            min="0"
            [showLabel]="false"
          ></sc-number-input>
        </div>
      </div>
    </div>

    <!-- DATETIME -->
    <div class="ui-g" *ngIf="datetimePropertyTypeId">
      <div class="ui-g-6"></div>
      <div class="ui-g-6">
        <sc-text-input
          controlName="datetimePropertyValue"
          [form]="group"
          label="{{ 'DATE_TIME' | translate }}"
          [showLabel]="false"
        ></sc-text-input>
      </div>
    </div>
  </div>

  <div class="ui-g-4 ui-g-nopad">
    <!-- PARAMETERS FIELD -->
    <div class="ui-g-6">
      <sc-text-input
        controlName="parameters"
        [form]="group"
        label="{{ 'PARAMETERS' | translate }}"
        [showLabel]="false"
      ></sc-text-input>
    </div>

    <!-- EVENT -->
    <!-- <div class="ui-g-3">
      {{ event }}
    </div> -->

    <!-- WAS_TRUE_ONE_TIME_IN_PAST_MS FIELD -->
    <div class="ui-g-3">
      <sc-number-input
        *ngIf="isRequiredTrueOneTime"
        controlName="wasTrueOneTimeInPastMs"
        [form]="group"
        label="{{ 'WAS_TRUE_ONE_TIME_IN_PAST_MS' | translate }}"
        [showLabel]="false"
        [min]="0"
      ></sc-number-input>
    </div>

    <!-- REQUIRED_VALIDITY_PERIOD FIELD -->
    <div class="ui-g-3">
      <sc-number-input
        controlName="requiredValidityPeriod"
        [form]="group"
        label="{{ 'REQUIRED_VALIDITY_PERIOD' | translate }}"
        [showLabel]="false"
        [min]="0"
      ></sc-number-input>
    </div>
  </div>
</div>
