import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  /**
   * Validate value with RegExp
   * @param regex RegExp pattern for validation
   * @param errorKey error key when validation fail
   */
  static pattern(regex: RegExp, errorKey: string = 'customPattern'): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error, else return error passed in the second parameter
      return valid ? null : { [errorKey]: true };
    };
  }

  /**
   * Compare value between two form controls
   * @param controlName1 control name for source value
   * @param controlName2 control name for compare value
   * @param errorKey error key when validation fail
   */
  static compare(controlName1: string, controlName2: string, errorKey: string = 'match') {
    return (control: AbstractControl) => {
      const source: string = control.get(controlName1).value; // get source value
      const compare: string = control.get(controlName2).value; // get compare value

      // compare is the value of controlName2 match controlName1
      if (source !== compare) {
        // if they don't match, set an error to formControl2
        control.get(controlName2).setErrors({
          [errorKey]: { source: controlName1, compare: controlName2 },
        });
      }
    };
  }
}
