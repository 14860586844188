<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <!-- HEADER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <p-toggleButton
          formControlName="isActive"
          offIcon="fa fa-square-o"
          offLabel="{{ 'ACTIVE' | translate }}"
          onIcon="fa fa-check-square-o"
          onLabel="{{ 'ACTIVE' | translate }}"
        ></p-toggleButton>
      </div>
    </div>

    <!-- BODY -->
    <div class="ui-g">
      <!-- NAME FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <!-- BULK_MODIFICATION_ENABLED -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="bulkModificationEnabled"
          [form]="form"
          label="{{ 'FORM_FIELD_BULK_MODIFICATION_ENABLED' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- TABLE FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="table"
          [form]="form"
          label="{{ 'TABLE' | translate }}"
          [options]="selectItems['tables']"
        ></sc-dropdown>
      </div>

      <!-- TYPE FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="type"
          [form]="form"
          label="{{ 'FORM_FIELD_TYPE' | translate }}"
          [options]="selectItems['types']"
        ></sc-dropdown>
      </div>

      <!-- SIZE FIELD -->
      <div class="ui-g-12">
        <sc-number-input
          controlName="size"
          [form]="form"
          label="{{ 'FORM_FIELD_SIZE' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>

      <!-- DEFAULT_VALUE FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="defaultValue"
          [form]="form"
          label="{{ 'FORM_FIELD_DEFAULT_VALUE' | translate }}"
        ></sc-text-input>
      </div>

      <!-- DISPLAY_TYPE FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="displayType"
          [form]="form"
          label="{{ 'FORM_FIELD_DISPLAY_TYPE' | translate }}"
          [options]="selectItems['displayTypes']"
        ></sc-dropdown>
      </div>

      <div *ngIf="isSelectbox">
        <sc-selectbox-setting-form
          (valueChanged)="updateDisplayConfig($event)"
        ></sc-selectbox-setting-form>
      </div>

      <!-- ERROR MESSAGE -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-12 ui-md-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</ng-template>
