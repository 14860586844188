<div class="modifier-item">
  <div class="modifier-item-body" *ngIf="control" [formGroup]="control">
    <div
      class="p-fluid p-formgrid p-grid p-inputtext-sm"
      style="align-items: center"
    >
      <div class="p-field p-col-12 p-md-3">
        <p-dropdown
          [options]="modifierTypeSelectItems"
          formControlName="type"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_COMMON_TYPE' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-2">
        <p-dropdown
          [options]="operatorSelectItems"
          formControlName="operator"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_RULE_OPERATOR' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-6">
        <!-- <div class="" [ngClass]="{ 'ui-inputgroup': control?.unit }"> -->
        <ng-container [ngSwitch]="structure?.type">
          <ng-container *ngSwitchCase="'numeric'">
            <input
              type="number"
              pInputText
              formControlName="value"
              placeholder="{{ 'CCP_COMMON_VALUE' | translate }}"
              [min]="structure?.min"
              [max]="structure?.max"
            />
          </ng-container>

          <ng-container *ngSwitchCase="'select'">
            <p-dropdown
              [options]="structure?.options"
              formControlName="value"
              placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
                'CCP_COMMON_VALUE' | translate
              }}"
              [filter]="true"
              appendTo="body"
            ></p-dropdown>
          </ng-container>

          <ng-container *ngSwitchCase="'custom_function'">
            <ng-container *ngTemplateOutlet="customFunction"></ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <input
              type="text"
              pInputText
              formControlName="value"
              placeholder="{{ 'CCP_COMMON_VALUE' | translate }}"
            />
          </ng-container>
        </ng-container>

        <!-- <span class="ui-inputgroup-addon" *ngIf="valueStructure?.unit">
            {{ valueStructure?.unit }}
          </span>
        </div> -->
      </div>

      <div class="p-field p-col-12 p-md-1" style="padding: unset">
        <button
          type="button"
          class="modifier-item__remove-button"
          (click)="removed.emit()"
        >
          <fa-icon icon="minus"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #customFunction>
  <div class="custom-function" *ngIf="control" [formGroup]="control">
    <div class="p-field p-inputtext-sm">
      <p-dropdown
        [options]="selectItems?.customFunctions"
        formControlName="value"
        placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
          'CCP_CUSTOM_FUNCTION' | translate
        }}"
        [filter]="true"
        appendTo="body"
      ></p-dropdown>
    </div>

    <!-- parameters form array -->
    <ng-container *ngIf="parametersForm">
      <ng-container *ngFor="let ctrl of parametersForm.controls; let i = index">
        <sc-function-variable-item
          [key]="i"
          [control]="ctrl"
          [dataSources]="dataSources"
          [selectItems]="selectItems"
          [isTemplate]="isTemplate"
        ></sc-function-variable-item>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
