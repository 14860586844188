import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { config as appConfig } from '@app/config';

@Component({
  selector: 'sc-page-widget-modal',
  templateUrl: './page-widget-modal.component.html',
  styleUrls: ['./page-widget-modal.component.scss'],
})
export class PageWidgetModalComponent implements OnInit, OnDestroy {
  isRemove: boolean;
  widget: any;
  iconUrlPrefix = appConfig().s3Url + '/';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    if (this.config && this.config.data) {
      if (typeof this.config.data.widget !== 'undefined') {
        this.widget = this.config.data.widget;
      }

      if (typeof this.config.data.isRemove === 'boolean') {
        this.isRemove = false;
      }
    }
  }

  ngOnDestroy(): void {
   this.sharedService.clearSubscribes(this.subscribers);
  }

  close(result: boolean) {
    this.dialog.close(result);
  }
}
