import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Rule } from '../models/rule';
import { RuleRequestOptions } from '../models/rule-request-options';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class RulesService {
  private apiUrl = config().apiUrl + '/rules';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  private verifyData(data: any): Rule {
    const newData = { ...data };
    delete newData.id;
    delete newData.companyId;
    delete newData.siteId;
    delete newData.locationId;
    delete newData.ruleTemplateId;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getRule(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getRules(options?: RuleRequestOptions) {
    if (!options.siteId) {
      options.siteId = this.sharedService.selectedSite.id;
    }
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    const url = this.apiUrl + queryParams;
    return this.httpClient.get(url);
  }

  createRule(data: Rule) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateRule(id: number, data: Rule) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteRule(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
