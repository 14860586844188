<form [formGroup]="form" novalidate>
  <p-card>
    <!--Header-->
    <p-header>
      <div class="ui-g">
        <div class="ui-g-6">
          <sc-text-input
            *ngIf="editMode"
            controlName="id"
            [form]="form"
            label="{{ 'ID' | translate }}"
          ></sc-text-input>
        </div>
        <div class="ui-g-6 text-right">
          <p-toggleButton
            formControlName="isActive"
            offIcon="fa fa-square-o"
            offLabel="{{ 'ACTIVE' | translate }}"
            onIcon="fa fa-check-square-o"
            onLabel="{{ 'ACTIVE' | translate }}"
          ></p-toggleButton>
        </div>
      </div>
    </p-header>

    <!--Body-->
    <div class="ui-g">
      <div class="ui-g">
        <div class="ui-g-6">
          <!-- NAME FIELD -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="name"
              [form]="form"
              label="{{ 'NAME' | translate }}"
            ></sc-text-input>
          </div>

          <!-- ROOM NUMBER FIELD -->
          <div class="ui-g-12">
            <label for="roomNumber" class="sc-form-label">
              {{ 'Room Number' | translate }}
            </label>
            <input type="text" id="roomNumber" pInputText />
          </div>

          <!-- FIRST, MIDDLE NAME FIELD -->
          <div class="ui-g-12">
            <label for="firstname" class="sc-form-label">
              {{ 'First, Middle Name' | translate }}
            </label>
            <input type="text" id="firstname" pInputText />
          </div>

          <!-- GIVEN NAME FIELD -->
          <div class="ui-g-12">
            <label for="lastname" class="sc-form-label">
              {{ 'Given Name' | translate }}
            </label>
            <input type="text" id="lastname" pInputText />
          </div>

          <!-- NATIONALITY FIELD -->
          <div class="ui-g-12">
            <label for="nationality" class="sc-form-label">
              {{ 'Nationality' | translate }}
            </label>
            <input type="text" id="nationality" pInputText />
          </div>

          <!-- AGE FIELD -->
          <div class="ui-g-12">
            <label for="age" class="sc-form-label">
              {{ 'Age' | translate }}
            </label>
            <input type="number" id="age" pInputText />
          </div>

          <!-- DATE/TIME CHECKIN FIELD -->
          <div class="ui-g-12">
            <label for="dateCheckin" class="sc-form-label">
              {{ 'Date/Time Checkin' | translate }}
            </label>
            <p-calendar
              inputId="dateCheckin"
              dateFormat="dd/mm/yy"
              hourFormat="24"
              showButtonBar="true"
              showTime="true"
              utc="true"
              [style]="{'width':'100%'}"
            ></p-calendar>
          </div>

          <!-- DATE/TIME CHECKOUT FIELD -->
          <div class="ui-g-12">
            <label for="dateCheckOut" class="sc-form-label">
              {{ 'Date/Time CheckOut' | translate }}
            </label>
            <p-calendar
              inputId="dateCheckOut"
              dateFormat="dd/mm/yy"
              hourFormat="24"
              showButtonBar="true"
              showTime="true"
              utc="true"
              [style]="{'width':'100%'}"
            ></p-calendar>
          </div>
        </div>

        <!-- RIGHT -->
        <div class="ui-g-6">
          <!-- MEMBER/CLIENT NUM FIELD -->
          <div class="ui-g-12">
            <label for="memberNum" class="sc-form-label">
              {{ 'Member/Client Num' | translate }}
            </label>
            <input type="number" id="memberNum" pInputText />
          </div>

          <!-- EXTERNAL THIRD PARTY  IDENTIFICATION NUMBER FIELD -->
          <div class="ui-g-12">
            <label for="identificationNumber" class="sc-form-label">
              {{ 'External Third Party  Identification Number' | translate }}
            </label>
            <input type="text" id="identificationNumber" pInputText />
          </div>

          <!-- INTERNAL CLIENT NUMBER FIELD -->
          <div class="ui-g-12">
            <label for="internalClientNumber" class="sc-form-label">
              {{ 'Internal Client Number' | translate }}
            </label>
            <input type="text" id="internalClientNumber" pInputText />
          </div>

          <!-- AUTOMATIC SCENARIO ALLOWED FIELD -->
          <div class="ui-g-12">
            <label for="automaticScenarioAllowed" class="sc-form-label">
              {{ 'Automatic Scenario allowed' | translate }}
            </label>
            <input type="text" id="automaticScenarioAllowed" pInputText />
          </div>

          <!-- BEHAVIOR TRACKING ALLOWED FIELD -->
          <div class="ui-g-12">
            <label for="dehaviorTrackingAllowed" class="sc-form-label">
              {{ 'Behavior Tracking Allowed' | translate }}
            </label>
            <input type="text" id="dehaviorTrackingAllowed" pInputText />
          </div>

          <!-- SPECIAL HEALTH CARE FLAG FIELD -->
          <div class="ui-g-12">
            <label for="specialHealthCareFlag" class="sc-form-label">
              {{ 'Special Health Care Flag' | translate }}
            </label>
            <input type="text" id="specialHealthCareFlag" pInputText />
          </div>

          <!-- FOOD ALLERGIES FLAG FIELD -->
          <div class="ui-g-12">
            <label for="foodAllergiesFlag" class="sc-form-label">
              {{ 'Food Allergies Flag' | translate }}
            </label>
            <input type="text" id="foodAllergiesFlag" pInputText />
          </div>

          <!-- COMFORT PROFILE ID FIELD -->
          <div class="ui-g-12">
            <label for="ComfortProfileId" class="sc-form-label">
              {{ 'Comfort Profile' | translate }}
            </label>
            <input type="text" id="ComfortProfileId" pInputText />
          </div>

          <!-- ENTERTAINMENT PROFILE ID FIELD -->
          <div class="ui-g-12">
            <label for="entertainmentProfileId" class="sc-form-label">
              {{ 'Entertainment Profile' | translate }}
            </label>
            <input type="text" id="entertainmentProfileId" pInputText />
          </div>

          <!-- PMS config file FIELD -->
          <div class="ui-g-12">
            <label for="configFiles" class="sc-form-label">
              {{ 'PMS config file' | translate }}
            </label>
            <div><input type="file" id="configFiles" multiple></div>
          </div>
        </div>
      </div>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <p-footer>
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>
      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>
        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </p-footer>
  </p-card>
</form>
