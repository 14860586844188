import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { timeout } from 'rxjs/operators';

interface TaskRequestData {
  automationIdx: string;
  apiKey: string;
  function: string;
  attributes?: { [key: string]: any };
}

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private apiUrl = config().apiUrl + '/_integrator';

  constructor(private httpClient: HttpClient) {}

  getAutomationLastSync(automationIdx: string) {
    return this.httpClient.get(`${this.apiUrl}/automation/${automationIdx}/lastSync`);
  }

  pingAutomation(automationIdx: string) {
    return this.httpClient.get(`${this.apiUrl}/automation/${automationIdx}/ping`).pipe(timeout(30000));
  }

  sendTask(data: TaskRequestData) {
    const apiUrl = `${config().apiUrl}/liveRequests`;
    return this.httpClient.post(apiUrl, data).pipe(timeout(30000));
  }
}
