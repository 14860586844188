import { AppStyleEffects } from './app-style.effect';
import { AuthEffects } from './auth.effect';
import { CompaniesEffects } from './companies.effect';
import { DevicesEffects } from './devices.effect';
import { InitialDataEffects } from './initial.effect';
import { LocalesEffects } from './locales.effect';
import { LocationsEffects } from './locations.effect';
import { PagesEffects } from './pages.effect';
import { RouterEffects } from './router.effect';
import { SceneTemplatesEffects } from './scene-templates.effect';
import { SitesEffects } from './sites.effect';
import { WidgetsEffects } from './widgets.effect';

export const effects: any[] = [
  AppStyleEffects,
  AuthEffects,
  CompaniesEffects,
  DevicesEffects,
  InitialDataEffects,
  LocalesEffects,
  LocationsEffects,
  PagesEffects,
  RouterEffects,
  SceneTemplatesEffects,
  SitesEffects,
  WidgetsEffects,
];

export * from './app-style.effect';
export * from './auth.effect';
export * from './companies.effect';
export * from './devices.effect';
export * from './initial.effect';
export * from './locales.effect';
export * from './locations.effect';
export * from './pages.effect';
export * from './router.effect';
export * from './scene-templates.effect';
export * from './sites.effect';
export * from './widgets.effect';
