import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PageTemplate } from '../models/page-template';

import { config } from '../../../config';

@Injectable()
export class PageTemplatesService {
  private apiUrl = config().apiUrl + '/pageTemplates';

  constructor(private httpClient: HttpClient) {}

  private verify(data: any): PageTemplate {
    const verifyData = { ...data };

    if (typeof verifyData.id !== 'undefined') {
      delete verifyData.id;
    }
    if (typeof verifyData.createdAt !== 'undefined') {
      delete verifyData.createdAt;
    }
    if (typeof verifyData.createdBy !== 'undefined') {
      delete verifyData.createdBy;
    }
    if (typeof verifyData.updatedAt !== 'undefined') {
      delete verifyData.updatedAt;
    }
    if (typeof verifyData.updatedBy !== 'undefined') {
      delete verifyData.updatedBy;
    }

    verifyData.sections = verifyData.sections.map(s => {
      delete s.selectedWidgets;

      s.widgets = s.widgets.map(w => {
        delete w.maxItemArea;
        delete w.minItemArea;
        delete w.maxItemCols;
        delete w.minItemCols;
        delete w.maxItemRows;
        delete w.minItemRows;
        return w;
      });

      return s;
    });

    return verifyData;
  }

  getPageTemplate(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getPageTemplates() {
    return this.httpClient.get(this.apiUrl);
  }

  createPageTemplate(data: PageTemplate) {
    return this.httpClient.post(this.apiUrl, this.verify(data));
  }

  updatePageTemplate(id: number, data: PageTemplate) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verify(data));
  }

  deletePageTemplate(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
