import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import { SelectItem } from 'primeng/api';

@Component({
  selector: 'advanced-form-group',
  templateUrl: 'advanced-form-group.component.html'
})
export class AdvancedFormGroupComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  variables: Array<SelectItem>;
  @Input()
  disables: any;

  advancedData: any;

  constructor() {}

  ngOnInit() {
    this.advancedData = this.group && this.group.value;
  }

  get styles(): FormArray {
    return this.group.get('styles') as FormArray;
  }
}
