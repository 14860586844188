import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class LocationBenchmarkFormsService {
  constructor(private formBuilder: FormBuilder) {}

  /**
   * Create Location Benchmark Form
   */
  initLocationBenchmarkForm(): FormGroup {
    return this.formBuilder.group({
      benchmarkTemplate: [null, Validators.required],
      locations: [null, Validators.required],
      from: [null, Validators.required],
      to: [null, Validators.required]
    });
    // return this.formBuilder.group({
    //   id: { value: null, disabled: true },
    //   name: [null, Validators.required],
    //   description: null,
    //   enablingMarker: this.initEnablingMarkerForm(),
    //   disablingMarker: this.initDisablingMarkerForm(),
    //   output: this.formBuilder.array(
    //     [this.initOutputForm()],
    //     Validators.required
    //   ),
    //   isIntervalLimited: false,
    //   intervalLimit: [{ value: null, disabled: true }, Validators.required],
    //   // isPublic: false,
    //   // sites: null,
    //   timeRangeMode: ['a', Validators.required],
    //   absoluteEndTime: null,
    //   absoluteStartTime: null,
    //   relativeAgoUnit: [{ value: null, disabled: true }, Validators.required],
    //   relativeAgoValue: [{ value: null, disabled: true }, Validators.required],
    //   locationId: [null, Validators.required],
    //   benchmarkTemplateId: [null, Validators.required],
    //   isActive: false,
    //   isDeleted: false
    // });
  }

  /**
   * Create an Output Form Group
   */
  initOutputForm(): FormGroup {
    return this.formBuilder.group({
      key: [null, Validators.required],
      options: null
    });
  }

  /**
   * Patch value to Output Form Array
   * @param form
   * @param data
   */
  setOutputForm(form: FormGroup, data: { [key: string]: any }[]) {
    const formGroups = [];
    data.forEach(item => {
      if (item.key !== 'duration') {
        const fg = this.initOutputForm();
        fg.patchValue(item);
        formGroups.push(fg);
      }
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('output', formArray);
  }

  /**
   * Create Enabling Marker Form Group
   */
  initEnablingMarkerForm(): FormGroup {
    return this.formBuilder.group({
      conditions: this.formBuilder.array(
        [this.initConditionsForm()],
        Validators.required
      )
    });
  }

  /**
   * Create Disabling Marker Form Group
   */
  initDisablingMarkerForm(): FormGroup {
    return this.formBuilder.group({
      connector: ['OR', Validators.required],
      conditions: this.formBuilder.array(
        [this.initConditionsForm(true)],
        Validators.required
      )
    });
  }

  /**
   * Create Condition Form Group of Enabling / Disabling Marker Form
   * @param disabling
   */
  initConditionsForm(disabling: boolean = false): FormGroup {
    const control: any = {
      property: [null, Validators.required],
      operator: [null, Validators.required],
      value: null
    };
    if (disabling) {
      control.invalidating = false;
    }
    return this.formBuilder.group(control);
  }

  /**
   * Patch value to Conditions Form Array of Enabling / Disabling Marker Form
   * @param form
   * @param data
   * @param disabling
   */
  setConditionsForm(
    form: FormGroup,
    data: { conditions: any[]; [key: string]: any },
    disabling?: boolean
  ) {
    const markerForm = (disabling
      ? form.controls['disablingMarker']
      : form.controls['enablingMarker']) as FormGroup;
    const formGroups = data.conditions.map(item => {
      const fg = this.initConditionsForm(disabling);
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    markerForm.setControl('conditions', formArray);
  }
}
