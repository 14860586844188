import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LocationHistoryLogRequest } from '@widgets/locations/models/location-history-log-request';
import { Site } from '@widgets/sites/models/site';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { Location } from '@widgets/locations/models/location';
import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class LocationHistoryLogsService {
  private baseUrl = config().apiUrl + '/companies/{companyId}/sites/{siteId}/locations';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private get apiUrl() {
    const selectedSite = this.sharedService.selectedSite;
    return this.baseUrl.replace('{companyId}', selectedSite.companyId + '').replace('{siteId}', selectedSite.id + '');
  }

  /**
   * Get Location History Logs
   * @param options
   */
  getHistoryLogs(options: LocationHistoryLogRequest) {
    let queryParams: any = [];

    for (const key in options.filter) {
      if (options.filter.hasOwnProperty(key)) {
        queryParams.push(`${key}=${options.filter[key]}`);
      }
    }

    if (!isNaN(options.limit)) {
      queryParams.push(`limit=${options.limit}`);
    } else {
      queryParams.push(`limit=50`);
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';

    const apiUrl =
      this.apiUrl +
      (options.locationId ? '/' + options.locationId : '') +
      '/history' +
      (options.latestLog ? '/latest' : '') +
      queryParams;

    return this.httpClient.get(apiUrl);
  }

  /**
   * Get Location History Logs Summary
   * @param locationId
   */
  getHistoryLogsSummary(locationIds: number[], options?: LocationHistoryLogRequest) {
    let queryParams: any = [];

    for (const key in options.filter) {
      if (options.filter.hasOwnProperty(key)) {
        queryParams.push(`${key}=${options.filter[key]}`);
      }
    }

    if (!isNaN(options.limit)) {
      queryParams.push(`limit=${options.limit}`);
    } else {
      queryParams.push(`limit=100`);
    }

    queryParams.push(`locations=${locationIds}`);

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';

    // const apiUrl = `${this.apiUrl}/${locationId}/history/summary${queryParams}`;
    const apiUrl = `${this.apiUrl}/history/summary${queryParams}`;

    return this.httpClient.get(apiUrl);
  }

  getHistoryLogsLazyLoad(locationId, options?) {
    let queryParams: any = [];

    for (const key in options) {
      if (options.hasOwnProperty(key) && options[key]) {
        if (['filter', 'sort', 'daterange'].indexOf(key) >= 0) {
          queryParams.push(key + '=' + encodeURI(JSON.stringify(options[key])));
        } else {
          queryParams.push(key + '=' + options[key]);
        }
      }
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';

    const apiUrl = `${this.apiUrl}/${locationId}/history/lazy`;

    return this.httpClient.get(apiUrl + queryParams);
  }

  getLocationPropertyDeviceTypeKeyMapping(key: string) {
    switch (key) {
      case 'acFanspeed': {
        return 'acf';
      }
      case 'acMode': {
        return 'acm';
      }
      case 'acStatus': {
        return 'acs';
      }
      case 'curtainsOpen': {
        return 'cm';
      }
      case 'doorLocked': {
        return 'sdl';
      }
      case 'doorOpen': {
        return 'osd';
      }
      case 'windowOpen': {
        return 'osw';
      }
      default: {
        return;
      }
    }
  }

  getLocationPropertyColor(key: string, value: any, site?: Site, location?: Location): string {
    const maxPeople = location.maxPeople || 0;
    const maxAcceptableConsumption = site.maxAcceptableConsumption;
    const maxAcceptableHumidity = site.maxAcceptableHumidity;
    const maxAcceptableTemperature = site.maxAcceptableTemperature;
    switch (key) {
      case 'acFanspeed': {
        if (value === 'h' || value === 'm' || value === 'l') {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'acMode':
      case 'acStatus': {
        if (value === 'c' || value === 'h' || value === 'v' || value === 'CoolOn') {
          return 'blue';
        } else if (value === 'o' || value === 'Off') {
          return 'grey';
        } else {
          return 'grey';
        }
      }
      case 'hvacSetpointHeating':
      case 'acSetpoint': {
        if (typeof value === 'number') {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'airQuality': {
        if (typeof value === 'number') {
          // if (value > 50) {
          //   return 'orange';
          // } else {
          //   return 'green';
          // }
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'avgParticulateMatter': {
        if (typeof value === 'number') {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'brightness': {
        if (typeof value === 'number') {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'consumption': {
        if (typeof value === 'number' && value !== 0) {
          // if (value > maxAcceptableConsumption) {
          //   return 'orange';
          // } else {
          //   return 'green';
          // }
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'consumptionTotal': {
        if (typeof value === 'number') {
          // if (value > maxAcceptableConsumption) {
          //   return 'orange';
          // } else {
          //   return 'green';
          // }
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'curtainsOpen': {
        if (value === 'o' || value === true || value === 1) {
          return 'blue';
        } else if (value === 'c' || value === false || value === 0) {
          return 'grey';
        } else {
          return 'grey';
        }
      }
      case 'emrTotal': {
        if (typeof value === 'number') {
          // if (value > maxAcceptableConsumption) {
          //   return 'orange';
          // } else {
          //   return 'green';
          // }
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'dndStatus': {
        if (value === 1 || value === true) {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'doorLocked': {
        if (value === 'y' || value === true || value === 1) {
          return 'blue';
        } else if (value === 'n' || value === false || value === 0) {
          return 'grey';
        } else {
          return 'grey';
        }
      }
      case 'doorOpen': {
        if (value === 'o' || value === true || value === 1) {
          return 'blue';
        } else if (value === 'c' || value === false || value === 0) {
          return 'grey';
        } else {
          return 'grey';
        }
      }
      case 'hasRule': {
        if (value > 0) {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'humidity':
      case 'outdoorHumidity': {
        if (typeof value === 'number') {
          // if (value > maxAcceptableHumidity) {
          //   return 'orange';
          // } else {
          //   return 'green';
          // }
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'isBookedAndCheckInDone': {
        return 'grey';
      }
      case 'isBookedButNoCheckInDone': {
        return 'grey';
      }
      case 'isCheckInInProgress': {
        return 'grey';
      }
      case 'isCheckOutInProgress': {
        return 'grey';
      }
      case 'isRaining': {
        if (value === 1 || value === true) {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'lightsOn': {
        if (value === 1 || value === true) {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'makeUpStatus': {
        if (value === 1 || value === true) {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'motion': {
        if (value === true || value === 1) {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'numPeople': {
        if (typeof value === 'number') {
          // if (value > maxPeople) {
          //   return 'orange';
          // } else {
          //   return 'green';
          // }
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'numPeopleTooMany':
      case 'numSocialDistanceViolations': {
        if (typeof value === 'number') {
          // if (value > 0) {
          //   return 'orange';
          // } else {
          //   return 'green';
          // }
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'occupied': {
        if (value === 'y' || value === true || value === 1) {
          return 'blue';
        } else if (value === 'n' || value === false || value === 0) {
          return 'grey';
        } else {
          return 'grey';
        }
      }
      case 'oooStatus': {
        if (value === 1 || value === true) {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'temperature':
      case 'outdoorTemperature': {
        if (typeof value === 'number') {
          // if (value > maxAcceptableTemperature) {
          //   return 'orange';
          // } else {
          //   return 'green';
          // }
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'windSpeed': {
        if (typeof value === 'number') {
          return 'blue';
        } else {
          return 'grey';
        }
      }
      case 'windowOpen': {
        if (value === 'o' || value === true || value === 1) {
          return 'blue';
        } else if (value === 'c' || value === false || value === 0) {
          return 'grey';
        } else {
          return 'grey';
        }
      }
      default: {
        return '';
      }
    }
  }

  getLocationPropertyIcon(key: string, value: any, unicode = false): string {
    switch (key) {
      case 'acFanspeed': {
        if (value === 'h' || value === 'm' || value === 'l') {
          return unicode ? '\uf863' : 'fas fa-fan';
        } else {
          return unicode ? null : '';
        }
      }
      case 'acMode':
      case 'acStatus': {
        if (value) {
          return unicode ? '\uf011' : 'fas fa-power-off';
        } else {
          return unicode ? null : '';
        }
      }
      case 'hvacSetpointHeating':
      case 'acSetpoint': {
        if (typeof value === 'number') {
          return unicode ? '\uf05b' : 'fas fa-crosshairs';
        } else {
          return unicode ? null : '';
        }
      }
      case 'airQuality': {
        if (typeof value === 'number') {
          return unicode ? '\uf2dc' : 'fas fa-snowflake';
        } else {
          return unicode ? null : '';
        }
      }
      case 'brightness': {
        if (typeof value === 'number') {
          return unicode ? '\uf185' : 'fas fa-sun';
        } else {
          return unicode ? null : '';
        }
      }
      case 'consumption':
      case 'consumptionTotal':
      case 'emrTotal': {
        if (typeof value === 'number') {
          return unicode ? '\uf0e7' : 'fas fa-bolt';
        } else {
          return unicode ? null : '';
        }
      }
      case 'curtainsOpen': {
        if (value === 'o' || value === true || value === 1) {
          return unicode ? '\uf133' : 'fas fa-calendar';
        } else if (value === 'c' || value === false || value === 0) {
          return unicode ? '\uf273' : 'fas fa-calendar-times';
        } else {
          return unicode ? null : '';
        }
      }
      case 'dndStatus': {
        return unicode ? '\uf05e' : 'fas fa-ban';
      }
      case 'doorLocked': {
        if (value === 'y' || value === true || value === 1) {
          return unicode ? '\uf023' : 'fas fa-lock';
        } else if (value === 'n' || value === false || value === 0) {
          return unicode ? '\uf3c1' : 'fas fa-lock-open';
        } else {
          return unicode ? null : '';
        }
      }
      case 'doorOpen': {
        if (value === 'o' || value === true || value === 1) {
          // return unicode ? '\uf0c8' : 'fas fa-square';
          return unicode ? '\uf52b' : 'fas fa-door-open';
        } else if (value === 'c' || value === false || value === 0) {
          // return unicode ? '\uf410' : 'fas fa-window-close';
          return unicode ? '\uf52a' : 'fas fa-door-closed';
        } else {
          return unicode ? null : '';
        }
      }
      case 'hasRule': {
        if (typeof value === 'number') {
          return unicode ? '\uf03a' : 'fas fa-list';
        } else {
          return unicode ? null : '';
        }
      }
      case 'humidity':
      case 'outdoorHumidity': {
        if (typeof value === 'number') {
          return unicode ? '\uf043' : 'fas fa-tint';
        } else {
          return unicode ? null : '';
        }
      }
      case 'isBookedAndCheckInDone': {
        return unicode ? '\uf14a' : 'fas fa-check-square';
      }
      case 'isBookedButNoCheckInDone': {
        return unicode ? '\uf14b' : 'fas fa-pen-square';
      }
      case 'isCheckInInProgress': {
        return unicode ? '\uf2f6' : 'fas fa-sign-in-alt';
      }
      case 'isCheckOutInProgress': {
        return unicode ? '\uf2f5' : 'fas fa-sign-out-alt';
      }
      case 'isRaining': {
        return unicode ? '\uf73d' : 'fas cloud-rain';
      }
      case 'lightsOn': {
        if (value === true || value === 1) {
          return unicode ? '\uf0eb' : 'fas fa-lightbulb';
        } else if (value === false || value === 0) {
          return unicode ? '\uf0eb' : 'fas fa-lightbulb';
        } else {
          return unicode ? null : '';
        }
      }
      case 'makeUpStatus': {
        return unicode ? '\uf51a' : 'fas fa-broom';
      }
      case 'motion': {
        if (value === true || value === 1) {
          return unicode ? '\uf554' : 'fas fa-walking';
        } else if (value === false || value === 0) {
          return unicode ? '\uf183' : 'fas fa-male';
        } else {
          return unicode ? null : '';
        }
      }
      case 'numPeople': {
        if (typeof value === 'number') {
          return unicode ? '\uf500' : 'fas fa-user-friends';
        } else {
          return unicode ? null : '';
        }
      }
      case 'numSocialDistanceViolations': {
        if (typeof value === 'number') {
          return unicode ? '\ue068' : 'fas fa-people-arrows';
        } else {
          return unicode ? null : '';
        }
      }
      case 'numPeopleTooMany': {
        if (typeof value === 'number') {
          return unicode ? '\ue073' : 'fas fa-users-slash';
        } else {
          return unicode ? null : '';
        }
      }
      case 'occupied': {
        if (value === 'y' || value === true || value === 1) {
          return unicode ? '\uf007' : 'fas fa-user';
        } else if (value === 'n' || value === false || value === 0) {
          return unicode ? '\uf235' : 'fas fa-user-times';
        } else {
          return unicode ? null : '';
        }
      }
      case 'oooStatus': {
        return unicode ? '\uf0ad' : 'fas fa-wrench';
      }
      case 'temperature':
      case 'outdoorTemperature': {
        if (typeof value === 'number') {
          return unicode ? '\uf2c9' : 'fas fa-thermometer-half';
        } else {
          return unicode ? null : '';
        }
      }
      case 'windSpeed': {
        if (typeof value === 'number') {
          return unicode ? '\uf72e' : 'fas fa-wind';
        } else {
          return unicode ? null : '';
        }
      }
      case 'windowOpen': {
        if (value === 'o' || value === true || value === 1) {
          return unicode ? '\uf111' : 'fas fa-circle';
        } else if (value === 'c' || value === false || value === 0) {
          return unicode ? '\uf057' : 'fas fa-times-circle';
        } else {
          return unicode ? null : '';
        }
      }
      case 'squareMeter': {
        return unicode ? '\uf546' : 'fas fa-ruler-combined';
      }
      default: {
        return unicode ? null : '';
      }
    }
  }

  getLocationPropertyMapValue(key: string, value: any, deviceType: DeviceType) {
    let mappedValue = value;
    if (value && deviceType && deviceType.mappingValues && deviceType.mappingValues.length) {
      // find mapping value
      for (const mappingValue of deviceType.mappingValues) {
        if (mappingValue.key === value) {
          mappedValue = mappingValue.name;
          break;
        }
      }
    }

    if (typeof mappedValue !== 'undefined' && mappedValue !== null && !this.isBooleanData(key) && !isNaN(mappedValue)) {
      // format number value
      mappedValue = this.sharedService.numberFormat(mappedValue, 1);
    }

    return mappedValue;
  }

  getLocationPropertyUnit(key: string, value?: any): string {
    switch (key) {
      case 'airQuality':
        return 'PM\u2082.\u2085';
      case 'brightness':
        return 'lux';
      case 'consumption':
      case 'consumptionTotal':
        return 'W';
      case 'emrTotal':
        return 'kWh';
      case 'humidity':
      case 'outdoorHumidity':
        return '%';
      case 'acSetpoint':
      case 'hvacSetpointHeating':
      case 'temperature':
      case 'outdoorTemperature':
        return '\u00B0C';
      case 'windSpeed':
        return 'KM/H';
      case 'avgParticulateMatter':
        return 'g/m\u00B3';
      case 'numPeople':
      case 'numPeopleTooMany':
        if (typeof value === 'number' && value > 1) {
          return 'people';
        }
        return 'person';
      case 'numSocialDistanceViolations':
        if (typeof value === 'number' && value > 1) {
          return 'violations';
        }
        return 'violation';
      default:
        return '';
    }
  }

  getLocationPropertyPrefix(key: string): string {
    switch (key) {
      case 'avgParticulateMatter':
        return 'PM\u2082.\u2085';
      default:
        return '';
    }
  }

  getLocationPropertyLabel(key: string, value: any): string {
    let label;

    switch (key) {
      case 'doorOpen': {
        if (value === true || value === 1) {
          label = 'LHL_DOOR_OPEN';
        } else if (value === false || value === 0) {
          label = 'LHL_DOOR_CLOSED';
        }
        break;
      }
      case 'doorLocked': {
        if (value === true || value === 1) {
          label = 'LHL_DOOR_LOCKED';
        } else if (value === false || value === 0) {
          label = 'LHL_DOOR_NOT_LOCKED';
        }
        break;
      }
      case 'hasRule': {
        if (value > 0) {
          label = 'LHL_RULES_ARE_APPLIED_TO_LOCATION';
        } else if (value === 0) {
          label = 'LHL_NO_RULE_WAS_APPLIED_TO_LOCAITON';
        }
        break;
      }
      case 'lightsOn': {
        if (value === true || value === 1) {
          label = 'LHL_LIGHT_ON';
        } else if (value === false || value === 0) {
          label = 'LHL_LIGHT_OFF';
        }
        break;
      }
      case 'motion': {
        if (value === true || value === 1) {
          label = 'LHL_MOTION';
        } else if (value === false || value === 0) {
          label = 'LHL_NO_MOTION';
        }
        break;
      }
      case 'occupied': {
        if (value === true || value === 1) {
          label = 'LHL_OCCUPIED';
        } else if (value === false || value === 0) {
          label = 'LHL_NOT_OCCUPIED';
        }
        break;
      }
      case 'windowOpen': {
        if (value === true || value === 1) {
          label = 'LHL_WINDOW_OPEN';
        } else if (value === false || value === 0) {
          label = 'LHL_WINDOW_CLOSED';
        }
        break;
      }
      case 'makeUpStatus': {
        if (value === true || value === 1) {
          label = 'DIRTY';
        } else if (value === false || value === 0) {
          label = 'CLEAN';
        }
        break;
      }
    }

    if (label) {
      return this.sharedService.getTranslation(label);
    }
    return '';
  }

  isBooleanData(key: string): boolean {
    const keys = [
      'curtainsOpen',
      'dndStatus',
      'doorLocked',
      'doorOpen',
      'hasRule',
      'isBookedAndCheckInDone',
      'isBookedButNoCheckInDone',
      'isCheckInInProgress',
      'isCheckOutInProgress',
      'isRaining',
      'lightsOn',
      'makeUpStatus',
      'motion',
      'occupied',
      'oooStatus',
      'windowOpen',
    ];
    return keys.indexOf(key) >= 0 ? true : false;
  }

  getLocationPropertyValueByKey(key: string, value: any, deviceTypes: DeviceType[]): string {
    if (this.isBooleanData(key)) {
      return !!value ? 'Yes' : 'No';
    } else {
      const deviceTypeKey = this.getLocationPropertyDeviceTypeKeyMapping(key);
      if (value && deviceTypeKey && deviceTypes) {
        // find device type
        const deviceType = deviceTypes.find((d) => d.key === deviceTypeKey);
        // find mapping value
        value = this.getLocationPropertyMapValue(key, value, deviceType);
      }

      if (value && !isNaN(value)) {
        value = this.sharedService.numberFormat(value);
      }

      return value;
    }
  }
}
