import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { LazyloadOptions } from '../models/lazyload-options';
import { ModalConfig } from '@app/sandc/modal/modal-config';

import { LiveRequestsService } from '../services/live-requests.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-lrl-table',
  templateUrl: './lrl-table.component.html',
  styleUrls: ['./lrl-table.component.scss']
})
export class LrlTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  table: GridOptions;
  tableTitle: string;
  datasource: any;
  toolbarItems: string[];
  lrlFormConfig: ModalConfig;

  dateFilter: any;
  isFetching = false;
  isFirstTime = true;

  private selectedDevice: DeviceDetails;
  private subscribers: { [key: string]: any } = {};
  private visibleColumns: string[] = [];

  constructor(
    private store: Store<fromStore.State>,
    private liveRequestsService: LiveRequestsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Set Table Name
    this.tableTitle = 'LIVE_REQUEST';
    // Set Table Toolbar Items
    this.toolbarItems = ['create', 'refresh', 'columns'];
    // Set Table Columns
    this.columns = this.createColumns();
    // Set user's visible columns
    if (
      this.config &&
      this.config.customOptions &&
      this.config.customOptions.visibleColumns
    ) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }

    // get device details
    this.subscribers.DEVICE_DETAILS = this.store
      .select(fromStore.getDeviceDetials)
      .subscribe(result => {
        if (result) {
          this.selectedDevice = result;

          this.lrlFormConfig = {
            name: 'DeviceTriggerActionForm',
            data: {
              deviceId: this.selectedDevice.id,
              deviceTypeId: this.selectedDevice.deviceTypeId,
              description: this.selectedDevice.description,
              commandActions: this.selectedDevice.commandActions,
              automationVersion: this.selectedDevice.automationVersion,
              apiKey: this.selectedDevice.apiKey,
              automationId: this.selectedDevice.id
            },
            options: { modalTitle: 'DEVICE_TRIGGER_ACTION_FORM_TITLE' }
          };

          this.isFirstTime = true;
          this.fetchDataset();
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (!this.isFetching) {
      this.isFetching = true;

      this.datasource = {
        getRows: rowParams => {
          const options: LazyloadOptions = {
            deviceId: this.selectedDevice.id,
            filter: rowParams.filterModel,
            sort: rowParams.sortModel,
            offset: rowParams.startRow,
            limit: 100,
            fields: [...this.visibleColumns].join()
          };

          if (this.dateFilter) {
            options.daterange = this.dateFilter;
          }

          this.subscribers.GET_LRL = this.liveRequestsService
            .getLiveRequestsLazyload(options)
            .subscribe((result: any) => {
              const rowData = result.data;
              let lastRow = -1;
              if (rowData.length < 100) {
                lastRow = rowParams.endRow - 100 + rowData.length;
              }
              rowParams.successCallback(rowData, lastRow);
            });
        }
      };

      // SET DATASOURCE IF TABLE READY
      if (this.table && this.table.api) {
        this.table.api.setDatasource(this.datasource);
      }

      this.isFetching = false;
      this.isFirstTime = false;
    }
  }

  onDateSelect(event) {
    this.dateFilter = {
      from: event.from,
      to: event.to
    };
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private getLrlStatus(params) {
    if (!params.data || !params.data.status) {
      return 'pending';
    } else if (params.data.status === 200 && !params.data.error) {
      return 'success';
    } else {
      return 'fail';
    }
  }

  private getLrlCommand(params) {
    if (!params.data || !params.data.params) {
      return '';
    }
    const p = params.data.params.split('&');
    const commandRegex = new RegExp('^ca=.*', 'g');
    const actionRegex = new RegExp('^a=.*', 'g');
    let action = '';
    let command = '';
    for (let i = 0; i < p.length; i++) {
      if (commandRegex.test(p[i])) {
        const c = p[i].split('=');
        if (c.length > 1) {
          command = ' (' + c[1] + ')';
        }
      } else if (actionRegex.test(p[i])) {
        const c = p[i].split('=');
        if (c.length > 1) {
          action = c[1];
        }
      }
    }
    return action + command;
  }

  private createColumns() {
    return [
      {
        colId: 'createdAt',
        headerName: 'CREATED_AT',
        field: 'createdAt',
        width: 150,
        cellRenderer: 'loadingRenderer',
        valueFormatter: params => this.sharedService.dateFormat(params.value)
      },
      {
        colId: 'status',
        headerName: 'STATUS',
        field: 'status',
        valueFormatter: this.getLrlStatus.bind(this),
        valueGetter: this.getLrlStatus.bind(this)
      },
      {
        colId: 'params',
        headerName: 'COMMAND',
        field: 'params',
        valueFormatter: this.getLrlCommand.bind(this),
        valueGetter: this.getLrlCommand.bind(this)
      }
    ];
  }
}
