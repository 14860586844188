<div class="location-operand container">
  <div class="location-operand-body" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12" *ngIf="!isTemplate">
        <label class="input-label" for="location">
          {{ "CCP_LOCATION" | translate }}
        </label>

        <p-dropdown
          inputId="location"
          [options]="selectItems?.locations"
          formControlName="location_idx"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_LOCATION' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div>
        <div class="p-field p-col-12 p-md-12">
          <label class="input-label" for="property">
            {{ "CCP_LOCATION_PROPERTY" | translate }}
          </label>
          <p-dropdown
            inputId="property"
            [options]="selectItems?.locationProperties"
            formControlName="property"
            placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
              'CCP_LOCATION_PROPERTY' | translate
            }}"
            [filter]="true"
            appendTo="body"
            [disabled]="!isTemplate"
          ></p-dropdown>
        </div>
      </div>
      <div *ngIf="isTemplate">
        <div class="p-field p-col-12 p-md-12">
          <label class="input-label" for="device_type">
            {{ "EDITABLE" | translate }}
          </label>
          <p-checkbox
            inputId="is_source_editable"
            [binary]="true"
            [formControl]="form?.get('is_source_editable')"
            [label]="'YES' | translate"
          ></p-checkbox>
        </div>
        <div class="p-field p-col-12 p-md-12" *ngIf="form?.get('is_source_editable').value == true">
          <label class="input-label" for="name">
            {{ "NAME" | translate }}
          </label>
          <input type="text" pInputText inputId="name" formControlName="name" />
        </div>
      </div>
    </div>
  </div>
</div>
