<div class="file-tree">
  <p-tree
    emptyMessage=""
    [filter]="true"
    selectionMode="single"
    [value]="files"
    (onNodeSelect)="onSelected($event)"
    (onNodeExpand)="onOpened($event)"
  ></p-tree>
</div>
