import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-property-modbus-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './property-modbus-form.component.html',
  styleUrls: ['./property-modbus-form.component.scss'],
})
export class PropertyModbusFormComponent implements OnInit, OnChanges {
  @Input()
  form: FormGroup;

  @Input()
  selectItems: { [key: string]: SelectItem[] } = {};

  modbusForm: FormGroup;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue) {
      this.updateModbusForm();
    }
  }

  ngOnInit(): void {}

  private updateModbusForm() {
    this.modbusForm = this.form.get('modbus') as FormGroup;
  }

  get isReadable() {
    return this.modbusForm.get('is_readable').value === true;
  }

  get isWriteable() {
    return this.modbusForm.get('is_writeable').value === true;
  }
}
