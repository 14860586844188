<div
  class="report-template-form"
  *ngIf="fetchingState === 0; else loadingIndicator"
>
  <div [formGroup]="form" *ngIf="form">
    <!--Header-->
    <div class="ui-g">
      <div class="ui-g-6 ui-g-nopad">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          [label]="'ID' | translate"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <sc-activate-button
          [form]="form"
          field="is_active"
        ></sc-activate-button>
      </div>
    </div>

    <!-- BODY -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-5">
        <sc-text-input
          controlName="name"
          [form]="form"
          [label]="'NAME' | translate"
        ></sc-text-input>
      </div>

      <div class="ui-g-12 ui-md-5">
        <sc-number-input
          controlName="priority"
          [form]="form"
          [label]="'PRIORITY' | translate"
          [min]="0"
          [max]="100"
        ></sc-number-input>
      </div>

      <div class="ui-g-12 ui-md-2">
        <label>{{ "VISIBILITY" | translate }}</label>
        <sc-checkbox-input
          controlName="is_public"
          [form]="form"
          [label]="'IS_PUBLIC' | translate"
        ></sc-checkbox-input>
      </div>
    </div>

    <!-- variables -->
    <div class="ui-g mt-3">
      <div class="ui-g-12">
        <h4 class="m-0">{{ "VARIABLES" | translate }}</h4>
      </div>
    </div>

    <!-- variables header -->
    <div class="ui-g">
      <div class="ui-g-2">
        {{ "TYPE" | translate }}
      </div>

      <div class="ui-g-4">
        {{ "VARIABLE_NAME" | translate }}
      </div>

      <div class="ui-g-4">
        {{ "DISPLAY_NAME" | translate }}
      </div>

      <div class="ui-g-2">
        <button
          pButton
          type="button"
          [icon]="'fa fa-plus'"
          (click)="addVariable()"
        ></button>
      </div>
    </div>

    <!-- variables body -->
    <div class="ui-g" formArrayName="variables">
      <div
        class="ui-g-12 ui-g-nopad"
        *ngFor="let ctrl of variablesForm.controls; let i = index"
        [formGroupName]="i"
      >
        <div class="ui-g">
          <div class="ui-g-2">
            <sc-dropdown
              controlName="type"
              [form]="ctrl"
              [label]="'TYPE' | translate"
              [options]="variableTypes"
              [showLabel]="false"
              [showClear]="false"
            ></sc-dropdown>
          </div>

          <div class="ui-g-4">
            <sc-text-input
              controlName="variable_name"
              [form]="ctrl"
              [label]="'VARIABLE_NAME' | translate"
              [showLabel]="false"
            ></sc-text-input>
          </div>

          <div class="ui-g-4">
            <sc-text-input
              controlName="display_name"
              [form]="ctrl"
              [label]="'DISPLAY_NAME' | translate"
              [showLabel]="false"
            ></sc-text-input>
          </div>

          <div class="ui-g-2">
            <sc-confirm-trigger
              buttonIcon="fa fa-minus"
              (onAccept)="removeVariable(i)"
            ></sc-confirm-trigger>
          </div>
        </div>
      </div>
    </div>

    <!-- templates -->
    <div class="ui-g mt-3">
      <div class="ui-g-10">
        <h4 class="m-0">{{ "TEMPLATES" | translate }}</h4>
      </div>

      <div class="ui-g-2 text-right">
        <button
          pButton
          type="button"
          [icon]="'fa fa-plus'"
          (click)="addTemplate()"
        ></button>
      </div>
    </div>

    <!-- templates header -->
    <div class="ui-g" formArrayName="templates">
      <div class="template-menu">
        <div
          *ngFor="let ctrl of templatesForm.controls; let i = index"
          class="template-menu-item"
          [ngClass]="{ active: currentTemplateIndex === i }"
          (click)="selectTemplate(i)"
        >
          <span
            class="flag-icon"
            [ngClass]="getFlag(ctrl.get('locale').value)"
          ></span>
          {{ (ctrl.get("locale").value | uppercase) || ("NEW" | translate) }}
        </div>
      </div>

      <!-- templates body -->
      <div
        class="ui-g-12 ui-g-nopad template-form"
        *ngIf="currentTemplateForm"
        [formGroup]="currentTemplateForm"
      >
        <div class="ui-g">
          <div class="ui-g-12">
            <sc-dropdown
              controlName="locale"
              [form]="currentTemplateForm"
              [label]="'LANGUAGE' | translate"
              [options]="locales"
              [showClear]="false"
            ></sc-dropdown>
          </div>
        </div>

        <div class="ui-g">
          <div class="ui-g-12">
            <sc-text-input
              controlName="subject"
              [form]="currentTemplateForm"
              [label]="'SUBJECT' | translate"
            ></sc-text-input>
          </div>
        </div>

        <div class="ui-g">
          <div class="ui-g-12">
            <sc-textarea
              controlName="message"
              [form]="currentTemplateForm"
              label="{{ 'MESSAGE' | translate }}"
              [rows]="5"
            ></sc-textarea>
          </div>
        </div>

        <div class="ui-g">
          <div class="ui-g-12">
            <sc-checkbox-input
              controlName="is_active"
              [form]="currentTemplateForm"
              [label]="'IS_ACTIVE' | translate"
            ></sc-checkbox-input>
          </div>
        </div>

        <div class="ui-g">
          <div class="ui-g-12">
            <sc-confirm-trigger
              buttonLabel="{{ 'REMOVE' | translate }}"
              (onAccept)="removeTemplate()"
            ></sc-confirm-trigger>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Message -->
    <div class="ui-g">
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.is_deleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.is_deleted || formData?.is_active"
          buttonClass="ui-button-success"
          [disabled]="form.invalid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
