import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { TechAlertsComponent } from './tech-alerts.component';
import { TechAlertModalComponent } from './tech-alert-modal/tech-alert-modal.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [TechAlertsComponent, TechAlertModalComponent],
  entryComponents: [TechAlertsComponent, TechAlertModalComponent],
  providers: []
})
export class TechAlertsModule {}
