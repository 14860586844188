<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate autocomplete="off">
    <!-- HEADER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          [label]="'ID' | translate"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <p-toggleButton
          *ngIf="isAdmin"
          formControlName="isBlocked"
          [offLabel]="'BLOCKED' | translate"
          [onLabel]="'BLOCKED' | translate"
          [styleClass]="isBlockedStyleClass"
        ></p-toggleButton>

        <p-toggleButton
          formControlName="isActive"
          [offIcon]="'fa fa-square-o'"
          [offLabel]="'ACTIVE' | translate"
          [onIcon]="'fa fa-check-square-o'"
          [onLabel]="'ACTIVE' | translate"
        ></p-toggleButton>
      </div>
    </div>

    <!-- BODY -->
    <h4 class="m-0 mt-3">
      {{ "INFORMATION" | translate }}
    </h4>

    <div class="ui-g">
      <div class="ui-g-6 ui-g-nopad">
        <!-- USERNAME FIELD -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="username"
            [form]="form"
            label="{{ 'USERNAME' | translate }}"
          ></sc-text-input>
          <div *ngIf="verifyData?.username">
            <p-message
              severity="error"
              [text]="verifyData?.username | translate"
            ></p-message>
          </div>
          <div *ngIf="isVerifying?.username">
            <i class="fa fa-refresh fa-spin"></i> {{ "VALIDATING" | translate }}
          </div>
        </div>

        <!-- PASSWORD FIELD -->
        <div class="ui-g-12" *ngIf="!editMode">
          <sc-password-input
            controlName="password"
            [form]="form"
            label="{{ 'PASSWORD' | translate }}"
          ></sc-password-input>
        </div>

        <!-- EMAIL FIELD -->
        <div class="ui-g-12">
          <sc-email-input
            controlName="email"
            [form]="form"
            label="{{ 'EMAIL' | translate }}"
          ></sc-email-input>
          <div *ngIf="verifyData?.email">
            <p-message
              severity="error"
              [text]="verifyData?.email | translate"
            ></p-message>
          </div>
          <div *ngIf="isVerifying?.email">
            <i class="fa fa-refresh fa-spin"></i> {{ "VALIDATING" | translate }}
          </div>
        </div>

        <!-- LOCALE FIELD -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="locale"
            [form]="form"
            label="{{ 'LANGUAGE' | translate }}"
            [options]="selectItems?.locales"
          ></sc-dropdown>
        </div>

        <!-- COMPANY_ID FIELD -->
        <div class="ui-g-12" *ngIf="isAdmin">
          <sc-dropdown
            controlName="companyId"
            [form]="form"
            label="{{ 'COMPANY' | translate }}"
            [options]="selectItems?.companies"
          ></sc-dropdown>
        </div>
      </div>

      <div class="ui-g-6 ui-g-nopad">
        <!-- FIRSTNAME Field -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="firstname"
            [form]="form"
            label="{{ 'FIRSTNAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- LASTNAME Field -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="lastname"
            [form]="form"
            label="{{ 'LASTNAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- PHONE Field -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="phone"
            [form]="form"
            label="{{ 'PHONE' | translate }}"
          ></sc-text-input>
        </div>

        <!-- GENDER Field -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="gender"
            [form]="form"
            label="{{ 'GENDER' | translate }}"
            [options]="selectItems?.gender"
          ></sc-dropdown>
        </div>
      </div>
    </div>

    <h4 class="m-0 mt-3">
      {{ "ACCESS_CONTROL" | translate }}
    </h4>

    <div class="ui-g">
      <!-- ROLES Field -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="roles"
          [form]="form"
          label="{{ 'ROLES' | translate }}"
          [options]="selectItems?.userRoles"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>

      <!-- MAIN_ROLE Field -->
      <div class="ui-g-12" *ngIf="isAdmin">
        <sc-dropdown
          controlName="mainRole"
          [form]="form"
          label="{{ 'MAIN_ROLE' | translate }}"
          [options]="selectItems?.userMainRoles"
        ></sc-dropdown>
      </div>

      <!-- BACKEND_VARIANT Field -->
      <div class="ui-g-12" *ngIf="isAdmin">
        <sc-dropdown
          controlName="backendVariant"
          [form]="form"
          label="{{ 'BACKEND_VARIANT' | translate }}"
          [options]="selectItems?.backendVariants"
        ></sc-dropdown>
      </div>
    </div>

    <h4 class="m-0 mt-3">
      {{ "TWO_FACTOR_AUTH" | translate }}
    </h4>

    <div class="ui-g">
      <div class="ui-g-12">
        <sc-radio-input
          controlName="twoFactorAuthMethod"
          [form]="form"
          label="{{ 'TWO_FACTOR_AUTH_METHOD' | translate }}"
          [options]="selectItems?.twoFactorAuthMethods"
        ></sc-radio-input>
      </div>
    </div>

    <h4 class="m-0 mt-3">
      {{ "NOTIFICATION" | translate }}
    </h4>

    <div class="ui-g">
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="isEmailNotificationEnabled"
          [form]="form"
          [label]="'EMAIL_NOTIFICATION_ENABLED' | translate"
        ></sc-checkbox-input>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="isSmsNotificationEnabled"
          [form]="form"
          [label]="'SMS_NOTIFICATION_ENABLED' | translate"
        ></sc-checkbox-input>
      </div>
    </div>

    <div class="ui-g">
      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonClass="ui-button-danger"
          buttonIcon="fa fa-trash"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!allowSubmitForm"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
