import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CeosIdentityFormService } from '../services/ceos-identity-form.service';
import { CeosIdentityService } from '../services/ceos-identity.service';
import { SharedService } from '@shared/shared.service';
import * as randomWords from 'random-words';

@Component({
  selector: 'sc-automation-form',
  templateUrl: './automation-form.component.html',
  styleUrls: ['./automation-form.component.scss'],
})
export class AutomationFormComponent implements OnInit, OnDestroy {
  fetchingState = 0;
  form: FormGroup;
  formData: any;
  isSubmitting = false;
  errorMessage: string;
  viewMode = false;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private ceosIdentityFormService: CeosIdentityFormService,
    private ceosIdentityService: CeosIdentityService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    if (this.config && this.config.data) {
      if (this.config.data.automation) {
        this.formData = this.config.data.automation;
      }

      if (this.config.data.viewOnly) {
        this.viewMode = true;
      }
    }

    this.initForm();
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal() {
    this.dialog.close();
  }

  private initForm() {
    this.form = this.ceosIdentityFormService.initCeosIdentityForm();
    if (this.viewMode) {
      this.form.patchValue(this.formData);
    } else {
      this.generateData();
    }
  }

  generateData() {
    const apiKey = this.sharedService.getRandomChars(64, true); // 64 chars long
    const mqttPassword = this.sharedService.getRandomChars(32, true); // 32 chars long
    const mqttUsername = randomWords({ exactly: 2, join: '', min: 3, max: 8 }); // word-like
    const osPassword = this.sharedService.getRandomChars(32, true); // 32 chars long
    const osUsername = randomWords({ exactly: 2, join: '', min: 3, max: 8 }); // word-like
    const ownerKey = this.sharedService.getRandomChars(64, true); // 64 chars long

    this.form.patchValue({
      api_key: apiKey,
      mqtt_username: mqttUsername,
      mqtt_password: mqttPassword,
      os_username: osUsername,
      os_password: osPassword,
      owner_key: ownerKey,
    });
  }

  copyText(key: string) {
    const value = this.form.get(key).value;
    this.sharedService.copyToClipboard(value);
  }

  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.getRawValue();

    this.subscribers.create = this.ceosIdentityService.createCeosIdentity(formData).subscribe({
      next: (result: any) => {
        // show notification
        const text = this.sharedService.getTranslation('CREATE_SUCCESS');
        const title = this.sharedService.getTranslation('CEOS_IDENTITY');
        this.sharedService.notify(title, text, 'success');

        // close the form and return user info
        this.isSubmitting = false;
        this.dialog.close(result.data);
      },
      error: (error) => {
        const text = this.sharedService.getTranslation('CREATE_FAIL');
        const title = this.sharedService.getTranslation('CEOS_IDENTITY');
        this.sharedService.notify(title, text, 'success');
        // display error message and unlock the form
        this.errorMessage = error;
        this.isSubmitting = false;
      },
    });
  }
}
