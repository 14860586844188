import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as fromRoot from '@app/store';
import { Store } from '@ngrx/store';
import { MenuItem } from '../models/menu-item';

@Component({
  selector: 'sc-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit, OnChanges {
  @Input()
  data: MenuItem;
  @Input()
  selected: MenuItem;
  @Input()
  sidebarExpanded: boolean;

  @Output()
  clicked = new EventEmitter<MenuItem>();

  @Output()
  menuExpanded = new EventEmitter<any>();

  expanded = false;
  hasChildren = false;
  highlight = false;
  isChildren = false;

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.childrenCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.childrenCheck();
    }
  }

  private childrenCheck() {
    if (this.data) {
      this.isChildren = this.data.isSetting;
      if (this.data.children && this.data.children.length) {
        this.hasChildren = true;
      }

      this.store.select(fromRoot.expandSettings).subscribe((expandSettings) => {
        this.expanded = expandSettings;
      });

      this.store.select(fromRoot.getSelectedMenu).subscribe((selectedMenu) => {
        if (selectedMenu === null) {
          //On first start, set the state to the first option
          this.highlight = true;
          this.store.dispatch(new fromRoot.SelectMenu({ selectedId: this.data.id }));
        } else if (this.data.id === selectedMenu || (this.data.id === 'settings' && this.expanded)) {
          this.highlight = true;
        } else {
          this.highlight = false;
          // if (this.data.id !== 'settings' && this.expanded && !this.selected.isSetting) {
          //   this.expanded = false;
          //   this.store.dispatch(new fromRoot.ExpandSettings({ expanded: false }));
          // }
        }
      });
    }
  }

  onClicked(menu: MenuItem, submenu: boolean = false) {
    if (((this.hasChildren && !submenu) || (this.data.id !== 'settings' && this.expanded)) && !this.isChildren) {
      this.expanded = !this.expanded;
      this.store.dispatch(new fromRoot.ExpandSettings({ expanded: this.expanded }));
    } else {
      this.clicked.emit(menu);
    }
    this.store.dispatch(new fromRoot.SelectMenu({ selectedId: menu.id }));
  }
}
