import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

interface SelectboxSetting {
  dataset: string;
  includingInactive: boolean;
  includingDeleted: boolean;
  filterConnector: string;
  filters: { key: string; value: any }[];
}

@Component({
  selector: 'sc-selectbox-setting-form',
  templateUrl: './selectbox-setting-form.component.html',
  styleUrls: ['./selectbox-setting-form.component.scss']
})
export class SelectboxSettingFormComponent implements OnInit, OnDestroy {
  @Output() valueChanged = new EventEmitter<SelectboxSetting>();

  form: FormGroup;
  selectItems: { [key: string]: SelectItem[] } = {};

  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectItems.connectors = config().selectItems.connectors;

    // TODO: find the place to store it
    this.selectItems.dataset = [
      { label: 'devices', value: 'devices' },
      { label: 'locations', value: 'locations' }
    ];

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);

    this.sharedService.clearTimeouts(this.timeouts);
  }

  initForm() {
    this.form = this.formBuilder.group({
      dataset: [null, Validators.required],
      includingInactive: false,
      includingDeleted: false,
      filterConnector: ['AND', Validators.required],
      filters: this.formBuilder.array([])
    });

    this.subscribers.FORM_DATA = this.form.valueChanges.subscribe(
      (value: SelectboxSetting) => {
        clearTimeout(this.timeouts.FORM_DATA);
        this.timeouts.FORM_DATA = setTimeout(() => {
          this.valueChanged.emit(value);
        }, 1000);
      }
    );
  }

  get filters(): FormArray {
    return this.form.get('filters') as FormArray;
  }

  addFilter() {
    const filters = this.filters;
    filters.push(
      this.formBuilder.group({
        key: [null, Validators.required],
        value: [null, Validators.required]
      })
    );
  }

  removeFilter(i: number) {
    const filters = this.filters;
    filters.removeAt(i);
  }
}
