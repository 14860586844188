<div [formGroup]="group" class="ui-g-12 ui-g-nopad">
  <div class="ui-g-10 ui-g-nopad">
    <!-- EXECUTE_ONLY_ONE_TIME -->
    <div class="ui-g-1">
      <div class="text-center" *ngIf="!isEnd">
        <sc-checkbox-input
          controlName="executeOnlyOneTimeUntilRuleWasEnd"
          [form]="group"
          label="{{ 'EXECUTE_ONLY_ONE_TIME_UNTIL_RULE_WAS_END' | translate }}"
          [showLabel]="false"
        ></sc-checkbox-input>
      </div>
      <div class="text-center" *ngIf="isEnd">
        <sc-checkbox-input
          controlName="executeOnlyOneTimeUntilRuleWasStart"
          [form]="group"
          label="{{ 'EXECUTE_ONLY_ONE_TIME_UNTIL_RULE_WAS_START' | translate }}"
          [showLabel]="false"
        ></sc-checkbox-input>
      </div>
    </div>

    <div class="ui-g-1 text-center">
      <sc-checkbox-input
        controlName="executeOnlyIfDifferentFromLocal"
        [form]="group"
        label="{{ 'EXECUTE_ONLY_IF_DIFFERENT_FROM_LOCAL' | translate }}"
        [showLabel]="false"
      ></sc-checkbox-input>
    </div>

    <!-- TARGET_TYPE_ID FIELD -->
    <div class="ui-g-2">
      <sc-dropdown
        controlName="targetTypeId"
        [form]="group"
        label="{{ 'TARGET_TYPE' | translate }}"
        [options]="optionData['targetTypes']"
        [showLabel]="false"
      ></sc-dropdown>
    </div>

    <div class="ui-g-2" [ngSwitch]="group.get('targetTypeId')?.value">
      <!-- DEVICE_TYPE_ID FIELD -->
      <div *ngSwitchCase="'d'">
        <sc-dropdown
          controlName="deviceTypeId"
          [form]="group"
          label="{{ 'DEVICE_TYPE' | translate }}"
          [options]="optionData['deviceTypes']"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <!-- DEVICE_TYPE_ID FIELD -->
      <div *ngSwitchCase="'dvs'">
        <sc-dropdown
          controlName="deviceTypeId"
          [form]="group"
          label="{{ 'DEVICE_TYPE' | translate }}"
          [options]="optionData['deviceTypes']"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <!-- LOCATION_PROPERTY_TYPE FIELD -->
      <div *ngSwitchCase="'l'">
        <sc-dropdown
          controlName="locationPropertyTypeId"
          [form]="group"
          label="{{ 'LOCATION_PROPERTY_TYPE' | translate }}"
          [options]="optionData['locationPropertyTypes']"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <!-- SERVICE_TYPE_ID FIELD -->
      <div *ngSwitchCase="'s'">
        <sc-dropdown
          controlName="serviceTypeId"
          [form]="group"
          label="{{ 'SERVICE_TYPE' | translate }}"
          [options]="optionData['services']"
          [showLabel]="false"
        ></sc-dropdown>
      </div>
    </div>

    <!-- ACTION -->
    <div class="ui-g-6 ui-g-nopad">
      <!-- DEVICE -->
      <div class="ui-g-12 ui-g-nopad" *ngIf="group.get('targetTypeId')?.value === 'd'">
        <!-- DEVICE ACTION FIELD -->
        <div class="ui-g-6" *ngIf="actions?.length > 0">
          <sc-dropdown
            controlName="action"
            [form]="group"
            label="{{ 'ACTION' | translate }}"
            [options]="actions"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <!-- PARAMETERS FIELD -->
        <div class="ui-g-6" *ngIf="parameters?.length > 0">
          <p-multiSelect
            [formControl]="selectedParameters"
            [options]="parameters"
            defaultLabel="{{ 'SELECT' | translate }} {{ 'PARAMETERS' | translate }}"
            [style]="{ 'width': '100%' }"
            appendTo="body"
          ></p-multiSelect>
        </div>
      </div>

      <!-- DEVICE VALUE STORAGE ACTION FIELD -->
      <div class="ui-g-12 ui-g-nopad" *ngIf="group.get('targetTypeId')?.value === 'dvs'">
        <div class="ui-g-6">
          <sc-dropdown
            controlName="action"
            [form]="group"
            label="{{ 'ACTION' | translate }}"
            [options]="optionData['deviceValueStorageActions']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>
      </div>

      <!-- LOCATION -->
      <div class="ui-g-12 ui-g-nopad" *ngIf="group.get('targetTypeId')?.value === 'l'">
        <div class="ui-g-6">
          <p-dropdown
            [formControl]="locationModifyType"
            [options]="optionData['locationModifyTypes']"
            placeholder="{{ 'SELECT' | translate }} {{ 'LOCATION_MODIFY_TYPES' | translate }}"
            filter="true"
            [style]="{ 'width': '100%' }"
            appendTo="body"
          ></p-dropdown>
        </div>

        <div class="ui-g-6">
          <div [ngSwitch]="locationModifyType.value">
            <div *ngSwitchCase="'adjust'">
              <div *ngIf="locationPropertyMappingValues">
                <sc-dropdown
                  controlName="locationPropertyAdjustValue"
                  [form]="group"
                  label="{{ 'ADJUST_VALUE' | translate }}"
                  [options]="locationPropertyMappingValues"
                  [showLabel]="false"
                ></sc-dropdown>
              </div>

              <div *ngIf="!locationPropertyMappingValues">
                <sc-text-input
                  controlName="locationPropertyAdjustValue"
                  [form]="group"
                  label="{{ 'ADJUST_VALUE' | translate }}"
                  [showLabel]="false"
                ></sc-text-input>
              </div>
            </div>

            <div *ngSwitchCase="'force'">
              <div *ngIf="locationPropertyMappingValues">
                <sc-dropdown
                  controlName="locationPropertyForceValue"
                  [form]="group"
                  label="{{ 'FORCE_VALUE' | translate }}"
                  [options]="locationPropertyMappingValues"
                  [showLabel]="false"
                ></sc-dropdown>
              </div>

              <div *ngIf="!locationPropertyMappingValues">
                <sc-text-input
                  controlName="locationPropertyForceValue"
                  [form]="group"
                  label="{{ 'FORCE_VALUE' | translate }}"
                  [showLabel]="false"
                ></sc-text-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- SERVICE -->
      <div class="ui-g-12 ui-g-nopad" *ngIf="group.get('targetTypeId')?.value === 's'">
        <div class="ui-g-6">
          <sc-dropdown
            controlName="notificationTemplateId"
            [form]="group"
            label="{{ 'NOTIFICATION_TEMPLATE' | translate }}"
            [options]="optionData['notificationTemplates']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>
      </div>
    </div>
  </div>

  <!-- DELAY -->
  <div class="ui-g-1">
    <sc-number-input
      controlName="delay"
      [form]="group"
      label="{{ 'DELAY' | translate }}"
      [showLabel]="false"
      [min]="0"
    ></sc-number-input>
  </div>

  <!-- REMOVE_BUTTON -->
  <div class="ui-g-1">
    <button
      pButton
      type="button"
      class="ui-button-warning"
      icon="fa fa-trash"
      (click)="removed.emit()"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'COMMAND' | translate }}"
      appendTo="body"
    ></button>
  </div>
</div>
