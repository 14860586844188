import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ModalConfig } from '@sc/modal/modal-config';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

@Component({
  selector: 'sc-site-action-cell',
  templateUrl: './site-action-cell.component.html',
})
export class SiteActionCellComponent implements ICellRendererAngularComp {
  cell: any;
  formConfig: ModalConfig;

  actions: any[];

  constructor(private store: Store<fromStore.State>) {}

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'SiteForm',
      data: this.cell.data,
      options: { modalTitle: 'SITE_FORM_TITLE' },
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        permission: 'site_u',
        type: 'modal',
        modalConfig: this.formConfig,
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        permission: 'site_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];
  }

  refresh(): boolean {
    return false;
  }

  deleteRow() {
    this.store.dispatch(new fromStore.DeleteSites(this.cell.data));
  }
}
