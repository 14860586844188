import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-property-form',
  templateUrl: './property-form.component.html',
  styleUrls: ['./property-form.component.scss'],
})
export class PropertyFormComponent implements OnInit, OnDestroy {
  @Input()
  form: FormGroup;

  @Input()
  selectItems: { [key: string]: SelectItem[] } = {};

  @Input()
  selectedProtocol: string;

  @Input()
  selectedLibrary: string;

  @Input()
  sourceData: { [key: string]: any } = {};

  mappingValues: any[] = [];

  private subscribers: { [key: string]: any } = {};

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    const formValue = this.form.value;

    if (formValue && formValue.type) {
      this.getMappingValues(formValue.type);
    }

    this.subscribers.deviceType = this.form.get('type').valueChanges.subscribe((value) => {
      if (!value) {
        this.mappingValues = [];
      } else {
        this.getMappingValues(value);
      }
    });
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private getMappingValues(deviceType: string) {
    if (this.sourceData && this.sourceData.deviceTypes) {
      const type = this.sourceData.deviceTypes.find((item) => item.key === deviceType);
      if (type && type.mappingValues) {
        this.mappingValues = type.mappingValues.map((item) => ({
          label: `${item.key} - ${item.name}`,
          value: item.key,
        }));
      }
    }
  }
}
