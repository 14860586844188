<div class="location-summary-form">
  <div class="sc-flex my-3">
    <!-- <input
      id="enable-customization"
      type="checkbox"
      [(ngModel)]="enableCustomization"
    />
    <label for="enable-customization">
      {{ "FLOORPLAN_LOCATION_SUMAMRY_ENABLE_CUSTOMIZATION" | translate }}
    </label> -->
    <p-checkbox
      inputId="enable-customization"
      [(ngModel)]="enableCustomization"
      [label]="'FLOORPLAN_LOCATION_SUMAMRY_ENABLE_CUSTOMIZATION' | translate"
    ></p-checkbox>
  </div>

  <div
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    class="location-properties-list"
  >
    <sc-location-summary-form-item
      *ngFor="let summaryItem of summaryItems"
      [data]="summaryItem"
    ></sc-location-summary-form-item>
  </div>

  <div class="sc-flex justify-end">
    <!-- <button
      type="button"
      pButton
      class="ui-button-success"
      [label]="'OK' | translate"
      (click)="onfinish()"
    ></button> -->

    <button
      type="button"
      pButton
      class="ui-button-success"
      [label]="'SAVE' | translate"
      (click)="onSave()"
    ></button>
  </div>
</div>
