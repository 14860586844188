<div class="apply-rule">
  <sc-rule-form
    [data]="ruleFormData"
    (onClose)="afterRuleCreated($event)"
  ></sc-rule-form>

  <!-- <div *ngIf="ruleTemplateControl" class="sc-flex">
    <p-dropdown
      [formControl]="ruleTemplateControl"
      [options]="selectItems?.ruleTemplates"
      placeholder="{{ 'SELECT' | translate }} {{ 'RULE_TEMPLATE' | translate }}"
      filter="true"
      [style]="{ width: '100%' }"
      appendTo="body"
    ></p-dropdown>

    <button
      type="button"
      pButton
      label="{{ 'APPLY' | translate }}"
      class="ui-button-success"
      (click)="onSelectRuleTemplate()"
    ></button>
  </div> -->
</div>
