import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRouter from '../reducers/router.reducer';

export const getRouterState = createFeatureSelector<fromRouter.RouterState>(
  'router'
);

export const getNavigationId = createSelector(
  getRouterState,
  (state: fromRouter.RouterState) => state && state.navigationId
);

export const getRouterStateUrl = createSelector(
  getRouterState,
  (state: fromRouter.RouterState) => state && state.state
);

export const getRouterStateUrlParams = createSelector(
  getRouterStateUrl,
  (stateUrl: fromRouter.RouterStateUrl) => stateUrl && stateUrl.params
);

export const getRouterStateUrlQueryParams = createSelector(
  getRouterStateUrl,
  (stateUrl: fromRouter.RouterStateUrl) => stateUrl && stateUrl.queryParams
);
