<div [formGroup]="group" >
  <div class="ui-g-12 p-0">
    <div class="ui-g-5">
      <sc-dropdown
        controlName="property"
        [form]="group"
        label="{{ 'PROPERTY' | translate }}"
        [options]="properties"
      ></sc-dropdown>
    </div>
    <div class="ui-g-5">
      <sc-autocomplete-input
        controlName="value"
        [form]="group"
        label="{{ 'VALUE' | translate }}"
        [options]="elements"
      ></sc-autocomplete-input>
    </div>
    <div class="ui-g-2 text-right mt-4">
      <button
        pButton
        type="button"
        class="ui-button-warning"
        icon="fa fa-trash"
        pTooltip="{{ 'REMOVE' | translate }} {{ 'ELEMENT_STYLE' | translate }}"
        appendTo="body"
        tooltipPosition="top"
        (click)="remove()"
      ></button>
    </div>
  </div>
</div>
