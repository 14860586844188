import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { GuestSession } from '../models/guest-session';
import { Site } from '../../sites/models/site';
import { ModalConfig } from '@sc/modal/modal-config';

import { GuestSessionsService } from '../services/guest-sessions.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';
import { GuestSessionActionCellComponent } from '../guest-session-action-cell/guest-session-action-cell.component';

@Component({
  selector: 'sc-guest-sessions-table',
  templateUrl: 'guest-sessions-table.component.html',
})
export class GuestSessionsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: GuestSession[];
  formConfig: ModalConfig;
  tableTitle: string;
  subscribers: { [key: string]: any };
  selectedSite: Site;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private guestSessionsService: GuestSessionsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'firstname',
        headerName: 'FIRSTNAME',
        field: 'firstname',
      },
      {
        colId: 'lastname',
        headerName: 'LASTNAME',
        field: 'lastname',
      },
      {
        colId: 'locale',
        headerName: 'LOCALE',
        field: 'locale',
      },
      {
        colId: 'gender',
        headerName: 'GENDER',
        field: 'gender',
      },
      {
        colId: 'locationId',
        headerName: 'LOCATION_ID',
        field: 'locationId',
      },
      {
        colId: 'pin',
        headerName: 'PIN',
        field: 'pin',
      },
      {
        colId: 'validFrom',
        headerName: 'VALID_FROM',
        field: 'validFrom',
        width: 150,
        valueFormatter: (params) => (params.value ? this.sharedService.dateFormat(params.value) : ''),
      },
      {
        colId: 'validTo',
        headerName: 'VALID_TO',
        field: 'validTo',
        width: 150,
        valueFormatter: (params) => (params.value ? this.sharedService.dateFormat(params.value) : ''),
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: GuestSessionActionCellComponent,
      },
    ];
    this.formConfig = {
      name: 'GuestSessionForm',
      options: { modalTitle: 'GUEST_SESSION_FORM_TITLE' },
    };
    this.tableTitle = 'GUEST_SESSIONS';
    this.subscribers = {};

    // Get Selected Site
    this.store
      .select(fromStore.getSelectedSite)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.fetchDataset();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    if (this.selectedSite) {
      const options = {
        companyId: this.selectedSite.companyId + '',
        siteId: this.selectedSite.id + '',
      };
      this.subscribers.GET_DATASET = this.guestSessionsService.getGuestSessions(options).subscribe((result: any) => {
        this.dataset = result.data;
      });
    }
  }
}
