import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DeviceModel } from '../models/device-model';
import { DeviceModelInstruction } from '../models/device-model-instruction';
import { DeviceModelProperty } from '../models/device-model-property';
import { DeviceModelFormService } from '../services/device-model-form.service';
import { DeviceModelService } from '../services/device-model.service';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { DeviceBrandsService } from '@widgets/device-brands/services/device-brands.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { ProtocolControllersService } from '@widgets/devices/services/protocol-controllers.service';
import { SharedService } from '@shared/shared.service';
import { Locale } from '@widgets/locales/models/locale';
import { config } from '@app/config';

@Component({
  selector: 'sc-device-model-form',
  templateUrl: './device-model-form.component.html',
  styleUrls: ['./device-model-form.component.scss'],
})
export class DeviceModel2FormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  formData: DeviceModel;
  form: FormGroup;
  instructionsForm: FormGroup;
  propertiesForm: FormArray;
  isFirstChange = true;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};
  selectedDeviceType: string;
  selectedTab: string = 'p';
  selectedProperty: number = 0;
  step = 1;
  tabs: any[] = [
    { label: 'PROPERTIES', value: 'p' },
    { label: 'INSTRUCTIONS', value: 'i' },
  ];
  sourceData: { [key: string]: any } = {};

  private locales: Locale[];
  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceModelFormsService: DeviceModelFormService,
    private deviceModelsService: DeviceModelService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceBrandsService: DeviceBrandsService,
    private deviceTypesService: DeviceTypesService,
    private protocolControllersService: ProtocolControllersService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.locales = this.sharedService.sourceData.locales;
    // this.initForm();
    this.fetchDeviceTypes();
    this.fetchDeviceBrands();
    this.fetchDeviceProtocols();
    this.fetchProtocolControllers();

    // EDIT MODE
    if (this.data) {
      this.editMode = true;
      this.fetchDeviceModel(this.data.id);
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDeviceModel(id: number) {
    this.fetchingState++;
    this.subscribers.fetchDeviceModel = this.deviceModelsService.getDeviceModel(id).subscribe((result: any) => {
      this.formData = result.data;
      this.selectedDeviceType = this.formData.is_automation ? 'a' : 'd';
      this.formData.instructions = this.formData.instructions && JSON.parse(this.formData.instructions as string);
      this.formData.properties = this.formData.properties && JSON.parse(this.formData.properties as string);
      this.formData.modbus_settings = this.formData.modbus_settings
        ? JSON.parse(this.formData.modbus_settings as string)
        : {};

      this.onSelectDeviceType(this.selectedDeviceType);

      this.form.patchValue(this.formData);
      if (this.selectedDeviceType === 'd') {
        this.deviceModelFormsService.patchPropertiesForm(this.form, this.formData.properties as DeviceModelProperty[]);
        this.deviceModelFormsService.patchLocaleFormArray(
          this.instructionsForm,
          this.formData.instructions as DeviceModelInstruction
        );
      }
      this.fetchingState--;
    });
  }

  private fetchDeviceBrands() {
    this.fetchingState++;
    this.subscribers.fetchDeviceBrands = this.deviceBrandsService.getDeviceBrands().subscribe((result: any) => {
      this.selectItems.brands = this.sharedService.createSelectItems(
        result.data.filter((d) => d.isActive && !d.isDeleted),
        false,
        'key'
      );
      this.fetchingState--;
    });
  }

  private fetchDeviceProtocols() {
    this.fetchingState++;
    this.subscribers.fetchDeviceProtocols = this.deviceProtocolsService
      .getDeviceProtocols()
      .subscribe((result: any) => {
        this.sourceData.protocols = result.data;
        this.selectItems.protocols = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted),
          false,
          'key'
        );
        this.fetchingState--;

        if (this.form) {
          this.updateSelectItemLibraries(this.form.value.protocol);
        }
      });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.sourceData.deviceTypes = result.data;
      this.selectItems.deviceTypes = this.sharedService.createSelectItems(
        result.data.filter((d) => d.isActive && !d.isDeleted && d.key !== 'a' && d.key !== 'g'),
        false,
        'key'
      );
      this.fetchingState--;
    });
  }

  private fetchProtocolControllers() {
    this.fetchingState++;
    this.subscribers.fetchProtocolControllers = this.protocolControllersService
      .getProtocolControllers()
      .subscribe((result: any) => {
        this.sourceData.protocolControllers = result.data;
        this.selectItems.protocolControllers = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted),
          false,
          'key'
        );
        this.fetchingState--;

        if (this.form) {
          this.updateSelectItemLibraries(this.form.value.protocol);
        }
      });
  }

  private initForm() {
    const selectItems = config().selectItems;
    this.selectItems.zwaveFrequencies = selectItems.zwaveFrequencies;
    this.selectItems.dataTypes = selectItems.dataTypes;

    this.form = this.deviceModelFormsService.initDeviceModelForm(this.locales);
    if (this.selectedDeviceType === 'd') {
      this.form.get('protocol').setValidators(Validators.required);
      this.form.patchValue({
        frequency: 'eu',
        protocol: 'zwave',
      });
      this.form.updateValueAndValidity();
      this.propertiesForm = this.form.get('properties') as FormArray;
      this.instructionsForm = this.form.get('instructions') as FormGroup;

      this.updateSelectItemLibraries(this.form.value.protocol);

      if (!this.editMode) {
        this.addProperty();
      }

      this.subscribers.protocol = this.form.get('protocol').valueChanges.subscribe((value) => {
        this.updateSelectItemLibraries(value);
        const frequency = this.form.get('frequency');
        if (value === 'zwave') {
          frequency.setValidators(Validators.required);
        } else {
          frequency.clearValidators();
        }

        if (!this.editMode || (this.editMode && !this.isFirstChange)) {
          frequency.reset();
        }

        if (this.editMode && this.isFirstChange) {
          this.isFirstChange = false;
        }

        this.form.updateValueAndValidity();
      });

      this.selectItems.readFunctions = [
        { label: 'Discrete Output Coil (FC 01)', value: 'fc01' },
        { label: 'Discrete Input Contact (FC 02)', value: 'fc02' },
        { label: 'Analog Output Holding Register (FC 03)', value: 'fc03' },
        { label: 'Analog Input Register (FC 04)', value: 'fc04' },
      ];

      this.selectItems.modbusDataTypes = [
        { label: 'Number', value: 'number' },
        { label: 'Boolean', value: 'bool' },
        { label: 'Integer (16)', value: 'int16' },
        { label: 'Unsigned Integer (16)', value: 'uint16' },
        { label: 'Integer (32)', value: 'int32' },
        { label: 'Unsigned Integer (32)', value: 'uint32' },
        { label: 'Float (32)', value: 'float32' },
      ];

      this.selectItems.writeFunctions = [
        { label: 'Discrete Outpout Coil (FC 05)', value: 'fc05' },
        { label: 'Analog Output Holding Register (FC 06)', value: 'fc06' },
        { label: 'Write Multiple Register (FC 16)', value: 'fc16' },
      ];

      this.selectItems.modbusOrders = [
        { label: 'Big-endian', value: 'be' },
        { label: 'Little-endian', value: 'le' },
      ];
    } else {
      this.form.get('library').clearValidators();
    }
  }

  private updateSelectItemLibraries(protocolKey: string) {
    if (!protocolKey) {
      return;
    }
    this.selectItems.libraries = [];
    const protocols = this.sourceData.protocols;
    const controllers = this.sourceData.protocolControllers;
    if (protocols && protocols.length && controllers && controllers.length) {
      const protocol = protocols.find((item) => item.key === protocolKey);
      if (protocol) {
        const libraries = controllers.filter(
          (item) => item.isActive && !item.isDeleted && item.protocolId === protocol.id
        );
        this.selectItems.libraries = this.sharedService.createSelectItems(libraries, false, 'key');
      }
    }
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
    // TODO: do something based on tab
  }

  onSelectProperty(event, opened) {
    if (opened) {
      this.selectedProperty = event.index;
    } else {
      this.selectedProperty = null;
    }
  }

  addProperty() {
    const form = this.deviceModelFormsService.initPropertyForm();
    this.propertiesForm.push(form);
  }

  removeProperty(index: number) {
    this.propertiesForm.removeAt(index);
  }

  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = { ...this.form.value };
    const locales = formData.instructions.locales;
    delete formData.instructions.locales;

    formData.instructions.full = [];
    formData.instructions.inclusion = [];
    formData.instructions.exclusion = [];

    if (locales && locales.length) {
      for (const locale of locales) {
        if (locale.locale) {
          if (locale.full) {
            formData.instructions.full.push({ language: locale.locale, text: locale.full });
          }
          if (locale.inclusion) {
            formData.instructions.inclusion.push({ language: locale.locale, text: locale.inclusion });
          }
          if (locale.exclusion) {
            formData.instructions.exclusion.push({ language: locale.locale, text: locale.exclusion });
          }
        }
      }
    }

    if (formData.properties && formData.properties.length) {
      for (const prop of formData.properties) {
        if (prop.values && prop.values.length) {
          prop.values = prop.values.map((val) => {
            return {
              mapped: val.mapped,
              raw: {
                read: val.raw_read,
                write: val.raw_write,
                read_type: val.raw_read_type,
                write_type: val.raw_write_type,
              },
              library: val.library,
            };
          });
        }
      }
    }

    // call api
    if (!this.editMode) {
      this.create(formData);
    } else {
      this.update(formData);
    }
  }

  private create(data: DeviceModel) {
    this.subscribers.create = this.deviceModelsService.createDeviceModel(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(data: DeviceModel) {
    this.subscribers.update = this.deviceModelsService.updateDeviceModel(this.formData.id, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete(force = false) {
    this.subscribers.delete = this.deviceModelsService.deleteDeviceModel(this.formData.id, force).subscribe({
      next: (result: any) => {
        if (result.warning) {
          this.subscribers.delete = this.sharedService
            .deleteWarningHandler(this.data, result.warning)
            .subscribe((confirm: any) => {
              if (confirm === true) {
                this.delete(true);
              }
            });
        } else {
          this.apiCallSuccess('DELETE_SUCCESS', result);
        }
      },
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE_MODEL');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  onSelectDeviceType(type: string) {
    this.selectedDeviceType = type;
    this.initForm();
    if (type === 'a') {
      this.form.patchValue({ is_automation: true });
    }
    this.step = 2;
  }

  onSelectLibrary() {
    const properties = this.propertiesForm.getRawValue();
    properties.forEach((property) => {
      property.values.forEach((value) => {
        value.library = this.selectedLibrary;
      });
    });
    this.propertiesForm.patchValue(properties);
  }

  onSelectProtocol() {
    if (this.selectedProtocol === 'internal') {
      this.form.get('library').clearValidators();
    }
  }

  get selectedProtocol() {
    return this.form.get('protocol').value;
  }

  get selectedLibrary() {
    return this.form.get('library').value;
  }
}
