import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

@Component({
  selector: 'sc-company-action-cell',
  templateUrl: 'company-action-cell.component.html',
})
export class CompanyActionCellComponent implements ICellRendererAngularComp {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  constructor(private store: Store<fromStore.State>) {}

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'CompanyForm',
      data: this.cell.data,
      options: { modalTitle: 'COMPANY_FORM_TITLE' },
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: '_u',
        type: 'modal',
        modalConfig: this.formConfig,
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];
  }

  refresh(): boolean {
    return false;
  }

  deleteRow() {
    this.store.dispatch(new fromStore.DeleteCompany(this.cell.data));
  }
}
