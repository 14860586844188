<a
  class="toolbar-item site-chooser"
  (click)="panel.show($event)"
  [ngClass]="{ active: opened }"
>
  <span class="toolbar-item-icon">
    <span class="fa fa-fw fa-globe"></span>
  </span>
  <span class="toolbar-item-label">
    {{ selectedSite?.name }}
  </span>
  <span class="toolbar-item-arrow">
    <span class="fa fa-fw fa-angle-down"></span>
  </span>
</a>

<p-overlayPanel
  #panel
  appendTo="body"
  styleClass="toolbar-site-chooser"
  (onShow)="opened = true"
  (onHide)="opened = false"
>
  <div class="search" *ngIf="showSearch">
    <input
      type="text"
      class="search-input"
      [formControl]="searchForm"
      [placeholder]="'SEARCH' | translate"
    />
  </div>

  <ul class="menu">
    <li
      class="menu-item"
      *ngFor="let site of filteredSites"
      (click)="chooseSite(site); panel.hide()"
    >
      <i
        class="fa fa-fw"
        [ngClass]="{
          'fa-check-square-o': selectedSite?.id === site.id,
          'fa-square-o': selectedSite?.id !== site.id
        }"
      ></i>
      {{ site.name }}
    </li>
  </ul>
</p-overlayPanel>
