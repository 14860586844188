import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LeftOperandTypes, DataSources, RuleEditorModes, SelectItems } from '../../models';

@Component({
  selector: 'sc-device-operand',
  templateUrl: './device-operand.component.html',
  styleUrls: ['./device-operand.component.scss'],
})
export class DeviceOperandComponent implements OnInit {
  _ruleEditorModes = RuleEditorModes;
  modifiers: any;
  devices: any[];

  @Input()
  form: FormGroup;

  @Input()
  valueFormControlName = 'device_idx';

  @Input()
  deviceTypeFormControlName = 'device_type';

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  viewMode: string;

  @Input()
  isTemplate: boolean;

  private operand: any;

  constructor() {}

  ngOnInit(): void {
    if (this.dataSources.operands && this.dataSources.operands.length) {
      this.operand = this.dataSources.operands.find((o) => o.id === LeftOperandTypes.DeviceValue);
      if (this.operand && this.operand.modifiers) {
        this.modifiers = this.operand.modifiers;
      }
    }

    if (this.form) {
      const formValue = this.form.getRawValue();
      this.updateDevicesList(formValue.device_type);
    }
  }

  private updateDevicesList(deviceTypeKey?: string) {
    this.devices = [];

    if (this.dataSources && this.dataSources.devicesTree && this.dataSources.devicesTree.length) {
      if (!deviceTypeKey) {
        this.devices = this.dataSources.devicesTree.filter((item) => item.children && item.children.length > 0);
      } else {
        for (const dev of this.dataSources.devicesTree) {
          if (!dev.children || !dev.children.length) {
            continue;
          }

          const children = dev.children.filter((item) => item.data.device_type === deviceTypeKey);
          if (children && children.length) {
            this.devices.push({ ...dev, children });
          }
        }
      }

      this.devices = this.devices.map((item) => ({ ...item, selectable: false }));
    }
  }
}
