<div [formGroup]="form" class="ui-g">
  <!-- TYPE -->
  <div class="ui-g-3">{{ deviceTypeName }}</div>

  <!-- NAME -->
  <div class="ui-g-3">
    <sc-text-input
      controlName="description"
      [form]="form"
      label="{{ 'DESCRIPTION' | translate }}"
      [showLabel]="false"
    ></sc-text-input>
  </div>

  <!-- ASSOCIATION -->
  <div class="ui-g-3">
    <sc-dropdown
      controlName="gatewayId"
      [form]="form"
      label="{{ 'GATEWAY' | translate }}"
      [options]="data.selectItems['gateways']"
      [showLabel]="false"
    ></sc-dropdown>
  </div>

  <!-- ACTIVE -->
  <div class="ui-g-1">
    <sc-checkbox-input
      controlName="isActive"
      [form]="form"
      label="{{ 'ACTIVE' | translate }}"
      [showLabel]="false"
    ></sc-checkbox-input>
  </div>

  <!-- APPLY ALL -->
  <div class="ui-g-2" *ngIf="applyAll && gatewayId">
    <p-checkbox
      [formControl]="applyAll"
      binary="true"
      label="{{ 'APPLY_ALL' | translate }}"
    ></p-checkbox>
  </div>
</div>
