import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { DeviceWizardModule } from '@widgets/device-wizard/device-wizard.module';
import { DevicesModule } from '@widgets/devices/devices.module';
import { LocationsModule } from '@widgets/locations/locations.module';
import { RulesModule } from '@widgets/rules/rules.module';

import { FloorPlannerComponent } from './floor-planner.component';
import { LocationShapeFormComponent } from './location-shape-form/location-shape-form.component';
import { LocationAssignFormComponent } from './location-assign-form/location-assign-form.component';
import { DeviceObjectFormComponent } from './device-object-form/device-object-form.component';
import { DeviceAssignFormComponent } from './device-assign-form/device-assign-form.component';
import { ApplyRuleFormComponent } from './apply-rule-form/apply-rule-form.component';
import { LocationDetailsModalComponent } from './location-details-modal/location-details-modal.component';
import { DeviceDetailsModalComponent } from './device-details-modal/device-details-modal.component';
import { DeviceModelItemComponent } from './device-model-item/device-model-item.component';
import { LocationSummaryFormComponent } from './location-summary-form/location-summary-form.component';
import { LocationSummaryFormItemComponent } from './location-summary-form/location-summary-form-item/location-summary-form-item.component';
import { VirtualDeviceHolderInfoComponent } from './device-details-modal/vdh-info/vdh-info.component';
import { VirtualDevicesTableComponent } from './device-details-modal/vds-table/vds-table.component';
import { VirtualDevicesActionCellComponent } from './device-details-modal/vds-action-cell/vds-action-cell.component';
import { VirtualDeviceLogsComponent } from './device-details-modal/vd-logs/vd-logs.component';
import { LocationInfoComponent } from './location-details-modal/location-info/location-info.component';
import { LocationDevicesComponent } from './location-details-modal/location-devices/location-devices.component';
import { LocationDevicesActionCellComponent } from './location-details-modal/location-devices-action-cell/location-devices-action-cell.component';
import { LocationHistoryTableComponent } from './location-details-modal/location-history/location-history.component';
import { LocationRulesComponent } from './location-details-modal/location-rules/location-rules.component';
import { LocationRuleHistoryComponent } from './location-details-modal/location-rule-history/location-rule-history.component';

const entryComponents: any[] = [
  ApplyRuleFormComponent,
  DeviceAssignFormComponent,
  DeviceDetailsModalComponent,
  DeviceModelItemComponent,
  DeviceObjectFormComponent,
  LocationAssignFormComponent,
  LocationDetailsModalComponent,
  LocationShapeFormComponent,
  LocationSummaryFormComponent,
  VirtualDevicesActionCellComponent,
  LocationDevicesActionCellComponent,
];
const components: any[] = [
  FloorPlannerComponent,
  LocationSummaryFormItemComponent,
  VirtualDeviceHolderInfoComponent,
  VirtualDevicesTableComponent,
  VirtualDevicesActionCellComponent,
  VirtualDeviceLogsComponent,
  LocationInfoComponent,
  LocationDevicesComponent,
  LocationDevicesActionCellComponent,
  LocationHistoryTableComponent,
  LocationRulesComponent,
  LocationRuleHistoryComponent,
];

@NgModule({
  imports: [SharedModule, DeviceWizardModule, DevicesModule, LocationsModule, RulesModule],
  exports: [FloorPlannerComponent],
  declarations: [...components, ...entryComponents],
  entryComponents: [...entryComponents],
  providers: [],
})
export class FloorPlannerModule {}
