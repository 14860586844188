import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { MakeUpMyRoomAlertComponent } from './make-up-my-room-alert.component';

@NgModule({
  imports: [SharedModule],
  declarations: [MakeUpMyRoomAlertComponent],
  entryComponents: [MakeUpMyRoomAlertComponent]
})
export class MakeUpMyRoomAlertModule {}
