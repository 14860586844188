import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Translation, TranslationValue } from './translation.interface';

import * as XLSX from 'xlsx';
import { config } from '../../config';

@Injectable()
export class TranslationsService {
  private apiUrl = `${config().apiUrl}/translations`;

  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {}

  private verifyData(data: any): Translation {
    const newData = { ...data };

    // delete not allowed values on payload
    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    // if (typeof newData.translationId !== 'undefined') {
    //   delete newData.translationId;
    // }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  getTranslation(id?: number) {
    let apiUrl;
    if (id) {
      apiUrl = `${this.apiUrl}/${id}`;
    } else {
      apiUrl = `${this.apiUrl}`;
    }
    return this.httpClient.get(apiUrl);
  }

  createTranslation(data: Translation) {
    return this.httpClient.post(this.apiUrl, this.verifyData(data));
  }

  updateTranslation(id: number, data: Translation) {
    const apiUrl = `${this.apiUrl}/${id}`;
    return this.httpClient.patch(apiUrl, this.verifyData(data));
  }

  deleteTranslation(id: number) {
    const apiUrl = `${this.apiUrl}/${id}`;
    return this.httpClient.patch(apiUrl, { isDeleted: true, isActive: false });
  }

  importTranslation(data: any) {
    return this.httpClient.patch(`${this.apiUrl}/import`, data);
  }

  // ____ FORM
  initValueFormGroup(locale: string = '') {
    return this.formBuilder.group({
      locale: [locale, Validators.required],
      value: [null, Validators.required]
    });
  }
  setValueFormArray(form: FormGroup, data: TranslationValue[]) {
    const formGroups = data.map(item => {
      const fg = this.initValueFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('translationValues', formArray);
  }

  // XLSX
  createXlsx(data: any[][], locale = 'cn') {
    const fileName = `translation-${locale}.xlsx`;
    // generate worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    // generate workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }

  readXlsx(file) {
    return new Promise((resolve, reject) => {
      let data: any[][];
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        return resolve(data);
      };
      reader.readAsBinaryString(file);
    });
  }
}
