import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Injectable()
export class LocationSummaryService {
  private baseUrl = config().apiUrl + '/companies/{companyId}/sites/{siteId}/locations/{locationId}/summary';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) { }

  private getApiUrl(locationId: any) {
    const selectedSite = this.sharedService.selectedSite;
    const apiUrl = this.baseUrl
      .replace('{companyId}', '' + selectedSite.companyId)
      .replace('{siteId}', '' + selectedSite.id)
      .replace('{locationId}', '' + locationId);
    return apiUrl;
  }

  getLocationSummary(locationId: number, options?: any) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    const apiUrl = this.getApiUrl(locationId) + queryParams;
    return this.httpClient.get(apiUrl);
  }

  getLocationSummaryHourly(locationId: number) {
    const apiUrl = this.getApiUrl(locationId) + '/hourly';
    return this.httpClient.get(apiUrl);
  }
}
