import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { GuestRequestPresetActionCellComponent } from './guest-request-preset-action-cell/guest-request-preset-action-cell.component';
import { GuestRequestPresetFormComponent } from './guest-request-preset-form/guest-request-preset-form.component';
import { MultiLanguageFormComponent } from './guest-request-preset-form/multi-language-form/multi-language-form.component';
import { GuestRequestPresetsTableComponent } from './guest-request-presets-table/guest-request-presets-table.component';

import { GuestRequestPresetsService } from './guest-request-presets.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    GuestRequestPresetActionCellComponent,
    GuestRequestPresetFormComponent,
    GuestRequestPresetsTableComponent,
    MultiLanguageFormComponent
  ],
  entryComponents: [
    GuestRequestPresetActionCellComponent,
    GuestRequestPresetFormComponent,
    GuestRequestPresetsTableComponent
  ],
  providers: [GuestRequestPresetsService]
})
export class GuestRequestPresetsModule {}
