<div class="summary-form-item" cdkDrag>
  <!-- <div class="summary-item-checkbox">
    <input [id]="data?.label" type="checkbox" [(ngModel)]="data.hidden" />
    <label [for]="data?.label">
      {{ 'HIDDEN' | translate }}
    </label>
  </div> -->
  <p-checkbox
    inputId="data?.label"
    [(ngModel)]="data.hidden"
    [label]="'HIDDEN' | translate"
  ></p-checkbox>

  <div class="summary-item-label">
    {{ data?.label }}
  </div>

  <div class="summary-item-move-handler" cdkDragHandle>
    <span class="fa fa-arrows-v"></span>
  </div>
</div>
