import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-custom-summary-form',
  templateUrl: 'custom-summary-form.component.html',
  styleUrls: ['custom-summary-form.component.scss'],
})
export class CustomSummaryFormComponent implements OnInit {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  summaryData: { key: string; label: string; visible: boolean }[];
  customSummaryData: {
    key: string;
    label: string;
    position: number;
    visible: boolean;
  }[];
  customSummaryDataCloned: {
    key: string;
    label: string;
    position: number;
    visible: boolean;
  }[];

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    // default config
    this.summaryData = [
      { key: 'occupied', label: 'LHL_OCCUPANCY', visible: true },
      { key: 'doorLocked', label: 'LHL_DOOR_LOCK_STATUS', visible: true },
      { key: 'doorOpen', label: 'LHL_DOOR_OPENING_STATUS', visible: true },
      { key: 'windowOpen', label: 'LHL_WINDOW_OPENING_STATUS', visible: true },
      { key: 'hasRule', label: 'LHL_RULES_APPLIED_STATUS', visible: true },
      { key: 'humidity', label: 'HUMIDITY', visible: true },
      { key: 'temperature', label: 'TEMPERATURE', visible: true },
      { key: 'consumption', label: 'POWERAGE', visible: true },
      { key: 'avgParticulateMatter', label: 'LHL_AVG_PARTICULATE_MATTER', visible: false },
      { key: 'numPeople', label: 'LHL_NUM_PEOPLE', visible: false },
      { key: 'numPeopleTooMany', label: 'LHL_NUM_PEOPLE_TOO_MANY', visible: false },
      { key: 'numSocialDistanceViolations', label: 'LHL_NUM_SOCIAL_DISTANCE_VIOLATIONS', visible: false },
      { key: 'acMode', label: 'AC_MODE', visible: true },
      { key: 'acSetpoint', label: 'AC_SETPOINT', visible: true },
      { key: 'acFanspeed', label: 'AC_FANSPEED', visible: true },
      { key: 'brightness', label: 'BRIGHTNESS', visible: false },
      { key: 'curtainsOpen', label: 'LHL_CURTAIN_STATUS', visible: false },
      { key: 'lightsOn', label: 'LHL_LIGHTING_STATUS', visible: false },
      { key: 'motion', label: 'LHL_MOTION_STATUS', visible: false },
      { key: 'emrTotal', label: 'EMR_TOTAL', visible: true },
    ];

    if (this.sharedService.userVariant === 'scv4') {
      this.summaryData.push(
        { key: 'acStatus', label: 'AC_STATUS', visible: false },
        { key: 'airQuality', label: 'AIR_QUALITY', visible: false },
        { key: 'dndStatus', label: 'DND_STATUS', visible: false },
        { key: 'makeUpStatus', label: 'MAKE_UP_STATUS', visible: false },
        { key: 'oooStatus', label: 'OOO_STATUS', visible: false },
        { key: 'isBookedAndCheckInDone', label: 'IS_BOOKED_AND_CHECK_IN_DONE', visible: false },
        { key: 'isBookedButNoCheckInDone', label: 'IS_BOOKED_BUT_NO_CHECK_IN_DONE', visible: false },
        { key: 'isCheckInInProgress', label: 'IS_CHECK_IN_IN_PROGRESS', visible: false },
        { key: 'isCheckOutInProgress', label: 'IS_CHECK_OUT_IN_PROGRESS', visible: false },
        { key: 'isRaining', label: 'IS_RAINING', visible: false },
        { key: 'outdoorHumidity', label: 'OUTDOOR_HUMIDITY', visible: false },
        { key: 'outdoorTemperature', label: 'OUTDOOR_TEMPERATURE', visible: false },
        { key: 'windSpeed', label: 'WIND_SPEED', visible: false }
      );
    }

    this.customSummaryData = [];
    const customData = this.data;

    for (let i = 0; i < this.summaryData.length; i++) {
      const sdata = this.summaryData[i];
      let found = false;

      if (customData && customData.length) {
        for (const cdata of customData) {
          if (sdata.key === cdata.key) {
            found = true;
            this.customSummaryData.push({
              ...cdata,
              label: sdata.label,
            });
            break;
          }
        }
      }

      if (!found) {
        this.customSummaryData.push({ ...sdata, position: i });
      }
    }

    this.customSummaryDataCloned = this.customSummaryData.map((data) => ({ ...data }));
  }

  toggle(data: any) {
    for (let i = 0; i < this.customSummaryData.length; i++) {
      if (this.customSummaryData[i].key === data.key) {
        this.customSummaryData[i].visible = !this.customSummaryData[i].visible;
        break;
      }
    }
  }

  cancel() {
    this.onDismiss.emit();
  }

  reset() {
    this.onClose.emit(this.customSummaryDataCloned);
  }

  save() {
    this.onClose.emit(this.customSummaryData);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.customSummaryData, event.previousIndex, event.currentIndex);
  }
}
