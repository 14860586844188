<div class="info-container">
  <p>
    {{ 'DEPENDENCY_WARNING_MESSAGE' | translate:{reference: reference} }}
  </p>

  <ul class="dependency-list">
    <li *ngFor="let dep of dependencies" class="dependency-item">
      <span *ngIf="dep?.ruleId">{{ dep?.ruleName }} (ID: {{ dep?.ruleId }}) of location {{ dep?.locationName }}</span>
      <span *ngIf="dep?.deviceId">{{ dep?.deviceName }} (ID: {{ dep?.deviceId }}) of location {{ dep?.locationName }}</span>
    </li>
  </ul>
</div>

<div class="checkbox-container">
  <input type="checkbox" id="confirm" [formControl]="confirm" />
  <label for="confirm">{{ 'DEPENDENCY_WARNING_CHECKBOX_MESSAGE' | translate:{reference: reference} }}</label>
</div>

<div class="action-container">
  <button
    type="button"
    pButton
    [label]="'CANCEL' | translate"
    class="ui-button-secondary"
    (click)="onCancel()"
  ></button>

  <button
    type="button"
    pButton
    class="ui-button-danger"
    [label]="'DELETE' | translate"
    (click)="onConfirm()"
    [disabled]="confirm?.value !== true"
  ></button>
</div>
