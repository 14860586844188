import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '../sites/models/site';
import { SCWidgetService } from '../widget.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-locations-consumption-alert',
  template: `
    <div [ngStyle]="{ 'height.px': config.widgetHeight }">
      <sc-dashboard-circles
        [config]="widgetConfig"
        [dataset]="widgetDataset"
        [loading]="isFirstTime"
      ></sc-dashboard-circles>
    </div>
  `,
})
export class LocationsConsomptionAlertComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: any[];
  info: any;
  isFetching: boolean;
  isFirstTime: boolean;
  locations: any[];
  widgetConfig: any;
  widgetDataset: any;
  widgetName: string = '';

  private intervals: { [key: string]: any } = {};
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private widgetService: SCWidgetService,
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.getSelectedSite = this.store
      .select(fromStore.getSelectedSite)

      .subscribe((result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.isFirstTime = true;
          this.fetchDataset();
          this.intervals.fetchDataset = setInterval(() => this.fetchDataset(), 5000);
        }
      });

    this.initInfo();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  private updateWidgetConfig() {
    this.widgetConfig = {
      titleText: this.widgetName,
      subTitleText: 'FOR_UNOCCUPIED_UNITS',
      modalConfig: {
        name: 'LocationAlertModal',
        data: this.dataset,
        options: {
          modalTitle: 'LOCATION_ALERT',
        },
      },
    };
  }

  /**
   * Number of Circle to display (Maximum is 3)
   */
  private updateWidgetDataset() {
    if (this.locations && this.locations.length > 0) {
      this.widgetDataset = [
        {
          customClass: this.locations.length > 10 ? 'red' : 'orange lighten-1',
          primaryText: this.locations.length,
          size: 'large',
        },
      ];
    } else {
      this.widgetDataset = [
        {
          customClass: 'grey lighten-2',
          primaryText: 0,
          size: 'large',
        },
      ];
    }
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite) {
      this.isFetching = true;
      const { id } = this.selectedSite;
      this.subscribers.GET_DATASET = this.widgetService.getConsumptionAlert(id).subscribe(
        (result: any) => {
          this.dataset = result.data;
          this.prepareInfo();
          this.updateWidgetConfig();
          this.updateWidgetDataset();
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  private initInfo() {
    this.locations = [];
    this.info = {
      occupied: 0,
      locations: 0,
      unoccupied: 0,
      aboveAcceptable: 0,
    };
  }

  private prepareInfo() {
    this.initInfo();
    this.dataset.forEach((l) => {
      // Total Locations
      this.info.locations++;

      if (typeof l.consumption !== 'undefined' && l.consumption > this.selectedSite.maxAcceptableConsumption) {
        // Total Above Acceptable Consumption
        this.info.aboveAcceptable++;

        if (l.occupied === true) {
          // Above Acceptable Consumption but occupy
          this.info.occupied++;
        } else {
          // Above Acceptable Consumption but unoccupy
          this.info.unoccupied++;
          this.locations.push(l);
        }
      }
    });
  }
}
