import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ModalConfig } from '@app/sandc/modal/modal-config';
import { DeviceDetails } from '@app/widgets/devices/models/device-details';
// import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceModel } from '@widgets/device-models-2/models/device-model';

@Component({
  selector: 'sc-vdh-info',
  templateUrl: './vdh-info.component.html',
  styleUrls: ['./vdh-info.component.scss'],
})
export class VirtualDeviceHolderInfoComponent implements OnInit, OnChanges {
  @Input()
  holderDevice: DeviceDetails;
  @Input()
  devices: DeviceDetails[];
  @Input()
  deviceModelEntities: { [key: string]: DeviceModel };

  deviceFormModal: ModalConfig;

  constructor() {}

  ngOnInit() {
    this.deviceFormModal = {
      data: this.holderDevice,
      name: 'DeviceForm',
      options: {
        modalTitle: 'DEVICE_FORM_TITLE',
        requiredPermission: 'device_c',
        fullScreen: true,
      },
    };

    this.updateDeviceData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deviceModelEntities && changes.deviceModelEntities.currentValue) {
      this.updateDeviceData();
    }
  }

  onUpdateSuccess(event) {
    if (event && event.result) {
      this.holderDevice = {
        ...event.result,
      };

      this.updateDeviceData();
    }
  }

  private updateDeviceData() {
    if (!this.deviceModelEntities || !this.devices || !this.holderDevice) {
      return;
    }

    const automation = this.devices.find((d) => d.id === this.holderDevice.automationId);
    const model: DeviceModel = this.deviceModelEntities[this.holderDevice.deviceModelId];

    this.holderDevice.automationName = automation ? automation.description : '';
    this.holderDevice.modelName = model ? model.product_name : '';
    this.holderDevice.protocolName = model ? model.protocol : '';
    // this.holderDevice.modelName = model ? model.name : '';
    // this.holderDevice.protocolName = model ? model.protocolName : '';
  }
}
