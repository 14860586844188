<nav class="text-center">
  <button
    pButton
    type="button"
    class="ui-button-secondary"
    icon="fa fa-play"
  ></button>
  <button
    pButton
    type="button"
    class="ui-button-secondary"
    icon="fa fa-stop"
  ></button>
</nav>

