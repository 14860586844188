import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MassRule } from '../models/rule';
import { RuleCommand } from '../models/rule-command';
import { RuleCommandParameter } from '../models/rule-command-parameter';
import { RuleCondition } from '../models/rule-condition';

import { RuleTemplateCommand } from '@widgets/rule-templates/models/rule-template-command';
import { RuleTemplateCondition } from '@widgets/rule-templates/models/rule-template-condition';
import { RuleTemplate } from '@app/widgets/rule-templates/models/rule-template';

@Injectable()
export class RuleMassEditFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initOptionsForm(): FormGroup {
    return this.formBuilder.group({
      ruleTemplate: [null, Validators.required],
      locations: [null, Validators.required],
      action: ['edit', Validators.required]
    });
  }

  initRuleForm(rule: MassRule, ruleTemplate: RuleTemplate): FormGroup {
    const form = this.formBuilder.group({
      id: [rule.id, Validators.required],
      ruleTemplateId: [rule.ruleTemplateId, Validators.required],
      locationId: [rule.locationId, Validators.required],
      locationName: rule.locationName,
      startCommands: this.formBuilder.array([]),
      endCommands: this.formBuilder.array([]),
      startConditions: this.formBuilder.array([]),
      endConditions: this.formBuilder.array([]),
      errors: null
    });

    this.setConditionFormArray(form, rule, ruleTemplate, 'startConditions');
    this.setConditionFormArray(form, rule, ruleTemplate, 'endConditions');
    this.setCommandFormArray(form, rule, ruleTemplate, 'startCommands');
    this.setCommandFormArray(form, rule, ruleTemplate, 'endCommands');

    return form;
  }

  initConditionFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: null,
      errors: null,
      requiredValidityPeriod: null,
      sourceType: null,
      connector: null,
      deviceId: null,
      deviceTypeId: null,
      datetimePropertyTypeId: null,
      datetimePropertyValue: null,
      event: null,
      locationId: null,
      locationPropertyTypeId: null,
      operator: null,
      parameters: null,
      referencedType: null,
      referencedDeviceId: null,
      referencedDeviceTypeId: null,
      referencedLocationId: null,
      referencedLocationPropertyTypeId: null,
      referencedValue: null,
      wasTrueOneTimeInPastMs: null
    });
  }

  setConditionFormArray(
    form: FormGroup,
    rule: MassRule,
    template: RuleTemplate,
    key: string
  ) {
    const tplCondtions = template[key];
    const condtions = rule[key];
    const formGroups = [];

    for (let i = 0; i < tplCondtions.length; i++) {
      const fg = this.initConditionFormGroup();

      fg.patchValue({
        ...tplCondtions[i],
        errors: condtions[i] && condtions[i].errors,
        id: condtions[i] && condtions[i].id
      });

      formGroups.push(fg);
    }

    const formArray = this.formBuilder.array(formGroups);
    form.setControl(key, formArray);
  }

  initCommandFormGroup(isService: boolean, key: string): FormGroup {
    const formControls: any = {
      id: null,
      errors: null,
      targetTypeId: null,
      action: null,
      delay: null,
      position: null,
      deviceId: null,
      deviceTypeId: null,
      serviceId: null,
      serviceTypeId: null,
      notificationTemplateId: null,
      parameters: isService ? null : this.formBuilder.array([]),
      locationId: null,
      locationPropertyTypeId: null,
      locationPropertyForceValue: null,
      locationPropertyAdjustValue: null,
      executeOnlyIfDifferentFromLocal: false
    };

    if (key === 'endCommands') {
      formControls.executeOnlyOneTimeUntilRuleWasStart = false;
    } else if (key === 'startCommands') {
      formControls.executeOnlyOneTimeUntilRuleWasEnd = false;
    }

    return this.formBuilder.group(formControls);
  }

  initCommandParameterFormGroup() {
    return this.formBuilder.group({
      id: null,
      key: [null, Validators.required],
      value: null
    });
  }

  setCommandFormArray(
    form: FormGroup,
    rule: MassRule,
    template: RuleTemplate,
    key: string
  ) {
    const tplCommands = template[key];
    const commands = rule[key];
    const formGroups = [];

    for (let i = 0; i < tplCommands.length; i++) {
      const isService = tplCommands[i].serviceTypeId ? true : false;
      const fg = this.initCommandFormGroup(isService, key);
      const itemWithoutParams = { ...tplCommands[i] };
      delete itemWithoutParams.parameters;
      itemWithoutParams.errors = commands[i] && commands[i].errors;
      itemWithoutParams.id = commands[i] && commands[i].id;
      fg.patchValue(itemWithoutParams);

      if (!isService && tplCommands[i].parameters) {
        this.setCommandParameterFormArray(fg, tplCommands[i].parameters);
      }

      formGroups.push(fg);
    }

    const formArray = this.formBuilder.array(formGroups);
    form.setControl(key, formArray);
  }

  setCommandParameterFormArray(form: FormGroup, data: RuleCommandParameter[]) {
    const parameterFormGroups = [];
    if (data.length > 0) {
      data.forEach(parameter => {
        if (parameter) {
          const paramForm = this.initCommandParameterFormGroup();
          paramForm.patchValue(parameter);
          parameterFormGroups.push(paramForm);
        }
      });
    }

    if (parameterFormGroups.length > 0) {
      form.setControl(
        'parameters',
        this.formBuilder.array(parameterFormGroups)
      );
    } else {
      form.controls['parameters'].reset();
    }
  }

  validateConditions(
    conds: RuleCondition[],
    tplConds: RuleTemplateCondition[]
  ) {
    const conditions = [];

    for (let i = 0; i < tplConds.length; i++) {
      const keys = conds[i] && Object.keys(conds[i]);
      const c = { ...conds[i], errors: <any>{} };

      if (!keys || !keys.length) {
        c.errors.empty = true;
      } else if (tplConds[i].sourceType !== c.sourceType) {
        c.errors.sourceType = true;
      } else if (
        tplConds[i].locationPropertyTypeId !== c.locationPropertyTypeId
      ) {
        c.errors.locationPropertyType = true;
      } else if (tplConds[i].deviceTypeId !== c.deviceTypeId) {
        c.errors.deviceTypeId = true;
      } else if (
        tplConds[i].datetimePropertyTypeId !== c.datetimePropertyTypeId
      ) {
        c.errors.datetimePropertyTypeId = true;
      } else if (tplConds[i].connector !== c.connector) {
        c.errors.connector = true;
      } else if (
        tplConds[i].referencedDeviceTypeId !== c.referencedDeviceTypeId
      ) {
        c.errors.referencedDeviceTypeId = true;
      } else if (
        tplConds[i].referencedLocationPropertyTypeId !==
        c.referencedLocationPropertyTypeId
      ) {
        c.errors.referencedLocationPropertyTypeId = true;
      } else if (tplConds[i].referencedType !== c.referencedType) {
        c.errors.referencedType = true;
      }

      conditions.push(c);
    }

    return conditions;
  }

  validateCommands(cmds: RuleCommand[], tplCmds: RuleTemplateCommand[]) {
    const commands = [];

    for (let i = 0; i < tplCmds.length; i++) {
      const keys = cmds[i] && Object.keys(cmds[i]);
      const c = { ...cmds[i], errors: <any>{} };

      if (!keys || !keys.length) {
        c.errors.empty = true;
      } else if (tplCmds[i].serviceTypeId !== c.serviceTypeId) {
        c.errors.serviceTypeId = true;
      } else if (tplCmds[i].targetTypeId !== c.targetTypeId) {
        c.errors.targetTypeId = true;
      } else if (tplCmds[i].action !== c.action) {
        c.errors.action = true;
      } else if (tplCmds[i].deviceTypeId !== c.deviceTypeId) {
        c.errors.deviceTypeId = true;
      } else if (
        tplCmds[i].locationPropertyTypeId !== c.locationPropertyTypeId
      ) {
        c.errors.locationPropertyTypeId = true;
      } else if (
        tplCmds[i].locationPropertyAdjustValue !== c.locationPropertyAdjustValue
      ) {
        c.errors.locationPropertyAdjustValue = true;
      } else if (
        tplCmds[i].locationPropertyForceValue !== c.locationPropertyForceValue
      ) {
        c.errors.locationPropertyForceValue = true;
      }

      commands.push(c);
    }

    return commands;
  }

  private cmdsOrCondsErrorsExist(
    data: {
      [key: string]: any;
      errors: { [key: string]: any };
    }[]
  ) {
    const e = data.filter(d => Object.keys(d.errors).length > 0);
    if (e && e.length) {
      return true;
    }
    return false;
  }

  ruleHasErrors(rule) {
    let err = false;

    if (
      rule.startConditions &&
      rule.startConditions.length &&
      this.cmdsOrCondsErrorsExist(rule.startConditions)
    ) {
      err = true;
    } else if (
      rule.startCommands &&
      rule.startCommands.length &&
      this.cmdsOrCondsErrorsExist(rule.startCommands)
    ) {
      err = true;
    } else if (
      rule.endConditions &&
      rule.endConditions.length &&
      this.cmdsOrCondsErrorsExist(rule.endConditions)
    ) {
      err = true;
    } else if (
      rule.endCommands &&
      rule.endCommands.length &&
      this.cmdsOrCondsErrorsExist(rule.endCommands)
    ) {
      err = true;
    }

    return err;
  }
}
