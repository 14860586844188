import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-behaviour-patterns-form',
  templateUrl: './behaviour-patterns-form.component.html'
})
export class BehaviourPatternsFormComponent implements OnInit, OnDestroy {
  @Input()
  form: FormGroup;
  @Input()
  formId: any;

  @Output()
  removed = new EventEmitter<any>();

  selectItems: { [key: string]: SelectItem[] } = {};
  private subscribers: { [key: string]: any } = {};

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.selectItems.reports = [
      { label: 'No', value: 'n' },
      { label: 'Info', value: 'i' },
      { label: 'Warn', value: 'w' },
      { label: 'Error', value: 'e' },
      { label: 'Crucial', value: 'c' },
      { label: 'Security alert', value: 's' }
    ];
    this.selectItems.operators = [
      { label: '==', value: '==' },
      { label: '!=', value: '!=' },
      { label: '>', value: '>' },
      { label: '<', value: '<' },
      { label: '>=', value: '>=' },
      { label: '<=', value: '<=' }
    ];
    this.selectItems.operandModifiers = [
      { label: '', value: '' },
      { label: '+', value: '+' },
      { label: '-', value: '-' },
      { label: '*', value: '*' },
      { label: '/', value: '/' }
    ];

    this.onFormValueChange();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private onFormValueChange() {
    this.form.get('compare_mode').valueChanges.subscribe(result => {
      if (typeof result === 'boolean') {
        this.form.get('compare_mode').setValue(result ? 1 : 0);
      }
    });

    this.form.get('ignore').valueChanges.subscribe(result => {
      if (typeof result === 'boolean') {
        this.form.get('ignore').setValue(result ? 1 : 0);
      }
    });
  }

  get formBackgroundColor() {
    return this.formId % 2 !== 0 ? 'grey lighten-4' : '';
  }

  get compareMode() {
    return this.form.get('compare_mode').value;
  }
}
