<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate autocomplete="itshouldnotworkforever">
    <!-- HEADER -->
    <!-- <div class="modal-header"></div> -->

    <!-- BODY -->
    <div class="modal-body profile-form__body">
      <!-- USERNAME -->
      <div class="ui-g mb-3">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "USERNAME" | translate }}
        </div>

        <div class="ui-g-12 ui-md-9">{{ formData.username }}</div>
      </div>

      <!-- EMAIL -->
      <div class="ui-g mb-3">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "EMAIL" | translate }}
        </div>

        <div class="ui-g-12 ui-md-9">{{ formData.email }}</div>
      </div>

      <!-- FULL_NAME -->
      <div class="ui-g mb-3">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "FULL_NAME" | translate }}
        </div>

        <div class="ui-g-12 ui-md-4">
          <sc-text-input
            controlName="firstname"
            [form]="form"
            label="{{ 'FIRSTNAME' | translate }}"
            [showLabel]="false"
          ></sc-text-input>
        </div>

        <div class="ui-g-12 ui-md-4">
          <sc-text-input
            controlName="lastname"
            [form]="form"
            label="{{ 'LASTNAME' | translate }}"
            [showLabel]="false"
          ></sc-text-input>
        </div>
      </div>

      <!-- PHONE -->
      <div class="ui-g mb-3">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "PHONE" | translate }}
        </div>

        <div class="ui-g-12 ui-md-5">
          <sc-text-input
            controlName="phone"
            [form]="form"
            label="{{ 'PHONE' | translate }}"
            [showLabel]="false"
          ></sc-text-input>
        </div>
      </div>

      <!-- GENDER -->
      <div class="ui-g mb-3">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "GENDER" | translate }}
        </div>

        <div class="ui-g-12 ui-md-9">
          <sc-radio-input
            controlName="gender"
            [form]="form"
            label="{{ 'GENDER' | translate }}"
            [options]="selectItems?.gender"
            [showLabel]="false"
          ></sc-radio-input>
        </div>
      </div>

      <!-- LANGUAGE -->
      <div class="ui-g mb-3">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "LANGUAGE" | translate }}
        </div>

        <div class="ui-g-12 ui-md-5">
          <sc-dropdown
            controlName="locale"
            [form]="form"
            label="{{ 'LANGUAGE' | translate }}"
            [options]="selectItems?.locales"
            [showLabel]="false"
          ></sc-dropdown>
        </div>
      </div>

      <div class="ui-g mb-3" *ngIf="isAdmin">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "BACKEND_VARIANT" | translate }}
        </div>

        <div class="ui-g-12 ui-md-5">
          <sc-dropdown
            controlName="backendVariant"
            [form]="form"
            label="{{ 'BACKEND_VARIANT' | translate }}"
            [options]="selectItems?.backendVariants"
            [showLabel]="false"
          ></sc-dropdown>
        </div>
      </div>

      <div class="ui-g mb-3">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "EMAIL_NOTIFICATION_ENABLED" | translate }}
        </div>

        <div class="ui-g-12 ui-md-5">
          <sc-checkbox-input
            controlName="isEmailNotificationEnabled"
            [form]="form"
            [label]="'YES' | translate"
          ></sc-checkbox-input>
        </div>
      </div>

      <div class="ui-g mb-3">
        <div class="ui-g-12 ui-md-3 text-right profile-form__label">
          {{ "SMS_NOTIFICATION_ENABLED" | translate }}
        </div>

        <div class="ui-g-12 ui-md-5">
          <sc-checkbox-input
            controlName="isSmsNotificationEnabled"
            [form]="form"
            [label]="'YES' | translate"
          ></sc-checkbox-input>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="modal-footer">
      <button
        pButton
        type="button"
        class="ui-button-secondary"
        (click)="dismissModal('cancel')"
        label="{{ 'CANCEL' | translate }}"
      ></button>

      <sc-submit-button
        *ngIf="!formData?.isDeleted || formData?.isActive"
        buttonClass="ui-button-success"
        [disabled]="!form.valid || form.pristine"
        [pending]="isSubmitting"
        (submitted)="submit()"
      ></sc-submit-button>
    </div>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
