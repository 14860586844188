import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { DeviceTaskTemplateActionCellComponent } from './device-task-template-action-cell/device-task-template-action-cell.component';
import { DeviceTaskTemplateFormComponent } from './device-task-template-form/device-task-template-form.component';
import { DeviceTaskTemplateTableComponent } from './device-task-template-table/device-task-template-table.component';
import { DeviceTaskTemplateParameterFormComponent } from './parameter-form/parameter-form.component';

import { DeviceTaskTemplateFormsService } from './services/device-task-template-form.service';
import { DeviceTaskTemplatesService } from './services/device-task-templates.service';

const COMPONENTS: any[] = [
  DeviceTaskTemplateActionCellComponent,
  DeviceTaskTemplateFormComponent,
  DeviceTaskTemplateTableComponent,
  DeviceTaskTemplateParameterFormComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [DeviceTaskTemplateFormsService, DeviceTaskTemplatesService]
})
export class DeviceTaskTemplatesModule {}
