<div [formGroup]="form" class="ui-g">
  <ng-container formArrayName="parameters">
    <ng-container
      *ngFor="let param of parametersForm.controls; let i=index"
      [formGroupName]="i"
    >
      <div class="ui-g-6 py-0 mb-1">
        <div *ngIf="param?.value?.key !== 'deviceId'">
          {{ param?.value?.key | translate }}
        </div>
      </div>

      <div class="ui-g-6 py-0 mb-1">
        <div [ngSwitch]="param?.value?.key">
          <div *ngSwitchCase="'deviceId'">
            {{ 'SELECTED' | translate }} {{ 'DEVICE' | translate }}
          </div>

          <div *ngSwitchDefault>
            <sc-text-input
              controlName="value"
              [form]="param"
              label="{{ 'VALUE' | translate }}"
              [showLabel]="false"
            ></sc-text-input>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
