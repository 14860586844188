import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RuleTemplateCommand } from '../models/rule-template-command';
import { RuleTemplateCondition } from '../models/rule-template-condition';

import { config } from '../../../config';

@Injectable()
export class RuleTemplateFormsService {
  patterns = config().regexPatterns;

  constructor(private formBuilder: FormBuilder) {}

  initRuleTemplateForm() {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      description: null,
      startMinExecutionTime: null,
      startMaxExecutionTime: null,
      startBlockingAfterExecutionTime: null,
      endMinExecutionTime: null,
      endMaxExecutionTime: null,
      endBlockingAfterExecutionTime: null,
      startConditions: this.formBuilder.array([]),
      startCommands: this.formBuilder.array([]),
      endCommands: this.formBuilder.array([]),
      endConditions: this.formBuilder.array([]),
      siteIds: null,
      purposeEnergySaving: false,
      startAllowRepetition: null,
      endAllowRepetition: null,
      energySavingPatternsEnd: null,
      energySavingPatternsStart: null,
      endConditionConnector: 'AND',
      startConditionConnector: 'AND',
      isPublic: false,
      isActive: false,
      isDeleted: false
    });
  }

  initCommandFormGroup(isEnd?: boolean) {
    const formControls: any = {
      id: null,
      targetTypeId: [null, Validators.required],
      position: [0, Validators.required],
      deviceTypeId: null,
      serviceTypeId: null,
      notificationTemplateId: null,
      action: null,
      parameters: null,
      delay: null,
      locationPropertyTypeId: null,
      locationPropertyForceValue: null,
      locationPropertyAdjustValue: null,
      ruleTemplateId: [null, Validators.required],
      executeOnlyIfDifferentFromLocal: false
    };
    if (isEnd === true) {
      formControls.executeOnlyOneTimeUntilRuleWasStart = false;
    } else {
      formControls.executeOnlyOneTimeUntilRuleWasEnd = false;
    }
    return this.formBuilder.group(formControls);
  }

  setCommandFormArray(
    form: FormGroup,
    data: RuleTemplateCommand[],
    isEnd?: boolean
  ) {
    const formGroups = data.map(item => {
      const fg = this.initCommandFormGroup(isEnd);
      // if (item.parameters && item.parameters.length > 0) {
      //   item.parameters = item.parameters.map((p: any) => p.key);
      // }
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    if (isEnd) {
      form.setControl('endCommands', formArray);
    } else {
      form.setControl('startCommands', formArray);
    }
  }

  initConditionFormGroup() {
    return this.formBuilder.group({
      id: null,
      position: [0, Validators.required],
      sourceType: [null, Validators.required],
      referencedType: null,
      connector: { value: null, disabled: true },
      deviceTypeId: null,
      event: null,
      locationPropertyTypeId: null,
      operator: null,
      parameters: null,
      referencedDeviceTypeId: null,
      referencedLocationPropertyTypeId: null,
      referencedValue: null,
      requiredValidityPeriod: 0,
      datetimePropertyTypeId: null,
      datetimePropertyValue: null,
      wasTrueOneTimeInPastMs: null,
      ruleTemplateId: [null, Validators.required]
    });
  }

  setConditionFormArray(
    form: FormGroup,
    data: RuleTemplateCondition[],
    isEnd?: boolean
  ) {
    const formGroups = data.map(item => {
      const fg = this.initConditionFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    if (isEnd) {
      form.setControl('endConditions', formArray);
    } else {
      form.setControl('startConditions', formArray);
    }
  }
}
