import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-circle-button',
  templateUrl: './circle-button.component.html',
  styleUrls: ['./circle-button.component.scss'],
})
export class CircleButtonComponent implements OnInit {
  @Input()
  icon: string;

  @Input()
  outlined: boolean;

  @Input()
  buttonStyle: string;

  @Input()
  className: string;

  @Output()
  clicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  getClasses() {
    let classNames = '';
    if (this.className) {
      classNames += this.className;
    }
    if (this.outlined === true) {
      classNames += ' circle-button--outlined';
    }
    return classNames;
  }
}
