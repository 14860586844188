<div
  class="rule-condition"
  [ngClass]="{
    'rule-condition--sublevel': sublevel,
    'rule-condition--last': isLastItem
  }"
>
  <div class="rule-condition-header">
    <div class="rule-condition-header__sublevel-connector" *ngIf="sublevel">
      {{ parentConnectorLabel | translate }}
    </div>

    <div class="rule-condition-header__connector">
      <!-- *ngIf="isTemplate" -->
      <button
        type="button"
        class="rule-condition-header__connector-button"
        (click)="openConnectorMenu()"
        *ngIf="isTemplate || isFromScratch"
      >
        {{ connectorLabel | translate }}
      </button>

      <span *ngIf="!isTemplate && !isFromScratch">
        {{ connectorLabel | translate }}
      </span>
    </div>

    <div class="rule-condition-header__description">
      {{ connectorDescription | translate }}
    </div>

    <!-- *ngIf="isTemplate" -->
    <div
      class="rule-condition-header__actions"
      *ngIf="isTemplate || isFromScratch"
    >
      <sc-action-button
        [menu]="actionMenu"
        (selected)="onActionSelected($event)"
      ></sc-action-button>
    </div>
  </div>

  <div class="rule-condition-body" *ngIf="conditions && conditions[connector]">
    <!-- conditions.AND || conditions.OR -->
    <ng-container
      *ngFor="let condition of conditions[connector]; let i = index"
    >
      <!-- Sub-condtion -->
      <sc-rule-condition
        *ngIf="(condition?.AND || condition?.OR) && !condition?.left"
        [conditions]="condition"
        [parentConnector]="connector"
        [sublevel]="true"
        [isLastItem]="i + 1 === conditions[connector]?.length"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [viewMode]="viewMode"
        [isFromScratch]="isFromScratch"
        [isTemplate]="isTemplate"
        (movedUp)="onConditionMovedUp(i)"
        (movedDown)="onConditionMovedDown(i)"
        (removed)="onConditionRemoved(i)"
      ></sc-rule-condition>

      <!-- Root-condition -->
      <sc-condition-item
        *ngIf="condition?.left && !condition?.AND && !condition?.OR"
        [condition]="condition"
        [connector]="connector"
        [firstItem]="i === 0"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [viewMode]="viewMode"
        [isFromScratch]="isFromScratch"
        [isTemplate]="isTemplate"
        (movedUp)="onConditionMovedUp(i)"
        (movedDown)="onConditionMovedDown(i)"
        (removed)="onConditionRemoved(i)"
      ></sc-condition-item>
    </ng-container>
  </div>
</div>
