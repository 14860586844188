import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

// COMPONENTS
import { BenchmarksOverviewTableComponent } from './overview-table/overview-table.component';
import { BenchmarkOverviewFormComponent } from './overview-form/overview-form.component';
import { BenchmarkAnalyseModalComponent } from './analyse-modal/analyse-modal.component';

const COMPONENTS: any[] = [
  BenchmarksOverviewTableComponent,
  BenchmarkOverviewFormComponent,
  BenchmarkAnalyseModalComponent
];

@NgModule({
  imports: [SharedModule],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  entryComponents: COMPONENTS
})
export class BenchmarksOverviewModule {}
