<div class="function-parameter-form" [formGroup]="form">
  <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
    <div class="p-field p-col-12 p-md-2">
      <sc-text-input
        controlName="id"
        [form]="form"
        [label]="(index === 0 ? 'ID' : '') | translate"
      ></sc-text-input>
    </div>

    <div class="p-field p-col-12 p-md-2">
      <sc-text-input
        controlName="label"
        [form]="form"
        [label]="(index === 0 ? 'LABEL' : '') | translate"
      ></sc-text-input>
    </div>

    <div class="p-field p-col-12 p-md-2">
      <sc-dropdown
        controlName="type"
        [form]="form"
        [label]="(index === 0 ? 'TYPE' : '') | translate"
        [options]="selectItems?.customFunctionParameterTypes"
      ></sc-dropdown>
    </div>

    <div class="p-field p-col-12 p-md-5" *ngIf="!isChangingType">
      <ng-container [ngSwitch]="form?.value?.type">
        <ng-container
          *ngSwitchCase="_customFunctionParameterTypes.CustomGlobalValue"
        >
          <ng-container *ngTemplateOutlet="customGlobalValue"></ng-container>
        </ng-container>

        <ng-container
          *ngSwitchCase="_customFunctionParameterTypes.LatestDhlValue"
        >
          <ng-container *ngTemplateOutlet="latestDhlValue"></ng-container>
        </ng-container>

        <ng-container
          *ngSwitchCase="_customFunctionParameterTypes.LatestLhlValue"
        >
          <ng-container *ngTemplateOutlet="latestLhlValue"></ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="p-field p-col-12 p-md-1">
      <button type="button" class="remove-button" (click)="removed.emit()">
        <fa-icon icon="times"></fa-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #customGlobalValue>
  <div class="custom-global-value" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-6">
        <sc-dropdown
          controlName="custom_global_idx"
          [form]="form"
          [label]="(index === 0 ? 'VALUE' : '') | translate"
          [options]="selectItems?.customGlobals"
        ></sc-dropdown>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #latestDhlValue>
  <div class="latest-dhl-value" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-6">
        <sc-dropdown
          controlName="device_type"
          [form]="form"
          [label]="(index === 0 ? 'VALUE' : '') | translate"
          [options]="selectItems?.deviceTypes"
        ></sc-dropdown>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #latestLhlValue>
  <div class="latest-lhl-value" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-6">
        <sc-dropdown
          controlName="property"
          [form]="form"
          [label]="(index === 0 ? 'VALUE' : '') | translate"
          [options]="selectItems?.locationProperties"
        ></sc-dropdown>
      </div>
    </div>
  </div>
</ng-template>
