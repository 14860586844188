import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LrlTableComponent } from './lrl-table/lrl-table.component';

import { LiveRequestsService } from './services/live-requests.service';

const COMPONENTS: any[] = [LrlTableComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [LiveRequestsService]
})
export class LiveRequestsModule {}
