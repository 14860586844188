import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sc-device-summary',
  templateUrl: './device-summary.component.html',
  styleUrls: ['./device-summary.component.scss'],
})
export class DeviceSummaryComponent implements OnInit {
  @Input()
  data: any[];
  @Input()
  source: any;
  @Input()
  productImage: string;

  constructor() {}

  ngOnInit() {}

  getStatusIcon(value) {
    if (value) {
      return 'fa fa-check green-text';
    }
    return 'fa fa-times red-text';
  }
}
