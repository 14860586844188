import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { QuickDateItem } from './quick-date-item';

import { SharedService } from '@shared/shared.service';

import * as moment from 'moment-timezone';

interface QuickDateResult {
  from: string;
  to: string;
  interval: string;
}

@Component({
  selector: 'sc-quick-date-select',
  templateUrl: './quick-date-select.component.html',
  styleUrls: ['./quick-date-select.component.scss'],
})
export class SCQuickDateSelectComponent implements OnInit, OnChanges {
  @Input()
  limitDate: boolean;
  @Input()
  selectInterval: boolean;
  @Input()
  showLabel: boolean;
  @Input()
  displayInVertical: boolean;
  @Input()
  quickTimeOnly: boolean;
  @Input()
  utcTime: boolean;
  @Input()
  showOnlyDate: boolean;
  @Input()
  maxDate: Date;
  @Input()
  minDate: Date;
  @Input()
  initMinDate: Date;
  @Input()
  initMaxDate: Date;
  @Input()
  initInterval: string;
  @Input()
  disabledAutoInterval: boolean;

  @Output()
  afterInit = new EventEmitter<FormGroup>();
  @Output()
  valueChange = new EventEmitter<QuickDateResult>();

  currentTime: QuickDateItem;
  format: string;
  formData: { from: string; to: string };
  dateForm: FormGroup;
  intervals: SelectItem[];
  quickDatetimes: SelectItem[];
  pickerInputTimeout: any;
  inputIds: { [key: string]: string };

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initMinDate && changes.initMinDate.currentValue) {
      this.updateDisplayValue(changes.initMinDate.currentValue, 'from');
    }
    if (changes.initMaxDate && changes.initMaxDate.currentValue) {
      this.updateDisplayValue(changes.initMaxDate.currentValue, 'to');
    }
    if (changes.initInterval && changes.initInterval.currentValue) {
      this.updateDisplayValue(changes.initInterval.currentValue, 'interval');
    }
  }

  ngOnInit() {
    this.inputIds = {
      from: 'from_' + new Date().getTime(),
      to: 'to_' + new Date().getTime(),
      interval: 'interval_' + new Date().getTime(),
      quickDatetime: 'quickDatetime_' + new Date().getTime(),
    };
    this.format = 'DD.MM.YYYYTHH:mm:ss';
    this.intervals = [
      { label: '10 seconds', value: '10s' },
      { label: 'minute', value: 'minute' },
      { label: '10 minutes', value: '10m' },
      { label: 'hour', value: 'hour' },
      { label: 'day', value: 'day' },
      { label: 'week', value: 'week' },
      { label: 'month', value: 'month' },
    ];

    this.quickDatetimes = [];
    if (!this.showOnlyDate) {
      this.quickDatetimes.push({
        label: 'Last 5 Minutes',
        value: { value: 5, unit: 'minute', interval: '10s', label: 'Last 5 Minutes' },
      });
      this.quickDatetimes.push({
        label: 'Last 10 Minutes',
        value: { value: 10, unit: 'minute', interval: 'minute', label: 'Last 10 Minutes' },
      });
      this.quickDatetimes.push({
        label: 'Last 20 Minutes',
        value: { value: 20, unit: 'minute', interval: 'minute', label: 'Last 20 Minutes' },
      });
      this.quickDatetimes.push({
        label: 'Last 30 Minutes',
        value: { value: 30, unit: 'minute', interval: 'minute', label: 'Last 30 Minutes' },
      });
      this.quickDatetimes.push({
        label: 'Last 45 Minutes',
        value: { value: 45, unit: 'minute', interval: 'minute', label: 'Last 45 Minutes' },
      });
      this.quickDatetimes.push({
        label: 'Last 1 Hour',
        value: { value: 1, unit: 'hour', interval: 'minute', label: 'Last 1 Hour' },
      });
      this.quickDatetimes.push({
        label: 'Last 3 Hours',
        value: { value: 3, unit: 'hour', interval: '10m', label: 'Last 3 Hours' },
      });
      this.quickDatetimes.push({
        label: 'Last 6 Hours',
        value: { value: 6, unit: 'hour', interval: 'hour', label: 'Last 6 Hours' },
      });
      this.quickDatetimes.push({
        label: 'Last 12 Hours',
        value: { value: 12, unit: 'hour', interval: 'hour', label: 'Last 12 Hours' },
      });
      this.quickDatetimes.push({
        label: 'Last 24 Hours',
        value: { value: 24, unit: 'hour', interval: 'hour', label: 'Last 24 Hours' },
      });
    }

    this.quickDatetimes.push({
      label: 'Today',
      value: { value: 0, unit: 'day', interval: 'hour', label: 'Today' },
    });
    this.quickDatetimes.push({
      label: 'Yesterday',
      value: { value: -1, unit: 'day', interval: 'hour', label: 'Yesterday' },
    });
    this.quickDatetimes.push({
      label: 'Last 3 Days',
      value: { value: 3, unit: 'day', interval: 'day', label: 'Last 3 Days' },
    });
    this.quickDatetimes.push({
      label: 'Last 7 Days',
      value: { value: 7, unit: 'day', interval: 'day', label: 'Last 7 Days' },
    });

    if (!this.limitDate) {
      this.quickDatetimes.push({
        label: 'Last 14 Days',
        value: { value: 14, unit: 'day', interval: 'day', label: 'Last 14 Days' },
      });
      this.quickDatetimes.push({
        label: 'Last 30 Days',
        value: { value: 30, unit: 'day', interval: 'day', label: 'Last 30 Days' },
      });
    }

    // CREATE DATE FORM
    this.dateForm = this.formBuilder.group({
      quickDatetime: null,
      from: this.initMinDate || this.minDate || null,
      to: this.initMaxDate || this.maxDate || null,
      interval: this.initInterval || null,
    });

    this.currentTime = { from: null, to: null, interval: null, label: null };

    const initFrom = this.initMinDate || this.minDate;
    const initTo = this.initMaxDate || this.maxDate;
    if (initFrom) {
      this.createResult(moment(initFrom), 'from', true);
    }
    if (initTo) {
      this.createResult(moment(initTo), 'to', true);
    }
    if (this.initInterval) {
      this.currentTime.interval = this.initInterval;
    }

    // EMIT AFTER INIT EVENT
    this.afterInit.emit(this.dateForm);
  }

  onIntervalChange() {
    this.currentTime.interval = this.dateForm.get('interval').value;
    this.valueChange.emit(this.currentTime);
  }

  onQuickDateSelect(event) {
    const time = event.value;
    let from;
    let to;

    switch (time.unit) {
      case 'minute':
      case 'hour':
        from = moment().subtract(time.value, time.unit);
        to = moment();
        break;
      case 'day':
        if (time.value === 0) {
          from = moment().hour(0).minute(0).second(0);
          to = moment().hour(23).minute(59).second(59);
        } else if (time.value === -1) {
          from = moment().subtract(1, 'day').hour(0).minute(0).second(0);
          to = moment().subtract(1, 'day').hour(23).minute(59).second(59);
        } else {
          from = moment().subtract(time.value, time.unit);
          to = moment();
        }
        break;
      default:
        break;
    }

    if (this.showOnlyDate === true) {
      from = from.startOf('day');
      to = to.endOf('day');
    }

    this.dateForm.patchValue({
      from: from.toDate(),
      to: to.toDate(),
    });

    if (this.utcTime === true) {
      this.currentTime.from = from.format(this.format);
      this.currentTime.to = to.format(this.format);
    } else {
      this.currentTime.from = from.utc().format(this.format);
      this.currentTime.to = to.utc().format(this.format);
    }
    this.currentTime.interval = time.interval;
    this.currentTime.label = time.label;

    this.dateForm.get('interval').setValue(this.currentTime.interval);
    this.valueChange.emit(this.currentTime);
  }

  onDatePickerSelect(value, inputId) {
    this.dateForm.get('quickDatetime').reset();
    const datetime: any = moment(value);
    this.createResult(datetime, inputId);
  }

  onDatePickerClear(inputId) {
    if (inputId === 'from') {
      this.currentTime.from = null;
    } else if (inputId === 'to') {
      this.currentTime.to = null;
    }

    this.currentTime.interval = null;
    this.dateForm.get('interval').setValue(this.currentTime.interval);
    this.dateForm.get('quickDatetime').reset();
    this.valueChange.emit(this.currentTime);
  }

  onDatePickerInput(event, inputId) {
    clearTimeout(this.pickerInputTimeout);
    this.pickerInputTimeout = setTimeout(() => {
      this.dateForm.get('quickDatetime').reset();
      const datetime: any = moment(event.target.value, ['DD/MM/YYYY HH:mm', 'DD/MM/YYYY']);
      this.createResult(datetime, inputId);
    }, 1000);
  }

  setPickerInterval() {
    if (this.currentTime.from && this.currentTime.to) {
      let from: any = moment(this.currentTime.from, this.format);
      let to: any = moment(this.currentTime.to, this.format);

      if (this.showOnlyDate === true) {
        from = from.startOf('day');
        to = to.endOf('day');
      }

      if (this.utcTime !== true) {
        from = from.utc();
        to = to.utc();
      }

      if (to.diff(from, 'days') <= 1) {
        this.currentTime.interval = 'hour';
      } else {
        this.currentTime.interval = 'day';
      }

      this.dateForm.get('interval').setValue(this.currentTime.interval);
    }
  }

  get displayStyle() {
    if (this.displayInVertical === true || this.quickTimeOnly === true) {
      return 'ui-g-12';
    } else if (!this.selectInterval) {
      return 'ui-g-12 ui-md-4 no-pad';
    }
    return 'ui-g-12 ui-md-6 ui-lg-3 no-pad';
  }

  private createResult(datetime, inputId, skipEventEmit = false) {
    if (this.showOnlyDate === true) {
      if (inputId === 'from') {
        datetime = datetime.startOf('day');
      } else if (inputId === 'to') {
        datetime = datetime.endOf('day');
      }
    } else {
      datetime = this.calcSiteTimeZoneOffset(datetime);
    }

    if (this.utcTime !== true) {
      datetime = datetime.utc();
    }

    datetime = datetime.format(this.format);

    if (inputId === 'from') {
      this.currentTime.from = datetime;
    } else if (inputId === 'to') {
      this.currentTime.to = datetime;
    }

    if (!this.disabledAutoInterval) {
      this.setPickerInterval();
    }

    if (!skipEventEmit) {
      this.valueChange.emit(this.currentTime);
    }
  }

  private calcSiteTimeZoneOffset(mDate) {
    const date = moment(mDate).toDate();
    const nowOffset = (date.getTimezoneOffset() / 60) * -1;
    const siteOffset = this.timezoneOffset / 60;
    const diffOffset = nowOffset - siteOffset;
    if (diffOffset > 0) {
      mDate.add(diffOffset, 'hours');
    } else if (diffOffset < 0) {
      mDate.subtract(diffOffset * -1, 'hours');
    }
    mDate.seconds(0).milliseconds(0);
    return mDate;
  }

  private get timezone() {
    return this.sharedService.selectedSite && this.sharedService.selectedSite.timezone;
  }

  private get timezoneOffset() {
    if (this.timezone) {
      return this.sharedService.getTimezoneOffset(this.timezone, true);
    }
    return 0;
  }

  private updateDisplayValue(value, key) {
    const form = this.dateForm && this.dateForm.get(key);
    if (form) {
      form.setValue(value);

      if (key === 'interval') {
        this.currentTime[key] = value;
      } else {
        this.createResult(moment(value), key, true);
      }
    }
  }
}
