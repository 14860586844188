import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-radio-input',
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
      ></sc-field-label>

      <div class="ui-g">
        <div *ngFor="let o of options" [ngClass]="displayStyle">
          <p-radioButton
            [formControlName]="controlName"
            [label]="o.label"
            [value]="o.value"
            [name]="inputId"
          ></p-radioButton>
        </div>
      </div>

      <sc-field-error
        [for]="label"
        [fieldControl]="form.controls[controlName]"
      ></sc-field-error>
    </div>
  `
})
export class SCRadioInputComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  options: SelectItem[];
  @Input()
  displayInVertical: boolean;

  inputId: string;

  constructor() {}

  ngOnInit() {
    this.showLabel =
      typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-text-input_' + this.controlName + new Date().getTime();
  }

  get displayStyle() {
    if (this.displayInVertical === true) {
      return 'ui-g-12';
    }
    return 'mr-3';
  }
}
