import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { TreeNode } from 'primeng/api';

import { FileManagerService } from '../file-manager.service';

@Component({
  selector: 'sc-file-path',
  templateUrl: './file-path.component.html',
  styleUrls: ['./file-path.component.scss']
})
export class FilePathComponent implements OnInit, OnChanges {
  @Input()
  selectedDir: TreeNode;

  @Output()
  opened = new EventEmitter<string>();

  path: string[];

  constructor(
    private fileManagerService: FileManagerService
  ) { }

  ngOnInit() {
    this.updatePath();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.selectedDir) {
      this.updatePath();
    }
  }

  updatePath() {
    if (this.selectedDir) {
      this.path = this.fileManagerService.getPath(this.selectedDir, true);
    } else {
      this.path = [];
    }
  }

  onOpened(index: number = -1) {
    let path = '';
    if (index >= 0) {
      path = [...this.path].slice(0, index + 1).join('/');
    }
    this.opened.emit(path);
  }
}
