import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Device } from '@widgets/devices/models/device';

import { config } from '@app/config';

@Injectable()
export class DeviceWizardService {
  private apiUrl = `${config().apiUrl}/_backend/deviceWizard`;

  constructor(private httpClient: HttpClient) { }

  getDevices(options?: { [prop: string]: any }) {
    const queryParams = [];
    if (options) {
      if (options.search) {
        queryParams.push(`s=${options.search}`);
      }
      if (options.deviceTypes && options.deviceTypes !== '') {
        queryParams.push(`t=${options.deviceTypes}`);
      }
      if (options.deviceProtocols && options.deviceProtocols !== '') {
        queryParams.push(`p=${options.deviceProtocols}`);
      }
    }
    return this.httpClient.get(`${this.apiUrl}?${queryParams.join('&')}`);
  }

  createDevices(devices: Device[]) {
    return this.httpClient.post(this.apiUrl, { devices });
  }

  updateDevices(devices: Device[]) {
    return this.httpClient.patch(this.apiUrl, { devices });
  }
}
