import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/auth/services/auth.service';
import { User } from '../models/user';
import { NewEmail } from '../models/new-email';
import { NewPassword } from '../models/new-password';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';
import { of } from 'rxjs';

@Injectable()
export class UsersService {
  private apiUrl = config().apiUrl + '/users';

  constructor(private httpClient: HttpClient, private sharedService: SharedService, private authService: AuthService) {}

  private verifyData(data: any): User {
    const newData = { ...data };

    delete newData.id;
    delete newData.username;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getUsersByRole(roleKey: string) {
    if (!this.authService.isAuthorized('admin')) {
      return of(false);
    }
    return this.httpClient.get(`${this.apiUrl}?role=${roleKey}`);
  }

  getUsersOf(key: 'company' | 'site' | 'all') {
    let queryParams: any = [];

    if (key === 'all') {
      if (!this.authService.isAuthorized('admin')) {
        return of(false);
      }
    } else if (key === 'company') {
      if (this.authService.isAuthorized('admin')) {
        queryParams.push(`companyId=${this.sharedService.selectedSite.companyId}`);
      } else {
        queryParams.push(`companyId=${this.sharedService.userCompanyId}`);
      }
    } else if (key === 'site') {
      if (this.authService.isAuthorized('admin')) {
        queryParams.push(`companyId=${this.sharedService.selectedSite.companyId}`);
        queryParams.push(`siteId=${this.sharedService.selectedSite.id}`);
      } else if (this.sharedService.userCompanyId) {
        queryParams.push(`companyId=${this.sharedService.userCompanyId}`);
        queryParams.push(`siteId=${this.sharedService.selectedSite.id}`);
      }
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  getUser(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  createUser(data: User) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateUser(id: number, data: User) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteUser(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isDeleted: true,
      isActive: false,
    });
  }

  setEmail(id: number, newEmail: NewEmail) {
    return this.httpClient.patch(`${this.apiUrl}/${id}/email`, newEmail);
  }

  setPassword(id: number, newPassword: NewPassword) {
    return this.httpClient.patch(`${this.apiUrl}/${id}/password`, newPassword);
  }

  verifyEmailAndUsername(value: string) {
    return this.httpClient.get(`${this.apiUrl}/verify?u=${value}`);
  }
}
