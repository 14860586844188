import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-al-time-cell',
  templateUrl: './al-time-cell.component.html'
})
export class AutomationLogsTimeCellComponent
  implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }

  get cellData() {
    if (this.cell && this.cell.data) {
      return this.cell.data;
    }
    return;
  }

  get cellValue() {
    if (this.cell && this.cell.valueFormatted) {
      return this.cell.valueFormatted;
    }
    return '';
  }

  get showLoading() {
    if (!this.cellData) {
      return true;
    }
    return false;
  }
}
