import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EndUserRole } from '../interfaces/end-user-role';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class EndUserRoleService {
  private apiUrl = config().apiUrl + '/endUserRoles';

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private sharedService: SharedService) {}

  /**
   * Delete properties that are not allowed on API
   * @param data
   */
  private verifyData(data: EndUserRole): EndUserRole {
    const newData = { ...data };
    delete newData.id;
    delete newData.idx;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    return newData;
  }

  getEndUserRole(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getEndUserRoles() {
    return this.httpClient.get(this.apiUrl);
  }

  createEndUserRole(data: EndUserRole) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateEndUserRole(id: number, data: EndUserRole) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteEndUserRole(id: number) {
    const formData = this.sharedService.createFormData({
      is_active: false,
      is_deleted: true,
    });
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }

  createEndUserRoleForm(): FormGroup {
    const form = this.formBuilder.group({
      id: { value: null, disabled: true },
      idx: { value: null, disabled: true },
      name: [null, Validators.required],
      key: [null, Validators.required],
      site_type_id: [null, Validators.required],
      is_active: false,
      is_deleted: false,
    });
    return form;
  }
}
