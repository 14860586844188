<div [formGroup]="form">
  <div class="ui-g">
    <!-- VALID_FROM FIELD -->
    <div class="ui-g-12 ui-md-6">
      <sc-datetime-input
        controlName="validFrom"
        [form]="form"
        [label]="'VALID_FROM' | translate"
      ></sc-datetime-input>
    </div>

    <!-- VALID_TO FIELD -->
    <div class="ui-g-12 ui-md-6">
      <sc-datetime-input
        controlName="validTo"
        [form]="form"
        [label]="'VALID_TO' | translate"
      ></sc-datetime-input>
    </div>

    <!-- PIN FIELD -->
    <div class="ui-g-12 ui-md-6">
      <div class="pin-wrapper">
        <div class="pin-input-wrapper">
          <sc-text-input
            controlName="pin"
            [form]="form"
            [label]="'PIN' | translate"
          ></sc-text-input>
        </div>

        <div class="pin-generate-button-wrapper">
          <button
            pButton
            type="button"
            [icon]="'fa fa-redo'"
            (click)="generatePin()"
          ></button>
        </div>
      </div>
    </div>

    <!-- LOCALE FIELD -->
    <div class="ui-g-12 ui-md-6">
      <sc-dropdown
        controlName="locale"
        [form]="form"
        [label]="'LANGUAGE' | translate"
        [options]="locales"
      ></sc-dropdown>
    </div>
  </div>

  <div class="ui-g py-4">
    <div class="ui-g-12 text-center">
      <p-checkbox
        [formControl]="accepted"
        [binary]="true"
        [label]="
          (editMode
            ? 'EDIT_GUEST_SESSION_CONFIRM_TEXT'
            : 'NEW_GUEST_SESSION_CONFIRM_TEXT'
          ) | translate
        "
      ></p-checkbox>
    </div>
  </div>

  <div class="ui-g">
    <div class="ui-g-12 text-center">
      <button
        type="button"
        class="mr-4"
        (click)="dismissModal()"
        pButton
        [label]="'CANCEL' | translate"
      ></button>

      <sc-submit-button
        buttonClass="ui-button-success"
        [disabled]="form.invalid || form?.pristine || !accepted?.value"
        [pending]="isSubmitting"
        (submitted)="submit()"
        label="SAVE"
      ></sc-submit-button>
    </div>
  </div>
</div>
