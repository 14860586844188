<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ "ABNORMAL_OCCUPANCY_ALERT" | translate }} -->
        {{ widgetName }}
      </h4>

      <div class="sc-widget-menu">
        <i
          class="fa fa-filter fa-fw sc-clickable green-text action-btn"
          (click)="menu.toggle($event)"
          pTooltip="{{ 'MORE' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
      </div>
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<p-overlayPanel
  #menu
  appendTo="body"
  styleClass="abnomal-occupancy-alert"
  [dismissable]="false"
>
  <div class="overlaypanel-header">
    <strong>{{ "FILTER" | translate }}</strong>
  </div>

  <div class="overlaypanel-body">
    <div class="ui-g" [formGroup]="form">
      <!-- TIMERANGE FIELD -->
      <sc-quick-date-select
        (valueChange)="onSelectDate($event)"
        [displayInVertical]="true"
        [showLabel]="true"
      ></sc-quick-date-select>

      <!-- DURATION_AFTER_DOOR_CLOSED FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="durationAfterDoorClosed"
          [form]="form"
          label="{{ 'DURATION_AFTER_DOOR_CLOSED' | translate }}"
          [options]="durationAfterDoorCloseds"
          [editable]="true"
        ></sc-dropdown>
      </div>
    </div>
  </div>

  <div class="overlaypanel-footer">
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="menu.hide()"
      label="{{ 'CLOSE' | translate }}"
    ></button>

    <sc-submit-button
      buttonClass="ui-button-success"
      [disabled]="isFetching"
      [pending]="isFetching"
      (submitted)="fetchDataset()"
      label="{{ 'CONFIRM' | translate }}"
    ></sc-submit-button>
  </div>
</p-overlayPanel>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body justify-start">
    <p-table
      [value]="dataset"
      [scrollable]="true"
      [scrollHeight]="config.widgetHeight - 170 + 'px'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "LOCATION" | translate }}</th>
          <th>{{ "OCCUPANCY_DELAY" | translate }}</th>
          <th>{{ "OCCUPANCY_DURATION_AFTER_DOOR_CLOSED" | translate }}</th>
          <th>{{ "OCCUPIED" | translate }}</th>
          <th>{{ "OCCUPIED" | translate }} {{ "LOGGED_AT" | translate }}</th>
          <th>{{ "DOOR_CLOSED" | translate }}</th>
          <th>{{ "DOOR_CLOSED" | translate }} {{ "LOGGED_AT" | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{ data.description }}</td>
          <td>{{ data.occupancyDelay }}</td>
          <td>{{ data.duration }}</td>
          <td>{{ data.occupied }}</td>
          <td>{{ formatDate(data.occupiedLoggedAt) }}</td>
          <td>{{ !data.doorOpen }}</td>
          <td>{{ formatDate(data.doorOpenLoggedAt) }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>
