<div [formGroup]="group" class="ui-g-12">
  <div class="ui-g-3">
    <sc-text-input
      controlName="key"
      [form]="group"
      label="{{ 'KEY' | translate }}"
    ></sc-text-input>
  </div>
  <div class="ui-g-2">
    <sc-dropdown
      controlName="type"
      [form]="group"
      label="{{ 'VARIABLE_TYPE' | translate }}"
      [options]="variableTypes"
    ></sc-dropdown>
  </div>
  <div class="ui-g-3">
    <div [ngSwitch]="dynamicInput">
      <div *ngSwitchCase="'colorInput'">
        <sc-color-input
          controlName="value"
          [form]="group"
          label="{{ 'VALUE' | translate }}"
          [outputFormat]="group.value['type']"
        ></sc-color-input>
      </div>

      <div *ngSwitchCase="'numberInput'">
        <sc-number-input
          controlName="value"
          [form]="group"
          label="{{ 'VALUE' | translate }}"
        ></sc-number-input>
      </div>

      <div *ngSwitchCase="'fileInput'">
        <sc-file-input
          controlName="value"
          [form]="group"
          label="{{ 'VALUE' | translate }}"
        ></sc-file-input>
      </div>

      <div *ngSwitchDefault>
        <sc-text-input
          controlName="value"
          [form]="group"
          label="{{ 'VALUE' | translate }}"
        ></sc-text-input>
      </div>
    </div>
  </div>

  <div class="ui-g-3">
    <sc-textarea
      controlName="description"
      [form]="group"
      label="{{ 'DESCRIPTION' | translate }}"
    ></sc-textarea>
  </div>

  <div class="ui-g-1 text-right mt-4">
    <button
      pButton
      type="button"
      class="ui-button-warning"
      icon="fa fa-trash"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'VARIABLE' | translate }}"
      appendTo="body"
      tooltipPosition="top"
      (click)="remove()"
    ></button>
  </div>
</div>
