<section *ngIf="fetchingState === 0; else loading">
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="1">
      <ng-template [ngTemplateOutlet]="step1"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="2">
      <ng-template [ngTemplateOutlet]="step2"></ng-template>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-template [ngTemplateOutlet]="step1"></ng-template>
    </ng-container>
  </ng-container>
</section>
<section class="footer">
  <div class="footer-left">
    <button
      *ngIf="step === 1 || !isGateway"
      type="button"
      pButton
      class="ui-button-secondary"
      (click)="cancel()"
      [label]="'CANCEL' | translate"
    ></button>
    <button
      *ngIf="step > 1"
      type="button"
      pButton
      class="ui-button-secondary"
      (click)="onBackClick()"
      [label]="'BACK' | translate"
    ></button>
  </div>
  <div class="footer-right">
    <button
      *ngIf="step !== totalSteps && totalSteps > 1"
      type="button"
      pButton
      (click)="onNextClick()"
      [label]="'NEXT' | translate"
      [disabled]="!allowNext"
      class="ui-button-secondary"
    ></button>
    <sc-submit-button
      *ngIf="step === totalSteps"
      buttonClass="ui-button-success"
      (submitted)="submit()"
      [disabled]="form.invalid"
      [label]="'SAVE' | translate"
    ></sc-submit-button>
  </div>
</section>

<ng-template #loading>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #step1>
  <div class="layout" [formGroup]="form">
    <div>
      <div class="sc-form-label">
        <label>{{ "MODEL" | translate }}</label>
        <span class="red-text"> *</span>
      </div>
      <p-dropdown
        formControlName="deviceModelId"
        [options]="selectedItems?.deviceModels"
        placeholder="{{ 'SELECT' | translate }}"
        (onChange)="onDeviceModelSelect($event.value)"
        filter="true"
        [style]="{ width: '100%' }"
        appendTo="body"
      ></p-dropdown>
    </div>
    <sc-text-input
      *ngIf="!isGateway"
      controlName="deviceName"
      label="{{ 'NAME' | translate }}"
      [form]="form"
    ></sc-text-input>
    <sc-tree-multi-select
      controlName="locations"
      [form]="form"
      label="{{ 'LOCATIONS' | translate }}"
      [options]="selectedItems?.locations"
    ></sc-tree-multi-select>
    <div style="display: flex; gap: 8px">
      <label class="sc-form-label">{{ "EXPECTED_ONLINE" | translate }}</label>
      <sc-checkbox-input
        controlName="expectedOnline"
        [form]="form"
        [showLabel]="false"
      ></sc-checkbox-input>
    </div>
    <sc-number-input
      controlName="maxSeenMinutesAgo"
      [form]="form"
      label="{{ 'MAX_SEEN_MINUTES_AGO' | translate }}"
      [min]="1"
    ></sc-number-input>
    <div>
      <label class="sc-form-label">{{ "LINKING" | translate }}</label>
      <sc-checkbox-input
        controlName="isLinkingAll"
        [form]="form"
        [label]="'LINK_ALL_DEVICES' | translate"
      ></sc-checkbox-input>
      <div style="padding-left: 24px">
        <sc-checkbox-input
          controlName="isIncludingAll"
          [form]="form"
          [label]="'INCLUDE_ALL_DEVICES' | translate"
        ></sc-checkbox-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #step2>
  <div class="layout" [formGroup]="form" *ngIf="form">
    <table *ngIf="gatewaysForm" class="table">
      <thead class="table__head">
        <tr>
          <th style="width: 200px">{{ "NAME" | translate }}</th>
          <th style="width: 100px">{{ "SERIAL_NUMBER" | translate }}</th>
          <th style="width: 100px">{{ "IP" | translate }}</th>
          <th style="width: 100px">{{ "PORT" | translate }}</th>
          <th style="width: 100px">{{ "REST_PORT" | translate }}</th>
        </tr>
      </thead>

      <tbody formArrayName="gatewaySettings" class="table__body">
        <tr *ngFor="let ctrl of gatewaysForm.controls">
          <td>
            <sc-text-input
              controlName="gatewayName"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-text-input>
          </td>

          <td>
            <sc-text-input
              controlName="serialNumber"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-text-input>
          </td>

          <td>
            <sc-text-input
              controlName="ip"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-text-input>
          </td>

          <td>
            <sc-text-input
              controlName="port"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-text-input>
          </td>

          <td>
            <sc-text-input
              controlName="restPort"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-text-input>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
