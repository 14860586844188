export enum DeviceContextMenu {
  Assign = 'a',
  Delete = 'del',
  Details = 'd',
  Move = 'm',
  ShowManagedDevices = 'smd',
  Unassign = 'u',
}

export enum LocationContextMenu {
  AddPoint = 'ap',
  ApplyRule = 'ar',
  Assign = 'a',
  Delete = 'del',
  Details = 'd',
  // EditSummary = 'es',
  MoveAndResize = 'm',
  RemovePoint = 'rp',
  Reshape = 'r',
  Unassign = 'u',
}
