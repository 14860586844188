import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalConfig } from '@sc/modal/modal-config';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-most-sold-rooms',
  templateUrl: './most-sold-rooms.component.html',
})
export class MostSoldRoomsComponent implements OnInit, OnDestroy {
  @Input() config: any;

  dataset: { [key: string]: any }[];
  isFetching: boolean;
  isFirstTime: boolean;
  widgetData: { [key: string]: any }[];
  modalConfig: ModalConfig;
  widgetName: string = '';

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.getSelectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFetching = false;
        this.isFirstTime = true;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private setWidgetData() {
    const data: any = this.dataset;

    this.widgetData = [
      {
        label: 'LAST_WEEK',
        location: '',
        occupied: '',
      },
      {
        label: 'THIS_MONTH',
        location: '',
        occupied: '',
      },
      {
        label: 'LAST_MONTH',
        location: '',
        occupied: '',
      },
      {
        label: 'LAST_YEAR',
        location: '',
        occupied: '',
      },
    ];

    if (data.lastWeek && data.lastWeek.length) {
      this.widgetData[0].location = data.lastWeek[0].location;
      this.widgetData[0].occupied = data.lastWeek[0].occupied;
    }
    if (data.thisMonth && data.thisMonth.length) {
      this.widgetData[1].location = data.thisMonth[0].location;
      this.widgetData[1].occupied = data.thisMonth[0].occupied;
    }
    if (data.lastMonth && data.lastMonth.length) {
      this.widgetData[2].location = data.lastMonth[0].location;
      this.widgetData[2].occupied = data.lastMonth[0].occupied;
    }
    if (data.lastYear && data.lastYear.length) {
      this.widgetData[3].location = data.lastYear[0].location;
      this.widgetData[3].occupied = data.lastMonth[0].occupied;
    }
  }

  private setModalConfig() {
    this.modalConfig = {
      name: 'MostSoldRoomsModal',
      data: this.dataset,
      options: {
        modalTitle: 'MOST_SOLD_ROOMS_MODAL_TITLE',
      },
    };
  }

  private fetchDataset() {
    if (this.isFetching || !this.selectedSite || !this.selectedSite.id) {
      return;
    }

    this.isFetching = true;
    this.subscribers.fetchDataset = this.widgetService.getMostSoldRooms(this.selectedSite.id).subscribe(
      (result: any) => {
        this.dataset = result.data;
        this.setWidgetData();
        this.setModalConfig();
        this.isFetching = false;
        this.isFirstTime = false;
      },
      (error: any) => {
        this.isFetching = false;
      }
    );
  }
}
