<div class="device-details-modal">
  <ul class="menu" *ngIf="holderDevice?.modelName === 'Omnis'">
    <li
      class="menu-item"
      (click)="currentMenu = 'info'"
      [ngClass]="{ active: currentMenu === 'info' }"
    >
      {{ "INFORMATION" | translate }}
    </li>

    <li
      class="menu-item"
      (click)="currentMenu = 'omnisEditor'"
      [ngClass]="{ active: currentMenu === 'omnisEditor' }"
    >
      {{ "EDITOR" | translate }}
    </li>
  </ul>

  <ng-container *ngIf="currentMenu === 'info'; then info"></ng-container>
  <ng-container
    *ngIf="currentMenu === 'omnisEditor'; then omnisEditor"
  ></ng-container>
</div>

<ng-template #info>
  <div class="ui-g device-details-info">
    <div class="ui-g-12 ui-md-6">
      <sc-vdh-info
        [holderDevice]="holderDevice"
        [devices]="devices"
        [deviceModelEntities]="deviceModelEntities"
      ></sc-vdh-info>
    </div>

    <div class="ui-g-12 ui-md-6">
      <sc-vds-table
        [virtualDevices]="virtualDevices"
        [devices]="devices"
        [deviceTypeEntities]="deviceTypeEntities"
      ></sc-vds-table>
    </div>
  </div>

  <div class="ui-g">
    <div class="ui-g-12 ui-md-6">
      <sc-vd-logs
        [virtualDevices]="virtualDevices"
        [deviceTypeEntities]="deviceTypeEntities"
      ></sc-vd-logs>
    </div>
  </div>
</ng-template>

<ng-template #omnisEditor>
  <sc-omnis-editor
    [device]="holderDevice"
    (saveClicked)="onEditorSave($event)"
  ></sc-omnis-editor>
</ng-template>
