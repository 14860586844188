import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActionMenuItem, RuleCommand, SelectItems, DataSources, RuleTemplateCommand } from '../../models';
import { UtilsService } from '../../services';
import { CommandModalComponent } from '../command-modal/command-modal.component';

@Component({
  selector: 'sc-command-item',
  templateUrl: './command-item.component.html',
  styleUrls: ['./command-item.component.scss'],
})
export class CommandItemComponent implements OnInit {
  actionMenu: ActionMenuItem[];
  commandLabel: string;

  @Input()
  command: RuleCommand;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  viewMode: string;

  @Input()
  isTemplate: boolean;

  @Input()
  isFromScratch: boolean;

  @Input()
  templateCommands: RuleTemplateCommand[];

  @Output()
  movedUp = new EventEmitter<any>();

  @Output()
  movedDown = new EventEmitter<any>();

  @Output()
  removed = new EventEmitter<any>();

  constructor(private changeDetectorRef: ChangeDetectorRef, private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.actionMenu = [
      { label: 'CCP_COMMON_MOVE_UP', value: 'move_up' },
      { label: 'CCP_COMMON_MOVE_DOWN', value: 'move_down' },
      { label: 'CCP_COMMON_REMOVE', value: 'remove' },
    ];

    this.setCommandLabel();
  }

  onActionSelected(action) {
    if (typeof action === 'undefined') {
      return;
    }

    switch (action) {
      case 'move_up':
        return this.movedUp.emit();
      case 'move_down':
        return this.movedDown.emit();
      case 'remove':
        return this.removed.emit();
    }
  }

  onCommandClick() {
    const modalRef = this.utilsService.modal(CommandModalComponent, {
      command: this.command,
      dataSources: this.dataSources,
      selectItems: this.selectItems,
      viewMode: this.viewMode,
      isTemplate: this.isTemplate,
      isFromScratch: this.isFromScratch,
      templateCommands: this.templateCommands,
    });
    modalRef.onClose.subscribe((result) => {
      if (result) {
        if (result.action === 'reset') {
          // this.command = { type: null };
          for (const key in this.command) {
            if (key !== 'type') {
              delete this.command[key];
            }
          }
          this.command.type = null;
        } else if (result.action === 'save' && result.data) {
          this.command = Object.assign(this.command, result.data);
        }
      }
      this.setCommandLabel();
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCommandLabel() {
    this.commandLabel = '';

    if (!this.command || !this.command.type) {
      return;
    }

    let label = this.command.type;
    switch (this.command.type) {
      case 'trigger_device':
        const device = this.dataSources.devices.find((item) => item.idx === this.command.target);
        const parentDevice = this.dataSources.devices.find((item) => item.idx === device?.parent_idx);
        const location = this.dataSources.locations.find((item) => item.idx === device?.location_idx);
        const n1Location = this.dataSources.locations.find((item) => item.idx === location?.parent_idx);
        const n2Location = this.dataSources.locations.find((item) => item.idx === n1Location?.parent_idx);
        const additionalInfo = `${n2Location ? `${n2Location.name} / ` : ''}${
          n1Location ? `${n1Location.name} / ` : ''
        }${location ? `${location.name} / ` : ''}${parentDevice ? `${parentDevice?.name} / ` : ''}${device?.name}`;

        label =
          'Trigger device <i>(' +
          this.command.target_type +
          ' = ' +
          this.command.value +
          ')</i><br><br>' +
          additionalInfo;
        break;
      case 'report':
        label = 'Send report';
        break;
      case 'user_task':
        label = 'Create task';
        break;
      case 'device_value_storage':
        label = 'Store value';
        if (this.command.action == 'restore') {
          label = 'Restore value';
        }
        break;
    }

    this.commandLabel = label;
    /*
    let count = 0;
    for (const key in this.command) {
      count++;
      if (typeof this.command[key] !== 'undefined') {
        if (key === 'modifiers') {
          this.commandLabel += `${key}: ${JSON.stringify(this.command[key])}`;
        } else {
          this.commandLabel += `${key}: ${this.command[key]}`;
        }
        if (count !== Object.keys(this.command).length) {
          this.commandLabel += ', ';
        }
      }
    }
    */
  }
}
