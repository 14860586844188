<div class="integrator-modal device-form">
  <div class="integrator-modal__header">
    <div class="integrator-modal__header__left">
      <button
        pButton
        type="button"
        class="ui-button-clear-text"
        [icon]="'fa fa-times'"
        (click)="dismissModal()"
      ></button>
    </div>
    <div class="integrator-modal__header__content" *ngIf="!editMode">
      <ng-container
        *ngIf="selectedDeviceType !== 'a' && selectedDeviceType !== 'd'"
      >
        {{ "DEVICE_FORM_SELECT_TYPE_TEXT" | translate }}
      </ng-container>

      <ng-container *ngIf="selectedDeviceType === 'a'">
        {{ "NEW_AUTOMATION" | translate }}
      </ng-container>

      <ng-container *ngIf="selectedDeviceType === 'd'">
        {{ "NEW_DEVICE" | translate }}
        &nbsp; {{ deviceFormStep }}/{{ deviceFormTotalStep }} &nbsp;
        <span *ngIf="deviceFormStep === 2">
          {{ "PROPERTIES" | translate }}
        </span>
        <span *ngIf="deviceFormStep === 3">
          {{ "CONFIRMATION" | translate }}
        </span>
      </ng-container>
    </div>

    <div class="integrator-modal__header__content" *ngIf="editMode">
      {{ device?.description }}
      <span *ngIf="isUUIDchanged" class="device-form__alert">{{
        "DEVICE_FORM_ALERT" | translate
      }}</span>
      <!-- <p-message severity="warn" text="Are you sure?"></p-message> -->
    </div>
  </div>

  <div
    class="integrator-modal__body"
    *ngIf="fetchingState === 0; else loadingIndicator"
  >
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="1">
        <ng-template [ngTemplateOutlet]="step1"></ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <ng-template [ngTemplateOutlet]="step2"></ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div class="ui-g" *ngIf="errorMessage">
    <div class="ui-g-12">
      <sc-form-error [message]="errorMessage | translate"></sc-form-error>
    </div>
  </div>

  <div class="integrator-modal__footer" *ngIf="fetchingState === 0">
    <div class="integrator-modal__footer__right">
      <sc-confirm-trigger
        *ngIf="editMode"
        (onAccept)="delete()"
        [buttonLabel]="'DELETE' | translate"
        [buttonClass]="'ui-button-secondary'"
      ></sc-confirm-trigger>
    </div>

    <div class="integrator-modal__footer__left">
      <button
        *ngIf="selectedDeviceType === 'd' && deviceFormStep > 1"
        type="button"
        pButton
        (click)="onBackClick()"
        [label]="'BACK' | translate"
        [disabled]="isSubmitting"
        class="ui-button-secondary"
      ></button>

      <button
        *ngIf="
          selectedDeviceType === 'd' && deviceFormStep < deviceFormTotalStep
        "
        type="button"
        pButton
        (click)="onNextClick()"
        [label]="'NEXT' | translate"
        [disabled]="!allowNext"
        class="ui-button-secondary"
      ></button>

      <sc-submit-button
        *ngIf="
          selectedDeviceType === 'd' && deviceFormStep === deviceFormTotalStep
        "
        buttonClass="ui-button-success"
        [disabled]="form?.invalid"
        [pending]="isSubmitting"
        (submitted)="submit()"
        [label]="'SAVE' | translate"
      ></sc-submit-button>

      <sc-submit-button
        *ngIf="selectedDeviceType === 'a' && step === 2"
        buttonClass="ui-button-success"
        [disabled]="form?.invalid"
        [pending]="isSubmitting"
        (submitted)="submit()"
        [label]="'SAVE' | translate"
      ></sc-submit-button>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #step1>
  <div class="px-3">
    <p class="step1-description">
      {{ "DEVICE_FORM_STEP_1_TEXT" | translate }}
    </p>

    <ul class="type-selector">
      <li
        class="type-selector__item"
        (click)="onSelectDeviceType(_DeviceType.Automation)"
      >
        {{ "AUTOMATION" | translate }}
      </li>

      <li
        class="type-selector__item"
        (click)="onSelectDeviceType(_DeviceType.Device)"
      >
        {{ "CONNECTED_DEVICE" | translate }}
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #step2>
  <div [ngSwitch]="selectedDeviceType">
    <div *ngSwitchCase="'a'">
      <ng-template [ngTemplateOutlet]="automationForm"></ng-template>
    </div>
    <div *ngSwitchCase="'d'">
      <ng-template [ngTemplateOutlet]="deviceForm"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #automationForm>
  <div
    class="integrator-modal__body__content integrator-modal__body__content--step1"
    [formGroup]="form"
    *ngIf="form"
  >
    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "FLOOR" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-dropdown
          controlName="floorNumber"
          [form]="form"
          label="{{ 'FLOOR' | translate }}"
          [showLabel]="false"
          [options]="selectItems?.floors"
          [showClear]="false"
        ></sc-dropdown>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "LOCATION" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-tree-select
          field="locationIdx"
          [form]="form"
          [label]="'LOCATION' | translate"
          [showLabel]="false"
          [options]="selectItems?.floorLocations"
        ></sc-tree-select>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "MODEL" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-dropdown
          controlName="deviceModelId"
          [form]="form"
          label="{{ 'MODEL' | translate }}"
          [showLabel]="false"
          [options]="selectItems?.deviceModels"
          [showClear]="false"
        ></sc-dropdown>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "NAME" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
          [showLabel]="false"
        ></sc-text-input>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "EXPECTED_ONLINE" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-checkbox-input
          controlName="expectedOnline"
          [form]="form"
          [showLabel]="false"
        ></sc-checkbox-input>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">
        {{ "MAX_SEEN_MINUTES_AGO" | translate }}:
      </div>
      <div class="ui-g-12 ui-md-8">
        <sc-number-input
          controlName="maxSeenMinutesAgo"
          [form]="form"
          label="{{ 'NAME' | translate }}"
          [showLabel]="false"
        ></sc-number-input>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "MODBUS_ACTIVE" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-checkbox-input
          controlName="modbusActive"
          [form]="form"
          [showLabel]="false"
        ></sc-checkbox-input>
      </div>
    </div>

    <div *ngIf="form.value.modbusActive" style="width: 90%; margin-left: 5%">
      <strong>{{ "MODBUS_SETTINGS" | translate }}</strong>
      <hr />
      <div class="ui-g">
        <div class="ui-g-12 ui-md-4">{{ "MODBUS_BUSES" | translate }}</div>
        <div class="ui-g-12 ui-md-8">
          <div [formGroup]="form">
            <div formGroupName="modbusSettings">
              <div formArrayName="buses">
                <div formGroupName="0">
                  <div class="ui-g">
                    <div
                      class="ui-g-12 ui-md-12"
                      style="
                        display: flex;
                        align-items: center;
                        column-gap: 8px;
                        flex-direction: row-reverse;
                      "
                    >
                      <p-inputSwitch formControlName="isActive"></p-inputSwitch>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "MODBUS_METHOD" | translate }}<br />
                      <p-dropdown
                        [options]="modbusMethods"
                        formControlName="method"
                      ></p-dropdown>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "PORT" | translate }}:<br />
                      <input pInputText type="text" formControlName="port" />
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "MODBUS_BAUDRATE" | translate }}<br />
                      <p-dropdown
                        [options]="modbusBaudrates"
                        formControlName="baudrate"
                      ></p-dropdown>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "MODBUS_PARITY" | translate }}<br />
                      <p-dropdown
                        [options]="modbusParities"
                        formControlName="parity"
                      ></p-dropdown>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "MODBUS_BYTESIZE" | translate }}<br />
                      <input
                        pInputText
                        type="number"
                        formControlName="bytesize"
                        min="7"
                        max="8"
                        maxlength="1"
                      />
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "MODBUS_STOPBITS" | translate }}<br />
                      <input
                        pInputText
                        type="number"
                        formControlName="stopbits"
                        min="0"
                        max="2"
                        maxlength="1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "OPERATING_MODE" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-radio-input
          controlName="operatingMode"
          [form]="form"
          [label]="'AUTOMATION' | translate"
          [options]="operatingModeOptions"
          [displayInVertical]="true"
          [showLabel]="false"
        ></sc-radio-input>
      </div>
    </div>

    <div
      *ngIf="form.value.operatingMode == 'gateway'"
      style="width: 90%; margin-left: 5%"
    >
      <strong>{{ "GATEWAY_SETTINGS" | translate }}</strong>
      <hr />
      <div class="ui-g">
        <div class="ui-g-12 ui-md-4">{{ "COOL_AUTOMATION" | translate }}</div>
        <div class="ui-g-12 ui-md-8">
          <div [formGroup]="form">
            <div formGroupName="gatewaySettings">
              <div formArrayName="coolautomation">
                <div formGroupName="0">
                  <div class="ui-g">
                    <div class="ui-g-12 ui-md-6">
                      {{ "SERIAL_NUMBER" | translate }}:<br />
                      <input
                        pInputText
                        type="text"
                        formControlName="serialNumber"
                      />
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "IP" | translate }}:<br />
                      <input pInputText type="text" formControlName="ip" />
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "PORT" | translate }}:<br />
                      <input pInputText type="text" formControlName="port" />
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      {{ "REST_PORT" | translate }}:<br />
                      <input
                        pInputText
                        type="text"
                        formControlName="restPort"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</ng-template>

<ng-template #deviceForm>
  <ng-container [ngSwitch]="deviceFormStep">
    <ng-container *ngSwitchCase="1">
      <ng-template [ngTemplateOutlet]="deviceFormStep1"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="2">
      <ng-template [ngTemplateOutlet]="deviceFormStep2"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="3">
      <ng-template [ngTemplateOutlet]="deviceFormStep3"></ng-template>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #deviceFormStep1>
  <div
    class="integrator-modal__body__content integrator-modal__body__content--step1"
    [formGroup]="form"
    *ngIf="form"
  >
    <div class="ui-g" *ngIf="editMode">
      <div class="ui-g-12 ui-md-4">{{ "NAME" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
          [showLabel]="false"
        ></sc-text-input>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "FLOOR" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-dropdown
          controlName="floorNumber"
          [form]="form"
          label="{{ 'FLOOR' | translate }}"
          [showLabel]="false"
          [options]="selectItems?.floors"
          [showClear]="false"
        ></sc-dropdown>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "LOCATION" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-tree-select
          field="locationIdx"
          [form]="form"
          [label]="'LOCATION' | translate"
          [showLabel]="false"
          [options]="selectItems?.floorLocations"
        ></sc-tree-select>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "AUTOMATION" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-dropdown
          controlName="automationId"
          [form]="form"
          label="{{ 'AUTOMATION' | translate }}"
          [showLabel]="false"
          [options]="selectItems?.automations"
          [showClear]="false"
          (actionChange)="onSelectAutomation()"
        ></sc-dropdown>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">{{ "MODEL" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-dropdown
          controlName="deviceModelId"
          [form]="form"
          label="{{ 'MODEL' | translate }}"
          [showLabel]="false"
          [options]="selectItems?.deviceModels"
          [showClear]="false"
          (actionChange)="onSelectDeviceModel()"
        ></sc-dropdown>
      </div>
    </div>

    <div class="ui-g" *ngIf="editMode || isModbus">
      <div class="ui-g-12 ui-md-4">{{ nodeIdLabel }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-text-input
          controlName="uuid"
          [form]="form"
          label="nodeIdLabel"
          [showLabel]="false"
        ></sc-text-input>
      </div>
    </div>

    <div class="ui-g" *ngIf="!editMode">
      <div class="ui-g-12 ui-md-4">{{ "NAME" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
          [showLabel]="false"
        ></sc-text-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deviceFormStep2>
  <div
    class="integrator-modal__body__content integrator-modal__body__content--step2"
    [formGroup]="form"
    *ngIf="form"
  >
    <table *ngIf="propertiesForm" class="table">
      <thead class="table__head">
        <tr>
          <th style="width: 200px">{{ "PROPERTY" | translate }}</th>
          <th style="width: 200px">{{ "NAME" | translate }}</th>
          <th style="width: 100px">{{ "ENABLED" | translate }}</th>
          <th style="width: 100px">{{ "HIDDEN" | translate }}</th>
          <th style="width: 150px">
            {{ "EXCLUDE_FROM_LOCATION_PROPERTY" | translate }}
          </th>
          <th style="width: 150px">
            {{ "MIN_DIFFERENCE_THRESHOLD" | translate }}
          </th>
        </tr>
      </thead>

      <tbody formArrayName="properties" class="table__body">
        <tr *ngFor="let ctrl of propertiesForm.controls">
          <td>
            <span *ngIf="!siblings[ctrl?.value?.deviceTypeId]">
              {{ getPropertyName(ctrl?.value?.deviceTypeId) }}
            </span>

            <div *ngIf="siblings[ctrl?.value?.deviceTypeId]">
              <sc-dropdown
                controlName="deviceTypeId"
                [form]="ctrl"
                [showLabel]="false"
                [options]="siblings[ctrl?.value?.deviceTypeId]"
                [showClear]="false"
              ></sc-dropdown>
            </div>
          </td>

          <td>
            <sc-text-input
              controlName="description"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-text-input>
          </td>

          <td class="text-center">
            <sc-checkbox-input
              controlName="isActive"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-checkbox-input>
          </td>

          <td class="text-center">
            <sc-checkbox-input
              controlName="isHidden"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-checkbox-input>
          </td>

          <td class="text-center">
            <sc-checkbox-input
              controlName="excludeFromLHL"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-checkbox-input>
          </td>

          <td>
            <sc-text-input
              controlName="diffDetectionFactor"
              [form]="ctrl"
              [showLabel]="false"
            ></sc-text-input>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #deviceFormStep3>
  <div
    class="integrator-modal__body__content integrator-modal__body__content--step3"
  >
    <div class="ui-g" *ngIf="editMode">
      <div class="ui-g-12 ui-md-4">{{ "NAME" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        {{ summary?.name }}
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">
        {{ "FLOOR" | translate }}
      </div>
      <div class="ui-g-12 ui-md-8">
        {{ summary?.floor }}
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">
        {{ "LOCATION" | translate }}
      </div>
      <div class="ui-g-12 ui-md-8">
        {{ summary?.location }}
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">
        {{ "AUTOMATION" | translate }}
      </div>
      <div class="ui-g-12 ui-md-8">
        {{ summary?.automation }}
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">
        {{ "MODEL" | translate }}
      </div>
      <div class="ui-g-12 ui-md-8">
        {{ summary?.model }}
      </div>
    </div>

    <div class="ui-g" *ngIf="!editMode">
      <div class="ui-g-12 ui-md-4">{{ "NAME" | translate }}:</div>
      <div class="ui-g-12 ui-md-8">
        {{ summary?.name }}
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12">
        <table *ngIf="properties" class="table">
          <thead class="table__head">
            <tr>
              <th style="width: 200px">{{ "PROPERTY" | translate }}:</th>
              <th style="width: 200px">{{ "NAME" | translate }}</th>
              <th style="width: 100px">{{ "ENABLED" | translate }}:</th>
              <th style="width: 100px">{{ "HIDDEN" | translate }}:</th>
              <th style="width: 150px">
                {{ "EXCLUDE_FROM_LOCATION_PROPERTY" | translate }}:
              </th>
              <th style="width: 150px">
                {{ "MIN_DIFFERENCE_THRESHOLD" | translate }}:
              </th>
            </tr>
          </thead>

          <tbody class="table__body">
            <tr *ngFor="let prop of properties">
              <td>{{ getPropertyName(prop.deviceTypeId) }}</td>

              <td>{{ prop.description }}</td>

              <td class="text-center">
                <span *ngIf="prop.isActive">
                  <i class="fa fa-check green-text"></i>
                </span>
                <span *ngIf="!prop.isActive">
                  <i class="fa fa-times red-text"></i>
                </span>
              </td>

              <td class="text-center">
                <span *ngIf="prop.isHidden">
                  <i class="fa fa-check green-text"></i>
                </span>
                <span *ngIf="!prop.isHidden">
                  <i class="fa fa-times red-text"></i>
                </span>
              </td>

              <td class="text-center">
                <span *ngIf="prop.excludeFromLHL">
                  <i class="fa fa-check green-text"></i>
                </span>
                <span *ngIf="!prop.excludeFromLHL">
                  <i class="fa fa-times red-text"></i>
                </span>
              </td>

              <td class="text-center">
                <span *ngIf="prop.diffDetectionFactor">
                  {{ prop.diffDetectionFactor }}
                </span>
                <span *ngIf="!prop.diffDetectionFactor">
                  <i class="fa fa-minus grey-text"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>
