<ng-container *ngFor="let conf of summaryConfig">
  <sc-location-summary-item
    *ngIf="conf.visible"
    [itemClass]="getBadgeStyle(conf.key)"
    [tooltip]="setTooltip(conf)"
    [icon]="getIconByKey(conf.key)"
    [isBoolean]="isBooleanData(conf.key)"
    [value]="getValueByKey(conf.key)"
    [prefix]="getPrefixByKey(conf.key)"
    [suffix]="getValueUnitByKey(conf.key)"
  ></sc-location-summary-item>
</ng-container>
