<div [formGroup]="form" class="ui-g">
  <!-- SECTION HEADER -->
  <div class="ui-g-10 ui-g-nopad">
    <h2 class="m-0">{{ "SECTIONS" | translate }}</h2>
  </div>

  <!-- ADD SECTION BUTTON -->
  <div class="ui-g-2 ui-g-nopad text-right">
    <button
      pButton
      type="button"
      class="ui-button-success"
      icon="fa fa-plus"
      (click)="add()"
      pTooltip="{{ 'ADD' | translate }} {{ 'SECTION' | translate }}"
      tooltipPosition="top"
      appendTo="body"
    ></button>
  </div>

  <!-- SECTION SELECTOR -->
  <div class="ui-g-12 ui-g-nopad">
    <button
      *ngFor="let ctrl of sectionsForm.controls; let i = index"
      pButton
      type="button"
      class="ui-button-secondary"
      [label]="ctrl.value?.name"
      (click)="selectSection(i)"
      [disabled]="currentSection === i"
    ></button>
  </div>

  <div class="ui-g-12 ui-g-nopad">
    <ng-container formArrayName="sections" *ngIf="isSectionFormReady">
      <ng-container [formGroupName]="currentSection">
        <ng-container [formGroup]="sectionsForm.controls[currentSection]">
          <div class="ui-g">
            <div class="ui-g-5">
              <sc-text-input
                controlName="sectionId"
                [form]="sectionsForm.controls[currentSection]"
                label="{{ 'ID' | translate }}"
              ></sc-text-input>
            </div>

            <div class="ui-g-5">
              <sc-text-input
                controlName="name"
                [form]="sectionsForm.controls[currentSection]"
                label="{{ 'NAME' | translate }}"
              ></sc-text-input>
            </div>
          </div>

          <div class="ui-g">
            <div class="ui-g-10">
              <sc-dropdown
                controlName="selectedWidgets"
                [form]="sectionsForm.controls[currentSection]"
                label="{{ 'WIDGETS' | translate }}"
                [options]="optionData?.widgets"
                [multiSelect]="true"
                [showToggleAll]="false"
              ></sc-dropdown>
            </div>

            <div class="ui-g-2 mt-1">
              <button
                pButton
                type="button"
                class="ui-button-warning mt-3"
                icon="fa fa-paint-brush"
                (click)="clearWidgets()"
                pTooltip="{{ 'CLEAR' | translate }} {{ 'WIDGETS' | translate }}"
                tooltipPosition="top"
                appendTo="body"
              ></button>

              <button
                pButton
                type="button"
                class="ui-button-danger mt-3 ml-1"
                icon="fa fa-trash"
                (click)="remove()"
                pTooltip="{{ 'REMOVE' | translate }} {{
                  'SECTION' | translate
                }}"
                tooltipPosition="top"
                appendTo="body"
              ></button>
            </div>
          </div>

          <div class="widgets-container">
            <gridster [options]="gridConfig">
              <gridster-item
                *ngFor="let widget of sectionWidgets"
                [item]="widget"
              >
                <div>{{ getWidgetName(widget) }}</div>
              </gridster-item>
            </gridster>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
