import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { Site } from '@widgets/sites/models/site';

import { DevicesService } from '@widgets/devices/services/devices.service';
import { DeviceHistoryLogsService } from '@widgets/devices/services/device-history-logs.service';
import { SCTableService } from '@sc/table/table.service';
import { SharedService } from '@shared/shared.service';

// interface HistoryLog extends DeviceDetails {
//   icon?: string;
//   label?: string;
//   unit?: string;
//   value?: any;
// }

@Component({
  selector: 'sc-vd-logs',
  templateUrl: './vd-logs.component.html',
  styleUrls: ['./vd-logs.component.scss'],
})
export class VirtualDeviceLogsComponent implements OnInit, OnDestroy {
  // @Input()
  // historyLogs: HistoryLog[];

  @Input()
  virtualDevices: DeviceDetails[];

  @Input()
  deviceTypeEntities: { [key: string]: DeviceType };

  columns: ColDef[];
  table: GridOptions;
  tableTitle = 'HISTORY';
  toolbarItems: string[] = [];

  isFetchingLatestHistory = false;
  private selectedSite: Site;
  private subscribers: { [prop: string]: any } = {};
  private intervals: { [prop: string]: any } = {};

  constructor(
    private tableService: SCTableService,
    private devicesService: DevicesService,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Set Table Columns
    this.columns = this.createColumns();

    this.selectedSite = this.sharedService.selectedSite;

    this.startAutoUpdate();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
    this.stopAutoUpdate();
  }

  private fetchLatestHistory() {
    if (this.isFetchingLatestHistory || !this.selectedSite) {
      return;
    }
    this.isFetchingLatestHistory = true;
    const options = {
      filter: {
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id,
        devices: this.virtualDevices.map((d) => d.idx).join(','),
      },
      latestLog: true,
      limit: 5000,
    };
    this.subscribers.fetchLatestHistory = this.deviceHistoryLogsService
      .getHistoryLogs(options)
      .subscribe((result: any) => {
        this.isFetchingLatestHistory = false;
        this.updateLatestHistory(result);
      });
  }

  private updateLatestHistory(latestHistory: any) {
    if (!this.virtualDevices || !latestHistory || !latestHistory.length) {
      return;
    }

    const itemsToUpdate = [];
    for (const history of latestHistory) {
      const device = this.virtualDevices.find((d) => d.idx + '' === history.deviceId + '');
      if (device) {
        const deviceType = this.deviceTypeEntities[device.deviceTypeId];
        if (deviceType) {
          const value = this.mapDHLValue(history.value, deviceType);
          const label = this.devicesService.deviceValueLabel(deviceType.key);
          const unit = this.devicesService.deviceValueUnit(deviceType.key);
          itemsToUpdate.push({
            id: device.id,
            label,
            unit,
            value,
            createdAt: history.createdAt,
            triggeredBy: history.triggeredBy,
          });
        }
      }
    }

    if (itemsToUpdate.length) {
      this.tableService.updateTableRowV2(this.table, itemsToUpdate);
    }
  }

  private mapDHLValue(data, deviceType) {
    let mappedValue = data;

    // find mapping value
    if (deviceType && deviceType.mappingValues && deviceType.mappingValues.length) {
      for (const mappingValue of deviceType.mappingValues) {
        if (mappingValue.key === data) {
          mappedValue = mappingValue.name;
          break;
        }
      }
    }

    // format number into 1 decimals
    if (typeof mappedValue === 'number') {
      mappedValue = this.sharedService.numberFormat(mappedValue, 1);
    }

    return mappedValue;
  }

  private startAutoUpdate() {
    this.fetchLatestHistory();
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = setInterval(() => this.fetchLatestHistory(), 10000);
  }

  private stopAutoUpdate() {
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = null;
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
  }

  private createColumns() {
    return [
      {
        colId: 'description',
        headerName: 'VIRTUAL_DEVICE',
        field: 'description',
        width: 200,
      },
      {
        colId: 'value',
        headerName: 'VALUE',
        field: 'value',
        valueFormatter: this.valueFormatter.bind(this),
        cellRenderer: this.cellRenderer.bind(this),
        width: 100,
      },
      {
        colId: 'createdAt',
        headerName: 'DATE',
        field: 'createdAt',
        valueFormatter: this.dateFormatter.bind(this),
        filterValueGetter: this.dateFormatter.bind(this),
        // valueGetter: this.dateFormatter.bind(this),
        width: 150,
        sort: 'desc',
      },
      {
        colId: 'triggeredBy',
        headerName: 'TRIGGERED_BY',
        field: 'triggeredBy',
        valueFormatter: this.triggeredByFormatter.bind(this),
        filterValueGetter: this.triggeredByFormatter.bind(this),
        // valueGetter: this.triggeredByFormatter.bind(this),
        cellRenderer: this.cellRenderer.bind(this),
        width: 100,
      },
    ];
  }

  private dateFormatter(params) {
    if (params && params.data && params.data.createdAt) {
      return this.sharedService.dateFormat(params.data.createdAt);
    }
    return '';
  }

  private valueFormatter(params) {
    if (params && params.data && params.data.value) {
      return `${params.data.value} ${params.data.unit}`;
    }
    return '';
  }

  private triggeredByFormatter(params) {
    if (params && params.data && params.data.triggeredBy) {
      if (params.data.triggeredBy.toUpperCase() === 'H') {
        return 'Human';
      } else if (params.data.triggeredBy.toUpperCase() === 'R') {
        return 'Rule';
      }
    }
    return '';
  }

  private cellRenderer(params) {
    if (params && params.valueFormatted) {
      return `<div class="text-center">${params.valueFormatted}</div>`;
    }
    return '';
  }
}
