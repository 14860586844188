import { Action } from '@ngrx/store';
import { Site } from '@widgets/sites/models/site';

export interface AppStyle {
  variables: { [key: string]: any };
  elements: { [key: string]: any };
}

export const LOAD_APP_STYLE = '[AppStyle] Load App Style';
export const LOAD_APP_STYLE_SUCCESS = '[AppStyle] Load App Style Success';
export const LOAD_APP_STYLE_FAIL = '[AppStyle] Load App Style Fail';

export class LoadAppStyle implements Action {
  readonly type = LOAD_APP_STYLE;
  constructor(public payload: Site) {}
}
export class LoadAppStyleSuccess implements Action {
  readonly type = LOAD_APP_STYLE_SUCCESS;
  constructor(public payload: AppStyle) {}
}
export class LoadAppStyleFail implements Action {
  readonly type = LOAD_APP_STYLE_FAIL;
  constructor(public payload: any) {}
}

export const RESET_APP_STYLE = '[AppStyle] Reset App Style';

export class ResetAppStyle implements Action {
  readonly type = RESET_APP_STYLE;
}

export const SET_APP_STYLE = '[AppStyle] Set App Style';

export class SetAppStyle implements Action {
  readonly type = SET_APP_STYLE;
  constructor(public payload: AppStyle) {}
}

// ACTION TYPES
export type AppStyleAction =
  | ResetAppStyle
  | SetAppStyle
  | LoadAppStyle
  | LoadAppStyleSuccess
  | LoadAppStyleFail;
