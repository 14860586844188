import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-command-form',
  templateUrl: './rule-command-form.component.html',
  styleUrls: ['./rule-command-form.component.scss']
})
export class RuleCommandFormComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  formId: number;
  @Input()
  data: { [key: string]: any };

  selectItems: { [key: string]: SelectItem[] };

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.selectItems = { ...this.data.selectItems };

    if (this.deviceTypeId) {
      // filter devices by device type
      const devices = this.data.devices.filter(
        (device: any) => device.deviceTypeId === this.deviceTypeId
      );
      // set device select items filter by device type
      this.selectItems.devices = this.sharedService.createSelectItems(
        devices,
        false,
        'tmpId'
      );
    }

    // set location select items
    this.selectItems.locations = this.sharedService.createSelectItems(
      this.data.locations,
      false,
      'tmpId'
    );

    this.patchFormValue();
  }

  private patchFormValue() {
    switch (this.targetTypeId) {
      case 'd':
      case 'dvs':
        if (this.selectItems.devices && this.selectItems.devices.length) {
          this.form.get('deviceId').setValue(this.selectItems.devices[0].value);
        }
        break;

      case 'l':
        if (this.selectItems.locations && this.selectItems.locations.length) {
          this.form
            .get('locationId')
            .setValue(this.selectItems.locations[0].value);
        }
        break;

      default:
        break;
    }

    // Patch parameters
    const parameters = this.parameterForms;

    if (parameters && parameters.controls && parameters.controls.length) {
      for (let i = 0; i < parameters.controls.length; i++) {
        const parameter = parameters.controls[i] as FormGroup;

        if (parameter.value.key === 'deviceId' && this.deviceTypeId) {
          const selectItemKey = 'param' + i + 'Devices';
          // create parameter device select items
          const paramDevices = this.data.devices.filter(
            (device: any) => device.deviceTypeId === this.deviceTypeId
          );
          this.data.selectItems[
            selectItemKey
          ] = this.sharedService.createSelectItems(
            paramDevices,
            false,
            'tmpId'
          );

          // set parameter value
          if (
            this.selectItems[selectItemKey] &&
            this.selectItems[selectItemKey].length
          ) {
            parameter
              .get('value')
              .setValue(this.data.selectItems[selectItemKey][0].value);
          }
        }
      }
    }
  }

  get parameterForms(): FormArray {
    return this.form.get('parameters') as FormArray;
  }

  get targetTypeId() {
    return this.form.get('targetTypeId').value;
  }

  get deviceTypeId() {
    return this.form.get('deviceTypeId').value;
  }

  get locationPropertyTypeId() {
    return this.form.get('locationPropertyTypeId').value;
  }

  get locationPropertyAdjustValue() {
    return this.form.get('locationPropertyAdjustValue').value;
  }

  get locationPropertyForceValue() {
    return this.form.get('locationPropertyForceValue').value;
  }

  get locationPropertyMapping() {
    if (
      this.data &&
      this.data.rawData &&
      this.data.rawData.locationProperties &&
      this.locationPropertyTypeId
    ) {
      const locationProperty = this.data.rawData.locationProperties.find(
        (property: any) => property.key === this.locationPropertyTypeId
      );
      if (locationProperty) {
        return locationProperty;
      }
    }
    return;
  }

  get locationPropertyMappingValues() {
    if (!this.locationPropertyMapping) {
      return;
    }

    switch (this.locationPropertyMapping.type) {
      case 'boolean':
        return this.selectItems.booleanOption;

      case 'deviceType':
        if (this.data && this.data.rawData && this.data.rawData.deviceTypes) {
          const deviceType = this.data.rawData.deviceTypes.find(
            (type: any) => type.key === this.locationPropertyMapping.deviceType
          );
          if (
            deviceType &&
            deviceType.mappingValues &&
            deviceType.mappingValues.length
          ) {
            return this.sharedService.createSelectItems(
              deviceType.mappingValues,
              false,
              'key'
            );
          }
        }
        return;
    }
  }

  get locationPropertyModifyKey() {
    if (this.locationPropertyAdjustValue) {
      return 'locationPropertyAdjustValue';
    } else if (this.locationPropertyForceValue) {
      return 'locationPropertyForceValue';
    }
  }

  get locationPropertyModifyLabel() {
    if (this.locationPropertyAdjustValue) {
      return 'ADJUST_VALUE';
    } else if (this.locationPropertyForceValue) {
      return 'FORCE_VALUE';
    }
  }

  get notificationTemplateId() {
    return this.form.get('notificationTemplateId').value;
  }

  get notificationTemplateName() {
    if (
      this.data &&
      this.data.rawData &&
      this.data.rawData.notificationTemplates &&
      this.notificationTemplateId
    ) {
      const notificationTemplate = this.data.rawData.notificationTemplates.find(
        (template: any) => template.id === this.notificationTemplateId
      );
      if (notificationTemplate) {
        return notificationTemplate.name || notificationTemplate.description;
      }
    }
    return '(empty)';
  }
}

/**
  action: "set_setpoint"
  delay: null
  deviceId: null
  deviceTypeId: 3
  executeOnlyIfDifferentFromLocal: null
  executeOnlyOneTimeUntilRuleWasStart: 1
  locationId: null
  locationPropertyAdjustValue: null
  locationPropertyForceValue: null
  locationPropertyTypeId: null
  notificationTemplateId: null
  parameters: [{…}]
  position: 0
  serviceId: null
  serviceTypeId: null
  targetTypeId: "d"
**/
