import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { SelectItem } from 'primeng/api';
import { Device } from '../models/device';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { DeviceHistoryLogsService } from '../services/device-history-logs.service';
import { LiveRequestsService } from '@widgets/live-requests/services/live-requests.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-add-dhl-form',
  templateUrl: 'device-add-dhl-form.component.html',
})
export class DeviceAddDHLFormComponent implements OnInit, OnDestroy {
  @Input()
  data: {
    device: Partial<Device>;
    deviceModel: DeviceModel;
    deviceType: DeviceType;
    [key: string]: any;
  };

  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  errorMessage: string;
  formData: any;
  form: FormGroup;
  isSubmitting = false;
  userVariant: string;
  commandValues: SelectItem[];
  serviceProviders: SelectItem[];

  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private sharedService: SharedService,
    private liveRequestsService: LiveRequestsService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.formData = this.data.device;
    this.commandValues = this.getCommandValues();

    // get user variant
    this.subscribers.userVariant = this.store
      .pipe(
        select(fromStore.getUserVariant),
        filter((userVariant) => (userVariant ? true : false)),
        take(1)
      )
      .subscribe((result) => {
        this.userVariant = result;
      });

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      value: [null, Validators.required],
      service: [null, Validators.required],
    });

    this.setServiceProviders();
  }

  // get device model
  get deviceModel(): DeviceModel {
    return this.data && this.data.deviceModel;
  }

  get deviceType(): DeviceModel {
    return this.data && this.data.deviceType;
  }

  // get command values
  private getCommandValues(): SelectItem[] {
    if (this.deviceModel && this.deviceModel.mappingValues && this.deviceModel.mappingValues.length) {
      return this.deviceModel.mappingValues.map((v) => ({
        label: v.name + '(' + v.controllerName + ')',
        value: v.key,
      }));
    }
    return [];
  }

  private setServiceProviders() {
    // Set service providers
    this.serviceProviders = [];

    switch (this.formData.automationVersion) {
      case 'v1rm':
        this.serviceProviders.push({ label: 'Pushy', value: 'pushy' });
        this.form.get('service').setValue('pushy');
        this.form.get('service').disable();
        break;
      case 'ceos':
      case 'v4':
        this.serviceProviders.push({ label: 'CloudMQTT', value: 'cloudmqtt' });
        this.form.get('service').setValue('cloudmqtt');
        this.form.get('service').disable();
        break;
      default:
        this.serviceProviders.push({ label: 'FCM', value: 'fcm' });
        this.serviceProviders.push({ label: 'Pushy', value: 'pushy' });
        this.form.patchValue({ service: 'fcm' });
        break;
    }
  }

  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const options: any = {
      apiKey: this.formData.automationApiKey,
      automationId: this.formData.automationId,
      // deviceId: this.formData.id,
      value: this.form.value.value,
      // locationId: this.formData.locationId,
      action: 'addDeviceHistoryLog',
    };

    if (this.userVariant === 'ceos') {
      options.deviceIdx = this.formData.idx;
      options.locationIdx = this.formData.locationIdx;
    } else {
      options.deviceId = this.formData.id;
      options.locationId = this.formData.locationId;
    }

    switch (this.formData.automationVersion) {
      case 'v1rm':
      case 'v4':
      case 'ceos':
        options.deviceType = this.deviceType.key;
        // this.subscribers.addDhl = this.deviceTriggerCommandsService
        //   .sendLiveRequest(options)
        this.subscribers.addDhl = this.liveRequestsService.sendLiveRequest(options).subscribe({
          next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
          error: this.apiCallError.bind(this, 'CREATE_FAIL'),
        });
        break;
      default:
        options.serviceProvider = this.form.value.service;
        delete options.action;
        this.subscribers.addDhl = this.deviceHistoryLogsService.addHistoryLog(options).subscribe({
          next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
          error: this.apiCallError.bind(this, 'CREATE_FAIL'),
        });
        break;
    }
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
