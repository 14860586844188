import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { AppStyleTemplateFormsService } from '../../services/app-style-template-forms.service';

@Component({
  selector: 'sc-element-style-form-group',
  templateUrl: 'element-style-form-group.component.html'
})
export class ElementStyleFormGroupComponent implements OnInit, OnChanges {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  variables: any[];
  @Output()
  removed = new EventEmitter();

  elements: string[];
  properties: SelectItem[];

  constructor(
    private appStyleTemplateFormsService: AppStyleTemplateFormsService
  ) {}

  ngOnInit() {
    this.properties = this.appStyleTemplateFormsService
      .getElementProperties('react-native')
      .map(prop => ({
        label: prop,
        value: prop
      }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.variables) {
      this.elements = changes.variables.currentValue;
    }
  }

  remove() {
    this.removed.emit();
  }
}
