import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import { Page } from '@app/core2/models/page';
import * as moment from 'moment';

interface Device {
  idx: string;
  name: string;
  locationName: string;
  locationIdx: string;
  fullName: string;
  min: number;
  max: number;
}

@Component({
  selector: 'sc-ecowatt',
  templateUrl: './ecowatt.component.html',
  styleUrls: ['./ecowatt.component.scss'],
})
export class EcowattComponent implements OnInit, OnDestroy {
  // The widget with its configuration
  @Input() config: any;

  page: Page;
  selectedSite: Site;

  subscribers: { [key: string]: any } = {};
  intervals: { [key: string]: any } = {};

  ecowattRawdata: any = [];
  ecowattData: any = [];

  cols: any[];

  isFetchingData: boolean = false;
  isFetchingDevices: boolean = false;
  lastFecth = null;
  lastLocale = '';

  widgetName: string = '';

  // Details modal
  isDetailsModalOpen: boolean = false;

  // Legend items
  legendItems: any[];

  constructor(private widgetService: SCWidgetService, private sharedService: SharedService) {}

  ngOnInit() {
    this.initWidgetName();
    this.fetchStatistics();
    this.intervals.fetchDataset = setInterval(() => this.fetchStatistics(), 1000);

    this.cols = [
      { field: '0h', header: '0h' },
      { field: '1h', header: '1h' },
      { field: '2h', header: '2h' },
      { field: '3h', header: '3h' },
      { field: '4h', header: '4h' },
      { field: '5h', header: '5h' },
      { field: '6h', header: '6h' },
      { field: '7h', header: '7h' },
      { field: '8h', header: '8h' },
      { field: '9h', header: '9h' },
      { field: '10h', header: '10h' },
      { field: '11h', header: '11h' },
      { field: '12h', header: '12h' },
      { field: '13h', header: '13h' },
      { field: '14h', header: '14h' },
      { field: '15h', header: '15h' },
      { field: '16h', header: '16h' },
      { field: '17h', header: '17h' },
      { field: '18h', header: '18h' },
      { field: '19h', header: '19h' },
      { field: '20h', header: '20h' },
      { field: '21h', header: '21h' },
      { field: '22h', header: '22h' },
      { field: '23h', header: '23h' },
    ];
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  initWidgetName() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);
  }

  getDisplayableDate(date) {
    const myDate = new Date(date);
    const weekday = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'][myDate.getDay()];
    const day = moment(myDate).locale(this.sharedService.userLocale).format('Do');
    const month = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ][myDate.getMonth()];

    return this.sharedService.userLocale === 'fr'
      ? `${this.sharedService.getTranslation(weekday)} ${day} ${this.sharedService.getTranslation(month)}`
      : `${this.sharedService.getTranslation(weekday)}, ${this.sharedService.getTranslation(month)} ${day}`;
  }

  getColorFromValue(value) {
    switch (value) {
      case 1:
        return 'green';
      case 2:
        return 'orange';
      case 3:
        return 'red';

      default:
        'gray';
    }
  }

  fetchStatistics() {
    if (
      this.lastLocale === this.sharedService.userLocale &&
      (this.isFetchingData || (moment().diff(this.lastFecth, 'minutes') < 30 && this.ecowattData.length > 0))
    ) {
      return;
    } else {
      if (this.isFetchingData) {
        return;
      }
    }

    this.isFetchingData = true;
    this.ecowattData = [];
    this.lastFecth = moment();
    this.lastLocale = this.sharedService.userLocale;

    this.legendItems = [
      {
        label: this.sharedService.getTranslation('ECOWATT_CONS_NORMAL'),
        color: this.widgetService.getColorByKey('green').hex,
      },
      {
        label: this.sharedService.getTranslation('ECOWATT_CONS_ABNORMAL'),
        color: this.widgetService.getColorByKey('orange').hex,
      },
      {
        label: this.sharedService.getTranslation('ECOWATT_CONS_EXCESSIVE'),
        color: this.widgetService.getColorByKey('red').hex,
      },
    ];

    this.subscribers.fetchDataset = this.widgetService.getEcowatt().subscribe(
      (result: any) => {
        this.isFetchingData = false;

        //Parse to JSON and keep only 3 days data
        this.ecowattRawdata = JSON.parse(result.data[0].value);
        this.ecowattRawdata.pop();

        this.ecowattRawdata.map((day) => {
          const rawData = {};
          day.values.forEach(
            (hour) =>
              (rawData[`${hour.pas}h`] = this.widgetService.getColorByKey(this.getColorFromValue(hour.hvalue)).hex)
          );
          this.ecowattData.push({
            day: this.getDisplayableDate(day.jour),
            value: day.dvalue,
            color: this.widgetService.getColorByKey(this.getColorFromValue(day.dvalue)).hex,
            data: [rawData],
          });
        });
      },
      (error: any) => {
        this.isFetchingData = false;
      }
    );
  }

  openDetailsModal() {
    this.isDetailsModalOpen = true;
  }
}
