import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  DeviceTaskTemplate,
  DeviceTaskTemplateParameter
} from '../models/device-task-template';

@Injectable()
export class DeviceTaskTemplateFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initDeviceTaskTemplateForm() {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      parameters: this.formBuilder.array([]),
      execute_instantly: true,
      recurring: false,
      site_ids: null,
      is_public: false,
      is_active: false,
      is_deleted: false
    });
  }

  initParameterFormGroup() {
    return this.formBuilder.group({
      key: [null, Validators.required],
      type: ['string', Validators.required],
      default_value: null
    });
  }

  setParameterFormArray(form: FormGroup, data: DeviceTaskTemplateParameter[]) {
    const formGroups = data.map(item => {
      const fg = this.initParameterFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('parameters', formArray);
  }
}
