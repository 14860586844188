<div
  class="tree-expand-button"
  pTooltip="{{ 'EXPAND_TOGGLE' | translate }}"
  tooltipPosition="top"
  appendTo="body"
>
  <button
    type="button"
    class="toggle-button"
    (click)="clickHandler()"
    [disabled]="disabled"
  >
    <span
      class="fa"
      [ngClass]="{ 'fa-eye-slash': currentState, 'fa-eye': !currentState }"
    ></span>
  </button>
</div>
