<div class="property-value-form">
  <div class="property-value-form__header">
    <div class="property-value-form__header__title">
      <strong>{{ "DEVICE_MODEL_VALUE_MAPPING" | translate }}</strong>
    </div>

    <div class="property-value-form__header__actions">
      <button
        pButton
        class="ui-button-text"
        [icon]="'fa fa-plus'"
        (click)="addValue()"
      ></button>
    </div>
  </div>

  <div class="property-value-body" [formGroup]="form">
    <div formArrayName="values">
      <div class="ui-g">
        <div class="ui-g-12 ui-md-10 ui-g-nopad">
          <div class="ui-g-12 ui-md-3">
            {{ "DEVICE_MODEL_VALUE_MAPPED" | translate }}
          </div>
          <div class="ui-g-12 ui-md-4">
            {{ "DEVICE_MODEL_RAW_READ" | translate }}
          </div>
          <div class="ui-g-12 ui-md-4">
            {{ "DEVICE_MODEL_RAW_WRITE" | translate }}
          </div>
          <div class="ui-g-12 ui-md-3" *ngIf="false">
            {{ "DEVICE_MODEL_LIBRARY" | translate }}
          </div>
        </div>
      </div>

      <div class="ui-g" *ngFor="let ctrl of valuesForm.controls; let i = index">
        <div class="ui-g-12 ui-md-10 ui-g-nopad">
          <div class="ui-g-12 ui-md-3">
            <sc-dropdown
              controlName="mapped"
              [form]="ctrl"
              [label]="'DEVICE_MODEL_VALUE_MAPPED' | translate"
              [showLabel]="false"
              [options]="mappingValues"
              [showClear]="false"
            ></sc-dropdown>
          </div>

          <div class="ui-g-12 ui-md-4" style="display: flex; column-gap: 8px">
            <div class="ui-g-12 ui-md-6 ui-g-nopad">
              <sc-text-input
                controlName="raw_read"
                [form]="ctrl"
                [label]="'DEVICE_MODEL_RAW_READ' | translate"
                [showLabel]="false"
              ></sc-text-input>
            </div>
            <div class="ui-g-12 ui-md-6 ui-g-nopad">
              <sc-dropdown
                controlName="raw_read_type"
                [form]="ctrl"
                [label]="'TYPE' | translate"
                [showLabel]="false"
                [options]="selectItems?.dataTypes"
                [showClear]="false"
              ></sc-dropdown>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4" style="display: flex; column-gap: 8px">
            <div class="ui-g-12 ui-md-6 ui-g-nopad">
              <sc-text-input
                controlName="raw_write"
                [form]="ctrl"
                [label]="'DEVICE_MODEL_RAW_WRITE' | translate"
                [showLabel]="false"
              ></sc-text-input>
            </div>
            <div class="ui-g-12 ui-md-6 ui-g-nopad">
              <sc-dropdown
                controlName="raw_write_type"
                [form]="ctrl"
                [label]="'TYPE' | translate"
                [showLabel]="false"
                [options]="selectItems?.dataTypes"
              ></sc-dropdown>
            </div>
          </div>
          <div class="ui-g-12 ui-md-1">
            <button
              pButton
              class="ui-button-text"
              [icon]="'fa fa-minus'"
              (click)="removeValue(i)"
            ></button>
          </div>
          <div class="ui-g-12 ui-md-3" *ngIf="false">
            <sc-dropdown
              controlName="library"
              [form]="ctrl"
              [label]="'DEVICE_MODEL_LIBRARY' | translate"
              [showLabel]="false"
              [options]="selectItems?.libraries"
              [showClear]="false"
              [disabled]="true"
            ></sc-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
