import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-location-live-data-cell',
  templateUrl: 'location-live-data-cell.component.html'
})
export class LocationLiveDataCellComponent implements ICellRendererAngularComp {
  cell: any;
  currentValue: any;
  diffValue: any;
  previousValue: any;
  valueType: string;

  constructor() {}

  agInit(params: any): void {
    this.currentValue =
      params.valueFormatted !== null ? params.valueFormatted : params.value;
    this.valueType = params.colDef.field;
  }

  refresh(params: any): boolean {
    this.previousValue = this.currentValue;
    if (this.isNumberData(this.previousValue)) {
      this.currentValue = params.value || 0;
      this.diffValue = this.currentValue - this.previousValue;
    } else {
      this.currentValue = params.value || '';
      this.diffValue = null;
    }
    return true;
  }

  formatNumberData(data: any): number {
    return typeof data === 'number' ? Math.abs(data) : null;
  }

  isNumberData(data: any): boolean {
    return typeof data === 'number' ? true : false;
  }

  isDataValid(data) {
    return typeof data === 'undefined' ? false : true;
  }

  getColor() {
    let color = 'grey-text';
    // if (this.diffValue > 0) {
    //   color = 'green-text';
    // } else if (this.diffValue < 0) {
    //   color = 'red-text';
    // }
    return color;
  }

  getIcon() {
    let icon = '';
    if (
      this.isNumberData(this.currentValue) &&
      this.isNumberData(this.previousValue)
    ) {
      if (this.currentValue > this.previousValue) {
        // icon = 'fa fa-plus ';
        icon = '+';
      } else if (this.currentValue < this.previousValue) {
        // icon = 'fa fa-minus';
        icon = '-';
      } else {
        // icon = 'fa fa-arrows-h';
      }
    }
    return icon;
  }
}
