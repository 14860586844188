<div class="location-details-modal">
  <ul class="menu">
    <li
      class="menu-item"
      (click)="currentMenu = 'info'"
      [ngClass]="{ active: currentMenu === 'info' }"
    >
      {{ "INFORMATION" | translate }}
    </li>
    <!--
    <li
      class="menu-item"
      (click)="currentMenu = 'rules'"
      [ngClass]="{ active: currentMenu === 'rules' }"
    >
      {{ "RULES" | translate }}
    </li>
    -->
    <li
      class="menu-item"
      (click)="currentMenu = 'summary'"
      [ngClass]="{ active: currentMenu === 'summary' }"
    >
      {{ "SUMMARY" | translate }}
    </li>
  </ul>

  <ng-container *ngIf="currentMenu === 'info'; then info"></ng-container>
  <ng-container *ngIf="currentMenu === 'rules'; then rules"></ng-container>
  <ng-container *ngIf="currentMenu === 'summary'; then summary"></ng-container>

  <!-- <ng-container *ngIf="currentMenu === 'deviceDetails'; then deviceDetails"></ng-container> -->
</div>

<ng-template #info>
  <div class="content-container">
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <sc-location-info
          [location]="selectedLocation"
          [locationTypes]="locationTypes"
        ></sc-location-info>
      </div>

      <div class="ui-g-12 ui-md-6">
        <sc-location-devices
          [devices]="devices"
          (viewDeviceDetails)="openDeviceDetail($event)"
        ></sc-location-devices>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12" *ngIf="locationHistoryConfig">
        <sc-location-history
          [config]="locationHistoryConfig"
          [selectedLocation]="selectedLocation"
        ></sc-location-history>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rules>
  <div class="content-container">
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <sc-location-rules
          *ngIf="locationRulesConfig"
          [config]="locationRulesConfig"
          [selectedLocation]="selectedLocation"
        ></sc-location-rules>
      </div>

      <div class="ui-g-12 ui-md-6">
        <sc-location-rule-history
          *ngIf="locationRulesHistoryConfig"
          [config]="locationRulesHistoryConfig"
          [selectedLocation]="selectedLocation"
        ></sc-location-rule-history>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #summary>
  <sc-location-summary-form
    [locationProperties]="locationProperties"
    [summaryConfig]="summaryConfig"
    (saved)="onSummaryConfigSaved($event)"
  ></sc-location-summary-form>
</ng-template>

<!-- <ng-template #deviceDetails>
  <div class="">
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <sc-vdh-info [holderDevice]="holderDevice" [devices]="devices" [deviceModelEntities]="deviceModelEntities"></sc-vdh-info>
      </div>

      <div class="ui-g-12 ui-md-6">
        <sc-vds-table [virtualDevices]="virtualDevices" [devices]="devices" [deviceTypeEntities]="deviceTypeEntities"></sc-vds-table>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <sc-vd-logs [virtualDevices]="virtualDevices" [deviceTypeEntities]="deviceTypeEntities"></sc-vd-logs>
      </div>
    </div>
  </div>
</ng-template> -->
