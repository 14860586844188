import { Component } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as moment from 'moment-timezone';

@Component({
  selector: 'sc-ceos-device-live-data-cell',
  templateUrl: 'ceos-device-live-data-cell.component.html',
})
export class CeosDeviceLiveDataCellComponent implements ICellRendererAngularComp {
  cell: any;
  currentData: any;
  previousData: any;
  liveValue: any;
  liveError: any;

  constructor(private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(params: any): boolean {
    this.cell = params;
    this.previousData = this.currentData;
    this.currentData = this.cell.value;
    // console.log(this.currentData);
    if (this.currentData.error) {
      this.liveError = this.currentData.error;
      this.liveValue = null;
    } else if (this.currentData.value) {
      this.liveError = null;
      // this.liveValue = this.currentData.value.map((item) => ({
      //   ...item,
      //   datetimeFormatted: this.sharedService.dateFormat(moment.utc(item.datetime, 'YYYY-MM-DD HH:mm:ss.SSSSS')),
      // }));
      this.liveValue = this.currentData.value;
    }
    return true;
  }

  getTooltipText(virtualDevice) {
    let text = virtualDevice.deviceTypeName || virtualDevice.name;
    if (virtualDevice.value && virtualDevice.valueAt) {
      text += ',\n ' + virtualDevice.valueAt;
    } else {
      text += ',\n no data yet';
    }
    return text;
  }
}
