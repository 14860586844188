import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable({providedIn: 'root'})
export class FilesService {
  private apiUrl = `${config().apiUrl}/_backend/fileManager`;

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) { }

  list(path: string = '') {
    const { id } = this.sharedService.selectedSite;
    const url = `${this.apiUrl}?siteId=${id}&path=${encodeURI(path)}`;
    return this.httpClient.get(url);
  }

  copy(files: any[], path: string) {
    const { id } = this.sharedService.selectedSite;
    const url = `${this.apiUrl}/copy?siteId=${id}`;
    files = files.map(f => f.data);
    const body = { path, files };
    return this.httpClient.post(url, body);
  }

  move(files: any[], path: string) {
    const { id } = this.sharedService.selectedSite;
    const url = `${this.apiUrl}/move?siteId=${id}`;
    files = files.map(f => f.data);
    const body = { path, files };
    return this.httpClient.post(url, body);
  }

  delete(files: any[]) {
    const { id } = this.sharedService.selectedSite;
    const url = `${this.apiUrl}?siteId=${id}`;
    files = files.map(f => f.data);
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { files }
    };
    return this.httpClient.delete(url, options);
  }

  createFolder(name: string, path?: string) {
    const { id } = this.sharedService.selectedSite;
    const url = `${this.apiUrl}/createFolder?siteId=${id}`;
    const body = { name, path };
    return this.httpClient.post(url, body);
  }

  rename(name: string, file: { [key: string]: any }) {
    const { id } = this.sharedService.selectedSite;
    const url = `${this.apiUrl}/rename?siteId=${id}`;
    const body = { name, file: file.data };
    return this.httpClient.post(url, body);
  }

  upload(files: any[], path?: string) {
    const { id } = this.sharedService.selectedSite;
    const url = `${this.apiUrl}/upload?siteId=${id}`;
    const formData: FormData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    if (path) {
      formData.append('path', path);
    }
    return this.httpClient.post(url, formData);
  }
}
