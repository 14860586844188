<div class="file-drop">
  <div class="dropzone" scFileDrop (fileDropped)="onFileBrowsedOrDropped($event)">
    <input #fileDropInput type="file" id="file-input" class="file-input" multiple (change)="onFileBrowsedOrDropped($event.target.files)" />
    <div class="message">{{ 'FILE_MANAGER_DRAG_AND_DROP_MESSAGE' | translate }}</div>
    <div class="message">{{ 'OR' | translate }}</div>
    <label for="file-input" class="trigger">{{ 'FILE_MANAGER_BROWSE_FILES' | translate }}</label>
  </div>

  <ul class="file-list">
    <li class="file" *ngFor="let file of files; let i = index">
      <div class="info">
        <div class="name">
          {{ file?.name }}
        </div>
        <div class="size">
          {{ formatBytes(file?.size) }}
        </div>
      </div>
      <div class="action" (click)="deleteFile(i)">
        <span class="fa fa-trash fa-fw"></span>
      </div>
    </li>
  </ul>

  <div class="footer">
    <button pButton type="button" class="ui-button-danger" [label]="'CLEAR'| translate" (click)="deleteFile()"></button>
    <button pButton type="button" class="ui-button-success" [label]="'UPLOAD'| translate" (click)="uploadFile()"></button>
  </div>
</div>
