import { LocationsService } from '@widgets/locations/services/locations.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import { Page } from '@app/core2/models/page';
import { PageService } from '@app/core2/page/page.service';
import { TreeNode } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'sc-air-quality',
  templateUrl: './air-quality.component.html',
})
export class AirQualityComponent implements OnInit, OnDestroy {
  @Input() config: any;

  chartData: any;
  chartLayout: any;
  subscribers: { [key: string]: any } = {};
  intervals: { [key: string]: any } = {};
  widgetName = 'Air quality';
  isInitialized: boolean = false;

  constructor(
    private widgetService: SCWidgetService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private pageService: PageService,
    private locationsService: LocationsService,
    private formBuilder: FormBuilder
  ) {
    this.chartLayout = {
      height: 180,
      width: 180,
      showlegend: false,
      margin: { t: 0, b: 0, l: 0, r: 0 },
      font: {
        family: 'Barlow',
        size: 13,
      },
    };
    this.renderChart();
  }

  ngOnInit() {
    this.isInitialized = true;
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  renderChart() {
    let values = [26, 3, 1];
    let labels = ['Good', 'Medium', 'Bad'];
    let colors = [
      this.widgetService.getColorByKey(this.widgetService.COLOR_GREEN).hex,
      this.widgetService.getColorByKey(this.widgetService.COLOR_ORANGE).hex,
      this.widgetService.getColorByKey(this.widgetService.COLOR_RED).hex,
    ];

    this.chartData = [
      {
        type: 'pie',
        values: values,
        labels: labels,
        marker: {
          colors: colors,
        },
        textinfo: 'label',
        textposition: 'inside',
        hole: 0.4,
        automargin: true,
      },
    ];
  }
}
