import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { CustomFunction } from '../models/custom-function';
import { CustomFunctionsService } from '../services/custom-functions.service';
import { SharedService } from '@shared/shared.service';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { CustomFunctionActionCellComponent } from '../custom-function-action-cell/custom-function-action-cell.component';

@Component({
  selector: 'sc-custom-functions-table',
  templateUrl: './custom-functions-table.component.html',
  styleUrls: ['./custom-functions-table.component.scss'],
})
export class CustomFunctionsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: CustomFunction[];
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string = 'CUSTOM_FUNCTIONS';

  private subscribers: { [key: string]: any } = {};

  constructor(private customFunctionsService: CustomFunctionsService, private sharedService: SharedService) {}

  ngOnInit() {
    this.initTable();
    this.fetchDataset();
  }

  ngOnDestroy() {
    // unsubscribe all subscribers
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.fetchDataset = this.customFunctionsService.getCustomFunctions().subscribe((result: any) => {
      this.dataset = result.data;
    });
  }

  private createColumns() {
    return [
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'return_type',
        headerName: 'RETURN_TYPE',
        field: 'return_type',
      },
      {
        colId: 'idx',
        headerName: 'IDX',
        field: 'idx',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'is_active',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'is_deleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: CustomFunctionActionCellComponent,
      },
    ];
  }

  private initTable() {
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'CustomFunctionForm',
      options: { modalTitle: 'CUSTOM_FUNCTION_FORM_TITLE' },
    };
  }
}
