import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItems, Subscribers, CustomFunction, CustomFunctionParameter } from '../../models';
import { UtilsService } from '../../services';

@Component({
  selector: 'sc-function-operand',
  templateUrl: './function-operand.component.html',
  styleUrls: ['./function-operand.component.scss'],
})
export class FunctionOperandComponent implements OnInit {
  variables: CustomFunctionParameter[];

  @Input()
  form: FormGroup;

  @Input()
  isTemplate: boolean;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  private customFunction: CustomFunction;
  private subscribers: Subscribers = {};

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    if (this.form) {
      const formValue = this.form.value;

      this.subscribeOnFormValueChanges();
      if (formValue && formValue.variables && formValue.variables.length) {
        this.variables = formValue.variables;
      }
    }
  }

  ngOnDestroy() {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private subscribeOnFormValueChanges() {
    this.subscribers.customFunctionId = this.form.get('custom_function_idx').valueChanges.subscribe((value) => {
      if (this.dataSources.customFunctions && this.dataSources.customFunctions.length) {
        this.customFunction = this.dataSources.customFunctions.find((item) => item.idx === value);
        let variables;
        if (this.customFunction && this.customFunction.parameters && this.customFunction.parameters.length) {
          try {
            variables = JSON.parse(this.customFunction.parameters as string);
          } catch (error) {}
        }
        this.variables = variables;
      }
    });
  }
}
