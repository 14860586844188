import { Component, OnInit, Input } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'sc-form-error',
  template: `
    <p-messages [value]="messages" [closable]="false"></p-messages>
  `
})
export class SCFormErrorComponent implements OnInit {
  @Input() message;

  constructor() {}

  ngOnInit() {}

  get messages(): Message[] {
    if (!this.message) {
      return;
    }
    return [
      {
        severity: 'error',
        summary: this.message
      }
    ];
  }
}
