<div class="rule-template-overview" *ngIf="ruleTemplate">
  <div class="rule-template-detail">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-6">
        <sc-input-label
          for="name"
          [title]="'CCP_COMMON_NAME' | translate"
          [required]="true"
        ></sc-input-label>

        <input
          type="text"
          pInputText
          inputId="name"
          [(ngModel)]="ruleTemplate.name"
        />
      </div>

      <div class="p-field p-col-12 p-md-6">
        <label class="input-label" for="repeat">
          {{ "CCP_COMMON_ACTIVE" | translate }}
        </label>

        <p-checkbox
          inputId="repeat"
          [binary]="true"
          [(ngModel)]="ruleTemplate.is_active"
          [label]="'CCP_COMMON_YES' | translate"
        ></p-checkbox>
      </div>

      <div class="p-field p-col-12 p-md-6">
        <sc-input-label
          for="description"
          [title]="'DESCRIPTION' | translate"
        ></sc-input-label>

        <input
          type="text"
          pInputText
          inputId="description"
          [(ngModel)]="ruleTemplate.description"
        />
      </div>

      <div class="p-field p-col-12 p-md-6">
        <sc-input-label
          for="category"
          [title]="'CATEGORY' | translate"
        ></sc-input-label>

        <p-dropdown
          inputId="category"
          [options]="ruleCategories"
          [(ngModel)]="ruleTemplate.rule_category_id"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-6">
        <sc-input-label
          for="view_mode"
          [title]="'CCP_COMMON_VIEW' | translate"
          [required]="true"
        ></sc-input-label>

        <p-dropdown
          inputId="view_mode"
          [options]="viewModes"
          [(ngModel)]="ruleTemplate.editor_view"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_COMMON_VIEW' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>
    </div>
  </div>

  <div
    class="rule-template-workflow"
    [ngClass]="{
      'rule-template-workflow--no-command': ruleTemplate?.commands?.length === 0
    }"
  >
    <div class="condition-header">
      <div class="circle condition-title">
        {{ "CCP_RULE_CONNECTOR_IF" | translate }}
      </div>
    </div>

    <ng-container *ngIf="ruleTemplate?.conditions">
      <sc-rule-condition
        [isTemplate]="true"
        [viewMode]="ruleTemplate?.editor_view"
        [conditions]="ruleTemplate?.conditions"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
      ></sc-rule-condition>
    </ng-container>

    <div class="command-header">
      <div class="circle command-title">
        {{ "CCP_RULE_CONNECTOR_THEN" | translate }}
      </div>
      <div class="command-description">
        {{ "CCP_RULE_CONNECTOR_THEN_DESCRIPTION" | translate }}
      </div>
    </div>

    <ng-container *ngIf="ruleTemplate?.commands">
      <sc-rule-commands
        [isTemplate]="true"
        [viewMode]="ruleTemplate?.editor_view"
        [commands]="ruleTemplate?.commands"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
      ></sc-rule-commands>
    </ng-container>
  </div>
</div>
