import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-device-type-mapping-value-form',
  templateUrl: './mapping-value-form.component.html'
})
export class DeviceTypeMappingValueFormComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Output()
  removed = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  remove() {
    this.removed.emit();
  }
}
