import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AverageTemperatureComponent } from './average-temperature.component';

@NgModule({
  imports: [SharedModule],
  declarations: [AverageTemperatureComponent],
  entryComponents: [AverageTemperatureComponent]
})
export class AverageTemperatureModule {}
