import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { EnergySavingsService } from '../services/energy-savings.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-total-saving',
  template: `
    <div [ngStyle]="{ 'height.px': config.widgetHeight }">
      <sc-dashboard-circles
        [config]="widgetConfig"
        [dataset]="widgetDataset"
        [loading]="isFirstTime"
      ></sc-dashboard-circles>
    </div>
  `,
})
export class TotalSavingComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isFirstTime: boolean;
  widgetConfig: any;
  widgetDataset: any;

  private dataset: { [key: string]: any };
  private isFetching: boolean;
  private intervals: { [key: string]: any } = {};
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private energySavingsService: EnergySavingsService,
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.widgetConfig = {
      titleText: this.sharedService.getLocaleWidgetName(this.config),
      subTitleText: 'SINCE_SYSTEM_IS_RUNNING',
    };

    // Get Selected Site
    this.subscribers.selectedSite = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
        this.intervals.fetchDataset = setInterval(() => this.fetchDataset(), 5000);
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite && this.selectedSite.id) {
      this.isFetching = true;
      this.subscribers.fetchDataset = this.energySavingsService.getEnergySavingsTotal(this.selectedSite.id).subscribe(
        (result: any) => {
          this.isFetching = false;
          this.isFirstTime = false;
          this.dataset = result.data;
          this.updateWidgetDataset();
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  private updateWidgetDataset() {
    this.widgetDataset = [
      {
        customClass: 'lightgreen',
        label: 'ENERGY',
        primaryText: this.getKWHSaved(),
        secondaryText: 'KW',
      },
      {
        customClass: 'lightgreen',
        label: 'COST',
        primaryText: this.getCostSaved(),
        secondaryText: 'THB',
      },
      {
        customClass: 'lightgreen',
        label: '%',
        primaryText: this.getPercentSaved(),
        secondaryText: '%',
      },
    ];
  }

  private getKWHSaved() {
    if (this.dataset && this.dataset.kwhSaved) {
      if (this.dataset.kwhSaved >= 10000) {
        return (this.dataset.kwhSaved / 1000).toFixed(0) + 'K';
      }
      return this.dataset.kwhSaved.toFixed(0);
    }
    return 0;
  }

  private getCostSaved() {
    if (this.dataset && this.dataset.costSaved) {
      if (this.dataset.costSaved >= 10000) {
        return (this.dataset.costSaved / 1000).toFixed(0) + 'K';
      }
      return this.dataset.costSaved.toFixed(0);
    }
    return 0;
  }

  private getPercentSaved() {
    if (this.dataset && this.dataset.percentSaved) {
      return this.dataset.percentSaved.toFixed(0);
    }
    return 0;
  }
}
