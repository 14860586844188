import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { DhlTableComponent } from './dhl-table/dhl-table.component';

import { DeviceHistoryLogsService } from './services/device-history-logs.service';

const COMPONENTS: any[] = [DhlTableComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [DeviceHistoryLogsService]
})
export class DeviceHistoryLogsModule {}
