/**
 * List of Modals (Dynamic Component)
 * - Modal Name = Component Name without "Component" at the end.
 **/

export * from '@app/core2/page-widget-form/page-widget-form.component';
export * from '@app/core2/page-section-form/page-section-form.component';

export * from '@widgets/ac-valve-alert/ac-valve-alert-modal/ac-valve-alert-modal.component';
export * from '@widgets/ag-alert/ag-alert-modal/ag-alert-modal.component';
export * from '@widgets/app-style-templates/app-style-template-form/app-style-template-form.component';
export * from '@widgets/app-styles/app-style-form/app-style-form.component';
export * from '@widgets/apps/app-form/app-form.component';
export * from '@widgets/battery-alert/battery-alert-modal/battery-alert-modal.component';
export * from '@widgets/benchmarks/benchmark-templates/benchmark-template-form/benchmark-template-form.component';
export * from '@widgets/benchmarks/benckmarks-overview/analyse-modal/analyse-modal.component';
export * from '@widgets/benchmarks/location-benchmarks/analyse-form/analyse-form.component';
export * from '@widgets/benchmarks/location-benchmarks/location-benchmark-detail/location-benchmark-detail.component';
export * from '@widgets/benchmarks/location-benchmarks/location-benchmark-form/location-benchmark-form.component';
export * from '@widgets/bulk-modification/bulk-modification-form/bulk-modification-form.component';
export * from '@widgets/companies/company-form/company-form.component';
export * from '@widgets/currencies/currency-form/currency-form.component';
export * from '@widgets/custom-apps/custom-app-form/custom-app-form.component';
export * from '@widgets/custom-functions/custom-function-form/custom-function-form.component';
export * from '@widgets/device-brands/device-brand-form/device-brand-form.component';
export * from '@widgets/device-models/device-model-form/device-model-form.component';
export * from '@widgets/device-models-2/device-model-form/device-model-form.component';
export * from '@widgets/device-protocols/device-protocol-form/device-protocol-form.component';
export * from '@widgets/device-task-templates/device-task-template-form/device-task-template-form.component';
export * from '@widgets/device-tasks/device-task-form/device-task-form.component';
export * from '@widgets/device-types/device-type-form/device-type-form.component';
export * from '@widgets/devices/device-add-dhl-form/device-add-dhl-form.component';
export * from '@widgets/devices/device-command-mapping-form/device-command-mapping-form.component';
export * from '@widgets/devices/device-form/device-form.component';
export * from '@widgets/devices/device-logs-comparison/device-logs-comparison.component';
export * from '@widgets/devices/device-logs-view/device-logs-view.component';
export * from '@widgets/devices/device-trigger-action-form/device-trigger-action-form.component';
export * from '@widgets/devices/protocol-controller-form/protocol-controller-form.component';
export * from '@widgets/electricity-costs/cost-form/cost-form.component';
export * from '@widgets/energy-saving/energy-efficiency-rating/detail-modal/detail-modal.component';
export * from '@widgets/end-users/role-form/role-form.component';
export * from '@widgets/events/event-form/event-form.component';
export * from '@widgets/guest-request-categories/guest-request-category-form/guest-request-category-form.component';
export * from '@widgets/guest-request-presets/guest-request-preset-form/guest-request-preset-form.component';
export * from '@widgets/guest-sessions/guest-sessions-form/guest-sessions-form.component';
export * from '@widgets/locales/locale-form/locale-form.component';
export * from '@widgets/location-generator/location-generator-form/location-generator-form.component';
export * from '@widgets/location-profiles/location-profile-form/location-profile-form.component';
export * from '@widgets/location-properties/location-property-form/location-property-form.component';
export * from '@widgets/location-statistics/location-statistics.component';
export * from '@widgets/location-templates/location-template-form/location-template-form.component';
export * from '@widgets/locations-alert-modal/locations-alert-modal.component';
export * from '@widgets/locations/custom-summary-form/custom-summary-form.component';
export * from '@widgets/locations/location-form/location-form.component';
export * from '@widgets/locations/location-qr-code/location-qr-code.component';
export * from '@widgets/locations/location-type-form/location-type-form.component';
export * from '@widgets/most-sold-rooms/most-sold-rooms-modal/most-sold-rooms-modal.component';
export * from '@widgets/notification-templates/notification-template-form/notification-template-form.component';
export * from '@widgets/notifications/notification-detail/notification-detail.component';
export * from '@widgets/page-templates/page-template-form/page-template-form.component';
export * from '@widgets/property-management-systems/property-management-system-form/property-management-system-form.component';
export * from '@widgets/report-templates/report-template-form/report-template-form.component';
export * from '@widgets/rule-categories/rule-category-form/rule-category-form.component';
export * from '@widgets/rule-scheduler-jobs/rule-scheduler-job-form/rule-scheduler-job-form.component';
export * from '@widgets/rule-templates/ceos-rule-template-form/ceos-rule-template-form.component';
export * from '@widgets/rule-templates/rule-template-form/rule-template-form.component';
export * from '@widgets/rules/ceos-rule-form/ceos-rule-form.component';
export * from '@widgets/rules/rule-form/rule-form.component';
export * from '@widgets/scene-templates/scene-template-form/scene-template-form.component';
export * from '@widgets/scenes/scene-form/scene-form.component';
export * from '@widgets/setting-form/fields-form/fields-form.component';
export * from '@widgets/setting-widgets/setting-widget-form/setting-widget-form.component';
export * from '@widgets/site-types/site-type-form/site-type-form.component';
export * from '@widgets/sites/site-form/site-form.component';
export * from '@widgets/user-task-templates/user-task-template-form/user-task-template-form.component';
export * from '@widgets/tech-alerts/tech-alert-modal/tech-alert-modal.component';
export * from '@widgets/translations/translation-form/translation-form.component';
export * from '@widgets/translations/translation-import-form/translation-import-form.component';
export * from '@widgets/users/profile-form/profile-form.component';
export * from '@widgets/users/user-email-form/user-email-form.component';
export * from '@widgets/users/user-form/user-form.component';
export * from '@widgets/users/user-password-form/user-password-form.component';
export * from '@widgets/users/user-permission-form/user-permission-form.component';
export * from '@widgets/users/user-role-form/user-role-form.component';
export * from '@widgets/widget-categories/widget-category-form/widget-category-form.component';
export * from '@app/widgets/bulk-creator/bulk-creator.component';
