import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Event } from '../models/event';
import { EventRequestOptions } from '../models/event-request-options';

import { config } from '../../../config';

@Injectable()
export class EventsService {
  private apiUrl = config().apiUrl + '/events';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): Event {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.eventId !== 'undefined') {
      delete newData.eventId;
    }
    if (typeof newData.companyId !== 'undefined') {
      delete newData.companyId;
    }
    if (typeof newData.siteId !== 'undefined') {
      delete newData.siteId;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  getEvent(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getEvents(options?: EventRequestOptions) {
    const queryParams = [];

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if (key === 'minimize') {
          if (options['minimize'] === true) {
            queryParams.push(key);
          }
        } else {
          queryParams.push(key + '=' + options[key]);
        }
      }
    }

    const apiUrl = this.apiUrl + (queryParams.length ? '?' + queryParams.join('&') : '');

    return this.httpClient.get(apiUrl);
  }

  createEvent(data: Event) {
    if (typeof data.eventId !== 'undefined') {
      delete data.eventId;
    }
    return this.httpClient.post(this.apiUrl, data);
  }

  updateEvent(id: number, data: Event) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteEvent(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, { isActive: false, isDeleted: true });
  }
}
