import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { GridsterConfig } from 'angular-gridster2';

import { PageTemplateFormsService } from '../../services/page-template-forms.service';
import { SharedService } from '@shared/shared.service';
import * as moment from 'moment';
import { config } from '@app/config';

@Component({
  selector: 'sc-sections-form',
  templateUrl: 'sections-form.component.html',
  styleUrls: ['sections-form.component.scss'],
})
export class SectionsFormComponent implements OnInit, OnDestroy {
  @Input()
  form: FormGroup;
  @Input()
  optionData: { [key: string]: any };

  editMode: boolean;
  currentSection: number;
  gridConfig: GridsterConfig;

  private subscribers: { [key: string]: any } = {};

  constructor(private pageTemplateFormsService: PageTemplateFormsService, private sharedService: SharedService) {}

  ngOnInit() {
    this.gridConfig = config().gridConfig as GridsterConfig;
    this.gridConfig.draggable = { enabled: true };
    delete this.gridConfig.colWidth;
    delete this.gridConfig.rowHeight;

    if (this.form.value.sections.length) {
      this.editMode = true;
      this.patchWidgetsOption();
      this.selectSection(0);
    } else {
      this.editMode = false;
      this.add();
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  get sectionsForm(): FormArray {
    return this.form.controls.sections as FormArray;
  }

  get sectionWidgets() {
    return this.sectionsForm.controls[this.currentSection].get('widgets').value;
  }

  get isSectionFormReady(): boolean {
    if (
      this.sectionsForm &&
      this.sectionsForm.controls &&
      this.sectionsForm.controls.length &&
      this.currentSection >= 0
    ) {
      return true;
    }
    return false;
  }

  add() {
    const number = this.sectionsForm.controls.length;
    const fg = this.pageTemplateFormsService.initSectionFormGroup();
    fg.patchValue({
      sectionId: `s${moment().valueOf()}`,
      name: `section ${number + 1}`,
      position: number,
    });
    this.sectionsForm.push(fg);
    this.selectSection(number);
  }

  remove() {
    this.sectionsForm.removeAt(this.currentSection);
  }

  selectSection(i: number) {
    this.currentSection = i;
    this.onSelectedWidgetsChange();
  }

  /**
   * handling widgets change event
   */
  onSelectedWidgetsChange() {
    this.subscribers.onSelectedWidgetsChange = this.sectionsForm.controls[this.currentSection]
      .get('selectedWidgets')
      .valueChanges.subscribe((value) => {
        let widgets = [];

        if (value && value.length) {
          widgets = value.map((widgetId) => {
            const widget = this.optionData.widgetsSrc.find((ws) => ws.id === widgetId);
            return widget;
          });
        }

        this.pageTemplateFormsService.setWidgetFormArray(
          this.sectionsForm.controls[this.currentSection] as FormGroup,
          widgets
        );
      });
  }

  getWidgetName(widget) {
    if (this.optionData && this.optionData.widgetsSrc && this.optionData.widgetsSrc.length) {
      const w = this.optionData.widgetsSrc.find((ws) => ws.id === widget.widgetId);

      if (w) {
        // return w.name;
        return this.sharedService.getLocaleWidgetName(w);
      }
    }
    return '(empty)';
  }

  clearWidgets() {
    this.sectionsForm.controls[this.currentSection].get('selectedWidgets').reset();
  }

  /**
   * Patch widget template config to fix the size of custom widget
   */
  patchWidgetsOption() {
    this.sectionsForm.controls.forEach((section) => {
      const widgets = section.get('widgets') as FormArray;
      widgets.controls.forEach((widget) => {
        const w = this.optionData.widgetsSrc.find((ws) => ws.id === widget.value.widgetId);
        if (w) {
          widget.patchValue({
            maxItemArea: w.maxItemArea,
            minItemArea: w.minItemArea,
            maxItemCols: w.maxItemCols,
            minItemCols: w.minItemCols,
            maxItemRows: w.maxItemRows,
            minItemRows: w.minItemRows,
          });
        }
      });
    });
  }
}
