import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DeviceModelFormService } from '../services/device-model-form.service';

@Component({
  selector: 'sc-property-value-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './property-value-form.component.html',
  styleUrls: ['./property-value-form.component.scss'],
})
export class PropertyValueFormComponent implements OnInit, OnChanges {
  @Input()
  form: FormGroup;

  @Input()
  selectItems: { [key: string]: SelectItem[] } = {};

  @Input()
  selectedProtocol: string;

  @Input()
  selectedLibrary: string;

  @Input()
  sourceData: { [key: string]: any } = {};

  @Input()
  mappingValues: any[] = [];

  @Input()
  libraries: any[] = [];

  valuesForm: FormArray;

  constructor(private deviceModelFormService: DeviceModelFormService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue) {
      this.updateValuesForm();
    }
  }

  ngOnInit(): void {}

  private updateValuesForm() {
    this.valuesForm = this.form.get('values') as FormArray;
  }

  addValue() {
    const form = this.deviceModelFormService.initPropertyValueForm(this.selectedProtocol, this.selectedLibrary);
    this.valuesForm.push(form);
  }

  removeValue(index: number) {
    this.valuesForm.removeAt(index);
  }
}
