import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { VariableType, VariableTypeLabel } from '../enums';
import { UserTaskTemplate, UserTaskTemplateTemplate, UserTaskTemplateVariable } from '../interfaces';
import { UserTaskTemplatesService, UserTaskTemplateFormService } from '../services';
import { SharedService } from '@shared/shared.service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-user-task-template-form',
  templateUrl: './user-task-template-form.component.html',
  styleUrls: ['./user-task-template-form.component.scss'],
})
export class UserTaskTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  currentTemplateForm: any;
  currentTemplateIndex: any;
  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: UserTaskTemplate;
  locales: SelectItem[] = [];
  isSubmitting = false;
  templatesForm: FormArray;
  variablesForm: FormArray;
  variableTypes: SelectItem[] = [];

  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private taskTemplatesService: UserTaskTemplatesService,
    private taskTemplateFormService: UserTaskTemplateFormService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchTaskTemplate(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchTaskTemplate(id: number) {
    this.fetchingState++;
    this.subscribers.fetchTaskTemplate = this.taskTemplatesService.getUserTaskTemplate(id).subscribe((result: any) => {
      this.formData = result.data;
      this.formData.templates = this.sharedService.tryParseJSON(this.formData.templates as string);
      this.formData.variables = this.sharedService.tryParseJSON(this.formData.variables as string);
      this.formData = this.formValueUnescape(this.formData);

      this.form.patchValue(this.formData);

      this.taskTemplateFormService.patchVariablesFormArray(
        this.form,
        this.formData.variables as UserTaskTemplateVariable[]
      );

      this.taskTemplateFormService.patchTemplatesFormArray(
        this.form,
        this.formData.templates as UserTaskTemplateTemplate[]
      );

      this.selectTemplate(0);

      this.fetchingState--;
    });
  }

  private initForm() {
    this.locales = this.sharedService.selectItems.locales;

    this.variableTypes = [
      { label: VariableTypeLabel.Device, value: VariableType.Device },
      { label: VariableTypeLabel.Location, value: VariableType.Location },
    ];
    this.form = this.taskTemplateFormService.initUserTaskTemplateFormGroup();
    this.variablesForm = this.form.get('variables') as FormArray;
    this.templatesForm = this.form.get('templates') as FormArray;

    if (!this.editMode) {
      this.addTemplate('en');
      this.currentTemplateForm = this.templatesForm.controls[0];
      this.currentTemplateIndex = 0;
    }
  }

  getFlag(locale) {
    return this.sharedService.getFlagClassName(locale);
  }

  private formValueEscape(formData) {
    if (formData.templates && formData.templates.length) {
      formData.templates = formData.templates.map((t) => {
        t.subject = this.sharedService.stringEscape(t.subject);
        t.message = this.sharedService.stringEscape(t.message);
        return t;
      });
    }
    return formData;
  }

  private formValueUnescape(formData) {
    if (formData.templates && formData.templates.length) {
      formData.templates = formData.templates.map((t) => {
        t.subject = this.sharedService.stringUnescape(t.subject);
        t.message = this.sharedService.stringUnescape(t.message);
        return t;
      });
    }
    return formData;
  }

  // API CALL
  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    let formData = this.form.value;
    formData = this.formValueEscape(formData);

    // call api
    if (this.editMode) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  }

  private create(formData) {
    this.subscribers.create = this.taskTemplatesService.createUserTaskTemplate(formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(formData) {
    this.subscribers.update = this.taskTemplatesService.updateUserTaskTemplate(this.formData.id, formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.taskTemplatesService.deleteUserTaskTemplate(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('USER_TASK_TEMPLATE');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  addVariable() {
    const form = this.taskTemplateFormService.initVariableFormGroup();
    this.variablesForm.push(form);
  }

  removeVariable(index: number) {
    this.variablesForm.removeAt(index);
  }

  addTemplate(locale?: string) {
    const form = this.taskTemplateFormService.initTemplateFormGroup();
    if (locale) {
      form.patchValue({ locale });
    }
    this.templatesForm.push(form);
    let lastIndex = this.templatesForm && this.templatesForm.controls && this.templatesForm.controls.length;
    if (lastIndex > 0) {
      lastIndex = lastIndex - 1;
      this.currentTemplateForm = this.templatesForm.controls[lastIndex];
      this.currentTemplateIndex = lastIndex;
    }
  }

  removeTemplate() {
    this.templatesForm.removeAt(this.currentTemplateIndex);
    if (this.templatesForm && this.templatesForm.controls && this.templatesForm.controls.length) {
      this.currentTemplateForm = this.templatesForm.controls[0];
      this.currentTemplateIndex = 0;
    } else {
      this.currentTemplateForm = null;
      this.currentTemplateIndex = null;
    }
  }

  selectTemplate(index) {
    if (this.templatesForm && this.templatesForm.controls && this.templatesForm.controls[index]) {
      this.currentTemplateIndex = index;
      this.currentTemplateForm = this.templatesForm.controls[index];
    }
  }
}
