import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { FilesComponent } from './files.component';

@NgModule({
  imports: [SharedModule],
  exports: [FilesComponent],
  declarations: [FilesComponent],
  entryComponents: [FilesComponent],
  providers: []
})
export class FilesModule {}
