export enum LocationShapes {
  Draw = 0,
  Rectangle,
  Irregular,
  Trapezoid,
  Parallelogram,
  Hexagon,
  Triangle,
  Lshape,
  Tshape,
  Irregular2,
}
