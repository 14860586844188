import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { config } from '@app/config';

import { SelectItem } from 'primeng/api';
import { LocationProperty } from '../models/location-property';

import { DeviceTypesService } from '@app/widgets/device-types/services/device-types.service';
import { LocationPropertiesService } from '../services/location-properties.service';
import { LocationPropertyFormsService } from '../services/location-property-forms.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-property-form',
  templateUrl: 'location-property-form.component.html'
})
export class LocationPropertyFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: LocationProperty;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  isSubmitting: boolean;
  subscribers: { [key: string]: any };
  fetchingState: number;

  selectItems: { [key: string]: SelectItem[] };

  constructor(
    private deviceTypesService: DeviceTypesService,
    private locationPropertiesService: LocationPropertiesService,
    private locationPropertyFormsService: LocationPropertyFormsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.subscribers = {};
    this.fetchingState = 0;
    this.selectItems = {};
    this.selectItems.operators = config().selectItems.operators;
    this.selectItems.propertyValueTypes = config().selectItems.locationPropertyValueTypes;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchLocationProperty(this.data.id);
    }

    this.fetchDeviceTypes();
    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchLocationProperty(id: number) {
    this.fetchingState++;
    this.subscribers.GET_LOCATION_PROPS = this.locationPropertiesService
      .getLocationProperty(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        if (result.data.operators) {
          this.formData.operators = result.data.operators.split(',');
        }
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_TYPES = this.deviceTypesService
      .getDeviceTypes()
      .subscribe((result: any) => {
        this.selectItems.deviceTypes = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted),
          false,
          'key'
        );
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.locationPropertyFormsService.initLocationPropertyForm();

    // watch "type" value
    this.form.controls['type'].valueChanges.subscribe(result => {
      // get allow operators
      this.selectItems.operators = this.getAllowOperators(result);
      // update selected operators
      this.form.controls['operators'].setValue(
        this.selectItems.operators.map(o => o.value)
      );
    });
  }

  getAllowOperators(type: string): SelectItem[] {
    switch (type) {
      case 'boolean':
      // return config().selectItems.operators.filter(o => o.value === '==');
      case 'deviceType':
        return config().selectItems.operators.filter(
          o => o.value === '==' || o.value === '!='
        );
      case 'number':
      default:
        return config().selectItems.operators;
    }
  }

  get locationPropType() {
    return this.form.get('type').value;
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.form.value;
      if (formData.operators) {
        formData.operators = formData.operators.join();
      }

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(formData) {
    this.locationPropertiesService
      .createLocationProperty(formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update(formData) {
    this.locationPropertiesService
      .updateLocationProperty(this.formData.id, formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.locationPropertiesService
      .deleteLocationProperty(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify(
      'Location Property',
      result.message + ' Success!',
      'success'
    );
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
