import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { Device } from '@widgets/devices/models/device';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';

import { DeviceModelsService } from '@widgets/device-models/services/device-models.service';
import { DeviceHistoryLogsService } from '@widgets/devices/services/device-history-logs.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { SCTableService } from '@sc/table/table.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { DeviceLiveDataCellComponent } from '@widgets/devices/device-live-data-cell/device-live-data-cell.component';
import { DeviceActionCellComponent } from '@widgets/devices/device-action-cell/device-action-cell.component';

@Component({
  selector: 'sc-location-devices-table',
  templateUrl: './location-devices-table.component.html',
})
export class LocationDevicesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: Device[];
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string;

  isFetchingDataset: boolean;
  isFetchingLatestHistory: boolean;
  fetchLatestHistoryInterval: any;

  private deviceList: Device[];
  private deviceModels: DeviceModel[];
  private deviceTypes: DeviceType[];
  selectedDevices: Device[];
  selectedLocation: Location;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  userVariant: string;

  constructor(
    private store: Store<fromStore.State>,
    private devicesService: DevicesService,
    private deviceModelsService: DeviceModelsService,
    private deviceTypesService: DeviceTypesService,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private tableService: SCTableService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.userVariant = this.sharedService.userVariant;
    this.selectedSite = this.sharedService.selectedSite;

    // get location details
    this.subscribers.locationDetail = this.store.pipe(select(fromStore.getLocationDetials)).subscribe((result) => {
      if (result) {
        this.selectedLocation = result;
        if (this.userVariant !== 'ceos') {
          this.fetchDataset();
        }
      }
    });

    if (this.userVariant === 'ceos') {
      this.config.customOptions = { ...this.config.customOptions, treeExpand: true, treeView: true };
    } else {
      // Set Table Name
      this.tableTitle = 'DEVICES';
      // Set Table Columns
      this.columns = this.createColumns();

      this.fetchDeviceTypes();
      this.fetchDeviceModels();
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.stopAutoUpdate();
  }

  fetchDeviceTypes() {
    this.subscribers.getDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data;

      if (this.table && this.table.api && !this.isFetchingDataset) {
        this.table.api.refreshCells({ columns: ['action'], force: true });
      }
    });
  }

  fetchDeviceModels() {
    this.subscribers.getDeviceModels = this.deviceModelsService.getDeviceModels().subscribe((result: any) => {
      this.deviceModels = result.data;

      if (this.table && this.table.api && !this.isFetchingDataset) {
        this.table.api.refreshCells({ columns: ['action'], force: true });
      }
    });
  }

  fetchDataset() {
    if (this.isFetchingDataset) {
      return;
    }
    this.isFetchingDataset = true;
    this.stopAutoUpdate();
    if (this.table && this.table.api) {
      this.table.api.showLoadingOverlay();
    }

    const options: any = {
      siteId: this.selectedSite.id,
      locationId: this.selectedLocation.id,
      columns: [
        'id',
        'avcCalcWeight',
        'apiKey',
        'automationId',
        'gatewayId',
        'deviceTypeId',
        'deviceModelId',
        'deviceTypeKey',
        'locationId',
        'isControllableByUser',
        'isActive',
        'isDeleted',
      ],
      // minimize: true
    };

    // CUSOTM VISIBLE COLUMNS
    if (
      this.config &&
      this.config.customOptions &&
      this.config.customOptions.visibleColumns &&
      this.config.customOptions.visibleColumns.length
    ) {
      options.columns = [...options.columns, ...this.config.customOptions.visibleColumns];
    } else {
      options.columns = [...options.columns, 'description'];
    }

    // CONVERT ARRAY INTO STRING
    options.columns = options.columns.join();

    this.subscribers.getDevices = this.devicesService.getDevices(options).subscribe((result: any) => {
      if (result.mappingKeys) {
        this.dataset = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);
      } else {
        this.dataset = result.data;
      }
      this.getDevicesRecursive(this.dataset);
      this.isFetchingDataset = false;
      if (this.table && this.table.api) {
        this.table.api.hideOverlay();
      }
      this.startAutoUpdate();
    });
  }

  fetchLatestHistory() {
    if (!this.selectedLocation || !this.selectedLocation.id || !this.selectedSite || !this.selectedSite.id) {
      return;
    }

    if (!this.isFetchingLatestHistory) {
      this.isFetchingLatestHistory = true;

      const option = {
        filter: {
          siteId: this.selectedSite.id,
          locationId: this.selectedLocation.id,
        },
        latestLog: true,
        limit: 5000,
      };

      this.subscribers.latestHistory = this.deviceHistoryLogsService.getHistoryLogs(option).subscribe((result: any) => {
        this.isFetchingLatestHistory = false;
        this.updateLatestHistory(result);
      });
    }
  }

  private updateLatestHistory(latestHistory: any) {
    if (latestHistory && latestHistory.length) {
      const itemsToUpdate = latestHistory.map((history) => ({
        id: +history.deviceId,
        latestLogValue: history.value,
        latestLogValueLoggedAt: history.createdAt,
      }));
      this.tableService.updateTableRow(this.table, itemsToUpdate);
    }
  }

  updateLiveStatus(liveData: any) {
    if (liveData && liveData.data && liveData.data.length) {
      const itemsToUpdate = liveData.data.map((ldata) => ({
        id: +ldata.deviceId,
        liveStatus: ldata.value,
        liveStatusLoggedAt: liveData.updatedAt,
      }));
      this.tableService.updateTableRowV2(this.table, itemsToUpdate);
    }
  }

  afterInitTable(table: GridOptions) {
    this.table = table;

    this.table.onSelectionChanged = () => {
      this.selectedDevices = this.table.api.getSelectedNodes().map((row) => row.data.id);
    };
    this.table.context.containerComponent = this;
  }

  private startAutoUpdate() {
    this.stopAutoUpdate();
    this.fetchLatestHistory();
    this.fetchLatestHistoryInterval = setInterval(() => this.fetchLatestHistory(), 10000);
  }

  private stopAutoUpdate() {
    clearInterval(this.fetchLatestHistoryInterval);
    this.fetchLatestHistoryInterval = null;
  }

  private getDevicesRecursive(dataset: any) {
    if (dataset && dataset.length) {
      this.deviceList = [];

      dataset.forEach((device) => {
        if (!device.isDeleted && device.deviceTypeKey !== 'a' && device.deviceTypeKey !== 'g') {
          this.deviceList.push(device);
        }

        if (device.children && device.children.length) {
          this.getDevicesRecursive(device.children);
        }
      });
    }
  }

  get deviceComparisonModalConfig() {
    return {
      name: 'DeviceLogsComparison',
      data: {
        devices: this.deviceList,
        selected: this.selectedDevices,
        deviceTypes: this.deviceTypes,
        deviceModels: this.deviceModels,
      },
      options: {
        modalTitle: 'DEVICE_LOGS_COMPARISON_TITLE',
        fullScreen: true,
      },
    };
  }

  private getDeviceTypeName(params) {
    if (this.deviceTypes && params && params.data && params.data.deviceTypeId) {
      const deviceType = this.deviceTypes.find((d) => d.id === params.data.deviceTypeId);
      return deviceType ? deviceType.name : '';
    }
    return '';
  }

  private createColumns() {
    return [
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
        cellRenderer: 'agGroupCellRenderer',
        checkboxSelection: (params) => {
          return (
            typeof params.data !== 'undefined' &&
            !params.data.isDeleted &&
            params.data.deviceTypeKey !== 'a' &&
            params.data.deviceTypeKey !== 'g'
          );
        },
      },
      {
        colId: 'deviceType',
        headerName: 'DEVICE_TYPE',
        valueFormatter: this.getDeviceTypeName.bind(this),
        valueGetter: this.getDeviceTypeName.bind(this),
      },
      {
        colId: 'latestLogValue',
        headerName: 'LATEST_LOG_VALUE',
        field: 'latestLogValue',
        cellRendererFramework: DeviceLiveDataCellComponent,
      },
      {
        colId: 'liveStatus',
        headerName: 'LIVE_STATUS',
        field: 'liveStatus',
        cellRendererFramework: DeviceLiveDataCellComponent,
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        filterParams: { newRowsAction: 'keep' },
        suppressFilter: true,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        filterParams: { newRowsAction: 'keep' },
        suppressFilter: true,
        hide: true,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceActionCellComponent,
      },
    ];
  }
}
