import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { IGuestRequestCategory } from '../guest-request-category.interface';
import { SelectItem } from 'primeng/api';

import { GuestRequestCategoriesService } from '../guest-request-categories.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-guest-request-category-form',
  templateUrl: './guest-request-category-form.component.html',
  styleUrls: ['./guest-request-category-form.component.scss']
})
export class GuestRequestCategoryFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  currentTitleTemplate = 0;
  currentDescriptionTemplate = 0;
  editMode: boolean;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: IGuestRequestCategory;
  isSubmitting: boolean;
  optionData: { [key: string]: SelectItem[] };

  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private guestRequestCategoriesService: GuestRequestCategoriesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.optionData = {
      locales: this.sharedService.selectItems.locales
    };

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchGuestRequestCategory(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchGuestRequestCategory(id: number) {
    this.fetchingState++;
    this.subscribers.getGuestRequestCategory = this.guestRequestCategoriesService
      .get(id)
      .subscribe((result: any) => {
        this.formData = result.data;

        this.formData.description = this.formData.description
          ? this.sharedService.jsonParse(this.formData.description)
          : [];
        this.formData.title = this.formData.title
          ? this.sharedService.jsonParse(this.formData.title)
          : [];

        this.form.patchValue(this.formData);

        this.guestRequestCategoriesService.setGuestRequestCategoryMultiLanguageFormArray(
          this.form,
          this.formData.description,
          'description'
        );
        this.guestRequestCategoriesService.setGuestRequestCategoryMultiLanguageFormArray(
          this.form,
          this.formData.title,
          'title'
        );
        this.fetchingState--;
      });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: { value: null, disabled: true },
      iconImagePath: null,
      description: this.formBuilder.array([]),
      title: this.formBuilder.array([]),
      isActive: false,
      isDeleted: false
    });

    if (!this.editMode) {
      this.addMultiLanguage('title');
      this.addMultiLanguage('description');
    }
  }

  flagIcon(locale) {
    if (locale) {
      locale = locale === 'en' ? 'gb' : locale;
      return 'flag-icon flag-icon-' + locale;
    }
    return '';
  }

  addMultiLanguage(controlName: string) {
    const control =  this.form.controls[controlName] as FormArray;
    control.push(
      this.guestRequestCategoriesService.initGuestRequestCategoryMultiLanguageFormGroup()
    );
    this.changeCurrentTemplate(controlName, control.length - 1);
  }

  removeMultiLanguage(controlName: string, i: number) {
    const control =  this.form.controls[controlName] as FormArray;
    control.removeAt(i);
    this.changeCurrentTemplate(controlName, 0);
  }

  get title(): FormArray {
    return this.form.get('title') as FormArray;
  }

  get description(): FormArray {
    return this.form.get('description') as FormArray;
  }

  private changeCurrentTemplate(name: string, index: number) {
    switch (name) {
      case 'title':
        this.currentTitleTemplate = index;
        break;

      case 'description':
        this.currentDescriptionTemplate = index;
        break;

      default:
        break;
    }
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.form.value;

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(formData) {
    delete formData.id;
    this.subscribers.create = this.guestRequestCategoriesService
      .create(formData)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'CREATE_FAIL')
      });
  }

  private update(formData) {
    this.subscribers.update = this.guestRequestCategoriesService
      .update(this.formData.id, formData)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'UPDATE_FAIL')
      });
  }

  delete() {
    this.subscribers.delete = this.guestRequestCategoriesService
      .delete(this.formData.id)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        error: this.apiCallError.bind(this, 'DELETE_FAIL')
      });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('GUEST_REQUEST_CATEGORY');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
