<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <div class="ui-g">
        <!-- BENCHMARK_TEMPLATE FIELD -->
        <div class="ui-g-4">
          <sc-dropdown
            controlName="benchmarkTemplateId"
            [form]="form"
            label="{{ 'BENCHMARK_TEMPLATE' | translate }}"
            [options]="selectItems['benchmarkTemplates']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <div class="ui-g-8">
          <sc-quick-date-select
            [showOnlyDate]="true"
            (valueChange)="onSelectDate($event)"
          ></sc-quick-date-select>
        </div>

        <!-- Error Message -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>

        <div [ngSwitch]="showResult" class="ui-g-12">
          <div *ngSwitchCase="1">
            <ag-grid-angular
              class="ag-theme-sc-clean"
              [gridOptions]="gridOptions"
              style="width: 100%; height: 100%"
            ></ag-grid-angular>
          </div>

          <div *ngSwitchCase="-1">
            <sc-form-error message="{{ 'BENCHMARK_NO_RESULT_FOUND' | translate }}"></sc-form-error>
          </div>
        </div>
      </div>

      <p-footer>
        <div class="ui-g-6"></div>

        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            label="{{ 'CANCEL' | translate }}"
            (click)="dismissModal('cancel')"
          ></button>

          <sc-submit-button
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
