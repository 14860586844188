<div class="report-variable" [formGroup]="control">
  <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
    <label [for]="'input-' + key" class="p-col-12 p-md-4">
      <!-- {{ control?.value?.variable_name }}: -->
      {{ control?.value?.display_name }}:
    </label>

    <div class="p-col-12 p-md-8">
      <ng-container [ngSwitch]="control?.value?.type">
        <ng-container *ngSwitchCase="'d'">
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="field-wrapper">
              <sc-tree-select
                [form]="control"
                field="value"
                [options]="dataSources?.devicesTree"
                [placeholder]="'CCP_COMMON_SELECT' | translate"
              ></sc-tree-select>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'l'">
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="field-wrapper">
              <p-dropdown
                [inputId]="'input-' + key"
                [options]="selectItems?.locations"
                formControlName="value"
                [placeholder]="'CCP_COMMON_SELECT' | translate"
                [filter]="true"
                appendTo="body"
              ></p-dropdown>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
