import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ColDef } from 'ag-grid';
import { AppStyle } from '../models/app-style';
import { ModalConfig } from '@sc/modal/modal-config';

import { AppStylesService } from '../services/app-styles.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';
import { AppStyleActionCellComponent } from '../app-style-action-cell/app-style-action-cell.component';

@Component({
  selector: 'sc-app-styles-table',
  templateUrl: './app-styles-table.component.html'
})
export class AppStylesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: AppStyle;
  formConfig: ModalConfig;
  tableTitle = 'APP_STYLES';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private appStylesService: AppStylesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'AppStyleForm',
      options: { modalTitle: 'APP_STYLE_FORM_TITLE', fullScreen: true }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.dataset = this.appStylesService
      .getAppStyles()
      .subscribe({
        next: (result: any) => {
          this.dataset = result.data;
        }
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        suppressSorting: true,
        suppressFilter: true,
        pinned: 'right',
        cellRendererFramework: AppStyleActionCellComponent
      }
    ];
  }
}
