import { Component, OnInit, OnDestroy, Input } from '@angular/core';

// import { GridOptions } from 'ag-grid';
import { Notification } from '../notification';
import { NotificationsService } from '../notifications.service';
import { SharedService } from '@shared/shared.service';
// import { SCTableService } from '@sc/table/table.service';

import { NotificationActionCellComponent } from '../notification-action-cell/notification-action-cell.component';
import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';

import * as moment from 'moment';

@Component({
  selector: 'sc-notifications-table',
  templateUrl: 'notifications-table.component.html',
})
export class NotificationsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;
  userId: number;

  dataset: Notification[];
  columns: any[];
  fetching: { [key: string]: any };
  // formConfig: {
  //   name: string;
  //   data?: { [key: string]: any };
  //   options?: { [key: string]: any };
  // };
  tableTitle: string;

  constructor(
    private notificationsService: NotificationsService,
    private sharedService: SharedService // private tableService: SCTableService
  ) {}

  ngOnInit() {
    // ____ GET USERNAME
    this.userId = this.sharedService.userId;
    this.columns = [
      {
        colId: 'subject',
        headerName: 'SUBJECT',
        field: 'subject',
        width: 300,
      },
      {
        colId: 'text',
        headerName: 'TEXT',
        field: 'text',
        width: 300,
      },
      {
        colId: 'priority',
        headerName: 'PRIORITY',
        field: 'priority',
        width: 120,
      },
      {
        colId: 'markSolved',
        headerName: 'MARK_SOLVED',
        valueFormatter: this.solvedFormat,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'markReason',
        headerName: 'MARK_REASON',
        field: 'unsolvableReason',
      },
      {
        colId: 'markAt',
        headerName: 'MARK_AT',
        valueFormatter: this.solvedAtFormat,
      },
      {
        colId: 'since',
        headerName: 'SINCE',
        field: 'createdAt',
        width: 150,
        valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      },
      {
        colId: 'readAt',
        headerName: 'READ',
        field: 'readAt',
        width: 150,
        valueFormatter: (params) => this.sharedService.dateFormat(params.value),
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: NotificationActionCellComponent,
      },
    ];
    // this.formConfig = {
    //   name: 'AddSectionForm',
    //   options: { modalTitle: 'AddSection' }
    // };
    this.tableTitle = 'NOTIFICATION';
    this.fetching = {};
    this.fetchDataset();
  }

  ngOnDestroy() {
    for (const key in this.fetching) {
      if (this.fetching.hasOwnProperty(key) && this.fetching[key]) {
        this.fetching[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.fetching.DATASET = this.notificationsService.get(false).subscribe((result: any) => {
      this.dataset = result;
    });
  }

  solvedFormat(params) {
    if (params.data.unsolvableAt) {
      return false;
    } else if (params.data.solvedAt) {
      return true;
    } else {
      return;
    }
  }

  solvedAtFormat(params) {
    if (params.data.unsolvableAt) {
      return moment(params.data.unsolvableAt).fromNow();
    } else if (params.data.solvedAt) {
      return moment(params.data.solvedAt).fromNow();
    } else {
      return;
    }
  }
}
