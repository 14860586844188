import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { NotificationTemplateActionCellComponent } from './notification-template-action-cell/notification-template-action-cell.component';
import { NotificationTemplateFormComponent } from './notification-template-form/notification-template-form.component';
import { EmailFormGroupComponent } from './notification-template-form/email-form-group/email-form-group.component';
import { GeneralFormGroupComponent } from './notification-template-form/general-form-group/general-form-group.component';
import { SmsFormGroupComponent } from './notification-template-form/sms-form-group/sms-form-group.component';
import { NotificationTemplatesTableComponent } from './notification-templates-table/notification-templates-table.component';

import { NotificationTemplateFormService } from './services/notification-template-form.service';
import { NotificationTemplatesService } from './services/notification-templates.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    NotificationTemplateActionCellComponent,
    NotificationTemplateFormComponent,
    EmailFormGroupComponent,
    GeneralFormGroupComponent,
    SmsFormGroupComponent,
    NotificationTemplatesTableComponent,
  ],
  entryComponents: [
    NotificationTemplateActionCellComponent,
    NotificationTemplateFormComponent,
    NotificationTemplatesTableComponent,
  ],
  providers: [NotificationTemplateFormService, NotificationTemplatesService],
})
export class NotificationTemplatesModule {}
