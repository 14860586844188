import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CeosRuleEditorModule } from '@app/ceos-rule-editor/ceos-rule-editor.module';

// COMPONENTS
import { LocationRuleActionCellComponent } from './location-rule-action-cell/location-rule-action-cell.component';
import { LocationRulesTableComponent } from './location-rules-table/location-rules-table.component';
import { LocationRHLTableComponent } from './location-rhl-table/location-rhl-table.component';
import { RuleFormComponent } from './rule-form/rule-form.component';
import { ConditionFormComponent } from './rule-form/condition-form/condition-form.component';
import { CommandFormComponent } from './rule-form/command-form/command-form.component';
import { CommandParameterFormComponent } from './rule-form/parameter-form/parameter-form.component';
import { DisabledRulesComponent } from './disabled-rules/disabled-rules.component';
import { EnabledRulesComponent } from './enabled-rules/enabled-rules.component';

import { AutomationRuleActionCellComponent } from './automation-rule-action-cell/automation-rule-action-cell.component';
import { AutomationRulesTableComponent } from './automation-rules-table/automation-rules-table.component';
import { AutomationRTHLTableComponent } from './automation-rthl-table/automation-rthl-table.component';
import { CeosRuleFormComponent } from './ceos-rule-form/ceos-rule-form.component';

// SERVICES
import { RuleFormsService } from './services/rule-forms.service';
import { RuleHistoryLogsService } from './services/rule-history-logs.service';
import { RulesService } from './services/rules.service';
import { CeosRulesService } from './services/ceos-rules.service';
import { CeosRuleHistoryLogsService } from './services/ceos-rule-history-logs.service';

@NgModule({
  imports: [SharedModule, CeosRuleEditorModule],
  exports: [
    RuleFormComponent,
    LocationRHLTableComponent,
    LocationRulesTableComponent,
    CeosRuleFormComponent,
    AutomationRulesTableComponent,
  ],
  declarations: [
    LocationRuleActionCellComponent,
    LocationRulesTableComponent,
    LocationRHLTableComponent,
    RuleFormComponent,
    ConditionFormComponent,
    CommandFormComponent,
    CommandParameterFormComponent,
    DisabledRulesComponent,
    EnabledRulesComponent,
    CeosRuleFormComponent,
    AutomationRuleActionCellComponent,
    AutomationRulesTableComponent,
    AutomationRTHLTableComponent,
  ],
  entryComponents: [
    LocationRuleActionCellComponent,
    LocationRulesTableComponent,
    LocationRHLTableComponent,
    RuleFormComponent,
    DisabledRulesComponent,
    EnabledRulesComponent,
    CeosRuleFormComponent,
    AutomationRuleActionCellComponent,
    AutomationRulesTableComponent,
    AutomationRTHLTableComponent,
  ],
  providers: [RuleFormsService, RuleHistoryLogsService, RulesService, CeosRulesService, CeosRuleHistoryLogsService],
})
export class RulesModule {}
