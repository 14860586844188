import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../reducers/auth.reducer';

export const getAuthState = createFeatureSelector<fromAuth.AuthState>('auth');

export const getAuthError = createSelector(getAuthState, fromAuth.getAuthError);

export const getAuthToken = createSelector(getAuthState, fromAuth.getAuthToken);

export const getAuthVerified = createSelector(getAuthState, fromAuth.getAuthVerified);

export const getAuthVerifying = createSelector(getAuthState, fromAuth.getAuthVerifying);

export const getAuthTwoFactorAuthMethod = createSelector(getAuthState, fromAuth.getAuthTwoFactorAuthMethod);

export const getAuthTwoFactorAuthVerified = createSelector(getAuthState, fromAuth.getAuthTwoFactorAuthVerified);
