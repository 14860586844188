import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { LocationGenerator } from '../models/location-generator.model';
import { LocationTemplate } from '@widgets/location-templates/models/location-template';
import { Rule } from '@app/widgets/rules/models/rule';

import { RuleFormsService } from '@widgets/rules/services/rule-forms.service';

import { LocationGeneratorService } from '../services/location-generator.service';
import { SharedService } from '@shared/shared.service';

interface LocationGeneratorConfig {
  locationTemplateId: number;
  amount: number;
  startIndex: number;
  prefix?: string;
  globalAutomation?: number;
  globalGateway?: number;
  parentId?: number;
}

@Component({
  selector: 'sc-location-generator-form',
  templateUrl: './location-generator-form.component.html',
  styleUrls: ['./location-generator-form.component.scss']
})
export class LocationGeneratorFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  isSubmitting = false;
  rawData: { [key: string]: any[] } = {};
  selectItems: { [key: string]: SelectItem[] } = {};
  // locationsToCreate: LocationGenerator[];
  // selectedTemplate: LocationTemplate;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private locationGeneratorService: LocationGeneratorService,
    private ruleFormsService: RuleFormsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchInitalData();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  fetchInitalData() {
    this.fetchingState++;

    const options = {
      siteId: this.sharedService.selectedSite.id
    };

    this.subscribers.GET_INITAL_DATA = this.locationGeneratorService
      .initial(options)
      .subscribe((result: any) => {
        this.rawData = { ...result.data };
        // console.log('rawData:', this.rawData);

        for (const key in result.data) {
          if (result.data.hasOwnProperty(key)) {
            if (key === 'devices') {
              // Gateways
              this.selectItems.gateways = this.sharedService.createSelectItems(
                result.data[key].filter(d => d.deviceTypeKey === 'g'),
                false
              );
              // Automations
              this.selectItems.automations = this.sharedService.createSelectItems(
                result.data[key].filter(d => d.deviceTypeKey === 'a'),
                false
              );
            } else if (key === 'locationProperties') {
              this.selectItems[key] = this.sharedService.createSelectItems(
                result.data[key],
                false,
                'key'
              );
            } else {
              this.selectItems[key] = this.sharedService.createSelectItems(
                result.data[key],
                key === 'locations' ? true : false
              );
            }
          }
        }
        // console.log('selectItems:', this.selectItems);

        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      amount: [1, Validators.required],
      locationTemplateId: [null, Validators.required],
      locations: this.formBuilder.array([]),
      parentId: null,
      prefix: null,
      startIndex: [1, Validators.required]
    });

    this.subscribers.WATCH_PARENT_ID = this.form
      .get('parentId')
      .valueChanges.subscribe(value => {
        this.updateLocations({ parentId: value });
      });
  }

  get locationForms(): FormArray {
    return this.form.get('locations') as FormArray;
  }

  // get dataPassThrough() {
  //   this.selectItems.booleanOption = this.sharedService.selectItems.booleanOption;
  //   return {
  //     rawData: this.rawData,
  //     selectItems: this.selectItems,
  //     isParent: true
  //   };
  // }
  getDataPassThrough(index: number) {
    this.selectItems.booleanOption = this.sharedService.selectItems.booleanOption;
    return {
      rawData: this.rawData,
      selectItems: this.selectItems,
      isFistLocation: index === 0 ? true : false
    };
  }

  private generateLocations(
    template: LocationTemplate,
    config: LocationGeneratorConfig
  ) {
    // Loop Locations and create Lcoation FormGroups
    const locationForms = [];
    for (let i = 0; i < config.amount; i++) {
      const locationId = 'location_0';
      // const locationName =
      //   (config.prefix || '') + template.description + (config.startIndex + i);
      const prefix = config.prefix || '';
      const locationName =
        prefix + template.description + (config.startIndex + i);
      const locationForm = this.formBuilder.group({
        tmpId: locationId,
        description: locationName,
        isActive: template.isActive,
        locationTypeId: template.locationTypeId,
        parentId: config.parentId || null,
        siteId: this.sharedService.selectedSite.id,
        devices: this.generateDevices(
          template.devices,
          config,
          locationId,
          // locationName
          prefix
        ),
        rules: this.generateRules(
          template.ruleTemplates,
          config,
          locationId,
          // locationName
          prefix
        )
      });

      // create child locations
      if (template.children && template.children.length) {
        this.generateChildren(
          template.children,
          config,
          prefix,
          locationId,
          locationForm
        );
      }

      locationForms.push(locationForm);
    }

    // set locations form data
    this.form.setControl('locations', this.formBuilder.array(locationForms));
  }

  private generateChildren(
    locations: any[],
    config: LocationGeneratorConfig,
    prefix: string,
    parentLocationId: string,
    parentLocationForm: FormGroup
  ) {
    // Loop Locations and create Lcoation FormGroups
    const locationForms = [];
    for (let i = 0; i < locations.length; i++) {
      const locationId = parentLocationId + '_' + i;
      // const locationName = (config.prefix || '') + (config.startIndex + i);
      const locationName = prefix + locations[i].description;
      const locationForm = this.formBuilder.group({
        tmpId: locationId,
        description: locationName,
        isActive: locations[i].isActive,
        locationTypeId: locations[i].locationTypeId,
        parentId: parentLocationId,
        siteId: this.sharedService.selectedSite.id,
        devices: this.generateDevices(
          locations[i].devices,
          config,
          locationId,
          // locations[i].description
          prefix
        ),
        rules: this.generateRules(
          locations[i].ruleTemplates,
          config,
          locationId,
          // locations[i].description
          prefix
        )
      });

      // create child locations
      if (locations[i].children && locations[i].children.length) {
        this.generateChildren(
          locations[i].children,
          config,
          prefix,
          locationId,
          locationForm
        );
      }

      locationForms.push(locationForm);
    }

    // set location children form data
    parentLocationForm.addControl(
      'children',
      this.formBuilder.array(locationForms)
    );
  }

  private generateDevices(
    devices: any[],
    config: LocationGeneratorConfig,
    locationTmpId: string,
    prefix: string
  ): FormArray {
    // Loop Devices and create Device FormGroups
    const formGroups = [];
    for (let i = 0; i < devices.length; i++) {
      const deviceForm = this.formBuilder.group({
        ...devices[i],
        description: prefix + devices[i].description,
        gatewayId: config.globalGateway || null,
        automationId: config.globalAutomation || null,
        tmpId: 'device_' + i,
        locationId: locationTmpId
      });
      formGroups.push(deviceForm);
    }

    // Devices FormArray
    return this.formBuilder.array(formGroups);
  }

  private generateRules(
    ruleTemplates: any[],
    config: LocationGeneratorConfig,
    locationTmpId: string,
    prefix: string
  ): FormArray {
    // Loop RuleTemplates and create Rule FormGroups
    const formGroups = [];
    for (let i = 0; i < ruleTemplates.length; i++) {
      const ruleTemplate = this.rawData.ruleTemplates.find(
        r => r.id === ruleTemplates[i].ruleTemplateId
      );

      if (!ruleTemplate) {
        continue;
      }

      // create rule form
      const ruleForm = this.ruleFormsService.initRuleForm();
      // patch rule template into rule form
      this.ruleFormsService.patchValueFromTemplate(ruleForm, {
        ...ruleTemplate,
        name: prefix + ruleTemplate.name
      });
      // patch rule required value
      ruleForm.patchValue({
        isActive: ruleTemplates[i].isActive,
        ruleTemplateId: ruleTemplates[i].ruleTemplateId,
        locationId: locationTmpId,
        siteId: this.sharedService.selectedSite.id,
        companyId: this.sharedService.selectedSite.companyId
      });
      formGroups.push(ruleForm);
    }

    // Rules FormArray
    return this.formBuilder.array(formGroups);
  }

  generate() {
    const formData = this.form.value;
    const template = this.rawData.locationTemplates.find(
      d => d.id === formData.locationTemplateId
    );

    if (formData && template) {
      template.children = this.sharedService.tryParseJSON(template.children);
      template.devices = this.sharedService.tryParseJSON(template.devices);
      template.ruleTemplates = this.sharedService.tryParseJSON(
        template.ruleTemplates
      );

      // clear locations form data
      this.form.get('locations').reset();

      this.generateLocations(template, formData);
    }
  }

  updateLocations(event: { parentId: number }) {
    const locations = this.locationForms;

    // loop locations form array
    for (let i = 0; i < locations.controls.length; i++) {
      const location = locations.controls[i] as FormGroup;

      if (event.parentId) {
        location.get('parentId').setValue(event.parentId);
      } else {
        location.get('parentId').reset();
      }
    }
  }

  updateDevices(event: { gatewayId: number; isActive: boolean }) {
    const locations = this.locationForms;

    // loop locations form array
    for (let i = 0; i < locations.controls.length; i++) {
      const location = locations.controls[i] as FormGroup;
      const devices = location.controls.devices as FormArray;

      for (let ii = 0; ii < devices.controls.length; ii++) {
        // skipped the first one (the one we already selected)
        if (i === 0 && ii === 0) {
          continue;
        }
        const device = devices.controls[ii] as FormGroup;
        device.patchValue({
          gatewayId: event.gatewayId,
          isActive: event.isActive
        });
      }
    }
  }

  updateRules(event: Rule) {
    const locations = this.locationForms;

    /*
      loop locations form array
      skipped the first location,
      normally it should be 1 rule(template) per location
    */
    for (let i = 1; i < locations.controls.length; i++) {
      const location = locations.controls[i] as FormGroup;
      const rules = location.controls.rules as FormArray;

      // loop rules form array
      for (let ii = 0; ii < rules.controls.length; ii++) {
        const rule = rules.controls[ii] as FormGroup;

        // find rule to update by ruleTemplateId
        if (rule.value.ruleTemplateId === event.ruleTemplateId) {
          // Update rule
          rule.patchValue({
            isActive: event.isActive
          });

          this.updateRuleCommands(rule, event);
          this.updateRuleConditions(rule, event);
        }
      }
    }
  }

  updateRuleCommands(rule: FormGroup, srcRule: Rule) {
    // Dynamic get RuleCommands
    const formNames = ['startCommands', 'endCommands'];

    // loop form names
    for (let i = 0; i < formNames.length; i++) {
      // FormArray of RuleCommands
      const commandForms = rule.get(formNames[i]) as FormArray;

      // Loop FormArray
      for (let ii = 0; ii < commandForms.controls.length; ii++) {
        // FormGroup of RuleCommands
        const command = commandForms.controls[ii] as FormGroup;

        // Loop RuleCommands from Source Rule
        for (let iii = 0; iii < srcRule[formNames[i]].length; iii++) {
          const tmpCmd = srcRule[formNames[i]][iii];

          // skipped if position is not the same or parameters is empty
          if (command.value.position !== tmpCmd.position) {
            continue;
          }

          // Update RuleCommands -> locationProperty[AdjustValue|ForceValue]
          if (tmpCmd.locationPropertyAdjustValue) {
            command.patchValue({
              locationPropertyAdjustValue: tmpCmd.locationPropertyAdjustValue,
              locationPropertyForceValue: null
            });
          } else if (tmpCmd.locationPropertyForceValue) {
            command.patchValue({
              locationPropertyAdjustValue: null,
              locationPropertyForceValue: tmpCmd.locationPropertyForceValue
            });
          }

          // Update RuleCommands -> parameters
          if (tmpCmd.parameters && tmpCmd.parameters.length) {
            command.get('parameters').setValue(tmpCmd.parameters);
          }
        }
      }
    }
  }

  updateRuleConditions(rule: FormGroup, srcRule: Rule) {
    // Dynamic get RuleConditions
    const formNames = ['startConditions', 'endConditions'];

    // loop form names
    for (let i = 0; i < formNames.length; i++) {
      // FormArray of RuleConditions
      const conditionForms = rule.get(formNames[i]) as FormArray;

      // Loop FormArray
      for (let ii = 0; ii < conditionForms.controls.length; ii++) {
        // FormGroup of RuleConditions
        const condition = conditionForms.controls[ii] as FormGroup;

        // Loop RuleConditions from Source Rule
        for (let iii = 0; iii < srcRule[formNames[i]].length; iii++) {
          const tmpCond = srcRule[formNames[i]][iii];

          // skipped if position is not the same
          if (condition.value.position !== tmpCond.position) {
            continue;
          }

          // Update RuleConditions -> referenced[DeviceId|LocationId|Value]
          if (tmpCond.referencedDeviceId) {
            condition.patchValue({
              referencedDeviceId: tmpCond.referencedDeviceId,
              referencedLocationId: null,
              referencedValue: null
            });
          } else if (tmpCond.referencedLocationId) {
            condition.patchValue({
              referencedDeviceId: null,
              referencedLocationId: tmpCond.referencedLocationId,
              referencedValue: null
            });
          } else if (tmpCond.referencedValue) {
            condition.patchValue({
              referencedDeviceId: null,
              referencedLocationId: null,
              referencedValue: tmpCond.referencedValue
            });
          }
        }
      }
    }
  }

  updateChildLocationDevices(event: { gatewayId: number; isActive: boolean }) {
    const locations = this.locationForms;

    // loop locations form array
    for (let i = 0; i < locations.controls.length; i++) {
      const location = locations.controls[i] as FormGroup;
      const children = location.controls.children as FormArray;

      // loop child locations form array
      for (let ii = 0; ii < children.controls.length; ii++) {
        const child = children.controls[ii] as FormGroup;
        const devices = child.controls.devices as FormArray;

        for (let iii = 0; iii < devices.controls.length; iii++) {
          // skipped the first child device of first child location (the one we already selected)
          if (i === 0 && ii === 0 && iii === 0) {
            continue;
          }

          const device = devices.controls[iii] as FormGroup;
          device.patchValue({
            gatewayId: event.gatewayId,
            isActive: event.isActive
          });
        }
      }
    }
  }

  updateChildLocationRules(event: Rule) {
    const locations = this.locationForms;

    // loop locations form array
    for (let i = 0; i < locations.controls.length; i++) {
      const location = locations.controls[i] as FormGroup;
      const children = location.controls.children as FormArray;

      // loop child locations form array
      for (let ii = 0; ii < children.controls.length; ii++) {
        // skipped the first child (the one we already selected)
        if (i === 0 && ii === 0) {
          continue;
        }

        const child = children.controls[ii] as FormGroup;
        const rules = child.controls.rules as FormArray;

        for (let iii = 0; iii < rules.controls.length; iii++) {
          const rule = rules.controls[iii] as FormGroup;

          // find rule to update by ruleTemplateId
          if (rule.value.ruleTemplateId === event.ruleTemplateId) {
            // Update rule
            rule.patchValue({
              isActive: event.isActive
            });

            this.updateRuleCommands(rule, event);
            this.updateRuleConditions(rule, event);
          }
        }
      }
    }
  }

  submit() {
    if (!this.locationForms.valid) {
      return (this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED');
    }

    // clear error message
    this.errorMessage = '';
    this.isSubmitting = true;

    const options = {
      siteId: this.sharedService.selectedSite.id,
      locations: this.form.value.locations
    };

    // const l = this.form.value.locations[0];
    // const c = l.children[0];
    // delete l.children;
    // console.log('Location:', JSON.stringify(l));
    // console.log('Children:', JSON.stringify(c));

    this.subscribers.GENERATE_LOCATIONS = this.locationGeneratorService
      .generate(options)
      .subscribe(
        (result: any) => {
          // show notification
          const title = this.sharedService.getTranslation('LOCATION_GENERATOR');
          const text = this.sharedService.getTranslation(
            'RUNNING_IN_BACKGROUND'
          );
          this.sharedService.notify(title, text, 'success');

          // close the form and return user info
          this.isSubmitting = false;
          this.onClose.emit();
        },
        (error: any) => {
          // display error message and unlock the form
          this.errorMessage = error;
          this.isSubmitting = false;
        }
      );
  }
}
