import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';

import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-disabled-rules',
  templateUrl: './disabled-rules.component.html',
  styleUrls: ['./disabled-rules.component.scss'],
})
export class DisabledRulesComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isFetchingDataset = false;
  isFirstTime = true;
  rowGroupMetadata: { [key: string]: any } = {};
  rules: any[];

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Get Selected Site
    this.subscribers.WATCH_SITE = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (this.isFetchingDataset) {
      return;
    }

    this.isFetchingDataset = true;

    this.subscribers.GET_DATASET = this.widgetService
      .getRulesStatus(this.selectedSite.id, false)
      .subscribe((result: any) => {
        this.rules = result.data;
        this.updateRowGroupMetaData();

        this.isFetchingDataset = false;
        this.isFirstTime = false;
      });
  }

  private updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (!this.rules || !this.rules.length) {
      return;
    }

    for (let i = 0; i < this.rules.length; i++) {
      const location = this.rules[i].locationName;
      if (i === 0) {
        this.rowGroupMetadata[location] = { index: 0, size: 1 };
      } else {
        const previousRowData = this.rules[i - 1];
        const previousRowGroup = previousRowData.locationName;
        if (location === previousRowGroup) {
          this.rowGroupMetadata[location].size++;
        } else {
          this.rowGroupMetadata[location] = { index: i, size: 1 };
        }
      }
    }
  }

  formatDate(deactivatedAt, updatedAt) {
    if (deactivatedAt) {
      return this.sharedService.dateFormat(deactivatedAt);
    } else if (updatedAt) {
      return this.sharedService.dateFormat(updatedAt);
    }
    return '';
  }
}
