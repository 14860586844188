<nav class="text-center">
    <button
    *ngIf="!cell.data.readAt"
    pButton
    type="button"
    class="ui-button-secondary"
    icon="fa fa-check"
    (click)="markAsRead()"
    pTooltip="{{ 'MARK_AS_READ' | translate }}"
    container="body"
  ></button>
  <sc-modal-trigger
    buttonIcon="fa fa-search"
    [modalConfig]="formConfig"
    (onClose)="markAsRead()"
    pTooltip="{{ 'DETAIL' | translate }}"
    container="body"
  ></sc-modal-trigger>
</nav>
