<div *ngIf="fetchingState === 0; else loadingIndicator">

<form [formGroup]="form" novalidate>
  <p-card>
    <!--Header-->
    <p-header>
      <div class="ui-g">
        <div class="ui-g-6">
          <sc-text-input
            *ngIf="editMode"
            controlName="id"
            [form]="form"
            label="{{ 'ID' | translate }}"
          ></sc-text-input>
        </div>
        <div class="ui-g-6 text-right">
          <p-toggleButton
            formControlName="isActive"
            offIcon="fa fa-square-o"
            offLabel="{{ 'ACTIVE' | translate }}"
            onIcon="fa fa-check-square-o"
            onLabel="{{ 'ACTIVE' | translate }}"
          ></p-toggleButton>
        </div>
      </div>
    </p-header>

    <!--Body-->
    <div class="ui-g">
      <div class="ui-g-12">
        <!-- NAME FIELD -->
        <div class="ui-g-6">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- CRON_PATTERN -->
        <div class="ui-g-6">
          <sc-textarea
            controlName="cronPattern"
            [form]="form"
            label="{{ 'CRON_PATTERN' | translate }}"
          ></sc-textarea>
        </div>
      </div>

      <div class="ui-g-12">
        <!-- LEFT Column -->
        <div class="ui-g-6 ui-md-12  ui-lg-6">
          <!-- FREQUENCY -->
          <div class="ui-g-12">
            <label class="sc-form-label">{{ 'FREQUENCY' | translate }}</label>
            <div>
              <span class="mr-3">
                <p-radioButton
                  name="frequency"
                  label="Recurring"
                  value="r"
                  formControlName="frequency"
                ></p-radioButton>
              </span>

              <span class="mr-3">
                <p-radioButton
                  name="frequency"
                  label="Non-recurring"
                  value="nr"
                  formControlName="frequency"
                ></p-radioButton>
              </span>

              <span class="mr-3">
                <p-radioButton
                  name="frequency"
                  label="Custom"
                  value="c"
                  formControlName="frequency"
                ></p-radioButton>
              </span>
            </div>
          </div>

          <div [ngSwitch]="form.get('frequency').value">
            <div *ngSwitchCase="'r'">
              <recurring-form [group]="form"></recurring-form>
            </div>

            <div *ngSwitchCase="'nr'">
              <non-recurring-form [group]="form"></non-recurring-form>
            </div>

            <div *ngSwitchCase="'c'">
              <custom-form [group]="form"></custom-form>
            </div>

            <div *ngSwitchDefault></div>
          </div>
        </div>

        <!-- RIGHT Column -->
        <div class="ui-g-6 ui-md-12 ui-lg-6">
          <!-- RULE_TEMPLATES FIELD -->
          <div class="ui-g-12">
            <sc-dropdown
              controlName="ruleTemplates"
              [form]="form"
              label="{{ 'RULE_TEMPLATES' | translate }}"
              [options]="optionData['ruleTemplates']"
              [multiSelect]="true"
            ></sc-dropdown>
          </div>

          <!-- LOCATIONS FIELD -->
          <div class="ui-g-12 grey lighten-2">
            <div class="ui-g-10">
              <label class="sc-form-label">
                {{ 'LOCATIONS' | translate }}
              </label>
            </div>
            <div class="ui-g-2 text-right">
              <button
                pButton
                type="button"
                class="ui-button-success"
                icon="fa fa-plus"
                (click)="addLocation()"
                pTooltip="{{ 'ADD' | translate }} {{ 'LOCATION' | translate }}"
                appendTo="body"
              ></button>
            </div>
          </div>
          <div class="ui-g-12">
            <div formArrayName="locations">
              <div *ngFor="let location of locationFormArray.controls; let i=index" [formGroupName]="i">
                <location-form
                  [group]="location"
                  [groupId]="i"
                  [optionData]="optionData['locations']"
                  (onRemove)="removeLocation(i)"
                ></location-form>
              </div>
            </div>
          </div>

          <!-- ACTION FIELD -->
          <div class="ui-g-12">
            <label class="sc-form-label">{{ 'ACTION' | translate }}</label>
            <div>
              <br>
              <span class="mr-3">
                <p-radioButton
                  name="action"
                  label="enable the rule(s)"
                  value="1"
                  formControlName="action"
                ></p-radioButton>
              </span>
              <span class="mr-3">
                <p-radioButton
                  name="action"
                  label="disable the rule(s)"
                  value="0"
                  formControlName="action"
                ></p-radioButton>
              </span>
            </div>
          </div>

          <!-- DISABLE_JOB_FROM FIELD -->
          <div class="ui-g-12">
            <div class="ui-g-6">
              <sc-datetime-input
                controlName="disableJobFrom"
                [form]="form"
                label="{{ 'DISABLE_JOB_FROM' | translate }}"
              ></sc-datetime-input>
            </div>

            <!-- DISABLE_JOB_TO FIELD -->
            <div class="ui-g-6">
              <sc-datetime-input
                controlName="disableJobTo"
                [form]="form"
                label="{{ 'DISABLE_JOB_TO' | translate }}"
              ></sc-datetime-input>
            </div>
          </div>
        </div>
      </div>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <p-footer>
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>
      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>
        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </p-footer>
  </p-card>
</form>

</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
