<div
  class="modal-trigger"
  [ngClass]="{ inline: displayInline }"
  (click)="openModal()"
  [pTooltip]="tooltipContent"
  appendTo="body"
  [tooltipPosition]="tooltipPosition"
  [escape]="tooltipEscape"
>
  <!-- custom trigger -->
  <div #ref class="custom-trigger">
    <ng-content></ng-content>
  </div>

  <!-- default trigger -->
  <ng-container *ngIf="ref?.children?.length == 0">
    <button
      type="button"
      class="modal-trigger-button"
      [ngClass]="buttonClass"
      [disabled]="disabled"
    >
      <span
        *ngIf="buttonIcon"
        class="modal-trigger-button__icon fa"
        [ngClass]="buttonIcon"
      ></span>

      <span *ngIf="buttonLabel" class="modal-trigger-button__label">
        {{ buttonLabel }}
      </span>
      <!-- [icon]="buttonIcon" -->
      <!-- [iconPos]="iconPos" -->
      <!-- [label]="buttonLabel" -->
    </button>
  </ng-container>
</div>

<p-dialog
  appendTo="body"
  [showHeader]="true"
  [modal]="true"
  [(visible)]="display"
  position="center"
  header="{{ headerText | translate: headerTextParams }}"
  (onHide)="dismissModal('cancel')"
  [focusOnShow]="false"
  [maximizable]="isMaximizable"
  [resizable]="isResizable"
>
  <!-- [draggable]="draggable" -->
  <!-- [styleClass]="fullScreen ? 'fullscreen' : ''" -->
  <!-- [responsive]="responsive" -->
  <!-- [breakpoint]="1000" -->
  <!-- [positionTop]="positionTop" -->
  <ng-template scModalHost></ng-template>
</p-dialog>
