import { Action } from '@ngrx/store';
import { Location } from '@widgets/locations/models/location';
import { LocationRequest } from '@widgets/locations/models/location-request';

export const LOAD_LOCATION = '[Pages] Load Location';
export const LOAD_LOCATION_FAIL = '[Pages] Load Location Fail';
export const LOAD_LOCATION_SUCCESS = '[Pages] Load Location Success';
export const LOAD_LOCATIONS = '[Pages] Load Locations';
export const LOAD_LOCATIONS_FAIL = '[Pages] Load Locations Fail';
export const LOAD_LOCATIONS_SUCCESS = '[Pages] Load Locations Success';
export const RESET_LOCATIONS = '[Locations] Reset Locations';
export const SELECT_LOCATION = '[Pages] Select location';

export class LoadLocation implements Action {
  readonly type = LOAD_LOCATION;
  constructor(public payload: number) {}
}

export class LoadLocationFail implements Action {
  readonly type = LOAD_LOCATION_FAIL;
  constructor(public payload: any) {}
}

export class LoadLocationSuccess implements Action {
  readonly type = LOAD_LOCATION_SUCCESS;
  constructor(public payload: Location) {}
}

export class LoadLocations implements Action {
  readonly type = LOAD_LOCATIONS;
  constructor(public payload: LocationRequest) {}
}

export class LoadLocationsFail implements Action {
  readonly type = LOAD_LOCATIONS_FAIL;
  constructor(public payload: any) {}
}

export class LoadLocationsSuccess implements Action {
  readonly type = LOAD_LOCATIONS_SUCCESS;
  constructor(public payload: Location[]) {}
}

export class ResetLocations implements Action {
  readonly type = RESET_LOCATIONS;
}

export class SelectLocation implements Action {
  readonly type = SELECT_LOCATION;
  constructor(public payload: number) {}
}

// ACTION TYPES
export type LocationsAction =
  | LoadLocation
  | LoadLocationFail
  | LoadLocationSuccess
  | LoadLocations
  | LoadLocationsFail
  | LoadLocationsSuccess
  | ResetLocations
  | SelectLocation;
