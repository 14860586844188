import { Component, OnInit, Input } from '@angular/core';

import { LocationProperty } from '../models/location-property';
import { ModalConfig } from '@sc/modal/modal-config';

import { LocationPropertiesService } from '../services/location-properties.service';

import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';
import { LocationPropertyActionCellComponent } from '../location-property-action-cell/location-property-action-cell.component';

@Component({
  selector: 'sc-location-properties-table',
  templateUrl: 'location-properties-table.component.html'
})
export class LocationPropertiesTableComponent implements OnInit {
  @Input()
  config: any;

  dataset: LocationProperty[];
  columns: any[];
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(private locationPropertiesService: LocationPropertiesService) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'label',
        headerName: 'LABEL',
        field: 'label'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'type',
        headerName: 'TYPE',
        field: 'type'
      },
      {
        colId: 'operators',
        headerName: 'OPERATORS',
        field: 'operators'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: LocationPropertyActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'LocationPropertyForm',
      options: { modalTitle: 'LOCATION_PROPERTY_FORM_TITLE' }
    };
    this.tableTitle = 'LOCATION_PROPERTIES';

    this.fetchDataset();
  }

  fetchDataset() {
    this.locationPropertiesService
      .getLocationProperties()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
