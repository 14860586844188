import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-rule-monitor-action-cell',
  templateUrl: 'rule-monitor-action-cell.component.html',
})
export class RuleMonitorActionCellComponent implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }
}
