import { Action } from '@ngrx/store';
import { Site } from '@widgets/sites/models/site';
// import { Location } from '@widgets/locations/models/location';

// Load Site
export const LOAD_SITE = '[Sites] Load Site';
export const LOAD_SITE_FAIL = '[Sites] Load Site Fail';
export const LOAD_SITE_SUCCESS = '[Sites] Load Site Success';

export class LoadSite implements Action {
  readonly type = LOAD_SITE;
  constructor(public payload: number) {}
}
export class LoadSiteFail implements Action {
  readonly type = LOAD_SITE_FAIL;
  constructor(public payload: any) {}
}
export class LoadSiteSuccess implements Action {
  readonly type = LOAD_SITE_SUCCESS;
  constructor(public payload: Site) {}
}

// Load Sites
export const LOAD_SITES = '[Sites] Load Sites';
export const LOAD_SITES_FAIL = '[Sites] Load Sites Fail';
export const LOAD_SITES_SUCCESS = '[Sites] Load Sites Success';

export class LoadSites implements Action {
  readonly type = LOAD_SITES;
  constructor(public payload: number) {}
}
export class LoadSitesFail implements Action {
  readonly type = LOAD_SITES_FAIL;
  constructor(public payload: any) {}
}
export class LoadSitesSuccess implements Action {
  readonly type = LOAD_SITES_SUCCESS;
  constructor(public payload: Site[]) {}
}

// Load Sites Table
export const LOAD_SITES_TABLE = '[Sites] Load Sites Table';
export const LOAD_SITES_TABLE_FAIL = '[Sites] Load Sites Table Fail';
export const LOAD_SITES_TABLE_SUCCESS = '[Sites] Load Sites Table Success';

export class LoadSitesTable implements Action {
  readonly type = LOAD_SITES_TABLE;
  constructor(public payload?: number) {}
}
export class LoadSitesTableFail implements Action {
  readonly type = LOAD_SITES_TABLE_FAIL;
  constructor(public payload: any) {}
}
export class LoadSitesTableSuccess implements Action {
  readonly type = LOAD_SITES_TABLE_SUCCESS;
  constructor(public payload: Site[]) {}
}

// Select Site
export const SELECT_SITE = '[Sites] Select Site';

export class SelectSite implements Action {
  readonly type = SELECT_SITE;
  constructor(public payload: number) {}
}

// CREATE SITE
export const CREATE_SITE = '[Sites] Create Sites';
export const CREATE_SITE_FAIL = '[Sites] Create Sites Fail';
export const CREATE_SITE_SUCCESS = '[Sites] Create Sites Success';

export class CreateSites implements Action {
  readonly type = CREATE_SITE;
  constructor(public payload: { site: Site /* floors?: Location[] */ }) {}
}
export class CreateSitesFail implements Action {
  readonly type = CREATE_SITE_FAIL;
  constructor(public payload: any) {}
}
export class CreateSitesSuccess implements Action {
  readonly type = CREATE_SITE_SUCCESS;
  constructor(public payload: Site) {}
}

// UPDATE SITE
export const UPDATE_SITE = '[Sites] Update Sites';
export const UPDATE_SITE_FAIL = '[Sites] Update Sites Fail';
export const UPDATE_SITE_SUCCESS = '[Sites] Update Sites Success';

export class UpdateSites implements Action {
  readonly type = UPDATE_SITE;
  constructor(public payload: Site) {}
}
export class UpdateSitesFail implements Action {
  readonly type = UPDATE_SITE_FAIL;
  constructor(public payload: any) {}
}
export class UpdateSitesSuccess implements Action {
  readonly type = UPDATE_SITE_SUCCESS;
  constructor(public payload: Site) {}
}

// DELETE SITE
export const DELETE_SITE = '[Sites] Delete Sites';
export const DELETE_SITE_FAIL = '[Sites] Delete Sites Fail';
export const DELETE_SITE_SUCCESS = '[Sites] Delete Sites Success';

export class DeleteSites implements Action {
  readonly type = DELETE_SITE;
  constructor(public payload: Site) {}
}
export class DeleteSitesFail implements Action {
  readonly type = DELETE_SITE_FAIL;
  constructor(public payload: any) {}
}
export class DeleteSitesSuccess implements Action {
  readonly type = DELETE_SITE_SUCCESS;
  constructor(public payload: Site) {}
}

// Reset Pending
export const SITE_RESET_PENDING_STATE = '[Sites] Reset pending state';

export class SiteResetPendingState implements Action {
  readonly type = SITE_RESET_PENDING_STATE;
}

// ACTION TYPES
export type SitesAction =
  | LoadSite
  | LoadSiteFail
  | LoadSiteSuccess
  | LoadSites
  | LoadSitesFail
  | LoadSitesSuccess
  | LoadSitesTable
  | LoadSitesTableFail
  | LoadSitesTableSuccess
  | SelectSite
  | CreateSites
  | CreateSitesFail
  | CreateSitesSuccess
  | UpdateSites
  | UpdateSitesFail
  | UpdateSitesSuccess
  | DeleteSites
  | DeleteSitesFail
  | DeleteSitesSuccess
  | SiteResetPendingState;
