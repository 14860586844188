import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-page-edit-menu',
  templateUrl: './page-edit-menu.component.html',
  styleUrls: ['./page-edit-menu.component.scss']
})
export class PageEditMenuComponent implements OnInit {
  @Input()
  editMode: boolean;

  @Output()
  addWidget = new EventEmitter<any>();
  @Output()
  cancel = new EventEmitter<any>();
  @Output()
  undo = new EventEmitter<any>();
  @Output()
  save = new EventEmitter<any>();
  @Output()
  toggleEditMode = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
