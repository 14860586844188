<div class="function-parameters">
  <div class="function-parameters-header">
    <div class="function-parameters-header__title">
      <div class="function-parameters-header__toggle" (click)="onToggle()">
        <fa-icon [icon]="arrowIcon" [fixedWidth]="true"></fa-icon>
      </div>

      {{ "CCP_CUSTOM_FUNCTION_PARAMETERS" | translate }}
    </div>

    <div class="function-parameters-header__actions">
      <button
        type="button"
        class="function-parameters-header__action-button"
        (click)="onAddParameter()"
      >
        <fa-icon [icon]="addIcon" [fixedWidth]="true"></fa-icon>
      </button>
    </div>
  </div>

  <div
    class="function-parameters-body"
    [ngClass]="{ 'function-parameters-body--closed': !show }"
  >
    <div *ngFor="let ctrl of parametersForm.controls; let i = index">
      <sc-function-parameter-form
        [form]="ctrl"
        [index]="i"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        (removed)="onRemoveParameter(i)"
      ></sc-function-parameter-form>
    </div>
  </div>
</div>
