import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AbnormalOccupancyAlertComponent } from './abnormal-occupancy-alert.component';

@NgModule({
  imports: [SharedModule],
  declarations: [AbnormalOccupancyAlertComponent],
  entryComponents: [AbnormalOccupancyAlertComponent]
})
export class AbnormalOccupancyAlertModule {}
