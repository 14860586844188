<div class="rule-overview" *ngIf="rule">
  <div class="rule-detail">
    <div *ngIf="!showEditor">
      <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
        <div class="p-col-12 p-md-6 p-md-offset-3">
          <label class="input-label" for="rule_template">
            {{ "CCP_RULE_TEMPLATE" | translate }}
          </label>
        </div>

        <div class="p-field p-col-12 p-md-4 p-md-offset-3">
          <p-dropdown
            inputId="rule_template"
            [options]="selectItems?.ruleTemplates"
            [formControl]="selectedTemplate"
            placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
              'CCP_RULE_TEMPLATE' | translate
            }}"
            [filter]="true"
            appendTo="body"
          ></p-dropdown>
        </div>

        <div class="p-field p-col-12 p-md-2">
          <button
            pButton
            type="button"
            class="p-button-sm p-button-outlined full-width-button"
            [label]="'CCP_COMMON_OK' | translate"
            (click)="onCreateFromTemplate()"
            [disabled]="!selectedTemplate?.value"
          ></button>
        </div>
      </div>

      <div class="p-grid p-mb-3 p-mt-1">
        <div class="p-col-12 center-text">
          {{ "CCP_COMMON_OR" | translate }}
        </div>
      </div>

      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6 p-md-offset-3">
          <button
            pButton
            type="button"
            class="p-button-sm p-button-outlined full-width-button"
            [label]="'CCP_COMMON_CREATE_FROM_SCRATCH' | translate"
            (click)="onCreateFromScratch()"
          ></button>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid p-inputtext-sm" *ngIf="showEditor">
      <div class="p-field p-col-12 p-md-6">
        <!-- <label class="input-label" for="name">
          {{ "CCP_COMMON_NAME" | translate }}
        </label> -->

        <sc-input-label
          for="name"
          [title]="'CCP_COMMON_NAME' | translate"
          [required]="true"
        ></sc-input-label>

        <input type="text" pInputText inputId="name" [(ngModel)]="rule.name" />
      </div>

      <div class="p-field p-col-12 p-md-2">
        <label class="input-label" for="repeat">
          {{ "CCP_COMMON_ACTIVE" | translate }}
        </label>

        <p-checkbox
          inputId="repeat"
          [binary]="true"
          [(ngModel)]="rule.is_active"
          [label]="'CCP_COMMON_YES' | translate"
          class="rule-active-checkbox"
        ></p-checkbox>
      </div>

      <div *ngIf="isSchedulerActive" class="p-field p-col-12 p-md-2">
        <label class="input-label" for="repeat">
          {{ "RULE_IS_DISABLED_BY_DEFAULT" | translate }}
        </label>

        <p-checkbox
          inputId="repeat"
          [binary]="true"
          [(ngModel)]="rule.is_disabled_by_default"
          [label]="'CCP_COMMON_YES' | translate"
          class="rule-active-checkbox"
        ></p-checkbox>
      </div>

      <!--
      <div class="p-field p-col-12 p-md-6">
        <sc-input-label
          for="view_mode"
          [title]="'CCP_COMMON_VIEW' | translate"
          [required]="true"
        ></sc-input-label>

        <p-dropdown
          inputId="view_mode"
          [options]="viewModes"
          [(ngModel)]="rule.editor_view"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_COMMON_VIEW' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-6">
        <label class="input-label" for="affected_devices">
          {{ "CCP_RULE_AFFECTED_DEVICES" | translate }}
        </label>

        <sc-tree-select
          inputId="affected_devices"
          [control]="affectedDevices"
          [options]="dataSources?.devicesTree"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_DEVICES' | translate
          }}"
          mode="checkbox"
        ></sc-tree-select>
      </div>

      <div class="p-field p-col-12 p-md-6">
        <label class="input-label" for="affected_locations">
          {{ "CCP_RULE_AFFECTED_LOCATIONS" | translate }}
        </label>

        <p-multiSelect
          inputId="affected_locations"
          [options]="selectItems?.locations"
          [(ngModel)]="rule.affected_locations"
          [filter]="true"
          defaultLabel="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_LOCATIONS' | translate
          }}"
          appendTo="body"
        ></p-multiSelect>
      </div>
      -->
    </div>
  </div>

  <div
    class="rule-workflow"
    [ngClass]="{ 'rule-workflow--no-command': rule?.commands?.length === 0 }"
    *ngIf="showEditor"
  >
    <div class="condition-header">
      <div class="circle condition-title">
        {{ "CCP_RULE_CONNECTOR_IF" | translate }}
      </div>
    </div>

    <ng-container *ngIf="rule?.conditions">
      <sc-rule-condition
        [viewMode]="rule?.editor_view"
        [conditions]="rule?.conditions"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isFromScratch]="isFromScratch"
      ></sc-rule-condition>
    </ng-container>

    <div class="command-header">
      <div class="circle command-title">
        {{ "CCP_RULE_CONNECTOR_THEN" | translate }}
      </div>
      <div class="command-description">
        {{ "CCP_RULE_CONNECTOR_THEN_DESCRIPTION" | translate }}
      </div>
    </div>

    <ng-container *ngIf="rule?.commands">
      <sc-rule-commands
        [viewMode]="rule?.editor_view"
        [commands]="rule?.commands"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isFromScratch]="isFromScratch"
        [templateCommands]="ruleTemplate?.commands"
      ></sc-rule-commands>
    </ng-container>
  </div>
</div>
