<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!--Header-->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>
          <div class="ui-g-6 text-right">
            <p-toggleButton
              formControlName="isActive"
              offIcon="fa fa-square-o"
              offLabel="{{ 'ACTIVE' | translate }}"
              onIcon="fa fa-check-square-o"
              onLabel="{{ 'ACTIVE' | translate }}"
            ></p-toggleButton>
          </div>
        </div>
      </p-header>

      <!--Body-->
      <div class="ui-g">
        <!-- RULE_TEMPLATE_ID FIELD -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="ruleTemplateId"
            [form]="form"
            label="{{ 'RULE_TEMPLATE' | translate }}"
            [options]="selectItems?.ruleTemplates"
          ></sc-dropdown>
        </div>

        <div class="ui-g-12 ui-g-nopad" *ngIf="ruleTemplateId">
          <!-- NAME Field -->
          <div class="ui-g-12">
            <sc-text-input
              controlName="name"
              [form]="form"
              label="{{ 'NAME' | translate }}"
            ></sc-text-input>
          </div>

          <!-- DESCRIPTION Field -->
          <div class="ui-g-12">
            <sc-textarea
              controlName="description"
              [form]="form"
              label="{{ 'DESCRIPTION' | translate }}"
            ></sc-textarea>
          </div>

          <!-- DISABLED_UNTIL FIELD -->
          <div class="ui-g-12">
            <div class="ui-g-4">
              <sc-datetime-input
                controlName="disabledUntil"
                [form]="form"
                label="{{ 'DISABLED_UNTIL' | translate }}"
              ></sc-datetime-input>
            </div>

            <div class="ui-g-4">
              <sc-dropdown
                controlName="affectedLocations"
                [form]="form"
                label="{{ 'AFFECTED_LOCATION' | translate }}"
                [options]="selectItems?.affectedLocations"
              ></sc-dropdown>
            </div>

            <div class="ui-g-4">
              <sc-dropdown
                controlName="affectedDevices"
                [form]="form"
                label="{{ 'AFFECTED_DEVICE' | translate }}"
                [options]="selectItems?.affectedDevices"
              ></sc-dropdown>
            </div>
          </div>

          <!-- START/END -->
          <div class="ui-g-12">
            <button
              pButton
              type="button"
              class="ui-button-info"
              label="{{ 'START' | translate }}"
              (click)="showCommandAndConditionForm('start')"
              [disabled]="currentCommandAndConditionForm === 'start'"
            ></button>
            <button
              pButton
              type="button"
              class="ui-button-info"
              label="{{ 'END' | translate }}"
              (click)="showCommandAndConditionForm('end')"
              [disabled]="currentCommandAndConditionForm === 'end'"
            ></button>
          </div>

          <!-- EXECUTION -->
          <div
            [ngSwitch]="currentCommandAndConditionForm"
            class="ui-g-12 ui-g-nopad"
          >
            <div *ngSwitchCase="'end'">
              <div class="ui-g-12 ui-g-nopad">
                <div class="ui-g-4">
                  <sc-number-input
                    controlName="endMinExecutionTime"
                    [form]="form"
                    label="{{ 'END_MIN_EXECUTION_TIME' | translate }}"
                  ></sc-number-input>
                </div>
                <div class="ui-g-4">
                  <sc-number-input
                    controlName="endMaxExecutionTime"
                    [form]="form"
                    label="{{ 'END_MAX_EXECUTION_TIME' | translate }}"
                  ></sc-number-input>
                </div>
                <div class="ui-g-4">
                  <sc-number-input
                    controlName="endBlockingAfterExecutionTime"
                    [form]="form"
                    label="{{ 'END_BLOCKING_AFTER_EXECUTION_TIME' | translate }}"
                  ></sc-number-input>
                </div>
              </div>

              <div class="ui-g-4 my-3">
                <sc-checkbox-input
                  controlName="endAllowRepetition"
                  [form]="form"
                  label="{{ 'RULE_END_ALLOW_REPETITION' | translate }}"
                ></sc-checkbox-input>
              </div>
              <!-- <div class="ui-g-12 ui-g-nopad">
                <div class="ui-g-4">
                  <sc-dropdown
                    controlName="energySavingPatternsEnd"
                    [form]="form"
                    label="{{ 'ENERGY_SAVING_PATTERNS' | translate }}"
                    [options]="selectItems?.energySavingPatterns"
                    [multiSelect]="true"
                  ></sc-dropdown>
                </div>
              </div> -->
            </div>

            <div *ngSwitchDefault>
              <div class="ui-g-12 ui-g-nopad">
                <div class="ui-g-4">
                  <sc-number-input
                    controlName="startMinExecutionTime"
                    [form]="form"
                    label="{{ 'START_MIN_EXECUTION_TIME' | translate }}"
                  ></sc-number-input>
                </div>
                <div class="ui-g-4">
                  <sc-number-input
                    controlName="startMaxExecutionTime"
                    [form]="form"
                    label="{{ 'START_MAX_EXECUTION_TIME' | translate }}"
                  ></sc-number-input>
                </div>
                <div class="ui-g-4">
                  <sc-number-input
                    controlName="startBlockingAfterExecutionTime"
                    [form]="form"
                    label="{{ 'START_BLOCKING_AFTER_EXECUTION_TIME' | translate }}"
                  ></sc-number-input>
                </div>

                <div class="ui-g-4 my-3">
                  <sc-checkbox-input
                    controlName="startAllowRepetition"
                    [form]="form"
                    label="{{ 'RULE_START_ALLOW_REPETITION' | translate }}"
                  ></sc-checkbox-input>
                </div>
              </div>

              <!-- <div class="ui-g-12 ui-g-nopad">
                <div class="ui-g-4">
                  <sc-dropdown
                    controlName="energySavingPatternsStart"
                    [form]="form"
                    label="{{ 'ENERGY_SAVING_PATTERNS' | translate }}"
                    [options]="selectItems?.energySavingPatterns"
                    [multiSelect]="true"
                  ></sc-dropdown>
                </div>
              </div> -->
            </div>
          </div>
          <!-- /EXECUTION -->

          <!-- CONDITIONS FORM -->
          <h2>
            {{ 'CONDITIONS' | translate }} ({{ currentCommandAndConditionForm }})
          </h2>

          <!-- GLOBAL CONNECTOR -->
          <div class="ui-g mb-3">
            {{ 'CONDITIONS' | translate }} {{ 'CONNECTOR' | translate }}:&nbsp;

            <span [ngSwitch]="currentCommandAndConditionForm">
              <span *ngSwitchCase="'end'">
                {{ endConditionConnector }}
              </span>

              <span *ngSwitchDefault>
                {{ startConditionConnector }}
              </span>
            </span>
          </div>

          <div class="grey lighten-2">
            <div class="ui-g">
              <div class="ui-g-1">{{ 'CONNECTOR' | translate }}</div>
              <div class="ui-g-3">{{ 'SOURCE' | translate }}</div>
              <div class="ui-g-1">{{ 'OPERATOR' | translate }}</div>
              <div class="ui-g-3">
                {{ 'REFERENCED' | translate }}
                <div class="ui-g">
                  <div class="ui-g-6">
                    <small>{{ 'SOURCE' | translate }}</small>
                  </div>
                  <div class="ui-g-6">
                    <small>{{ 'VALUE' | translate }}</small>
                  </div>
                </div>
              </div>
              <div class="ui-g-4 p-0">
                <div class="ui-g p-0">
                  <div class="ui-g-6">{{ 'PARAMETERS' | translate }}</div>
                  <div class="ui-g-3">
                    {{ 'WAS_TRUE_ONE_TIME_IN_PAST' | translate }}
                  </div>
                  <!-- <div class="ui-g-3">{{ 'EVENT' | translate }}</div> -->
                  <div class="ui-g-3">{{ 'VALIDITY_PERIOD' | translate }}</div>
                </div>
              </div>
            </div>
          </div>

          <div [ngSwitch]="currentCommandAndConditionForm">
            <div *ngSwitchCase="'end'">
              <div formArrayName="endConditions">
                <div
                  *ngFor="
                    let endCondition of endConditions.controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <sc-rule-condition-form
                    [group]="endCondition"
                    [groupId]="i"
                    [optionData]="selectItems"
                  ></sc-rule-condition-form>
                </div>
              </div>
            </div>
            <div *ngSwitchDefault>
              <div formArrayName="startConditions">
                <div
                  *ngFor="
                    let condition of startConditions.controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <sc-rule-condition-form
                    [group]="condition"
                    [groupId]="i"
                    [optionData]="selectItems"
                  ></sc-rule-condition-form>
                </div>
              </div>
            </div>
          </div>

          <!-- COMMANDS FORM -->
          <h2>
            {{ 'COMMANDS' | translate }} ({{ currentCommandAndConditionForm }})
          </h2>

          <div class="grey lighten-2">
            <div class="ui-g">
              <div class="ui-g-11 p-0">
                <div
                  class="ui-g-1 text-center"
                  pTooltip="{{ 'EXECUTE_ONLY_ONE_TIME' | translate }}"
                  appendTo="body"
                >
                  1x
                </div>
                <div
                  class="ui-g-1 text-center"
                  pTooltip="{{
                    'EXECUTE_ONLY_IF_DIFFERENT_FROM_LOCAL' | translate
                  }}"
                  appendTo="body"
                >
                  !=
                </div>
                <div class="ui-g-3">{{ 'SOURCE' | translate }}</div>
                <div class="ui-g-2">{{ 'ACTION' | translate }}</div>
                <div class="ui-g-4">
                  {{ 'PARAMETERS' | translate }}
                  <div class="ui-g">
                    <div class="ui-g-6">
                      <small>{{ 'KEY' | translate }}</small>
                    </div>
                    <div class="ui-g-6">
                      <small>{{ 'VALUE' | translate }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-1">{{ 'DELAY' | translate }}</div>
            </div>
          </div>

          <div [ngSwitch]="currentCommandAndConditionForm">
            <div *ngSwitchCase="'end'">
              <div formArrayName="endCommands">
                <div
                  *ngFor="let endCommand of endCommands.controls; let i = index"
                  [formGroupName]="i"
                >
                  <sc-rule-command-form
                    [group]="endCommand"
                    [groupId]="i"
                    [optionData]="selectItems"
                  ></sc-rule-command-form>
                </div>
              </div>
            </div>
            <div *ngSwitchDefault>
              <div formArrayName="startCommands">
                <div
                  *ngFor="let command of startCommands.controls; let i = index"
                  [formGroupName]="i"
                >
                  <sc-rule-command-form
                    [group]="command"
                    [groupId]="i"
                    [optionData]="selectItems"
                  ></sc-rule-command-form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Error Message -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>
      </div>

      <!--Footer-->
      <p-footer>
        <div class="ui-g-6">
          <sc-confirm-trigger
            *ngIf="editMode && !formData?.isDeleted"
            buttonIcon="fa fa-trash"
            buttonClass="ui-button-danger"
            buttonLabel="{{ 'DELETE' | translate }}"
            (onAccept)="delete()"
          ></sc-confirm-trigger>
        </div>
        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            label="{{ 'CANCEL' | translate }}"
            (click)="dismissModal('cancel')"
          ></button>
          <sc-submit-button
            *ngIf="!formData?.isDeleted || formData?.isActive"
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
