import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SCWidgetService } from '@widgets/widget.service';
import { Location } from '@widgets/locations/models/location';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { SharedService } from '@shared/shared.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { LocationType as LocationTypeEnum } from '@app/integrator/enums';

@Component({
  selector: 'sc-areas-creator',
  templateUrl: './areas-creator.component.html',
  styleUrls: ['./areas-creator.component.scss'],
})
export class AreasCreatorComponent implements OnInit, OnDestroy {
  @Input() config: any;

  floors: Location[] = [];
  units: Location[] = [];
  form: FormGroup;
  locationsTree: TreeNode[];
  nestedLocations: any[];
  flatLocations: any[];
  selectedLocations: TreeNode[] = [];

  isFetchingLocations: boolean;
  private subscribers: { [key: string]: any } = {};

  @Output() onSubmit: EventEmitter<Location[]> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.initForm();
    this.fetchLocations();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchLocations() {
    if (this.isFetchingLocations) {
      return;
    }
    this.isFetchingLocations = true;
    const options = {
      siteId: this.sharedService.selectedSite.id,
      columns: ['id', 'idx', 'locationTypeId', 'isActive', 'isDeleted', 'description'].join(),
      tree: true,
      minimize: true,
    };

    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
      this.nestedLocations = result.mappingKeys
        ? this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys)
        : result.data;
      this.flatLocations = this.widgetService.flattenTreeRecursively(this.nestedLocations);
      this.locationsTree = this.widgetService
        .nestedDatasetToTreeNode(this.nestedLocations, true)
        .filter((location) => location.children.length > 0);
      this.isFetchingLocations = false;
      this.expandAll();
    });
  }

  addArea() {
    this.areas.push(
      this.formBuilder.group({
        description: this.formBuilder.control(`${this.sharedService.getTranslation('AREA')} ${this.areas.length + 1}`),
      })
    );
  }

  removeArea(index: number) {
    if (this.areas.length > 0) {
      this.areas.removeAt(index);
    }
  }

  get areas(): FormArray {
    return this.form.get('areas') as FormArray;
  }

  submit(): void {
    const validParents = [LocationTypeEnum.Area, LocationTypeEnum.Unit]; //Could create an area only under an area or a unit
    const newAreas: Array<Location> = [];
    this.selectedLocations.map((selectedLocation) => {
      const location = this.flatLocations.filter((flatLocation) => flatLocation.idx === selectedLocation.data);
      if (validParents.includes(location[0].locationTypeKey)) {
        this.areas.getRawValue().map((area) =>
          newAreas.push({
            description: area.description,
            locationTypeId: 2,
            parentId: location[0].id,
            parentIdx: location[0].idx,
            isActive: true,
            isDeleted: false,
            floorNumber: location[0].floorNumber,
            siteId: this.sharedService.selectedSite.id,
          })
        );
      }
    });
    this.onSubmit.emit(newAreas);
  }

  cancel() {
    this.onCancel.emit();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      areas: this.formBuilder.array([]),
    });
  }

  expandAll() {
    this.locationsTree.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }
}
