import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-parameter-form',
  templateUrl: './location-parameter-form.component.html',
  styleUrls: ['./location-parameter-form.component.scss']
})
export class LocationParameterFormComponent implements OnInit {
  @Input()
  data: any;
  @Input()
  form: FormGroup;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    // console.log(this.data);
    // console.log(this.form.get('locationPropertyTypeId').value);
  }

  get formControlName() {
    const formData = this.form.value;
    if (
      typeof formData.locationPropertyAdjustValue !== 'undefined' &&
      formData.locationPropertyAdjustValue !== null
    ) {
      return 'locationPropertyAdjustValue';
    }
    // else if (
    //   typeof formData.locationPropertyForceValue !== 'undefined' &&
    //   formData.locationPropertyForceValue !== null
    // ) {
    return 'locationPropertyForceValue';
    // }
  }

  get formControlLabel() {
    const formData = this.form.value;
    if (
      typeof formData.locationPropertyAdjustValue !== 'undefined' &&
      formData.locationPropertyAdjustValue !== null
    ) {
      return 'ADJUST_VALUE';
    }
    // else if (
    //   typeof formData.locationPropertyForceValue !== 'undefined' &&
    //   formData.locationPropertyForceValue !== null
    // ) {
    return 'FORCE_VALUE';
    // }
  }

  get mappingValues() {
    const locationPropertyKey = this.form.get('locationPropertyTypeId').value;
    if (
      this.data &&
      this.data.raw &&
      this.data.raw.locationProperties &&
      locationPropertyKey
    ) {
      // Get Location Property
      const locationProperty = this.data.raw.locationProperties.find(
        d => d.key === locationPropertyKey
      );

      if (locationProperty) {
        // Check Location Property
        switch (locationProperty.type) {
          case 'boolean':
            return this.data.selectItems.booleanOption;

          case 'deviceType':
            if (this.data.raw.deviceTypes) {
              // Get Device Type
              const deviceType = this.data.raw.deviceTypes.find(
                d => d.key === locationProperty.deviceType
              );

              if (
                deviceType &&
                deviceType.mappingValues &&
                deviceType.mappingValues.length
              ) {
                // Get Device Type Mapping Values
                return this.sharedService.createSelectItems(
                  deviceType.mappingValues,
                  false,
                  'key'
                );
              }
            }
            return;
        }
      }
    }
    return;
  }
}
