export enum Cursors {
  Normal = 'default',
  Draw = 'crosshair',
  DrawAlt = 'cell',
  Link = 'pointer',
  Move = 'move',
  Pan = 'grabbing',
}

export enum CustomControlIcons {
  Cancel = 'assets/images/x-mark.svg',
  Save = 'assets/images/check-mark.svg',
}
