<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ tableTitle | translate }} -->
        {{ widgetName }}
      </h4>

      <!-- <div class="sc-widget-menu"></div> -->
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <sc-table
    [columns]="columns"
    [config]="config"
    [dataset]="dataset"
    title="{{ tableTitle | translate }}"
    [hideHeader]="true"
    [toolbarItems]="toolbarItems"
  ></sc-table>
</ng-template>
