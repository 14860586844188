import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Site } from '@widgets/sites/models/site';
import { GuestSession } from '../models/guest-session';
import { GuestSessionFormsService } from '../services/guest-session-form.service';
import { GuestSessionsService } from '../services/guest-sessions.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-guest-sessions-form',
  templateUrl: 'guest-sessions-form.component.html',
})
export class GuestSessionFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: GuestSession;
  isSubmitting: boolean;
  selectedSite: Site;
  selectItems: { [key: string]: SelectItem[] };

  private subscribers: { [key: string]: any } = {};

  constructor(
    private guestSessionFormsService: GuestSessionFormsService,
    private guestSessionsService: GuestSessionsService,
    private locationsService: LocationsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;
    this.selectItems = {
      companies: this.sharedService.selectItems.companies,
      gender: this.sharedService.selectItems.gender,
      locales: this.sharedService.selectItems.locales,
      locations: [],
      sites: this.sharedService.selectItems.sites,
    };

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchGuestSession(this.data.id);
    }

    this.fetchLocations();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchGuestSession(id: string) {
    this.fetchingState++;

    this.subscribers.fetchGuestSession = this.guestSessionsService.getGuestSession(id).subscribe((result: any) => {
      this.formData = result.data;

      // CONVERT `DATE STRING` TO `JS DATE OBJECT`
      if (this.formData.validFrom) {
        this.formData.validFrom = this.sharedService.parseDateStringToDateObject(this.formData.validFrom);
      }
      if (this.formData.validTo) {
        this.formData.validTo = this.sharedService.parseDateStringToDateObject(this.formData.validTo);
      }

      this.form.patchValue(this.formData);
      this.fetchingState--;
    });
  }

  private fetchLocations() {
    this.fetchingState++;

    const options = {
      companyId: this.selectedSite.companyId,
      siteId: this.selectedSite.id,
    };

    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
      this.selectItems.locations = this.sharedService.createSelectItems(
        result.data.filter((d) => d.isActive && !d.isDeleted),
        false,
        'idx'
      );
      this.fetchingState--;
    });
  }

  private initForm() {
    this.form = this.guestSessionFormsService.initGuestSessionForm();

    if (!this.editMode) {
      this.form.patchValue({
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id,
      });
    }
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.form.getRawValue();
      delete formData.id;

      if (formData.validFrom) {
        formData.validFrom = this.sharedService.parseDateObjectToDateString(formData.validFrom);
      }
      if (formData.validTo) {
        formData.validTo = this.sharedService.parseDateObjectToDateString(formData.validTo);
      }

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(formData) {
    this.guestSessionsService.createGuestSession(formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(formData) {
    this.guestSessionsService.updateGuestSession(this.formData.id, formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.guestSessionsService.deleteGuestSession(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('GUEST_SESSION');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
