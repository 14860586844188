<div [formGroup]="group">
  <div class="ui-g">
    <div class="ui-g-12">
      <!-- LOCALE FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="locale"
          [form]="group"
          label="{{ 'LANGUAGE' | translate }}"
          [options]="optionData['locales']"
        ></sc-dropdown>
      </div>

      <!-- TEXT FIELD -->
      <div class="ui-g-12">
        <sc-textarea
          controlName="content"
          [form]="group"
          label="{{ 'CONTENT' | translate }}"
          [rows]="5"
          [secondaryLabel]="secondaryLabel"
        ></sc-textarea>
      </div>

      <!-- IS_ACTIVE -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="isActive"
          [form]="group"
          label="{{ 'ACTIVE' | translate }}"
        ></sc-checkbox-input>
      </div>

      <div class="ui-g-12">
        <button
          pButton
          type="button"
          class="ui-button-danger"
          label="{{ 'REMOVE' | translate }}"
          (click)="removed.emit()"
        ></button>
      </div>
    </div>
  </div>
</div>
