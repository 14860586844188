import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid';

import { SharedService } from '@shared/shared.service';

import { CustomHeaderComponent } from '@sc/table/custom-header/custom-header.component';

@Component({
  selector: 'sc-energy-efficiency-rating-detail-modal',
  templateUrl: 'detail-modal.component.html'
})
export class EnergyEfficiencyRatingDetailModalComponent
  implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  gridOptions: GridOptions;
  tableTitle: string;
  toolbarItems: string[];

  subscribers: { [key: string]: any };

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.subscribers = {};

    const total = { rating: 0, isTotal: true };
    if (this.data && this.data.details && this.data.details.length) {
      const details = [...this.data.details];
      for (let i = 0; i < details.length; i++) {
        total.rating += details[i].evaluationAmount * details[i].ratingAmount;
      }
    }

    total.rating = total.rating * 100;

    // console.log(total);
    this.gridOptions = {
      animateRows: false,
      enableColResize: true,
      enableSorting: true,
      floatingFilter: true,
      rowData: this.data.details,
      columnDefs: this.columns,
      pinnedBottomRowData: [total],
      frameworkComponents: { agColumnHeader: CustomHeaderComponent }
    };
  }

  ngOnDestroy() {
    // for (const key in this.subscribers) {
    //   if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
    //     this.subscribers[key].unsubscribe();
    //   }
    // }
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  get columns(): ColDef[] {
    return [
      {
        headerName: 'TOPIC',
        colId: 'topic',
        field: 'topic'
      },
      {
        headerName: 'EVALUATION',
        colId: 'evaluation',
        field: 'evaluation'
      },
      {
        headerName: 'RESULT',
        colId: 'result',
        field: 'resultInSec',
        valueFormatter: params => {
          if (params.data && params.data.isTotal) {
            return 'Total:';
          } else if (!params.value) {
            return '';
          }
          const h = params.value / 60 / 60;
          const m = (h - Math.floor(h)) * 60;
          const s = (m - Math.floor(m)) * 60;
          return (
            (h < 10 ? '0' : '') +
            Math.floor(h) +
            ':' +
            (m < 10 ? '0' : '') +
            Math.floor(m) +
            ':' +
            (s < 10 ? '0' : '') +
            Math.floor(s) +
            ' Hours'
          );
        }
      },
      {
        headerName: 'RATING',
        colId: 'rating',
        field: 'rating',
        valueFormatter: params => {
          if (!params.value) {
            return '';
          }
          return this.sharedService.numberFormat(params.value) + '%';
        }
      }
    ];
  }

  get showResultTable() {
    // if (this.fetchingState === 0) {
    //   return true;
    // }
    // return false;
    return true;
  }

  // get totalRating() {
  //   const total = { rating: 0 };
  //   if (this.data && this.data.details && this.data.details.length) {
  //     const details = [...this.data.details];
  //     for (let i = 0; i < details.length; i++) {
  //       total.rating += details[i].evaluationAmount * details[i].ratingAmout;

  //       // switch (details[i].tag) {
  //       //   case 'humidity':
  //       //   total.rating = details[i].evaluationAmount
  //       //   break;

  //       //   case 'temperature':
  //       //   total.rating = details[i]
  //       //   break;

  //       // }
  //     }
  //   }

  //   total.rating = total.rating * 100;

  //   return total;
  // }
}
