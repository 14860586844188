<div [formGroup]="form" class="ui-g">
  <!-- FIELD FIELD -->
  <div class="ui-g-5">
    <sc-dropdown
      controlName="field"
      [form]="form"
      label="{{ 'FORM_FIELD' | translate }}"
      [options]="selectItems['fields']"
    ></sc-dropdown>
  </div>

  <!-- VALUE FIELD -->
  <div class="ui-g-5" *ngIf="fieldValue && fieldOption">
    <div [ngSwitch]="fieldOption.displayType">
      <div *ngSwitchCase="'checkbox'" class="mt-4">
        <sc-checkbox-input
          controlName="value"
          [form]="form"
          label="{{ 'YES' | translate }}"
        ></sc-checkbox-input>
      </div>

      <div *ngSwitchCase="'color'">
        <sc-color-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
        ></sc-color-input>
      </div>

      <!-- <div *ngSwitchCase="'date'">
        <sc-datetime-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
        ></sc-datetime-input>
      </div>       -->

      <div *ngSwitchCase="'number'">
        <sc-number-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
        ></sc-number-input>
      </div>

      <!-- <div *ngSwitchCase="'radio'">
        <sc-radio-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
          [options]=""
        ></sc-radio-input>
      </div> -->

      <!-- <div *ngSwitchCase="'selectbox'">
        <sc-dropdown
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
          [options]=""
        ></sc-dropdown>
      </div> -->

      <div *ngSwitchCase="'textarea'">
        <sc-textarea
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
        ></sc-textarea>
      </div>

      <div *ngSwitchDefault>
        <sc-text-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
        ></sc-text-input>
      </div>
    </div>
  </div>

  <!-- ACTION -->
  <div class="ui-g-2 text-right">
    <button
      pButton
      type="button"
      class="ui-button-warning mt-3"
      icon="fa fa-trash"
      (click)="remove()"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'FORM_FIELD' | translate }}"
      tooltipPosition="top"
      appendTo="body"
    ></button>
  </div>
</div>
