import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-guest-response-cell',
  templateUrl: 'guest-response-cell.component.html',
})
export class GuestResponseCellComponent implements ICellRendererAngularComp {
  cell: any;
  guestResponses = [];

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
    if (this.cell.value) {
      const guestResponses = this.cell.value;
      this.guestResponses = Object.keys(guestResponses).map(id => guestResponses[id]);
    }
  }

  refresh(): boolean {
    return false;
  }

  getTotalGuestResponse(answer: 'yes' | 'no' | 'maybe') {
    if (this.guestResponses.length) {
      let total = 0;
      this.guestResponses.forEach(r => {
        const lastResponse = r[r.length - 1];
        if (lastResponse.status === answer) {
          total += 1;
        }
      });
      return total;
    }
    return 0;
  }
}
