import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '@app/widgets/users/models/user';

@Component({
  selector: 'sc-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnChanges {
  @Input()
  logoDefault: string;
  @Input()
  logoMobile: string;
  @Input()
  user: User;

  @Output()
  loggedOut = new EventEmitter();
  @Output()
  openMenuPanel = new EventEmitter();
  @Output()
  openNotificationPanel = new EventEmitter();
  @Output()
  viewMyAccount = new EventEmitter();

  showSiteSelector = false;
  showNotificationsIcon = false;
  screenWidth: any;
  typeScreenWidth: string;
  userId: number;
  username: string;

  private logo = 'assets/images/logo_full.png';
  private mobileLogo = 'assets/images/logo.png';

  constructor() {}

  ngOnInit() {
    this.onResize();

    this.logoDefault = this.logoDefault || this.logo;
    this.logoMobile = this.logoMobile || this.mobileLogo;
  }

  ngOnChanges(changes: SimpleChanges) {
    const logoDefault = changes.logoDefault && changes.logoDefault.currentValue;
    const logoMobile = changes.logoMobile && changes.logoMobile.currentValue;
    const user = changes.user && changes.user.currentValue;

    if (!logoDefault) {
      this.logoDefault = this.logo;
    }

    if (!logoMobile) {
      this.logoMobile = this.mobileLogo;
    }

    if (user) {
      this.userId = user.id;
      this.username = user.username;
      this.checkMainRole();
      this.checkEndUserRole();
    }
  }

  @HostListener('window:resize') onResize(event?: any) {
    this.screenWidth = (event && event.target && event.target.innerWidth) || window.innerWidth;
    if (this.screenWidth < 1000) {
      this.typeScreenWidth = 'sm';
    } else {
      this.typeScreenWidth = 'lg';
    }
  }

  private checkMainRole() {
    if (this.user && this.user.mainRole === 'sc') {
      this.showSiteSelector = true;
    } else {
      this.showSiteSelector = false;
    }
  }

  private checkEndUserRole() {
    if (this.user && this.user.mainRole === 'enduser') {
      this.showNotificationsIcon = true;
    } else {
      this.showNotificationsIcon = false;
    }
  }
}
