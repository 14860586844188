<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'LOCAL_WEATHER' | translate }} -->
        {{ widgetName }}
      </h4>

      <!-- <div class="sc-widget-menu"></div> -->
    </div>

    <ng-container
      *ngIf="isFirstTime && !error; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div *ngIf="error" class="weather-error">
    {{ "WEATHER_NOT_FOUND" | translate }}
  </div>
  <div *ngIf="!error" class="sc-widget-body local-weather-body">
    <div
      class="justify-start align-start sc-flex grow-1 direction-column justify-content-between w-100 local-weather-bg"
    >
      <div class="sc-flex grow-1 justify-content-between w-100">
        <div
          class="sc-flex grow-1 direction-column justify-content-center align-items-center local-weather-info"
        >
          <span class="local-weather-label">{{
            "TEMPERATURE" | translate
          }}</span>
          <span class="local-weather-value">{{ weatherTemp }} &deg;</span>
        </div>

        <div
          class="sc-flex grow-1 direction-column justify-content-center align-items-center local-weather-info"
        >
          <span class="local-weather-label">{{ "HUMIDITY" | translate }}</span>
          <span class="local-weather-value">{{ weatherHumidity }} %</span>
        </div>
      </div>
      <hr />
      <div class="sc-flex grow-1 justify-content-between w-100">
        <div
          class="sc-flex grow-1 direction-column justify-content-center align-items-center local-weather-info"
        >
          <img src="{{ weatherIcon() }}" width="60" height="60" />
          <!-- <small class="local-weather-label">{{ weatherDescription }}</small> -->
        </div>

        <div
          class="sc-flex grow-1 direction-column justify-content-center align-items-center local-weather-info"
        >
          <span class="local-weather-label">
            {{ "REAL_TIME" | translate }} {{ "SENSOR" | translate }}
          </span>
          <span class="badge local-weather-badge">
            {{ "RAIN" | translate }} {{ "DETECTED" | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
