import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalConfig } from '@sc/modal/modal-config';
import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { LocationsService } from '../services/locations.service';
import { SharedService } from '@shared/shared.service';
import { AuthService } from '@app/auth/services/auth.service';
import { GuestSessionComponent } from '@widgets/locations/guest-session/guest-session.component';
import { GuestSessionFormComponent } from '@widgets/locations/guest-session-form/guest-session-form.component';

@Component({
  selector: 'sc-location-action-cell',
  templateUrl: './location-action-cell.component.html',
})
export class LocationActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];
  displayCellActions = false;
  cell: any;
  childFormConfig: ModalConfig;
  formConfig: ModalConfig;
  qrCodeFormConfig: ModalConfig;
  statisticsFormConfig: ModalConfig;
  data: { [key: string]: any };
  context: any;
  containerComponent: any;
  parentComponent: any;
  node: any;

  private guestSession: DynamicDialogRef;
  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  agInit(params: any): void {
    this.cell = params;
    this.data = this.cell.data;
    this.context = this.cell.context;
    this.node = this.cell.node;
    // table component
    this.parentComponent = this.context.parentComponent;
    // locations table component
    this.containerComponent = this.context.containerComponent;

    if (this.data && !this.data.isDeleted) {
      this.displayCellActions = true;
    }

    this.formConfig = {
      name: 'LocationForm',
      data: this.data,
      options: { modalTitle: 'LOCATION_FORM_TITLE' },
    };

    this.childFormConfig = {
      name: 'LocationForm',
      data: { child: true, parentData: this.data },
      options: { modalTitle: 'LOCATION_FORM_TITLE' },
    };

    // this.qrCodeFormConfig = {
    //   name: 'LocationQrCode',
    //   data: this.data,
    //   options: {
    //     modalTitle: 'QR_CODE_FOR_LOCATION',
    //     modalTitleParams: {
    //       value: this.data.description,
    //     },
    //   },
    // };

    this.statisticsFormConfig = {
      name: 'LocationStatistics',
      data: this.data,
      options: {
        modalTitle: 'STATISTICS_OF_LOCATION',
        modalTitleParams: {
          value: this.data.description,
        },
        fullScreen: true,
      },
    };

    this.actions = [];

    this.actions.push({
      key: 'DETAILS',
      icon: 'fa fa-search',
      permission: 'location_r',
      type: 'button',
      onClick: this.viewLocationDetails.bind(this),
    });

    this.actions.push({
      key: 'STATISTICS',
      icon: 'fa fa-line-chart',
      type: 'modal',
      modalConfig: this.statisticsFormConfig,
    });

    if (this.authService.isAuthorized('admin')) {
      if (this.data.locationTypeKey === 'f') {
        // open floorplan
        this.actions.push({
          key: 'FLOORPLAN_SHOW_FLOOR_PLAN',
          icon: 'fa fa-map-o',
          permission: 'floorplan_r',
          type: 'button',
          onClick: this.viewFloorplan.bind(this),
        });
      } else if (this.data.locationTypeKey === 'u') {
        // open floorplan and pan to location
        this.actions.push({
          key: 'FLOORPLAN_SHOW_ON_FLOOR_PLAN',
          icon: 'fa fa-binoculars',
          permission: 'floorplan_r',
          type: 'button',
          onClick: this.viewFloorplan.bind(this),
        });
      }
    }

    // this.actions.push({
    //   key: 'QR_CODE',
    //   icon: 'fa fa-qrcode',
    //   type: 'modal',
    //   modalConfig: this.qrCodeFormConfig,
    // });

    this.actions.push({
      key: 'GUEST_SESSION',
      icon: 'fa fa-user',
      type: 'button',
      onClick: this.openGuestSession.bind(this),
    });

    this.actions.push({
      key: 'LOCATION_CREATE_CHILD',
      icon: 'fa fa-plus',
      permission: 'location_c',
      type: 'modal',
      modalConfig: this.childFormConfig,
      onModalClose: this.addChildRow.bind(this),
    });

    this.actions.push({
      key: 'EDIT',
      icon: 'fa fa-pencil',
      permission: 'location_u',
      type: 'modal',
      modalConfig: this.formConfig,
      onModalClose: this.updateRow.bind(this),
    });

    this.actions.push({
      key: 'DELETE',
      icon: 'fa fa-trash',
      permission: 'location_d',
      type: 'confirm',
      onConfirm: this.deleteRow.bind(this),
    });
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    // Clear subscribes
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearTimeouts(this.timeouts);
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  addChildRow(event) {
    if (event.result) {
      this.cell.context.containerComponent.fetchDataset();
    }
  }

  deleteRow() {
    this.locationsService.deleteLocation(this.data.id).subscribe(
      (result: any) => {
        this.cell.context.parentComponent.updateRow({
          id: this.data.id,
          isActive: false,
          isDeleted: true,
        });

        const text = this.sharedService.getTranslation('DELETE_SUCCESS');
        const title = this.sharedService.getTranslation('LOCATION');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const text = this.sharedService.getTranslation('DELETE_FAIL');
        const title = this.sharedService.getTranslation('LOCATION');
        this.sharedService.notify(title, text, 'error');
      }
    );
  }

  private viewLocationDetails() {
    const path = ['locations', this.data.id];
    const query = { site: this.sharedService.selectedSite.id };
    this.store.dispatch(new fromStore.Go({ path, query }));
  }

  private viewFloorplan() {
    const path = ['floorplan'];
    let floor = this.data.id;
    let location;
    if (this.data.locationTypeKey === 'u') {
      floor = this.data.parentId;
      location = this.data.id;
    }
    path.push(floor);
    const query = {
      site: this.sharedService.selectedSite.id,
      // floor,
      location,
    };
    this.store.dispatch(new fromStore.Go({ path, query }));
  }

  private openGuestSession() {
    const headerTitle = this.sharedService.getTranslation('GUEST_SESSION');
    this.guestSession = this.dialogService.open(GuestSessionComponent, {
      header: headerTitle,
      data: { location: this.data },
    });

    this.subscribers.guestSessionClose = this.guestSession.onClose.subscribe((result) => {
      if (typeof result !== undefined) {
        this.timeouts.beforeOpenGuestSessionForm = setTimeout(() => {
          this.openGuestSessionForm(result);
        }, 500);
      }
    });
  }

  private openGuestSessionForm(data: any) {
    const headerTitle = data.isNew ? 'NEW_GUEST_SESSION' : 'EDIT_GUEST_SESSION';
    const modal = this.dialogService.open(GuestSessionFormComponent, {
      header: this.sharedService.getTranslation(headerTitle),
      data: {
        editMode: !data.isNew,
        guestSession: data.guestSession,
        location: this.data,
      },
    });

    // this.subscribers.guestSessionDestroy = this.guestSession.onDestroy.subscribe(() => {
    //   // this.containerComponent.startAutoUpdate();
    // });
  }
}
