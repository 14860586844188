import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { DeviceBrand } from '../models/device-brand';
import { ModalConfig } from '@sc/modal/modal-config';

import { DeviceBrandsService } from '../services/device-brands.service';
import { SharedService } from '@shared/shared.service';

import { DeviceBrandActionCellComponent } from '../device-brand-action-cell/device-brand-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-device-brands-table',
  templateUrl: './device-brands-table.component.html'
})
export class DeviceBrandsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: DeviceBrand[];
  formConfig: ModalConfig;
  tableTitle: string;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceBrandsService: DeviceBrandsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.tableTitle = 'DEVICE_BRANDS';
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'DeviceBrandForm',
      options: { modalTitle: 'DEVICE_BRAND_FORM_TITLE' }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.DATASET = this.deviceBrandsService
      .getDeviceBrands()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceBrandActionCellComponent
      }
    ];
  }
}
