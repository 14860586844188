import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-command-form',
  templateUrl: 'command-form.component.html',
})
export class CommandFormComponent implements OnInit, OnDestroy {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  optionData: any;
  @Output()
  removed = new EventEmitter();

  commandActions: any;
  actions: SelectItem[];
  parameters: SelectItem[];
  isEnd: boolean;
  isEditMode: boolean;
  subscribers: { [key: string]: any };

  constructor() {}

  get commandNumber() {
    return this.groupId + 1;
  }

  ngOnInit() {
    this.subscribers = {};

    this.group.patchValue({ position: this.groupId });

    if (this.group.value.deviceTypeId) {
      this.getCommandAction(this.group.value.deviceTypeId);
    } else if (!this.group.value.deviceTypeId) {
      this.isEditMode = false;
    }

    this.onFormValueChange();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  onFormValueChange(): void {
    this.subscribers.deviceTypeId = this.group.get('deviceTypeId').valueChanges.subscribe((val) => {
      if (val) {
        this.group.patchValue({
          action: null,
          delay: null,
          parameters: [],
        });
        this.getCommandAction(val);
        this.parameters = [];
      }
    });

    this.subscribers.action = this.group.get('action').valueChanges.subscribe((val) => {
      if (val) {
        this.group.patchValue({
          delay: null,
          parameters: [],
        });
        this.getCommandActionParameters(val);
      }
    });
  }

  getCommandAction(deviceTypeId): void {
    const deviceTypeSrc = this.optionData.deviceTypesSrc.find((d) => d.id === deviceTypeId);

    this.commandActions = deviceTypeSrc.commandActions;
    this.actions = this.commandActions
      .filter((ca) => ca.isActive)
      .map((ca) => ({
        label: ca.commandActionId,
        value: ca.commandActionId,
      }));

    if (this.group && this.group.value && this.group.value.action) {
      this.getCommandActionParameters(this.group.value.action);
    }
  }

  getCommandActionParameters(actionId): void {
    if (this.group && this.group.value && this.group.value.deviceTypeId) {
      const action = this.commandActions.find((ca: any) => ca.commandActionId === actionId);
      if (action) {
        this.parameters = action.parameters.map((param) => ({
          label: param.parameterId,
          value: param.parameterId,
        }));
      }
    }
  }
}
