import { Action } from '@ngrx/store';
import { Device } from '@widgets/devices/models/device';
import { DeviceRequest } from '@widgets/devices/models/device-request';
import { DeviceDetails } from '@app/widgets/devices/models/device-details';

export const LOAD_DEVICE = '[Devices] Load Device Details';
export const LOAD_DEVICE_FAIL = '[Devices] Load Device Details Fail';
export const LOAD_DEVICE_SUCCESS = '[Devices] Load Device Details Success';
export const LOAD_DEVICES = '[Devices] Load Devices';
export const LOAD_DEVICES_FAIL = '[Devices] Load Devices Fail';
export const LOAD_DEVICES_SUCCESS = '[Devices] Load Devices Success';
export const RESET_DEVICES = '[Devices] Reset Devices';
export const SELECT_DEVICE = '[Devices] Select Device';

export class LoadDevice implements Action {
  readonly type = LOAD_DEVICE;
  constructor(public payload: number) {}
}

export class LoadDeviceFail implements Action {
  readonly type = LOAD_DEVICE_FAIL;
  constructor(public payload: any) {}
}

export class LoadDeviceSuccess implements Action {
  readonly type = LOAD_DEVICE_SUCCESS;
  constructor(public payload: DeviceDetails) {}
}

export class LoadDevices implements Action {
  readonly type = LOAD_DEVICES;
  constructor(public payload: DeviceRequest) {}
}

export class LoadDevicesFail implements Action {
  readonly type = LOAD_DEVICES_FAIL;
  constructor(public payload: any) {}
}

export class LoadDevicesSuccess implements Action {
  readonly type = LOAD_DEVICES_SUCCESS;
  constructor(public payload: Device[]) {}
}

export class ResetDevices implements Action {
  readonly type = RESET_DEVICES;
}

export class SelectDevice implements Action {
  readonly type = SELECT_DEVICE;
  constructor(public payload: number) {}
}

// ACTION TYPES
export type DevicesAction =
  | LoadDevices
  | LoadDevicesFail
  | LoadDevicesSuccess
  | LoadDevice
  | LoadDeviceFail
  | LoadDeviceSuccess
  | ResetDevices
  | SelectDevice;
