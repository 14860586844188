<div class="instruction-form" [formGroup]="form">
  <div class="ui-g">
    <div class="ui-g-12">
      <!-- <sc-file-input
        controlName="url"
        [form]="form"
        label="{{ 'MANUFACTURER_DOCUMENTATION' | translate }}"
      ></sc-file-input> -->

      <sc-local-file-input
        controlName="url"
        [form]="form"
        label="{{ 'MANUFACTURER_DOCUMENTATION' | translate }}"
      ></sc-local-file-input>
    </div>
  </div>

  <div class="ui-g" formArrayName="locales" *ngIf="localesForm">
    <div class="ui-g-12">
      <p-accordion [activeIndex]="0">
        <p-accordionTab
          *ngFor="let ctrl of localesForm.controls; let i = index"
          [formGroupName]="i"
        >
          <p-header>
            <span
              class="flag-icon"
              [ngClass]="getFlag(ctrl?.get('locale')?.value)"
            ></span>
            {{ ctrl?.get("locale")?.value | uppercase }}
          </p-header>

          <div class="ui-g">
            <div class="ui-g-12">
              <sc-text-editor-input
                [form]="ctrl"
                controlName="full"
                label="{{ 'FULL_INSTRUCTION' | translate }}"
              ></sc-text-editor-input>
            </div>

            <div class="ui-g-12">
              <sc-text-editor-input
                [form]="ctrl"
                controlName="inclusion"
                label="{{ 'INCLUSION_INSTRUCTION' | translate }}"
              ></sc-text-editor-input>
            </div>

            <div class="ui-g-12">
              <sc-text-editor-input
                [form]="ctrl"
                controlName="exclusion"
                label="{{ 'EXCLUSION_INSTRUCTION' | translate }}"
              ></sc-text-editor-input>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
