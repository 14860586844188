import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { Site } from '@widgets/sites/models/site';

import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

import * as moment from 'moment-timezone';

@Component({
  selector: 'sc-abnormal-occupancy-alert',
  templateUrl: './abnormal-occupancy-alert.component.html',
  styleUrls: ['./abnormal-occupancy-alert.component.scss'],
})
export class AbnormalOccupancyAlertComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  fetchInterval: any;
  isFetching: boolean;
  isFirstTime: boolean;
  dataset: any;
  form: FormGroup;
  durationAfterDoorCloseds: SelectItem[];
  widgetName: string = '';

  private selectedDate: any;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.WATCH_SITE = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.initForm();
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private initForm() {
    this.durationAfterDoorCloseds = [
      { label: '300', value: 300 },
      { label: '600', value: 600 },
      { label: '900', value: 900 },
      { label: '1800', value: 1800 },
      { label: '2700', value: 2700 },
      { label: '3600', value: 3600 },
    ];

    const from = moment.tz(this.selectedSite.timezone).subtract(1, 'day').utc().format('DD.MM.YYYYTHH:mm:ss');
    const to = moment.tz(this.selectedSite.timezone).utc().format('DD.MM.YYYYTHH:mm:ss');

    this.form = this.formBuilder.group({
      from,
      to,
      durationAfterDoorClosed: 900,
    });
  }

  fetchDataset() {
    if (!this.isFetching && this.selectedSite && this.selectedSite.id) {
      this.isFetching = true;
      const formData = this.form.value;
      this.subscribers.GET_DATASET = this.widgetService.getAbnormalOccupancy(this.selectedSite.id, formData).subscribe(
        (result: any) => {
          this.dataset = result.data;
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  onSelectDate(event) {
    this.form.patchValue({ from: event.from, to: event.to });
  }

  formatDate(date) {
    return this.sharedService.dateFormat(date);
  }
}
