import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'non-recurring-form',
  templateUrl: 'non-recurring-form.component.html'
})
export class NonRecurringFormComponent implements OnInit {
  @Input() group: FormGroup;

  constructor() { }

  ngOnInit() {}
}
