import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CustomAppActionCellComponent } from './custom-app-action-cell/custom-app-action-cell.component';
import { CustomAppFormComponent } from './custom-app-form/custom-app-form.component';
import { CustomAppsTableComponent } from './custom-apps-table/custom-apps-table.component';

import { CustomAppFormsService } from './services/custom-app-forms.service';
import { CustomAppsService } from './services/custom-apps.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    CustomAppActionCellComponent,
    CustomAppFormComponent,
    CustomAppsTableComponent
  ],
  entryComponents: [
    CustomAppActionCellComponent,
    CustomAppFormComponent,
    CustomAppsTableComponent
  ],
  providers: [CustomAppFormsService, CustomAppsService]
})
export class CustomAppsModule {}
