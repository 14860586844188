import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '@widgets/users/models/user';
import { MenuItem } from './models/menu-item';

@Component({
  selector: 'sc-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit, OnChanges {
  @Input()
  menu: MenuItem[];
  @Input()
  expand: boolean;
  @Input()
  overlay: boolean;
  @Input()
  user: User;
  @Input()
  logoDefault: string;
  @Input()
  logoMobile: string;

  @Output()
  menuClicked = new EventEmitter<MenuItem>();
  @Output()
  toggled = new EventEmitter<any>();

  displayName: string;
  selectedMenu: MenuItem;

  private logo = 'assets/images/logo-full.svg';
  private mobileLogo = 'assets/images/logo.svg';

  constructor() {}

  ngOnInit() {
    this.logoDefault = this.logoDefault || this.logo;
    this.logoMobile = this.logoMobile
      ? this.logoMobile
      : !this.logoMobile && this.logoDefault !== this.logo
      ? null
      : this.mobileLogo;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && changes.user.currentValue) {
      this.updateDisplayName();
    }
  }

  onMenuClicked(menu: MenuItem) {
    this.selectedMenu = menu;
    this.menuClicked.emit(menu);
  }

  onToggleClicked() {
    this.toggled.emit();
  }

  onHoverMenu() {
    if (!this.expand) {
      this.toggled.emit();
    }
  }

  private updateDisplayName() {
    if (this.user.firstname) {
      this.displayName = this.user.firstname;

      if (this.user.lastname) {
        this.displayName += ' ' + this.user.lastname;
      }
    } else {
      this.displayName = this.user.username;
    }
  }

  getLogo(expand: boolean) {
    if (expand) {
      return `url("${this.logoDefault}")`;
    } else {
      return this.logoMobile ? `url("${this.logoMobile}")` : 'none';
    }
  }
}
