import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-field-form',
  templateUrl: './field-form.component.html',
  styleUrls: ['./field-form.component.scss']
})
export class FieldFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() formId: number;
  @Input() data: { [key: string]: any };

  @Output() removed = new EventEmitter<any>();

  selectItems: SelectItem[];
  rawData: { [key: string]: any };
  fieldOption: any;

  constructor() {}

  ngOnInit() {
    this.selectItems = this.data.selectItems;
    this.rawData = this.data.rawData;

    // watch field value
    this.form.controls['field'].valueChanges.subscribe(value => {
      // find field option
      this.fieldOption = this.rawData.fields.find(f => f.name === value);

      // patch default value to value field
      if (this.fieldOption.defaultValue) {
        // is defaultValue number ? convert it!
        let defaultValue = isNaN(this.fieldOption.defaultValue)
          ? this.fieldOption.defaultValue
          : +this.fieldOption.defaultValue;

        // convert 1/0 to true/false
        if (this.fieldOption.type === 'TINYINT') {
          defaultValue = defaultValue === 1 ? true : false;
        }

        this.form.patchValue({
          value: defaultValue,
          type: this.fieldOption.type
        });
      }
    });
  }

  remove() {
    this.removed.emit();
  }

  get fieldValue() {
    return this.form.get('field').value;
  }
}
