import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { RulesService, UtilsService } from '../../services';
import {
  DataSources,
  RuleEditorModes,
  RuleTemplateCommand,
  SelectItems,
  CustomFunction,
  CustomFunctionParameter,
} from '../../models';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-trigger-device',
  templateUrl: './trigger-device.component.html',
  styleUrls: ['./trigger-device.component.scss'],
})
export class TriggerDeviceComponent implements OnInit {
  _ruleEditorModes = RuleEditorModes;
  actions: any[];
  valueStructure: any;
  modifiers: any;
  devices: any[];
  variables: CustomFunctionParameter[];

  @Input()
  commandForm: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  viewMode: string;

  @Input()
  isTemplate: boolean;

  @Input()
  isFromScratch: boolean;

  @Input()
  templateCommands: RuleTemplateCommand[];

  VALUE_FIXED = 'fixed';
  VALUE_FUNCTION = 'function';

  private isInEditMode = false;
  private isExtraCommand = false;
  private subscribers: { [key: string]: any } = {};
  private customFunction: CustomFunction;

  constructor(
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private rulesService: RulesService
  ) {}

  ngOnInit(): void {
    if (this.commandForm) {
      const formValue = this.commandForm.value;

      // check if command is an extra over template
      if (!this.isFromScratch && !formValue.action) {
        this.isExtraCommand = true;
      } else if (!this.isFromScratch && formValue.action && formValue.target) {
        this.isInEditMode = true;
      }

      if (formValue.target) {
        const device = this.getDevice(formValue.target);
        if (device) {
          this.updateActions(device.device_type);
          this.updateModifiers(device.device_type);
        }
      } else if (formValue.target_type) {
        this.updateActions(formValue.target_type);
        this.updateModifiers(formValue.target_type);
      }

      // filter device by target type
      this.updateDevicesList(formValue.target_type);

      // if (!this.isTemplate) {
      // prevent value field from editable
      // if (formValue.value !== null) {
      //   this.commandForm.get('value').disable();
      // }
      // }

      this.subscribeOnFormValueChanges();

      if (formValue && formValue.variables && formValue.variables.length) {
        this.variables = formValue.variables;
      }

      this.subscribeTarget();
    }
  }

  ngOnDestroy(): void {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private subscribeTarget() {
    if (this.isTemplate) {
      this.subscribers.target_type = this.commandForm.get('target_type').valueChanges.subscribe((value) => {
        this.resetData();

        if (value) {
          this.updateActions(value);
          this.updateModifiers(value);
        }
      });
    } else if (this.isFromScratch || this.isExtraCommand || this.isInEditMode) {
      this.subscribers.target = this.commandForm.get('target').valueChanges.subscribe((value) => {
        this.resetData();
        const device = this.getDevice(value);
        if (device) {
          this.updateActions(device.device_type);
          this.updateModifiers(device.device_type);
        }
      });
    }
  }

  private getDevice(idx: string) {
    if (!this.dataSources || !this.dataSources.devices || !this.dataSources.devices.length) {
      return;
    }
    return this.dataSources.devices.find((d) => d.idx === idx);
  }

  private getDeviceType(deviceTypeKey: string) {
    if (!this.dataSources || !this.dataSources.deviceTypes || !this.dataSources.deviceTypes.length) {
      return;
    }
    return this.dataSources.deviceTypes.find((d) => d.key === deviceTypeKey);
  }

  private getTargetDeviceTypeActions(deviceTypeKey: string) {
    const deviceType = this.getDeviceType(deviceTypeKey);
    if (deviceType && deviceType.actions) {
      return Object.keys(deviceType.actions).map((actionKey) => {
        const action = deviceType.actions[actionKey];
        return { label: action.label, value: actionKey };
      });
    }
    return;
  }

  private getDeviceTypeValueStructure(deviceTypeKey: string) {
    const structure = this.getDeviceType(deviceTypeKey);
    if (structure && structure.render) {
      return { ...structure.render };
    }
    return;
  }

  private resetData() {
    this.actions = [];
    this.valueStructure = null;
    this.commandForm.get('action').reset();
    this.commandForm.get('action').disable();
    this.commandForm.get('value').reset();
  }

  private updateActions(deviceTypeKey: string) {
    const actions = this.getTargetDeviceTypeActions(deviceTypeKey);
    if (actions) {
      this.actions = actions;
      // unlock action field
      this.commandForm.get('action').enable();

      if (this.actions && this.actions.length === 1) {
        // auto select action if there is only one
        this.commandForm.get('action').setValue(this.actions[0].value);
        // lock action field
        this.commandForm.get('action').disable();
      }
    }
  }

  private updateModifiers(deviceTypeKey: string) {
    const valueStructure = this.getDeviceTypeValueStructure(deviceTypeKey);
    if (valueStructure) {
      this.valueStructure = valueStructure;

      if (this.valueStructure.modifiers) {
        this.modifiers = this.valueStructure.modifiers;

        this.commandForm.addControl('modifiers', this.formBuilder.array([]));
        this.commandForm.updateValueAndValidity();
      }
    }
  }

  private updateDevicesList(deviceTypeKey?: string) {
    let filteredDevices = [];

    if (
      this.dataSources &&
      this.dataSources.commandDevicesTreeWithOnlyWriteableChildren &&
      this.dataSources.commandDevicesTreeWithOnlyWriteableChildren.length
    ) {
      let devicesTree = this.dataSources.commandDevicesTreeWithOnlyWriteableChildren;
      for (let vdh of devicesTree) {
        let filteredDevice = Object.assign({}, vdh);
        filteredDevice.children = [];
        for (let child of vdh.children) {
          let vd = Object.assign({}, child);
          if (vd.data.device_type != deviceTypeKey) {
            continue;
          }
          filteredDevice.children.push(vd);
        }

        if (filteredDevice.children.length) {
          filteredDevices.push(filteredDevice);
        }
      }
      this.devices = filteredDevices;
    }
  }

  private subscribeOnFormValueChanges() {
    this.subscribers.customFunctionId = this.commandForm.get('custom_function_idx').valueChanges.subscribe((value) => {
      if (this.dataSources.customFunctions && this.dataSources.customFunctions.length) {
        this.customFunction = this.dataSources.customFunctions.find((item) => item.idx === value);
        let variables;
        if (this.customFunction && this.customFunction.parameters && this.customFunction.parameters.length) {
          try {
            variables = JSON.parse(this.customFunction.parameters as string);
          } catch (error) {}
        }
        this.variables = variables;
      }
    });
  }
}
