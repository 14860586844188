import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeviceTask } from '../models/device-task';
import { DeviceTaskRequestOptions } from '../models/device-task-request-options';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class DeviceTasksService {
  private apiUrl = config().apiUrl + '/deviceTasks';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  private verifyData(data: any): DeviceTask {
    const newData = { ...data };
    delete newData.id;
    return newData;
  }

  private createFormData(data: DeviceTask) {
    const formData: FormData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'parameters') {
          if (!data[key] || !data[key].length) {
            continue;
          }
          for (let i = 0; i < data[key].length; i++) {
            formData.append('parameters[' + i + '][key]', data[key][i].key);
            formData.append('parameters[' + i + '][value]', data[key][i].value);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    return formData;
  }

  getDeviceTask(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getDeviceTasks(options?: DeviceTaskRequestOptions) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  createDeviceTask(data: DeviceTask) {
    const formData = this.createFormData(data);
    return this.httpClient.post(this.apiUrl, formData);
  }

  updateDeviceTask(id: number, data: DeviceTask) {
    const formData = this.createFormData(this.verifyData(data));
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }

  deleteDeviceTask(id: number) {
    const formData = this.createFormData({ is_deleted: true });
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }
}
