<div class="location-rules">
  <!-- <div class="location-rules-header">
    <div class="sc-flex align-center">
      <h4 class="header-title">
        {{ 'RULES' | translate }}
      </h4>
    </div>
  </div> -->

  <div class="location-rules-body">
    <sc-table
      [columns]="columns"
      [config]="config"
      [formConfig]="formConfig"
      [lazyload]="true"
      [title]="tableTitle"
      (afterInit)="afterInitTable($event)"
      (displayColumnsChange)="updateVisibleColumns($event)"
      (reloadData)="fetchDataset()"
      [hideIcon]="true"
    ></sc-table>
  </div>
</div>
