import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AutomationLogsDeviceCellComponent } from './al-device-cell/al-device-cell.component';
import { AutomationLogsMessageCellComponent } from './al-message-cell/al-message-cell.component';
import { AutomationLogsTimeCellComponent } from './al-time-cell/al-time-cell.component';
import { AutomationLogsTableComponent } from './al-table/al-table.component';

const COMPONENTS: any[] = [
  AutomationLogsDeviceCellComponent,
  AutomationLogsMessageCellComponent,
  AutomationLogsTimeCellComponent,
  AutomationLogsTableComponent
];

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS
})
export class AutomatioLogsOverviewModule {}
