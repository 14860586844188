<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!-- HEADER -->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>

          <div class="ui-g-6 text-right">
            <p-toggleButton
              formControlName="isActive"
              offIcon="fa fa-square-o"
              offLabel="{{ 'ACTIVE' | translate }}"
              onIcon="fa fa-check-square-o"
              onLabel="{{ 'ACTIVE' | translate }}"
            ></p-toggleButton>
          </div>
        </div>
      </p-header>

      <!-- BODY -->
      <div class="ui-g ui-g-nopad">
        <!-- NAME FIELD -->
        <div class="ui-g-12 ui-g-nopad">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- DESCRIPTION FIELD -->
        <div class="ui-g-12 ui-g-nopad">
          <sc-textarea
            controlName="description"
            [form]="form"
            label="{{ 'DESCRIPTION' | translate }}"
          ></sc-textarea>
        </div>

        <!-- CONSUMPTION FIELD -->
        <h3>{{ 'CONSUMPTION' | translate }}</h3>
        <div class="ui-g-12 ui-g-nopad">
          <!-- NORMAL_CONSUMPTION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="normalConsumption"
              [form]="form"
              label="{{ 'NORMAL_CONSUMPTION' | translate }}"
            ></sc-number-input>
          </div>

          <!-- NORMAL_CONSUMPTION_DEVIATION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="normalConsumptionDeviation"
              [form]="form"
              label="{{ 'NORMAL_CONSUMPTION_DEVIATION' | translate }}"
            ></sc-number-input>
          </div>

          <!-- NORMAL_LIGHTING_CONSUMPTION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="normalLightingConsumption"
              [form]="form"
              label="{{ 'NORMAL_LIGHTING_CONSUMPTION' | translate }}"
            ></sc-number-input>
          </div>

          <!-- NORMAL_LIGHTING_CONSUMPTION_DEVIATION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="normalLightingConsumptionDeviation"
              [form]="form"
              label="{{ 'NORMAL_LIGHTING_CONSUMPTION_DEVIATION' | translate }}"
            ></sc-number-input>
          </div>

          <!-- NORMAL_APPLIANCE_CONSUMPTION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="normalApplianceConsumption"
              [form]="form"
              label="{{ 'NORMAL_APPLIANCE_CONSUMPTION' | translate }}"
            ></sc-number-input>
          </div>

          <!-- NORMAL_APPLIANCE_CONSUMPTION_DEVIATION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="normalApplianceConsumptionDeviation"
              [form]="form"
              label="{{ 'NORMAL_APPLIANCE_CONSUMPTION_DEVIATION' | translate }}"
            ></sc-number-input>
          </div>

          <!-- NORMAL_AC_CONSUMPTION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="normalAcConsumption"
              [form]="form"
              label="{{ 'NORMAL_AC_CONSUMPTION' | translate }}"
            ></sc-number-input>
          </div>

          <!-- NORMAL_AC_CONSUMPTION_DEVIATION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="normalAcConsumptionDeviation"
              [form]="form"
              label="{{ 'NORMAL_AC_CONSUMPTION_DEVIATION' | translate }}"
            ></sc-number-input>
          </div>

          <!-- TOTAL_CONSUMPTION_IGNORE_DEVIATION FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="totalConsumptionIgnoreDeviation"
              [form]="form"
              label="{{ 'TOTAL_CONSUMPTION_IGNORE_DEVIATION' | translate }}"
            ></sc-number-input>
          </div>
        </div>

        <!-- TEMPERATURE FIELD -->
        <h3>{{ 'TEMPERATURE' | translate }}</h3>
        <div class="ui-g-12 ui-g-nopad">
          <!-- MIN_TEMPERATURE FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="minTemperature"
              [form]="form"
              label="{{ 'MIN_TEMPERATURE' | translate }}"
            ></sc-number-input>
          </div>

          <!-- MAX_TEMPERATURE FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="maxTemperature"
              [form]="form"
              label="{{ 'MAX_TEMPERATURE' | translate }}"
            ></sc-number-input>
          </div>

          <!-- TEMPERATURE_MEASUREMENT_ACCURACY FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="temperatureMeasurementAccuracy"
              [form]="form"
              label="{{ 'TEMPERATURE_MEASUREMENT_ACCURACY' | translate }}"
            ></sc-number-input>
          </div>

          <!-- TEMPERATURE_IGNORE_DEVIATION_PERCENT FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="temperatureIgnoreDeviationPercent"
              [form]="form"
              label="{{ 'TEMPERATURE_IGNORE_DEVIATION_PERCENT' | translate }}"
            ></sc-number-input>
          </div>

          <!-- COMFORT_TEMPERATURE FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="comfortTemperature"
              [form]="form"
              label="{{ 'COMFORT_TEMPERATURE' | translate }}"
            ></sc-number-input>
          </div>

          <!-- DISPLAYED_TEMPERATURE_MODIFIER FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="displayedTemperatureModifier"
              [form]="form"
              label="{{ 'DISPLAYED_TEMPERATURE_MODIFIER' | translate }}"
            ></sc-number-input>
          </div>
        </div>

        <!-- HUMIDITY FIELD -->
        <h3>{{ 'HUMIDITY' | translate }}</h3>
        <div class="ui-g-12 ui-g-nopad">
          <!-- MIN_HUMIDITY FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="minHumidity"
              [form]="form"
              label="{{ 'MIN_HUMIDITY' | translate }}"
            ></sc-number-input>
          </div>

          <!-- MAX_HUMIDITY FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="maxHumidity"
              [form]="form"
              label="{{ 'MAX_HUMIDITY' | translate }}"
            ></sc-number-input>
          </div>

          <!-- HUMIDITY_MEASUREMENT_ACCURACY FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="humidityMeasurementAccuracy"
              [form]="form"
              label="{{ 'HUMIDITY_MEASUREMENT_ACCURACY' | translate }}"
            ></sc-number-input>
          </div>

          <!-- HUMIDITY_IGNORE_DEVIATION_PERCENT FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="humidityIgnoreDeviationPercent"
              [form]="form"
              label="{{ 'HUMIDITY_IGNORE_DEVIATION_PERCENT' | translate }}"
            ></sc-number-input>
          </div>

          <!-- COMFORT_HUMIDITY FIELD -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="comfortHumidity"
              [form]="form"
              label="{{ 'COMFORT_HUMIDITY' | translate }}"
            ></sc-number-input>
          </div>
        </div>

        <!-- AVG_TEMPERATURE_DROPPING -->
        <h3>{{ 'AVG_TEMPERATURE_DROPPING' | translate }}</h3>
        <div class="TODO:RULE_HEADER_SECTION ui-g-12 grey lighten-2">
          <div class="ui-g-12">
            <div class="ui-g-2">
              {{ 'SECONDS_PER_DEGREE' | translate }}
            </div>
            <div class="ui-g-3">
                {{ 'TIME' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'OUTDOOR_TEMPERATURE' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'INDOOR_TEMPERATURE' | translate }}
            </div>
            <div class="ui-g-1">
              <button
                pButton
                type="button"
                class="ui-button-success"
                icon="fa fa-plus"
                (click)="addAvgTemperature('avgTemperatureDropping')"
              ></button>
            </div>
          </div>
        </div>
        <div formArrayName="avgTemperatureDropping" class="ui-g-12">
          <div [formGroupName]="i" *ngFor="let atd of avgTemperatureDroppingFormArray.controls; let i=index">
            <avg-temperature-form
              [group]="atd"
              [groupId]="i"
              (onRemove)="removeAvgTemperature('avgTemperatureDropping', i)"
            ></avg-temperature-form>
          </div>
        </div>

        <!-- AVG_TEMPERATURE_RAISING -->
        <h3>{{ 'AVG_TEMPERATURE_RAISING' | translate }}</h3>
        <div class="TODO:RULE_HEADER_SECTION ui-g-12 grey lighten-2" >
          <div class="ui-g-12">
            <div class="ui-g-2">
              {{ 'SECONDS_PER_DEGREE' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'TIME' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'OUTDOOR_TEMPERATURE' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'INDOOR_TEMPERATURE' | translate }}
            </div>
            <div class="ui-g-1">
              <button
                pButton
                type="button"
                class="ui-button-success"
                icon="fa fa-plus"
                (click)="addAvgTemperature('avgTemperatureRaising')"
              ></button>
            </div>
          </div>
        </div>
        <div formArrayName="avgTemperatureRaising" class="ui-g-12">
          <div [formGroupName]="i" *ngFor="let atr of avgTemperatureRaisingFormArray.controls; let i=index">
            <avg-temperature-form
              [group]="atr"
              [groupId]="i"
              (onRemove)="removeAvgTemperature('avgTemperatureRaising', i)"
            ></avg-temperature-form>
          </div>
        </div>

        <!-- AVG_HUMIDITY_DROPPING -->
        <h3>{{ 'AVG_HUMIDITY_DROPPING' | translate }}</h3>
        <div class="TODO:RULE_HEADER_SECTION ui-g-12 grey lighten-2">
          <div class="ui-g-12">
            <div class="ui-g-2">
              {{ 'SECONDS_PER_DEGREE' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'TIME' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'OUTDOOR_HUMIDITY' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'INDOOR_HUMIDITY' | translate }}
            </div>
            <div class="ui-g-1">
              <button
                pButton
                type="button"
                class="ui-button-success"
                icon="fa fa-plus"
                (click)="addAvgHumidity('avgHumidityDropping')"
              ></button>
            </div>
          </div>
        </div>
        <div formArrayName="avgHumidityDropping" class="ui-g-12">
          <div [formGroupName]="i" *ngFor="let ahd of avgHumidityDroppingFormArray.controls; let i=index">
            <avg-humidity-form
              [group]="ahd"
              [groupId]="i"
              (onRemove)="removeAvgHumidity('avgHumidityDropping', i)"
            ></avg-humidity-form>
          </div>
        </div>

        <!-- AVG_HUMIDITY_RAISING -->
        <h3>{{ 'AVG_HUMIDITY_RAISING' | translate }}</h3>
        <div class="TODO:RULE_HEADER_SECTION ui-g-12 grey lighten-2">
          <div class="ui-g-12">
            <div class="ui-g-2">
              {{ 'SECONDS_PER_DEGREE' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'TIME' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'OUTDOOR_HUMIDITY' | translate }}
            </div>
            <div class="ui-g-3">
              {{ 'INDOOR_HUMIDITY' | translate }}
            </div>
            <div class="ui-g-1">
              <button
                pButton
                type="button"
                icon="fa fa-plus"
                class="ui-button-success"
                (click)="addAvgHumidity('avgHumidityRaising')"
              ></button>
            </div>
          </div>
        </div>
        <div formArrayName="avgHumidityRaising" class="ui-g-12">
          <div [formGroupName]="i" *ngFor="let ahr of avgHumidityRaisingFormArray.controls; let i=index">
            <avg-humidity-form
              [group]="ahr"
              [groupId]="i"
              (onRemove)="removeAvgHumidity('avgHumidityRaising', i)"
            ></avg-humidity-form>
          </div>
        </div>

        <!-- ERROR MESSAGE -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>
      </div>

      <!-- FOOTER -->
      <p-footer>
        <div class="ui-g-6">
          <sc-confirm-trigger
            *ngIf="editMode && !formData?.isDeleted"
            buttonIcon="fa fa-trash"
            buttonClass="ui-button-danger"
            buttonLabel="{{ 'DELETE' | translate }}"
            (onAccept)="delete()"
          ></sc-confirm-trigger>
        </div>
        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            label="{{ 'CANCEL' | translate }}"
            (click)="dismissModal('cancel')"
          ></button>
          <sc-submit-button
            *ngIf="!formData?.isDeleted || formData?.isActive"
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
