<div *ngIf="fetchingState === 0; else loadingIndicator">

<form [formGroup]="form" novalidate>
  <p-card>
    <p-header>
      <div class="ui-g">
        <div class="ui-g-6">
          <sc-text-input
            *ngIf="editMode"
            controlName="id"
            [form]="form"
            label="{{ 'ID' | translate }}"
          ></sc-text-input>
        </div>
        <div class="ui-g-6 text-right">
          <p-toggleButton
            formControlName="isActive"
            offIcon="fa fa-square-o"
            offLabel="{{ 'ACTIVE' | translate }}"
            onIcon="fa fa-check-square-o"
            onLabel="{{ 'ACTIVE' | translate }}"
          ></p-toggleButton>
        </div>
      </div>
    </p-header>

    <div class="ui-g">
      <div class="ui-g-12">
        <h2 class="mt-0">{{ 'BASIC_INFORMATION' | translate }}</h2>
      </div>

      <!-- NAME -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <!-- DESCRIPTION FIELD -->
      <div class="ui-g-12">
        <sc-textarea
          controlName="description"
          [form]="form"
          label="{{ 'DESCRIPTION' | translate }}"
        ></sc-textarea>
      </div>

      <!-- IS_PUBLIC FIELD -->
      <div class="ui-g-12">
        <sc-radio-input
          controlName="isPublic"
          [form]="form"
          label="{{ 'PUBLIC' | translate }}"
          [options]="selectItems['public']"
        ></sc-radio-input>
      </div>

      <!-- SITES FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="sites"
          [form]="form"
          label="{{ 'SITES' | translate }}"
          [options]="selectItems['sites']"
          [showLabel]="false"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>

      <!-- TIME_RANGE_MODE FIELD -->
      <div class="ui-g-12">
        <sc-radio-input
          controlName="timeRangeMode"
          [form]="form"
          label="{{ 'TIME_RANGE' | translate }}"
          [options]="selectItems['timeRangeModes']"
        ></sc-radio-input>
      </div>

      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-2">
          {{ 'LAST' | translate }}
        </div>

        <!-- RELATIVE_AGO_VALUE -->
        <div class="ui-g-5">
          <sc-number-input
            controlName="relativeAgoValue"
            [form]="form"
            label="{{ 'RELATIVE_AGO_VALUE' | translate }}"
            [showLabel]="false"
          ></sc-number-input>
        </div>

        <!-- RELATIVE_AGO_UNIT FIELD -->
        <div class="ui-g-5">
          <sc-dropdown
            controlName="relativeAgoUnit"
            [form]="form"
            label="{{ 'RELATIVE_AGO_UNIT' | translate }}"
            [options]="selectItems['relativeAgoUnits']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>
      </div>

      <!-- IS_INTERVAL_LIMITED FIELD -->
      <div class="ui-g-12">
        <sc-radio-input
          controlName="isIntervalLimited"
          [form]="form"
          label="{{ 'INTERVALS' | translate }}"
          [options]="selectItems['intervalLimit']"
        ></sc-radio-input>
      </div>

      <!-- INTERVAL_LIMIT -->
      <div class="ui-g-12">
        <div class="ui-g-2">
          {{ 'LIMIT' | translate }}
        </div>
        <div class="ui-g-6">
          <sc-number-input
            controlName="intervalLimit"
            [form]="form"
            label="{{ 'INTERVAL_LIMIT' | translate }}"
            [showLabel]="false"
          ></sc-number-input>
        </div>
      </div>

      <!-- OUTPUT FORM -->
      <div class="ui-g-12">
        <sc-output-form
          [form]="form"
          [optionData]="selectItems"
        ></sc-output-form>
      </div>

      <!-- ENABLING_MARKER FORM -->
      <div class="ui-g-12">
        <sc-enabling-marker-form
          [form]="form"
          [optionData]="selectItems"
        ></sc-enabling-marker-form>
      </div>

      <!-- DISABLING_MARKER FORM -->
      <div class="ui-g-12">
        <sc-disabling-marker-form
          [form]="form"
          [optionData]="selectItems"
        ></sc-disabling-marker-form>
      </div>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <p-footer>
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>
        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </p-footer>
  </p-card>
</form>

</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
