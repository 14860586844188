<div class="bulk-creator__content" *ngIf="fetchingState === 0; else loading">
  <ng-container [ngSwitch]="option">
    <ng-container *ngSwitchCase="'menu'">
      <ng-template [ngTemplateOutlet]="menu"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'units'">
      <ng-template [ngTemplateOutlet]="units"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'areas'">
      <ng-template [ngTemplateOutlet]="areas"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'devices'">
      <ng-template [ngTemplateOutlet]="devices"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'automations'">
      <ng-template [ngTemplateOutlet]="automations"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'gateways'">
      <ng-template [ngTemplateOutlet]="gateways"></ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #menu>
  <div class="bulk-creator__title">
    <div>{{ "BULK_CREATOR_TITLE" | translate }}</div>
  </div>
  <div class="bulk-creator__menu">
    <div
      *ngIf="data.id === 'locations'"
      class="bulk-creator__option"
      (click)="onSelectOption('units')"
    >
      <h1>{{ "UNITS" | translate }}</h1>
      <h2>{{ "BULK_CREATOR_UNITS_DESC" | translate }}</h2>
    </div>
    <div
      *ngIf="data.id === 'locations'"
      class="bulk-creator__option"
      (click)="onSelectOption('areas')"
    >
      <h1>{{ "AREAS" | translate }}</h1>
      <h2>{{ "BULK_CREATOR_AREAS_DESC" | translate }}</h2>
    </div>
    <div class="bulk-creator__option" (click)="onSelectOption('devices')">
      <h1>{{ "DEVICES" | translate }}</h1>
      <h2>{{ "BULK_CREATOR_DEVICES_DESC" | translate }}</h2>
    </div>
    <div
      *ngIf="!data.automationId"
      class="bulk-creator__option"
      (click)="onSelectOption('automations')"
    >
      <h1>{{ "AUTOMATIONS" | translate }}</h1>
      <h2>{{ "BULK_CREATOR_AUTOMATIONS_DESC" | translate }}</h2>
    </div>
    <div
      *ngIf="!data.automationId"
      class="bulk-creator__option"
      (click)="onSelectOption('gateways')"
    >
      <h1>{{ "GATEWAYS" | translate }}</h1>
      <h2>{{ "BULK_CREATOR_GATEWAYS_DESC" | translate }}</h2>
    </div>
  </div>
</ng-template>

<ng-template #units>
  <div class="bulk-creator__title">
    <div>{{ "BULK_CREATOR_UNITS_TITLE" | translate }}</div>
  </div>
  <sc-units-creator
    (onSubmit)="submit($event)"
    (onCancel)="cancel()"
  ></sc-units-creator>
</ng-template>

<ng-template #areas>
  <div class="bulk-creator__title">
    <div>{{ "BULK_CREATOR_AREAS_TITLE" | translate }}</div>
  </div>
  <sc-areas-creator
    (onSubmit)="submit($event)"
    (onCancel)="cancel()"
  ></sc-areas-creator>
</ng-template>

<ng-template #devices>
  <div class="bulk-creator__title">
    <div>
      {{ "BULK_CREATOR_DEVICES_TITLE" | translate }}
    </div>
  </div>
  <sc-devices-creator
    (onSubmit)="submit($event)"
    (onCancel)="cancel()"
    (onChangeStep)="changeStep($event)"
    [(step)]="step"
    [totalSteps]="totalSteps"
  ></sc-devices-creator>
</ng-template>

<ng-template #automations>
  <div class="bulk-creator__title">
    <div>{{ "BULK_CREATOR_AUTOMATIONS_TITLE" | translate }}</div>
  </div>
  <sc-automations-creator
    (onSubmit)="submit($event)"
    (onCancel)="cancel()"
  ></sc-automations-creator>
</ng-template>

<ng-template #gateways>
  <div class="bulk-creator__title">
    <div>
      {{ "BULK_CREATOR_GATEWAYS_TITLE" | translate }} {{ step }}/{{
        totalSteps
      }}
    </div>
  </div>
  <sc-automations-creator
    (onSubmit)="submit($event)"
    (onCancel)="cancel()"
    [isGateway]="true"
    (onChangeStep)="changeStep($event)"
    [(step)]="step"
    [totalSteps]="totalSteps"
  ></sc-automations-creator>
</ng-template>
