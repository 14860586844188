import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'multi-language-form',
  templateUrl: 'multi-language-form.component.html',
})
export class MultiLanguageFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() groupId: any;
  @Input() optionData: { [key: string]: { [key: string]: any }[] };
  @Output() onRemove = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  remove() {
    this.onRemove.emit();
  }
}
