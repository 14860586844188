import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TreeModule } from 'primeng/tree';

import { FileDropComponent } from './file-drop/file-drop.component';
import { FileDropDirective } from './file-drop/file-drop.directive';
import { FileListComponent } from './file-list/file-list.component';
import { FilePathComponent } from './file-path/file-path.component';
import { FileToolbarComponent } from './file-toolbar/file-toolbar.component';
import { FileTreeComponent } from './file-tree/file-tree.component';
import { FileManagerComponent } from './file-manager.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    TooltipModule,
    TranslateModule.forChild({ extend: true, isolate: false }),
    TreeModule,
  ],
  declarations: [
    FileDropComponent,
    FileDropDirective,
    FileListComponent,
    FilePathComponent,
    FileToolbarComponent,
    FileTreeComponent,
    FileManagerComponent,
  ],
  exports: [FileManagerComponent],
})
export class SCFileManagerModule {}
