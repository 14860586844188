import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { SelectItem } from 'primeng/api';
import { DeviceDetails } from '@widgets/devices/models/device-details';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'device-assign-form',
  templateUrl: './device-assign-form.component.html',
  styleUrls: ['./device-assign-form.component.scss'],
})
export class DeviceAssignFormComponent implements OnInit {
  deviceControl: FormControl = new FormControl();
  selectItems: { [prop: string]: SelectItem[] } = {};

  private selectedModel: number;
  private devices: DeviceDetails[] = [];

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    const data = this.config && this.config.data;
    this.devices = data && data.devices;
    this.selectedModel = data && data.selectedModel;
    // this.locations = data && data.locations;
    // this.floor = data && data.floor;

    // show only VDH where model is match
    this.selectItems.devices = this.sharedService.createSelectItems(
      this.devices.filter((d) => d.deviceTypeKey === 'vdh' && d.deviceModelId === this.selectedModel)
    );
  }

  onSelectDevice() {
    const device = this.devices.find((l) => l.id === this.deviceControl.value);
    this.dialog.close(device);
  }

  onCreateClicked() {
    this.dialog.close();
  }
}
