import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { DeviceBrandActionCellComponent } from './device-brand-action-cell/device-brand-action-cell.component';
import { DeviceBrandFormComponent } from './device-brand-form/device-brand-form.component';
import { DeviceBrandsTableComponent } from './device-brands-table/device-brands-table.component';

import { DeviceBrandsService } from './services/device-brands.service';

const COMPONENTS: any[] = [
  DeviceBrandActionCellComponent,
  DeviceBrandFormComponent,
  DeviceBrandsTableComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [DeviceBrandsService]
})
export class DeviceBrandsModule {}
