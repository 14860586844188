import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { User } from '../models/user';
import { ModalConfig } from '@sc/modal/modal-config';
import { SharedService } from '@shared/shared.service';
import { UsersService } from '../services/users.service';
import { UserActionCellComponent } from '../user-action-cell/user-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { FlagCellComponent } from '@widgets/_shared/flag-cell/flag-cell.component';

@Component({
  selector: 'sc-users-table',
  templateUrl: 'users-table.component.html',
})
export class UsersTableComponent implements OnInit, OnDestroy {
  columns: any[];
  dataset: User[];
  formConfig: ModalConfig;
  tableTitle: string = 'USERS';
  toolbarItems: string[] = ['create', 'refresh', 'filter', 'columns', 'site'];

  @Input()
  config: any;

  private mode: 'all_site' | 'this_site' | 'integrator' | 'default_user' = 'this_site';
  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private usersService: UsersService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'UserForm',
      options: { modalTitle: 'USER_FORM_TITLE' },
    };

    this.subscribers.selectedSite = this.store.select(fromStore.getSelectedSite).subscribe((data) => {
      clearTimeout(this.timeouts.fetchDataset);
      this.timeouts.fetchDataset = setTimeout(() => {
        this.fetchDataset();
      }, 500);
    });
  }

  ngOnDestroy() {
    this.sharedService.clearTimeouts(this.timeouts);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (this.mode === 'integrator' || this.mode === 'default_user') {
      this.subscribers.fetchDataset = this.usersService
        .getUsersByRole(this.mode)
        .subscribe(this.onDatasetLoaded.bind(this));
    } else if (this.mode === 'all_site') {
      this.subscribers.fetchDataset = this.usersService.getUsersOf('all').subscribe(this.onDatasetLoaded.bind(this));
    } else {
      this.subscribers.fetchDataset = this.usersService.getUsersOf('site').subscribe(this.onDatasetLoaded.bind(this));
    }
  }

  private onDatasetLoaded(result: any) {
    this.dataset = result.data;
  }

  private createColumns() {
    return [
      {
        colId: 'username',
        headerName: 'USERNAME',
        field: 'username',
      },
      {
        colId: 'companyId',
        headerName: 'COMPANY_ID',
        field: 'companyId',
      },
      {
        colId: 'firstname',
        headerName: 'FIRSTNAME',
        field: 'firstname',
      },
      {
        colId: 'lastname',
        headerName: 'LASTNAME',
        field: 'lastname',
      },
      {
        colId: 'email',
        headerName: 'EMAIL',
        field: 'email',
      },
      {
        colId: 'phone',
        headerName: 'PHONE',
        field: 'phone',
      },
      {
        colId: 'permissions',
        headerName: 'PERMISSIONS',
        field: 'permissions',
      },
      {
        colId: 'roles',
        headerName: 'ROLES',
        field: 'roles',
      },
      {
        colId: 'gender',
        headerName: 'GENDER',
        field: 'gender',
      },
      {
        colId: 'locale',
        headerName: 'LOCALE',
        field: 'locale',
        cellRendererFramework: FlagCellComponent,
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        width: 150,
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: UserActionCellComponent,
      },
    ];
  }

  onSiteModeChanged(event) {
    this.mode = event;
    this.fetchDataset();
  }
}
