import { EmptyWidgetComponent } from '@widgets/empty-widget/empty-widget.component';
import { ACOffComponent } from '@widgets/ac-off/ac-off.component';
import { ACValveAlertComponent } from '@widgets/ac-valve-alert/ac-valve-alert.component';
import { AGAlertComponent } from '@widgets/ag-alert/ag-alert.component';
import { AbnormalOccupancyAlertComponent } from '@widgets/abnormal-occupancy-alert/abnormal-occupancy-alert.component';
import { AirQualityComponent } from '@widgets/air-quality/air-quality.component';
import { AppStyleTemplatesTableComponent } from '@widgets/app-style-templates/app-style-templates-table/app-style-templates-table.component';
import { AppStylesTableComponent } from '@widgets/app-styles/app-styles-table/app-styles-table.component';
import { AppsTableComponent } from '@widgets/apps/apps-table/apps-table.component';
import { AutomationLogsTableComponent } from '@widgets/automation-logs/overview/al-table/al-table.component';
import { AutomationRTHLTableComponent } from '@widgets/rules/automation-rthl-table/automation-rthl-table.component';
import { AutomationRulesTableComponent } from '@widgets/rules/automation-rules-table/automation-rules-table.component';
import { AutomationsTableComponent } from '@widgets/automations/automations-table/automations-table.component';
import { AverageHumidityComponent } from '@widgets/average-humidity/average-humidity.component';
import { AverageTemperatureComponent } from '@widgets/average-temperature/average-temperature.component';
import { BatteryAlertWidgetComponent } from '@widgets/battery-alert/battery-alert-widget/battery-alert-widget.component';
import { BenchmarkTemplatesTableComponent } from '@widgets/benchmarks/benchmark-templates/benchmark-templates-table/benchmark-templates-table.component';
import { BenchmarksOverviewTableComponent } from '@widgets/benchmarks/benckmarks-overview/overview-table/overview-table.component';
import { CleaningStatusComponent } from '@widgets/cleaning-status/cleaning-status.component';
import { CeosDevicesTableComponent } from '@widgets/devices/ceos-devices-table/ceos-devices-table.component';
import { CeosRuleTemplatesTableComponent } from '@widgets/rule-templates/ceos-rule-templates-table/ceos-rule-templates-table.component';
import { CompaniesTableComponent } from '@widgets/companies/companies-table/companies-table.component';
import { CostSavedComponent } from '@widgets/energy-saving/cost-saved/cost-saved.component';
import { CustomFunctionsTableComponent } from './custom-functions/custom-functions-table/custom-functions-table.component';
import { CurrenciesTableComponent } from '@widgets/currencies/currencies-table/currencies-table.component';
import { CustomAppsTableComponent } from '@widgets/custom-apps/custom-apps-table/custom-apps-table.component';
import { DeviceBrandsTableComponent } from '@widgets/device-brands/device-brands-table/device-brands-table.component';
import { DeviceCommandMappingTableComponent } from '@widgets/devices/device-command-mapping-table/device-command-mapping-table.component';
import { DeviceDetailsComponent } from '@widgets/devices/device-details/device-details.component';
import { DeviceModelsTableComponent } from '@widgets/device-models/device-models-table/device-models-table.component';
import { DeviceModels2TableComponent } from '@widgets/device-models-2/device-models-table/device-models-table.component';
import { DeviceProtocolsTableComponent } from '@widgets/device-protocols/device-protocols-table/device-protocols-table.component';
import { DeviceTaskTableComponent } from '@widgets/device-tasks/device-task-table/device-task-table.component';
import { DeviceTaskTemplateTableComponent } from '@widgets/device-task-templates/device-task-template-table/device-task-template-table.component';
import { DeviceTypesTableComponent } from '@widgets/device-types/device-types-table/device-types-table.component';
import { DevicesTableComponent } from '@widgets/devices/devices-table/devices-table.component';
import { DhlTableComponent } from './device-history-logs/dhl-table/dhl-table.component';
import { DisabledRulesComponent } from '@widgets/rules/disabled-rules/disabled-rules.component';
import { DoorActivityComponent } from './door-activity/door-activity.component';
import { EkhoComponent } from './ekho/ekho.component';
import { ElectricityCostsTableComponent } from '@widgets/electricity-costs/costs-table/costs-table.component';
import { EnabledRulesComponent } from '@widgets/rules/enabled-rules/enabled-rules.component';
import { RolesTableComponent as EndUserRoleTableComponent } from '@widgets/end-users/roles-table/roles-table.component';
import { EnergyEfficiencyRatingComponent } from '@widgets/energy-saving/energy-efficiency-rating/energy-efficiency-rating.component';
import { EnergySavedComponent } from '@widgets/energy-saving/energy-saved/energy-saved.component';
import { EventsTableComponent } from '@widgets/events/events-table/events-table.component';
import { FieldsOverviewComponent } from '@widgets/setting-form/fields-overview/fields-overview.component';
import { FilesComponent } from '@widgets/files/files.component';
import { FloorPlanComponent } from '@widgets/floorplan/floorplan.component';
import { GuestRequestCategoriesTableComponent } from '@widgets/guest-request-categories/guest-request-categories-table/guest-request-categories-table.component';
import { GuestRequestPresetsTableComponent } from '@widgets/guest-request-presets/guest-request-presets-table/guest-request-presets-table.component';
import { GuestRequestsTableComponent } from '@widgets/guest-requests/guest-requests-table/guest-requests-table.component';
import { GuestSessionsTableComponent } from '@widgets/guest-sessions/guest-sessions-table/guest-sessions-table.component';
import { HousekeepingComponent } from '@widgets/housekeeping/housekeeping.component';
import { LocalWeatherComponent } from '@widgets/local-weather/local-weather.component';
import { LocalesTableComponent } from '@widgets/locales/locales-table/locales-table.component';
import { LocationBenchmarksTableComponent } from '@widgets/benchmarks/location-benchmarks/location-benchmarks-table/location-benchmarks-table.component';
import { LocationDevicesTableComponent } from '@widgets/locations/location-devices-table/location-devices-table.component';
import { LocationHistoryTableComponent } from '@widgets/locations/location-history-table/location-history-table.component';
import { LocationProfilesTableComponent } from '@widgets/location-profiles/location-profiles-table/location-profiles-table.component';
import { LocationPropertiesTableComponent } from '@widgets/location-properties/location-properties-table/location-properties-table.component';
import { LocationRHLTableComponent } from '@widgets/rules/location-rhl-table/location-rhl-table.component';
import { LocationRulesTableComponent } from '@widgets/rules/location-rules-table/location-rules-table.component';
import { LocationSavingTableComponent } from '@widgets/energy-saving/location-saving-table/location-saving-table.component';
import { LocationSummaryComponent } from '@widgets/location-summary/location-summary.component';
import { LocationSummaryHourlyLogsComponent } from '@widgets/location-summary/hourly-logs/hourly-logs.component';
import { LocationTemplatesTableComponent } from '@widgets/location-templates/location-templates-table/location-templates-table.component';
import { LocationTypesTableComponent } from '@widgets/locations/location-types-table/location-types-table.component';
import { LocationsConsomptionAlertComponent } from '@widgets/locations-consumption-alert/locations-consumption-alert.component';
import { LocationsHumidityAlertComponent } from '@widgets/locations-humidity-alert/locations-humidity-alert.component';
import { LocationsOccupancyHistoryComponent } from '@widgets/locations-occupancy-history/locations-occupancy-history.component';
import { LocationsOccupancyLiveComponent } from '@widgets/locations-occupancy-live/locations-occupancy-live.component';
import { LocationsOccupancyOverviewComponent } from '@widgets/locations-occupancy-overview/locations-occupancy-overview.component';
import { LocationsTableComponent } from '@widgets/locations/locations-table/locations-table.component';
import { LocationsTemperatureAlertComponent } from '@widgets/locations-temperature-alert/locations-temperature-alert.component';
import { LocationsWindowOpenComponent } from '@widgets/locations-window-open/locations-window-open.component';
import { LrlTableComponent } from '@widgets/live-requests/lrl-table/lrl-table.component';
import { MainDoorOpenComponent } from '@widgets/main-door-open/main-door-open.component';
import { MakeUpMyRoomAlertComponent } from '@widgets/make-up-my-room-alert/make-up-my-room-alert.component';
import { MostSoldRoomsComponent } from '@widgets/most-sold-rooms/most-sold-rooms.component';
import { MyAccountComponent } from '@widgets/users/my-account/my-account.component';
import { NotificationHistoryLogTableComponent } from '@widgets/locations/notification-history-log-table/notification-history-log-table.component';
import { NotificationTemplatesTableComponent } from '@widgets/notification-templates/notification-templates-table/notification-templates-table.component';
import { NotificationsTableComponent } from '@widgets/notifications/notifications-table/notifications-table.component';
import { PageTemplatesTableComponent } from '@widgets/page-templates/page-templates-table/page-templates-table.component';
import { PropertyManagementSystemsTableComponent } from '@widgets/property-management-systems/property-management-systems-table/property-management-systems-table.component';
import { ProtocolControllersTableComponent } from '@widgets/devices/protocol-controllers-table/protocol-controllers-table.component';
import { ReportTemplatesTableComponent } from '@widgets/report-templates/report-templates-table/report-templates-table.component';
import { RoomCorrectionComponent } from '@widgets/room-correction/room-correction.component';
import { RoomUnoccupancyAvgComponent } from '@widgets/room-unoccupancy-avg/room-unoccupancy-avg.component';
import { RoomUnoccupancyHistoryComponent } from '@widgets/room-unoccupancy-history/room-unoccupancy-history.component';
import { RuleCategoriesTableComponent } from '@widgets/rule-categories/rule-categories-table/rule-categories-table.component';
import { RuleMassEditComponent } from '@widgets/rule-mass-edit/rule-mass-edit.component';
import { RuleMonitorTableComponent } from '@widgets/rule-monitor/rule-monitor-table/rule-monitor-table.component';
import { RuleSchedulerJobsTableComponent } from '@widgets/rule-scheduler-jobs/rule-scheduler-jobs-table/rule-scheduler-jobs-table.component';
import { RuleTemplatesTableComponent } from '@widgets/rule-templates/rule-templates-table/rule-templates-table.component';
import { SceneTemplatesTableComponent } from '@widgets/scene-templates/scene-templates-table/scene-templates-table.component';
import { ScenesTableComponent } from '@widgets/scenes/scenes-table/scenes-table.component';
import { SettingWidgetsTableComponent } from '@widgets/setting-widgets/setting-widgets-table/setting-widgets-table.component';
import { SiteTypesTableComponent } from '@widgets/site-types/site-types-table/site-types-table.component';
import { SitesTableComponent } from '@widgets/sites/sites-table/sites-table.component';
import { UserTaskTemplatesTableComponent } from './user-task-templates/user-task-templates-table/user-task-templates-table.component';
import { TechAlertsComponent } from '@widgets/tech-alerts/tech-alerts.component';
import { TopFiveColdestRoomsComponent } from '@widgets/top-five-coldest-rooms/top-five-coldest-rooms.component';
import { TopFiveHottestRoomsComponent } from '@widgets/top-five-hottest-rooms/top-five-hottest-rooms.component';
import { TotalSavingComponent } from '@widgets/energy-saving/total-saving/total-saving.component';
import { TranslationsTableComponent } from '@widgets/translations/translations-table/translations-table.component';
import { TrashTableComponent } from '@widgets/trash/trash-table/trash-table.component';
import { UserPermissionsTableComponent } from '@widgets/users/user-permissions-table/user-permissions-table.component';
import { UserRolesTableComponent } from '@widgets/users/user-roles-table/user-roles-table.component';
import { UsersTableComponent } from '@widgets/users/users-table/users-table.component';
import { VirtualDeviceHoldersTableComponent } from '@widgets/devices/virtual-device-holders-table/virtual-device-holders-table.component';
import { WidgetCategoriesTableComponent } from '@widgets/widget-categories/widget-categories-table/widget-categories-table.component';
import { AirQualityLiveComponent } from './air-quality-live/air-quality-live.component';
import { IotCounterComponent } from './iot-counter/iot-counter.component';
import { TriggeredRulesComponent } from './triggered-rules/triggered-rules.component';
import { CeosAlertComponent } from './ceos-alert/ceos-alert.component';
import { BatteryStatusComponent } from './battery-status/battery-status.component';
import { EcowattComponent } from './ecowatt/ecowatt.component';
import { ConsumptionComponent } from './consumption/consumption.component';

export const WIDGETS: { [key: string]: any } = {
  empty_widget: EmptyWidgetComponent,
  abnormal_occupancy_alert: AbnormalOccupancyAlertComponent,
  ac_off: ACOffComponent,
  ac_valve_alert: ACValveAlertComponent,
  air_quality: AirQualityComponent,
  air_quality_live: AirQualityLiveComponent,
  ag_alert: AGAlertComponent,
  app_style_templates_table: AppStyleTemplatesTableComponent,
  app_styles_table: AppStylesTableComponent,
  apps_table: AppsTableComponent,
  automation_logs: AutomationLogsTableComponent,
  automations_table: AutomationsTableComponent,
  automation_rule_trigger_history_logs_table: AutomationRTHLTableComponent,
  automation_rules_table: AutomationRulesTableComponent,
  average_humidity: AverageHumidityComponent,
  average_temperature: AverageTemperatureComponent,
  battery_alert: BatteryAlertWidgetComponent,
  benchmark_templates_table: BenchmarkTemplatesTableComponent,
  benchmarks_overview_table: BenchmarksOverviewTableComponent,
  ceos_devices_table: CeosDevicesTableComponent,
  ceos_rule_templates_table: CeosRuleTemplatesTableComponent,
  cleaning_status: CleaningStatusComponent,
  companies_table: CompaniesTableComponent,
  cost_saved: CostSavedComponent,
  custom_functions_table: CustomFunctionsTableComponent,
  currencies_table: CurrenciesTableComponent,
  custom_apps_table: CustomAppsTableComponent,
  device_brands_table: DeviceBrandsTableComponent,
  device_command_mapping_table: DeviceCommandMappingTableComponent,
  device_details: DeviceDetailsComponent,
  device_history_logs_table: DhlTableComponent,
  device_models_table: DeviceModelsTableComponent,
  device_models_2_table: DeviceModels2TableComponent,
  device_protocols_table: DeviceProtocolsTableComponent,
  device_task_table: DeviceTaskTableComponent,
  device_task_template_table: DeviceTaskTemplateTableComponent,
  device_types_table: DeviceTypesTableComponent,
  devices_table: DevicesTableComponent,
  disabled_rules: DisabledRulesComponent,
  door_activity: DoorActivityComponent,
  ekho: EkhoComponent,
  electricity_costs_table: ElectricityCostsTableComponent,
  enabled_rules: EnabledRulesComponent,
  end_user_roles_table: EndUserRoleTableComponent,
  energy_efficiency_rating: EnergyEfficiencyRatingComponent,
  energy_saved: EnergySavedComponent,
  events_table: EventsTableComponent,
  fields_table: FieldsOverviewComponent,
  files: FilesComponent,
  floorplan: FloorPlanComponent,
  guest_request_categories_table: GuestRequestCategoriesTableComponent,
  guest_request_presets_table: GuestRequestPresetsTableComponent,
  guest_requests_table: GuestRequestsTableComponent,
  guest_sessions_table: GuestSessionsTableComponent,
  housekeeping: HousekeepingComponent,
  live_request_logs_table: LrlTableComponent,
  local_weather: LocalWeatherComponent,
  locales_table: LocalesTableComponent,
  location_benchmarks_table: LocationBenchmarksTableComponent,
  location_devices_table: LocationDevicesTableComponent,
  location_history_table: LocationHistoryTableComponent,
  location_notification_history_log_table: NotificationHistoryLogTableComponent,
  location_profiles_table: LocationProfilesTableComponent,
  location_properties_table: LocationPropertiesTableComponent,
  location_rule_history_log_table: LocationRHLTableComponent,
  location_rules_table: LocationRulesTableComponent,
  location_saving_table: LocationSavingTableComponent,
  location_scenes_table: ScenesTableComponent,
  location_summary: LocationSummaryComponent,
  location_summary_hourly_logs: LocationSummaryHourlyLogsComponent,
  location_templates_table: LocationTemplatesTableComponent,
  location_types_table: LocationTypesTableComponent,
  locations_consumption_alert: LocationsConsomptionAlertComponent,
  locations_humidity_alert: LocationsHumidityAlertComponent,
  locations_occupancy_history: LocationsOccupancyHistoryComponent,
  locations_occupancy_live: LocationsOccupancyLiveComponent,
  locations_occupancy_overview: LocationsOccupancyOverviewComponent,
  locations_table: LocationsTableComponent,
  locations_temperature_alert: LocationsTemperatureAlertComponent,
  locations_window_open: LocationsWindowOpenComponent,
  main_door_open: MainDoorOpenComponent,
  make_up_my_room_alert: MakeUpMyRoomAlertComponent,
  most_sold_rooms: MostSoldRoomsComponent,
  my_account: MyAccountComponent,
  notification_templates_table: NotificationTemplatesTableComponent,
  notifications_table: NotificationsTableComponent,
  page_templates_table: PageTemplatesTableComponent,
  pms_table: PropertyManagementSystemsTableComponent,
  protocol_controllers_table: ProtocolControllersTableComponent,
  report_templates_table: ReportTemplatesTableComponent,
  room_correction: RoomCorrectionComponent,
  room_unoccupancy_avg: RoomUnoccupancyAvgComponent,
  room_unoccupancy_history: RoomUnoccupancyHistoryComponent,
  rule_categories_table: RuleCategoriesTableComponent,
  rule_mass_edit: RuleMassEditComponent,
  rule_monitor_table: RuleMonitorTableComponent,
  rule_scheduler_jobs_table: RuleSchedulerJobsTableComponent,
  rule_templates_table: RuleTemplatesTableComponent,
  scene_templates_table: SceneTemplatesTableComponent,
  site_types_table: SiteTypesTableComponent,
  sites_table: SitesTableComponent,
  tech_alerts: TechAlertsComponent,
  top_five_coldest_rooms: TopFiveColdestRoomsComponent,
  top_five_hottest_rooms: TopFiveHottestRoomsComponent,
  total_saving: TotalSavingComponent,
  translations_table: TranslationsTableComponent,
  trash_table: TrashTableComponent,
  user_permissions_table: UserPermissionsTableComponent,
  user_roles_table: UserRolesTableComponent,
  user_task_templates_table: UserTaskTemplatesTableComponent,
  users_table: UsersTableComponent,
  virtual_device_holders_table: VirtualDeviceHoldersTableComponent,
  widget_categories_table: WidgetCategoriesTableComponent,
  widgets_table: SettingWidgetsTableComponent,
  iot_counter: IotCounterComponent,
  triggered_rules: TriggeredRulesComponent,
  ceos_alert: CeosAlertComponent,
  battery_status: BatteryStatusComponent,
  ecowatt: EcowattComponent,
  consumption: ConsumptionComponent,
};
