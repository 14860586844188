<div class="relative-datetime-operand container">
  <div class="relative-datetime-operand-body" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-5">
        <label class="input-label" for="value">
          {{ "CCP_COMMON_VALUE" | translate }}
        </label>

        <input
          type="number"
          pInputText
          inputId="value"
          formControlName="value"
          [min]="0"
        />
      </div>

      <div class="p-field p-col-12 p-md-5">
        <label class="input-label" for="unit">
          {{ "CCP_COMMON_UNIT" | translate }}
        </label>

        <p-dropdown
          inputId="unit"
          [options]="timeUnits"
          formControlName="unit"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_COMMON_UNIT' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-2">
        <div class="mode-wrapper">
          {{ form?.value?.mode }}
        </div>
      </div>

      <div class="p-field p-col-12 p-md-4" *ngIf="isTemplate">
        <label class="input-label" for="name">
          {{ "NAME" | translate }}
        </label>

        <input type="text" pInputText inputId="name" formControlName="name" />
      </div>

      <div class="p-field p-col-12 p-md-4 pt-4" *ngIf="isTemplate">
        <p-checkbox
          inputId="is_source_editable"
          [binary]="true"
          [formControl]="form?.get('is_source_editable')"
          [label]="'EDITABLE' | translate"
        ></p-checkbox>
      </div>
    </div>
  </div>
</div>
