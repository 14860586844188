<div class="vdh-info">
  <div class="vdh-info-header">
    <div class="sc-flex align-center">
      <h4 class="header-title">
        {{ "INFORMATION" | translate }}
      </h4>
    </div>

    <div class="sc-flex justify-end align-center">
      <sc-modal-trigger
        *scHasPermission="'device_u'"
        [modalConfig]="deviceFormModal"
        [buttonIcon]="'fas fa-pencil-alt'"
        buttonClass="ui-button-secondary"
        (onClose)="onUpdateSuccess($event)"
        pTooltip="{{ 'EDIT' | translate }}"
        tooltipPosition="top"
        appendTo="body"
      ></sc-modal-trigger>
    </div>
  </div>

  <div class="vdh-info-body">
    <ul class="device-detail">
      <li class="device-detail-item">
        <div class="detail-label">{{ "NAME" | translate }}:</div>

        <div class="detail-value">
          {{ holderDevice?.description }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">{{ "ID" | translate }}:</div>

        <div class="detail-value">
          {{ holderDevice?.id }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">{{ "DEVICE_MODEL" | translate }}:</div>

        <div class="detail-value">
          {{ holderDevice?.modelName }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">{{ "PROTOCOL" | translate }}:</div>

        <div class="detail-value">
          {{ holderDevice?.protocolName }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">{{ "AUTOMATION" | translate }}:</div>

        <div class="detail-value">
          {{ holderDevice?.automationName }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">{{ "ACTIVE" | translate }}:</div>

        <div class="detail-value">
          {{ (holderDevice?.isActive ? "YES" : "NO") | translate }}
        </div>
      </li>
    </ul>
  </div>
</div>
