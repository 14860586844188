
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


import { config } from '@app/config';

@Injectable()
export class AppStyleService {
  private baseUrl = config().apiUrl;

  constructor(private httpClient: HttpClient) {}

  /**
   * Load custom app style
   */
  getCustomAppStyle(companyId: number, siteId?: number) {
    let apiUrl = `${this.baseUrl}/_backend/style/${companyId}`;
    if (siteId) {
      apiUrl += `/${siteId}`;
    }
    return this.httpClient
      .get(apiUrl)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
