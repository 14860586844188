import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalConfig } from '@sc/modal/modal-config';
import { DeviceModel } from '../models/device-model';
import { DeviceModelService } from '../services/device-model.service';
import { SharedService } from '@shared/shared.service';
import { DeviceModelActionCellComponent } from '../device-model-action-cell/device-model-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-device-models-table',
  templateUrl: './device-models-table.component.html',
})
export class DeviceModels2TableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: DeviceModel[];
  formConfig: ModalConfig;
  tableTitle: string;

  private subscribers: { [key: string]: any } = {};

  constructor(private deviceModelService: DeviceModelService, private sharedService: SharedService) {}

  ngOnInit() {
    this.initTable();
    this.initFormModal();
    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.dataset = this.deviceModelService.getDeviceModels().subscribe((result: any) => {
      this.dataset = result.data;
    });
  }

  private initTable() {
    this.tableTitle = 'DEVICE_MODELS';
    this.columns = [
      {
        colId: 'product_name',
        headerName: 'PRODUCT_NAME',
        field: 'product_name',
      },
      {
        colId: 'display_name',
        headerName: 'DISPLAY_NAME',
        field: 'display_name',
      },
      {
        colId: 'brand',
        headerName: 'BRAND',
        field: 'brand',
      },
      {
        colId: 'protocol',
        headerName: 'PROTOCOL',
        field: 'protocol',
      },
      {
        colId: 'frequency',
        headerName: 'FREQUENCY',
        field: 'frequency',
      },
      {
        colId: 'unique_id',
        headerName: 'UNIQUE_ID',
        field: 'unique_id',
      },
      {
        colId: 'idx',
        headerName: 'IDX',
        field: 'idx',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'is_active',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'is_deleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceModelActionCellComponent,
      },
    ];
  }

  private initFormModal() {
    this.formConfig = {
      name: 'DeviceModel2Form',
      options: { modalTitle: 'DEVICE_MODEL_FORM_TITLE', fullScreen: true },
    };
  }
}
