import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ActionMenuItem, RuleConditions, DataSources, SelectItems } from '../../models';
import { ActionMenuService, RulesService, UtilsService } from '../../services';

@Component({
  selector: 'sc-rule-condition',
  templateUrl: './rule-condition.component.html',
  styleUrls: ['./rule-condition.component.scss'],
})
export class RuleConditionComponent implements OnInit, OnChanges {
  actionMenu: ActionMenuItem[];
  connectorMenu: ActionMenuItem[];
  connector: 'AND' | 'OR' = 'AND';
  connectorLabel: string;
  connectorDescription: string;
  parentConnectorLabel: string;

  @Input()
  conditions: RuleConditions;

  @Input()
  isLastItem: boolean;

  @Input()
  parentConnector: 'AND' | 'OR';

  @Input()
  sublevel: boolean;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Output()
  movedUp = new EventEmitter<any>();

  @Output()
  movedDown = new EventEmitter<any>();

  @Output()
  removed = new EventEmitter<any>();

  @Input()
  viewMode: string;

  @Input()
  isTemplate: boolean;

  @Input()
  isFromScratch: boolean;

  constructor(
    private actionMenuService: ActionMenuService,
    private changeDetectorRef: ChangeDetectorRef,
    private rulesService: RulesService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.actionMenu = [
      {
        label: 'CCP_RULE_ADD_CONDITION',
        value: 'add_condition',
      },
      {
        label: 'CCP_RULE_ADD_SUB_CONDITION',
        value: 'add_sub_condition',
      },
      {
        label: 'CCP_COMMON_MOVE_UP',
        value: 'move_up',
        disabled: !this.sublevel,
        hidden: !this.sublevel,
      },
      {
        label: 'CCP_COMMON_MOVE_DOWN',
        value: 'move_down',
        disabled: !this.sublevel,
        hidden: !this.sublevel,
      },
      {
        label: 'CCP_COMMON_REMOVE',
        value: 'remove',
        disabled: !this.sublevel,
        hidden: !this.sublevel,
      },
    ];

    this.connectorMenu = [];

    this.getConnector();
    this.setParentConnectorLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conditions && changes.conditions.currentValue && !changes.conditions.firstChange) {
      this.getConnector();
    }
    if (changes.parentConnector && changes.parentConnector.currentValue && !changes.parentConnector.firstChange) {
      this.setParentConnectorLabel();
    }
  }

  private getConnector() {
    if (this.conditions) {
      if (this.conditions.AND) {
        this.connector = 'AND';
      } else if (this.conditions.OR) {
        this.connector = 'OR';
      }
      this.setConnectorLabel();
      this.setConnectorDescription();
    }
  }

  private setConnectorLabel() {
    if (this.connector === 'AND') {
      this.connectorLabel = 'CCP_RULE_CONNECTOR_ALL';
    } else if (this.connector === 'OR') {
      this.connectorLabel = 'CCP_RULE_CONNECTOR_ANY';
    }
    this.changeDetectorRef.detectChanges();
  }

  private setConnectorDescription() {
    if (this.connector === 'AND') {
      this.connectorDescription = 'CCP_RULE_CONNECTOR_ALL_DESCRIPTION';
    } else if (this.connector === 'OR') {
      this.connectorDescription = 'CCP_RULE_CONNECTOR_ANY_DESCRIPTION';
    } else {
      this.connectorDescription = '';
    }
    this.changeDetectorRef.detectChanges();
  }

  private setParentConnectorLabel() {
    this.parentConnectorLabel = 'CCP_RULE_CONNECTOR_' + this.parentConnector;
    this.changeDetectorRef.detectChanges();
  }

  onActionSelected(action) {
    if (typeof action === 'undefined') {
      return;
    }

    switch (action) {
      case 'add_condition':
        return this.addConditionHandler();
      case 'add_sub_condition':
        return this.addConditionHandler(true);
      case 'move_up':
        return this.movedUp.emit();
      case 'move_down':
        return this.movedDown.emit();
      case 'remove':
        return this.removed.emit();
    }
  }

  openConnectorMenu() {
    const menu = [
      { label: 'CCP_RULE_CONNECTOR_ALL', value: 'AND' },
      { label: 'CCP_RULE_CONNECTOR_ANY', value: 'OR' },
    ];

    const connectorMenu = this.actionMenuService.open(menu);
    connectorMenu.onClose.subscribe((data) => {
      if (typeof data === 'undefined' || data === this.connector) {
        return;
      }

      const tmpConds = this.conditions[this.connector];
      delete this.conditions[this.connector];

      this.connector = data;
      this.conditions[this.connector] = tmpConds;

      this.setConnectorLabel();
      this.setConnectorDescription();
    });
  }

  private addConditionHandler(isSubCondition = false) {
    if (!this.conditions[this.connector]) {
      this.conditions[this.connector] = [];
    }

    const condition = this.rulesService.initRuleCondition(this.isTemplate);

    if (isSubCondition) {
      this.conditions[this.connector].push({ AND: [condition] });
    } else {
      this.conditions[this.connector].push(condition);
    }
  }

  onConditionMovedUp(index) {
    this.utilsService.arrayMove(this.conditions[this.connector], index, index - 1);
  }

  onConditionMovedDown(index) {
    this.utilsService.arrayMove(this.conditions[this.connector], index, index + 1);
  }

  onConditionRemoved(index) {
    this.conditions[this.connector].splice(index, 1);
  }
}
