import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DeviceTask, DeviceTaskParameter } from '../models/device-task';

import * as moment from 'moment';

@Injectable()
export class DeviceTaskFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initDeviceTaskForm() {
    const from = moment().toDate();
    const to = moment()
      .add(1, 'hour')
      .toDate();
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      template_id: [null, Validators.required],
      device_id: [null, Validators.required],
      parameters: this.formBuilder.array([]),
      published_from: [from, Validators.required],
      published_to: [to, Validators.required]
    });
  }

  initParameterFormGroup() {
    return this.formBuilder.group({
      key: [null, Validators.required],
      value: null
    });
  }

  setParameterFormArray(form: FormGroup, data: DeviceTaskParameter[]) {
    const formGroups = data.map(item => {
      const fg = this.initParameterFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('parameters', formArray);
  }
}
