import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private apiUrl = config().apiUrl + '/_integrator/sites';

  constructor(private httpClient: HttpClient) {}

  getSites() {
    return this.httpClient.get(this.apiUrl);
  }

  createSite(data) {
    return this.httpClient.post(this.apiUrl, data);
  }

  bulkEditCommand(data) {
    return this.httpClient.post(`${config().apiUrl}/public/entities/edit`, data);
  }

  bulkDeleteCommand(data) {
    return this.httpClient.post(`${config().apiUrl}/public/entities/delete`, data);
  }
}
