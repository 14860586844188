<div class="page-section">
  <ul
    class="section-list"
    dnd-sortable-container
    [sortableData]="displaySections"
  >
    <li
      *ngFor="let section of displaySections; let i = index"
      class="section-item"
      [ngClass]="onSelected(section)"
      (click)="switchSection.emit(section)"
      dnd-sortable
      [dragData]="displaySections"
      [sortableIndex]="i"
      (onDropSuccess)="moveSection.emit($event)"
    >
      {{ section.name | translate }}

      <ng-container *ngIf="editMode">
        <span
          pTooltip="{{ 'MOVE' | translate }} {{ 'SECTION' | translate }}"
          tooltipPosition="top"
          appendTo="body"
          dnd-sortable-handle
          class="section-item-action"
        >
          <i class="fa fa-arrows-h fa-fw"></i>
        </span>

        <span
          pTooltip="{{ 'RESET' | translate }} {{ 'SECTION' | translate }}"
          tooltipPosition="top"
          appendTo="body"
          (click)="resetSection.emit(section)"
          class="section-item-action"
        >
          <i class="fa fa-undo fa-fw"></i>
        </span>

        <span
          *ngIf="displaySections?.length > 1"
          pTooltip="{{ 'DELETE' | translate }} {{ 'SECTION' | translate }}"
          tooltipPosition="top"
          appendTo="body"
          (click)="deleteSection.emit(section)"
          class="section-item-action"
        >
          <i class="fa fa-times fa-fw"></i>
        </span>
      </ng-container>
    </li>
  </ul>

  <div class="add-section-form-trigger">
    <sc-modal-trigger
      *ngIf="editMode && allowCustom"
      [modalConfig]="modalConfig"
      (onClose)="onAddSection($event)"
      buttonIcon="fa fa-plus fa-fw"
      buttonClass="p-2 green white-text"
      tooltipContent="{{ 'ADD' | translate }} {{ 'SECTION' | translate }}"
      tooltipPosition="left"
      buttonClass="modal-trigger-button-icon-only modal-green"
    ></sc-modal-trigger>
  </div>
</div>
