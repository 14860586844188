import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Page } from '@app/core2/models/page';
import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';
import { TreeNode } from 'primeng/api';

@Injectable()
export class SCWidgetService {
  private baseUrl = config().apiUrl + '/_backend/_widgets';

  COLOR_GRAY = 'gray';
  COLOR_GREEN = 'green';
  COLOR_BLUE = 'blue';
  COLOR_PURPLE = 'purple';
  COLOR_YELLOW = 'yellow';
  COLOR_ORANGE = 'orange';
  COLOR_RED = 'red';
  COLOR_LIGHTGREEN = 'lightgreen';
  colors: any[];

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {
    this.colors = [
      { name: 'Gray', key: this.COLOR_GRAY, hex: '#d3d3d3' },
      { name: 'Green', key: this.COLOR_GREEN, hex: '#67C987' },
      { name: 'Blue', key: this.COLOR_BLUE, hex: '#87cefa' },
      { name: 'Purple', key: this.COLOR_PURPLE, hex: '#8470ff' },
      { name: 'Yellow', key: this.COLOR_YELLOW, hex: '#ffff00' },
      { name: 'Orange', key: this.COLOR_ORANGE, hex: '#ffa500' },
      { name: 'Red', key: this.COLOR_RED, hex: '#ff4500' },
      { name: 'Lightgreen', key: this.COLOR_LIGHTGREEN, hex: '#D0E8D8' },
    ];
  }

  getColorByKey(colorKey) {
    return this.colors.find((color) => color.key === colorKey);
  }

  getAGAlert(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/agAlert?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getBatteryAlert(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/batteryAlert?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getOccupancy(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/occupancy?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getOccupancyHistory(siteId: number, options?: { [key: string]: any }) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const queryParams = [];

    for (const key in options) {
      if (options.hasOwnProperty(key) && options[key]) {
        queryParams.push(key + '=' + encodeURIComponent(options[key]));
      }
    }

    let apiUrl = `${this.baseUrl}/occupancyHistory?siteId=${siteId}`;
    if (queryParams.length) {
      apiUrl += '&' + queryParams.join('&');
    }
    return this.httpClient.get(apiUrl);
  }

  getTemperatureAlert(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/temperatureAlert?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getHumidityAlert(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/humidityAlert?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getConsumptionAlert(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/consumptionAlert?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getOpenWindows(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/openWindows?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getTechAlerts(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/techAlerts?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getRoomCorrection(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/roomCorrection?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getMakeUpMyRoom(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/makeUpMyRoom?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getEnergyEfficiencyRating(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/energyEfficiencyRating?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getHousekeeping(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/housekeeping?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getMainDoorOpen(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/mainDoorOpen?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getAverageTemperature(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/averageTemperature?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getAverageHumidity(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/averageHumidity?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getTopFiveHottestRooms(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/topFiveHottest?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getTopFiveColdestRooms(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/topFiveColdest?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getAbnormalOccupancy(siteId: number, options: { [key: string]: any }) {
    const site = this.sharedService.selectedSite;
    options.siteId = siteId || site.id;
    if (!options.from) {
      delete options.from;
    }
    if (!options.to) {
      delete options.to;
    }
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    let apiUrl = `${this.baseUrl}/abnormalOccupancy`;
    apiUrl += queryParams;
    // if (options) {
    //   if (options.from) {
    //     apiUrl += `&from=${options.from}`;
    //   }
    //   if (options.to) {
    //     apiUrl += `&to=${options.to}`;
    //   }
    // }
    return this.httpClient.get(apiUrl);
  }

  getRulesStatus(siteId: number, activated = true) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    let apiUrl = `${this.baseUrl}/rulesStatus?siteId=${siteId}`;
    apiUrl += activated ? '&active' : '';
    return this.httpClient.get(apiUrl);
  }

  getRoomUnoccupancyAvg(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/roomUnoccupancyAvg?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getRoomUnoccupancyHistory(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/roomUnoccupancyHistory?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getACValveAlert(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/acValveAlert?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getMostSoldRooms(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/mostSoldRooms?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getACOff(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/acOff?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getDoorActivity(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/doorActivity?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getLocationsOccupancyLiveStats(siteId: number, locationIdxs: string[], useLocationSelection: boolean) {
    return this.httpClient.post(`${this.baseUrl}/locationOccupancyLive`, {
      siteId,
      locationIdxs,
      useLocationSelection,
    });
  }

  getLocationsCleaningStatusStats(siteId: number, locationIdxs: string[], useLocationSelection: boolean) {
    return this.httpClient.post(`${this.baseUrl}/locationCleaningStatus`, {
      siteId,
      locationIdxs,
      useLocationSelection,
    });
  }

  checkSelectedTreeNodes(nodes: TreeNode[], keys: string[]) {
    let selection: TreeNode[] = [];
    nodes.forEach((node) => {
      // Check parent
      if (keys.includes(node.data)) {
        selection.push(node);
      }

      if (node.children != undefined) {
        node.children.forEach((child) => {
          // Check child if the parent is not selected
          if (keys.includes(child.data) && !keys.includes(node.data)) {
            node.partialSelected = true;
            child.parent = node;
          }

          // Check the child if the parent is selected
          // Push the parent in str to new iteration and mark all the children
          if (keys.includes(node.data)) {
            child.parent = node;
            keys.push(child.data);
          }
        });
      } else {
        return;
      }

      let subSelection = this.checkSelectedTreeNodes(node.children, keys);
      if (subSelection) {
        selection = selection.concat(subSelection);
      }

      node.children.forEach((child) => {
        if (child.partialSelected) {
          node.partialSelected = true;
        }
      });
    });
    return selection;
  }

  nestedDatasetToTreeNode(nestedDataset, hideIcon = false) {
    let tmpTree: TreeNode[] = [];
    let expandedIcon = 'pi pi-folder-open';
    let collapsedIcon = 'pi pi-folder';
    for (let ds of nestedDataset) {
      if (ds.isActive != true) {
        continue;
      }

      if (hideIcon) {
        expandedIcon = '';
        collapsedIcon = '';
      }

      let tmpBranch = {
        label: ds.description,
        data: ds.idx,
        expandedIcon,
        collapsedIcon,
        children: [],
      };

      if ('children' in ds) {
        tmpBranch.children = this.nestedDatasetToTreeNode(ds.children, hideIcon);
      }
      tmpTree.push(tmpBranch);
    }
    return tmpTree;
  }

  setWidgetCustomPreferences(page: Page, widgetUniqueId: string, customPreferences: object) {
    let tmpPage = JSON.parse(JSON.stringify(page));
    let sectionIndex = 0;
    for (let i = 0, c = page.sections.length; i < c; i++) {
      let widgetFound = false;
      for (let ii = 0, cc = page.sections[i].widgets.length; ii < cc; ii++) {
        if (page.sections[i].widgets[ii].uniqueId === widgetUniqueId) {
          sectionIndex = i;
          widgetFound = true;
          tmpPage.sections[i].widgets[ii].customPreferences = customPreferences;
          break;
        }
      }
      if (widgetFound) {
        break;
      }
    }
    return { page: tmpPage, sectionIndex };
  }

  getEkhosOfSite(siteId: number) {
    return this.httpClient.get(`${this.baseUrl}/ekho/devices?siteId=${siteId}`);
  }

  getEkhosStats(siteId, mode, ekhos, thresholds) {
    return this.httpClient.post(`${this.baseUrl}/ekho/stats`, {
      siteId,
      mode,
      ekhos,
      thresholds,
    });
  }

  getAirQualitySensorsOfSite(siteId: number) {
    return this.httpClient.get(`${this.baseUrl}/airQuality/devices?siteId=${siteId}`);
  }

  getAirQualityStats(siteId, unit, devices, thresholds) {
    return this.httpClient.post(`${this.baseUrl}/airQuality/stats`, {
      siteId,
      unit,
      devices,
      thresholds,
    });
  }

  flattenTreeRecursively(nodes, flattedNodes = []) {
    for (let node of nodes) {
      flattedNodes.push(node);
      if (node.children !== undefined && Array.isArray(node.children) && node.children.length) {
        flattedNodes.concat(this.flattenTreeRecursively(node.children, flattedNodes));
      }
    }
    return flattedNodes;
  }

  getLocationPathRecursively(location, locations, path = null) {
    if (!path) {
      path = [];
    }

    let child = null;
    if (!path.length) {
      // Find initial child
      child = locations.find((l) => l.idx == location.idx);
      path.push({
        idx: child.idx,
        id: child.id,
        type: child.locationTypeKey,
        name: child.description,
      });
    } else {
      // Find latest child
      let childIdx = path.at(-1).idx;
      child = locations.find((l) => l.idx == childIdx);
    }

    // Climb up parents
    if (child && child.parentIdx) {
      let parent = locations.find((l) => l.idx == child.parentIdx);
      if (parent) {
        path.push({
          idx: parent.idx,
          id: parent.id,
          type: parent.locationTypeKey,
          name: parent.description,
        });
        if (parent.parentIdx) {
          path = this.getLocationPathRecursively(location, locations, path);
        }
      }
    }

    return path;
  }

  patchPathToLocations(locations, flatLocations) {
    let patchedLocations = [];
    for (let location of locations) {
      location.path = this.getLocationPathRecursively(location, flatLocations).reverse();
      location.searchPath = location.path.map((p) => p.name).join('_');
      patchedLocations.push(location);
    }
    return patchedLocations;
  }

  getIotCounter(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/iotCounter?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getTriggeredRules(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/triggeredRules?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getCeosAlert(siteIds: number[]) {
    const apiUrl = `${this.baseUrl}/ceosAlert?siteIds=${siteIds}`;
    return this.httpClient.get(apiUrl);
  }

  getBatteryStatus(siteId: number) {
    const site = this.sharedService.selectedSite;
    siteId = siteId || site.id;
    const apiUrl = `${this.baseUrl}/batteryStatus?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getEcowatt() {
    const apiUrl = `${this.baseUrl}/ecowatt`;
    return this.httpClient.get(apiUrl);
  }

  getConsumptions(devices: string) {
    return this.httpClient.get(`${this.baseUrl}/consumptions?devices=${devices}`);
  }
}
