<div *ngIf="fetchingState === 0; else: loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!-- Header -->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-12">
            <button
              pButton
              type="button"
              class="ui-button-info"
              label="{{ 'IMPORT' | translate }}"
              (click)="showImportExport('import')"
              [disabled]="currentImportExportForm === 'import'"
            ></button>
            <button
              pButton
              type="button"
              class="ui-button-info"
              label="{{ 'EXPORT' | translate }}"
              (click)="showImportExport('export')"
              [disabled]="currentImportExportForm === 'export'"
            ></button>
          </div>
        </div>
      </p-header>

      <!-- Body -->
      <div [ngSwitch]="currentImportExportForm" class="ui-g">
        <div *ngSwitchCase="'export'">
          <h3>{{ 'EXPORT' | translate }}</h3>
          <div class="ui-g-12">
            <sc-dropdown
              controlName="locale"
              [form]="form"
              label="{{ 'LOCALE' | translate }}"
              [options]="selectItems['locales']"
            ></sc-dropdown>
          </div>

          <div class="ui-g-12">
            {{ data?.selectedRows?.length || 0 }} {{ 'SELECTED' | translate }}
            {{ 'TRANSLATIONS' | translate }}
          </div>
        </div>

        <div *ngSwitchDefault>
          <h3>{{ 'IMPORT' | translate }}</h3>
          <div class="ui-g-12">
            <sc-local-file-input
              controlName="translationFile"
              [form]="form"
              label="{{ 'TRANSLATION_FILE' | translate }}"
            ></sc-local-file-input>
          </div>
        </div>
      </div>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>

      <!-- Footer -->
      <p-footer>
        <div class="ui-g-6"></div>
        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            (click)="dismissModal('cancel')"
            label="{{ 'CANCEL' | translate }}"
          ></button>

          <sc-submit-button
            buttonClass="ui-button-success"
            [disabled]="!form.valid"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
