import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ProtocolController } from '../models/protocol-controller';

import { config } from '@app/config';

@Injectable()
export class ProtocolControllersService {
  private apiUrl = config().apiUrl + '/protocolControllers';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): ProtocolController {
    const newData = Object.assign({}, data);

    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getProtocolController(id?: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getProtocolControllers(options?: any) {
    return this.httpClient.get(this.apiUrl);
  }

  createProtocolController(data: ProtocolController) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateProtocolController(id: number, data: ProtocolController) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteProtocolController(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
