import { Action } from '@ngrx/store';
import { Company } from '@widgets/companies/models/company';

// ____ LOAD COMPANY
export const LOAD_COMPANY = '[Companies] Load Company';
export const LOAD_COMPANY_FAIL = '[Companies] Load Company Fail';
export const LOAD_COMPANY_SUCCESS = '[Companies] Load Company Success';

export class LoadCompany implements Action {
  readonly type = LOAD_COMPANY;
  constructor(public payload: number) {}
}

export class LoadCompanyFail implements Action {
  readonly type = LOAD_COMPANY_FAIL;
  constructor(public payload: any) {}
}

export class LoadCompanySuccess implements Action {
  readonly type = LOAD_COMPANY_SUCCESS;
  constructor(public payload: Company) {}
}

// ____ LOAD COMPANIES
export const LOAD_COMPANIES = '[Companies] Load Companies';
export const LOAD_COMPANIES_FAIL = '[Companies] Load Companies Fail';
export const LOAD_COMPANIES_SUCCESS = '[Companies] Load Companies Success';

export class LoadCompanies implements Action {
  readonly type = LOAD_COMPANIES;
}

export class LoadCompaniesFail implements Action {
  readonly type = LOAD_COMPANIES_FAIL;
  constructor(public payload: any) {}
}

export class LoadCompaniesSuccess implements Action {
  readonly type = LOAD_COMPANIES_SUCCESS;
  constructor(public payload: Company[]) {}
}

// ____ CREATE COMPANY
export const CREATE_COMPANY = '[Companies] Create Company';
export const CREATE_COMPANY_FAIL = '[Companies] Create Company Fail';
export const CREATE_COMPANY_SUCCESS = '[Companies] Create Companies Success';

export class CreateCompany implements Action {
  readonly type = CREATE_COMPANY;
  constructor(public payload: Company) {}
}

export class CreateCompanyFail implements Action {
  readonly type = CREATE_COMPANY_FAIL;
  constructor(public payload: any) {}
}

export class CreateCompanySuccess implements Action {
  readonly type = CREATE_COMPANY_SUCCESS;
  constructor(public payload: Company) {}
}

// ____ UPDATE COMPANY
export const UPDATE_COMPANY = '[Companies] Update Company';
export const UPDATE_COMPANY_FAIL = '[Companies] Update Company Fail';
export const UPDATE_COMPANY_SUCCESS = '[Companies] Update Company Success';

export class UpdateCompany implements Action {
  readonly type = UPDATE_COMPANY;
  constructor(public payload: Company) {}
}

export class UpdateCompanyFail implements Action {
  readonly type = UPDATE_COMPANY_FAIL;
  constructor(public payload: any) {}
}

export class UpdateCompanySuccess implements Action {
  readonly type = UPDATE_COMPANY_SUCCESS;
  constructor(public payload: Company) {}
}

// ____ DELETE COMPANY
export const DELETE_COMPANY = '[Companies] Delete Company';
export const DELETE_COMPANY_FAIL = '[Companies] Delete Company Fail';
export const DELETE_COMPANY_SUCCESS = '[Companies] Delete Company Success';

export class DeleteCompany implements Action {
  readonly type = DELETE_COMPANY;
  constructor(public payload: Company) {}
}

export class DeleteCompanyFail implements Action {
  readonly type = DELETE_COMPANY_FAIL;
  constructor(public payload: any) {}
}

export class DeleteCompanySuccess implements Action {
  readonly type = DELETE_COMPANY_SUCCESS;
  constructor(public payload: Company) {}
}

// Reset Pending
export const COMPANY_RESET_PENDING_STATE = '[Companies] Reset pending state';

export class CompanyResetPendingState implements Action {
  readonly type = COMPANY_RESET_PENDING_STATE;
}

// ACTION TYPES
export type CompaniesAction =
  | LoadCompany
  | LoadCompanyFail
  | LoadCompanySuccess
  | LoadCompanies
  | LoadCompaniesFail
  | LoadCompaniesSuccess
  | CreateCompany
  | CreateCompanyFail
  | CreateCompanySuccess
  | UpdateCompany
  | UpdateCompanyFail
  | UpdateCompanySuccess
  | DeleteCompany
  | DeleteCompanyFail
  | DeleteCompanySuccess
  | CompanyResetPendingState;
