<div *ngIf="fetchingState === 0; else loadingIndicator">
  <div [formGroup]="form">
    <!--Header-->
    <div class="ui-g">
      <div class="ui-g-6 ui-g-nopad">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <sc-activate-button [form]="form" field="isActive"></sc-activate-button>
      </div>
    </div>

    <!-- BODY -->
    <div class="ui-g">
      <!-- LEFT_COLUMN -->
      <div class="ui-g-12 ui-md-6 ui-g-nopad">
        <div class="ui-g-12">
          <sc-text-input
            controlName="key"
            [form]="form"
            label="{{ 'KEY' | translate }}"
          ></sc-text-input>
        </div>

        <!-- <div class="ui-g-12">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div> -->

        <div class="ui-g-12">
          <sc-dropdown
            controlName="widgets"
            [form]="form"
            label="{{ 'WIDGETS' | translate }}"
            [options]="selectItems?.widgets"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>
      </div>

      <!-- RIGHT_COLUMN -->
      <div class="ui-g-12 ui-md-6 ui-g-nopad">
        <div class="ui-g-12">
          <sc-local-file-input
            controlName="icon"
            [form]="form"
            label="{{ 'ICON' | translate }}"
          ></sc-local-file-input>
        </div>

        <div class="ui-g-12">
          <sc-dropdown
            controlName="ruleTemplates"
            [form]="form"
            label="{{ 'RULE_TEMPLATES' | translate }}"
            [options]="selectItems?.ruleTemplates"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>
      </div>
    </div>

    <div class="ui-g" formArrayName="locales">
      <div class="ui-g-12">
        <p-accordion [activeIndex]="0">
          <p-accordionTab
            *ngFor="let ctrl of localesForm.controls; let i = index"
            [formGroupName]="i"
          >
            <p-header>
              <span
                class="flag-icon"
                [ngClass]="getFlag(ctrl?.get('locale')?.value)"
              ></span>
              {{ ctrl?.get("locale")?.value | uppercase }}
            </p-header>

            <div class="ui-g">
              <div class="ui-g-12">
                <sc-text-input
                  [form]="ctrl"
                  controlName="name"
                  label="{{ 'NAME' | translate }}"
                ></sc-text-input>

                <!-- <sc-textarea
                  [form]="ctrl"
                  controlName="description"
                  label="{{ 'DESCRIPTION' | translate }}"
                  [rows]="5"
                ></sc-textarea> -->
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>

    <!-- Error Message -->
    <div class="ui-g">
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="form.invalid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
