import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { config } from '@app/config';

@Component({
  selector: 'sc-device-plan',
  templateUrl: './device-plan.component.html',
  styleUrls: ['./device-plan.component.scss']
})
export class DevicePlanComponent implements OnInit {
  @Input()
  selectedDevice: any;
  @Input()
  devices: any[];
  @Input()
  location: any;
  @Output()
  positionChanged = new EventEmitter<{ x: number; y: number }>();

  floorplanImage: string;
  startPosition: { x: number; y: number };

  private maxPositionX = 700;
  private minPositionX = 0;
  private maxPositionY = 500;
  private minPositionY = 0;

  constructor() { }

  ngOnInit() {
    if (this.location && this.location.floorplanImage) {
      this.floorplanImage = config().s3Url + '/' + this.location.floorplanImage;
    }

    this.startPosition = this.getStartPosition();
  }

  onDragEnded(event) {
    const element = event.source.getRootElement();
    const boundingClientRect = element.getBoundingClientRect();
    const parentPosition = this.getParentPosition(element);
    const x = boundingClientRect.x - parentPosition.left;
    const y = boundingClientRect.y - parentPosition.top;
    this.positionChanged.emit({ x , y });
  }

  private getParentPosition(el) {
    let left = 0;
    let top = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      left += el.offsetLeft - el.scrollLeft;
      top += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top, left };
  }

  private getStartPosition() {
    if (
      this.selectedDevice &&
      typeof this.selectedDevice.instructionPositionX === 'number' &&
      typeof this.selectedDevice.instructionPositionY === 'number'
    ) {
      if (this.selectedDevice.instructionPositionX < this.minPositionX) {
        this.selectedDevice.instructionPositionX = this.minPositionX;
      } else if (this.selectedDevice.instructionPositionX > this.maxPositionX) {
        this.selectedDevice.instructionPositionX = this.maxPositionX;
      }

      if (this.selectedDevice.instructionPositionY < this.minPositionY) {
        this.selectedDevice.instructionPositionY = this.minPositionY;
      } else if (this.selectedDevice.instructionPositionY > this.maxPositionY) {
        this.selectedDevice.instructionPositionY = this.maxPositionY;
      }

      return { x: this.selectedDevice.instructionPositionX, y: this.selectedDevice.instructionPositionY };
    } else {
      return { x: this.maxPositionX / 2, y: this.maxPositionY / 2 };
    }
  }
}
