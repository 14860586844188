<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <!-- HEADER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <p-toggleButton
          formControlName="isActive"
          onLabel="{{ 'ACTIVE' | translate }}"
          onIcon="fa fa-check-square-o"
          offLabel="{{ 'ACTIVE' | translate }}"
          offIcon="fa fa-square-o"
        ></p-toggleButton>
      </div>
    </div>

    <!-- BODY -->
    <div class="ui-g">
      <!-- SCENE_TEMPLATE_ID FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="sceneTemplateId"
          [form]="form"
          label="{{ 'SCENE_TEMPLATE' | translate }}"
          [options]="selectItems['sceneTemplates']"
        ></sc-dropdown>
      </div>

      <div class="ui-g-12 ui-g-nopad" *ngIf="sceneTemplateId">
        <!-- NAME FIELD -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- DESCRIPTION FIELD -->
        <div class="ui-g-12">
          <sc-textarea
            controlName="description"
            [form]="form"
            label="{{ 'DESCRIPTION' | translate }}"
          ></sc-textarea>
        </div>

        <!-- COMMANDS FORM -->
        <div class="ui-g-12">
          <h2>{{ 'COMMANDS' | translate }}</h2>

          <div class="grey lighten-2">
            <div class="ui-g">
              <div class="ui-g-3">{{ 'SOURCE' | translate }}</div>

              <div class="ui-g-3">{{ 'ACTION' | translate }}</div>

              <div class="ui-g-5">
                {{ 'PARAMETERS' | translate }}

                <div class="ui-g">
                  <div class="ui-g-6">
                    <small>{{ 'KEY' | translate }}</small>
                  </div>
                  <div class="ui-g-6">
                    <small>{{ 'VALUE' | translate }}</small>
                  </div>
                </div>
              </div>

              <div class="ui-g-1">{{ 'DELAY' | translate }}</div>
            </div>
          </div>

          <div formArrayName="commands">
            <div
              *ngFor="let command of commandsFormArray.controls; let i = index"
              [formGroupName]="i"
            >
              {{ command['deviceTypeId'] }}
              <sc-scene-command-form
                [group]="command"
                [groupId]="i"
                [optionData]="rawData['devices']"
              ></sc-scene-command-form>
            </div>
          </div>
        </div>
      </div>

      <!-- ERROR MESSAGE -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</ng-template>
