import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WidgetCategory } from '../models/widget-category';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Injectable()
export class WidgetCategoriesService {
  private apiUrl = config().apiUrl + '/widgetCategories';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  /**
   * Delete properties that are not allowed on API
   * @param data
   */
  private verifyData(data: WidgetCategory): WidgetCategory {
    const newData = { ...data };
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getWidgetCategory(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getWidgetCategories() {
    return this.httpClient.get(this.apiUrl);
  }

  createWidgetCategory(data: WidgetCategory) {
    const formData = this.sharedService.createFormData(data, ['icon']);
    return this.httpClient.post(this.apiUrl, formData);
  }

  updateWidgetCategory(id: number, data: WidgetCategory) {
    const formData = this.sharedService.createFormData(this.verifyData(data), ['icon']);
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }

  deleteWidgetCategory(id: number) {
    const formData = this.sharedService.createFormData({
      isActive: false,
      isDeleted: true,
    });
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }
}
