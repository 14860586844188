<div [formGroup]="group" class="ui-g-12 ui-g-nopad">
  <div class="ui-g-2 ui-md-6 ui-lg-2">
    <sc-text-input
      controlName="commandActionId"
      [form]="group"
      label="{{ 'COMMAND_ACTION' | translate }} {{ 'ID' | translate }}"
    ></sc-text-input>
  </div>

  <div class="ui-g-2 ui-md-3 ui-lg-2 mt-4">
    <p-toggleButton
      formControlName="isActive"
      offIcon="fa fa-square-o"
      offLabel="{{ 'ACTIVE' | translate }}"
      onIcon="fa fa-check-square-o"
      onLabel="{{ 'ACTIVE' | translate }}"
    ></p-toggleButton>
  </div>

  <div class="text-right ui-g-2 ui-md-3 ui-lg-2 mt-4">
    <button
      pButton
      type="button"
      class="ui-button-success"
      icon="fa fa-plus"
      (click)="addParam()"
      pTooltip="{{ 'ADD' | translate }} {{ 'PARAMETER' | translate }}"
      appendTo="body"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-warning"
      icon="fa fa-trash"
      (click)="remove()"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'ACTION' | translate }}"
      appendTo="body"
    ></button>
  </div>

  <div class="ui-g-6 ui-md-12 ui-lg-6 pl-4">
    <div formArrayName="parameters">
      <div *ngFor="let ctrl of parameters.controls; let i=index" [formGroupName]="i">
        <sc-device-type-action-parameter-form
          [group]="ctrl"
          [groupId]="i"
          (removed)="removeParam(i)"
        ></sc-device-type-action-parameter-form>
      </div>
    </div>
  </div>
</div>
