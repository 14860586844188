import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { App } from '../models/app';

import { AppFormsService } from '../services/app-forms.service';
import { AppsService } from '../services/apps.service';
import { AppStyleTemplatesService } from '../../app-style-templates/services/app-style-templates.service';
import { SharedService } from '@shared/shared.service';

import { config } from '../../../config';

@Component({
  selector: 'sc-app-form',
  templateUrl: 'app-form.component.html'
})
export class AppFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: App;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  patterns = config().regexPatterns;
  isSubmitting: boolean;
  subscribers: { [key: string]: any };
  fetchingState: number;
  appStyleTemplates: SelectItem[];

  constructor(
    private appFormsService: AppFormsService,
    private appsService: AppsService,
    private appStyleTemplatesService: AppStyleTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.subscribers = {};
    this.fetchingState = 0;
    this.fetchAppStyleTemplates();

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchApp(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchAppStyleTemplates() {
    this.fetchingState++;
    this.subscribers.GET_APP_STYLE_TEMPLATES = this.appStyleTemplatesService
      .getAppStyleTemplates()
      .subscribe((result: any) => {
        // create app style templates list
        this.appStyleTemplates = this.sharedService.createSelectItems(
          result.data.filter(d => !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  fetchApp(id: number) {
    this.fetchingState++;
    this.subscribers.GET_APP = this.appsService
      .getApp(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.appFormsService.initAppForm();
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      // call api
      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create() {
    this.appsService
      .createApp(this.form.value)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update() {
    this.appsService
      .updateApp(this.formData.id, this.form.value)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.appsService
      .deleteApp(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify('App', result.message + ' Success!', 'success');
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
