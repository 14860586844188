import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Injectable()
export class MqttService {
  private userApiUrl = config().apiUrl + '/mqtt/user';
  private aclApiUrl = config().apiUrl + '/mqtt/acl';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  getUsers() {
    return this.httpClient.get(`${this.userApiUrl}`);
  }

  getUser(username: string) {
    return this.httpClient.get(`${this.userApiUrl}/${username}`);
  }

  createUser(username: string, password: string) {
    return this.httpClient.post(this.userApiUrl, { username, password });
  }

  updateUser(username: string, password: string) {
    return this.httpClient.patch(`${this.userApiUrl}/${username}`, { password });
  }

  deleteUser(username: string) {
    return this.httpClient.delete(`${this.userApiUrl}/${username}`);
  }

  getAcls() {
    return this.httpClient.get(`${this.userApiUrl}`);
  }

  createAcl(username: string, password: string) {
    return this.httpClient.post(this.userApiUrl, { username, password });
  }

  deleteAcl(topic: string, username?: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { topic, username },
    };
    return this.httpClient.delete(this.aclApiUrl, httpOptions);
  }
}
