<div [formGroup]="form" class="ui-g">
  <h4>
    {{ (isEndCondition ? 'END' : 'START') | translate }}
    {{ 'CONDITIONS' | translate }}
  </h4>

  <div class="ui-g-12 ui-g-nopad grey lighten-2">
    <div class="ui-g-1">
      {{ 'CONNECTOR' | translate }}
    </div>

    <div class="ui-g-3">
      {{ 'SOURCE' | translate }}
    </div>

    <div class="ui-g-1">
      {{ 'OPERATOR' | translate }}
    </div>

    <div class="ui-g-3">
      {{ 'REFERENCED' | translate }}
      <div class="ui-g">
        <div class="ui-g-6">
          <small>{{ 'SOURCE' | translate }}</small>
        </div>
        <div class="ui-g-6">
          <small>{{ 'VALUE' | translate }}</small>
        </div>
      </div>
    </div>

    <!-- <div class="ui-g-4 ui-g-nopad">
      <div class="ui-g-6">
        {{ 'PARAMETERS' | translate }}
      </div>
      <div class="ui-g-3">
        {{ 'WAS_TRUE_ONE_TIME_IN_PAST' | translate }}
      </div>
      <div class="ui-g-3">
        {{ 'VALIDITY_PERIOD' | translate }}
      </div>
    </div> -->
  </div>

  <ng-container [formArrayName]="conditionsFormName">
    <ng-container
      *ngFor="let cond of conditionsForm.controls; let i = index"
      [formGroupName]="i"
    >
      <div class="ui-g-12 ui-g-nopad">
        <!-- CONNECTOR -->
        <div class="ui-g-1">
          <div *ngIf="i > 0">
            {{ cond?.value?.connector }}
          </div>
        </div>

        <!-- SOURCE -->
        <div class="ui-g-3">
          <div [ngSwitch]="cond?.value?.sourceType">
            <!-- DEVICE -->
            <div *ngSwitchCase="'d'">
              {{ getDeviceTypeName(cond?.value?.deviceTypeId) }}
            </div>

            <!-- LOCATION -->
            <div *ngSwitchCase="'l'">
              {{
                getLocationPropertyLabel(cond?.value?.locationPropertyTypeId)
              }}
            </div>

            <!-- DATETIME -->
            <div *ngSwitchCase="'dt'">
              {{ cond?.value?.datetimePropertyTypeId }}
            </div>
          </div>
        </div>

        <!-- OPERATOR -->
        <div class="ui-g-1">
          {{ cond?.value?.operator }}
        </div>

        <!-- REFERENCED -->
        <div class="ui-g-3 ui-g-nopad">
          <!-- LOCATION / DEVICE -->
          <div class="ui-g" *ngIf="!cond?.value?.datetimePropertyTypeId">
            <!-- REFERENCED_TYPE -->
            <div class="ui-g-6" [ngSwitch]="cond?.value?.referencedType">
              <!-- DEVICE -->
              <div *ngSwitchCase="'d'">
                <!-- {{ 'REFERENCED' | translate }} -->
                {{ getDeviceTypeName(cond?.value?.referencedDeviceTypeId) }}
              </div>

              <!-- LOCATION -->
              <div *ngSwitchCase="'l'">
                <!-- {{ 'REFERENCED' | translate }} -->
                {{ 'LOCATION' | translate }}
              </div>

              <!-- VALUE -->
              <div *ngSwitchCase="'v'">
                <!-- {{ 'SELF' | translate }} -->
              </div>
            </div>

            <!-- REFERENCED_VALUE -->
            <div class="ui-g-6" [ngSwitch]="cond?.value?.referencedType">
              <!-- DEVICE -->
              <div *ngSwitchCase="'d'">
                {{ 'VALUE' | translate }}
              </div>

              <!-- LOCATION -->
              <div *ngSwitchCase="'l'">
                {{
                  getLocationPropertyLabel(
                    cond?.value?.referencedLocationPropertyTypeId
                  )
                }}
              </div>

              <!-- VALUE -->
              <div *ngSwitchCase="'v'">
                <div *ngIf="hasError(cond?.value)">
                  {{ cond?.value?.referencedValue }}
                </div>

                <div
                  *ngIf="!hasError(cond?.value) && hasMappingValues(cond, i)"
                >
                  <sc-dropdown
                    controlName="referencedValue"
                    [form]="cond"
                    label="{{ 'REFERENCED_VALUE' | translate }}"
                    [options]="hasMappingValues(cond, i)"
                    [showLabel]="false"
                  ></sc-dropdown>
                </div>

                <div
                  *ngIf="!hasError(cond?.value) && !hasMappingValues(cond, i)"
                >
                  <sc-text-input
                    controlName="referencedValue"
                    [form]="cond"
                    label="{{ 'REFERENCED_VALUE' | translate }}"
                    [showLabel]="false"
                  ></sc-text-input>
                </div>
              </div>
            </div>
          </div>

          <!-- DATETIME -->
          <div class="ui-g" *ngIf="cond?.value?.datetimePropertyTypeId">
            <!-- DATETIME_PROPERTY_VALUE -->
            <div class="ui-g-6 ui-g-offset-6">
              <div *ngIf="hasError(cond?.value)">
                {{ cond?.value?.datetimePropertyValue }}
              </div>

              <sc-text-input
                *ngIf="!hasError(cond?.value)"
                controlName="datetimePropertyValue"
                [form]="cond"
                label="{{ 'DATETIME_PROPERTY_VALUE' | translate }}"
                [showLabel]="false"
              ></sc-text-input>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="hasError(cond?.value) && conditions[i]"
        class="ui-g-12 ui-g-nopad"
        [ngClass]="hasError(cond?.value)"
      >
        <ng-container *ngIf="isEmpty(conditions[i])">
          <div class="text-center">{{ 'RULE_CONDITION_NOT_EXISTS' | translate }}</div>
        </ng-container>

        <ng-container *ngIf="!isEmpty(conditions[i])">
          <!-- CONNECTOR -->
          <div class="ui-g-1">
            <div *ngIf="i > 0">
              {{ conditions[i].connector }}
            </div>
          </div>

          <!-- SOURCE -->
          <div class="ui-g-3">
            <div [ngSwitch]="conditions[i].sourceType">
              <!-- DEVICE -->
              <div *ngSwitchCase="'d'">
                {{ getDeviceTypeName(conditions[i].deviceTypeId) }}
              </div>

              <!-- LOCATION -->
              <div *ngSwitchCase="'l'">
                {{
                  getLocationPropertyLabel(conditions[i].locationPropertyTypeId)
                }}
              </div>

              <!-- DATETIME -->
              <div *ngSwitchCase="'dt'">
                {{ conditions[i].datetimePropertyTypeId }}
              </div>
            </div>
          </div>

          <!-- OPERATOR -->
          <div class="ui-g-1">
            {{ conditions[i].operator }}
          </div>

          <!-- REFERENCED -->
          <div class="ui-g-3 ui-g-nopad">
            <!-- LOCATION / DEVICE -->
            <div class="ui-g" *ngIf="!conditions[i].datetimePropertyTypeId">
              <!-- REFERENCED_TYPE -->
              <div class="ui-g-6" [ngSwitch]="conditions[i].referencedType">
                <!-- DEVICE -->
                <div *ngSwitchCase="'d'">
                  <!-- {{ 'REFERENCED' | translate }} -->
                  {{ getDeviceTypeName(conditions[i].referencedDeviceTypeId) }}
                </div>

                <!-- LOCATION -->
                <div *ngSwitchCase="'l'">
                  <!-- {{ 'REFERENCED' | translate }} -->
                  {{ 'LOCATION' | translate }}
                </div>

                <!-- VALUE -->
                <div *ngSwitchCase="'v'">
                  <!-- {{ 'SELF' | translate }} -->
                </div>
              </div>

              <!-- REFERENCED_VALUE -->
              <div class="ui-g-6" [ngSwitch]="conditions[i].referencedType">
                <!-- DEVICE -->
                <div *ngSwitchCase="'d'">
                  {{ 'VALUE' | translate }}
                </div>

                <!-- LOCATION -->
                <div *ngSwitchCase="'l'">
                  {{
                    getLocationPropertyLabel(
                      conditions[i].referencedLocationPropertyTypeId
                    )
                  }}
                </div>

                <!-- VALUE -->
                <div *ngSwitchCase="'v'">
                  {{ conditions[i].referencedValue }}
                </div>
              </div>
            </div>

            <!-- DATETIME -->
            <div class="ui-g" *ngIf="conditions[i].datetimePropertyTypeId">
              <!-- DATETIME_PROPERTY_VALUE -->
              <div class="ui-g-6 ui-g-offset-6">
                {{ conditions[i].datetimePropertyValue }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
