import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationsWindowOpenComponent } from './locations-window-open.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationsWindowOpenComponent],
  entryComponents: [LocationsWindowOpenComponent],
  providers: []
})
export class LocationsWindowOpenModule {}
