import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SelectItem, Subscribers } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  arrayMove(array: any[], fromIndex: number, toIndex: number, padding = false) {
    if (toIndex < 0) {
      toIndex = 0;
    } else if (toIndex >= array.length) {
      toIndex = array.length;

      if (padding) {
        let k = toIndex - array.length + 1;
        while (k--) {
          array.push(undefined);
        }
      }
    }
    array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
    return array;
  }

  clearSubscribers(subscribers: Subscribers): void {
    Object.keys(subscribers).forEach((key) => {
      if (typeof subscribers[key] !== 'undefined') {
        subscribers[key].unsubscribe();
      }
    });
  }

  confirm(onAccept, options: Confirmation = {}) {
    options.header = options.header || 'Confirmation';
    options.message = options.message || 'Are you sure that you want to perform this action?';
    options.icon = options.icon || 'pi pi-exclamation-triangle';
    options.acceptButtonStyleClass = options.acceptButtonStyleClass || 'p-button-sm p-button-danger';
    options.rejectButtonStyleClass =
      options.rejectButtonStyleClass || 'p-button-sm p-button-outlined p-button-secondary';
    this.confirmationService.confirm({
      ...options,
      accept: onAccept,
    });
  }

  modal(component: ComponentType<any>, data?: any, config: any = {}) {
    const defaultConfig: any = {
      showHeader: false,
      dismissableMask: true,
      styleClass: 'modal-width--responsive', // 'modal-width--auto
      ...config,
      data,
    };
    return this.dialogService.open(component, defaultConfig);
  }

  selectItemMapper(data: any, labelKey = 'label', valueKey = 'id'): SelectItem {
    return { label: data[labelKey] || data[valueKey], value: data[valueKey] };
  }

  textTranslate(key: string, params = {}): Observable<string> {
    return this.translateService.get(key, params).pipe(take(1));
  }
}
