import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { DeviceTaskTemplate } from '../models/device-task-template';
import { SelectItem } from 'primeng/api';

import { DeviceTaskTemplateFormsService } from '../services/device-task-template-form.service';
import { DeviceTaskTemplatesService } from '../services/device-task-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-task-template-form',
  templateUrl: './device-task-template-form.component.html',
  styleUrls: ['./device-task-template-form.component.scss']
})
export class DeviceTaskTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: DeviceTaskTemplate;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};

  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceTaskTemplateFormsService: DeviceTaskTemplateFormsService,
    private deviceTaskTemplatesService: DeviceTaskTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectItems.sites = this.sharedService.createSelectItems(
      this.sharedService.sourceData.sites,
      false
    );
    this.selectItems.parameterTypes = [
      { label: 'Integer', value: 'integer' },
      { label: 'String', value: 'string' },
      { label: 'File', value: 'file' }
    ];

    this.initForm();

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      // FETCH FORM DATA
      this.fetchFormData(this.data.id);
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchFormData(id: number) {
    this.fetchingState++;
    this.subscribers.GET_FORM_DATA = this.deviceTaskTemplatesService
      .getDeviceTaskTemplate(id)
      .subscribe((result: any) => {
        this.formData = result.data;

        this.formData.site_ids = this.formData.site_ids
          ? this.sharedService.jsonParse(this.formData.site_ids)
          : [];

        this.formData.parameters = this.formData.parameters
          ? this.sharedService.jsonParse(this.formData.parameters)
          : [];

        this.form.patchValue(this.formData);

        this.deviceTaskTemplateFormsService.setParameterFormArray(
          this.form,
          this.formData.parameters
        );

        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.deviceTaskTemplateFormsService.initDeviceTaskTemplateForm();

    this.subscribers.WATCH_IS_PUBLIC = this.form
      .get('is_public')
      .valueChanges.subscribe(value => {
        if (value) {
          this.form.controls['site_ids'].reset();
          this.form.controls['site_ids'].disable();
        } else {
          this.form.controls['site_ids'].enable();
        }
      });
  }

  addParameter() {
    const control = <FormArray>this.form.controls['parameters'];
    control.push(this.deviceTaskTemplateFormsService.initParameterFormGroup());
  }

  removeParameter(i: number) {
    const control = <FormArray>this.form.controls['parameters'];
    control.removeAt(i);
  }

  get parametersForm(): FormArray {
    return <FormArray>this.form.controls['parameters'];
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };

      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(data: DeviceTaskTemplate) {
    this.subscribers.CREATE = this.deviceTaskTemplatesService
      .createDeviceTaskTemplate(data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  update(data: DeviceTaskTemplate) {
    this.subscribers.UPDATE = this.deviceTaskTemplatesService
      .updateDeviceTaskTemplate(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE = this.deviceTaskTemplatesService
      .deleteDeviceTaskTemplate(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE_TASK_TEMPLATES');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
