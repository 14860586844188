import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SCModalDirective } from './modal.directive';

import { SCModalTriggerComponent } from './modal-trigger.component';

@NgModule({
  imports: [CommonModule, ButtonModule, DialogModule, TooltipModule, TranslateModule.forChild()],
  declarations: [SCModalDirective, SCModalTriggerComponent],
  exports: [SCModalTriggerComponent],
})
export class SCModalModule {}
