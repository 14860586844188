import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  SmsTemplate,
  EmailTemplate,
  GeneralTemplate
} from '../models/notification-template';

@Injectable()
export class NotificationTemplateFormService {
  constructor(private formBuilder: FormBuilder) {}

  initNotificationTemplateFormGroup() {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      description: null,
      priority: 0,
      placeholders: null,
      roles: [null, Validators.required],
      emailTemplates: this.formBuilder.array([]),
      generalTemplates: this.formBuilder.array([]),
      smsTemplates: this.formBuilder.array([]),
      isPublic: false,
      siteIds: null,
      isActiveSendEmails: false,
      isActiveSendSms: false,
      isActive: false,
      isDeleted: false
    });
  }

  initEmailFormGroup() {
    return this.formBuilder.group({
      subject: [null, Validators.required],
      content: null,
      contentHtml: null,
      locale: [null, Validators.required],
      notificationTemplateId: [null, Validators.required],
      isActive: false
    });
  }

  setEmailFormArray(form: FormGroup, data: EmailTemplate[]) {
    const formGroups = data.map(item => this.formBuilder.group(item));
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('emailTemplates', formArray);
  }

  initGeneralFormGroup() {
    return this.formBuilder.group({
      subject: [null, Validators.required],
      content: null,
      locale: [null, Validators.required],
      notificationTemplateId: [null, Validators.required],
      isActive: false
    });
  }

  setGeneralFormArray(form: FormGroup, data: GeneralTemplate[]) {
    const formGroups = data.map(item => this.formBuilder.group(item));
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('generalTemplates', formArray);
  }

  initSmsFormGroup() {
    return this.formBuilder.group({
      content: [null, Validators.required],
      locale: [null, Validators.required],
      notificationTemplateId: [null, Validators.required],
      isActive: false
    });
  }

  setSmsFormArray(form: FormGroup, data: SmsTemplate[]) {
    const formGroups = data.map(item => this.formBuilder.group(item));
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('smsTemplates', formArray);
  }
}
