import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { SelectItem } from 'primeng/api';
import { Benchmark } from '../../models/benchmark';
import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';

import { BenchmarkTemplateFormsService } from '../../services/benchmark-template-forms.service';
import { BenchmarkTemplatesService } from '../../services/benchmark-templates.service';
import { LocationBenchmarkFormsService } from '../../services/location-benchmark-forms.service';
import { BenchmarksBatchService } from '../../services/benchmarks-batch.service';
import { SharedService } from '@shared/shared.service';

import * as moment from 'moment';

@Component({
  selector: 'sc-location-benchmark-form',
  templateUrl: 'location-benchmark-form.component.html'
})
export class LocationBenchmarkFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: Benchmark;
  form: FormGroup;
  errorMessage: string;
  fetchingState: number;
  isSubmitting: boolean;
  selectItems: { [key: string]: SelectItem[] };

  private selectedSite: Site;
  private selectedLocation: Location;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private benchmarksBatchService: BenchmarksBatchService,
    private benchmarkTemplateFormsService: BenchmarkTemplateFormsService,
    private benchmarkTemplatesService: BenchmarkTemplatesService,
    private locationBenchmarkFormsService: LocationBenchmarkFormsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectItems = this.benchmarkTemplateFormsService.getFormSelectItems();
    this.selectedSite = this.sharedService.selectedSite;

    // get location details
    this.subscribers.LOCATION_DETAIL = this.store
      .select(fromStore.getLocationDetials)
      .subscribe(result => {
        if (result) {
          this.selectedLocation = result;
        }
      });

    this.fetchBenchmarkTemplates();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchBenchmarkTemplates() {
    this.fetchingState++;
    this.subscribers.GET_BENCHMARK_TEMPLATE = this.benchmarkTemplatesService
      .getBenchmarkTemplates()
      .subscribe((result: any) => {
        const benchmarkTemplates = result.data.filter(d => {
          if (d.isActive && !d.isDeleted) {
            if (d.isPublic) {
              return true;
            } else if (d.sites && d.sites.indexOf(this.selectedSite.id) >= 0) {
              return true;
            }
            return false;
          }
          return false;
        });
        this.selectItems.benchmarkTemplates = this.sharedService.createSelectItems(
          benchmarkTemplates,
          false
        );
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.locationBenchmarkFormsService.initLocationBenchmarkForm();
    this.form.patchValue({ locations: this.selectedLocation.id });
  }

  onSelectDate(event) {
    this.form.patchValue({
      from: event.from,
      to: event.to
    });
  }

  submit() {
    this.isSubmitting = true;
    const formData = { ...this.form.value };

    this.subscribers.GENERATE_BENCHMARK = this.benchmarksBatchService
      .generateBenchmark(formData)
      .subscribe(
        (result: any) => {
          this.isSubmitting = false;
          // close the form and return result
          const rowData = {
            id: '' + new Date().getTime(), // <-- just for indexing in the table
            batch_id: result.data.batch_id,
            benchmarkTemplateId: formData.benchmarkTemplate,
            locationId: formData.locations,
            start_time: moment
              .utc(formData.from, 'DD.MM.YYYYTHH:mm:ss')
              .format(),
            status: result.data.status
          };
          this.onClose.emit(rowData);

          this.sharedService.notify(
            'Create Benchmark Success',
            'Benchmark is running in background until done',
            'info'
          );
        },
        (error: any) => {
          // display error message and unlock the form
          this.errorMessage = error;
          this.isSubmitting = false;
        }
      );
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
