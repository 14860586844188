import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ModalConfig } from '@sc/modal/modal-config';

@Component({
  selector: 'sc-vds-action-cell',
  templateUrl: './vds-action-cell.component.html',
})
export class VirtualDevicesActionCellComponent implements ICellRendererAngularComp {
  cell: any;
  deviceForm: ModalConfig;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;

    this.deviceForm = {
      data: this.cell.data,
      name: 'DeviceForm',
      options: {
        modalTitle: 'DEVICE_FORM_TITLE',
        requiredPermission: 'device_c',
        fullScreen: true,
      },
    };
  }

  refresh(params: any): boolean {
    return false;
  }

  onUpdateSuccess(event) {
    if (event.result) {
      if (this.cell && this.cell.context && this.cell.context) {
        if (this.cell.context.parentComponent) {
          this.cell.context.parentComponent.updateRow({ ...event.result, id: this.cell.data.id });
        }
      }
    }
  }
}
