import { Component, OnInit, Input } from '@angular/core';

import { DashboardCircleConfig, DashboardCircleDataset } from './dashboard-circles';

@Component({
  selector: 'sc-dashboard-circles',
  templateUrl: './dashboard-circles.component.html',
  styleUrls: ['./dashboard-circles.component.scss'],
})
export class SCDashboardCirclesComponent implements OnInit {
  @Input()
  config: DashboardCircleConfig;
  @Input()
  dataset: DashboardCircleDataset[];
  @Input()
  loading: boolean;

  ngOnInit() {
    if (!this.dataset || this.dataset.length === 0) {
      this.dataset = [{ primaryText: 0, customClass: 'grey lighten-2' }];
    }
  }
}
