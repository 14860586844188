import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AutomationSettingComponent } from './automation-setting/automation-setting.component';
import { DeviceHolderSettingComponent } from './device-holder-setting/device-holder-setting.component';
import { DeviceItemComponent } from './device-item/device-item.component';
import { DevicePlanComponent } from './device-plan/device-plan.component';
import { DeviceSettingComponent } from './device-setting/device-setting.component';
import { DeviceSummaryComponent } from './device-summary/device-summary.component';
import { CreateWizardComponent } from './create-wizard/create-wizard.component';
import { UpdateWizardComponent } from './update-wizard/update-wizard.component';
import { SearchFormComponent } from './search-form/search-form.component';

import { DeviceWizardService } from './device-wizard.service';

@NgModule({
  imports: [SharedModule],
  exports: [DeviceItemComponent, SearchFormComponent],
  declarations: [
    AutomationSettingComponent,
    DeviceHolderSettingComponent,
    DeviceItemComponent,
    DevicePlanComponent,
    DeviceSettingComponent,
    DeviceSummaryComponent,
    SearchFormComponent,
    CreateWizardComponent,
    UpdateWizardComponent,
  ],
  entryComponents: [CreateWizardComponent, UpdateWizardComponent],
  providers: [DeviceWizardService],
})
export class DeviceWizardModule {}
