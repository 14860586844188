import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CreateOptions } from '../models/create-options';
import { LazyloadOptions } from '../models/lazyload-options';
import { SummaryOptions } from '../models/summary-options';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class DeviceHistoryLogsService {
  private apiUrl = config().apiUrl + '/devices';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  /**
   * Get Device History Logs / Latest
   * @param options
   */
  getHistoryLogs(options: LazyloadOptions) {
    let queryParams: any = [];

    for (const key in options.filter) {
      if (options.filter.hasOwnProperty(key) && key !== 'deviceId') {
        queryParams.push(`${key}=${options.filter[key]}`);
      }
    }

    if (options.fields) {
      queryParams.push(`fields=${options.fields}`);
    }

    if (!isNaN(options.limit)) {
      queryParams.push(`limit=${options.limit}`);
    } else {
      queryParams.push(`limit=500`);
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';

    const url =
      this.apiUrl +
      (options.deviceId ? '/' + options.deviceId : '') +
      '/history' +
      (options.latestLog ? '/latest' : '') +
      queryParams;

    return this.httpClient.get(url);
  }

  /**
   * Get Device History Logs Summary
   * @param options
   */
  getHistoryLogsSummary(options: SummaryOptions) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    const url = `${this.apiUrl}/history/summary/${queryParams}`;
    return this.httpClient.get(url);
  }

  /**
   * Create Device History Log
   * @param options
   */
  addHistoryLog(options: CreateOptions) {
    // TODO: add cloudMQTT
    const url =
      `${config().apiUrl}/_automation/` +
      `${options.automationId}/webserver/` +
      (options.serviceProvider === 'pushy' ? 'pushy/' : '') +
      `send?apiKey=${options.apiKey}` +
      `&action=addDeviceHistoryLog` +
      `&deviceId=${options.deviceId}` +
      `&value=${options.value}`;

    return this.httpClient.post(url, {});
  }

  /**
   * Get Devices History Log of Automation
   * @param options
   */
  getAutomationHistoryLogs(automationId: number, options: any) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    const url =
      this.apiUrl + '/history/automation/' + automationId + queryParams;
    return this.httpClient.get(url);
  }
}
