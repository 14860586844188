<div class="location-devices">
  <!-- <div class="location-devices-header">
    <div class="sc-flex align-center">
      <h4 class="header-title">
        {{ 'DEVICES' | translate }}
      </h4>
    </div>
  </div> -->

  <div class="location-devices-body">
    <sc-table
      [columns]="columns"
      [dataset]="dataset"
      [title]="tableTitle"
      [toolbarItems]="toolbarItems"
      [hideIcon]="true"
      (afterInit)="afterInitTable($event)"
    ></sc-table>
  </div>
</div>
