import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { Location } from '../locations/models/location';
import { Site } from '../sites/models/site';

import { LocationsService } from '../locations/services/locations.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'sc-locations-alert-modal',
  templateUrl: './locations-alert-modal.component.html'
})
export class LocationAlertModalComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  dataset: any[];
  isFetching: boolean;

  private locations: Location[];
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    public locationsService: LocationsService,
    public sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;
    this.fetchLocations();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchLocations() {
    this.isFetching = true;
    const { companyId, id } = this.selectedSite;
    this.subscribers.GET_LOCATION = this.locationsService
      .getLocations({ companyId, siteId: id })
      .subscribe((result: any) => {
        this.locations = result.data;
        this.getLocationDetail();
        this.isFetching = false;
      });
  }

  getLocationDetail() {
    if (this.data && this.data.length > 0) {
      this.dataset = this.data.map(item => {
        const location = this.locations.find(l => l.id === +item.locationId);
        return { ...location, ...item };
      });
    }
  }

  getDisplayValue(location) {
    if (location.humidity) {
      return this.sharedService.numberFormat(location.humidity);
    } else if (location.temperature) {
      return this.sharedService.numberFormat(location.temperature);
    } else if (location.consumption) {
      return this.sharedService.numberFormat(location.consumption);
    } else if (location.windowOpen) {
      return location.windowOpen;
    } else if (location.doorOpen) {
      return location.doorOpen;
    } else if (location.makeUpStatus) {
      return location.makeUpStatus;
    }
    // else if (location.occupied) {
    //   return location.occupied;
    // }
  }

  getDisplayLoggedAt(location) {
    if (location.humidityLoggedAt) {
      return this.sharedService.dateFormat(location.humidityLoggedAt);
    } else if (location.temperatureLoggedAt) {
      return this.sharedService.dateFormat(location.temperatureLoggedAt);
    } else if (location.consumptionLoggedAt) {
      return this.sharedService.dateFormat(location.consumptionLoggedAt);
    } else if (location.windowOpenLoggedAt) {
      return this.sharedService.dateFormat(location.windowOpenLoggedAt);
    } else if (location.doorOpenLoggedAt) {
      return this.sharedService.dateFormat(location.doorOpenLoggedAt);
    } else if (location.makeUpStatusLoggedAt) {
      return this.sharedService.dateFormat(location.makeUpStatusLoggedAt);
    }
    // else if (location.occupiedLoggedAt) {
    //   return this.sharedService.dateFormat(location.occupiedLoggedAt);
    // }
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
