<div class="search-form">
  <form [formGroup]="form" novalidate autocomplete="off">
    <div class="ui-g">
      <!-- search -->
      <div class="ui-g-4">
        <sc-text-input
          controlName="search"
          [form]="form"
          label="{{ 'SEARCH' | translate }}"
        ></sc-text-input>
      </div>

      <!-- device type -->
      <div class="ui-g-4">
        <sc-dropdown
          controlName="deviceTypes"
          [form]="form"
          label="{{ 'DEVICE_TYPES' | translate }}"
          [options]="selectItems?.deviceTypes"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>

      <!-- protocol -->
      <div class="ui-g-4">
        <sc-dropdown
          controlName="deviceProtocols"
          [form]="form"
          label="{{ 'PROTOCOLS' | translate }}"
          [options]="selectItems?.deviceProtocols"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>
    </div>
  </form>
</div>
