<div class="sc-widget">
  <div class="sc-widget-header">
    <h4 class="sc-widget-title">
      {{ titleText | translate }}
    </h4>

    <!-- <div class="sc-widget-menu"></div> -->
  </div>

  <ng-container *ngIf="loading; then spinner; else content"></ng-container>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body">
    <div class="sc-flex justify-content-between w-100">
      <div class="sc-flex">
        <sc-square
          *ngIf="squareOptions"
          [customClass]="squareOptions.customClass"
          [icon]="squareOptions.icon"
          [primaryText]="squareOptions.primaryText"
        ></sc-square>
      </div>

      <div class="sc-flex direction-column grow-1 justify-content-end">
        <small *ngIf="subtitleText" class="grey-text lighten-2">
          {{ subtitleText | translate }}
        </small>

        <div *ngFor="let d of topThreeData" class="ui-g ui-g-nopad text-right">
          <div class="ui-g-12 ui-md-6 ui-g-nopad">
            {{ d.label | translate: d.labelParams }}
          </div>
          <div class="ui-g-12 ui-md-6 ui-g-nopad">
            {{ d.value | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="sc-flex direction-column grow-1 w-100">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="sc-widget-footer" *ngIf="detailsModal">
    <sc-modal-trigger [modalConfig]="detailsModal">
      <a class="sc-clickable">
        {{ 'DETAILS' | translate }}
        <i class="fa fa-angle-right"></i>
      </a>
    </sc-modal-trigger>
  </div>
</ng-template>
