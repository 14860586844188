import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

// COMPONENTS
import { RuleMassEditComponent } from './rule-mass-edit.component';
import { OptionsFormComponent } from './options-form/options-form.component';
import { RuleListComponent } from './rule-list/rule-list.component';
import { RuleMassEditFormComponent } from './rule-mass-edit-form/rule-mass-edit-form.component';
import { RuleConditionMassEditFormComponent } from './condition-form/condition-form.component';
import { RuleCommandMassEditFormComponent } from './command-form/command-form.component';
import { DeviceParameterFormComponent } from './device-parameter-form/device-parameter-form.component';
import { LocationParameterFormComponent } from './location-parameter-form/location-parameter-form.component';

// SERVICES
import { RuleMassEditFormsService } from './services/rule-mass-edit-forms.service';
import { RuleMassEditService } from './services/rule-mass-edit.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    RuleMassEditComponent,
    OptionsFormComponent,
    RuleListComponent,
    RuleMassEditFormComponent,
    RuleCommandMassEditFormComponent,
    RuleConditionMassEditFormComponent,
    DeviceParameterFormComponent,
    LocationParameterFormComponent
  ],
  entryComponents: [RuleMassEditComponent /*RuleMassEditFormComponent*/],
  providers: [RuleMassEditFormsService, RuleMassEditService]
})
export class RuleMassEditModule {}
