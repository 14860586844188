import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule, FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';

// primeng modules
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule, DialogService } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

import * as fromComponents from './components';

const primengModules: any[] = [
  BreadcrumbModule,
  ButtonModule,
  CalendarModule,
  CheckboxModule,
  ConfirmDialogModule,
  DropdownModule,
  DynamicDialogModule,
  InputNumberModule,
  InputTextModule,
  InputTextareaModule,
  MessagesModule,
  MessageModule,
  MultiSelectModule,
  OverlayPanelModule,
  PanelModule,
  RadioButtonModule,
  TableModule,
  ToastModule,
  TooltipModule,
  TreeModule,
  TreeTableModule,
];

@NgModule({
  declarations: [...fromComponents.components],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule.forChild({ extend: true, isolate: false }),
    ...primengModules,
  ],
  exports: [fromComponents.RuleTemplateOverviewComponent, fromComponents.RuleOverviewComponent, fromComponents.RuleConditionComponent, fromComponents.RuleCommandsComponent, fromComponents.InputLabelComponent],
  providers: [ConfirmationService, DialogService, MessageService],
})
export class CeosRuleEditorModule {
  constructor(faConfig: FaConfig, library: FaIconLibrary) {
    library.addIconPacks(fas);
    faConfig.defaultPrefix = 'fas';
    faConfig.fixedWidth = true;
  }
}
