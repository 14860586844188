import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from '@app/shared/shared.service';
import { NotificationsService } from '@app/widgets/notifications/notifications.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sc-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit, OnDestroy {
  public notification: any;
  public isFetching: boolean;
  public isRead: boolean;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private modalConfig: DynamicDialogConfig,
    private modalRef: DynamicDialogRef,
    private notificationsService: NotificationsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.modalConfig && this.modalConfig.data) {
      this.notification = this.modalConfig.data.notification;
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  closeModal() {
    this.modalRef.close();
  }

  setAsRead() {
    this.subscribers.setAsRead = this.notificationsService.setAsread(this.notification.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  apiCallSuccess(result: any) {
    this.isRead = false;
    this.modalRef.close();
  }

  apiCallError(error: any) {
    this.isRead = false;
    const text = this.sharedService.getTranslation('UPDATE_FAIL');
    const title = this.sharedService.getTranslation('NOTIFICATION');
    this.sharedService.notify(title, text, 'error');
  }
}
