import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { GridOptions } from 'ag-grid';
import { Device } from '../models/device';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { ProtocolController } from '@widgets/devices/models/protocol-controller';
import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';
import { ModalConfig } from '@sc/modal/modal-config';

import { AuthService } from '@app/auth/services/auth.service';
import { DevicesService } from '../services/devices.service';
import { DeviceModelsService } from '@widgets/device-models/services/device-models.service';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { ProtocolControllersService } from '@widgets/devices/services/protocol-controllers.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { DeviceHistoryLogsService } from '../services/device-history-logs.service';
import { SCTableService } from '@sc/table/table.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { DeviceActionCellComponent } from '../device-action-cell/device-action-cell.component';
import { DeviceLiveDataCellComponent } from '../device-live-data-cell/device-live-data-cell.component';

import { uniq, uniqBy } from 'lodash';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateWizardComponent } from '@widgets/device-wizard/create-wizard/create-wizard.component';

@Component({
  selector: 'sc-devices-table',
  templateUrl: './devices-table.component.html',
})
export class DevicesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;
  @Input()
  selectedLocation: Location;

  columns: any[];
  dataset: Device[];
  formConfig: ModalConfig;
  tableTitle = 'DEVICES';
  datasource: any;
  table: GridOptions;
  isFetchingDataset = false;
  isFetchingLatestHistory = false;
  isAvailable = this.authService.isAuthorized('admin');
  deviceTypes: DeviceType[] = []; // share to logs comparison, wizard
  deviceModels: DeviceModel[] = []; // share to logs, logs comparison, wizard
  deviceProtocols: DeviceProtocol[] = []; // share to wizard
  protocolControllers: ProtocolController[] = []; // share to wizard
  gridOptions: GridOptions;
  selectedDevices: Device[] = [];

  devices: Device[] = [];
  private deviceIds: number[] = [];
  private selectedSite: Site;
  private intervals: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};
  private visibleColumns: string[] = [];
  private wizardModal: DynamicDialogRef;

  constructor(
    private authService: AuthService,
    private devicesService: DevicesService,
    private deviceModelsService: DeviceModelsService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceTypesService: DeviceTypesService,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private protocolControllersService: ProtocolControllersService,
    private tableService: SCTableService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    // Table Columns
    this.columns = this.createColumns();

    // Create Device Form Config
    this.formConfig = {
      name: 'DeviceForm',
      options: {
        modalTitle: 'DEVICE_FORM_TITLE',
        requiredPermission: 'device_c',
        fullScreen: true,
      },
    };

    // Set user's visible columns
    if (this.config && this.config.customOptions && this.config.customOptions.visibleColumns) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }

    // Get Selected Site
    this.subscribers.watchSite = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.selectedDevices = [];
        this.devices = [];
        this.stopAutoUpdate();
        this.fetchDeviceModels();
        this.fetchDeviceProtocols();
        this.fetchDeviceTypes();
        this.fetchProtocolControllers();
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
    this.stopAutoUpdate();
  }

  fetchDataset() {
    if (this.isFetchingDataset || !this.selectedSite) {
      return;
    }

    this.isFetchingDataset = true;

    const columns = [
      ...this.visibleColumns,
      'isControllableByUser',
      'avcCalcWeight',
      'apiKey',
      'automationId',
      'deviceModelId',
      'deviceTypeId',
      'virtualDeviceHolderId',
      'gatewayId',
      'locationId',
      'ip',
      'port',
      'isActive',
      'isDeleted',
    ].join();

    this.datasource = {
      getRows: (params) => {
        const options: any = {
          siteId: this.selectedSite.id,
          columns,
          filter: params.filterModel,
          sort: params.sortModel,
          offset: params.startRow,
          limit: 100,
        };

        if (this.selectedLocation) {
          options.locationId = this.selectedLocation.id;
        }

        this.subscribers.getDataset = this.devicesService.getDevices(options).subscribe((result: any) => {
          const rowData = result.data;
          this.collectDevices(rowData);
          let lastRow = -1;
          if (rowData.length < 100) {
            lastRow = params.endRow - 100 + rowData.length;
          }
          params.successCallback(rowData, lastRow);
          this.isFetchingDataset = false;
          this.startAutoUpdate();
        });
      },
    };

    if (this.table && this.table.api) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  private fetchDeviceTypes() {
    this.deviceTypes = [];
    this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data;
    });
  }

  private fetchDeviceModels() {
    this.deviceModels = [];
    this.subscribers.fetchDeviceModels = this.deviceModelsService.getDeviceModels().subscribe((result: any) => {
      this.deviceModels = result.data;
    });
  }

  private fetchDeviceProtocols() {
    this.deviceProtocols = [];
    this.subscribers.fetchDeviceProtocols = this.deviceProtocolsService.getDeviceProtocols().subscribe({
      next: (result: any) => {
        this.deviceProtocols = result.data;
      },
    });
  }

  private fetchProtocolControllers() {
    this.protocolControllers = [];
    this.subscribers.getProtocolController = this.protocolControllersService.getProtocolControllers().subscribe({
      next: (result: any) => {
        this.protocolControllers = result.data;
      },
    });
  }

  private fetchLatestHistory() {
    if (this.isFetchingLatestHistory || !this.selectedSite) {
      return;
    }
    this.isFetchingLatestHistory = true;
    const options = {
      filter: {
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id,
      },
      latestLog: true,
      limit: 5000,
    };
    this.subscribers.GET_LATEST_HISTORY = this.deviceHistoryLogsService
      .getHistoryLogs(options)
      .subscribe((result: any) => {
        this.isFetchingLatestHistory = false;
        this.updateLatestHistory(result);
      });
  }

  private updateLatestHistory(latestHistory: any) {
    if (this.isFetchingDataset || !latestHistory || !latestHistory.length) {
      return;
    }
    const itemsToUpdate = latestHistory.map((history) => {
      return {
        id: +history.deviceId,
        latestLogValue: history.value,
        latestLogValueLoggedAt: history.createdAt,
      };
    });
    this.tableService.updateTableRow(this.table, itemsToUpdate);
  }

  updateLiveStatus(liveData: any) {
    if (liveData && liveData.data && liveData.data.length) {
      const itemsToUpdate = liveData.data.map((ldata) => ({
        id: +ldata.deviceId,
        liveStatus: ldata.value,
        liveStatusLoggedAt: liveData.updatedAt,
      }));
      this.tableService.updateTableRow(this.table, itemsToUpdate);
    }
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.rowDeselection = true;
    this.table.groupSelectsChildren = true;
    this.table.onSelectionChanged = () => {
      const selected = this.table.api.getSelectedNodes();
      if (selected && selected.length) {
        this.selectedDevices = selected.map((row) => row.data.id);
      }
    };

    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private startAutoUpdate() {
    this.fetchLatestHistory();
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = setInterval(() => this.fetchLatestHistory(), 10000);
  }

  private stopAutoUpdate() {
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = null;
    this.isFetchingLatestHistory = false;
  }

  // collect devices showns on the table
  private collectDevices(newDevices: any[]) {
    if (!newDevices || !newDevices.length) {
      return;
    }

    for (let i = 0; i < newDevices.length; i++) {
      const d = newDevices[i];

      // || d.deviceTypeKey === 'a'
      if (d.isDeleted || d.deviceTypeKey === 'g') {
        continue;
      } else if (this.deviceIds.indexOf(d.id) >= 0) {
        continue;
      }

      this.devices.push(d);
      this.deviceIds.push(d.id);

      if (d.children && d.children.length) {
        this.collectDevices(d.children);
      }
    }
  }

  get deviceComparisonModalConfig() {
    const devices = uniqBy(this.devices, 'id');
    const selected = uniq(this.selectedDevices);
    return {
      name: 'DeviceLogsComparison',
      data: {
        deviceModels: this.deviceModels,
        deviceTypes: this.deviceTypes,
        devices,
        selected,
      },
      options: {
        modalTitle: 'DEVICE_LOGS_COMPARISON_TITLE',
        fullScreen: true,
      },
    };
  }

  get bulkModificationFormConfig() {
    const selected = uniq(this.selectedDevices);
    return {
      name: 'BulkModificationForm',
      data: {
        table: 'devices',
        selected,
      },
      options: {
        modalTitle: 'BULK_MODIFICATION_FORM_TITLE',
        fullScreen: true,
      },
    };
  }

  private createColumns() {
    return [
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
        cellRenderer: 'loadingRenderer',
        checkboxSelection: (params) => {
          return (
            typeof params.data !== 'undefined' &&
            !params.data.isDeleted &&
            params.data.deviceTypeKey !== 'a' &&
            params.data.deviceTypeKey !== 'g'
          );
        },
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'deviceType',
        headerName: 'DEVICE_TYPE',
        field: 'deviceType',
      },
      {
        colId: 'deviceTypeId',
        headerName: 'DEVICE_TYPE_ID',
        field: 'deviceTypeId',
      },
      {
        colId: 'deviceModelId',
        headerName: 'DEVICE_MODEL_ID',
        field: 'deviceModelId',
      },
      {
        colId: 'locationName',
        headerName: 'LOCATION',
        field: 'locationName',
      },
      {
        colId: 'locationId',
        headerName: 'LOCATION_ID',
        field: 'locationId',
      },
      {
        colId: 'latestLogValue',
        headerName: 'LATEST_LOG_VALUE',
        field: 'latestLogValue',
        cellRendererFramework: DeviceLiveDataCellComponent,
        suppressSorting: true,
        suppressFilter: true,
      },
      {
        colId: 'liveStatus',
        headerName: 'LIVE_STATUS',
        field: 'liveStatus',
        cellRendererFramework: DeviceLiveDataCellComponent,
        suppressSorting: true,
        suppressFilter: true,
      },
      {
        colId: 'uuid',
        headerName: 'NODE_ID',
        field: 'uuid',
      },
      {
        colId: 'isGrouped',
        headerName: 'IS_GROUPED',
        field: 'isGrouped',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: ['equals'],
          defaultOption: 'equals',
        },
      },
      {
        colId: 'gatewayId',
        headerName: 'GATEWAY_ID',
        field: 'gatewayId',
      },
      {
        colId: 'automationId',
        headerName: 'AUTOMATION_ID',
        field: 'automationId',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        suppressSorting: true,
        suppressFilter: true,
      },
      {
        colId: 'isInActive',
        headerName: 'INACTIVE',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        filterValueGetter: (params) => {
          if (!params.data.isActive && !params.data.isDeleted) {
            return 1;
          }
          return 0;
        },
        suppressSorting: true,
        suppressFilter: true,
        hide: true,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        suppressSorting: true,
        suppressFilter: true,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        width: 50,
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceActionCellComponent,
      },
    ];
  }

  openWizard() {
    this.stopAutoUpdate();
    this.wizardModal = this.dialogService.open(CreateWizardComponent, {
      header: this.sharedService.getTranslation('DEVICE_WIZARD_MODAL_TITLE'),
      styleClass: 'fullscreen',
      data: {
        deviceModels: this.deviceModels,
        deviceTypes: this.deviceTypes,
        deviceProtocols: this.deviceProtocols,
        protocolControllers: this.protocolControllers,
      },
    });

    this.wizardModal.onClose.subscribe((result: any) => {
      this.table.api.refreshInfiniteCache();
      this.startAutoUpdate();
    });
  }
}
