<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate style="padding: 30px">
    <!-- header -->
    <div class="ui-g">
      <div class="ui-g-6 ui-g-nopad">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <p-toggleButton
          formControlName="isActive"
          offIcon="fa fa-square-o"
          offLabel="{{ 'ACTIVE' | translate }}"
          onIcon="fa fa-check-square-o"
          onLabel="{{ 'ACTIVE' | translate }}"
        ></p-toggleButton>
      </div>
    </div>

    <!-- body -->
    <div class="ui-g">
      <!-- key -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="key"
          [form]="form"
          label="{{ 'KEY' | translate }}"
        ></sc-text-input>
      </div>

      <!-- name -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <!-- model -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="model"
          [form]="form"
          label="{{ 'MODEL' | translate }}"
        ></sc-text-input>
      </div>

      <!-- specification -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="specification"
          [form]="form"
          label="{{ 'SPECIFICATION' | translate }}"
        ></sc-text-input>
      </div>

      <!-- device type -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="deviceTypeId"
          [form]="form"
          label="{{ 'DEVICE TYPE' | translate }}"
          [options]="selectItems?.deviceTypes"
        ></sc-dropdown>
      </div>

      <!-- brand -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="brandId"
          [form]="form"
          label="{{ 'BRAND' | translate }}"
          [options]="selectItems?.brands"
        ></sc-dropdown>
      </div>

      <!-- protocol -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="protocolId"
          [form]="form"
          label="{{ 'PROTOCOL' | translate }}"
          [options]="selectItems?.protocols"
        ></sc-dropdown>
      </div>

      <!-- only for device type `vdh` or `a` -->
      <div
        class="ui-g-12"
        *ngIf="deviceTypeKey === 'vdh' || deviceTypeKey === 'a'"
      >
        <!-- product image -->
        <sc-local-file-input
          controlName="productImage"
          [form]="form"
          label="{{ 'IMAGE' | translate }}"
        ></sc-local-file-input>

        <!-- icon image -->
        <sc-local-file-input
          controlName="iconImage"
          [form]="form"
          label="{{ 'ICON' | translate }}"
        ></sc-local-file-input>
      </div>

      <!-- automation version -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="automationVersion"
          [form]="form"
          label="{{ 'AUTOMATION_VERSION' | translate }}"
        ></sc-text-input>
      </div>

      <h4>{{ "INSTRUCTION" | translate }}</h4>

      <!-- instruction intro -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="instructionIntro"
          [form]="form"
          label="{{ 'INSTRUCTION_INTRO' | translate }}"
        ></sc-text-input>
      </div>

      <!-- instruction inclusion -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="instructionInclusion"
          [form]="form"
          label="{{ 'INSTRUCTION_INCLUSION' | translate }}"
        ></sc-text-input>
      </div>

      <!-- instruction exclusion -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="instructionExclusion"
          [form]="form"
          label="{{ 'INSTRUCTION_EXCLUSION' | translate }}"
        ></sc-text-input>
      </div>

      <!-- zwave/zigbee controller -->
      <ng-container *ngIf="isAutomation">
        <!-- zwave -->
        <h4>{{ "ZWAVE" | translate }}</h4>

        <!-- zwave status -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zwaveStatus"
            [form]="form"
            label="{{ 'ZWAVE_STATUS' | translate }}"
            [options]="selectItems?.zwaveStatus"
          ></sc-dropdown>
        </div>

        <!-- zwave controller software -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zwaveControllerSoftware"
            [form]="form"
            label="{{ 'ZWAVE_CONTROLLER_SOFTWARE' | translate }}"
            [options]="selectItems?.zwaveControllerSoftwares"
          ></sc-dropdown>
        </div>

        <!-- zwave controller port -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zwaveControllerPort"
            [form]="form"
            label="{{ 'ZWAVE_CONTROLLER_PORT' | translate }}"
            [options]="selectItems?.zwaveControllerPorts"
            [editable]="true"
          ></sc-dropdown>
        </div>

        <!-- zigbee -->
        <h4>{{ "ZIGBEE" | translate }}</h4>

        <!-- zigbee status -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zigbeeStatus"
            [form]="form"
            label="{{ 'ZIGBEE_STATUS' | translate }}"
            [options]="selectItems?.zigbeeStatus"
          ></sc-dropdown>
        </div>

        <!-- zigbee controller port -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="zigbeeControllerPort"
            [form]="form"
            label="{{ 'ZIGBEE_CONTROLLER_PORT' | translate }}"
            [options]="selectItems?.zigbeeControllerPorts"
            [editable]="true"
          ></sc-dropdown>
        </div>
      </ng-container>

      <!-- behaviour patterns -->
      <ng-container *ngIf="!isAutomation && behaviourPatterns?.controls">
        <div class="ui-g-12 ui-g-nopad mt-5">
          <div class="ui-g-12 ui-md-8 ui-g-nopad mb-3">
            <h4 class="m-0">
              {{ "ABNORMAL_BEHAVIOR_DETECTION_PATTERNS" | translate }}
            </h4>
          </div>
          <div class="ui-g-12 ui-md-4 ui-g-nopad text-right">
            <button
              pButton
              type="button"
              class="ui-button-success"
              (click)="addBehaviourPattern()"
              icon="fa fa-plus"
              pTooltip="
                {{ 'ADD' | translate }}
                {{ 'ABNORMAL_BEHAVIOR_DETECTION_PATTERNS' | translate }}
              "
            ></button>
          </div>
        </div>

        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g-12 ui-md-1 text-center">
            {{ "COMPARE_WITH_LAST_DHL" | translate }}
          </div>

          <div class="ui-g-12 ui-md-2 text-center">
            {{ "OPERAND_X" | translate : { value: 1 } }}
          </div>

          <div class="ui-g-12 ui-md-1 text-center">
            {{ "OPERATOR" | translate }}
          </div>

          <div class="ui-g-12 ui-md-5 text-center">
            {{ "OPERAND_X" | translate : { value: 2 } }}
          </div>

          <div class="ui-g-12 ui-md-1 text-center">
            {{ "REPORT" | translate }}
          </div>

          <div class="ui-g-12 ui-md-1 text-center">
            {{ "IGNORE" | translate }}
          </div>

          <div class="ui-g-12 ui-md-1"></div>
        </div>

        <div class="ui-g-12 ui-g-nopad mb-5">
          <ng-container formArrayName="behaviourPatterns">
            <ng-container
              *ngFor="let ctrl of behaviourPatterns.controls; let i = index"
              [formGroupName]="i"
            >
              <sc-behaviour-patterns-form
                [form]="ctrl"
                [formId]="i"
                (removed)="removeBehaviourPattern($event)"
              ></sc-behaviour-patterns-form>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <!-- mapping values -->
      <h4>{{ "VALUE_MAPPINGS" | translate }}</h4>

      <!-- tab header -->
      <div class="ui-g-12">
        <ul class="mapping-group">
          <li
            *ngFor="let ctrl of availableControllers"
            class="mapping-group-item"
            [ngClass]="{ active: ctrl?.id === selectedController?.id }"
            (click)="selectController(ctrl)"
          >
            {{ ctrl?.name }}
          </li>
        </ul>
      </div>

      <!-- tab content -->
      <div class="ui-g-12">
        <ng-container formArrayName="valueMappings">
          <ng-container
            *ngFor="let ctrl of valueMappings.controls; let i = index"
            [formGroupName]="i"
          >
            <sc-device-model-value-mapping-form
              *ngIf="
                ctrl.value?.protocolControllerId === selectedController?.id
              "
              [group]="ctrl"
              [groupId]="i"
              [mappingValues]="sourceData?.mappingValues"
            ></sc-device-model-value-mapping-form>
          </ng-container>
        </ng-container>
      </div>

      <!-- error message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!-- footer -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div
        class="ui-g-6 text-right"
        style="
          display: flex;
          justify-content: flex-end;
          column-gap: 8px;
          padding-top: 16px;
        "
      >
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
