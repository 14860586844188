import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { SiteType } from '../models/site-type';
import { SiteTypesService } from '../services/site-types.service';
import { SharedService } from '@shared/shared.service';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { IconCellComponent } from '@widgets/_shared/icon-cell/icon-cell.component';
import { LocaleTextCellComponent } from '@widgets/_shared/locale-text-cell/locale-text-cell.component';
import { SiteTypeActionCellComponent } from '../site-type-action-cell/site-type-action-cell.component';

@Component({
  selector: 'sc-site-types-table',
  templateUrl: './site-types-table.component.html',
  styleUrls: ['./site-types-table.component.scss'],
})
export class SiteTypesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: SiteType[];
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string = 'SITE_TYPES';

  private subscribers: { [key: string]: any } = {};

  constructor(private siteTypesService: SiteTypesService, private sharedService: SharedService) {}

  ngOnInit() {
    this.initTable();
    this.fetchDataset();
  }

  ngOnDestroy() {
    // unsubscribe all subscribers
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.fetchDataset = this.siteTypesService.getSiteTypes().subscribe((result: any) => {
      this.dataset = result.data;
    });
  }

  private createColumns() {
    return [
      {
        colId: 'icon',
        headerName: 'ICON',
        field: 'icon',
        cellRendererFramework: IconCellComponent,
        suppressFilter: true,
        suppressSorting: true,
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
        cellRendererFramework: LocaleTextCellComponent,
        valueFormatter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'name', true),
        filterValueGetter: (params) => this.sharedService.tableMultiLanguagesFormatter(params, 'name', true),
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: SiteTypeActionCellComponent,
      },
    ];
  }

  private initTable() {
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'SiteTypeForm',
      options: { modalTitle: 'SITE_TYPE_FORM_TITLE' },
    };
  }

  afterTableInit(table: GridOptions) {
    this.table = table;

    this.table.getRowHeight = (params) => 35;
  }
}
