import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Widget } from '../models/page-section-widget';
import { WidgetDirective } from './widget.directive';
import { DynamicWidgetComponent } from './dynamic-widget.component';

import { WIDGETS } from '@widgets/widgets';

@Component({
  selector: 'sc-widget',
  styleUrls: ['./widget.component.scss'],
  templateUrl: './widget.component.html',
})
export class WidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  widget: Widget;
  @Input()
  editMode: boolean;
  @Output()
  deleteWidget = new EventEmitter();

  @ViewChild(WidgetDirective, { static: true })
  widgetHost: WidgetDirective;

  previousWidget: Widget;
  viewContainerRef: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.editMode = this.editMode || false;
    this.loadComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.widget && changes.widget.currentValue) {
      if (!this.previousWidget || changes.widget.currentValue.key !== this.previousWidget.key) {
        this.previousWidget = { ...changes.widget.currentValue };
        this.loadComponent();
      }
    }
  }

  ngOnDestroy() {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }

  getWidgetComponent() {
    // find widget component by type and id
    // return empty widget if not found
    const widget = WIDGETS[this.widget.key] || WIDGETS['empty_widget'];
    return widget;
  }

  loadComponent() {
    if (this.widget.key && this.widget.type) {
      const widget = this.getWidgetComponent();

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(widget);

      this.viewContainerRef = this.widgetHost.viewContainerRef;
      this.viewContainerRef.clear();

      const componentRef = this.viewContainerRef.createComponent(componentFactory);
      // pass @Input()[data] through the x-widget
      const dynamicComponent = componentRef.instance as DynamicWidgetComponent;
      dynamicComponent.config = this.widget;
    }
  }
}
