<form [formGroup]="form" novalidate>
  <!-- Body -->
  <div class="ui-g">
    <div class="ui-g-12">
      {{ 'DEVICE_TRIGGER_ACTION_FORM_DESCRIPTION' | translate }}
      {{ formData.description || formData.deviceId }}.
    </div>

    <!-- COMMAND FIELD -->
    <div class="ui-g-12">
      <h4 class="m-0">
        {{ 'COMMAND' | translate }} {{ 'ACTION' | translate }}
      </h4>
    </div>

    <div class="ui-g-12">
      <sc-radio-input
        controlName="commandAction"
        [form]="form"
        [options]="commandList"
        [showLabel]="false"
        [displayInVertical]="true"
      ></sc-radio-input>
    </div>

    <!-- PARAMETERS FIELD -->
    <ng-container *ngIf="parameters?.controls?.length">
      <div class="ui-g-12">
        <h4 class="m-0">{{ 'PARAMETERS' | translate }}</h4>
      </div>

      <div class="ui-g-12">
        <ng-container formArrayName="parameters">
          <ng-container
            *ngFor="let parameter of parameters.controls; let i = index"
            [formGroupName]="i"
          >
            <div [formGroup]="parameter">
              <sc-text-input
                controlName="value"
                [form]="parameter"
                label="{{ parameter.get('key').value | translate }}"
              ></sc-text-input>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <!-- SERVICE -->
    <div class="ui-g-12">
      <h4 class="m-0">{{ 'SERVICE' | translate }}</h4>
    </div>

    <div class="ui-g-12">
      <sc-radio-input
        controlName="service"
        [form]="form"
        [options]="serviceProviders"
        [showLabel]="false"
        [displayInVertical]="true"
      ></sc-radio-input>
    </div>

    <!-- Error Message -->
    <div class="ui-g-12" *ngIf="errorMessage">
      <sc-form-error [message]="errorMessage|translate"></sc-form-error>
    </div>
  </div>

  <!-- Footer -->
  <div class="ui-g">
    <div class="ui-g-6"></div>

    <div class="ui-g-6 text-right">
      <button
        pButton
        type="button"
        class="ui-button-secondary"
        label="{{ 'CANCEL' | translate }}"
        (click)="dismissModal('cancel')"
      ></button>

      <sc-submit-button
        *ngIf="!formData?.isDeleted || formData?.isActive"
        buttonClass="ui-button-success"
        [pending]="isSubmitting"
        (submitted)="submit()"
        [disabled]="!form.valid || form.pristine"
      ></sc-submit-button>
    </div>
  </div>
</form>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
