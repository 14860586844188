import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { DeviceProtocolActionCellComponent } from '../device-protocols/device-protocol-action-cell/device-protocol-action-cell.component';
import { DeviceProtocolFormComponent } from '../device-protocols/device-protocol-form/device-protocol-form.component';
import { DeviceProtocolsTableComponent } from '../device-protocols/device-protocols-table/device-protocols-table.component';

import { DeviceProtocolsService } from './services/device-protocols.service';

const COMPONENTS: any[] = [
  DeviceProtocolActionCellComponent,
  DeviceProtocolFormComponent,
  DeviceProtocolsTableComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [DeviceProtocolsService]
})
export class DeviceProtocolsModule {}
