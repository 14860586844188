<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'TOP_X_COLDEST_ROOMS' | translate: { value: 5 } }} -->
        {{ widgetName }}
      </h4>

      <!-- <div class="sc-widget-menu"></div> -->
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body">
    <table>
      <tbody>
        <tr *ngFor="let d of dataset">
          <td>{{ d.description }}</td>
          <td class="text-right">{{ d.temperature }} °C</td>
          <td class="text-right">
            <span *ngFor="let i of getNumIcon(d.temperature)">
              <i class="fa fa-fw fa-snowflake-o blue-text"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
