<div class="text-center">
  <div *ngIf="!photo">
    <i class="fa fa-image"
      pTooltip="{{ 'NO_IMAGE' | translate }}"
      appendTo="body"
      tooltipPosition="top"
    ></i>
  </div>

  <div *ngIf="photo">
    <a [href]="photo" class="sc-clickable" target="_blank">
      <i class="fa fa-image green-text"
        pTooltip="{{ 'VIEW_IMAGE' | translate }}"
        appendTo="body"
        tooltipPosition="top"
      ></i>
    </a>
  </div>
</div>
