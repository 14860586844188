import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CurrenciesActionCellComponent } from './currencies-action-cell/currencies-action-cell.component';
import { CurrencyFormComponent } from './currency-form/currency-form.component';
import { CurrenciesTableComponent } from './currencies-table/currencies-table.component';

import { CurrenciesService } from './services/currencies.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    CurrenciesActionCellComponent,
    CurrencyFormComponent,
    CurrenciesTableComponent
  ],
  entryComponents: [
    CurrenciesActionCellComponent,
    CurrencyFormComponent,
    CurrenciesTableComponent
  ],
  providers: [CurrenciesService]
})
export class CurrenciesModule {}
