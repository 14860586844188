import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BenchmarkTemplate } from '../models/benchmark-template';

import { config } from '@app/config';

@Injectable()
export class BenchmarkTemplatesService {
  private apiUrl = config().apiUrl + '/benchmarkTemplates';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): BenchmarkTemplate {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  getBenchmarkTemplate(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getBenchmarkTemplates() {
    return this.httpClient.get(this.apiUrl);
  }

  createBenchmarkTemplate(data: BenchmarkTemplate) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateBenchmarkTemplate(id: number, data: BenchmarkTemplate) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteBenchmarkTemplate(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
