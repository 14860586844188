import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LiveDataRequest, TriggerCommandAction } from '../models/device-trigger-command';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

// interface LiveRequestsLazyOptions {
//   companyId: number;
//   siteId: number;
//   locationId: number;
//   deviceId: number;
//   daterange: { from: string; to: string };
//   format: string;
//   fields: string;
//   filter: { [key: string]: any };
//   limit: number;
//   offset: number;
//   sort: { colId: string; sort: string }[];
// }

@Injectable()
export class DeviceTriggerCommandsService {
  private apiUrl = config().apiUrl + '/_automation';
  private backendUrl = config().apiUrl + '/_backend';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  /**
   * Request Live status of Device
   * @param option
   */
  requestLiveData(option: LiveDataRequest) {
    let url =
      `${this.apiUrl}/${option.automationId}` +
      `/webserver/send?apiKey=${option.apiKey}` +
      `&action=${option.action}` +
      `&sessionId=${option.sessionId}`;

    if (option.deviceId) {
      url += `&deviceId=${option.deviceId}`;
    } else if (option.gatewayId) {
      url += `&gatewayId=${option.gatewayId}`;
    } else {
      url += `&automationId=${option.automationId}`;
    }

    return this.httpClient.post(url, {});
  }

  requestLiveDataWithPushy(option: LiveDataRequest) {
    let url =
      `${this.apiUrl}/${option.automationId}` +
      `/webserver/pushy/send?apiKey=${option.apiKey}` +
      `&action=${option.action}` +
      `&sessionId=${option.sessionId}`;

    if (option.deviceId) {
      url += `&deviceId=${option.deviceId}`;
    } else if (option.gatewayId) {
      url += `&gatewayId=${option.gatewayId}`;
    } else {
      url += `&automationId=${option.automationId}`;
    }

    return this.httpClient.post(url, {});
  }

  /**
   * Get Live status of Device By Request Session
   * @param automationId
   * @param sessionId
   */
  getLiveData(automationId: string, sessionId: string) {
    const url = `${this.apiUrl}/${automationId}/webserver/get` + `?sessionId=${sessionId}&format=json`;
    return this.httpClient.get(url);
  }

  /**
   * Send Command Action to Device
   * @param option
   */
  triggerCommandAction(option: TriggerCommandAction) {
    let url =
      `${this.apiUrl}/${option.automationId}` +
      `/webserver/send?apiKey=${option.apiKey}` +
      '&action=triggerDeviceCommandAction' +
      `&deviceId=${option.deviceId}` +
      `&commandAction=${option.commandAction}`;

    if (option.parameters && option.parameters.length > 0) {
      option.parameters.forEach((item) => {
        url += '&' + item.key + (item.value ? '=' + item.value : '');
      });
    }

    return this.httpClient.post(url, {});
  }

  triggerCommandActionWithPushy(option: TriggerCommandAction) {
    let url =
      `${this.apiUrl}/${option.automationId}` +
      `/webserver/pushy/send?apiKey=${option.apiKey}` +
      '&action=triggerDeviceCommandAction' +
      `&deviceId=${option.deviceId}` +
      `&commandAction=${option.commandAction}`;

    if (option.parameters && option.parameters.length > 0) {
      option.parameters.forEach((item) => {
        url += '&' + item.key + (item.value ? '=' + item.value : '');
      });
    }

    return this.httpClient.post(url, {});
  }

  // /**
  //  * Create Live Request
  //  * @param options
  //  */
  // sendLiveRequest(options: LiveDataRequest) {
  //   const apiUrl = this.backendUrl + '/liveRequest';
  //   delete options.sessionId;
  //   return this.httpClient.post(apiUrl, options);
  // }

  // /**
  //  * Get Live Request data
  //  * @param sessionId
  //  */
  // getLiveRequestResult(sessionId: string) {
  //   const apiUrl = this.backendUrl + '/liveRequest/' + sessionId;
  //   return this.httpClient.get(apiUrl);
  // }

  // /**
  //  * Get Live Request List (Lazyload)
  //  * @param options
  //  */
  // getLiveRequestsLazy(options: LiveRequestsLazyOptions) {
  //   const queryParams = this.sharedService.urlQueryParamsCreator(options);
  //   const apiUrl = this.backendUrl + '/liveRequest/lazy' + queryParams;
  //   return this.httpClient.get(apiUrl);
  // }
}
