import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid';
// import { ModalConfig } from '@sc/modal/modal-config';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { DeviceType } from '@widgets/device-types/models/device-type';

import { VirtualDevicesActionCellComponent } from '../vds-action-cell/vds-action-cell.component';

@Component({
  selector: 'sc-vds-table',
  templateUrl: './vds-table.component.html',
  styleUrls: ['./vds-table.component.scss'],
})
export class VirtualDevicesTableComponent implements OnInit, OnChanges {
  @Input()
  virtualDevices: DeviceDetails[];
  @Input()
  devices: DeviceDetails[];
  @Input()
  deviceTypeEntities: { [key: string]: DeviceType };

  columns: ColDef[];
  table: GridOptions;
  tableTitle = 'VIRTUAL_DEVICES';
  toolbarItems: string[] = [];

  constructor() {}

  ngOnInit() {
    // Set Table Columns
    this.columns = this.createColumns();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deviceTypeEntities && changes.deviceTypeEntities.currentValue && this.table) {
      this.table.api.refreshCells({ force: true });
    }
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
  }

  private createColumns() {
    return [
      {
        colId: 'description',
        headerName: 'NAME',
        field: 'description',
      },
      {
        colId: 'deviceTypeName',
        headerName: 'DEVICE_TYPE',
        // field: 'deviceTypeName',
        valueFormatter: (params) => this.deviceTypeNameFormatter(params.data),
        valueGetter: (params) => this.deviceTypeNameFormatter(params.data),
        cellRenderer: (params) => params.valueFormatted,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        width: 50,
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: VirtualDevicesActionCellComponent,
      },
    ];
  }

  private deviceTypeNameFormatter(device) {
    if (!this.deviceTypeEntities || !this.devices || !device) {
      return '';
    }

    const deviceType = this.deviceTypeEntities[device.deviceTypeId];
    return deviceType ? deviceType.name : '';
  }
}
