import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { DeviceModel2FormComponent } from './device-model-form/device-model-form.component';
import { DeviceModelActionCellComponent } from './device-model-action-cell/device-model-action-cell.component';
import { DeviceModels2TableComponent } from './device-models-table/device-models-table.component';
import { DeviceModelFormService } from './services/device-model-form.service';
import { DeviceModelService } from './services/device-model.service';
import { PropertyConfigFormComponent } from './property-config-form/property-config-form.component';
import { PropertyValueFormComponent } from './property-value-form/property-value-form.component';
import { PropertyModbusFormComponent } from './property-modbus-form/property-modbus-form.component';
import { PropertyFormComponent } from './property-form/property-form.component';
import { InstructionFormComponent } from './instruction-form/instruction-form.component';
import { ModbusSettingsFormComponent } from './modbus-settings-form/modbus-settings-form.component';

const COMPONENTS: any[] = [DeviceModels2TableComponent, DeviceModelActionCellComponent, DeviceModel2FormComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [
    ...COMPONENTS,
    PropertyConfigFormComponent,
    PropertyValueFormComponent,
    PropertyModbusFormComponent,
    PropertyFormComponent,
    InstructionFormComponent,
    ModbusSettingsFormComponent,
  ],
  entryComponents: [...COMPONENTS],
  providers: [DeviceModelFormService, DeviceModelService],
})
export class DeviceModels2Module {}
