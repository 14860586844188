import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { DeviceModelService } from '../services/device-model.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-model-action-cell',
  templateUrl: './device-model-action-cell.component.html',
  styleUrls: ['./device-model-action-cell.component.scss'],
})
export class DeviceModelActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  formConfig: ModalConfig;

  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  private subscribers: { [key: string]: any } = {};

  constructor(private deviceModelService: DeviceModelService, private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;

    // Edit form config
    this.formConfig = {
      name: 'DeviceModel2Form',
      data: this.cell.data,
      options: { modalTitle: 'DEVICE_MODEL_FORM_TITLE', fullScreen: true },
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this),
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  updateRow(event) {
    // update row data
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow(force = false) {
    this.subscribers.delete = this.deviceModelService.deleteDeviceModel(this.cell.data.id, force).subscribe({
      next: (result: any) => {
        if (result.warning) {
          this.subscribers.delete = this.sharedService
            .deleteWarningHandler(this.cell.data, result.warning)
            .subscribe((confirm: any) => {
              if (confirm === true) {
                this.deleteRow(true);
              }
            });
        } else {
          // update row data
          this.cell.context.parentComponent.updateRow({
            id: this.cell.data.id,
            is_active: false,
            is_deleted: true,
          });
          // show success message
          const text = this.sharedService.getTranslation('DELETE_SUCCESS');
          const title = this.sharedService.getTranslation('DEVICE_MODEL');
          this.sharedService.notify(title, text, 'success');
        }
      },
      error: (error: any) => {
        // show error message
        const message = this.sharedService.getTranslation('DELETE_FAIL');
        const title = this.sharedService.getTranslation('DEVICE_MODEL');
        this.sharedService.notify(title, message, 'error');
      },
    });
  }
}
