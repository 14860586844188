<div *ngIf="fetchingState === 0; else loadingIndicator">
  <div [formGroup]="form">
    <!--Header-->
    <div class="ui-g">
      <div class="ui-g-6 ui-g-nopad">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          [label]="'ID' | translate"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <sc-activate-button
          [form]="form"
          field="is_active"
        ></sc-activate-button>
      </div>
    </div>

    <!-- BODY -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <sc-text-input
          controlName="name"
          [form]="form"
          [label]="'NAME' | translate"
        ></sc-text-input>
      </div>

      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="return_type"
          [form]="form"
          [label]="'CCP_CUSTOM_FUNCTION_RETURN_TYPE' | translate"
          [options]="selectItems?.returnTypes"
        ></sc-dropdown>
      </div>

      <div class="ui-g-12">
        <sc-input-code
          [form]="form"
          formField="syntax"
          [label]="'CCP_CUSTOM_FUNCTION_SYNTAX' | translate"
          [options]="syntaxEditorOptions"
        ></sc-input-code>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12">
        <sc-function-parameters
          [form]="form"
          [dataSources]="dataSources"
          [selectItems]="selectItems"
        ></sc-function-parameters>
      </div>
    </div>

    <!-- Error Message -->
    <div class="ui-g">
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.is_deleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.is_deleted || formData?.is_active"
          buttonClass="ui-button-success"
          [disabled]="form.invalid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
