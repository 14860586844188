<div *ngIf="isFetchingDataset; then spinner; else content"></div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <sc-table
    [columns]="columns"
    [config]="config"
    [dataset]="dataset"
    (reloadData)="fetchDataset()"
    (afterInit)="afterInitTable($event)"
    title="{{ tableTitle | translate }}"
    [toolbarItems]="toolbarItems"
    icon="fa fa-recycle"
  ></sc-table>
</ng-template>
