import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationStatisticsComponent } from './location-statistics.component';
import { DatasetFormComponent } from './dataset-form/dataset-form.component';
import { GraphFormComponent } from './graph-form/graph-form.component';

import { LocationStatisticsFormsService } from './services/location-statistics-forms.service';
import { LocationStatisticsService } from './services/location-statistics.service';

@NgModule({
  imports: [SharedModule],
  declarations: [
    LocationStatisticsComponent,
    DatasetFormComponent,
    GraphFormComponent
  ],
  entryComponents: [LocationStatisticsComponent],
  providers: [LocationStatisticsFormsService, LocationStatisticsService]
})
export class LocationStatisticsModule { }
