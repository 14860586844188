import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { SiteTypesTableComponent } from './site-types-table/site-types-table.component';
import { SiteTypeActionCellComponent } from './site-type-action-cell/site-type-action-cell.component';
import { SiteTypeFormComponent } from './site-type-form/site-type-form.component';

import { SiteTypeFormService } from './services/site-type-form.service';
import { SiteTypesService } from './services/site-types.service';

const components: any[] = [SiteTypesTableComponent, SiteTypeActionCellComponent, SiteTypeFormComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...components],
  entryComponents: [...components],
  providers: [SiteTypeFormService, SiteTypesService],
})
export class SiteTypesModule {}
