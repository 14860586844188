import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { BenchmarkTemplateFormsService } from '../../../services/benchmark-template-forms.service';

@Component({
  selector: 'sc-disabling-marker-form',
  templateUrl: 'disabling-marker-form.component.html'
})
export class DisablingMarkerFormComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  optionData: any;

  locationPropOfForms: any[];

  constructor(
    private benchmarkTemplateFormsService: BenchmarkTemplateFormsService
  ) {}

  ngOnInit() {
    this.locationPropOfForms = [];
  }

  get disablingMarkerForm(): FormGroup {
    return this.form.controls['disablingMarker'] as FormGroup;
  }

  get conditionsForm(): FormArray {
    return this.disablingMarkerForm.controls['conditions'] as FormArray;
  }

  add() {
    this.conditionsForm.push(
      this.benchmarkTemplateFormsService.initConditionsForm(true)
    );
  }

  remove(i) {
    this.conditionsForm.removeAt(i);
  }

  displayValueField(i: number) {
    const operator = this.conditionsForm.controls[i].get('operator').value;
    if (operator === 'changed') {
      return false;
    }
    return true;
  }

  private getLocationProp(key: string) {
    return this.optionData.locationPropertiesRaw.find(lp => lp.key === key);
  }

  private getDeviceTypes(key: string) {
    if (this.optionData && this.optionData.deviceTypesRaw) {
      return this.optionData.deviceTypesRaw.find(dt => dt.key === key);
    }
  }

  allowOperators(i: number) {
    const propKey = this.conditionsForm.controls[i].get('property').value;
    const locationProp = this.getLocationProp(propKey);
    this.locationPropOfForms[i] = locationProp;
    if (
      locationProp &&
      locationProp.operators &&
      locationProp.operators.length
    ) {
      const operators = locationProp.operators.split(',');
      return this.optionData.operators.filter(
        o => operators.indexOf(o.value) >= 0
      );
    }
    return this.optionData.operators;
  }

  locationPropertyMappingValues(i: number) {
    const propKey = this.conditionsForm.controls[i].get('property').value;
    const locationProp = this.getLocationProp(propKey);
    if (locationProp) {
      switch (locationProp.type) {
        case 'boolean':
          return this.optionData.booleanOption;

        case 'deviceType':
          const deviceType = this.getDeviceTypes(locationProp.deviceType);
          if (
            deviceType &&
            deviceType.mappingValues &&
            deviceType.mappingValues.length
          ) {
            return deviceType.mappingValues.map(mv => ({
              label: mv.name,
              value: mv.key
            }));
          }
      }
    }
    return;
  }
}
