import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class OmnisEditorFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initEditorForm(): FormGroup {
    return this.formBuilder.group({
      // id: { value: null, disabled: true },
      // deviceId: [null, Validators.required],
      lines: this.formBuilder.array([]),
      scopes: this.formBuilder.array([]),
      squares: [this.createSquares(), Validators.required],
      // squares: [this.initSquaresForm(), Validators.required],
    });
  }

  initLineForm(): FormGroup {
    const id = 'l' + new Date().getTime();
    const name = 'new line';
    return this.formBuilder.group({
      id: [id, Validators.required],
      name: [name, Validators.required], // 'Line 1',
      fromX: [null, Validators.required], // 1,
      fromY: [null, Validators.required], // 2,
      toX: [null, Validators.required], // 3,
      toY: [null, Validators.required], // 4,
      squares: [null, Validators.required], // [2, 5]
      new: true, // only use in form
    });
  }

  initScopeForm(): FormGroup {
    const id = 's' + new Date().getTime();
    const name = 'new scope';
    return this.formBuilder.group({
      id: [id, Validators.required],
      name: [name, Validators.required],
      squares: [null, Validators.required], // [2, 5]
      new: true, // only use in form
    });
  }

  // initSquaresForm(): FormGroup {
  //   const totalGridItems = 8 * 8; // rows * columns
  //   const squares = {};
  //   const defaultValue: any = { enabled: true, lines: [], scopes: [] };
  //   for (let i = 1; i <= totalGridItems; i++) {
  //     defaultValue.id = i;
  //     squares[i] = [defaultValue, Validators.required];
  //   }
  //   return this.formBuilder.group(squares);
  // }

  createSquares(rows: number = 8, cols: number = 8): { [prop: string]: any } {
    const totalGridItems = rows * cols;
    const squares = {};
    for (let i = 1; i <= totalGridItems; i++) {
      squares[i] = { id: i, enabled: true, line: null, scope: null };
    }
    return squares;
  }
}
