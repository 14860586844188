import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import * as fromRoot from '@app/store';
import { Store } from '@ngrx/store';
import { routerCancelAction } from '@ngrx/router-store';
import { UtilsService } from '../../ceos-rule-editor/services';
import { ViewCompileResult } from '@angular/compiler/src/view_compiler/view_compiler';

@Component({
  selector: 'sc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input()
  items: MenuItem[] = new Array();

  home: MenuItem = { icon: 'pi pi-home', routerLink: '/' };

  constructor(private store: Store<fromRoot.State>, private utilsService: UtilsService) {}

  ngOnInit() {
    this.store.select(fromRoot.getRouterStateUrl).subscribe((router) => {
      const path = router.url.split('/');
      this.items = [];
      for (let index = 0; index < path.length; index++) {
        if (path[index] !== 'integrator' && path[index] !== 'enduser' && path[index] !== '') {
          this.utilsService.textTranslate(path[index].toUpperCase()).subscribe((result) => {
            this.items.push({ label: `${result}` });
          });
        }
      }
    });
  }
}
