import * as fromDevices from '../actions/devices.action';
import { Device } from '@widgets/devices/models/device';
import { DeviceDetails } from '@widgets/devices/models/device-details';

export interface DeviceState {
  entities: { [id: string]: Device | DeviceDetails };
  error: any;
  loaded: boolean;
  loadedAt: number;
  loading: boolean;
  selectedId: number;
}

export const initialState = {
  entities: null,
  error: null,
  loaded: false,
  loadedAt: null,
  loading: false,
  selectedId: null,
};

export function reducer(state = initialState, action: fromDevices.DevicesAction): DeviceState {
  switch (action.type) {
    case fromDevices.LOAD_DEVICE: {
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    }

    case fromDevices.LOAD_DEVICE_FAIL: {
      const error = action.payload;
      return {
        ...state,
        error,
        loading: false,
      };
    }

    case fromDevices.LOAD_DEVICE_SUCCESS: {
      const device = action.payload;
      const entities = {
        ...state.entities,
        [device.id]: device,
      };
      return {
        ...state,
        loaded: true,
        loading: false,
        entities,
      };
    }

    case fromDevices.LOAD_DEVICES: {
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    }

    case fromDevices.LOAD_DEVICES_FAIL: {
      const error = action.payload;
      return {
        ...state,
        error,
        loading: false,
      };
    }

    case fromDevices.LOAD_DEVICES_SUCCESS: {
      const devices = action.payload;
      const entities = devices.reduce((tmpEntities: { [id: string]: Device }, device: Device) => {
        return {
          ...tmpEntities,
          [device.id]: device,
        };
      }, {});
      const loadedAt = new Date().getTime();
      return {
        ...state,
        loaded: true,
        loadedAt,
        loading: false,
        entities,
      };
    }

    case fromDevices.RESET_DEVICES: {
      return { ...initialState };
    }

    case fromDevices.SELECT_DEVICE: {
      const deviceId = action.payload;
      return { ...state, selectedId: deviceId };
    }
  }

  return state;
}

export const getDeviceEntities = (state: DeviceState) => state.entities;

export const getDevicesError = (state: DeviceState) => state.error;

export const getDevicesLoaded = (state: DeviceState) => state.loaded;

export const getDevicesLoadedAt = (state: DeviceState) => state.loadedAt;

export const getDevicesLoading = (state: DeviceState) => state.loading;

export const getSelectedDeviceId = (state: DeviceState) => state.selectedId;
