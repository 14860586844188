import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-instruction-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './instruction-form.component.html',
  styleUrls: ['./instruction-form.component.scss'],
})
export class InstructionFormComponent implements OnInit {
  @Input()
  form: FormGroup;

  localesForm: FormArray;

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.localesForm = this.form.get('locales') as FormArray;
  }

  getFlag(locale: string) {
    return this.sharedService.getFlagClassName(locale);
  }
}
