<div [formGroup]="group" class="ui-g-12 ui-g-nopad">
  <div class="ui-g-5">
    <sc-text-input
      controlName="key"
      [form]="group"
      label="{{ 'KEY' | translate }}"
    ></sc-text-input>
  </div>

  <div class="ui-g-5">
    <sc-text-input
      controlName="name"
      [form]="group"
      label="{{ 'NAME' | translate }}"
    ></sc-text-input>
  </div>

  <div class="ui-g-2 text-right ">
    <button
      pButton
      type="button"
      class="ui-button-warning"
      icon="fa fa-trash"
      (click)="remove()"
      pTooltip="{{ 'REMOVE' | translate }} {{ 'MAPPING_VALUE' | translate }}"
      tooltipPosition="top"
      appendTo="body"
    ></button>
  </div>
</div>
