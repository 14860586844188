import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-email-input',
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
      ></sc-field-label>

      <div [ngClass]="{ 'ui-inputgroup': prefix }">
        <span class="ui-inputgroup-addon" *ngIf="prefix">
          {{ prefix }}
        </span>
        <input
          pInputText
          type="email"
          [id]="inputId"
          [formControlName]="controlName"
          [placeholder]="placeholder"
          autocomplete="off"
        />
      </div>

      <sc-field-error
        [for]="label"
        [fieldControl]="form.controls[controlName]"
      ></sc-field-error>
    </div>
  `
})
export class SCEmailInputComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  placeholder: string;
  @Input()
  prefix: string;

  inputId: string;

  constructor() {}

  ngOnInit() {
    this.placeholder = this.placeholder || '';
    this.showLabel =
      typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-email-input_' + this.controlName + new Date().getTime();
  }
}
