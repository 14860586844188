<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!--Header-->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>
          <div class="ui-g-6 text-right">
            <p-toggleButton
              formControlName="isActive"
              offIcon="fa fa-square-o"
              offLabel="{{ 'ACTIVE' | translate }}"
              onIcon="fa fa-check-square-o"
              onLabel="{{ 'ACTIVE' | translate }}"
            ></p-toggleButton>
          </div>
        </div>
      </p-header>

      <!--Body-->
      <div class="ui-g">
        <!-- APP_STYLE_TEMPLATE_ID Field -->
        <div class="ui-g-12">
          <sc-dropdown
            controlName="appStyleTemplateId"
            [form]="form"
            label="{{ 'APP_STYLE_TEMPLATE' | translate }}"
            [options]="templates"
          ></sc-dropdown>
        </div>

        <!-- KEY Field -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="key"
            [form]="form"
            label="{{ 'KEY' | translate }}"
          ></sc-text-input>
        </div>

        <!-- NAME Field -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- DESCRIPTION FIELD -->
        <div class="ui-g-12">
          <sc-textarea
            controlName="description"
            [form]="form"
            label="{{ 'DESCRIPTION' | translate }}"
          ></sc-textarea>
        </div>

        <div *ngIf="form.get('appStyleTemplateId').value" class="ui-g-12">
          <!-- BASIC SETTINGS FORM -->
          <div class="ui-g-12 ui-g-nopad">
            <h2 class="grey lighten-2 p-2">{{ 'BASIC_SETTINGS' | translate }}</h2>
          </div>

          <div formArrayName="variables">
            <div *ngFor="let vrb of variables.controls; let i=index" [formGroupName]="i">
              <basic-form-group
                [group]="vrb"
                [groupId]="i"
              ></basic-form-group>
            </div>
          </div>

          <div class="ui-g-12 ui-g-nopad" *ngFor="let variable of variablesDisables">
            <div class="ui-g-12">
              <div class="ui-g-6">
                <span class="red-text">
                  ({{ 'DELETED' | translate }})
                </span>
                <strong class="grey-text">
                  @{{ variable.key }}
                </strong>
                <br />
                <small class="grey-text">
                  {{ variable.description || 'NO_DESCRIPTION' | translate }}
                </small>
              </div>
              <div class="ui-g-6">
                {{ variable.value }}
              </div>
            </div>
          </div>

          <!-- ADVANCED SETTINGS FORM -->
          <div class="ui-g-12 ui-g-nopad">
            <h2 class="grey lighten-2 p-2">{{ 'ADVANCED_SETTINGS' | translate }}</h2>
          </div>
          <div class="ui-g-12">
            <div class="ui-g-4">
              <strong class="grey-text">
                {{ 'ELEMENT' | translate }}
              </strong>
            </div>
            <div class="ui-g-8 text-center">
              <strong class="grey-text">
                {{ 'STYLES' | translate }}
              </strong>
            </div>
          </div>
          <div formArrayName="elements">
            <div *ngFor="let elm of elements.controls; let i=index" [formGroupName]="i">
              <advanced-form-group
                [group]="elm"
                [groupId]="i"
                [variables]="availableVars"
                [disables]="elementDisable(elm)"
              ></advanced-form-group>
            </div>
          </div>

          <div class="ui-g-12" *ngFor="let element of allElementsDisables">
            <div class="ui-g-4">
                <span class="red-text">
                  ({{ 'DELETED' | translate }})
                </span>
                <strong class="grey-text">
                  {{ element.name }}
                </strong>
            </div>
            <div class="ui-g-8">
              <div *ngFor="let style of element.styles">
                <div class="ui-g">
                  <div class="ui-g-6 text-right">
                    <span class="blue-text">
                      {{ style.property }}
                    </span>
                  </div>
                  <div class="ui-g-6">
                    {{ style.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Error Message -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error message="{{ errorMessage | translate }}"></sc-form-error>
        </div>
      </div>

      <!--Footer-->
      <p-footer>
        <div class="ui-g-6">
          <sc-confirm-trigger
            *ngIf="editMode && !formData?.isDeleted"
            buttonIcon="fa fa-trash"
            buttonClass="ui-button-danger"
            buttonLabel="{{ 'DELETE' | translate }}"
            (onAccept)="delete()"
          ></sc-confirm-trigger>
        </div>
        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            (click)="dismissModal('cancel')"
            label="{{ 'CANCEL' | translate }}"
          ></button>
          <sc-submit-button
            *ngIf="!formData?.isDeleted || formData?.isActive"
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
