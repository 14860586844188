<ng-container *ngIf="!isInitialDataLoading; else loading">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #loading>
  <div id="preloading-overlay">
    <div class="outer">
      <div class="inner">
        <div class="animate-scale">
          <img class="logo" src="assets/images/logo.png" />
        </div>
        <div class="loading-text" data-text="Initialize...">Initialize...</div>
      </div>
    </div>
  </div>
</ng-template>
