import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { Device } from '@widgets/devices/models/device';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { SharedService } from '@shared/shared.service';
import { LiveRequestsService } from '@widgets/live-requests/services/live-requests.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CeosAutomationTriggerFormComponent } from '@widgets/devices/ceos-automation-trigger-form/ceos-automation-trigger-form.component';
import { CeosDeviceFormComponent } from '@widgets/devices/ceos-device-form/ceos-device-form.component';

@Component({
  selector: 'sc-ceos-device-action-cell',
  templateUrl: './ceos-device-action-cell.component.html',
})
export class CeosDeviceActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  data: { [key: string]: any };
  context: any;
  containerComponent: any;
  parentComponent: any;
  node: any;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];
  isGettingLiveDataPushy = false;

  private triggerForm: DynamicDialogRef;
  private editForm: DynamicDialogRef;
  private liveDataReqOpt: any;
  private liveDataCount: { [key: string]: number } = {};
  private subscribers: { [key: string]: any } = {};
  private intervals: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private devicesService: DevicesService,
    private sharedService: SharedService,
    private dialogService: DialogService,
    private liveRequestsService: LiveRequestsService
  ) {}

  agInit(params: any): void {
    if (!params.data || !params.data.id) {
      return;
    }

    this.cell = params;
    this.data = this.cell.data;
    this.context = this.cell.context;
    this.node = this.cell.node;
    // table component
    this.parentComponent = this.context.parentComponent;
    // devices table component
    this.containerComponent = this.context.containerComponent;

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        permission: 'device_u',
        type: 'button',
        onClick: this.openEditForm.bind(this),
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        permission: 'device_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];

    if (this.data.deviceTypeKey === 'a') {
      this.actions = [
        {
          key: 'DETAILS',
          icon: 'fa fa-search',
          permission: 'device_r',
          type: 'button',
          onClick: this.viewDeviceDetails.bind(this),
        },
        {
          key: 'TRIGGER',
          icon: 'fa fa-terminal',
          type: 'button',
          onClick: this.openTriggerForm.bind(this),
        },
        ...this.actions,
      ];
    } else {
      this.actions = [
        {
          key: 'DEVICE_REQUEST_LIVE_STATUS',
          icon: this.getLiveDataIconPushy(),
          type: 'button',
          onClick: this.sendLiveDataRequest.bind(this),
        },
        ...this.actions,
      ];
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {
    // ____ CLEAR SUBSCRIBES & INTERVALS
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  get showActionCell(): boolean {
    if (this.actions && this.data && this.data.id && !this.data.isDeleted) {
      return true;
    }
    return false;
  }

  private updateRow(event: { result: Device }) {
    if (event.result) {
      this.parentComponent.updateRow({ ...event.result, id: this.data.id });
      this.cell.api.redrawRows([this.node]);
    }
  }

  private deleteRow(force = false) {
    this.subscribers.deleteDevice = this.devicesService.deleteDevice(this.data.id, force).subscribe({
      next: (result: any) => {
        if (result.warning) {
          this.subscribers.delete = this.sharedService
            .deleteWarningHandler(this.cell.data, result.warning)
            .subscribe((confirm: any) => {
              if (confirm === true) {
                this.deleteRow(true);
              }
            });
        } else {
          this.parentComponent.updateRow({
            id: this.data.id,
            isActive: false,
            isDeleted: true,
          });
          this.cell.api.redrawRows([this.node]);
          const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
          const title = this.sharedService.getTranslation('DEVICE');
          this.sharedService.notify(title, text, 'success');
        }
      },
      error: (error: any) => {
        const text = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('DEVICE');
        this.sharedService.notify(title, text, 'error');
      },
    });
  }

  private createLiveDataRequestOptions() {
    const options: any = {};

    switch (this.data.deviceTypeKey) {
      case 'a':
        options.apiKey = this.data.apiKey;
        options.automationId = this.data.id;
        options.action = 'getAllDeviceStatusLive';
        break;

      default:
        options.action = 'getOneDeviceStatusLive';
        options.apiKey = this.data.automationApiKey;
        options.automationId = this.data.automationId;
        options.deviceIdx = this.data.idx;
        break;
    }

    return options;
  }

  private getLiveDataIconPushy() {
    let icon = '';
    if (this.data && this.data.deviceTypeKey) {
      switch (this.data.deviceTypeKey) {
        case 'a':
        case 'g':
          icon = 'fa-certificate';
          break;

        default:
          icon = 'fa-asterisk';
          break;
      }
      if (this.isGettingLiveDataPushy) {
        icon += ' fa-spin';
      }
    }
    return icon;
  }

  private activeLiveDataSpinner() {
    // ____ ACTIVE INDICATOR
    this.node.setDataValue('liveStatus', 'FETCHING');

    // ____ STOP INTERVAL && UNSUBSCRIBE
    if (this.subscribers.requestLiveData) {
      this.subscribers.requestLiveData.unsubscribe();
    }
    if (this.subscribers.getLiveData) {
      this.subscribers.getLiveData.unsubscribe();
    }
    if (this.intervals.getLiveData) {
      clearInterval(this.intervals.getLiveData);
      this.intervals.getLiveData = null;
    }
  }

  private sendLiveDataRequest() {
    this.activeLiveDataSpinner();

    // create live data request options
    this.liveDataReqOpt = this.createLiveDataRequestOptions();

    this.subscribers.requestLiveData = this.liveRequestsService.sendLiveRequest(this.liveDataReqOpt).subscribe(
      (result: any) => {
        if (!result.data || !result.data.sessionId) {
          this.node.setDataValue('liveStatus', 'ERROR_SESSION');
          return;
        } else if (result.data.notification && result.data.notification.error) {
          this.node.setDataValue('liveStatus', 'ERROR_SEND_TO_AUTOMATION');
          return;
        }

        // store sessionId into liveDataReqOpt
        this.liveDataReqOpt.sessionId = result.data.sessionId;
        // ____ START GET LIVE DATA INTERVAL
        this.liveDataCount[this.liveDataReqOpt.sessionId] = 0;
        this.intervals.getLiveData = setInterval(() => this.getLiveDataResult(), 2000);
      },
      (error: any) => {
        this.node.setDataValue('liveStatus', 'ERROR');
        this.isGettingLiveDataPushy = false;
      }
    );
  }

  private getLiveDataResult() {
    this.subscribers.getLiveData = this.liveRequestsService
      .getLiveRequestResult(this.liveDataReqOpt.sessionId)
      .subscribe((result: any) => {
        if (result && result.data && (result.data.error || result.data.value)) {
          // ____ STOP GET LIVE DATA INTERVAL
          if (this.intervals.getLiveData) {
            clearInterval(this.intervals.getLiveData);
            this.intervals.getLiveData = null;
          }

          // ____ STOP INDICATOR
          if (result.data.error !== null) {
            const error = JSON.parse(result.data.error);
            this.node.setDataValue('liveStatus', { error });
          } else {
            const liveData = JSON.parse(result.data.value) || [];
            const virtualDevices = this.data.virtualDevices ? JSON.parse(JSON.stringify(this.data.virtualDevices)) : [];
            const deviceTypes = this.containerComponent.ceosDeviceTypeEntities;
            for (const vd of virtualDevices) {
              for (const ld of liveData) {
                if (ld.device_idx === vd.idx) {
                  const deviceType = deviceTypes[ld.device_type];

                  if (deviceType && deviceType.render && deviceType.render.type) {
                    if (
                      deviceType.render.type === 'select' &&
                      deviceType.render.options &&
                      deviceType.render.options.length
                    ) {
                      const opt = deviceType.render.options.find((item) => item.value === ld.value);
                      vd.formattedValue = opt ? opt.label : ld.value;
                    } else if (deviceType.render.type === 'numeric') {
                      vd.formattedValue = this.sharedService.numberFormat(ld.value, 1);
                    }
                    vd.unit = deviceType.render.unit;
                  }
                  vd.value = ld.value;
                  vd.valueAt = this.sharedService.dateFormat(ld.datetime, 'fromNow');
                }
              }
            }
            this.node.setDataValue('liveStatus', { value: virtualDevices });
          }
          this.isGettingLiveDataPushy = false;
        } else {
          this.liveDataCount[this.liveDataReqOpt.sessionId]++;
          if (this.liveDataCount[this.liveDataReqOpt.sessionId] >= 15) {
            // ____ STOP GET LIVE DATA INTERVAL
            if (this.intervals.getLiveData) {
              clearInterval(this.intervals.getLiveData);
              this.intervals.getLiveData = null;
            }

            // ____ STOP INDICATOR
            this.node.setDataValue('liveStatus', 'TIMEOUT');
            this.isGettingLiveDataPushy = false;
          }
        }
      });
  }

  private viewDeviceDetails() {
    const path = ['devices', this.data.id];
    const query = { site: this.sharedService.selectedSite.id };
    this.store.dispatch(new fromStore.Go({ path, query }));
  }

  private openTriggerForm() {
    this.containerComponent.stopAutoUpdate();

    const headerTitle = this.sharedService.getTranslation('REMOTE_TRIGGERING');
    this.triggerForm = this.dialogService.open(CeosAutomationTriggerFormComponent, {
      header: headerTitle,
      data: { automation: this.data },
    });

    this.subscribers.triggerFormDestroy = this.triggerForm.onDestroy.subscribe(() => {
      this.containerComponent.startAutoUpdate();
    });
  }

  private openEditForm() {
    this.containerComponent.stopAutoUpdate();

    this.editForm = this.dialogService.open(CeosDeviceFormComponent, {
      header: this.data.description,
      data: { device: this.data },
    });

    this.subscribers.editFormClose = this.editForm.onClose.subscribe((result) => {
      if (result) {
        const data: any = {
          id: result.id,
          description: result.description,
          isActive: result.isActive,
        };
        if (typeof result.isDeleted !== 'undefined') {
          data.isDeleted = result.isDeleted;
        }

        this.parentComponent.updateRow(data);
        this.cell.api.redrawRows([this.node]);
      }
    });

    this.subscribers.editFormDestroy = this.editForm.onDestroy.subscribe(() => {
      this.containerComponent.startAutoUpdate();
    });
  }
}
