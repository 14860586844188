import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EndUserRole } from '../interfaces/end-user-role';
import { EndUserRoleService } from '../services/end-user-role.service';
import { SiteTypesService } from '@widgets/site-types/services/site-types.service';
import { SharedService } from '@shared/shared.service';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'sc-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
})
export class EndUserRoleFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: EndUserRole;
  isSubmitting = false;
  siteTypes: SelectItem[] = [];

  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private endUserRoleService: EndUserRoleService,
    private siteTypesService: SiteTypesService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchFormData(this.data.id);
    }

    this.fetchSiteTypes();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchFormData(id: number) {
    this.fetchingState++;
    this.subscribers.fetchFormData = this.endUserRoleService.getEndUserRole(id).subscribe((result: any) => {
      this.formData = result.data;
      this.form.patchValue(this.formData);
      this.fetchingState--;
    });
  }

  private fetchSiteTypes() {
    this.fetchingState++;
    this.subscribers.fetchSiteTypes = this.siteTypesService.getSiteTypes().subscribe((result: any) => {
      const siteTypes = result.data
        .filter((item) => item.isActive && !item.isDeleted)
        .map((item) => {
          const name = this.sharedService.getLocaleWidgetName(item);
          item.name = name;
          return item;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      this.siteTypes = this.sharedService.createSelectItems(siteTypes);
      this.fetchingState--;
    });
  }

  private initForm() {
    this.form = this.endUserRoleService.createEndUserRoleForm();
    // do something e.g. when this field change update that field...
  }

  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.value;

    // call api
    if (this.editMode) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  }

  private create(formData) {
    this.subscribers.create = this.endUserRoleService.createEndUserRole(formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(formData) {
    this.subscribers.update = this.endUserRoleService.updateEndUserRole(this.formData.id, formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.endUserRoleService.deleteEndUserRole(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('END_USER_ROLE');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }
}
