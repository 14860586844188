<div class="sc-flex justify-content-end transparent" style="height: 30px">
  <div class="p-1">
    {{ selectedDevices?.length || 0 }} {{ "SELECTED_DEVICES" | translate }}
  </div>

  <sc-modal-trigger
    *scHasPermission="'device_bulk_modification'"
    [buttonIcon]="'fa fa-pencil'"
    [buttonLabel]="'BULK_MODIFICATION' | translate"
    [modalConfig]="bulkModificationFormConfig"
    [disabled]="!selectedDevices?.length"
  ></sc-modal-trigger>

  <sc-modal-trigger
    [buttonIcon]="'fa fa-line-chart'"
    [buttonLabel]="'ANALYTICS' | translate"
    [modalConfig]="deviceComparisonModalConfig"
    [disabled]="isFetchingDataset || !devices?.length"
  ></sc-modal-trigger>

  <div class="create-wizard-trigger" *ngIf="isAvailable">
    <button
      type="button"
      pButton
      class="ui-button-success"
      (click)="openWizard()"
      [label]="'DEVICE_WIZARD_WIZARD' | translate"
      [icon]="'fa fa-magic'"
    ></button>
  </div>
</div>

<div style="height: calc(100% - 30px)">
  <sc-table
    [columns]="columns"
    [config]="config"
    [formConfig]="formConfig"
    (afterInit)="afterInitTable($event)"
    (reloadData)="fetchDataset()"
    (displayColumnsChange)="updateVisibleColumns($event)"
    [title]="tableTitle"
    [lazyload]="true"
    icon="fa fa-mobile"
  ></sc-table>
</div>
