<div class="app-layout" scAppStyle>
  <sc-left-sidebar
    *ngIf="isLoggedIn"
    [menu]="myMenu"
    [user]="user"
    [expand]="showMenuPanel"
    [logoDefault]="logoDefault"
    [logoMobile]="logoMobile"
    [overlay]="isOverlayMenuActive"
    (menuClicked)="navigate($event)"
    (toggled)="toggleMenu()"
  ></sc-left-sidebar>

  <div
    class="main"
    [ngClass]="{
      'menu-expanded': showMenuPanel,
      'menu-overlay': isOverlayMenuActive
    }"
  >
    <sc-toolbar
      *ngIf="isLoggedIn && user"
      [user]="user"
      [logoDefault]="logoDefault"
      [logoMobile]="logoMobile"
      (openMenuPanel)="toggleMenu()"
      (loggedOut)="logOut()"
      (viewMyAccount)="onViewMyAccount()"
    ></sc-toolbar>
    <sc-breadcrumb [items]="items"></sc-breadcrumb>
    <div class="content">
      <router-outlet></router-outlet>
    </div>

    <p-toast position="bottom-right"></p-toast>
    <p-confirmDialog></p-confirmDialog>
  </div>
</div>
