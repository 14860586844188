import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AutomationActionCellComponent } from './automation-action-cell/automation-action-cell.component';
import { AutomationsTableComponent } from './automations-table/automations-table.component';
import { AutomationFormComponent } from './automation-form/automation-form.component';
import { CeosIdentityFormService } from './services/ceos-identity-form.service';
import { CeosIdentityService } from './services/ceos-identity.service';

const COMPONENTS: any[] = [AutomationActionCellComponent, AutomationsTableComponent, AutomationFormComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [CeosIdentityFormService, CeosIdentityService],
})
export class AutomationsModule {}
