import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { PageTemplateActionCellComponent } from './page-template-action-cell/page-template-action-cell.component';
import { PageTemplateFormComponent } from './page-template-form/page-template-form.component';
import { PageTemplatesTableComponent } from './page-templates-table/page-templates-table.component';

import { SectionsFormComponent } from './page-template-form/sections-form/sections-form.component';

import { PageTemplateFormsService } from './services/page-template-forms.service';
import { PageTemplatesService } from './services/page-templates.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    PageTemplateActionCellComponent,
    PageTemplateFormComponent,
    PageTemplatesTableComponent,
    SectionsFormComponent
  ],
  entryComponents: [
    PageTemplateActionCellComponent,
    PageTemplateFormComponent,
    PageTemplatesTableComponent
  ],
  providers: [PageTemplateFormsService, PageTemplatesService]
})
export class PageTemplatesModule { }
