<div [formGroup]="form" class="ui-g mb-2">
  <div class="ui-g-5 ui-g-nopad">{{ form.value?.key | translate }}</div>

  <div class="ui-g-5 ui-g-nopad">
    <div [ngSwitch]="form.value?.key">
      <!-- DEVICE_VALUE_STORAGE -->
      <div *ngSwitchCase="'deviceId'">
        <sc-dropdown
          controlName="value"
          [form]="form"
          label="{{ 'DEVICE' | translate }}"
          [options]="devices"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <div *ngSwitchDefault>
        <sc-text-input
          controlName="value"
          [form]="form"
          label="{{ 'VALUE' | translate }}"
          [showLabel]="false"
        ></sc-text-input>
      </div>
    </div>
  </div>
</div>
