import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromStore from '@app/store';
import { Locale } from '../models/locale';
import { config } from '@app/config';

@Component({
  selector: 'sc-locale-form',
  templateUrl: 'locale-form.component.html',
})
export class LocaleFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: Locale;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  patterns = config().regexPatterns;
  pendingState: number;
  submitting: boolean;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private formBuilder: FormBuilder, private store: Store<fromStore.State>) {}

  ngOnInit() {
    // clear form pending state
    this.pendingState = 0;
    this.store.dispatch(new fromStore.LocaleResetPendingState());

    // watch form pending error
    this.store
      .select(fromStore.getLocalePendingError)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        this.errorMessage = result;
      });

    // close form when create or update success
    this.store
      .select(fromStore.getLocalePending)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((result) => {
        this.pendingState++;
        this.submitting = result;
        if (this.pendingState === 3) {
          if (!this.submitting && !this.errorMessage) {
            this.onClose.emit();
          }
          this.pendingState = 0;
        }
      });

    if (this.data) {
      // EDIT MODE
      this.editMode = true;

      // FETCH LOCALE
      this.store.dispatch(new fromStore.LoadLocale(this.data.id));
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [null, Validators.compose([Validators.required, Validators.pattern(this.patterns.id)])],
      language: [null, Validators.required],
      languageNative: [null, Validators.required],
      isTextToTextTranslationSupported: false,
      isTextToSpeechTranslationSupported: false,
      isSpeechToTextTranslationSupported: false,
      isActive: false,
      isDeleted: false,
    });

    if (this.editMode) {
      this.form.get('id').disable();
      // SUBSCRIBE LOCALE
      this.store
        .select(fromStore.getLocaleFormData)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((result) => {
          if (result) {
            this.formData = result;
            this.form.patchValue(this.formData);
          }
        });
    }
  }

  submit() {
    if (this.form.valid) {
      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create() {
    const fromData = this.form.getRawValue();
    this.store.dispatch(new fromStore.CreateLocale(fromData));
  }

  update() {
    const fromData = this.form.getRawValue();
    fromData.id = this.data.id;
    this.store.dispatch(new fromStore.UpdateLocale(fromData));
  }

  delete() {
    this.store.dispatch(new fromStore.DeleteLocale(this.formData));
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
