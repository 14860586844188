import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeviceWizard } from '../device-wizard';

@Component({
  selector: 'sc-device-setting',
  templateUrl: './device-setting.component.html',
  styleUrls: ['./device-setting.component.scss']
})
export class DeviceSettingComponent implements OnInit {
  @Input()
  data: DeviceWizard;
  @Input()
  form: FormGroup;
  @Input()
  formId: number;

  isOpened = true;

  constructor() { }

  ngOnInit() { }
}
