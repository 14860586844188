import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { LocationProfile } from '../models/location-profile';
import { ModalConfig } from '@sc/modal/modal-config';

import { LocationProfilesService } from '../services/location-profile.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { LocationProfileActionCellComponent } from '../location-profile-action-cell/location-profile-action-cell.component';

@Component({
  selector: 'sc-location-profiles-table',
  templateUrl: 'location-profiles-table.component.html'
})
export class LocationProfilesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: LocationProfile[];
  formConfig: ModalConfig;
  tableTitle: string;
  subscribers: { [key: string]: any } = {};

  constructor(
    private locationProfilesService: LocationProfilesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.tableTitle = 'LOCATION_PROFILES';

    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: LocationProfileActionCellComponent
      }
    ];

    this.formConfig = {
      name: 'LocationProfileForm',
      options: { modalTitle: 'LOCATION_PROFILE_FORM_TITLE' }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.GET_DATASET = this.locationProfilesService
      .getLocationProfiles()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
