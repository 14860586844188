import { LocationsService } from '@widgets/locations/services/locations.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import { Page } from '@app/core2/models/page';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormArray } from '@angular/forms';

interface Device {
  idx: string;
  name: string;
  locationName: string;
  locationIdx: string;
  fullName: string;
  min: number;
  max: number;
}

@Component({
  selector: 'sc-air-quality-live',
  templateUrl: './air-quality-live.component.html',
})
export class AirQualityLiveComponent implements OnInit, OnDestroy {
  // The widget with its configuration
  @Input() config: any;

  UNIT_COV = 'cov';
  UNIT_CO2 = 'co2';

  page: Page;
  selectedSite: Site;

  subscribers: { [key: string]: any } = {};
  intervals: { [key: string]: any } = {};

  chartData: any;
  chartLayout: any;

  isInitialized: boolean = false;
  isFetchingStatistics: boolean = false;
  isFetchingDevices: boolean = false;

  widgetName: string = '';
  defaultWidgetName: string = '';
  customWidgetName: string = '';

  // Config modal
  isConfigModalOpen: boolean = false;
  isConfigModalInitialized: boolean = false;

  // All available Devices, loaded from API
  devices: Device[] = [];
  filteredDevices: Device[] = [];

  // Device, currently selected in the form
  selectedDevice;

  configForm: FormGroup;

  // Details modal
  isDetailsModalOpen: boolean = false;
  isDetailsModalInitialized: boolean = false;

  // Statistics
  deviceStats: any[] = [];

  thresholds = [
    {
      index: 0,
      name: 'Ok',
      color: 'green',
      min: 0,
      max: 0,
    },
    {
      index: 1,
      name: 'Warning',
      color: 'orange',
      min: 1,
      max: 99,
    },
    {
      index: 2,
      name: 'Alert',
      color: 'red',
      min: 100,
      max: 100,
    },
  ];

  legendItems: any[] = [];

  constructor(
    private widgetService: SCWidgetService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private formBuilder: FormBuilder
  ) {
    this.chartLayout = {
      height: 190,
      width: 190,
      showlegend: false,
      margin: { t: 15, b: 15, l: 15, r: 15 },
      font: {
        family: 'barlow',
        size: 13,
      },
    };

    this.configForm = this.formBuilder.group({
      name: [''],
      unit: [this.UNIT_CO2],
      selectedDevice: [''],
      selectedDevices: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.initWidgetName();
    this.fetchInitialData();
    this.intervals.fetchDataset = setInterval(() => this.fetchStatistics(), 10000);

    this.configForm.get('unit').valueChanges.subscribe((selectedValue) => {
      this.filteredDevices = this.filterDevices(this.devices, selectedValue);
      this.configForm.patchValue({
        selectedDevice: [''],
      });
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  isConfigured() {
    let devices = this.config.customPreferences?.devices || [];
    return devices && devices.length ? true : false;
  }

  initWidgetName() {
    this.defaultWidgetName = this.sharedService.getLocaleWidgetName(this.config);
    if (this.config?.customPreferences?.name) {
      this.customWidgetName = this.config.customPreferences.name;
    }
    this.widgetName = this.customWidgetName ? this.customWidgetName : this.defaultWidgetName;
  }

  get selectedDevices(): FormArray {
    return this.configForm.get('selectedDevices') as FormArray;
  }

  selectDevice() {
    let device: Device = this.configForm.get('selectedDevice').value;
    this.selectedDevices.push(
      this.formBuilder.group({
        device: [device],
        min: [],
        max: [],
      })
    );
  }

  unselectDevice(index: number) {
    this.selectedDevices.removeAt(index);
  }

  fetchInitialData() {
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (site) => {
        this.selectedSite = site;
        this.subscribers.watchPage = this.store.pipe(select(fromStore.getCurrentPage)).subscribe({
          next: (page) => {
            this.page = page;
            this.fetchStatistics();
            this.fetchDevices();
            this.isInitialized = true;
          },
        });
      },
    });
  }

  fetchStatistics() {
    if (this.isFetchingStatistics) {
      return;
    }

    this.isFetchingStatistics = true;

    let devices = this.config?.customPreferences?.devices?.map((device) => {
      return {
        idx: device.idx,
        min: device.min,
        max: device.max,
      };
    });

    if (!devices) {
      return;
    }

    let unit = this.config?.customPreferences?.unit;
    if (!unit) {
      return;
    }

    this.subscribers.fetchDataset = this.widgetService
      .getAirQualityStats(this.selectedSite.id, unit, devices, this.thresholds)
      .subscribe(
        (result: any) => {
          this.isFetchingStatistics = false;
          this.deviceStats = [];

          for (const device of result.data.devices) {
            let tmpDevice = {
              idx: device.idx,
              thresholdIndex: device.threshold_index,
              thresholdName: this.thresholds.find((t) => t.index == device.threshold_index)?.name,
              percentage: device.percentage,
              value: device.value,
              fullName: null,
              min: null,
              max: null,
            };

            for (const cfgDevice of this.config.customPreferences?.devices) {
              if (tmpDevice.idx == cfgDevice.idx) {
                tmpDevice.min = cfgDevice.min;
                tmpDevice.max = cfgDevice.max;
                break;
              }
            }

            for (const e of this.devices) {
              if (tmpDevice.idx == e.idx) {
                tmpDevice.fullName = e.fullName;
                break;
              }
            }

            this.deviceStats.push(tmpDevice);
          }

          this.renderChart(result.data.thresholds);
        },
        (error: any) => {
          this.isFetchingStatistics = false;
        }
      );
  }

  fetchDevices() {
    this.isFetchingDevices = true;
    return new Promise((resolve, reject) => {
      this.subscribers.fetchDevices = this.widgetService.getAirQualitySensorsOfSite(this.selectedSite.id).subscribe(
        (result: any) => {
          this.isFetchingDevices = false;
          this.devices = result.data.map((device) => {
            return {
              idx: device.device_idx,
              name: device.device_name,
              locationIdx: device.location_idx,
              locationName: device.location_name,
              fullName: device.location_name + ' - ' + device.device_name,
              propertyName: device.property_name,
              propertyIdx: device.property_idx,
              deviceTypeId: device.device_type_id,
            };
          });
          this.filteredDevices = this.filterDevices(this.devices, this.configForm.value.unit);
          resolve(this.devices);
        },
        (error: any) => {
          this.isFetchingDevices = false;
        }
      );
    });
  }

  filterDevices(devices, unit) {
    let deviceTypeId = 54; // Particulate Matter Sensor
    if (unit == this.UNIT_CO2) {
      deviceTypeId = 73; // CO2 Sensor
    }
    let filtered = [];
    for (let device of devices) {
      if (device.deviceTypeId == deviceTypeId) {
        // TODO: avoid duplicates
        filtered.push(device);
      }
    }
    return filtered;
  }

  resetWidgetName() {
    this.configForm.patchValue({
      name: '',
    });
  }

  openConfigModal() {
    this.isConfigModalInitialized = false;
    this.isConfigModalOpen = true;
    this.fetchDevices().then((devices) => {
      this.patchConfigForm(this.config, devices);
      this.isConfigModalInitialized = true;
    });
  }

  patchConfigForm(config, devices) {
    if (!config.customPreferences) {
      return;
    }

    this.configForm.patchValue({
      name: config.customPreferences.name,
      unit: config.customPreferences.unit,
    });

    this.selectedDevices.clear();
    for (let selectedDevice of config.customPreferences.devices) {
      for (let device of devices) {
        if (device.idx == selectedDevice.idx) {
          this.selectedDevices.push(
            this.formBuilder.group({
              device: device,
              min: selectedDevice.min,
              max: selectedDevice.max,
            })
          );
          break;
        }
      }
    }
  }

  saveConfig() {
    let cfg = this.configForm.value;
    let devices = [];
    for (let device of cfg.selectedDevices) {
      devices.push({
        idx: device.device.idx,
        min: device.min,
        max: device.max,
      });
    }

    let customPreferences = {
      name: cfg.name,
      unit: cfg.unit,
      devices: devices,
    };

    this.isConfigModalOpen = false;
    let { page, sectionIndex } = this.widgetService.setWidgetCustomPreferences(
      this.page,
      this.config.uniqueId,
      customPreferences
    );
    this.store.dispatch(new fromStore.UpdatePage(page));
  }

  openDetailsModal() {
    this.isDetailsModalInitialized = false;
    this.isDetailsModalOpen = true;
    this.fetchDevices().then((devices) => {
      this.isDetailsModalInitialized = true;
    });
  }

  renderChart(threshholds) {
    let values = [];
    let labels = [];
    let colors = [];
    this.legendItems = [];
    for (const threshold of threshholds) {
      values.push(threshold.total);
      labels.push(threshold.name);
      colors.push(this.widgetService.getColorByKey(threshold.color).hex);

      this.legendItems.push({
        value: threshold.total,
        label: threshold.name,
        color: this.widgetService.getColorByKey(threshold.color).hex,
      });
    }

    this.chartData = [
      {
        type: 'pie',
        values: values,
        labels: labels,
        marker: {
          colors: colors,
        },
        textposition: 'inside',
        hole: 0.6,
      },
    ];
  }
}
