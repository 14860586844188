<div class="command-item">
  <div class="command-info" (click)="onCommandClick()">
    <div *ngIf="commandLabel" [innerHtml]="commandLabel"></div>
    <div *ngIf="!commandLabel">
      {{ ("CCP_RULE_COMMAND_IS_NOT_SET" | translate) }}
    </div>
  </div>

  <!-- *ngIf="isTemplate" -->
  <div class="command-actions" *ngIf="isTemplate || isFromScratch">
    <sc-action-button
      [menu]="actionMenu"
      (selected)="onActionSelected($event)"
    ></sc-action-button>
  </div>
</div>
