<div [formGroup]="group">
  <div class="ui-g">
    <div class="ui-g-3">
      <sc-text-input
        controlName="parameterId"
        [form]="group"
        label="{{ 'PARAMETER' | translate }} {{ 'ID' | translate }}"
      ></sc-text-input>
    </div>

    <div class="ui-g-3">
      <sc-text-input
        controlName="type"
        [form]="group"
        label="{{ 'TYPE' | translate }}"
      ></sc-text-input>
    </div>

    <div class="ui-g-3">
      <sc-text-input
        controlName="defaultValues"
        [form]="group"
        label="{{ 'DEFAULT_VALUES' | translate }}"
      ></sc-text-input>
    </div>

    <div class="ui-g-3 mt-4 text-right">
      <button
        pButton
        type="button"
        class="ui-button-warning"
        icon="fa fa-minus"
        (click)="remove()"
        pTooltip="{{ 'REMOVE' | translate }} {{ 'PARAMETER' | translate }}"
        appendTo="body"
      ></button>
    </div>
  </div>
</div>
