<div [formGroup]="group">
  <div class="ui-g mb-3">
    <div class="ui-g-12">
      <span class="flag-icon" [ngClass]="getFlag()"></span>
      {{ group.value?.locale | uppercase }}
    </div>
    <!-- VALUE FIELD -->
    <div class="ui-g-12">
      <sc-textarea
        controlName="value"
        [form]="group"
        label="{{ group.value?.locale }}"
        [showLabel]="false"
      ></sc-textarea>
    </div>
  </div>
</div>
