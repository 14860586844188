<sc-overview-form
  [disabled]="isFormPendingState"
  [selectItems]="selectItems"
  [analyseConfig]="analyseModalConfig"
  (afterInit)="afterFormInit($event)"
  (generated)="generateBenchmark($event)"
  (searched)="fetchDataset($event)"
></sc-overview-form>

<div class="p-2 text-right" *ngIf="benchmarkGenerateStatus">
  <div [ngSwitch]="benchmarkGenerateStatus">
    <div *ngSwitchCase="1">
      <small class="green-text">
        <i class="fa fa-refresh fa-spin"></i>
        {{
          'BENCHMARK_GENERATING_IN_PROGRESS'
            | translate: benchmarkGenerateTranslateParams
        }}
      </small>
    </div>
    <div *ngSwitchCase="2">
      <small class="red-text">
        <i class="fa fa-exclamation-triangle"></i>
        {{
          'BENCHMARK_GENERATING_TIMEOUT'
            | translate: benchmarkGenerateTranslateParams
        }}
      </small>
    </div>
    <div *ngSwitchCase="3">
      <small class="green-text">
        <i class="fa fa-refresh fa-spin"></i>
        {{
          'BENCHMARK_GENERATING_EXISTING_CHECK'
            | translate: benchmarkGenerateTranslateParams
        }}
      </small>
    </div>
  </div>
</div>

<sc-table
  [columns]="columns"
  [config]="config"
  [dataset]="dataset"
  icon="fa fa-task"
  [title]="tableTitle"
  [toolbarItems]="toolbarItems"
  (afterInit)="afterTableInit($event)"
></sc-table>
