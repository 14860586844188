import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { Site } from '@widgets/sites/models/site';

import { LocationStatisticsFormsService } from './services/location-statistics-forms.service';
import { LocationStatisticsService } from './services/location-statistics.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { LocationPropertiesService } from '@widgets/location-properties/services/location-properties.service';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import * as moment from 'moment';

@Component({
  selector: 'sc-location-statistics',
  templateUrl: './location-statistics.component.html',
  styleUrls: ['./location-statistics.component.scss'],
})
export class LocationStatisticsComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  errorMessage: string;
  form: FormGroup;
  formData: {
    from: string;
    to: string;
    interval: 'day' | 'week' | 'month' | 'hour' | 'minute' | '10s' | '10m';
    isCombine?: boolean;
    isCompare?: boolean;
    graphs: any[];
    locations: number[];
    compareLocations: number[];
    fetchingRequired: boolean;
  };
  charts1: any;
  charts2: any;
  isFetching = false;
  isChangingInterval = false;
  currentInterval: string;
  selectItems: { [key: string]: any[] } = {};
  dataset1: any[];
  dataset2: any[];

  private locations: any[];

  private zoomedRange: [number, number];
  private selectedSite: Site;
  private userVariant: string;
  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private locationStatisticsFormsService: LocationStatisticsFormsService,
    private locationStatisticsService: LocationStatisticsService,
    private locationsService: LocationsService,
    private locationPropertiesService: LocationPropertiesService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;
    this.userVariant = this.sharedService.userVariant;
    this.selectItems = this.locationStatisticsFormsService.formSelectItems();

    this.subscribers.locations = this.store.select(fromStore.getAllLocations).subscribe((result) => {
      if (result) {
        this.locations = result;
      }
    });

    this.fetchLocationProps();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  downloadCSV() {
    const locations = [];
    const selectedLocations = [];
    const comparedLocations = [];
    this.formData.locations?.map((locationIdx) => {
      const locationName = this.locations.find((location) => location.idx === locationIdx).description;
      if (locationName) {
        selectedLocations.push(locationName);
      }
    });
    locations.push(selectedLocations.join());
    if (this.formData.compareLocations) {
      this.formData.compareLocations?.map((locationIdx) => {
        const locationName = this.locations.find((location) => location.idx === locationIdx).description;
        if (locationName) {
          comparedLocations.push(locationName);
        }
      });
      locations.push(comparedLocations.join());
    }
    this.locationStatisticsService.csvDownload(locations, this.selectedSite.timezone, [this.dataset1, this.dataset2]);
  }

  private initForm() {
    this.form = this.locationStatisticsFormsService.initDatasetForm();

    // patch location id, when call from location
    if (this.data && (this.data.id || this.data.idx)) {
      const key = this.userVariant === 'ceos' ? 'idx' : 'id';
      this.form.patchValue({ locations: [this.data[key]] });
    } else {
      // load location of sites
      this.fetchLocations();
    }

    const fieldsToWatch = ['locations', 'compareLocations', 'from', 'to', 'interval', 'isCompare'];
    for (let i = 0; i < fieldsToWatch.length; i++) {
      const field = fieldsToWatch[i];
      this.subscribers[field + 'Field'] = this.form.get(field).valueChanges.subscribe((value) => {
        if (field === 'isCompare') {
          if (value === true) {
            this.form.get('compareLocations').setValidators(Validators.required);
          } else {
            this.form.get('compareLocations').clearValidators();
            this.form.get('compareLocations').reset();
          }
        } else {
          this.form.get('fetchingRequired').setValue(true);
        }
      });
    }
  }

  private fetchLocations() {
    if (!this.selectedSite || !this.selectedSite.id) {
      return;
    }

    const options = {
      siteId: this.selectedSite.id,
      columns: 'id,idx,description,isActive,isDeleted,parentId',
      tree: true,
    };

    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
      const valueKey = this.userVariant === 'ceos' ? 'idx' : 'id';
      const locations = result.data.filter((d) => d.isActive && !d.isDeleted);
      this.selectItems.locations = this.sharedService.createMultiSelectItems(locations, 'children', valueKey);
    });
  }

  private fetchLocationProps() {
    this.subscribers.fetchLocationProps = this.locationPropertiesService
      .getLocationProperties()
      .subscribe((result: any) => {
        this.selectItems.graphs = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted),
          false,
          'key'
        );
      });
  }

  fetchLocationStatistics(forceReload = false, interval?: string) {
    this.formData = this.form.value;

    if (!this.form.valid) {
      // do nothing
      return;
    }
    if (!forceReload && !this.formData.fetchingRequired) {
      // re-create the chart
      this.createChart();
      return;
    }

    this.isFetching = true;

    this.resetData();

    const options: any = {
      from: this.formData.from,
      to: this.formData.to,
      interval: interval || this.formData.interval,
      fields: this.formData.graphs.map((g) => g.dataset).join(),
      locations: this.formData.locations.join(),
      compareLocations: null,
    };

    if (this.formData.isCompare && this.formData.compareLocations) {
      options.compareLocations = this.formData.compareLocations.join();
    }

    this.subscribers.fetchLocationStatistics = this.locationStatisticsService.getLocationStatistics(options).subscribe(
      (result: any) => {
        if (!result || !result.source) {
          this.resetData('NO_DATA_FOUND');
        } else {
          this.dataset1 = result.source || null;
          this.dataset2 = result.compare || null;
          this.createChart();
        }

        this.isFetching = false;
        this.isChangingInterval = false;
        this.form.get('fetchingRequired').setValue(false);
      },
      (error: any) => {
        this.resetData('SOMETHING_WENT_WRONG');
        this.isFetching = false;
      }
    );
  }

  private resetData(errMsg?: string) {
    this.charts1 = null;
    this.charts2 = null;
    this.dataset1 = null;
    this.dataset2 = null;
    this.errorMessage = errMsg;
  }

  private getPrevValue(dataset: any[], currentIndex: number, key: string) {
    const prevIndex = currentIndex - 1;
    let prevItem: any;
    if (prevIndex >= 0) {
      prevItem = dataset[prevIndex];
    }
    if (prevItem && prevItem[key] !== null) {
      return prevItem[key];
    }
    return null;
  }

  private valueFormatter(value: number, label: string) {
    let digit = 1;
    if (label === 'CONSUMPTION' || label === 'CONSUMPTIONTOTAL') {
      // convert watt to kilo watt
      digit = 3;
      value = value / 1000;
    }
    value = this.sharedService.numberFormat(value, digit);
    return value;
  }

  private valueUnit(label: string) {
    switch (label.toUpperCase()) {
      case 'AIRQUALITY':
        return 'PM2.5';

      case 'BRIGHTNESS':
        return 'LUX';

      case 'CONSUMPTION':
      case 'CONSUMPTIONTOTAL':
        return 'KW';

      case 'EMRTOTAL':
        return 'KWH';

      case 'HUMIDITY':
      case 'OUTDOORHUMIDITY':
        return '%';

      case 'TEMPERATURE':
      case 'OUTDOORTEMPERATURE':
        return '°C';

      case 'WINDSPEED':
        return 'KM/H';

      default:
        return '';
    }
  }

  private isBooleanValue(label: string) {
    const keys = config().lhlHasBooleanValue;
    return keys.indexOf(label.toLowerCase()) >= 0 ? true : false;
  }

  private createChart() {
    if (this.formData.isCombine === true) {
      this.charts1 = this.getChart(this.dataset1, true);
      if (this.formData.isCompare === true && this.dataset2) {
        this.charts2 = this.getChart(this.dataset2, true);
      }
    } else {
      this.charts1 = this.getChart(this.dataset1, false);
      if (this.formData.isCompare === true && this.dataset2) {
        this.charts2 = this.getChart(this.dataset2, false);
      }
    }
  }

  private getChartLabel(label: string) {
    return label === 'CONSUMPTION' ? 'POWERAGE' : label === 'EMRTOTAL' ? 'CONSUMPTION' : label;
  }

  private getChart(dataset: any[], isCombine: boolean) {
    // [{ data: [trace1], layout: {} }, { data: [trace2], layout: {} }, ..., { data: [traceN], layout: {} }]
    const axes = {};
    const charts = [];
    const traces = [];
    const graphs = this.formData.graphs;
    let leftSpace = 0;
    let rightSpace = 0;
    let firstRight = true;
    let firstLeft = true;
    const axisSpace = 0.075;
    const axisMapper: any = {};

    // create axis mapper
    let ycount = 1;
    for (let i = 0; i < graphs.length; i++) {
      const graph = graphs[i];
      if (graph.dataset === graph.axis && !axisMapper[graph.dataset]) {
        axisMapper[graph.dataset] = ycount === 1 ? 'y' : 'y' + ycount;
        ycount += 1;
      }
    }

    // create trace and axis
    for (let i = 0; i < graphs.length; i++) {
      const graph = graphs[i];
      const label = graph.dataset.toUpperCase();
      const unit = this.valueUnit(label);
      const axisName = axisMapper[graph.axis].replace('y', 'yaxis');

      let showAxis = true;
      if (graph.styles) {
        const mode = ['lines'];
        for (let ii = 0; ii < graph.styles.length; ii++) {
          if (graph.styles[ii] === 'hide_tick') {
            showAxis = false;
            break;
          }
        }
      }

      const trace: any = {
        name: this.getChartLabel(label),
        mode: 'lines',
        type: 'scatter',
        line: {
          color: graph.color,
          dash: 'solid',
          shape: 'linear',
        },
        connectgaps: true,
        x: [], // date
        y: [], // value
        yaxis: isCombine ? axisMapper[graph.axis] : 'y',
      };
      let layout: any = {
        showlegend: true,
        legend: { orientation: 'h', xanchor: 'center', x: 0.5 },
        title: isCombine ? 'Combine chart of ' + graphs.length + ' variables' : this.getChartLabel(label),
        xaxis: {
          rangeslider: { borderwidth: 1, range: [] },
          showline: false,
          showgrid: false,
          title: 'Date/Time',
          type: 'date',
        },
      };

      // apply zoom range
      if (this.zoomedRange) {
        layout.xaxis.range = this.zoomedRange;
        //   layout.xaxis.rangeslider.range = zoomrange;
        // } else if (fullrange) {
        //   const fullrange = [
        //     moment(this.formData.from, 'DD.MM.YYYY"T"HH:mm:ss').valueOf(),
        //     moment(this.formData.to, 'DD.MM.YYYY"T"HH:mm:ss').valueOf()
        //   ];
        //   layout.xaxis.range = fullrange;
      }

      if (!isCombine || graph.dataset === graph.axis) {
        // create y axis
        axes[axisName] = {
          title: unit ? this.getChartLabel(label) + ' (' + unit + ')' : this.getChartLabel(label),
          type: 'linear',
          showline: false,
          rangemode: 'nonnegative',
          color: graph.color,
          side: graph.position,
          titlefont: { color: graph.color },
          tickfont: { color: graph.color },
          tickformat: '.1f',
        };

        if (this.isBooleanValue(label)) {
          axes[axisName].range = [0, 1];
        }

        if (label === 'CONSUMPTION' || label === 'CONSUMPTIONTOTAL') {
          axes[axisName].tickformat = '.3f';
        }

        if (isCombine) {
          // set y axis of data
          if (graph.position === 'right') {
            if (firstRight) {
              firstRight = false;
              if (axisName !== 'yaxis') {
                axes[axisName].anchor = 'x';
                axes[axisName].overlaying = 'y';
              }
            } else {
              axes[axisName].anchor = 'free';
              axes[axisName].overlaying = 'y';
              if (showAxis) {
                axes[axisName].position = 1 - axisSpace * rightSpace;
                rightSpace += 1;
              }
            }
          } else if (graph.position === 'left') {
            if (firstLeft) {
              firstLeft = false;
              if (axisName !== 'yaxis') {
                axes[axisName].anchor = 'x';
                axes[axisName].overlaying = 'y';
              }
            } else {
              axes[axisName].anchor = 'free';
              axes[axisName].overlaying = 'y';
              if (showAxis) {
                axes[axisName].position = axisSpace * leftSpace;
                leftSpace += 1;
              }
            }
          }

          if (graph.dataset !== graph.axis) {
            axes[axisName].visible = false;
          }
        }
      }

      // apply custom styles
      if (graph.styles) {
        const mode = ['lines'];
        for (let ii = 0; ii < graph.styles.length; ii++) {
          switch (graph.styles[ii]) {
            case 'dash_line':
              trace.line.dash = 'dash';
              break;
            case 'draw_points':
              // trace.mode = 'lines+markers';
              mode.push('markers');
              break;
            case 'filled':
              trace.fill = 'tozeroy';
              break;
            case 'hide_line':
              // trace.mode = 'none';
              mode.splice(0, 1);
              break;
            case 'hide_grid':
              axes[axisName].showgrid = false;
              break;
            case 'hide_tick':
              axes[axisName].visible = false;
              break;
            case 'stepped_line':
              trace.line.shape = 'hv';
              break;
          }
        }
        trace.mode = mode.length ? mode.join('+') : 'none';
      }

      // get data
      for (let ii = 0; ii < dataset.length; ii++) {
        const item = dataset[ii];
        const date = this.sharedService.dateFormat(item.key, 'YYYY-MM-DD HH:mm:ss');

        for (const key in item) {
          if (item.hasOwnProperty(key) && key.replace('avg', '').toUpperCase() === label) {
            let value = item[key];

            // no data exists, use a previous data if possible
            if (value === null) {
              const prevValue = this.getPrevValue(dataset, ii, key);
              if (prevValue) {
                item[key] = prevValue;
                value = prevValue;
              }
            }

            // filter only a number value
            if (value !== null && value !== NaN) {
              value = this.valueFormatter(value, label);
            }
            trace.x.push(date);
            trace.y.push(value);

            break;
          }
        }
      }

      // create chart
      if (isCombine) {
        layout = { ...layout, ...axes };
        // reserve the spaces for multiple Y axes
        const leftDomain = axisSpace * leftSpace;
        const rightDomain = 1 - axisSpace * rightSpace;
        layout.xaxis.domain = [leftDomain, rightDomain];
        traces.push(trace);
        if (i === graphs.length - 1) {
          charts.push({ data: traces, layout });
        }
      } else {
        layout.yaxis = axes[axisName];
        charts.push({ data: [trace], layout });
      }
    }

    return charts;
  }

  onZoom(event: any /*, divId: string*/) {
    if (!event) {
      return;
    }

    if (this.timeouts.onZoom) {
      clearTimeout(this.timeouts.onZoom);
    }
    this.timeouts.onZoom = setTimeout(() => {
      let from: any;
      let to: any;
      let interval: any;
      let reset = false;
      // let zoomrange: any;

      if (!this.currentInterval) {
        // only first time
        this.currentInterval = this.form.value.interval;
      }

      if (event['xaxis.range']) {
        // zoom on graph
        from = event['xaxis.range'][0];
        to = event['xaxis.range'][1];
      } else if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
        // zoom on slider
        from = event['xaxis.range[0]'];
        to = event['xaxis.range[1]'];
      } else if (event['xaxis.autorange']) {
        // reset zoom
        reset = true;
        interval = this.form.value.interval;
        this.zoomedRange = null;
      }

      if (from && to) {
        if (typeof from === 'string') {
          // remove micro sec from date string
          from = from.substring(0, 19);
          from = moment(from, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm']);
        } else if (typeof from === 'number') {
          // timestamp milliseconds
          from = moment(from);
        }
        if (typeof to === 'string') {
          // remove micro sec from date string
          to = to.substring(0, 19);
          to = moment(to, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm']);
        } else if (typeof to === 'number') {
          // timestamp milliseconds
          to = moment(to);
        }
        const diff = to.diff(from, 'minutes');
        if (diff <= 5) {
          // 10 seconds if less or equal than 5 minutes
          interval = '10s';
        } else if (diff <= 120) {
          // 1 minute if less than 2 hours
          interval = 'minute';
        } else if (diff <= 2880) {
          // 10 minutes if less or equal than 2 days
          interval = '10m';
        } else if (diff <= 10080) {
          // 1 hour if less than 7 days
          interval = 'hour';
        } else {
          // 1 day if more than 7 days
          interval = 'day';
        }

        // zoomrange = [from.valueOf(), to.valueOf()];
        this.zoomedRange = [from.valueOf(), to.valueOf()];
      }

      if (reset || (interval && this.currentInterval !== interval)) {
        this.isChangingInterval = true;
        this.currentInterval = interval;
        // this.fetchLocationStatistics(true, interval, zoomrange);
        this.fetchLocationStatistics(true, interval);
      } else {
        // this.zoomSync(zoomrange, divId);
      }
    }, 500);
  }
}
