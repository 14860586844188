import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';

import { sortBy } from 'lodash';

@Injectable({ providedIn: 'root' })
export class FileManagerService {
  constructor() { }

  createTree(nodes: any[]) {
    const fileTree: TreeNode[] = [];

    if (!nodes || !nodes.length) {
      return fileTree;
    }

    for (const n of nodes) {
      const node: TreeNode = {
        label: n.name || '',
        data: n || '',
        // icon: this.getIcon(n),
      };
      if (n.isDirectory) {
        node.children = [];
        node.collapsedIcon = 'fa fa-fw fa-folder';
        node.expandedIcon = 'fa fa-fw fa-folder-open';
        node.leaf = false;
      } else {
        node.icon = this.getIcon(n);
        node.leaf = true;
      }
      fileTree.push(node);
    }

    return sortBy(fileTree, 'label');
  }

  getExtension(data) {
    if (data && data.name === data.name.split('.').pop() && data.isDirectory) {
      return 'folder';
    }
    return data.name.split('.').pop();
  }

  getIcon(data) {
    const extension = this.getExtension(data);
    switch (extension) {
      case 'doc':
        return 'fa fa-fw fa-file-word-o';

      case 'avi':
      case 'mov':
      case 'mp4':
        return 'fa fa-fw fa-file-video-o';

      case 'jpeg':
      case 'jpg':
      case 'png':
        return 'fa fa-fw fa-file-image-o';

      case 'rar':
      case 'tar':
      case 'zip':
        return 'fa fa-fw fa-file-archive';

      case 'folder':
        return 'fa fa-fw fa-folder';

      case 'txt':
        return 'fa fa-fw fa-file-text-o';

      default:
        return 'fa fa-fw fa-file-o';
    }
  }

  getPath(file: any, split: boolean = false): string[] {
    let path = '';
    if (file && file.data) {
      if (file.data.path) {
        path = file.data.path + '/' + file.data.name;
      } else {
        path = file.data.name;
      }
    }
    return split ? path.split('/') : [path];
  }

  findDir(tree: TreeNode[], path) {
    for (const t of tree) {
      if (!t.data || !t.data.isDirectory) {
        continue;
      }
      const nodePath = t.data.path === '' ? t.data.name : t.data.path + '/' + t.data.name;
      if (nodePath === path) {
        return t;
      } else if (t.children && t.children.length) {
        return this.findDir(t.children, path);
      }
    }
    return;
  }

  // getCurrentDirPath(file: any, split: boolean = false): string[] {
  //   let path: any = '';
  //   if (file && file.path) {
  //     path = file.path;
  //   }
  //   path += '/' + file.name;
  //   path = split ? path.split('/') : [path];
  //   return path;
  // }

  // getReadableSize(data) {
  //   const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  //   // TODO: get file size
  //   let bytes = data.size;
  //   let unit = 0;

  //   if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
  //     return '';
  //   }

  //   while (bytes >= 1024) {
  //     bytes /= 1024;
  //     unit++;
  //   }

  //   return bytes.toFixed(1) + ' ' + units[unit];
  // }
}
