<div *ngIf="dataset.length; then tableTpl; else emptyTpl"></div>

<ng-template #tableTpl>
  <p-table [value]="dataset">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'NAME' | translate }}</th>
        <th>{{ 'DEVICE_TYPE' | translate }}</th>
        <th>{{ 'LOCATION' | translate }}</th>
        <th>{{ 'LEVEL' | translate }}</th>
        <th>{{ 'LOGGED_AT' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data.deviceName }}</td>
        <td>{{ data.deviceTypeName }}</td>
        <td>{{ data.locationName }}</td>
        <td class="text-center">
          <div [ngStyle]="getLevelStyle(data)">{{ data.value }} %</div>
        </td>
        <td>{{ getLoggedAt(data) }}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<ng-template #emptyTpl> <div>Everything is OK</div> </ng-template>
