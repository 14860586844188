<div [formGroup]="group">
  <div class="ui-g">
    <div class="ui-g-12 ui-g-nopad">
      <!-- LOCALE FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="locale"
          [form]="group"
          label="{{ 'LOCALE' | translate }}"
          [options]="optionData?.locales"
        ></sc-dropdown>
      </div>

      <!-- TEXT FIELD -->
      <div class="ui-g-12">
        <sc-textarea
          controlName="text"
          [form]="group"
          label="{{ 'TEXT' | translate }}"
        ></sc-textarea>
      </div>

      <div class="ui-g-12">
        <button
          pButton
          type="button"
          class="ui-button-danger"
          label="{{ 'REMOVE' | translate }}"
          (click)="remove()"
        ></button>
      </div>
    </div>
  </div>
</div>
