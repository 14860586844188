import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-condition-form',
  templateUrl: './rule-condition-form.component.html',
  styleUrls: ['./rule-condition-form.component.scss']
})
export class RuleConditionFormComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  formId: number;
  @Input()
  data: { [key: string]: any };

  selectItems: { [key: string]: SelectItem[] };

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.selectItems = { ...this.data.selectItems };

    if (this.deviceTypeId) {
      const devices = this.data.devices.filter(
        (d: any) => d.deviceTypeId === this.deviceTypeId
      );
      this.selectItems.devices = this.sharedService.createSelectItems(
        devices,
        false,
        'tmpId'
      );
    }

    if (this.referencedDeviceTypeId) {
      const refDevices = this.data.devices.filter(
        (d: any) => d.deviceTypeId === this.referencedDeviceTypeId
      );
      this.selectItems.refDevices = this.sharedService.createSelectItems(
        refDevices,
        false,
        'tmpId'
      );
    }

    this.selectItems.locations = this.sharedService.createSelectItems(
      this.data.locations,
      false,
      'tmpId'
    );

    this.patchFormValue();
  }

  get sourceType() {
    return this.form.get('sourceType').value;
  }

  get deviceTypeId() {
    return this.form.get('deviceTypeId').value;
  }

  get referencedDeviceTypeId() {
    return this.form.get('referencedDeviceTypeId').value;
  }

  get locationPropertyTypeId() {
    return this.form.get('locationPropertyTypeId').value;
  }

  get referencedType() {
    return this.form.get('referencedType').value;
  }

  patchFormValue() {
    switch (this.sourceType) {
      case 'd':
        if (this.selectItems.devices && this.selectItems.devices.length) {
          this.form.get('deviceId').setValue(this.selectItems.devices[0].value);
        }
        break;
      case 'dvs':
        break;
      case 'l':
        if (this.selectItems.locations && this.selectItems.locations.length) {
          this.form
            .get('locationId')
            .setValue(this.selectItems.locations[0].value);
        }
        break;
    }

    switch (this.referencedType) {
      case 'd':
        if (this.selectItems.refDevices && this.selectItems.refDevices.length) {
          this.form
            .get('referencedDeviceId')
            .setValue(this.selectItems.refDevices[0].value);
        }
        break;
      case 'l':
        if (this.selectItems.locations && this.selectItems.locations.length) {
          this.form
            .get('referencedLocationId')
            .setValue(this.selectItems.locations[0].value);
        }
        break;
    }
  }

  get mappingValue() {
    switch (this.sourceType) {
      case 'd':
        return this.deviceTypeMappingValues;

      case 'l':
        return this.locationPropertyMappingValues;

      default:
        return;
    }
  }

  get deviceTypeMappingValues() {
    if (
      this.data &&
      this.data.rawData &&
      this.data.rawData.deviceTypes &&
      this.deviceTypeId
    ) {
      const deviceType = this.data.rawData.deviceTypes.find(
        d => d.id === this.deviceTypeId
      );
      if (
        deviceType &&
        deviceType.mappingValues &&
        deviceType.mappingValues.length
      ) {
        return this.sharedService.createSelectItems(
          deviceType.mappingValues,
          false,
          'key'
        );
      }
    }
    return;
  }

  get locationPropertyMapping() {
    if (
      this.data &&
      this.data.rawData &&
      this.data.rawData.locationProperties &&
      this.locationPropertyTypeId
    ) {
      const locationProperty = this.data.rawData.locationProperties.find(
        d => d.key === this.locationPropertyTypeId
      );
      if (locationProperty) {
        return locationProperty;
      }
    }
    return;
  }

  get locationPropertyMappingValues() {
    if (this.locationPropertyMapping) {
      switch (this.locationPropertyMapping.type) {
        case 'boolean':
          return this.data.selectItems.booleanOption;

        case 'deviceType':
          if (this.data.rawData && this.data.rawData.deviceTypes) {
            const deviceType = this.data.rawData.deviceTypes.find(
              d => d.key === this.locationPropertyMapping.deviceType
            );
            if (
              deviceType &&
              deviceType.mappingValues &&
              deviceType.mappingValues.length
            ) {
              return this.sharedService.createSelectItems(
                deviceType.mappingValues,
                false,
                'key'
              );
            }
          }
          return;
      }
    }
    return;
  }
}

/**
    connector: "AND"
    datetimePropertyTypeId: null
    datetimePropertyValue: null
    deviceId: null
    deviceTypeId: null
    event: null
    locationId: null
    locationPropertyTypeId: "occupied"
    operator: "=="
    parameters: null
    position: 1
    referencedDeviceId: null
    referencedLocationId: null
    referencedLocationPropertyTypeId: null
    referencedType: "v"
    referencedValue: "false"
    requiredValidityPeriod: 0
    sourceType: "l"
    wasTrueOneTimeInPastMs: null
**/
