import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sc-location-summary-item',
  templateUrl: './location-summary-item.component.html',
  styleUrls: ['./location-summary-item.component.scss'],
})
export class LocationSummaryItemComponent implements OnInit, OnChanges {
  @Input()
  icon: string;
  @Input()
  isBoolean: boolean;
  @Input()
  itemClass: string;
  @Input()
  prefix: string;
  @Input()
  suffix: string;
  @Input()
  tooltip: string;
  @Input()
  value: string | number;

  diffValue: number;

  constructor() {}

  ngOnInit() {
    this.diffValue = 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.value &&
      !changes.value.firstChange &&
      changes.value.currentValue &&
      changes.value.previousValue &&
      typeof changes.value.currentValue === 'number' &&
      typeof changes.value.previousValue === 'number'
    ) {
      this.diffValue = changes.value.currentValue - changes.value.previousValue;
    }
  }

  get diffIcon() {
    if (this.diffValue > 0) {
      return 'fas fa-level-up-alt';
    } else if (this.diffValue < 0) {
      return 'fas fa-level-down-alt';
    } else {
      return '';
    }
  }

  // get diffStyle() {
  //   // console.log(this.itemClass);
  //   if (/indigo/gi.test(this.itemClass)) {
  //     return
  //   } else if (/orange/gi.test(this.itemClass)) {
  //     return
  //   }else if (/green/gi.test(this.itemClass)) {

  //   }else if (/yellow/gi.test(this.itemClass)) {
  //     return {color: '#'}
  //   }
  //   return
  // }
}
