import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CustomFunctionParameter,
  CustomFunctionParameterTypes,
  Rule,
  RuleCommand,
  RuleCondition,
  RuleModifier,
  LeftOperandTypes,
  RuleModifierParameter,
  RightOperandTypes,
  OperatorTypes,
  CommandTypes,
} from '../models';
import { RULE_STRUCTURE } from '../config';

@Injectable({
  providedIn: 'root',
})
export class RulesService {
  constructor(private formBuilder: FormBuilder) {}

  initRuleCondition(isTemplate = false): RuleCondition {
    return {
      key: isTemplate ? new Date().getTime() + '' : null,
      left: {
        type: null,
      },
      operator: {
        type: null,
      },
      right: {
        type: null,
      },
    };
  }

  initRuleCommand(isTemplate = false): RuleCommand {
    return { key: isTemplate ? new Date().getTime() + '' : null, type: null };
  }

  initRuleForm(isTemplate = false): FormGroup {
    const conditions = {
      AND: [
        {
          left: { type: null },
          operator: { type: null },
          right: { type: null },
        },
      ],
    };
    const commands = [{ type: null }];
    const fields: any = {
      idx: { value: null, disabled: true },
      name: [null, Validators.required],
      commands: [commands, Validators.required],
      conditions: [conditions, Validators.required],
      description: null,
      automation_id: null,
      rule_id: { value: null, disabled: true },
      affected_devices: null,
      affected_locations: null,
      view: null,
    };
    if (isTemplate) {
      fields.rule_category_id = [null, Validators.required];
    }
    return this.formBuilder.group(fields);
  }

  initRuleConditionRootForm(conenctor: 'AND' | 'OR' = 'AND'): FormGroup {
    const fields: any = {
      [conenctor]: this.formBuilder.array([this.initRuleConditionForm()]),
    };
    return this.formBuilder.group(fields);
  }

  initLeftOperandForm(type?: string, isTemplate = false): FormGroup {
    const fields: any = {
      type: [null, Validators.required],
      name: null,
      is_source_editable: false,
    };
    if (type) {
      switch (type) {
        case LeftOperandTypes.DeviceDateTime:
        case LeftOperandTypes.DeviceValue:
          fields.device_type = null;
          fields.modifiers = this.formBuilder.array([]);
          if (!isTemplate) {
            fields.device_idx = [null, Validators.required];
          }
          break;
        case LeftOperandTypes.LocationPropertyDateTime:
        case LeftOperandTypes.LocationPropertyValue:
          fields.property = [null, Validators.required];
          if (!isTemplate) {
            fields.location_idx = [null, Validators.required];
          }
          break;
        case LeftOperandTypes.FunctionValue:
          fields.custom_function_idx = [null, Validators.required];
          fields.variables = this.formBuilder.array([]);
          break;
        case LeftOperandTypes.GlobalValue:
          fields.custom_global_idx = [null, Validators.required];
          break;
      }
    }
    return this.formBuilder.group(fields);
  }

  initOperatorForm(type?: string): FormGroup {
    const fields: any = {
      type: [null, Validators.required],
      included_past: null,
      is_included_past_editable: false,
    };
    if (type === OperatorTypes.ChangedAtLeastNtimes || type === OperatorTypes.ChangedMaxNtimes) {
      fields.previous_rule_trigger_as_included_past = true;
    } else {
      fields.min_validity_period = null;
      fields.is_min_validation_period_editable = null;
    }
    return this.formBuilder.group(fields);
  }

  initRightOperandForm(type?: string, isTemplate = false): FormGroup {
    const fields: any = {
      type: [null, Validators.required],
      value: [null, Validators.required],
      name: null,
      is_source_editable: false,
    };
    switch (type) {
      case RightOperandTypes.DatetimeAbsolute:
        fields.pattern = [null, Validators.required];
        break;
      case RightOperandTypes.DatetimeRelative:
        fields.mode = [null, Validators.required];
        fields.unit = [null, Validators.required];
        break;
      case RightOperandTypes.Device:
        fields.device_type = [null, Validators.required];
        fields.modifiers = this.formBuilder.array([]);
        if (isTemplate) {
          fields.value = null;
        }
        break;
      // case RightOperandTypes.Value:
      //   break;
    }
    return this.formBuilder.group(fields);
  }

  initRuleConditionForm(): FormGroup {
    const fields: any = {
      left: this.initLeftOperandForm(),
      operator: this.initOperatorForm(),
      right: this.initRightOperandForm(),
    };
    return this.formBuilder.group(fields);
  }

  initRuleCommandForm(type: string, isTemplate?: boolean): FormGroup {
    const fields: any = {
      type: [type, Validators.required],
      action: [null, Validators.required],
      target: [null, Validators.required],
      target_type: null,
      value: null,
      delay: 0,
      timeout: 0,
      repeat: false,
      repetition_pause: 0,
      ignore_current_value: true,
      modifiers: this.formBuilder.array([]),
      variables: this.formBuilder.array([]),
      name: null,
      is_target_editable: false,
      is_value_editable: false,
      is_delay_editable: false,
      is_timeout_editable: false,
      is_repeat_editable: false,
      is_repetition_pause_editable: false,
      value_type: 'fixed',
      custom_function_idx: null,
      is_source_editable: false,
      function_name: null,
    };
    switch (type) {
      case CommandTypes.DeviceValueStorage:
      case CommandTypes.TriggerDevice:
        if (isTemplate) {
          fields.target = null;
          fields.target_type = [null, Validators.required];
        }
        break;
      case CommandTypes.RuleBlocker:
      case CommandTypes.TaskQueue:
        if (isTemplate) {
          fields.target = null;
        }
        break;
    }
    return this.formBuilder.group(fields);
  }

  initModifierForm(hasParameter = false, isTemplate?: boolean): FormGroup {
    const fields: any = {
      type: [null, Validators.required],
      operator: [null, Validators.required],
      value: [null, Validators.required],
    };
    if (hasParameter) {
      fields.parameters = this.formBuilder.array([]);
    }
    if (isTemplate) {
      fields.value = null;
    }
    return this.formBuilder.group(fields);
  }

  setModifierForm(form: FormGroup, data: RuleModifier[]): void {
    const formGroups = data.map((item) => {
      const hasParameter = item.parameters && item.parameters.length ? true : false;
      const fg = this.initModifierForm(hasParameter);
      fg.patchValue(item);
      if (hasParameter) {
        this.setModifierParametersForm(fg, item.parameters);
      }
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('modifiers', formArray);
    form.updateValueAndValidity();
  }

  initModifierCustomFunctionParameterForm(type: string, isTemplate?: boolean): FormGroup {
    const fields: any = {
      id: [null, Validators.required],
      label: [null, Validators.required],
      type: [null, Validators.required],
    };
    switch (type) {
      case CustomFunctionParameterTypes.CustomGlobalValue:
        if (isTemplate) {
          fields.custom_global_idx = null;
        } else {
          fields.custom_global_idx = [null, Validators.required];
        }
        break;
      case CustomFunctionParameterTypes.LatestDhlValue:
        if (isTemplate) {
          fields.device_idx = null;
          fields.device_type = null;
        } else {
          fields.device_idx = [null, Validators.required];
          fields.device_type = [null, Validators.required];
        }
        break;
      case CustomFunctionParameterTypes.LatestLhlValue:
        if (isTemplate) {
          fields.location_idx = null;
          fields.property = null;
        } else {
          fields.location_idx = [null, Validators.required];
          fields.property = [null, Validators.required];
        }
        break;
    }
    return this.formBuilder.group(fields);
  }

  setModifierParametersForm(form: FormGroup, data: RuleModifierParameter[], isTemplate?: boolean): void {
    const formGroups = data.map((item) => {
      const fg = this.initModifierCustomFunctionParameterForm(item.type, isTemplate);
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('parameters', formArray);
    form.updateValueAndValidity();
  }

  removeModifierParametersForm(form: FormGroup): void {
    form.removeControl('parameters');
    form.updateValueAndValidity();
  }

  initFunctionVariableForm(type: string, isTemplate = false): FormGroup {
    const fields: any = {
      id: [null, Validators.required],
      label: [null, Validators.required],
      type: [null, Validators.required],
    };
    switch (type) {
      case CustomFunctionParameterTypes.CustomGlobalValue:
        fields.custom_global_idx = [null, Validators.required];
        break;
      case CustomFunctionParameterTypes.LatestDhlValue:
        fields.device_type = [null, Validators.required];
        if (isTemplate) {
          fields.device_idx = null;
        } else {
          fields.device_idx = [null, Validators.required];
        }
        break;
      case CustomFunctionParameterTypes.LatestLhlValue:
        fields.property = [null, Validators.required];
        if (isTemplate) {
          fields.location_idx = null;
        } else {
          fields.location_idx = [null, Validators.required];
        }
        break;
    }
    return this.formBuilder.group(fields);
  }

  setFunctionVariablesForm(form: FormGroup, data: CustomFunctionParameter[], isTemplate = false): void {
    const formGroups = data.map((item) => {
      const fg = this.initFunctionVariableForm(item.type, isTemplate);
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('variables', formArray);
    form.updateValueAndValidity();
  }

  initReportVariablesForm() {
    const fields: any = {
      display_name: [null, Validators.required],
      variable_name: [null, Validators.required],
      type: [null, Validators.required],
      value: [null, Validators.required],
    };
    return this.formBuilder.group(fields);
  }

  setReportVariablesForm(form: FormGroup, data: any[]): void {
    const formGroups = data.map((item) => {
      const fg = this.initReportVariablesForm();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('variables', formArray);
    form.updateValueAndValidity();
  }

  initUserTaskVariablesForm() {
    const fields: any = {
      display_name: [null, Validators.required],
      variable_name: [null, Validators.required],
      type: [null, Validators.required],
      value: [null, Validators.required],
    };
    return this.formBuilder.group(fields);
  }

  setUserTaskVariablesForm(form: FormGroup, data: any[]): void {
    const formGroups = data.map((item) => {
      const fg = this.initUserTaskVariablesForm();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('variables', formArray);
    form.updateValueAndValidity();
  }

  getStructure(key?: string) {
    if (key) {
      return RULE_STRUCTURE[key];
    }
    return RULE_STRUCTURE;
  }

  getOperandStructures(key?: string): any {
    const structure = this.getStructure();
    if (structure && structure.operands) {
      if (key) {
        return structure.operands.find((o) => o.id === key);
      }
      return structure.operands;
    }
    return;
  }

  getOperatorStructures(key?: string): any {
    const structure = this.getStructure();
    if (structure && structure.operators) {
      if (key) {
        return structure.operators.find((o) => o.id === key);
      }
      return structure.operators;
    }
    return;
  }

  getAbsoluteDatetimePatternsStructures(key?: string): any {
    const structure = this.getStructure();
    if (structure && structure.absolute_datetime_patterns) {
      if (key) {
        return structure.absolute_datetime_patterns.find((o) => o.id === key);
      }
      return structure.absolute_datetime_patterns;
    }
    return;
  }
}
