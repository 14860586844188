import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GuestRequest } from './guest-request';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class GuestRequestsService {
  private apiUrl = config().apiUrl + '/guestRequests';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  private verify(data: any): GuestRequest {
    const verifyData = { ...data };

    delete verifyData.id;
    delete verifyData.createdAt;
    delete verifyData.createdBy;
    delete verifyData.updatedAt;
    delete verifyData.updatedBy;

    return verifyData;
  }

  getGuestRequest(id: string) {
    return this.httpClient.get(this.apiUrl + '/' + id);
  }

  getGuestRequests(filters: any) {
    const queryParams = this.sharedService.urlQueryParamsCreator(filters);
    return this.httpClient.get(this.apiUrl + queryParams);
  }

  createGuestRequest(data: GuestRequest) {
    return this.httpClient.post(this.apiUrl, this.verify(data));
  }

  updateGuestRequest(id: string, data: GuestRequest) {
    return this.httpClient.patch(this.apiUrl + '/' + id, this.verify(data));
  }

  deleteGuestRequest(id: string) {
    return this.httpClient.patch(this.apiUrl + '/' + id, {
      isActive: false,
      isDeleted: true
    });
  }
}
