<section *ngIf="fetchingState === 0; else loading">
  <div class="layout" [formGroup]="deviceForm">
    <sc-tree-multi-select
      controlName="locations"
      [form]="deviceForm"
      label="{{ 'LOCATIONS' | translate }}"
      [options]="selectedItems?.locations"
      [propagateSelectionDown]="false"
    ></sc-tree-multi-select>
    <div class="devices-form__header">
      <div class="label">{{ "DEVICES" | translate }}</div>
      <button
        type="button"
        pButton
        [icon]="'fa fa-fw fa-plus'"
        (click)="addDevice()"
      ></button>
    </div>

    <div class="content-container content-container--devices">
      <div class="accordion">
        <div
          class="accordion__item"
          *ngFor="let device of devices.controls; index as idx"
        >
          <div class="accordion__item__header">
            <div class="accordion__item__header_left">
              <i
                class="fa"
                [ngClass]="{
                  'fa-chevron-right': !device.expanded,
                  'fa-chevron-down': device.expanded
                }"
                (click)="expandDevice(idx)"
              ></i>
            </div>

            <div class="accordion__item__header_content">
              {{ device?.get("name")?.value }}
            </div>

            <div class="accordion__item__header_right">
              <button
                class="minus-btn"
                type="button"
                pButton
                [icon]="'fa fa-fw fa-minus'"
                (click)="removeDevice(idx)"
              ></button>
            </div>
          </div>

          <div
            class="accordion__item_body"
            *ngIf="device.get('expanded').value"
          >
            <div [formGroup]="device">
              <div>
                <div class="sc-form-label">
                  <label>{{ "MODEL" | translate }}</label>
                  <span class="red-text"> *</span>
                </div>
                <p-dropdown
                  formControlName="deviceModelId"
                  [options]="selectedItems?.deviceModels"
                  placeholder="{{ 'SELECT' | translate }}"
                  (onChange)="onDeviceModelSelect($event.value, idx)"
                  filter="true"
                  [style]="{ width: '100%' }"
                  appendTo="body"
                ></p-dropdown>
              </div>

              <sc-text-input
                controlName="name"
                label="{{ 'DEVICE_NAME' | translate }}"
                [form]="device"
              ></sc-text-input>

              <table *ngIf="device.get('properties').length > 0" class="table">
                <thead class="table__head">
                  <tr>
                    <th style="width: 200px">{{ "PROPERTY" | translate }}</th>
                    <th style="width: 200px">{{ "NAME" | translate }}</th>
                    <th style="width: 100px">{{ "ENABLED" | translate }}</th>
                    <th style="width: 100px">{{ "HIDDEN" | translate }}</th>
                    <th style="width: 150px">
                      {{ "EXCLUDE_FROM_LOCATION_PROPERTY" | translate }}
                    </th>
                    <th style="width: 150px">
                      {{ "MIN_DIFFERENCE_THRESHOLD" | translate }}
                    </th>
                  </tr>
                </thead>

                <tbody formArrayName="properties" class="table__body">
                  <tr *ngFor="let ctrl of device.get('properties').controls">
                    <td>
                      <span *ngIf="!siblings[ctrl?.value?.deviceTypeId]">
                        {{ getPropertyName(ctrl?.value?.deviceTypeId) }}
                      </span>

                      <div *ngIf="siblings[ctrl?.value?.deviceTypeId]">
                        <sc-dropdown
                          controlName="deviceTypeId"
                          [form]="ctrl"
                          [showLabel]="false"
                          [options]="siblings[ctrl?.value?.deviceTypeId]"
                          [showClear]="false"
                        ></sc-dropdown>
                      </div>
                    </td>

                    <td>
                      <sc-text-input
                        controlName="description"
                        [form]="ctrl"
                        [showLabel]="false"
                      ></sc-text-input>
                    </td>

                    <td class="text-center">
                      <sc-checkbox-input
                        controlName="isActive"
                        [form]="ctrl"
                        [showLabel]="false"
                      ></sc-checkbox-input>
                    </td>

                    <td class="text-center">
                      <sc-checkbox-input
                        controlName="isHidden"
                        [form]="ctrl"
                        [showLabel]="false"
                      ></sc-checkbox-input>
                    </td>

                    <td class="text-center">
                      <sc-checkbox-input
                        controlName="excludeFromLHL"
                        [form]="ctrl"
                        [showLabel]="false"
                      ></sc-checkbox-input>
                    </td>

                    <td>
                      <sc-text-input
                        controlName="diffDetectionFactor"
                        [form]="ctrl"
                        [showLabel]="false"
                      ></sc-text-input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="footer">
  <div class="footer-left">
    <button
      *ngIf="step === 1"
      type="button"
      pButton
      class="ui-button-secondary"
      (click)="cancel()"
      [label]="'CANCEL' | translate"
    ></button>
    <button
      *ngIf="step > 1"
      type="button"
      pButton
      class="ui-button-secondary"
      (click)="onBackClick()"
      [label]="'BACK' | translate"
    ></button>
  </div>
  <div class="footer-right">
    <button
      *ngIf="step !== totalSteps && totalSteps > 1"
      type="button"
      pButton
      (click)="onNextClick()"
      [label]="'NEXT' | translate"
      [disabled]="!allowNext"
      class="ui-button-secondary"
    ></button>
    <sc-submit-button
      *ngIf="step === totalSteps"
      buttonClass="ui-button-success"
      (submitted)="submit()"
      [disabled]="deviceForm.invalid"
      [label]="'SAVE' | translate"
    ></sc-submit-button>
  </div>
</section>

<ng-template #loading>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
