import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { SelectItem } from 'primeng/api';
import { DeviceTriggerCommandsService } from '../services/device-trigger-commands.service';
import { SharedService } from '@shared/shared.service';
import { LiveRequestsService } from '@widgets/live-requests/services/live-requests.service';
import { sortBy } from 'lodash';

@Component({
  selector: 'sc-device-trigger-action-form',
  templateUrl: 'device-trigger-action-form.component.html',
})
export class DeviceTriggerActionFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: any;
  form: FormGroup;
  errorMessage: string;
  commandList: SelectItem[] = [];
  serviceProviders: SelectItem[] = [];
  isSubmitting: boolean;
  userVariant: string;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private deviceTriggerCommandsService: DeviceTriggerCommandsService,
    private sharedService: SharedService,
    private liveRequestsService: LiveRequestsService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.formData = this.data;

    // get user variant
    this.subscribers.userVariant = this.store
      .pipe(
        select(fromStore.getUserVariant),
        filter((userVariant) => (userVariant ? true : false)),
        take(1)
      )
      .subscribe((result) => {
        this.userVariant = result;
      });

    // set command actions
    if (this.formData && this.formData.commandActions && this.formData.commandActions.length) {
      this.formData.commandActions.forEach((ca) => {
        if (ca.isActive) {
          this.commandList.push({
            label: ca.commandActionId,
            value: {
              commandActionId: ca.commandActionId,
              parameters: ca.parameters,
            },
          });
        }
      });
      this.commandList = sortBy(this.commandList, 'label');
    }

    this.initForm();
  }

  ngOnDestroy() {
    // Clear subscribes
    this.sharedService.clearSubscribes(this.subscribers);
  }

  initForm() {
    this.form = this.formBuilder.group({
      commandAction: [null, Validators.required],
      parameters: this.formBuilder.array([]),
      service: [null, Validators.required],
    });

    this.setServiceProviders();

    this.form.get('commandAction').valueChanges.subscribe((val) => {
      if (val) {
        this.setParametersFormArray(val.parameters);
      }
    });
  }

  setServiceProviders() {
    // Set service providers
    this.serviceProviders = [];

    switch (this.formData.automationVersion) {
      case 'v1rm':
        this.serviceProviders.push({ label: 'Pushy', value: 'pushy' });
        this.form.get('service').setValue('pushy');
        this.form.get('service').disable();
        break;
      case 'ceos':
      case 'v4':
        this.serviceProviders.push({ label: 'CloudMQTT', value: 'cloudmqtt' });
        this.form.get('service').setValue('cloudmqtt');
        this.form.get('service').disable();
        break;
      default:
        this.serviceProviders.push({ label: 'FCM', value: 'fcm' });
        this.serviceProviders.push({ label: 'Pushy', value: 'pushy' });
        this.form.patchValue({ service: 'fcm' });
        break;
    }
  }

  // PARAMETER FORM
  initParameterFormGroup() {
    return this.formBuilder.group({
      key: [null, Validators.required],
      value: null,
    });
  }

  setParametersFormArray(data: any[]) {
    const formGroups = data.map((item) => {
      const f = this.initParameterFormGroup();
      f.patchValue({
        key: item.parameterId,
        value: item.defaultValues,
      });
      return f;
    });
    const formArray = this.formBuilder.array(formGroups);
    this.form.setControl('parameters', formArray);
  }

  get parameters(): FormArray {
    return this.form.get('parameters') as FormArray;
  }

  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    switch (this.formData.automationVersion) {
      case 'ceos':
      case 'v1rm':
      case 'v4':
        this.triggerActionForV1RM();
        break;
      default:
        this.triggerAction();
        break;
    }
  }

  triggerAction() {
    const option = {
      apiKey: this.formData.apiKey,
      automationId: this.formData.automationId,
      deviceId: this.formData.deviceId,
      commandAction: this.form.value.commandAction.commandActionId,
      parameters: this.form.value.parameters,
    };

    let service = this.deviceTriggerCommandsService.triggerCommandAction(option);
    if (this.form.value.service === 'pushy') {
      service = this.deviceTriggerCommandsService.triggerCommandActionWithPushy(option);
    }

    this.subscribers.triggeringCommand = service.subscribe(
      (result: any) => {
        // show notification
        const params = {
          device: this.formData.description || this.formData.deviceId,
          action: this.form.value.commandAction.commandActionId,
        };
        const text = this.sharedService.getTranslation('DEVICE_TRIGGER_NOTIFICATION_TEXT', params);
        const title = this.sharedService.getTranslation('DEVICE_TRIGGER_NOTIFICATION_TITLE');
        this.sharedService.notify(title, text, 'success');

        // close the form and return result
        this.isSubmitting = false;
        this.onClose.emit(result);
      },
      (error: any) => {
        // display error message and unlock the form
        this.errorMessage = error;
        this.isSubmitting = false;
      }
    );
  }

  triggerActionForV1RM() {
    const formValue = this.form.value;
    const options: any = {
      action: 'triggerDeviceCommandAction',
      apiKey: this.formData.apiKey,
      automationId: this.formData.automationId,
      commandAction: formValue.commandAction.commandActionId,
      parameters: formValue.parameters,
    };

    if (this.userVariant === 'ceos') {
      if (this.formData.deviceTypeKey === 'a' && formValue.commandAction.commandActionId === 'config_update') {
        options.action = 'pullConfig';
        delete options.commandAction;
        delete options.parameters;
      }
    }

    if (this.formData.deviceId) {
      options.deviceId = this.formData.deviceId;
    } else if (this.formData.deviceIdx) {
      options.deviceIdx = this.formData.deviceIdx;
    }

    this.subscribers.triggeringCommand = this.liveRequestsService.sendLiveRequest(options).subscribe(
      (result: any) => {
        // show notification
        const params = {
          device: this.formData.description || this.formData.deviceId,
          action: this.form.value.commandAction.commandActionId,
        };
        const text = this.sharedService.getTranslation('DEVICE_TRIGGER_NOTIFICATION_TEXT', params);
        const title = this.sharedService.getTranslation('DEVICE_TRIGGER_NOTIFICATION_TITLE');
        this.sharedService.notify(title, text, 'success');

        // close the form and return user info
        this.isSubmitting = false;
        this.onClose.emit(result.data);
      },
      (error: any) => {
        // display error message and unlock the form
        this.errorMessage = error;
        this.isSubmitting = false;
      }
    );
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
