<div [formGroup]="form" class="ui-g">
  <!-- CONNECTOR FIELD -->
  <div class="ui-g-1">
    <span *ngIf="formId > 0"> {{ form.value?.connector }} </span>
  </div>

  <!-- SOURCE -->
  <div class="ui-g-3">
    <ng-container [ngSwitch]="form.value?.sourceType">
      <!-- DEVICE_ID FIELD -->
      <ng-container *ngSwitchCase="'d'">
        <sc-dropdown
          controlName="deviceId"
          [form]="form"
          label="{{ 'DEVICE' | translate }}"
          [options]="selectItems['devices']"
          [showLabel]="false"
        ></sc-dropdown>
      </ng-container>

      <!-- LOCATION_ID FIELD -->
      <ng-container *ngSwitchCase="'l'">
        <div class="ui-g">
          <div class="ui-g-6 ui-g-nopad">
            <sc-dropdown
              controlName="locationId"
              [form]="form"
              label="{{ 'LOCATION' | translate }}"
              [options]="selectItems['locations']"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <div class="ui-g-6 ui-g-nopad text-center">
            {{ form.value?.locationPropertyTypeId }}
          </div>
        </div>
      </ng-container>

      <!-- DATETIME_ID FIELD -->
      <ng-container *ngSwitchCase="'dt'">
        {{ form.value?.datetimePropertyTypeId }}
      </ng-container>
    </ng-container>
  </div>

  <!-- OPERATOR -->
  <div class="ui-g-1">{{ form.value?.operator }}</div>

  <!-- REFERENCED -->
  <div class="ui-g-3 ui-g-nopad">
    <!-- DEVICES OR LOCATIONS -->
    <div class="ui-g" *ngIf="!form.value?.datetimePropertyTypeId">
      <div class="ui-g-6" [ngSwitch]="form.value?.referencedType">
        <!-- REFERENCED_TYPE=DEVICE -->
        <div *ngSwitchCase="'d'">
          <sc-dropdown
            controlName="referencedDeviceId"
            [form]="form"
            label="{{ 'REFERENCED' | translate }} {{ 'DEVICE' | translate }}"
            [options]="selectItems['refDevices']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <!-- REFERENCED_TYPE=LOCATION -->
        <div *ngSwitchCase="'l'">
          <sc-dropdown
            controlName="referencedLocationId"
            [form]="form"
            label="{{ 'REFERENCED' | translate }} {{ 'LOCATION' | translate }}"
            [options]="selectItems['locations']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <!-- REFERENCED_TYPE=VALUE -->
        <div *ngSwitchCase="'v'">{{ 'SELF' | translate }}</div>
      </div>

      <!-- REFERENCED_VALUE -->
      <div class="ui-g-6" [ngSwitch]="form.value?.referencedType">
        <!-- REFERENCED_DEVICE -->
        <div *ngSwitchCase="'d'">
          {{ 'DEVICE' | translate }} {{ 'VALUE' | translate }}
        </div>

        <!-- REFERENCED_LOCATION -->
        <div *ngSwitchCase="'l'">
          {{ form.value?.referencedLocationPropertyTypeId }}
        </div>

        <!-- REFERENCED_VALUE FIELD -->
        <div *ngSwitchCase="'v'">
          <div *ngIf="mappingValue">
            <sc-dropdown
              controlName="referencedValue"
              [form]="form"
              label="{{ 'REFERENCED_VALUE' | translate }}"
              [options]="mappingValue"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <div *ngIf="!mappingValue">
            <sc-text-input
              controlName="referencedValue"
              [form]="form"
              label="{{ 'REFERENCED_VALUE' | translate }}"
              [showLabel]="false"
            ></sc-text-input>
          </div>
        </div>
      </div>
    </div>

    <!-- DATETIME -->
    <div class="ui-g" *ngIf="form.value?.datetimePropertyTypeId">
      <div class="ui-g-6">
        <sc-text-input
          controlName="datetimePropertyValue"
          [form]="form"
          label="{{ 'DATE_TIME' | translate }}"
          [showLabel]="false"
        ></sc-text-input>
      </div>
    </div>
  </div>
</div>
