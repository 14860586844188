import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeviceModel } from '@widgets/device-models-2/models/device-model';
import { DeviceModelProperty } from '@widgets/device-models-2/models/device-model-property';
import { SharedService } from '@shared/shared.service';
// import { config } from '@app/config';

interface CustomDeviceModel extends DeviceModel {
  brandName?: string;
  protocolName?: string;
  [key: string]: any;
}

@Component({
  selector: 'sc-device-model-item',
  templateUrl: './device-model-item.component.html',
  styleUrls: ['./device-model-item.component.scss'],
})
export class DeviceModelItemComponent implements OnInit {
  @Input()
  data: CustomDeviceModel;

  @Output()
  selected = new EventEmitter<any>();

  isOpened = false;
  productImage: string;
  properties: DeviceModelProperty[] = [];

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    // if (this.data.productImage) {
    //   this.productImage = config().s3Url + '/' + this.data.productImage;
    // }

    if (this.data && this.data.properties) {
      this.properties = this.data.properties as DeviceModelProperty[];
    }
  }

  onSelectDevice() {
    this.selected.emit(this.data);
  }
}
