import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as localeActions from '../actions/locales.action';
import { LocalesService } from '@widgets/locales/services/locales.service';
import { SharedService } from '@shared/shared.service';

@Injectable()
export class LocalesEffects {
  constructor(private action$: Actions, private localesService: LocalesService, private sharedService: SharedService) {}

  @Effect()
  loadLocale$ = this.action$.pipe(
    ofType(localeActions.LOAD_LOCALE),
    map((action: localeActions.LoadLocale) => action.payload),
    switchMap((payload) => {
      return this.localesService.get(payload).pipe(
        map((result: any) => new localeActions.LoadLocaleSuccess(result.data)),
        catchError((error) => of(new localeActions.LoadLocaleFail(error)))
      );
    })
  );

  @Effect()
  loadLocales$ = this.action$.pipe(
    ofType(localeActions.LOAD_LOCALES),
    switchMap(() => {
      return this.localesService.get().pipe(
        map((result: any) => new localeActions.LoadLocalesSuccess(result.data)),
        catchError((error) => of(new localeActions.LoadLocalesFail(error)))
      );
    })
  );

  @Effect()
  createLocale$ = this.action$.pipe(
    ofType(localeActions.CREATE_LOCALE),
    map((action: localeActions.CreateLocale) => action.payload),
    switchMap((locale) => {
      return this.localesService.create(locale).pipe(
        map((result: any) => {
          this.sharedService.notify('Locale', 'Create Success', 'success');
          return new localeActions.CreateLocaleSuccess(result.data);
        }),
        catchError((error) => of(new localeActions.CreateLocaleFail(error)))
      );
    })
  );

  @Effect()
  updateLocale$ = this.action$.pipe(
    ofType(localeActions.UPDATE_LOCALE),
    map((action: localeActions.UpdateLocale) => action.payload),
    switchMap((locale) => {
      return this.localesService.update(locale.id, locale).pipe(
        map((result: any) => {
          this.sharedService.notify('Locale', 'Update Success', 'success');
          return new localeActions.UpdateLocaleSuccess(locale);
        }),
        catchError((error) => of(new localeActions.UpdateLocaleFail(error)))
      );
    })
  );

  @Effect()
  deleteLocale$ = this.action$.pipe(
    ofType(localeActions.DELETE_LOCALE),
    map((action: localeActions.DeleteLocale) => action.payload),
    switchMap((locale) => {
      return this.localesService.delete(locale.id).pipe(
        map((result: any) => {
          this.sharedService.notify('Locale', 'Delete Success', 'success');
          return new localeActions.DeleteLocaleSuccess(locale);
        }),
        catchError((error) => of(new localeActions.DeleteLocaleFail(error)))
      );
    })
  );
}
