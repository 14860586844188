import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { DeviceType } from '../models/device-type';

import { DeviceTypeFormsService } from '../services/device-type-forms.service';
import { DeviceTypesService } from '../services/device-types.service';
import { SharedService } from '@shared/shared.service';

// import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
// import { DependencyWarningComponent } from '@widgets/dependency-warning/dependency-warning.component';

@Component({
  selector: 'sc-device-type-form',
  templateUrl: './device-type-form.component.html'
})
export class DeviceTypeFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: DeviceType;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;

  isSubmitting = false;
  fetchingState = 0;
  chartTypes: SelectItem[];

  private subscribers: { [key: string]: any } = {};
  // private warningDialog: DynamicDialogRef;

  constructor(
    private deviceTypeFormsService: DeviceTypeFormsService,
    private deviceTypesService: DeviceTypesService,
    private sharedService: SharedService,
    // private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.chartTypes = [
      { label: 'Line Chart', value: 'line' },
      { label: 'Bar Chart', value: 'bar' },
      { label: 'Doughnut Chart', value: 'doughnut' },
      { label: 'Pie Chart', value: 'pie' }
    ];

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchDeviceType(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDeviceType(id: number) {
    this.fetchingState++;
    this.subscribers.getDeviceType = this.deviceTypesService
      .getDeviceType(id)
      .subscribe({
        next: (result: any) => {
          this.formData = result.data;
          this.formData.commandActions = this.sharedService.jsonParse(
            this.formData.commandActions
          );
          this.formData.protocolMapping = this.sharedService.jsonParse(
            this.formData.protocolMapping
          );

          this.form.patchValue(this.formData);

          this.deviceTypeFormsService.setActionFormArray(
            this.form,
            this.formData.commandActions
          );

          this.deviceTypeFormsService.setMappingValueFormArray(
            this.form,
            this.formData.mappingValues
          );

          this.fetchingState--;
        }
      });
  }

  initForm() {
    this.form = this.deviceTypeFormsService.initDeviceTypeFrom();
  }

  // ACTION FORM
  addAction() {
    const control = this.form.controls.commandActions as FormArray;
    control.push(this.deviceTypeFormsService.initActionFormGroup());
  }

  removeAction(i: number) {
    const control = this.form.controls.commandActions as FormArray;
    control.removeAt(i);
  }

  get commandActions(): FormArray {
    return this.form.controls.commandActions as FormArray;
  }

  addMappingValues() {
    const control = this.form.controls.mappingValues as FormArray;
    const form = this.deviceTypeFormsService.initMappingValueFormGroup();
    form.removeControl('id');
    control.push(form);
  }

  removeMappingValues(i: number) {
    const control = this.form.controls.mappingValues as FormArray;
    control.removeAt(i);
  }

  get mappingValues(): FormArray {
    return this.form.controls.mappingValues as FormArray;
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      // call api
      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create() {
    const formData = this.form.value;
    this.subscribers.create = this.deviceTypesService
      .createDeviceType(formData)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'CREATE_FAIL')
      });
  }

  private update() {
    const formData = this.form.value;
    this.subscribers.update = this.deviceTypesService
      .updateDeviceType(this.formData.id, formData)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'UPDATE_FAIL')
      });
  }

  delete(force = false) {
    this.subscribers.delete = this.deviceTypesService
      .deleteDeviceType(this.formData.id, force)
      .subscribe({
        // next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        next: (result: any) => {
          if (result.warning) {
            // this.sharedService.deleteWarningHandler(
            //   this.data,
            //   result.warning,
            //   this.delete.bind(this)
            // );
            this.subscribers.delete = this.sharedService
              .deleteWarningHandler(this.data, result.warning)
              .subscribe((confirm: any) => {
                if (confirm === true) {
                  this.delete(true);
                }
              });
          } else {
            this.apiCallSuccess('DELETE_SUCCESS', result);
          }
        },
        error: this.apiCallError.bind(this, 'DELETE_FAIL')
      });
  }

  // private deleteWarningHandler(dependencies) {
  //   this.warningDialog = this.dialogService.open(DependencyWarningComponent, {
  //     header: this.sharedService.getTranslation('DEPENDENCY_WARNING_TITLE'),
  //     data: { source: this.data, dependencies }
  //   });

  //   this.warningDialog.onClose.subscribe((result: any) => {
  //     if (result === true) {
  //       this.delete(true);
  //     }
  //   });
  // }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE_TYPE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
