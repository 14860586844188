import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLocations from '../reducers/locations.reducer';
import * as fromRouter from '../selectors/router.selector';

export const getLocationsState = createFeatureSelector<fromLocations.LocationState>('locations');

export const getLocationEntities = createSelector(getLocationsState, fromLocations.getLocationEntities);

export const getAllLocations = createSelector(getLocationEntities, (entities) =>
  entities ? Object.keys(entities).map((id) => entities[id]) : []
);

export const getLocationsError = createSelector(getLocationsState, fromLocations.getLocationsError);

export const getLocationsLoaded = createSelector(getLocationsState, fromLocations.getLocationsLoaded);

export const getLocationsLoadedAt = createSelector(getLocationsState, fromLocations.getLocationsLoadedAt);

export const getLocationsLoading = createSelector(getLocationsState, fromLocations.getLocationsLoading);

export const getSelectedLocationId = createSelector(getLocationsState, fromLocations.getSelectedLocationId);

export const getSelectedLocation = createSelector(
  getLocationEntities,
  getSelectedLocationId,
  (entities, id) => entities && entities[id]
);

export const getLocationDetials = createSelector(
  getLocationEntities,
  fromRouter.getRouterStateUrlParams,
  fromRouter.getRouterStateUrlQueryParams,
  (entities, params, query) => {
    if (!entities) {
      return;
    }

    if (params && !isNaN(params.pageParams)) {
      if (params.pageId === 'locations' || params.pageId === 'floorplan') {
        return entities[+params.pageParams];
      }
    }

    return;
  }
);
