import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ModalConfig } from '@sc/modal/modal-config';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-tech-alerts',
  templateUrl: './tech-alerts.component.html',
})
export class TechAlertsComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  alertClass: string;
  alertText: string;
  detailsModal: ModalConfig;
  isFetching = false;
  isFirstTime = true;
  // titleText = 'TECH_ALERTS';
  subTitleText = 'REPORTED_AUTOMATICALLY';
  widgetName: string = '';

  private data: any;
  private intervals: { [key: string]: any } = {};
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private widgetService: SCWidgetService,
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.selectedSite = this.store.select(fromStore.getSelectedSite).subscribe({
      next: (result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.isFirstTime = true;
          this.fetchDataset();
          this.intervals.autoUpdate = setInterval(() => this.fetchDataset(), 30000);
        }
      },
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (this.isFetching || !this.selectedSite || !this.selectedSite.id) {
      return;
    }

    this.isFetching = true;
    this.subscribers.fetchDataset = this.widgetService.getTechAlerts(this.selectedSite.id).subscribe(
      (result: any) => {
        this.data = result.data;
        this.setDetailsModal();
        this.setAlertDetails();
        this.isFetching = false;
        this.isFirstTime = false;
      },
      (error: any) => {
        this.isFetching = false;
      }
    );
  }

  private setDetailsModal() {
    this.detailsModal = {
      name: 'TechAlertModal',
      options: {
        modalTitle: 'TECH_ALERT_MODAL_TITLE',
        fullScreen: true,
      },
      data: this.data,
    };
  }

  private setAlertDetails() {
    const amount = (this.data && this.data.length) || 0;

    if (amount > 10) {
      this.alertClass = 'red';
    } else if (amount > 0) {
      this.alertClass = 'orange lighten-1';
    } else {
      this.alertClass = 'grey lighten-2';
    }

    this.alertText = amount;
  }
}
