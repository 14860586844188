import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { DevicesModule } from '@widgets/devices/devices.module';

import { CustomSummaryFormComponent } from './custom-summary-form/custom-summary-form.component';
import { LocationActionCellComponent } from './location-action-cell/location-action-cell.component';
import { LocationDetailHeaderComponent } from './location-detail-header/location-detail-header.component';
import { LocationDevicesTableComponent } from './location-devices-table/location-devices-table.component';
import { LocationFormComponent } from './location-form/location-form.component';
// import { LocationGraphFormComponent } from './location-statistics/graph-form/graph-form.component';
import { LocationHistoryTableComponent } from './location-history-table/location-history-table.component';
import { LocationLiveDataCellComponent } from './location-live-data-cell/location-live-data-cell.component';
import { LocationQrCodeComponent } from './location-qr-code/location-qr-code.component';
// import { LocationStatisticsComponent } from './location-statistics/location-statistics.component';
import { LocationSummaryCellComponent } from './location-summary-cell/location-summary-cell.component';
import { LocationSummaryItemComponent } from './location-summary-item/location-summary-item.component';
import { LocationTypeActionCellComponent } from './location-type-action-cell/location-type-action-cell.component';
import { LocationTypeFormComponent } from './location-type-form/location-type-form.component';
import { LocationTypesTableComponent } from './location-types-table/location-types-table.component';
import { LocationsTableComponent } from './locations-table/locations-table.component';
import { NotificationHistoryLogTableComponent } from './notification-history-log-table/notification-history-log-table.component';

import { LocationFormsService } from './services/location-forms.service';
import { LocationHistoryLogsService } from './services/location-history-logs.service';
import { LocationTypesService } from './services/location-types.service';
import { LocationsService } from './services/locations.service';
import { GuestSessionComponent } from './guest-session/guest-session.component';
import { GuestSessionFormComponent } from './guest-session-form/guest-session-form.component';

const COMPONENTS: any[] = [
  CustomSummaryFormComponent,
  LocationActionCellComponent,
  LocationDetailHeaderComponent,
  LocationDevicesTableComponent,
  LocationFormComponent,
  // LocationGraphFormComponent,
  LocationHistoryTableComponent,
  LocationLiveDataCellComponent,
  LocationQrCodeComponent,
  // LocationStatisticsComponent,
  LocationSummaryCellComponent,
  LocationSummaryItemComponent,
  LocationTypeActionCellComponent,
  LocationTypeFormComponent,
  LocationTypesTableComponent,
  LocationsTableComponent,
  NotificationHistoryLogTableComponent,
  GuestSessionComponent,
  GuestSessionFormComponent,
];

@NgModule({
  imports: [SharedModule, DevicesModule],
  exports: [LocationHistoryTableComponent, LocationDevicesTableComponent],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [LocationTypesService, LocationsService, LocationFormsService, LocationHistoryLogsService],
})
export class LocationsModule {}
