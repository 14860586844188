import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-rule-template-form',
  templateUrl: 'rule-template-form.component.html',
})
export class RuleTemplateFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() groupId: any;
  @Input() optionData: any;
  @Output() removed = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
