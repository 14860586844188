import { Component, OnInit, Input, OnChanges, EventEmitter, SimpleChanges, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'sc-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent implements OnInit, OnChanges {
  @Input()
  options: TreeNode[];
  @Input()
  control: FormControl;
  @Input()
  form: FormGroup;
  @Input()
  field: string;
  @Input()
  placeholder: string;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  warning: string;

  @Output()
  onSelect: EventEmitter<any> = new EventEmitter();

  inputId: string;
  selected: TreeNode;
  displayItem: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.updateDisplayItems();
    }
  }

  ngOnInit() {
    this.showLabel = this.showLabel === true;
    this.inputId = 'sc-tree-select-' + this.field + new Date().getTime();
    this.updateDisplayItems();
  }

  get isDisabled(): boolean {
    const form: FormGroup = this.form && this.field && (this.form.get(this.field) as FormGroup);
    if (form) {
      return form.disabled;
    } else if (this.control) {
      return this.control.disabled;
    }
  }

  openPanel(menuPanel, event) {
    if (!this.isDisabled) {
      menuPanel.toggle(event);
    }
  }

  nodeSelect(menuPanel, event) {
    menuPanel.hide();

    if (this.selected) {
      this.displayItem = this.selected.label;
      const form: FormGroup = this.form && this.field && (this.form.get(this.field) as FormGroup);
      if (form) {
        form.setValue(this.selected.data);
      } else if (this.control) {
        this.control.setValue(this.selected.data);
      }
      this.onSelect.emit(this.selected.data);
    }
  }

  private updateDisplayItems() {
    this.displayItem = null;
    let selectedValue;

    if (this.form && this.field) {
      selectedValue = this.form.get(this.field).value;
    } else if (this.control) {
      selectedValue = this.control.value;
    }

    if (selectedValue) {
      this.getOptionFromValue(this.options, selectedValue, (option) => {
        if (option) {
          this.selected = option;
          this.displayItem = option.label;
        }
      });
    }
  }

  private getOptionFromValue(options, value, callback) {
    if (options && options.length) {
      for (const item of options) {
        if (item.data && item.data === value) {
          return callback(item);
        }

        if (item.children && item.children.length) {
          this.getOptionFromValue(item.children, value, callback);
        }
      }
    }
  }
}
