<div class="device-item" [ngClass]="{ 'opened': isOpened }">
  <div class="device-item-header">
    <div class="device-item-header-toggler">
      <span
        class="fa fa-fw"
        [ngClass]="{ 'fa-chevron-up': isOpened, 'fa-chevron-down': !isOpened }"
        (click)="isOpened = !isOpened"
      ></span>
    </div>

    <div class="device-item-header-title">
      <div class="primary">
        {{ data?.modelName }}
      </div>
      <div class="secondary">
        {{ data?.model }}
      </div>
    </div>

    <div class="device-item-header-actions">
      <a class="action-item" (click)="onSelectDevice()">
        {{ 'SELECT' | translate }}
      </a>
    </div>
  </div>

  <div class="device-item-content" *ngIf="isOpened">
    <div class="section">
      <h5 class="section-title">
        {{ 'DEVICE_TYPES' | translate }}
      </h5>
      <ul class="section-content">
        <li *ngFor="let vd of data?.virtualDevices">
          {{ vd.deviceTypeName }}
        </li>
      </ul>
    </div>

    <div class="section">
      <h5 class="section-title">
        {{ 'DEVICE_PROTOCOL' | translate }}
      </h5>
      <ul class="section-content">
        <li>{{ data?.protocolName }}</li>
      </ul>
    </div>

    <div class="section">
      <h5 class="section-title">
        {{ 'DEVICE_BRAND' | translate }}
      </h5>
      <ul class="section-content">
        <li>{{ data?.brandName }}</li>
      </ul>
    </div>

    <div class="section">
      <img [src]="productImage" width="100" />
    </div>
  </div>
</div>
