import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { CustomApp } from '../models/custom-app';
import { ModalConfig } from '@sc/modal/modal-config';

import { CustomAppsService } from '../services/custom-apps.service';

import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';
import { CustomAppActionCellComponent } from '../custom-app-action-cell/custom-app-action-cell.component';

@Component({
  selector: 'sc-custom-apps-table',
  templateUrl: 'custom-apps-table.component.html'
})
export class CustomAppsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: CustomApp[];
  subscribers: { [key: string]: any };
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(private customAppsService: CustomAppsService) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'appId',
        headerName: 'APP_ID',
        field: 'appId'
      },
      {
        colId: 'appStyleId',
        headerName: 'APP_STYLE_ID',
        field: 'appStyleId'
      },
      {
        colId: 'companyId',
        headerName: 'COMPANY_ID',
        field: 'companyId'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        suppressSorting: true,
        suppressFilter: true,
        pinned: 'right',
        cellRendererFramework: CustomAppActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'CustomAppForm',
      options: { modalTitle: 'CUSTOM_APP_FORM_TITLE' }
    };
    this.tableTitle = 'CUSTOM_APPS';
    this.subscribers = {};

    this.fetchDataset();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.subscribers.DATASET = this.customAppsService
      .getCustomApps()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
