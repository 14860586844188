<section class="layout">
  <div class="areas-form__header">
    <div class="label">{{ "AREAS" | translate }}</div>
    <button
      type="button"
      pButton
      [icon]="'fa fa-fw fa-plus'"
      (click)="addArea()"
    ></button>
  </div>
  <div class="areas-form__content">
    <div *ngFor="let area of areas.controls; index as idx">
      <div class="areas-form__content-line">
        <sc-text-input
          class="area-desc"
          controlName="description"
          [form]="area"
          [showLabel]="false"
        ></sc-text-input>
        <button
          class="minus-btn"
          type="button"
          pButton
          [icon]="'fa fa-fw fa-minus'"
          (click)="removeArea(idx)"
        ></button>
      </div>
    </div>
  </div>

  <div class="label">{{ "PARENTS" | translate }}:</div>
  <div class="sc-tree-wrapper">
    <p-tree
      [filter]="false"
      [propagateSelectionUp]="false"
      [loading]="isFetchingLocations"
      [value]="locationsTree"
      selectionMode="checkbox"
      [(selection)]="selectedLocations"
    ></p-tree>
  </div>
</section>
<section class="footer">
  <div class="footer-left">
    <button
      type="button"
      pButton
      class="ui-button-secondary"
      (click)="cancel()"
      [label]="'CANCEL' | translate"
    ></button>
  </div>
  <div class="footer-right">
    <sc-submit-button
      buttonClass="ui-button-success"
      (submitted)="submit()"
      [disabled]="form.invalid"
      [label]="'SAVE' | translate"
    ></sc-submit-button>
  </div>
</section>
