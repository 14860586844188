import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SelectItem, TreeNode } from 'primeng/api';

import { LocationStatisticsFormsService } from '../services/location-statistics-forms.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-graph-form',
  templateUrl: './graph-form.component.html',
  styleUrls: ['./graph-form.component.scss'],
})
export class GraphFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() selectItems: { [key: string]: (SelectItem | TreeNode)[] };

  axes: SelectItem[] = [];

  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private locationStatisticsFormsService: LocationStatisticsFormsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.addGraph();
    this.watchGraphsForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  get graphForms(): FormArray {
    return this.form && <FormArray>this.form.get('graphs');
  }

  get isCompare(): boolean {
    return this.form && this.form.get('isCompare').value;
  }

  addGraph() {
    const forms = this.graphForms;
    const i = forms.length;
    const form = this.locationStatisticsFormsService.initGraphForm();

    // patch position and color
    form.patchValue({
      color: this.selectItems.colors[i],
      position: 'left',
    });

    // watch dataset
    this.subscribers['graph' + i] = form.get('dataset').valueChanges.subscribe((value) => {
      if (value) {
        form.patchValue({ axis: value });
        // allow fetching data
        this.form.get('fetchingRequired').setValue(true);
      }
    });
    forms.push(form);
  }

  removeGraph(i: number) {
    const forms = this.graphForms;
    forms.removeAt(i);
  }

  private watchGraphsForm() {
    /**
     * 1. create axis select items
     * 2. check axis
     */
    this.form.get('graphs').valueChanges.subscribe((result) => {
      clearTimeout(this.timeouts.watchGraphs);
      this.timeouts.watchGraphs = setTimeout(() => {
        const axes = [];
        for (let i = 0; i < result.length; i++) {
          const dataset = result[i].dataset;
          const axis = result[i].axis;
          const position = result[i].position;

          if (!dataset) {
            continue;
          }

          if (dataset !== axis) {
            // doesn't use own axis then clear position
            this.graphForms.controls[i].get('position').reset();
            this.graphForms.controls[i].get('position').disable();
          } else if (dataset === axis) {
            if (!position) {
              this.graphForms.controls[i].get('position').setValue('left');
            }
            this.graphForms.controls[i].get('position').enable();
          }

          // add axis to select items
          axes.push({ label: dataset, value: dataset });
        }
        this.axes = axes;
      }, 500);
    });
  }
}
