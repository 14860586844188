<div *ngIf="fetchingState === 0; else loadingIndicator">
  <div class="ui-g mb-3">
    <div class="ui-g-12 mb-3">
      <h3>
        {{ benchmarkTemplateName }}
      </h3>
      <span>{{ description }}</span>
    </div>
  </div>

  <div *ngIf="gridOptions">
    <ag-grid-angular
      class="ag-theme-sc-clean"
      [gridOptions]="gridOptions"
      style="width: 100%; height: 100%"
    ></ag-grid-angular>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
