import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ConfirmationService, SelectItem, TreeNode } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { DeviceContextMenu, LocationContextMenu } from './models/context-menus';
import { DeviceStates } from './models/device';
import { LocationStates, CleaningStates } from './models/location';
import { LocationShapes } from './models/location-shapes';
import { ObjectLayers, ObjectTypes } from './models/object';
import { Cursors } from './models/floorplan';

import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { ProtocolController } from '@widgets/devices/models/protocol-controller';
import { LocationDetails } from '@widgets/locations/models/location-details';
import { LocationProperty } from '@widgets/location-properties/models/location-property';
import { LocationType } from '@widgets/locations/models/location-type';
import { Site } from '@widgets/sites/models/site';

import { ApplyRuleFormComponent } from './apply-rule-form/apply-rule-form.component';
// import { CreateWizardComponent } from '@widgets/device-wizard/create-wizard/create-wizard.component';
import { DeviceAssignFormComponent } from './device-assign-form/device-assign-form.component';
import { DeviceDetailsModalComponent } from './device-details-modal/device-details-modal.component';
import { DeviceObjectFormComponent } from './device-object-form/device-object-form.component';
import { LocationAssignFormComponent } from './location-assign-form/location-assign-form.component';
import { LocationDetailsModalComponent } from './location-details-modal/location-details-modal.component';
import { LocationShapeFormComponent } from './location-shape-form/location-shape-form.component';
import { LocationSummaryFormComponent } from './location-summary-form/location-summary-form.component';
import { DeviceFormComponent } from '@app/integrator/components/device-form/device-form.component';

import { FloorplanService } from './services/floorplan.service';
import { SharedService } from '@shared/shared.service';

import { config as appConfig } from '@app/config';

declare let fabric: any;

@Component({
  selector: 'sc-floor-planner',
  templateUrl: './floor-planner.component.html',
  styleUrls: ['./floor-planner.component.scss'],
})
export class FloorPlannerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  devices: any[];
  @Input()
  deviceTypes: DeviceType[] = []; // share to wizard
  @Input()
  deviceModels: DeviceModel[] = []; // share to wizard
  @Input()
  deviceProtocols: DeviceProtocol[] = []; // share to wizard
  @Input()
  protocolControllers: ProtocolController[] = []; // share to wizard
  @Input()
  locationProperties: LocationProperty[] = []; // share to summary data
  @Input()
  locationTypes: LocationType[] = []; //share to location detail
  @Input()
  floor: any;
  @Input()
  locations: LocationDetails[];
  @Input()
  dhls: any[];
  @Input()
  lhls: any[];
  @Input()
  deviceEvents: any[];
  @Input()
  locationEvents: any[];
  @Input()
  readOnlyMode: boolean;
  @Input()
  site: Site;
  @Input()
  zoomedLocation: LocationDetails;

  @Output()
  afterInit = new EventEmitter();
  @Output()
  valueChanged = new EventEmitter();
  @Output()
  eventLayerToggled = new EventEmitter();

  @ViewChild('addObjectMenu', { static: true })
  private addObjectMenu;
  @ViewChild('assignedDeviceMenu', { static: true })
  private assignedDeviceMenu;
  @ViewChild('assignedLocationMenu', { static: true })
  private assignedLocationMenu;
  @ViewChild('unassignedDeviceMenu', { static: true })
  private unassignedDeviceMenu;
  @ViewChild('unassignedLocationMenu', { static: true })
  private unassignedLocationMenu;
  @ViewChild('locationEditMenu', { static: true })
  private locationEditMenu;
  @ViewChild('workspace', { static: true })
  private workspace;

  private activeLine: any;
  private activeShape: any;
  private canvas: any;
  private contextMenuActive: boolean = false;
  private contextMenuPreselect: boolean = false;
  private contextMenus: any[] = [];
  private drawMode: boolean = false;
  private floorplanImage: string;
  private isInitializing: boolean = true;
  private isModifyingObject: boolean = false;
  private lastCoords: { x: number; y: number };
  private lineArray: any[] = [];
  private pointArray: any[] = [];
  private selectedObjectCloned: any;
  private selectedPointIndex: number;
  private unallowActions: string[] = [];

  private subscribers: any = {};
  private timeouts: any = {};
  deviceContextMenu = DeviceContextMenu;
  locationContextMenu = LocationContextMenu;
  objectTypes = ObjectTypes;

  private applyRuleForm: DynamicDialogRef;
  private deviceAssignForm: DynamicDialogRef;
  private deviceObjectForm: DynamicDialogRef;
  private deviceWizardForm: DynamicDialogRef;
  private locationAssignForm: DynamicDialogRef;
  private locationShapeForm: DynamicDialogRef;
  private locationSummaryForm: DynamicDialogRef;
  private locationDetailsModal: DynamicDialogRef;
  private deviceDetailsModal: DynamicDialogRef;

  showLayerMenu: boolean = false;
  selectedLayers: any[] = [];
  selectItems: { [prop: string]: SelectItem[] | TreeNode[] } = {};
  selectedObject: any;

  constructor(
    private dialogService: DialogService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private floorplanService: FloorplanService
  ) {}

  ngOnInit() {
    this.contextMenus = [
      this.assignedDeviceMenu,
      this.assignedLocationMenu,
      this.unassignedDeviceMenu,
      this.unassignedLocationMenu,
      this.locationEditMenu,
      this.addObjectMenu,
    ];

    if (this.floor && this.floor.floorplanImage) {
      this.floorplanImage = appConfig().s3Url + '/' + this.floor.floorplanImage;
    }

    this.fabricCustom();
    this.initLayerController();
  }

  ngAfterViewInit() {
    // add a bit delay for can get height and width of parent element
    this.timeouts.initCanvas = setTimeout(() => {
      // create canvas
      this.initCanvas();

      if (this.floor && this.floor.floorplanStructure && this.floor.floorplanStructure.objects) {
        // load objects from json
        this.canvas.loadFromJSON(
          this.floor.floorplanStructure,
          () => {
            // Callback, invoked when json is parsed and corresponding objects are initialized
            this.canvas.renderAll();

            // update flag
            this.isInitializing = false;

            // update location object aspectRatio
            this.updateLocationScale();

            // create device scope for Omnis sensor
            this.drawDeviceScopes();

            if (this.zoomedLocation) {
              this.zoomToLocation();
            }

            this.updateLocationSummaryConfig();

            // emit after init event
            this.afterInit.emit();
          },
          (o, object) => {
            // optional method for further parsing of JSON elements, called after each fabric object created.
            if (!this.readOnlyMode) {
              if (object.type === ObjectTypes.Device) {
                object.on('mousedown', this.onDeviceMouseDown.bind(this));
              } else if (object.type === ObjectTypes.Location) {
                object.on('mousedown', this.onLocationMouseDown.bind(this));
              }
            }
          }
        );
      } else {
        // update flag
        this.isInitializing = false;
        // emit after init event
        this.afterInit.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deviceEvents && changes.deviceEvents.currentValue) {
      this.createDeviceEvents();
    }
    if (changes.locationEvents && changes.locationEvents.currentValue) {
      this.createLocationEvents();
    }
    if (changes.dhls && changes.dhls.currentValue) {
      this.updateDevicesDHL();
    }
    if (changes.lhls && changes.lhls.currentValue) {
      this.updateLocationsLHL();
    }
    if (changes.zoomedLocation && changes.zoomedLocation.currentValue) {
      this.zoomToLocation();
    }
    if (changes.devices && changes.devices.currentValue) {
      this.drawDeviceScopes();
    }
    if (changes.floor && changes.floor.currentValue) {
      this.drawDeviceScopes();
    }
    if (changes.deviceModels && changes.deviceModels.currentValue) {
      this.loadDeviceModelIcons();
    }
    if (changes.locationProperties && changes.locationProperties.currentValue) {
      this.updateLocationSummaryConfig();
    }
  }

  ngOnDestroy() {
    this.sharedService.clearTimeouts(this.timeouts);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private showContextMenu(ctxMenu, event) {
    this.hideContextMenu();
    this.contextMenuActive = true;
    ctxMenu.style.display = 'block';
    this.timeouts.showContextMenu = setTimeout(() => {
      ctxMenu.style.left = event.pointer.x + 'px';
      ctxMenu.style.top = event.pointer.y + 'px';
    });
  }

  private hideContextMenu() {
    this.contextMenuActive = false;
    this.contextMenuPreselect = false;

    for (const ctxMenu of this.contextMenus) {
      ctxMenu.nativeElement.style.display = '';
      ctxMenu.nativeElement.style.left = '';
      ctxMenu.nativeElement.style.top = '';
    }
  }

  private clearAutoSelectObjectOnContextMenu() {
    if (this.contextMenuActive) {
      // hide context menu if opened
      this.hideContextMenu();
      // deselect an auto select object
      this.deselectSelectedObject();
    }
  }

  private initCanvas() {
    this.canvas = new fabric.Canvas(this.workspace.nativeElement);
    this.canvas.setHeight(this.canvas.wrapperEl.parentElement.offsetHeight);
    this.canvas.setWidth(this.canvas.wrapperEl.parentElement.offsetWidth);
    // this.canvas.backgroundColor = '#ddd';
    this.canvas.defaultCursor = Cursors.Normal;
    this.canvas.fireRightClick = true;
    this.canvas.hoverCursor = Cursors.Normal;
    this.canvas.preserveObjectStacking = true;
    this.canvas.renderOnAddRemove = false;
    this.canvas.selection = false;
    this.canvas.stateful = false;
    this.canvas.stopContextMenu = true;

    if (this.floorplanImage) {
      if (/\.svg$/gi.test(this.floorplanImage)) {
        fabric.loadSVGFromURL(
          this.floorplanImage,
          (objects, options) => {
            this.canvas.setBackgroundImage(
              fabric.util.groupSVGElements(objects, options),
              this.canvas.renderAll.bind(this.canvas)
            );
          },
          null,
          { crossOrigin: 'anonymous' }
        );
      } else {
        this.canvas.setBackgroundImage(this.floorplanImage, this.canvas.renderAll.bind(this.canvas));
        // {
        //   originX: 'left',
        //   originY: 'top',
        // }
      }
    }

    // canvas event handling
    this.canvas.on('mouse:down', this.onMouseDown.bind(this));
    this.canvas.on('mouse:up', this.onMouseUp.bind(this));
    this.canvas.on('mouse:move', this.onMouseMove.bind(this));
    this.canvas.on('mouse:wheel', this.onMouseWheel.bind(this));
    this.canvas.on('selection:created', this.onSelectionCreated.bind(this));
    this.canvas.on('selection:cleared', this.onSelectionCleared.bind(this));
  }

  private initLayerController() {
    this.selectItems.layers = [
      {
        label: 'Devices',
        data: ObjectLayers.Device,
        expanded: true,
        children: [
          { label: 'Relationships', data: ObjectLayers.DeviceRelationship },
          { label: 'Scopes', data: ObjectLayers.DeviceScope },
          { label: 'Events', data: ObjectLayers.DeviceEvent },
        ],
      },
      {
        label: 'Locations',
        data: ObjectLayers.Location,
        children: [{ label: 'Events', data: ObjectLayers.LocationEvent }],
      },
    ];

    this.selectedLayers = Object.keys(ObjectLayers)
      .filter((layer) => ObjectLayers[layer] !== ObjectLayers.DeviceScope)
      .map((layer) => ObjectLayers[layer]);
  }

  private toggleCanvasObjects() {
    this.eventLayerToggled.emit(this.selectedLayers);

    const objects = this.canvas.getObjects();
    for (const obj of objects) {
      let visible = false;

      if (obj.type === ObjectTypes.Event) {
        if (obj.eventType === ObjectTypes.Device && this.selectedLayers.indexOf(ObjectLayers.DeviceEvent) >= 0) {
          visible = true;
        } else if (
          obj.eventType === ObjectTypes.Location &&
          this.selectedLayers.indexOf(ObjectLayers.LocationEvent) >= 0
        ) {
          visible = true;
        }
      } else if (this.selectedLayers.indexOf(obj.type) >= 0) {
        visible = true;
      }

      obj.visible = visible;
    }
    this.canvas.renderAll();
  }

  toggleLayer(layer) {
    const layerIndex = this.selectedLayers.indexOf(layer.data);

    if (layerIndex >= 0) {
      this.selectedLayers.splice(layerIndex, 1);
    } else {
      this.selectedLayers.push(layer.data);
    }

    this.toggleCanvasObjects();
  }

  layerMenuCheckMark(layer) {
    if (this.selectedLayers.indexOf(layer.data) >= 0) {
      return 'fa-check-square-o';
    }
    return 'fa-square-o';
  }

  private fabricCustom() {
    // find PPI (pixel per inch)
    this.floorplanService.getPPI();

    // load all images
    this.floorplanService.loadImages();

    // added subclasses
    this.floorplanService.addCustomSubclasses();

    // delete rotating point control globally
    // delete fabric.Object.prototype.controls.mtr;

    // add save control
    this.floorplanService.addCustomControl(
      'saveControl',
      this.editSave.bind(this),
      0.5,
      -0.5,
      16,
      -16,
      fabric._imageIcons.controlIconSave
    );

    // add cancel control
    this.floorplanService.addCustomControl(
      'cancelControl',
      this.editCancel.bind(this),
      -0.5,
      -0.5,
      -16,
      -16,
      fabric._imageIcons.controlIconCancel
    );
  }

  private loadDeviceModelIcons() {
    if (fabric && !fabric._imageIcons) {
      fabric._imageIcons = {};
    }
    // load device model icons
    for (const dm of this.deviceModels) {
      const key = 'deviceModel' + dm.id;
      if (dm.iconImage) {
        fabric._imageIcons[key] = document.createElement('img');
        fabric._imageIcons[key].src = appConfig().s3Url + '/' + dm.iconImage;
      } else if (fabric._imageIcons[key]) {
        delete fabric._imageIcons[key];
      }
    }

    if (this.canvas) {
      this.canvas.renderAll();
    }
  }

  private zoomCanvas(value: number, point?: { x: number; y: number }) {
    if (value === 0) {
      this.canvas.setZoom(1);
      return;
    }

    let currentZoom = this.canvas.getZoom();
    currentZoom += value;

    if (currentZoom > 4) {
      currentZoom = 4;
    } else if (currentZoom < 0.4) {
      currentZoom = 0.4;
    }

    if (point) {
      this.canvas.zoomToPoint(point, currentZoom);
    } else {
      this.canvas.setZoom(currentZoom);
    }
    this.canvas.renderAll();
  }

  private zoomToLocation(object?: any) {
    if (this.isInitializing) {
      return;
    }

    object = object || this.getLocationObjects(this.zoomedLocation.idx)[0];
    if (object && object.type === ObjectTypes.Location) {
      const canvasWidth = this.canvas.getWidth();
      const canvasHeight = this.canvas.getHeight();
      const canvasCenterX = canvasWidth / 2;
      const canvasCenterY = canvasHeight / 2;
      const targetX = object.left + object.width / 2;
      const targetY = object.top + object.height / 2;
      const panPoint = new fabric.Point(-canvasCenterX + targetX, -canvasCenterY + targetY);
      this.canvas.absolutePan(panPoint);
      const zoomPoint = new fabric.Point(canvasCenterX, canvasCenterY);
      let zoomValue = Math.floor(canvasHeight / object.height);
      zoomValue = zoomValue > 3.5 ? 3.5 : zoomValue;
      this.canvas.zoomToPoint(zoomPoint, zoomValue);
      this.canvas.requestRenderAll();
    }
  }

  private onDeviceMouseDown(options) {
    if (!this.isModifyingObject) {
      const device = options.target;
      // normal mode
      this.clearAutoSelectObjectOnContextMenu();

      if (options.button === 3) {
        // on right click
        this.contextMenuPreselect = true;
        // auto select an object which mouse is on
        this.canvas.setActiveObject(device);
        // show device menu
        if (device.deviceState === DeviceStates.Assigned) {
          this.showContextMenu(this.assignedDeviceMenu.nativeElement, options);
        } else {
          this.showContextMenu(this.unassignedDeviceMenu.nativeElement, options);
        }
        // this.showContextMenu(this.deviceMenu.nativeElement, options);
      }
    }
  }

  private onLocationMouseDown(options) {
    if (!this.isModifyingObject) {
      // normal mode
      this.clearAutoSelectObjectOnContextMenu();
    }

    if (options.button === 3) {
      // on right click
      const location = options.target;

      if (!this.isModifyingObject) {
        this.contextMenuPreselect = true;
        // auto select an object which mouse is on
        this.canvas.setActiveObject(location);
      }

      // right click on any of polygon points?
      let isClickedOnPoint = false;
      for (let i = 0; i < location.points.length; i++) {
        const point = location.points[i];
        const diffX = Math.abs(point.x - options.absolutePointer.x);
        const diffY = Math.abs(point.y - options.absolutePointer.y);
        if (diffX <= 10 && diffY <= 10) {
          isClickedOnPoint = true;
          this.selectedPointIndex = i;
          break;
        }
      }

      if (this.isModifyingObject && isClickedOnPoint && location.edit) {
        // show location edit menu (add/remove point) if click on point and in modify mode
        this.showContextMenu(this.locationEditMenu.nativeElement, options);
      } else if (!this.isModifyingObject) {
        // show location menu if not in modify mode
        if (location.locationState === LocationStates.Assigned) {
          this.showContextMenu(this.assignedLocationMenu.nativeElement, options);
        } else {
          this.showContextMenu(this.unassignedLocationMenu.nativeElement, options);
        }
        // this.showContextMenu(this.locationMenu.nativeElement, options);
      }
    }
  }

  private onMouseDown(options) {
    // hide layer menu
    this.showLayerMenu = false;

    const event = options.e;
    if (event.altKey === true) {
      // start pan mode
      this.canvas.isDragging = true;
      this.canvas.selection = false;
      this.canvas.lastPosX = event.clientX;
      this.canvas.lastPosY = event.clientY;
      this.canvas.defaultCursor = Cursors.Pan;
    } else if (this.drawMode) {
      // draw mode
      if (options.target && this.pointArray[0] && options.target.id === this.pointArray[0].id) {
        // when click on the starting point, create object
        this.createLocationObject(this.pointArray);
        // exit draw mode
        this.toggleDrawPolygon();
      } else {
        this.addPoint(options);
      }
    } else if (!this.isModifyingObject && !this.readOnlyMode) {
      this.clearAutoSelectObjectOnContextMenu();

      if (options.button === 3) {
        // right click on canvas

        // store X and Y of the cursor
        this.lastCoords = options.absolutePointer;

        if (!this.contextMenuActive && !this.contextMenuPreselect) {
          this.showContextMenu(this.addObjectMenu.nativeElement, options);
        }
      } else if (options.button === 1) {
        // left click on canvas
      }
    }
  }

  private onMouseUp(options) {
    // stop pan mode
    if (this.canvas.isDragging) {
      this.canvas.isDragging = false;
      this.updateObjectsCoords();

      if (this.drawMode) {
        this.canvas.defaultCursor = Cursors.Draw;
        this.canvas.hoverCursor = Cursors.Draw;
      } else {
        this.canvas.defaultCursor = Cursors.Normal;
        this.canvas.hoverCursor = Cursors.Normal;
      }
    }
  }

  private onMouseMove(options) {
    if (this.canvas.isDragging) {
      // pan mode

      const event = options.e;
      this.canvas.viewportTransform[4] += event.clientX - this.canvas.lastPosX;
      this.canvas.viewportTransform[5] += event.clientY - this.canvas.lastPosY;
      this.canvas.requestRenderAll();
      this.canvas.lastPosX = event.clientX;
      this.canvas.lastPosY = event.clientY;
    } else if (this.drawMode) {
      // draw mode

      this.canvas.defaultCursor = Cursors.Draw;
      if (options.target && this.pointArray[0] && options.target.id === this.pointArray[0].id) {
        // cursor when hover on starting point
        this.canvas.hoverCursor = Cursors.DrawAlt;
      } else {
        // cursor when hover on other points
        this.canvas.hoverCursor = Cursors.Draw;
      }

      // guide line (or polygon)
      if (this.activeLine) {
        const pointer = this.canvas.getPointer(options.e);
        this.activeLine.set({ x2: pointer.x, y2: pointer.y });
        const points = this.activeShape.get('points');
        points[this.pointArray.length] = {
          x: pointer.x,
          y: pointer.y,
        };
        this.activeShape.set({ points });
      }
      this.canvas.renderAll();
    }
  }

  private onMouseWheel(options) {
    const event = options.e;
    if (event.altKey === true) {
      // zoom
      if (event.deltaY > 0) {
        // wheel down (zoom out)
        this.zoomCanvas(-0.05, { x: event.offsetX, y: event.offsetY });
      } else {
        // wheel up (zoom in)
        this.zoomCanvas(0.05, { x: event.offsetX, y: event.offsetY });
      }
    }

    event.preventDefault();
    event.stopPropagation();
  }

  private onSelectionCreated(options) {
    this.selectedObject = options.target;
    if (!this.isModifyingObject) {
      this.selectedObjectCloned = JSON.parse(JSON.stringify(this.selectedObject));
    }

    this.setUnallowActions(this.selectedObject);
  }

  private onSelectionCleared(options) {
    this.clearAutoSelectObjectOnContextMenu();

    if (this.selectedObject && this.isModifyingObject) {
      // force selected object to always selected when in modify mode
      this.canvas.setActiveObject(this.selectedObject);
      this.canvas.renderAll();
    }
  }

  private deselectSelectedObject() {
    this.selectedObject = null;
    this.selectedObjectCloned = null;
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
    // enable selection to all objects
    // this.enableObjectsEvent();
  }

  // private disableObjectsEvent() {
  //   // disable selection to all objects except the one we selected
  //   const objects = this.canvas.getObjects();
  //   for (const object of objects) {
  //     if (object.id !== this.selectedObject.id) {
  //       object.evented = false;
  //     }
  //   }
  //   this.canvas.renderAll();
  // }

  // private enableObjectsEvent() {
  //   // enable selection to all objects except event type object
  //   const objects = this.canvas.getObjects();
  //   for (const object of objects) {
  //     switch (object.type) {
  //       case ObjectTypes.Device:
  //       case ObjectTypes.DeviceScope:
  //       case ObjectTypes.Location:
  //         object.evented = true;
  //         break;
  //     }
  //     this.canvas.renderAll();
  //   }
  // }

  private updateObjectsCoords(objects?: any[]) {
    objects = objects || this.canvas.getObjects();
    for (const object of objects) {
      object.setCoords();
      if (object.type === 'polygon' && object.edit) {
        this.setCustomControlPoints(object);
      }
    }
  }

  private toggleDrawPolygon() {
    this.deselectSelectedObject();

    if (this.drawMode) {
      // stop draw mode
      this.canvas.defaultCursor = Cursors.Normal;
      this.canvas.hoverCursor = Cursors.Normal;
    } else {
      // start draw mode
      this.canvas.defaultCursor = Cursors.Draw;
      this.canvas.hoverCursor = Cursors.Draw;
    }

    this.activeLine = null;
    this.activeShape = null;
    this.lineArray = [];
    this.pointArray = [];
    this.drawMode = !this.drawMode;
  }

  private addPoint(options) {
    const x = options.absolutePointer.x;
    const y = options.absolutePointer.y;

    // draw a guideline
    const linePoints = [x, y, x, y];
    const lineOption = {
      evented: false,
      fill: '#444',
      hasBorders: false,
      hasControls: false,
      objectCaching: false,
      originX: 'center',
      originY: 'center',
      selectable: false,
      stroke: '#444',
      strokeWidth: 2,
    };
    // create a new guideline and update activeLine
    const line = new fabric.Line(linePoints, lineOption);
    this.activeLine = line;
    this.lineArray.push(line);

    // draw a guideline point
    const pointOption = {
      id: new Date().getTime(), // additional attribute
      fill: this.pointArray.length ? '#444' : '#0f0', // mark a starting point as green color
      hasBorders: false,
      hasControls: false,
      objectCaching: false,
      originX: 'center',
      originY: 'center',
      radius: 5,
      selectable: false,
      stroke: '#444',
      strokeWidth: 0.5,
      left: x,
      top: y,
    };
    // create a new guideline point
    const point = new fabric.Circle(pointOption);
    this.pointArray.push(point);

    // draw a polygon
    let polygonPoints: any[];
    const polygonOptions = {
      evented: false,
      fill: '#444',
      hasBorders: false,
      hasControls: false,
      objectCaching: false,
      opacity: 0.5,
      selectable: false,
      stroke: '#444',
      strokeWidth: 1,
    };

    if (!this.activeShape) {
      // first point of polygon
      polygonPoints = [{ x, y }];
    } else {
      // not the first point of polygon, get all points of polygon
      polygonPoints = this.activeShape.get('points');
      // add new point
      polygonPoints.push({ x, y });
      // remove current polygon (without new point)
      this.canvas.remove(this.activeShape);
    }

    // create a new sample polygon and update activeShape
    const polygon = new fabric.Polygon(polygonPoints, polygonOptions);
    this.activeShape = polygon;

    // add a new sample polygon, line, point to canvas
    this.canvas.add(polygon);
    this.canvas.add(line);
    this.canvas.add(point);
    this.canvas.renderAll();
  }

  private createLocationObject(drawPoints: { left: number; top: number }[], options: any = {}) {
    const points = [];

    // collect all guildline points and remove them from canvas
    for (const point of drawPoints) {
      points.push({ x: point.left, y: point.top });
      this.canvas.remove(point);
    }

    // remove all guidelines and remove them canvas
    for (const line of this.lineArray) {
      this.canvas.remove(line);
    }

    // remove an active guideline and sample polygon
    this.canvas.remove(this.activeLine);
    this.canvas.remove(this.activeShape);

    // set default summary config
    if (!options.summaryConfig && this.locationProperties && this.locationProperties.length) {
      options.summaryConfig = {};
      options.summaryConfig.enableCustomization = false;
      options.summaryConfig.summaryItems = this.locationProperties.map((item) => ({
        id: item.id,
        key: item.key,
        hidden: true,
      }));
    }

    if (!options.aspectRatio && typeof this.floor.floorplanScale === 'number' && this.floor.floorplanScale > 0) {
      options.aspectRatio = this.floor.floorplanScale;
    }

    // get last index of location object
    const lastLocationIndex = this.findLastObjectZIndex(ObjectTypes.Location);
    // create a locationObject from collected points
    const locationObject = new fabric.LocationObject(points, options);
    // add event handler on locationObject mouse down
    locationObject.on('mousedown', this.onLocationMouseDown.bind(this));
    // add location to canvas
    this.canvas.add(locationObject);
    // move location to last index position
    locationObject.moveTo(lastLocationIndex);
    this.canvas.renderAll();
    return locationObject;
  }

  private setCustomControlPoints(object) {
    const lastControl = object.points.length - 1;
    object.controls = object.points.reduce((acc, point, index) => {
      const anchorIndex = index > 0 ? index - 1 : lastControl;
      acc['p' + index] = new fabric.Control({
        positionHandler: this.polygonPositionHandler(),
        actionHandler: this.anchorWrapper(anchorIndex, this.actionHandler),
        actionName: 'modifyPolygon',
        pointIndex: index,
      });
      return acc;
    }, {});

    object.controls.saveControl = fabric.Object.prototype.controls.saveControl;
    object.controls.cancelControl = fabric.Object.prototype.controls.cancelControl;
  }

  private polygonPositionHandler() {
    /**
     * define a function that can locate the controls.
     * this function will be used both for drawing and for interaction.
     * more info: http://fabricjs.com/custom-controls-polygon
     */
    return function (dim, finalMatrix, fabricObject) {
      if (!fabricObject.points[this.pointIndex]) {
        // NOTE: no idea why. just avoid error after cancel edit when added new point
        return {};
      }

      const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
      const y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;

      return fabric.util.transformPoint(
        { x, y },
        fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
      );
    };
  }

  private actionHandler(eventData, transform, x, y) {
    /**
     * define a function that will define what the control does
     * this function will be called on every mouse move after a control has been
     * clicked and is being dragged.
     * The function receive as argument the mouse event, the current trasnform object
     * and the current position in canvas coordinate
     * transform.target is a reference to the current object being transformed,
     * more info: http://fabricjs.com/custom-controls-polygon
     */
    const polygon = transform.target;
    const currentControl = polygon.controls[polygon.__corner];
    const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
    const polygonBaseSize = polygon._getNonTransformedDimensions();
    const size = polygon._getTransformedDimensions(0, 0);
    const finalPointPosition = {
      x: (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
      y: (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y,
    };
    polygon.points[currentControl.pointIndex] = finalPointPosition;
    return true;
  }

  private anchorWrapper(anchorIndex, fn) {
    /**
     * define a function that can keep the polygon in the same position when we change its
     * width/height/top/left.
     * more info: http://fabricjs.com/custom-controls-polygon
     */
    return function (eventData, transform, x, y) {
      const fabricObject = transform.target;
      const absolutePoint = fabric.util.transformPoint(
        {
          x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
          y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
        },
        fabricObject.calcTransformMatrix()
      );
      fabricObject._setPositionDimensions({});
      const polygonBaseSize = fabricObject._getNonTransformedDimensions();
      const newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
      const newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
      // action performed
      return fn(eventData, transform, x, y);
    };
  }

  private createDeviceObject(left: number, top: number, options?: any) {
    const deviceObject = new fabric.DeviceObject({ left, top, ...options });

    // add event handler on deviceObject mouse down
    deviceObject.on('mousedown', this.onDeviceMouseDown.bind(this));

    // add device to canvas
    this.canvas.add(deviceObject);
    deviceObject.moveTo(this.findLastObjectZIndex(ObjectTypes.Device));
    this.canvas.renderAll();

    return deviceObject;
  }

  private editCancel(eventData, target) {
    // console.log('editCancel');
    if (!this.selectedObjectCloned) {
      return;
    }
    // console.log(eventData);
    // TODO: try to import from JSON
    if (this.selectedObjectCloned.type === ObjectTypes.Device) {
      // this.selectedObject.hasBorders = true;
      // this.selectedObject.hasControls = true;
      // this.selectedObject.lockMovementX = false;
      // this.selectedObject.lockMovementY = false;
      // this.selectedObject.moveable = true;

      // console.log('selectedObjectCloned.type:', this.selectedObjectCloned.type);
      // re-create new device from the cloned object
      const device = this.createDeviceObject(this.selectedObjectCloned.left, this.selectedObjectCloned.top, {
        id: this.selectedObjectCloned.id,
        automation: this.selectedObjectCloned.automation,
        deviceModel: this.selectedObjectCloned.deviceModel,
        deviceState: this.selectedObjectCloned.deviceState,
        dhl: this.selectedObjectCloned.dhl,
        location: this.selectedObjectCloned.location,
      });
      // console.log('createDeviceObject');
      this.createDeviceScope(device);
      // console.log('createDeviceScope');
    } else if (this.selectedObjectCloned.type === ObjectTypes.Location) {
      // this.selectedObject.edit = false;
      // this.selectedObject.controls = fabric.Object.prototype.controls;
      // this.selectedObject.cornerStyle = 'rect';
      // this.selectedObject.hasBorders = true;
      // this.selectedObject.hasControls = true;
      // this.selectedObject.lockMovementX = false;
      // this.selectedObject.lockMovementY = false;
      // this.selectedObject.lockScalingX = false;
      // this.selectedObject.lockScalingY = false;
      // this.selectedObject.moveable = true;

      // console.log('selectedObjectCloned.type:', this.selectedObjectCloned.type);
      this.resetPolygonControl();
      // console.log('resetPolygonControl');
      // re-create new location from the cloned object
      this.createLocationObject(
        this.selectedObjectCloned.points.map((p) => ({ left: p.x, top: p.y })),
        {
          id: this.selectedObjectCloned.id,
          label: this.selectedObjectCloned.label,
          locationState: this.selectedObjectCloned.locationState,
          lhl: this.selectedObjectCloned.lhl,
          summaryConfig: this.selectedObjectCloned.summaryConfig,
        }
      );
      // console.log('createLocationObject');
    }

    this.deleteObject(target);
    // console.log('deleteObject\n===========');
    this.isModifyingObject = false;
  }

  private editSave(eventData, target) {
    this.deselectSelectedObject();
    this.canvas.renderAll();
    this.isModifyingObject = false;
  }

  private setUnallowActions(object) {
    const actions = [];
    if (object.type === ObjectTypes.Device) {
      // Device object context menu
      switch (object.deviceState) {
        case DeviceStates.Assigned:
        case DeviceStates.Alert:
          // not allow when assigned or alert
          actions.push(DeviceContextMenu.Assign);
          actions.push(DeviceContextMenu.Delete);
          break;
        case DeviceStates.Unassigned:
          // not allow when unassigned
          actions.push(DeviceContextMenu.Details);
          actions.push(DeviceContextMenu.Unassign);
          break;
      }

      // if device is not an automation
      if (object.deviceModel && object.deviceModel.deviceTypeKey !== 'a') {
        actions.push(DeviceContextMenu.ShowManagedDevices);
      }
    } else if (object.type === ObjectTypes.Location) {
      // Location object context menu
      switch (object.locationState) {
        case LocationStates.Assigned:
        case LocationStates.Alert:
          // not allow when assigned or alert
          actions.push(LocationContextMenu.Assign);
          actions.push(LocationContextMenu.Delete);
          break;
        case LocationStates.Unassigned:
          // not allow when unassigned
          actions.push(LocationContextMenu.Details);
          actions.push(LocationContextMenu.ApplyRule);
          actions.push(LocationContextMenu.Unassign);
          break;
      }
    }
    this.unallowActions = actions;
    return actions;
  }

  isMenuEnabled(action) {
    if (this.unallowActions.indexOf(action) >= 0) {
      return false;
    }
    return true;
  }

  addObjectMenuHandler(objectType: string) {
    this.hideContextMenu();

    if (objectType === ObjectTypes.Location) {
      // add location object
      this.openLocationShapeForm();
    } else if (objectType === ObjectTypes.Device) {
      // add device object
      this.openDeviceObjectForm();
    }
  }

  deviceMenuHandler(action: string) {
    this.hideContextMenu();
    // this.disableObjectsEvent();

    switch (action) {
      case DeviceContextMenu.Details:
        this.openDeviceDetails();
        break;
      case DeviceContextMenu.ShowManagedDevices:
        this.showManagedDevices();
        break;
      case DeviceContextMenu.Assign:
        this.openDeviceAssignForm();
        break;
      case DeviceContextMenu.Move:
        this.moveDevice();
        break;
      case DeviceContextMenu.Unassign:
        this.openDeviceUnassignForm();
        break;
      case DeviceContextMenu.Delete:
        this.deleteObject(this.selectedObject);
        break;
    }
  }

  locationMenuHandler(action: string) {
    this.hideContextMenu();
    // this.disableObjectsEvent();

    switch (action) {
      case LocationContextMenu.Details:
        this.openLocationDetails();
        break;
      case LocationContextMenu.ApplyRule:
        this.openLocationApplyRuleForm();
        break;
      case LocationContextMenu.Assign:
        this.openLocationAssignForm();
        break;
      case LocationContextMenu.MoveAndResize:
        this.resizePolygon();
        break;
      case LocationContextMenu.Reshape:
        this.reshapePolygon();
        break;
      case LocationContextMenu.Unassign:
        this.openLocationUnassignForm();
        break;
      case LocationContextMenu.Delete:
        this.deleteObject(this.selectedObject);
        break;
      case LocationContextMenu.AddPoint:
        this.addPolygonPoint();
        break;
      case LocationContextMenu.RemovePoint:
        this.removePolygonPoint();
        break;
        // case LocationContextMenu.EditSummary:
        //   this.openEditSumamryForm();
        break;
    }
  }

  private openLocationDetails() {
    const location = this.locations.find((l) => l.idx === this.selectedObject.id);
    if (!location) {
      return;
    }

    const dialogOption: any = {};
    dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_LOCATION_DETAILS_MODAL_TITLE');
    // dialogOption.closable = false;
    dialogOption.closeOnEscape = false;
    dialogOption.modal = false;
    dialogOption.data = {
      location,
      summaryConfig: this.selectedObject.summaryConfig,
      locationProperties: this.locationProperties,
      locationTypes: this.locationTypes,
      devices: this.devices.filter((item) => item.isActive && !item.isDeleted && item.locationIdx === location.idx),
    };
    this.locationDetailsModal = this.dialogService.open(LocationDetailsModalComponent, dialogOption);
    this.subscribers.locationDetailsModal = this.locationDetailsModal.onClose.subscribe((result: any) => {
      // update summart config
      if (result.summaryConfig) {
        this.selectedObject.summaryConfig = result.summaryConfig;
      }
      // clear selection on current location
      this.deselectSelectedObject();

      // open device detail modal
      if (result.deviceId) {
        const device = this.getDeviceObjects(result.deviceId)[0];
        if (device) {
          this.timeouts.switchToDeviceDetails = setTimeout(() => {
            this.canvas.setActiveObject(device);
            this.openDeviceDetails();
          }, 1000);
        }
      }
    });
  }

  private openLocationApplyRuleForm() {
    const dialogOption: any = {};
    dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_APPLY_RULE_FORM_TITLE');
    dialogOption.styleClass = 'fullscreen';
    dialogOption.data = {
      location: this.selectedObject,
    };

    this.applyRuleForm = this.dialogService.open(ApplyRuleFormComponent, dialogOption);

    this.subscribers.applyRuleForm = this.applyRuleForm.onClose.subscribe((ruleTemplate: any) => {
      // console.log(ruleTemplate);
    });
  }

  private openLocationShapeForm() {
    const dialogOption: any = {};
    dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_LOCATION_SHAPE_FORM_TITLE');
    // dialogOption.contentStyle = '"max-width.px":800';
    // dialogOption.style = 'width:700px';
    dialogOption.styleClass = 'location-shape-modal';

    this.locationShapeForm = this.dialogService.open(LocationShapeFormComponent, dialogOption);
    this.subscribers.locationShapeForm = this.locationShapeForm.onClose.subscribe((result: any) => {
      let points;

      switch (result) {
        case LocationShapes.Draw:
          // switch to draw mode
          this.toggleDrawPolygon();
          break;
        case LocationShapes.Rectangle:
          points = [
            { left: 0, top: 0 },
            { left: 200, top: 0 },
            { left: 200, top: 100 },
            { left: 0, top: 100 },
          ];
          break;
        case LocationShapes.Irregular:
          points = [
            { left: 0, top: 25 },
            { left: 200, top: 0 },
            { left: 200, top: 100 },
            { left: 0, top: 100 },
          ];
          break;
        case LocationShapes.Trapezoid:
          points = [
            { left: 0, top: 0 },
            { left: 200, top: 0 },
            { left: 175, top: 100 },
            { left: 25, top: 100 },
          ];
          break;
        case LocationShapes.Parallelogram:
          points = [
            { left: 25, top: 0 },
            { left: 225, top: 0 },
            { left: 200, top: 100 },
            { left: 0, top: 100 },
          ];
          break;
        case LocationShapes.Hexagon:
          points = [
            { left: 50, top: 0 },
            { left: 150, top: 0 },
            { left: 200, top: 50 },
            { left: 150, top: 100 },
            { left: 50, top: 100 },
            { left: 0, top: 50 },
          ];
          break;
        case LocationShapes.Triangle:
          points = [
            { left: 100, top: 0 },
            { left: 200, top: 100 },
            { left: 0, top: 100 },
          ];
          break;
        case LocationShapes.Lshape:
          points = [
            { left: 0, top: 0 },
            { left: 75, top: 0 },
            { left: 75, top: 100 },
            { left: 150, top: 100 },
            { left: 150, top: 175 },
            { left: 0, top: 175 },
          ];
          break;
        case LocationShapes.Tshape:
          points = [
            { left: 0, top: 0 },
            { left: 200, top: 0 },
            { left: 200, top: 50 },
            { left: 125, top: 50 },
            { left: 125, top: 150 },
            { left: 75, top: 150 },
            { left: 75, top: 50 },
            { left: 0, top: 50 },
          ];
          break;
        case LocationShapes.Irregular2:
          points = [
            { left: 80, top: 0 },
            { left: 175, top: 0 },
            { left: 175, top: 60 },
            { left: 125, top: 60 },
            { left: 125, top: 175 },
            { left: 50, top: 175 },
            { left: 50, top: 60 },
            { left: 80, top: 60 },
          ];
          break;
      }

      if (points && points.length) {
        // update coordinates based on pointer position
        for (const p of points) {
          p.left += this.lastCoords.x;
          p.top += this.lastCoords.y;
        }
        this.createLocationObject(points);
      }
    });
  }

  private openLocationAssignForm() {
    const dialogOption: any = {};
    dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_LOCATION_ASSIGN_FORM_TITLE');
    dialogOption.data = {
      locations: this.locations,
      floor: this.floor,
    };

    this.locationAssignForm = this.dialogService.open(LocationAssignFormComponent, dialogOption);

    this.subscribers.locationAssignForm = this.locationAssignForm.onClose.subscribe((location: any) => {
      this.selectedObject.id = location.idx;
      this.selectedObject.label = location.description;
      this.selectedObject.setLocationState(LocationStates.Assigned);
      this.canvas.renderAll();
      this.deselectSelectedObject();
    });
  }

  private openLocationUnassignForm() {
    this.confirmationService.confirm({
      // TODO: update message
      header: 'Confirmation',
      message: 'Are you sure that you want to proceed?',
      accept: () => {
        this.selectedObject.id = new Date().getTime();
        this.selectedObject.label = null;
        this.selectedObject.setLocationState(LocationStates.Unassigned);
        this.canvas.renderAll();
        this.deselectSelectedObject();
      },
    });
  }

  // private openEditSumamryForm() {
  //   const dialogOption: any = {};
  //   dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_LOCATION_SUMAMRY_FORM_TITLE');
  //   dialogOption.data = {
  //     summaryConfig: this.selectedObject.summaryConfig,
  //     locationProperties: this.locationProperties,
  //   };
  //   this.locationSummaryForm = this.dialogService.open(LocationSummaryFormComponent, dialogOption);
  //   this.locationSummaryForm.onClose.subscribe((result: any) => {
  //     if (result) {
  //       this.selectedObject.summaryConfig = result;
  //     }
  //     this.deselectSelectedObject();
  //   });
  // }

  private reshapePolygon() {
    this.hideContextMenu();

    if (!this.selectedObject) {
      return;
    }

    this.isModifyingObject = true;

    // disable moving, rotation, and scaling
    this.selectedObject.edit = true;
    this.selectedObject.cornerStyle = 'circle';
    this.selectedObject.hasBorders = false;
    this.selectedObject.hasControls = true;
    this.selectedObject.lockMovementX = true;
    this.selectedObject.lockMovementY = true;
    this.selectedObject.lockRotation = true;
    this.selectedObject.lockScalingX = true;
    this.selectedObject.lockScalingY = true;
    this.selectedObject.moveable = false;

    // update control points
    this.setCustomControlPoints(this.selectedObject);

    this.canvas.renderAll();
  }

  private resizePolygon() {
    this.hideContextMenu();

    if (!this.selectedObject) {
      return;
    }

    this.isModifyingObject = true;

    // enable moving, rotation, and scaling
    this.selectedObject.edit = false;
    this.selectedObject.controls = fabric.Object.prototype.controls;
    this.selectedObject.cornerStyle = 'rect';
    this.selectedObject.hasBorders = true;
    this.selectedObject.hasControls = true;
    this.selectedObject.lockMovementX = false;
    this.selectedObject.lockMovementY = false;
    this.selectedObject.lockScalingX = false;
    this.selectedObject.lockScalingY = false;
    this.selectedObject.moveable = true;
    // hide rotating control
    this.selectedObject.setControlsVisibility({ mtr: false });
    this.canvas.renderAll();
  }

  private resetPolygonControl() {
    if (this.selectedObject && this.selectedObject.edit) {
      this.selectedObject.edit = false;
      this.selectedObject.controls = fabric.Object.prototype.controls;
      this.selectedObject.cornerStyle = 'rect';
    }

    this.selectedObject.hasBorders = false;
    this.selectedObject.hasControls = false;
    this.selectedObject.lockMovementX = true;
    this.selectedObject.lockMovementY = true;
    this.selectedObject.lockRotation = true;
    this.selectedObject.lockScalingX = true;
    this.selectedObject.lockScalingY = true;
    this.selectedObject.moveable = false;
  }

  private addPolygonPoint() {
    this.hideContextMenu();

    if (!this.selectedObject) {
      return;
    }

    // get current points
    const points = this.selectedObject.get('points');
    // find the new point coordinate
    const nextPointIndex = (this.selectedPointIndex + 1) % points.length;
    const newPointX = (points[nextPointIndex].x + points[this.selectedPointIndex].x) / 2;
    const newPointY = (points[nextPointIndex].y + points[this.selectedPointIndex].y) / 2;

    // add new point next to the selected point
    points.splice(this.selectedPointIndex + 1, 0, {
      x: newPointX,
      y: newPointY,
    });

    if (points.length) {
      // update points
      this.selectedObject.set({ points: points });
      // update control points
      this.setCustomControlPoints(this.selectedObject);
      // update dimensions
      this.selectedObject._setPositionDimensions({});
    }

    this.canvas.renderAll();
  }

  private removePolygonPoint() {
    this.hideContextMenu();

    if (!this.selectedObject) {
      return;
    }

    // get current points
    const points = this.selectedObject.get('points');
    // remove selected point
    points.splice(this.selectedPointIndex, 1);

    if (points.length) {
      // update points
      this.selectedObject.set({ points: points });
      // update control points
      this.setCustomControlPoints(this.selectedObject);
      // update dimensions
      this.selectedObject._setPositionDimensions({});
    }

    this.canvas.renderAll();
  }

  private deleteObject(object) {
    // clear selection and remove the selected object
    this.deselectSelectedObject();
    if (object.type === ObjectTypes.Device && object.deviceScope) {
      // delete an exists device scope
      this.canvas.remove(object.deviceScope);
    }
    this.canvas.remove(object);
    this.canvas.renderAll();
  }

  private openDeviceObjectForm() {
    if (
      !this.lastCoords ||
      !this.selectedObject ||
      this.selectedObject.type !== ObjectTypes.Location ||
      this.selectedObject.locationState !== LocationStates.Assigned
    ) {
      return;
    }

    // create device object to location
    const dialogOption: any = {};
    dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_DEVICE_OBJECT_FORM_TITLE');

    this.deviceObjectForm = this.dialogService.open(DeviceObjectFormComponent, dialogOption);
    this.subscribers.deviceObjectForm = this.deviceObjectForm.onClose.subscribe((deviceModel: DeviceModel) => {
      const device = this.createDeviceObject(this.lastCoords.x, this.lastCoords.y, {
        deviceModel: deviceModel.id,
        location: this.selectedObject.id, // location idx
      });
    });
  }

  private openDeviceDetails() {
    if (!this.devices) {
      return;
    }

    const vdh = this.devices.find((d) => d.idx === this.selectedObject.id);

    if (!vdh) {
      return;
    }

    // const vds = this.devices.filter((d) => d.virtualDeviceHolderId === this.selectedObject.id);
    const vds = this.devices.filter((d) => d.parentIdx === this.selectedObject.id);
    const dialogOption: any = {};
    dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_DEVICE_DETAILS_MODAL_TITLE');
    dialogOption.data = {
      vdh,
      vds,
      dhl: this.selectedObject.dhl,
      devices: this.devices,
      deviceModels: this.deviceModels,
    };

    this.deviceDetailsModal = this.dialogService.open(DeviceDetailsModalComponent, dialogOption);
    this.subscribers.deviceDetailsModal = this.deviceDetailsModal.onClose.subscribe((result: any) => {
      this.deselectSelectedObject();
    });
  }

  private openDeviceAssignForm() {
    const dialogOption: any = {};
    dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_DEVICE_ASSIGN_FORM_TITLE');
    dialogOption.data = {
      locations: this.locations,
      floor: this.floor,
      devices: this.devices,
      selectedModel: this.selectedObject.deviceModel,
    };

    this.deviceAssignForm = this.dialogService.open(DeviceAssignFormComponent, dialogOption);
    this.subscribers.deviceAssignForm = this.deviceAssignForm.onClose.subscribe((device: any) => {
      if (device) {
        // select device from list
        this.selectedObject.id = device.idx || device.id;
        this.selectedObject.automation = device.automationId;
        this.selectedObject.installationHeight = device.installationHeight;
        this.selectedObject.setDeviceState(DeviceStates.Assigned);
        this.createDeviceScope(this.selectedObject);
        this.canvas.renderAll();
        this.deselectSelectedObject();
      } else {
        // create device
        // TODO: pass select device model to the modal
        this.timeouts.openWizardForm = setTimeout(() => {
          // this.deviceWizardForm = this.dialogService.open(CreateWizardComponent, {
          //   header: this.sharedService.getTranslation('DEVICE_WIZARD_MODAL_TITLE'),
          //   styleClass: 'device-wizard-modal',
          //   data: {
          //     selectedLocation: this.selectedObject.location,
          //     selectedModel: this.selectedObject.deviceModel,
          //     deviceModels: this.deviceModels,
          //     deviceTypes: this.deviceTypes,
          //     deviceProtocols: this.deviceProtocols,
          //     protocolControllers: this.protocolControllers,
          //   },
          // });
          // console.log('selectedObject:', this.selectedObject);
          const selectedLocation = this.locations.find((item) => item.idx === this.selectedObject.location);
          const selectedModel = this.deviceModels.find((item) => item.id === this.selectedObject.deviceModel);
          // console.log('selectedLocation:', selectedLocation);
          // console.log('selectedModel:', selectedModel);
          this.deviceWizardForm = this.dialogService.open(DeviceFormComponent, {
            showHeader: false,
            styleClass: 'no-padding-content',
            data: { selectedLocation, selectedModel },
          });

          this.subscribers.deviceWizardForm = this.deviceWizardForm.onClose.subscribe((result: any) => {
            // console.log(result);
            // if (result && result.length) {
            //   this.devices.push(result[0]);
            //   this.selectedObject.id = result[0].idx || result[0].id;
            //   this.selectedObject.automation = result[0].automationId;
            //   this.selectedObject.installationHeight = result[0].installationHeight;
            //   this.selectedObject.setDeviceState(DeviceStates.Assigned);
            //   this.createDeviceScope(this.selectedObject);
            //   this.canvas.renderAll();
            //   this.deselectSelectedObject();
            // }
            if (result && result.data && result.action === 'CREATE') {
              const dev = result.data;
              this.devices.push(dev);
              this.selectedObject.id = dev.idx || dev.id;
              this.selectedObject.automation = dev.automationId;
              this.selectedObject.installationHeight = dev.installationHeight || null;
              this.selectedObject.setDeviceState(DeviceStates.Assigned);
              this.createDeviceScope(this.selectedObject);
              this.canvas.renderAll();
              this.deselectSelectedObject();
            }
          });
        }, 1000);
      }
    });
  }

  private openDeviceUnassignForm() {
    this.confirmationService.confirm({
      // TODO: update message
      header: 'Confirmation',
      message: 'Are you sure that you want to proceed?',
      accept: () => {
        this.selectedObject.id = new Date().getTime();
        this.selectedObject.setDeviceState(DeviceStates.Unassigned);
        this.canvas.renderAll();
        this.deselectSelectedObject();
      },
    });
  }

  private moveDevice() {
    this.isModifyingObject = true;

    // enable moving
    this.selectedObject.hasBorders = true;
    this.selectedObject.hasControls = true;
    this.selectedObject.lockMovementX = false;
    this.selectedObject.lockMovementY = false;
    this.selectedObject.moveable = true;

    this.canvas.renderAll();
  }

  private showManagedDevices() {
    const managedDevices = this.getDeviceObjects(null, this.selectedObject.id);
    const relationships = [];
    for (const md of managedDevices) {
      const points = [this.selectedObject.left, this.selectedObject.top, md.left, md.top];
      const relationship = new fabric.DeviceRelationshipObject(points);

      // store relationship line in device for can update it when moving object
      md.relationships = [relationship];
      relationships.push(relationship);
      this.canvas.add(relationship);
      relationship.moveTo(this.findFirstObjectZIndex(ObjectTypes.Device));
    }

    // store relationship lines in automation for can update it when moving object
    this.selectedObject.relationships = relationships;

    this.clearAutoSelectObjectOnContextMenu();
    this.canvas.renderAll();
  }

  private getDeviceObjects(device?: string, automation?: string) {
    // get all devices or by automation id
    const devices = [];
    const objects = this.canvas.getObjects();
    for (const o of objects) {
      if (o.type === ObjectTypes.Device) {
        const skip = (device && device !== o.id + '') || (automation && automation !== o.automation);
        if (!skip) {
          devices.push(o);
        }
      }
    }
    return devices;
  }

  private getLocationObjects(location?: string) {
    // get all locations or by id
    const locations = [];
    const objects = this.canvas.getObjects();
    for (const o of objects) {
      if (o.type === ObjectTypes.Location) {
        const skip = location && location !== o.id + '';
        if (!skip) {
          locations.push(o);

          if (location) {
            break;
          }
        }
      }
    }
    return locations;
  }

  private getObjectZIndex(object) {
    return this.canvas.getObjects().indexOf(object);
  }

  private findFirstObjectZIndex(objectType) {
    const objects = this.canvas.getObjects();
    let zindex = 0;
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].type === objectType) {
        zindex = i;
        break;
      }
    }
    return zindex;
  }

  private findLastObjectZIndex(objectType) {
    const objects = this.canvas.getObjects();
    let zindex = 0;
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].type !== objectType) {
        continue;
      }
      zindex = i;
    }
    return zindex;
  }

  private findIndexOfEventObject(object) {
    return (element) => element.id === object.id;
  }

  private animateEventObj(eventObject, attributes, sourceObject) {
    eventObject.animate(
      {
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        ...attributes,
      },
      {
        duration: 1000,
        easing: fabric.util.ease.easeOutCubic,
        onChange: this.canvas.renderAll.bind(this.canvas),
        onComplete: () => {
          this.canvas.renderAll();

          this.timeouts[eventObject.id] = setTimeout(() => {
            // remove event from canvas
            this.canvas.remove(eventObject);

            // remove event from source
            const eIndex = sourceObject.activeEvents.findIndex(this.findIndexOfEventObject(eventObject));
            if (eIndex >= 0) {
              sourceObject.activeEvents.splice(eIndex, 1);
            }
            this.canvas.renderAll();
          }, 5000);
        },
      }
    );
    this.canvas.renderAll();
  }

  private createEventObject(options, data) {
    const eventObject = new fabric.EventObject({
      opacity: 0,
      scaleX: 0,
      scaleY: 0,
      ...options,
      label: data.label,
      icon: data.icon,
      value: data.value,
      unit: data.unit,
      showValue: data.showValue,
    });

    // add to canvas
    this.canvas.add(eventObject);
    return eventObject;
  }

  private adjustActiveEventsPosition(object) {
    if (!object || !object.activeEvents || !object.activeEvents.length) {
      return;
    }

    for (let i = 1; i < object.activeEvents.length; i++) {
      const activeEvent = object.activeEvents[i];
      activeEvent.showTriangle = false;
      activeEvent.top -= 30;
    }
  }

  private updateActiveEventPosition(object) {
    if (!object || !object.activeEvents || !object.activeEvents.length) {
      return;
    }

    const animateAttributes = { top: '-=30' };
    for (let i = 0; i < object.activeEvents.length; i++) {
      this.animateEventObj(object.activeEvents[i], animateAttributes, object);
    }
  }

  private createDeviceEvents() {
    if (this.isInitializing || !this.deviceEvents || !this.deviceEvents.length) {
      return;
    }

    const devices = this.getDeviceObjects();

    for (const device of devices) {
      if (device.deviceState === DeviceStates.Unassigned) {
        continue;
      }

      for (let i = 0; i < this.deviceEvents.length; i++) {
        const dhl = this.deviceEvents[i];

        if (dhl.parentIdx !== device.id && dhl.virtualDeviceHolderId + '' !== device.id + '') {
          continue;
        }

        // create device event
        const deviceEvent = this.createEventObject(
          {
            id: new Date().getTime() + '_' + device.id,
            left: device.left,
            top: device.top,
            eventType: ObjectTypes.Device,
          },
          dhl
        );

        // store event in activeEvents of device object
        device.activeEvents.unshift(deviceEvent);

        // adjust all exist active events position
        if (i > 0) {
          // skip first because it is in good position.
          this.adjustActiveEventsPosition(device);
        }
      }

      // update all events position (animate move up)
      this.updateActiveEventPosition(device);
    }
  }

  private createLocationEvents() {
    if (this.isInitializing || !this.locationEvents || !this.locationEvents.length) {
      return;
    }

    const locations = this.getLocationObjects();

    for (const location of locations) {
      if (location.locationState === LocationStates.Unassigned) {
        continue;
      }

      // get center point of location
      const centerPoint = location.getCenterPoint();

      for (let i = 0; i < this.locationEvents.length; i++) {
        const lhl = this.locationEvents[i];

        if (lhl.locationId + '' !== location.id + '') {
          continue;
        }

        // create a location event
        const locationEvent = this.createEventObject(
          {
            id: new Date().getTime() + '_' + location.id,
            left: centerPoint.x,
            top: centerPoint.y + location.labelY - location.labelFontSize,
            eventType: ObjectTypes.Location,
          },
          lhl
        );

        // store event in activeEvents of location object
        location.activeEvents.unshift(locationEvent);

        // adjust all exist active events position
        if (i > 0) {
          // skip first because it is in good position.
          this.adjustActiveEventsPosition(location);
        }
      }

      // update all events position (animate move up)
      this.updateActiveEventPosition(location);
    }
  }

  private updateCleaningState() {
    const locations = this.getLocationObjects();
    for (const location of locations) {
      let cleaningState = CleaningStates.Unknown;
      if (location?.lhl?.makeUpStatus?.value == 'YES') {
        cleaningState = CleaningStates.Dirty;
      } else if (location?.lhl?.makeUpStatus?.value == 'NO') {
        cleaningState = CleaningStates.Clean;
      }
      location.setCleaningState(cleaningState);
    }
  }

  private updateDeviceCleaningState(deviceId, dhl) {
    const devices = this.getDeviceObjects();
    for (const device of devices) {
      if (device.id != deviceId) {
        continue;
      }

      let cleaningState = CleaningStates.Unknown;
      if (dhl.value == 'y') {
        cleaningState = CleaningStates.Dirty;
      } else if (dhl.value == 'n') {
        cleaningState = CleaningStates.Clean;
      }
      device.setCleaningState(cleaningState);
    }
  }


  private updateLocationsLHL() {
    if (this.isInitializing || !this.lhls || !this.lhls.length) {
      return;
    }

    const locations = this.getLocationObjects();
    let changeDetected = false;

    for (const location of locations) {
      for (const lhl of this.lhls) {
        if (lhl.locationId + '' !== location.id + '') {
          continue;
        }
        location.lhl = lhl;
        changeDetected = true;
        break;
      }
    }

    this.updateCleaningState();

    if (changeDetected) {
      this.canvas.renderAll();
    }
  }

  private updateLocationScale() {
    if (!this.floor || typeof this.floor.floorplanScale !== 'number') {
      return;
    }

    const locations = this.getLocationObjects();
    for (const location of locations) {
      if (location.aspectRatio !== this.floor.floorplanScale) {
        location.aspectRatio = this.floor.floorplanScale;
        location._calcArea();
      }
    }

    this.canvas.renderAll();
  }

  private updateDevicesDHL() {
    if (this.isInitializing || !this.dhls) {
      return;
    }

    const devices = this.getDeviceObjects();
    for (const device of devices) {
      let dhls = this.dhls[device.id];
      if (!dhls) {
        continue;
      }

      for (const dhl of dhls) {
        if (dhl.deviceType != 'makeup') {
          continue;
        }
        this.updateDeviceCleaningState(device.id, dhl);
        break;
      }
    }
  }

  private drawDeviceScopes() {
    if (this.isInitializing) {
      return;
    }

    const deviceObjects = this.getDeviceObjects();

    for (const device of deviceObjects) {
      // TODO: filter only Omnis sensor
      if (device.deviceModel && device.deviceModel.modelName !== 'Omnis') {
        continue;
      }
      this.createDeviceScope(device);
    }
    this.canvas.renderAll();
  }

  private createDeviceScope(device) {
    if (!this.devices || !this.floor || !device) {
      return;
    } else if (device.deviceModel && device.deviceModel.modelName !== 'Omnis') {
      return;
    }

    if (device.deviceScope) {
      this.canvas.remove(device.deviceScope);
      device.deviceScope = null;
    }

    const deviceSrc = this.devices.find((d) => d.id === device.id);
    const centerPoint = device.getCenterPoint();
    const zIndex = this.getObjectZIndex(device);

    device.installationHeight = (deviceSrc && deviceSrc.installationHeight) || device.installationHeight || 250;
    device.gridRows = device.gridRows || 8;
    device.gridColumns = device.gridColumns || 8;
    const squareSide = Math.tan((30 * Math.PI) / 180) * device.installationHeight * 2;
    // *NOTE: scale the square to floorplan scale e.g.
    //      1:floorplanScale = a:squareSide (1 x squareSide = floorplanScale x a)
    //      1:100 = a:250
    //      1 x 250 = 100 x a
    //      250 / 100 = a
    //      2.5 = a
    let scaleSquareSide = squareSide / this.floor.floorplanScale;
    let pxincm = Math.round(fabric._ppi / 2.54);
    let gridWidth = scaleSquareSide * pxincm;
    const rects = [];
    const width = gridWidth / device.gridColumns;
    const height = gridWidth / device.gridRows;
    let rowX = 0;
    let rowY = 0;

    for (let i = 0; i < device.gridRows; i++) {
      if (i > 0) {
        rowX = 0;
        rowY += height;
      }

      for (let ii = 0; ii < device.gridColumns; ii++) {
        if (ii > 0) {
          rowX += width;
        }

        const grid = new fabric.Rect({
          id: device.id + '_' + i + '_' + ii,
          left: rowX,
          top: rowY,
          width,
          height,
          fill: '#38c0ff',
          opacity: 0.6,
          stroke: '#33ccff',
        });
        grid.on('mousedown', function (e) {
          // console.log('mousedown:', device.id + '_' + i + '_' + ii + ':', e.subTargets[0]);
          console.log('I am a square number ' + (ii + 1) + ' of row ' + (i + 1) + 'from device ' + device.id);
        });
        grid.on('mouseover', function (e) {
          e.target.opacity = 1;
          e.target.canvas.renderAll();
        });
        grid.on('mouseout', function (e) {
          e.target.opacity = 0.6;
          e.target.canvas.renderAll();
        });
        rects.push(grid);
      }
    }

    const deviceScopeObject = new fabric.DeviceScopeObject(rects, {
      left: centerPoint.x,
      top: centerPoint.y,
      originX: 'center',
      originY: 'center',
      subTargetCheck: true,
      visible: this.selectedLayers.indexOf(ObjectLayers.DeviceScope) >= 0 ? true : false,
    });
    device.deviceScope = deviceScopeObject;

    // add to canvas
    this.canvas.add(deviceScopeObject);
    this.canvas.moveTo(deviceScopeObject, zIndex);
  }

  private updateLocationSummaryConfig() {
    if (this.locationProperties && this.locationProperties.length && !this.isInitializing) {
      const locations = this.getLocationObjects();
      // The following blocked the location property customization.
      // It might break something unknown.
      /*
      for (const location of locations) {
        location.summaryConfig = {
          enableCustomization: false,
          summaryItems: this.locationProperties.map((item) => ({
            id: item.id,
            key: item.key,
            hidden: true,
          })),
        };
      }
      */
    }
  }

  saveChanges() {
    if (this.isInitializing) {
      return;
    }

    const json = this.canvas.toJSON();
    delete json.background;
    delete json.backgroundImage;
    json.objects = json.objects
      .filter((o) => o.type === ObjectTypes.Device || o.type === ObjectTypes.Location)
      .map((o) => {
        delete o.dhl;
        delete o.lhl;
        return o;
      });

    this.valueChanged.emit({
      id: this.floor.id,
      floorplanStructure: json,
    });
  }
}
