<div [formGroup]="form">
  <sc-field-label
    *ngIf="showLabel"
    [for]="inputId"
    [fieldControl]="form.controls[controlName]"
    [title]="label"
    [description]="description"
    [warning]="warning"
  ></sc-field-label>

  <div *ngIf="!selectedFile" class="ui-inputgroup">
    <input
      pInputText
      type="text"
      [id]="inputId"
      [formControlName]="controlName"
      [placeholder]="placeholder"
      autocomplete="off"
    />

    <button
      pButton
      type="button"
      icon="fa fa-folder-open"
      (click)="openBrowser()"
      class="green white-text"
    ></button>
  </div>

  <div *ngIf="selectedFile" class="sc-file-selected-wrapper">
    <div class="sc-file-name sc-clickable" (click)="openBrowser()">
      <i class="fa fa-file fa-fw grey-text"></i> {{ selectedFile.name }}
    </div>

    <div class="sc-file-action">
      <i
        class="fa fa-times fa-fw sc-clickable"
        (click)="onDeselectFile()"
      ></i>
    </div>
  </div>

  <sc-field-error
    [for]="label"
    [fieldControl]="form.controls[controlName]"
  ></sc-field-error>
</div>

<p-dialog
  *ngIf="showBrowser"
  [draggable]="false"
  [(visible)]="showBrowser"
  appendTo="body"
>
  <p-header>
    {{ 'FILE_MANAGER_BROWSE_FILES' | translate }}
  </p-header>

  <div class="file-browser-container">
    <sc-file-manager
      [fileTree]="fileTree"
      [loading]="fileLoading"
      mode="browser"
      (openFile)="onOpenFile($event)"
      (selectFile)="onSelectFile($event)"
    ></sc-file-manager>
  </div>

  <p-footer>
    <button pButton type="button" class="ui-button-secondary" [label]="'CANCEL'| translate" (click)="showBrowser = false; onDeselectFile()"></button>
    <button pButton type="button" class="ui-button-success" [label]="'OK'| translate" (click)="showBrowser = false"></button>
  </p-footer>
</p-dialog>
