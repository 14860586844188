import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'sc-file-tree',
  templateUrl: './file-tree.component.html',
  styleUrls: ['./file-tree.component.scss']
})
export class FileTreeComponent implements OnInit {
  @Input()
  files: TreeNode[];

  @Output()
  selected = new EventEmitter<TreeNode>();
  @Output()
  opened = new EventEmitter<TreeNode>();

  constructor() { }

  ngOnInit() {
  }

  onSelected(event: any) {
    if (event && event.node) {
      this.selected.emit(event.node);
    }
  }

  onOpened(event: any) {
    if (event && event.node) {
      this.opened.emit(event.node);
    }
  }
}
