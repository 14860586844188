import { NgModule } from '@angular/core';
import { SCActionButtonModule } from './action-button/action-button.module';
import { SCActivateButtonModule } from './activate-button/activate-button.module';
import { SCConfirmModule } from './confirm/confirm.module';
import { SCCustomWidgetsModule } from './custom-widgets/custom-widgets.module';
import { SCFileManagerModule } from './file-manager/file-manager.module';
import { SCFormModule } from './form/form.module';
import { SCLoadingIndicatorModule } from './loading-indicator/loading-indicator.module';
import { SCModalModule } from './modal/modal.module';
import { SCQuickDateSelectModule } from './quick-date-select/quick-date-select.module';
import { SCShapesModule } from './shapes/shapes.module';
import { SCTableModule } from './table/table.module';
import { OmnisEditorModule } from './omnis-editor/omnis-editor.module';

export const MODULES: any[] = [
  SCActionButtonModule,
  SCActivateButtonModule,
  SCConfirmModule,
  SCCustomWidgetsModule,
  SCFileManagerModule,
  SCFormModule,
  SCLoadingIndicatorModule,
  SCModalModule,
  SCQuickDateSelectModule,
  SCShapesModule,
  SCTableModule,
  OmnisEditorModule,
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
})
export class SandcModule {}
