import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { GridOptions } from 'ag-grid';
import { SelectItem } from 'primeng/api';
import { BenchmarkTemplate } from '../../models/benchmark-template';
import { Benchmark } from '../../models/benchmark';
import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';

import { BenchmarkTemplatesService } from '../../services/benchmark-templates.service';
import { BenchmarksBatchService } from '../../services/benchmarks-batch.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-benchmark-analyse-form',
  // styleUrls: ['analyse-form.component.scss'],
  templateUrl: 'analyse-form.component.html'
})
export class LocationBenchmarkAnalyseFormComponent
  implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: Benchmark;
  form: FormGroup;
  errorMessage: string;
  fetchingState = 0;
  isSubmitting = false;
  gridOptions: GridOptions;

  selectItems: { [key: string]: SelectItem[] } = {};

  private dataset: any[];
  private avg: { [key: string]: any } = {};
  private sum: { [key: string]: any } = {};
  private selectedSite: Site;
  private selectedLocation: Location;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private formBuilder: FormBuilder,
    private benchmarkTemplatesService: BenchmarkTemplatesService,
    private benchmarksBatchService: BenchmarksBatchService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;

    // get location details
    this.subscribers.LOCATION_DETAIL = this.store
      .select(fromStore.getLocationDetials)
      .subscribe(result => {
        if (result) {
          this.selectedLocation = result;
        }
      });

    this.fetchBenchmarkTemplates();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchBenchmarkTemplates() {
    this.fetchingState++;
    this.subscribers.GET_BENCHMARK_TEMPLATE = this.benchmarkTemplatesService
      .getBenchmarkTemplates()
      .subscribe((result: any) => {
        const benchmarkTemplates = result.data.filter(
          (d: BenchmarkTemplate) => {
            if (d.isActive && !d.isDeleted) {
              if (d.isPublic) {
                return true;
              } else if (
                d.sites &&
                d.sites.indexOf(this.selectedSite.id) >= 0
              ) {
                return true;
              }
            }
            return false;
          }
        );
        this.selectItems.benchmarkTemplates = this.sharedService.createSelectItems(
          benchmarkTemplates,
          false
        );
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      benchmarkTemplateId: [null, Validators.required],
      from: [null, Validators.required],
      to: [null, Validators.required]
    });
  }

  onSelectDate(event) {
    this.form.patchValue({
      from: event.from,
      to: event.to
    });
  }

  submit() {
    this.isSubmitting = true;
    // reset total and average data
    this.avg = {};
    this.sum = {};

    // create request body
    const formData = { ...this.form.value };
    const options: any = {
      analyse: true,
      from: formData.from,
      to: formData.to,
      benchmarkTemplate: formData.benchmarkTemplateId,
      locations: [this.selectedLocation.id]
    };

    this.benchmarksBatchService.getBenchmarks(options).subscribe(
      (result: any) => {
        this.dataset = result.data;
        if (this.dataset && this.dataset.length) {
          this.createBenchmarkResultTable();
        }
        this.isSubmitting = false;
      },
      (error: any) => {
        // display error message and unlock the form
        this.errorMessage = error;
        this.isSubmitting = false;
      }
    );
  }

  getOutputData(benchmarkResult) {
    const infoKeys = [
      'id',
      'batch_id',
      'benchmarkTemplateId',
      'locationId',
      'siteId',
      'companyId',
      'createdAt',
      'createdBy',
      'startDate',
      'endDate'
    ];
    const outputData = [];
    for (let i = 0; i < benchmarkResult.length; i++) {
      const data = {};
      for (const key in benchmarkResult[i]) {
        if (
          benchmarkResult[i].hasOwnProperty(key) &&
          infoKeys.indexOf(key) < 0
        ) {
          data[key] = benchmarkResult[i][key];
          if (typeof data[key] === 'number') {
            this.sum[key] = this.sum[key]
              ? this.sum[key] + data[key]
              : 0 + data[key];
          }
        }
      }
      outputData.push(data);
    }

    // calculate average
    Object.keys(this.sum).forEach(val => {
      this.avg[val] = this.sum[val] / benchmarkResult.length;
    });

    return outputData;
  }

  createBenchmarkResultTable() {
    const rowData = this.getOutputData(this.dataset);
    const columnDefs = [
      {
        headerName: 'Start Time',
        colId: 'start_time',
        field: 'start_time',
        valueFormatter: params => this.sharedService.dateFormat(params.value)
      },
      {
        headerName: 'End Time',
        colId: 'end_time',
        field: 'end_time',
        valueFormatter: params => {
          if (params.data.isAvgRow) {
            return 'Average:';
          } else if (params.data.isTotalRow) {
            return 'Total:';
          }
          return this.sharedService.dateFormat(params.value);
        },
        pinnedRowCellRenderer: params => `
          <div style="text-align:right">
            ${params.valueFormatted || ''}
          </div>
        `
      },
      {
        headerName: 'Duration',
        colId: 'duration',
        field: 'duration',
        valueFormatter: params => {
          const tmp = { hours: 0, minutes: 0, seconds: 0 };
          tmp.hours = params.data.duration / 60 / 60;
          tmp.minutes = (tmp.hours - Math.floor(tmp.hours)) * 60;
          tmp.seconds = (tmp.minutes - Math.floor(tmp.minutes)) * 60;

          return (
            (tmp.hours < 10 ? '0' : '') +
            Math.floor(tmp.hours) +
            ':' +
            (tmp.minutes < 10 ? '0' : '') +
            Math.floor(tmp.minutes) +
            ':' +
            (tmp.seconds < 10 ? '0' : '') +
            Math.floor(tmp.seconds) +
            ' Hours'
          );
        },
        cellRenderer: params => `
          <div style="text-align:right">
            ${params.valueFormatted || ''}
          </div>
        `,
        pinnedRowCellRenderer: params => `
          <div style="text-align:right;color:${params.color};">
            ${params.valueFormatted || ''}
          </div>
        `,
        pinnedRowCellRendererParams: { color: 'blue' }
      }
    ];

    // create columns
    const excludeColumns = ['start_time', 'end_time', 'duration'];
    for (const key in rowData[0]) {
      if (rowData[0].hasOwnProperty(key) && excludeColumns.indexOf(key) < 0) {
        const col: any = {
          headerName: key,
          colId: key,
          field: key
        };

        const keyword = key.replace(/^(start|end)\_/i, '');

        if (
          key !== 'disabling_reason' &&
          this.sharedService.isLHLValueBoolean(keyword) === false
        ) {
          col.valueFormatter = params =>
            this.sharedService.numberFormat(params.value);
          col.cellRenderer = params => {
            let color = params.normal;
            if (params.data.isAvgRow) {
              color = params.avg;
            } else {
              if (+params.valueFormatted > this.avg[params.colDef.colId]) {
                color = params.higherAvg;
              } else if (
                +params.valueFormatted < this.avg[params.colDef.colId]
              ) {
                color = params.lowerAvg;
              }
            }

            return `
                <span style="color:${color}">
                  ${params.valueFormatted || ''}
                </span>
              `;
          };
          col.cellRendererParams = {
            avg: 'blue',
            higherAvg: 'red',
            lowerAvg: 'green',
            normal: 'black'
          };
        }

        columnDefs.push(col);
      }
    }

    this.sum.isTotalRow = true;
    this.avg.isAvgRow = true;

    this.gridOptions = {
      animateRows: false,
      enableColResize: true,
      enableSorting: true,
      floatingFilter: true,
      rowData,
      columnDefs,
      pinnedTopRowData: [this.sum, this.avg]
    };
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  get showResult() {
    if (this.isSubmitting === false) {
      if (!this.dataset || !this.dataset.length) {
        return -1;
      }
      return 1;
    }
    return 0;
  }
}
