import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { SelectItem } from 'primeng/api';
import { Location } from '../models/location';
import { Site } from '@widgets/sites/models/site';

import { LocationFormsService } from '../services/location-forms.service';
import { LocationTypesService } from '../services/location-types.service';
import { LocationsService } from '../services/locations.service';
import { SharedService } from '@shared/shared.service';
import { LocationType } from '../models/location-type';

@Component({
  selector: 'sc-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['location-form.component.scss'],
})
export class LocationFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: Location;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};
  backendVariant: string;

  private locationTypes: LocationType[];
  private parentLocations: Location[] = [];
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private locationTypesService: LocationTypesService,
    private locationFormsService: LocationFormsService,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;

    this.subscribers.getBackendVariant = this.store.pipe(select(fromStore.getUserVariant)).subscribe((result) => {
      this.backendVariant = result;
    });

    if (this.data && !this.data.child) {
      // EDIT MODE
      this.editMode = true;
      // FETCH LOCATION
      this.fetchLocation(this.data.id);
    }

    this.fetchParentLocations();
    this.fetchLocationTypes();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchLocation(id: number) {
    this.fetchingState++;
    this.subscribers.getLocation = this.locationsService.getLocation(id).subscribe((result: any) => {
      this.formData = result.data;
      this.form.patchValue(this.formData);
      this.fetchingState--;
    });
  }

  private fetchParentLocations() {
    this.fetchingState++;
    const options = {
      columns: 'id,description,idx,isActive,isDeleted',
      siteId: this.selectedSite.id,
    };
    this.subscribers.getParentLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
      this.parentLocations = result.data;
      this.selectItems.locations = this.sharedService.createSelectItems(result.data.filter((data) => !data.isDeleted));
      this.fetchingState--;
    });
  }

  private fetchLocationTypes() {
    this.fetchingState++;
    this.subscribers.getLocationTypes = this.locationTypesService.getLocationTypes().subscribe((result: any) => {
      this.locationTypes = result.data;
      this.selectItems.locationTypes = this.sharedService.createSelectItems(
        result.data.filter((data) => data.isActive && !data.isDeleted)
      );

      if (!this.editMode && this.data && this.data.child === true) {
        // create child location, auto select `unit` type
        const unit = result.data.find((d) => d.isActive && !d.isDeleted && d.key === 'u');
        if (this.form && unit) {
          this.form.patchValue({
            locationTypeId: unit.id,
          });
        }
      }

      this.fetchingState--;
    });
  }

  private initForm() {
    this.form = this.locationFormsService.initLocationForm();

    if (this.editMode === true) {
      // edit mode
      this.form.controls.locationTypeId.disable();
      this.form.controls.parentId.disable();
    } else {
      // create mode
      if (this.data && this.data.child === true) {
        // create child location
        this.form.patchValue({
          siteId: this.data.parentData.siteId,
          parentId: this.data.parentData.id,
        });
      } else {
        // create location
        this.form.patchValue({
          siteId: this.selectedSite.id,
        });
      }
    }

    // if floorplan image is added, required floorplan scale
    this.subscribers.floorplanImage = this.form.get('floorplanImage').valueChanges.subscribe({
      next: (result) => {
        if (result) {
          this.form.get('floorplanScale').setValidators(Validators.required);
        } else {
          this.form.get('floorplanScale').clearValidators();
        }
        this.form.get('floorplanScale').updateValueAndValidity();
      },
    });

    // force update parentIdx when parentId changes
    this.subscribers.parentId = this.form.get('parentId').valueChanges.subscribe({
      next: (result) => {
        if (result) {
          const location = this.parentLocations.find((item) => item.id === result);
          if (location) {
            const idx = location.idx || 'idx_' + location.id;
            this.form.get('parentIdx').setValue(idx);
          } else {
            this.form.get('parentIdx').setValue(null);
          }
        } else {
          this.form.get('parentIdx').setValue(null);
        }
      },
    });
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(data: Location) {
    this.subscribers.create = this.locationsService.createLocation(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(data: Location) {
    this.subscribers.update = this.locationsService.updateLocation(this.formData.id, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.locationsService.deleteLocation(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('LOCATION');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  getLocationType(): LocationType {
    if (this.locationTypes && this.locationTypes.length) {
      const formValue = this.form.getRawValue();
      const type = this.locationTypes.find((item) => item.id === formValue.locationTypeId);
      if (type) {
        return type;
      }
    }
    return;
  }

  get isFloor(): boolean {
    const type = this.getLocationType();
    return type && type.key === 'f' ? true : false;
  }

  get ceosField(): boolean {
    if (this.backendVariant === 'ceos') {
      return true;
    }
    return false;
  }
}
