<div class="modal">
  <div class="modal__header">
    <div class="modal__header__label">
      <div class="modal__header__title">
        {{ "CCP_RULE_OPERATOR_MODAL_TITLE" | translate }}
      </div>
      <div class="modal__header__description">
        {{ "CCP_RULE_OPERATOR_MODAL_DESCRIPTION" | translate }}
      </div>
    </div>

    <div class="modal__header__action">
      <sc-close-button (clicked)="close()"></sc-close-button>
    </div>
  </div>

  <div class="modal__body">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="1">
        <sc-list-select
          [items]="selectItems?.operators"
          (selectItem)="selectOperator($event)"
        ></sc-list-select>
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <ng-template [ngTemplateOutlet]="step2"></ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal__footer">
    <!-- *ngIf="isTemplate" -->
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="onResetClick()"
      label="Reset"
      *ngIf="isTemplate || isFromScratch"
    ></button>
    &nbsp;
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="onOkClick()"
      label="OK"
      [disabled]="form?.invalid"
    ></button>
  </div>
</div>

<ng-template #step2>
  <div [formGroup]="form" *ngIf="form && operatorParams?.length">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <ng-container [ngSwitch]="operatorType">
        <ng-container *ngSwitchCase="_operatorTypes?.ChangedAtLeastNtimes">
          <div class="p-field p-col-12">
            <strong>
              <small>{{ "CCP_RULE_INCLUDED_PAST_BEGINS" | translate }}</small>
            </strong>
          </div>

          <div class="p-field p-col-12">
            <div class="changed-at-least-field">
              <div class="content content--left">
                <input
                  type="radio"
                  id="previous_rule_trigger_as_included_past"
                  name="changedAtLeastNtimesOption"
                  [formControl]="changedAtLeastNtimesOption"
                  [value]="1"
                />
              </div>

              <div class="content content--right">
                <label for="previous_rule_trigger_as_included_past">
                  {{ "CCP_RULE_AT_PREVIOUS_RULE_TRIGGER" | translate }}
                </label>
              </div>
            </div>
          </div>

          <div class="p-field p-col-12">
            <div class="changed-at-least-field">
              <div class="content content--left">
                <input
                  type="radio"
                  name="changedAtLeastNtimesOption"
                  [formControl]="changedAtLeastNtimesOption"
                  [value]="2"
                />
              </div>

              <div class="content content--right">
                <div class="ui-inputgroup operator-modal-input">
                  <input
                    type="number"
                    pInputText
                    inputId="included_past"
                    formControlName="included_past"
                    min="0"
                  />
                  <span
                    class="ui-inputgroup-addon w-100 operator-modal-input-addon"
                  >
                    {{ "CCP_COMMON_SECONDS" | translate }}
                    {{ "CCP_COMMON_AGO" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="_operatorTypes?.ChangedMaxNtimes">
          <div class="p-field p-col-12">
            <strong>
              <small>{{ "CCP_RULE_INCLUDED_PAST_BEGINS" | translate }}</small>
            </strong>
          </div>

          <div class="p-field p-col-12">
            <div class="changed-max-field">
              <div class="content content--left">
                <input
                  type="radio"
                  id="previous_rule_trigger_as_included_past"
                  name="changedMaxNtimesOption"
                  [formControl]="changedMaxNtimesOption"
                  [value]="1"
                />
              </div>

              <div class="content content--right">
                <label for="previous_rule_trigger_as_included_past">
                  {{ "CCP_RULE_AT_PREVIOUS_RULE_TRIGGER" | translate }}
                </label>
              </div>
            </div>
          </div>

          <div class="p-field p-col-12">
            <div class="changed-max-field">
              <div class="content content--left">
                <input
                  type="radio"
                  name="changedMaxNtimesOption"
                  [formControl]="changedMaxNtimesOption"
                  [value]="2"
                />
              </div>

              <div class="content content--right">
                <div class="ui-inputgroup operator-modal-input">
                  <input
                    type="number"
                    pInputText
                    inputId="included_past"
                    formControlName="included_past"
                    min="0"
                  />
                  <span
                    class="ui-inputgroup-addon w-100 operator-modal-input-addon"
                  >
                    {{ "CCP_COMMON_SECONDS" | translate }}
                    {{ "CCP_COMMON_AGO" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="_operatorTypes?.Equal">
          <div class="p-field p-col-12 p-md-4">
            <label class="input-label" for="included_past">
              Included past
            </label>

            <div class="ui-inputgroup">
              <input
                type="number"
                pInputText
                id="included_past"
                formControlName="included_past"
                [min]="0"
              />

              <span class="ui-inputgroup-addon">s</span>
            </div>
          </div>

          <div class="p-field p-col-12 p-md-4">
            <label class="input-label" for="min_validity_period">
              Min. validity-period
            </label>

            <div class="ui-inputgroup operator-modal-input">
              <input
                type="number"
                pInputText
                id="min_validity_period"
                formControlName="min_validity_period"
                [min]="0"
              />

              <span class="ui-inputgroup-addon operator-modal-input-addon"
                >s</span
              >
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div
            class="p-field p-col-12 p-md-4"
            *ngFor="let param of operatorParams"
          >
            <label
              class="input-label"
              [for]="param?.id"
              *ngIf="param?.value?.type !== 'checkbox'"
            >
              {{ param?.label }}
            </label>

            <div [ngClass]="{ 'ui-inputgroup': param?.value?.unit }">
              <ng-container [ngSwitch]="param?.value?.type">
                <ng-container *ngSwitchCase="'numeric'">
                  <input
                    type="number"
                    pInputText
                    [id]="param?.id"
                    [formControlName]="param?.id"
                    [min]="param?.value?.min"
                    [max]="param?.value?.max"
                  />
                </ng-container>

                <ng-container *ngSwitchCase="'select'">
                  <p-dropdown
                    [inputId]="param?.id"
                    [formControlName]="param?.id"
                    [options]="param?.value?.options"
                    placeholder="Select"
                    [filter]="true"
                  ></p-dropdown>
                </ng-container>

                <ng-container *ngSwitchCase="'checkbox'">
                  <p-checkbox
                    [inputId]="param?.id"
                    [formControlName]="param?.id"
                    [binary]="true"
                    [label]="param.label"
                  ></p-checkbox>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <input
                    type="text"
                    pInputText
                    [id]="param?.id"
                    [formControlName]="param?.id"
                  />
                </ng-container>
              </ng-container>

              <span class="ui-inputgroup-addon" *ngIf="param?.value?.unit">
                {{ param?.value?.unit }}
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="p-fluid p-formgrid p-grid p-inputtext-sm" *ngIf="isTemplate">
      <ng-container [ngSwitch]="operatorType">
        <ng-container *ngSwitchCase="_operatorTypes?.ChangedAtLeastNtimes">
          <div
            class="p-field p-col-12 p-md-4"
            *ngIf="changedAtLeastNtimesOption?.value === 2"
          >
            <p-checkbox
              inputId="is_included_past_editable"
              [binary]="true"
              [formControl]="form?.get('is_included_past_editable')"
              [label]="'EDITABLE' | translate"
            ></p-checkbox>
          </div>

          <!-- <div class="p-field p-col-12 p-md-4">
            <p-checkbox
              inputId="is_previous_rule_trigger_as_included_past_editable"
              [binary]="true"
              [formControl]="
                form?.get('is_previous_rule_trigger_as_included_past_editable')
              "
              [label]="'EDITABLE' | translate"
            ></p-checkbox>
          </div> -->
        </ng-container>

        <ng-container *ngSwitchCase="_operatorTypes?.Equal">
          <div class="p-field p-col-12 p-md-4">
            <p-checkbox
              inputId="is_included_past_editable"
              [binary]="true"
              [formControl]="form?.get('is_included_past_editable')"
              [label]="'EDITABLE' | translate"
            ></p-checkbox>
          </div>

          <div class="p-field p-col-12 p-md-4">
            <p-checkbox
              inputId="is_min_validation_period_editable"
              [binary]="true"
              [formControl]="form?.get('is_min_validation_period_editable')"
              [label]="'EDITABLE' | translate"
            ></p-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
