import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { UserTaskTemplatesTableComponent } from './user-task-templates-table/user-task-templates-table.component';
import { UserTaskTemplateFormComponent } from './user-task-template-form/user-task-template-form.component';
import { UserTaskTemplateActionCellComponent } from './user-task-template-action-cell/user-task-template-action-cell.component';
import { UserTaskTemplateFormService } from './services/user-task-template-form.service';
import { UserTaskTemplatesService } from './services/user-task-templates.service';

@NgModule({
  imports: [SharedModule],
  declarations: [UserTaskTemplatesTableComponent, UserTaskTemplateActionCellComponent, UserTaskTemplateFormComponent],
  entryComponents: [
    UserTaskTemplatesTableComponent,
    UserTaskTemplateActionCellComponent,
    UserTaskTemplateFormComponent,
  ],
  providers: [UserTaskTemplateFormService, UserTaskTemplatesService],
})
export class UserTaskTemplatesModule {}
