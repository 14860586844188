<div [formGroup]="group">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-6">{{ group?.value?.key | translate }}</div>
    <div class="ui-g-6">
      <div [ngSwitch]="group?.value?.key">
        <div *ngSwitchCase="'deviceId'">
          <sc-dropdown
            controlName="value"
            [form]="group"
            label="{{ 'DEVICE' | translate }}"
            [options]="devices"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <div *ngSwitchDefault>
          <sc-text-input
            controlName="value"
            [form]="group"
            label="{{ 'VALUE' | translate }}"
            [showLabel]="false"
          ></sc-text-input>
        </div>
      </div>
    </div>
  </div>
</div>
