import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RuleTemplate } from '../models/rule-template';

import { config } from '@app/config';

@Injectable()
export class RuleTemplatesService {
  private apiUrl = config().apiUrl + '/ruleTemplates';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): RuleTemplate {
    const newData = { ...data };
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  createRuleTemplate(data: RuleTemplate) {
    return this.httpClient.post(this.apiUrl, data);
  }

  getRuleTemplates(id?: number) {
    return this.httpClient.get(this.apiUrl + (id ? '/' + id : ''));
  }

  updateRuleTemplate(id: number, data: RuleTemplate) {
    return this.httpClient.patch(this.apiUrl + '/' + id, this.verifyData(data));
  }

  deleteRuleTemplate(id: number) {
    return this.httpClient.patch(this.apiUrl + '/' + id, {
      isActive: false,
      isDeleted: true
    });
  }
}
