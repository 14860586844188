import { Component, OnInit, Input } from '@angular/core';

import { RuleCommand } from '../../models';
import { RulesService, UtilsService } from '../../services';
import { DataSources, SelectItems, RuleTemplateCommand } from '../../models';

@Component({
  selector: 'sc-rule-commands',
  templateUrl: './rule-commands.component.html',
  styleUrls: ['./rule-commands.component.scss'],
})
export class RuleCommandsComponent implements OnInit {
  @Input()
  commands: RuleCommand[];

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  viewMode: string;

  @Input()
  isTemplate: boolean;

  @Input()
  isFromScratch: boolean;

  @Input()
  templateCommands: RuleTemplateCommand[];

  constructor(private rulesService: RulesService, private utilsService: UtilsService) {}

  ngOnInit(): void {}

  addCommand() {
    if (!this.commands) {
      this.commands = [];
    }
    this.commands.push(this.rulesService.initRuleCommand(this.isTemplate));
  }

  onCommandMovedUp(index: number) {
    this.utilsService.arrayMove(this.commands, index, index - 1);
  }

  onCommandMovedDown(index: number) {
    this.utilsService.arrayMove(this.commands, index, index + 1);
  }

  onCommandRemoved(index: number) {
    this.commands.splice(index, 1);
  }
}
