import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { DeviceTask } from '../models/device-task';
import {
  DeviceTaskTemplate,
  DeviceTaskTemplateParameter
} from '@widgets/device-task-templates/models/device-task-template';
import { SelectItem } from 'primeng/api';
import { Site } from '@widgets/sites/models/site';

import { DeviceTaskFormsService } from '../services/device-task-form.service';
import { DeviceTasksService } from '../services/device-task.service';
import { DeviceTaskTemplatesService } from '@widgets/device-task-templates/services/device-task-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-task-form',
  templateUrl: './device-task-form.component.html',
  styleUrls: ['./device-task-form.component.scss']
})
export class DeviceTaskFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: DeviceTask;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};
  rawData: { [key: string]: any } = {};
  currentSite: Site;
  templateParameters: DeviceTaskTemplateParameter[];
  selectedTemplate: DeviceTaskTemplate;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceTaskFormsService: DeviceTaskFormsService,
    private deviceTasksService: DeviceTasksService,
    private deviceTaskTemplatesService: DeviceTaskTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.currentSite = this.sharedService.selectedSite;
    this.fetchTaskTemplates();

    if (this.data && this.data.id) {
      // EDIT MODE
      this.editMode = true;
      // FETCH FORM DATA
      this.fetchTask(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchTask(id: number) {
    this.fetchingState++;
    this.subscribers.GET_TASK = this.deviceTasksService
      .getDeviceTask(id)
      .subscribe((result: any) => {
        this.formData = result.data;

        // Parse date string to date object
        if (this.formData.published_from) {
          this.formData.published_from = this.sharedService.parseDateStringToDateObject(
            this.formData.published_from
          );
        }
        if (this.formData.published_to) {
          this.formData.published_to = this.sharedService.parseDateStringToDateObject(
            this.formData.published_to
          );
        }

        this.formData.parameters = this.formData.parameters
          ? this.sharedService.jsonParse(this.formData.parameters)
          : [];

        this.form.patchValue(this.formData);
        this.deviceTaskFormsService.setParameterFormArray(
          this.form,
          this.formData.parameters
        );

        this.fetchingState--;
      });
  }

  private fetchTaskTemplates() {
    this.fetchingState++;
    this.subscribers.GET_TASK_TEMPLATES = this.deviceTaskTemplatesService
      .getDeviceTaskTemplates()
      .subscribe((result: any) => {
        const templates = [];

        for (let i = 0; i < result.data.length; i++) {
          const d = result.data[i];
          if (!d.is_active || d.is_deleted) {
            continue;
          }

          d.site_ids = d.site_ids
            ? this.sharedService.jsonParse(d.site_ids)
            : [];

          if (d.is_public || d.site_ids.indexOf(this.currentSite.id) >= 0) {
            d.parameters = d.parameters
              ? this.sharedService.jsonParse(d.parameters)
              : [];

            if (this.editMode && d.id === this.data.template_id) {
              this.onSelectTemplate(d);
            }

            templates.push(d);
          }
        }

        this.rawData.taskTemplates = templates;
        this.selectItems.taskTemplates = this.sharedService.createSelectItems(
          templates,
          false
        );

        this.fetchingState--;
      });
  }

  private initForm() {
    this.form = this.deviceTaskFormsService.initDeviceTaskForm();
    this.form.patchValue({ device_id: this.data.deviceId });

    this.subscribers.WATCH_TEMPLATE = this.form
      .get('template_id')
      .valueChanges.subscribe(value => {
        if (this.rawData && this.rawData.taskTemplates) {
          const tpl = this.rawData.taskTemplates.find(t => t.id === value);
          if (tpl) {
            this.onSelectTemplate(tpl);
          }
        }
      });
  }

  private onSelectTemplate(template: DeviceTaskTemplate) {
    this.selectedTemplate = template;
    this.templateParameters = template.parameters;
    const parameters = template.parameters.map(p => ({
      key: p.key,
      value: p.default_value
    }));
    this.deviceTaskFormsService.setParameterFormArray(this.form, parameters);
  }

  get parametersForm(): FormArray {
    return this.form.controls.parameters as FormArray;
  }

  removeParameter(i: number) {
    const control = this.form.controls.parameters as FormArray;
    control.removeAt(i);
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };
      if (formData.published_from) {
        formData.published_from = this.sharedService.parseDateObjectToDateString(
          formData.published_from
        );
      }
      if (formData.published_to) {
        formData.published_to = this.sharedService.parseDateObjectToDateString(
          formData.published_to
        );
      }

      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(data: DeviceTask) {
    this.subscribers.CREATE = this.deviceTasksService
      .createDeviceTask(data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  private update(data: DeviceTask) {
    this.subscribers.UPDATE = this.deviceTasksService
      .updateDeviceTask(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE = this.deviceTasksService
      .deleteDeviceTask(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE_TASKS');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    const data = {
      ...result.data,
      name: this.selectedTemplate.name,
      recurring: this.selectedTemplate.recurring,
      execute_instantly: this.selectedTemplate.execute_instantly,
      is_deleted: false
    };
    this.onClose.emit(data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
