<div class="ui-g">
  <div class="ui-g-12 display">
    <sc-dataset-form
      [form]="form"
      [selectItems]="selectItems"
    ></sc-dataset-form>
  </div>
</div>

<div class="ui-g">
  <div class="ui-g-6" *ngIf="currentInterval">
    {{ "CURRENT" | translate }} {{ "INTERVAL" | translate }}:
    {{ currentInterval }}
  </div>

  <div
    class="location-statistics-footer"
    [ngClass]="{ 'ui-g-12': !currentInterval, 'ui-g-6': currentInterval }"
  >
    <button
      *ngIf="dataset1 && dataset1.length"
      type="button"
      pButton
      icon="fa fa-download"
      label="{{ 'DOWNLOAD_CSV' | translate }}"
      (click)="downloadCSV()"
    ></button>

    <button
      type="button"
      pButton
      icon="fa fa-refresh {{ isFetching ? 'fa-spin' : '' }}"
      label="{{ 'UPDATE' | translate }}"
      (click)="fetchLocationStatistics()"
      [disabled]="isFetching || !form.valid"
    ></button>
  </div>
</div>

<div class="ui-g">
  <div class="ui-g-12 ui-g-nopad">
    <sc-form-error message="{{ errorMessage | translate }}"></sc-form-error>
  </div>
</div>

<div class="ui-g">
  <div
    *ngIf="charts1?.length"
    class="ui-g-nopad"
    [ngClass]="{
      'ui-g-12': !formData?.isCompare,
      'ui-g-6': formData?.isCompare
    }"
  >
    <div *ngFor="let c of charts1; let i = index">
      <plotly-plot
        [data]="c.data"
        [layout]="c.layout"
        [config]="{ displayModeBar: false }"
        (relayout)="onZoom($event)"
        (doubleClick)="onZoom($event)"
      ></plotly-plot>
      <!-- [divId]="genDivId(1, i)" -->
      <!-- (afterPlot)="onAfterPlot(1, i)" -->
      <div *ngIf="isChangingInterval" class="chart-loading-overlay">
        <span class="loading-text"> {{ "LOADING" | translate }}... </span>
      </div>
    </div>
  </div>

  <div *ngIf="formData?.isCompare && charts2?.length" class="ui-g-6 ui-g-nopad">
    <div *ngFor="let c of charts2; let i = index">
      <plotly-plot
        [data]="c.data"
        [layout]="c.layout"
        [config]="{ displayModeBar: false }"
        (relayout)="onZoom($event)"
        (doubleClick)="onZoom($event)"
      ></plotly-plot>
      <!-- [divId]="genDivId(2, i)" -->
      <!-- (afterPlot)="onAfterPlot(2, i)" -->
      <div *ngIf="isChangingInterval" class="chart-loading-overlay">
        <span class="loading-text"> {{ "LOADING" | translate }}... </span>
      </div>
    </div>
  </div>
</div>
