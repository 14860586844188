import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { Site } from '@widgets/sites/models/site';
import { TreeNode } from 'primeng/api';

import { FilesService } from './files.service';
import { FileManagerService } from '@sc/file-manager/file-manager.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  fileLoading = false;
  fileTree: TreeNode[];

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private filesService: FilesService,
    private fileManagerService: FileManagerService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    // Get Selected Site
    this.subscribers.getSelectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          this.retrieveFiles();
        }
      },
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private retrieveFiles(path: string = '', parentDir?: TreeNode) {
    if (this.fileLoading && this.subscribers.getFiles) {
      this.subscribers.getFiles.unsubscribe();
    }
    this.fileLoading = true;
    this.subscribers.getFiles = this.filesService.list(path).subscribe({
      next: (result: any) => {
        this.updateTree(result.data, parentDir);
        this.fileLoading = false;
      },
      error: (error: any) => {
        this.fileLoading = false;
      },
    });
  }

  onCopyFile(event) {
    if (this.fileLoading) {
      return;
    }
    this.fileLoading = true;
    this.subscribers.copyFile = this.filesService.copy([event.copyFile], event.copyPath).subscribe({
      next: (result: any) => {
        this.updateTree(result.data[0], event.parentDir);
        this.fileLoading = false;
      },
      error: (error: any) => {
        this.fileLoading = false;
      },
    });
  }

  onMoveFile(event) {
    if (this.fileLoading) {
      return;
    }
    this.fileLoading = true;
    this.subscribers.moveFile = this.filesService.move([event.moveFile], event.movePath).subscribe({
      next: (result: any) => {
        this.cleanupSourceDir(event.moveFile);
        this.updateTree(result.data[0], event.parentDir);
        this.fileLoading = false;
      },
      error: (error: any) => {
        this.fileLoading = false;
      },
    });
  }

  private updateTree(data, parentDir, newFolder: boolean = false) {
    const files = this.fileManagerService.createTree(data);
    if (parentDir) {
      if (newFolder) {
        parentDir.children = [...parentDir.children, ...files];
      } else {
        parentDir.children = files;
      }
    } else {
      if (newFolder) {
        this.fileTree = [...this.fileTree, ...files];
      } else {
        this.fileTree = files;
      }
    }
  }

  private cleanupSourceDir(file: TreeNode) {
    let isHome = true;
    let currentChildren = this.fileTree;
    let parentDir;

    if (file.parent) {
      isHome = false;
      parentDir = file.parent;
      currentChildren = parentDir.children;
    }

    const newChildren = [];
    for (const child of currentChildren) {
      if (child.data && child.data.key && child.data.key !== file.data.key) {
        newChildren.push(child);
      }
    }

    if (isHome) {
      this.fileTree = newChildren;
    } else {
      parentDir.children = newChildren;
    }
  }

  onDeleteFile(event) {
    if (this.fileLoading) {
      return;
    }
    this.fileLoading = true;
    this.subscribers.deleteFile = this.filesService.delete([event.deleteFile]).subscribe({
      next: (result: any) => {
        this.cleanupSourceDir(event.deleteFile);
        this.fileLoading = false;
      },
      error: (error: any) => {
        this.fileLoading = false;
      },
    });
  }

  onSelectFile(event) {}

  onOpenFile(event) {
    this.retrieveFiles(event.path, event.file);
  }

  onRenameFile(event) {
    if (this.fileLoading) {
      return;
    }
    this.fileLoading = true;
    this.subscribers.renameFile = this.filesService.rename(event.newFileName, event.file).subscribe({
      next: (result: any) => {
        event.file.data = result.data;
        event.file.label = result.data.name;
        this.fileLoading = false;
      },
      error: (error: any) => {
        this.fileLoading = false;
      },
    });
  }

  onCreateFolder(event) {
    if (this.fileLoading) {
      return;
    }
    this.fileLoading = true;
    this.subscribers.createFolder = this.filesService.createFolder(event.newFolderName, event.newFolderPath).subscribe({
      next: (result: any) => {
        this.updateTree([result.data], event.parentDir, true);
        this.fileLoading = false;
      },
      error: (error: any) => {
        this.fileLoading = false;
      },
    });
  }

  onUploadFiles(event) {
    if (this.fileLoading) {
      return;
    }
    this.fileLoading = true;
    this.subscribers.uploadFiles = this.filesService.upload(event.uploadFiles, event.uploadPath).subscribe({
      next: (result: any) => {
        this.updateTree(result.data, event.parentDir);
        this.fileLoading = false;
      },
      error: (error: any) => {
        this.fileLoading = false;
      },
    });
  }
}
