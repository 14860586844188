import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromPageTemplates from '../reducers/page-templates.reducer';

export const getPageTemplateState = createFeatureSelector<
  fromPageTemplates.PageTemplateState
>('pageTemplates');

export const getPageTemplatesEntities = createSelector(
  getPageTemplateState,
  fromPageTemplates.getPageTemplatesEntities
);

export const getAllPageTemplates = createSelector(
  getPageTemplatesEntities,
  entities => (entities ? Object.keys(entities).map(id => entities[id]) : [])
);

export const getPageTemplatesLoaded = createSelector(
  getPageTemplateState,
  fromPageTemplates.getPageTemplatesLoaded
);

export const getPageTemplatesLoading = createSelector(
  getPageTemplateState,
  fromPageTemplates.getPageTemplatesLoading
);
