<div [formGroup]="group">
  <div formGroupName="customOpts">
    <div class="ui-g-12">
      <sc-textarea
        controlName="cronPattern"
        [form]="group"
        label="{{ 'CRON_PATTERN' | translate }}"
      ></sc-textarea>
      <!-- <sc-field-label
        for="cronPattern"
        title="{{ 'CRON_PATTERN' | translate }}"
        [fieldControl]="group.controls['cronPattern']"
      ></sc-field-label>
      <textarea  id="cronPattern" pInputTextarea formControlName="cronPattern"></textarea>
      <sc-field-error
        for="{{ 'CRON_PATTERN' | translate }}"
        [fieldControl]="group.controls['cronPattern']"
      ></sc-field-error> -->
    </div>
  </div>
</div>
