<form [formGroup]="form" novalidate>
  <div class="ui-g">
    <div class="ui-g-8 ui-g-nopad">
      <h3 class="mt-0">{{ form?.value?.locationName }}</h3>
    </div>
    <div *ngIf="showApplyAll" class="ui-g-4 ui-g-nopad text-right">
      <p-checkbox
        [formControl]="applyAll"
        binary="true"
        label="{{ 'APPLY_ALL' | translate }}"
      ></p-checkbox>
    </div>

    <!-- START_CONDTIONS -->
    <div class="ui-g-12 ui-g-nopad">
      <sc-rule-condition-mass-edit-form
        [form]="form"
        [data]="data"
        [isEndCondition]="false"
      ></sc-rule-condition-mass-edit-form>
    </div>

    <!-- START_COMMANDS -->
    <div class="ui-g-12 ui-g-nopad mb-5">
      <sc-rule-command-mass-edit-form
        [form]="form"
        [data]="data"
        [isEndCommand]="false"
      ></sc-rule-command-mass-edit-form>
    </div>

    <!-- END_CONDTIONS -->
    <div class="ui-g-12 ui-g-nopad">
      <sc-rule-condition-mass-edit-form
        [form]="form"
        [data]="data"
        [isEndCondition]="true"
      ></sc-rule-condition-mass-edit-form>
    </div>

    <!-- END_COMMANDS -->
    <div class="ui-g-12 ui-g-nopad">
      <sc-rule-command-mass-edit-form
        [form]="form"
        [data]="data"
        [isEndCommand]="true"
      ></sc-rule-command-mass-edit-form>
    </div>
  </div>
</form>
