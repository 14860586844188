import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-device-type-action-parameter-form',
  templateUrl: './action-parameter-form.component.html'
})
export class DeviceTypeActionParameterFormComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Output()
  removed = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  remove() {
    this.removed.emit();
  }
}
