import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { LocationHistoryLog } from '../models/location-history-log';
import { Location } from '@widgets/locations/models/location';

import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { LocationHistoryLogsService } from '../services/location-history-logs.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-history-table',
  templateUrl: './location-history-table.component.html',
})
export class LocationHistoryTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: LocationHistoryLog[];
  table: GridOptions;
  tableTitle: string;
  dateFilter: any;
  datasource: any;
  toolbarItems: string[];

  isFetchingDataset: boolean;

  private deviceTypes: DeviceType[];
  private selectedLocation: Location;
  private subscribers: { [key: string]: any } = {};
  private visibleColumns: string[] = [];

  constructor(
    private store: Store<fromStore.State>,
    private deviceTypesService: DeviceTypesService,
    private locationHistoryLogsService: LocationHistoryLogsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.config.gridOptions = {
      floatingFilter: false,
      defaultColDef: {
        autoHeight: true,
        suppressFilter: true,
        suppressMenu: true,
        suppressSorting: true,
      },
    };
    // Set new height of widget
    // this.config.widgetHeight = this.config.widgetHeight - 25;
    // Set Table Name
    this.tableTitle = 'LOCATION_HISTORY_LOG';
    // Set Table Toolbar Items
    this.toolbarItems = ['refresh', 'columns'];
    // Set Table Columns
    this.columns = this.createColumns();
    // Set user's visible columns
    if (this.config && this.config.customOptions && this.config.customOptions.visibleColumns) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }

    // get location details
    this.subscribers.LOCATION_DETAIL = this.store.select(fromStore.getLocationDetials).subscribe((result) => {
      if (result) {
        this.selectedLocation = result;
        this.fetchDataset();
      }
    });

    this.fetchDeviceTypes();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.datasource = {
        getRows: (rowParams) => {
          // LHL OPTIONS
          const options: any = {
            filter: rowParams.filterModel,
            sort: rowParams.sortModel,
            offset: rowParams.startRow,
            daterange: this.dateFilter,
            limit: 100,
          };

          // CUSOTM VISIBLE COLUMNS
          if (this.visibleColumns.length) {
            options.fields = [...this.visibleColumns].join();
          }

          // GET LHL
          this.subscribers.GET_LHL = this.locationHistoryLogsService
            .getHistoryLogsLazyLoad(this.selectedLocation.id, options)
            .subscribe((result: any) => {
              const rowData = result.data;
              let lastRow = -1;
              if (rowData.length < options.limit) {
                lastRow = rowParams.startRow + rowData.length;
              }
              rowParams.successCallback(rowData, lastRow);
            });
        },
      };

      // SET DATASOURCE IF TABLE READY
      if (this.table && this.table.api) {
        this.table.api.setDatasource(this.datasource);
      }

      this.isFetchingDataset = false;
    }
  }

  fetchDeviceTypes() {
    this.subscribers.GET_DEVICE_TYPES = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data;
    });
  }

  onDateSelect(event) {
    this.dateFilter = {
      from: event.from,
      to: event.to,
    };
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private getMappingValue(params, deviceTypeKey) {
    let currentValue = params.data && params.data[params.colDef.colId];
    if (currentValue && this.deviceTypes) {
      // find device type
      const deviceType = this.deviceTypes.find((d) => d.key === deviceTypeKey);
      if (deviceType && deviceType.mappingValues && deviceType.mappingValues.length) {
        // find mapping value
        for (let i = 0; i < deviceType.mappingValues.length; i++) {
          if (deviceType.mappingValues[i].key === currentValue) {
            currentValue = deviceType.mappingValues[i].name;
            break;
          }
        }
      }
    }
    return currentValue;
  }

  private createColumns() {
    return [
      {
        colId: 'createdAt',
        headerName: 'DATE',
        field: 'createdAt',
        cellRenderer: 'loadingRenderer',
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'acFanspeed',
        headerName: 'AC_FANSPEED',
        field: 'acFanspeed',
        hide: true,
        valueFormatter: (params) => this.getMappingValue(params, 'acf'),
        valueGetter: (params) => this.getMappingValue(params, 'acf'),
      },
      {
        colId: 'acFanspeedLoggedAt',
        headerName: 'AcFanspeedLoggedAt',
        field: 'acFanspeedLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'acMode',
        headerName: 'AC_MODE',
        field: 'acMode',
        hide: true,
        valueFormatter: (params) => this.getMappingValue(params, 'acm'),
        valueGetter: (params) => this.getMappingValue(params, 'acm'),
      },
      {
        colId: 'acModeLoggedAt',
        headerName: 'AcModeLoggedAt',
        field: 'acModeLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'acSetpoint',
        headerName: 'AC_SETPOINT',
        field: 'acSetpoint',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value : '';
        },
      },
      {
        colId: 'acSetpointLoggedAt',
        headerName: 'AcSetpointLoggedAt',
        field: 'acSetpointLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'acStatus',
        headerName: 'AC_STATUS',
        field: 'acStatus',
        width: 200,
        hide: false,
        valueFormatter: (params) => this.getMappingValue(params, 'acs'),
        valueGetter: (params) => this.getMappingValue(params, 'acs'),
      },
      {
        colId: 'acStatusLoggedAt',
        headerName: 'AcStatusLoggedAt',
        field: 'acStatusLoggedAt',
        hide: false,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'airQuality',
        headerName: 'AIR_QUALITY',
        field: 'airQuality',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + 'pm2.5' : '';
        },
      },
      {
        colId: 'airQualityLoggedAt',
        headerName: 'AirQualityLoggedAt',
        field: 'airQualityLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'brightness',
        headerName: 'BRIGHTNESS',
        field: 'brightness',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + 'lux' : '';
        },
      },
      {
        colId: 'brightnessLoggedAt',
        headerName: 'BrightnessLoggedAt',
        field: 'brightnessLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'consumption',
        headerName: 'POWERAGE',
        field: 'consumption',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + 'W' : '';
        },
      },
      {
        colId: 'consumptionLoggedAt',
        headerName: 'ConsumptionLoggedAt',
        field: 'consumptionLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'consumptionTotal',
        headerName: 'CONSUMPTION_TOTAL',
        field: 'consumptionTotal',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + 'W' : '';
        },
      },
      {
        colId: 'consumptionTotalLoggedAt',
        headerName: 'ConsumptionTotalLoggedAt',
        field: 'consumptionTotalLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'curtainsOpen',
        headerName: 'CURTAINS_OPEN',
        field: 'curtainsOpen',
        hide: true,
        valueFormatter: (params) => this.getMappingValue(params, 'cm'),
        valueGetter: (params) => this.getMappingValue(params, 'cm'),
      },
      {
        colId: 'curtainsOpenLoggedAt',
        headerName: 'CurtainsOpenLoggedAt',
        field: 'curtainsOpenLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'emrTotal',
        headerName: 'EMR_TOTAL',
        field: 'consumptionTotal',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + 'kWh' : '';
        },
      },
      {
        colId: 'emrTotalLoggedAt',
        headerName: 'EmrTotalLoggedAt',
        field: 'emrTotalLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'dndStatus',
        headerName: 'DND_STATUS',
        field: 'dndStatus',
        hide: true,
      },
      {
        colId: 'dndStatusLoggedAt',
        headerName: 'DndStatusLoggedAt',
        field: 'dndStatusLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'doorLocked',
        headerName: 'DOOR_LOCKED',
        field: 'doorLocked',
        hide: true,
        valueFormatter: (params) => this.getMappingValue(params, 'sdl'),
        valueGetter: (params) => this.getMappingValue(params, 'sdl'),
      },
      {
        colId: 'doorLockedLoggedAt',
        headerName: 'DoorLockedLoggedAt',
        field: 'doorLockedLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'doorOpen',
        headerName: 'DOOR_OPEN',
        field: 'doorOpen',
        hide: true,
        valueFormatter: (params) => this.getMappingValue(params, 'osd'),
        valueGetter: (params) => this.getMappingValue(params, 'osd'),
      },
      {
        colId: 'doorOpenLoggedAt',
        headerName: 'DoorOpenLoggedAt',
        field: 'doorOpenLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'humidity',
        headerName: 'HUMIDITY',
        field: 'humidity',
        width: 200,
        hide: false,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + '%' : '';
        },
      },
      {
        colId: 'humidityLoggedAt',
        headerName: 'HumidityLoggedAt',
        field: 'humidityLoggedAt',

        hide: false,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'isBookedAndCheckInDone',
        headerName: 'IS_BOOKED_AND_CHECK_IN_DONE',
        field: 'isBookedAndCheckInDone',
        hide: true,
      },
      {
        colId: 'isBookedAndCheckInDoneLoggedAt',
        headerName: 'IsBookedAndCheckInDoneLoggedAt',
        field: 'isBookedAndCheckInDoneLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'isBookedButNoCheckInDone',
        headerName: 'IS_BOOKED_BUT_NO_CHECK_IN_DONE',
        field: 'isBookedButNoCheckInDone',
        hide: true,
      },
      {
        colId: 'isBookedButNoCheckInDoneLoggedAt',
        headerName: 'IsBookedButNoCheckInDoneLoggedAt',
        field: 'isBookedButNoCheckInDoneLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'isCheckInInProgress',
        headerName: 'IS_CHECK_IN_IN_PROGRESS',
        field: 'isCheckInInProgress',
        hide: true,
      },
      {
        colId: 'isCheckInInProgressLoggedAt',
        headerName: 'IsCheckInInProgressLoggedAt',
        field: 'isCheckInInProgressLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'isCheckOutInProgress',
        headerName: 'IS_CHECK_OUT_IN_PROGRESS',
        field: 'isCheckOutInProgress',
        hide: true,
      },
      {
        colId: 'isCheckOutInProgressLoggedAt',
        headerName: 'IsCheckOutInProgressLoggedAt',
        field: 'isCheckOutInProgressLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'isRaining',
        headerName: 'IS_RAINING',
        field: 'isRaining',
        hide: true,
      },
      {
        colId: 'isRainingLoggedAt',
        headerName: 'IsRainingLoggedAt',
        field: 'isRainingLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'lightsOn',
        headerName: 'LIGHTS_ON',
        field: 'lightsOn',
        hide: true,
      },
      {
        colId: 'lightsOnLoggedAt',
        headerName: 'LightsOnLoggedAt',
        field: 'lightsOnLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'makeUpStatus',
        headerName: 'MAKE_UP_STATUS',
        field: 'makeUpStatus',
        hide: true,
      },
      {
        colId: 'makeUpStatusLoggedAt',
        headerName: 'MakeUpStatusLoggedAt',
        field: 'makeUpStatusLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'numPeople',
        headerName: 'LHL_NUM_PEOPLE',
        field: 'numPeople',
        hide: true,
      },
      {
        colId: 'numPeopleLoggedAt',
        headerName: 'LHL_NUM_PEOPLE_LOGGED_AT',
        field: 'numPeopleLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'occupied',
        headerName: 'OCCUPIED',
        field: 'occupied',
        width: 200,
        hide: false,
      },
      {
        colId: 'occupiedLoggedAt',
        headerName: 'OccupiedLoggedAt',
        field: 'occupiedLoggedAt',

        hide: false,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'oooStatus',
        headerName: 'OOO_STATUS',
        field: 'oooStatus',
        hide: true,
      },
      {
        colId: 'oooStatusLoggedAt',
        headerName: 'OooStatusLoggedAt',
        field: 'oooStatusLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'outdoorHumidity',
        headerName: 'OUTDOOR_HUMIDITY',
        field: 'outdoorHumidity',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + '%' : '';
        },
      },
      {
        colId: 'outdoorHumidityLoggedAt',
        headerName: 'OutdoorHumidityLoggedAt',
        field: 'outdoorHumidityLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'outdoorTemperature',
        headerName: 'OUTDOOR_TEMPERATURE',
        field: 'outdoorTemperature',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + '°C' : '';
        },
      },
      {
        colId: 'outdoorTemperatureLoggedAt',
        headerName: 'OutdoorTemperatureLoggedAt',
        field: 'outdoorTemperatureLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'temperature',
        headerName: 'TEMPERATURE',
        field: 'temperature',
        width: 200,
        hide: false,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + '°C' : '';
        },
      },
      {
        colId: 'temperatureLoggedAt',
        headerName: 'TemperatureLoggedAt',
        field: 'temperatureLoggedAt',

        hide: false,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'windSpeed',
        headerName: 'WIND_SPEED',
        field: 'windSpeed',
        hide: true,
        valueFormatter: (params) => {
          const value = this.sharedService.numberFormat(params.value);
          return value ? value + 'km/h' : '';
        },
      },
      {
        colId: 'windSpeedLoggedAt',
        headerName: 'WindSpeedLoggedAt',
        field: 'windSpeedLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
      {
        colId: 'windowOpen',
        headerName: 'WINDOW_OPEN',
        field: 'windowOpen',
        hide: true,
        valueFormatter: (params) => this.getMappingValue(params, 'osw'),
        valueGetter: (params) => this.getMappingValue(params, 'osw'),
      },
      {
        colId: 'windowOpenLoggedAt',
        headerName: 'WindowOpenLoggedAt',
        field: 'windowOpenLoggedAt',
        hide: true,
        valueFormatter: this.sharedService.tableDateFormatter.bind(this.sharedService),
        width: 200,
      },
    ];
  }
}
