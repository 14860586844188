import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-ag-alert-modal',
  templateUrl: 'ag-alert-modal.component.html'
})
export class AGAlertModalComponent implements OnInit {
  @Input() data: any;
  @Output() onClose = new EventEmitter();
  @Output() onDismiss = new EventEmitter();

  dataset: any[];

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.dataset = this.data.devices;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  getIssue(data: { [key: string]: any }) {
    if (!data.loggedAt) {
      return 'Log was not found';
    } else if (data.value && data.value.level === 'e') {
      return 'Error: ' + data.value.title;
    } else if (data.value && data.value.level === 'w') {
      return 'Warning: ' + data.value.title;
    } else if (data.duration) {
      return (
        'Last log found since ' +
        (data.duration > 60
          ? Math.floor(data.duration / 60) + ' hour(s) ago'
          : data.duration + ' minute(s) ago')
      );
    }
    return '';
  }

  getIssueStyle(data: { [key: string]: any }) {
    let color;

    switch (data.status) {
      case 'error':
      case 'warning':
        if (data.duration >= 60) {
          color = 'red';
        } else {
          color = 'orange';
        }
        break;
      case 'info':
        color = 'blue';
        break;
      default:
        break;
    }

    return { color };
  }

  getLoggedAt(data: { [key: string]: any }) {
    if (data.loggedAt) {
      return this.sharedService.dateFormat(data.loggedAt);
    }
    return '';
  }
}
