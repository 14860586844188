import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { Site } from '@widgets/sites/models/site';

import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-top-five-coldest-rooms',
  templateUrl: './top-five-coldest-rooms.component.html',
  styleUrls: ['./top-five-coldest-rooms.component.scss'],
})
export class TopFiveColdestRoomsComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  fetchInterval: any;
  isFetching: boolean;
  isFirstTime: boolean;
  dataset: any;
  selectedSite: Site;
  widgetData: any;
  widgetName: string = '';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.getSelectedSite = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite && this.selectedSite.id) {
      this.isFetching = true;
      this.subscribers.fetchDataset = this.widgetService.getTopFiveColdestRooms(this.selectedSite.id).subscribe(
        (result: any) => {
          this.dataset = result.data || [];
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  getNumIcon(data: number) {
    const numbers = [];
    let max = 0;
    if (data <= 20) {
      max = 5;
    } else if (data <= 23) {
      max = 4;
    } else if (data <= 26) {
      max = 3;
    } else if (data <= 29) {
      max = 2;
    } else {
      max = 1;
    }
    for (let i = 0; i < max; i++) {
      numbers.push(i);
    }
    return numbers;
  }
}
