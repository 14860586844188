import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromGrids from '../reducers/grids.reducer';

export const getGridState = createFeatureSelector<fromGrids.GridState>('grids');

export const getGridHeight = createSelector(
  getGridState,
  fromGrids.getGridHeight
);

export const getGridMinColumns = createSelector(
  getGridState,
  fromGrids.getGridMinColumns
);
