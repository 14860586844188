<div class="tree-select">
  <div class="tree-select__input" (click)="op.toggle($event)">
    <div class="tree-select__input__selected">
      <ng-container *ngIf="displayItems?.length">
        <ng-container *ngIf="displayItems?.length <= maxSelectedDisplay">
          <span *ngFor="let item of displayItems; let i = index">
            {{ i > 0 ? "," : "" }} {{ item }}
          </span>
        </ng-container>

        <ng-container *ngIf="displayItems?.length > maxSelectedDisplay">
          <span>
            {{ displayItems?.length }} {{ "CCP_COMMON_SELECTED" | translate }}
          </span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!displayItems?.length && placeholder">
        <span class="tree-select__input__placeholder">
          {{ placeholder }}
        </span>
      </ng-container>

      <ng-container *ngIf="!displayItems?.length && !placeholder">
        <span class="tree-select__input__placeholder">
          {{ "CCP_COMMON_SELECT" | translate }} {{ label }}
        </span>
      </ng-container>
    </div>

    <div class="tree-select__input__trigger">
      <fa-icon icon="chevron-down"></fa-icon>
    </div>
  </div>

  <p-overlayPanel #op appendTo="body" styleClass="tree-select__overlay-panel">
    <p-tree
      styleClass="tree-select__tree-item"
      [value]="options"
      [propagateSelectionUp]="false"
      [propagateSelectionDown]="false"
      [(selection)]="selected"
      [selectionMode]="selectionMode"
      (onNodeSelect)="nodeSelect($event, op)"
      (onNodeUnselect)="nodeSelect($event, op)"
    ></p-tree>
  </p-overlayPanel>
</div>
