import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface OpenWeatherMap {
  cityName?: string;
  countryCode?: string;
  lat?: number;
  lon?: number;
  zipcode?: number;
}

@Injectable()
export class LocalWeatherService {
  private apiUrl =
    'https://api.openweathermap.org/data/2.5/weather?mode=json&units=metric&APPID=e76ca9ba36cc2d133954fd072ed1742d';

  constructor(private http: HttpClient) {}

  getCurrentWeather(options: OpenWeatherMap) {
    let apiUrl = this.apiUrl;

    if (options.cityName) {
      apiUrl += `&q=${options.cityName}`;
    } else if (options.zipcode) {
      apiUrl += `&zip=${options.zipcode}`;
    }

    if ((options.zipcode || options.cityName) && options.countryCode) {
      apiUrl += `,${options.countryCode}`;
    }

    return this.http.jsonp(apiUrl, 'callback');
  }
}
