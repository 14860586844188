import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { Site } from '@widgets/sites/models/site';

import { SharedService } from '@shared/shared.service';
import { LocationHistoryLogsService } from '@widgets/locations/services/location-history-logs.service';

@Component({
  selector: 'sc-location-summary-cell',
  templateUrl: './location-summary-cell.component.html',
  styleUrls: ['./location-summary-cell.component.scss'],
})
export class LocationSummaryCellComponent implements ICellRendererAngularComp {
  info: any;
  site: Site;
  cell: any;

  constructor(private sharedService: SharedService, private locationHistoryLogsService: LocationHistoryLogsService) {}

  agInit(params: any): void {
    this.cell = params;
    this.info = params.data;
    this.site = this.sharedService.selectedSite;
  }

  refresh(params: any): boolean {
    return false;
  }

  getColorByKey(key: string): string {
    const value = key === 'hasRule' ? this.info.numRules : this.info[key];
    return this.locationHistoryLogsService.getLocationPropertyColor(key, value, this.site, this.info);
  }

  getIconByKey(key: string): string {
    const value = key === 'hasRule' ? this.info.numRules : this.info[key];
    return this.locationHistoryLogsService.getLocationPropertyIcon(key, value);
  }

  getValueByKey(key: string): string {
    let deviceTypeKey = this.locationHistoryLogsService.getLocationPropertyDeviceTypeKeyMapping(key);
    let value = this.info[key];

    if (value && deviceTypeKey && this.deviceTypes) {
      // find device type
      const deviceType = this.deviceTypes.find((d) => d.key === deviceTypeKey);
      // find mapping value
      value = this.locationHistoryLogsService.getLocationPropertyMapValue(key, value, deviceType);
    }

    if (value && !isNaN(value)) {
      value = this.sharedService.numberFormat(value);
    }

    return value;
  }

  getValueUnitByKey(key: string): string {
    const value = key === 'hasRule' ? this.info.numRules : this.info[key];
    return this.locationHistoryLogsService.getLocationPropertyUnit(key, value);
  }

  getPrefixByKey(key: string): string {
    return this.locationHistoryLogsService.getLocationPropertyPrefix(key);
  }

  dateFormat(date) {
    if (typeof date !== 'undefined') {
      return this.sharedService.dateFormat(date);
    } else {
      return '';
    }
  }

  isBooleanData(key: string): boolean {
    return this.locationHistoryLogsService.isBooleanData(key);
  }

  isDataValid(data: any, type: string): boolean {
    return typeof data === type ? true : false;
  }

  setTooltip(item) {
    const key = item.key;
    const value = key === 'hasRule' ? this.info.numRules : this.info[key];
    let loggedAt = this.info[key + 'LoggedAt'] ? this.dateFormat(this.info[key + 'LoggedAt']) : '';
    let displayText = '';

    if (key === 'numPeople') {
      const maxPeople = this.info.maxPeople || null;
      const params = { numPeople: value || '0', maxPeople: maxPeople || '0' };
      displayText += this.sharedService.getTranslation('LHL_NUMBER_PEOPLE_TOOLTIP_1', params); // 'Number of people: {{numPeople}}/{{maxPeople}}'

      if (typeof value === 'number' && maxPeople !== null && value > maxPeople) {
        const numPeopleTooMany = '' + (value - maxPeople);
        displayText += ', ';
        displayText += this.sharedService.getTranslation('LHL_NUMBER_PEOPLE_TOOLTIP_2', { numPeopleTooMany }); // '{{numPeopleTooMany}} too many!'
      }
    } else {
      displayText =
        this.locationHistoryLogsService.getLocationPropertyLabel(key, value) ||
        this.sharedService.getTranslation(item.label);
    }

    displayText = displayText ? `<strong>${displayText}</strong>` : '';
    loggedAt = loggedAt ? `\n<small>${this.sharedService.getTranslation('LOGGED_AT')} ${loggedAt}</small>` : '';

    if (key === 'hasRule' && this.info.numRules > 0) {
      this.info.activeRules.forEach((rule: string) => {
        displayText += '<div>- ' + rule + '</div>';
      });
    }

    return '<div>' + displayText + loggedAt + '</div>';
  }

  getBadgeStyle(key) {
    const classes = [];
    classes.push(this.getColorByKey(key));

    if (this.isBooleanData(key)) {
      classes.push('icon-only');
    }

    return classes.join(' ');
  }

  get deviceTypes() {
    if (
      this.cell &&
      this.cell.context &&
      this.cell.context.containerComponent &&
      this.cell.context.containerComponent.deviceTypes
    ) {
      return this.cell.context.containerComponent.deviceTypes;
    }
    return [];
  }

  get summaryConfig() {
    if (
      this.cell &&
      this.cell.context &&
      this.cell.context.containerComponent &&
      this.cell.context.containerComponent.summaryConfig
    ) {
      return this.cell.context.containerComponent.summaryConfig;
    }
    return [];
  }
}
