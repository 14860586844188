import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscriber, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map, take, takeUntil, timeout } from 'rxjs/operators';
import * as fromStore from '@app/store/selectors';
import { State } from '@app/store/reducers';
import { Site } from '@widgets/sites/models/site';
import { SelectItem, TreeNode } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DependencyWarningComponent } from '@shared/dependency-warning/dependency-warning.component';
import { escape, snakeCase, unescape } from 'lodash';
import * as moment from 'moment-timezone';
import { SiteType } from '@app/widgets/site-types/models/site-type';
import { MultiLangValue } from './models/multi-lang-value';

export interface SCSelectItem extends SelectItem {
  [prop: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class SharedService implements OnDestroy {
  // currentTranslation: any;
  currentSiteType: SiteType;
  selectedSite: Site;
  selectItems: { [prop: string]: SelectItem[] };
  sourceData: { [prop: string]: any[] };
  username: string;
  userCompanyId: number;
  userId: number;
  userLocale: string;
  userMainRole: string;
  userRoles: string[];
  userVariant: string;

  private siteTypes: { [key: string]: SiteType } = {};
  private baseUrl = config().apiUrl;
  private destroy: Subject<any> = new Subject();
  private warningDialog: DynamicDialogRef;

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    private store: Store<State>,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {
    this.selectItems = { ...config().selectItems };
    this.sourceData = {};

    // get timezone
    this.selectItems.timezones = moment.tz.names().map((tz) => {
      return { label: tz, value: tz };
    });

    // get locales
    this.store.pipe(select(fromStore.getAllLocales), takeUntil(this.destroy)).subscribe((result) => {
      this.sourceData.locales = result;
      this.selectItems.locales = this.createSelectItems(result, false);
    });

    // get site types
    this.store
      .pipe(
        select(fromStore.selectSiteTypeEntities),
        takeUntil(this.destroy),
        filter((data) => (data && Object.keys(data).length > 0 ? true : false))
      )
      .subscribe((result) => {
        this.siteTypes = result;
        this.updateCurrentSiteType();
      });

    // get sites
    this.store.pipe(select(fromStore.getSelectedSite), takeUntil(this.destroy)).subscribe((result) => {
      this.selectedSite = result;
      this.updateCurrentSiteType();
    });

    this.store.pipe(select(fromStore.getAllActiveSites), takeUntil(this.destroy)).subscribe((result) => {
      this.sourceData.sites = result;
      this.selectItems.sites = this.createSelectItems(result, false);
    });

    // get comapnies
    this.store.pipe(select(fromStore.getCompanies), takeUntil(this.destroy)).subscribe((result) => {
      this.sourceData.companies = result;
      this.selectItems.companies = this.createSelectItems(
        result.filter((r) => r.isActive && !r.isDeleted),
        false
      );
    });

    // get user
    this.store
      .pipe(
        select(fromStore.getUserState),
        takeUntil(this.destroy),
        filter((user) => !!user)
      )
      .subscribe((user) => {
        this.userId = user.userId;
        this.username = user.username;
        this.userCompanyId = user.companyId;
        this.userLocale = user.locale;
        this.userMainRole = user.mainRole;
        this.userRoles = user.roles;
        this.userVariant = user.variant;
      });

    // Get translation List
    // this.translateService.onLangChange
    //   .pipe(
    //     takeUntil(this.destroy),
    //     filter((event) => !!event.translations),
    //     map((event) => event.translations)
    //   )
    //   .subscribe((translations) => {
    //     this.currentTranslation = translations;
    //   });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  resetData() {
    this.selectItems = { ...config().selectItems };
    this.sourceData = {};

    // get timezone
    this.selectItems.timezones = moment.tz.names().map((tz) => {
      return { label: tz, value: tz };
    });

    this.currentSiteType = null;
    this.selectedSite = null;
    this.username = null;
    this.userCompanyId = null;
    this.userId = null;
    this.userLocale = null;
    this.userMainRole = null;
    this.userRoles = null;
    this.userVariant = null;
  }

  /**
   * Load Initial Data of BE (at starting of app)
   */
  getInitialData() {
    const apiUrl = `${this.baseUrl}/_backend/init`;
    return this.httpClient.get(apiUrl).pipe(catchError((error: any) => observableThrowError(error)));
  }

  getCeosScaffold() {
    return this.httpClient.get(`${this.baseUrl}/_ceos/scaffold`);
  }

  private updateCurrentSiteType() {
    if (this.siteTypes && this.selectedSite && this.selectedSite.siteTypeId) {
      this.currentSiteType = this.siteTypes[this.selectedSite.siteTypeId];
    }
  }

  /**
   * Get Site Info by siteId
   */
  getSiteBySiteId(siteId) {
    if (!siteId || !this.sourceData.sites || this.sourceData.sites.length === 0) {
      return;
    }
    const data = this.sourceData.sites.find((d) => d.siteId === siteId);
    return {
      id: data.id,
      name: data.name,
      description: data.description,
    };
  }

  /**
   * Get Company Info by companyId
   */
  getCompanyByCompanyId(companyId) {
    if (!companyId || !this.sourceData.companies || this.sourceData.companies.length === 0) {
      return;
    }
    const data = this.sourceData.companies.find((d) => d.companyId === companyId);
    return {
      id: data.id,
      name: data.name,
    };
  }

  /**
   * NOTIFICATION MESSAGE
   */
  notify(title: string, message: string, severity?: 'success' | 'info' | 'warn' | 'error', clearBeforeAdd?: boolean) {
    if (clearBeforeAdd === true) {
      this.clearNotify();
    }

    this.messageService.add({
      severity: severity || 'info',
      summary: title || '',
      detail: message || '',
      closable: false,
    });
  }

  clearNotify() {
    this.messageService.clear();
  }

  /**
   * CONVERT DATE STRING TO DATE OBJECT (UTC)
   */
  parseDateStringToDateObject(date: string, isUtc: boolean = false) {
    const myDate = moment(date);
    if (myDate.isValid() === true) {
      if (!isUtc && this.selectedSite && this.selectedSite.timezone) {
        const offset = this.getTimezoneOffset(this.selectedSite.timezone, true);
        myDate.utcOffset(offset);
      }
      return myDate.toDate();
    }
    return null;
  }

  /**
   * CONVERT DATE OBJECT TO DATE STRING (UTC)
   */
  parseDateObjectToDateString(date: Date, isUtc: boolean = false) {
    const myDate = moment(date);
    if (myDate.isValid() === true) {
      if (!isUtc) {
        myDate.utc();
      }
      return myDate.format();
    }
    return null;
  }

  /**
   *
   */
  dateFormat(
    date: Date | string | number,
    targetFormat: string = 'DD.MM.YYYY HH:mm:ss',
    isUtc: boolean = false,
    isUnix: boolean = false
  ) {
    let myDate;
    if (date === 'now') {
      myDate = moment();
    } else if (isUnix && (date + '').length === 10) {
      myDate = moment.unix(date);
    } else {
      myDate = moment(date);
    }

    if (myDate.isValid() === true) {
      let offset;
      if (!isUtc && this.selectedSite && this.selectedSite.timezone) {
        offset = this.getTimezoneOffset(this.selectedSite.timezone, true);
        myDate.utcOffset(offset);
      }
      if (targetFormat === 'fromNow') {
        return myDate.fromNow();
      }
      return myDate.format(targetFormat);
    }
    return '';
  }

  /**
   * NUMBER FORMATTER
   */
  numberFormat(value: any, decimal: number = 2) {
    if (isNaN(value) === true) {
      return value;
    }
    return Number(Math.round((value + 'e' + decimal) as any) + 'e-' + decimal);
    // .toFixed(decimal);
  }

  /**
   * CREATE PRIMENG SELECT_ITEMS
   */
  createSelectItems(
    data: { [key: string]: any }[],
    startWithBlank: boolean = false,
    keyValue: string = 'id',
    additionalKey?: string[],
    labelValue?: string
  ): SCSelectItem[] {
    const selectItems: SCSelectItem[] = [];

    if (startWithBlank === true) {
      selectItems.push({ label: '', value: '' });
    }

    data.forEach((d) => {
      const item: any = {
        label: d[labelValue] || d.label || d.name || d.key || d.description || d.language || d.username || d.id,
        value: d[keyValue],
      };

      // add extra keys to the item
      if (additionalKey && additionalKey.length) {
        for (const akey of additionalKey) {
          item[akey] = d[akey];
        }
      }

      // add flag keys to the item if language key exists
      if (d.language) {
        item.flag = 'flag-icon-' + (d.id === 'en' ? 'gb' : d.id);
      }

      selectItems.push(item);
    });

    return this.sortBy(selectItems, 'label') as SCSelectItem[];
  }

  /**
   * TEXT TRANSLATE
   */
  textToTextTranslate(text: string, srcLocale: string, destLocale: string = 'en'): Observable<string> {
    let apiUrl = `${this.baseUrl}/_tools/translator/textToText`;
    if (text && srcLocale && srcLocale !== destLocale) {
      apiUrl += `?from=${srcLocale}&to=${destLocale}&text=${text}`;
    }
    return this.httpClient.get(apiUrl, { responseType: 'text' });
  }

  /**
   * CREATE CRON REGEX PATTERN
   */
  createCronRegex() {
    const regexByField: any = {
      sec: '[0-5]?\\d',
      min: '[0-5]?\\d',
      hour: '[01]?\\d|2[0-3]',
      day: '0?[1-9]|[12]\\d|3[01]',
      month: '[1-9]|1[012]',
      dayOfWeek: '[0-7]',
      year: '|\\d{4}',
    };

    for (const field of regexByField) {
      const num = regexByField[field];
      let range =
        '(?:' + num + ')' + '(?:' + '(?:-|/|,' + (field === 'dayOfWeek' ? '|#' : '') + ')' + '(?:' + num + ')' + ')?';
      if (field === 'dayOfWeek') {
        range += '(?:L)?';
      }
      if (field === 'month') {
        range += '(?:L|W)?';
      }
      regexByField[field] = '\\?|\\*|' + range + '(?:,' + range + ')*';
    }

    const monthValues = 'JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC';
    const monthRange = '(?:' + monthValues + ')(?:(?:-)(?:' + monthValues + '))?';
    regexByField.month += '|\\?|\\*|' + monthRange + '(?:,' + monthRange + ')*';

    const dayOfWeekValues = 'MON|TUE|WED|THU|FRI|SAT|SUN';
    const dayOfWeekRange = '(?:' + dayOfWeekValues + ')(?:(?:-)(?:' + dayOfWeekValues + '))?';
    regexByField.dayOfWeek += '|\\?|\\*|' + dayOfWeekRange + '(?:,' + dayOfWeekRange + ')*';

    return (
      '^\\s*($|#|\\w+\\s*=|' +
      '(' +
      regexByField.sec +
      ')\\s+' +
      '(' +
      regexByField.min +
      ')\\s+' +
      '(' +
      regexByField.hour +
      ')\\s+' +
      '(' +
      regexByField.day +
      ')\\s+' +
      '(' +
      regexByField.month +
      ')\\s+' +
      '(' +
      regexByField.dayOfWeek +
      ')(|\\s)+' +
      '(' +
      regexByField.year +
      ')' +
      ')$'
    );
  }

  /**
   * stringify escape sequences
   */
  jsonEscape(str) {
    if (typeof str !== 'string') {
      return;
    }
    return str.replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t');
  }

  /**
   * parse json string to json object
   */
  jsonParse(str) {
    if (typeof str !== 'string') {
      return str;
    }
    return JSON.parse(this.jsonEscape(str));
  }

  tryParseJSON(str: string) {
    try {
      return JSON.parse(str);
    } catch (error) {
      return str;
    }
  }

  /**
   * Converts the characters "&", "<", ">", '"', and "'" in string
   * to their corresponding HTML entities
   * Read more: https://lodash.com/docs/4.17.10#escape
   */
  stringEscape(str) {
    if (typeof str !== 'string') {
      return;
    }
    return escape(str).replace(/\r\n|\r|\n/gm, '&lt;br&gt;');
  }

  /**
   * this method converts the HTML entities &amp;, &lt;, &gt;, &quot;, and &#39; in string
   * to their corresponding characters.
   * Read more: https://lodash.com/docs/4.17.10#unescape
   */
  stringUnescape(str) {
    if (typeof str !== 'string') {
      return;
    }
    return unescape(str).replace(/\<br\>/gm, '\n');
  }

  /**
   * Object Key Replacer
   */
  keyReplacer(source: { [key: string]: any }, newKeys: { [key: string]: string }): { [key: string]: any } {
    const obj: any = {};
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (newKeys[key]) {
          obj[newKeys[key]] = source[key];
        } else {
          obj[key] = source[key];
        }
      }
    }
    return obj;
  }

  /**
   * Decode minimize object
   */
  decodeMiminizeObjects(
    source: { [key: string]: any }[],
    mappingKeys: { [key: string]: string }
  ): { [key: string]: any }[] {
    const tmpArr = [...source];

    for (let i = 0; i < tmpArr.length; i++) {
      tmpArr[i] = this.keyReplacer(tmpArr[i], mappingKeys);

      if (tmpArr[i].children) {
        tmpArr[i].children = this.decodeMiminizeObjects(tmpArr[i].children, mappingKeys);
      }
    }

    return tmpArr;
  }

  /**
   * move item in array
   */
  arrayMove(data: any[], oldIndex: number, newIndex: number) {
    if (newIndex >= data.length) {
      let k = newIndex - data.length + 1;
      while (k--) {
        data.push(undefined);
      }
    }
    data.splice(newIndex, 0, data.splice(oldIndex, 1)[0]);
    return data;
  }

  /**
   * get translation
   */
  getTranslation(key: string, params?: { [key: string]: string }) {
    // if (this.currentTranslation && this.currentTranslation[key]) {
    //   let text = this.currentTranslation[key];
    //   if (params) {
    //     for (const placeholder in params) {
    //       if (params.hasOwnProperty(placeholder) && typeof params[placeholder] !== 'undefined') {
    //         const regex = new RegExp('\\{\\{\\s?' + placeholder + '\\s?\\}\\}', 'gm');
    //         text = text.replace(regex, params[placeholder]);
    //       }
    //     }
    //   }
    //   return text;
    // }
    // return key;

    let value = key;
    this.translateService
      .get(key, params)
      .pipe(
        filter((data) => !!data),
        map((data) => data),
        take(1),
        timeout(10000)
      )
      .subscribe((data) => {
        value = data;
      });
    return value;
  }

  /**
   * Check LHL data
   */
  isLHLValueBoolean(key: string): boolean {
    const keys = [
      'CURTAINSOPEN',
      'DNDSTATUS',
      'DOORLOCKED',
      'DOOROPEN',
      'HASRULE',
      'ISBOOKEDANDCHECKINDONE',
      'ISBOOKEDBUTNOCHECKINDONE',
      'ISCHECKININPROGRESS',
      'ISCHECKOUTINPROGRESS',
      'ISRAINING',
      'LIGHTSON',
      'MAKEUPSTATUS',
      'OCCUPIED',
      'OOOSTATUS',
      'WINDOWOPEN',
    ];
    return keys.indexOf(key.toUpperCase()) >= 0 ? true : false;
  }

  /**
   * Delete some keys like id, createdAt, updatedBy,... from Object
   */
  cleanUpObject(data: { [key: string]: any }, extraKeys?: string[]): { [key: string]: any } {
    const newObj = { ...data };

    // delete default keys
    delete newObj.id;
    delete newObj.createdAt;
    delete newObj.createdBy;
    delete newObj.updatedAt;
    delete newObj.updatedBy;

    // delete extra keys
    if (extraKeys && extraKeys.length) {
      for (const key of extraKeys) {
        delete newObj[key];
      }
    }

    return newObj;
  }

  /**
   * Get offset of timezone in hours
   */
  getTimezoneOffset(key: string, numberResult = false) {
    if (numberResult === true) {
      return moment.tz(key).utcOffset();
    }
    return moment.tz(key).format('Z');
  }

  /**
   * Random number between min and max
   */
  randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /**
   * Convert Object into url query params string
   */
  urlQueryParamsCreator(data: { [key: string]: any }, encodeURI = true) {
    const queryParams: any = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        let value = data[key];
        if (encodeURI) {
          if (typeof value === 'object') {
            value = encodeURIComponent(JSON.stringify(value));
          } else {
            value = encodeURIComponent(value);
          }
        }
        queryParams.push(key + '=' + value);
      }
    }

    return queryParams.length ? '?' + queryParams.join('&') : '';
  }

  /**
   * Create options for tree multi select
   */
  createMultiSelectItems(
    dataset: { [key: string]: any }[],
    childrenKey: string = 'children',
    valueKey: string = 'id',
    isTopLvlSelectable?: boolean
  ): TreeNode[] {
    const result = [];
    if (dataset) {
      dataset.forEach((item) => {
        this.createMultiSelectItemRecursive(item, result, childrenKey, valueKey, isTopLvlSelectable);
      });
    }
    return result;
  }

  /**
   * Create tree multi select option recursive
   */
  private createMultiSelectItemRecursive(
    data: { [key: string]: any },
    result: any[],
    childrenKey: string = 'children',
    valueKey: string = 'id',
    isTopLvlSelectable?: boolean,
    children?: any[]
  ) {
    // Ignore deleted items
    if (data.hasOwnProperty('isDeleted') && data.isDeleted) {
      return;
    }

    const item = {
      label: data.name || data.description || data.key || data.idx || data.id,
      data: data[valueKey],
      expanded: true,
      selectable: isTopLvlSelectable,
    } as TreeNode;

    // create children array if exists
    if (data[childrenKey]) {
      item.children = [];
    }

    if (children) {
      // if children array of parent was sent, add item here
      children.push(item);
    } else {
      result.push(item);
    }

    // Looping child if exists
    if (data[childrenKey]) {
      data[childrenKey].forEach((childItem) => {
        this.createMultiSelectItemRecursive(childItem, result, childrenKey, valueKey, true, item.children);
      });
    }
  }

  createTree(data: any[], parentKey = 'parentId', idKey = 'id', childrenKey = 'children') {
    const tree = [];

    const entities = data.reduce((prev, curr) => {
      return { ...prev, [curr[idKey]]: curr };
    }, {});

    for (const id in entities) {
      if (entities.hasOwnProperty(id)) {
        const entity = entities[id];
        // the element is not at the root level
        if (entity[parentKey]) {
          // create children array if not exists
          if (!entities[entity[parentKey]][childrenKey]) {
            entities[entity[parentKey]][childrenKey] = [];
          }
          entities[entity[parentKey]][childrenKey].push(entity);
        } else {
          // the element is at the root level
          tree.push(entity);
        }
      }
    }

    return tree;
  }

  /**
   * Unsubscribe all subscribers
   */
  clearSubscribes(subscribers: { [key: string]: Subscriber<any> | Subscription }) {
    for (const key in subscribers) {
      if (typeof subscribers[key] !== 'undefined') {
        subscribers[key].unsubscribe();
        subscribers[key] = null;
      }
    }
  }

  /**
   * Clear timeouts
   */
  clearTimeouts(timeouts: { [key: string]: any }) {
    for (const key in timeouts) {
      if (timeouts.hasOwnProperty(key)) {
        clearTimeout(timeouts[key]);
      }
    }
  }

  /**
   * Clear intervals
   */
  clearIntervals(intervals: { [key: string]: any }) {
    for (const key in intervals) {
      if (intervals.hasOwnProperty(key)) {
        clearInterval(intervals[key]);
        intervals[key] = null;
      }
    }
  }

  objectKeyToSnakeCase(object: { [key: string]: any }): { [key: string]: any } {
    const newObject = {};
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        newObject[snakeCase(key)] = object[key];
      }
    }
    return newObject;
  }

  deleteWarningHandler(source: any, dependencies: any[]) {
    this.warningDialog = this.dialogService.open(DependencyWarningComponent, {
      header: this.getTranslation('DEPENDENCY_WARNING_TITLE'),
      data: { source, dependencies },
    });

    return this.warningDialog.onClose;
  }

  createFormData(data: { [prop: string]: any }, fileKeys: string[] = []) {
    const formData: any = new FormData();

    if (Array.isArray(data)) {
    } else {
      for (const key in data) {
        if (typeof data[key] === 'undefined') {
          continue;
        }

        if (data[key] === null) {
          // key value is null
          formData.append(key, '');
        } else if (typeof data[key] === 'object' && fileKeys.indexOf(key) === -1) {
          // key value is object and not null and key is not a file key
          formData.append(key, JSON.stringify(data[key]));
        } else {
          // key value is file or any
          formData.append(key, data[key]);
        }
      }
    }
    return formData;
  }

  sortBy(data: { [prop: string]: any }[], prop: string): { [prop: string]: any }[] {
    return data.sort((obj1, obj2) => {
      const val1 = obj1[prop];
      const val2 = obj2[prop];
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      }
      return 0;
    });
  }

  toTitleCase(str: string) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  tableDateFormatter(params, key = 'value') {
    if (params && params[key]) {
      return this.dateFormat(params[key]);
    }
    return '';
  }

  tableMultiLanguagesFormatter(params, key = 'value', isFilterValueGetter = false) {
    const locale = this.userLocale;
    let value;
    if (isFilterValueGetter === true) {
      value = params && params.data && params.data[key];
    } else {
      value = params && params[key];
    }

    let formattedValue = '';
    if (typeof value !== 'undefined' && value !== null) {
      if (typeof value === 'string') {
        value = JSON.parse(value);
      }
      formattedValue = value[locale] || value.en || '';
    }

    return formattedValue;
  }

  getLocaleWidgetName(widget) {
    if (widget && widget.name) {
      let name;
      if (typeof widget.name === 'string') {
        name = JSON.parse(widget.name);
      } else if (typeof widget.name === 'object' && widget.name !== null) {
        name = widget.name;
      }
      return name[this.userLocale] || name.en || widget.key;
    }
    return '';
  }

  getFlagClassName(locale) {
    if (locale === 'en') {
      locale = 'gb';
    }
    return 'flag-icon-' + locale;
  }

  /**
   * similar to getLocaleWidgetName but more global
   * @param item
   * @param fallbackLocale
   * @returns
   */
  getLocaleValue(item: MultiLangValue, fallbackLocale = 'en') {
    const locale = this.userLocale || fallbackLocale;
    let value;
    if (typeof item === 'string') {
      value = JSON.parse(item);
    } else if (typeof item === 'object' && item !== null) {
      value = item;
    }
    if (value) {
      return value[locale] || value[fallbackLocale];
    }
    return '';
  }

  getFullPathImageURL(imagePath: string) {
    return `${config().s3Url}/${imagePath}`;
  }

  getRandomChars(length = 16, useUrlSafe = false) {
    const Password = {
      _pattern: /[a-zA-Z0-9\~\!\@\#\$\%\^\&\*\(\)\_\-\+\=]/,
      _urlSafePattern: /[a-zA-Z0-9\_\-]/,
      _prevValue: null,
      _getRandomByte: function () {
        // http://caniuse.com/#feat=getrandomvalues
        if (window.crypto && window.crypto.getRandomValues) {
          var result = new Uint8Array(1);
          window.crypto.getRandomValues(result);
          return result[0];
          // } else if (window.msCrypto && window.msCrypto.getRandomValues) {
          //   var result = new Uint8Array(1);
          //   window.msCrypto.getRandomValues(result);
          //   return result[0];
        } else {
          return Math.floor(Math.random() * 256);
        }
      },
      generate: function (length) {
        return Array.apply(null, { length: length })
          .map(function () {
            var result;
            while (true) {
              result = String.fromCharCode(this._getRandomByte());
              if (this._prevValue !== result) {
                this._prevValue = result;
                if (useUrlSafe === true) {
                  if (this._urlSafePattern.test(result)) {
                    return result;
                  }
                } else {
                  if (this._pattern.test(result)) {
                    return result;
                  }
                }
              }
            }
          }, this)
          .join('');
      },
    };
    return Password.generate(length);
  }

  copyToClipboard(value: string = '') {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
