import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-text-input',
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
        [description]="description"
        [warning]="warning"
      ></sc-field-label>

      <div [ngClass]="{ 'ui-inputgroup': prefix || hasAction, 'display-flex': hasAction }">
        <span class="ui-inputgroup-addon" *ngIf="prefix">
          {{ prefix }}
        </span>
        <input
          pInputText
          type="text"
          [id]="inputId"
          [formControlName]="controlName"
          [placeholder]="placeholder"
          autocomplete="off"
          (change)="actionChange.emit()"
          (keyup)="actionKeyUp.emit($event)"
        />
        <button pButton type="button" [icon]="actionIcon" *ngIf="hasAction" (click)="actionClicked.emit()"></button>
      </div>

      <sc-field-error [for]="label" [fieldControl]="form.controls[controlName]"></sc-field-error>
    </div>
  `,
})
export class SCTextInputComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  warning: string;
  @Input()
  placeholder: string;
  @Input()
  prefix: string;
  @Input()
  hasAction: boolean;
  @Input()
  actionIcon: string = 'fa fa-copy';
  @Output()
  actionClicked = new EventEmitter<void>();
  @Output()
  actionChange = new EventEmitter<void>();
  @Output()
  actionKeyUp = new EventEmitter<void>();

  inputId: string;

  constructor() {}

  ngOnInit() {
    this.placeholder = this.placeholder || '';
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-text-input_' + this.controlName + new Date().getTime();
  }
}
