import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'sc-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})

export class FileListComponent implements OnInit {
  @Input()
  files: TreeNode[];

  @Output()
  selected = new EventEmitter<TreeNode>();
  @Output()
  opened = new EventEmitter<TreeNode>();

  displayFiles: TreeNode[];

  constructor() { }

  ngOnInit() { }

  onSelected(node: TreeNode) {
    this.selected.emit(node);
  }

  onOpened(node: TreeNode) {
    if (!node || !node.data || !node.data.isDirectory) {
      return;
    }
    this.opened.emit(node);
  }
}
