import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RuleTemplate } from '@app/ceos-rule-editor/models';

import { config } from '@app/config';

@Injectable()
export class CeosRuleTemplatesService {
  private apiUrl = config().apiUrl + '/ceosRuleTemplates';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): any {
    const newData = { ...data };
    delete newData.id;
    delete newData.idx;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    return newData;
  }

  getCeosRuleTemplate(id: number | string) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getCeosRuleTemplates() {
    return this.httpClient.get(this.apiUrl);
  }

  getCeosRuleTemplatesOfCompany(companyId) {
    return this.httpClient.get(`${this.apiUrl}?cid=${companyId}`);
  }

  createCeosRuleTemplate(data: RuleTemplate) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateCeosRuleTemplate(id: number, data: RuleTemplate) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteCeosRuleTemplate(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      is_active: false,
      is_deleted: true,
    });
  }
}
