import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationsOccupancyOverviewComponent } from './locations-occupancy-overview.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationsOccupancyOverviewComponent],
  entryComponents: [LocationsOccupancyOverviewComponent],
  providers: []
})
export class LocationsOccupancyOverviewModule {}
