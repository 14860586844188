import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { RuleFormsService } from '../../services/rule-forms.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-command-form',
  templateUrl: './command-form.component.html',
})
export class CommandFormComponent implements OnInit, OnDestroy {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  optionData: any;

  devices = [];
  services = [];
  notificationTemplates = [];
  isEnd: boolean;
  locationModifyType: string;
  locationModifyValue = new FormControl();
  notificationTemplateName: string;

  private subscribers: { [key: string]: any } = {};

  constructor(private ruleFormsService: RuleFormsService, private sharedService: SharedService) {}

  ngOnInit() {
    if (this.group && this.group.value) {
      const value = this.group.value;

      if (typeof value.executeOnlyOneTimeUntilRuleWasEnd !== 'undefined') {
        this.isEnd = false;
      } else if (typeof value.executeOnlyOneTimeUntilRuleWasStart !== 'undefined') {
        this.isEnd = true;
      }

      if (value.deviceTypeId) {
        this.devices = this.optionData.devices.filter((device: any) => device.deviceTypeId === value.deviceTypeId);
      } else {
        this.services = this.optionData.services;
        this.notificationTemplates = this.optionData.notificationTemplatesSource;
      }

      // locationProperty adjustValue or forceValue
      if (value.locationPropertyAdjustValue) {
        this.locationModifyType = 'adjustValue';
      } else if (value.locationPropertyForceValue) {
        this.locationModifyType = 'forceValue';
      }

      // if parameters exist mean edit mode
      if (this.locationModifyType && value.parameters && value.parameters.length) {
        // set location modify value
        this.locationModifyValue.setValue(value.parameters[0].value);
      }

      this.updateFormControl();

      // check notification template
      const notificationTemplateId = this.group.get('notificationTemplateId').value;
      if (notificationTemplateId) {
        const template = this.notificationTemplates.find((nt) => nt.id === notificationTemplateId);
        if (template) {
          this.notificationTemplateName = template.name || template.description;
        } else {
          this.notificationTemplateName = '';
          this.group.get('notificationTemplateId').reset();
        }
      }
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private updateFormControl() {
    switch (this.group.get('targetTypeId').value) {
      case 'd':
      case 'dvs':
        this.group.controls['deviceId'].setValidators(Validators.required);
        this.group.controls['locationId'].clearValidators();
        this.group.controls['locationId'].reset();
        this.group.controls['serviceTypeId'].clearValidators();
        this.group.controls['serviceTypeId'].reset();
        break;
      case 'l':
        this.group.controls['deviceId'].clearValidators();
        this.group.controls['deviceId'].reset();
        this.group.controls['locationId'].setValidators(Validators.required);
        this.group.controls['serviceTypeId'].clearValidators();
        this.group.controls['serviceTypeId'].reset();
        break;
      case 's':
        this.group.controls['deviceId'].clearValidators();
        this.group.controls['deviceId'].reset();
        this.group.controls['locationId'].clearValidators();
        this.group.controls['locationId'].reset();
        this.group.controls['serviceTypeId'].setValidators(Validators.required);
        break;
      default:
        break;
    }

    this.subscribers.locationModifyValue = this.locationModifyValue.valueChanges.subscribe((val) => {
      if (val && val.length) {
        // set parameters
        const parameters = [{ key: this.locationModifyType, value: val }];
        this.ruleFormsService.setCommandParameterFormArray(this.group, parameters);

        // set locationProperty adjustValue or forceValue
        switch (this.locationModifyType) {
          case 'adjustValue':
            this.group.controls['locationPropertyAdjustValue'].setValue(val);
            break;
          case 'forceValue':
            this.group.controls['locationPropertyForceValue'].setValue(val);
            break;
          default:
            break;
        }

        this.group.markAsDirty();
      } else {
        this.group.controls['parameters'].reset();
      }
    });
  }

  get parameters(): FormArray {
    return this.group.controls['parameters'] as FormArray;
  }

  get targetTypeId() {
    return this.group.get('targetTypeId').value;
  }

  get serviceTypeId() {
    return this.group.get('serviceTypeId').value;
  }

  // get notificationTemplateName() {
  //   const id = this.group.get('notificationTemplateId').value;
  //   if (id) {
  //     const nTemplate = this.notificationTemplates.find(nt => nt.id === id);
  //     return nTemplate ? nTemplate.name || nTemplate.description : id;
  //   }
  //   return;
  // }

  get deviceId() {
    return this.group.get('deviceId').value;
  }

  get action() {
    return this.group.get('action').value;
  }

  get locationPropertyTypeId() {
    return this.group.get('locationPropertyTypeId').value;
  }

  get locationPropertyMapping() {
    if (this.optionData && this.optionData.locationPropertiesList && this.locationPropertyTypeId) {
      const locationProperty = this.optionData.locationPropertiesList.find(
        (d) => d.key === this.locationPropertyTypeId
      );
      return locationProperty;
    }
    return;
  }

  get locationPropertyMappingValues() {
    if (this.locationPropertyMapping) {
      switch (this.locationPropertyMapping.type) {
        case 'boolean':
          return this.optionData.booleanOption;

        case 'deviceType':
          if (this.optionData && this.optionData.deviceTypesSource) {
            const deviceType = this.optionData.deviceTypesSource.find(
              (d) => d.key === this.locationPropertyMapping.deviceType
            );
            if (deviceType && deviceType.mappingValues && deviceType.mappingValues.length) {
              return this.sharedService.createSelectItems(deviceType.mappingValues, false, 'key');
            }
          }
          break;

        default:
          break;
      }
    }
    return;
  }

  get formBackgroundColor() {
    return this.groupId % 2 !== 0 ? 'grey lighten-4' : '';
  }
}
