import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItems, SelectItem } from '../../models';

@Component({
  selector: 'sc-value-operand',
  templateUrl: './value-operand.component.html',
  styleUrls: ['./value-operand.component.scss'],
})
export class ValueOperandComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  structure: { [key: string]: any } = {};

  @Input()
  isTemplate: boolean;

  booleanOptions: SelectItem[];

  constructor() {}

  ngOnInit(): void {
    this.booleanOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];

    // if (!this.isTemplate && this.form) {
    //   // prevent value field from editable
    //   this.form.get('value').disable();
    // }
  }
}
