import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { LocationTemplateFormsService } from '../../services/location-template-forms.service';

@Component({
  selector: 'sc-location-form',
  templateUrl: 'location-form.component.html'
})
export class LocationFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() selectItems: any;

  constructor(private formService: LocationTemplateFormsService) {}

  ngOnInit() {}

  // devices
  get devicesFormArray() {
    return this.form.get('devices') as FormArray;
  }
  addDevice() {
    const controls = <FormArray>this.form.controls['devices'];
    controls.push(this.formService.initDeviceFormGroup());
  }
  removeDevice(index: number) {
    const controls = <FormArray>this.form.controls['devices'];
    controls.removeAt(index);
  }

  // ruleTemplates
  get ruleTemplatesFormArray() {
    return this.form.get('ruleTemplates') as FormArray;
  }
  addRuleTemplate() {
    const controls = <FormArray>this.form.controls['ruleTemplates'];
    controls.push(this.formService.initRuleFormGroup());
  }
  removeRuleTemplate(index: number) {
    const controls = <FormArray>this.form.controls['ruleTemplates'];
    controls.removeAt(index);
  }

  // children
  get childrenFormArray() {
    return this.form.get('children') as FormArray;
  }
  addChild() {
    const controls = <FormArray>this.form.controls['children'];
    controls.push(this.formService.initLocationTemplateForm(true));
  }
  removeChild(index: number) {
    const controls = <FormArray>this.form.controls['children'];
    controls.removeAt(index);
  }
}
