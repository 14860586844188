import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SCCircleComponent } from './circle/circle.component';
import { SCRectangleComponent } from './rectangle/rectangle.component';
import { SCSquareComponent } from './square/square.component';

export const COMPONENTS: any[] = [SCCircleComponent, SCRectangleComponent, SCSquareComponent];

@NgModule({
  imports: [CommonModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: [],
})
export class SCShapesModule {}
