import { Injectable } from '@angular/core';

import { DeviceStateColors, DeviceIcons, DeviceStates } from '../models/device';
import { LocationStateColors, LocationStates, CleaningStates, CleaningStateColors } from '../models/location';
import {
  LocationPropertyColors,
  LocationPropertyBorderColors,
  LocationPropertyIcons,
} from '../models/location-property';
import { ObjectClasses, ObjectTypes } from '../models/object';
import { Cursors, CustomControlIcons } from '../models/floorplan';

declare let fabric: any;

@Injectable({ providedIn: 'root' })
export class FloorplanService {
  constructor() {}

  private createLocationObjectSubclass() {
    // Location Object
    fabric.LocationObject = fabric.util.createClass(fabric.Polygon, {
      type: ObjectTypes.Location,
      initialize: function (points, options) {
        options = options || {};
        points = points || [];

        // set default values
        options.borderColor = options.borderColor || '#222';
        options.cornerColor = options.cornerColor || '#222';
        options.cornerSize = options.cornerSize || 10;
        options.cornerStrokeColor = options.cornerStrokeColor || '#222';
        options.hasBorders = typeof options.hasBorders !== 'undefined' ? options.hasBorders : false;
        options.hasControls = typeof options.hasControls !== 'undefined' ? options.hasControls : false;
        options.hasRotatingPoint = typeof options.hasRotatingPoint !== 'undefined' ? options.hasRotatingPoint : false;
        options.objectCaching = typeof options.objectCaching !== 'undefined' ? options.objectCaching : false;
        options.lockMovementX = typeof options.lockMovementX !== 'undefined' ? options.lockMovementX : true;
        options.lockMovementY = typeof options.lockMovementY !== 'undefined' ? options.lockMovementY : true;
        options.lockRotation = typeof options.lockRotation !== 'undefined' ? options.lockRotation : true;
        options.lockScalingX = typeof options.lockScalingX !== 'undefined' ? options.lockScalingX : true;
        options.lockScalingY = typeof options.lockScalingY !== 'undefined' ? options.lockScalingY : true;
        options.moveable = typeof options.moveable !== 'undefined' ? options.moveable : false;
        options.selectable = typeof options.selectable !== 'undefined' ? options.selectable : false;
        options.strokeUniform = typeof options.strokeUniform !== 'undefined' ? options.strokeUniform : true;
        options.transparentCorners =
          typeof options.transparentCorners !== 'undefined' ? options.transparentCorners : false;

        this.callSuper('initialize', points, options);

        // additional attributes
        this.set('activeEvents', []);
        this.set('areaUnit', options.areaUnit || 'm');
        this.set('aspectRatio', options.aspectRatio || 100); // 1 cm on screen is 100cm
        this.set('id', options.id || new Date().getTime());
        this.set('label', options.label || 'Unassigned');
        this.set('labelFontColor', options.labelColor || '#0C2E55');
        this.set('labelFontFamily', options.labelFont || 'Lexend');
        this.set('labelFontSize', options.labelSize || 14);
        this.set('labelX', 0);
        this.set('labelY', 0);
        this.set('lhl', options.lhl || null);
        this.set('summaryConfig', options.summaryConfig || null);
        this.set('summaryIconWidth', 25);
        this.set('summaryTextWidth', 75);
        this.set('summaryItemHeight', 20);
        this.set('summaryItemSpace', 3);

        this.setLocationState(options.locationState);
        this._calcArea();

        this.on('modified', this._onModified);
      },
      toObject: function () {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          id: this.get('id'),
          area: this.get('area'),
          areaUnit: this.get('areaUnit'),
          aspectRatio: this.get('aspectRatio'),
          label: this.get('label'),
          lhl: this.get('lhl'),
          locationState: this.get('locationState'),
          summaryConfig: this.get('summaryConfig'),
        });
      },
      setLocationState: function (locationState) {
        locationState = locationState in LocationStates ? locationState : LocationStates.Unassigned;
        this.set('locationState', locationState);

        // set state color
        switch (this.locationState) {
          case LocationStates.Alert:
            // this.set('fill', LocationStateColors.AlertBackground);
            // this.set('stroke', LocationStateColors.AlertBorder);
            break;

          case LocationStates.Assigned:
            this.set('fill', LocationStateColors.AssignedBackground);
            // this.set('stroke', LocationStateColors.AssignedBorder);
            break;

          case LocationStates.Unassigned:
          default:
            this.set('fill', LocationStateColors.UnassignedBackground);
            // this.set('stroke', LocationStateColors.UnassignedBorder);
            break;
        }
      },
      setCleaningState: function (cleaningState) {
        if (cleaningState == CleaningStates.Clean) {
          this.set('fill', CleaningStateColors.CleanBackground);
        } else if (cleaningState == CleaningStates.Dirty) {
          this.set('fill', CleaningStateColors.DirtyBackground);
        }
      },
      _calcArea: function () {
        // 1m = 100cm
        // 1ft = 30.48cm
        // 1in = 2.54cm

        let area = 0;
        const totalPoints = this.points.length;
        if (totalPoints < 3) {
          // need at least 3 points for area calculation
          this.set('area', 0);
        }

        // pixel area calculation
        for (let i = 0; i < totalPoints; i++) {
          const nextPointIndex = (i + 1) % totalPoints;
          area += this.points[i].x * this.points[nextPointIndex].y - this.points[i].y * this.points[nextPointIndex].x;
        }

        // square pixel
        area = Math.abs(area / 2);

        // px per centimeter
        let cm = Math.round(fabric._ppi / 2.54);

        // square pixels to square centimeters
        area = (area / cm ** 2) * this.aspectRatio;

        if (this.areaUnit === 'm') {
          // square centimeters to square meters
          area = area / 100;
        } else if (this.areaUnit === 'ft') {
          // square centimeters to square feet
          area = area / 30.48;
        }

        this.set('area', area.toFixed(2));
      },
      _onModified: function (event) {
        // console.log('locationOnModified::event:', event);

        // TransformMatrix [scaleX, skewY, skewX, scaleY, translationX, translationY]
        const matrix = this.calcTransformMatrix();
        const transformedPoints = this.get('points')
          .map((p) => new fabric.Point(p.x - this.pathOffset.x, p.y - this.pathOffset.y))
          .map((p) => fabric.util.transformPoint(p, matrix));

        // update points instead of scaling
        this.set({
          points: transformedPoints,
          scaleX: 1,
          scaleY: 1,
        });

        // update dimentions (transform border and corner)
        this._setPositionDimensions({});

        // re-calculate area
        this._calcArea();
      },
      _isTextSummaryData: function (key) {
        const list = [
          'airQuality',
          'brightness',
          'consumption',
          'consumptionTotal',
          'humidity',
          'acFanspeed',
          'acMode',
          'acSetpoint',
          'hvacSetpointHeating',
          'acStatus',
          'squareMeter',
          'temperature',
          'numSocialDistanceViolations',
        ];
        return list.indexOf(key) >= 0 ? true : false;
      },
      _calcSummaryDimensions: function (items, startY) {
        const iconWidth = this.summaryIconWidth;
        const textWidth = this.summaryTextWidth;
        const itemHeight = this.summaryItemHeight;
        const spacer = this.summaryItemSpace;
        const objectWidth = this.width;

        const rows: any[] = [];
        let totalWidth = 0;
        const displayItems = [];

        // filter and check if items fitted in one row
        for (let i = 0; i < items.length; i++) {
          const item: any = items[i];

          if (item.hidden) {
            continue;
          } else if (
            item.key !== 'squareMeter' &&
            (!this.lhl || typeof this.lhl[item.key] === 'undefined' || this.lhl[item.key] === null)
          ) {
            continue;
          }

          item.height = itemHeight;
          if (this._isTextSummaryData(item.key)) {
            item.width = textWidth;
          } else {
            item.width = iconWidth;
          }
          totalWidth += item.width + spacer;

          // add item to display items
          displayItems.push(item);
        }

        if (objectWidth > totalWidth) {
          // items fit in one row
          rows.push({
            startX: -totalWidth / 2,
            startY: startY,
            items: displayItems,
          });
        } else {
          // items didn't fit in one row
          let x = 0;
          let y = startY;
          let currentRow = 0;
          let tmpRows = [];

          // calculate rows and item per row
          for (let i = 0; i < displayItems.length; i++) {
            const item: any = displayItems[i];

            if (item.hidden) {
              continue;
            }

            // total width of this item
            const nextX = x + item.width + spacer;

            if (nextX > objectWidth) {
              // this item didn't fit in this row, put in the next row
              currentRow += 1;
              x = item.width + spacer;
            } else {
              // this item fits in this row
              x = nextX;
            }

            if (!tmpRows[currentRow]) {
              // create new row
              tmpRows[currentRow] = [];
            }

            // add item into the row
            tmpRows[currentRow].push(item);
          }

          for (let i = 0; i < tmpRows.length; i++) {
            const startX = tmpRows[i].reduce((acc, cur) => acc + cur.width + spacer, 0);
            rows.push({
              startX: -(startX + spacer) / 2,
              startY: y + itemHeight * i + spacer * i,
              items: tmpRows[i],
            });
          }
        }

        return rows;
      },
      _summaryRender: function (ctx, items) {
        const dimensions = this._calcSummaryDimensions(items, this.labelY);
        const spacer = this.summaryItemSpace;

        for (let i = 0; i < dimensions.length; i++) {
          const dim = dimensions[i];
          let x = dim.startX;
          let y = dim.startY;

          for (let ii = 0; ii < dim.items.length; ii++) {
            const item = dim.items[ii];
            const prevItem = ii > 0 ? dim.items[ii - 1] : null;
            let icon;
            let text;
            let contentColor = this.labelFontColor;

            x += ii === 0 ? 0 : item.width;
            x += prevItem ? prevItem.width - item.width : 0;
            x += spacer;

            ctx.fillStyle = LocationPropertyColors.Grey; // default background color
            ctx.lineWidth = 1; // border width
            ctx.strokeStyle = LocationPropertyColors.Darkblue; // default border color

            if (item.key === 'squareMeter') {
              if (this.area) {
                text = this.area + (this.areaUnit ? this.areaUnit : '') + '\u00B2';
                ctx.fillStyle = LocationPropertyColors.Purple;
                icon = '\uf546';
              }
            } else if (item.key === 'avgParticulateMatter') {
              // TODO:
              // icon = 'PM2.5';
              // item.width = 60;
              // text = this.lhl[item.key].value + ' g/m³';
              continue;
            } else if (typeof this.lhl[item.key] !== 'undefined') {
              // lhl
              ctx.fillStyle = LocationPropertyColors[this.lhl[item.key].color];
              ctx.strokeStyle = LocationPropertyBorderColors[this.lhl[item.key].color];
              contentColor = LocationPropertyBorderColors[this.lhl[item.key].color];
              if (this._isTextSummaryData(item.key)) {
                text = this.lhl[item.key].value + this.lhl[item.key].unit;
              }
              icon = this.lhl[item.key].icon;
            }

            // draw square (background)
            if (icon || text) {
              const r = x + item.width,
                b = y + item.height,
                tl = 4,
                tr = 4,
                bl = 4,
                br = 4;
              ctx.beginPath();
              ctx.moveTo(x + tl, y);
              ctx.lineTo(r - tr, y);
              ctx.quadraticCurveTo(r, y, r, y + tr);
              ctx.lineTo(r, b - br);
              ctx.quadraticCurveTo(r, b, r - br, b);
              ctx.lineTo(x + bl, b);
              ctx.quadraticCurveTo(x, b, x, b - bl);
              ctx.lineTo(x, y + tl);
              ctx.quadraticCurveTo(x, y, x + tl, y);
              ctx.stroke();
              ctx.fill();
            }

            // draw icon
            if (typeof icon !== 'undefined') {
              // TODO: using font awesome 5
              let textX = x + this.summaryIconWidth / 2;
              let textY = y + this.summaryItemHeight / 2;

              ctx.font = `900 ${this.labelFontSize}px "Font Awesome 5 Free"`;
              ctx.fillStyle = contentColor;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(icon, textX, textY);
            }

            // draw text
            if (typeof text !== 'undefined') {
              let textX = x + this.summaryTextWidth / 2;
              let textY = y + this.summaryItemHeight / 2;

              ctx.font = this.labelFontSize * 0.725 + 'px ' + this.labelFontFamily;
              ctx.fillStyle = contentColor;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';

              if (icon) {
                ctx.textAlign = 'right';
                textX = x + this.summaryTextWidth - this.summaryIconWidth * 0.125;
              }

              ctx.fillText(text, textX, textY);
            }
          }
        }
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);

        const x = 0; // center of location
        const y = 0; // center of location

        if (this.label) {
          this.labelY = y - this.height * 0.25;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = 'bold ' + this.labelFontSize + 'px ' + this.labelFontFamily;
          ctx.fillStyle = this.labelFontColor;
          ctx.fillText(this.label, x, this.labelY - this.labelFontSize);
        }

        if (
          this.summaryConfig &&
          this.summaryConfig.enableCustomization &&
          this.summaryConfig.summaryItems &&
          this.summaryConfig.summaryItems.length
        ) {
          this._summaryRender(ctx, this.summaryConfig.summaryItems);
        } else {
          // default or disable customization
          const summaryItems = this.summaryConfig.summaryItems.map((item) => {
            item.hidden = false;
            return item;
          });
          this._summaryRender(ctx, summaryItems);
        }
      },
    });
    fabric.LocationObject.fromObject = function (object, callback) {
      return fabric.Object._fromObject(ObjectClasses.Location, object, callback, 'points');
    };
    // console.log('Location Klass:', fabric.util.getKlass(ObjectClasses.Location));
  }

  private createDeviceObjectSubclass() {
    // Device Object
    fabric.DeviceObject = fabric.util.createClass(fabric.Circle, {
      type: ObjectTypes.Device,
      initialize: function (options) {
        options = options || {};

        // set default values
        options.borderColor = options.borderColor || '#000';
        options.cornerColor = options.cornerColor || '#000';
        options.cornerSize = options.cornerSize || 10;
        options.cornerStrokeColor = options.cornerStrokeColor || '#000';
        options.hasBorders = typeof options.hasBorders !== 'undefined' ? options.hasBorders : false;
        options.hasControls = typeof options.hasControls !== 'undefined' ? options.hasControls : false;
        options.objectCaching = typeof options.objectCaching !== 'undefined' ? options.objectCaching : false;
        options.originX = options.originX || 'center';
        options.originY = options.originY || 'center';
        options.lockMovementX = typeof options.lockMovementX !== 'undefined' ? options.lockMovementX : true;
        options.lockMovementY = typeof options.lockMovementY !== 'undefined' ? options.lockMovementY : true;
        options.lockRotation = typeof options.lockRotation !== 'undefined' ? options.lockRotation : true;
        options.lockScalingX = typeof options.lockScalingX !== 'undefined' ? options.lockScalingX : true;
        options.lockScalingY = typeof options.lockScalingY !== 'undefined' ? options.lockScalingY : true;
        options.moveable = typeof options.moveable !== 'undefined' ? options.moveable : false;
        options.radius = options.radius || 15;
        options.selectable = typeof options.selectable !== 'undefined' ? options.selectable : false;
        options.strokeUniform = typeof options.strokeUniform !== 'undefined' ? options.strokeUniform : true;
        options.transparentCorners =
          typeof options.transparentCorners !== 'undefined' ? options.transparentCorners : false;

        this.callSuper('initialize', options);

        // custom controls
        this.set('controls', {
          cancelControl: fabric.Object.prototype.controls.cancelControl,
          saveControl: fabric.Object.prototype.controls.saveControl,
        });

        // additional attributes
        this.set('id', options.id || new Date().getTime());
        this.set('activeEvents', []);
        this.set('automation', options.automation || null);
        this.set('deviceModel', options.deviceModel || null);
        this.set('deviceScope', options.deviceScope || null);
        this.set('dhl', options.dhl || null);
        this.set('installationHeight', options.installationHeight || 0);
        this.set('location', options.location || null);
        this.set('relationships', []);

        this.setDeviceState(options.deviceState);

        // event handlers
        this.on('moving', this._onMoving);
      },
      toObject: function () {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          id: this.get('id'),
          automation: this.get('automation'),
          deviceModel: this.get('deviceModel'),
          deviceState: this.get('deviceState'),
          dhl: this.get('dhl'),
          installationHeight: this.get('installationHeight'),
          location: this.get('location'),
        });
      },
      setDeviceState: function (deviceState) {
        if (typeof deviceState !== 'undefined') {
          deviceState = deviceState in DeviceStates ? deviceState : DeviceStates.Unassigned;
          this.set('deviceState', deviceState);
        }

        // get icon image and color
        switch (this.deviceState) {
          case DeviceStates.Alert:
            this.set('deviceStateIcon', fabric._imageIcons.deviceIconAlert);
            this.set('fill', DeviceStateColors.AlertBackground);
            this.set('stroke', DeviceStateColors.AlertBorder);
            break;

          case DeviceStates.Assigned:
            if (this.deviceModel && this.deviceModel.deviceTypeKey === 'a') {
              this.set('deviceStateIcon', fabric._imageIcons.deviceIconAutomation);
              this.set('fill', DeviceStateColors.AutomationAssignedBackground);
            } else {
              this.set('deviceStateIcon', fabric._imageIcons.deviceIconAssigned);
              this.set('fill', DeviceStateColors.AssignedBackground);
            }
            this.set('stroke', DeviceStateColors.AssignedBorder);
            break;

          case DeviceStates.Unassigned:
          default:
            if (this.deviceModel && this.deviceModel.deviceTypeKey === 'a') {
              this.set('deviceStateIcon', fabric._imageIcons.deviceIconAutomation);
            } else {
              this.set('deviceStateIcon', fabric._imageIcons.deviceIconUnassigned);
            }
            this.set('fill', DeviceStateColors.UnassignedBackground);
            this.set('stroke', DeviceStateColors.UnassignedBorder);
            break;
        }

        // use device model icon if exist
        if (this.deviceState !== DeviceStates.Alert && this.deviceModel && this.deviceModel.modelId) {
          const deviceModelIcon = 'deviceModel' + this.deviceModel.modelId;
          if (fabric._imageIcons[deviceModelIcon]) {
            this.set('deviceStateIcon', fabric._imageIcons[deviceModelIcon]);
          }
        }
      },
      setCleaningState: function (cleaningState) {
        if (cleaningState == CleaningStates.Clean) {
          this.set('fill', CleaningStateColors.CleanBackground);
          this.set('stroke', CleaningStateColors.CleanBackground);
        } else if (cleaningState == CleaningStates.Dirty) {
          this.set('fill', CleaningStateColors.DirtyBackground);
          this.set('stroke', CleaningStateColors.DirtyBackground);
        }
      },
      _onMoving: function (event) {
        // console.log('deviceOnMove::event:', event);
        const obj = event.target;
        const canvas = obj.canvas;

        // update relationships
        for (const r of this.relationships) {
          if (this.deviceModel && this.deviceModel.deviceTypeKey && this.deviceModel.deviceTypeKey === 'a') {
            r.set({ x1: obj.left, y1: obj.top });
          } else {
            r.set({ x2: obj.left, y2: obj.top });
          }
        }

        // update device scope
        if (this.deviceScope) {
          this.deviceScope.set({ left: obj.left, top: obj.top });
          this.deviceScope.setCoords();
        }

        canvas.renderAll();
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);

        // update device style when no state pass
        this.setDeviceState();

        const w = this.width * 0.75;
        const h = this.height * 0.75;
        const x = -w / 2;
        const y = -h / 2;
        if (this.deviceStateIcon) {
          ctx.drawImage(this.deviceStateIcon, x, y, w, h);
        }
      },
    });
    fabric.DeviceObject.fromObject = function (object, callback) {
      return fabric.Object._fromObject(ObjectClasses.Device, object, callback);
    };
    // console.log('Device Klass:', fabric.util.getKlass(ObjectClasses.Device));
  }

  private createEventObjectSubclass() {
    // Event Object
    fabric.EventObject = fabric.util.createClass(fabric.Rect, {
      type: ObjectTypes.Event,
      initialize: function (options) {
        options = options || {};

        // set default values
        options.fill = options.fill || '#ddd';
        options.height = options.height || 25;
        options.originX = options.originX || 'center';
        options.originY = options.originY || 'center';
        options.rx = options.rx || 5;
        options.ry = options.ry || 5;
        options.stroke = options.stroke || '#444';
        options.width = options.width || 150;

        options.evented = false;
        options.hasBorders = false;
        options.hasControls = false;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockRotation = true;
        options.lockScalingX = true;
        options.lockScalingY = true;
        options.moveable = false;
        options.objectCaching = false;
        options.selectable = false;

        this.callSuper('initialize', options);

        // additional attributes
        this.set('id', options.id || new Date().getTime());
        this.set('eventType', typeof options.eventType !== 'undefined' ? options.eventType : '');
        this.set('fontColor', options.fontColor || '#444');
        this.set('fontFamily', options.fontFamily || 'Montserrat');
        this.set('fontSize', options.fontSize || 12);
        this.set('icon', typeof options.icon !== 'undefined' ? options.icon : '');
        this.set('label', typeof options.label !== 'undefined' ? options.label : '');
        this.set('showTriangle', typeof options.showTriangle === 'undefined' ? true : options.showTriangle);
        this.set('showValue', typeof options.showValue === 'undefined' ? true : options.showValue);
        this.set('unit', typeof options.unit !== 'undefined' ? options.unit : '');
        this.set('value', typeof options.value !== 'undefined' ? options.value : '');
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);

        ctx.font = `900 ${this.fontSize}px "Font Awesome 5 Free"`;
        ctx.fillStyle = this.fontColor;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        let x = 0; // this is center
        const y = 0; // this is center
        let text = '';

        if (typeof this.icon !== 'undefined' && this.icon !== null) {
          ctx.fillText(this.icon, -this.width / 2 + this.fontSize, y);
        }

        if (typeof this.label !== 'undefined' && !this.showValue) {
          text += this.label;
        }

        if (typeof this.value !== 'undefined' && this.showValue) {
          text += (text ? ': ' : '') + this.value;
          if (typeof this.unit !== 'undefined') {
            text += ' ' + this.unit;
          }
        }

        ctx.font = 'bold ' + this.fontSize + 'px ' + this.fontFamily;
        ctx.fillText(text, x, y);

        if (this.showTriangle) {
          // draw triangle at the bottom of the first
          const px1 = 0;
          const py1 = this.height / 2;
          ctx.beginPath();
          ctx.moveTo(px1 - 10, py1 - 1);
          ctx.lineTo(px1, py1 + 5);
          ctx.lineTo(px1 + 10, py1 - 1);
          ctx.closePath();
          ctx.fillStyle = this.fill;
          ctx.fill();

          ctx.beginPath();
          ctx.moveTo(px1 - 10, py1);
          ctx.lineTo(px1, py1 + 5);
          ctx.lineTo(px1 + 10, py1);
          ctx.lineWidth = 1;
          ctx.strokeStyle = this.stroke;
          ctx.stroke();
        }
      },
    });
    // fabric.EventObject.fromObject = function (object, callback) {
    //   return fabric.Object._fromObject(ObjectClasses.Event, object, callback);
    // };
    // console.log('Event Klass:', fabric.util.getKlass(ObjectClasses.Event));
  }

  private createDeviceRelationshipObjectSubclass() {
    // Device Relationship Object
    fabric.DeviceRelationshipObject = fabric.util.createClass(fabric.Line, {
      type: ObjectTypes.DeviceRelationship,
      initialize: function (points, options) {
        options = options || {};
        points = points || [];

        // set default values
        options.evented = false;
        options.hasBorders = false;
        options.hasControls = false;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockRotation = true;
        options.lockScalingX = true;
        options.lockScalingY = true;
        options.moveable = false;
        options.objectCaching = false;
        options.originX = 'center';
        options.originY = 'center';
        options.padding = 0;
        options.perPixelTargetFind = true;
        options.selectable = false;
        options.stroke = '#f00';
        options.strokeWidth = 1;

        this.callSuper('initialize', points, options);
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);
      },
    });
    // fabric.DeviceRelationshipObject.fromObject = function (object, callback) {
    //   return fabric.Object._fromObject(ObjectClasses.DeviceRelationship, object, callback);
    // };
    // console.log('DeviceRelationship Klass:', fabric.util.getKlass(ObjectClasses.DeviceRelationship));
  }

  private createDeviceScopeObjectSubclass() {
    // Device Scope Object
    fabric.DeviceScopeObject = fabric.util.createClass(fabric.Group, {
      type: ObjectTypes.DeviceScope,
      initialize: function (objects, options) {
        options = options || {};
        objects = objects || [];

        // set default values
        // options.evented = false;
        options.hasBorders = false;
        options.hasControls = false;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockRotation = true;
        options.lockScalingX = true;
        options.lockScalingY = true;
        options.moveable = false;
        options.objectCaching = false;
        options.originX = 'center';
        options.originY = 'center';
        options.padding = 0;
        options.perPixelTargetFind = true;
        options.selectable = false;
        options.stroke = '#f00';
        options.strokeWidth = 1;

        this.callSuper('initialize', objects, options);
      },
      _render: function (ctx) {
        this.callSuper('_render', ctx);
      },
    });
  }

  private customControlRenderer(icon: any) {
    return function (ctx, left, top, styleOverride, fabricObject) {
      const size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      if (icon) {
        ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      }
      ctx.restore();
    };
  }

  addCustomControl(
    controlName: string,
    mouseUpHandler: Function,
    x: number = 0,
    y: number = 0,
    offsetX: number = 0,
    offsetY: number = 0,
    icon?: any
  ) {
    fabric.Object.prototype.controls[controlName] = new fabric.Control({
      // actionHandler: () => {},
      cornerSize: 24,
      cursorStyle: Cursors.Link,
      mouseUpHandler: mouseUpHandler,
      offsetX,
      offsetY,
      x,
      y,
      render: this.customControlRenderer(icon),
    });
  }

  addCustomSubclasses() {
    this.createLocationObjectSubclass();
    this.createDeviceObjectSubclass();
    this.createDeviceRelationshipObjectSubclass();
    this.createDeviceScopeObjectSubclass();
    this.createEventObjectSubclass();
  }

  getPPI() {
    // create a div
    const div = document.createElement('div');
    div.style.width = '1in';
    // add the div to body
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(div);
    // get PPI and convert to number
    const ppi = parseFloat(window.getComputedStyle(div, null).getPropertyValue('width'));
    // remove the div
    body.removeChild(div);
    // store in fabric, so Location object can access it.
    fabric._ppi = ppi;
  }

  loadImages() {
    // clean up before re-load images
    if (fabric && !fabric._imageIcons) {
      fabric._imageIcons = {};
    }

    // load device icons
    for (const icon of Object.keys(DeviceIcons)) {
      const key = 'deviceIcon' + icon;
      fabric._imageIcons[key] = document.createElement('img');
      fabric._imageIcons[key].src = DeviceIcons[icon];
    }

    // load custom control icons
    for (const icon of Object.keys(CustomControlIcons)) {
      const key = 'controlIcon' + icon;
      fabric._imageIcons[key] = document.createElement('img');
      fabric._imageIcons[key].src = CustomControlIcons[icon];
    }
  }
}
