import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { WidgetCategoriesService } from '../services/widget-categories.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-widget-category-action-cell',
  templateUrl: './widget-category-action-cell.component.html',
  styleUrls: ['./widget-category-action-cell.component.scss'],
})
export class WidgetCategoryActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  private subscribers: any = {};

  constructor(private widgetCategoriesService: WidgetCategoriesService, private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'WidgetCategoryForm',
      data: this.cell.data,
      options: { modalTitle: 'WIDGET_CATEGORY_FORM_TITLE', fullScreen: true },
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: '_u',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this),
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.subscribers.delete = this.widgetCategoriesService.deleteWidgetCategory(this.cell.data.id).subscribe(
      (response: any) => {
        this.cell.context.parentComponent.updateRow({
          id: this.cell.data.id,
          isActive: false,
          isDeleted: true,
        });
        const title = this.sharedService.getTranslation('WIDGET_CATEGORY');
        const text = this.sharedService.getTranslation('DELETE_SUCCESS');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const title = this.sharedService.getTranslation('WIDGET_CATEGORY');
        const text = this.sharedService.getTranslation('DELETE_FAIL');
        this.sharedService.notify(title, text, 'error');
      }
    );
  }
}
