import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-autocomplete-input',
  styles: [
    `
      ::ng-deep .ui-autocomplete-dd .ui-autocomplete-dropdown {
        border: 1px solid #e6e6e6 !important;
        position: absolute;
        color: var(--darkblue_color);
      }
    `,
  ],
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
      ></sc-field-label>

      <div>
        <p-autoComplete
          [inputId]="inputId"
          [formControlName]="controlName"
          [suggestions]="options"
          (completeMethod)="getSuggestions($event)"
          [minLength]="1"
          [dropdown]="true"
          [placeholder]="placeholder"
        ></p-autoComplete>
      </div>

      <sc-field-error [for]="label" [fieldControl]="form.controls[controlName]"></sc-field-error>
    </div>
  `,
})
export class SCAutocompleteInputComponent implements OnInit {
  @Input() controlName: string;
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() showLabel: boolean;
  @Input() placeholder: string;
  @Input() options: string[] = [];

  @Output() valueChanged = new EventEmitter<string>();

  // suggestions: string[] = [];

  inputId: string;

  constructor() {}

  ngOnInit() {
    this.placeholder = this.placeholder || '';
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-autocomplete-input_' + this.controlName + new Date().getTime();
    // this.suggestions = this.options;
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.options) {
  //     this.suggestions = changes.options.currentValue;
  //   }
  // }

  getSuggestions(event) {
    // this.suggestions = [];
    // for (let i = 0; i < this.options.length; i++) {
    //   const item = this.options[i];
    //   if (item.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
    //     this.suggestions.push(item);
    //   }
    // }
    // console.log(event);
    this.options = [];
    this.valueChanged.emit(event.query.toLowerCase());
  }
}
