import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import * as moment from 'moment';

@Component({
  selector: 'sc-guest-request-reaction-time-cell',
  templateUrl: 'guest-request-reaction-time-cell.component.html',
})
export class GuestRequestReactionTimeCellComponent implements ICellRendererAngularComp {
  cell: any;
  data: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
    this.data = this.cell.data;
  }

  refresh(): boolean {
    return false;
  }

  dateFormat(datetime) {
    if (typeof datetime !== 'undefined' && datetime !== null) {
      return moment(datetime).fromNow(true);
    }
    return '';
  }

  get statusColor() {
    if (this.data.isAccepted) {
      return 'green';
    } else if (this.data.isDeclined) {
      return 'green';
    } else {
      const minDiff = moment().diff(moment(this.data.createdAt), 'minute');
      if (minDiff > 10) {
        return 'red';
      } else if (minDiff > 5) {
        return 'orange';
      } else {
        return 'green';
      }
    }
  }

  get statusTime() {
    if (this.data.isAccepted) {
      return moment(this.data.acceptedAt).from(moment(this.data.createdAt), true);
    } else if (this.data.isDeclined) {
      return moment(this.data.declinedAt).from(moment(this.data.createdAt), true);
    }
    return '';
  }
}
