import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { GuestSessionActionCellComponent } from './guest-session-action-cell/guest-session-action-cell.component';
import { GuestSessionFormComponent } from './guest-sessions-form/guest-sessions-form.component';
import { GuestSessionsTableComponent } from './guest-sessions-table/guest-sessions-table.component';

import { GuestSessionFormsService } from './services/guest-session-form.service';
import { GuestSessionsService } from './services/guest-sessions.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    GuestSessionActionCellComponent,
    GuestSessionFormComponent,
    GuestSessionsTableComponent,
  ],
  entryComponents: [
    GuestSessionActionCellComponent,
    GuestSessionFormComponent,
    GuestSessionsTableComponent,
  ],
  providers: [GuestSessionFormsService, GuestSessionsService],
})
export class GuestSessionsModule {}
