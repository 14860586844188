<!-- Configuration modal -->
<p-dialog
  header="Config"
  [(visible)]="isConfigModalOpen"
  appendTo="body"
  [style]="{ width: '80vw', maxWidth: '800px' }"
>
  <form [formGroup]="configForm" class="sc-widget-config__form">
    <div>
      <div class="ui-fluid">
        <div class="p-field p-grid">
          <label for="firstname" class="p-col-12 p-md-3">{{
            "WIDGET_CUSTOM_TITLE" | translate
          }}</label>
          <div class="p-col-12 p-md-9">
            <div class="ui-inputgroup display-flex">
              <input
                type="text"
                pInputText
                [placeholder]="defaultWidgetName"
                formControlName="name"
              />
              <button
                pButton
                type="button"
                icon="pi pi-undo"
                (click)="resetWidgetName()"
              ></button>
            </div>
          </div>
        </div>
        <div
          class="p-field p-grid p-align-center"
          style="display: flex; flex-direction: column; align-items: stretch"
        >
          <p-accordion>
            <p-accordionTab
              header="{{ 'ELECTRICITY' | translate }}"
              [selected]="true"
            >
              <div class="div-category">
                <p-checkbox
                  inputId="showElec"
                  [binary]="true"
                  [formControl]="configForm?.get('showElec')"
                  [label]="'ACTIVE' | translate"
                ></p-checkbox>
                <p-pickList
                  sourceHeader="{{ 'AVAILABLE_DEVICES' | translate }}"
                  targetHeader="{{ 'SELECTED_DEVICES' | translate }}"
                  [filterBy]="filterBy"
                  dragdrop="true"
                  [source]="elecMeterDevices"
                  [target]="selectedElecMeterDevices"
                  [showSourceControls]="false"
                  [showTargetControls]="false"
                >
                  <ng-template let-device pTemplate="item">
                    <div style="display: flex; column-gap: 4px">
                      <div *ngIf="isMultisites">
                        <b>{{ device.siteName }}</b> -
                      </div>
                      <div>
                        {{ device.description }}
                      </div>
                    </div>
                  </ng-template>
                </p-pickList>
              </div>
            </p-accordionTab>
            <p-accordionTab header="{{ 'GAZ' | translate }}">
              <div class="div-category">
                <p-checkbox
                  inputId="showGaz"
                  [binary]="true"
                  [formControl]="configForm?.get('showGaz')"
                  [label]="'ACTIVE' | translate"
                ></p-checkbox>
                <p-pickList
                  sourceHeader="{{ 'AVAILABLE_DEVICES' | translate }}"
                  targetHeader="{{ 'SELECTED_DEVICES' | translate }}"
                  dragdrop="true"
                  filterBy="description"
                  [source]="gazMeterDevices"
                  [target]="selectedGazMeterDevices"
                  [showSourceControls]="false"
                  [showTargetControls]="false"
                >
                  <ng-template let-device pTemplate="item">
                    <div>
                      {{ device.description }}
                    </div>
                  </ng-template>
                </p-pickList>
              </div>
            </p-accordionTab>
            <p-accordionTab header="{{ 'WATER' | translate }}">
              <div class="div-category">
                <p-checkbox
                  inputId="showWater"
                  [binary]="true"
                  [formControl]="configForm?.get('showWater')"
                  [label]="'ACTIVE' | translate"
                ></p-checkbox>
                <p-pickList
                  sourceHeader="{{ 'AVAILABLE_DEVICES' | translate }}"
                  targetHeader="{{ 'SELECTED_DEVICES' | translate }}"
                  filterBy="description"
                  dragdrop="true"
                  [source]="waterMeterDevices"
                  [target]="selectedWaterMeterDevices"
                  [showSourceControls]="false"
                  [showTargetControls]="false"
                >
                  <ng-template let-device pTemplate="item">
                    <div>
                      {{ device.description }}
                    </div>
                  </ng-template>
                </p-pickList>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </div>
  </form>
  <p-footer class="air-quality-live__footer">
    <button
      type="button"
      (click)="saveConfig()"
      pButton
      label="{{ 'SUBMIT' | translate }}"
      class="ui-button-success"
    ></button>
  </p-footer>
</p-dialog>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body">
    <ng-container *ngIf="!isConfigured()">
      <div class="sc-flex">
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          label="{{ 'WIDGET_CONFIGURATION_REQUIRED' | translate }}"
        ></button>
      </div>
    </ng-container>
    <ng-container *ngIf="isConfigured() && chartData">
      <plotly-plot
        [data]="chartData"
        [layout]="chartLayout"
        [config]="{ displayModeBar: false }"
      ></plotly-plot>
    </ng-container>
  </div>
</ng-template>

<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">{{ widgetName }}</h4>
      <div class="sc-widget-menu">
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          title="Config"
          [disabled]="!isInitialized"
          class="consumptions-action-btn"
        ></button>
      </div>
    </div>
    <ng-container
      *ngIf="
        isInitialized && (!isConfigured() || (isConfigured() && chartData));
        then content;
        else spinner
      "
    ></ng-container>
  </div>
</div>
