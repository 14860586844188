<div [formGroup]="group" class="ui-g-12">
  <div formGroupName="recurringOpts">
    <!-- INTERVAL_UNIT -->
    <div class="ui-g-12">
      <label for="intervalUnit" class="sc-form-label">
        {{ 'INTERVAL' | translate }}
      </label>
      <p-dropdown
        inputId='intervalUnit'
        [options]="intervalList"
        placeholder="{{ 'SELECT' | translate }} {{ 'INTERVAL' | translate }}"
        formControlName="intervalUnit"
        filter="true"
        appendTo="body"
      ></p-dropdown>
    </div>

    <!-- EVERY -->
    <div [ngSwitch]="group.get('recurringOpts.intervalUnit').value" class="ui-g-12">
      <div *ngSwitchCase="'minute'">
        <span class="mr-3">
          {{ 'EVERY' | translate }}
        </span>
        <span class="mr-3">
          <p-spinner
            formControlName="minuteInterval"
            [min]="0"
            placeholder="MM"
            size="4"
          ></p-spinner>
        </span>
        <span>
          {{ 'MINUTES' | translate }}
        </span>
      </div>

      <div *ngSwitchCase="'hour'">
        <span class="mr-3">
          {{ 'EVERY' | translate }}
        </span>
        <span class="mr-3">
          <p-spinner
            formControlName="hourInterval"
            [min]="0"
            placeholder="HH"
            size="4"
          ></p-spinner>
        </span>
        <span>
          {{ 'HOURS' | translate }}
        </span>
      </div>

      <div *ngSwitchCase="'week'">
        <div class="ui-g-12">
          <div class="ui-g-6">
            <div>
              <p-checkbox
                binary="true"
                label="{{ 'SUNDAY' | translate }} (0)"
                [formControl]="group.get('recurringOpts.sunday')"
              ></p-checkbox>
            </div>
            <div>
              <p-checkbox
                binary="true"
                label="{{ 'MONDAY' | translate }} (1)"
                [formControl]="group.get('recurringOpts.monday')"
              ></p-checkbox>
            </div>
            <div>
              <p-checkbox
                binary="true"
                label="{{ 'TUESDAY' | translate }} (2)"
                [formControl]="group.get('recurringOpts.tuesday')"
              ></p-checkbox>
            </div>
            <div>
              <p-checkbox
                binary="true"
                label="{{ 'WEDNESDAY' | translate }} (3)"
                [formControl]="group.get('recurringOpts.wednesday')"
              ></p-checkbox>
            </div>

          </div>
          <div class="ui-g-6">
            <div>
              <p-checkbox
                binary="true"
                label="{{ 'THURSDAY' | translate }} (4)"
                [formControl]="group.get('recurringOpts.thursday')"
              ></p-checkbox>
            </div>
            <div>
              <p-checkbox
                binary="true"
                label="{{ 'FRIDAY' | translate }} (5)"
                [formControl]="group.get('recurringOpts.friday')"
              ></p-checkbox>
            </div>
            <div>
              <p-checkbox
                binary="true"
                label="{{ 'SATURDAY' | translate }} (6)"
                [formControl]="group.get('recurringOpts.saturday')"
              ></p-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchDefault></div>
    </div>

    <!-- AT -->
    <div *ngIf="showTimepicker()" class="ui-g-12">
      <div class="ui-g">
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g-12 ui-md-2">
            {{ 'AT' | translate }}
          </div>

          <div class="ui-g-12 ui-md-5">
            <p-spinner
              type="number"
              formControlName="timeHour"
              [min]="0"
              [max]="23"
              placeholder="HH"
              size="4"
            ></p-spinner>
          </div>

          <div class="ui-g-12 ui-md-5">
            <p-spinner
              type="number"
              formControlName="timeMinute"
              [min]="0"
              [max]="59"
              placeholder="MM"
              size="4"
            ></p-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
