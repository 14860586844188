import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-textarea',
  template: `
    <div [formGroup]="form" class="sc-textarea-group">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
        [subtitle]="secondaryLabel"
        [description]="description"
        [warning]="warning"
      ></sc-field-label>

      <textarea
        class="sc-textarea-value"
        pInputTextarea
        [id]="inputId"
        [formControlName]="controlName"
        [cols]="cols"
        [rows]="rows"
      ></textarea>

      <sc-field-error [for]="label" [fieldControl]="form.controls[controlName]"></sc-field-error>
    </div>
  `,
})
export class SCTextareaComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  cols: number;
  @Input()
  rows: number;
  @Input()
  description: string;
  @Input()
  warning: string;
  @Input()
  secondaryLabel: string;

  inputId: string;

  constructor() {}

  ngOnInit() {
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-textarea_' + this.controlName + new Date().getTime();
    this.cols = this.cols || 30;
    this.rows = this.rows || 1;
  }
}
