import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { ColDef } from 'ag-grid';
import { Site } from '@widgets/sites/models/site';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';

import { EnergyEfficiencyRatingActionCellComponent } from './action-cell/action-cell.component';
import { EnergyEfficiencyRatingRatingCellComponent } from './rating-cell/rating-cell.component';

@Component({
  selector: 'sc-energy-efficiency-rating',
  templateUrl: `./energy-efficiency-rating.component.html`,
})
export class EnergyEfficiencyRatingComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: { [key: string]: any }[];
  tableTitle: string;
  toolbarItems: string[];

  isFirstTime = true;
  isFetching = false;
  widgetName: string = '';

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.WATCH_SITE = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
      }
    });

    this.initTable();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite) {
      this.isFetching = true;
      const { id } = this.selectedSite;
      this.subscribers.GET_DATASET = this.widgetService.getEnergyEfficiencyRating(id).subscribe(
        (result: any) => {
          this.dataset = result.data;
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  private initTable() {
    this.tableTitle = 'ENERGY_EFFICIENCY_RATING';
    this.toolbarItems = [];
    this.columns = this.createColumns();
  }

  private createColumns(): ColDef[] {
    return [
      {
        headerName: 'LOCATION',
        colId: 'locationName',
        field: 'locationName',
      },
      {
        headerName: 'RATING',
        colId: 'rating',
        field: 'rating',
        cellRendererFramework: EnergyEfficiencyRatingRatingCellComponent,
      },
      {
        headerName: '',
        colId: 'action',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: EnergyEfficiencyRatingActionCellComponent,
      },
    ];
  }
}
