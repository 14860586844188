<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!--Header-->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6 ui-g-nopad">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>

          <div class="ui-g-6 text-right">
            <p-toggleButton
              formControlName="isActive"
              offIcon="fa fa-square-o"
              offLabel="{{ 'ACTIVE' | translate }}"
              onIcon="fa fa-check-square-o"
              onLabel="{{ 'ACTIVE' | translate }}"
            ></p-toggleButton>
          </div>
        </div>
      </p-header>

      <!--Body-->
      <div class="ui-g">
        <div class="ui-g-12">
          <sc-dropdown
            controlName="categories"
            [form]="form"
            label="{{ 'CATEGORIES' | translate }}"
            [options]="optionData['requestCategories']"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>
      </div>

      <div class="ui-g mt-5">
        <div class="ui-g-10 ui-g-nopad">
          <h2 class="m-0">
            {{ 'GUEST_REQUEST_PRESET' | translate }} {{ 'TITLE' | translate }}
          </h2>
        </div>

        <div class="ui-g-2 text-right">
          <button
            pButton
            type="button"
            class="ui-button-success"
            pTooltip="{{ 'ADD' | translate }} {{ 'GUEST_REQUEST_PRESET' | translate }} {{ 'TITLE' | translate }}"
            icon="fa fa-plus"
            (click)="addMultiLanguage('title')"
          ></button>
        </div>
      </div>

      <div class="ui-g">
        <div class="ui-g-12" formArrayName="title">
          <ul class="template-menu">
            <li *ngFor="let ctrl of title.controls; let i=index"
              class="template-menu-item"
              [ngClass]="{'active': currentTitleTemplate === i}"
            >
              <a (click)="currentTitleTemplate = i" class="sc-clickable">
                <span [ngClass]="flagIcon(ctrl.get('locale').value)"></span>
                {{ (ctrl.get('locale').value | uppercase) || ('NEW' | translate) }}
              </a>
            </li>
          </ul>

          <div *ngFor="let ctrl of title.controls; let i=index" class="template-form">
            <multi-language-form
              *ngIf="i === currentTitleTemplate"
              [group]="ctrl"
              [groupId]="i"
              [optionData]="optionData"
              (onRemove)="removeMultiLanguage('title', i)"
            ></multi-language-form>
          </div>
        </div>
      </div>

      <div class="ui-g mt-5">
        <div class="ui-g-10 ui-g-nopad">
          <h2 class="m-0">
            {{ 'GUEST_REQUEST_PRESET' | translate }} {{ 'DESCRIPTION' | translate }}
          </h2>
        </div>

        <div class="ui-g-2 text-right">
          <button
            pButton
            type="button"
            class="ui-button-success"
            icon="fa fa-plus"
            pTooltip="{{ 'ADD' | translate }} {{ 'GUEST_REQUEST_PRESET' | translate }} {{ 'DESCRIPTION' | translate }}"
            (click)="addMultiLanguage('description')"
          ></button>
        </div>
      </div>

      <div class="ui-g">
        <div class="ui-g-12" formArrayName="description">
          <ul class="template-menu">
            <li *ngFor="let ctrl of description.controls; let i=index"
              class="template-menu-item"
              [ngClass]="{'active': currentDescriptionTemplate === i}"
            >
              <a (click)="currentDescriptionTemplate = i" class="sc-clickable">
                <span [ngClass]="flagIcon(ctrl.get('locale').value)"></span>
                {{ (ctrl.get('locale').value | uppercase) || ('NEW' | translate) }}
              </a>
            </li>
          </ul>

          <div *ngFor="let ctrl of description.controls; let i=index" class="template-form">
            <multi-language-form
              *ngIf="i === currentDescriptionTemplate"
              [group]="ctrl"
              [groupId]="i"
              [optionData]="optionData"
              (onRemove)="removeMultiLanguage('description', i)"
            ></multi-language-form>
          </div>
        </div>
      </div>


      <!-- Error Message -->
      <div class="ui-g mt-3">
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>
      </div>

      <!--Footer-->
      <p-footer>
        <div class="ui-g-6">
          <sc-confirm-trigger
            *ngIf="editMode && !formData?.isDeleted"
            buttonClass="ui-button-danger"
            buttonIcon="fa fa-trash"
            buttonLabel="{{ 'DELETE' | translate }}"
            (onAccept)="delete()"
          ></sc-confirm-trigger>
        </div>

        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            label="{{ 'CANCEL' | translate }}"
            (click)="dismissModal('cancel')"
          ></button>

          <sc-submit-button
            *ngIf="!formData?.isDeleted || formData?.isActive"
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
