<div *ngIf="dataset.length then tableTpl; else emptyTpl"></div>

<ng-template #tableTpl>
  <p-table [value]="dataset">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'SUBJECT' | translate }}</th>
        <th>{{ 'CONTENT' | translate }}</th>
        <th>{{ 'AT' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data.subject }}</td>
        <td>{{ data.text }}</td>
        <td>{{ getCreatedAt(data) }}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>

<ng-template #emptyTpl>
  <div>No data</div>
</ng-template>
