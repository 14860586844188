import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';
import { config as appConfig } from '@app/config';

@Component({
  selector: 'sc-room-correction',
  templateUrl: './room-correction.component.html',
  styleUrls: ['./room-correction.component.scss'],
})
export class RoomCorrectionComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  chartData: any;
  chartLayout: any;
  dataset: any;
  fetchInterval: any;
  isFetching: boolean;
  isFirstTime: boolean;
  viewMode: string;
  widgetData: any;
  widgetHeight: string;
  widgetWidth: string;
  widgetName: string = '';

  private appConfig = appConfig();
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    this.widgetWidth = '100%';
    this.widgetHeight = this.config.rows * this.appConfig.gridConfig.fixedRowHeight - 80 + 'px';

    // Get Selected Site
    this.subscribers.getSelectedSite = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.switchViewMode('HISTORY');
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite && this.selectedSite.id) {
      this.isFetching = true;
      this.subscribers.getDataset = this.widgetService.getRoomCorrection(this.selectedSite.id).subscribe(
        (result: any) => {
          this.dataset = result.data;
          this.createChart();
          this.createWidgetData();
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  switchViewMode(mode) {
    this.viewMode = mode;
  }

  private createWidgetData() {
    this.widgetData = [
      {
        customClass: 'lightgreen',
        label: 'TODAY',
        primaryText: this.dataset.today,
        secondaryText: 'TIMES',
        size: 'small',
      },
      {
        customClass: 'lightgreen',
        label: 'LAST_X_DAYS',
        labelParams: { value: 7 },
        primaryText: this.formatDisplayData(this.dataset.last_7_days),
        secondaryText: 'TIMES',
        size: 'small',
      },
      {
        customClass: 'lightgreen',
        label: 'THIS_MONTH',
        primaryText: this.formatDisplayData(this.dataset.this_month),
        secondaryText: 'TIMES',
        size: 'small',
      },
      {
        customClass: 'lightgreen',
        label: 'THIS_YEAR',
        primaryText: this.formatDisplayData(this.dataset.this_year),
        secondaryText: 'TIMES',
        size: 'small',
      },
      {
        customClass: 'lightgreen',
        label: 'TOTAL',
        primaryText: this.formatDisplayData(this.dataset.total),
        secondaryText: 'TIMES',
        size: 'small',
      },
    ];
  }

  private formatDisplayData(data: number): string {
    return data >= 1000 ? this.sharedService.numberFormat(data / 1000, 1) + 'K' : data + '';
  }

  private createChartData() {
    const patterns = { ...this.dataset.energy_saving_patterns };
    const keys = Object.keys(patterns);
    if (!keys.length) {
      return;
    }

    const labels = [];
    const values = [];

    for (const k of keys) {
      labels.push(k.replace(/\_/gi, ' '));
      values.push(patterns[k]);
    }
    return { labels, values };
  }

  private createChart() {
    const data = this.createChartData();
    if (!data) {
      return;
    }

    this.chartData = [
      {
        type: 'pie',
        labels: data.labels,
        values: data.values,
        marker: { colors: this.appConfig.colors },
        textinfo: 'label+percent',
        textposition: 'outside',
        automargin: true,
      },
    ];

    this.chartLayout = {
      margin: { t: 0, b: 0, r: 0, l: 0, pad: 5 },
      font: { family: 'Barlow' },
      showlegend: false,
      autosize: true,
    };
  }
}
