<form [formGroup]="form" novalidate class="white">
  <div class="ui-g">
    <!-- BENCHMARK_TEMPLATE FIELD -->
    <div class="ui-g-6 ui-md-3">
      <sc-dropdown
        controlName="benchmarkTemplate"
        [form]="form"
        label="{{ 'BENCHMARK_TEMPLATE' | translate }}"
        [options]="selectItems['benchmarkTemplates']"
        [showLabel]="false"
      ></sc-dropdown>
    </div>

    <!-- LOCATIONS FIELD -->
    <div class="ui-g-6 ui-md-3">
      <sc-tree-multi-select
        controlName="locations"
        [form]="form"
        label="{{ 'LOCATIONS' | translate }}"
        [options]="selectItems['locations']"
        [showLabel]="false"
      ></sc-tree-multi-select>
    </div>

    <!-- QUICK DATE FILED -->
    <div class="ui-g-12 ui-md-6">
      <sc-quick-date-select
        [showOnlyDate]="true"
        (afterInit)="onQucikDateInit($event)"
        (valueChange)="onSelectDate($event)"
      ></sc-quick-date-select>
    </div>
  </div>

  <div class="ui-g" *ngIf="!disabled">
    <div class="ui-g-12 text-right">
      <sc-confirm-trigger
        buttonIcon="fa fa-gear"
        buttonLabel="{{ 'GENERATE' | translate }}"
        [displayInline]="true"
        header="{{ 'BENCHMARK_GENERATING' | translate }}"
        message="{{ 'BENCHMARK_GENERATING_CONFIRM' | translate:benchmarkGenerateTranslateParams }}"
        (onAccept)="generate()"
        [disabled]="!form.valid || form.pristine"
      ></sc-confirm-trigger>

      <sc-modal-trigger
        buttonIcon="fa fa-line-chart"
        buttonLabel="{{ 'ANALYSE' | translate }}"
        [displayInline]="true"
        [modalConfig]="analyseConfig"
        [disabled]="!form.valid || form.pristine"
      ></sc-modal-trigger>

      <button
        pButton
        type="button"
        class="ui-button-secondary"
        icon="fa fa-search"
        label="{{ 'SEARCH' | translate }}"
        (click)="search()"
        [disabled]="!form.valid || form.pristine"
      ></button>
    </div>
  </div>
</form>
