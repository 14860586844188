<div>
  <sc-field-label
    *ngIf="showLabel"
    [for]="inputId"
    [fieldControl]="control || form.controls[controlName]"
    [title]="label"
    [description]="description"
    [warning]="warning"
  ></sc-field-label>

  <div class="dropdown-input" (click)="op.toggle($event)">
    <div class="selected-items">
      <ng-container *ngIf="displayItems">
        <ng-container *ngIf="selected?.length <= maxSelectedDisplay">
          <span *ngFor="let item of displayItems; let i = index">
            {{ i > 0 ? "," : "" }} {{ item }}
          </span>
        </ng-container>

        <ng-container *ngIf="selected?.length > maxSelectedDisplay">
          <span>{{ selected.length }} {{ "SELECTED" | translate }}</span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!displayItems?.length && placeholder">
        <span class="placeholder">{{ placeholder }}</span>
      </ng-container>

      <ng-container *ngIf="!displayItems?.length && !placeholder">
        <span class="placeholder">{{ "SELECT" | translate }} {{ label }}</span>
      </ng-container>
    </div>

    <div class="trigger"><i class="fa fa-chevron-down"></i></div>
  </div>

  <sc-field-error
    [for]="label"
    [fieldControl]="control || form.controls[controlName]"
  ></sc-field-error>

  <p-overlayPanel #op appendTo="body" styleClass="tree-multi-select">
    <p-tree
      styleClass="dropdown-tree"
      [value]="options"
      [propagateSelectionUp]="false"
      [propagateSelectionDown]="propagateSelectionDown"
      selectionMode="checkbox"
      [(selection)]="selected"
      (onNodeSelect)="nodeSelect($event)"
      (onNodeUnselect)="nodeSelect($event)"
      [loading]="options ? false : true"
      loadingIcon="fa-refresh"
    ></p-tree>
    <!-- [metaKeySelection]="false" -->
  </p-overlayPanel>
</div>
