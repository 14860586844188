import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AverageHumidityComponent } from './average-humidity.component';

@NgModule({
  imports: [SharedModule],
  declarations: [AverageHumidityComponent],
  entryComponents: [AverageHumidityComponent]
})
export class AverageHumidityModule {}
