<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!-- HEADER -->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6 ui-g-nopad">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>
          <div class="ui-g-6 text-right">
            <p-toggleButton
              formControlName="isActive"
              onLabel="{{ 'ACTIVE' | translate }}"
              onIcon="fa fa-check-square-o"
              offLabel="{{ 'ACTIVE' | translate }}"
              offIcon="fa fa-square-o"
            ></p-toggleButton>
          </div>
        </div>
      </p-header>

      <!-- BODY -->
      <div class="ui-g ui-g-nopad">
        <!-- NAME FIELD -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- DESCRIPTION FIELD -->
        <div class="ui-g-12">
          <sc-textarea
            controlName="description"
            [form]="form"
            label="{{ 'DESCRIPTION' | translate }}"
          ></sc-textarea>
        </div>

        <!-- COMMANDS -->
        <div class="ui-g-12">
          <div class="sc-flex align-items-center justify-content-between">
            <h3 class="m-0">{{ 'COMMANDS' | translate }}</h3>

            <button
              pButton
              type="button"
              class="ui-button-success"
              icon="fa fa-plus"
              pTooltip="{{ 'ADD' | translate }} {{ 'COMMAND' | translate }}"
              appendTo="body"
              (click)="addCommand()"
            ></button>
          </div>
        </div>

        <div formArrayName="commands" class="ui-g-12">
          <div *ngFor="let command of commandsFormArray.controls; let i=index" [formGroupName]="i">
            <sc-command-form
              [group]="command"
              [groupId]="i"
              [optionData]="selectItems"
              (removed)="removeCommand(i)"
            ></sc-command-form>
          </div>
        </div>


       <!-- ERROR MESSAGE -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>
      </div>

      <!-- FOOTER -->
      <p-footer>
        <div class="ui-g-6">
          <sc-confirm-trigger
            *ngIf="editMode && !formData?.isDeleted"
            buttonIcon="fa fa-trash"
            buttonClass="ui-button-danger"
            buttonLabel="{{ 'DELETE' | translate }}"
            (onAccept)="delete()"
          ></sc-confirm-trigger>
        </div>
        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            label="{{ 'CANCEL' | translate }}"
            (click)="dismissModal('cancel')"
          ></button>
          <sc-submit-button
            *ngIf="!formData?.isDeleted || formData?.isActive"
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="submitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
