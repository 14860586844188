import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-submit-button',
  styleUrls: ['./submit-button.component.scss'],
  templateUrl: './submit-button.component.html',
})
export class SCSubmitButtonComponent implements OnInit {
  @Input()
  buttonClass: string;
  @Input()
  disabled: boolean;
  @Input()
  hideIcon: boolean = false;
  @Input()
  label: string = 'CONFIRM';
  @Input()
  pending: boolean;
  @Input()
  pendingText: string = 'PROCESSING';

  @Output()
  submitted = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
