import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { SCWidgetService } from './widget.service';

import { EmptyWidgetComponent } from './empty-widget/empty-widget.component';

// ag-grid shared components
import { CheckboxCellComponent } from './_shared/checkbox-cell/checkbox-cell.component';
import { FlagCellComponent } from './_shared/flag-cell/flag-cell.component';
import { IconCellComponent } from './_shared/icon-cell/icon-cell.component';
import { LocaleTextCellComponent } from './_shared/locale-text-cell/locale-text-cell.component';
import { MultiLanguageCellComponent } from './_shared/multi-language-cell/multi-language-cell.component';
import { PhotoCellComponent } from './_shared/photo-cell/photo-cell.component';
import { SchedulerCellComponent } from './_shared/scheduler-cell/scheduler-cell.component';
import { TagsCellComponent } from './_shared/tags-cell/tags-cell.component';
import { TranslationValueCellComponent } from './_shared/translation-value-cell/translation-value-cell.component';

// SC Widgets
import { ACOffModule } from './ac-off/ac-off.module';
import { ACValveAlertModule } from './ac-valve-alert/ac-valve-alert.module';
import { AGAlertModule } from './ag-alert/ag-alert.module';
import { AbnormalOccupancyAlertModule } from './abnormal-occupancy-alert/abnormal-occupancy-alert.module';
import { AirQualityModule } from './air-quality/air-quality.module';
import { AirQualityLiveModule } from './air-quality-live/air-quality-live.module';
import { AppStyleTemplatesModule } from './app-style-templates/app-style-templates.module';
import { AppStylesModule } from './app-styles/app-styles.module';
import { AppsModule } from './apps/apps.module';
import { AutomationLogsModule } from './automation-logs/automation-logs.module';
import { AutomationsModule } from './automations/automations.module';
import { AverageHumidityModule } from './average-humidity/average-humidity.module';
import { AverageTemperatureModule } from './average-temperature/average-temperature.module';
import { BatteryAlertModule } from './battery-alert/battery-alert.module';
import { BenchmarksModule } from './benchmarks/benchmarks.module';
import { BulkModificationModule } from './bulk-modification/bulk-modification.module';
import { CleaningStatusModule } from './cleaning-status/cleaning-status.module';
import { CompaniesModule } from './companies/companies.module';
import { CurrenciesModule } from './currencies/currencies.module';
import { CustomAppsModule } from './custom-apps/custom-apps.module';
import { CustomFunctionsModule } from './custom-functions/custom-functions.module';
import { DeviceBrandsModule } from './device-brands/device-brands.module';
import { DeviceHistoryLogsModule } from './device-history-logs/device-history-logs.module';
import { DeviceModelsModule } from './device-models/device-models.module';
import { DeviceModels2Module } from './device-models-2/device-models.module';
import { DeviceProtocolsModule } from './device-protocols/device-protocols.module';
import { DeviceTaskTemplatesModule } from './device-task-templates/device-task-templates.module';
import { DeviceTasksModule } from './device-tasks/device-tasks.module';
import { DeviceTypesModule } from './device-types/device-types.module';
import { DeviceWizardModule } from './device-wizard/device-wizard.module';
import { DevicesModule } from './devices/devices.module';
import { DoorActivityModule } from './door-activity/door-activity.module';
import { EkhoModule } from './ekho/ekho.module';
import { ElectricityCostsModule } from './electricity-costs/electricity-costs.module';
import { EndUsersModule } from './end-users/end-users.module';
import { EnergySavingModule } from './energy-saving/energy-saving.module';
import { EventsModule } from './events/events.module';
import { FilesModule } from './files/files.module';
import { FloorPlanModule } from './floorplan/floorplan.module';
import { GuestRequestCategoriesModule } from './guest-request-categories/guest-request-categories.module';
import { GuestRequestPresetsModule } from './guest-request-presets/guest-request-presets.module';
import { GuestRequestsModule } from './guest-requests/guest-requests.module';
import { GuestSessionsModule } from './guest-sessions/guest-session.module';
import { HousekeepingModule } from './housekeeping/housekeeping.module';
import { LiveRequestsModule } from './live-requests/live-requests.module';
import { LocalWeatherModule } from './local-weather/local-weather.module';
import { LocalesModule } from './locales/locales.module';
import { LocationAlertModalModule } from './locations-alert-modal/locations-alert-modal.module';
import { LocationGeneratorModule } from './location-generator/location-generator.module';
import { LocationProfilesModule } from './location-profiles/location-profiles.module';
import { LocationPropertiesModule } from './location-properties/location-properties.module';
import { LocationStatisticsModule } from './location-statistics/location-statistics.module';
import { LocationSummaryModule } from './location-summary/location-summary.module';
import { LocationTemplatesModule } from './location-templates/location-templates.module';
import { LocationsConsomptionAlertModule } from './locations-consumption-alert/locations-consumption-alert.module';
import { LocationsHumidityAlertModule } from './locations-humidity-alert/locations-humidity-alert.module';
import { LocationsModule } from './locations/locations.module';
import { LocationsOccupancyHistoryModule } from './locations-occupancy-history/locations-occupancy-history.module';
import { LocationsOccupancyLiveModule } from './locations-occupancy-live/locations-occupancy-live.module';
import { LocationsOccupancyOverviewModule } from './locations-occupancy-overview/locations-occupancy-overview.module';
import { LocationsTemperatureAlertModule } from './locations-temperature-alert/locations-temperature-alert.module';
import { LocationsWindowOpenModule } from './locations-window-open/locations-window-open.module';
import { MainDoorOpenModule } from './main-door-open/main-door-open.module';
import { MakeUpMyRoomAlertModule } from './make-up-my-room-alert/make-up-my-room-alert.module';
import { MostSoldRoomsModule } from './most-sold-rooms/most-sold-rooms.module';
import { NotificationTemplatesModule } from './notification-templates/notification-templates.module';
import { NotificationsModule } from './notifications/notifications.module';
import { PageTemplatesModule } from './page-templates/page-templates.module';
import { PropertyManagementSystemsModule } from './property-management-systems/property-management-systems.module';
import { ReportTemplatesModule } from './report-templates/report-templates.module';
import { RoomCorrectionModule } from './room-correction/room-correction.module';
import { RoomUnoccupancyAvgModule } from './room-unoccupancy-avg/room-unoccupancy-avg.module';
import { RoomUnoccupancyHistoryModule } from './room-unoccupancy-history/room-unoccupancy-history.module';
import { RuleCategoriesModule } from './rule-categories/rule-categories.module';
import { RuleMassEditModule } from './rule-mass-edit/rule-mass-edit.module';
import { RuleMonitorModule } from './rule-monitor/rule-monitor.module';
import { RuleSchedulerJobsModule } from './rule-scheduler-jobs/rule-scheduler-jobs.module';
import { RuleTemplatesModule } from './rule-templates/rule-templates.module';
import { RulesModule } from './rules/rules.module';
import { SceneTemplatesModule } from './scene-templates/scene-templates.module';
import { ScenesModule } from './scenes/scenes.module';
import { SettingFormModule } from './setting-form/setting-form.module';
import { SettingWidgetsModule } from './setting-widgets/setting-widgets.module';
import { SiteTypesModule } from './site-types/site-types.module';
import { SitesModule } from './sites/sites.module';
import { UserTaskTemplatesModule } from './user-task-templates/user-task-templates.module';
import { TechAlertsModule } from './tech-alerts/tech-alerts.module';
import { TopFiveColdestRoomsModule } from './top-five-coldest-rooms/top-five-coldest-rooms.module';
import { TopFiveHottestRoomsModule } from './top-five-hottest-rooms/top-five-hottest-rooms.module';
import { TranslationsModule } from './translations/translations.module';
import { TrashModule } from './trash/trash.module';
import { UsersModule } from './users/users.module';
import { WidgetCategoriesModule } from './widget-categories/widget-categories.module';
import { IotCounterModule } from './iot-counter/iot-counter.module';
import { TriggeredRulesModule } from './triggered-rules/triggered-rules.module';
import { BulkCreatorModule } from './bulk-creator/bulk-creator.module';
import { CeosAlertModule } from './ceos-alert/ceos-alert.module';
import { BatteryStatusModule } from './battery-status/battery-status.module';
import { EcowattModule } from './ecowatt/ecowatt.module';
import { ConsumptionModule } from './consumption/consumption.module';

const COMPONENTS: any[] = [
  // ____ EMPTY WIDGET (WILL APPEAR WHEN NO WIDGET MATCHED)
  EmptyWidgetComponent,
  // ____ AG_GRID
  CheckboxCellComponent,
  FlagCellComponent,
  IconCellComponent,
  LocaleTextCellComponent,
  MultiLanguageCellComponent,
  PhotoCellComponent,
  SchedulerCellComponent,
  TagsCellComponent,
  TranslationValueCellComponent,
];

const MODULES: any[] = [
  SharedModule,
  ACOffModule,
  ACValveAlertModule,
  AGAlertModule,
  AbnormalOccupancyAlertModule,
  AirQualityModule,
  AirQualityLiveModule,
  AppStyleTemplatesModule,
  AppStylesModule,
  AppsModule,
  AutomationLogsModule,
  AutomationsModule,
  AverageHumidityModule,
  AverageTemperatureModule,
  BatteryAlertModule,
  BenchmarksModule,
  BulkModificationModule,
  CleaningStatusModule,
  CompaniesModule,
  CurrenciesModule,
  CustomAppsModule,
  CustomFunctionsModule,
  DeviceBrandsModule,
  DeviceHistoryLogsModule,
  DeviceModelsModule,
  DeviceModels2Module,
  DeviceProtocolsModule,
  DeviceTaskTemplatesModule,
  DeviceTasksModule,
  DeviceTypesModule,
  DeviceWizardModule,
  DevicesModule,
  DoorActivityModule,
  EkhoModule,
  ElectricityCostsModule,
  EndUsersModule,
  EnergySavingModule,
  EventsModule,
  FilesModule,
  FloorPlanModule,
  GuestRequestCategoriesModule,
  GuestRequestPresetsModule,
  GuestRequestsModule,
  GuestSessionsModule,
  HousekeepingModule,
  LiveRequestsModule,
  LocalWeatherModule,
  LocalesModule,
  LocationAlertModalModule,
  LocationGeneratorModule,
  LocationProfilesModule,
  LocationPropertiesModule,
  LocationStatisticsModule,
  LocationSummaryModule,
  LocationTemplatesModule,
  LocationsConsomptionAlertModule,
  LocationsHumidityAlertModule,
  LocationsModule,
  LocationsOccupancyHistoryModule,
  LocationsOccupancyLiveModule,
  LocationsOccupancyOverviewModule,
  LocationsTemperatureAlertModule,
  LocationsWindowOpenModule,
  MainDoorOpenModule,
  MakeUpMyRoomAlertModule,
  MostSoldRoomsModule,
  NotificationTemplatesModule,
  NotificationsModule,
  PageTemplatesModule,
  PropertyManagementSystemsModule,
  ReportTemplatesModule,
  RoomCorrectionModule,
  RoomUnoccupancyAvgModule,
  RoomUnoccupancyHistoryModule,
  RuleCategoriesModule,
  RuleMassEditModule,
  RuleMonitorModule,
  RuleSchedulerJobsModule,
  RuleTemplatesModule,
  RulesModule,
  SceneTemplatesModule,
  ScenesModule,
  SettingFormModule,
  SettingWidgetsModule,
  SiteTypesModule,
  SitesModule,
  UserTaskTemplatesModule,
  TechAlertsModule,
  TopFiveColdestRoomsModule,
  TopFiveHottestRoomsModule,
  TranslationsModule,
  TrashModule,
  TriggeredRulesModule,
  UsersModule,
  WidgetCategoriesModule,
  IotCounterModule,
  BulkCreatorModule,
  CeosAlertModule,
  BatteryStatusModule,
  EcowattModule,
  ConsumptionModule,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [SCWidgetService],
})
export class SCWidgetsModule {}
