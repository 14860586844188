import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CleaningStatusComponent } from './cleaning-status.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [CleaningStatusComponent],
  entryComponents: [CleaningStatusComponent],
  providers: []
})
export class CleaningStatusModule {}
