<!-- <sc-modal-trigger
  [modalConfig]="deviceForm"
  [buttonIcon]="'fas fa-eye'"
  buttonClass="ui-button-secondary"
  (onClose)="onUpdateSuccess($event)"
  pTooltip="{{ 'DETAILS' | translate }}"
  tooltipPosition="top"
  appendTo="body"
></sc-modal-trigger> -->

<button
  type="button"
  pButton
  class="ui-button-secondary"
  [icon]="'fas fa-eye'"
  (click)="openDeviceDetails()"
  pTooltip="{{ 'DETAILS' | translate }}"
  tooltipPosition="top"
  appendTo="body"
></button>
