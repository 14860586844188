<div class="p-fluid p-grid">
  <div
    class="p-col-12 p-md-6"
    *ngFor="let item of items"
    (click)="selectItem.emit(item.value)"
  >
    <div class="list-select-item">
      {{ item.label }}
    </div>
  </div>
</div>
