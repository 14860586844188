import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss'],
})
export class InputCodeComponent implements OnInit, OnChanges {
  @Input()
  control: FormControl;

  @Input()
  form: FormGroup;

  @Input()
  formField: string;

  @Input()
  label: string;

  @Input()
  options: { [key: string]: any };

  inputControl: FormControl;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.control && changes.control.currentValue) ||
      (changes.form && changes.form.currentValue) ||
      (changes.formField && changes.formField.currentValue)
    ) {
      this.updateInputControl();
    }
  }

  private updateInputControl() {
    if (this.control) {
      this.inputControl = this.control;
    } else if (this.form && this.formField) {
      this.inputControl = this.form.get(this.formField) as FormControl;
    } else {
      this.inputControl = null;
    }
  }

  get hasError() {
    return this.inputControl && this.inputControl.invalid && (this.inputControl.touched || this.inputControl.dirty);
  }
}
