<ng-container
  *ngIf="isFetchingDataset; then spinner; else content"
></ng-container>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <sc-table
    [columns]="columns"
    [config]="config"
    [dataset]="dataset"
    [title]="tableTitle"
    [toolbarItems]="toolbarItems"
    (reloadData)="fetchDetails()"
  ></sc-table>
</ng-template>
