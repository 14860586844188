import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { EnergySavedComponent } from './energy-saved.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [EnergySavedComponent],
  entryComponents: [EnergySavedComponent],
  providers: []
})
export class EnergySavedModule {}
