import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../config';

@Injectable()
export class NotificationsService {
  private apiUrl = config().apiUrl + '/user';
  private publicApiUrl = config().apiUrl + '/public';

  constructor(private httpClient: HttpClient) {}

  get(isNew: boolean = true) {
    return this.httpClient.get(`${this.publicApiUrl}/notifications?unreadOnly=${isNew}`);
  }

  getUnsolved() {
    return this.httpClient.get(`${config().apiUrl}/notifications?unsolved`);
  }

  read(userId: number, notificationId: string) {
    return this.httpClient.get(`${this.apiUrl}/${userId}/notifications/${notificationId}/read`);
  }

  setAsread(notificationId: string) {
    return this.httpClient.patch(`${this.publicApiUrl}/notifications/${notificationId}`, { read: true });
  }

  solved(userId: number, notificationId: string) {
    return this.httpClient.get(`${this.apiUrl}/${userId}/notifications/${notificationId}/solved`);
  }

  unsolved(userId: number, notificationId: string, reason: string) {
    return this.httpClient.get(`${this.apiUrl}/${userId}/notifications/${notificationId}/unsolved?reason=${reason}`);
  }
}
