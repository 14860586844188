import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromInitialData from '../reducers/initial.reducer';

export const getInitialDataState = createFeatureSelector<fromInitialData.InitialDataState>('initialData');

export const getInitialDataLoaded = createSelector(getInitialDataState, fromInitialData.getInitialDataLoaded);

export const getInitialDataLoading = createSelector(getInitialDataState, fromInitialData.getInitialDataLoading);

export const getInitialData = createSelector(getInitialDataState, fromInitialData.getInitialData);

export const getInitialDataCompanies = createSelector(getInitialData, (data) => (data && data.companies) || []);

export const getInitialDataSites = createSelector(getInitialData, (data) => (data && data.sites) || []);

export const getDeviceBrands = createSelector(getInitialData, (data) => (data && data.deviceBrands) || []);

export const getDeviceProtocols = createSelector(getInitialData, (data) => (data && data.deviceProtocols) || []);

export const getDeviceTypes = createSelector(getInitialData, (data) => (data && data.deviceTypes) || []);

export const getLocationTypes = createSelector(getInitialData, (data) => (data && data.locationTypes) || []);

export const getLocationTemplates = createSelector(getInitialData, (data) => (data && data.locationTemplates) || []);

export const getLocationProfiles = createSelector(getInitialData, (data) => (data && data.locationProfiles) || []);

export const getRuleTemplates = createSelector(getInitialData, (data) => (data && data.ruleTemplates) || []);

export const getPropertyManagementSystems = createSelector(
  getInitialData,
  (data) => (data && data.propertyManagementSystems) || []
);

export const getAppStyle = createSelector(getInitialData, (data) => (data.appStyle && data.appStyle.style) || {});
