import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserPermission } from '../models/user-permission';

import { UserPermissionsService } from '../services/user-permissions.service';
import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Component({
  selector: 'sc-user-permission-form',
  templateUrl: 'user-permission-form.component.html'
})
export class UserPermissionFormComponent implements OnInit {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: UserPermission;
  form: FormGroup;
  errorMessage: string;
  editMode: boolean;
  patterns = config().regexPatterns;
  isSubmitting: boolean;
  subscribers: { [key: string]: any };
  fetchingState: number;

  constructor(
    private formBuilder: FormBuilder,
    private userPermissionsService: UserPermissionsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchingState = 0;
    this.subscribers = {};

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchPermission(this.data.id);
    }

    this.initForm();
  }

  fetchPermission(id: number) {
    this.fetchingState++;
    this.subscribers.GET_PERMISSION = this.userPermissionsService
      .get(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      // permissionId: [
      //   null,
      //   Validators.compose([
      //     Validators.required,
      //     Validators.pattern(this.patterns.id)
      //   ])
      // ],
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      description: null,
      isActive: false,
      isDeleted: false
    });

    // if (this.editMode) {
    //   this.form.get('permissionId').disable();
    // }
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      // call api
      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create() {
    const formData = this.form.value;
    this.userPermissionsService
      .create(formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update() {
    const formData = this.form.value;
    this.userPermissionsService
      .update(this.formData.id, formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.userPermissionsService
      .delete(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify(
      'User Permission',
      result.message + ' Success!',
      'success'
    );
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
