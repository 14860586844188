<div class="task-queue container">
  <div class="task-queue-body" *ngIf="commandForm" [formGroup]="commandForm">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-3" *ngIf="isTemplate">
        <label class="input-label" for="name">
          {{ "NAME" | translate }}
        </label>

        <input type="text" pInputText inputId="name" formControlName="name" />
      </div>

      <div class="p-field p-col-12 p-md-3">
        <label class="input-label" for="target">
          {{ "CCP_RULE_TASKS_OF_RULE" | translate }}
        </label>

        <!-- placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
          'CCP_COMMON_TASKS' | translate
        }}" -->
        <p-multiSelect
          inputId="target"
          [options]="selectItems?.rules"
          formControlName="target"
          [filter]="true"
          appendTo="body"
        ></p-multiSelect>

        <div *ngIf="isTemplate">
          <p-checkbox
            inputId="is_target_editable"
            [binary]="true"
            [formControl]="commandForm?.get('is_target_editable')"
            [label]="'EDITABLE' | translate"
          ></p-checkbox>
        </div>
      </div>

      <div class="p-field p-col-12 p-md-3">
        <label class="input-label" for="action">
          {{ "CCP_COMMON_ACTION" | translate }}
        </label>

        <p-dropdown
          inputId="action"
          [options]="actions"
          formControlName="action"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_COMMON_ACTION' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>
    </div>
  </div>

  <sc-command-options
    *ngIf="commandForm"
    [form]="commandForm"
    [isTemplate]="isTemplate"
  ></sc-command-options>
</div>
