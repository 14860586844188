<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-body user-password-form__body">
    <!-- CURRENT_PASSWORD -->
    <div class="mb-3">
      <sc-password-input
        controlName="current"
        [form]="form"
        label="{{ 'CURRENT_PASSWORD' | translate }}"
      ></sc-password-input>
    </div>

    <div class="mb-3">
      <!-- NEW_PASSWORD -->
      <sc-password-input
        controlName="new"
        [form]="form"
        label="{{ 'NEW_PASSWORD' | translate }}"
      ></sc-password-input>
    </div>

    <div class="mb-3">
      <!-- REPEAT_PASSWORD -->
      <sc-password-input
        controlName="repeat"
        [form]="form"
        label="{{ 'REPEAT_PASSWORD' | translate }}"
      ></sc-password-input>
    </div>

    <!-- Error Message -->
    <div *ngIf="errorMessage">
      <sc-form-error [message]="errorMessage | translate"></sc-form-error>
    </div>
  </div>

  <!--Footer-->
  <div class="modal-footer">
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="dismissModal('cancel')"
      label="{{ 'CANCEL' | translate }}"
    ></button>

    <sc-submit-button
      buttonClass="ui-button-success"
      [disabled]="!form.valid || form.pristine"
      [pending]="isSubmitting"
      (submitted)="submit()"
    ></sc-submit-button>
  </div>
</form>
