import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { BenchmarkTemplateFormsService } from '../../../services/benchmark-template-forms.service';

@Component({
  selector: 'sc-output-form',
  templateUrl: 'output-form.component.html'
})
export class OutputFormComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  optionData: any;

  constructor(
    private benchmarkTemplateFormsService: BenchmarkTemplateFormsService
  ) {}

  ngOnInit() {}

  get outputForm(): FormArray {
    return this.form.controls['output'] as FormArray;
  }

  add() {
    const control = <FormArray>this.form.controls['output'];
    control.push(this.benchmarkTemplateFormsService.initOutputForm());
  }

  remove(i) {
    const control = <FormArray>this.form.controls['output'];
    control.removeAt(i);
  }

  displayOptionField(control) {
    const key = control.value && control.value.key;
    switch (key) {
      case 'duration':
      case 'disablingReason':
        return false;
      default:
        return true;
    }
  }
}
