import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'basic-form-group',
  templateUrl: 'basic-form-group.component.html'
})
export class BasicFormGroupComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;

  basicData: any;

  constructor() {}

  ngOnInit() {
    this.basicData = this.group.value;
  }

  dynamicInput() {
    if (this.basicData && this.basicData.type) {
      switch (this.basicData.type) {
        case 'hex':
        case 'rgba':
          return 'colorInput';

        case 'pct':
        case 'px':
          return 'numberInput';

        case 'file':
          return 'fileInput';

        default:
          return 'textInput';
      }
    }

    return 'textInput';
  }
}
