import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as fromStore from '@app/store';
import { GridsterConfig, GridsterItemComponentInterface } from 'angular-gridster2';
import { PageTemplate } from '../models/page-template';
import { Page } from '../models/page';
import { Section } from '../models/page-section';
import { Widget } from '../models/page-section-widget';
import { WidgetCategory } from '@widgets/widget-categories/models/widget-category';
import { Site } from '@widgets/sites/models/site';
import { AuthService } from '@app/auth/services/auth.service';
import { PageService } from './page.service';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';
import { SiteActionCellComponent } from '@app/integrator/components';

@Component({
  selector: 'sc-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit, OnDestroy, AfterViewInit {
  gridConfig: GridsterConfig;
  currentPage: Page;
  editMode: boolean;
  gridResizeTimeout: any;
  isLastWidgetInitSuccess: boolean;
  pageTemplates: PageTemplate[];
  showWidgetForm: boolean;
  userId: number;
  userVariant: string;
  visualisedPage: Page;
  visualisedSection: Section;
  visualisedMenu: string;
  visualisedWidgets: Widget[];
  viewInitTimeout: any;
  widgetInitTimeout: any;
  widgets: Widget[];
  widgetCategories: WidgetCategory[];
  editDashboardPermissions = 'dashboards_u,integrator_site_dashboard_u,enduser_site_dashboard_u';

  private componentDestroyed: Subject<any> = new Subject();
  private menuItems = config().menuItems;
  private selectedSite: Site;

  constructor(
    private store: Store<fromStore.State>,
    private authService: AuthService,
    private pageService: PageService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.gridConfig = config().gridConfig as GridsterConfig;
    this.gridConfig.itemInitCallback = this.gridItemInit.bind(this);
    this.gridConfig.itemChangeCallback = this.gridItemChange.bind(this);

    this.subscribeStore();
  }

  ngOnDestroy() {
    // clear widget init timeout
    if (this.widgetInitTimeout) {
      clearTimeout(this.widgetInitTimeout);
      this.widgetInitTimeout = null;
    }

    if (this.viewInitTimeout) {
      clearTimeout(this.viewInitTimeout);
      this.viewInitTimeout = null;
    }

    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  ngAfterViewInit() {
    this.viewInitTimeout = setTimeout(() => {
      if (this.gridConfig && this.gridConfig.api) {
        this.gridConfig.api.resize();
      }
    }, 1500);
  }

  private subscribeStore() {
    // get user id
    this.store.pipe(select(fromStore.getCurrentUserId), takeUntil(this.componentDestroyed)).subscribe((result) => {
      this.userId = result;
    });

    // get user variant
    this.store.pipe(select(fromStore.getUserVariant), takeUntil(this.componentDestroyed)).subscribe((result) => {
      this.userVariant = result || 'scv4'; // fallback to scv4 if not exists
    });

    // get visualised page
    this.store.pipe(select(fromStore.getSelectedPage), takeUntil(this.componentDestroyed)).subscribe((result) => {
      if (result) {
        this.visualisedPage = { ...result };
      }
    });

    // get visualised section
    this.store.pipe(select(fromStore.getSelectedSection), takeUntil(this.componentDestroyed)).subscribe((result) => {
      if (result) {
        this.visualisedSection = { ...result };
      }
    });

    // get visualised section
    this.store.pipe(select(fromStore.getSelectedMenu), takeUntil(this.componentDestroyed)).subscribe((result) => {
      if (result) {
        this.visualisedMenu = result;
      }
    });

    // get visualised widgets
    this.store.pipe(select(fromStore.getSelectedWidgets), takeUntil(this.componentDestroyed)).subscribe((result) => {
      if (result && result.length > 0) {
        this.visualisedWidgets = [...result];
      } else {
        this.visualisedWidgets = [];
      }
    });

    // get edit mode
    this.store.pipe(select(fromStore.getEditMode), takeUntil(this.componentDestroyed)).subscribe((result) => {
      this.editMode = result;
    });

    // get show widget form flag
    this.store.pipe(select(fromStore.getWidgetShowForm), takeUntil(this.componentDestroyed)).subscribe((result) => {
      this.showWidgetForm = result;
    });

    // automatically resize content when menu show/hide
    this.store.pipe(select(fromStore.getShowMenu), takeUntil(this.componentDestroyed)).subscribe((result) => {
      if (this.gridConfig && this.gridConfig.api) {
        this.gridConfig.api.resize();
      }
    });

    // get page_templates for form
    this.store.pipe(select(fromStore.getAllPageTemplates), takeUntil(this.componentDestroyed)).subscribe((result) => {
      this.pageTemplates = result;
    });

    // get widgets for form
    this.store.pipe(select(fromStore.getAllWidgets), takeUntil(this.componentDestroyed)).subscribe((result) => {
      this.widgets = result.filter((r) => r.isActive && !r.isDeleted && r.type === 'dashboard');
    });

    // get widget categories for form
    this.store
      .pipe(select(fromStore.getAllWidgetCategories), takeUntil(this.componentDestroyed))
      .subscribe((result) => {
        this.widgetCategories = result.filter((r) => r.isActive && !r.isDeleted);
      });

    // action on site is changed
    this.store.pipe(select(fromStore.getSelectedSite), takeUntil(this.componentDestroyed)).subscribe((result) => {
      if (!result || !this.userId) {
        return;
      }

      if (!this.selectedSite) {
        this.selectedSite = result;
      } else if (this.selectedSite && this.selectedSite.id !== result.id) {
        this.selectedSite = result;

        // CLEAR SELECTED LOCATIONS AND DEVICES OF PREVIOUS SITE
        this.store.dispatch(new fromStore.ResetDevices());
        this.store.dispatch(new fromStore.ResetLocations());
        // GO BACK TO LOCATIONS OR DEVICES PAGE OF NEW SITE
        if (this.currentPage) {
          let path;
          switch (this.currentPage.key) {
            case 'dashboards':
              this.store.dispatch(new fromStore.DisableEditMode());
              break;
            case 'floorplan':
            case 'location_details':
              path = ['locations'];
              break;
            case 'device_details':
              path = ['devices'];
              break;
          }
          if (path) {
            this.store.dispatch(new fromStore.Go({ path }));
          }
        }
      }
    });

    // get current page
    this.store
      .pipe(
        select(
          fromStore.getCurrentPage,
          this.visualisedMenu === 'integrator_sites' ? { siteId: this.selectedSite.id } : {}
        ),
        debounceTime(500),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((result) => {
        if (result) {
          // PERMISSION CHECK IF NOT ALLOW FORCE GO TO DASHBOARDS
          if (this.authService.allow(`${result.key}_r`)) {
            this.currentPage = JSON.parse(JSON.stringify(result));

            // if page is in this list
            const pageKeys = ['device_details', 'devices', 'location_details', 'setting_rules'];
            // filter which sections have to display by section id prefix (e.g. scv4_ or ceos_)
            if (pageKeys.indexOf(this.currentPage.key) >= 0) {
              const idRegexp = new RegExp(`^${this.userVariant}_`, 'i');
              const sections = [];
              for (const s of this.currentPage.sections) {
                if (idRegexp.test(s.sectionId)) {
                  sections.push(s);
                }
              }
              this.currentPage.sections = sections;
            }

            this.initPage();
          } else {
            this.store.dispatch(new fromStore.Go({ path: ['my_account'] }));
          }
        }
      });
  }

  private initPage() {
    this.isLastWidgetInitSuccess = false;

    // reset scroll position
    window.scrollTo(0, 0);
    // stop edit mode and hide widgets form sidebar
    this.store.dispatch(new fromStore.DisableEditMode());
    this.store.dispatch(new fromStore.HideWidgetForm());

    if (!this.currentPage) {
      return;
    }

    const menu = this.menuItems.find((m) => m.id === this.currentPage.key);
    let pageTitle;
    if (menu) {
      pageTitle = this.sharedService.getTranslation(menu.label);
    } else {
      pageTitle = this.currentPage.key.replace('_', ' ');
      pageTitle = this.sharedService.toTitleCase(pageTitle);
    }
    this.pageService.updateTitle(pageTitle);

    if (this.currentPage.key === 'integrator_overview') delete this.currentPage.siteId;
    this.store.dispatch(new fromStore.VisualisePage(this.currentPage));
    this.store.dispatch(new fromStore.VisualiseSection(this.currentPage.sections[0]));
    const widgets = (this.currentPage.sections[0] && this.currentPage.sections[0].widgets) || [];
    if (widgets) {
      this.store.dispatch(new fromStore.VisualiseWidgets(this.currentPage.sections[0].widgets));
      if (widgets.length === 0) {
        this.isLastWidgetInitSuccess = true;
      }
    }
  }

  private gridItemInit(item: Widget, itemComponent: GridsterItemComponentInterface) {
    // wait 1.5 second to make sure that the last widget was init, before editable
    clearTimeout(this.widgetInitTimeout);
    this.widgetInitTimeout = setTimeout(() => {
      this.isLastWidgetInitSuccess = true;
    }, 1500);
  }

  private gridItemChange(item: Widget, itemComponent: GridsterItemComponentInterface) {
    if (this.editMode && this.isLastWidgetInitSuccess) {
      this.onUpdateWidget(item);
    }
  }

  onSwitchMode() {
    this.store.dispatch(new fromStore.EnableEditMode());
  }

  showSectionsMenu() {
    if (this.currentPage) {
      if (/^dashboards_\D+/g.test(this.currentPage.key)) {
        return true;
      } else if (!this.editMode && this.currentPage.sections.length > 1) {
        return true;
      } else if (this.editMode && this.currentPage.allowCustomSections) {
        return true;
      }
    }
    return false;
  }

  onAddSection(event: Section) {
    if (event.widgets && event.widgets.length) {
      event.widgets = event.widgets.map((widget) => {
        const tmp = { ...widget };
        tmp.widgetId = widget.id;
        tmp.userId = this.userId;
        delete tmp.id;
        return tmp;
      });
    }

    this.store.dispatch(new fromStore.AddPageSection(event));
    this.store.dispatch(new fromStore.VisualiseSection(event));
    this.store.dispatch(new fromStore.VisualiseWidgets(event.widgets));
  }

  onDeleteSection(event: Section) {
    this.store.dispatch(new fromStore.RemovePageSection(event));
  }

  onMoveSection(event: Section[]) {
    const sections = event.map((section, index) => ({
      ...section,
      position: index,
    }));
    this.store.dispatch(new fromStore.MovePageSection(sections));
  }

  onResetSection(event: Section) {
    if (this.visualisedPage && this.userId) {
      const template = this.pageTemplates.find((pt) => pt.id === this.visualisedPage.pageTemplateId);

      const sectionToReset = { ...event };
      for (let i = 0; i < template.sections.length; i++) {
        const section = { ...template.sections[i] };

        if (section.sectionId === event.sectionId) {
          const widgetIds = section.widgets.map((w) => w.widgetId);
          sectionToReset.widgets = this.widgets
            .filter((widget) => widgetIds.indexOf(widget.id) >= 0)
            .map((widget) => {
              const tmp = { ...widget };
              tmp.widgetId = widget.id;
              tmp.userId = this.userId;
              delete tmp.id;
              return tmp;
            });
          break;
        }
      }

      this.store.dispatch(new fromStore.ResetPageSection(sectionToReset));
      this.store.dispatch(new fromStore.VisualiseSection(sectionToReset));
      this.store.dispatch(new fromStore.VisualiseWidgets(sectionToReset.widgets));
    }
  }

  onSwitchSection(event: Section) {
    this.store.dispatch(new fromStore.VisualiseSection(event));
    this.store.dispatch(new fromStore.VisualiseWidgets(event.widgets));
  }

  // widgets
  onEditAddWidget() {
    this.store.dispatch(new fromStore.ShowWidgetForm());
  }

  onAddWidgetCancelled() {
    this.store.dispatch(new fromStore.HideWidgetForm());
  }

  onAddWidget(events: Widget[]) {
    const widgets = JSON.parse(JSON.stringify(events));
    this.store.dispatch(new fromStore.VisualiseWidgets(widgets));
    this.store.dispatch(new fromStore.UpdatePageSectionWidgets(widgets));
  }

  onUpdateWidget(event: Widget) {
    const totalWidgets = this.visualisedWidgets && this.visualisedWidgets.length;
    if (totalWidgets > 0) {
      for (let i = 0; i < totalWidgets; i++) {
        if (this.visualisedWidgets[i].uniqueId === event.uniqueId) {
          this.visualisedWidgets[i] = {
            ...this.visualisedWidgets[i],
            ...event,
          };
          break;
        }
      }
    }
  }

  onRemoveWidget(event: Widget) {
    const widgets = this.visualisedWidgets.filter((w) => w.uniqueId !== event.uniqueId);
    this.store.dispatch(new fromStore.UpdatePageSectionWidgets(widgets));
    this.store.dispatch(new fromStore.VisualiseWidgets(widgets));
  }

  onEditCancel() {
    this.initPage();
    this.isLastWidgetInitSuccess = true;
  }

  onEditReset() {
    if (this.visualisedPage) {
      const template = this.pageTemplates.find((pt) => pt.id === this.visualisedPage.pageTemplateId);
      const sections = [];

      if (template) {
        // update widgets of template
        template.sections.forEach((section) => {
          const widgets = [];
          section.widgets.forEach((item) => {
            for (let i = 0; i < this.widgets.length; i++) {
              if (item.widgetId === this.widgets[i].id) {
                const w = { ...this.widgets[i] };
                w.widgetId = w.id;
                w.userId = this.userId;
                delete w.id;
                widgets.push(w);
                break;
              }
            }
          });
          sections.push({ ...section, widgets });
        });

        const id = this.visualisedPage.id;
        const pageTemplateId = template.id;
        const tmp = { ...template };
        delete tmp.id;
        delete tmp.allowInheritance;
        const page = {
          ...tmp,
          pageTemplateId,
          id,
          sections,
        };

        this.store.dispatch(new fromStore.UpdatePage(page));
        this.store.dispatch(new fromStore.VisualisePage(page));
        this.store.dispatch(new fromStore.VisualiseSection(sections[0]));
        this.store.dispatch(new fromStore.VisualiseWidgets(sections[0].widgets));
      }
    }
    this.store.dispatch(new fromStore.DisableEditMode());
  }

  onEditSave() {
    if (this.visualisedPage) {
      const page = { ...this.visualisedPage };
      const section = { ...this.visualisedSection };
      let widgets = [...this.visualisedWidgets];
      section.widgets = widgets;
      page.sections = page.sections.map((s) => {
        if (s.sectionId === section.sectionId) {
          return section;
        }
        return s;
      });
      this.store.dispatch(new fromStore.UpdatePage(page));
      this.store.dispatch(new fromStore.VisualisePage(page));
      this.store.dispatch(new fromStore.VisualiseSection(section));
      this.store.dispatch(new fromStore.VisualiseWidgets(section.widgets));
    }
    this.store.dispatch(new fromStore.DisableEditMode());
  }
}
