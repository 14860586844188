import { Action, createReducer, on } from '@ngrx/store';
import * as RuleBulkAction from '../actions/rule-bulk.action';

export interface State {
  automationIdxs: string[];
  ruleTemplateIdx: string;
}

export const initialState: State = {
  automationIdxs: null,
  ruleTemplateIdx: null,
};

const ruleBulkReducer = createReducer(
  initialState,
  on(RuleBulkAction.startRuleBulk, (state, { automationIdxs, ruleTemplateIdx }) => {
    return { automationIdxs, ruleTemplateIdx };
  }),
  on(RuleBulkAction.clearRuleBulks, (state) => {
    return { automationIdxs: null, ruleTemplateIdx: null };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return ruleBulkReducer(state, action);
}
