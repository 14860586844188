import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { Store } from '@ngrx/store';
import * as fromRoot from '@app/store';

import { Rule } from '../models/rule';
import { RuleTemplate } from '@widgets/rule-templates/models/rule-template';
import { SelectItem } from 'primeng/api';
import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';

import { DevicesService } from '@widgets/devices/services/devices.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { LocationPropertiesService } from '@widgets/location-properties/services/location-properties.service';
import { NotificationTemplatesService } from '@widgets/notification-templates/services/notification-templates.service';
import { RuleTemplatesService } from '@widgets/rule-templates/services/rule-templates.service';
import { DeviceTypesService } from '@app/widgets/device-types/services/device-types.service';
import { RuleFormsService } from '../services/rule-forms.service';
import { RulesService } from '../services/rules.service';
import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Component({
  selector: 'sc-rule-form',
  templateUrl: './rule-form.component.html',
})
export class RuleFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: Rule;
  form: FormGroup;
  errorMessage: string;
  editMode = false;
  fetchingState = 0;
  isSubmitting = false;
  currentCommandAndConditionForm = 'start';
  selectItems: { [key: string]: any };

  ruleTemplates: SelectItem[];
  ruleTemplatesSource: RuleTemplate[];
  private selectedLocation: Location;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private ruleTemplatesService: RuleTemplatesService,
    private locationsService: LocationsService,
    private locationPropertiesService: LocationPropertiesService,
    private devicesService: DevicesService,
    private deviceTypesService: DeviceTypesService,
    private notificationTemplatesService: NotificationTemplatesService,
    private ruleFormsService: RuleFormsService,
    private rulesService: RulesService,
    private sharedService: SharedService,
    private store: Store<fromRoot.State>
  ) {}

  ngOnInit() {
    this.selectItems = config().selectItems;
    this.selectedSite = this.sharedService.selectedSite;

    // get location id
    this.subscribers.routerState = this.store.select(fromRoot.getRouterStateUrl).subscribe((routerState) => {
      const params = routerState.params;
      const queryParams = routerState.queryParams;
      if (params && params.pageId) {
        switch (params.pageId) {
          case 'locations':
            // routerState.params: {pageId: "locations", pageParams: "322"}
            // routerState.queryParams: {site: "41"}
            if (params.pageParams && !isNaN(params.pageParams)) {
              this.selectedLocation = { id: +params.pageParams };
            }
            break;
          case 'floorplan':
            // routerState.params: {pageId: "floorplan"}
            // routerState.queryParams: {site: "41", floor: "320", location: "322"}
            if (this.data && this.data.locationId) {
              this.selectedLocation = { id: +this.data.locationId };
            }
            break;
        }
      }
    });

    if (this.data && this.data.id) {
      this.editMode = true;
      this.fetchRule(this.data.id);
    }

    this.fetchDevices();
    this.fetchDeviceTypes();
    this.fetchLocations();
    this.fetchRuleTemplates();
    this.fetchNotificationTemplates();
    this.fetchLocationProperties();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDevices() {
    this.fetchingState++;
    const options = {
      siteId: this.selectedSite.id,
      locationId: this.selectedLocation.id,
    };
    this.subscribers.getDevices = this.devicesService.getDevices(options).subscribe({
      next: (result: any) => {
        this.selectItems.devices = result.data
          .filter((item) => item.isActive && !item.isDeleted)
          .map((item) => {
            return {
              label: item.description || item.id,
              value: item.id,
              deviceTypeId: item.deviceTypeId,
            };
          });

        this.selectItems.affectedDevices = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted && d.isVirtualDevicesHolder),
          false
        );

        this.fetchingState--;
      },
    });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.getDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe({
      next: (result: any) => {
        this.selectItems.deviceTypesSource = result.data;
        this.fetchingState--;
      },
    });
  }

  private fetchLocations() {
    this.fetchingState++;
    const options = {
      companyId: this.selectedSite.companyId,
      siteId: this.selectedSite.id,
      columns: 'id,locationTypeId,description,isActive,isDeleted',
    };
    this.subscribers.getLocations = this.locationsService.getLocations(options).subscribe({
      next: (result: any) => {
        this.selectItems.locations = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted),
          false
        );

        this.selectItems.affectedLocations = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted),
          false
        );

        this.fetchingState--;
      },
    });
  }

  private fetchRuleTemplates() {
    this.fetchingState++;
    this.subscribers.getRuleTemplates = this.ruleTemplatesService.getRuleTemplates().subscribe({
      next: (result: any) => {
        this.ruleTemplatesSource = result.data;

        const ruleTemplates = result.data.filter((d) => {
          const siteIds = d.siteIds || [];
          return d.isActive && !d.isDeleted && (d.isPublic || siteIds.indexOf(this.selectedSite.id) >= 0);
        });

        this.selectItems.ruleTemplates = this.sharedService.createSelectItems(ruleTemplates, false);

        this.fetchingState--;
      },
    });
  }

  private fetchNotificationTemplates() {
    this.fetchingState++;
    this.subscribers.getNotificationTemplates = this.notificationTemplatesService.get().subscribe({
      next: (result: any) => {
        const notificationTemplates = result.data.filter((d) => {
          const siteIds = d.siteIds || [];
          return d.isActive && !d.isDeleted && (d.isPublic || siteIds.indexOf(this.selectedSite.id) >= 0);
        });
        this.selectItems.notificationTemplatesSource = notificationTemplates;
        this.fetchingState--;
      },
    });
  }

  private fetchLocationProperties() {
    this.fetchingState++;
    this.subscribers.getLocationProps = this.locationPropertiesService.getLocationProperties().subscribe({
      next: (result: any) => {
        this.selectItems.locationPropertiesList = result.data;
        this.fetchingState--;
      },
    });
  }

  private fetchRule(id: number) {
    this.fetchingState++;
    this.subscribers.getRule = this.rulesService.getRule(id).subscribe({
      next: (result: any) => {
        this.formData = result.data;

        // Parse date string to date object
        if (this.formData.disabledUntil) {
          this.formData.disabledUntil = this.sharedService.parseDateStringToDateObject(this.formData.disabledUntil);
        }

        // Convert string with comma separate into array
        if (this.formData.energySavingPatternsEnd) {
          this.formData.energySavingPatternsEnd = (<string>this.formData.energySavingPatternsEnd).split(',');
        }

        if (this.formData.energySavingPatternsStart) {
          this.formData.energySavingPatternsStart = (<string>this.formData.energySavingPatternsStart).split(',');
        }

        // store as array in DB but the form accept singular value
        if (result.data.affectedDevices) {
          this.formData.affectedDevices = this.sharedService.tryParseJSON(result.data.affectedDevices)[0];
        }

        if (result.data.affectedLocations) {
          this.formData.affectedLocations = this.sharedService.tryParseJSON(result.data.affectedLocations)[0];
        }

        // PATCH FORM DATA INTO FORM
        this.ruleFormsService.patchValue(this.form, this.formData);

        this.fetchingState--;
      },
    });
  }

  private initForm() {
    this.form = this.ruleFormsService.initRuleForm();

    if (this.editMode === true) {
      this.form.get('ruleTemplateId').disable();
    } else {
      this.subscribers.ruleTemplateId = this.form.get('ruleTemplateId').valueChanges.subscribe((value) => {
        if (value) {
          this.setRuleInfo(value);
        }
      });

      this.form.patchValue({
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id,
        locationId: this.selectedLocation.id,
        affectedLocations: this.selectedLocation.id,
      });
    }
  }

  showCommandAndConditionForm(type: string) {
    this.currentCommandAndConditionForm = type;
  }

  setRuleInfo(ruleTemplateId: number) {
    const template = this.ruleTemplatesSource && this.ruleTemplatesSource.find((rt) => rt.id === ruleTemplateId);

    if (template) {
      if (template.energySavingPatternsEnd) {
        template.energySavingPatternsEnd = (<string>template.energySavingPatternsEnd).split(',');
      }

      if (template.energySavingPatternsStart) {
        template.energySavingPatternsStart = (<string>template.energySavingPatternsStart).split(',');
      }

      // PATCH RULE_TEMPLATE INTO RULE
      this.ruleFormsService.patchValueFromTemplate(this.form, template);
    }
  }

  get ruleTemplateId(): number {
    if (this.form) {
      return this.form.get('ruleTemplateId').value;
    }
    return;
  }

  get startConditions(): FormArray {
    return this.form.controls['startConditions'] as FormArray;
  }
  get endConditions(): FormArray {
    return this.form.controls['endConditions'] as FormArray;
  }

  get startCommands(): FormArray {
    return this.form.controls['startCommands'] as FormArray;
  }
  get endCommands(): FormArray {
    return this.form.controls['endCommands'] as FormArray;
  }

  get endConditionConnector(): string {
    if (this.form) {
      return this.form.get('endConditionConnector').value;
    }
    return '';
  }

  get startConditionConnector(): string {
    if (this.form) {
      return this.form.get('startConditionConnector').value;
    }
    return '';
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.getRawValue() };
      delete formData.id;

      // Parse date object to date string
      if (formData.disabledUntil) {
        formData.disabledUntil = this.sharedService.parseDateObjectToDateString(formData.disabledUntil);
      }

      // Convert array into string with comma separate
      if (formData.energySavingPatternsEnd) {
        formData.energySavingPatternsEnd = formData.energySavingPatternsEnd.join(',');
      }

      if (formData.energySavingPatternsStart) {
        formData.energySavingPatternsStart = formData.energySavingPatternsStart.join(',');
      }

      if (formData.affectedDevices) {
        formData.affectedDevices = [formData.affectedDevices];
      }

      if (formData.affectedLocations) {
        formData.affectedLocations = [formData.affectedLocations];
      }

      if (this.editMode) {
        this.update(this.formData.id, formData);
      } else {
        this.create(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(data: Rule) {
    this.subscribers.create = this.rulesService.createRule(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(id: number, data: Rule) {
    this.subscribers.update = this.rulesService.updateRule(id, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.rulesService.deleteRule(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('RULE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
