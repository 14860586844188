import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { GuestRequestPresetsService } from '../guest-request-presets.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-guest-request-preset-action-cell',
  templateUrl: './guest-request-preset-action-cell.component.html'
})
export class GuestRequestPresetActionCellComponent implements ICellRendererAngularComp {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  constructor(
    private guestRequestPresetsService: GuestRequestPresetsService,
    private sharedService: SharedService
  ) { }

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'GuestRequestPresetForm',
      data: this.cell.data,
      options: { modalTitle: 'GUEST_REQUEST_PRESET_FORM_TITLE' }
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: '_u',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this)
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this)
      }
    ];
  }

  refresh(): boolean {
    return false;
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.guestRequestPresetsService.delete(this.cell.data.id).subscribe(
      (result: any) => {
        this.cell.context.parentComponent.updateRow({
          id: this.cell.data.id,
          isActive: false,
          isDeleted: true
        });
        const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
        const title = this.sharedService.getTranslation('GUEST_REQUEST_PRESET');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const text = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('GUEST_REQUEST_PRESET');
        this.sharedService.notify(title, text, 'error');
      }
    );
  }
}
