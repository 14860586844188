export enum LocationPropertyColors {
  Purple = '#3f51b5',
  Green = '#64d490',
  Orange = '#ea5e25',
  Yellow = '#ffc107',
  Grey = '#f4f4f4',
  Black = '#222222',
  Blue = '#e9f6fe',
  Darkblue = '#0c2e55',
}

export enum LocationPropertyBorderColors {
  Grey = '#9f9f9f',
  Blue = '#26A5F5',
}

export enum LocationPropertyIcons {
  Info = 'assets/images/info.svg',
  AirQuality = 'assets/images/air-quality.svg',
  Brightness = 'assets/images/brightness.svg',
  Consumption = 'assets/images/consumption.svg',
  // ConsumptionTotal = 'assets/images/consumption.svg',
  CurtainsOpen = 'assets/images/curtain.svg',
  DoNotDisturbStatus = 'assets/images/do-not-disturb.svg',
  DoorLocked = 'assets/images/door-locked.svg',
  DoorOpen = 'assets/images/door.svg',
  Humidity = 'assets/images/humidity.svg',
  HVACFanspeed = 'assets/images/fan-speed.svg',
  HVACMode = 'assets/images/hvac-mode.svg',
  HVACSetpoint = 'assets/images/setpoint.svg',
  HVACStatus = 'assets/images/hvac-mode.svg',
  // IsBookedAndCheckInDone = 'assets/images/info.svg',
  // IsBookedButNoCheckInDone = 'assets/images/info.svg',
  // IsCheckInInProgress = 'assets/images/info.svg',
  // IsCheckOutInProgress = 'assets/images/info.svg',
  IsRaining = 'assets/images/raining.svg',
  LightsOn = 'assets/images/light-blub.svg',
  MakeUpStatus = 'assets/images/make-up.svg',
  Occupied = 'assets/images/occupied.svg',
  OutOfOrderStatus = 'assets/images/out-of-order.svg',
  OutdoorHumidity = 'assets/images/humidity.svg',
  OutdoorTemperature = 'assets/images/temperature.svg',
  SquareMeter = 'assets/images/square-meter.svg',
  Temperature = 'assets/images/temperature.svg',
  WindSpeed = 'assets/images/wind-speed.svg',
  WindowOpen = 'assets/images/window.svg',
}
