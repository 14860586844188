import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalConfig } from '@sc/modal/modal-config';
import { User } from '../models/user';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { AuthService } from '@app/auth/services/auth.service';
import { SharedService } from '@shared/shared.service';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'sc-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  fetchingState = 0;
  formData: User;
  subscribers: { [key: string]: any } = {};
  title = 'INFORMATION';
  isAdmin: boolean;

  constructor(
    private auth: AuthService,
    private store: Store<fromStore.State>,
    private sharedService: SharedService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    if (this.sharedService.userId) {
      this.fetchUser(this.sharedService.userId);
    }
    this.isAdmin = this.auth.isAuthorized('admin');
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchUser(id: number) {
    this.fetchingState++;
    this.subscribers.GET_USER = this.usersService.getUser(id).subscribe((result: any) => {
      this.formData = result.data;

      // update user variant
      this.store.dispatch(new fromStore.UpdateUserVariant(this.formData.backendVariant));

      this.fetchingState--;
    });
  }

  updateProfile(event) {
    this.formData = Object.assign({}, this.formData, event.result);
  }

  get editProfileFormConfig(): ModalConfig {
    return {
      name: 'ProfileForm',
      data: this.formData,
      options: { modalTitle: 'USER_PROFILE_FORM_TITLE' },
    };
  }

  get changePasswordFormConfig(): ModalConfig {
    return {
      name: 'UserPasswordForm',
      data: this.formData,
      options: { modalTitle: 'USER_PASSWORD_FORM_TITLE' },
    };
  }

  get changeEmailFormConfig(): ModalConfig {
    return {
      name: 'UserEmailForm',
      data: this.formData,
      options: { modalTitle: 'USER_EMAIL_FORM_TITLE' },
    };
  }

  get flag() {
    if (this.formData && this.formData.locale) {
      let lang = this.formData.locale;
      if (lang === 'en') {
        lang = 'gb';
      }
      return 'flag-icon flag-icon-' + lang;
    }
    return;
  }

  get gender() {
    if (this.formData && this.formData.gender) {
      switch (this.formData.gender.toUpperCase()) {
        case 'M':
          return { icon: 'fa fa-mars', text: 'MAN' };
        case 'F':
          return { icon: 'fa fa-venus', text: 'WOMAN' };
      }
    }
    return;
  }

  get language() {
    return this.sharedService.selectItems.locales
      .find((locale) => locale.value === this.formData.locale)
      .label.toUpperCase();
  }
}
