import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { CeosIdentity } from '../models/ceos-identity';
import { CeosIdentityRequestOption } from '../models/ceos-identity-request-option';
import { SharedService } from '@shared/shared.service';

@Injectable()
export class CeosIdentityService {
  private apiUrl = `${config().apiUrl}/ceosIdentities`;

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private verifyData(data: any): CeosIdentity {
    const newData = { ...data };
    delete newData.id;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    return newData;
  }

  getCeosIdentities(options?: CeosIdentityRequestOption) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(`${this.apiUrl}${queryParams}`);
  }

  getCeosIdentity(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  createCeosIdentity(data: CeosIdentity) {
    return this.httpClient.post(this.apiUrl, this.verifyData(data));
  }

  updateCeosIdentity(id: number, data: CeosIdentity) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteCeosIdentity(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isDeleted: true,
      isActive: false,
    });
  }

  validateOwner(id: number, key: string) {
    const queryParams = this.sharedService.urlQueryParamsCreator({ id, key });
    return this.httpClient.get(`${this.apiUrl}/owner${queryParams}`);
  }

  updateOwner(identities: number[], owner: number) {
    return this.httpClient.patch(`${this.apiUrl}/owner`, { identities, owner });
  }
}
