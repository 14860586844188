import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { GridOptions } from 'ag-grid';
import { Company } from '@widgets/companies/models/company';
import { Site } from '../models/site';
import { ModalConfig } from '@sc/modal/modal-config';
import { SharedService } from '@shared/shared.service';
import { SiteActionCellComponent } from '../site-action-cell/site-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

interface SiteRow extends Site {
  company?: string;
}

@Component({
  selector: 'sc-sites-table',
  templateUrl: 'sites-table.component.html',
})
export class SitesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: SiteRow[];
  columns: any[];
  formConfig: ModalConfig;
  tableTitle: string;
  companyId: number;
  isAdmin = false;
  isIntegrator = false;

  companies: Company[] = [];
  companySelector = new FormControl();

  private subscribers: { [key: string]: any } = {};

  constructor(private store: Store<fromStore.State>, private sharedService: SharedService) {}

  ngOnInit() {
    // get user companyId
    this.companyId = this.sharedService.userCompanyId;
    // check user is an admin
    this.isAdmin = this.sharedService.userRoles && this.sharedService.userRoles.includes('admin');
    // site form
    this.formConfig = {
      name: 'SiteForm',
      options: { modalTitle: 'SITE_FORM_TITLE' },
    };

    this.initTable();
    this.fetchDataset();
    this.subscribeData();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (this.isAdmin) {
      return this.store.dispatch(new fromStore.LoadSitesTable());
    }
    return this.store.dispatch(new fromStore.LoadSitesTable(this.companyId));
  }

  afterInitTable(table: GridOptions) {
    // Show company column for admin
    if (this.isAdmin) {
      setTimeout(() => table.columnApi.setColumnsVisible(['company', 'companyId'], true), 500);
    }
  }

  private initTable() {
    this.tableTitle = 'SITES';
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
      },
      {
        colId: 'ip',
        headerName: 'IP',
        field: 'ip',
      },
      {
        colId: 'port',
        headerName: 'PORT',
        field: 'port',
      },
      {
        colId: 'companyId',
        headerName: 'COMPANY_ID',
        field: 'companyId',
      },
      {
        colId: 'timezone',
        headerName: 'TIMEZONE',
        field: 'timezone',
      },
      {
        colId: 'currency',
        headerName: 'CURRENCY',
        field: 'currency',
      },
      {
        colId: 'phone',
        headerName: 'PHONE',
        field: 'phone',
      },
      {
        colId: 'fax',
        headerName: 'FAX',
        field: 'fax',
      },
      {
        colId: 'email',
        headerName: 'EMAIL',
        field: 'email',
      },
      {
        colId: 'website',
        headerName: 'WEBSITE',
        field: 'website',
      },
      {
        colId: 'lat',
        headerName: 'LATITUDE',
        field: 'lat',
      },
      {
        colId: 'lng',
        headerName: 'LONGITUDE',
        field: 'lng',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        width: 100,
        maxWidth: 100,
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: SiteActionCellComponent,
      },
    ];

    // Add company column for admin
    if (this.isAdmin) {
      this.columns.splice(5, 0, {
        colId: 'company',
        headerName: 'COMPANY',
        field: 'company',
      });
    }
  }

  private subscribeData() {
    this.subscribers.companies = this.store.select(fromStore.getInitialDataCompanies).subscribe((result) => {
      if (result && result.id) {
        this.companies = [result];
      } else {
        this.companies = result;
      }
    });

    this.subscribers.sites = this.store.select(fromStore.getTableSites).subscribe((result) => {
      if (result) {
        let dataset: SiteRow[];

        if (this.isAdmin && this.companies.length) {
          dataset = [];
          [...result].forEach((d) => {
            const company = this.companies.find((c) => c.id === d.companyId);
            dataset.push({
              ...d,
              company: company ? company.name : null,
            });
          });
        } else {
          dataset = [...result];
        }

        this.dataset = dataset;
      }
    });
  }
}
