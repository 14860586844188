import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { BulkModificationFormService } from '../services/bulk-modification-form.service';
import { BulkModificationService } from '../services/bulk-modification.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-bulk-modification-form',
  templateUrl: './bulk-modification-form.component.html',
  styleUrls: ['./bulk-modification-form.component.scss']
})
export class BulkModificationFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  isSubmitting = false;
  rawData: { [key: string]: any } = {};
  selectItems: { [key: string]: SelectItem[] } = {};
  selectedData: { [key: string]: any }[] = [];
  previewData: { [key: string]: any }[] = [];

  private subscribers: { [key: string]: any } = {};

  constructor(
    private bulkModificationFormService: BulkModificationFormService,
    private bulkModificationService: BulkModificationService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Retrieve all fields of table which allow bulk modification
    if (this.data && this.data.table && this.data.selected) {
      this.retrieveInitialData();
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  retrieveInitialData() {
    this.fetchingState++;
    const options = {
      siteId: this.sharedService.selectedSite.id,
      selected: this.data.selected,
      table: this.data.table
    };
    this.subscribers.GET_INITIAL_DATA = this.bulkModificationService
      .initial(options)
      .subscribe((result: any) => {
        // full detail of Selected Data
        this.selectedData = result.data.data;

        // available fields for bulk modification
        this.rawData.fields = result.data.fields;
        this.selectItems.fields = this.sharedService.createSelectItems(
          result.data.fields.filter(d => d.isActive && !d.isDeleted),
          false,
          'name'
        );
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.bulkModificationFormService.initBulkModificationForm();

    // set "table" field value
    this.form.patchValue({
      table: this.data.table
    });

    // watch fields value and update live preview
    this.form.get('fields').valueChanges.subscribe(fields => {
      this.previewData = [];

      // loop selected data
      for (let i = 0; i < this.selectedData.length; i++) {
        const tmp = {
          id: this.selectedData[i].id,
          name:
            this.selectedData[i].description ||
            this.selectedData[i].name ||
            '(empty)',
          fields: []
        };

        // loop selected fields
        for (let ii = 0; ii < fields.length; ii++) {
          if (!fields[ii].field) {
            continue;
          }

          // convert 1/0 to true/false
          let currentValue = this.selectedData[i][fields[ii].field];
          if (fields[ii].type === 'TINYINT') {
            currentValue = currentValue === 1 ? true : false;
          }

          tmp.fields.push({
            key: fields[ii].field,
            currentValue,
            newValue: fields[ii].value
          });
        }

        this.previewData.push(tmp);
      }
    });
  }

  get fields(): FormArray {
    return this.form.get('fields') as FormArray;
  }

  addField() {
    const control = this.fields;
    control.push(
      this.bulkModificationFormService.initBulkModificationFieldForm()
    );
  }

  removeField(i: number) {
    const control = this.fields;
    control.removeAt(i);
  }

  get fieldData() {
    return {
      selectItems: this.selectItems,
      rawData: this.rawData
    };
  }

  submit() {
    if (!this.form.valid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }

    // clear error message
    this.errorMessage = null;
    // this.isSubmitting = true;

    const dataToUpdate = [];
    const formData = { ...this.form.value };
    const fields: any = {};

    // loop fields
    for (let i = 0; i < formData.fields.length; i++) {
      let value = formData.fields[i].value;
      // convert true/false to 1/0
      if (formData.fields[i].type === 'TINYINT') {
        value = value === true ? 1 : 0;
      }
      fields[formData.fields[i].field] = value;
    }

    // loop selected data
    for (let i = 0; i < this.selectedData.length; i++) {
      dataToUpdate.push({ ...fields, id: this.selectedData[i].id });
    }

    const options = {
      siteId: this.sharedService.selectedSite.id,
      table: this.data.table
    };
    this.subscribers.BULK_UPDATE = this.bulkModificationService
      .bulkUpdate(options, dataToUpdate)
      .subscribe(
        (result: any) => {
          // show notification
          const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
          const title = this.sharedService.getTranslation('BULK_MODIFICATION');
          this.sharedService.notify(title, text, 'success');

          // close the form and return user info
          this.isSubmitting = false;
          this.onClose.emit(result.data);
        },
        (error: any) => {
          // display error message and unlock the form
          this.errorMessage = error;
          this.isSubmitting = false;
        }
      );
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  livePreviewTrackBy(index, item) {
    return item.id;
  }
}
