import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { TriggeredRulesComponent } from './triggered-rules.component';

@NgModule({
  imports: [SharedModule],
  declarations: [TriggeredRulesComponent],
  entryComponents: [TriggeredRulesComponent],
})
export class TriggeredRulesModule {}
