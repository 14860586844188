import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { DeviceCommandMapping } from '../models/device-command-mapping';

import { DeviceBrandsService } from '@widgets/device-brands/services/device-brands.service';
import { DeviceCommandMappingService } from '../services/device-command-mapping.service';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { ProtocolControllersService } from '../services/protocol-controllers.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-device-command-mapping-form',
  templateUrl: './device-command-mapping-form.component.html'
})
export class DeviceCommandMappingFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: DeviceCommandMapping;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};

  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private deviceCommandMappingsService: DeviceCommandMappingService,
    private deviceBrandsService: DeviceBrandsService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceTypesService: DeviceTypesService,
    private protocolControllersService: ProtocolControllersService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchDeviceBrands();
    this.fetchDeviceProtocols();
    this.fetchDeviceTypes();
    this.fetchProtocolControllers();

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchDeviceCommandMapping(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDeviceCommandMapping(id: number) {
    this.fetchingState++;
    this.deviceCommandMappingsService
      .getCommandMapping(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  private fetchDeviceBrands() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_BRANDS = this.deviceBrandsService
      .getDeviceBrands()
      .subscribe((result: any) => {
        this.selectItems.deviceBrands = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  private fetchDeviceProtocols() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_PROTOCOLS = this.deviceProtocolsService
      .getDeviceProtocols()
      .subscribe((result: any) => {
        this.selectItems.deviceProtocols = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  private fetchProtocolControllers() {
    this.fetchingState++;
    this.subscribers.GET_PROTOCOL_CONTROLLERS = this.protocolControllersService
      .getProtocolControllers()
      .subscribe((result: any) => {
        this.selectItems.protocolControllers = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_TYPES = this.deviceTypesService
      .getDeviceTypes()
      .subscribe((result: any) => {
        this.selectItems.deviceTypes = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: { value: null, disabled: true },
      deviceBrandId: null,
      deviceProtocolId: [null, Validators.required],
      protocolControllerId: [null, Validators.required],
      deviceTypeId: [null, Validators.required],
      command: [null, Validators.required],
      key: [null, Validators.required],
      syntax: null,
      delay: null,
      isActive: false,
      isDeleted: false
    });

    if (this.editMode === true) {
      this.form.get('command').disable();
      this.form.get('deviceBrandId').disable();
    }
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.form.value;

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(data: DeviceCommandMapping) {
    this.subscribers.CREATE_COMMAND_MAPPING = this.deviceCommandMappingsService
      .createCommandMapping(data)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  private update(data: DeviceCommandMapping) {
    this.subscribers.UPDATE_COMMAND_MAPPING = this.deviceCommandMappingsService
      .updateCommandMapping(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE_COMMAND_MAPPING = this.deviceCommandMappingsService
      .deleteCommandMapping(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE_COMMAND_MAPPING');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
