import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { DeviceCommandMapping } from '../models/device-command-mapping';
import { ModalConfig } from '@sc/modal/modal-config';

import { DeviceCommandMappingService } from '../services/device-command-mapping.service';
import { SharedService } from '@shared/shared.service';

import { DeviceCommandMappingActionCellComponent } from '../device-command-mapping-action-cell/device-command-mapping-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-device-command-mapping-table',
  templateUrl: 'device-command-mapping-table.component.html'
})
export class DeviceCommandMappingTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: DeviceCommandMapping[];
  formConfig: ModalConfig;
  tableTitle = 'DEVICE_COMMAND_MAPPING';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceCommandMappingService: DeviceCommandMappingService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.columns = this.createColumns();

    this.formConfig = {
      name: 'DeviceCommandMappingForm',
      options: { modalTitle: 'DEVICE_COMMAND_MAPPING_FORM_TITLE' }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.DATASET = this.deviceCommandMappingService
      .getCommandMappings()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'command',
        headerName: 'COMMAND',
        field: 'command'
      },
      {
        colId: 'deviceProtocolId',
        headerName: 'DEVICE_PROTOCOL_ID',
        field: 'deviceProtocolId'
      },
      {
        colId: 'deviceTypeId',
        headerName: 'DEVICE_TYPE_ID',
        field: 'deviceTypeId'
      },
      {
        colId: 'syntax',
        headerName: 'SYNTAX',
        field: 'syntax'
      },
      {
        colId: 'delay',
        headerName: 'DELAY',
        field: 'delay'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceCommandMappingActionCellComponent
      }
    ];
  }
}
