import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class AppFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initAppForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      description: null,
      appStyleTemplateId: null,
      isActive: false,
      isDeleted: false
    });
  }
}
