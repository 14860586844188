<div class="ui-g ui-g-nopad">
  <div class="ui-g-12 ui-md-8">
    <sc-quick-date-select [limitDate]="true" (valueChange)="onDateSelect($event)"></sc-quick-date-select>
  </div>
  <div class="ui-g-12 ui-md-4">
    <button pButton type="button" class="ui-button-success" label="{{ 'UPDATE' | translate }}"
      (click)="fetchDeviceHistory(true)" pTooltip="{{ 'FORCE_RELOAD' | translate }}" tooltipPosition="top"
      appendTo="body"></button>

    <button *ngIf="!isFirstFetch" pButton type="button" class="ui-button-secondary" icon="fa fa-refresh {{ isAutoUpdate ? 'fa-spin' : '' }}"
      (click)="toggleAutoRefresh()" pTooltip="{{ 'AUTO_UPDATE_TOGGLE' | translate }}" tooltipPosition="top"
      appendTo="body"></button>
  </div>
</div>

<div class="ui-g">
  <div class="ui-g-12 ui-md-6">
    <button pButton type="button" [ngClass]="changeViewBtnClass('table')" icon="fa fa-fw fa-table"
      (click)="onChangeView('table')"></button>

    <button pButton type="button" [ngClass]="changeViewBtnClass('line-chart')" icon="fa fa-fw fa-line-chart"
      (click)="onChangeView('line-chart')"></button>

    <button pButton type="button" [ngClass]="changeViewBtnClass('bar-chart')" icon="fa fa-fw fa-bar-chart"
      (click)="onChangeView('bar-chart')"></button>

    <a href="{{ linkcsv }}" target="_blank">csv</a>
  </div>
  <div class="ui-g-12 ui-md-6 text-right">
    <div id="sc-chart-label"></div>
  </div>
</div>

<div [ngSwitch]="currentView" class="ui-g">
  <div *ngSwitchCase="'table'" class="ui-g-12 ui-g-nopad" style="border:1px solid #f2f2f2; height:400px;">
    <sc-table [columns]="tableColumns" [dataset]="[]" [hideHeader]="true" (afterInit)="afterTableInit($event)">
    </sc-table>
  </div>

  <div *ngSwitchDefault class="ui-g-12 ui-g-nopad">
    <ng-container *ngIf="chartData">
      <plotly-plot
        [data]="chartData"
        [layout]="chartOptions"
        [config]="{displayModeBar: false}"
        (relayout)="afterRelayout()"
      ></plotly-plot>
    </ng-container>
    <ng-container *ngIf="!chartData && isFetchDeviceHistory">
      <sc-loading-indicator></sc-loading-indicator>
    </ng-container>
  </div>
</div>
