<div class="sc-flex grow-1 direction-column mb-3">
  <h5 class="m-0">{{ label }}</h5>

  <div class="sc-flex grow-1 direction-column mb-1">
    <div class="sc-flex justify-content-between align-items-center">
      <span class="grey-text">
        <!-- {{ label }}  -->
        {{ 'UNOCCUPIED' | translate }}
      </span>
      <span class="badge grey">
        {{ unoccupied }} / {{ total }} ({{
          unoccupiedPercent | number: '1.0-0'
        }}
        %)
      </span>
    </div>

    <p-progressBar
      [value]="unoccupiedPercent | number: '1.0-0'"
      [showValue]="false"
    ></p-progressBar>
  </div>

  <div class="sc-flex grow-1 direction-column">
    <div class="sc-flex justify-content-between align-items-center">
      <span class="grey-text">
        <!-- {{ label }}  -->
        {{ 'MAKE_UP_ROOM' | translate }}
      </span>
      <span class="badge grey">
        {{ makeUpMyRoom }} / {{ total }} ({{
          makeUpMyRoomPercent | number: '1.0-0'
        }}
        %)
      </span>
    </div>

    <p-progressBar
      [value]="makeUpMyRoomPercent | number: '1.0-0'"
      [showValue]="false"
    ></p-progressBar>
  </div>
</div>
