<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="ui-g">
    <!-- LOCATIONS -->
    <div class="ui-g-4">
      <sc-tree-multi-select
        controlName="locations"
        [form]="form"
        label="{{ 'LOCATIONS' | translate }}"
        [options]="selectItems['locations']"
      ></sc-tree-multi-select>
    </div>

    <!-- RULE_TEMPLATE -->
    <div class="ui-g-4">
      <sc-dropdown
        controlName="ruleTemplate"
        [form]="form"
        label="{{ 'RULE_TEMPLATE' | translate }}"
        [options]="selectItems['ruleTemplates']"
      ></sc-dropdown>
    </div>

    <!-- ACTION -->
    <div class="ui-g-3">
      <sc-dropdown
        controlName="action"
        [form]="form"
        label="{{ 'ACTION' | translate }}"
        [options]="selectItems['actions']"
      ></sc-dropdown>
    </div>

    <!-- RUN BUTTON -->
    <div class="ui-g-1 p-1 mt-4">
      <button
        pButton
        type="submit"
        class="ui-button-info"
        icon="fa fa-play"
        [disabled]="!form.valid"
      ></button>
    </div>
  </div>
</form>
