import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DeviceModelFormService } from '../services/device-model-form.service';

@Component({
  selector: 'sc-property-config-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './property-config-form.component.html',
  styleUrls: ['./property-config-form.component.scss'],
})
export class PropertyConfigFormComponent implements OnInit, OnChanges {
  @Input()
  form: FormGroup;

  @Input()
  selectItems: { [key: string]: SelectItem[] } = {};

  @Input()
  sourceData: { [key: string]: any } = {};

  configForm: FormArray;

  constructor(private deviceModelFormService: DeviceModelFormService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue) {
      this.updateConfigForm();
    }
  }

  ngOnInit(): void {}

  private updateConfigForm() {
    this.configForm = this.form.get('config') as FormArray;
  }

  addConfig() {
    const form = this.deviceModelFormService.initPropertyConfigForm();
    this.configForm.push(form);
  }

  removeConfig(index: number) {
    this.configForm.removeAt(index);
  }
}
