import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { RoomUnoccupancyHistoryComponent } from './room-unoccupancy-history.component';

@NgModule({
  imports: [SharedModule],
  exports: [RoomUnoccupancyHistoryComponent],
  declarations: [RoomUnoccupancyHistoryComponent],
  entryComponents: [RoomUnoccupancyHistoryComponent],
  providers: []
})
export class RoomUnoccupancyHistoryModule {}
