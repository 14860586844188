import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { LocationDetails } from '../models/location-details';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-detail-header',
  styleUrls: ['location-detail-header.component.scss'],
  templateUrl: 'location-detail-header.component.html',
})
export class LocationDetailHeaderComponent implements OnInit, OnDestroy {
  selectedLocation: LocationDetails;
  neighbour: LocationDetails[];

  private locations: LocationDetails[];
  private myPosition: number;
  private subscribers: { [key: string]: any } = {};

  constructor(private store: Store<fromStore.State>, private sharedService: SharedService) {}

  ngOnInit() {
    // get locations
    this.subscribers.locations = this.store.select(fromStore.getAllLocations).subscribe((result) => {
      if (result) {
        this.locations = result;
      }
    });

    // get location details
    this.subscribers.locationDetail = this.store.select(fromStore.getLocationDetials).subscribe((result) => {
      if (result) {
        this.selectedLocation = result;
        this.getNeighbours();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  visitNeighbour(location) {
    this.store.dispatch(new fromStore.Go({ path: ['locations', location.id] }));
  }

  backToLocations() {
    this.store.dispatch(new fromStore.Go({ path: ['locations'] }));
  }

  private getNeighbours() {
    if (!this.locations || !this.locations.length) {
      return;
    }
    this.neighbour = [];
    // for (let i = 0; i < this.locations.length; i++) {
    for (const l of this.locations) {
      if (l.isActive && !l.isDeleted && l.parentId === this.selectedLocation.parentId) {
        this.neighbour.push(l);
      }
    }
    this.getPosition();
  }

  private getPosition() {
    if (!this.neighbour || !this.neighbour.length) {
      return;
    }
    for (let i = 0; i < this.neighbour.length; i++) {
      if (this.neighbour[i].id === this.selectedLocation.id) {
        this.myPosition = i;
        break;
      }
    }
  }

  get locationStatus() {
    return this.selectedLocation && this.selectedLocation.isActive ? 'ACTIVE' : 'INACTIVE';
  }

  get locationStatusColor() {
    return this.locationStatus === 'ACTIVE' ? 'green' : 'red';
  }

  get leftNeighbour() {
    if (this.neighbour && this.neighbour.length > 1 && typeof this.myPosition !== 'undefined') {
      let position = this.myPosition - 1;
      position = position < 0 ? this.neighbour.length - 1 : position;
      return this.neighbour[position];
    }
    return;
  }

  get rightNeighbour() {
    if (this.neighbour && this.neighbour.length > 1 && typeof this.myPosition !== 'undefined') {
      let position = this.myPosition + 1;
      position = position < this.neighbour.length ? position : 0;
      return this.neighbour[position];
    }
    return;
  }

  // Treat the id as the unique identifier for the object
  trackById(index, locaiton) {
    return locaiton.id;
  }
}
