import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { LocationProfile } from '../models/location-profile';

import { LocationProfilesService } from '../services/location-profile.service';
import { LocationProfileFormsService } from '../services/location-profile-forms.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-profile-form',
  templateUrl: 'location-profile-form.component.html'
})
export class LocationProfileFormComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Output() onClose = new EventEmitter();
  @Output() onDismiss = new EventEmitter();

  formData: LocationProfile;
  form: FormGroup;
  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  isSubmitting = false;
  subscribers: { [key: string]: any } = {};

  constructor(
    private formService: LocationProfileFormsService,
    private locationProfilesService: LocationProfilesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.data) {
      // Edit Mode
      this.editMode = true;
      // Fetch Location Profile
      this.fetchLocationProfile(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {}

  fetchLocationProfile(id: number) {
    this.fetchingState++;
    this.subscribers.GET_LOCATION_PROFILE = this.locationProfilesService
      .getLocationProfile(id)
      .subscribe((result: any) => {
        this.formData = this.parseDateObject(result.data);

        this.form.patchValue(this.formData);

        this.formService.setAvgTemperatureDroppingFormArray(
          this.form,
          this.formData.avgTemperatureDropping
        );

        this.formService.setAvgTemperatureeRaisingFormArray(
          this.form,
          this.formData.avgTemperatureRaising
        );

        this.formService.setAvgHumidityDroppingFormArray(
          this.form,
          this.formData.avgHumidityDropping
        );

        this.formService.setAvgHumidityRaisingFormArray(
          this.form,
          this.formData.avgHumidityRaising
        );

        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formService.initLocationProfileForm();
  }

  // Temperature
  get avgTemperatureDroppingFormArray(): FormArray {
    return this.form.get('avgTemperatureDropping') as FormArray;
  }
  get avgTemperatureRaisingFormArray(): FormArray {
    return this.form.get('avgTemperatureRaising') as FormArray;
  }
  addAvgTemperature(fieldName: string) {
    const controls = <FormArray>this.form.controls[fieldName];
    controls.push(this.formService.initFormGroup());
  }
  removeAvgTemperature(fieldName: string, i: number) {
    const controls = <FormArray>this.form.controls[fieldName];
    controls.removeAt(i);
  }

  // Humidity
  get avgHumidityDroppingFormArray(): FormArray {
    return this.form.get('avgHumidityDropping') as FormArray;
  }
  get avgHumidityRaisingFormArray(): FormArray {
    return this.form.get('avgHumidityRaising') as FormArray;
  }
  addAvgHumidity(fieldName: string) {
    const controls = <FormArray>this.form.controls[fieldName];
    controls.push(this.formService.initFormGroup());
  }
  removeAvgHumidity(fieldName: string, i: number) {
    const controls = <FormArray>this.form.controls[fieldName];
    controls.removeAt(i);
  }

  // parse timeFrom and timeTo from date string to date object
  parseDateObject(formData) {
    const tmp = { ...formData };

    // avgTemperatureDropping
    if (tmp.avgTemperatureDropping && tmp.avgTemperatureDropping.length) {
      tmp.avgTemperatureDropping = tmp.avgTemperatureDropping.map(item => {
        item.timeFrom = this.sharedService.parseDateStringToDateObject(
          item.timeFrom
        );
        item.timeTo = this.sharedService.parseDateStringToDateObject(
          item.timeTo
        );
        return item;
      });
    }

    // avgTemperatureRaising
    if (tmp.avgTemperatureRaising && tmp.avgTemperatureRaising.length) {
      tmp.avgTemperatureRaising = tmp.avgTemperatureRaising.map(item => {
        item.timeFrom = this.sharedService.parseDateStringToDateObject(
          item.timeFrom
        );
        item.timeTo = this.sharedService.parseDateStringToDateObject(
          item.timeTo
        );
        return item;
      });
    }

    // avgHumidityDropping
    if (tmp.avgHumidityDropping && tmp.avgHumidityDropping.length) {
      tmp.avgHumidityDropping = tmp.avgHumidityDropping.map(item => {
        item.timeFrom = this.sharedService.parseDateStringToDateObject(
          item.timeFrom
        );
        item.timeTo = this.sharedService.parseDateStringToDateObject(
          item.timeTo
        );
        return item;
      });
    }

    // avgHumidityRaising
    if (tmp.avgHumidityRaising && tmp.avgHumidityRaising.length) {
      tmp.avgHumidityRaising = tmp.avgHumidityRaising.map(item => {
        item.timeFrom = this.sharedService.parseDateStringToDateObject(
          item.timeFrom
        );
        item.timeTo = this.sharedService.parseDateStringToDateObject(
          item.timeTo
        );
        return item;
      });
    }

    return tmp;
  }

  // parse timeFrom and timeTo from date object to date string
  parseDateString(formData) {
    const tmp = { ...formData };

    // avgTemperatureDropping
    if (tmp.avgTemperatureDropping && tmp.avgTemperatureDropping.length) {
      tmp.avgTemperatureDropping = tmp.avgTemperatureDropping.map(item => {
        item.timeFrom = this.sharedService.parseDateObjectToDateString(
          item.timeFrom
        );
        item.timeTo = this.sharedService.parseDateObjectToDateString(
          item.timeTo
        );
        return item;
      });
    }

    // avgTemperatureRaising
    if (tmp.avgTemperatureRaising && tmp.avgTemperatureRaising.length) {
      tmp.avgTemperatureRaising = tmp.avgTemperatureRaising.map(item => {
        item.timeFrom = this.sharedService.parseDateObjectToDateString(
          item.timeFrom
        );
        item.timeTo = this.sharedService.parseDateObjectToDateString(
          item.timeTo
        );
        return item;
      });
    }

    // avgHumidityDropping
    if (tmp.avgHumidityDropping && tmp.avgHumidityDropping.length) {
      tmp.avgHumidityDropping = tmp.avgHumidityDropping.map(item => {
        item.timeFrom = this.sharedService.parseDateObjectToDateString(
          item.timeFrom
        );
        item.timeTo = this.sharedService.parseDateObjectToDateString(
          item.timeTo
        );
        return item;
      });
    }

    // avgHumidityRaising
    if (tmp.avgHumidityRaising && tmp.avgHumidityRaising.length) {
      tmp.avgHumidityRaising = tmp.avgHumidityRaising.map(item => {
        item.timeFrom = this.sharedService.parseDateObjectToDateString(
          item.timeFrom
        );
        item.timeTo = this.sharedService.parseDateObjectToDateString(
          item.timeTo
        );
        return item;
      });
    }

    return tmp;
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.parseDateString(this.form.value);

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(formData) {
    this.locationProfilesService
      .createLocationProfile(formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update(formData) {
    this.locationProfilesService
      .updateLocationProfile(this.formData.id, formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.locationProfilesService
      .deleteLocationProfile(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify(
      'Location Profile',
      result.message + ' Success!',
      'success'
    );
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
