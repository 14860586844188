<div class="global-operand container">
  <div class="global-operand-body" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-4">
        <label class="input-label" for="custom_global_idx">
          {{ "CCP_CUSTOM_GLOBAL" | translate }}
        </label>

        <p-dropdown
          inputId="custom_global_idx"
          [options]="selectItems?.customGlobals"
          formControlName="custom_global_idx"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_CUSTOM_GLOBAL' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12 p-md-4" *ngIf="isTemplate">
        <label class="input-label" for="name">
          {{ "NAME" | translate }}
        </label>

        <input type="text" pInputText inputId="name" formControlName="name" />
      </div>

      <div class="p-field p-col-12 p-md-4 pt-4" *ngIf="isTemplate">
        <p-checkbox
          inputId="is_source_editable"
          [binary]="true"
          [formControl]="form?.get('is_source_editable')"
          [label]="'EDITABLE' | translate"
        ></p-checkbox>
      </div>
    </div>
  </div>
</div>
