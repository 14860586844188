import { Component, OnInit } from '@angular/core';
// import { FormControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

// import { SelectItem } from 'primeng/api';
// import { RuleTemplate } from '@widgets/rule-templates/models/rule-template';

// import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'apply-rule-form',
  templateUrl: './apply-rule-form.component.html',
  styleUrls: ['./apply-rule-form.component.scss'],
})
export class ApplyRuleFormComponent implements OnInit {
  // ruleTemplateControl: FormControl;
  // selectItems: { [prop: string]: SelectItem[] } = {};
  ruleFormData: any = {};

  // private ruleTemplates: RuleTemplate[] = [];

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef // private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.config && this.config.data) {
      // console.log(this.config);
      //   if (this.config.data.ruleTemplates) {
      //     this.ruleTemplates = this.config.data.ruleTemplates;
      //   }
      if (this.config.data.location) {
        this.ruleFormData.locationId = this.config.data.location.id;
      }
    }

    // this.selectItems.ruleTemplates = this.sharedService.createSelectItems(
    //   this.ruleTemplates
    // );

    // this.ruleTemplateControl = new FormControl();
    // this.ruleTemplateControl.valueChanges.subscribe({
    //   next: (result) => {
    //     console.log(result);
    //   },
    // });
  }

  // onSelectRuleTemplate() {
  //   console.log(this.ruleTemplateControl.value);
  //   const ruleTemplate = this.ruleTemplates.find(
  //     (l) => l.id === this.ruleTemplateControl.value
  //   );
  //   this.dialog.close(ruleTemplate);
  // }

  afterRuleCreated(event) {
    // console.log(event);
    setTimeout(() => {
      this.dialog.close(event);
    });
  }
}
