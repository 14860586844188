<button
  type="submit"
  class="submit-button"
  [ngClass]="buttonClass"
  (click)="submitted.emit()"
  [disabled]="disabled || pending"
>
  <i
    *ngIf="!hideIcon"
    class="fa fa-fw"
    [ngClass]="{ 'fa-refresh fa-spin': pending, 'fa-check': !pending }"
  ></i>

  <span class="submit-button__label">
    {{ (pending ? pendingText : label) | translate }}
  </span>
</button>
