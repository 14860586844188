import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-device-task-parameter-form',
  templateUrl: 'parameter-form.component.html'
})
export class DeviceTaskParameterFormComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input()
  formId: any;
  @Input()
  template: any;

  @Output()
  removed = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  remove() {
    this.removed.emit();
  }
}
