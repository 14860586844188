import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCompanies from '../reducers/companies.reducer';

export const getCompaniesState = createFeatureSelector<fromCompanies.CompanyState>('companies');

export const getCompaniesEntities = createSelector(
  getCompaniesState,
  (state: fromCompanies.CompanyState) => state && state.entities
);

export const getCompanies = createSelector(getCompaniesEntities, (entities) =>
  entities ? Object.keys(entities).map((id) => entities[id]) : []
);

export const getLoaded = createSelector(getCompaniesState, (state: fromCompanies.CompanyState) => state.loaded);

export const getLoading = createSelector(getCompaniesState, (state: fromCompanies.CompanyState) => state.loading);

export const getFormData = createSelector(getCompaniesState, (state: fromCompanies.CompanyState) => state.formData);

export const getFormDataLoading = createSelector(
  getCompaniesState,
  (state: fromCompanies.CompanyState) => state.formDataLoading
);

export const getFormDataError = createSelector(
  getCompaniesState,
  (state: fromCompanies.CompanyState) => state.formDataError
);

export const getCompanyPending = createSelector(
  getCompaniesState,
  (state: fromCompanies.CompanyState) => state.pending
);

export const getCompanyPendingError = createSelector(
  getCompaniesState,
  (state: fromCompanies.CompanyState) => state.pendingError
);
