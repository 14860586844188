<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <div class="location-form__content">
      <!--Header-->
      <div class="ui-g">
        <div class="ui-g-6 ui-g-nopad">
          <sc-text-input
            *ngIf="editMode && !ceosField"
            controlName="id"
            [form]="form"
            label="{{ 'ID' | translate }}"
          ></sc-text-input>

          <sc-text-input
            *ngIf="editMode && ceosField"
            controlName="idx"
            [form]="form"
            label="{{ 'IDX' | translate }}"
          ></sc-text-input>
        </div>

        <div class="ui-g-6 text-right location-active">
          <sc-checkbox-input
            controlName="isActive"
            [form]="form"
            label="{{ 'ACTIVE' | translate }}"
            [showLabel]="true"
          ></sc-checkbox-input>
        </div>
      </div>

      <!-- INFORMATION -->
      <h4 class="m-0">
        {{ "INFORMATION" | translate }}
      </h4>

      <div class="ui-g mb-4 location-information">
        <!-- LEFT COLUMN -->
        <div class="ui-g-6">
          <!-- LOCATION_TYPE_ID -->
          <div class="ui-g-12 ui-g-nopad">
            <sc-dropdown
              controlName="locationTypeId"
              [form]="form"
              label="{{ 'LOCATION_TYPE' | translate }}"
              [options]="selectItems?.locationTypes"
            ></sc-dropdown>
          </div>

          <!-- PARENT_ID -->
          <div class="ui-g-12 ui-g-nopad">
            <sc-dropdown
              controlName="parentId"
              [form]="form"
              label="{{ 'PARENT' | translate }}"
              [options]="selectItems?.locations"
            ></sc-dropdown>
          </div>

          <!-- DESCRIPTION -->
          <div class="ui-g-12 ui-g-nopad">
            <sc-text-input
              controlName="description"
              [form]="form"
              label="{{ 'DESCRIPTION' | translate }}"
            ></sc-text-input>
          </div>
        </div>

        <!-- RIGHT COLUMN -->
        <div class="ui-g-6">
          <!-- LOCATION_TYPE_NAME -->
          <!-- <div class="ui-g-12 ui-g-nopad" *ngIf="ceosField">
      <sc-text-input
        controlName="locationTypeName"
        [form]="form"
        label="{{ 'CCP_LOCATION_TYPE_NAME' | translate }}"
      ></sc-text-input>
    </div> -->

          <!-- MAX_PEOPLE -->
          <div class="ui-g-12 ui-g-nopad" *ngIf="ceosField">
            <sc-number-input
              controlName="maxPeople"
              [form]="form"
              label="{{ 'MAX_PEOPLE' | translate }}"
            ></sc-number-input>
          </div>

          <!-- FLOORPLAN IMAGE -->
          <div
            class="ui-g-12 ui-g-nopad"
            *ngIf="!ceosField || (ceosField && isFloor)"
          >
            <sc-local-file-input
              controlName="floorplanImage"
              [form]="form"
              label="{{ 'LOCATION_FLOORPLAN_IMAGE' | translate }}"
            ></sc-local-file-input>
          </div>

          <!-- FLOORPLAN SCALE -->
          <div
            class="ui-g-12 ui-g-nopad"
            *ngIf="!ceosField || (ceosField && isFloor)"
          >
            <sc-number-input
              controlName="floorplanScale"
              [form]="form"
              label="{{ 'LOCATION_FLOORPLAN_SCALE' | translate }}"
              [min]="0"
            ></sc-number-input>
          </div>

          <!-- CONDITION -->
          <div class="ui-g-12 ui-g-nopad" *ngIf="!ceosField">
            <sc-number-input
              controlName="locationCondition"
              [form]="form"
              label="{{ 'CONDITION' | translate }}"
            ></sc-number-input>
          </div>
        </div>
      </div>

      <!-- OCCUPANCY -->
      <h4 class="m-0">
        {{ "OCCUPANCY" | translate }}
      </h4>

      <div class="ui-g mb-4">
        <!-- TRACK_OCCUPANCY -->
        <div class="ui-g-12">
          <sc-checkbox-input
            controlName="trackOccupancy"
            [form]="form"
            label="{{ 'TRACK_OCCUPANCY' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- OCCUPANCY_DELAY -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="occupancyDelay"
            [form]="form"
            label="{{ 'OCCUPANCY_DELAY' | translate }}"
            [min]="0"
          ></sc-number-input>
        </div>

        <!-- OCCUPANCY_TIMEOUT -->
        <div class="ui-g-6">
          <sc-number-input
            controlName="occupancyTimeout"
            [form]="form"
            label="{{ 'OCCUPANCY_TIMEOUT' | translate }}"
            [min]="0"
          ></sc-number-input>
        </div>
      </div>

      <!-- AC VALVE ALERT -->
      <ng-container *ngIf="!ceosField">
        <h4 class="m-0">
          {{ "AC_VALVE_ALERT" | translate }}
        </h4>

        <div class="ui-g mb-4">
          <!-- AC_VALVE_ALERT_DIFF_POINT -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="acValveAlertDiffPoint"
              [form]="form"
              label="{{ 'AC_VALVE_ALERT_DIFF_POINT' | translate }}"
              [min]="0"
              description="{{
                'AC_VALVE_ALERT_DIFF_POINT_LEAVE_BLANK_TEXT' | translate
              }}"
            ></sc-number-input>
          </div>

          <!-- AC_VALVE_ALERT_TIMEOUT -->
          <div class="ui-g-6">
            <sc-number-input
              controlName="acValveAlertTimeout"
              [form]="form"
              label="{{ 'AC_VALVE_ALERT_TIMEOUT' | translate }}"
              [min]="0"
              description="{{
                'AC_VALVE_ALERT_TIMEOUT_LEAVE_BLANK_TEXT' | translate
              }}"
              suffix="{{ 'MINUTES' | translate }}"
            ></sc-number-input>
          </div>
        </div>

        <!-- LOCATION STATUS -->
        <h4 class="m-0" *ngIf="editMode">
          {{ "STATUS" | translate }}
        </h4>

        <div class="ui-g mb-4" *ngIf="editMode">
          <!-- IS_OUT_OF_ORDER -->
          <div class="ui-g-6">
            <sc-checkbox-input
              controlName="isOutOfOrder"
              [form]="form"
              label="{{ 'IS_OUT_OF_ORDER' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- IS_DO_NOT_DISTURB_MODE_ACTIVE -->
          <div class="ui-g-6">
            <sc-checkbox-input
              controlName="isDoNotDisturbModeActive"
              [form]="form"
              label="{{ 'IS_DO_NOT_DISTURB_MODE_ACTIVE' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- IS_MAKE_UP_ROOM_REQUESTED -->
          <div class="ui-g-6">
            <sc-checkbox-input
              controlName="isMakeUpMyRoomRequested"
              [form]="form"
              label="{{ 'IS_MAKE_UP_ROOM_REQUESTED' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- IS_BOOKED_BUT_NO_CHECK_IN_DONE -->
          <div class="ui-g-6">
            <sc-checkbox-input
              controlName="isBookedButNoCheckInDone"
              [form]="form"
              label="{{ 'IS_BOOKED_BUT_NO_CHECK_IN_DONE' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- IS_BOOKED_AND_CHECK_IN_DONE -->
          <div class="ui-g-6">
            <sc-checkbox-input
              controlName="isBookedAndCheckInDone"
              [form]="form"
              label="{{ 'IS_BOOKED_AND_CHECK_IN_DONE' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- IS_CHECK_IN_IN_PROGRESS -->
          <div class="ui-g-6">
            <sc-checkbox-input
              controlName="isCheckInInProgress"
              [form]="form"
              label="{{ 'IS_CHECK_IN_IN_PROGRESS' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- IS_CHECK_OUT_IN_PROGRESS -->
          <div class="ui-g-6">
            <sc-checkbox-input
              controlName="isCheckOutInProgress"
              [form]="form"
              label="{{ 'IS_CHECK_OUT_IN_PROGRESS' | translate }}"
            ></sc-checkbox-input>
          </div>

          <!-- DISABLE_CALCULATION -->
          <div class="ui-g-6">
            <sc-checkbox-input
              controlName="disableCalculation"
              [form]="form"
              label="{{ 'DISABLE_CALCULATION' | translate }}"
            ></sc-checkbox-input>
          </div>
        </div>
      </ng-container>

      <!-- Error Message -->
      <div class="ui-g">
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage | translate"></sc-form-error>
        </div>
      </div>
    </div>
    <div class="location-form__footer">
      <!--Footer-->
      <div class="ui-g">
        <div class="ui-g-6">
          <sc-confirm-trigger
            *ngIf="editMode && !formData?.isDeleted"
            buttonIcon="fa fa-trash"
            buttonClass="ui-button-danger"
            buttonLabel="{{ 'DELETE' | translate }}"
            (onAccept)="delete()"
          ></sc-confirm-trigger>
        </div>

        <div class="ui-g-6 text-right location-form__footer-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            (click)="dismissModal('cancel')"
            label="{{ 'CANCEL' | translate }}"
          ></button>

          <sc-submit-button
            *ngIf="!formData?.isDeleted || formData?.isActive"
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
