import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FormGroup } from '@angular/forms';
import { CustomFunctionParameterTypes } from '../enums/custom-function-parameter-type';
import { CustomFunctionFormService } from '../services/custom-function-form.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-function-parameter-form',
  templateUrl: './function-parameter-form.component.html',
  styleUrls: ['./function-parameter-form.component.scss'],
})
export class FunctionParameterFormComponent implements OnInit, OnDestroy {
  @Input()
  form: FormGroup;

  @Input()
  index: number;

  @Input()
  dataSources: any = {};

  @Input()
  selectItems: any = {};

  @Output()
  removed = new EventEmitter<void>();

  _customFunctionParameterTypes = CustomFunctionParameterTypes;
  deviceSelectItems: SelectItem[] = [];
  isChangingType = false;

  private subscribers: any = {};

  constructor(private customFunctionFormService: CustomFunctionFormService, private sharedService: SharedService) {}

  ngOnInit(): void {
    if (this.selectItems && this.selectItems.devices) {
      this.deviceSelectItems = this.selectItems.devices;
    }

    this.subscribers.parameterType = this.form.get('type').valueChanges.subscribe((value) => {
      this.customFunctionFormService.setCustomFunctionParameterValidator(this.form, value);

      if (value === CustomFunctionParameterTypes.LatestDhlValue) {
        this.subscribeDeviceType();
      } else {
        this.unsubscribeDeviceType();
      }
    });
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private subscribeDeviceType() {
    const deviceTypeControl = this.form.get('device_type');
    if (deviceTypeControl && deviceTypeControl.valueChanges) {
      this.subscribers.deviceType = deviceTypeControl.valueChanges.subscribe((value) => {
        if (value && this.dataSources && this.dataSources.devices) {
          this.deviceSelectItems = this.dataSources.devices
            .filter((item) => item.device_type === value)
            .map((item) => ({ label: item.name, value: item.idx }));
        } else {
          this.deviceSelectItems = [];
        }
      });
    }
  }

  private unsubscribeDeviceType() {
    if (this.subscribers.deviceType) {
      this.subscribers.deviceType.unsubscribe();
      this.subscribers.deviceType = null;
    }
  }
}
