import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { TopFiveHottestRoomsComponent } from './top-five-hottest-rooms.component';

@NgModule({
  imports: [SharedModule],
  declarations: [TopFiveHottestRoomsComponent],
  entryComponents: [TopFiveHottestRoomsComponent]
})
export class TopFiveHottestRoomsModule {}
