import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Site } from '../models/site';
import { Location } from '@widgets/locations/models/location';
import { config } from '@app/config';

@Injectable()
export class SitesService {
  private apiUrl = config().apiUrl + '/sites';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): Site {
    const newData = { ...data };
    delete newData.id;
    delete newData.companyId;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getSite(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`).pipe(catchError((error: any) => observableThrowError(error)));
  }

  getSites(companyId?: number) {
    const queryParams = companyId ? `?companyId=${companyId}` : '';
    return this.httpClient
      .get(`${this.apiUrl}${queryParams}`)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  createSite(data: Site /*, floors?: Location[]*/) {
    const site: any = { ...data };
    // if (floors && floors.length) {
    //   site.floors = floors;
    // }
    return this.httpClient.post(this.apiUrl, site).pipe(catchError((error: any) => observableThrowError(error)));
  }

  updateSite(data: Site) {
    return this.httpClient
      .patch(`${this.apiUrl}/${data.id}`, this.verifyData(data))
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  deleteSite(data: Site) {
    return this.httpClient
      .patch(`${this.apiUrl}/${data.id}`, { isDeleted: true, isActive: false })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
