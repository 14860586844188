import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { NotificationTemplate } from '../models/notification-template';
import { ModalConfig } from '@sc/modal/modal-config';

import { NotificationTemplatesService } from '../services/notification-templates.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { NotificationTemplateActionCellComponent } from '../notification-template-action-cell/notification-template-action-cell.component';

@Component({
  selector: 'sc-notification-templates-table',
  templateUrl: './notification-templates-table.component.html'
})
export class NotificationTemplatesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: NotificationTemplate[];
  subscribers: { [key: string]: any };
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(
    private notificationTemplatesService: NotificationTemplatesService
  ) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'placeholders',
        headerName: 'PLACEHOLDERS',
        field: 'placeholders'
      },
      {
        colId: 'priority',
        headerName: 'PRIORITY',
        field: 'priority'
      },
      {
        colId: 'isActiveSendSms',
        headerName: 'ACTIVE_SEND_SMS',
        field: 'isActiveSendSms',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isActiveSendEmails',
        headerName: 'ACTIVE_SEND_EMAILS',
        field: 'isActiveSendEmails',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: NotificationTemplateActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'NotificationTemplateForm',
      options: { modalTitle: 'NOTIFICATION_TEMPLATE_FORM_TITLE', fullScreen: true }
    };
    this.tableTitle = 'NOTIFICATION_TEMPLATES';
    this.subscribers = {};

    this.fetchDataset();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.subscribers.GET_DATASET = this.notificationTemplatesService
      .get()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
