import { Component, OnInit, Input } from '@angular/core';

import { Company } from '../models/company';
import { ModalConfig } from '@sc/modal/modal-config';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromStore from '@app/store';

import { CompanyActionCellComponent } from '../company-action-cell/company-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-companies-table',
  templateUrl: 'companies-table.component.html',
})
export class CompaniesTableComponent implements OnInit {
  @Input()
  config: any;

  columns: any[];
  dataset$: Observable<Company[]>;
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        width: 100,
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        width: 100,
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        width: 150,
        cellRendererFramework: CompanyActionCellComponent,
        suppressSorting: true,
        suppressFilter: true,
        pinned: 'right',
      },
    ];
    this.formConfig = {
      name: 'CompanyForm',
      options: { modalTitle: 'COMPANY_FORM_TITLE' },
    };
    this.tableTitle = 'COMPANIES';
    this.dataset$ = this.store.select(fromStore.getCompanies);

    this.fetchDataset();
  }

  fetchDataset() {
    this.store.dispatch(new fromStore.LoadCompanies());
  }
}
