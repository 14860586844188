import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Scene } from '../models/scene';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class ScenesService {
  private apiUrl = config().apiUrl + '/scenes';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  private verifyData(data: any): Scene {
    const newData = { ...data };

    delete newData.id;
    delete newData.sceneTemplateId;
    delete newData.locationId;
    delete newData.siteId;
    delete newData.companyId;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getScene(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getScenes(options: { [key: string]: any }) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(this.apiUrl + queryParams);
  }

  createScene(data: Scene) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateScene(id: number, data: Scene) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteScene(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isDeleted: true,
      isActive: false
    });
  }
}
