import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-room-unoccupancy-avg',
  templateUrl: './room-unoccupancy-avg.component.html',
})
export class RoomUnoccupancyAvgComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: { [key: string]: any };
  fetchInterval: any;
  isFetching: boolean;
  isFirstTime: boolean;
  widgetData: { [key: string]: any }[];
  widgetName: string = '';

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.getSelectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFetching = false;
        this.isFirstTime = true;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private setWidgetData() {
    const data = this.dataset;
    const avgLastSeven = this.sharedService.numberFormat(data.last7Days.avg, 1);
    const avgLastSevenPercent = this.sharedService.numberFormat(data.last7Days.avgPercent, 1);
    const avgLastMonth = this.sharedService.numberFormat(data.last30Days.avg, 1);
    const avgLastMonthPercent = this.sharedService.numberFormat(data.last30Days.avgPercent, 1);

    this.widgetData = [
      {
        label: 'LAST_X_DAYS',
        labelParams: { value: 7 },
        primaryText: avgLastSeven,
        secondaryText: 'hrs',
        customClass: 'orange lighten-1',
      },
      {
        label: 'LAST_X_DAYS',
        labelParams: { value: 7 },
        primaryText: avgLastSevenPercent,
        secondaryText: '%',
        customClass: 'orange lighten-1',
      },
      {
        label: 'LAST_X_DAYS',
        labelParams: { value: 30 },
        primaryText: avgLastMonth,
        secondaryText: 'hrs',
        customClass: 'orange lighten-1',
      },
      {
        label: 'LAST_X_DAYS',
        labelParams: { value: 30 },
        primaryText: avgLastMonthPercent,
        secondaryText: '%',
        customClass: 'orange lighten-1',
      },
    ];
  }

  fetchDataset() {
    if (this.isFetching || !this.selectedSite || !this.selectedSite.id) {
      return;
    }

    this.isFetching = true;
    this.subscribers.fetchDataset = this.widgetService.getRoomUnoccupancyAvg(this.selectedSite.id).subscribe(
      (result: any) => {
        this.dataset = result.data;
        this.setWidgetData();
        this.isFetching = false;
        this.isFirstTime = false;
      },
      (error: any) => {
        this.isFetching = false;
      }
    );
  }
}
