<div class="device-operand container">
  <div class="device-operand-body" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm" *ngIf="!isTemplate">
      <div class="p-field p-col-12 p-md-12">
        <label class="input-label" for="device">
          {{ "CCP_DEVICE" | translate }}
        </label>

        <sc-tree-select
          [form]="form"
          [field]="valueFormControlName"
          [options]="devices"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_DEVICE' | translate
          }}"
        ></sc-tree-select>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid p-inputtext-sm" *ngIf="isTemplate">
      <div class="p-field p-col-12 p-md-12">
        <label class="input-label" for="device_type">
          {{ "CCP_DEVICE_TYPE" | translate }}
        </label>
        <p-dropdown
          inputId="device_type"
          [options]="selectItems?.deviceTypes"
          [formControlName]="deviceTypeFormControlName"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_DEVICE_TYPE' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label class="input-label" for="device_type">
          {{ "EDITABLE" | translate }}
        </label>
        <p-checkbox
          inputId="is_source_editable"
          [binary]="true"
          [formControl]="form?.get('is_source_editable')"
          [label]="'YES' | translate"
        ></p-checkbox>
      </div>
      <div
        class="p-field p-col-12 p-md-12"
        *ngIf="form?.get('is_source_editable').value == true"
      >
        <label class="input-label" for="name">
          {{ "NAME" | translate }}
        </label>
        <input type="text" pInputText inputId="name" formControlName="name" />
      </div>
    </div>
  </div>

  <sc-modifiers
    *ngIf="viewMode === _ruleEditorModes.Expert && modifiers"
    [dataSources]="dataSources"
    [form]="form"
    [modifiers]="modifiers"
    [isTemplate]="isTemplate"
    [selectItems]="selectItems"
  ></sc-modifiers>
</div>
