<div [formGroup]="group">
  <div class="ui-g-12">
    <!-- ELEMENT -->
    <div class="ui-g-4">
      <strong class="grey-text">{{ advancedData.name }}</strong>
    </div>
    <!-- STYLES -->
    <div class="ui-g-8">
      <div formArrayName="styles">
        <div *ngFor="let styleCtrl of styles.controls; let i=index" [formGroupName]="i">
          <div class="ui-g">
            <div class="ui-g-6 text-right">
              <span class="blue-text">{{ styleCtrl.value.property }}</span>
            </div>
            <div class="ui-g-6">
              <sc-dropdown
                controlName="value"
                [form]="styleCtrl"
                label="{{ 'VALUE' | translate }}"
                [showLabel]="false"
                [options]="variables"
                [editable]="true"
              ></sc-dropdown>
            </div>
          </div>
        </div>
      </div>

     <!-- STYLES DISABLE -->
        <div *ngFor="let styleDisables of disables.styles">
          <div class="ui-g">
            <div class="ui-g-6 text-right">
              <span class="red-text">({{ 'DELETED' | translate }})</span> <span class="grey-text"> {{ styleDisables.property }}</span>
            </div>
            <div class="ui-g-6">
                {{ styleDisables.value }}
            </div>
          </div>
        </div>

    </div>


  </div>
</div>
