import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceModelInstruction, DeviceModelInstructionText } from '../models/device-model-instruction';
import { DeviceModelProperty } from '../models/device-model-property';
import { Locale } from '@widgets/locales/models/locale';

@Injectable()
export class DeviceModelFormService {
  constructor(private formBuilder: FormBuilder) {}

  initDeviceModelForm(locales: Locale[]): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      idx: { value: null, disabled: true },
      unique_id: [null, Validators.required],
      product_name: [null, Validators.required],
      display_name: [null, Validators.required],
      brand: [null, Validators.required],
      protocol: null,
      frequency: null,
      library: [null, Validators.required],
      instructions: this.initInstructionsForm(locales),
      modbus_settings: this.initModbusSettingsForm(),
      properties: this.formBuilder.array([]),
      is_automation: false,
      is_active: false,
      is_deleted: false,
    });
  }

  initModbusSettingsForm(): FormGroup {
    return this.formBuilder.group({
      word_order: ['be'],
      byte_order: ['be'],
      timeout_ms: [1000],
    });
  }

  initInstructionsForm(locales: Locale[]): FormGroup {
    return this.formBuilder.group({
      url: null,
      locales: this.initLocaleFormArray(locales),
    });
  }

  initPropertyForm(): FormGroup {
    return this.formBuilder.group({
      type: [null, Validators.required], // string => "acm"
      display_name: [null, Validators.required], // string => "Mode"
      unique_id: null, // string => "64-1-0"
      min_diff_threshold: null, // string => "10%"
      values: this.formBuilder.array([]),
      config: this.formBuilder.array([]),
      modbus: this.initPropertyModbusForm(),
    });
  }

  initPropertyModbusForm(): FormGroup {
    return this.formBuilder.group({
      is_readable: [true],
      read_function: [null],
      read_address: [null],
      read_modifier: [null],
      read_data_type: ['number'],
      read_decimals: [null],
      read_position: [null],
      read_count: [null],
      is_writeable: [false],
      write_function: [null],
      write_address: [null],
      write_modifier: [null],
      write_data_type: [null],
      write_decimals: [null],
      is_polling_enabled: [true],
      polling_pause_seconds: [1],
    });
  }

  initPropertyValueForm(protocol: string = 'zwave', library: string = 'ozw'): FormGroup {
    return this.formBuilder.group({
      mapped: [null, Validators.required], // string => "o"
      raw_read: [null, Validators.required], // string => "Off"
      raw_write: null, // string => "Off"
      raw_read_type: [null, Validators.required],
      raw_write_type: null,
      library: [protocol === 'modbus' ? 'm2m' : library, Validators.required], // string => "ozw"
    });
  }

  initPropertyConfigForm(): FormGroup {
    return this.formBuilder.group({
      description: [null, Validators.required], // string => "Whatever"
      unique_id: [null, Validators.required], // string => "23-1-0"
      type: ['int', Validators.required], // string => "int"
      value: [null, Validators.required], // string => "1"
    });
  }

  patchPropertiesForm(form: FormGroup, data: DeviceModelProperty[]) {
    const properties = form.get('properties') as FormArray;
    properties.clear();
    if (data && data.length) {
      for (const item of data) {
        const prop = this.initPropertyForm();
        const config = prop.get('config') as FormArray;
        const values = prop.get('values') as FormArray;
        prop.patchValue(item);

        if (item.config && item.config.length) {
          for (const conf of item.config) {
            const cf = this.initPropertyConfigForm();
            cf.patchValue(conf);
            config.push(cf);
          }
        }

        if (item.values && item.values.length) {
          for (const val of item.values) {
            const vf = this.initPropertyValueForm(form.get('protocol').value, form.get('library').value);
            vf.patchValue({
              mapped: val.mapped,
              raw_read: val.raw.read,
              raw_read_type: val.raw.read_type,
              raw_write: val.raw.write,
              raw_write_type: val.raw.write_type,
            });
            values.push(vf);
          }
        }
        properties.push(prop);
      }
    }
  }

  initLocaleFormGroup(locale: string) {
    return this.formBuilder.group({
      locale: [locale, Validators.required],
      full: null,
      inclusion: null,
      exclusion: null,
    });
  }

  initLocaleFormArray(locales: Locale[]) {
    const formGroups = [];
    formGroups.push(this.initLocaleFormGroup('en'));
    for (const locale of locales) {
      if (locale.isActive && !locale.isDeleted && locale.id !== 'en') {
        formGroups.push(this.initLocaleFormGroup(locale.id));
      }
    }
    return this.formBuilder.array(formGroups);
  }

  /**
   *
   * @param form instructions form
   * @param data instructions
   */
  patchLocaleFormArray(form: FormGroup, data: DeviceModelInstruction) {
    const controls = (form.get('locales') as FormArray).controls;
    const full = data.full as DeviceModelInstructionText[];
    const inclusion = data.inclusion as DeviceModelInstructionText[];
    const exclusion = data.exclusion as DeviceModelInstructionText[];

    for (const ctrl of controls) {
      const locale = ctrl.value && ctrl.value.locale;
      const localeFull = full && full.find((item) => item.language === locale);
      const localeInclusion = inclusion && inclusion.find((item) => item.language === locale);
      const localeExclusion = exclusion && exclusion.find((item) => item.language === locale);

      if (localeFull) {
        ctrl.patchValue({ full: localeFull.text });
      }
      if (localeInclusion) {
        ctrl.patchValue({ inclusion: localeInclusion.text });
      }
      if (localeExclusion) {
        ctrl.patchValue({ exclusion: localeExclusion.text });
      }
    }
  }
}
