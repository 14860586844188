import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { SelectItem, TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SharedService } from '@shared/shared.service';
import { DeviceFormService, EXTENDERS, PROTOCOLS } from '../../services/device-form.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { DeviceModelService } from '@widgets/device-models-2/services/device-model.service';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { DeviceModel } from '@widgets/device-models-2/models/device-model';
import { DeviceBrandsService } from '@widgets/device-brands/services/device-brands.service';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { LocationDetails } from '@widgets/locations/models/location-details';
import { Site } from '@widgets/sites/models/site';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceBrand } from '@widgets/device-brands/models/device-brand';
import { DeviceService as IntegratorDeviceService } from '../../services/device.service';
import { config } from '@app/config';
import { CeosDevice } from '@app/widgets/devices/models/ceos-device';
import { DeviceType as DeviceTypeEnum, LocationType as LocationTypeEnum } from '../../enums';

@Component({
  selector: 'sc-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss'],
})
export class DeviceFormComponent implements OnInit, OnDestroy {
  _DeviceType = DeviceTypeEnum;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  propertiesForm: FormArray;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] | TreeNode[] } = {};
  step = 1;
  deviceFormStep = 1;
  deviceFormTotalStep = 3;
  properties: any[] = [];
  summary: any = {};
  selectedDeviceType: string;
  editMode: boolean = false;
  device: CeosDevice;
  siblings: any = {};
  isModbus: boolean = false;
  nodeIdLabel: string = '';

  operatingModeOptions = [
    { label: this.sharedService.getTranslation('AUTOMATION'), value: 'automation' },
    { label: this.sharedService.getTranslation('GATEWAY'), value: 'gateway' },
  ];

  modbusMethods = [{ label: 'rtu', value: 'rtu' }];

  modbusBaudrates = [
    { label: '1200', value: '1200' },
    { label: '2400', value: '2400' },
    { label: '4800', value: '4800' },
    { label: '9600', value: '9600' },
    { label: '19200', value: '19200' },
    { label: '38400', value: '38400' },
    { label: '57600', value: '57600' },
    { label: '115200', value: '115200' },
  ];

  modbusParities = [
    { label: 'None', value: 'N' },
    { label: 'Even', value: 'E' },
    { label: 'Odd', value: 'O' },
  ];

  private isAutomation: boolean = false;
  private devices: DeviceDetails[] = [];
  private deviceModels: DeviceModel[] = [];
  private deviceTypes: DeviceType[] = [];
  private deviceBrands: DeviceBrand[] = [];
  private deviceProtocols: DeviceProtocol[] = [];
  private locations: LocationDetails[] = [];
  private scaffold: any;
  private preSelectedAutomation: CeosDevice;
  private preSelectedLocation: LocationDetails;
  private preSelectedModel: DeviceModel;
  private selectedModel: DeviceModel;
  private selectedBrand: DeviceBrand;
  private selectedLocation: LocationDetails;
  private selectedProtocol: DeviceProtocol;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private deviceFormService: DeviceFormService,
    private deviceModelService: DeviceModelService,
    private deviceTypesService: DeviceTypesService,
    private deviceBrandsService: DeviceBrandsService,
    private deviceProtocolsService: DeviceProtocolsService,
    private devicesService: DevicesService,
    private integratorDeviceService: IntegratorDeviceService,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit(): void {
    const data = this.config && this.config.data;

    if (data.device) {
      this.device = this.config.data.device;
      this.editMode = true;
      if (this.device.deviceTypeKey === DeviceTypeEnum.Automation) {
        this.onSelectDeviceType(DeviceTypeEnum.Automation);
      } else {
        this.onSelectDeviceType(DeviceTypeEnum.Device);
      }
      if (this.device.protocolKey === 'modbus') {
        this.nodeIdLabel = this.sharedService.getTranslation('SLAVE_ID');
        this.isModbus = true;
      } else {
        this.nodeIdLabel = this.sharedService.getTranslation('NODE_ID');
        this.isModbus = false;
      }
    }

    if (data.selectedAutomation) {
      this.preSelectedAutomation = data.selectedAutomation;
    }

    if (data.selectedLocation) {
      this.preSelectedLocation = data.selectedLocation;
    }

    if (data.selectedModel && !this.editMode) {
      this.preSelectedModel = data.selectedModel;
      // force chosing type to device
      if (this.preSelectedModel.is_automation) {
        this.onSelectDeviceType(DeviceTypeEnum.Automation);
      } else {
        this.onSelectDeviceType(DeviceTypeEnum.Device);
      }
    }

    this.subscribers.requiredData = this.store
      .pipe(
        select(fromStore.getSelectedSite),
        filter((site) => !!site),
        take(1)
      )
      .subscribe((site) => {
        this.selectedSite = site;
        this.fetchDevices();
        this.fetchLocations();
      });

    this.fetchDeviceBrands();
    this.fetchDeviceProtocols();
    this.fetchDeviceModels();
    this.fetchDeviceTypes();
    this.fetchCeosScaffold();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal() {
    this.dialog.close();
  }

  private fetchDeviceModels() {
    this.fetchingState++;
    this.subscribers.fetchDevices = this.deviceModelService.getDeviceModels().subscribe((result: any) => {
      this.deviceModels = result.data.filter((item) => item.is_active && !item.is_deleted);
      this.updateDeviceModelSelectItems();

      if (!this.editMode && this.preSelectedModel) {
        // update selected model in case of slow network
        this.selectedModel = this.deviceModels.find((item) => item.id === this.preSelectedModel.id);

        // set device name
        if (this.isAutomation) {
          this.updateAutomationName();
        } else {
          this.updateDeviceName();
        }
      }

      this.fetchingState--;
    });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data.filter((item) => item.isActive && !item.isDeleted);
      this.prepareSiblings();
      this.fetchingState--;
    });
  }

  private fetchDeviceBrands() {
    this.fetchingState++;
    this.subscribers.fetchDeviceBrands = this.deviceBrandsService.getDeviceBrands().subscribe((result: any) => {
      this.deviceBrands = result.data.filter((item) => item.isActive && !item.isDeleted);
      this.fetchingState--;
    });
  }

  private fetchDeviceProtocols() {
    this.fetchingState++;
    this.subscribers.fetchDeviceProtocols = this.deviceProtocolsService
      .getDeviceProtocols()
      .subscribe((result: any) => {
        this.deviceProtocols = result.data.filter((item) => item.isActive && !item.isDeleted);
        this.updateDeviceModelSelectItems();
        this.fetchingState--;
      });
  }

  private fetchDevices() {
    if (!this.selectedSite) {
      return;
    }
    this.fetchingState++;
    const options = { siteId: this.selectedSite.id };
    this.subscribers.fetchDevices = this.devicesService.getDevices(options).subscribe((result: any) => {
      this.devices = result.data.filter((item) => !item.isDeleted);

      // set automation select items
      this.selectItems.automations = this.sharedService.createSelectItems(
        this.devices.filter((item) => item.deviceTypeKey === DeviceTypeEnum.Automation)
      );

      this.fetchingState--;
    });
  }

  private fetchLocations() {
    if (!this.selectedSite) {
      return;
    }
    this.fetchingState++;
    const options = { siteId: this.selectedSite.id };
    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
      this.locations = result.data.filter((item) => !item.isDeleted);

      // set floor select items
      this.selectItems.floors = this.sharedService.createSelectItems(
        this.locations.filter((item) => item.locationTypeKey === LocationTypeEnum.Floor),
        false,
        'floorNumber'
      );

      // set location select items
      if (this.editMode && this.device) {
        this.updateFloorLocationSelectItems(this.device.locationFloorNumber);
      } else if (!this.editMode && this.preSelectedLocation) {
        // update selected location in case of slow network
        this.selectedLocation = this.locations.find((item) => item.id === this.preSelectedLocation.id);

        this.updateFloorLocationSelectItems(this.preSelectedLocation.floorNumber);

        // set device name
        if (this.isAutomation) {
          this.updateAutomationName();
        }
      }

      this.fetchingState--;
    });
  }

  private fetchCeosScaffold() {
    this.fetchingState++;
    this.subscribers.fetchCeosScaffold = this.sharedService.getCeosScaffold().subscribe({
      next: (result: any) => {
        this.scaffold = result.data;
        this.prepareSiblings();
        this.fetchingState--;
      },
    });
  }

  private getDeviceModelLabel(model: DeviceModel) {
    let label = model.product_name;

    if (this.selectedDeviceType === DeviceTypeEnum.Device) {
      const frequencies = config().selectItems.zwaveFrequencies;
      const frequency = frequencies.find((item) => item.value === model.frequency);
      const protocol = this.deviceProtocols.find(
        (item) => item.isActive && !item.isDeleted && item.key === model.protocol
      );

      if (protocol) {
        label += `[${protocol.name}${frequency ? '' : ']'}`;
      }
      if (frequency) {
        label += `${protocol ? ',' : ''} ${frequency.label}]`;
      }
    }

    return label;
  }

  private updateDeviceModelSelectItems() {
    if (!this.deviceProtocols) {
      return;
    }

    let deviceModels = this.deviceModels.filter((deviceModel) => !!deviceModel.is_automation === this.isAutomation);

    deviceModels = this.deviceModelService.filterDeviceModelsByCeosVersion(this.automationVersion, deviceModels);

    if (!this.isModbusActiveOnAutomation) {
      deviceModels = deviceModels.filter((deviceModel) => deviceModel.protocol !== 'modbus');
    }

    if (deviceModels) {
      this.selectItems.deviceModels = deviceModels
        .map((item) => ({
          label: this.getDeviceModelLabel(item),
          value: item.id,
        }))
        //Sort by name
        .sort((x, y) => {
          if (x.label < y.label) {
            return -1;
          }
          if (x.label > y.label) {
            return 1;
          }
          return 0;
        });
    }
  }

  private initForm() {
    this.form = this.deviceFormService.initDeviceWizardForm(this.isAutomation);
    this.propertiesForm = this.form.get('properties') as FormArray;

    if (this.editMode) {
      this.form.patchValue({
        floorNumber: this.device.locationFloorNumber,
        locationIdx: this.device.locationIdx,
        automationId: this.device.automationId,
        deviceModelId: this.device.deviceModelId,
        name: this.device.description,
        uuid: this.device.uuid,
      });

      if (this.isAutomation) {
        this.form.patchValue({
          expectedOnline: this.device.expectedOnline,
          maxSeenMinutesAgo: this.device.maxSeenMinutesAgo,
          operatingMode: this.device.operatingMode || 'automation',
          gatewaySettings: this.device.gatewaySettings ? JSON.parse(this.device.gatewaySettings) || {} : {},
          modbusActive: this.device.modbusActive,
          modbusSettings: this.device.modbusSettings ? JSON.parse(this.device.modbusSettings) || {} : {},
        });
      }

      this.form.get('floorNumber').disable();
      this.form.get('automationId').disable();
      this.form.get('deviceModelId').disable();
      this.form.get('locationIdx').disable();
    }
    this.subscribers.floorNumber = this.form.get('floorNumber').valueChanges.subscribe((value) => {
      this.form.get('locationIdx').reset();
      this.selectItems.floorLocations = [];

      if (typeof value !== 'undefined' && value !== null && this.locations && this.locations.length) {
        this.updateFloorLocationSelectItems(value);
      }
    });

    this.subscribers.locationIdx = this.form.get('locationIdx').valueChanges.subscribe((value) => {
      if (value && this.locations && this.locations.length) {
        this.selectedLocation = this.locations.find((item) => item.idx === value);
        if (this.isAutomation) {
          this.updateAutomationName();
        }
      }
    });

    this.subscribers.deviceModelId = this.form.get('deviceModelId').valueChanges.subscribe((value) => {
      if (value && this.deviceModels && this.deviceModels.length) {
        this.selectedModel = this.deviceModels.find((item) => item.id === value);
        if (this.isAutomation) {
          this.updateAutomationName();
        } else {
          this.updateDeviceName();
        }
      }
    });

    // patch automation details
    if (!this.editMode) {
      if (!this.isAutomation) {
        if (this.preSelectedLocation) {
          this.form.get('floorNumber').setValue(this.preSelectedLocation.floorNumber);
          this.form.get('floorNumber').disable();
          this.form.get('locationIdx').setValue(this.preSelectedLocation.idx);
          this.form.get('locationIdx').disable();
        } else if (this.preSelectedAutomation) {
          this.form.get('floorNumber').setValue(this.preSelectedAutomation.locationFloorNumber);
          this.form.get('floorNumber').disable();
          this.form.get('locationIdx').setValue(this.preSelectedAutomation.locationIdx);
          this.form.get('locationIdx').disable();
          this.form.get('automationId').setValue(this.preSelectedAutomation.id);
          this.form.get('automationId').disable();
        }
      }

      if (this.preSelectedModel) {
        this.form.get('deviceModelId').setValue(this.preSelectedModel.id);
        this.form.get('deviceModelId').disable();
      }
    }
  }

  private getDeviceDetails() {
    const formValue = this.form.getRawValue();
    this.selectedLocation = this.locations.find((item) => item.idx === formValue.locationIdx);
    this.selectedModel = this.deviceModels.find((item) => item.id === formValue.deviceModelId);
    if (this.selectedModel) {
      this.selectedBrand = this.deviceBrands.find((item) => item.key === this.selectedModel.brand);
      this.selectedProtocol = this.deviceProtocols.find((item) => item.key === this.selectedModel.protocol);
    }
  }

  private updateAutomationName() {
    let name = '';
    if (this.selectedModel && this.selectedLocation) {
      name = `${this.selectedLocation.description} ${this.selectedModel.display_name}`;
    } else if (this.selectedLocation) {
      name = this.selectedLocation.description;
    } else if (this.selectedModel) {
      name = this.selectedModel.display_name;
    }
    this.form.get('name').setValue(name);
  }

  private updateDeviceName() {
    let name = '';
    if (this.selectedModel) {
      name = this.selectedModel.display_name;
    }
    this.form.get('name').setValue(name);
  }

  private updateFloorLocationSelectItems(floorNumber: number) {
    const tmpFloorLocations: any = {};
    const floor = this.locations.find(
      (item) => item.locationTypeKey === LocationTypeEnum.Floor && item.floorNumber === floorNumber
    );
    const floorLocations = this.locations.filter(
      (item) => item.locationTypeKey !== LocationTypeEnum.Floor && item.floorNumber === floorNumber
    );
    const tmpChildLocations = [];

    for (const l of floorLocations) {
      if (l.parentIdx) {
        if (l.parentIdx === floor.idx) {
          tmpFloorLocations[l.idx] = {
            label: l.description,
            data: l.idx,
            children: [],
            expanded: true,
          };
        } else {
          if (!this.isAutomation) {
            tmpChildLocations.push(l);
          }
        }
      }
    }

    for (const l of tmpChildLocations) {
      if (tmpFloorLocations[l.parentIdx]) {
        tmpFloorLocations[l.parentIdx].children.push({
          label: l.description,
          data: l.idx,
        });
      }
    }

    this.selectItems.floorLocations = Object.keys(tmpFloorLocations).map((key) => tmpFloorLocations[key]);
  }

  private createProperties() {
    if (this.selectedModel) {
      const properties = JSON.parse(this.selectedModel.properties as string);
      if (properties && properties.length) {
        const formValue = this.form.getRawValue();
        const vds = [];

        for (const prop of properties) {
          const type = this.deviceTypes.find((item) => item.key === prop.type);

          if (type) {
            const vd: any = {
              description: prop.display_name,
              automationId: formValue.automationId,
              locationIdx: this.selectedLocation.idx,
              locationId: this.selectedLocation.id || null,
              siteId: this.selectedSite.id,
              brandId: this.selectedBrand.id,
              protocolId: this.selectedProtocol.id,
              deviceModelId: this.selectedModel.id,
              deviceTypeId: type.id,
              uniqueId: prop.unique_id,
              diffDetectionFactor: prop.min_diff_threshold,
              excludeFromLHL: false,
              isActive: true,
              isHidden: false,
            };
            vds.push(vd);
          }
        }

        this.deviceFormService.patchDeviceProperties(this.form, vds);
      }
    }
  }

  private prepareSiblings() {
    if (!this.deviceTypes || !this.deviceTypes.length || !this.scaffold) {
      return;
    }

    for (const type of this.deviceTypes) {
      const scaffoldType = this.scaffold.device_types[type.key];

      if (scaffoldType && scaffoldType.siblings && scaffoldType.siblings.length && !this.siblings[type.id]) {
        const siblingTypes = this.deviceTypes.filter(
          (item) => [type.key, ...scaffoldType.siblings].indexOf(item.key) >= 0
        );
        this.siblings[type.id] = siblingTypes.map((item) => ({ label: item.name, value: item.id }));
      }
    }
  }

  onSelectDeviceModel() {
    this.errorMessage = null;
    this.selectedProtocol = this.deviceProtocols.find((item) => item.key === this.selectedModel.protocol);

    if (this.selectedProtocol?.key === 'modbus') {
      this.nodeIdLabel = this.sharedService.getTranslation('SLAVE_ID');
      this.isModbus = true;
      this.form.patchValue({
        uuid: 1,
      });
    } else {
      this.nodeIdLabel = this.sharedService.getTranslation('NODE_ID');
      this.isModbus = false;
      this.form.patchValue({
        uuid: null,
      });
    }
  }

  onSelectDeviceType(type: string) {
    this.selectedDeviceType = type;
    this.isAutomation = this.selectedDeviceType === DeviceTypeEnum.Automation;
    this.initForm();
    this.updateDeviceModelSelectItems();
    this.step = 2;
  }

  onSelectAutomation() {
    this.updateDeviceModelSelectItems();
  }

  onBackClick() {
    this.deviceFormStep--;
  }

  onNextClick() {
    if (!this.allowNext) {
      return;
    }

    const nextStep = this.deviceFormStep + 1;
    if (nextStep === 2) {
      this.getDeviceDetails();

      if (!this.editMode) {
        const automationId = this.form.get('automationId').value;
        this.errorMessage = null;

        if (!PROTOCOLS.includes(this.selectedProtocol.id) && !EXTENDERS.includes(this.selectedModel.id))
          if (this.deviceFormService.isCeosReachDevicesLimitation(automationId, this.devices)) {
            const params = {
              ceos: this.devices.find((device) => device.id === automationId).description,
            };
            this.errorMessage = this.sharedService.getTranslation('MAX_IOT_REACHED', params);
            return;
          }
      }

      if (this.editMode) {
        const vds = this.devices.filter((item) => item.parentIdx === this.device.idx);
        this.deviceFormService.patchDeviceProperties(this.form, vds, this.editMode);
      } else {
        this.createProperties();
      }
    } else if (nextStep === 3) {
      // this.updatePropertyNames();

      const formValue = this.form.getRawValue();
      this.summary = {
        floor: '',
        location: '',
        automation: '',
        model: '',
        name: formValue.name,
      };
      this.properties = formValue.properties;

      const floor = this.locations.find(
        (item) => item.locationTypeKey === LocationTypeEnum.Floor && item.floorNumber === formValue.floorNumber
      );
      if (floor) {
        this.summary.floor = floor.description;
      }
      const location = this.locations.find((item) => item.idx === formValue.locationIdx);
      if (location) {
        this.summary.location = location.description;
      }
      const automation = this.devices.find((item) => item.id === formValue.automationId);
      if (automation) {
        this.summary.automation = automation.description;
      }

      if (this.selectedModel) {
        this.summary.model = this.getDeviceModelLabel(this.selectedModel);
      }
    }
    this.deviceFormStep = nextStep;
  }

  getPropertyName(deviceTypeId: number) {
    const type = this.deviceTypes.find((item) => item.id === deviceTypeId);
    if (type) {
      return type.name;
    }
    return '';
  }

  get allowNext() {
    switch (this.deviceFormStep) {
      case 1:
        if (!this.errorMessage) {
          return true;
        }
        return false;
      case 2: {
        if (this.form.valid) {
          return true;
        }
        return false;
      }

      default: {
        return true;
      }
    }
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formValue = this.form.getRawValue();
    let data;

    if (this.selectedDeviceType === DeviceTypeEnum.Device) {
      const type = this.deviceTypes.find((item) => item.key === 'vdh');
      const vdh = {
        description: formValue.name || this.selectedModel.display_name,
        automationId: formValue.automationId,
        locationIdx: this.selectedLocation.idx,
        locationId: this.selectedLocation.id,
        siteId: this.selectedSite.id,
        brandId: this.selectedBrand.id,
        protocolId: this.selectedProtocol.id,
        deviceModelId: this.selectedModel.id,
        deviceTypeId: type.id,
        uniqueId: this.selectedModel.unique_id,
        diffDetectionFactor: null,
        excludeFromLHL: false,
        isActive: true,
        isHidden: false,
        isVirtualDevicesHolder: true,
        isVirtualDevice: false,
        uuid: this.editMode ? formValue.uuid : null,
      };
      const vds = [...formValue.properties];

      //CP_192
      if (this.selectedProtocol.key === 'modbus') {
        vdh.uuid = formValue.uuid;
        vds.forEach((vd) => {
          vd.uuid = vd.uniqueId;
        });
      } else {
        if (this.editMode) {
          // Get device model properties
          const deviceModelProperties = this.deviceModels.find(
            (deviceModel) => deviceModel.id === this.selectedModel.id
          )?.properties as string;

          if (deviceModelProperties) {
            const properties = JSON.parse(deviceModelProperties);
            // Update properties uuid
            for (let i = 0; i < vds.length; i++) {
              if (!vds[i].uuid || vds[i].uuid === '' || this.device.uuid !== formValue.uuid) {
                formValue.uuid && formValue.uuid !== ''
                  ? (vds[i].uuid = `${formValue.uuid}-${properties[i].unique_id}`)
                  : (vds[i].uuid = null);
              }
            }
          }
        }
      }

      data = { vdh, vds };
    } else if (this.selectedDeviceType === DeviceTypeEnum.Automation) {
      this.getDeviceDetails();
      const type = this.deviceTypes.find(
        (item) => item.isActive && !item.isDeleted && item.key === DeviceTypeEnum.Automation
      );
      const a: any = {
        description: formValue.name,
        locationIdx: this.selectedLocation.idx,
        locationId: this.selectedLocation.id,
        siteId: this.selectedSite.id,
        brandId: this.selectedBrand.id,
        deviceModelId: this.selectedModel.id,
        deviceTypeId: type.id,
        uniqueId: this.selectedModel.unique_id,
        expectedOnline: formValue.expectedOnline,
        maxSeenMinutesAgo: formValue.maxSeenMinutesAgo,
        operatingMode: formValue.operatingMode,
        gatewaySettings: JSON.stringify(formValue.gatewaySettings),
        modbusActive: formValue.modbusActive,
        modbusSettings: JSON.stringify(formValue.modbusSettings),
      };
      if (!this.editMode) {
        a.isActive = true;
      }
      data = { a };
    }

    if (this.editMode) {
      this.update(data);
    } else {
      this.create(data);
    }
  }

  private create(data: any) {
    this.subscribers.update = this.integratorDeviceService.createDevice(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
      complete: () => this.store.dispatch(new fromStore.LoadDevices({ siteId: this.selectedSite.id })),
    });
  }

  private update(data: CeosDevice) {
    let tmp;
    if (this.selectedDeviceType === DeviceTypeEnum.Automation) {
      tmp = { ...data.a };
    } else if (this.selectedDeviceType === DeviceTypeEnum.Device) {
      tmp = { ...data.vdh, virtualDevices: [...data.vds] };
    }

    this.subscribers.update = this.devicesService.updateCeosDevice(this.device.idx, tmp).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.devicesService.deleteDevice(this.device.id, true).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('DEVICE');
    this.sharedService.notify(title, text, 'success');
    const action = message.replace('_SUCCESS', '');

    // close the form and return user info
    this.isSubmitting = false;
    this.dialog.close({ action, data: result.data });
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  get isUUIDchanged() {
    if (this.form) {
      const formUUID = this.device.uuid ? this.device.uuid : '';
      const deviceUUID = this.form.get('uuid').value ? this.form.get('uuid').value : '';
      return deviceUUID !== formUUID;
    }
  }

  get automationVersion() {
    if (this.form) {
      return this.devices.find((device) => device.id === this.form.get('automationId').value)?.uniqueId;
    }
  }

  get isModbusActiveOnAutomation() {
    if (this.form) {
      return this.devices.find((device) => device.id === this.form.get('automationId').value)?.modbusActive;
    }
  }
}
