import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalConfig } from '@sc/modal/modal-config';

import { SharedService } from '@shared/shared.service';

import { DeviceDetailsModalComponent } from '../../device-details-modal/device-details-modal.component';

@Component({
  selector: 'sc-location-devices-action-cell',
  templateUrl: './location-devices-action-cell.component.html',
})
export class LocationDevicesActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  // deviceForm: ModalConfig;

  private containerComponent: any;
  private deviceDetailsModal: DynamicDialogRef;
  private containerComponentInterval: any;
  constructor(private dialogService: DialogService, private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;

    // this.deviceForm = {
    //   data: this.cell.data,
    //   name: 'DeviceForm',
    //   options: {
    //     modalTitle: 'DEVICE_FORM_TITLE',
    //     requiredPermission: 'device_c',
    //     fullScreen: true,
    //   },
    // };

    // console.log(this.cell);
    // const containerComponent = this.cell.context.containerComponent;
    // console.log(containerComponent);
    // const vdh = containerComponent.devices.find((d) => d.id === this.cell.data.id);
    // const vds = containerComponent.devices.filter((d) => d.virtualDeviceHolderId === this.cell.data.id);
    // console.log('vdh:', vdh);
    // console.log('vds:', vds);
    // this.deviceForm = {
    //   data: { vdh, vds },
    //   name: 'DeviceDetailsModal',
    //   options: {
    //     modalTitle: 'FLOORPLAN_DEVICE_DETAILS_MODAL_TITLE',
    //   },
    // };

    this.containerComponentInterval = setInterval(() => {
      // console.log('containerComponentInterval');
      if (this.cell && this.cell.context && this.cell.context.containerComponent) {
        this.containerComponent = this.cell.context.containerComponent;
      }

      if (this.containerComponent) {
        clearInterval(this.containerComponentInterval);
        this.containerComponentInterval = null;
      }
    }, 1000);
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {
    clearInterval(this.containerComponentInterval);
    this.containerComponentInterval = null;
  }

  onUpdateSuccess(event) {
    // console.log('onUpdateSuccess:', event);
    // if (event.result) {
    //   if (this.cell && this.cell.context && this.cell.context) {
    //     if (this.cell.context.parentComponent) {
    //       this.cell.context.parentComponent.updateRow({ ...event.result, id: this.cell.data.id });
    //     }
    //   }
    // }
  }

  // get deviceForm(): ModalConfig {
  //   if (this.cell && this.cell.context && this.cell.context.containerComponent) {
  //     const containerComponent = this.cell.context.containerComponent;
  //     console.log(containerComponent);
  //     const vdh = containerComponent.devices.find((d) => d.id === this.cell.data.id);
  //     const vds = containerComponent.devices.filter((d) => d.virtualDeviceHolderId === this.cell.data.id);
  //     console.log('vdh:', vdh);
  //     console.log('vds:', vds);
  //     return {
  //       data: { vdh, vds },
  //       name: 'DeviceDetailsModal',
  //       options: {
  //         modalTitle: 'FLOORPLAN_DEVICE_DETAILS_MODAL_TITLE',
  //       },
  //     };
  //   }
  //   return;
  // }

  // openDeviceDetails() {
  //   const tableComp = this.cell.context.containerComponent;
  //   const devices = (tableComp && tableComp.devices) || [];
  //   const deviceModels = (tableComp && tableComp.deviceModels) || [];

  //   const vdh = devices.find((d) => d.id === this.cell.data.id);
  //   const vds = devices.filter((d) => d.virtualDeviceHolderId === this.cell.data.id);
  //   const dialogOption: any = {};
  //   dialogOption.header = this.sharedService.getTranslation('FLOORPLAN_DEVICE_DETAILS_MODAL_TITLE');
  //   dialogOption.modal = false;
  //   dialogOption.data = {
  //     vdh,
  //     vds,
  //     dhl: [], //this.selectedObject.dhl
  //     devices,
  //     deviceModels,
  //   };

  //   this.deviceDetailsModal = this.dialogService.open(DeviceDetailsModalComponent, dialogOption);
  //   this.deviceDetailsModal.onClose.subscribe((result: any) => {
  //     console.log(result);
  //   });
  // }

  openDeviceDetails() {
    // console.log(this.containerComponent);
    if (this.containerComponent && this.containerComponent.viewDetails) {
      this.containerComponent.viewDetails(this.cell.data.id);
    }
  }
}
