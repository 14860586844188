import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocationStatisticsRequest } from '../models/location-statistics-request';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';
import * as FileSaver from 'file-saver';
import * as moment from 'moment-timezone';

@Injectable()
export class LocationStatisticsService {
  private baseUrl = config().apiUrl + '/companies/{companyId}/sites/{siteId}/locations/history/stats';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  getDownloadLink(options: LocationStatisticsRequest) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    const selectedSite = this.sharedService.selectedSite;
    const apiUrl = this.baseUrl
      .replace('{companyId}', '' + selectedSite.companyId)
      .replace('{siteId}', '' + selectedSite.id);
    return apiUrl + queryParams;
  }

  getLocationStatistics(options: LocationStatisticsRequest) {
    const apiUrl = this.getDownloadLink(options);
    return this.httpClient.get(apiUrl);
  }

  createCSVData(data, rows, locations, timezone) {
    for (let i = 0; i < data.length; i++) {
      const row = [];

      for (const key in data[i]) {
        if (data[i].hasOwnProperty(key) && key !== 'key_as_string') {
          if (key === 'key') {
            row.push(moment.tz(data[i][key], timezone).format('DD.MM.YYYY HH:mm:ss'));
          } else {
            if (data[i][key] === '' || data[i][key] === null) {
              row.push('');
            } else {
              if (Number(data[i][key])) {
                row.push(Number(data[i][key]).toFixed(2));
              } else {
                row.push(data[i][key]);
              }
            }
          }
        }
      }
      row.push(locations);
      rows.push(row.join(';'));
    }

    return rows;
  }

  csvDownload(locations: any[], siteTZ: string, data: any[]) {
    if (!data || !data.length) {
      return;
    }

    let csv = [];
    let header = Object.keys(data[0][0]).join(';');
    header = header.replace('key_as_string;key', 'datetime');
    header += ';locations';
    csv.push(header);

    csv = this.createCSVData(data[0], csv, locations[0], siteTZ);

    if (data[1] && data[1].length) {
      csv = this.createCSVData(data[1], csv, locations[1], siteTZ);
    }

    //Export file
    const blob = new Blob([csv.join('\r\n')], { type: 'text/csv' });
    FileSaver.saveAs(blob, `LS_${new Date().valueOf()}`);
  }
}
