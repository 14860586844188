<div class="vds-table">
  <!-- <div class="vds-table-header">
    <div class="sc-flex align-center">
      <h4 class="header-title">
        {{ 'VIRTUAL_DEVICES' | translate }}
      </h4>
    </div>
  </div> -->

  <div class="vds-table-body">
    <sc-table
      [columns]="columns"
      [dataset]="virtualDevices"
      [title]="tableTitle"
      [toolbarItems]="toolbarItems"
      [hideIcon]="true"
      (afterInit)="afterInitTable($event)"
    ></sc-table>

    <!-- <table>
      <thead>
        <tr>
          <th>
            {{ 'NAME' | translate }}
          </th>
          <th>
            {{ 'TYPE' | translate }}
          </th>
          <th>
            {{ 'ACTION' | translate }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let device of devices">
          <td>{{ device.description }}</td>
          <td>{{ device.deviceTypeKey }}</td>
          <td>
            <sc-modal-trigger
              *scHasPermission="'device_u'"
              [modalConfig]="getDeviceFormModal(device)"
              [buttonIcon]="'fas fa-pencil-alt'"
              buttonClass="ui-button-secondary"
              (onClose)="onUpdateSuccess($event, device)"
              pTooltip="{{ 'EDIT' | translate }}"
              tooltipPosition="top"
              appendTo="body"
            ></sc-modal-trigger>
          </td>
        </tr>
      </tbody>
    </table> -->
  </div>
</div>
