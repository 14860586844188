export const AUTOMATION_DEVICE_ID = 0; // 0 would be interpreted as local
export const MQTT_REQUEST_TIMEOUT = 30000; // 5 seconds

export const RETURN_TYPES = [
  { id: 'boolean', label: 'Boolean' },
  { id: 'numeric', label: 'Numeric' },
  { id: 'text', label: 'Text' },
];

export const VALUE_TYPES = [
  { id: 'boolean', label: 'Boolean' },
  { id: 'datepicker', label: 'Date Picker' },
  { id: 'datetimepicker', label: 'Date & Time Picker' },
  { id: 'numeric', label: 'Numeric' },
  { id: 'select', label: 'Select' },
  { id: 'text', label: 'Text' },
  { id: 'timepicker', label: 'Time Picker' },
];

export const ENFORCEMENT_MODES = [
  { id: 'enforce', label: 'Enforce' },
  { id: 'enforced_by', label: 'Enforced by' },
];
