<div class="sc-flex direction-column" [ngStyle]="{ height: '100%' }">
  <div
    *ngIf="!hideHeader"
    class="sc-flex justify-content-between transparent"
    style="height: 50px"
  >
    <div class="sc-flex align-items-center">
      <h4 *ngIf="title" class="table-title" [ngClass]="{ 'no-icon': hideIcon }">
        <i
          *ngIf="icon && !hideIcon"
          class="fa fa-fw green-text"
          [ngClass]="icon"
        ></i>
        {{ title | translate }}
      </h4>
    </div>

    <div class="sc-flex justify-content-end align-items-center">
      <ng-container *ngIf="isActiveToolbarItem('site')">
        <sc-table-site-selector
          *scHasRole="siteSelectorPermission"
          (siteChanged)="onSiteModeChanged($event)"
        ></sc-table-site-selector>
      </ng-container>

      <ng-container *ngIf="isActiveToolbarItem('create') && formConfig">
        <sc-modal-trigger
          *scHasPermission="formConfig?.options?.requiredPermission"
          [modalConfig]="formConfig"
          (onClose)="addRow($event)"
        >
          <button type="button" class="create-button">
            <span class="create-button__icon">
              <i class="fa fa-plus"></i>
            </span>
            <span class="create-button__label">
              {{ "CREATE" | translate }}
            </span>
          </button>
        </sc-modal-trigger>
      </ng-container>

      <ng-container *ngIf="isActiveToolbarItem('refresh')">
        <sc-table-refresh-button
          (onClick)="refresh()"
          [refreshing]="refreshing"
        ></sc-table-refresh-button>
      </ng-container>

      <ng-container *ngIf="isActiveToolbarItem('filter') && gridReady">
        <sc-data-display-button
          [gridOptions]="gridOptions"
        ></sc-data-display-button>
      </ng-container>

      <ng-container *ngIf="isActiveToolbarItem('columns') && gridReady">
        <sc-column-display-button
          [gridOptions]="gridOptions"
          [default]="config?.customOptions?.visibleColumns"
          (saved)="save($event)"
          (columnsChanged)="onDisplayColumnsChanged($event)"
        ></sc-column-display-button>
      </ng-container>

      <ng-container
        *ngIf="
          isActiveToolbarItem('treeExpand') &&
          gridReady &&
          !lazyload &&
          config?.customOptions?.treeView
        "
      >
        <sc-tree-expand-button
          [gridOptions]="gridOptions"
          [default]="config?.customOptions?.treeExpand"
          (onExpand)="updateTreeExpand($event)"
        ></sc-tree-expand-button>
      </ng-container>

      <ng-container
        *ngIf="
          isActiveToolbarItem('customSummary') &&
          config?.customSummaryModalConfig
        "
      >
        <sc-modal-trigger
          buttonIcon="fa fa-tags"
          [modalConfig]="config?.customSummaryModalConfig"
          (onClose)="updateLocationSummaryColumn($event)"
          tooltipContent="{{ 'CUSTOM_SUMMARY_DATA' | translate }}"
          tooltipPosition="left"
        ></sc-modal-trigger>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="showDateFilter"
    class="sc-flex justify-content-between transparent"
    style="height: 56px; padding-bottom: 8px"
  >
    <sc-quick-date-select
      (valueChange)="filterDateChange.emit($event)"
    ></sc-quick-date-select>
  </div>

  <div *ngIf="gridOptions" class="sc-flex" style="height: 100%">
    <ag-grid-angular
      #agGrid
      class="ag-theme-sc-clean"
      [gridOptions]="gridOptions"
      style="height: 100%; width: 100%"
      [suppressRowClickSelection]="suppressRowClickSelection"
    >
    </ag-grid-angular>
  </div>
</div>
