import { ActionReducerMap } from '@ngrx/store';
import * as fromAppStyle from './app-style.reducer';
import * as fromAuth from './auth.reducer';
import * as fromCompanies from './companies.reducer';
import * as fromDevices from './devices.reducer';
import * as fromGrids from './grids.reducer';
import * as fromInitial from './initial.reducer';
import * as fromLocales from './locales.reducer';
import * as fromLocations from './locations.reducer';
import * as fromPageTemplates from './page-templates.reducer';
import * as fromPages from './pages.reducer';
import * as fromRouter from './router.reducer';
import * as fromRuleBulk from './rule-bulk.reducer';
import * as fromSceneTemplates from './scene-templates.reducer';
import * as fromSiteType from './site-type.reducer';
import * as fromSites from './sites.reducer';
import * as fromUser from './user.reducer';
import * as fromWidgetCategories from './widget-categories.reducer';
import * as fromWidgets from './widgets.reducer';

export interface State {
  appStyle: fromAppStyle.AppStyleState;
  auth: fromAuth.AuthState;
  companies: fromCompanies.CompanyState;
  devices: fromDevices.DeviceState;
  grids: fromGrids.GridState;
  initialData: fromInitial.InitialDataState;
  locales: fromLocales.LocaleState;
  locations: fromLocations.LocationState;
  pageTemplates: fromPageTemplates.PageTemplateState;
  pages: fromPages.PageState;
  router: fromRouter.RouterState;
  ruleBulk: fromRuleBulk.State;
  sceneTemplates: fromSceneTemplates.SceneTemplateState;
  siteType: fromSiteType.State;
  sites: fromSites.SiteState;
  user: fromUser.UserState;
  widgetCategories: fromWidgetCategories.WidgetCategoriesState;
  widgets: fromWidgets.WidgetState;
}

export const reducers: ActionReducerMap<State> = {
  appStyle: fromAppStyle.reducer,
  auth: fromAuth.reducer,
  companies: fromCompanies.reducer,
  devices: fromDevices.reducer,
  grids: fromGrids.reducer,
  initialData: fromInitial.reducer,
  locales: fromLocales.reducer,
  locations: fromLocations.reducer,
  pageTemplates: fromPageTemplates.reducer,
  pages: fromPages.reducer,
  router: fromRouter.reducers,
  ruleBulk: fromRuleBulk.reducer,
  sceneTemplates: fromSceneTemplates.reducer,
  siteType: fromSiteType.reducer,
  sites: fromSites.reducer,
  user: fromUser.reducer,
  widgetCategories: fromWidgetCategories.reducer,
  widgets: fromWidgets.reducer,
};
