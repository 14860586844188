import { NgModule } from '@angular/core';

import { CostSavedModule } from './cost-saved/cost-saved.module';
import { EnergySavedModule } from './energy-saved/energy-saved.module';
import { TotalSavingModule } from './total-saving/total-saving.module';
import { LocationSavingTableModule } from './location-saving-table/location-saving-table.module';
import { EnergyEfficiencyRatingModule } from './energy-efficiency-rating/energy-efficiency-rating.module';

import { EnergySavingsService } from './services/energy-savings.service';

const MODULES: any[] = [
  CostSavedModule,
  EnergySavedModule,
  TotalSavingModule,
  LocationSavingTableModule,
  EnergyEfficiencyRatingModule
];

@NgModule({
  imports: MODULES,
  exports: MODULES,
  providers: [EnergySavingsService]
})
export class EnergySavingModule {}
