import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { TranslationActionCellComponent } from './translation-action-cell/translation-action-cell.component';
import { TranslationFormComponent } from './translation-form/translation-form.component';
import { TranslationsTableComponent } from './translations-table/translations-table.component';
import { ValueFormComponent } from './translation-form/value-form/value-form.component';
import { TranslationImportFormComponent } from './translation-import-form/translation-import-form.component';

import { TranslationsService } from './translations.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    TranslationActionCellComponent,
    TranslationFormComponent,
    TranslationsTableComponent,
    ValueFormComponent,
    TranslationImportFormComponent
  ],
  entryComponents: [
    TranslationActionCellComponent,
    TranslationFormComponent,
    TranslationsTableComponent,
    TranslationImportFormComponent
  ],
  providers: [TranslationsService]
})
export class TranslationsModule {}
