import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeviceModel } from '@widgets/device-models/models/device-model';

import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';

@Injectable()
export class DeviceModelsService {
  private apiUrl = config().apiUrl + '/deviceModels';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private verifyData(data: any): DeviceModel {
    const newData = Object.assign({}, data);
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getDeviceModel(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getDeviceModels() {
    return this.httpClient.get(this.apiUrl);
  }

  createDeviceModel(data: DeviceModel) {
    const formData = this.sharedService.createFormData(this.verifyData(data), ['productImage', 'iconImage']);
    return this.httpClient.post(this.apiUrl, formData);
  }

  updateDeviceModel(id: number, data: DeviceModel) {
    const formData = this.sharedService.createFormData(this.verifyData(data), ['productImage', 'iconImage']);
    return this.httpClient.patch(`${this.apiUrl}/${id}`, formData);
  }

  deleteDeviceModel(id: number, force: boolean = false) {
    let apiUrl = `${this.apiUrl}/${id}`;
    if (force === true) {
      apiUrl += '?force';
    }
    const formData = this.sharedService.createFormData({
      isActive: false,
      isDeleted: true,
    });
    return this.httpClient.patch(apiUrl, formData);
  }
}
