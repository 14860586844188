export enum DeviceIcons {
  Alert = 'assets/images/warning.svg',
  Assigned = 'assets/images/wireless.svg',
  Automation = 'assets/images/automation.svg',
  Unassigned = 'assets/images/gear.svg',
}

export enum DeviceStateColors {
  AutomationAssignedBackground = '#2196F3',
  AutomationAssignedBorder = '#222',
  AlertBackground = 'rgba(217,83,79,0.8)',
  AlertBorder = '#222',
  AssignedBackground = 'rgba(78, 187, 217, 0.8)', // blue
  AssignedBorder = '#4ebbd9', // '#222',
  UnassignedBackground = 'rgba(255,193,7,0.8)', // yellow
  UnassignedBorder = '#ffc107'
}

export enum DeviceStates {
  Unassigned = 0,
  Assigned,
  Alert,
}

export enum CleaningStates {
  Unknown = 0,
  Clean = 1,
  Dirty = 2,
}
