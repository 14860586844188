import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';
import { RulesService, UtilsService } from '../../services';

@Component({
  selector: 'sc-report-command',
  templateUrl: './report-command.component.html',
  styleUrls: ['./report-command.component.scss'],
})
export class ReportCommandComponent implements OnInit, OnDestroy {
  @Input()
  commandForm: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  variableForm: FormArray;

  private subscribers: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private rulesService: RulesService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.commandForm.get('action').setValue('use_report_template');
    this.variableForm = this.commandForm.get('variables') as FormArray;

    if (!this.isTemplate) {
      this.initVariableForm();
    }
  }

  ngOnDestroy(): void {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private initVariableForm() {
    const formValue = this.commandForm.value;
    const reportTemplateIdx = formValue.target;

    if (reportTemplateIdx) {
      const reportTemplates = this.dataSources && this.dataSources.reportTemplates;
      if (reportTemplates && reportTemplates.length) {
        const template = reportTemplates.find((item) => item.idx === reportTemplateIdx);
        if (template && template.variables && (!formValue.variables || !formValue.variables.length)) {
          const variables = JSON.parse(template.variables);
          if (variables.length) {
            this.rulesService.setReportVariablesForm(this.commandForm, variables);
            this.variableForm = this.commandForm.get('variables') as FormArray;
          }
        }
      }
    }
  }
}
