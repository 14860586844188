import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LocationTemplate } from '../models/location-template';

import { config } from '@app/config';

@Injectable()
export class LocationTemplatesService {
  private apiUrl = config().apiUrl + '/locationTemplates';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): LocationTemplate {
    const newData = { ...data };

    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getLocationTemplate(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getLocationTemplates() {
    return this.httpClient.get(this.apiUrl);
  }

  createLocationTemplate(data: LocationTemplate) {
    return this.httpClient.post(this.apiUrl, this.verifyData(data));
  }

  updateLocationTemplate(id: number, data: LocationTemplate) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteLocationTemplate(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isDeleted: true,
      isActive: false
    });
  }
}
