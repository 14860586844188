<p-panel
  header="{{ 'COMMAND' | translate }} {{ commandNumber }}"
  [toggleable]="true"
  expandIcon="fa fa-chevron-down"
  collapseIcon="fa fa-chevron-up"
>
  <div [formGroup]="group">
    <div class="ui-g ui-g-nopad">
      <!-- DEVICE_TYPE_ID FIELD -->
      <div class="ui-g-6">
        <sc-dropdown
          controlName="deviceTypeId"
          [form]="group"
          label="{{ 'DEVICE_TYPE' | translate }}"
          [options]="optionData['deviceTypes']"
        ></sc-dropdown>
      </div>

      <!-- DEVICE ACTION FIELD -->
      <div class="ui-g-6">
        <div *ngIf="actions?.length > 0">
          <sc-dropdown
            controlName="action"
            [form]="group"
            label="{{ 'ACTION' | translate }}"
            [options]="actions"
          ></sc-dropdown>
        </div>
      </div>

      <!-- PARAMETERS FIELD -->
      <div class="ui-g-6" >
        <div *ngIf="parameters?.length > 0">
          <sc-dropdown
            controlName="parameters"
            [form]="group"
            label="{{ 'PARAMETERS' | translate }}"
            [options]="parameters"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>
      </div>

      <!-- DELAY FIELD -->
      <div class="ui-g-6">
        <div *ngIf="group.get('deviceTypeId')?.value">
          <sc-number-input
            controlName="delay"
            [form]="group"
            label="{{ 'DELAY' | translate }}"
          ></sc-number-input>
        </div>
      </div>

      <!-- REMOVE BUTTON -->
      <div class="ui-g-12" >
        <button
          pButton
          type="button"
          class="ui-button-warning"
          icon="fa fa-trash"
          label="{{ 'REMOVE' | translate }} {{ 'COMMAND' | translate }}"
          (click)="removed.emit()"
        ></button>
      </div>
    </div>
  </div>
</p-panel>
