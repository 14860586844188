<p-dialog
  header="Config"
  [(visible)]="isConfigModalOpen"
  appendTo="body"
  [style]="{ width: '80vw', maxWidth: '800px' }"
>
  <form [formGroup]="configForm" class="sc-widget-config__form">
    <div *ngIf="!isConfigModalInitialized">
      <sc-loading-indicator></sc-loading-indicator>
    </div>
    <div *ngIf="isConfigModalInitialized">
      <div class="ui-fluid">
        <div class="p-field p-grid">
          <label for="firstname" class="p-col-12 p-md-3">{{
            "WIDGET_CUSTOM_TITLE" | translate
          }}</label>
          <div class="p-col-12 p-md-9">
            <div class="ui-inputgroup display-flex">
              <input
                type="text"
                pInputText
                [placeholder]="defaultWidgetName"
                formControlName="name"
              />
              <button
                pButton
                type="button"
                icon="pi pi-undo"
                (click)="resetWidgetName()"
              ></button>
            </div>
          </div>
        </div>
        <div class="p-field p-grid p-align-center">
          <label for="lastname" class="p-col-12 p-md-3 p-col-align-start"
            >Mode</label
          >
          <div class="p-col-12 p-md-9">
            <div class="p-field-radiobutton">
              <p-radioButton
                name="selectedEkhoMode"
                [value]="EKHO_MODE_LEVEL"
                inputId="selectedEkhoMode1"
                formControlName="mode"
              ></p-radioButton>
              <label for="selectedEkhoMode1">Level</label>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                name="selectedEkhoMode"
                [value]="EKHO_MODE_HIT_COUNTER"
                inputId="selectedEkhoMode2"
                formControlName="mode"
              ></p-radioButton>
              <label for="selectedEkhoMode2">Hit Counter</label>
            </div>
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="occupiedColor" class="p-col-12 p-md-3 p-col-align-start"
            >Devices</label
          >
          <div class="p-col-12 p-md-9">
            <div class="ui-inputgroup display-flex">
              <p-dropdown
                [options]="ekhos"
                formControlName="selectedEkho"
                optionLabel="fullName"
                placeholder="Select an Ekho"
                [filter]="true"
              ></p-dropdown>
              <button
                pButton
                type="button"
                icon="pi pi-plus"
                [disabled]="!configForm.value.selectedEkho.idx"
                (click)="selectEkho()"
              ></button>
            </div>
            <br />
            <div *ngIf="!selectedEkhos.value.length">Please add an Ekho.</div>
            <div formArrayName="selectedEkhos">
              <p-table
                [value]="selectedEkhos.controls"
                *ngIf="selectedEkhos.value.length"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Name</th>
                    <th width="100">Empty at</th>
                    <th width="100">Full at</th>
                    <th width="60"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ctrl let-rowIndex="rowIndex">
                  <tr [formGroupName]="rowIndex">
                    <td>
                      {{ ctrl.value.ekho.fullName }}
                    </td>
                    <td>
                      <div class="ui-inputgroup display-flex">
                        <p-inputNumber
                          formControlName="min"
                          size="2"
                        ></p-inputNumber>
                        <span class="ui-inputgroup-addon">{{
                          configForm.value.mode == EKHO_MODE_LEVEL
                            ? "mm"
                            : "Hits"
                        }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="ui-inputgroup display-flex">
                        <p-inputNumber
                          formControlName="max"
                          size="2"
                        ></p-inputNumber>
                        <span class="ui-inputgroup-addon">{{
                          configForm.value.mode == EKHO_MODE_LEVEL
                            ? "mm"
                            : "Hits"
                        }}</span>
                      </div>
                    </td>
                    <td>
                      <button
                        pButton
                        icon="pi pi-minus"
                        (click)="unselectEkho(rowIndex)"
                      ></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <button
      type="button"
      (click)="saveConfig()"
      pButton
      label="{{ 'SUBMIT' | translate }}"
      class="ui-button-success"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  header="{{ widgetName }}"
  [(visible)]="isDetailsModalOpen"
  appendTo="body"
  [style]="{ width: '60vw', maxWidth: '600px' }"
>
  <div *ngIf="!isDetailsModalInitialized">
    <sc-loading-indicator></sc-loading-indicator>
  </div>
  <div *ngIf="isDetailsModalInitialized" style="padding: 0 35px 16px">
    <p-table [value]="ekhoStats">
      <ng-template pTemplate="header">
        <tr>
          <th width="150">Ekho</th>
          <th>Empty at</th>
          <th>Full at</th>
          <th>Abs. value</th>
          <th>Rel. value</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ekho>
        <tr>
          <td>{{ ekho.fullName }}</td>
          <td>{{ ekho.min }}</td>
          <td>{{ ekho.max }}</td>
          <td>{{ ekho.value }}</td>
          <td>{{ ekho.percentage }} %</td>
          <td>{{ ekho.thresholdName }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body ekho-body">
    <ng-container *ngIf="!isConfigured()">
      <div class="sc-flex">
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          label="{{ 'WIDGET_CONFIGURATION_REQUIRED' | translate }}"
        ></button>
      </div>
    </ng-container>
    <ng-container *ngIf="isConfigured() && chartData">
      <div class="sc-widget-legend ekho-widget-legend">
        <ul>
          <li *ngFor="let legendItem of legendItems">
            <span
              class="color"
              [ngStyle]="{ 'background-color': legendItem.color }"
            ></span>
            <span class="label ekho-label"
              >{{ legendItem.value }} {{ legendItem.label }}</span
            >
          </li>
        </ul>
      </div>
      <div class="ekho-widget-plotly">
        <plotly-plot
          [data]="chartData"
          [layout]="chartLayout"
          [config]="{ displayModeBar: false }"
        ></plotly-plot>
      </div>
    </ng-container>
  </div>
</ng-template>

<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">{{ widgetName }}</h4>
      <div class="sc-widget-menu">
        <button
          type="button"
          (click)="openDetailsModal()"
          pButton
          icon="pi pi-eye"
          title="Details"
          [disabled]="!isInitialized"
          class="ekho-action-btn"
          *ngIf="isInitialized && chartData && isConfigured()"
        ></button>
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          title="Config"
          [disabled]="!isInitialized"
          class="ekho-action-btn"
        ></button>
      </div>
    </div>
    <ng-container
      *ngIf="
        isInitialized && (!isConfigured() || (isConfigured() && chartData));
        then content;
        else spinner
      "
    ></ng-container>
  </div>
</div>
