<div class="location-assign">
  <div>
    <sc-modal-trigger
      buttonLabel="{{ 'CREATE' | translate }}"
      buttonClass="modal-trigger-button-with-borders"
      [modalConfig]="createLocationForm"
      (onClose)="onCreateClicked($event)"
    ></sc-modal-trigger>
  </div>

  <div class="separator">{{ "OR" | translate }}</div>

  <div *ngIf="locationControl" class="sc-flex location-selector">
    <p-dropdown
      [formControl]="locationControl"
      [options]="selectItems?.locations"
      placeholder="{{ 'SELECT' | translate }} {{ 'LOCATION' | translate }}"
      filter="true"
      [style]="{ width: '100%' }"
      appendTo="body"
    ></p-dropdown>

    <button
      type="button"
      pButton
      label="{{ 'ASSIGN' | translate }}"
      class="ui-button-success"
      (click)="onSelectLocation()"
    ></button>
  </div>
</div>
