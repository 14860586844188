import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PropertyManagementSystem } from '../models/property-management-system';

import { PropertyManagementSystemsService } from '../services/property-management-systems.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-property-management-system-form',
  templateUrl: 'property-management-system-form.component.html'
})
export class PropertyManagementSystemFormComponent implements OnInit {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: PropertyManagementSystem;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  isSubmitting: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private propertyManagementSystemsService: PropertyManagementSystemsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.formData = this.data;
    }
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      // propertyManagementSystemId: [null, Validators.required],
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      isActive: false,
      isDeleted: false
    });

    if (this.editMode === true) {
      // this.form.get('propertyManagementSystemId').disable();
      this.form.patchValue(this.formData);
    }
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.form.value;

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(formData) {
    this.propertyManagementSystemsService
      .create(formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update(formData) {
    this.propertyManagementSystemsService
      .update(this.formData.id, formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.propertyManagementSystemsService
      .delete(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify('PMS', result.message + ' Success!', 'success');
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
