<div class="notif-modal">
  <div class="notif-modal__header">
    <div class="notif-modal__header__content">
      <div *ngIf="!isFetching; else loadingIndicator" class="notif-modals">
        <p class="detail">
          <!-- <span class="detail-name"> {{ "MESSAGE" | translate }}: </span> -->
          <span>{{ notification.content }}</span>
        </p>
        <!-- <p class="detail">
          <span class="detail-name">{{ "PROTOCOL" | translate }}:</span>
          <span>{{ protocol }}</span>
        </p> -->
        <!-- <p class="detail" *ngIf="frequency">
          <span class="detail-name">{{ "FREQUENCY" | translate }}:</span>
          <span>{{ frequency }}</span>
        </p> -->
        <!-- <p class="detail">
          <span class="detail-name">{{ "PROPERTIES" | translate }}:</span>
          <span>
            <ul>
              <li *ngFor="let property of properties">
                - {{ property.display_name }}
              </li>
            </ul>
          </span>
        </p> -->
        <!-- <p class="detail">
          <span class="detail-name">{{ "MANUAL" | translate }}:</span>
          <a
            [href]="manualDownloadLink"
            target="_blank"
            *ngIf="manualDownloadLink"
          >
            {{ "DOWNLOAD" | translate }}
          </a>
        </p> -->
      </div>

      <ng-template #loadingIndicator>
        <sc-loading-indicator></sc-loading-indicator>
      </ng-template>
    </div>
  </div>
  <div class="integrator-modal__footer notif-modal__footer">
    <div
      class="integrator-modal__footer__left integrator-modal__footer__left--left notif-modal__footer--left"
    >
      <button
        type="button"
        class="ui-button-success"
        (click)="setAsRead()"
        pButton
        label="{{ 'MARK_AS_READ' | translate }}"
      ></button>
    </div>
    <div
      class="integrator-modal__footer__right integrator-modal__footer__right--right notif-modal__footer--right"
    >
      <button
        type="button"
        class="ui-button-secondary"
        (click)="closeModal()"
        pButton
        label="{{ 'CLOSE' | translate }}"
      ></button>
    </div>
  </div>
</div>
