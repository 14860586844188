import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserPermission } from '../models/user-permission';

import { config } from '@app/config';

@Injectable()
export class UserPermissionsService {
  private apiUrl = config().apiUrl + '/permissions';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): UserPermission {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.permissionId !== 'undefined') {
      delete newData.permissionId;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  create(data: UserPermission) {
    return this.httpClient.post(this.apiUrl, data);
  }

  get(id?: number) {
    return this.httpClient.get(this.apiUrl + (id ? '/' + id : ''));
  }

  update(id: number, data: UserPermission) {
    return this.httpClient.patch(this.apiUrl + '/' + id, this.verifyData(data));
  }

  delete(id: number) {
    return this.httpClient.patch(this.apiUrl + '/' + id, {
      isDeleted: true,
      isActive: false
    });
  }
}
