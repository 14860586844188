<ng-container
  *ngIf="!showSpinner; then content; else loadingIndicator"
></ng-container>

<ng-template #content>
  <sc-table
    [columns]="columns"
    [config]="config"
    (afterInit)="afterInitTable($event)"
    (reloadData)="fetchDataset()"
    (displayColumnsChange)="updateVisibleColumns($event)"
    [title]="tableTitle"
    [lazyload]="true"
    icon="fa fa-mobile"
    [toolbarItems]="toolbarItems"
    [showDateFilter]="true"
    (filterDateChange)="onDateSelect($event)"
  ></sc-table>
</ng-template>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
