import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { AppStyleTemplateActionCellComponent } from './app-style-template-action-cell/app-style-template-action-cell.component';
import { AppStyleTemplateFormComponent } from './app-style-template-form/app-style-template-form.component';
import { AppStyleTemplatesTableComponent } from './app-style-templates-table/app-style-templates-table.component';
import { ElementFormGroupComponent } from './app-style-template-form/element-form-group/element-form-group.component';
import { ElementStyleFormGroupComponent } from './app-style-template-form/element-style-form-group/element-style-form-group.component';
import { VariableFormGroupComponent } from './app-style-template-form/variable-form-group/variable-form-group.component';

import { AppStyleTemplateFormsService } from './services/app-style-template-forms.service';
import { AppStyleTemplatesService } from './services/app-style-templates.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    AppStyleTemplateActionCellComponent,
    AppStyleTemplateFormComponent,
    AppStyleTemplatesTableComponent,
    ElementFormGroupComponent,
    ElementStyleFormGroupComponent,
    VariableFormGroupComponent
  ],
  entryComponents: [
    AppStyleTemplateActionCellComponent,
    AppStyleTemplateFormComponent,
    AppStyleTemplatesTableComponent
  ],
  providers: [AppStyleTemplateFormsService, AppStyleTemplatesService]
})
export class AppStyleTemplatesModule {}
