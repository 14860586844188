export interface CustomFunction {
  idx?: string;
  name?: string;
  syntax?: string;
  parameters?: CustomFunctionParameter[] | string;
  return_type?: string;
  is_deleted?: boolean;
}

export interface CustomFunctionParameter {
  id: string;
  label: string;
  type: string;
  custom_global_key?: string;
  device_idx?: string;
  device_type?: string;
  location_idx?: string;
  property?: string;
}

export enum CustomFunctionParameterTypes {
  LatestDhlValue = 'latest_dhl_value',
  LatestDhlValueLabel = 'Latest DHL Value',
  LatestLhlValue = 'latest_lhl_value',
  LatestLhlValueLabel = 'Latest LHL Value',
  CustomGlobalValue = 'custom_global_value',
  CustomGlobalValueLabel = 'Custom Global Value',
}
