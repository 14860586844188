import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { IotCounterComponent } from './iot-counter.component';

@NgModule({
  imports: [SharedModule],
  declarations: [IotCounterComponent],
  entryComponents: [IotCounterComponent],
})
export class IotCounterModule {}
