import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserTaskTemplateTemplate } from '../interfaces/user-task-template-template';
import { UserTaskTemplateVariable } from '../interfaces/user-task-template-variable';

@Injectable({
  providedIn: 'root',
})
export class UserTaskTemplateFormService {
  constructor(private formBuilder: FormBuilder) {}

  initUserTaskTemplateFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      idx: { value: null, disabled: true },
      name: [null, Validators.required],
      priority: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      templates: this.formBuilder.array([]),
      variables: this.formBuilder.array([]),
      is_public: false,
      is_active: false,
      is_deleted: false,
    });
  }

  initVariableFormGroup(): FormGroup {
    return this.formBuilder.group({
      display_name: [null, Validators.required],
      type: [null, Validators.required],
      variable_name: [null, Validators.required],
    });
  }

  patchVariablesFormArray(form: FormGroup, data: UserTaskTemplateVariable[]) {
    const variables = form.get('variables') as FormArray;
    variables.clear();
    for (const item of data) {
      const variable = this.initVariableFormGroup();
      variable.patchValue(item);
      variables.push(variable);
    }
  }

  initTemplateFormGroup(): FormGroup {
    return this.formBuilder.group({
      locale: [null, Validators.required],
      subject: [null, Validators.required],
      message: null,
      is_active: false,
    });
  }

  patchTemplatesFormArray(form: FormGroup, data: UserTaskTemplateTemplate[]) {
    const templates = form.get('templates') as FormArray;
    templates.clear();
    for (const item of data) {
      const template = this.initTemplateFormGroup();
      template.patchValue(item);
      templates.push(template);
    }
  }
}
