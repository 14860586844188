<!-- <div class="h-100"> -->
<!-- [ngStyle]="{ 'height.px': config.widgetHeight }" -->

<div class="sc-widget">
  <div class="sc-widget-page__header">
    <div class="sc-widget-page__header__content">
      <h3 class="m-0 sc-widget-page__header__content__title">
        {{ "MY_ACCOUNT" | translate }}
      </h3>
      <span
        class="icon icon-account sc-widget-page__header__content__icon"
      ></span>
    </div>
  </div>
  <div class="sc-widget-header">
    <h4 class="sc-widget-title">
      {{ title | translate }}
    </h4>

    <div class="sc-widget-menu">
      <sc-modal-trigger
        buttonIcon="icon icon-pencil"
        [modalConfig]="editProfileFormConfig"
        tooltipContent="{{ 'EDIT_PROFILE' | translate }}"
        [displayInline]="true"
        (onClose)="updateProfile($event)"
        buttonClass="modal-trigger-button-with-borders modal-trigger-button-icon-only"
      ></sc-modal-trigger>

      <sc-modal-trigger
        buttonIcon="icon icon-mail"
        [modalConfig]="changeEmailFormConfig"
        tooltipContent="{{ 'CHANGE_EMAIL' | translate }}"
        [displayInline]="true"
        (onClose)="updateProfile($event)"
        buttonClass="modal-trigger-button-with-borders modal-trigger-button-icon-only"
      ></sc-modal-trigger>

      <sc-modal-trigger
        buttonIcon="icon icon-key"
        [modalConfig]="changePasswordFormConfig"
        tooltipContent="{{ 'CHANGE_PASSWORD' | translate }}"
        [displayInline]="true"
        (onClose)="updateProfile($event)"
        buttonClass="modal-trigger-button-with-borders modal-trigger-button-icon-only"
      ></sc-modal-trigger>
    </div>
  </div>

  <ng-container
    *ngIf="fetchingState === 0; then content; else spinner"
  ></ng-container>
</div>
<!-- </div> -->

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="grid-two-columns-content">
    <div class="grid-column-left">
      <!-- EMAIL -->
      <div>
        <label for="email" class="sc-form-label">
          {{ "EMAIL" | translate }}
        </label>
        <input
          id="email"
          type="text"
          pInputText
          value="{{ formData?.email }}"
          disabled
        />
      </div>
      <!-- PHONE -->
      <div>
        <label for="phone" class="sc-form-label">
          {{ "PHONE" | translate }}
        </label>
        <input
          id="phone"
          type="text"
          pInputText
          value="{{ formData?.phone }}"
          disabled
        />
      </div>
      <!-- LANGUAGE -->
      <div>
        <label for="language" class="sc-form-label">
          {{ "LANGUAGE" | translate }}
        </label>
        <input
          id="language"
          type="text"
          pInputText
          value="{{ language | translate }}"
          disabled
        />
      </div>
    </div>
    <div class="grid-column-right">
      <!-- FULL_NAME -->
      <div>
        <label for="fullname" class="sc-form-label">
          {{ "FULL_NAME" | translate }}
        </label>
        <input
          id="fullname"
          type="text"
          pInputText
          value="{{ formData?.firstname }} {{ formData?.lastname }}"
          disabled
        />
      </div>
      <!-- GENDER -->
      <div>
        <label for="gender" class="sc-form-label">
          {{ "GENDER" | translate }}
        </label>
        <input
          id="gender"
          type="text"
          pInputText
          value="{{ gender.text | translate }}"
          disabled
        />
      </div>
      <!-- BACKEND_VARIANT -->
      <div *ngIf="isAdmin">
        <label for="backendVariant" class="sc-form-label">
          {{ "BACKEND_VARIANT" | translate }}
        </label>
        <input
          id="backendVariant"
          type="text"
          pInputText
          value="{{ formData?.backendVariant }}"
          disabled
        />
      </div>
    </div>
  </div>
</ng-template>
