<div class="sc-flex direction-row justify-content-around align-items-center">
  <span class="badge green" pTooltip="{{ 'YES'| translate }}" appendTo="body" tooltipPosition="top">
    <i class="fa fa-smile-o"></i>
    {{ getTotalGuestResponse('yes') }}
  </span>
  <span class="badge grey" pTooltip="{{ 'MAYBE'| translate }}" appendTo="body" tooltipPosition="top">
    <i class="fa fa-meh-o"></i>
    {{ getTotalGuestResponse('maybe') }}
  </span>
  <span class="badge orange" pTooltip="{{ 'NO'| translate }}" appendTo="body" tooltipPosition="top">
    <i class="fa fa-frown-o"></i>
    {{ getTotalGuestResponse('no') }}
  </span>
</div>
