<div class="ceos-device-trigger-form">
  <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
    <!-- action / command -->
    <div class="p-field p-col-12 p-md-6">
      <h5 class="m-0 mb-2">
        {{ "REMOTE_TRIGGERING" | translate }}
      </h5>

      <ng-container [ngSwitch]="render?.type">
        <ng-container *ngSwitchCase="'numeric'">
          <ng-template
            [ngTemplateOutlet]="valueInput"
            [ngTemplateOutletContext]="{ $implicit: render }"
          ></ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'select'">
          <ng-template
            *ngIf="actions?.length === 1"
            [ngTemplateOutlet]="valueSelect"
            [ngTemplateOutletContext]="{ $implicit: render?.options }"
          ></ng-template>

          <ng-template
            *ngIf="actions?.length > 1"
            [ngTemplateOutlet]="actionSelect"
            [ngTemplateOutletContext]="{ $implicit: actions }"
          ></ng-template>
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ "NOT_ALLOWED" | translate }}
        </ng-container>
      </ng-container>
    </div>

    <!-- indicators -->
    <div class="p-field p-col-12 p-md-2">
      <div class="mt-4" *ngIf="isSubmitting">
        <i
          class="fa fa-spinner fa-spin"
          pTooltip="Command sent. We're waiting for the automation to accept."
          tooltipPosition="top"
          appendTo="body"
        ></i>
      </div>

      <div
        class="mt-4"
        *ngIf="
          !isSubmitting && !errorMessage && isSubmitSuccess && commandAcceptedAt
        "
      >
        <i
          class="fa fa-check green-text"
          pTooltip="Command accepted at {{ commandAcceptedAt }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
      </div>

      <div
        class="mt-4"
        *ngIf="!isSubmitting && errorMessage && !isSubmitSuccess"
      >
        <i
          class="fa fa-exclamation-triangle red-text"
          pTooltip="We could not reach the automation, the command timed out."
          tooltipPosition="top"
          appendTo="body"
        ></i>
      </div>
    </div>

    <!-- latest value -->
    <div class="p-field p-col-12 p-md-4">
      <h5 class="m-0 mb-2">
        {{ "LATEST_VALUE" | translate }}
      </h5>

      <div *ngIf="latestValue">
        {{ latestValue?.formattedValue || latestValue?.value
        }}{{ latestValue?.unit || "" }},
        {{ latestValue?.humanReadableCreatedAt }}
      </div>
      <div *ngIf="!device?.value">
        {{ "NO_DATA_YET" | translate }}
      </div>

      <!-- <div *ngIf="device?.value">
        {{ device?.formattedValue || device?.value }}{{ device?.unit || "" }},
        {{ device?.valueAt }}
      </div>
      <div *ngIf="!device?.value">
        {{ "NO_DATA_YET" | translate }}
      </div> -->
    </div>
  </div>

  <!-- history -->
  <div>
    <h5 class="m-0 mb-2">
      {{ "HISTORY" | translate }}
    </h5>

    <div>
      <div *ngIf="chart">
        <plotly-plot
          [data]="chart?.data"
          [layout]="chart?.layout"
          [config]="{ displayModeBar: false }"
        ></plotly-plot>
      </div>

      <p-table [value]="latestDHLs" [scrollable]="true" scrollHeight="250px">
        <!-- [loading]="isFetchDeviceHistory" -->
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width: 250px" />
            <col style="width: 250px" />
            <col style="width: 150px" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>{{ "DATE" | translate }}</th>
            <th>{{ "VALUE" | translate }}</th>
            <th>{{ "TRIGGERED_BY" | translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item?.formattedCreatedAt || item?.createdAt }}</td>
            <td>
              {{ item?.formattedValue || item?.value }}{{ item?.unit || "" }}
            </td>
            <td>{{ item?.triggeredBy }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #actionSelect let-options>
  <div class="action-select">
    <div class="p-formgrid p-grid">
      <div class="p-col-12 p-md-7 p-lg-9">
        <p-dropdown
          [options]="options"
          [formControl]="triggerValue"
          placeholder="{{ 'SELECT' | translate }} {{ 'ACTION' | translate }}"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-col-12 p-md-5 p-lg-3">
        <button
          pButton
          type="button"
          class="ui-button-success w-100"
          [label]="'TRIGGER' | translate"
          (click)="deviceTrigger(true)"
          [disabled]="triggerValue?.invalid || isSubmitting"
        ></button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #valueSelect let-options>
  <div class="value-select">
    <div class="p-formgrid p-grid">
      <div class="p-col-12 p-md-7 p-lg-9">
        <p-dropdown
          [options]="options"
          [formControl]="triggerValue"
          placeholder="{{ 'SELECT' | translate }} {{ 'VALUE' | translate }}"
          appendTo="body"
        ></p-dropdown>
      </div>

      <div class="p-col-12 p-md-5 p-lg-3">
        <button
          pButton
          type="button"
          class="ui-button-success w-100"
          [label]="'TRIGGER' | translate"
          (click)="deviceTrigger()"
          [disabled]="triggerValue?.invalid || isSubmitting"
        ></button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #valueInput let-render>
  <div class="value-input">
    <div class="p-formgrid p-grid">
      <div class="p-col-12 p-md-7 p-lg-9">
        <div class="ui-inputgroup p-inputtext-sm">
          <input
            pInputText
            type="number"
            [formControl]="triggerValue"
            [max]="render?.max"
            [min]="render?.min"
            step="0.1"
          />

          <span class="ui-inputgroup-addon" *ngIf="render?.unit">
            {{ render?.unit }}
          </span>
        </div>
      </div>

      <div class="p-col-12 p-md-5 p-lg-3">
        <button
          pButton
          type="button"
          class="ui-button-success w-100"
          [label]="'TRIGGER' | translate"
          (click)="deviceTrigger()"
          [disabled]="triggerValue?.invalid || isSubmitting"
        ></button>
      </div>
    </div>
  </div>
</ng-template>
