<div class="sc-widget">
  <div class="sc-widget-header">
    <h4 class="sc-widget-title">
      {{ config?.titleText | translate }}
    </h4>
    <div class="sc-widget-menu">
      <sc-modal-trigger
        [modalConfig]="config.modalConfig"
        tooltipContent="Config"
        tooltipPosition="bottom"
      >
        <a class="dashboard-action-btn">
          <i class="pi pi-cog"></i>
        </a>
      </sc-modal-trigger>
    </div>
  </div>

  <ng-container *ngIf="loading; then spinner; else content"></ng-container>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body circles-body">
    <div *ngIf="dataset" class="sc-flex justify-content-around">
      <div *ngFor="let c of dataset" class="m-1">
        <div *ngIf="c?.label" class="text-center mb-1">
          {{ c.label | translate: c.labelParams }}
        </div>
        <sc-circle
          [customClass]="'custom'"
          [customStyle]="c.customStyle"
          [icon]="c.icon"
          [primaryText]="c.primaryText"
          [secondaryText]="c.secondaryText"
          [size]="c.size"
        ></sc-circle>
      </div>
    </div>
    <small *ngIf="config?.subTitleText" class="circles-subtitle">
      {{ config.subTitleText | translate }}
    </small>

    <div
      *ngIf="config?.footerText"
      class="sc-flex justify-content-center align-items-center"
    >
      {{ config.footerText | translate }}
    </div>
  </div>
</ng-template>
