<div class="automation-setting">
  <div class="automation-setting-header">
    <div class="automation-info">
      <div class="primary">
        {{ data?.modelName }}
      </div>

      <div class="secondary">
        {{ data?.model }}
      </div>
    </div>

    <div class="automation-image" *ngIf="productImage">
      <img [src]="productImage" width="70" />
    </div>
  </div>

  <div class="automation-setting-body" [formGroup]="form">
    <div class="ui-g">
      <!-- DESCRIPTION -->
      <div class="ui-g-12 ui-md-6">
        <sc-text-input
          controlName="description"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <!-- IS_ACTIVE -->
      <div class="ui-g-12 ui-md-6 mt-3">
        <sc-checkbox-input
          controlName="isActive"
          [form]="form"
          label="{{ 'ACTIVE' | translate }}"
        ></sc-checkbox-input>
      </div>
    </div>

    <div class="ui-g">
      <!-- LOCATION_ID -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="locationId"
          [form]="form"
          label="{{ 'LOCATION' | translate }}"
          [options]="selectItems?.locations"
        ></sc-dropdown>
      </div>

      <!-- LOCATION_TO_CONTROL -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="locationsToControl"
          [form]="form"
          label="{{ 'LOCATIONS_TO_CONTROL' | translate }}"
          [options]="selectItems?.unitLocations"
          [multiSelect]="true"
        ></sc-dropdown>
      </div>
    </div>

    <div class="ui-g">
      <!-- INSTALLATION_PIN FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-text-input
          controlName="installationPin"
          [form]="form"
          label="{{ 'INSTALLATION_PIN' | translate }}"
        ></sc-text-input>
      </div>

      <!-- PIN FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-text-input
          controlName="pin"
          [form]="form"
          label="{{ 'PIN' | translate }}"
          warning="{{ 'WARNING_AUTOMATION_MISSING' | translate }}"
        ></sc-text-input>
      </div>

      <!-- API_KEY FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-text-input
          controlName="apiKey"
          [form]="form"
          label="{{ 'API_KEY' | translate }}"
          warning="{{ 'WARNING_AUTOMATION_MISSING' | translate }}"
        ></sc-text-input>
      </div>
    </div>

    <div class="ui-g">
      <!-- CONFIG_FETCH_INTERVAL -->
      <div class="ui-g-12 ui-md-6">
        <sc-number-input
          controlName="configFetchInterval"
          [form]="form"
          label="{{ 'CONFIG_FETCH_INTERVAL' | translate }}"
          suffix="{{ 'SECONDS' | translate }}"
        ></sc-number-input>
      </div>
    </div>

    <div class="ui-g">
      <!-- ZWAVE_STATUS -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="zwaveStatus"
          [form]="form"
          label="{{ 'ZWAVE' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- ZWAVE_CONTROLLER_SOFTWARE -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="zwaveControllerSoftware"
          [form]="form"
          label="{{ 'ZWAVE_CONTROLLER_SOFTWARE' | translate }}"
          [options]="selectItems?.zwaveControllerSoftwares"
        ></sc-dropdown>
      </div>

      <!-- ZWAVE_CONTROLLER_PORT -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="zwaveControllerPort"
          [form]="form"
          label="{{ 'ZWAVE_CONTROLLER_PORT' | translate }}"
          [options]="selectItems?.zwaveControllerPorts"
          [editable]="true"
        ></sc-dropdown>
      </div>
    </div>


    <div class="ui-g">
      <!-- ZIGBEE_STATUS -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="zigbeeStatus"
          [form]="form"
          label="{{ 'ZIGBEE' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- ZIGBEE_CONTROLLER_PORT -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="zigbeeControllerPort"
          [form]="form"
          label="{{ 'ZIGBEE_CONTROLLER_PORT' | translate }}"
          [options]="selectItems?.zigbeeControllerPorts"
          [editable]="true"
        ></sc-dropdown>
      </div>
    </div>
  </div>
</div>
