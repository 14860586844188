import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { LocationPropertiesService } from '../services/location-properties.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-property-action-cell',
  templateUrl: './location-property-action-cell.component.html'
})
export class LocationPropertyActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  private subscribers: { [key: string]: any } = {};

  constructor(
    private locationPropertiesService: LocationPropertiesService,
    private sharedService: SharedService
  ) { }

  agInit(params: any): void {
    this.cell = params;

    this.formConfig = {
      name: 'LocationPropertyForm',
      data: this.cell.data,
      options: { modalTitle: 'LOCATION_PROPERTY_FORM_TITLE' }
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: '_u',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this)
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this)
      }
    ];
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.subscribers.DELETE_LOCATION_PROPS = this.locationPropertiesService
      .deleteLocationProperty(this.cell.data.id)
      .subscribe(
        (result: any) => {
          this.cell.context.parentComponent.updateRow({
            id: this.cell.data.id,
            isActive: false,
            isDeleted: true
          });

          const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
          const title = this.sharedService.getTranslation('LOCATION_PROPERTY');
          this.sharedService.notify(title, text, 'success');
        },
        (error: any) => {
          const text = this.sharedService.getTranslation('UPDATE_FAIL');
          const title = this.sharedService.getTranslation('LOCATION_PROPERTY');
          this.sharedService.notify(title, text, 'error');
        }
      );
  }
}
