import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Widget } from '../models/page-section-widget';

import { config } from '@app/config';

@Injectable()
export class WidgetService {
  private apiUrl = config().apiUrl + '/customWidgets';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): Widget {
    const newData = { ...data };
    delete newData.id;
    delete newData.widgetId;
    delete newData.customWidgetId;
    delete newData.categories;
    delete newData.adminOnly;
    delete newData.description;
    delete newData.icon;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    delete newData.widgetHeight;
    return newData;
  }

  get(id?: string) {
    return this.httpClient.get(this.apiUrl + (id ? '/' + id : ''));
  }

  create(data: Widget) {
    return this.httpClient.post(this.apiUrl, data);
  }

  update(id: string, data: Widget) {
    return this.httpClient.patch(this.apiUrl + '/' + id, this.verifyData(data));
  }

  delete(id: string) {
    return this.httpClient.patch(this.apiUrl + '/' + id, {
      isActive: false,
      isDeleted: true,
    });
  }
}
