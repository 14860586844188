<div [formGroup]="group" class="ui-g-12 p-0" [ngClass]="formBackgroundColor">
  <div class="ui-g-11 p-0">
    <!-- EXECUTE_ONLY_ONE_TIME -->
    <div class="ui-g-1 text-center" *ngIf="!isEnd">
      <sc-checkbox-input
        controlName="executeOnlyOneTimeUntilRuleWasEnd"
        [form]="group"
        label="{{ 'EXECUTE_ONLY_ONE_TIME_UNTIL_RULE_WAS_END' | translate }}"
        [showLabel]="false"
      ></sc-checkbox-input>
    </div>
    <div class="ui-g-1 text-center" *ngIf="isEnd">
      <sc-checkbox-input
        controlName="executeOnlyOneTimeUntilRuleWasStart"
        [form]="group"
        label="{{ 'EXECUTE_ONLY_ONE_TIME_UNTIL_RULE_WAS_START' | translate }}"
        [showLabel]="false"
      ></sc-checkbox-input>
    </div>

    <div class="ui-g-1 text-center">
      <sc-checkbox-input
        controlName="executeOnlyIfDifferentFromLocal"
        [form]="group"
        label="{{ 'EXECUTE_ONLY_IF_DIFFERENT_FROM_LOCAL' | translate }}"
        [showLabel]="false"
      ></sc-checkbox-input>
    </div>

    <!-- SOURCE -->
    <div class="ui-g-3">
      <div [ngSwitch]="targetTypeId">
        <!-- DEVICE_ID FIELD -->
        <div *ngSwitchCase="'d'">
          <sc-dropdown
            controlName="deviceId"
            [form]="group"
            label="{{ 'DEVICE' | translate }}"
            [options]="devices"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <!-- DEVICE_ID FIELD (DVS) -->
        <div *ngSwitchCase="'dvs'">
          <sc-dropdown
            controlName="deviceId"
            [form]="group"
            label="{{ 'DEVICE' | translate }}"
            [options]="devices"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <!-- LOCATION_ID FIELD -->
        <div *ngSwitchCase="'l'">
          <div class="ui-g-12 p-0">
            <div class="ui-g-12 p-0">
              <sc-dropdown
                controlName="locationId"
                [form]="group"
                label="{{ 'LOCATION' | translate }}"
                [options]="optionData['locations']"
                [showLabel]="false"
              ></sc-dropdown>
            </div>
            <div class="ui-g-12 p-0">
              {{ locationPropertyTypeId }}
            </div>
          </div>
        </div>

        <!-- SERVICE_ID FIELD -->
        <div *ngSwitchCase="'s'">
          <div [ngSwitch]="serviceTypeId">
            <div *ngSwitchCase="'n'">{{ 'NOTIFIER' | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- ACTION -->
    <!-- DEVICE -->
    <div class="ui-g-2" *ngIf="deviceId">
      {{ action }}
    </div>
    <div class="ui-g-4 p-0" *ngIf="deviceId">
      <!-- PARAMETERS FIELD -->
      <div formArrayName="parameters">
        <div
          *ngFor="let parameter of parameters.controls; let i = index"
          [formGroupName]="i"
        >
          <sc-rule-command-parameter-form
            [group]="parameter"
            [groupId]="i"
            [devices]="devices"
          ></sc-rule-command-parameter-form>
        </div>
      </div>
    </div>

    <!-- SERVICE -->
    <div class="ui-g-6" *ngIf="serviceTypeId">
      <div [ngSwitch]="serviceTypeId">
        <div *ngSwitchCase="'n'">{{ notificationTemplateName }}</div>
      </div>
    </div>

    <!-- LOCATION -->
    <div class="ui-g-2" *ngIf="targetTypeId === 'l'">
      <div [ngSwitch]="locationModifyType">
        <div *ngSwitchCase="'adjustValue'">
          {{ 'ADJUST_VALUE' | translate }}
        </div>
        <div *ngSwitchCase="'forceValue'">{{ 'FORCE_VALUE' | translate }}</div>
      </div>
    </div>

    <div class="ui-g-2 ui-g-offset-2" *ngIf="targetTypeId === 'l'">
      <!-- <div [ngSwitch]="locationModifyType"> -->
      <!-- <div *ngSwitchCase="'adjustValue'">
          <div *ngIf="locationPropertyMappingValues">
            <sc-dropdown
              controlName="locationPropertyAdjustValue"
              [form]="group"
              label="{{ 'ADJUST_VALUE' | translate }}"
              [options]="locationPropertyMappingValues"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <div *ngIf="!locationPropertyMappingValues">
            <sc-text-input
              controlName="locationPropertyAdjustValue"
              [form]="group"
              label="{{ 'ADJUST_VALUE' | translate }}"
              [showLabel]="false"
            ></sc-text-input>
          </div>
        </div> -->

      <!-- <div *ngSwitchCase="'forceValue'"> -->
      <div *ngIf="locationPropertyMappingValues">
        <p-dropdown
          [formControl]="locationModifyValue"
          [options]="locationPropertyMappingValues"
          placeholder="{{ 'SELECT' | translate }} {{
            'FORCE_VALUE' | translate
          }}"
          filter="true"
          [style]="{ width: '100%' }"
          appendTo="body"
        ></p-dropdown>
        <!-- <sc-dropdown
              controlName="locationPropertyForceValue"
              [form]="group"
              label="{{ 'FORCE_VALUE' | translate }}"
              [options]="locationPropertyMappingValues"
              [showLabel]="false"
            ></sc-dropdown> -->
      </div>

      <div *ngIf="!locationPropertyMappingValues">
        <input pInputText type="text" [formControl]="locationModifyValue" />
        <!-- <sc-text-input
              controlName="locationPropertyForceValue"
              [form]="group"
              label="{{ 'FORCE_VALUE' | translate }}"
              [showLabel]="false"
            ></sc-text-input> -->
      </div>
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>

  <!-- DELAY -->
  <div class="ui-g-1 ">
    <sc-number-input
      controlName="delay"
      [form]="group"
      label="{{ 'DELAY' | translate }}"
      [showLabel]="false"
    ></sc-number-input>
  </div>
</div>
