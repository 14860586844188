<div class="sc-page-edit-menu">
  <ng-container *ngIf="!editMode">
    <button
      type="button"
      pButton
      class="green"
      icon="fa fa-pencil"
      pTooltip="{{ 'EDIT_PAGE' | translate }}"
      appendTo="body"
      tooltipPosition="left"
      (click)="toggleEditMode.emit()"
    ></button>
  </ng-container>

  <ng-container *ngIf="editMode">
    <button
      type="button"
      pButton
      icon="fa fa-plus"
      pTooltip="{{ 'ADD' | translate }} {{ 'WIDGET' | translate }}"
      appendTo="body"
      tooltipPosition="left"
      (click)="addWidget.emit()"
    ></button>

    <button
      type="button"
      pButton
      icon="fa fa-undo"
      pTooltip="{{ 'UNDO' | translate }}"
      appendTo="body"
      tooltipPosition="left"
      (click)="undo.emit()"
    ></button>

    <button
      type="button"
      pButton
      icon="fa fa-check"
      pTooltip="{{ 'SAVE' | translate }}"
      appendTo="body"
      tooltipPosition="left"
      (click)="save.emit()"
    ></button>

    <button
      type="button"
      pButton
      icon="fa fa-times"
      pTooltip="{{ 'CANCEL' | translate }}"
      appendTo="body"
      tooltipPosition="left"
      (click)="cancel.emit()"
    ></button>
  </ng-container>
</div>
