import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeviceBrand } from '../models/device-brand';

import { config } from '@app/config';

@Injectable()
export class DeviceBrandsService {
  private apiUrl = config().apiUrl + '/deviceBrands';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): DeviceBrand {
    const newData = Object.assign({}, data);
    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;
    return newData;
  }

  getDeviceBrand(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getDeviceBrands() {
    return this.httpClient.get(this.apiUrl);
  }

  createDeviceBrand(data: DeviceBrand) {
    return this.httpClient.post(this.apiUrl, this.verifyData(data));
  }

  updateDeviceBrand(id: number, data: DeviceBrand) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteDeviceBrand(id: number, force: boolean = false) {
    let apiUrl = `${this.apiUrl}/${id}`;
    if (force === true) {
      apiUrl += '?force';
    }
    return this.httpClient.patch(apiUrl, {
      isActive: false,
      isDeleted: true
    });
  }
}
