import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DevicesCreatorService {
  constructor(private formBuilder: FormBuilder) {}

  initDevicePropertyForm(editMode = false): FormGroup {
    const fields: any = {
      brandId: [null, Validators.required],
      description: [null, Validators.required],
      deviceTypeId: [null, Validators.required],
      deviceModelId: [null, Validators.required],
      protocolId: [null, Validators.required],
      siteId: [null, Validators.required],
      uniqueId: [null, Validators.required],
      diffDetectionFactor: null,
      excludeFromLHL: false,
      isActive: false,
      isHidden: false,
      isVirtualDevicesHolder: false,
      isVirtualDevice: true,
    };
    if (editMode) {
      fields.id = null;
    }
    return this.formBuilder.group(fields);
  }

  patchDeviceProperties(form: FormGroup, properties: any[], editMode = false) {
    const propertiesForm = form.get('properties') as FormArray;
    propertiesForm.clear();
    for (const property of properties) {
      const propertyForm = this.initDevicePropertyForm(editMode);
      propertyForm.patchValue(property);
      propertiesForm.push(propertyForm);
    }
  }
}
