import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { RoomUnoccupancyAvgComponent } from './room-unoccupancy-avg.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [RoomUnoccupancyAvgComponent],
  entryComponents: [RoomUnoccupancyAvgComponent],
  providers: []
})
export class RoomUnoccupancyAvgModule {}
