<p-overlayPanel #menu appendTo="body" styleClass="table-site-selector">
  <ul class="site-list">
    <li class="site-list-item" *ngFor="let o of options">
      <a (click)="selectSiteMode(o); menu.hide()">
        {{ o.label | translate }}
      </a>
    </li>
  </ul>
</p-overlayPanel>

<div
  class="table-site-selector"
  pTooltip="{{ 'SITE' | translate }}"
  tooltipPosition="top"
  appendTo="body"
>
  <button type="button" class="trigger-button" (click)="menu.toggle($event)">
    {{ siteMode }}
  </button>
</div>
