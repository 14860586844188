import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { Rule } from '@app/widgets/rules/models/rule';

@Component({
  selector: 'sc-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss']
})
export class LocationFormComponent implements OnInit {
  @Input()
  form: FormGroup;
  @Input() formId: number;
  @Input() data: {
    [key: string]: any;
    rawData: { [key: string]: any };
    selectItems: { [key: string]: SelectItem[] };
  };

  @Output() applyToAllDevices = new EventEmitter();
  @Output() applyToAllRules = new EventEmitter();
  @Output() applyToChildLocationDevices = new EventEmitter();
  @Output() applyToChildLocationRules = new EventEmitter();

  selectItems: { [key: string]: SelectItem[] };

  constructor() {}

  ngOnInit() {
    // console.log('LocationForm:Init:', this.data);
    this.selectItems = this.data.selectItems || {};
  }

  get deviceForms(): FormArray {
    return this.form.get('devices') as FormArray;
  }

  get ruleForms(): FormArray {
    return this.form.get('rules') as FormArray;
  }

  get childrenForms(): FormArray {
    return this.form.get('children') as FormArray;
  }

  // get dataPassThrough() {
  //   const form = { ...this.form.value };

  //   const locationSelf = { ...form };
  //   delete locationSelf.children;
  //   delete locationSelf.devices;
  //   delete locationSelf.rules;

  //   const devices = [...form.devices];
  //   const locations = [locationSelf /*, ...form.children*/];

  //   return {
  //     ...this.data,
  //     devices,
  //     locations
  //   };
  // }

  getDataPassThrough(index: number) {
    const formValue = { ...this.form.value };

    const locationSelf = { ...formValue };
    delete locationSelf.children;
    delete locationSelf.devices;
    delete locationSelf.rules;

    const devices = [...formValue.devices];
    const locations = [locationSelf /*, ...formValue.children*/];

    return {
      ...this.data,
      isFirstChildLocation:
        this.data.isFistLocation && index === 0 ? true : false,
      devices,
      locations
    };
  }

  onDeviceApplyAllChecked(event: { gatewayId: number; isActive: boolean }) {
    this.applyToAllDevices.emit(event);
  }

  onRuleApplyAllChecked(event: Rule) {
    this.applyToAllRules.emit(event);
  }

  onChildDeviceApplyAllChecked(event: {
    gatewayId: number;
    isActive: boolean;
  }) {
    this.applyToChildLocationDevices.emit(event);
  }

  onChildRuleApplyAllChecked(event: Rule) {
    this.applyToChildLocationRules.emit(event);
  }
}
