import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSites from '../reducers/sites.reducer';

import { Site } from '@widgets/sites/models/site';

import { sortBy } from 'lodash';

export const getSiteState = createFeatureSelector<fromSites.SiteState>('sites');

export const getSitesEntities = createSelector(getSiteState, fromSites.getSitesEntities);

export const getSitesTableEntities = createSelector(getSiteState, fromSites.getSitesTableEntities);

export const getSelectedSiteId = createSelector(getSiteState, fromSites.getSelectedId);

export const getSelectedSite = createSelector(
  getSitesEntities,
  getSelectedSiteId,
  (entities, siteId): Site => (entities && siteId ? entities[siteId] : {})
);

export const getSites = createSelector(getSitesEntities, (entities) =>
  entities
    ? sortBy(
        Object.keys(entities).map((id) => entities[id]),
        'name'
      )
    : []
);

export const getTableSites = createSelector(getSitesTableEntities, (entities) =>
  entities
    ? sortBy(
        Object.keys(entities).map((id) => entities[id]),
        'name'
      )
    : []
);

export const getAllActiveSites = createSelector(getSites, (sites) =>
  sites ? sites.filter((s) => s.isActive && !s.isDeleted) : []
);

export const getSiteFormData = createSelector(getSiteState, fromSites.getFormData);

export const getSiteFormDataLoading = createSelector(getSiteState, fromSites.getFormDataLoading);

export const getSiteFormDataError = createSelector(getSiteState, fromSites.getFormDataError);

export const getSiteLoading = createSelector(getSiteState, fromSites.getLoading);

export const getSiteLoaded = createSelector(getSiteState, fromSites.getLoaded);

export const getSitePending = createSelector(getSiteState, fromSites.getPending);

export const getSitePendingError = createSelector(getSiteState, fromSites.getPendingError);
