import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../../config';
import { RuleSchedulerJob } from '../models/rule-scheduler-job';

@Injectable()
export class RuleSchedulerJobsService {
  private apiUrl = `${config().apiUrl}/ruleSchedulerJobs`;

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): RuleSchedulerJob {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.ruleSchedulerJobId !== 'undefined') {
      delete newData.ruleSchedulerJobId;
    }
    if (typeof newData.companyId !== 'undefined') {
      delete newData.companyId;
    }
    if (typeof newData.siteId !== 'undefined') {
      delete newData.siteId;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  getRuleSchedulerJob(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getRuleSchedulerJobs(options?: { [key: string]: any }) {
    let queryParam: any = [];
    if (options) {
      for (const key in options) {
        if (options.hasOwnProperty(key)) {
          queryParam.push(`${key}=${options[key]}`);
        }
      }
    }
    queryParam = queryParam.length ? '?' + queryParam.join('&') : '';

    const apiUrl = `${this.apiUrl}${queryParam}`;

    return this.httpClient.get(apiUrl);
  }

  createRuleSchedulerJob(data: RuleSchedulerJob) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateRuleSchedulerJob(id: number, data: RuleSchedulerJob) {
    const apiUrl = `${this.apiUrl}/${id}`;
    return this.httpClient.patch(apiUrl, this.verifyData(data));
  }

  deleteRuleSchedulerJob(id: number) {
    const apiUrl = `${this.apiUrl}/${id}`;
    return this.httpClient.patch(apiUrl, { isDeleted: true, isActive: false });
  }
}
