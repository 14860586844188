import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-circle',
  styleUrls: ['circle.component.scss'],
  template: `
    <div class="sc-circle" [ngStyle]="customStyle" [ngClass]="circleClass">
      <div class="primary-text">
        <i *ngIf="icon" class="fa" [ngClass]="icon"></i>
        {{ displayPrimaryText }}
      </div>
      <div *ngIf="secondaryText" class="secondary-text">
        {{ displaySecondaryText }}
      </div>
    </div>
  `,
})
export class SCCircleComponent {
  @Input()
  customClass: string;
  @Input()
  customStyle: string;
  @Input()
  icon: string;
  @Input()
  primaryText: string | number;
  @Input()
  secondaryText: string | number;
  @Input()
  size: 'small' | 'large';

  get circleClass() {
    const c = [];
    if (this.size) {
      c.push(this.size);
    }
    if (this.customClass) {
      c.push(this.customClass);
    } else {
      c.push('grey lighten-2');
    }
    return c.join(' ');
  }

  get displayPrimaryText() {
    if (typeof this.primaryText === 'undefined' || this.primaryText === null) {
      return '';
    }
    return this.primaryText;
  }

  get displaySecondaryText() {
    if (typeof this.secondaryText === 'undefined' || this.secondaryText === null) {
      return '';
    }
    return this.secondaryText;
  }
}
