import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { ProtocolController } from '../models/protocol-controller';
import { ModalConfig } from '@sc/modal/modal-config';

import { ProtocolControllersService } from '../services/protocol-controllers.service';

import { SharedService } from '@shared/shared.service';

import { ProtocolControllerActionCellComponent } from '../protocol-controller-action-cell/protocol-controller-action-cell.component';
import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-protocol-controllers-table',
  templateUrl: 'protocol-controllers-table.component.html'
})
export class ProtocolControllersTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: ProtocolController[];
  formConfig: ModalConfig;
  tableTitle = 'PROTOCOL_CONTROLLER';
  private subscribers: { [key: string]: any } = {};

  constructor(
    private protocolControllersService: ProtocolControllersService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Table Columns
    this.columns = this.createColumns();

    // Create Form Config
    this.formConfig = {
      name: 'ProtocolControllerForm',
      options: { modalTitle: 'PROTOCOL_CONTROLLER_FORM_TITLE' }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.DATASET = this.protocolControllersService
      .getProtocolControllers()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: ProtocolControllerActionCellComponent
      }
    ];
  }
}
