import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-number-input',
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
        [description]="description"
      ></sc-field-label>

      <div [ngClass]="{ 'ui-inputgroup': prefix || suffix }">
        <span class="ui-inputgroup-addon" *ngIf="prefix"> {{ prefix }} </span>
        <input
          pInputText
          type="number"
          [id]="inputId"
          [formControlName]="controlName"
          [placeholder]="placeholder"
          [max]="max"
          [min]="min"
          [step]="step"
          autocomplete="off"
        />
        <span class="ui-inputgroup-addon" *ngIf="suffix"> {{ suffix }} </span>
      </div>

      <sc-field-error [for]="label" [fieldControl]="form.controls[controlName]"></sc-field-error>
    </div>
  `,
})
export class SCNumberInputComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  placeholder: string;
  @Input()
  prefix: string;
  @Input()
  suffix: string;
  @Input()
  min: number;
  @Input()
  max: number;
  @Input()
  step: number;

  inputId: string;

  constructor() {}

  ngOnInit() {
    this.placeholder = this.placeholder || '';
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.inputId = 'sc-input-number_' + this.controlName + new Date().getTime();
  }
}
