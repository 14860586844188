export enum ObjectClasses {
  Grid = 'Grid',
  GridItem = 'GridItem',
  GridOverlay = 'GridOverlay',
  CountingLine = 'CountingLine',
  Scope = 'Scope',
}

export enum ObjectLayers {
  Grid = 'grid',
  GridItem = 'gridItem',
  GridOverlay = 'gridOverlay',
  CountingLine = 'countingLine',
  Scope = 'scope',
}

export enum ObjectTypes {
  Grid = 'grid',
  GridItem = 'gridItem',
  GridOverlay = 'gridOverlay',
  CountingLine = 'countingLine',
  Scope = 'scope',
}
