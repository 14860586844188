import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Rule, RuleTemplate } from '@app/ceos-rule-editor/models';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { Site } from '@widgets/sites/models/site';
import { CeosRuleTemplatesService } from '@widgets/rule-templates/services/ceos-rule-templates.service';
import { CeosRulesService } from '@widgets/rules/services/ceos-rules.service';
import { CustomFunction } from '@widgets/custom-functions/models/custom-function';
import { CustomFunctionsService } from '@widgets/custom-functions/services/custom-functions.service';
import { NotificationTemplatesService } from '@widgets/notification-templates/services/notification-templates.service';
import { RuleCategoriesService } from '@widgets/rule-categories/services/rule-categories.service';
import { SharedService } from '@shared/shared.service';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { RuleCategory } from '@app/widgets/rule-categories/models/rule-category';
import { ReportTemplate } from '@app/widgets/report-templates/interfaces';
import { ReportTemplatesService } from '@widgets/report-templates/services/report-templates.service';
import { UserTaskTemplate } from '@app/widgets/user-task-templates/interfaces/user-task-template';
import { UserTaskTemplatesService } from '@app/widgets/user-task-templates/services';

interface CeosRuleTemplateDetails extends RuleTemplate {
  [key: string]: any;
}

@Component({
  selector: 'sc-ceos-rule-template-form',
  templateUrl: 'ceos-rule-template-form.component.html',
  styleUrls: ['ceos-rule-template-form.component.scss'],
})
export class CeosRuleTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode: boolean;
  errorMessage: string;
  isSubmitting = false;
  fetchingState = 0;

  ruleTemplate: CeosRuleTemplateDetails;
  rules: Rule[] = [];
  ruleTemplates: RuleTemplate[] = [];
  customFunctions: CustomFunction[] = [];
  customGlobals: any[] = [];
  deviceTypes: any[] = [];
  notificationTemplates: any = [];
  locationProperties: any[] = [];
  ruleCategories: any[] = [];
  scaffold: any = {};
  reportTemplates: ReportTemplate[] = [];
  userTaskTemplates: UserTaskTemplate[] = [];
  selectedDevice: DeviceDetails;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private ceosRuleTemplatesService: CeosRuleTemplatesService,
    private ceosRulesService: CeosRulesService,
    private customFunctionsService: CustomFunctionsService,
    private notificationTemplatesService: NotificationTemplatesService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private ruleCategoriesService: RuleCategoriesService,
    private reportTemplatesService: ReportTemplatesService,
    private userTaskTemplatesService: UserTaskTemplatesService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;

    // get device details
    this.subscribers.selectedDevice = this.store.select(fromStore.getDeviceDetials).subscribe((result) => {
      if (result) {
        this.selectedDevice = result;

        if (!this.editMode) {
          this.fetchRules();
        }
      }
    });

    // check edit mode
    if (this.data && this.data.id) {
      this.editMode = true;
      this.fetchRuleTemplate(this.data.id);
    } else {
      this.ruleTemplate = {
        name: null,
        rule_category_id: 5, // ! set default category to `Other` quick & dirty
        conditions: {
          AND: [
            {
              key: new Date().getTime() - 1 + '',
              left: { type: null },
              operator: { type: null },
              right: { type: null },
            },
          ],
        },
        commands: [{ key: new Date().getTime() + 1 + '', type: null }],
        is_active: false,
        is_deleted: false,
      };
    }

    this.fetchCeosScaffold();
    this.fetchNotificationTemplates();
    this.fetchCustomFunction();
    this.fetchRuleCategories();
    this.fetchReportTemplates();
    this.fetchUserTaskTemplates();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchCeosScaffold() {
    this.fetchingState++;
    this.subscribers.getCeosScaffold = this.sharedService.getCeosScaffold().subscribe({
      next: (result: any) => {
        this.scaffold = result.data;
        this.deviceTypes = Object.keys(this.scaffold.device_types)
          .filter((key) => key !== 'a' && key !== 'vdh')
          .map((key) => ({
            ...this.scaffold.device_types[key],
            key,
          }));
        this.locationProperties = Object.keys(this.scaffold.location_properties).map((key) => ({
          ...this.scaffold.location_properties[key],
          key,
        }));
        this.fetchingState--;
      },
    });
  }

  private fetchNotificationTemplates() {
    this.fetchingState++;
    this.subscribers.getNotificationTemplates = this.notificationTemplatesService.get().subscribe({
      next: (result: any) => {
        this.notificationTemplates = result.data.filter((d) => {
          const siteIds = d.siteIds || [];
          return d.isActive && !d.isDeleted && (d.isPublic || siteIds.indexOf(this.selectedSite.id) >= 0);
        });
        this.fetchingState--;
      },
    });
  }

  private fetchReportTemplates() {
    this.fetchingState++;
    this.subscribers.fetchReportTemplates = this.reportTemplatesService.getReportTemplates().subscribe({
      next: (result: any) => {
        this.reportTemplates = result.data.filter((d) => {
          return d.is_active && !d.is_deleted;
        });
        this.fetchingState--;
      },
    });
  }

  private fetchUserTaskTemplates() {
    this.fetchingState++;
    this.subscribers.fetchUserTaskTemplates = this.userTaskTemplatesService.getUserTaskTemplates().subscribe({
      next: (result: any) => {
        this.userTaskTemplates = result.data.filter((d) => {
          return d.is_active && !d.is_deleted;
        });
        this.fetchingState--;
      },
    });
  }

  private fetchRules() {
    this.fetchingState++;
    const options: any = {
      automationId: this.selectedDevice.id,
    };
    this.subscribers.getCeosRules = this.ceosRulesService.getCeosRules(options).subscribe({
      next: (result: any) => {
        this.rules = result.data.filter((item: Rule) => !item.is_deleted);
        this.fetchingState--;
      },
    });
  }

  private fetchRuleTemplate(id: number) {
    this.fetchingState++;
    this.subscribers.getCeosRule = this.ceosRuleTemplatesService.getCeosRuleTemplate(id).subscribe({
      next: (result: any) => {
        this.ruleTemplate = result.data;
        this.ruleTemplate.commands = JSON.parse(result.data.commands as string);
        this.ruleTemplate.conditions = JSON.parse(result.data.conditions as string);
        this.fetchingState--;
      },
    });
  }

  private fetchCustomFunction() {
    this.fetchingState++;
    this.subscribers.fetchCustomFunction = this.customFunctionsService.getCustomFunctions().subscribe({
      next: (result: any) => {
        this.customFunctions = result.data.filter((item: CustomFunction) => !item.is_deleted);
        this.fetchingState--;
      },
    });
  }

  private fetchRuleCategories() {
    this.fetchingState++;
    this.subscribers.fetchRuleCategories = this.ruleCategoriesService.getRuleCategories().subscribe({
      next: (result: any) => {
        this.ruleCategories = result.data
          .filter((item: RuleCategory) => item.isActive && !item.isDeleted)
          .map((item) => {
            const name = JSON.parse(item.name);
            const data = {
              label: name ? name.en : item.key,
              value: item.id,
            };
            return data;
          });
        this.fetchingState--;
      },
    });
  }

  submit() {
    if (this.editMode) {
      this.update(this.ruleTemplate.id, this.ruleTemplate);
    } else {
      this.create(this.ruleTemplate);
    }
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('RULE_TEMPLATE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  private create(data: RuleTemplate) {
    this.subscribers.create = this.ceosRuleTemplatesService.createCeosRuleTemplate(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(id: number, data: RuleTemplate) {
    this.subscribers.update = this.ceosRuleTemplatesService.updateCeosRuleTemplate(id, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.ceosRuleTemplatesService.deleteCeosRuleTemplate(this.ruleTemplate.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }
}
