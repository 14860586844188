import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { RuleTemplate } from '../models/rule-template';
import { Site } from '@widgets/sites/models/site';

import { DeviceTypesService } from '@app/widgets/device-types/services/device-types.service';
import { LocationPropertiesService } from '@widgets/location-properties/services/location-properties.service';
import { NotificationTemplatesService } from '@widgets/notification-templates/services/notification-templates.service';
import { RuleTemplateFormsService } from '../services/rule-template-form.service';
import { RuleTemplatesService } from '../services/rule-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-template-form',
  templateUrl: './rule-template-form.component.html',
})
export class RuleTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  currentCommandAndConditionForm = 'start';
  formData: RuleTemplate;
  form: FormGroup;
  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  isSubmitting = false;
  selectItems: { [prop: string]: SelectItem[] } = {};

  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private deviceTypesService: DeviceTypesService,
    private notificationTemplatesService: NotificationTemplatesService,
    private locationPropertiesService: LocationPropertiesService,
    private ruleTemplatesService: RuleTemplatesService,
    private ruleTemplateFormsService: RuleTemplateFormsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;
    this.selectItems = this.sharedService.selectItems;
    this.selectItems.sites = this.sharedService.createSelectItems(this.sharedService.sourceData.sites);

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchRuleTemplate(this.data.id);
    }

    this.fetchDeviceTypes();
    this.fetchNotificationTemplates();
    this.fetchLocationProperties();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchRuleTemplate(id: number) {
    this.fetchingState++;
    this.subscribers.getRuleTemplate = this.ruleTemplatesService.getRuleTemplates(id).subscribe({
      next: (result: any) => {
        this.formData = result.data;

        this.formData.siteIds = this.formData.siteIds ? this.sharedService.jsonParse(this.formData.siteIds) : [];

        if (this.formData.energySavingPatternsEnd) {
          this.formData.energySavingPatternsEnd = (<string>this.formData.energySavingPatternsEnd).split(',');
        }

        if (this.formData.energySavingPatternsStart) {
          this.formData.energySavingPatternsStart = (<string>this.formData.energySavingPatternsStart).split(',');
        }

        this.form.patchValue(this.formData);

        if (this.formData.startConditions) {
          this.ruleTemplateFormsService.setConditionFormArray(this.form, this.formData.startConditions);
        }
        if (this.formData.startCommands) {
          this.ruleTemplateFormsService.setCommandFormArray(this.form, this.formData.startCommands);
        }
        if (this.formData.endConditions) {
          this.ruleTemplateFormsService.setConditionFormArray(this.form, this.formData.endConditions, true);
        }
        if (this.formData.endCommands) {
          this.ruleTemplateFormsService.setCommandFormArray(this.form, this.formData.endCommands, true);
        }

        this.fetchingState--;
      },
    });
  }

  private fetchNotificationTemplates() {
    this.fetchingState++;
    this.subscribers.getNotificationTemplates = this.notificationTemplatesService.get().subscribe({
      next: (result: any) => {
        const notificationTemplates = result.data.filter((d) => {
          const siteIds = d.siteIds || [];
          return d.isActive && !d.isDeleted && (d.isPublic || siteIds.indexOf(this.selectedSite.id) >= 0);
        });
        this.selectItems.notificationTemplates = this.sharedService.createSelectItems(notificationTemplates);
        this.fetchingState--;
      },
    });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.getDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe({
      next: (result: any) => {
        this.selectItems.deviceTypesList = result.data;
        this.selectItems.deviceTypes = this.sharedService.createSelectItems(result.data.filter((d) => !d.isDeleted));
        this.fetchingState--;
      },
    });
  }

  private fetchLocationProperties() {
    this.fetchingState++;
    this.subscribers.getLocationProperties = this.locationPropertiesService.getLocationProperties().subscribe({
      next: (result: any) => {
        this.selectItems.locationPropertyTypes = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted),
          false,
          'key'
        );
        this.selectItems.locationPropertiesList = result.data;
        this.fetchingState--;
      },
    });
  }

  // INIT FORM
  private initForm() {
    this.form = this.ruleTemplateFormsService.initRuleTemplateForm();

    this.subscribers.isPublic = this.form.get('isPublic').valueChanges.subscribe((value) => {
      if (value) {
        this.form.get('siteIds').reset();
        this.form.get('siteIds').disable();
      } else {
        this.form.get('siteIds').enable();
      }
    });

    this.subscribers.endConditionConnector = this.form.get('endConditionConnector').valueChanges.subscribe((value) => {
      if (value) {
        this.setConditionConnector();
      }
    });

    this.subscribers.startConditionConnector = this.form
      .get('startConditionConnector')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.setConditionConnector();
        }
      });
  }

  showCommandAndConditionForm(type: string) {
    this.currentCommandAndConditionForm = type;
  }

  // COMMANDS FORM
  addCommand() {
    const commands = this.currentCommandAndConditionForm === 'start' ? 'startCommands' : 'endCommands';
    const isEnd = this.currentCommandAndConditionForm === 'start' ? false : true;

    const control = <FormArray>this.form.controls[commands];
    const form = this.ruleTemplateFormsService.initCommandFormGroup(isEnd);

    form.removeControl('id');

    if (this.editMode) {
      form.patchValue({ ruleTemplateId: this.formData.id });
    } else {
      form.controls['ruleTemplateId'].clearValidators();
      form.removeControl('ruleTemplateId');
    }

    control.push(form);
  }

  removeCommand(i: number) {
    const commands = this.currentCommandAndConditionForm === 'start' ? 'startCommands' : 'endCommands';

    const control = <FormArray>this.form.controls[commands];
    control.removeAt(i);
  }

  get startCommands(): FormArray {
    return this.form.controls['startCommands'] as FormArray;
  }

  get endCommands(): FormArray {
    return this.form.controls['endCommands'] as FormArray;
  }

  // CONDITIONS FORM
  addCondition() {
    const conditions = this.currentCommandAndConditionForm === 'start' ? 'startConditions' : 'endConditions';

    const control = <FormArray>this.form.controls[conditions];
    const form = this.ruleTemplateFormsService.initConditionFormGroup();
    form.removeControl('id');
    this.setConditionConnector(form);
    // if (control.length > 0) {
    //   form.patchValue({ connector: 'AND' });
    // }

    if (this.editMode) {
      form.patchValue({ ruleTemplateId: this.formData.id });
    } else {
      form.controls['ruleTemplateId'].clearValidators();
      form.removeControl('ruleTemplateId');
    }

    control.push(form);
  }

  removeCondition(i: number) {
    const conditions = this.currentCommandAndConditionForm === 'start' ? 'startConditions' : 'endConditions';

    const control = <FormArray>this.form.controls[conditions];
    control.removeAt(i);
  }

  get startConditions(): FormArray {
    return this.form.controls['startConditions'] as FormArray;
  }
  get endConditions(): FormArray {
    return this.form.controls['endConditions'] as FormArray;
  }

  private setConditionConnector(form?: FormGroup) {
    const connectorKey =
      this.currentCommandAndConditionForm === 'start' ? 'startConditionConnector' : 'endConditionConnector';
    const connector = this.form.get(connectorKey).value;

    if (connector) {
      if (form) {
        form.patchValue({ connector });
      } else {
        const conditions = this.currentCommandAndConditionForm === 'start' ? 'startConditions' : 'endConditions';

        const conditionsForm = <FormArray>this.form.controls[conditions];
        conditionsForm.controls.forEach((ctrl) => {
          ctrl.patchValue({ connector });
        });
      }
    }
  }

  // API CALL
  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = Object.assign({}, this.form.getRawValue());
      delete formData.id;

      if (formData.energySavingPatternsEnd) {
        formData.energySavingPatternsEnd = formData.energySavingPatternsEnd.join(',');
      }

      if (formData.energySavingPatternsStart) {
        formData.energySavingPatternsStart = formData.energySavingPatternsStart.join(',');
      }

      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(this.formData.id, formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create(data: RuleTemplate) {
    this.subscribers.create = this.ruleTemplatesService.createRuleTemplate(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(id: number, data: RuleTemplate) {
    this.subscribers.update = this.ruleTemplatesService.updateRuleTemplate(id, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.ruleTemplatesService.deleteRuleTemplate(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('RULE_TEMPLATE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
