import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sc-location-shape-form',
  templateUrl: './location-shape-form.component.html',
  styleUrls: ['./location-shape-form.component.scss'],
})
export class LocationShapeFormComponent implements OnInit {
  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef
  ) {}

  ngOnInit() {}

  selectShape(index: number) {
    this.dialog.close(index);
  }

  drawShape() {
    this.dialog.close(0);
  }
}
