import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../../config';

@Injectable()
export class EnergySavingsService {
  private apiUrl = `${config().apiUrl}/energySavings`;

  constructor(private httpClient: HttpClient) {}

  getEnergySavings(siteId: number) {
    const apiUrl = `${this.apiUrl}?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getEnergySavingsTotal(siteId: number) {
    const apiUrl = `${this.apiUrl}/total?siteId=${siteId}`;
    return this.httpClient.get(apiUrl);
  }

  getLocationEnergySavings(locationId: number) {
    const apiUrl = `${this.apiUrl}/location/${locationId}`;
    return this.httpClient.get(apiUrl);
  }
}
