import { Directive } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';

@Directive({ selector: '[scAppStyle]' })
export class AppStyleDirective {
  private selectedSite: Site;

  constructor(private store: Store<fromStore.State>) {
    this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && result.id && (!this.selectedSite || result !== this.selectedSite)) {
        this.selectedSite = result;
        this.store.dispatch(new fromStore.LoadAppStyle(this.selectedSite));
      }
    });
  }
}
