import { Action } from '@ngrx/store';
import { SceneTemplate } from '@widgets/scene-templates/models/scene-template';

// ____ LOAD SCENE TEMPLATE
export const LOAD_SCENE_TEMPLATE = '[Scene Templates] Load Scene Template';
export const LOAD_SCENE_TEMPLATE_FAIL = '[Scene Templates] Load Scene Template Fail';
export const LOAD_SCENE_TEMPLATE_SUCCESS = '[Scene Templates] Load Scene Template Success';

export class LoadSceneTemplate implements Action {
  readonly type = LOAD_SCENE_TEMPLATE;
  constructor(public payload: number) {}
}

export class LoadSceneTemplateFail implements Action {
  readonly type = LOAD_SCENE_TEMPLATE_FAIL;
  constructor(public payload: any) {}
}

export class LoadSceneTemplateSuccess implements Action {
  readonly type = LOAD_SCENE_TEMPLATE_SUCCESS;
  constructor(public payload: SceneTemplate) {}
}

// ____ LOAD SCENE TEMPLATES
export const LOAD_SCENE_TEMPLATES = '[Scene Templates] Load Scene Templates';
export const LOAD_SCENE_TEMPLATES_FAIL = '[Scene Templates] Load Scene Templates Fail';
export const LOAD_SCENE_TEMPLATES_SUCCESS = '[Scene Templates] Load Scene Templates Success';

export class LoadSceneTemplates implements Action {
  readonly type = LOAD_SCENE_TEMPLATES;
}

export class LoadSceneTemplatesFail implements Action {
  readonly type = LOAD_SCENE_TEMPLATES_FAIL;
  constructor(public payload: any) {}
}

export class LoadSceneTemplatesSuccess implements Action {
  readonly type = LOAD_SCENE_TEMPLATES_SUCCESS;
  constructor(public payload: SceneTemplate[]) {}
}

// ____ CREATE SCENE TEMPLATE
export const CREATE_SCENE_TEMPLATE = '[Scene Templates] Create Scene Template';
export const CREATE_SCENE_TEMPLATE_FAIL = '[Scene Templates] Create Scene Template Fail';
export const CREATE_SCENE_TEMPLATE_SUCCESS = '[Scene Templates] Create Scene Template Success';

export class CreateSceneTemplate implements Action {
  readonly type = CREATE_SCENE_TEMPLATE;
  constructor(public payload: SceneTemplate) {}
}

export class CreateSceneTemplateFail implements Action {
  readonly type = CREATE_SCENE_TEMPLATE_FAIL;
  constructor(public payload: any) {}
}

export class CreateSceneTemplateSuccess implements Action {
  readonly type = CREATE_SCENE_TEMPLATE_SUCCESS;
  constructor(public payload: SceneTemplate) {}
}

// ____ UPDATE SCENE TEMPLATE
export const UPDATE_SCENE_TEMPLATE = '[Scene Templates] Update Scene Template';
export const UPDATE_SCENE_TEMPLATE_FAIL = '[Scene Templates] Update Scene Template Fail';
export const UPDATE_SCENE_TEMPLATE_SUCCESS = '[Scene Templates] Update Scene Template Success';

export class UpdateSceneTemplate implements Action {
  readonly type = UPDATE_SCENE_TEMPLATE;
  constructor(public payload: SceneTemplate) {}
}

export class UpdateSceneTemplateFail implements Action {
  readonly type = UPDATE_SCENE_TEMPLATE_FAIL;
  constructor(public payload: any) {}
}

export class UpdateSceneTemplateSuccess implements Action {
  readonly type = UPDATE_SCENE_TEMPLATE_SUCCESS;
  constructor(public payload: SceneTemplate) {}
}

// ____ DELETE SCENE TEMPLATE
export const DELETE_SCENE_TEMPLATE = '[Scene Templates] Delete Scene Template';
export const DELETE_SCENE_TEMPLATE_FAIL = '[Scene Templates] Delete Scene Template Fail';
export const DELETE_SCENE_TEMPLATE_SUCCESS = '[Scene Templates] Delete Scene Template Success';

export class DeleteSceneTemplate implements Action {
  readonly type = DELETE_SCENE_TEMPLATE;
  constructor(public payload: SceneTemplate) {}
}

export class DeleteSceneTemplateFail implements Action {
  readonly type = DELETE_SCENE_TEMPLATE_FAIL;
  constructor(public payload: any) {}
}

export class DeleteSceneTemplateSuccess implements Action {
  readonly type = DELETE_SCENE_TEMPLATE_SUCCESS;
  constructor(public payload: SceneTemplate) {}
}

// Reset Pending
export const SCENE_TEMPLATE_RESET_PENDING_STATE = '[Scene Templates] Reset pending state';

export class SceneTemplateResetPendingState implements Action {
  readonly type = SCENE_TEMPLATE_RESET_PENDING_STATE;
}

// ACTION TYPES
export type SceneTemplatesAction =
  | LoadSceneTemplate
  | LoadSceneTemplateFail
  | LoadSceneTemplateSuccess
  | LoadSceneTemplates
  | LoadSceneTemplatesFail
  | LoadSceneTemplatesSuccess
  | CreateSceneTemplate
  | CreateSceneTemplateFail
  | CreateSceneTemplateSuccess
  | UpdateSceneTemplate
  | UpdateSceneTemplateFail
  | UpdateSceneTemplateSuccess
  | DeleteSceneTemplate
  | DeleteSceneTemplateFail
  | DeleteSceneTemplateSuccess
  | SceneTemplateResetPendingState;
