import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'sc-device-type-action-form',
  templateUrl: './action-form.component.html'
})
export class DeviceTypeActionFormComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Output()
  removed = new EventEmitter();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {}

  remove() {
    this.removed.emit();
  }

  initParam() {
    return this.formBuilder.group({
      parameterId: [null, Validators.required],
      type: null,
      defaultValues: null
    });
  }

  addParam() {
    const control = <FormArray>this.group.controls['parameters'];
    control.push(this.initParam());
  }

  removeParam(i: number) {
    const control = <FormArray>this.group.controls['parameters'];
    control.removeAt(i);
  }

  get parameters(): FormArray {
    return this.group.get('parameters') as FormArray;
  }
}
