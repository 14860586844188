import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { EventsService } from '../services/events.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-event-action-cell',
  templateUrl: './event-action-cell.component.html'
})
export class EventActionCellComponent implements ICellRendererAngularComp {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  constructor(
    private eventsService: EventsService,
    private sharedService: SharedService
  ) { }

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'EventForm',
      data: this.cell.data,
      options: { modalTitle: 'EVENT_FORM_TITLE', fullScreen: true }
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: 'event_u',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this)
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: 'event_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this)
      }
    ];
  }

  refresh(): boolean {
    return false;
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.eventsService.deleteEvent(this.cell.data.id).subscribe(
      (result: any) => {
        this.cell.context.parentComponent.updateRow({
          id: this.cell.data.id,
          isActive: false,
          isDeleted: true
        });

        // show notification
        const text = this.sharedService.getTranslation('UPDATE_SUCCESS');
        const title = this.sharedService.getTranslation('EVENT');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const text = this.sharedService.getTranslation('UPDATE_FAIL');
        const title = this.sharedService.getTranslation('EVENT');
        this.sharedService.notify(title, text, 'success');
      }
    );
  }
}
