import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { LocationTemplate } from '../models/location-template';

// import { DeviceBrandsService } from '@widgets/devices/services/device-brands.service';
import { DeviceModelsService } from '@widgets/device-models/services/device-models.service';
// import { DeviceProtocolsService } from '@widgets/devices/services/device-protocols.service';
import { DeviceTypesService } from '@app/widgets/device-types/services/device-types.service';
import { LocationTypesService } from '@widgets/locations/services/location-types.service';
import { LocationProfilesService } from '@widgets/location-profiles/services/location-profile.service';
import { LocationTemplateFormsService } from '../services/location-template-forms.service';
import { LocationTemplatesService } from '../services/location-templates.service';
import { RuleTemplatesService } from '@widgets/rule-templates/services/rule-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-template-form',
  templateUrl: 'location-template-form.component.html'
})
export class LocationTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: LocationTemplate;
  form: FormGroup;
  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  isSubmitting = false;
  subscribers: { [key: string]: any } = {};
  selectItems: { [key: string]: SelectItem[] } = {};

  constructor(
    private formService: LocationTemplateFormsService,
    private deviceModelsService: DeviceModelsService,
    // private deviceBrandsService: DeviceBrandsService,
    // private deviceProtocolsService: DeviceProtocolsService,
    private deviceTypesService: DeviceTypesService,
    private locationTypesService: LocationTypesService,
    private locationProfilesService: LocationProfilesService,
    private locationTemplatesService: LocationTemplatesService,
    private ruleTemplatesService: RuleTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.data) {
      // Edit Mode
      this.editMode = true;
      // Fetch Location Template
      this.fetchLocationTemplate(this.data.id);
    }

    this.fetchDeviceModels();
    // this.fetchDeviceBrands();
    this.fetchDeviceTypes();
    // this.fetchDeviceProtocols();
    this.fetchLocationTypes();
    this.fetchLocationProfiles();
    this.fetchRuleTemplates();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchLocationTemplate(id: number) {
    this.fetchingState++;
    this.subscribers.GET_LOCATION_TEMPLATE = this.locationTemplatesService
      .getLocationTemplate(id)
      .subscribe((result: any) => {
        this.formData = result.data;

        // this.formData.devices = this.sharedService.jsonParse(
        //   this.formData.devices
        // );
        // this.formData.ruleTemplates = this.sharedService.jsonParse(
        //   this.formData.ruleTemplates
        // );
        // this.formData.children = this.sharedService.jsonParse(
        //   this.formData.children
        // );

        this.form.patchValue(this.formData);

        this.formService.setDeviceFormArray(this.form, this.formData.devices);
        this.formService.setRuleFormArray(
          this.form,
          this.formData.ruleTemplates
        );
        this.formService.setChildrenFormArray(
          this.form,
          this.formData.children
        );
        this.fetchingState--;
      });
  }

  private fetchDeviceModels() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_MODELS = this.deviceModelsService
      .getDeviceModels()
      .subscribe((result: any) => {
        this.selectItems.rawDeviceModels = result.data;
        this.selectItems.deviceModels = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
      });
    this.fetchingState--;
  }

  // private fetchDeviceBrands() {
  //   this.fetchingState++;
  //   this.subscribers.GET_DEVICE_BRANDS = this.deviceBrandsService
  //     .getDeviceBrands()
  //     .subscribe((result: any) => {
  //       this.selectItems.deviceBrands = this.sharedService.createSelectItems(
  //         result.data.filter(d => d.isActive && !d.isDeleted)
  //       );
  //     });
  //   this.fetchingState--;
  // }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_TYPES = this.deviceTypesService
      .getDeviceTypes()
      .subscribe((result: any) => {
        this.selectItems.deviceTypes = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
      });
    this.fetchingState--;
  }

  // private fetchDeviceProtocols() {
  //   this.fetchingState++;
  //   this.subscribers.GET_DEVICE_PROTOCOLS = this.deviceProtocolsService
  //     .getDeviceProtocols()
  //     .subscribe((result: any) => {
  //       this.selectItems.deviceProtocols = this.sharedService.createSelectItems(
  //         result.data.filter(d => d.isActive && !d.isDeleted)
  //       );
  //     });
  //   this.fetchingState--;
  // }

  private fetchLocationTypes() {
    this.fetchingState++;
    this.subscribers.GET_LOCATION_TYPES = this.locationTypesService
      .getLocationTypes()
      .subscribe((result: any) => {
        this.selectItems.locationTypes = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
      });
    this.fetchingState--;
  }

  private fetchLocationProfiles() {
    this.fetchingState++;
    this.subscribers.GET_LOCATION_PROFILES = this.locationProfilesService
      .getLocationProfiles()
      .subscribe((result: any) => {
        this.selectItems.locationProfiles = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
      });
    this.fetchingState--;
  }

  private fetchRuleTemplates() {
    this.fetchingState++;
    this.subscribers.GET_RULE_TEMPLATES = this.ruleTemplatesService
      .getRuleTemplates()
      .subscribe((result: any) => {
        this.selectItems.ruleTemplates = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive && !d.isDeleted)
        );
        this.fetchingState--;
      });
  }

  private initForm() {
    this.form = this.formService.initLocationTemplateForm();
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = this.form.value;

      // call api
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(formData) {
    this.subscribers.CREATE_LOCATION_TEMPLATE = this.locationTemplatesService
      .createLocationTemplate(formData)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  update(formData) {
    this.subscribers.UPDATE_LOCATION_TEMPLATE = this.locationTemplatesService
      .updateLocationTemplate(this.formData.id, formData)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE_LOCATION_TEMPLATE = this.locationTemplatesService
      .deleteLocationTemplate(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('LOCATION_TEMPLATE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
