<div [formGroup]="form" class="ui-g ui-g-nopad">
  <!-- LOCATION_TYPE FIELD -->
  <div class="ui-g-12">
    <sc-dropdown
      controlName="locationTypeId"
      [form]="form"
      label="{{ 'LOCATION_TYPE' | translate }}"
      [options]="selectItems['locationTypes']"
    ></sc-dropdown>
  </div>

  <!-- LOCATION_PROFILES FIELD -->
  <div class="ui-g-12">
    <sc-dropdown
      controlName="locationProfileId"
      [form]="form"
      label="{{ 'LOCATION_PROFILE' | translate }}"
      [options]="selectItems['locationProfiles']"
    ></sc-dropdown>
  </div>

  <!-- DESCRIPTION FIELD -->
  <div class="ui-g-12">
    <sc-textarea
      controlName="description"
      [form]="form"
      label="{{ 'DESCRIPTION' | translate }}"
    ></sc-textarea>
  </div>

  <!-- RULE_TEMPLATES -->
  <div class="ui-g-12">
    <div class="sc-flex align-items-center justify-content-between">
      <h3 class="m-0">{{ 'RULE_TEMPLATES' | translate }}</h3>

      <button
        pButton
        type="button"
        class="ui-button-success"
        icon="fa fa-plus"
        (click)="addRuleTemplate()"
        pTooltip="{{ 'ADD' | translate }} {{ 'RULE_TEMPLATE' | translate }}"
        appendTo="body"
      ></button>
    </div>
  </div>

  <div formArrayName="ruleTemplates" class="ui-g-12">
    <div
      *ngFor="
        let ruleTemplateControl of ruleTemplatesFormArray.controls;
        let i = index
      "
      [formGroupName]="i"
    >
      <sc-rule-template-form
        [group]="ruleTemplateControl"
        [groupId]="i"
        [optionData]="selectItems"
        (removed)="removeRuleTemplate(i)"
      ></sc-rule-template-form>
    </div>
  </div>

  <!-- DEVICES -->
  <div class="ui-g-12">
    <div class="sc-flex align-items-center justify-content-between">
      <h3 class="m-0">{{ 'DEVICES' | translate }}</h3>

      <button
        pButton
        type="button"
        class="ui-button-success"
        icon="fa fa-plus"
        (click)="addDevice()"
        pTooltip="{{ 'ADD' | translate }} {{ 'DEVICE' | translate }}"
        appendTo="body"
      ></button>
    </div>
  </div>

  <div formArrayName="devices" class="ui-g-12">
    <div
      *ngFor="let deviceControl of devicesFormArray.controls; let i = index"
      [formGroupName]="i"
    >
      <sc-device-form
        [group]="deviceControl"
        [groupId]="i"
        [optionData]="selectItems"
        (removed)="removeDevice(i)"
      ></sc-device-form>
    </div>
  </div>

  <!-- CHILDREN -->
  <div class="ui-g-12">
    <div class="sc-flex align-items-center justify-content-between">
      <h3 class="m-0">{{ 'CHILDREN' | translate }}</h3>

      <button
        pButton
        type="button"
        class="ui-button-success"
        icon="fa fa-plus"
        (click)="addChild()"
        pTooltip="{{ 'ADD' | translate }} {{ 'CHILD' | translate }}"
        appendTo="body"
      ></button>
    </div>
  </div>

  <div formArrayName="children" class="ui-g-12">
    <div
      *ngFor="let childControl of childrenFormArray.controls; let i = index"
      [formGroupName]="i"
    >
      <sc-child-form
        [group]="childControl"
        [groupId]="i"
        [optionData]="selectItems"
        (removed)="removeChild(i)"
      ></sc-child-form>
    </div>
  </div>
</div>
