import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Currency } from '../models/currency';

import { CurrenciesService } from '../services/currencies.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-currency-form',
  templateUrl: 'currency-form.component.html'
})
export class CurrencyFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: Currency;
  isSubmitting = false;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private currenciesService: CurrenciesService
  ) {}

  ngOnInit() {
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      // FETCH CURRENCY
      this.fetchCurrency(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchCurrency(id: number) {
    this.fetchingState++;
    this.subscribers.GET_CURRENCY = this.currenciesService
      .getCurrency(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      isActive: false,
      isDeleted: false
    });
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(data: Currency) {
    this.subscribers.CREATE_FIELD = this.currenciesService
      .createCurrency(data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  update(data: Currency) {
    this.subscribers.UPDATE_FIELD = this.currenciesService
      .updateCurrency(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE_FIELD = this.currenciesService
      .deleteCurrency(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('CURRENCY');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
