import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Locale } from '@widgets/locales/models/locale';
import * as fromLocales from '../reducers/locales.reducer';

export const getLocalesState = createFeatureSelector<fromLocales.LocaleState>('locales');

export const getLocalesEntities = createSelector(getLocalesState, fromLocales.getLocalesEntities);

export const getSelectedLocaleId = createSelector(getLocalesState, fromLocales.getSelectedId);

export const getSelectedLocale = createSelector(
  getLocalesEntities,
  getSelectedLocaleId,
  (entities, localeId): Locale => (entities && localeId ? entities[localeId] : {})
);

export const getAllLocales = createSelector(getLocalesEntities, (entities) =>
  entities ? Object.keys(entities).map((id) => entities[id]) : []
);

export const getLocalesLoaded = createSelector(getLocalesState, fromLocales.getLocalesLoaded);

export const getLocalesLoading = createSelector(getLocalesState, fromLocales.getLocalesLoading);

export const getLocalePending = createSelector(getLocalesState, fromLocales.getPending);

export const getLocalePendingError = createSelector(getLocalesState, fromLocales.getPendingError);

export const getLocaleFormData = createSelector(getLocalesState, fromLocales.getLocaleFormData);

export const getLoadFormData = createSelector(getLocalesState, fromLocales.getLoadFormData);

export const getLoadFormDataError = createSelector(getLocalesState, fromLocales.getLoadFormDataError);
