<div class="modal">
  <div class="modal__header">
    <div class="modal__header__label">
      <div class="modal__header__title">
        {{ modalTitle | translate }}
      </div>
      <div class="modal__header__description">
        {{ modalDescription | translate }}
      </div>
    </div>

    <div class="modal__header__action">
      <sc-close-button (clicked)="close()"></sc-close-button>
    </div>
  </div>

  <div class="modal__body">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="1">
        <sc-list-select
          [items]="operandTypes"
          (selectItem)="onSelectOperandType($event)"
        ></sc-list-select>
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <ng-template [ngTemplateOutlet]="step2"></ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal__footer">
    <!-- *ngIf="isTemplate" -->
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="onResetClick()"
      [label]="'CCP_COMMON_RESET' | translate"
      *ngIf="isTemplate || isFromScratch"
    ></button>

    <button
      pButton
      type="button"
      class="ui-button-secondary"
      (click)="onOkClick()"
      [label]="'CCP_COMMON_OK' | translate"
      [disabled]="form?.invalid"
    ></button>
  </div>
</div>

<ng-template #step2>
  <ng-container [ngSwitch]="operandType">
    <ng-container *ngSwitchCase="_rightOperandTypes.DatetimeAbsolute">
      <sc-absolute-datetime-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-absolute-datetime-operand>
    </ng-container>

    <ng-container *ngSwitchCase="_rightOperandTypes.DatetimeRelative">
      <sc-relative-datetime-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
      ></sc-relative-datetime-operand>
    </ng-container>

    <ng-container *ngSwitchCase="_rightOperandTypes.Device">
      <sc-device-operand
        [form]="form"
        valueFormControlName="value"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [viewMode]="viewMode"
        [isTemplate]="isTemplate"
      ></sc-device-operand>
    </ng-container>

    <ng-container *ngSwitchCase="_rightOperandTypes.Value">
      <sc-value-operand
        [form]="form"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [structure]="valueStructure"
        [isTemplate]="isTemplate"
      ></sc-value-operand>
    </ng-container>
  </ng-container>
</ng-template>
