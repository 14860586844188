import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { SCShapesModule } from '../shapes/shapes.module';
import { SCModalModule } from '../modal/modal.module';
import { SCLoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';

import { SCDashboardAlertComponent } from './dashboard-alert/dashboard-alert.component';
import { SCDashboardCirclesComponent } from './dashboard-circles/dashboard-circles.component';
import { SCDashboardSquareComponent } from './dashboard-square/dashboard-square.component';
import { SCDashboardTopThreeComponent } from './dashboard-top-three/dashboard-top-three.component';

export const COMPONENTS: any[] = [
  SCDashboardAlertComponent,
  SCDashboardCirclesComponent,
  SCDashboardSquareComponent,
  SCDashboardTopThreeComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ProgressBarModule,
    TooltipModule,
    TranslateModule.forChild({ extend: true, isolate: false }),
    SCLoadingIndicatorModule,
    SCModalModule,
    SCShapesModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class SCCustomWidgetsModule {}
