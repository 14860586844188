<sc-table
  [columns]="columns"
  [config]="config"
  [dataset]="dataset"
  [formConfig]="formConfig"
  [icon]="'fa fa-globe'"
  [title]="tableTitle | translate"
  (afterInit)="afterInitTable($event)"
  (reloadData)="fetchDataset()"
></sc-table>
