import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-condition-form',
  templateUrl: './condition-form.component.html'
})
export class ConditionsFormComponent implements OnInit, OnDestroy {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  optionData: any;
  @Output()
  removed = new EventEmitter();

  private subscribers: { [key: string]: any } = {};

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.group.patchValue({ position: this.groupId });
    this.onFormValueChange();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private onFormValueChange() {
    this.subscribers.SOURCE_TYPE = this.group
      .get('sourceType')
      .valueChanges.subscribe(value => {
        if (value) {
          this.group.patchValue({
            referencedType: null,
            deviceTypeId: null,
            event: null,
            locationPropertyTypeId: null,
            operator: null,
            parameters: null,
            referencedDeviceTypeId: null,
            referencedLocationPropertyTypeId: null,
            referencedValue: null,
            requiredValidityPeriod: 0,
            datetimePropertyTypeId: null,
            datetimePropertyValue: null
          });
        }
      });

    this.subscribers.DEVICE_TYPE_ID = this.group
      .get('deviceTypeId')
      .valueChanges.subscribe(value => {
        if (value) {
          this.group.patchValue({ referencedType: null });
        }
      });

    this.subscribers.LOCATION_PROPERTY_TYPE_ID = this.group
      .get('locationPropertyTypeId')
      .valueChanges.subscribe(value => {
        if (value) {
          this.group.patchValue({ referencedType: null });
        }
      });

    this.subscribers.DATETIME_PROPERTY_TYPE_ID = this.group
      .get('datetimePropertyTypeId')
      .valueChanges.subscribe(value => {
        if (value) {
          this.group.patchValue({ referencedType: null });
        }
      });

    this.subscribers.REFERENCED_TYPE = this.group
      .get('referencedType')
      .valueChanges.subscribe(value => {
        if (value) {
          this.group.patchValue({
            referencedDeviceTypeId: null,
            referencedLocationPropertyTypeId: null,
            referencedValue: null,
            datetimePropertyValue: null
          });
        }
      });
  }

  get sourceType() {
    return this.group.get('sourceType').value;
  }

  get deviceTypeId() {
    return this.group.get('deviceTypeId').value;
  }

  get locationPropertyTypeId() {
    return this.group.get('locationPropertyTypeId').value;
  }

  get hasMappingValues() {
    switch (this.sourceType) {
      case 'd':
        return this.mappingValue && this.mappingValue.length ? true : false;

      case 'l':
        return this.mappingValue && this.mappingValue.length ? true : false;

      default:
        return false;
    }
  }

  get mappingValue() {
    switch (this.sourceType) {
      case 'd':
        return this.deviceTypeMappingValues;

      case 'l':
        return this.locationPropertyMappingValues;

      default:
        return;
    }
  }

  get deviceTypeMappingValues() {
    if (
      this.optionData &&
      this.optionData.deviceTypesList &&
      this.deviceTypeId
    ) {
      const deviceType = this.optionData.deviceTypesList.find(
        d => d.id === this.deviceTypeId
      );
      if (
        deviceType &&
        deviceType.mappingValues &&
        deviceType.mappingValues.length
      ) {
        return this.sharedService.createSelectItems(
          deviceType.mappingValues,
          false,
          'key'
        );
      }
    }
    return;
  }

  get locationPropertyMapping() {
    if (
      this.optionData &&
      this.optionData.locationPropertiesList &&
      this.locationPropertyTypeId
    ) {
      const locationProperty = this.optionData.locationPropertiesList.find(
        d => d.key === this.locationPropertyTypeId
      );
      return locationProperty;
    }
    return;
  }

  get locationPropertyMappingValues() {
    if (this.locationPropertyMapping) {
      switch (this.locationPropertyMapping.type) {
        case 'boolean':
          return this.optionData.booleanOption;

        case 'deviceType':
          if (this.optionData && this.optionData.deviceTypesList) {
            const deviceType = this.optionData.deviceTypesList.find(
              d => d.key === this.locationPropertyMapping.deviceType
            );
            if (
              deviceType &&
              deviceType.mappingValues &&
              deviceType.mappingValues.length
            ) {
              return this.sharedService.createSelectItems(
                deviceType.mappingValues,
                false,
                'key'
              );
            }
          }
          break;

        default:
          break;
      }
    }
    return;
  }

  get availableOperators() {
    if (
      this.locationPropertyMapping &&
      this.locationPropertyMapping.operators &&
      this.locationPropertyMapping.operators.length
    ) {
      const operators = this.locationPropertyMapping.operators.split(',');

      const ao = this.optionData.operators.filter(
        o => operators.indexOf(o.value) >= 0
      );
      return ao;
    }
    return this.optionData.operators;
  }

  get isRequiredTrueOneTime() {
    if (
      this.group.get('sourceType').value === 'd' &&
      this.group.get('referencedType').value === 'v'
    ) {
      return true;
    }
    return false;
  }
}
