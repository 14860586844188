import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { MainDoorOpenComponent } from './main-door-open.component';

@NgModule({
  imports: [SharedModule],
  declarations: [MainDoorOpenComponent],
  entryComponents: [MainDoorOpenComponent]
})
export class MainDoorOpenModule {}
