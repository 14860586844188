<div *ngIf="fetchingState === 0; else loadingIndicator">
  <form [formGroup]="form" novalidate>
    <p-card>
      <!--Header-->
      <p-header>
        <div class="ui-g">
          <div class="ui-g-6 ui-g-nopad">
            <sc-text-input
              *ngIf="editMode"
              controlName="id"
              [form]="form"
              label="{{ 'ID' | translate }}"
            ></sc-text-input>
          </div>

          <div class="ui-g-6 text-right">
            <p-toggleButton
              formControlName="isActive"
              offIcon="fa fa-square-o"
              offLabel="{{ 'ACTIVE' | translate }}"
              onIcon="fa fa-check-square-o"
              onLabel="{{ 'ACTIVE' | translate }}"
            ></p-toggleButton>
          </div>
        </div>
      </p-header>

      <!--Body-->
      <div class="ui-g">
        <!-- KEY FIELD -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="key"
            [form]="form"
            label="{{ 'KEY' | translate }}"
          ></sc-text-input>
        </div>

        <!-- NAME FIELD -->
        <div class="ui-g-12">
          <sc-text-input
            controlName="name"
            [form]="form"
            label="{{ 'NAME' | translate }}"
          ></sc-text-input>
        </div>

        <!-- CHART_TYPE FIELD -->
        <div class="ui-g-12 p-2">
          <sc-dropdown
            controlName="chartType"
            [form]="form"
            label="{{ 'CHART_TYPE' | translate }}"
            [options]="chartTypes"
          ></sc-dropdown>
        </div>

        <!-- ICON FIELD -->
        <div class="ui-g-12">
          <sc-local-file-input
            controlName="icon"
            [form]="form"
            label="{{ 'icon' | translate }}"
          ></sc-local-file-input>
        </div>

        <h3>{{ 'DEVICE_ALERT' | translate }}</h3>
        <div class="ui-g-12">
          <sc-checkbox-input
            controlName="isDeviceAlertEnabled"
            [form]="form"
            label="{{ 'DEVICE_ALERT_ENABLED' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- DEVICE_ALERT_TIMEOUT FIELD -->
        <div class="ui-g-6 ui-g-nopad">
          <div class="ui-g-7">
            <sc-number-input
              controlName="deviceAlertTimeout"
              [form]="form"
              label="{{ 'DEVICE_ALERT_TIMEOUT' | translate }}"
              [min]="0"
              description="leave blank will take threshold value"
            ></sc-number-input>
          </div>
          <div class="ui-g-5">
            <div class="pt-4">
              {{ 'MINUTES' | translate }}
            </div>
          </div>
        </div>

        <!-- ACTIONS FIELD -->
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g-10 ui-g-nopad">
            <h4>{{ 'ACTIONS' | translate }}</h4>
          </div>
          <div class="ui-g-2 text-right">
            <button
              pButton
              type="button"
              class="ui-button-success"
              icon="fa fa-plus"
              pTooltip="{{ 'ADD' | translate }} {{ 'ACTION' | translate }}"
              (click)="addAction()">
            </button>
          </div>
        </div>

        <div formArrayName="commandActions" class="ui-g-12">
          <div *ngFor="let ctrl of commandActions.controls; let i=index" [formGroupName]="i">
            <sc-device-type-action-form
              [group]="ctrl"
              [groupId]="i"
              (removed)="removeAction(i)"
            ></sc-device-type-action-form>
          </div>
        </div>

        <!-- MAPPING_VALUES FIELD -->
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g-10 ui-g-nopad">
            <h4>{{ 'MAPPING_VALUES' | translate }}</h4>
          </div>
          <div class="ui-g-2 text-right">
            <button
              pButton
              type="button"
              class="ui-button-success"
              icon="fa fa-plus"
              pTooltip="{{ 'ADD' | translate }} {{ 'MAPPING_VALUE' | translate }}"
              (click)="addMappingValues()">
            </button>
          </div>
        </div>

        <div formArrayName="mappingValues" class="ui-g-12">
          <div *ngFor="let ctrl of mappingValues.controls; let i=index" [formGroupName]="i">
            <sc-device-type-mapping-value-form
              [group]="ctrl"
              [groupId]="i"
              (removed)="removeMappingValues(i)"
            ></sc-device-type-mapping-value-form>
          </div>
        </div>

        <!-- Error Message -->
        <div class="ui-g-12" *ngIf="errorMessage">
          <sc-form-error [message]="errorMessage|translate"></sc-form-error>
        </div>
      </div>

      <!--Footer-->
      <p-footer>
        <div class="ui-g-6">
          <sc-confirm-trigger
            *ngIf="editMode && !formData?.isDeleted"
            buttonIcon="fa fa-trash"
            buttonClass="ui-button-danger"
            buttonLabel="{{ 'DELETE' | translate }}"
            (onAccept)="delete()"
          ></sc-confirm-trigger>
        </div>

        <div class="ui-g-6 text-right">
          <button
            pButton
            type="button"
            class="ui-button-secondary"
            (click)="dismissModal('cancel')"
            label="{{ 'CANCEL' | translate }}"
          ></button>

          <sc-submit-button
            *ngIf="!formData?.isDeleted || formData?.isActive"
            buttonClass="ui-button-success"
            [disabled]="!form.valid || form.pristine"
            [pending]="isSubmitting"
            (submitted)="submit()"
          ></sc-submit-button>
        </div>
      </p-footer>
    </p-card>
  </form>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
