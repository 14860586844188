<div [formGroup]="form" class="ui-g">
  <h4>
    {{ (isEndCommand ? 'END' : 'START') | translate }}
    {{ 'COMMANDS' | translate }}
  </h4>

  <!-- HEADER -->
  <div class="ui-g-12 ui-g-nopad grey lighten-2">
    <div class="ui-g-3">{{ 'SOURCE' | translate }}</div>

    <div class="ui-g-2">{{ 'ACTION' | translate }}</div>

    <div class="ui-g-4">
      {{ 'PARAMETERS' | translate }}

      <div class="ui-g">
        <div class="ui-g-6">
          <small>{{ 'KEY' | translate }}</small>
        </div>
        <div class="ui-g-6">
          <small>{{ 'VALUE' | translate }}</small>
        </div>
      </div>
    </div>
  </div>

  <!-- BODY -->
  <ng-container [formArrayName]="commandsFormName">
    <ng-container
      *ngFor="let cmd of commandsForm.controls; let i = index"
      [formGroupName]="i"
    >
      <div class="ui-g-12 ui-g-nopad">
        <!-- SOURCE -->
        <div class="ui-g-3">
          <div [ngSwitch]="cmd?.value?.targetTypeId">
            <!-- DEVICE -->
            <div *ngSwitchCase="'d'">
              {{ getDeviceTypeName(cmd?.value?.deviceTypeId) }}
            </div>

            <!-- DEVICE_VALUE_STORAGE -->
            <div *ngSwitchCase="'dvs'">
              {{ getDeviceTypeName(cmd?.value?.deviceTypeId) }}
            </div>

            <!-- LOCATION -->
            <div *ngSwitchCase="'l'">
              {{ getLocationPropertyLabel(cmd?.value?.locationPropertyTypeId) }}
            </div>

            <!-- SERVICE -->
            <div *ngSwitchCase="'s'">
              <div [ngSwitch]="cmd?.value?.serviceTypeId">
                <div *ngSwitchCase="'n'">{{ 'NOTIFIER' | translate }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- ACTION -->
        <div class="ui-g-2">{{ getCommandActionText(cmd) | translate }}</div>

        <!-- PARAMETERS -->
        <div class="ui-g-4">
          <div [ngSwitch]="cmd?.value?.targetTypeId">
            <!-- DEVICE -->
            <div *ngSwitchCase="'d'">
              <sc-device-parameter-form
                [form]="cmd"
                [data]="data"
              ></sc-device-parameter-form>
            </div>

            <!-- DEVICE_VALUE_STORAGE -->
            <div *ngSwitchCase="'dvs'">
              <sc-device-parameter-form
                [form]="cmd"
                [data]="data"
              ></sc-device-parameter-form>
            </div>

            <!-- LOCATION -->
            <div *ngSwitchCase="'l'">
              <sc-location-parameter-form
                [form]="cmd"
                [data]="data"
              ></sc-location-parameter-form>
            </div>

            <div *ngSwitchCase="'s'">
              <div class="ui-g-6 ui-g-offset-6 ui-g-nopad">
                {{
                  getNotificationTemplateName(
                    cmd?.value?.notificationTemplateId
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="hasError(cmd?.value)"
        class="ui-g-12 ui-g-nopad"
        [ngClass]="hasError(cmd?.value)"
      >
        <ng-container *ngIf="isEmpty(commands[i])">
          <div class="text-center">{{ 'RULE_COMMAND_NOT_EXISTS' | translate }}</div>
        </ng-container>

        <ng-container *ngIf="!isEmpty(commands[i])">
          <!-- SOURCE -->
          <div class="ui-g-3">
            <div [ngSwitch]="commands[i].targetTypeId">
              <!-- DEVICE -->
              <div *ngSwitchCase="'d'">
                {{ getDeviceTypeName(commands[i].deviceTypeId) }}
              </div>

              <!-- DEVICE_VALUE_STORAGE -->
              <div *ngSwitchCase="'dvs'">
                {{ getDeviceTypeName(commands[i].deviceTypeId) }}
              </div>

              <!-- LOCATION -->
              <div *ngSwitchCase="'l'">
                {{
                  getLocationPropertyLabel(commands[i].locationPropertyTypeId)
                }}
              </div>

              <!-- SERVICE -->
              <div *ngSwitchCase="'s'">
                <div [ngSwitch]="commands[i].serviceTypeId">
                  <div *ngSwitchCase="'n'">{{ 'NOTIFIER' | translate }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- ACTION -->
          <div class="ui-g-2">
            {{ getCommandActionText(commands[i]) | translate }}
          </div>

          <!-- PARAMETERS -->
          <div class="ui-g-4">
            <ng-container [ngSwitch]="commands[i].targetTypeId">
              <!-- DEVICE -->
              <ng-container *ngSwitchCase="'d'">
                <ng-container *ngIf="commands[i]?.parameters?.length">
                  <ng-container *ngFor="let param of commands[i].parameters">
                    <div class="ui-g-6">
                      {{ param.key }}
                    </div>

                    <div class="ui-g-6">
                      {{ param.value }}
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- DEVICE_VALUE_STORAGE -->
              <ng-container *ngSwitchCase="'dvs'">
                <ng-container *ngIf="commands[i]?.parameters?.length">
                  <ng-container *ngFor="let param of commands[i].parameters">
                    <div class="ui-g-6">
                      {{ param.key }}
                    </div>

                    <div class="ui-g-6">
                      {{ param.value }}
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- LOCATION -->
              <ng-container *ngSwitchCase="'l'">
                <div class="ui-g-6 ui-g-offset-6 ui-g-nopad">
                  {{ getLocationParameter(commands[i]) }}
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'s'">
                <div class="ui-g-6 ui-g-offset-6 ui-g-nopad">
                  {{
                    getNotificationTemplateName(
                      commands[i].notificationTemplateId
                    )
                  }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
