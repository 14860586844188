import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as companiesActions from '../actions/companies.action';

import { CompaniesService } from '@widgets/companies/services/companies.service';
import { SharedService } from '@shared/shared.service';

@Injectable()
export class CompaniesEffects {
  constructor(
    private action$: Actions,
    private companiesService: CompaniesService,
    private sharedService: SharedService
  ) {}

  @Effect()
  loadCompany$ = this.action$.pipe(
    ofType(companiesActions.LOAD_COMPANY),
    map((action: companiesActions.LoadCompany) => action.payload),
    switchMap((payload) => {
      return this.companiesService.getCompanies(payload).pipe(
        map((result: any) => new companiesActions.LoadCompanySuccess(result.data)),
        catchError((error) => of(new companiesActions.LoadCompanyFail(error.error.message)))
      );
    })
  );

  @Effect()
  loadCompanies$ = this.action$.pipe(
    ofType(companiesActions.LOAD_COMPANIES),
    switchMap((payload) => {
      return this.companiesService.getCompanies().pipe(
        map((result: any) => new companiesActions.LoadCompaniesSuccess(result.data)),
        catchError((error) => of(new companiesActions.LoadCompaniesFail(error.error.message)))
      );
    })
  );

  @Effect()
  createCompanies$ = this.action$.pipe(
    ofType(companiesActions.CREATE_COMPANY),
    map((action: companiesActions.CreateCompany) => action.payload),
    switchMap((company) => {
      return this.companiesService.createCompany(company).pipe(
        map((result: any) => {
          this.sharedService.notify('Comapnies', 'Create Success', 'success');
          return new companiesActions.CreateCompanySuccess(result.data);
        }),
        catchError((error) => of(new companiesActions.CreateCompanyFail(error.error.message)))
      );
    })
  );

  @Effect()
  updateCompany$ = this.action$.pipe(
    ofType(companiesActions.UPDATE_COMPANY),
    map((action: companiesActions.UpdateCompany) => action.payload),
    switchMap((company) => {
      return this.companiesService.updateCompany(company).pipe(
        map((result: any) => {
          this.sharedService.notify('Comapnies', 'Update Success', 'success');
          return new companiesActions.UpdateCompanySuccess(company);
        }),
        catchError((error) => of(new companiesActions.UpdateCompanyFail(error.error.message)))
      );
    })
  );

  @Effect()
  deleteCompany$ = this.action$.pipe(
    ofType(companiesActions.DELETE_COMPANY),
    map((action: companiesActions.DeleteCompany) => action.payload),
    switchMap((company) => {
      return this.companiesService.deleteCompany(company).pipe(
        map((result: any) => {
          this.sharedService.notify('Comapnies', 'Delete Success', 'success');
          return new companiesActions.DeleteCompanySuccess(company);
        }),
        catchError((error) => of(new companiesActions.DeleteCompanySuccess(error.error.message)))
      );
    })
  );
}
