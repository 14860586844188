import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataSources, CustomFunctionParameter, SelectItems } from '../../models';
import { RulesService } from '../../services';

@Component({
  selector: 'sc-function-variables',
  templateUrl: './function-variables.component.html',
  styleUrls: ['./function-variables.component.scss'],
})
export class FunctionVariablesComponent implements OnInit, OnChanges {
  variablesForm: FormArray;

  @Input()
  dataSources: DataSources = {};

  @Input()
  form: FormGroup;

  @Input()
  isTemplate: boolean;

  @Input()
  selectItems: SelectItems = {};

  @Input()
  variables: CustomFunctionParameter[];

  constructor(private rulesService: RulesService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setParametersForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variables && changes.variables.currentValue) {
      this.setParametersForm();
    }
  }

  private setParametersForm() {
    const variables = this.variables.map((item: any) => {
      let custom_global_key;
      if (this.dataSources && this.dataSources.customGlobals) {
        const customGlobal = this.dataSources.customGlobals.find(
          (cg) => cg.idx === item.custom_global_idx || cg.key === item.custom_global_key
        );

        if (customGlobal) {
          custom_global_key = customGlobal.key;
        }
      }
      return { ...item, custom_global_key };
    });
    this.rulesService.setFunctionVariablesForm(this.form, variables, this.isTemplate);
    this.variablesForm = this.form.get('variables') as FormArray;
    this.changeDetectorRef.detectChanges();
  }
}
