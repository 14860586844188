import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import * as fromStore from '@app/store/reducers';
import { getAuthToken } from '@app/store/selectors/auth.selector';
import { getRoles, getPermissions } from '@app/store/selectors/user.selector';
import { Credential } from '../models/credential';
import { Token } from '../models/token';
import { User } from '../models/user';
import * as jwtDecode from 'jwt-decode';
import { config } from '@app/config';

@Injectable()
export class AuthService {
  public token: string;
  private decodedToken: Token;
  private apiUrl = config().apiUrl + '/auth';

  private allowPermissions: string[];
  private allowRoles: string[];

  constructor(private httpClient: HttpClient, private store: Store<fromStore.State>) {
    // get back token in case user refesh the page
    this.store.pipe(select(getAuthToken)).subscribe({
      next: (result) => {
        if (result) {
          this.token = result;
          this.decodedToken = jwtDecode(this.token);
        }
      },
    });

    this.store.pipe(select(getRoles)).subscribe({
      next: (result) => {
        this.allowRoles = result;
      },
    });

    this.store.pipe(select(getPermissions)).subscribe({
      next: (result) => {
        this.allowPermissions = result;
      },
    });
  }

  private verifyToken(result: any) {
    const user: User = result.data;
    if (user.token) {
      this.token = user.token;
      this.decodedToken = jwtDecode(this.token);
      user.scope = this.decodedToken.scope;
    }
    return user;
  }

  private verifyToken2(result: any) {
    const user: User = result.data;
    if (user.token) {
      this.token = user.token;
      this.decodedToken = jwtDecode(this.token);
    }
    return user;
  }

  login(credential: Credential) {
    const data = { ...credential };
    delete data.twoFactorAuthCode;
    return this.httpClient.post(this.apiUrl, data).pipe(
      map((result) => this.verifyToken(result)),
      catchError((error: any) => observableThrowError(error.error.message))
    );
  }

  login2(credential: Credential) {
    const data = { ...credential };
    return this.httpClient.post(this.apiUrl + '2', data).pipe(
      map((result) => this.verifyToken2(result)),
      catchError((error: any) => observableThrowError(error.error.message))
    );
  }

  verifyTwoFactor(credential: Credential) {
    const data = { ...credential };
    delete data.password;
    const apiUrl = this.apiUrl + '/twoFactor';
    return this.httpClient.post(apiUrl, data).pipe(
      map((result) => this.verifyToken(result)),
      catchError((error: any) => observableThrowError(error.error.message))
    );
  }

  logout(): void {
    this.token = null;
    this.decodedToken = null;

    // clear local storage
    localStorage.clear();
  }

  resendUnblock(username: string) {
    const apiUrl = this.apiUrl + '/unblock/resend?username=' + username;
    return this.httpClient.get(apiUrl);
  }

  unblock(username: string, key: string) {
    const apiUrl = this.apiUrl + '/unblock?key=' + key + '&username=' + username;
    return this.httpClient.get(apiUrl);
  }

  isValidToken(): boolean {
    if (this.token && this.decodedToken) {
      const now = Math.floor(new Date().getTime() / 1000);
      const timeLeft = this.decodedToken.exp - now;
      if (timeLeft > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isAuthorized(role: string): boolean {
    if (this.allowRoles && this.allowRoles.length && this.allowRoles.indexOf(role) >= 0) {
      return true;
    }
    return false;
  }

  allow(permission: string): boolean {
    if (this.allowPermissions && this.allowPermissions.length && this.allowPermissions.indexOf(permission) >= 0) {
      return true;
    }
    return false;
  }
}
