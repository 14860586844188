import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DashboardSquareConfig, DashboardSquareDataset } from './dashboard-square';

@Component({
  selector: 'sc-dashboard-square',
  templateUrl: './dashboard-square.component.html',
  styleUrls: ['./dashboard-square.component.scss'],
})
export class SCDashboardSquareComponent implements OnChanges {
  @Input()
  config: DashboardSquareConfig;
  @Input()
  dataset: DashboardSquareDataset;
  @Input()
  loading: boolean;

  value: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataset && changes.dataset.currentValue) {
      this.getPercentValue();
    }
  }

  private getPercentValue() {
    if (!this.dataset) {
      this.value = 0;
    }
    this.value = (this.dataset.aboveAcceptable / this.dataset.locations) * 100;
  }
}
