import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';
import { UserTaskTemplate } from '../interfaces/user-task-template';

@Injectable({
  providedIn: 'root',
})
export class UserTaskTemplatesService {
  private apiUrl = config().apiUrl + '/userTaskTemplates';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: UserTaskTemplate): UserTaskTemplate {
    const newData = { ...data };
    delete newData.id;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    return newData;
  }

  getUserTaskTemplate(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getUserTaskTemplates() {
    return this.httpClient.get(this.apiUrl);
  }

  getUserTaskTemplatesOfCompany(companyId) {
    return this.httpClient.get(`${this.apiUrl}?cid=${companyId}`);
  }

  createUserTaskTemplate(data: UserTaskTemplate) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateUserTaskTemplate(id: number, data: UserTaskTemplate) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteUserTaskTemplate(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      is_active: false,
      is_deleted: true,
    });
  }
}
