import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DeviceModel } from '../models/device-model';
import { BehaviourPattern } from '../models/behaviour-pattern';

@Injectable()
export class DeviceModelFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initDeviceModelForm() {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      model: [null, Validators.required],
      specification: null,
      deviceTypeId: [null, Validators.required],
      brandId: [null, Validators.required],
      protocolId: [null, Validators.required],
      instructionIntro: null,
      instructionInclusion: null,
      instructionExclusion: null,
      automationVersion: null,
      zwaveStatus: null,
      zwaveControllerSoftware: null,
      zwaveControllerPort: null,
      zigbeeStatus: null,
      zigbeeControllerPort: null,
      behaviourPatterns: null,
      productImage: '',
      iconImage: '',
      valueMappings: this.formBuilder.array([]),
      isActive: false,
      isDeleted: false,
    });
  }

  initValueMappingFormGroup() {
    return this.formBuilder.group({
      id: null,
      deviceModelId: null,
      protocolControllerId: [null, Validators.required],
      deviceTypeMappingValueId: [null, Validators.required],
      mappedValue: [null, Validators.required],
    });
  }

  setValueMappingFormArray(form: FormGroup, data: DeviceModel[]) {
    const formGroups = data.map((item) => {
      const fg = this.initValueMappingFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('valueMappings', formArray);
  }

  resetValueMappingFormArray(form: FormGroup) {
    form.setControl('valueMappings', this.formBuilder.array([]));
  }

  initBehaviourPatternsFormGroup() {
    return this.formBuilder.group({
      compare_mode: 0,
      operator: '==',
      operand_modifier: null,
      operand_value: [null, Validators.required],
      report: 'n',
      ignore: 1,
    });
  }

  initBehaviourPatternsFormArray(form: FormGroup) {
    form.setControl('behaviourPatterns', this.formBuilder.array([]));
  }

  setBehaviourPatternsFormArray(form: FormGroup, data: BehaviourPattern[]) {
    const formGroups = data.map((item) => {
      const fg = this.initBehaviourPatternsFormGroup();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('behaviourPatterns', formArray);
  }

  resetBehaviourPatternsForm(form: FormGroup) {
    form.setControl('behaviourPatterns', null);
  }
}
