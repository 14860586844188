<form [formGroup]="form" novalidate>
  <div class="ui-g">
    <!-- VALUE -->
    <div class="ui-g-12 ui-g-nopad">
      <sc-radio-input
        *ngIf="commandValues.length"
        controlName="value"
        [form]="form"
        label="{{ 'VALUE' | translate }}"
        [options]="commandValues"
        [displayInVertical]="true"
      ></sc-radio-input>

      <sc-text-input
        *ngIf="!commandValues.length"
        controlName="value"
        [form]="form"
        label="{{ 'VALUE' | translate }}"
      ></sc-text-input>
    </div>

    <!-- SERVICE -->
    <div class="ui-g-12">
      <sc-radio-input
        controlName="service"
        [form]="form"
        label="{{ 'SERVICE' | translate }}"
        [options]="serviceProviders"
        [displayInVertical]="true"
      ></sc-radio-input>
    </div>

    <!-- Error Message -->
    <div class="ui-g-12" *ngIf="errorMessage">
      <sc-form-error [message]="errorMessage|translate"></sc-form-error>
    </div>
  </div>

  <div class="ui-g">
    <div class="ui-g-6"></div>

    <div class="ui-g-6 text-right">
      <button
        pButton
        type="button"
        class="ui-button-secondary"
        label="{{ 'CANCEL' | translate }}"
        (click)="dismissModal('cancel')"
      ></button>

      <sc-submit-button
        *ngIf="!formData?.isDeleted || formData?.isActive"
        buttonClass="ui-button-success"
        [pending]="isSubmitting"
        (submitted)="submit()"
        [disabled]="!form.valid || form.pristine"
      ></sc-submit-button>
    </div>
  </div>
</form>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
