import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'sc-datetime-input',
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
      ></sc-field-label>

      <p-calendar
        [inputId]="inputId"
        [formControlName]="controlName"
        [dateFormat]="dateFormat"
        [showTime]="showTime"
        [timeOnly]="timeOnly"
        [style]="{ width: '100%' }"
        hourFormat="24"
        [showButtonBar]="showButtonBar"
        [inline]="inline"
        [placeholder]="placeholder"
        appendTo="body"
      ></p-calendar>
      <!--[utc]="isUtc"-->

      <sc-field-error
        [for]="label"
        [fieldControl]="form.controls[controlName]"
      ></sc-field-error>
    </div>
  `
})
export class SCDatetimeInputComponent implements OnInit {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  placeholder: string;
  @Input()
  dateFormat: string;
  @Input()
  showTime: boolean;
  @Input()
  timeOnly: boolean;
  @Input()
  inline: boolean;
  @Input()
  showButtonBar: boolean;
  @Input()
  isUtc: boolean;

  inputId: string;

  constructor() {}

  ngOnInit() {
    this.inputId =
      'sc-datetime-input_' + this.controlName + new Date().getTime();
    this.placeholder = this.placeholder || '';
    this.dateFormat = this.dateFormat || 'dd.mm.yy';
    this.showButtonBar =
      typeof this.showButtonBar === 'boolean' ? this.showButtonBar : true;
    this.showLabel =
      typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.showTime = typeof this.showTime === 'boolean' ? this.showTime : true;
    this.timeOnly = typeof this.timeOnly === 'boolean' ? this.timeOnly : false;
    this.isUtc = typeof this.isUtc === 'boolean' ? this.isUtc : false;

    if (this.timeOnly === true) {
      this.showTime = true;
      this.showButtonBar = false;
    }
  }
}
