import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { EkhoComponent } from './ekho.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [EkhoComponent],
  entryComponents: [EkhoComponent],
  providers: []
})
export class EkhoModule {}
