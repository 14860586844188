<div class="function-variable-item" [formGroup]="control">
  <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
    <label [for]="'input-' + key" class="p-col-12 p-md-4">
      {{ control?.value?.label }}:
    </label>

    <div class="p-col-12 p-md-8">
      <ng-container [ngSwitch]="control?.value?.type">
        <ng-container
          *ngSwitchCase="_customFunctionParameterTypes.CustomGlobalValue"
        >
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="field-wrapper">
              {{ customGlobalLabel }}
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngSwitchCase="_customFunctionParameterTypes.LatestDhlValue"
        >
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="field-wrapper" *ngIf="!isTemplate">
              <!-- <p-dropdown
                [inputId]="'input-' + key"
                [options]="deviceSelectItems"
                formControlName="device_idx"
                placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
                  'CCP_DEVICE' | translate
                }}"
                [filter]="true"
                appendTo="body"
              ></p-dropdown> -->
              <sc-tree-select
                [form]="control"
                field="device_idx"
                [options]="deviceSelectItems"
                placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
                  'CCP_DEVICE' | translate
                }}"
              ></sc-tree-select>
            </div>

            <div class="field-wrapper" *ngIf="isTemplate">
              {{ deviceTypeLabel }}
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngSwitchCase="_customFunctionParameterTypes.LatestLhlValue"
        >
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="field-wrapper p-mr-3" *ngIf="!isTemplate">
              <p-dropdown
                [inputId]="'input-' + key"
                [options]="selectItems?.locations"
                formControlName="location_idx"
                placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
                  'CCP_LOCATION' | translate
                }}"
                [filter]="true"
                appendTo="body"
              ></p-dropdown>
            </div>

            <div class="p-d-flex p-ai-center p-jc-end">
              <span *ngIf="isTemplate">
                {{ "CCP_LOCATION" | translate }}
                &nbsp;
              </span>

              <span>{{ locationPropertyLabel | lowercase }}</span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
