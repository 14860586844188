import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions } from 'ag-grid';
import { ConfirmationService, SelectItem, TreeNode } from 'primeng/api';
import { ModalConfig } from '@sc/modal/modal-config';
import { Site } from '@widgets/sites/models/site';
import { Benchmark } from '../../models/benchmark';
import { OverviewFormData } from '../../models/overview-form-data';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as fromStore from '@app/store';
import { BenchmarkTemplatesService } from '../../services/benchmark-templates.service';
import { BenchmarksBatchService } from '../../services/benchmarks-batch.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-overview-table',
  templateUrl: 'overview-table.component.html',
})
export class BenchmarksOverviewTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  table: GridOptions;
  tableTitle: string;
  toolbarItems: string[];

  dataset: Benchmark[];
  fetchingState: number;
  rawData: { [key: string]: any };
  selectItems: {
    [key: string]: SelectItem[];
    locations?: any[];
  };
  subscribers: { [key: string]: any };

  benchmarkStatusIntervals: any;
  benchmarkGenerateStatus: number;
  benchmarkGenerateTranslateParams: { name: string };
  isFetchingBenchmarkStatus: boolean;
  benchmarkStatusFetchCount: number;

  form: FormGroup;
  formData: OverviewFormData;

  selectedSite: Site;

  private destroyed: Subject<void> = new Subject();

  constructor(
    private benchmarkTemplatesService: BenchmarkTemplatesService,
    private benchmarksService: BenchmarksBatchService,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.fetchingState = 0;
    this.rawData = {};
    this.selectItems = {};
    this.subscribers = {};
    this.dataset = [];
    this.tableTitle = 'BENCHMARKS';
    this.toolbarItems = ['columns'];
    this.columns = [
      {
        colId: 'startDate',
        headerName: 'DATE',
        field: 'start_time',
        sort: 'desc',
        valueFormatter: (params) => this.sharedService.dateFormat(params.value, 'DD/MM/YYYY'),
      },
      {
        colId: 'benchmarkTemplateName',
        headerName: 'BENCHMARK_TEMPLATE',
        field: 'benchmarkTemplateName',
      },
      {
        colId: 'locationName',
        headerName: 'LOCATION_NAME',
        field: 'locationName',
      },
      {
        colId: 'benchmarkTemplateId',
        headerName: 'BENCHMARK_TEMPLATE_ID',
        field: 'benchmarkTemplateId',
        hide: true,
      },
      {
        colId: 'locationId',
        headerName: 'LOCATION_ID',
        field: 'locationId',
        aggFunc: 'sum',
        hide: true,
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
        hide: true,
      },
    ];

    // WATCH SELECTED SITE AND GET LOCATIONS
    this.store
      .select(fromStore.getSelectedSite)
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;
          // reset form when site changed
          if (this.form) {
            this.form.reset();
          }

          // set form data
          this.formData = {}; // analyse: true
          // get site's timezone offset
          this.formData.timezone = this.sharedService.getTimezoneOffset(this.selectedSite.timezone);

          // get site's locations
          this.fetchLocations();
        }
      });

    this.fetchBenchmarkTemplates();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();

    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }

    this.stopGenerateStatusCheck();
  }

  fetchBenchmarkTemplates() {
    this.fetchingState++;
    this.subscribers.GET_BENCHMARK_TEMPLATES = this.benchmarkTemplatesService
      .getBenchmarkTemplates()
      .subscribe((result: any) => {
        this.rawData.benchmarkTemplates = result.data;
        this.selectItems.benchmarkTemplatesRaw = result.data;
        this.selectItems.benchmarkTemplates = this.sharedService.createSelectItems(
          result.data.filter((d) => d.isActive && !d.isDeleted),
          false
        );
        this.fetchingState--;
      });
  }

  fetchLocations() {
    this.fetchingState++;
    this.selectItems.locations = [];
    // FIXME: API return more columns than specify
    const options = {
      siteId: this.selectedSite.id,
      columns: 'id,description,isActive,isDeleted,parentId',
      tree: true,
    };
    this.subscribers.GET_LOCATIONS = this.locationsService.getLocations(options).subscribe((result: any) => {
      // Create tree-multi-select items
      this.selectItems.locations = this.sharedService.createMultiSelectItems(
        result.data.filter((d: any) => d.isActive && !d.isDeleted)
      );
      this.fetchingState--;
    });
  }

  fetchDataset(event?: OverviewFormData, callback?: Function) {
    this.fetchingState++;
    this.subscribers.GET_DATASET = this.benchmarksService.getBenchmarks(this.formData).subscribe((result: any) => {
      this.dataset = result.data;
      this.fetchingState--;

      if (callback) {
        callback(result.data);
      }
    });
  }

  generateBenchmark(event?: OverviewFormData) {
    this.checkBenchmarkExistsBeforeGenerate((exists) => {
      if (!exists) {
        this.benchmarkGenerateStatus = 1;
        this.subscribers.GENERATE_BENCHMARK = this.benchmarksService
          .generateBenchmark(this.formData)
          .subscribe((result: any) => {
            this.startGenerateStatusCheck(result.data.batch_id);
          });
      }
    });
  }

  // Check Benchmark result exists
  // to avoid re-generate when result is existed
  checkBenchmarkExistsBeforeGenerate(callback: Function) {
    this.benchmarkGenerateStatus = 3;
    this.fetchDataset(null, (benchmarkResult: any) => {
      this.benchmarkGenerateStatus = null;
      if (benchmarkResult.length === 0) {
        return callback(false);
      }

      // show confirmation for re-generating
      this.confirmationService.confirm({
        icon: 'fa fa-task',
        header: this.sharedService.getTranslation('BENCHMARK_REGENERATING'),
        message: this.sharedService.getTranslation(
          'BENCHMARK_REGENERATING_CONFIRM',
          this.benchmarkGenerateTranslateParams
        ),
        accept: () => {
          return callback(false);
        },
      });

      return true;
    });
  }

  // Check Benchmark Generating Status
  getGenerateStatus(batchId: string) {
    if (!this.isFetchingBenchmarkStatus) {
      this.isFetchingBenchmarkStatus = true;
      this.subscribers.GENERATE_BENCHMARK_STATUS = this.benchmarksService
        .generateBenchmarkStatus(batchId)
        .subscribe((result: any) => {
          if (result && result.data && result.data.status === 'completed') {
            this.benchmarkGenerateStatus = null;
            this.stopGenerateStatusCheck();
            this.fetchDataset();
          } else {
            this.benchmarkStatusFetchCount++;
          }
          this.isFetchingBenchmarkStatus = false;
        });
    }
  }

  // Start Interval for checking benchmark generating status
  startGenerateStatusCheck(batchId: string) {
    this.benchmarkStatusFetchCount = 0;

    clearInterval(this.benchmarkStatusIntervals);
    this.benchmarkStatusIntervals = setInterval(() => {
      this.getGenerateStatus(batchId);
      if (this.benchmarkStatusFetchCount >= 20) {
        // 60 seconds passed
        this.stopGenerateStatusCheck();
        this.benchmarkGenerateStatus = 2;
      }
    }, 3000);
  }

  // stop Interval for checking benchmark generating status
  stopGenerateStatusCheck() {
    clearInterval(this.benchmarkStatusIntervals);
    this.benchmarkStatusIntervals = null;
  }

  // Callback after form init
  afterFormInit(event: FormGroup) {
    this.form = event;
    this.subscribers.FORM = this.form.valueChanges.subscribe((result) => {
      this.formData = { ...this.formData, ...result };

      // Set benchmarkGenerateTranslateParams
      if (result.benchmarkTemplate) {
        const benchmarkTemplate = this.rawData.benchmarkTemplates.find(
          (bt) => bt.id === this.formData.benchmarkTemplate
        );
        this.benchmarkGenerateTranslateParams = {
          name: benchmarkTemplate ? benchmarkTemplate.name : this.formData.benchmarkTemplate,
        };
      }
    });
  }

  // Callback after table init
  afterTableInit(event: GridOptions) {
    this.table = event;
    this.table.context.containerComponent = this;
  }

  // Analyse modal config
  get analyseModalConfig(): ModalConfig {
    return {
      name: 'BenchmarkAnalyseModal',
      options: { modalTitle: 'BENCHMARK_ANALYSE_MODAL_TITLE' },
      data: {
        formData: this.formData,
        rawData: this.rawData,
        selectItems: this.selectItems,
      },
    };
  }

  get isFormPendingState() {
    if (this.benchmarkGenerateStatus === 1 || this.benchmarkGenerateStatus === 3) {
      return true;
    }
    return false;
  }
}
