import { Page } from '@app/core2/models/page';
import { Section } from '@app/core2/models/page-section';
import { Widget } from '@app/core2/models/page-section-widget';
import { Action } from '@ngrx/store';

// LOAD PAGES
export const LOAD_PAGES = '[Pages] Load Pages';
export const LOAD_PAGES_FAIL = '[Pages] Load Pages Fail';
export const LOAD_PAGES_SUCCESS = '[Pages] Load Pages Success';

export class LoadPages implements Action {
  readonly type = LOAD_PAGES;
}

export class LoadPagesFail implements Action {
  readonly type = LOAD_PAGES_FAIL;
  constructor(public payload: any) {}
}

export class LoadPagesSuccess implements Action {
  readonly type = LOAD_PAGES_SUCCESS;
  constructor(public payload: Page[]) {}
}

// VISUALISE PAGE
export const VISUALISE_PAGE = '[Pages] Visualise Page';

export class VisualisePage implements Action {
  readonly type = VISUALISE_PAGE;
  constructor(public payload: Page) {}
}

// TOGGLE MODE
export const ENABLE_EDIT_MODE = '[Pages] Enable Edit Mode';
export const DISABLE_EDIT_MODE = '[Pages] Disable Edit Mode';

export class EnableEditMode implements Action {
  readonly type = ENABLE_EDIT_MODE;
}

export class DisableEditMode implements Action {
  readonly type = DISABLE_EDIT_MODE;
}

// ADD/REMOVE PAGE SECTION
export const VISUALISE_SECTION = '[Pages] Visualise Section';
export const ADD_PAGE_SECTION = '[Pages] Add Page Section';
export const REMOVE_PAGE_SECTION = '[Pages] Remove Page Section';
export const RESET_PAGE_SECTION = '[Pages] Reset Page Section';
export const MOVE_PAGE_SECTIONS = '[Pages] Move Page Sections';

export class VisualiseSection implements Action {
  readonly type = VISUALISE_SECTION;
  constructor(public payload: Section) {}
}

export class AddPageSection implements Action {
  readonly type = ADD_PAGE_SECTION;
  constructor(public payload: Section) {}
}

export class RemovePageSection implements Action {
  readonly type = REMOVE_PAGE_SECTION;
  constructor(public payload: Section) {}
}

export class ResetPageSection implements Action {
  readonly type = RESET_PAGE_SECTION;
  constructor(public payload: Section) {}
}

export class MovePageSection implements Action {
  readonly type = MOVE_PAGE_SECTIONS;
  constructor(public payload: Section[]) {}
}

// ADD/REMOVE PAGE SECTION WIDGETS
export const UPDATE_PAGE_SECTION_WIDGETS = '[Pages] Update Page Section Widgets';

export class UpdatePageSectionWidgets implements Action {
  readonly type = UPDATE_PAGE_SECTION_WIDGETS;
  constructor(public payload: Widget[]) {}
}

// TOGGLE MENU
export const HIDE_MENU = '[Pages] Hide Menu';
export const SHOW_MENU = '[Pages] Show Menu';
export const ACTIVATE_OVERLAY_MENU = '[Pages] Activate Overlay Menu';
export const DEACTIVATE_OVERLAY_MENU = '[Pages] Deactivate Overlay Menu';
export const HIDE_OVERLAY_MENU = '[Pages] Hide Overlay Menu';
export const SHOW_OVERLAY_MENU = '[Pages] Show Overlay Menu';
export const UPDATE_MENU = '[Pages] Update Menu';
export const SELECT_MENU = '[Pages] Select menu';
export const EXPAND_SETTINGS = '[Pages] Expand settings';

export class HideMenu implements Action {
  readonly type = HIDE_MENU;
}

export class ShowMenu implements Action {
  readonly type = SHOW_MENU;
}

export class ActivateOverlayMenu implements Action {
  readonly type = ACTIVATE_OVERLAY_MENU;
}

export class DeactivateOverlayMenu implements Action {
  readonly type = DEACTIVATE_OVERLAY_MENU;
}

export class HideOverlayMenu implements Action {
  readonly type = HIDE_OVERLAY_MENU;
}

export class ShowOverlayMenu implements Action {
  readonly type = SHOW_OVERLAY_MENU;
}

export class UpdateMenu implements Action {
  readonly type = UPDATE_MENU;
}

export class SelectMenu implements Action {
  readonly type = SELECT_MENU;
  constructor(public payload: { selectedId: string }) {}
}

export class ExpandSettings implements Action {
  readonly type = EXPAND_SETTINGS;
  constructor(public payload: { expanded: boolean }) {}
}

// UPDATE PAGE
export const UPDATE_PAGE = '[Pages] Update Page';
export const UPDATE_PAGE_FAIL = '[Pages] Update Page Fail';
export const UPDATE_PAGE_SUCCESS = '[Pages] Update Page Success';

export class UpdatePage implements Action {
  readonly type = UPDATE_PAGE;
  constructor(public payload: Page) {}
}

export class UpdatePageFail implements Action {
  readonly type = UPDATE_PAGE_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePageSuccess implements Action {
  readonly type = UPDATE_PAGE_SUCCESS;
  constructor(public payload: Page) {}
}

// ACTION TYPES
export type PagesAction =
  | LoadPages
  | LoadPagesFail
  | LoadPagesSuccess
  | VisualisePage
  | VisualiseSection
  | UpdatePageSectionWidgets
  | AddPageSection
  | RemovePageSection
  | ResetPageSection
  | MovePageSection
  | EnableEditMode
  | DisableEditMode
  | HideMenu
  | ShowMenu
  | ActivateOverlayMenu
  | DeactivateOverlayMenu
  | HideOverlayMenu
  | ShowOverlayMenu
  | UpdateMenu
  | SelectMenu
  | ExpandSettings
  | UpdatePage
  | UpdatePageFail
  | UpdatePageSuccess;
