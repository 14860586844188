import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class LocationFormsService {
  constructor(private formBuilder: FormBuilder) {}

  /**
   * Create Location Stats Form
   */
  initLocationStatsForm(): FormGroup {
    return this.formBuilder.group({
      from: [null, Validators.required],
      to: [null, Validators.required],
      interval: [null, Validators.required],
      isCombine: false,
      graphs: this.formBuilder.array([this.initGraphForm()], Validators.required),
      locations: null,
    });
  }

  /**
   * Create Location Stats Graph form
   */
  initGraphForm(): FormGroup {
    return this.formBuilder.group({
      dataset: [null, Validators.required],
      yAxisID: null,
      position: null,
      backgroundColor: [null, Validators.required],
      borderColor: [null, Validators.required],
      styles: null,
      // opacity: null,
    });
  }

  initLocationForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      idx: { value: null, disabled: true },
      parentIdx: null,
      automationId: null,
      siteId: [null, Validators.required],
      parentId: null,
      locationTypeId: [null, Validators.required],
      description: [null, Validators.required],
      locationCondition: [0, [Validators.min(0), Validators.max(100)]],
      disableCalculation: false,
      trackOccupancy: false,
      occupancyDelay: null,
      occupancyTimeout: null,
      isBookedButNoCheckInDone: false,
      isBookedAndCheckInDone: false,
      isCheckInInProgress: false,
      isCheckOutInProgress: false,
      isDoNotDisturbModeActive: false,
      isMakeUpMyRoomRequested: false,
      isOutOfOrder: false,
      acValveAlertDiffPoint: null,
      acValveAlertTimeout: null,
      floorplanImage: null,
      floorplanScale: null,
      locationTypeName: null,
      maxPeople: [0, Validators.min(0)],
      floorNumber: null,
      isActive: false,
      isDeleted: false,
    });
  }
}
