<div class="device-setting" [formGroup]="form" [ngClass]="{ opened: isOpened }">
  <div class="device-setting-header">
    <div class="device-setting-header-toggler">
      <span
        class="fa fa-fw"
        [ngClass]="{ 'fa-chevron-up': isOpened, 'fa-chevron-down': !isOpened }"
        (click)="isOpened = !isOpened"
      ></span>
    </div>

    <div class="device-setting-header-title">
      {{ data?.deviceTypeName }}
    </div>

    <div class="device-setting-header-actions">
      <sc-checkbox-input
        controlName="isActive"
        [form]="form"
        label="{{ 'ACTIVE' | translate }}"
        [showLabel]="false"
      ></sc-checkbox-input>
    </div>
  </div>

  <div class="device-setting-content" *ngIf="isOpened">
    <div class="ui-g">
      <!-- DESCRIPTION -->
      <div class="ui-g-12 ui-md-6">
        <sc-text-input
          controlName="description"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <!-- AVERAGE_CALCULATION_WEIGHT -->
      <div class="ui-g-12 ui-md-3">
        <sc-number-input
          controlName="avcCalcWeight"
          [form]="form"
          label="{{ 'AVERAGE_CALCULATION_WEIGHT' | translate }}"
        ></sc-number-input>
      </div>

      <!-- DIFF_DETECTION_FACTOR -->
      <div class="ui-g-12 ui-md-3">
        <sc-text-input
          controlName="diffDetectionFactor"
          [form]="form"
          label="{{ 'CCP_DEVICE_MIN_DIFFERENCE_THRESHOLD' | translate }}"
        ></sc-text-input>
      </div>

      <!-- EXCLUDE_FROM_LHL -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="excludeFromLHL"
          [form]="form"
          label="{{ 'EXCLUDE_FROM_LHL' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- IS_GLOBAL_DEVICE -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="isGlobalDevice"
          [form]="form"
          label="{{ 'GLOBAL_DEVICE' | translate }}"
        ></sc-checkbox-input>
      </div>

      <!-- CONSIDER_FOR_OCCUPANCY -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="considerForOccupancy"
          [form]="form"
          label="{{ 'CONSIDER_FOR_OCCUPANCY' | translate }}"
        ></sc-checkbox-input>
      </div>
    </div>
  </div>
</div>
