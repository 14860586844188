import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'sc-checkbox-cell',
  templateUrl: 'checkbox-cell.component.html',
})
export class CheckboxCellComponent implements ICellRendererAngularComp {
  cell: any;

  constructor() {}

  agInit(params: any): void {
    this.cell = params;
  }

  refresh(): boolean {
    return false;
  }

  icon() {
    if (
      this.cell.value === true ||
      this.cell.value === 1 ||
      this.cell.valueFormatted === true ||
      this.cell.valueFormatted === 1
    ) {
      return 'fa fa-check green-text';
      // } else if (
      //   this.cell.value === false ||
      //   this.cell.valueFormatted === false ||
      //   this.cell.value === 0 ||
      //   this.cell.value === null
      // ) {
      //   return 'fa fa-times red-text';
    } else {
      return 'fa fa-times red-text';
    }
  }
}
