<form
  [formGroup]="form"
  autocomplete="off"
  (ngSubmit)="submit()"
>
  <ng-container *ngIf="!twoFactorAuthMethod">
    <div class="mb-3">
      <label for="username">
        {{ 'USERNAME' | translate }}
      </label>
      <input
        id="username"
        type="text"
        pInputText
        formControlName="username"
        autocomplete="username"
      />
    </div>

    <div class="mb-3">
      <label for="password">
        {{ 'PASSWORD' | translate }}
      </label>
      <input
        id="password"
        type="password"
        pInputText
        formControlName="password"
        autocomplete="new-password"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="twoFactorAuthMethod">
    <div class="mb-3">
      <label for="twoFactorAuthCode">
        {{ 'TWO_FACTOR_AUTH_CODE' | translate }}
      </label>
      <input
        id="twoFactorAuthCode"
        type="text"
        pInputText
        formControlName="twoFactorAuthCode"
        autocomplete="new-password"
      />
    </div>
  </ng-container>

  <div class="mb-3">
    <button
      pButton
      type="submit"
      [disabled]="isVerifying"
      label="{{ (isVerifying ? 'CONNECTING' : 'LOGIN') | translate }}"
      icon="fa {{ isVerifying ? 'fa-refresh fa-spin' : 'fa-sign-in' }}"
      iconPos="right"
    ></button>
  </div>

  <div *ngIf="errorMessages">
    <p-messages [value]="errorMessages" [closable]="false"></p-messages>
  </div>
</form>

<div class="py-5" *ngIf="isBlocked">
  <div *ngIf="countDownResend > 0">
    Unblock email was sent. You can resend again in
    {{ countDownResend }} second(s).
  </div>

  <div *ngIf="!countDownResend">
    Please check an email for unblock the account. Didn't get email ?
    <a
      class="green-text sc-clickable"
      [class.disabled]="isResending"
      (click)="resend()"
    >
      resend
    </a>

    <div class="grey-text" *ngIf="isResending">
      <small>
        <i class="fa fa-refresh fa-spin"></i> Sending unblock email...
      </small>
    </div>
  </div>
</div>
