<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        {{ widgetName }}
      </h4>
    </div>

    <div class="sc-widget-body">
      <p>{{ "COMING_SOON" | translate }}</p>
    </div>
  </div>
</div>
