import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LocationProfile } from '../models/location-profile';

import { config } from '@app/config';

@Injectable()
export class LocationProfilesService {
  apiUrl = config().apiUrl + '/locationProfiles';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): LocationProfile {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  getLocationProfile(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getLocationProfiles() {
    return this.httpClient.get(this.apiUrl);
  }

  createLocationProfile(data: LocationProfile) {
    return this.httpClient.post(this.apiUrl, this.verifyData(data));
  }

  updateLocationProfile(id: number, data: LocationProfile) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteLocationProfile(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isDeleted: true,
      isActive: false
    });
  }
}
