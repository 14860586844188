import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class LocationPropertyFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initLocationPropertyForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      label: [null, Validators.required],
      operators: [null, Validators.required],
      type: [null, Validators.required],
      unit: null,
      deviceType: null,
      decimals: [null, [Validators.min(0)]],
      min: [null, [Validators.min(0)]],
      max: [null, [Validators.min(0)]],
      isActive: false,
      isDeleted: false
    });
  }
}
