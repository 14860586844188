import { isDevMode } from '@angular/core';
import { environment } from '@env/environment';
import { CompactType, DisplayGrid, GridType } from 'angular-gridster2';

// TODO: 1. use 'key' instead of 'id' and 'username'
//       2. delete 'id' and 'username'
const regexPatterns = {
  id: /^\w+$/,
  username: /^\w+$/,
  key: /^\w+$/,
  password: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
  phone: /^\+[1-9][0-9]{6,}$/,
  // NOT TESTED YET
  cron: '^\\s*($|#|\\w+\\s*=|(\\?|\\*|(?:[0-5]?\\d)(?:(?:-|/|\\,)(?:[0-5]?\\d))?(?:,(?:[0-5]?\\d)(?:(?:-|/|\\,)(?:[0-5]?\\d))?)*)\\s+(\\?|\\*|(?:[0-5]?\\d)(?:(?:-|/|\\,)(?:[0-5]?\\d))?(?:,(?:[0-5]?\\d)(?:(?:-|/|\\,)(?:[0-5]?\\d))?)*)\\s+(\\?|\\*|(?:[01]?\\d|2[0-3])(?:(?:-|/|\\,)(?:[01]?\\d|2[0-3]))?(?:,(?:[01]?\\d|2[0-3])(?:(?:-|/|\\,)(?:[01]?\\d|2[0-3]))?)*)\\s+(\\?|\\*|(?:0?[1-9]|[12]\\d|3[01])(?:(?:-|/|\\,)(?:0?[1-9]|[12]\\d|3[01]))?(?:,(?:0?[1-9]|[12]\\d|3[01])(?:(?:-|/|\\,)(?:0?[1-9]|[12]\\d|3[01]))?)*)\\s+(\\?|\\*|(?:[1-9]|1[012])(?:(?:-|/|\\,)(?:[1-9]|1[012]))?(?:L|W)?(?:,(?:[1-9]|1[012])(?:(?:-|/|\\,)(?:[1-9]|1[012]))?(?:L|W)?)*|\\?|\\*|(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(?:,(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*)\\s+(\\?|\\*|(?:[0-6])(?:(?:-|/|\\,|#)(?:[0-6]))?(?:L)?(?:,(?:[0-6])(?:(?:-|/|\\,|#)(?:[0-6]))?(?:L)?)*|\\?|\\*|(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?(?:,(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?)*)(|\\s)+(\\?|\\*|(?:|\\d{4})(?:(?:-|/|\\,)(?:|\\d{4}))?(?:,(?:|\\d{4})(?:(?:-|/|\\,)(?:|\\d{4}))?)*))$',
};

const breakPoints = {
  md: 1200,
  sm: 800,
};

// *** WARNING!! MODIFY THIS WILL BREAK THE PAGE LAYOUT
const gridConfig = {
  gridType: GridType.VerticalFixed, // 'verticalFixed',
  minCols: 12,
  maxCols: 12,
  minRows: 1,
  maxRows: 50,
  fixedRowHeight: 150,
  fixedColWidth: 150,
  mobileBreakpoint: breakPoints.sm,
  keepFixedHeightInMobile: false,
  keepFixedWidthInMobile: false,
  pushItems: true,
  compactType: CompactType.CompactUp, // 'compactUp',
  displayGrid: DisplayGrid.None, // 'none',
  draggable: {
    enabled: true,
    dragHandleClass: 'widget-drag-handler',
    ignoreContent: true,
  },
  resizable: {
    enabled: true,
    handles: {
      s: false,
      e: false,
      n: false,
      w: false,
      se: true,
      ne: false,
      sw: false,
      nw: false,
    },
  },
  disableWarnings: true,
  // disableAutoPositionOnConflict: true,
  // setGridSize: true,
};

// MENU CONFIG
const menu = {
  breakPoints: breakPoints.md,
};

const menuItems = [
  {
    id: 'setting_app_style_templates',
    label: 'APP_STYLE_TEMPLATES',
    icon: 'fa fa-fw fa-paint-brush',
    isSetting: true,
  },
  {
    id: 'setting_app_styles',
    label: 'APP_STYLES',
    icon: 'fa fa-fw fa-magic',
    isSetting: true,
  },
  {
    id: 'setting_apps',
    label: 'APPS',
    icon: 'fa fa-fw fa-star-o',
    isSetting: true,
  },
  {
    id: 'setting_custom_apps',
    label: 'CUSTOM_APPS',
    icon: 'fa fa-fw fa-star',
    isSetting: true,
  },
  {
    id: 'setting_benchmark_templates',
    label: 'BENCHMARK_TEMPLATES',
    icon: 'fa fa-fw fa-dashboard',
    isSetting: true,
  },
  {
    id: 'setting_device_task_templates',
    label: 'DEVICE_TASK_TEMPLATES',
    icon: 'fa fa-fw fa-tasks',
    isSetting: true,
  },
  {
    id: 'setting_devices',
    label: 'DEVICES',
    icon: 'fa fa-fw fa-mobile',
    isSetting: true,
  },
  {
    id: 'setting_enery_saving',
    label: 'ENERGY_SAVING',
    icon: 'fa fa-fw fa-dollar',
    isSetting: true,
  },
  {
    id: 'setting_form',
    label: 'FORM',
    icon: 'fa fa-fw fa-list-alt',
    isSetting: true,
  },
  {
    id: 'setting_guest_requests',
    label: 'GUEST_REQUESTS',
    icon: 'fa fa-fw fa-edit',
    isSetting: true,
  },
  {
    id: 'setting_internationalization',
    label: 'INTERNATIONALIZATION',
    icon: 'fa fa-fw fa-globe',
    isSetting: true,
  },
  {
    id: 'setting_locations',
    label: 'LOCATIONS',
    icon: 'fa fa-fw fa-location-arrow',
    isSetting: true,
  },
  {
    id: 'setting_notification_templates',
    label: 'NOTIFICATION_TEMPLATES',
    icon: 'fa fa-fw fa-bell-o',
    isSetting: true,
  },
  {
    id: 'setting_page_templates',
    label: 'PAGE_TEMPLATES',
    icon: 'fa fa-fw fa-puzzle-piece',
    isSetting: true,
  },
  {
    id: 'setting_pms',
    label: 'PMS',
    icon: 'fa fa-fw fa-hotel',
    isSetting: true,
  },
  {
    id: 'setting_report_templates',
    label: 'REPORT_TEMPLATES',
    icon: 'fa fa-fw fa-file-invoice-dollar',
    isSetting: true,
  },
  {
    id: 'setting_rules',
    label: 'RULES',
    icon: 'fa fa-fw fa-exclamation-triangle',
    isSetting: true,
  },
  {
    id: 'setting_scene_templates',
    label: 'SCENE_TEMPLATES',
    icon: 'fa fa-fw fa-object-group',
    isSetting: true,
  },
  {
    id: 'setting_sites',
    label: 'SITES',
    icon: 'fa fa-fw fa-globe',
    isSetting: true,
  },
  {
    id: 'setting_user_task_templates',
    label: 'USER_TASK_TEMPLATES',
    icon: 'fa fa-fw fa-tasks',
    isSetting: true,
  },
  {
    id: 'trash',
    label: 'TRASH',
    icon: 'fa fa-fw fa-trash',
    isSetting: true,
  },
  {
    id: 'setting_users',
    label: 'USERS',
    icon: 'fa fa-fw fa-users',
    isSetting: true,
  },
  {
    id: 'setting_widgets',
    label: 'WIDGETS',
    icon: 'fa fa-fw fa-window-maximize',
    isSetting: true,
  },
  {
    id: 'dashboards',
    label: 'DASHBOARDS',
    icon: 'fa fa-fw fa-tachometer',
    isSetting: false,
  },
  {
    id: 'devices',
    label: 'DEVICES',
    icon: 'fa fa-fw fa-mobile',
    isSetting: false,
  },
  {
    id: 'locations',
    label: 'LOCATIONS',
    icon: 'fa fa-fw fa-location-arrow',
    isSetting: false,
  },
  {
    id: 'automations',
    label: 'AUTOMATIONS',
    icon: 'fa fa-fw fa-microchip',
    isSetting: false,
  },
  {
    id: 'sites',
    label: 'SITES',
    icon: 'fa fa-fw fa-globe',
    isSetting: false,
  },
  {
    id: 'events',
    label: 'EVENTS',
    icon: 'fa fa-fw fa-calendar',
    isSetting: false,
  },
  {
    id: 'guest_requests',
    label: 'GUEST_REQUESTS',
    icon: 'fa fa-fw fa-edit',
    isSetting: false,
  },
  {
    id: 'guest_sessions',
    label: 'GUEST_SESSIONS',
    icon: 'fa fa-fw fa-address-card',
    isSetting: false,
  },
  {
    id: 'rule_monitor',
    label: 'RULE_MONITOR',
    icon: 'fa fa-fw fa-desktop',
    isSetting: false,
  },
  {
    id: 'benchmarks',
    label: 'BENCHMARKS',
    icon: 'fa fa-fw fa-tasks',
    isSetting: false,
  },
  {
    id: 'users',
    label: 'USERS',
    icon: 'fa fa-fw fa-users',
    isSetting: false,
  },
  {
    id: 'filesmanager',
    label: 'FILES_MANAGER',
    icon: 'fa fa-fw fa-file',
    isSetting: false,
  },
  // FIXME: setting route for normal user
  {
    id: 'setting_app_styles',
    label: 'APP_STYLES',
    icon: 'fa fa-fw fa-magic',
    permission: 'app_styles_r',
    isSetting: false,
  },
  {
    id: 'setting_custom_apps',
    label: 'CUSTOM_APPS',
    icon: 'fa fa-fw fa-star',
    permission: 'custom_apps_r',
    isSetting: false,
  },
  // FIXME: END setting route for normal user
  {
    id: 'companies',
    label: 'COMPANIES',
    icon: 'fa fa-fw fa-building',
    isSetting: false,
  },
];

const integratorMenuItems = [
  {
    id: 'integrator_overview',
    path: ['integrator', 'overview'],
    label: 'OVERVIEW',
    icon: 'icon icon-overview',
    isSetting: false,
  },
  {
    id: 'integrator_sites',
    path: ['integrator', 'sites'],
    label: 'MY_SITES',
    icon: 'icon icon-sites',
    isSetting: false,
  },
  {
    id: 'integrator_automations',
    path: ['integrator', 'automations'],
    label: 'MY_AUTOMATIONS',
    icon: 'icon icon-automation',
    isSetting: false,
  },
  {
    id: 'my_account',
    path: ['my_account'],
    label: 'MY_ACCOUNT',
    icon: 'icon icon-account',
    isSetting: false,
  },
  {
    id: 'integrator_company',
    path: ['integrator', 'company'],
    label: 'MY_COMPANY',
    icon: 'icon icon-company',
    isSetting: false,
  },
  {
    id: 'integrator_rule_templates',
    path: ['integrator', 'rule_templates'],
    label: 'RULE_TEMPLATES',
    isSetting: true,
  },
  {
    id: 'integrator_report_templates',
    path: ['integrator', 'report_templates'],
    label: 'REPORT_TEMPLATES',
    isSetting: true,
  },
  {
    id: 'integrator_user_task_templates',
    path: ['integrator', 'user_task_templates'],
    label: 'USER_TASK_TEMPLATES',
    isSetting: true,
  },
  {
    id: 'integrator_custom_functions',
    path: ['integrator', 'custom_functions'],
    label: 'CUSTOM_FUNCTIONS',
    isSetting: true,
  },
];

const endUserMenuItems = [
  {
    id: 'integrator_overview',
    path: ['integrator', 'overview'],
    label: 'OVERVIEW',
    icon: 'icon icon-overview',
    isSetting: false,
  },
  {
    id: 'enduser_sites',
    path: ['enduser', 'sites'],
    label: 'MY_SITES',
    icon: 'icon icon-sites',
    isSetting: false,
  },
  {
    id: 'my_account',
    path: ['my_account'],
    label: 'MY_ACCOUNT',
    icon: 'icon icon-account',
    isSetting: false,
  },
];

// SELECT ITEMS FOR DROPDOWN
const booleanOption = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

const connectors = [
  { label: 'AND', value: 'AND' },
  { label: 'OR', value: 'OR' },
];

const datetimePropertyTypes = [
  { label: 'Date (dd.mm.yyyy)', value: 'date' },
  { label: 'Datetime (dd.mm.yyyy HH:ii:ss)', value: 'datetime' },
  { label: 'Day (1-31)', value: 'day' },
  { label: 'Hour (1-24)', value: 'hour' },
  { label: 'Minute (0-59)', value: 'minute' },
  { label: 'Month (1-12)', value: 'month' },
  // { label: 'Second (0-59)',                  value: 'second' },
  // { label: 'Sunset',                         value: 'sunset' },
  // { label: 'Sunrise',                        value: 'sunrise' },
  { label: 'Time (HH:ii:ss)', value: 'time' },
  { label: 'Weekday (mon-sun)', value: 'weekday' },
  { label: 'Year', value: 'year' },
];

const deviceValueStorageActions = [
  { label: 'store', value: 'store' },
  { label: 'restore', value: 'restore' },
];

const energySavingPatterns = [
  { label: 'HVAC turned off', value: 'ac_turned_off' },
  { label: 'HVAC setpoint corrected', value: 'ac_setpoint_corrected' },
  { label: 'Lights turned off', value: 'lights_turned_off' },
];

const events = [{ label: 'onChange', value: 'onChange' }];

const gender = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
];

const locationModifyTypes = [
  { label: 'Adjust Value', value: 'adjust' },
  { label: 'Force Value', value: 'force' },
];

// const locationPropertyTypes = [
//   { label: 'HVAC Fanspeed', value: 'acFanspeed' },
//   { label: 'HVAC Mode', value: 'acMode' },
//   { label: 'HVAC Setpoint', value: 'acSetpoint' },
//   { label: 'HVAC Status', value: 'acStatus' },
//   { label: 'Air Quality', value: 'airQuality' },
//   { label: 'Brightness', value: 'brightness' },
//   { label: 'Consumption', value: 'consumption' },
//   { label: 'Consumption Total', value: 'consumptionTotal' },
//   { label: 'Curtains Open', value: 'curtainsOpen' },
//   { label: 'Do Not Disturb Status', value: 'dndStatus' },
//   { label: 'Door Locked', value: 'doorLocked' },
//   { label: 'Door Open', value: 'doorOpen' },
//   { label: 'Humidity', value: 'humidity' },
//   { label: 'Is booked and check in done', value: 'isBookedAndCheckInDone' },
//   { label: 'Is booked but no check in done', value: 'isBookedButNoCheckInDone' },
//   { label: 'Is check in in-progress', value: 'isCheckInInProgress' },
//   { label: 'Is check out in-progress', value: 'isCheckOutInProgress' },
//   { label: 'Is raining', value: 'isRaining' },
//   { label: 'Lights On', value: 'lightsOn' },
//   { label: 'Make Up Status', value: 'makeUpStatus' },
//   { label: 'Occupied', value: 'occupied' },
//   { label: 'Out Of Order Status', value: 'oooStatus' },
//   { label: 'Outdoor Humidity', value: 'outdoorHumidity' },
//   { label: 'Outdoor Temperature', value: 'outdoorTemperature' },
//   { label: 'Square Meter', value: 'squareMeter' },
//   { label: 'Temperature', value: 'temperature' },
//   { label: 'Wind Speed', value: 'windSpeed' },
//   { label: 'Window Open', value: 'windowOpen' },
// ];

const locationPropertyValueTypes = [
  { label: 'Boolean', value: 'boolean' },
  { label: 'Device Type', value: 'deviceType' },
  { label: 'Number', value: 'number' },
];

const operators = [
  { label: '==', value: '==' },
  { label: '>=', value: '>=' },
  { label: '<=', value: '<=' },
  { label: '>', value: '>' },
  { label: '<', value: '<' },
  { label: '!=', value: '!=' },
];

const referencedTypes = [
  { label: 'Device', value: 'd' },
  { label: 'Location', value: 'l' },
  { label: 'Value', value: 'v' },
  { label: 'Value Changes', value: 'vc' },
];

const services = [
  { label: 'Notifier', value: 'n' },
  // { label: 'Task Manager', value: 'tm' }
];

const sourceTypes = [
  { label: 'Date & Time', value: 'dt' },
  { label: 'Device', value: 'd' },
  { label: 'Location', value: 'l' },
];

const targetTypes = [
  { label: 'Device', value: 'd' },
  { label: 'Device value storage', value: 'dvs' },
  { label: 'Location', value: 'l' },
  { label: 'Service', value: 's' },
];

const deviceValueModifiers = [
  { label: '+', value: '+' },
  { label: '-', value: '-' },
  { label: '*', value: '*' },
  { label: '/', value: '/' },
  { label: '=', value: '=' },
];

const zigbeeStatus = [
  { label: 'On', value: 1 },
  { label: 'Off', value: 0 },
];

const zigbeeControllerPorts = [
  { label: '/dev/ttyS0', value: '/dev/ttyS0' },
  { label: '/dev/ttyS1', value: '/dev/ttyS1' },
  { label: '/dev/ttyS2', value: '/dev/ttyS2' },
  { label: '/dev/ttyS3', value: '/dev/ttyS3' },
  { label: '/dev/ttyS4', value: '/dev/ttyS4' },
  { label: '/dev/ttyS5', value: '/dev/ttyS5' },
];

const zwaveStatus = [
  { label: 'On', value: 1 },
  { label: 'Off', value: 0 },
];

const zwaveFrequencies = [
  { label: 'AU - 921.4MHz', value: 'au' },
  { label: 'EU - 868.4MHz', value: 'eu' },
  { label: 'US - 908.4MHz', value: 'us' },
];

const zwaveControllerPorts = [
  { label: '/dev/ttyACM0', value: '/dev/ttyACM0' },
  { label: '/dev/ttyACM1', value: '/dev/ttyACM1' },
  { label: '/dev/ttyACM2', value: '/dev/ttyACM2' },
  { label: '/dev/ttyACM3', value: '/dev/ttyACM3' },
  { label: '/dev/ttyS0', value: '/dev/ttyS0' },
  { label: '/dev/ttyS1', value: '/dev/ttyS1' },
  { label: '/dev/ttyS2', value: '/dev/ttyS2' },
  { label: '/dev/ttyS3', value: '/dev/ttyS3' },
  { label: '/dev/ttyUSB0', value: '/dev/ttyUSB0' },
  { label: '/dev/ttyUSB1', value: '/dev/ttyUSB1' },
  { label: '/dev/ttyUSB2', value: '/dev/ttyUSB2' },
  { label: '/dev/ttyUSB3', value: '/dev/ttyUSB3' },
];

const colors = [
  'rgba(3, 72, 176, 1)',
  'rgba(219, 84, 97, 1)',
  'rgba(105, 143, 63, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(205, 202, 92, 1)',
  'rgba(86, 116, 150, 1)',
  'rgba(255, 103, 0, 1)',
  'rgba(128, 78, 73, 1)',
  'rgba(6, 116, 129, 1)',
  'rgba(51, 28, 20, 1)',
  'rgba(234, 158, 150, 1)',
  'rgba(6, 77, 61, 1)',
  'rgba(178, 139, 80, 1)',
  'rgba(192, 60, 185, 1)',
  'rgba(242, 98, 71, 1)',
];

const chartAxes = [
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' },
];

const chartStyles = [
  { label: 'Dash Line', value: 'dash_line' },
  { label: 'Draw Points', value: 'draw_points' },
  { label: 'Filled', value: 'filled' },
  { label: 'Hide Grid', value: 'hide_grid' },
  { label: 'Hide Line', value: 'hide_line' },
  { label: 'Hide Tick', value: 'hide_tick' },
  { label: 'Stepped Line', value: 'stepped_line' },
];

const dataTypes = [
  { label: 'Boolean', value: 'bool' },
  { label: 'Float', value: 'float' },
  { label: 'Integer', value: 'int' },
  { label: 'String', value: 'string' },
];

const twoFactorAuthMethods = [
  { label: 'Disable', value: '' },
  { label: 'Email', value: 'email' },
  { label: 'SMS', value: 'sms' },
];

const notificationTemplatePlaceholders = [
  { label: '%deviceId%', value: '%deviceId%' },
  { label: '%locationId%', value: '%locationId%' },
  { label: '%ruleId%', value: '%ruleId%' },
  { label: '%device_name%', value: '%device_name%' },
  { label: '%location_name%', value: '%location_name%' },
  { label: '%recipient_name%', value: '%recipient_name%' },
  { label: '%rule_name%', value: '%rule_name%' },
  { label: '%site_name%', value: '%site_name%' },
];

const backendVariants = [
  { label: 'Ceos', value: 'ceos' },
  { label: 'SCv4', value: 'scv4' },
];

const userMainRoles = [
  { label: 'S&C team member', value: 'sc' },
  { label: 'Integrator', value: 'integrator' },
  { label: 'End user', value: 'enduser' },
];

const selectItems: any = {
  booleanOption,
  chartAxes,
  chartStyles,
  connectors,
  dataTypes,
  datetimePropertyTypes,
  deviceValueModifiers,
  deviceValueStorageActions,
  energySavingPatterns,
  events,
  gender,
  locationModifyTypes,
  // locationPropertyTypes,
  locationPropertyValueTypes,
  notificationTemplatePlaceholders,
  operators,
  referencedTypes,
  services,
  sourceTypes,
  targetTypes,
  twoFactorAuthMethods,
  zigbeeControllerPorts,
  zigbeeStatus,
  zwaveControllerPorts,
  zwaveFrequencies,
  zwaveStatus,
  backendVariants,
  userMainRoles,
};

const deviceTypeKeyHasNumberValue = [
  'acsp',
  'bl',
  'bs',
  'emr',
  'hs',
  'ps',
  'ts',
  'vs',
  'ws',
  'dim',
  'pcl',
  'pc',
  'pcg',
];
const lhlHasBooleanValue = [
  'occupied',
  'windowopen',
  'dooropen',
  'doorlocked',
  'curtainsopen',
  'dndstatus',
  'makeupstatus',
  'ooostatus',
  'ischeckininprogress',
  'isbookedandcheckindone',
  'isbookedbutnocheckindone',
  'ischeckoutinprogress',
  'israining',
  'lightson',
];

// copied from https://gist.github.com/keeguon/2310008
const countries = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];
selectItems.countries = countries.map((c) => ({ label: c.name, value: c.code }));

const teamRoles = [
  { label: 'Admin', value: 'admin' },
  { label: 'Member', value: 'member' },
];
selectItems.teamRoles = teamRoles;

const returnTypes = [
  { id: 'boolean', label: 'Boolean' },
  { id: 'numeric', label: 'Numeric' },
  { id: 'text', label: 'Text' },
];
selectItems.returnTypes = returnTypes.map((item) => ({ label: item.label, value: item.id }));

const valueTypes = [
  { id: 'boolean', label: 'Boolean' },
  { id: 'datepicker', label: 'Date Picker' },
  { id: 'datetimepicker', label: 'Date & Time Picker' },
  { id: 'numeric', label: 'Numeric' },
  { id: 'select', label: 'Select' },
  { id: 'text', label: 'Text' },
  { id: 'timepicker', label: 'Time Picker' },
];
selectItems.valueTypes = valueTypes.map((item) => ({ label: item.label, value: item.id }));

function getConfig() {
  // `DEVELOPMENT` ENVIRONMENT
  const envConfig = {
    apiUrl: environment.apiUrl,
    s3Url: environment.s3Url,
    regexPatterns,
    breakPoints,
    colors,
    deviceTypeKeyHasNumberValue,
    gridConfig,
    lhlHasBooleanValue,
    menu,
    menuItems,
    integratorMenuItems,
    endUserMenuItems,
    selectItems,
    countries,
  };

  // `PRODUCTION` ENVIRONMENT
  if (!isDevMode()) {
    envConfig.apiUrl = environment.apiUrl;
    envConfig.s3Url = environment.s3Url;
  }

  return envConfig;
}

export const config = getConfig;
