import { NgModule } from '@angular/core';

// MODULES
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SpinnerModule } from 'primeng/spinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TabViewModule } from 'primeng/tabview';
import { FieldsetModule } from 'primeng/fieldset';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SplitButtonModule } from 'primeng/splitbutton';
import { PaginatorModule } from 'primeng/paginator';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { FileUploadModule } from 'primeng/fileupload';

// SERVICES
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

export const MODULES: any[] = [
  AccordionModule,
  AutoCompleteModule,
  BlockUIModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  CheckboxModule,
  ConfirmDialogModule,
  DialogModule,
  DynamicDialogModule,
  DropdownModule,
  EditorModule,
  FileUploadModule,
  FullCalendarModule,
  InputNumberModule,
  InputTextModule,
  InputTextareaModule,
  InputSwitchModule,
  ListboxModule,
  MessageModule,
  MessagesModule,
  MultiSelectModule,
  OverlayPanelModule,
  PanelModule,
  PanelMenuModule,
  PickListModule,
  ProgressBarModule,
  RadioButtonModule,
  ScrollPanelModule,
  SidebarModule,
  SpinnerModule,
  ToolbarModule,
  TooltipModule,
  ToastModule,
  ToggleButtonModule,
  TableModule,
  TreeModule,
  TabViewModule,
  FieldsetModule,
  BreadcrumbModule,
  SplitButtonModule,
  PaginatorModule,
];

@NgModule({
  imports: MODULES,
  exports: MODULES,
  providers: [ConfirmationService, DialogService, MessageService],
})
export class PrimeModule {}
