import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { GridOptions } from 'ag-grid';
import { Device } from '@widgets/devices/models/device';
import { Site } from '@widgets/sites/models/site';
import { ModalConfig } from '@sc/modal/modal-config';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { DeviceHistoryLogsService } from '@widgets/devices/services/device-history-logs.service';
import { SharedService } from '@shared/shared.service';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { Location } from '@widgets/locations/models/location';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { CeosDeviceActionCellComponent } from '@widgets/devices/ceos-device-action-cell/ceos-device-action-cell.component';
import { CeosDeviceLiveDataCellComponent } from '@widgets/devices/ceos-device-live-data-cell/ceos-device-live-data-cell.component';
import { CeosDevicePropertiesCellComponent } from '@widgets/devices/ceos-device-properties-cell/ceos-device-properties-cell.component';

@Component({
  selector: 'sc-ceos-devices-table',
  templateUrl: './ceos-devices-table.component.html',
})
export class CeosDevicesTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  config: any;
  @Input()
  selectedLocation: Location;

  columns: any[];
  dataset: Device[];
  // formConfig: ModalConfig;
  table: GridOptions;
  datasource: any;
  tableTitle = 'DEVICES';
  isFetchingDataset = false;
  isFetchingLatestDeviceHistoryLogs = false;
  gridOptions: GridOptions;
  scaffold: any;
  ceosDeviceTypeEntities: any;
  // ceosDeviceTypes: any[];
  deviceTypes: DeviceType[] = [];

  private selectedSite: Site;
  private intervals: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};
  private visibleColumns: string[] = [];

  constructor(
    private devicesService: DevicesService,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private deviceTypesService: DeviceTypesService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    // console.log(this.config);
    // Table Columns
    this.columns = this.createColumns();

    // Set user's visible columns
    if (this.config && this.config.customOptions && this.config.customOptions.visibleColumns) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }

    // Get Selected Site
    this.subscribers.watchSite = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;

        this.fetchDataset();
      }
    });

    this.fetchCeosScaffold();
    this.fetchDeviceTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLocation && changes.selectedLocation.currentValue) {
      this.fetchDataset();
    }
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearIntervals(this.timeouts);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchCeosScaffold() {
    this.subscribers.getCeosScaffold = this.sharedService.getCeosScaffold().subscribe({
      next: (result: any) => {
        this.scaffold = result.data;
        this.ceosDeviceTypeEntities = this.scaffold.device_types;
        // this.ceosDeviceTypes = Object.keys(this.scaffold.device_types).map((key) => ({
        //   ...this.scaffold.device_types[key],
        //   key,
        // }));
        this.updatePropertiesColumn();
      },
    });
  }

  private fetchDeviceTypes() {
    this.deviceTypes = [];
    this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data;
    });
  }

  fetchDataset() {
    if (this.isFetchingDataset || !this.selectedSite) {
      return;
    }

    this.isFetchingDataset = true;
    this.stopAutoUpdate();
    this.toggleTableOverlay();

    const options: any = { siteId: this.selectedSite.id };
    if (this.selectedLocation) {
      options.locationIdx = this.selectedLocation.idx;
    }
    this.subscribers.getDataset = this.devicesService.getCeosDevices(options).subscribe((result: any) => {
      if (result.mappingKeys) {
        this.dataset = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);
      } else {
        this.dataset = result.data;
      }

      this.isFetchingDataset = false;
      this.updatePropertiesColumn();
      this.startAutoUpdate();
      this.toggleTableOverlay(true);
    });
  }

  /**
   * get latest dhls of all devices of the automation's site
   */
  private fetchLatestHistory() {
    // TODO:
    if (this.isFetchingLatestDeviceHistoryLogs || !this.selectedSite) {
      return;
    }
    this.isFetchingLatestDeviceHistoryLogs = true;
    const options = {
      filter: {
        companyId: this.selectedSite.companyId,
        siteId: this.selectedSite.id,
      },
      latestLog: true,
      limit: 5000,
    };
    this.subscribers.getLatestDeviceHistoryLogs = this.deviceHistoryLogsService
      .getHistoryLogs(options)
      .subscribe((result: any) => {
        this.isFetchingLatestDeviceHistoryLogs = false;
        this.updateLatestHistory(result);
      });
  }

  private updateLatestHistory(latestDHLs: any[]) {
    if (this.isFetchingDataset || !this.table || !latestDHLs || !latestDHLs.length || !this.ceosDeviceTypeEntities) {
      return;
    }

    this.table.api.forEachNode((node) => {
      const rowData = node.data;
      const virtualDevices = rowData && rowData.virtualDevices;
      let changeDetect = false;
      // console.log('virtualDevices:', virtualDevices);
      if (virtualDevices && virtualDevices.length) {
        for (const vd of virtualDevices) {
          const deviceType = this.ceosDeviceTypeEntities[vd.deviceTypeKey];

          if (deviceType) {
            vd.deviceTypeName = deviceType.label;
          }

          for (const dhl of latestDHLs) {
            if ((vd.id === dhl.deviceId || vd.idx === dhl.deviceId) && vd.value !== dhl.value) {
              // When hovering a property (VD):
              // show a tooltip like <device_type_name>, <time_ago> ago`.
              // If we don’t have a DHL: <device_type_name>, no data yet`
              if (deviceType && deviceType.render && deviceType.render.type) {
                if (
                  deviceType.render.type === 'select' &&
                  deviceType.render.options &&
                  deviceType.render.options.length
                ) {
                  const opt = deviceType.render.options.find((item) => item.value === dhl.value);
                  vd.formattedValue = opt ? opt.label : dhl.value;
                } else if (deviceType.render.type === 'numeric') {
                  vd.formattedValue = this.sharedService.numberFormat(dhl.value, 1);
                }
                vd.unit = deviceType.render.unit;
              }
              vd.value = dhl.value;
              vd.valueAt = this.sharedService.dateFormat(dhl.createdAt, 'fromNow');

              changeDetect = true;
              break;
            }
          }
        }
      }

      if (changeDetect) {
        node.setDataValue('properties', virtualDevices);
      }
    });
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;
    this.updatePropertiesColumn();
    this.startAutoUpdate();

    // this.table.getRowNodeId = (data) => {
    //   return data.idx;
    // };
    this.table.getRowClass = (params) => {
      const classes = [];
      if (params.data) {
        if (!params.data.isActive || params.data.isDeleted) {
          classes.push('sc-agrow-inactive');
        }
        if (params.data.isDeleted) {
          classes.push('sc-agrow-deleted');
        }
      }
      return classes.join(' ');
    };

    // this.table.getRowStyle = (params) => {
    //   let style: any = {};
    //   if (params.data) {
    //     if (!params.data.isActive || params.data.isDeleted) {
    //       style.opacity = 0.5;
    //     }
    //     if (params.data.isDeleted) {
    //       style['text-decoration'] = 'line-through';
    //     }
    //   }
    //   return style;
    // };
  }

  private updatePropertiesColumn() {
    if (this.timeouts.propertiesColumnUpdate) {
      clearTimeout(this.timeouts.propertiesColumnUpdate);
    }
    this.timeouts.propertiesColumnUpdate = setTimeout(() => {
      if (this.table && this.table.api && this.ceosDeviceTypeEntities) {
        this.table.api.forEachNode((node) => {
          const rowData = node.data;
          const virtualDevices = rowData && rowData.virtualDevices;

          if (virtualDevices && virtualDevices.length) {
            for (const vd of virtualDevices) {
              const deviceType = this.ceosDeviceTypeEntities[vd.deviceTypeKey];

              if (deviceType) {
                vd.deviceTypeName = deviceType.label;
              }
            }
          }

          node.setDataValue('properties', virtualDevices);
        });
      }
    }, 500);
  }

  private startAutoUpdate() {
    this.stopAutoUpdate();
    this.fetchLatestHistory();
    this.intervals.fetchLatestDHL = setInterval(() => this.fetchLatestHistory(), 10000);
  }

  private stopAutoUpdate() {
    clearInterval(this.intervals.fetchLatestDHL);
    this.intervals.fetchLatestDHL = null;
    this.isFetchingLatestDeviceHistoryLogs = false;
  }

  private toggleTableOverlay(hide = false) {
    if (this.table && this.table.api) {
      if (hide) {
        this.table.api.hideOverlay();
      } else {
        this.table.api.showLoadingOverlay();
      }
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private createColumns() {
    return [
      {
        colId: 'description',
        headerName: 'NAME',
        field: 'description',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { suppressCount: true },
      },
      {
        colId: 'locationName',
        headerName: 'LOCATION',
        field: 'locationName',
      },
      {
        colId: 'properties',
        headerName: 'PROPERTIES',
        field: 'properties',
        cellRendererFramework: CeosDevicePropertiesCellComponent,
        suppressSorting: true,
        suppressFilter: true,
      },
      {
        colId: 'liveStatus',
        headerName: 'LIVE_STATUS',
        field: 'liveStatus',
        cellRendererFramework: CeosDeviceLiveDataCellComponent,
        suppressSorting: true,
        suppressFilter: true,
      },
      {
        colId: 'idx',
        headerName: 'IDX',
        field: 'idx',
        hide: true,
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        filterParams: { newRowsAction: 'keep' },
        suppressFilter: true,
        hide: true,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        filterParams: { newRowsAction: 'keep' },
        suppressFilter: true,
        hide: true,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        width: 50,
        cellRendererFramework: CeosDeviceActionCellComponent,
        suppressSorting: true,
        suppressFilter: true,
      },
    ];
  }
}
