import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

// Components
import { SceneTemplatesTableComponent } from './scene-templates-table/scene-templates-table.component';
import { SceneTemplateActionCellComponent } from './scene-template-action-cell/scene-template-action-cell.component';
import { SceneTemplateFormComponent } from './scene-template-form/scene-template-form.component';
import { CommandFormComponent } from './scene-template-form/command-form/command-form.component';

// Services
import { SceneTemplatesService } from './services/scene-templates.service';
import { SceneTemplateFormsService } from './services/scene-template-forms.service';

const components = [
  SceneTemplatesTableComponent,
  SceneTemplateActionCellComponent,
  SceneTemplateFormComponent,
  CommandFormComponent,
];

@NgModule({
  imports: [SharedModule],
  declarations: [...components],
  entryComponents: [...components],
  providers: [SceneTemplatesService, SceneTemplateFormsService],
})
export class SceneTemplatesModule {}
