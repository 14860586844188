import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationsConsomptionAlertComponent } from './locations-consumption-alert.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationsConsomptionAlertComponent],
  entryComponents: [LocationsConsomptionAlertComponent],
  providers: []
})
export class LocationsConsomptionAlertModule {}
