<div *ngIf="fetchingState === 0; else loadingIndicator">
  <p-card>
    <!--Header-->
    <p-header>
      <div class="ui-g">
        <div class="ui-g-6"></div>

        <div class="ui-g-6 text-right"></div>
      </div>
    </p-header>

    <!--Body-->
    <div class="ui-g">
      <div class="ui-g-12">
        <sc-rule-template-overview
          [automationId]="selectedDevice?.id"
          [customFunctions]="customFunctions"
          [customGlobals]="customGlobals"
          [deviceTypes]="deviceTypes"
          [locationProperties]="locationProperties"
          [notificationTemplates]="notificationTemplates"
          [ruleTemplate]="ruleTemplate"
          [rules]="rules"
          [ruleCategories]="ruleCategories"
          [reportTemplates]="reportTemplates"
          [userTaskTemplates]="userTaskTemplates"
        ></sc-rule-template-overview>
      </div>

      <!-- Error Message -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!--Footer-->
    <p-footer>
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !ruleTemplate?.is_deleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>

        <sc-submit-button
          *ngIf="!ruleTemplate?.is_deleted || ruleTemplate?.is_active"
          buttonClass="ui-button-success"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </p-footer>
  </p-card>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
