import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-locale-text-cell',
  templateUrl: 'locale-text-cell.component.html',
})
export class LocaleTextCellComponent implements ICellRendererAngularComp {
  localeText: string;

  private cell: any;

  constructor(private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;

    this.localeText = this.sharedService.tableMultiLanguagesFormatter(this.cell);
  }

  refresh(): boolean {
    return false;
  }
}
