import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-command-form',
  templateUrl: './command-form.component.html'
})
export class CommandFormComponent implements OnInit, OnDestroy {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  optionData: any;
  @Output()
  removed = new EventEmitter();

  commandActions: any;
  actions: SelectItem[];
  parameters: SelectItem[];
  locationModifyType = new FormControl();
  selectedParameters = new FormControl();
  isEnd: boolean;
  parametersSource: any[];

  private subscribers: any = {};

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.group.patchValue({ position: this.groupId });
    this.onFormValueChange();

    // load command actions on edit mode
    if (this.group.value.deviceTypeId) {
      this.fetchCommandAction(this.group.value.deviceTypeId);
    }

    // check adjust/force location property value
    if (this.group.value.locationPropertyAdjustValue) {
      this.locationModifyType.setValue('adjust');
    } else if (this.group.value.locationPropertyForceValue) {
      this.locationModifyType.setValue('force');
    }

    // check start/end command
    if (typeof this.group.value.executeOnlyOneTimeUntilRuleWasEnd !== 'undefined') {
      this.isEnd = false;
    } else if (typeof this.group.value.executeOnlyOneTimeUntilRuleWasStart !== 'undefined') {
      this.isEnd = true;
    }

    // if parameters exist mean edit mode
    if (this.group.value.parameters && this.group.value.parameters.length) {
      // parameters before edit
      this.parametersSource = this.group.value.parameters;
      // set selected value on multi select
      const params = this.group.value.parameters.map(p => p.key);
      this.selectedParameters.setValue(params);
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private onFormValueChange(): void {
    this.subscribers.WATCH_TARGET_TYPE_ID = this.group.get('targetTypeId').valueChanges.subscribe(val => {
      if (val) {
        this.selectedParameters.reset();
        this.group.patchValue({
          deviceTypeId: null,
          serviceTypeId: null,
          notificationTemplateId: null,
          action: null,
          parameters: null,
          delay: null,
          locationPropertyTypeId: null,
          locationPropertyForceValue: null,
          locationPropertyAdjustValue: null
        });
      }
    });

    this.subscribers.deviceTypeId = this.group.get('deviceTypeId').valueChanges.subscribe(val => {
      if (val) {
        this.selectedParameters.reset();
        this.fetchCommandAction(val);
        this.group.patchValue({ action: null, parameters: null });
        this.parameters = [];
      }
    });

    this.subscribers.action = this.group.get('action').valueChanges.subscribe(val => {
      if (val) {
        this.selectedParameters.reset();
        this.getActionParameters(val);
      }
    });

    this.subscribers.parameters = this.selectedParameters.valueChanges.subscribe(val => {
      if (val && val.length) {
        const parameters = [];
        // looping parameters
        for (let i = 0; i < val.length; i++) {
          let found = false;

          // check if exist
          if (this.parametersSource) {
            for (let j = 0; j < this.parametersSource.length; j++) {
              if (val[i] === this.parametersSource[j].key) {
                found = true;
                parameters.push(this.parametersSource[j]);
                break;
              }
            }
          }

          if (!found) {
            parameters.push({ key: val[i], value: null });
            this.group.markAsDirty();
          }
        }

        // update form
        this.group.controls['parameters'].setValue(parameters);
      } else {
        this.group.patchValue({ parameters: null });
      }
    });

    this.subscribers.locationModifyType = this.locationModifyType.valueChanges.subscribe(val => {
      if (val === 'adjust') {
        this.group.get('locationPropertyForceValue').reset();
      } else if (val === 'force') {
        this.group.get('locationPropertyAdjustValue').reset();
      }
    });
  }

  /**
   * Get Command Actions
   * @param actionId
   */
  private fetchCommandAction(deviceTypeId) {
    if (this.optionData && this.optionData.deviceTypesList) {
      const deviceType = this.optionData.deviceTypesList.find(
        d => d.id === deviceTypeId
      );

      if (deviceType) {
        this.commandActions = [...deviceType.commandActions];
        this.actions = [];

        for (let i = 0; i < this.commandActions.length; i++) {
          if (this.commandActions[i].isActive) {
            this.actions.push({
              label: this.commandActions[i].commandActionId,
              value: this.commandActions[i].commandActionId
            });
          }
        }

        if (this.group.value.action) {
          this.getActionParameters(this.group.value.action);
        }
      }
    }
  }

  /**
   * Get Command Action Parameters
   * @param actionId
   */
  private getActionParameters(actionId) {
    if (
      this.group.get('deviceTypeId').value &&
      this.group.get('targetTypeId').value === 'd'
    ) {
      this.parameters = [];
      const action = this.commandActions.find(
        (ca: any) => ca.commandActionId === actionId
      );
      if (action && action.parameters) {
        action.parameters.forEach(param => {
          this.parameters.push({
            label: param.parameterId,
            value: param.parameterId
          });
        });
      }
    }
  }

  get locationPropertyTypeId() {
    return this.group.get('locationPropertyTypeId').value;
  }

  get locationPropertyMapping() {
    if (
      this.optionData &&
      this.optionData.locationPropertiesList &&
      this.locationPropertyTypeId
    ) {
      const locationProperty = this.optionData.locationPropertiesList.find(
        d => d.key === this.locationPropertyTypeId
      );
      return locationProperty;
    }
    return;
  }

  get locationPropertyMappingValues() {
    if (this.locationPropertyMapping) {
      switch (this.locationPropertyMapping.type) {
        case 'boolean':
          return this.optionData.booleanOption;

        case 'deviceType':
          if (this.optionData && this.optionData.deviceTypesList) {
            const deviceType = this.optionData.deviceTypesList.find(
              d => d.key === this.locationPropertyMapping.deviceType
            );
            if (
              deviceType &&
              deviceType.mappingValues &&
              deviceType.mappingValues.length
            ) {
              return this.sharedService.createSelectItems(
                deviceType.mappingValues,
                false,
                'key'
              );
            }
          }
          break;

        default:
          break;
      }
    }
    return;
  }
}
