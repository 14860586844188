import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'editable',
  templateUrl: 'editable.component.html',
  styleUrls: ['editable.component.scss'],
})
export class EditableComponent implements OnInit {
  value = '';

  @Input()
  mode: 'add' | 'edit' | 'view' = 'view';

  @Input()
  disabled: boolean;

  @Input()
  form: FormGroup;

  @Input()
  field: string;

  @Input()
  type: 'text' | 'number' = 'text';

  @Input()
  canSave: boolean;

  @Output()
  cancelled = new EventEmitter<any>();
  @Output()
  deleted = new EventEmitter<any>();
  @Output()
  edit = new EventEmitter<any>();
  @Output()
  saved = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (typeof this.form !== 'undefined' && typeof this.field !== 'undefined') {
      this.value = this.form.get(this.field).value;
    }

    // by default it's true
    this.canSave = typeof this.canSave === 'undefined';
  }

  updateFormControl() {
    if (this.mode === 'add' || this.mode === 'edit') {
      this.form.setErrors({ viewMode: true });
    } else {
      this.form.setErrors(null);
    }
  }

  changeMode(mode: 'add' | 'edit' | 'view') {
    if (this.disabled) {
      return;
    }

    this.mode = mode;
    // avoid ERROR: ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
    this.updateFormControl();
    if (mode === 'edit') {
      this.edit.emit(this.form.value);
    }
  }

  cancel() {
    this.form.get(this.field).setValue(this.value);
    this.changeMode('view');
    this.cancelled.emit(this.form.value);
  }

  save() {
    if (this.canSave !== true) {
      return;
    }
    this.value = this.form.get(this.field).value;
    this.changeMode('view');
    this.saved.emit(this.form.value);
  }

  delete() {
    this.deleted.emit(this.form.value);
  }
}
