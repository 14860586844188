<div [formGroup]="group">
  <div formGroupName="nonRecurringOpts">
    <div class="ui-g-12">
      <label for="triggerAt" class="sc-form-label">
        {{ 'DATE_TIME' | translate }}
      </label>
      <div class="text-center">
        <p-calendar
          inputId="triggerAt"
          formControlName="triggerAt"
          dateFormat="dd/mm/yy"
          hourFormat="24"
          showButtonBar="true"
          showTime="true"
          [style]="{'width':'100%'}"
          [inline]="true"
        ></p-calendar>
        <!-- utc="true" -->
      </div>
    </div>
  </div>
</div>
