import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from '../models/user';
import { SelectItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { debounceTime } from 'rxjs/operators';
import * as fromStore from '@app/store';
import { UsersService } from '../services/users.service';
import { UserRolesService } from '../services/user-roles.service';
import { AuthService } from '@app/auth/services/auth.service';
import { SharedService } from '@shared/shared.service';
import { CompaniesService } from '@widgets/companies/services/companies.service';
import { UserFormsService } from '../services/user-forms.service';

@Component({
  selector: 'sc-user-form',
  styleUrls: ['./user-form.component.scss'],
  templateUrl: './user-form.component.html',
})
export class UserFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: User;
  isAdmin: boolean;
  isSubmitting = false;
  isVerifying = { email: false, username: false };
  selectItems: { [key: string]: SelectItem[] } = {};
  verifyData = { email: null, username: null };

  private subscribers: { [key: string]: any } = {};

  constructor(
    private auth: AuthService,
    private companiesService: CompaniesService,
    private usersService: UsersService,
    private userRolesService: UserRolesService,
    private userFormsService: UserFormsService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.selectItems = this.sharedService.selectItems;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchUser(this.data.id);
    }

    this.isAdmin = this.auth.isAuthorized('admin');

    if (this.isAdmin) {
      this.fetchCompanies();
    } else {
      this.fetchCompanies(this.sharedService.userCompanyId);
    }

    this.fetchRoles();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchUser(id: number) {
    this.fetchingState++;
    this.subscribers.getUser = this.usersService.getUser(id).subscribe({
      next: (result: any) => {
        this.formData = result.data;
        this.formData.roles = this.sharedService.jsonParse(this.formData.roles);
        this.formData.permissions = this.sharedService.jsonParse(this.formData.permissions);
        this.form.patchValue(this.formData);
        this.fetchingState--;
      },
    });
  }

  private fetchRoles() {
    this.fetchingState++;
    this.subscribers.getUserRoles = this.userRolesService.get().subscribe({
      next: (result: any) => {
        this.selectItems.userRoles = [];

        if (result && result.data) {
          const roles = [];

          for (let i = 0; i < result.data.length; i++) {
            const role = result.data[i];
            if (role.isDeleted === 1) {
              continue;
            } else if (role.assignableByAdminOnly && !this.isAdmin) {
              continue;
            }
            roles.push(role);
          }

          this.selectItems.userRoles = this.sharedService.createSelectItems(roles, false);
        }

        this.fetchingState--;
      },
    });
  }

  private fetchCompanies(companyId?: number) {
    this.fetchingState++;
    this.subscribers.fetchCompanies = this.companiesService.getCompanies(companyId).subscribe((result: any) => {
      const companies = companyId ? [result.data] : result.data;
      this.selectItems.companies = this.sharedService.createSelectItems(companies);
      this.fetchingState--;
    });
  }

  private verifyUser(key: string, value: string) {
    this.isVerifying[key] = true;
    this.verifyData[key] = null;
    this.subscribers['verify' + key] = this.usersService.verifyEmailAndUsername(value).subscribe(
      (result: any) => {
        if (!result.valid) {
          switch (result.message) {
            case 'User exists':
              this.verifyData[key] = 'ALREADY_EXISTS';
              break;
            case 'Invalid value':
              this.verifyData[key] = 'INVALID_VALUE';
              break;
            case 'Verify error':
              this.verifyData[key] = 'ERROR';
              break;
          }
        }
        this.isVerifying[key] = false;
      },
      (error) => {
        this.verifyData[key] = 'ERROR';
        this.isVerifying[key] = false;
      }
    );
  }

  get isBlocked() {
    return this.form.get('isBlocked').value;
  }

  get isBlockedStyleClass() {
    return this.isBlocked ? 'is-block-active' : '';
  }

  private initForm() {
    this.form = this.userFormsService.initUserForm();

    if (this.editMode === true) {
      this.form.get('username').disable();
      this.form.removeControl('password');
      this.form.get('companyId').clearValidators();
      this.form.updateValueAndValidity();
    } else {
      if (this.isAdmin) {
        this.form.patchValue({ companyId: this.sharedService.selectedSite.companyId });
      } else {
        this.form.patchValue({ companyId: this.sharedService.userCompanyId });
      }
    }

    this.subscribers.email = this.form
      .get('email')
      .valueChanges.pipe(debounceTime(500))
      .subscribe((result) => {
        if (!this.editMode || (this.formData && this.formData.email !== result)) {
          this.verifyUser('email', result);
        } else if (this.editMode && this.formData && this.formData.email == result) {
          this.verifyData.email = null;
        }
      });

    if (!this.editMode) {
      this.subscribers.username = this.form
        .get('username')
        .valueChanges.pipe(debounceTime(500))
        .subscribe((result) => {
          this.verifyUser('username', result);
        });
    }
  }

  get allowSubmitForm() {
    if (
      this.form.invalid ||
      this.form.pristine ||
      this.verifyData.email !== null ||
      this.verifyData.username !== null ||
      this.isVerifying.email === true ||
      this.isVerifying.username === true
    ) {
      return false;
    }
    return true;
  }

  // API CALL
  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    } else if (this.verifyData.email !== null || this.verifyData.username !== null) {
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    // call api
    if (!this.editMode) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    const formData = this.form.getRawValue();
    delete formData.id;
    this.subscribers.createUser = this.usersService.createUser(formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update() {
    this.subscribers.updateUser = this.usersService.updateUser(this.formData.id, this.form.value).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.deleteUser = this.usersService.deleteUser(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('USER');
    this.sharedService.notify(title, text, 'success');

    // update user variant
    this.store.dispatch(new fromStore.UpdateUserVariant(this.form.value.backendVariant));

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
