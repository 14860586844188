import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as fromLocation from '../actions/locations.action';
import { LocationsService } from '@widgets/locations/services/locations.service';

@Injectable()
export class LocationsEffects {
  constructor(private action$: Actions, private locationsService: LocationsService) {}

  @Effect()
  loadLocation$ = this.action$.pipe(
    ofType(fromLocation.LOAD_LOCATION),
    map((action: fromLocation.LoadLocation) => action.payload),
    switchMap((id) => {
      return this.locationsService.getLocation(id).pipe(
        map((result: any) => new fromLocation.LoadLocationSuccess(result.data)),
        catchError((error) => of(new fromLocation.LoadLocationFail(error)))
      );
    })
  );

  @Effect()
  loadLocations$ = this.action$.pipe(
    ofType(fromLocation.LOAD_LOCATIONS),
    map((action: fromLocation.LoadLocations) => action.payload),
    switchMap((requestOptions) => {
      return this.locationsService.getLocations(requestOptions).pipe(
        map((result: any) => new fromLocation.LoadLocationsSuccess(result.data)),
        catchError((error) => of(new fromLocation.LoadLocationsFail(error)))
      );
    })
  );
}
