import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationAlertModalComponent } from './locations-alert-modal.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationAlertModalComponent],
  entryComponents: [LocationAlertModalComponent],
  providers: []
})
export class LocationAlertModalModule {}
