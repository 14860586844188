<div class="device-plan">
  <div class="device-plan-header">
    <h3>
      {{ 'DEVICE_WIZARD_PLEASE_CHOOSE_DEVICE_POSITION' | translate }}
    </h3>

    <div class="marker-description">
      <div class="device-marker-container">
        <div class="device-marker new-device">
          <div class="marker">
            <div class="dot"></div>
            <div class="pulse"></div>
          </div>
        </div>

        <div class="device-marker-label">
          {{ 'DEVICE_WIZARD_THIS_DEVICE' | translate }}
        </div>
      </div>

      <div class="device-marker-container">
        <div class="device-marker">
          <div class="marker">
            <div class="dot"></div>
            <div class="pulse"></div>
          </div>
        </div>

        <div class="device-marker-label">
          {{ 'DEVICE_WIZARD_OTHER_DEVICE' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="device-plan-boundary">
    <div
      class="device-marker"
      *ngFor="let device of devices"
      [ngStyle]="{ top: device?.instructionPositionY + 'px', left: device?.instructionPositionX + 'px' }"
    >
      <div class="marker">
        <div class="dot"></div>
        <div class="pulse"></div>
      </div>

      <div class="values-tooltips">
        <span class="virtual-device-holder">
          {{ device?.description }}
        </span>
        <ul class="virtual-devices-status" *ngIf="device?.virtualDevices?.length">
          <li *ngFor="let vd of device.virtualDevices">
            {{ vd.valueLabel }}: {{ vd.value }} {{ vd.unit }}
          </li>
        </ul>
      </div>
    </div>

    <div
      class="device-marker new-device"
      cdkDrag
      cdkDragBoundary=".device-plan-boundary"
      (cdkDragEnded)="onDragEnded($event)"
      [cdkDragFreeDragPosition]="startPosition"
    >
      <div class="marker animate moveable">
        <div class="dot"></div>
        <div class="pulse"></div>
      </div>

      <div class="values-tooltips">
        <span class="virtual-device-holder">
          {{ selectedDevice?.description }}
        </span>
        <ul class="virtual-devices-status" *ngIf="selectedDevice?.virtualDevices?.length">
          <li *ngFor="let vd of selectedDevice.virtualDevices">
            {{ vd.valueLabel }}: {{ vd.value }} {{ vd.unit }}
          </li>
        </ul>
      </div>
    </div>

    <img class="floorplan-image" [src]="floorplanImage" alt="Room">
  </div>
</div>
