<div class="vd-logs">
  <!-- <div class="vd-logs-header">
    <div class="sc-flex align-center">
      <h4 class="header-title">
        {{ 'HISTORY' | translate }}
      </h4>
    </div>
  </div> -->

  <div class="vd-logs-body">
    <sc-table
      [columns]="columns"
      [dataset]="virtualDevices"
      [title]="tableTitle"
      [toolbarItems]="toolbarItems"
      [hideIcon]="true"
      (afterInit)="afterInitTable($event)"
    ></sc-table>
  </div>
</div>
