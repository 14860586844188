import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { Device } from '@widgets/devices/models/device';
import { DeviceModel } from '@widgets/device-models/models/device-model';
import { DeviceType } from '@widgets/device-types/models/device-type';

import { DeviceHistoryLogsService } from '@widgets/devices/services/device-history-logs.service';
import { SharedService } from '@shared/shared.service';

import { config } from '@app/config';
import * as moment from 'moment-timezone';

interface DeviceRowData extends Device {
  [key: string]: any;
}

interface FormData {
  devices: number[];
  chartType: 'line' | 'bar';
  from: string;
  to: string;
  interval: 'day' | 'week' | 'month' | 'hour' | 'minute' | '10s' | '10m';
}

@Component({
  selector: 'sc-device-logs-comparison',
  templateUrl: './device-logs-comparison.component.html',
  styleUrls: ['./device-logs-comparison.component.scss'],
})
export class DeviceLogsComparisonComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  charts: { [key: string]: any };
  dataset: any[];
  devices: DeviceRowData[];
  deviceModels: DeviceModel[];
  deviceTypes: DeviceType[];
  devicePropsForm: FormGroup;
  formData: FormData;
  form: FormGroup;
  fromDate: Date;
  toDate: Date;
  isFetchingDeviceHistory = false;
  selectItems: { [key: string]: SelectItem[] } = {};
  selectedDevices: Device[] = [];

  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.initForm();

    // data passthrough (selected devices) from the table
    if (this.data) {
      if (this.data.devices && this.data.devices.length) {
        this.devices = this.data.devices;
        this.createSelectItemDevices();
      }
      if (this.data.deviceModels && this.data.deviceModels.length) {
        this.deviceModels = this.data.deviceModels;
      }
      if (this.data.deviceTypes && this.data.deviceTypes.length) {
        this.deviceTypes = this.data.deviceTypes;
      }
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.timeouts);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchDeviceHistory(force?: boolean) {
    if (!this.form.valid) {
      return;
    }
    if (force !== true && this.isFetchingDeviceHistory === true) {
      return;
    }

    this.isFetchingDeviceHistory = true;
    const formData = this.form.value;

    this.subscribers.fetchDHL = this.deviceHistoryLogsService
      .getHistoryLogsSummary(formData)
      .subscribe((result: any) => {
        this.dataset = result.data;
        this.createChart();
        this.isFetchingDeviceHistory = false;
      });
  }

  onDateChanged(event: any) {
    if (event.from) {
      this.form.get('from').setValue(event.from);
    }
    if (event.to) {
      this.form.get('to').setValue(event.to);
    }
    if (event.interval) {
      this.form.get('interval').setValue(event.interval);
    }
  }

  onAddDevice() {
    const formValue = this.devicePropsForm.value;
    // const automation = formValue.automation && this.devices.find((d) => d.id === formValue.automation);
    const device = formValue.device && this.devices.find((d) => d.id === formValue.device);

    if (/*automation && */ device) {
      const vdh = this.devices.find((d) => d.id === device.virtualDeviceHolderId);
      let label = device.description;

      if (vdh) {
        label = `${vdh.description} -> ${label}`;
      }

      device.label = label;
      // device.automationName = automation.description;

      this.selectedDevices.push(device);

      this.devicePropsForm.get('device').reset();
    }
  }

  onRemoveDevice(device: Device, index: number) {
    if (typeof index !== 'undefined') {
      this.selectedDevices = this.selectedDevices.filter((d, i) => i !== index);
    }
    // if (device && device.id) {
    //   this.selectedDevices = this.selectedDevices.filter((d, i) => d.id !== device.id);
    // }
    // console.log(this.selectedDevices);
  }

  onSubmit() {
    if (this.selectedDevices && this.selectedDevices.length) {
      this.form.patchValue({ devices: this.selectedDevices.map((d) => d.id) });
      console.log(this.form.value);
      this.fetchDeviceHistory(true);
    }
  }

  private createSelectItemDevices() {
    const automations = [];
    const devices = {};

    for (const dev of this.devices) {
      // get Automations and VDHs
      if (dev.deviceTypeKey === 'a') {
        automations.push({ label: dev.description, value: dev.id });
      } else if (dev.deviceTypeKey === 'vdh') {
        devices[dev.id] = {
          label: dev.description,
          items: [],
          automationId: dev.automationId,
        };
      }
    }

    for (const dev of this.devices) {
      // get VDs
      if (
        dev.deviceTypeKey !== 'vdh' &&
        dev.deviceTypeKey !== 'a' &&
        dev.virtualDeviceHolderId &&
        devices[dev.virtualDeviceHolderId] &&
        devices[dev.virtualDeviceHolderId].items
      ) {
        devices[dev.virtualDeviceHolderId].items.push({ label: dev.description, value: dev.id });
      }
    }

    this.selectItems.automations = automations;
    this.selectItems.devices = Object.keys(devices).map((id) => devices[id]);

    this.devicePropsForm.get('automation').setValue(automations[0].value);
  }

  private initForm() {
    this.selectItems.chartTypes = [
      { label: 'Line', value: 'line' },
      { label: 'Bar', value: 'bar' },
    ];

    // initial form data
    this.fromDate = moment().subtract(24, 'hour').utc().toDate();
    this.toDate = moment().utc().toDate();
    const fromDate = moment().subtract(24, 'hour').utc().format('DD.MM.YYYYTHH:mm:ss');
    const toDate = moment().utc().format('DD.MM.YYYYTHH:mm:ss');

    // init filter form
    this.form = this.formBuilder.group({
      devices: [null, Validators.required],
      isCombine: false,
      chartType: 'line',
      from: [fromDate, Validators.required],
      to: [toDate, Validators.required],
      interval: 'hour',
    });

    // init device properties form
    this.devicePropsForm = this.formBuilder.group({
      automation: [null, Validators.required],
      device: [null, Validators.required],
    });

    // auto fetch data if selected from table
    if (this.data.selected && this.data.selected.length) {
      this.form.patchValue({ devices: this.data.selected });
      this.fetchDeviceHistory();
    }

    // re create charts when combine or type changed
    this.subscribers.isCombine = this.form.get('isCombine').valueChanges.subscribe((result) => {
      this.createChart();
    });

    this.subscribers.chartType = this.form.get('chartType').valueChanges.subscribe((result) => {
      this.createChart();
    });

    // get devices of selected automation
    this.subscribers.selectedAutomation = this.devicePropsForm.get('automation').valueChanges.subscribe((result) => {
      this.devicePropsForm.get('device').reset();
      this.selectItems.filteredDevices = this.selectItems.devices.filter((d: any) => d.automationId === result);
    });
  }

  private getDevice(deviceId: number) {
    if (this.devices && this.devices.length) {
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].id === deviceId) {
          return { ...this.devices[i] };
        }
      }
    }
    return;
  }

  private getDeviceType(deviceId: number) {
    const device = this.getDevice(deviceId);
    if (device && this.deviceTypes && this.deviceTypes.length) {
      for (let i = 0; i < this.deviceTypes.length; i++) {
        if (this.deviceTypes[i].id === device.deviceTypeId) {
          return { ...this.deviceTypes[i] };
        }
      }
    }
    return;
  }

  private getDeviceModel(deviceId: number) {
    const device = this.getDevice(deviceId);
    if (device && this.deviceModels && this.deviceModels.length) {
      for (let i = 0; i < this.deviceModels.length; i++) {
        if (this.deviceModels[i].id === device.deviceModelId) {
          return { ...this.deviceModels[i] };
        }
      }
    }
    return;
  }

  private getPrevValue(dataset: any[], currentIndex: number) {
    const prevIndex = currentIndex - 1;
    let prevItem: any;
    if (prevIndex >= 0) {
      prevItem = dataset[prevIndex];
    }
    if (prevItem && prevItem.stats && prevItem.stats.value) {
      return prevItem.stats.value;
    }
    return null;
  }

  private getYTick(deviceTypeKey): { tickvals: number[]; ticktext: string[] } {
    switch (deviceTypeKey) {
      case 'acf':
        return {
          tickvals: [0, 1, 2, 3],
          ticktext: ['Auto', 'Low', 'Medium', 'High'],
        };

      case 'acm':
      case 'lc':
        return {
          tickvals: [0, 1],
          ticktext: ['OFF', 'ON'],
        };

      case 'ms':
        return {
          tickvals: [0, 1],
          ticktext: ['NO', 'YES'],
        };

      case 'os':
      case 'osd':
      case 'osw':
        return {
          tickvals: [0, 1],
          ticktext: ['Closed', 'Open'],
        };

      default:
        return;
    }
  }

  private getYTitle(deviceTypeKey: string) {
    let title;
    switch (deviceTypeKey) {
      case 'acf':
        title = 'AC_FANSPEED';
        break;

      case 'acm':
        title = 'AC_MODE';
        break;

      case 'acsp':
        title = 'AC_SETPOINT';
        break;

      case 'bl':
        title = 'BATTERY_LEVEL';
        break;

      case 'bs':
        title = 'BRIGHTNESS';
        break;

      case 'hs':
        title = 'HUMIDITY';
        break;

      case 'lc':
        title = 'LIGHTING_CIRCUIT';
        break;

      case 'ms':
        title = 'MOTION';
        break;

      case 'os':
        title = 'OPENING_SENSOR';
        break;

      case 'osd':
        title = 'DOOR';
        break;

      case 'osw':
        title = 'WINDOW';
        break;

      case 'ts':
        title = 'TEMPERATURE';
        break;

      case 'ws':
        title = 'WATTAGE';
        break;

      default:
        title = 'VALUE';
        break;
    }
    return title ? this.sharedService.getTranslation(title) : '';
  }

  private valueUnit(deviceTypeKey: string) {
    switch (deviceTypeKey) {
      case 'bl':
      case 'hs':
        return '%';

      case 'bs':
        return 'lux';

      case 'ts':
        return '°C';

      case 'ws':
        return 'w/h';

      default:
        return '';
    }
  }

  private isNumberData(deviceTypeKey: string) {
    const types = config().deviceTypeKeyHasNumberValue;
    return deviceTypeKey && types.indexOf(deviceTypeKey) >= 0 ? true : false;
  }

  private createChart() {
    if (!this.dataset) {
      return;
    }

    const formData = this.form.getRawValue();
    const rawData = [...this.dataset];
    const charts = [];
    const traces = [];
    const axes = {};
    const colors = config().colors;

    for (let i = 0; i < rawData.length; i++) {
      // if combine then create one chart [{ data: [trace1, trace2, ..., traceN], layout: {} }]
      // else create many charts [{ data: [trace1], layout: {} }, { data: [trace2], layout: {} }, ..., { data: [traceN], layout: {} }]

      // item = { deviceId: "1234", value: [{key: 1234567890, stats: { value: 0 } }, ...] }
      const item = rawData[i];
      const deviceId = +item.deviceId;
      const values: any[] = item.value;
      const trace: any = {
        x: [], // date
        y: [], // value
      };
      let layout: any = {
        xaxis: {
          rangeslider: {
            borderwidth: 1,
            range: [],
          },
          showgrid: false,
          showline: false,
          title: 'Date/Time',
          type: 'date',
        },
        // margin: {
        //   pad: 5
        // }
      };

      // layout and style per data type
      const device = this.getDevice(deviceId);
      const deviceType = this.getDeviceType(deviceId);
      let isNumberData;
      let unit;
      let yTitle;
      const axisPos = i + 1;
      const axisName = i > 0 ? 'yaxis' + axisPos : 'yaxis';
      if (device) {
        trace.name = device.description;
      }
      if (deviceType) {
        isNumberData = this.isNumberData(deviceType.key);
        unit = this.valueUnit(deviceType.key);
        yTitle = this.getYTitle(deviceType.key);

        // create y axis
        axes[axisName] = {
          title: unit ? yTitle + ' (' + unit + ')' : yTitle,
          type: 'linear',
          showgrid: false,
          showline: false,
          rangemode: 'nonnegative',
          color: colors[i],
          titlefont: { color: colors[i] },
          tickfont: { color: colors[i] },
        };

        if (formData.isCombine) {
          const selectedDevices = formData.devices;
          layout.title = 'Combine Chart of ' + selectedDevices.length + ' devices';
          // hide y axis
          // axes[axisName].visible = false;
          // set y axis of data
          if (i > 0) {
            axes[axisName].anchor = 'free';
            axes[axisName].position = 0.1 * (i - 1);
            axes[axisName].overlaying = 'y';
            axes[axisName].side = 'left';
            trace.yaxis = 'y' + axisPos;
          }
        } else {
          layout.title = device.description;
        }

        if (isNumberData) {
          axes[axisName].tickformat = '.1f';
          axes[axisName].ticksuffix = unit;
        } else {
          const ytick = this.getYTick(deviceType.key);
          if (ytick) {
            axes[axisName].tickmode = 'array';
            axes[axisName].tickvals = ytick.tickvals;
            axes[axisName].ticktext = ytick.ticktext;
          }
        }

        // if (this.chartType === 'line') {
        if (formData.chartType === 'line') {
          trace.type = 'scatter';
          trace.mode = 'lines';
          trace.line = {
            color: colors[i],
            dash: 'solid',
            shape: 'linear',
          };
          trace.connectgaps = true;
          if (!isNumberData) {
            // plot line in step
            trace.line.shape = 'hv';
          }
          // } else if (this.chartType === 'bar') {
        } else if (formData.chartType === 'bar') {
          trace.type = 'bar';
          trace.marker = { color: colors[i] };
          layout.barmode = 'group';
          layout.bargap = 0.15;
          layout.bargroupgap = 0.1;
        }
      }

      for (let ii = 0; ii < values.length; ii++) {
        const v = values[ii];
        const date = this.sharedService.dateFormat(v.key, 'YYYY-MM-DD HH:mm:ss');
        let value = v.stats && v.stats.value;

        // no data exists, use a previous data if possible
        if (isNumberData && (value === null || value === 0)) {
          const prevValue = this.getPrevValue(values, ii);
          if (prevValue) {
            values[ii].stats.value = prevValue;
            value = prevValue;
          }
        }
        if (value !== null && value !== NaN) {
          value = this.sharedService.numberFormat(value, 1);
          trace.x.push(date);
          trace.y.push(value);
        }
      }

      if (formData.isCombine) {
        layout = { ...layout, ...axes };
        // reserve the spaces for multiple Y axes
        layout.xaxis.domain = [0.1 * i, 1];
        traces.push(trace);
        if (i === rawData.length - 1) {
          charts.push({ data: traces, layout });
        }
      } else {
        layout.yaxis = axes[axisName];
        charts.push({ data: [trace], layout });
      }
    }

    this.charts = charts;
  }
}
