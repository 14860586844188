import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CustomFormComponent } from './rule-scheduler-job-form/custom-form/custom-form.component';
import { LocationFormComponent } from './rule-scheduler-job-form/location-form/location-form.component';
import { NonRecurringFormComponent } from './rule-scheduler-job-form/non-recurring-form/non-recurring-form.component';
import { RecurringFormComponent } from './rule-scheduler-job-form/recurring-form/recurring-form.component';
import { RuleSchedulerJobActionCellComponent } from './rule-scheduler-job-action-cell/rule-scheduler-job-action-cell.component';
import { RuleSchedulerJobFormComponent } from './rule-scheduler-job-form/rule-scheduler-job-form.component';
import { RuleSchedulerJobsTableComponent } from './rule-scheduler-jobs-table/rule-scheduler-jobs-table.component';

import { RuleSchedulerJobsService } from './services/rule-scheduler-jobs.service';
import { RuleSchedulerJobFormService } from './services/rule-scheduler-job-form.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    CustomFormComponent,
    LocationFormComponent,
    NonRecurringFormComponent,
    RecurringFormComponent,
    RuleSchedulerJobActionCellComponent,
    RuleSchedulerJobFormComponent,
    RuleSchedulerJobsTableComponent,
  ],
  entryComponents: [
    RuleSchedulerJobActionCellComponent,
    RuleSchedulerJobFormComponent,
    RuleSchedulerJobsTableComponent,
  ],
  providers: [RuleSchedulerJobsService, RuleSchedulerJobFormService],
})
export class RuleSchedulerJobsModule {}
