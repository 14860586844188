import * as fromLocations from '../actions/locations.action';
import { Location } from '@widgets/locations/models/location';

export interface LocationState {
  entities: { [id: string]: Location };
  error: any;
  loaded: boolean;
  loadedAt: number;
  loading: boolean;
  selectedLocationId: number;
}

export const initialState = {
  entities: null,
  error: null,
  loaded: false,
  loadedAt: null,
  loading: false,
  selectedLocationId: null,
};

export function reducer(state = initialState, action: fromLocations.LocationsAction): LocationState {
  switch (action.type) {
    case fromLocations.RESET_LOCATIONS: {
      return { ...initialState };
    }

    case fromLocations.LOAD_LOCATION: {
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    }
    case fromLocations.LOAD_LOCATION_FAIL: {
      const error = action.payload;
      return {
        ...state,
        error,
        loading: false,
        loaded: false,
      };
    }
    case fromLocations.LOAD_LOCATION_SUCCESS: {
      const location = action.payload;
      const entities = { ...state.entities, [location.id]: location };
      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromLocations.LOAD_LOCATIONS: {
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    }
    case fromLocations.LOAD_LOCATIONS_FAIL: {
      const error = action.payload;
      return {
        ...state,
        error,
        loading: false,
        loaded: false,
      };
    }
    case fromLocations.LOAD_LOCATIONS_SUCCESS: {
      const locations = action.payload;
      const entities = locations.reduce((tmpEntities: { [id: string]: Location }, location: Location) => {
        return {
          ...tmpEntities,
          [location.id]: location,
        };
      }, {});
      const loadedAt = new Date().getTime();
      return {
        ...state,
        loading: false,
        loaded: true,
        loadedAt,
        entities,
      };
    }

    case fromLocations.SELECT_LOCATION: {
      const selectedLocationId = action.payload;
      return { ...state, selectedLocationId };
    }
  }
  return state;
}

export const getLocationEntities = (state: LocationState) => state.entities;
export const getLocationsError = (state: LocationState) => state.error;
export const getLocationsLoaded = (state: LocationState) => state.loaded;
export const getLocationsLoadedAt = (state: LocationState) => state.loadedAt;
export const getLocationsLoading = (state: LocationState) => state.loading;
export const getSelectedLocationId = (state: LocationState) => state.selectedLocationId;
