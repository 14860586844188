import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import { Page } from '@app/core2/models/page';
import { FormBuilder, FormGroup } from '@angular/forms';

interface Device {
  idx: string;
  name: string;
  locationName: string;
  locationIdx: string;
  fullName: string;
  min: number;
  max: number;
}

@Component({
  selector: 'sc-battery-status',
  templateUrl: './battery-status.component.html',
})
export class BatteryStatusComponent implements OnInit, OnDestroy {
  // The widget with its configuration
  @Input() config: any;

  DISPLAY_VALUE = 'value';
  DISPLAY_PERCENT = 'percent';

  page: Page;
  selectedSite: Site;

  subscribers: { [key: string]: any } = {};
  intervals: { [key: string]: any } = {};

  chartData: any;
  chartLayout: any;

  isInitialized: boolean = false;
  isFetchingStatistics: boolean = false;
  isFetchingDevices: boolean = false;

  widgetName: string = '';
  defaultWidgetName: string = '';
  customWidgetName: string = '';

  // Details modal
  isDetailsModalOpen: boolean = false;

  // Config modal
  isConfigModalOpen: boolean = false;
  configForm: FormGroup;

  // Statistics
  batteryStats: any[] = [];
  legendItems: any[] = [];

  constructor(
    private widgetService: SCWidgetService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private formBuilder: FormBuilder
  ) {
    this.chartLayout = {
      height: 190,
      width: 190,
      showlegend: false,
      margin: { t: 15, b: 15, l: 15, r: 15 },
      font: {
        family: 'barlow',
        size: 13,
      },
    };

    this.configForm = this.formBuilder.group({
      name: [''],
      display: [this.DISPLAY_VALUE],
      warningThreshold: 25,
      criticalThreshold: 10,
    });
  }

  ngOnInit() {
    this.initWidgetName();
    this.fetchInitialData();
    this.intervals.fetchDataset = setInterval(() => this.fetchStatistics(), 10000);
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  initWidgetName() {
    this.defaultWidgetName = this.sharedService.getLocaleWidgetName(this.config);
    if (this.config?.customPreferences?.name) {
      this.customWidgetName = this.config.customPreferences.name;
    }
    this.widgetName = this.customWidgetName ? this.customWidgetName : this.defaultWidgetName;
  }

  fetchInitialData() {
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (site) => {
        this.selectedSite = site;
        this.subscribers.watchPage = this.store.pipe(select(fromStore.getCurrentPage)).subscribe({
          next: (page) => {
            this.page = page;
            this.fetchStatistics();
            this.isInitialized = true;
          },
        });
      },
    });
  }

  fetchStatistics() {
    if (this.isFetchingStatistics) {
      return;
    }

    this.isFetchingStatistics = true;

    this.subscribers.fetchDataset = this.widgetService.getBatteryStatus(this.selectedSite.id).subscribe(
      (result: any) => {
        this.isFetchingStatistics = false;

        this.batteryStats = [];
        let batteryOk = [];
        let batteryWarning = [];
        let batteryAlert = [];
        result.data.map((device) => {
          const warningThreshold =
            this.config.customPreferences?.warningThreshold || this.configForm.get('warningThreshold').value;
          const criticalThreshold =
            this.config.customPreferences?.criticalThreshold || this.configForm.get('criticalThreshold').value;
          const batteryLevel = device.value;
          if (batteryLevel < criticalThreshold) {
            batteryAlert.push(device);
            this.batteryStats.push(
              Object.assign({}, device, {
                batteryLevel,
                status: 'Alert',
              })
            );
          } else if (batteryLevel <= warningThreshold) {
            batteryWarning.push(device);
            this.batteryStats.push(
              Object.assign({}, device, {
                batteryLevel,
                status: 'Warning',
              })
            );
          } else if (batteryLevel > warningThreshold) {
            batteryOk.push(device);
            this.batteryStats.push(
              Object.assign({}, device, {
                batteryLevel,
                status: 'Ok',
              })
            );
          }
        });

        const thresholds = [
          {
            index: 0,
            name: 'Ok',
            color: 'green',
            total: batteryOk.length,
          },
          {
            index: 1,
            name: 'Warning',
            color: 'orange',
            total: batteryWarning.length,
          },
          {
            index: 2,
            name: 'Alert',
            color: 'red',
            total: batteryAlert.length,
          },
        ];

        this.renderChart(thresholds);
      },
      (error: any) => {
        this.isFetchingStatistics = false;
      }
    );
  }

  openDetailsModal() {
    this.isDetailsModalOpen = true;
  }

  openConfigModal() {
    this.isConfigModalOpen = true;
    this.patchConfigForm(this.config);
  }

  resetWidgetName() {
    this.configForm.patchValue({
      name: '',
    });
  }

  patchConfigForm(config) {
    if (!config.customPreferences) {
      return;
    }

    this.configForm.patchValue({
      name: config.customPreferences.name,
      display: config.customPreferences.display,
      warningThreshold: config.customPreferences.warningThreshold,
      criticalThreshold: config.customPreferences.criticalThreshold,
    });
  }

  saveConfig() {
    let cfg = this.configForm.value;

    let customPreferences = {
      name: cfg.name,
      display: cfg.display,
      warningThreshold: cfg.warningThreshold,
      criticalThreshold: cfg.criticalThreshold,
    };

    this.isConfigModalOpen = false;
    let { page, sectionIndex } = this.widgetService.setWidgetCustomPreferences(
      this.page,
      this.config.uniqueId,
      customPreferences
    );
    this.store.dispatch(new fromStore.UpdatePage(page));
  }

  renderChart(thresholds) {
    let values = [];
    let labels = [];
    let colors = [];
    this.legendItems = [];

    for (const threshold of thresholds) {
      values.push(threshold.total);
      labels.push(threshold.name);
      colors.push(this.widgetService.getColorByKey(threshold.color).hex);

      this.legendItems.push({
        value: threshold.total,
        label: threshold.name,
        color: this.widgetService.getColorByKey(threshold.color).hex,
      });
    }

    this.chartData = [
      {
        type: 'pie',
        values: values,
        labels: labels,
        textinfo: this.config.customPreferences?.display || 'value',
        marker: {
          colors: colors,
        },
        textposition: 'inside',
        hole: 0.6,
      },
    ];
  }
}
