import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { MenuItemComponent } from './menu-item/menu-item.component';
import { LeftSidebarComponent } from './left-sidebar.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';

@NgModule({
  imports: [SharedModule],
  exports: [LeftSidebarComponent],
  declarations: [MenuItemComponent, LeftSidebarComponent, ToggleButtonComponent],
  providers: [],
})
export class LeftSidebarModule {}
