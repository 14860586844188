<div class="sc-flex justify-content-center align-items-center">
  <div *ngIf="schedulesHtml">
    <i class="fa fa-calendar"
      [pTooltip]="schedulesHtml"
      tooltipPosition="top"
      [escape]="false"
      appendTo="body"
    ></i>
  </div>
</div>
