import { Component, OnInit, Input, OnChanges, EventEmitter, SimpleChanges, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedService } from '@app/shared/shared.service';
import { SelectItem } from 'primeng/api';

interface SCSelectItem extends SelectItem {
  flag?: string;
}

@Component({
  selector: 'sc-dropdown',
  template: `
    <div [formGroup]="form">
      <sc-field-label
        *ngIf="showLabel"
        [for]="inputId"
        [fieldControl]="form.controls[controlName]"
        [title]="label"
        [description]="description"
        [warning]="warning"
      ></sc-field-label>

      <div *ngIf="multiSelect; then multiSelectInput; else singleSelectInput"></div>

      <ng-template #singleSelectInput>
        <p-dropdown
          [formControlName]="controlName"
          [inputId]="inputId"
          [options]="options"
          placeholder="placeholder"
          [showClear]="showClear"
          [disabled]="disabled"
          [filter]="showFilter"
          [editable]="editable"
          [style]="{ width: '100%' }"
          appendTo="body"
          (onChange)="actionChange.emit()"
        >
          <ng-template let-item pTemplate="selectedItem">
            <div class="sc-flex">
              <span *ngIf="item.flag && !item.icon" class="mr-2">
                <span class="flag-icon" [ngClass]="item.flag"></span>
              </span>
              <span *ngIf="item.icon && !item.flag" class="mr-2">
                <i class="fa" [ngClass]="item.icon"></i>
              </span>
              <span>{{ item.label }}</span>
            </div>
          </ng-template>

          <ng-template let-opt pTemplate="item">
            <div *ngIf="opt.label !== ''">
              <span *ngIf="opt.flag && !opt.icon">
                <span class="flag-icon" [ngClass]="opt.flag"></span>
              </span>
              <span *ngIf="opt.icon && !opt.flag">
                <i class="fa" [ngClass]="opt.icon"></i>
              </span>
              <span>{{ opt.label }}</span>
            </div>
            <div *ngIf="opt.label === ''"><span>&nbsp;</span></div>
          </ng-template>
        </p-dropdown>
      </ng-template>

      <ng-template #multiSelectInput>
        <p-multiSelect
          [formControlName]="controlName"
          [inputId]="inputId"
          [options]="options"
          [defaultLabel]="defaultLabel"
          [style]="{ width: '100%' }"
          appendTo="body"
          [showToggleAll]="showToggleAll"
          (onChange)="actionChange.emit()"
          [disabled]="disabled"
        >
          <ng-template let-opt pTemplate="item">
            <span *ngIf="opt.label !== ''">
              <span *ngIf="opt.flag && !opt.icon">
                <span class="flag-icon" [ngClass]="opt.flag"></span>
              </span>
              <span *ngIf="opt.icon && !opt.flag">
                <i class="fa" [ngClass]="opt.icon"></i>
              </span>
              <span>{{ opt.label }}</span>
            </span>
            <span *ngIf="opt.label === ''"> &nbsp; </span>
          </ng-template>
        </p-multiSelect>
      </ng-template>

      <sc-field-error [for]="label" [fieldControl]="form.controls[controlName]"></sc-field-error>
    </div>
  `,
})
export class SCDropdownComponent implements OnInit, OnChanges {
  @Input()
  controlName: string;
  @Input()
  form: FormGroup;
  @Input()
  label: string;
  @Input()
  disabled: boolean;
  @Input()
  editable: boolean;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  warning: string;
  @Input()
  multiSelect: boolean;
  @Input()
  showToggleAll: boolean;
  @Input()
  showClear: boolean;
  @Input()
  options: SCSelectItem[];
  @Input()
  defaultLabel: string;
  @Input()
  showFilter: boolean;

  @Output()
  actionChange = new EventEmitter<void>();

  inputId: string;
  placeholder: string;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.options = this.options || [];
    this.showClear = typeof this.showClear === 'boolean' ? this.showClear : true;
    this.showLabel = typeof this.showLabel === 'boolean' ? this.showLabel : true;
    this.showToggleAll = typeof this.showToggleAll === 'boolean' ? this.showToggleAll : true;
    this.disabled = typeof this.disabled === 'boolean' ? this.disabled : false;
    this.editable = typeof this.editable === 'boolean' ? this.editable : false;
    this.inputId = 'sc-dropdown_' + this.controlName + new Date().getTime();
    this.defaultLabel =
      this.defaultLabel ||
      `${this.sharedService.getTranslation('SELECT')} ${this.label ? this.label.toLowerCase() : ''}`;
    this.showFilter = typeof this.showFilter === 'boolean' ? this.showFilter : true;
    this.placeholder = `${this.sharedService.getTranslation('SELECT')} ${this.label ? this.label.toLowerCase() : ''}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && !changes.options.isFirstChange) {
      if (changes.options.currentValue) {
        this.options = changes.options.currentValue;
      } else {
        this.options = [];
      }
    }
  }
}
