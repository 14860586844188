import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
// import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';
import { LocationDetails } from '@app/widgets/locations/models/location-details';

import { RulesService } from '@widgets/rules/services/rules.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { LocationRuleActionCellComponent } from '@widgets/rules/location-rule-action-cell/location-rule-action-cell.component';

@Component({
  selector: 'sc-location-rules',
  templateUrl: './location-rules.component.html',
  styleUrls: ['./location-rules.component.scss'],
})
export class LocationRulesComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  @Input()
  selectedLocation: LocationDetails;

  columns: ColDef[];
  datasource: any;
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string;

  isFetchingDataset: boolean;

  // private selectedLocation: Location;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};
  private visibleColumns: string[] = [];

  constructor(
    // private store: Store<fromStore.State>,
    private rulesService: RulesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Set new height of widget
    // this.config.widgetHeight = this.config.widgetHeight - 25;
    // Set Table Name
    this.tableTitle = 'RULES';
    // Set Table Columns
    this.columns = this.createColumns();
    // Set user's visible columns
    if (this.config && this.config.customOptions && this.config.customOptions.visibleColumns) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }
    // Set create rule form
    this.formConfig = {
      name: 'RuleForm',
      options: { modalTitle: 'RULE_FORM_TITLE', fullScreen: true },
      data: { locationId: this.selectedLocation.id },
    };

    // get site details
    this.selectedSite = this.sharedService.selectedSite;

    // get location details
    // this.subscribers.LOCATION_DETAIL = this.store
    //   .select(fromStore.getLocationDetials)
    //   .subscribe(result => {
    //     if (result) {
    //       this.selectedLocation = result;
    //       this.fetchDataset();
    //     }
    //   });

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  // fetchDataset() {
  //   this.isFetchingDataset = true;
  //   const options = {
  //     locationId: this.selectedLocation.id,
  //     columns: 'id,name,description,isActive,isDeleted',
  //     minimize: true
  //   };
  //   this.subscribers.GET_DATASET = this.rulesService
  //     .getRules(options)
  //     .subscribe((result: any) => {
  //       if (result.mappingKeys) {
  //         this.dataset = this.sharedService.decodeMiminizeObjects(
  //           result.data,
  //           result.mappingKeys
  //         );
  //       } else {
  //         this.dataset = result.data;
  //       }
  //       this.isFetchingDataset = false;
  //     });
  // }

  fetchDataset() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.datasource = {
        getRows: (rowParams) => {
          // OPTIONS
          const options: any = {
            companyId: this.selectedSite.companyId,
            siteId: this.selectedSite.id,
            locationId: this.selectedLocation.id,
            filter: rowParams.filterModel,
            sort: rowParams.sortModel,
            offset: rowParams.startRow,
            columns: 'id,name,description,isActive,isDeleted',
            limit: 100,
          };

          // CUSOTM VISIBLE COLUMNS
          // if (this.visibleColumns.length) {
          //   options.columns = [...this.visibleColumns].join();
          // }

          // GET DATASET
          this.subscribers.GET_DATASET = this.rulesService.getRules(options).subscribe((result: any) => {
            const rowData = result.data;
            let lastRow = -1;
            if (rowData.length < options.limit) {
              lastRow = rowParams.startRow + rowData.length;
            }
            rowParams.successCallback(rowData, lastRow);
          });
        },
      };

      // SET DATASOURCE IF TABLE READY
      if (this.table && this.table.api) {
        this.table.api.setDatasource(this.datasource);
      }

      this.isFetchingDataset = false;
    }
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private createColumns(): ColDef[] {
    return [
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
        cellRenderer: 'loadingRenderer',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
      },
      {
        colId: 'isInActive',
        headerName: 'INACTIVE',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        valueGetter: (params) =>
          (params.data.isActive === 0 || params.data.isActive === false) &&
          (params.data.isDeleted === 0 || params.data.isDeleted === false),
        suppressFilter: true,
        hide: true,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: LocationRuleActionCellComponent,
      },
    ];
  }
}
