<p-dialog
  header="Config"
  [(visible)]="isConfigModalOpen"
  appendTo="body"
  [style]="{ width: '60vw', maxWidth: '600px' }"
>
  <div class="ui-fluid cleaning-status-config-modal__body">
    <div class="p-field p-grid">
      <label for="firstname" class="p-col-12 p-md-3">{{
        "WIDGET_CUSTOM_TITLE" | translate
      }}</label>
      <div class="p-col-12 p-md-9">
        <div class="ui-inputgroup cleaning-status-input-group">
          <input
            type="text"
            pInputText
            [(ngModel)]="customWidgetName"
            [placeholder]="defaultWidgetName"
          />
          <button
            pButton
            type="button"
            icon="pi pi-undo"
            (click)="resetWidgetName()"
            [disabled]="customWidgetName == ''"
          ></button>
        </div>
      </div>
    </div>
    <div class="p-field p-grid">
      <label
        for="cleanColor"
        class="p-col-12 p-md-3"
        pTooltip="The color to display the clean state"
        >Clean</label
      >
      <div class="p-col-12 p-md-9">
        <div class="ui-inputgroup cleaning-status-input-group">
          <p-dropdown
            [options]="colors"
            [(ngModel)]="customCleanColor"
            optionLabel="name"
          ></p-dropdown>
          <button
            pButton
            type="button"
            icon="pi pi-undo"
            (click)="resetCleanColor()"
            [disabled]="!customCleanColor"
          ></button>
        </div>
      </div>
    </div>
    <div class="p-field p-grid">
      <label
        for="dirtyColor"
        class="p-col-12 p-md-3"
        pTooltip="The color to display the dirty state"
        >Dirty</label
      >
      <div class="p-col-12 p-md-9">
        <div class="ui-inputgroup cleaning-status-input-group">
          <p-dropdown
            [options]="colors"
            [(ngModel)]="customDirtyColor"
            optionLabel="name"
          ></p-dropdown>
          <button
            pButton
            type="button"
            icon="pi pi-undo"
            (click)="resetDirtyColor()"
            [disabled]="!customDirtyColor"
          ></button>
        </div>
      </div>
    </div>
    <div class="p-field p-grid p-align-center">
      <label for="lastname" class="p-col-12 p-md-3 p-col-align-start"
        >Locations</label
      >
      <div class="p-col-12 p-md-9">
        <div class="p-field-radiobutton">
          <p-radioButton
            name="useLocationSelection"
            [value]="false"
            [(ngModel)]="useLocationSelection"
            inputId="useLocationSelection1"
          ></p-radioButton>
          <label for="useLocationSelection1">All units</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            name="useLocationSelection"
            [value]="true"
            [(ngModel)]="useLocationSelection"
            inputId="useLocationSelection2"
          ></p-radioButton>
          <label for="useLocationSelection2">Selection</label>
        </div>

        <div *ngIf="useLocationSelection">
          <div class="sc-tree-wrapper">
            <p-tree
              [propagateSelectionUp]="false"
              [propagateSelectionDown]="false"
              [filter]="true"
              [loading]="isFetchingLocations"
              [value]="locationsTree"
              selectionMode="checkbox"
              [(selection)]="selectedLocations"
            ></p-tree>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button
      type="button"
      (click)="saveConfig()"
      pButton
      label="{{ 'SUBMIT' | translate }}"
      class="ui-button-success"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  header="{{ widgetName }}"
  [(visible)]="isDetailsModalOpen"
  appendTo="body"
  [style]="{ width: '50vw', maxWidth: '800px' }"
>
  <div *ngIf="!isDetailsModalInitialized">
    <sc-loading-indicator></sc-loading-indicator>
  </div>
  <p-tabView *ngIf="isDetailsModalInitialized">
    <p-tabPanel header="Clean">
      <p>
        {{ numCleanLocations }} of {{ numTotalLocations }} locations are clean.
      </p>
      <p-table
        [value]="cleanLocations"
        *ngIf="numCleanLocations > 0"
        sortField="searchPath"
        [sortOrder]="1"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="searchPath">
              Location <p-sortIcon field="searchPath"></p-sortIcon>
            </th>
            <th pSortableColumn="lastChange" width="180">
              Clean since <p-sortIcon field="lastChange"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-location>
          <tr>
            <td>
              <span
                *ngFor="
                  let path of location.path;
                  let i = index;
                  let last = last
                "
              >
                <a
                  class="sc-clickable"
                  (click)="goToLocation(path.id, path.type)"
                  >{{ path.name }}</a
                >
                <i
                  *ngIf="!last"
                  class="pi pi-chevron-right"
                  style="font-size: 0.75em; margin-left: 5px; margin-right: 5px"
                ></i>
              </span>
            </td>
            <td>{{ location.lastChange }}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Dirty">
      <p>
        {{ numDirtyLocations }} of {{ numTotalLocations }} locations are dirty.
      </p>
      <p-table
        [value]="dirtyLocations"
        *ngIf="numDirtyLocations > 0"
        sortField="searchPath"
        [sortOrder]="1"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="searchPath">
              Location <p-sortIcon field="searchPath"></p-sortIcon>
            </th>
            <th pSortableColumn="lastChange" width="180">
              Dirty since <p-sortIcon field="lastChange"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-location>
          <tr>
            <td>
              <span
                *ngFor="
                  let path of location.path;
                  let i = index;
                  let last = last
                "
              >
                <a
                  class="sc-clickable"
                  (click)="goToLocation(path.id, path.type)"
                  >{{ path.name }}</a
                >
                <i
                  *ngIf="!last"
                  class="pi pi-chevron-right"
                  style="font-size: 0.75em; margin-left: 5px; margin-right: 5px"
                ></i>
              </span>
            </td>
            <td>{{ location.lastChange }}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</p-dialog>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body cleaning-status-body">
    <div class="sc-widget-legend cleaning-status-widget-legend">
      <ul>
        <li>
          <span
            class="color"
            [ngStyle]="{ 'background-color': cleanColor.hex }"
          ></span>
          <span class="label"
            >{{ numCleanLocations }} {{ "CLEAN" | translate }}</span
          >
        </li>
        <li>
          <span
            class="color"
            [ngStyle]="{ 'background-color': dirtyColor.hex }"
          ></span>
          <span class="label"
            >{{ numDirtyLocations }} {{ "DIRTY" | translate }}</span
          >
        </li>
      </ul>
    </div>
    <div class="cleaning-status-widget-plotly">
      <plotly-plot
        [data]="chartData"
        [layout]="chartLayout"
        [config]="{ displayModeBar: false }"
      ></plotly-plot>
    </div>
  </div>
</ng-template>

<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">{{ widgetName }}</h4>
      <div class="sc-widget-menu">
        <button
          type="button"
          (click)="openDetailsModal()"
          pButton
          icon="pi pi-eye"
          title="Details"
          [disabled]="!isInitialized"
          class="cleaning-status-action-btn"
        ></button>
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          title="Config"
          [disabled]="!isInitialized"
          class="cleaning-status-action-btn"
        ></button>
      </div>
    </div>
    <ng-container
      *ngIf="isInitialized && chartData; then content; else spinner"
    ></ng-container>
  </div>
</div>
