<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'ROOM_CORRECTION' | translate }} -->
        {{ widgetName }}
      </h4>

      <div class="sc-widget-menu">
        <i
          class="fa fa-search fa-fw sc-clickable green-text"
          (click)="switchViewMode('HISTORY')"
          pTooltip="{{ 'HISTORY' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>

        <i
          class="fa fa-line-chart fa-fw sc-clickable green-text"
          (click)="switchViewMode('ENERGY_SAVING')"
          pTooltip="{{ 'ENERGY_SAVING' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>
      </div>
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body justify-between">
    <ng-container [ngSwitch]="viewMode">
      <ng-container *ngSwitchDefault>
        <ng-container *ngIf="widgetData">
          <div class="sc-flex justify-content-around w-100">
            <div *ngFor="let i of [0, 1]">
              <div *ngIf="widgetData[i].label" class="text-center mb-1">
                {{ widgetData[i].label | translate: widgetData[i].labelParams }}
              </div>
              <sc-circle
                [customClass]="widgetData[i].customClass"
                [customStyle]="widgetData[i].customStyle"
                [icon]="widgetData[i].icon"
                [primaryText]="widgetData[i].primaryText"
                [secondaryText]="widgetData[i].secondaryText | translate"
                [size]="widgetData[i].size"
              ></sc-circle>
            </div>
          </div>

          <div class="sc-flex justify-content-around w-100">
            <div *ngFor="let i of [2, 3, 4]">
              <div *ngIf="widgetData[i].label" class="text-center mb-1">
                {{ widgetData[i].label | translate: widgetData[i].labelParams }}
              </div>
              <sc-circle
                [customClass]="widgetData[i].customClass"
                [customStyle]="widgetData[i].customStyle"
                [icon]="widgetData[i].icon"
                [primaryText]="widgetData[i].primaryText"
                [secondaryText]="widgetData[i].secondaryText | translate"
                [size]="widgetData[i].size"
              ></sc-circle>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'ENERGY_SAVING'">
        <div *ngIf="chartData" class="w-100">
          <plotly-plot
            [data]="chartData"
            [layout]="chartLayout"
            [useResizeHandler]="true"
            [style]="{
              position: 'relative',
              width: widgetWidth,
              height: widgetHeight
            }"
            [config]="{ displayModeBar: false }"
          ></plotly-plot>
        </div>
        <ng-container *ngIf="!chartData">
          No Energy Saving found!
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
