import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SelectItem, TreeNode } from 'primeng/api';
import { QuickDateItem } from '@sc/quick-date-select/quick-date-item';

@Component({
  selector: 'sc-dataset-form',
  templateUrl: './dataset-form.component.html',
  styleUrls: ['./dataset-form.component.scss']
})
export class DatasetFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() selectItems: { [key: string]: (SelectItem | TreeNode)[] };

  constructor() {}

  ngOnInit() {}

  onDateSelect(event: QuickDateItem) {
    this.form.patchValue(event);
  }
}
