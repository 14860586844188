import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';

import { EndUserRoleService } from '../services/end-user-role.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-role-action-cell',
  templateUrl: './role-action-cell.component.html',
  styleUrls: ['./role-action-cell.component.scss'],
})
export class RoleActionCellComponent implements ICellRendererAngularComp {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  constructor(private endUserRoleService: EndUserRoleService, private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'EndUserRoleForm',
      data: this.cell.data,
      options: { modalTitle: 'END_USER_ROLE_FORM_TITLE' },
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        // permission: '_u',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this),
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        // permission: '_d',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];
  }

  refresh(): boolean {
    return false;
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.endUserRoleService.deleteEndUserRole(this.cell.data.id).subscribe(
      (response: any) => {
        this.cell.context.parentComponent.updateRow({
          id: this.cell.data.id,
          isActive: false,
          isDeleted: true,
        });
        const title = this.sharedService.getTranslation('END_USER_ROLE');
        const text = this.sharedService.getTranslation('DELETE_SUCCESS');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const title = this.sharedService.getTranslation('END_USER_ROLE');
        const text = this.sharedService.getTranslation('DELETE_FAIL');
        this.sharedService.notify(title, text, 'error');
      }
    );
  }
}
