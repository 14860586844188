import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-location-summary-form-item',
  templateUrl: './location-summary-form-item.component.html',
  styleUrls: ['./location-summary-form-item.component.scss'],
})
export class LocationSummaryFormItemComponent implements OnInit {
  @Input()
  data: any;

  constructor() {}

  ngOnInit() {}
}
