import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { GridOptions } from 'ag-grid';
import { GuestRequest } from '../guest-request';
import { Site } from '@widgets/sites/models/site';

import { GuestRequestsService } from '../guest-requests.service';
import { GuestRequestPresetsService } from '@widgets/guest-request-presets/guest-request-presets.service';
import { SCTableService } from '@sc/table/table.service';
import { SharedService } from '@shared/shared.service';

import { FlagCellComponent } from '@widgets/_shared/flag-cell/flag-cell.component';
import { PhotoCellComponent } from '@widgets/_shared/photo-cell/photo-cell.component';
import { GuestRequestActionCellComponent } from '../guest-request-action-cell/guest-request-action-cell.component';
import { GuestRequestStatusCellComponent } from '../guest-request-status-cell/guest-request-status-cell.component';
import { GuestRequestTextCellComponent } from '../guest-request-text-cell/guest-request-text-cell.component';
import { GuestRequestReactionTimeCellComponent } from '../guest-request-reaction-time-cell/guest-request-reaction-time-cell.component';

import { config } from '@app/config';

import * as moment from 'moment';

@Component({
  selector: 'sc-guest-requests-table',
  templateUrl: 'guest-requests-table.component.html',
})
export class GuestRequestsTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: GuestRequest[] = [];
  subscribers: { [key: string]: any } = {};
  tableTitle: string;

  table: GridOptions;
  currentDataset: GuestRequest[];
  fetchGuestRequestInterval: any;
  isFetching = false;
  isFirstTime = true;
  presetsData: any;
  selectedSite: Site;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private guestRequestService: GuestRequestsService,
    private guestRequestPresetsService: GuestRequestPresetsService,
    private sharedService: SharedService,
    private tableService: SCTableService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    // table name
    this.tableTitle = 'GUEST_REQUESTS';

    // table columns
    this.columns = [
      {
        colId: 'id',
        headerName: 'Id',
        field: 'id',
      },
      {
        colId: 'companyId',
        headerName: 'COMPANY_ID',
        field: 'companyId',
      },
      {
        colId: 'siteId',
        headerName: 'SITE_ID',
        field: 'siteId',
      },
      {
        colId: 'locationId',
        headerName: 'LOCATION_ID',
        field: 'locationId',
      },
      {
        colId: 'guestSessionId',
        headerName: 'GUEST_SESSION_ID',
        field: 'guestSessionId',
      },
      {
        colId: 'guestGender',
        headerName: 'GENDER',
        field: 'guestGender',
        width: 100,
        valueFormatter: (params) => {
          if (params && params.value) {
            return params.value.toUpperCase() === 'M' ? 'Male' : 'Female';
          }
          return '';
        },
      },
      {
        colId: 'locale',
        headerName: 'LOCALE',
        field: 'locale',
        width: 100,
        cellRendererFramework: FlagCellComponent,
      },
      {
        colId: 'categoryId',
        headerName: 'CATEGORY_ID',
        field: 'categoryId',
      },
      {
        colId: 'presetId',
        headerName: 'PRESET_ID',
        field: 'presetId',
      },
      {
        colId: 'attachedFiles',
        headerName: 'IMAGE',
        field: 'attachedFiles',
        width: 80,
        cellRendererFramework: PhotoCellComponent,
        valueFormatter: (params) => {
          const photo = typeof params.value !== 'undefined' ? params.value[0].path : null;
          return photo ? `${config().s3Url}/${photo}` : null;
        },
      },
      {
        colId: 'text',
        headerName: 'TEXT',
        field: 'text',
        cellRendererFramework: GuestRequestTextCellComponent,
      },
      {
        colId: 'status',
        headerName: 'STATUS',
        field: 'status',
        width: 120,
        cellRendererFramework: GuestRequestStatusCellComponent,
      },
      {
        colId: 'reaction',
        headerName: 'Reaction',
        field: 'reaction',
        width: 120,
        cellRendererFramework: GuestRequestReactionTimeCellComponent,
      },
      {
        colId: 'createdAt',
        headerName: 'CREATED_AT',
        field: 'createdAt',
        width: 120,
        valueFormatter: (params) => {
          if (typeof params.value !== 'undefined') {
            return moment(params.value).fromNow();
          }
          return '';
        },
        sort: 'desc',
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        width: 120,
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: GuestRequestActionCellComponent,
      },
    ];

    // Get Selected Site
    this.store
      .select(fromStore.getSelectedSite)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((result) => {
        if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
          this.selectedSite = result;

          if (this.table && this.dataset) {
            this.tableService.clearTable(this.table);
          }

          if (!this.isFirstTime) {
            this.stopAutoUpdate();
          }

          this.fetchDataset();
        }
      });

    this.fetchPresets();
  }

  ngOnDestroy() {
    this.stopAutoUpdate();

    this.componentDestroyed.next();
    this.componentDestroyed.complete();

    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchPresets() {
    this.subscribers.GET_PRESETS = this.guestRequestPresetsService.get().subscribe((result: any) => {
      this.presetsData = result.data;
    });
  }

  fetchDataset() {
    if (!this.isFetching && this.selectedSite) {
      this.isFetching = true;

      // ____ GET GUEST REQUESTS
      this.subscribers.GET_DATASET = this.guestRequestService
        .getGuestRequests({
          companyId: this.selectedSite.companyId,
          siteId: this.selectedSite.id,
        })
        .subscribe((result: any) => {
          this.dataset = result.data;
          this.tableService.updateTableRow(this.table, this.dataset);
          this.isFetching = false;

          if (this.isFirstTime) {
            this.startAutoUpdate();
          }
        });
    }
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
  }

  startAutoUpdate() {
    this.isFirstTime = false;
    clearInterval(this.fetchGuestRequestInterval);
    this.fetchGuestRequestInterval = setInterval(() => {
      this.fetchDataset();
    }, 10000);
  }

  stopAutoUpdate() {
    this.isFetching = false;
    this.isFirstTime = true;
    clearInterval(this.fetchGuestRequestInterval);
    this.fetchGuestRequestInterval = null;
  }
}
