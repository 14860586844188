import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { FieldActionCellComponent } from './field-action-cell/field-action-cell.component';
import { FieldsFormComponent } from './fields-form/fields-form.component';
import { FieldsOverviewComponent } from './fields-overview/fields-overview.component';
import { SelectboxSettingFormComponent } from './selectbox-setting-form/selectbox-setting-form.component';

import { FieldsService } from './services/fields.service';

const COMPONENTS: any[] = [
  FieldActionCellComponent,
  FieldsFormComponent,
  FieldsOverviewComponent
];

const SUBCOMPONENTS: any[] = [SelectboxSettingFormComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS, ...SUBCOMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [FieldsService]
})
export class SettingFormModule {}
