import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-table-refresh-button',
  templateUrl: 'table-refresh-button.component.html',
  styleUrls: ['table-refresh-button.component.scss'],
})
export class TableRefreshButtonComponent implements OnInit {
  @Input() refreshing: boolean;
  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.refreshing = false;
  }

  clickHandler() {
    this.onClick.emit();
  }
}
