import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Credential } from '../models/credential';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'sc-login-page',
  styleUrls: ['./login-page.component.scss'],
  templateUrl: './login-page.component.html',
})
export class LoginPageComponent implements OnInit, OnDestroy {
  countDownResend: number;
  form: FormGroup;
  isResending = false;
  isVerifying = false;
  twoFactorAuthMethod: string;

  private authError: string;
  private componentDestroyed: Subject<void> = new Subject();
  private intervals: { [key: string]: any } = {};
  private loginData: Credential;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    // force log out if user navigate to this page
    this.authService.logout();

    this.createLoginForm();
    this.subscribeData();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  private subscribeData() {
    // subscribe auth error message
    this.store.pipe(select(fromStore.getAuthError), takeUntil(this.componentDestroyed)).subscribe({
      next: (result) => {
        this.authError = result;
      },
    });

    // subscribe auth verifying
    this.store.pipe(select(fromStore.getAuthVerifying), takeUntil(this.componentDestroyed)).subscribe({
      next: (result) => {
        this.isVerifying = result;
        if (this.isVerifying) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      },
    });

    // subscribe auth verifying
    this.store.pipe(select(fromStore.getAuthTwoFactorAuthMethod), takeUntil(this.componentDestroyed)).subscribe({
      next: (result) => {
        this.twoFactorAuthMethod = result;
      },
    });
  }

  private createLoginForm() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      twoFactorAuthCode: null,
    });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.loginData = this.form.value;
    if (this.twoFactorAuthMethod) {
      this.store.dispatch(new fromStore.VerifyTwoFactorAuth(this.loginData));
    } else {
      this.store.dispatch(new fromStore.VerifyUser(this.loginData));
    }
  }

  get isBlocked() {
    const regex = /blocked/gi;
    if (this.authError && regex.test(this.authError)) {
      return true;
    }
    return false;
  }

  get errorMessages() {
    if (this.authError) {
      return [{ severity: 'error', summary: this.authError }];
    }
    return;
  }

  resend() {
    if (this.isResending) {
      return;
    }

    this.isResending = true;
    this.authService.resendUnblock(this.loginData.username).subscribe({
      next: (result: any) => {
        this.startResendCountDown();
        this.isResending = false;
      },
    });
  }

  private startResendCountDown() {
    this.countDownResend = 60;
    clearInterval(this.intervals.RESEND_COUNT_DOWN);
    this.intervals.RESEND_COUNT_DOWN = setInterval(() => {
      if (this.countDownResend > 0) {
        this.countDownResend--;
      } else {
        clearInterval(this.intervals.RESEND_COUNT_DOWN);
        this.intervals.RESEND_COUNT_DOWN = null;
      }
    }, 1000);
  }
}
