import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid';

import { ModalConfig } from '../modal/modal-config';
import { WidgetCustomOptions, Widget } from '@app/core2/models/page-section-widget';

import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromStore from '@app/store';

import { SCTableService } from './table.service';
import { SharedService } from '@shared/shared.service';

import { isArray } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class SCTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  columns: ColDef[];

  @Input()
  config: Widget;

  @Input()
  dataset: { [key: string]: any }[];

  @Input()
  formConfig: ModalConfig;

  @Input()
  title: string;

  @Input()
  lazyload: boolean;

  @Input()
  icon: string;

  @Input()
  hideIcon: boolean;

  @Input()
  toolbarItems: ('create' | 'refresh' | 'filter' | 'columns' | 'treeExpand' | 'customSummary' | 'site')[];

  @Input()
  showDateFilter: boolean;

  @Input()
  hideHeader: boolean;

  @Input()
  suppressRowClickSelection: boolean = false;

  @Input()
  adjustToFit: boolean;

  @Output()
  reloadData = new EventEmitter();

  @Output()
  afterInit = new EventEmitter();

  @Output()
  displayColumnsChange = new EventEmitter();

  @Output()
  customSummarySave = new EventEmitter();

  @Output()
  filterDateChange = new EventEmitter();

  @Output()
  siteModeChanged = new EventEmitter();

  gridOptions: GridOptions;
  gridReady: boolean;
  refreshing: boolean;
  // updateCustomWidget: any;
  errorMessage: string;
  pending: boolean;
  saveCustom = false;
  siteSelectorPermission = 'admin';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private sharedService: SharedService,
    private tableService: SCTableService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (typeof this.icon === 'undefined' && typeof this.title !== 'undefined') {
      this.icon = 'fa-table';
    }

    this.gridOptions = this.lazyload
      ? this.tableService.initLazyLoadTable(this.columns)
      : this.tableService.initTable(this.columns, this.dataset);

    // apply custom grid config
    if (this.config && this.config.gridOptions) {
      for (const key in this.config.gridOptions) {
        if (this.config.gridOptions.hasOwnProperty(key)) {
          this.gridOptions[key] = this.config.gridOptions[key];
        }
      }
    }

    this.gridOptions.context = { parentComponent: this };
    this.gridOptions.onGridReady = (params) => {
      this.gridReady = true;

      if (this.gridOptions.api) {
        // show 'loading' overlay
        if (!this.lazyload && !this.dataset) {
          this.gridOptions.api.showLoadingOverlay();
        }

        this.setColumnsSize();
        this.setDefaultFilters();
      }

      // emit event table after init
      this.afterInit.emit(this.gridOptions);
    };

    // tree view
    if (!this.lazyload && this.config && this.config.customOptions && this.config.customOptions.treeView) {
      this.gridOptions.getNodeChildDetails = (row) => {
        if (row.children && row.children.length) {
          return {
            children: row.children,
            expanded: this.config.customOptions.treeExpand || false,
            group: true,
            key: row.id,
          };
        } else {
          return null;
        }
      };
    }

    this.subscribers.translation = this.translateService.onTranslationChange
      .pipe(filter((value) => !!value))
      .subscribe((value) => {
        if (this.gridOptions && this.gridOptions.api) {
          this.gridOptions.api.refreshHeader();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const dataset = changes.dataset && changes.dataset.currentValue;
    // watch dataset
    if (this.gridOptions && dataset) {
      if (!this.lazyload && this.config && this.config.customOptions && this.config.customOptions.treeView) {
        // update dataset of tree view
        this.gridOptions.api.setRowData(dataset);
      } else {
        // update dataset of normal view
        this.tableService.addTableRow(this.gridOptions, dataset, true);
      }
      this.refreshing = false;
      // clear all overlays
      this.gridOptions.api.hideOverlay();
    }
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    // if (this.updateCustomWidget) {
    //   this.updateCustomWidget.unsubscribe();
    // }
  }

  /**
   * Add new row into table
   * @param event
   */
  addRow(event: { result?: { [prop: string]: any } | { [prop: string]: any }[]; error?: any }) {
    if (event.result) {
      if (this.lazyload) {
        this.gridOptions.api.refreshInfiniteCache();
      } else if (!this.lazyload && this.config && this.config.customOptions && this.config.customOptions.treeView) {
        // reload talbe only for tree view
        this.gridOptions.context.containerComponent.fetchDataset();
      } else {
        if (isArray(event.result)) {
          this.tableService.updateTableRow(this.gridOptions, event.result as { [prop: string]: any }[]);
        } else {
          this.tableService.addTableRow(this.gridOptions, [event.result]);
        }
      }
    }
  }

  /**
   * Update row in table by id, username or key
   * @param event
   */
  // updateRow(event: { id?: string; username?: string; key?: string; [prop: string]: any }) {
  updateRow(event: { [prop: string]: any }, compareKey?: string) {
    compareKey = compareKey || (event.id && 'id') || (event.username && 'username') || (event.key && 'key');
    if (compareKey) {
      this.tableService.updateTableRow(this.gridOptions, [event], compareKey);
    }
  }

  /**
   * Reload dataset
   */
  refresh() {
    if (this.lazyload) {
      this.gridOptions.api.purgeInfiniteCache();
      // this.gridOptions.api.refreshInfiniteCache();
    } else {
      this.refreshing = true;
      this.gridOptions.api.showLoadingOverlay();
    }
    this.reloadData.emit();
  }

  /**
   * Restore columns width
   */
  onDisplayColumnsChanged(event) {
    this.displayColumnsChange.emit(event);

    this.setColumnsSize();
  }

  setColumnsSize() {
    if (this.gridReady && this.gridOptions && (this.gridOptions.api || this.gridOptions.columnApi)) {
      const columns = this.gridOptions.columnApi.getAllColumns();
      if (this.config && this.config.customOptions && this.config.customOptions.sizeColumns) {
        this.config.customOptions.sizeColumns.forEach((size) => {
          const column = columns.find((col: any) => col.colId === size.colId);
          this.gridOptions.columnApi.setColumnWidth(column, size.width);
        });
      } else if (this.adjustToFit) {
        this.gridOptions.api.sizeColumnsToFit();
      }
    }
  }

  /**
   * Update custom widget
   * @param options
   */
  save(options: WidgetCustomOptions) {
    if (!this.config) {
      return;
    }

    const customWidget = { ...this.config };
    delete customWidget.customSummaryModalConfig;
    delete customWidget.name;
    delete customWidget.description;
    delete customWidget.categories;
    customWidget.customOptions = { ...this.config.customOptions, ...options };
    if (typeof customWidget.userId === 'undefined') {
      customWidget.userId = this.sharedService.userId;
    }

    delete customWidget.gridOptions;

    this.store.dispatch(new fromStore.SaveCustomSetting(customWidget));

    this.subscribers.saveTableSetting = combineLatest([
      this.store.select(fromStore.getPagesPendingError),
      this.store.select(fromStore.getPagesPending),
    ])
      .pipe(
        map(([error, pending]) => ({ error, pending })),
        filter((data) => !data.pending),
        take(1)
      )
      .subscribe((data) => {
        if (data.error) {
          this.sharedService.notify('Table setting', 'Save table setting failed ', 'error', true);
        } else {
          this.sharedService.notify('Table setting', 'Save table setting succeeded', 'success', true);
        }
      });
  }

  updateTreeExpand(currentValue: boolean) {
    if (!this.lazyload && this.config && this.config.customOptions && this.config.customOptions.treeView) {
      // NOTE: DO LIKE THIS TO AVOID `ERROR OF SET VALUE TO READ-ONLY VARIABLE`
      this.config.customOptions = {
        ...this.config.customOptions,
        treeExpand: currentValue,
      };
    }
  }

  updateLocationSummaryColumn(event) {
    const options = {
      locationSummaryConfig: event.result,
    };
    this.save(options);
    this.customSummarySave.emit(options.locationSummaryConfig);
  }

  isActiveToolbarItem(key) {
    if (
      this.hideHeader ||
      (this.toolbarItems && this.toolbarItems.indexOf(key) < 0) ||
      (key === 'site' && !this.toolbarItems)
    ) {
      return false;
    }
    return true;
  }

  private columnExists(column) {
    if (this.columns) {
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].colId === column) {
          return true;
        }
      }
    }
    return false;
  }

  private setDefaultFilters() {
    const filters: any = {};

    if (this.lazyload) {
      if (this.columnExists('isActive')) {
        filters.isActive = {
          type: 'equals',
          filter: 1,
        };
      }
      if (this.columnExists('isInActive')) {
        filters.isInActive = {
          type: 'equals',
          filter: 1,
        };
      }
    }
    if (this.columnExists('isDeleted')) {
      filters.isDeleted = {
        type: 'equals',
        filter: 0,
      };
    }

    this.gridOptions.api.setFilterModel(filters);
  }

  onSiteModeChanged(event) {
    this.siteModeChanged.emit(event);
  }
}
