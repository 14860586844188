import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { ColDef } from 'ag-grid';
import { Location } from '@widgets/locations/models/location';

import { LocationSummaryService } from './services/location-summary.service';
import { SharedService } from '@shared/shared.service';


@Component({
  selector: 'sc-location-summary',
  templateUrl: './location-summary.component.html',
  styleUrls: ['./location-summary.component.scss']
})

export class LocationSummaryComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  isFetchingDataset = false;
  isFirstTime = true;
  dataset: any[];
  tableColumns: ColDef[];
  tableTitle: string;
  toolbarItems: string[];

  private selectedLocation: Location;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private locationSummaryService: LocationSummaryService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
  ) { }

  ngOnInit() {
    this.tableColumns = this.createColumns();
    this.tableTitle = 'SUMMARY';
    this.toolbarItems = ['refresh', 'columns'];

    // get location details
    this.subscribers.LOCATION_DETAIL = this.store
      .pipe(select(fromStore.getLocationDetials))
      .subscribe(result => {
        if (result) {
          this.selectedLocation = result;
          this.isFirstTime = true;
          this.fetchDataset(true);
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset(force?: boolean) {
    if (!force && this.isFetchingDataset) {
      return;
    }

    this.isFetchingDataset = true;

    this.subscribers.getLocationSummary = this.locationSummaryService
      .getLocationSummary(this.selectedLocation.id)
      .subscribe((result: any) => {
        this.dataset = result.data;

        this.isFetchingDataset = false;
        this.isFirstTime = false;
      });
  }

  private dateFormatter(data) {
    if (data) {
      return this.sharedService.dateFormat(data, 'DD.MM.YYYY');
    }
    return '';
  }

  private dataFormatter(params: any) {
    const field = params && params.colDef && params.colDef.field;
    let totalTime = params.data[field];
    let unit: string;
    const counterValue = ['setpoint_changes_count', 'door_changes_count'];
    if (counterValue.indexOf(field) >= 0) {
      unit = 'time(s)';
    } else {
      unit = 'hour(s)';
      totalTime = totalTime / 60 / 60;
      totalTime = this.sharedService.numberFormat(totalTime, 2);
    }
    return  totalTime + ' ' + unit;
  }

  private dataCellRenderer(params: any) {
    return '<div class="text-right">' + params.valueFormatted + '</div>';
  }

  private createColumns() {
    return [
      {
        colId: 'key',
        headerName: 'DATE',
        field: 'key',
        valueFormatter: params => this.dateFormatter(params.data.key),
        filterValueGetter: params => this.dateFormatter(params.data.key),
      },
      {
        colId: 'occupied',
        headerName: 'LOCATION_SUMMARY_OCCUPIED',
        field: 'occupied',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'unoccupied',
        headerName: 'LOCATION_SUMMARY_UNOCCUPIED',
        field: 'unoccupied',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'lights_on',
        headerName: 'LOCATION_SUMMARY_LIGHTS_ON',
        field: 'lights_on',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'lights_off',
        headerName: 'LOCATION_SUMMARY_LIGHTS_OFF',
        field: 'lights_off',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'setpoint_changes_count',
        headerName: 'LOCATION_SUMMARY_SETPOINT_CHANGES_COUNT',
        field: 'setpoint_changes_count',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'hvac_mode_off',
        headerName: 'LOCATION_SUMMARY_HVAC_MODE_OFF',
        field: 'hvac_mode_off',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'hvac_mode_cooling',
        headerName: 'LOCATION_SUMMARY_HVAC_MODE_COOLING',
        field: 'hvac_mode_cooling',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'hvac_mode_heating',
        headerName: 'LOCATION_SUMMARY_HVAC_MODE_HEATING',
        field: 'hvac_mode_heating',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'hvac_mode_ventilating',
        headerName: 'LOCATION_SUMMARY_HVAC_MODE_VENTILATING',
        field: 'hvac_mode_ventilating',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
      {
        colId: 'door_changes_count',
        headerName: 'LOCATION_SUMMARY_DOOR_CHANGES_COUNT',
        field: 'door_changes_count',
        valueFormatter: this.dataFormatter.bind(this),
        filterValueGetter: this.dataFormatter.bind(this),
        cellRenderer: this.dataCellRenderer.bind(this)
      },
    ];
  }
}
