import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { Location } from '@widgets/locations/models/location';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-notification-history-log-table',
  templateUrl: 'notification-history-log-table.component.html'
})
export class NotificationHistoryLogTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: any[];
  tableTitle: string;

  private selectedLocation: Location;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.columns = this.createColumns();
    this.tableTitle = 'NOTIFICATION_HISTORY_LOG';

    this.subscribers.LOCATION_DETAIL = this.store
      .select(fromStore.getLocationDetials)
      .subscribe(result => {
        this.selectedLocation = result;
        this.fetchDataset();
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    // TODO: COMPLETE THIS FUNCTION AFTER API READY
    this.dataset = [];
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'emailHistory',
        headerName: 'Email',
        field: 'emailHistory'
      },
      {
        colId: 'smsHistory',
        headerName: 'SMS',
        field: 'smsHistory'
      }
    ];
  }
}
