import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItems } from '../../models';

@Component({
  selector: 'sc-custom-global',
  templateUrl: './custom-global.component.html',
  styleUrls: ['./custom-global.component.scss'],
})
export class CustomGlobalComponent implements OnInit {
  actions: any[];
  valueStructure: any;

  @Input()
  commandForm: FormGroup;

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {
    this.actions = [{ label: 'Set', value: 'set' }];

    if (this.commandForm) {
      this.commandForm.get('action').setValue(this.actions[0].value);
      this.commandForm.get('action').disable();
    }
  }
}
