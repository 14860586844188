import { Injectable } from '@angular/core';
import { ActionMenuComponent } from '../components/action-menu/action-menu.component';
import { ActionMenuItem } from '../models/action-menu-item';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class ActionMenuService {
  constructor(private dialogService: DialogService) {}

  open(menu: ActionMenuItem[]) {
    const config = {
      contentStyle: { padding: '0px' },
      dismissableMask: true,
      showHeader: false,
      styleClass: 'modal-width--responsive',
      data: { menu },
      width: '450px'
    };
    const modalRef = this.dialogService.open(ActionMenuComponent, config);
    return modalRef;
  }
}
