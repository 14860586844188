import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { SettingWidget } from '../models/setting-widget';
import { SettingWidgetFormService } from '../services/setting-widget-form.service';
import { SettingWidgetsService } from '../services/setting-widgets.service';
import { WidgetCategoriesService } from '@widgets/widget-categories/services/widget-categories.service';
import { SharedService } from '@shared/shared.service';
import { Locale } from '@widgets/locales/models/locale';

@Component({
  selector: 'sc-setting-widget-form',
  templateUrl: 'setting-widget-form.component.html',
})
export class SettingWidgetFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: SettingWidget;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};

  private locales: Locale[];
  private subscribers: { [key: string]: any } = {};

  constructor(
    private widgetCategoriesService: WidgetCategoriesService,
    private widgetsService: SettingWidgetsService,
    private widgetFormService: SettingWidgetFormService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.locales = this.sharedService.sourceData.locales;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchWidget(this.data.id);
    }

    this.fetchWidgetCategories();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchWidget(id: number) {
    this.fetchingState++;
    this.subscribers.fetchWidget = this.widgetsService.getWidget(id).subscribe((result: any) => {
      this.formData = result.data;

      if (this.formData.categories) {
        this.formData.categories = JSON.parse(this.formData.categories);
      } else {
        this.formData.categories = <any>[];
      }

      this.form.patchValue(this.formData);
      this.widgetFormService.patchLocaleFormArray(this.form, this.formData);
      this.fetchingState--;
    });
  }

  private fetchWidgetCategories() {
    this.fetchingState++;
    this.subscribers.fetchWidgetCategories = this.widgetCategoriesService
      .getWidgetCategories()
      .subscribe((result: any) => {
        this.selectItems.widgetCategories = result.data
          .filter((item) => item.isActive && !item.isDeleted)
          .map((item) => {
            const name = JSON.parse(item.name);
            const data = {
              label: name ? name.en : item.key,
              value: item.id,
            };
            return data;
          });
        this.fetchingState--;
      });
  }

  private initForm() {
    this.selectItems.widgetTypes = [
      { label: 'Custom', value: 'custom' },
      { label: 'Chart', value: 'chart' },
      { label: 'Dashboard', value: 'dashboard' },
      { label: 'Table', value: 'table' },
    ];

    this.selectItems.availability = [
      { label: 'Mono', value: 'MONO' },
      { label: 'Multi', value: 'MULTI' },
      { label: 'Any', value: 'ANY' },
    ];

    this.form = this.widgetFormService.initWidgetForm(this.locales);
  }

  get localesForm(): FormArray {
    return this.form.get('locales') as FormArray;
  }

  getFlag(locale) {
    switch (locale) {
      case 'en':
        locale = 'gb';
        break;
      default:
        break;
    }
    return 'flag-icon-' + locale;
  }

  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.value;
    const locales = formData.locales;
    delete formData.locales;
    formData.name = {};
    formData.description = {};

    // convert locales to name and description object
    if (locales && locales.length) {
      // locale: "en", name: "Table Widget", description: "table table table table table table"
      for (const locale of locales) {
        if (locale.locale && locale.name) {
          formData.name[locale.locale] = locale.name;
          formData.description[locale.locale] = locale.description?.replace(/'/g, "''");
        }
      }
    }

    // call api
    if (this.editMode) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  }

  private create(data: SettingWidget) {
    this.subscribers.CREATE_WIDGET = this.widgetsService.createWidget(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(data: SettingWidget) {
    this.subscribers.UPDATE_WIDGET = this.widgetsService.updateWidget(this.formData.id, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.DELETE_WIDGET = this.widgetsService.deleteWidget(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('WIDGET_CATEGORY');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }
}
