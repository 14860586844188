import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SharedService } from '@shared/shared.service';
import { LiveRequestsService } from '@widgets/live-requests/services/live-requests.service';
import { DeviceDetails } from '../models/device-details';

@Component({
  selector: 'sc-ceos-automation-trigger-form',
  templateUrl: 'ceos-automation-trigger-form.component.html',
})
export class CeosAutomationTriggerFormComponent implements OnInit, OnDestroy {
  errorMessage: string;
  isSubmitting: boolean;
  isSubmitSuccess: boolean;
  commandAcceptedAt: any;
  actions: SelectItem[] = [];
  form: FormGroup;
  automation: DeviceDetails;
  getLiveDataCount: number;

  private intervals: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};

  constructor(
    private config: DynamicDialogConfig,
    private dialog: DynamicDialogRef,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private liveRequestsService: LiveRequestsService
  ) {}

  ngOnInit() {
    if (this.config && this.config.data) {
      if (this.config.data.automation) {
        this.automation = this.config.data.automation;
      }
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal() {
    this.dialog.close();
  }

  private initForm() {
    this.actions = [{ label: 'Pull latest configuration from cloud', value: 'pullConfig' }];

    this.form = this.formBuilder.group({
      apiKey: [null, Validators.required],
      automationId: [null, Validators.required],
      action: ['pullConfig', Validators.required],
    });

    if (this.automation) {
      this.form.patchValue({
        apiKey: this.automation.apiKey,
        automationId: this.automation.id,
      });
    }
  }

  private stopGetLiveData() {
    if (this.intervals.getLiveData) {
      clearInterval(this.intervals.getLiveData);
      this.intervals.getLiveData = null;
    }
  }

  private getLiveResult(sessionId: string) {
    this.subscribers.getLiveData = this.liveRequestsService.getLiveRequestResult(sessionId).subscribe((result: any) => {
      if (result && result.data && (result.data.error || result.data.value)) {
        // stop get live result because value or error exists
        this.stopGetLiveData();

        // get result or error
        if (result.data.error) {
          this.errorMessage = result.data.error;
        } else {
          this.commandAcceptedAt = this.sharedService.dateFormat(new Date());
          this.isSubmitSuccess = true;
        }
        this.isSubmitting = false;
      } else {
        this.getLiveDataCount += 1;

        if (this.getLiveDataCount >= 15) {
          // stop get live result because no reponse for too long
          this.stopGetLiveData();
          this.errorMessage = 'timeout';
          this.isSubmitting = false;
        }
      }
    });
  }

  sendLiveRequest() {
    if (this.form.invalid) {
      return;
    }

    const options = this.form.value;

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;
    this.isSubmitSuccess = false;
    this.commandAcceptedAt = null;

    this.subscribers.triggeringCommand = this.liveRequestsService.sendLiveRequest(options).subscribe(
      (result: any) => {
        this.getLiveDataCount = 0;
        this.intervals.getLiveData = setInterval(() => {
          this.getLiveResult(result.data.sessionId);
        }, 2000);
      },
      (error: any) => {
        // display error message and unlock the form
        this.errorMessage = 'send request failed';
        this.isSubmitting = false;
        this.isSubmitSuccess = false;
      }
    );
  }
}
