import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { TrashTableComponent } from './trash-table/trash-table.component';

import { TrashService } from './services/trash.service';

@NgModule({
  imports: [SharedModule],
  exports: [TrashTableComponent],
  declarations: [TrashTableComponent],
  entryComponents: [TrashTableComponent],
  providers: [TrashService]
})
export class TrashModule {}
