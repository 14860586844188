export const RULE_STRUCTURE = {
  absolute_datetime_patterns: [
    {
      id: 'time',
      label: 'Time',
      pattern: 'HH:ii',
      value: {
        type: 'timepicker',
      },
    },
    {
      id: 'date',
      label: 'Date',
      pattern: 'dd.mm.YYYY',
      value: {
        type: 'datepicker',
      },
    },
    {
      id: 'datetime',
      label: 'Date & time',
      pattern: 'dd.mm.YYYY HH:ii',
      value: {
        type: 'datetimepicker',
      },
    },
    {
      id: 'minute',
      label: 'Minute',
      pattern: 'ii',
      value: {
        type: 'numeric',
        min: 0,
        max: 59,
      },
    },
    {
      id: 'hour',
      label: 'Hour',
      pattern: 'HH',
      value: {
        type: 'numeric',
        min: 0,
        max: 23,
      },
    },
    {
      id: 'day',
      label: 'Day',
      pattern: 'dd',
      value: {
        type: 'numeric',
        min: 0,
        max: 31,
      },
    },
    {
      id: 'weekday',
      label: 'Day of the week',
      pattern: 'HH',
      value: {
        type: 'select',
        options: [
          { value: 'mon', label: 'Monday' },
          { value: 'tue', label: 'Tueday' },
          { value: 'wed', label: 'Wednesday' },
          { value: 'thu', label: 'Thursday' },
          { value: 'fri', label: 'Friday' },
          { value: 'sat', label: 'Saturday' },
          { value: 'sun', label: 'Sunday' },
        ],
      },
    },
    {
      id: 'last_day_of_the_month',
      label: 'Last day of the month',
    },
    {
      id: 'day_month',
      label: 'Day & month',
      pattern: 'dd.mm.',
      value: {
        type: 'datepicker',
      },
    },
    {
      id: 'year',
      label: 'Year',
      pattern: 'YYYY',
      value: {
        type: 'numeric',
        maxlength: 4,
      },
    },
    // {
    //   id: 'scheduler',
    //   label: 'Scheduler',
    // },
  ],
  operands: [
    {
      id: 'device_value',
      label: 'Device value',
      display_left: true,
      display_right: true,
      template: {
        device_id: {
          value: null,
        },
      },
      modifiers: {
        limit: 5,
        compatible: [
          {
            type: 'numeric', // numeric input
            operators: ['+', '-'],
          },
          {
            type: 'custom_function', // select with functions
            return_values: ['numeric'], // show only custom functions with numeric return values
            operators: ['+', '-', '*', '/'],
          },
        ],
      },
      compatible_right_operands: [
        {
          id: 'device_value',
          operators: [
            {
              id: '==',
              params: 'all',
            },
          ],
        },
        {
          id: 'location',
        },
        {
          id: 'value',
        },
      ],
    },
    {
      id: 'device_value_changed_at',
      label: 'Device value changed at',
      display_left: true,
      display_right: true,
      template: {
        device_id: {
          value: null,
        },
      },
      compatible_right_operands: [
        {
          id: 'device_value_changed_at',
        },
        {
          id: 'location_property_value_changed_at',
        },
        {
          id: 'datetime_absolute',
        },
        {
          id: 'datetime_relative',
        },
      ],
    },
    {
      id: 'datetime_now',
      label: 'Now',
      display_left: true,
      display_right: false,
      compatible_right_operands: [
        {
          id: 'datetime_absolute',
          operators: [
            {
              id: '==',
            },
            {
              id: '!=',
            },
            {
              id: '>',
            },
            {
              id: '<',
            },
            {
              id: '>=',
            },
            {
              id: '<=',
            },
          ],
        },
      ],
    },
    {
      id: 'datetime_absolute',
      label: 'Absolute date',
      display_left: false,
      display_right: true,
      template: {
        pattern: '',
        value: '',
      },
    },
    {
      id: 'datetime_relative',
      label: 'Relative date',
      display_left: false,
      display_right: true,
      template: {
        mode: 'ago',
        value: 0, // always in seconds
        unit: 's', // s = seconds, m = minutes, h = hours
      },
    },
    {
      id: 'location_property_value',
      label: 'Location property value',
      display_left: true,
      display_right: true,
      template: {
        location_id: {
          value: null,
        },
        property: {
          value: null,
        },
      },
    },
    // TODO: all the other operands
  ],
  operators: [
    {
      id: '==',
      label: 'Equal',
      params: [
        {
          id: 'included_past',
          label: 'Included past',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
        {
          id: 'min_validity_period',
          label: 'Min. validity-period',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
      ],
    },
    {
      id: '!=',
      label: 'Not equal',
      params: [
        {
          id: 'included_past',
          label: 'Included past',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
        {
          id: 'min_validity_period',
          label: 'Min. validity-period',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
      ],
    },
    {
      id: '>',
      label: 'Greater',
      params: [
        {
          id: 'included_past',
          label: 'Included past',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
        {
          id: 'min_validity_period',
          label: 'Min. validity-period',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
      ],
    },
    {
      id: '<',
      label: 'Less',
      params: [
        {
          id: 'included_past',
          label: 'Included past',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
        {
          id: 'min_validity_period',
          label: 'Min. validity-period',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
      ],
    },
    {
      id: '>=',
      label: 'Greater or equal',
      params: [
        {
          id: 'included_past',
          label: 'Included past',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
        {
          id: 'min_validity_period',
          label: 'Min. validity-period',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
      ],
    },
    {
      id: '<=',
      label: 'Less or equal',
      params: [
        {
          id: 'included_past',
          label: 'Included past',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
        {
          id: 'min_validity_period',
          label: 'Min. validity-period',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
      ],
    },
    {
      id: 'changed_at_least_n_times',
      label: 'Changed at least n times',
      params: [
        {
          id: 'included_past',
          label: 'Included past',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
        {
          id: 'previous_rule_trigger_as_included_past',
          label: 'Previous rule trigger as included past',
          value: {
            value: 0,
            type: 'checkbox',
            true_value: 1,
            false_value: 0,
          },
        },
      ],
    },
    {
      id: 'changed_max_n_times',
      label: 'Changed max. n times',
      params: [
        {
          id: 'included_past',
          label: 'Included past',
          value: {
            value: 0,
            type: 'numeric',
            unit: 's',
          },
        },
        {
          id: 'previous_rule_trigger_as_included_past',
          label: 'Previous rule trigger as included past',
          value: {
            value: 0,
            type: 'checkbox',
            true_value: 1,
            false_value: 0,
          },
        },
      ],
    },
  ],
};
