<div class="floorplan">
  <sc-floor-planner
    *ngIf="selectedFloor"
    [floor]="selectedFloor"
    [zoomedLocation]="selectedLocation"
    [deviceModels]="deviceModels"
    [deviceProtocols]="deviceProtocols"
    [deviceTypes]="deviceTypes"
    [devices]="devices"
    [deviceEvents]="deviceEvents"
    [dhls]="latestDhls"
    [lhls]="latestLhls"
    [locationEvents]="locationEvents"
    [locationProperties]="locationProperties"
    [locationTypes]="locationTypes"
    [locations]="locations"
    [protocolControllers]="protocolControllers"
    [site]="selectedSite"
    (afterInit)="onAfterInit()"
    (eventLayerToggled)="onEventLayerToggled($event)"
    (valueChanged)="onValueChanged($event)"
    ></sc-floor-planner>
    <!-- (viewDeviceDetails)="onViewDeviceDetails($event)" -->
    <!-- (viewLocationDetails)="onViewLocationDetails($event)" -->
</div>


