import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';

import { Site } from '@widgets/sites/models/site';

import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-average-humidity',
  templateUrl: './average-humidity.component.html',
  styleUrls: ['./average-humidity.component.scss'],
})
export class AverageHumidityComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  fetchInterval: any;
  isFetching: boolean;
  isFirstTime: boolean;
  dataset: any;
  selectedSite: Site;
  widgetData: any;
  widgetName: string = '';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.WATCH_SITE = this.store.select(fromStore.getSelectedSite).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFirstTime = true;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchDataset() {
    if (!this.isFetching && this.selectedSite && this.selectedSite.id) {
      this.isFetching = true;
      this.subscribers.GET_DATASET = this.widgetService.getAverageHumidity(this.selectedSite.id).subscribe(
        (result: any) => {
          this.dataset = result.data;
          this.createWidgetData();
          this.isFetching = false;
          this.isFirstTime = false;
        },
        (error: any) => {
          this.isFetching = false;
        }
      );
    }
  }

  private createWidgetData() {
    this.widgetData = [
      {
        customClass: 'sc-average-info',
        label: 'LAST_MONTH',
        primaryText: this.dataset.last_month,
        secondaryText: '%',
        // size: 'small'
      },
      {
        customClass: 'sc-average-info',
        label: 'THIS_MONTH',
        primaryText: this.dataset.this_month,
        secondaryText: '%',
        // size: 'small'
      },
      {
        customClass: 'sc-average-info',
        label: 'LAST_X_DAYS',
        labelParams: { value: 7 },
        primaryText: this.dataset.last_7_days,
        secondaryText: '%',
        // size: 'small'
      },
      {
        customClass: 'sc-average-info',
        label: 'TODAY',
        primaryText: this.dataset.today,
        secondaryText: '%',
        // size: 'small'
      },
    ];
  }
}
