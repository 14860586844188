import { Component, OnInit, Output, EventEmitter } from '@angular/core';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'sc-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent implements OnInit {
  // closeIcon = faTimes;

  @Output()
  clicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
