import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocaleActionCellComponent } from './locale-action-cell/locale-action-cell.component';
import { LocaleFormComponent } from './locale-form/locale-form.component';
import { LocalesTableComponent } from './locales-table/locales-table.component';

import { LocalesService } from './services/locales.service';

const components: any[] = [LocaleActionCellComponent, LocaleFormComponent, LocalesTableComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...components],
  entryComponents: [...components],
  providers: [LocalesService],
})
export class LocalesModule {}
