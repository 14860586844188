import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { DeviceType } from '../models/device-type';
import { ModalConfig } from '@sc/modal/modal-config';

import { DeviceTypesService } from '../services/device-types.service';

import { DeviceTypeActionCellComponent } from '../device-type-action-cell/device-type-action-cell.component';
import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';

@Component({
  selector: 'sc-device-types-table',
  templateUrl: './device-types-table.component.html'
})
export class DeviceTypesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: DeviceType[];
  subscribers: { [key: string]: any };
  formConfig: ModalConfig;
  tableTitle: string;

  constructor(private deviceTypesService: DeviceTypesService) {}

  ngOnInit() {
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: DeviceTypeActionCellComponent
      }
    ];
    this.formConfig = {
      name: 'DeviceTypeForm',
      options: { modalTitle: 'DEVICE_TYPE_FORM_TITLE', fullScreen: true }
    };
    this.tableTitle = 'DEVICE_TYPES';
    this.subscribers = {};

    this.fetchDataset();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchDataset() {
    this.subscribers.DATASET = this.deviceTypesService
      .getDeviceTypes()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
