import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-sms-form-group',
  templateUrl: './sms-form-group.component.html',
})
export class SmsFormGroupComponent implements OnInit, OnChanges {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  optionData: { [key: string]: any };
  @Input()
  placeholders: string;

  @Output()
  removed = new EventEmitter();

  secondaryLabel: string;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.updateSecondaryLabel();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.placeholders) {
      this.updateSecondaryLabel();
    }
  }

  private updateSecondaryLabel() {
    if (this.placeholders && this.placeholders.length) {
      const placeholders = this.sharedService.getTranslation('PLACEHOLDERS');
      this.secondaryLabel = '(' + placeholders + ': ' + this.placeholders + ')';
    } else {
      this.secondaryLabel = '';
    }
  }
}
