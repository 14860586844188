import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FloorPlannerModule } from '@app/floor-planner/floor-planner.module';
import { FloorPlanComponent } from './floorplan.component';

@NgModule({
  imports: [SharedModule, FloorPlannerModule],
  declarations: [FloorPlanComponent],
  entryComponents: [FloorPlanComponent],
})
export class FloorPlanModule {}
