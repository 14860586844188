import { Component, OnInit, Input } from '@angular/core';
import { ModalConfig } from '@sc/modal/modal-config';

interface ActionItem {
  key: string;
  icon?: string;
  permission?: string;
  type: 'modal' | 'confirm' | 'button';
}

export interface ActionItemModal extends ActionItem {
  modalConfig: ModalConfig;
  onModalClose?: (data: any) => any;
}

export interface ActionItemConfirm extends ActionItem {
  onConfirm?: (data: any) => any;
  onReject?: (data: any) => any;
}

export interface ActionItemButton extends ActionItem {
  buttonConfig?: {
    data: any;
    [key: string]: any;
  };
  onClick: (data: any) => any;
}

@Component({
  selector: 'sc-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent implements OnInit {
  @Input()
  actions: ActionItemModal[] | ActionItemConfirm[] | ActionItemButton[];

  @Input()
  buttonIcon: string = 'fa fa-ellipsis-v';

  @Input()
  data: any;

  constructor() {}

  ngOnInit() {}

  onClick(action: ActionItemButton) {
    // on button clicked
    if (action && action.onClick) {
      const data = (action.buttonConfig && action.buttonConfig.data) || this.data;
      action.onClick({ data });
    }
  }

  onConfirm(event: any, action: ActionItemConfirm) {
    // on confirm trigger confirm clicked
    if (action && action.onConfirm) {
      action.onConfirm({ ...event });
    }
  }

  onReject(event: any, action: ActionItemConfirm) {
    // on confirm trigger reject clicked
    if (action && action.onReject) {
      action.onReject({ ...event });
    }
  }

  onModalClose(event: any, action: ActionItemModal) {
    // on modal trigger clicked
    if (action && action.onModalClose) {
      action.onModalClose({ ...event });
    }
  }
}
