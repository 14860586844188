import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SharedService } from '@shared/shared.service';

import {
  DeviceHistoryLogRequest,
  DeviceHistoryLogSummaryRequest,
  DeviceHistoryLogCreate,
} from '../models/device-history-log-request';

import { config } from '@app/config';

@Injectable()
export class DeviceHistoryLogsService {
  apiUrl = config().apiUrl + '/devices';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  /**
   * Get Device History Logs / Latest
   * @param options
   */
  getHistoryLogs(options: DeviceHistoryLogRequest) {
    let queryParams: any = [];

    for (const key in options.filter) {
      if (options.filter.hasOwnProperty(key) && key !== 'deviceId') {
        queryParams.push(`${key}=${options.filter[key]}`);
      }
    }

    if (options.fields) {
      queryParams.push(`fields=${options.fields}`);
    }

    if (!isNaN(options.limit)) {
      queryParams.push(`limit=${options.limit}`);
    } else {
      queryParams.push(`limit=500`);
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';

    const apiUrl =
      this.apiUrl +
      (options.deviceId ? '/' + options.deviceId : '') +
      '/history' +
      (options.latestLog ? '/latest' : '') +
      queryParams;

    return this.httpClient.get(apiUrl);
  }

  /**
   * Get Device History Logs Summary
   * @param options
   */
  getHistoryLogsSummary(options: DeviceHistoryLogSummaryRequest) {
    let queryParams: any = [];

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        queryParams.push(`${key}=${options[key]}`);
      }
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';

    const apiUrl = `${this.apiUrl}/history/summary/${queryParams}`;

    return this.httpClient.get(apiUrl);
  }

  /**
   * Create Device History Log
   * @param options
   */
  addHistoryLog(options: DeviceHistoryLogCreate) {
    const apiUrl =
      `${config().apiUrl}/_automation/` +
      `${options.automationId}/webserver/` +
      (options.serviceProvider === 'pushy' ? 'pushy/' : '') +
      `send?apiKey=${options.apiKey}` +
      `&action=addDeviceHistoryLog` +
      `&deviceId=${options.deviceId}` +
      `&value=${options.value}`;

    return this.httpClient.post(apiUrl, {});
  }

  /**
   * Get Devices History Log of Automation
   * @param options
   */
  getAutomationHistoryLogs(automationId: number, options: any) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(`${this.apiUrl}/history/automation/${automationId}${queryParams}`);
  }

  getDeviceTypeIcon(key: string, value: any, unicode = false): string {
    switch (key) {
      case 'acf': {
        return unicode ? '\uf863' : 'fas fa-fan';
      }
      case 'acm':
      case 'acs': {
        return unicode ? '\uf011' : 'fas fa-power-off';
      }
      case 'hvacsph':
      case 'acsp': {
        return unicode ? '\uf05b' : 'fas fa-crosshairs';
      }
      case 'bl': {
        return unicode ? '\uf242' : 'fas fa-battery-half';
      }
      case 'bs': {
        return unicode ? '\uf185' : 'fas fa-sun';
      }
      case 'vs':
      case 'ws': {
        return unicode ? '\uf0e7' : 'fas fa-bolt';
      }
      case 'lc': {
        return unicode ? '\uf0eb' : 'fas fa-lightbulb';
      }
      case 'cm': {
        if (value === 'o' || value === true || value === 1) {
          return unicode ? '\uf133' : 'fas fa-calendar';
        } else {
          return unicode ? '\uf273' : 'fas fa-calendar-times';
        }
      }
      case 'sdl': {
        if (value === 'y' || value === true || value === 1) {
          return unicode ? '\uf023' : 'fas fa-lock';
        } else {
          return unicode ? '\uf3c1' : 'fas fa-lock-open';
        }
      }
      case 'osd': {
        if (value === 'o' || value === true || value === 1) {
          return unicode ? '\uf52b' : 'fas fa-door-open';
        } else {
          return unicode ? '\uf52a' : 'fas fa-door-closed';
        }
      }
      case 'hs': {
        return unicode ? '\uf043' : 'fas fa-tint';
      }
      case 'ms': {
        if (value === 'y' || value === true || value === 1) {
          return unicode ? '\uf007' : 'fas fa-user';
        } else {
          return unicode ? '\uf235' : 'fas fa-user-times';
        }
      }
      case 'ts': {
        return unicode ? '\uf2c9' : 'fas fa-thermometer-half';
      }
      case 'osw': {
        if (value === 'o' || value === true || value === 1) {
          return unicode ? '\uf111' : 'fas fa-circle';
        } else {
          return unicode ? '\uf057' : 'fas fa-times-circle';
        }
      }
      case 's':
      case 'sw':
      case 'sm': {
        if (value === 'o' || value === true || value === 1) {
          return unicode ? '\uf205' : 'fas fa-toggle-on';
        } else {
          return unicode ? '\uf204' : 'fas fa-toggle-off';
        }
      }
      case 'dim': {
        return unicode ? '\uf042' : 'fas fa-adjust';
      }
      default: {
        return null;
      }
    }
  }
}
