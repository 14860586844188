import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'sc-tree-select-2',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelect2Component implements OnInit, OnChanges {
  @Input()
  options: TreeNode[];
  @Input()
  placeholder: string;
  @Input()
  label: string;
  @Input()
  showLabel: boolean;
  @Input()
  description: string;
  @Input()
  warning: string;
  @Input()
  disabled: boolean;
  @Input()
  selectedValue: any;

  @Output()
  selectedValueChange = new EventEmitter<any>();

  inputId: string;
  displayItem: string;
  selected: TreeNode;

  private desiredTooltip: string;

  constructor() {}
  nodeTooltipOver(parameter) {
    if (parameter) {
      this.desiredTooltip = 'your tooltip';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options || changes.selectedValue) {
      this.updateDisplayItems();
    }
  }

  ngOnInit() {
    this.showLabel = this.showLabel === true;
    this.inputId = 'sc-tree-select-2-' + new Date().getTime();
    this.updateDisplayItems();
  }

  openPanel(menuPanel, event) {
    if (!this.disabled) {
      menuPanel.toggle(event);
    }
  }

  nodeSelect(menuPanel, event) {
    menuPanel.hide();

    if (this.selected) {
      this.displayItem = this.selected.label;
      this.selectedValue = this.selected.data;
      this.selectedValueChange.emit(this.selectedValue);
    }
  }

  private updateDisplayItems() {
    this.displayItem = null;
    if (this.selectedValue) {
      this.getOptionFromValue(this.options, this.selectedValue, (option) => {
        if (option) {
          this.selected = option;
          this.displayItem = option.label;
        }
      });
    }
  }

  private getOptionFromValue(options, value, callback) {
    if (options && options.length) {
      for (const item of options) {
        if (item.data && item.data === value) {
          return callback(item);
        }

        if (item.children && item.children.length) {
          this.getOptionFromValue(item.children, value, callback);
        }
      }
    }
  }
}
