import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SiteType } from '../models/site-type';
import { SiteTypesService } from '../services/site-types.service';
import { SiteTypeFormService } from '../services/site-type-form.service';
import { SharedService } from '@shared/shared.service';
import { CeosRuleTemplatesService } from '@widgets/rule-templates/services/ceos-rule-templates.service';
import { SettingWidgetsService } from '@widgets/setting-widgets/services/setting-widgets.service';
// import { WidgetCategoriesService } from '@widgets/widget-categories/services/widget-categories.service';
import { SelectItem, TreeNode } from 'primeng/api';
// import { combineLatest } from 'rxjs';
// import { map } from 'rxjs/operators';

@Component({
  selector: 'sc-site-type-form',
  templateUrl: './site-type-form.component.html',
  styleUrls: ['./site-type-form.component.scss'],
})
export class SiteTypeFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  localesForm: FormArray;
  formData: SiteType;
  isSubmitting = false;
  selectItems: { [key: string]: (SelectItem | TreeNode)[] } = {};

  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private siteTypesService: SiteTypesService,
    private siteTypeFormService: SiteTypeFormService,
    private ceosRuleTemplatesService: CeosRuleTemplatesService,
    private settingWidgetsService: SettingWidgetsService
  ) {}
  // private widgetCategoriesService: WidgetCategoriesService

  ngOnInit(): void {
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchFormData(this.data.id);
    }

    this.fetchCeosRuleTemplates();
    this.fetchWidgets();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchFormData(id: number) {
    this.fetchingState++;
    this.subscribers.fetchFormData = this.siteTypesService.getSiteType(id).subscribe((result: any) => {
      this.formData = result.data;
      this.formData.ruleTemplates = this.sharedService.tryParseJSON(<string>this.formData.ruleTemplates);
      this.formData.widgets = this.sharedService.tryParseJSON(<string>this.formData.widgets);
      this.form.patchValue(this.formData);
      this.siteTypeFormService.patchLocaleFormArray(this.form, this.formData);
      this.fetchingState--;
    });
  }

  private fetchCeosRuleTemplates() {
    this.fetchingState++;
    this.subscribers.fetchCeosRuleTemplates = this.ceosRuleTemplatesService
      .getCeosRuleTemplates()
      .subscribe((result: any) => {
        this.selectItems.ruleTemplates = this.sharedService.createSelectItems(
          result.data.filter((item) => item.is_active && !item.is_deleted).sort((a, b) => a.name.localeCompare(b.name))
        );
        // console.log(this.selectItems);
        this.fetchingState--;
      });
  }

  private fetchWidgets() {
    this.fetchingState++;
    // this.subscribers.fetchWidgets = combineLatest([
    //   this.settingWidgetsService.getWidgets(),
    //   this.widgetCategoriesService.getWidgetCategories(),
    // ])
    //   .pipe(
    //     map((data: any) => {
    //       return {
    //         widgets: data[0].data.filter((item) => item.isActive && !item.isDeleted),
    //         categories: data[1].data.filter((item) => item.isActive && !item.isDeleted),
    //       };
    //     })
    //   )
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.createWidgetsSelectItem(data);
    //     this.fetchingState--;
    //   });

    this.subscribers.fetchWidgets = this.settingWidgetsService.getWidgets().subscribe((result: any) => {
      this.selectItems.widgets = this.sharedService.createSelectItems(
        result.data
          .filter((item) => item.isActive && !item.isDeleted && item.type === 'dashboard')
          .map((item) => {
            const name = this.sharedService.getLocaleWidgetName(item);
            item.name = name;
            return item;
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      );
      // console.log(this.selectItems);
      this.fetchingState--;
    });
  }

  // private createWidgetsSelectItem(data: any) {
  //   console.log('createWidgetsSelectItem:', data);
  //   if (!data.widgets || !data.categories) {
  //     return;
  //   }

  //   const locale = this.sharedService.userLocale;
  //   // create widget categories
  //   const categoriedWidgets = data.categories.reduce((prev, curr) => {
  //     const name = typeof curr.name === 'string' ? JSON.parse(curr.name) : curr.name;
  //     prev[curr.id] = {
  //       label: name ? name[locale] || name.en : curr.key,
  //       items: [],
  //     };
  //     return prev;
  //   }, {});

  //   // add widget to category
  //   for (const w of data.widgets) {
  //     const category = w.categories && w.categories[0];

  //     if (category && categoriedWidgets[category]) {
  //       const name = typeof w.name === 'string' ? JSON.parse(w.name) : w.name;

  //       categoriedWidgets[category].items.push({
  //         label: name ? name[locale] || name.en : w.key,
  //         value: w.id,
  //       });
  //     }
  //   }
  //   console.log('categoriedWidgets:', categoriedWidgets);
  //   this.selectItems.widgets = Object.keys(categoriedWidgets).map((id) => {
  //     const category = categoriedWidgets[id];
  //     // sort by widget name
  //     category.items = category.items.sort((a, b) => a.name.localeCompare(b.name));
  //     return category;
  //   });
  // }

  getFlag(locale) {
    return this.sharedService.getFlagClassName(locale);
  }

  private initForm() {
    const locales = this.sharedService.sourceData.locales;
    this.form = this.siteTypeFormService.initSiteTypeForm(locales);
    this.localesForm = this.form.get('locales') as FormArray;
  }

  // API CALL
  submit() {
    if (this.form.invalid) {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
      return;
    }

    // clear error message
    this.errorMessage = null;
    this.isSubmitting = true;

    const formData = this.form.value;
    const locales = formData.locales;
    delete formData.locales;
    formData.name = {};
    // formData.description = {};

    // convert locales to name object
    if (locales && locales.length) {
      for (const locale of locales) {
        if (locale.locale && locale.name) {
          formData.name[locale.locale] = locale.name;
          // formData.description[locale.locale] = locale.description;
        }
      }
    }

    // call api
    if (this.editMode) {
      this.update(formData);
    } else {
      this.create(formData);
    }
  }

  private create(formData) {
    this.subscribers.create = this.siteTypesService.createSiteType(formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(formData) {
    this.subscribers.update = this.siteTypesService.updateSiteType(this.formData.id, formData).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.siteTypesService.deleteSiteType(this.formData.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const title = this.sharedService.getTranslation('SITE_TYPE');
    const text = this.sharedService.getTranslation(message);
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }
}
