<div
  class="sc-flex justify-content-between transparent mb-3 align-items-center"
  style="height: 30px"
>
  <div>
    {{ tableTitle | translate }}
  </div>

  <button
    type="button"
    pButton
    class="ui-button-success"
    (click)="openCreateForm()"
    [label]="'CREATE' | translate"
  ></button>
</div>

<div style="height: calc(100% - 50px)">
  <sc-table
    [columns]="columns"
    [config]="config"
    [dataset]="dataset"
    (reloadData)="fetchDataset()"
    (afterInit)="onAfterTableInit($event)"
    [hideHeader]="true"
  ></sc-table>
</div>
