import { Component, OnInit, Input } from '@angular/core';
import { GridOptions } from 'ag-grid';

@Component({
  selector: 'sc-data-display-button',
  templateUrl: './data-display-button.component.html',
  styleUrls: ['./data-display-button.component.scss'],
})
export class DataDisplayComponent implements OnInit {
  @Input()
  gridOptions: GridOptions;

  private currentFilters = {
    ACTIVE: true,
    INACTIVE: true,
    DELETED: false,
  };

  filters: { key: string; value: boolean }[] = [];

  constructor() {}

  ngOnInit() {
    this.gridOptions.isExternalFilterPresent = () => true;
    this.gridOptions.doesExternalFilterPass = (node) => {
      const data = node.data;

      if (this.currentFilters.ACTIVE === true) {
        if (typeof data.isActive !== 'undefined' && (data.isActive === 1 || data.isActive === true)) {
          return true;
        } else if (typeof data.is_active !== 'undefined' && (data.is_active === 1 || data.is_active === true)) {
          return true;
        }
      }

      if (this.currentFilters.DELETED === true) {
        if (typeof data.isDeleted !== 'undefined' && (data.isDeleted === 1 || data.isDeleted === true)) {
          return true;
        } else if (typeof data.is_deleted !== 'undefined' && (data.is_deleted === 1 || data.is_deleted === true)) {
          return true;
        }
      }

      if (this.currentFilters.INACTIVE === true) {
        if (
          typeof data.isActive !== 'undefined' &&
          (data.isActive === 0 || data.isActive === false) &&
          typeof data.isDeleted !== 'undefined' &&
          (data.isDeleted === 0 || data.isDeleted === false)
        ) {
          return true;
        } else if (
          typeof data.is_active !== 'undefined' &&
          (data.is_active === 0 || data.is_active === false) &&
          typeof data.is_deleted !== 'undefined' &&
          (data.is_deleted === 0 || data.is_deleted === false)
        ) {
          return true;
        }
      }

      return false;
    };

    this.setFilters();
  }

  /**
   * Filter change handler
   * @param key
   */
  toggle(key) {
    this.currentFilters[key] = !this.currentFilters[key];
    this.updateDisplayData();
    this.setFilters();
  }

  private setFilters() {
    this.filters = Object.keys(this.currentFilters).map((key) => ({
      key,
      value: this.currentFilters[key],
    }));
  }

  updateDisplayData() {
    if (!this.gridOptions) {
      return;
    }

    const filters: any = {};
    if (this.gridOptions.rowModelType === 'infinite') {
      // this is lazyload mode
      if (!this.currentFilters.ACTIVE && !this.currentFilters.INACTIVE && !this.currentFilters.DELETED) {
        // ignore when all uncheck
      } else {
        filters.isActive = {
          type: 'equals',
          filter: this.currentFilters.ACTIVE ? 1 : 0,
        };
        filters.isInActive = {
          type: 'equals',
          filter: this.currentFilters.INACTIVE ? 1 : 0,
        };
        filters.isDeleted = {
          type: 'equals',
          filter: this.currentFilters.DELETED ? 1 : 0,
        };
      }
    } else {
      // this is normal mode
      if (this.currentFilters.ACTIVE && !this.currentFilters.INACTIVE && !this.currentFilters.DELETED) {
        filters.isActive = { type: 'equals', filter: 1 };
      } else if (!this.currentFilters.ACTIVE && this.currentFilters.INACTIVE && !this.currentFilters.DELETED) {
        filters.isActive = { type: 'equals', filter: 0 };
      } else if (!this.currentFilters.ACTIVE && !this.currentFilters.INACTIVE && this.currentFilters.DELETED) {
        filters.isDeleted = { type: 'equals', filter: 1 };
      }
    }

    this.gridOptions.api.setFilterModel(filters);
  }
}
