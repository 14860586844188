import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RolesTableComponent } from './roles-table/roles-table.component';
import { RoleActionCellComponent } from './role-action-cell/role-action-cell.component';
import { EndUserRoleFormComponent } from './role-form/role-form.component';

import { EndUserRoleService } from './services/end-user-role.service';

const components: any[] = [RolesTableComponent, RoleActionCellComponent, EndUserRoleFormComponent];

@NgModule({
  imports: [SharedModule],
  declarations: [...components],
  entryComponents: [...components],
  providers: [EndUserRoleService],
})
export class EndUsersModule {}
