<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <!-- HEADER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <p-toggleButton
          formControlName="is_active"
          offIcon="fa fa-square-o"
          offLabel="{{ 'ACTIVE' | translate }}"
          onIcon="fa fa-check-square-o"
          onLabel="{{ 'ACTIVE' | translate }}"
        ></p-toggleButton>
      </div>
    </div>

    <!-- BODY -->
    <div class="ui-g">
      <!-- KEY FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="key"
          [form]="form"
          label="{{ 'KEY' | translate }}"
        ></sc-text-input>
      </div>

      <!-- NAME FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="name"
          [form]="form"
          label="{{ 'NAME' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-12 ui-g-nopad">
        <!-- EXECUTE_INSTANTLY FIELD -->
        <div class="ui-g-6 ui-lg-4 mt-3">
          <sc-checkbox-input
            controlName="execute_instantly"
            [form]="form"
            label="{{ 'EXECUTE_INSTANTLY' | translate }}"
          ></sc-checkbox-input>
        </div>

        <!-- RECURRING FIELD -->
        <div class="ui-g-6 ui-lg-4 mt-3">
          <sc-checkbox-input
            controlName="recurring"
            [form]="form"
            label="{{ 'RECURRING' | translate }}"
          ></sc-checkbox-input>
        </div>
      </div>

      <div class="ui-g-12 ui-g-nopad">
        <!-- EXCLUSIVE_SITES FIELD -->
        <div class="ui-g-6 ui-lg-8">
          <sc-dropdown
            controlName="site_ids"
            [form]="form"
            label="{{ 'EXCLUSIVE_SITES' | translate }}"
            [options]="selectItems['sites']"
            [multiSelect]="true"
          ></sc-dropdown>
        </div>

        <!-- IS_PUBLIC FIELD -->
        <div class="ui-g-6 ui-lg-4 mt-3">
          <sc-checkbox-input
            controlName="is_public"
            [form]="form"
            label="{{ 'IS_PUBLIC' | translate }}"
          ></sc-checkbox-input>
        </div>
      </div>

      <!-- PARAMETERS -->
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-10 ui-g-nopad">
          <h4>{{ 'PARAMETERS' | translate }}</h4>
        </div>
        <div class="ui-g-2 text-right">
          <button
            pButton
            type="button"
            class="ui-button-success"
            icon="fa fa-plus"
            pTooltip="{{ 'ADD' | translate }} {{ 'PARAMETER' | translate }}"
            (click)="addParameter()">
          </button>
        </div>
      </div>

      <div formArrayName="parameters" class="ui-g-12">
        <div *ngFor="let ctrl of parametersForm.controls; let i=index" [formGroupName]="i">
          <sc-device-task-template-parameter-form
            [form]="ctrl"
            [formId]="i"
            (removed)="removeParameter(i)"
            [selectItems]="selectItems"
          ></sc-device-task-template-parameter-form>
        </div>
      </div>

      <!-- ERROR MESSAGE -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.is_deleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-12 ui-md-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.is_deleted || formData?.is_active"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</ng-template>
