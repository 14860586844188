import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from '@app/config';
import { SharedService } from '@shared/shared.service';

interface AutomationLogsRequest {
  siteId?: number;
  locationId?: number;
  deviceId?: number;
  from?: string;
  to?: string;
  devices?: number[];
  levels?: string[];
  tag?: string;
  subtag?: string;
}

interface AutomationLogsLazyRequest {
  siteId?: number;
  locationId?: number;
  devices?: number[] | string;
  levels?: string[] | string;
  tag?: string;
  subtag?: string;
  daterange?: { from: string; to: string };
  format?: number;
  fields?: string[] | string;
  filter?: any;
  limit?: number;
  offset?: number;
  sort?: any[];
}

@Injectable()
export class AutomationLogsService {
  private apiUrl = config().apiUrl + '/automationLogs';

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) {}

  /**
   * Get Automation Logs
   * @param options
   */
  getAutomationLogs(options: AutomationLogsRequest) {
    const queryParams = this.sharedService.urlQueryParamsCreator(
      options,
      false
    );
    const apiUrl = `${this.apiUrl}/${queryParams}`;
    return this.httpClient.get(apiUrl);
  }

  /**
   * Get Automation Logs (lazylaod)
   * @param options
   */
  getAutomationLogsLazy(options: AutomationLogsLazyRequest) {
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    const apiUrl = `${this.apiUrl}/lazy${queryParams}`;
    return this.httpClient.get(apiUrl);
  }
}
