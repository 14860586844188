<div *ngIf="!isFetching; else loadingIndicator" style="padding: 0 35px 8px">
  <p-table [value]="dataset">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ "NAME" | translate }}</th>
        <th>{{ "VALUE" | translate }}</th>
        <th>{{ "LOGGED_AT" | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr>
        <td>{{ data.description }}</td>
        <td>{{ getDisplayValue(data) }}</td>
        <td>{{ getDisplayLoggedAt(data) }}</td>
      </tr>
    </ng-template>
  </p-table>

  <div *ngIf="data.length < 1">
    {{ "NO_DATA" | translate }}
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>
