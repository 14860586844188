import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { DeviceDetails } from '@widgets/devices/models/device-details';
import { Site } from '@widgets/sites/models/site';

import { AutomationLogsService } from '@widgets/automation-logs/services/automation-logs.service';
import { SharedService } from '@shared/shared.service';

import * as moment from 'moment-timezone';

@Component({
  selector: 'sc-device-details-header',
  templateUrl: './device-details-header.component.html',
  styleUrls: ['./device-details-header.component.scss']
})
export class DeviceDetailsHeaderComponent implements OnInit, OnDestroy {
  private isFetchingAHL = false;
  private lastAHL: any;
  private intervals: { [key: string]: any } = {};
  private selectedDevice: DeviceDetails;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private domSanitizer: DomSanitizer,
    private store: Store<fromStore.State>,
    private automationLogsService: AutomationLogsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // get site details
    this.selectedSite = this.sharedService.selectedSite;
    // get device details
    this.subscribers.DEVICE_DETAILS = this.store
      .select(fromStore.getDeviceDetials)
      .subscribe(result => {
        if (result) {
          this.selectedDevice = result;

          clearInterval(this.intervals.GET_LAST_SEEN);
          this.intervals.GET_LAST_SEEN = setInterval(() => {
            this.getLastAHL();
          }, 60000);
          this.getLastAHL();
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  private getLastAHL() {
    if (this.isFetchingAHL) {
      return;
    }
    this.isFetchingAHL = true;
    const options: any = {
      siteId: this.selectedSite.id,
      devices: this.selectedDevice.id,
      limit: 1
    };
    this.subscribers.GET_AHL = this.automationLogsService
      .getAutomationLogsLazy(options)
      .subscribe((result: any) => {
        this.lastAHL = result.data && result.data[0];
        this.isFetchingAHL = false;
      });
  }

  get deviceId() {
    if (this.selectedDevice && this.selectedDevice.id) {
      return this.selectedDevice.id;
    }
    return;
  }

  get deviceName() {
    if (this.selectedDevice && this.selectedDevice.description) {
      return this.selectedDevice.description;
    }
    return;
  }

  get anydeskLink() {
    if (this.selectedDevice && this.selectedDevice.anydeskId) {
      return this.domSanitizer.bypassSecurityTrustUrl(
        'anydesk:' + this.selectedDevice.anydeskId
      );
    }
    return;
  }

  get lastSeen() {
    if (this.lastAHL && this.lastAHL.syncAt) {
      return moment(this.lastAHL.syncAt).fromNow();
    }
    return;
  }
}
