<div [formGroup]="group">
  <div class="ui-g">
    <div class="ui-g-12">
      <!-- LOCALE FIELD -->
      <div class="ui-g-12">
        <sc-dropdown
          controlName="locale"
          [form]="group"
          label="{{ 'LANGUAGE' | translate }}"
          [options]="optionData['locales']"
        ></sc-dropdown>
      </div>

      <!-- SUBJECT FIELD -->
      <div class="ui-g-12">
        <sc-text-input
          controlName="subject"
          [form]="group"
          label="{{ 'SUBJECT' | translate }}"
        ></sc-text-input>
      </div>

      <!-- TEXT FIELD -->
      <div class="ui-g-12">
        <sc-textarea
          controlName="content"
          [form]="group"
          label="{{ 'CONTENT' | translate }}"
          [rows]="5"
          [secondaryLabel]="secondaryLabel"
        ></sc-textarea>
      </div>

      <!-- HTML FIELD -->
      <div class="ui-g-12">
        <label for="html_{{ groupId }}" class="sc-form-label">{{ 'HTML' | translate }}</label>
        <span *ngIf="placeholders" class="red-text" style="font-size: 12px;">
          {{ secondaryLabel }}
        </span>
        <p-editor formControlName="contentHtml" [style]="{'height':'320px'}">
          <p-header>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-link"></button>
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
            </span>
          </p-header>
        </p-editor>
      </div>

      <!-- IS_ACTIVE FIELD -->
      <div class="ui-g-12">
        <sc-checkbox-input
          controlName="isActive"
          [form]="group"
          label="{{ 'ACTIVE' | translate }}"
        ></sc-checkbox-input>
      </div>

      <div class="ui-g-12">
        <button
          pButton
          type="button"
          class="ui-button-danger"
          label="{{ 'REMOVE' | translate }}"
          (click)="removed.emit()"
        ></button>
      </div>
    </div>
  </div>
</div>
