<div class="notifier-command container">
  <div
    class="notifier-command-body"
    *ngIf="commandForm"
    [formGroup]="commandForm"
  >
    <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
      <div class="p-field p-col-12 p-md-3" *ngIf="isTemplate">
        <label class="input-label" for="name">
          {{ "NAME" | translate }}
        </label>

        <input type="text" pInputText inputId="name" formControlName="name" />
      </div>

      <div class="p-field p-col-12 p-md-3">
        <label class="input-label" for="target">
          {{ "CCP_NOTIFICATION_TEMPLATE" | translate }}
        </label>

        <p-dropdown
          inputId="target"
          [options]="selectItems?.notificationTemplates"
          formControlName="target"
          placeholder="{{ 'CCP_COMMON_SELECT' | translate }} {{
            'CCP_NOTIFICATION_TEMPLATE' | translate
          }}"
          [filter]="true"
          appendTo="body"
        ></p-dropdown>

        <div *ngIf="isTemplate">
          <p-checkbox
            inputId="is_target_editable"
            [binary]="true"
            [formControl]="commandForm?.get('is_target_editable')"
            [label]="'EDITABLE' | translate"
          ></p-checkbox>
        </div>
      </div>
    </div>
  </div>

  <sc-command-options
    *ngIf="commandForm"
    [form]="commandForm"
    [isTemplate]="isTemplate"
  ></sc-command-options>
</div>
