import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataSources, SelectItems } from '../../models';
import { RulesService, UtilsService } from '../../services';

@Component({
  selector: 'sc-user-task-command',
  templateUrl: './user-task-command.component.html',
  styleUrls: ['./user-task-command.component.scss'],
})
export class UserTaskCommandComponent implements OnInit, OnDestroy {
  @Input()
  commandForm: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  variableForm: FormArray;

  private subscribers: any = {};

  constructor(private rulesService: RulesService, private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.commandForm.get('action').setValue('use_user_task_template');
    this.variableForm = this.commandForm.get('variables') as FormArray;

    if (!this.isTemplate) {
      this.initVariableForm();
    }
  }

  ngOnDestroy(): void {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private initVariableForm() {
    const formValue = this.commandForm.value;
    const userTaskTemplateIdx = formValue.target;

    if (userTaskTemplateIdx) {
      const userTaskTemplates = this.dataSources && this.dataSources.userTaskTemplates;
      if (userTaskTemplates && userTaskTemplates.length) {
        const template = userTaskTemplates.find((item) => item.idx === userTaskTemplateIdx);
        if (template && template.variables && (!formValue.variables || !formValue.variables.length)) {
          const variables = JSON.parse(template.variables);
          if (variables.length) {
            this.rulesService.setUserTaskVariablesForm(this.commandForm, variables);
            this.variableForm = this.commandForm.get('variables') as FormArray;
          }
        }
      }
    }
  }
}
