import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DeviceWizard } from '../device-wizard';

@Component({
  selector: 'sc-device-holder-setting',
  templateUrl: './device-holder-setting.component.html',
  styleUrls: ['./device-holder-setting.component.scss'],
})
export class DeviceHolderSettingComponent implements OnInit {
  @Input()
  data: DeviceWizard;
  @Input()
  form: FormGroup;
  @Input()
  productImage: string;
  @Input()
  selectItems: { [prop: string]: SelectItem[] };

  showOmnisRequiredFields = false;

  constructor() {}

  ngOnInit() {
    if (this.data.deviceTypeKey === 'vdh' && this.data.model === 'Omnis') {
      this.showOmnisRequiredFields = true;
      this.form.get('installationHeight').setValidators(Validators.required);
      this.form.get('installationHeight').updateValueAndValidity();
    }
  }
}
