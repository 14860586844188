import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-site-chooser',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './site-chooser.component.html',
  styleUrls: ['./site-chooser.component.scss'],
})
export class SiteChooserComponent implements OnInit, OnDestroy {
  filteredSites: Site[];
  opened: boolean;
  searchForm = new FormControl();
  selectedSite: Site;
  showSearch = false;
  searchText: string;

  private sites: Site[];
  private subscribers: { [key: string]: any } = {};

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<fromStore.State>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.subscribers.sites = this.store.pipe(select(fromStore.getAllActiveSites)).subscribe((data) => {
      this.sites = data;
      this.showSearch = this.sites.length > 3 ? true : false;
      this.filterSite();
      this.changeDetectorRef.detectChanges();
    });

    this.subscribers.selectedSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((data) => {
      this.selectedSite = data;
      this.changeDetectorRef.detectChanges();
    });

    this.searchForm.valueChanges.subscribe((result: string) => {
      this.searchText = (result && result.toLowerCase()) || '';
      this.filterSite();
    });
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private filterSite() {
    if (this.sites && this.sites.length) {
      if (this.searchText) {
        this.filteredSites = this.sites.filter((site) => site.name.toLowerCase().includes(this.searchText));
      } else {
        this.filteredSites = this.sites;
      }
    } else {
      this.filteredSites = [];
    }
  }

  chooseSite(site: any) {
    this.store.dispatch(new fromStore.SelectSite(site.id));
  }
}
