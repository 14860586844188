import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { EventActionCellComponent } from './event-action-cell/event-action-cell.component';
import { EventFormComponent } from './event-form/event-form.component';
import { PushNotificationSchedulerFormComponent } from './event-form/push-notification-scheduler-form/push-notification-scheduler-form.component';
import { MultiLanguageFormComponent } from './event-form/multi-language-form/multi-language-form.component';
import { EventsTableComponent } from './events-table/events-table.component';
import { GuestResponseCellComponent } from './guest-response-cell/guest-response-cell.component';

import { EventFormsService } from './services/event-forms.service';
import { EventsService } from './services/events.service';

@NgModule({
  imports: [SharedModule],
  exports: [EventFormComponent, EventsTableComponent],
  declarations: [
    EventActionCellComponent,
    EventFormComponent,
    EventsTableComponent,
    GuestResponseCellComponent,
    PushNotificationSchedulerFormComponent,
    MultiLanguageFormComponent,
  ],
  entryComponents: [
    EventActionCellComponent,
    EventFormComponent,
    EventsTableComponent,
    GuestResponseCellComponent,
  ],
  providers: [EventFormsService, EventsService],
})
export class EventsModule {}
