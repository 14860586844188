<div class="ui-g" [ngStyle]="{ 'height.px': 50 }">
  <div class="ui-g-6">
    <h4 class="m-1">
      <i class="fa fa-trash"></i> {{ 'TRASH' | translate }}
    </h4>
  </div>
</div>

<div class="ui-g" [ngStyle]="{ 'height.px': 50 }">
  <div class="ui-g-6 ui-md-4">
    <p-dropdown
      [options]="dataTypes"
      placeholder="{{ 'SELECT' | translate }} {{ 'DATA_TYPE' | translate }}"
      filter="true"
      [style]="{'width':'100%'}"
      (onChange)="changeDataType($event)"
    ></p-dropdown>
  </div>

  <div class="ui-g-6 ui-md-8 text-right">
    <button
      pButton
      type="button"
      class="ui-button-danger"
      label="{{ 'DELETE' | translate }} {{ 'SELECTED' | translate }}"
      (click)="onClick('delete')"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-warning"
      label="{{ 'RESTORE' | translate }} {{ 'SELECTED' | translate }}"
      (click)="onClick('restore')"
    ></button>
  </div>
</div>

<ag-grid-angular
  #agGrid
  [ngStyle]="{ 'height.px': config.widgetHeight - 100, 'width': '100%' }"
  class="ag-theme-sc-clean"
  [gridOptions]="gridOptions"
></ag-grid-angular>
