import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { NotificationTemplate } from '../models/notification-template';

import { NotificationTemplatesService } from '../services/notification-templates.service';
import { NotificationTemplateFormService } from '../services/notification-template-form.service';
import { UserRolesService } from '@widgets/users/services/user-roles.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-notification-template-form',
  templateUrl: './notification-template-form.component.html',
  styleUrls: ['./notification-template-form.component.scss']
})
export class NotificationTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: NotificationTemplate;
  isSubmitting = false;
  isDeletingEmail = false;
  isDeletingGeneral = false;
  isDeletingSms = false;
  selectItems: { [key: string]: SelectItem[] };

  currentEmailTemplate = 0;
  currentGeneralTemplate = 0;
  currentSmsTemplate = 0;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private notificationTemplatesService: NotificationTemplatesService,
    private notificationTemplateFormService: NotificationTemplateFormService,
    private userRolesService: UserRolesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.selectItems = this.sharedService.selectItems;

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchNotificationTemplate(this.data.id);
    }

    this.fetchUserRoles();
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private fetchNotificationTemplate(id: number) {
    this.fetchingState++;
    this.subscribers.getNotificationTempalte = this.notificationTemplatesService
      .get(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.formValueUnescape(this.formData);
        this.form.patchValue(this.formData);

        this.notificationTemplateFormService.setEmailFormArray(
          this.form,
          this.formData.emailTemplates
        );
        this.notificationTemplateFormService.setGeneralFormArray(
          this.form,
          this.formData.generalTemplates
        );
        this.notificationTemplateFormService.setSmsFormArray(
          this.form,
          this.formData.smsTemplates
        );

        this.fetchingState--;
      });
  }

  private fetchUserRoles() {
    this.fetchingState++;
    this.subscribers.getUserRoles = this.userRolesService
      .get()
      .subscribe((result: any) => {
        this.selectItems.userRoles = this.sharedService.createSelectItems(
          result.data.filter(d => d.isActive)
        );
        this.fetchingState--;
      });
  }

  // INIT FORM
  private initForm() {
    this.form = this.notificationTemplateFormService.initNotificationTemplateFormGroup();

    this.subscribers.isPublic = this.form.get('isPublic').valueChanges.subscribe(value => {
      if (value) {
        this.form.get('siteIds').reset();
        this.form.get('siteIds').disable();
      } else {
        this.form.get('siteIds').enable();
      }
    });

    if (!this.editMode) {
      this.addGeneral();
      // this.addEmail();
      // this.addSms();
    }
  }

  flagIcon(locale) {
    if (locale) {
      locale = locale === 'en' ? 'gb' : locale;
      return 'flag-icon flag-icon-' + locale;
    }
    return '';
  }

  get placeholders(): string {
    if (this.form) {
      const placeholders = this.form.get('placeholders').value;
      if (placeholders) {
        return placeholders.join(', ');
      }
    }
    return '';
  }

  // EMAIL FORM
  get emailTemplates(): FormArray {
    return this.form.get('emailTemplates') as FormArray;
  }

  addEmail() {
    const control = this.form.get('emailTemplates') as FormArray;
    const form = this.notificationTemplateFormService.initEmailFormGroup();
    if (this.editMode) {
      form.patchValue({ notificationTemplateId: this.formData.id });
    } else {
      form.controls.notificationTemplateId.clearValidators();
      form.removeControl('notificationTemplateId');
    }
    control.push(form);
    this.currentEmailTemplate = control.length - 1;
  }

  removeEmail(i: number) {
    this.isDeletingEmail = true;
    const control = this.form.get('emailTemplates') as FormArray;
    control.removeAt(i);
    this.currentEmailTemplate = 0;
  }

  // GENERAL FORM
  get generalTemplates(): FormArray {
    return this.form.get('generalTemplates') as FormArray;
  }

  addGeneral() {
    const control = this.form.get('generalTemplates') as FormArray;
    const form = this.notificationTemplateFormService.initGeneralFormGroup();
    if (this.editMode) {
      form.patchValue({ notificationTemplateId: this.formData.id });
    } else {
      form.controls.notificationTemplateId.clearValidators();
      form.removeControl('notificationTemplateId');
    }
    control.push(form);
    this.currentGeneralTemplate = control.length - 1;
  }

  removeGeneral(i: number) {
    this.isDeletingGeneral = true;
    const control = this.form.get('generalTemplates') as FormArray;
    control.removeAt(i);
    this.currentGeneralTemplate = 0;
  }

  // SMS FORM
  get smsTemplates(): FormArray {
    return this.form.get('smsTemplates') as FormArray;
  }

  addSms() {
    const control = this.form.get('smsTemplates') as FormArray;
    const form = this.notificationTemplateFormService.initSmsFormGroup();
    if (this.editMode) {
      form.patchValue({ notificationTemplateId: this.formData.id });
    } else {
      form.controls.notificationTemplateId.clearValidators();
      form.removeControl('notificationTemplateId');
    }
    control.push(form);
    this.currentSmsTemplate = control.length - 1;
  }

  removeSms(i: number) {
    this.isDeletingSms = true;
    const control = this.form.get('smsTemplates') as FormArray;
    control.removeAt(i);
    this.currentSmsTemplate = 0;
  }

  private formValueEscape(formData) {
    if (formData.emailTemplates && formData.emailTemplates.length) {
      formData.emailTemplates = formData.emailTemplates.map(t => {
        t.subject = this.sharedService.stringEscape(t.subject);
        t.content = this.sharedService.stringEscape(t.content);
        t.contentHtml = this.sharedService.stringEscape(t.contentHtml);
        return t;
      });
    }
    if (formData.generalTemplates && formData.generalTemplates.length) {
      formData.generalTemplates = formData.generalTemplates.map(t => {
        t.subject = this.sharedService.stringEscape(t.subject);
        t.content = this.sharedService.stringEscape(t.content);
        return t;
      });
    }
    if (formData.smsTemplates && formData.smsTemplates.length) {
      formData.smsTemplates = formData.smsTemplates.map(t => {
        t.content = this.sharedService.stringEscape(t.content);
        return t;
      });
    }
    return formData;
  }

  private formValueUnescape(formData) {
    if (formData.emailTemplates && formData.emailTemplates.length) {
      formData.emailTemplates = formData.emailTemplates.map(t => {
        t.subject = this.sharedService.stringUnescape(t.subject);
        t.content = this.sharedService.stringUnescape(t.content);
        t.contentHtml = this.sharedService.stringUnescape(t.contentHtml);
        return t;
      });
    }

    if (formData.generalTemplates && formData.generalTemplates.length) {
      formData.generalTemplates = formData.generalTemplates.map(t => {
        t.subject = this.sharedService.stringUnescape(t.subject);
        t.content = this.sharedService.stringUnescape(t.content);
        return t;
      });
    }

    if (formData.smsTemplates && formData.smsTemplates.length) {
      formData.smsTemplates = formData.smsTemplates.map(t => {
        t.content = this.sharedService.stringUnescape(t.content);
        return t;
      });
    }

    if (formData.siteIds = formData.siteIds) {
      formData.siteIds = this.sharedService.jsonParse(this.formData.siteIds);
    }

    return formData;
  }

  // API CALL
  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      // call api
      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  private create() {
    const formData = this.formValueEscape(this.form.getRawValue());
    delete formData.id;
    this.subscribers.create = this.notificationTemplatesService
      .create(formData)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'CREATE_FAIL')
      });
  }

  private update() {
    const formData = this.formValueEscape(this.form.value);
    this.subscribers.update = this.notificationTemplatesService
      .update(this.formData.id, formData)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        error: this.apiCallError.bind(this, 'UPDATE_FAIL')
      });
  }

  delete() {
    this.subscribers.delete = this.notificationTemplatesService
      .delete(this.formData.id)
      .subscribe({
        next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        error: this.apiCallError.bind(this, 'DELETE_FAIL')
      });
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('NOTIFICATION_TEMPLATE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
