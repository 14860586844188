<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <!-- HEADER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-text-input
          *ngIf="editMode"
          controlName="id"
          [form]="form"
          label="{{ 'ID' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-6 text-right">
        <p-toggleButton
          formControlName="isActive"
          offIcon="fa fa-square-o"
          offLabel="{{ 'ACTIVE' | translate }}"
          onIcon="fa fa-check-square-o"
          onLabel="{{ 'ACTIVE' | translate }}"
        ></p-toggleButton>
      </div>
    </div>

    <!-- BODY -->
    <div class="ui-g">
      <!-- DATE_FROM FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-datetime-input
          controlName="dateFrom"
          [form]="form"
          label="{{ 'DATE_FROM' | translate }}"
          [showTime]="false"
        ></sc-datetime-input>
      </div>

      <!-- DATE_TO FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-datetime-input
          controlName="dateTo"
          [form]="form"
          label="{{ 'DATE_TO' | translate }}"
          [showTime]="false"
        ></sc-datetime-input>
      </div>

      <!-- TIME_FROM FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-datetime-input
          controlName="timeFrom"
          [form]="form"
          label="{{ 'TIME_FROM' | translate }}"
          [timeOnly]="true"
        ></sc-datetime-input>
      </div>

      <!-- TIME_TO FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-datetime-input
          controlName="timeTo"
          [form]="form"
          label="{{ 'TIME_TO' | translate }}"
          [timeOnly]="true"
        ></sc-datetime-input>
      </div>

      <!-- UNIT FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="unit"
          [form]="form"
          label="{{ 'ELECTRICITY_COSTS_UNIT' | translate }}"
          [options]="selectItems['units']"
        ></sc-dropdown>
      </div>

      <!-- UNIT_PRICE FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-number-input
          controlName="unitPrice"
          [form]="form"
          label="{{ 'ELECTRICITY_COSTS_UNIT_PRICE' | translate }}"
          [min]="0"
        ></sc-number-input>
      </div>

      <!-- CURRENCY FIELD -->
      <div class="ui-g-12 ui-md-6">
        <sc-dropdown
          controlName="currencyId"
          [form]="form"
          label="{{ 'CURRENCY' | translate }}"
          [options]="selectItems['currencies']"
        ></sc-dropdown>
      </div>

      <!-- ERROR MESSAGE -->
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.isDeleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div class="ui-g-12 ui-md-6 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.isDeleted || formData?.isActive"
          buttonClass="ui-button-success"
          [disabled]="!form.valid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</ng-template>
