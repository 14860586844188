<div class="text-right">
  <strong [ngSwitch]="isNumberData(currentValue)">
    <span *ngSwitchCase="true">
      {{ formatNumberData(currentValue) | number:'1.2-2' }}
    </span>
    <span *ngSwitchDefault>
      {{ currentValue }}
    </span>
  </strong>

  <strong *ngIf="isDataValid(currentValue)">
    <span [ngSwitch]="valueType">
      <span *ngSwitchCase="'humidity'">%</span>
      <span *ngSwitchCase="'temperature'">&deg;C</span>
      <span *ngSwitchCase="'consumption'">W</span>
      <span *ngSwitchCase="'consumptionTotal'">W</span>
      <span *ngSwitchCase="'voltage'">V</span>
      <span *ngSwitchDefault></span>
    </span>
  </strong>

  <small>
    <span [class]="getColor()" *ngIf="isDataValid(diffValue)">
      <!-- <i [class]="getIcon()"></i> -->
      {{ getIcon() }}
      {{ formatNumberData(diffValue) | number:'1.2-2' }}
    </span>
    <span [class]="getColor()" *ngIf="!isDataValid(diffValue)">
      {{ previousValue }}
    </span>
  </small>
</div>
