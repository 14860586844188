import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { BehaviourPatternsFormComponent } from './behaviour-patterns-form/behaviour-patterns-form.component'
import { DeviceModelActionCellComponent } from './device-model-action-cell/device-model-action-cell.component';
import { DeviceModelFormComponent } from './device-model-form/device-model-form.component';
import { DeviceModelValueMappingFormComponent } from './value-mapping-form/value-mapping-form.component';
import { DeviceModelsTableComponent } from './device-models-table/device-models-table.component';

import { DeviceModelFormsService } from './services/device-model-forms.service';
import { DeviceModelsService } from './services/device-models.service';

const COMPONENTS: any[] = [
  BehaviourPatternsFormComponent,
  DeviceModelActionCellComponent,
  DeviceModelFormComponent,
  DeviceModelValueMappingFormComponent,
  DeviceModelsTableComponent
];

@NgModule({
  imports: [SharedModule],
  exports: [BehaviourPatternsFormComponent],
  declarations: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [DeviceModelFormsService, DeviceModelsService]
})
export class DeviceModelsModule {}
