import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { PageTemplate } from '../models/page-template';
import { ModalConfig } from '@sc/modal/modal-config';

import { PageTemplatesService } from '../services/page-templates.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { PageTemplateActionCellComponent } from '../page-template-action-cell/page-template-action-cell.component';

@Component({
  selector: 'sc-page-templates-table',
  templateUrl: 'page-templates-table.component.html'
})
export class PageTemplatesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: any[];
  dataset: PageTemplate[];
  formConfig: ModalConfig;
  subscribers: { [key: string]: any } = {};
  tableTitle: string;

  constructor(
    private pageTemplatesService: PageTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // table name
    this.tableTitle = 'PAGE_TEMPLATES';

    // table columns
    this.columns = [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'key',
        headerName: 'KEY',
        field: 'key'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: PageTemplateActionCellComponent
      }
    ];

    // new form config
    this.formConfig = {
      name: 'PageTemplateForm',
      options: { modalTitle: 'PAGE_TEMPLATE_FORM_TITLE', fullScreen: true }
    };

    // get page templates
    this.fetchDataset();
  }

  ngOnDestroy() {
    // unsubscribe all subscribers
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.GET_DATASET = this.pageTemplatesService
      .getPageTemplates()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }
}
