import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { PropertyManagementSystemActionCellComponent } from './property-management-system-action-cell/property-management-system-action-cell.component';
import { PropertyManagementSystemFormComponent } from './property-management-system-form/property-management-system-form.component';
import { PropertyManagementSystemsTableComponent } from './property-management-systems-table/property-management-systems-table.component';

import { PropertyManagementSystemsService } from './services/property-management-systems.service';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    PropertyManagementSystemActionCellComponent,
    PropertyManagementSystemFormComponent,
    PropertyManagementSystemsTableComponent,
  ],
  entryComponents: [
    PropertyManagementSystemActionCellComponent,
    PropertyManagementSystemFormComponent,
    PropertyManagementSystemsTableComponent,
  ],
  providers: [PropertyManagementSystemsService],
})
export class PropertyManagementSystemsModule {}
