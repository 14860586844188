import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import {
  DataSources,
  CustomFunction,
  CustomGlobal,
  Device,
  DeviceType,
  Location,
  LocationProperty,
  Rule,
  RuleEditorModes,
  RuleTemplate,
  SelectItem,
  SelectItems,
  Subscribers,
} from '../../models';
import { RulesService, UtilsService } from '../../services';
import { ReportTemplate } from '@app/widgets/report-templates/interfaces';
import { UserTaskTemplate } from '@app/widgets/user-task-templates/interfaces';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { getAllWidgets } from '@app/store/selectors/widgets.selector';

@Component({
  selector: 'sc-rule-overview',
  templateUrl: './rule-overview.component.html',
  styleUrls: ['./rule-overview.component.scss'],
})
export class RuleOverviewComponent implements OnInit, OnChanges, OnDestroy {
  dataSources: DataSources = {};
  selectItems: SelectItems = {};
  viewModes: SelectItem[];
  showEditor = false;
  affectedDevices = new FormControl();
  selectedTemplate = new FormControl();
  isFromScratch = false;
  ruleTemplate: RuleTemplate;
  isSchedulerActive: boolean = false;

  @Input() automationId: number;
  @Input() rule: Rule;
  @Input() devices: Device[];
  @Input() locations: Location[];
  @Input() rules: Rule[];
  @Input() ruleTemplates: RuleTemplate[];
  @Input() customFunctions: CustomFunction[];
  @Input() customGlobals: CustomGlobal[];
  @Input() deviceTypes: DeviceType[];
  @Input() locationProperties: LocationProperty[];
  @Input() notificationTemplates: any[];
  @Input() reportTemplates: ReportTemplate[];
  @Input() userTaskTemplates: UserTaskTemplate[];

  private subscribers: Subscribers = {};

  constructor(
    private rulesService: RulesService,
    private utilsService: UtilsService,
    private store: Store<fromStore.State>
  ) {
    this.store.pipe(select(getAllWidgets)).subscribe({
      next: (result) => {
        if (result.findIndex((widget) => widget.key === 'scheduler') !== -1) {
          this.isSchedulerActive = true;
        }
      },
    });
  }

  ngOnInit(): void {
    // edit mode then skipped create options selector
    if (this.rule && this.rule.idx) {
      this.showEditor = true;
    }

    // update selectedTemplate form control
    if (this.rule.rule_template_idx) {
      this.selectedTemplate.setValue(this.rule.rule_template_idx);
      this.selectedTemplate.disable();
      if (this.showEditor !== true) {
        this.onCreateFromTemplate();
      }
    }

    // update affectedDevices form control
    if (this.rule.affected_devices) {
      this.affectedDevices.setValue(this.rule.affected_devices);
    }

    this.rule.editor_view = RuleEditorModes.Integrator;

    this.onFormValueChange();
    this.loadFormData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customFunctions && changes.customFunctions.currentValue) {
      this.manageData('customFunctions', changes.customFunctions.currentValue);
    }
    if (changes.customGlobals && changes.customGlobals.currentValue) {
      this.manageData('customGlobals', changes.customGlobals.currentValue, 'label', 'key');
    }
    if (changes.devices && changes.devices.currentValue) {
      this.manageDevices();
    }
    if (changes.deviceTypes && changes.deviceTypes.currentValue) {
      this.manageData('deviceTypes', changes.deviceTypes.currentValue, 'label', 'key');
    }
    if (changes.locations && changes.locations.currentValue) {
      this.manageData('locations', changes.locations.currentValue);
    }
    if (changes.locationProperties && changes.locationProperties.currentValue) {
      this.manageData('locationProperties', changes.locationProperties.currentValue, 'label', 'key');
    }
    if (changes.ruleTemplates && changes.ruleTemplates.currentValue) {
      this.manageData('ruleTemplates', changes.ruleTemplates.currentValue);
    }
    if (changes.rules && changes.rules.currentValue) {
      this.manageData('rules', changes.rules.currentValue);
    }
    if (changes.notificationTemplates && changes.notificationTemplates.currentValue) {
      this.manageData('notificationTemplates', changes.notificationTemplates.currentValue);
    }
    if (changes.reportTemplates && changes.reportTemplates.currentValue) {
      this.manageData('reportTemplates', changes.reportTemplates.currentValue);
    }
    if (changes.userTaskTemplates && changes.userTaskTemplates.currentValue) {
      this.manageData('userTaskTemplates', changes.userTaskTemplates.currentValue);
    }
  }

  ngOnDestroy() {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private loadFormData() {
    // set available views
    this.viewModes = [
      { label: RuleEditorModes.ExpertLabel, value: RuleEditorModes.Expert },
      { label: RuleEditorModes.AdvancedLabel, value: RuleEditorModes.Advanced },
      { label: RuleEditorModes.NormalLabel, value: RuleEditorModes.Normal },
      { label: RuleEditorModes.BackendLabel, value: RuleEditorModes.Backend },
      { label: RuleEditorModes.IntegratorLabel, value: RuleEditorModes.Integrator },
    ];

    // from rule structure
    this.dataSources.absoluteDatetimePatterns = this.rulesService.getAbsoluteDatetimePatternsStructures();
    this.dataSources.operands = this.rulesService.getOperandStructures();
    this.dataSources.operators = this.rulesService.getOperatorStructures();

    this.selectItems.absoluteDatetimePatterns = this.dataSources.absoluteDatetimePatterns.map((item) =>
      this.utilsService.selectItemMapper(item, 'label', 'id')
    );
    this.selectItems.operators = this.dataSources.operators.map((item) =>
      this.utilsService.selectItemMapper(item, 'label', 'id')
    );
  }

  private onFormValueChange() {
    this.subscribers.affectedDevices = this.affectedDevices.valueChanges.subscribe((data) => {
      this.rule.affected_devices = data;
    });
  }

  private getTemplate(idx: string): RuleTemplate {
    if (this.dataSources && this.dataSources.ruleTemplates && this.dataSources.ruleTemplates.length) {
      return this.dataSources.ruleTemplates.find((item) => item.idx === idx);
    }
    return;
  }

  onCreateFromTemplate() {
    if (!this.rule.rule_template_idx) {
      this.rule.rule_template_idx = this.selectedTemplate.value;
    }
    let template: RuleTemplate = this.getTemplate(this.rule.rule_template_idx);

    // template is immutable so we need to make it mutable before patching
    try {
      template = JSON.parse(JSON.stringify(template));
    } catch (error) {}

    if (template) {
      template.commands = template.commands ? JSON.parse(template.commands as string) : [];
      template.conditions = template.conditions ? JSON.parse(template.conditions as string) : {};
      this.ruleTemplate = template;

      delete template.idx;
      delete template.id;
      delete template.created_at;
      delete template.created_by;
      delete template.updated_at;
      delete template.updated_by;
      delete template.is_active;
      delete template.is_deleted;
      this.showEditor = true;
      this.rule = Object.assign(this.rule, template);

      this.rule.is_active = false;
      this.rule.is_deleted = false;
    }
  }

  onCreateFromScratch() {
    // create from scratch
    this.showEditor = true;
    this.isFromScratch = true;
    this.rule = Object.assign(this.rule, {
      name: null,
      conditions: {
        AND: [
          {
            left: { type: null },
            operator: { type: null },
            right: { type: null },
          },
        ],
      },
      commands: [{ type: null }],
      // set default view
      editor_view: RuleEditorModes.Normal,
      is_active: false,
      is_deleted: false,
    });
  }

  private manageDevices() {
    this.dataSources.devices = this.devices;
    this.selectItems.devices = this.devices
      .filter((item) => item.categories !== 'controller')
      .map((item) => this.utilsService.selectItemMapper(item, 'name', 'idx'));

    // devices tree from devices
    const devicesTree = this.createDevicesTreeSortedByAutomationId();
    const vdhDevicesTree = devicesTree.filter((item) => item.data && item.data.categories !== 'controller');
    const vdhCommandDevicesTree = devicesTree.filter(
      (item) => item.data && item.data.categories !== 'controller' && !item.data.command_allowed
    );

    if (devicesTree && devicesTree.length) {
      // devices tree with all children
      this.dataSources.devicesTree = vdhDevicesTree.map((item) => ({ ...item, expanded: true }));

      // devices tree with only writeable children
      this.dataSources.devicesTreeWithOnlyWriteableChildren = vdhDevicesTree.map((item) => {
        const filteredChildren = [];
        // filter only writeable devices
        for (const child of item.children) {
          if (this.deviceTypes && this.deviceTypes.length) {
            const deviceType = this.deviceTypes.find((item) => item.key === child.data.device_type);
            if (deviceType && deviceType.writeable) {
              filteredChildren.push(child);
            }
          }
        }
        return { ...item, expanded: true, children: filteredChildren };
      });

      this.dataSources.commandDevicesTree = vdhCommandDevicesTree.map((item) => ({ ...item, expanded: true }));
      this.dataSources.commandDevicesTreeWithOnlyWriteableChildren = vdhCommandDevicesTree.map((item) => {
        const filteredChildren = [];
        // filter only writeable devices
        for (const child of item.children) {
          if (this.deviceTypes && this.deviceTypes.length) {
            const deviceType = this.deviceTypes.find((item) => item.key === child.data.device_type);
            if (deviceType && deviceType.writeable) {
              filteredChildren.push(child);
            }
          }
        }
        return { ...item, expanded: true, children: filteredChildren };
      });
    }
  }

  private createDevicesTree() {
    if (
      !this.devices ||
      !this.devices.length ||
      !this.deviceTypes ||
      !this.deviceTypes.length ||
      !this.locations ||
      !this.locations.length
    ) {
      return;
    }

    const tree: { [key: string]: TreeNode } = {};

    for (const device of this.devices) {
      if (device.device_type === 'vdh') {
        const location = this.locations.find((item) => item.idx === device.location_idx);
        const deviceType = this.deviceTypes.find((item) => item.key === device.device_type);

        tree[device.idx] = {
          label: device.name,
          data: { ...device, location, deviceType },
          children: [],
          expanded: false,
        };
      }
    }

    for (const device of this.devices) {
      if (device.parent_idx && device.device_type !== 'vdh' && tree[device.parent_idx]) {
        const location = this.locations.find((item) => item.idx === device.location_idx);
        const deviceType = this.deviceTypes.find((item) => item.key === device.device_type);
        const parentDevice = this.devices.find((item) => item.idx === device?.parent_idx);
        const n1Location = this.locations.find((item) => item.idx === location?.parent_idx);
        const n2Location = this.locations.find((item) => item.idx === n1Location?.parent_idx);

        tree[device.parent_idx].children.push({
          label: `${n2Location ? `${n2Location.name} / ` : ''}${n1Location ? `${n1Location.name} / ` : ''}${
            location ? `${location.name} / ` : ''
          }${parentDevice ? `${parentDevice.name} / ` : ''}${device.name}`,
          data: { ...device, location, deviceType },
        });
      }
    }

    return Object.keys(tree).map((idx) => tree[idx]);
  }

  private createDevicesTreeSortedByAutomationId() {
    const devices = this.createDevicesTree();

    const filteredDevices = devices.map((device) => ({
      ...device,
      // label: device.label + (device.data.automation_id === this.automationId ? ' (L)' : ' (E)'),
    }));
    const sortedDevices = filteredDevices.sort((a, b) => {
      if (a.data.automation_id === this.automationId) {
        if (a.data.automation_id === b.data.automation_id) {
          return a.data.name - b.data.name;
        }
        return -1;
      }
      return a.data.name - b.data.name;
    });
    return sortedDevices;
  }

  private manageData(type: string, data: any[], labelKey = 'name', valueKey = 'idx') {
    this.dataSources[type] = data;
    this.selectItems[type] = data.map((item) => this.utilsService.selectItemMapper(item, labelKey, valueKey));
  }
}
