import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-guest-request-text-cell',
  templateUrl: 'guest-request-text-cell.component.html'
})
export class GuestRequestTextCellComponent implements ICellRendererAngularComp {
  cell: any;
  text: string;
  presets: any;

  constructor(private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
    this.text = this.cell.value;
    this.presets = this.cell.context.parentComponent.presetsData;
  }

  refresh(): boolean {
    return false;
  }

  translate() {
    const myLocale = this.sharedService.userLocale;
    this.sharedService
      .textToTextTranslate(this.cell.data.text, this.cell.data.locale, myLocale)
      .subscribe((result: any) => {
        this.text = result.replace(/<[^>]*>/g, '');
      });
  }

  get isOtherCategory() {
    return this.cell.data.presetId === '0' ? true : false;
  }

  findPresetByLocale() {
    if (this.presets && !this.isOtherCategory) {
      const preset = this.presets.find(p => p.id === this.cell.data.presetId);
      const myLocale = this.sharedService.userLocale;
      const title = preset.title.find(t => t.locale === myLocale);
      return title.text;
    } else {
      return this.text;
    }
  }
}
