import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RuleHistoryLogsRequestOptions } from '../models/rule-history-logs-request-options';

import { SharedService } from '@shared/shared.service';

import { config } from '../../../config';

@Injectable()
export class CeosRuleHistoryLogsService {
  private baseUrl = config().apiUrl + '/companies/{companyId}/sites/{siteId}';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private get apiUrl(): string {
    let apiUrl = this.baseUrl;

    const site = this.sharedService.selectedSite;

    if (site.companyId) {
      apiUrl = apiUrl.replace('{companyId}', site.companyId + '');
    }

    if (site.id) {
      apiUrl = apiUrl.replace('{siteId}', site.id + '');
    }

    return apiUrl;
  }

  getRuleHistoryLogLazyLoad(automationId: number, options?: any) {
    const apiUrl = `${this.apiUrl}/devices/${automationId}/ruleTriggers/history/lazy`;
    const queryParams: any = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(apiUrl + queryParams);
  }

  getRuleHistoryLogs(ruleIdx?: string, options: any = {}) {
    let apiUrl = `${this.apiUrl}/ruleTriggers`;
    if (ruleIdx) {
      apiUrl += `/${ruleIdx}`;
    }
    apiUrl += `/history`;

    if (!options.limit) {
      options.limit = 50;
    }

    const queryParams: any = this.sharedService.urlQueryParamsCreator(options);
    return this.httpClient.get(apiUrl + queryParams);
  }

  getLatestRuleHistoryLogs() {
    const apiUrl = this.apiUrl + '/ruleTriggers/history/latest';
    return this.httpClient.get(apiUrl);
  }
}
