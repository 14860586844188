import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  Renderer2,
} from '@angular/core';

import { ModalConfig } from './modal-config';
import { SCModalDirective } from './modal.directive';
import { SCDynamicModalComponent } from './dynamic-modal.component';

import * as MODALS from './modals';

@Component({
  selector: 'sc-modal-trigger',
  templateUrl: './modal-trigger.component.html',
  styleUrls: ['./modal-trigger.component.scss'],
})
export class SCModalTriggerComponent implements OnInit, OnDestroy {
  @Input()
  buttonIcon: string;
  @Input()
  buttonLabel: string;
  @Input()
  buttonClass: string;
  @Input()
  disabled: boolean;
  @Input()
  displayInline: boolean;
  @Input()
  modalConfig: ModalConfig;
  @Input()
  iconPos: string;
  @Input()
  tooltipContent: string;
  @Input()
  tooltipEscape: boolean;
  @Input()
  tooltipPosition: 'top' | 'left' | 'bottom' | 'right';

  @Output()
  onDismiss = new EventEmitter();
  @Output()
  onClose = new EventEmitter();

  @ViewChild(SCModalDirective, { static: true })
  modalHost: SCModalDirective;

  display = false;
  // draggable = true;
  // fullScreen = false;
  // positionTop = 75;
  // responsive = true;

  private subscribers: { [key: string]: any } = {};

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.iconPos === null || this.iconPos === undefined || this.iconPos === '') {
      this.iconPos = 'left';
    }
    this.buttonClass = this.buttonClass || 'ui-button-success';

    // if (this.modalConfig && this.modalConfig.options && this.modalConfig.options.fullScreen === true) {
    //   this.draggable = false;
    // this.fullScreen = true;
    // this.responsive = false;
    // this.positionTop = 0;
    // }

    // if (window.innerWidth <= 1000) {
    //   this.positionTop = 0;
    // }

    this.tooltipContent = this.tooltipContent || '';
    this.tooltipEscape = this.tooltipEscape || false;
    this.tooltipPosition = this.tooltipPosition || 'top';
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key)) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  get headerText() {
    if (this.modalConfig && this.modalConfig.options && this.modalConfig.options.modalTitle) {
      return this.modalConfig.options.modalTitle;
    }
    return '';
  }

  get headerTextParams() {
    if (this.modalConfig && this.modalConfig.options && this.modalConfig.options.modalTitleParams) {
      return this.modalConfig.options.modalTitleParams;
    }
    return;
  }

  get isMaximizable() {
    if (this.modalConfig && this.modalConfig.options && this.modalConfig.options.modalMaximizable !== undefined) {
      return this.modalConfig.options.modalMaximizable;
    }
    return true;
  }

  get isResizable() {
    if (this.modalConfig && this.modalConfig.options && this.modalConfig.options.modalResizable !== undefined) {
      return this.modalConfig.options.modalResizable;
    }
    return true;
  }

  openModal() {
    if (this.modalConfig && this.modalConfig.name) {
      const modal = MODALS[this.modalConfig.name + 'Component'];

      if (modal) {
        this.display = true;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modal);
        const viewContainerRef = this.modalHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const dynamicModal = componentRef.instance as SCDynamicModalComponent;

        // ____ PASSTHROUGH DATA TO MODAL
        if (this.modalConfig && this.modalConfig.data) {
          dynamicModal.data = this.modalConfig.data;
        }
        // ____ SUBSCRIBE ON MODAL DISMISS
        if (dynamicModal.onDismiss) {
          this.subscribers.ON_DISMISS = dynamicModal.onDismiss.subscribe((reason) => {
            this.dismissModal(reason);
          });
        }
        // ____ SUBSCRIBE ON MODAL CLOSE
        if (dynamicModal.onClose) {
          this.subscribers.ON_CLOSE = dynamicModal.onClose.subscribe((result) => {
            this.closeModal(result);
          });
        }
      }
    }
  }

  clearViewContainer() {
    // HIDE MODAL
    this.display = false;

    // DESTROY CONTENT COMPONENT
    this.modalHost.viewContainerRef.clear();
  }

  dismissModal(reason) {
    this.clearViewContainer();
    this.onDismiss.emit({ reason });
  }

  closeModal(result) {
    this.clearViewContainer();
    this.onClose.emit({ result });
  }
}
