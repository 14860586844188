import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { LocationsOccupancyHistoryComponent } from './locations-occupancy-history.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [LocationsOccupancyHistoryComponent],
  entryComponents: [LocationsOccupancyHistoryComponent],
  providers: []
})
export class LocationsOccupancyHistoryModule {}
