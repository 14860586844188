import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { BenchmarkTemplate } from '../../models/benchmark-template';

import { BenchmarkTemplateFormsService } from '../../services/benchmark-template-forms.service';
import { BenchmarkTemplatesService } from '../../services/benchmark-templates.service';
import { DeviceTypesService } from '@app/widgets/device-types/services/device-types.service';
import { LocationPropertiesService } from '@widgets/location-properties/services/location-properties.service';
import { SitesService } from '@widgets/sites/services/sites.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-benchmark-template-form',
  templateUrl: 'benchmark-template-form.component.html'
})
export class BenchmarkTemplateFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  formData: BenchmarkTemplate;
  form: FormGroup;
  editMode: boolean;
  errorMessage: string;
  fetchingState: number;
  isSubmitting: boolean;
  selectItems: { [key: string]: SelectItem[] };
  subscribers: { [key: string]: any };

  private isAdmin: boolean;

  constructor(
    private benchmarkTemplateFormsService: BenchmarkTemplateFormsService,
    private benchmarkTemplatesService: BenchmarkTemplatesService,
    private deviceTypesService: DeviceTypesService,
    private locationPropertiesService: LocationPropertiesService,
    private sitesService: SitesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchingState = 0;
    this.subscribers = {};
    this.selectItems = this.benchmarkTemplateFormsService.getFormSelectItems();
    this.selectItems.booleanOption = this.sharedService.selectItems.booleanOption;

    this.isAdmin =
      this.sharedService.userRoles &&
      this.sharedService.userRoles.includes('admin');

    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      this.fetchBenchmarkTemplate(this.data.id);
    }

    this.fetchSites();
    this.fetchLocationProperties();
    this.fetchDeviceTypes();

    this.initForm();
  }

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  fetchSites() {
    let service;
    if (this.isAdmin) {
      service = this.sitesService.getSites();
    } else {
      service = this.sitesService.getSite(this.sharedService.selectedSite.id);
    }
    if (service) {
      this.fetchingState++;
      this.subscribers.GET_SITES = service.subscribe((result: any) => {
        const sites = this.isAdmin
          ? result.data.filter(d => d.isActive && !d.isDeleted)
          : [result.data];
        this.selectItems.sites = this.sharedService.createSelectItems(
          sites,
          false
        );
        this.fetchingState--;
      });
    }
  }

  fetchBenchmarkTemplate(id: number) {
    this.fetchingState++;
    this.subscribers.GET_BENCHMARK_TEMPLATE = this.benchmarkTemplatesService
      .getBenchmarkTemplate(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.formData.enablingMarker = this.sharedService.jsonParse(
          this.formData.enablingMarker
        );
        this.formData.disablingMarker = this.sharedService.jsonParse(
          this.formData.disablingMarker
        );
        this.formData.output = this.sharedService.jsonParse(
          this.formData.output
        );

        this.form.patchValue(this.formData);
        this.benchmarkTemplateFormsService.setConditionsForm(
          this.form,
          this.formData.enablingMarker,
          false
        );
        this.benchmarkTemplateFormsService.setConditionsForm(
          this.form,
          this.formData.disablingMarker,
          true
        );
        this.benchmarkTemplateFormsService.setOutputForm(
          this.form,
          this.formData.output
        );
        this.fetchingState--;
      });
  }

  fetchLocationProperties() {
    this.fetchingState++;
    this.subscribers.GET_LOCATION_PROPERTIES = this.locationPropertiesService
      .getLocationProperties()
      .subscribe((result: any) => {
        this.selectItems.locationPropertiesRaw = result.data;
        this.fetchingState--;
      });
  }

  fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.GET_DEVICE_TYPES = this.deviceTypesService
      .getDeviceTypes()
      .subscribe((result: any) => {
        this.selectItems.deviceTypesRaw = result.data;
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.benchmarkTemplateFormsService.initBenchmarkTemplateForm();

    this.subscribers.WATCH_IS_PUBLIC = this.form.controls[
      'isPublic'
    ].valueChanges.subscribe(value => {
      if (value) {
        this.form.controls['sites'].reset();
        this.form.controls['sites'].disable();
      } else {
        this.form.controls['sites'].enable();
      }
    });

    this.subscribers.WATCH_IS_INTERVAL_LIMITED = this.form.controls[
      'isIntervalLimited'
    ].valueChanges.subscribe(value => {
      if (value) {
        this.form.controls['intervalLimit'].enable();
        this.form.controls['intervalLimit'].setValidators(Validators.required);
      } else {
        this.form.controls['intervalLimit'].reset();
        this.form.controls['intervalLimit'].disable();
        this.form.controls['intervalLimit'].clearValidators();
      }
    });

    this.subscribers.WATCH_TIME_RANGE_MODE = this.form.controls[
      'timeRangeMode'
    ].valueChanges.subscribe(value => {
      if (value === 'r') {
        this.form.controls['relativeAgoUnit'].enable();
        this.form.controls['relativeAgoUnit'].setValidators(
          Validators.required
        );
        this.form.controls['relativeAgoValue'].enable();
        this.form.controls['relativeAgoValue'].setValidators(
          Validators.required
        );
      } else {
        this.form.controls['relativeAgoUnit'].reset();
        this.form.controls['relativeAgoUnit'].disable();
        this.form.controls['relativeAgoUnit'].clearValidators();
        this.form.controls['relativeAgoValue'].reset();
        this.form.controls['relativeAgoValue'].disable();
        this.form.controls['relativeAgoValue'].clearValidators();
      }
    });
  }

  submit() {
    // console.log(JSON.stringify(this.form.getRawValue()));
    if (this.form.valid) {
      this.errorMessage = null;
      this.isSubmitting = true;

      if (!this.editMode) {
        this.create();
      } else {
        this.update();
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create() {
    const formData = { ...this.form.value };
    formData.output.push({ key: 'duration', options: null });

    this.benchmarkTemplatesService
      .createBenchmarkTemplate(formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  update() {
    const formData = this.form.value;
    formData.output.push({ key: 'duration', options: null });

    this.benchmarkTemplatesService
      .updateBenchmarkTemplate(this.formData.id, formData)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  delete() {
    this.benchmarkTemplatesService
      .deleteBenchmarkTemplate(this.formData.id)
      .subscribe(this.apiCallSuccess.bind(this), this.apiCallError.bind(this));
  }

  apiCallSuccess(result: any) {
    // show notification
    this.sharedService.notify('Success', result.message, 'success');
    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
