import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { BenchmarkTemplateActionCellComponent } from './benchmark-template-action-cell/benchmark-template-action-cell.component';
import { BenchmarkTemplatesTableComponent } from './benchmark-templates-table/benchmark-templates-table.component';
import { BenchmarkTemplateFormComponent } from './benchmark-template-form/benchmark-template-form.component';
import { OutputFormComponent } from './benchmark-template-form/output-form/output-form.component';
import { EnablingMarkerFormComponent } from './benchmark-template-form/enabling-marker-form/enabling-marker-form.component';
import { DisablingMarkerFormComponent } from './benchmark-template-form/disabling-marker-form/disabling-marker-form.component';

const EXPORT_COMPONENTS: any[] = [
  BenchmarkTemplatesTableComponent,
  BenchmarkTemplateFormComponent
];
const COMPONENTS: any[] = [
  BenchmarkTemplateActionCellComponent,
  OutputFormComponent,
  EnablingMarkerFormComponent,
  DisablingMarkerFormComponent
];

@NgModule({
  imports: [SharedModule],
  exports: [...EXPORT_COMPONENTS],
  declarations: [...EXPORT_COMPONENTS, ...COMPONENTS],
  entryComponents: [...EXPORT_COMPONENTS, ...COMPONENTS]
})
export class BenchmarkTemplatesModule {}
