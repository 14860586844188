<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        {{ 'DISABLED_RULES' | translate }}
      </h4>
    </div>

    <ng-container *ngIf="isFirstTime; then spinner; else content"></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <p-table [value]="rules" [scrollable]="true" [scrollHeight]="config.widgetHeight - 80 + 'px'">
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Rule</th>
        <th>Since</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr class="ui-widget-header" *ngIf="rowGroupMetadata[rowData.locationName].index === rowIndex">
        <td colspan="3">
          <span style="font-weight:bold">{{rowData.locationName}}</span>
        </td>
      </tr>
      <tr>
        <td>{{rowData.id}}</td>
        <td>{{rowData.name}}</td>
        <td>{{formatDate(rowData.deactivatedAt, rowData.updatedAt)}}</td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
