import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { ModalConfig } from '@sc/modal/modal-config';
import { Location } from '@widgets/locations/models/location';
import { Site } from '@widgets/sites/models/site';
import { DeviceDetails } from '@widgets/devices/models/device-details';

import { CeosRulesService } from '../services/ceos-rules.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { AutomationRuleActionCellComponent } from '../automation-rule-action-cell/automation-rule-action-cell.component';

@Component({
  selector: 'sc-automation-rules-table',
  templateUrl: 'automation-rules-table.component.html',
})
export class AutomationRulesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  datasource: any;
  formConfig: ModalConfig;
  table: GridOptions;
  tableTitle: string;

  isFetchingDataset: boolean;

  private selectedLocation: Location;
  private selectedSite: Site;
  private selectedDevice: DeviceDetails;
  private subscribers: { [key: string]: any } = {};
  private visibleColumns: string[] = [];

  constructor(
    private store: Store<fromStore.State>,
    private ceosRulesService: CeosRulesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Set new height of widget
    // this.config.widgetHeight = this.config.widgetHeight - 25;
    // Set Table Name
    this.tableTitle = 'RULES';
    // Set Table Columns
    this.columns = this.createColumns();
    // Set user's visible columns
    if (this.config && this.config.customOptions && this.config.customOptions.visibleColumns) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }
    // Set create rule form
    this.formConfig = {
      name: 'CeosRuleForm',
      options: { modalTitle: 'RULE_FORM_TITLE', fullScreen: true },
    };

    // get site details
    this.selectedSite = this.sharedService.selectedSite;

    // get location details
    // this.subscribers.locationDetail = this.store.select(fromStore.getLocationDetials).subscribe((result) => {
    //   if (result) {
    //     this.selectedLocation = result;
    // this.fetchDataset();
    //   }
    // });

    // get device details
    this.subscribers.selectedDevice = this.store.select(fromStore.getDeviceDetials).subscribe((result) => {
      if (result) {
        this.selectedDevice = result;
        this.fetchDataset();
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.datasource = {
        getRows: (rowParams) => {
          // OPTIONS
          const options: any = {
            // companyId: this.selectedSite.companyId,
            // siteId: this.selectedSite.id,
            // locationId: this.selectedLocation.id,
            automationId: this.selectedDevice.id,
            filter: rowParams.filterModel,
            sort: rowParams.sortModel,
            offset: rowParams.startRow,
            columns: 'id,name,is_active,is_deleted',
            limit: 100,
          };

          // CUSOTM VISIBLE COLUMNS
          // if (this.visibleColumns.length) {
          //   options.columns = [...this.visibleColumns].join();
          // }
          // console.log(options);
          // GET DATASET
          this.subscribers.getDataset = this.ceosRulesService.getCeosRules(options).subscribe((result: any) => {
            const rowData = result.data;
            let lastRow = -1;
            if (rowData.length < options.limit) {
              lastRow = rowParams.startRow + rowData.length;
            }
            rowParams.successCallback(rowData, lastRow);
          });
        },
      };

      // SET DATASOURCE IF TABLE READY
      if (this.table && this.table.api) {
        this.table.api.setDatasource(this.datasource);
      }

      this.isFetchingDataset = false;
    }
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private createColumns(): ColDef[] {
    return [
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name',
        cellRenderer: 'loadingRenderer',
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id',
      },
      {
        colId: 'idx',
        headerName: 'IDX',
        field: 'idx',
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'is_active',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
      },
      {
        colId: 'isInActive',
        headerName: 'INACTIVE',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        valueGetter: (params) =>
          (params.data.is_active === 0 || params.data.is_active === false) &&
          (params.data.is_deleted === 0 || params.data.is_deleted === false),
        suppressFilter: true,
        hide: true,
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'is_deleted',
        cellRendererFramework: CheckboxCellComponent,
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: AutomationRuleActionCellComponent,
      },
    ];
  }
}
