<div class="tree-select" [ngClass]="{ 'tree-select--disabled': isDisabled }">
  <div class="tree-select__input" (click)="openPanel(menuPanel, $event)">
    <div class="tree-select__input__selected">
      <span *ngIf="displayItem">
        {{ displayItem }}
      </span>

      <span
        class="tree-select__input__placeholder"
        *ngIf="!displayItem && placeholder"
      >
        {{ placeholder }}
      </span>

      <span
        class="tree-select__input__placeholder"
        *ngIf="!displayItem && !placeholder"
      >
        {{ "SELECT" | translate }} {{ label }}
      </span>
    </div>

    <div class="tree-select__input__trigger">
      <i class="fa fa-chevron-down"></i>
    </div>
  </div>

  <p-overlayPanel
    #menuPanel
    appendTo="body"
    styleClass="tree-select__overlay-panel"
  >
    <p-tree
      styleClass="tree-select__tree-item"
      [value]="options"
      [propagateSelectionUp]="false"
      [propagateSelectionDown]="false"
      [(selection)]="selected"
      selectionMode="single"
      (onNodeSelect)="nodeSelect(menuPanel, $event)"
      (onNodeUnselect)="nodeSelect(menuPanel, $event)"
    ></p-tree>
  </p-overlayPanel>
</div>
