<ng-container [formGroup]="form">
  <p-panel header="{{ 'LOCATIONS' | translate }}" [toggleable]="true">
    <div *ngIf="selectItems?.locations?.length">
      <sc-tree-multi-select
        controlName="locations"
        [form]="form"
        label="{{ 'LOCATIONS' | translate }}"
        [options]="selectItems?.locations"
      ></sc-tree-multi-select>
      <br />
      <sc-checkbox-input
        controlName="isCompare"
        [form]="form"
        label="{{ 'COMPARE' | translate }}"
      ></sc-checkbox-input>
      <br />
      <sc-tree-multi-select
        controlName="compareLocations"
        [form]="form"
        label="{{ 'COMPARE' | translate }} {{ 'LOCATIONS' | translate }}"
        [options]="selectItems?.locations"
        *ngIf="isCompare"
      >
      </sc-tree-multi-select>
    </div>
  </p-panel>
  <p-panel header="{{ 'AXES' | translate }}" [toggleable]="true">
    <sc-checkbox-input
      controlName="isCombine"
      [form]="form"
      label="{{ 'COMBINE' | translate }}"
    ></sc-checkbox-input>
    <!-- Graph form header -->
    <div class="display graph-form-header">
      <div class="ui-g-4 ui-g-nopad">
        <label class="sc-form-label">
          {{ "DATASET" | translate }}
        </label>
      </div>
      <div class="ui-g-2 ui-g-nopad">
        <label class="sc-form-label">
          {{ "AXIS" | translate }}
        </label>
      </div>
      <div class="ui-g-2 ui-g-nopad">
        <label class="sc-form-label">
          {{ "POSITION" | translate }}
        </label>
      </div>
      <div class="ui-g-1 ui-g-nopad">
        <label class="sc-form-label">
          {{ "COLOR" | translate }}
        </label>
      </div>
      <div class="ui-g-2 ui-g-nopad">
        <label class="sc-form-label">
          {{ "STYLE" | translate }}
        </label>
      </div>
      <div class="ui-g-1 p-1 text-center">
        <!-- Add Graph Button -->
        <button
          pButton
          type="button"
          class="ui-button-icon-only"
          (click)="addGraph()"
          icon="fa fa-plus"
          pTooltip="{{ 'ADD' | translate }} {{ 'VARIABLE' | translate }}"
          appendTo="body"
        ></button>
      </div>
    </div>

    <!-- Graph forms -->
    <ng-container formArrayName="graphs">
      <ng-container
        *ngFor="let gf of graphForms?.controls; let i = index"
        [formGroupName]="i"
      >
        <div [formGroup]="gf" class="display mb-2 py-1">
          <!-- DATASET -->
          <div class="ui-g-6 ui-md-4 ui-g-nopad pr-2">
            <sc-dropdown
              controlName="dataset"
              [form]="gf"
              label="{{ 'DATASET' | translate }}"
              [options]="selectItems?.graphs"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <!-- AXIS -->
          <div class="ui-g-6 ui-md-2 ui-g-nopad pr-2">
            <sc-dropdown
              controlName="axis"
              [form]="gf"
              label="{{ 'AXIS' | translate }}"
              [options]="axes"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <!-- POSITION -->
          <div class="ui-g-6 ui-md-2 ui-g-nopad pr-2">
            <sc-dropdown
              controlName="position"
              [form]="gf"
              label="{{ 'POSITION' | translate }}"
              [options]="selectItems?.positions"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <!-- COLOR -->
          <div class="ui-g-6 ui-md-1 ui-g-nopad pr-2">
            <sc-color-input
              controlName="color"
              [form]="gf"
              label="{{ 'COLOR' | translate }}"
              [showLabel]="false"
              outputFormat="rgba"
              [disableInput]="true"
              [presetColors]="selectItems?.colors"
            ></sc-color-input>
          </div>

          <!-- STYLE -->
          <div class="ui-g-6 ui-md-2 ui-g-nopad pr-2">
            <sc-dropdown
              controlName="styles"
              [form]="gf"
              label="{{ 'STYLES' | translate }}"
              [options]="selectItems?.styles"
              [showLabel]="false"
              [multiSelect]="true"
            ></sc-dropdown>
          </div>

          <!-- REMOVE_BUTTON -->
          <div class="ui-g-12 ui-md-1 p-1 text-center">
            <button
              pButton
              type="button"
              class="ui-button-icon-only color-danger"
              icon="fa fa-times"
              (click)="removeGraph(i)"
              pTooltip="{{ 'REMOVE' | translate }} {{ 'VARIABLE' | translate }}"
              appendTo="body"
            ></button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </p-panel>
</ng-container>
