import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DeviceWizard } from '../device-wizard';

@Component({
  selector: 'sc-automation-setting',
  templateUrl: './automation-setting.component.html',
  styleUrls: ['./automation-setting.component.scss']
})
export class AutomationSettingComponent implements OnInit {
  @Input()
  data: DeviceWizard;
  @Input()
  form: FormGroup;
  @Input()
  productImage: string;
  @Input()
  selectItems: { [prop: string]: SelectItem[] };

  constructor() { }

  ngOnInit() { }
}
