import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';

import { sortBy } from 'lodash';

@Component({
  selector: 'sc-device-form',
  templateUrl: 'device-form.component.html'
})
export class DeviceFormComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() groupId: any;
  @Input() optionData: any;
  @Output() removed = new EventEmitter();

  deviceModels: SelectItem[] = [];

  constructor() {}

  get deviceNumber() {
    return this.groupId + 1;
  }

  ngOnInit() {
    this.group.get('deviceTypeId').valueChanges.subscribe(value => {
      if (value) {
        this.initDeviceModelSelectItems(value);
      }
    });

    this.group.get('deviceModelId').valueChanges.subscribe(value => {
      if (value) {
        this.setDeviceInfo(value);
      }
    });

    // Edit mode
    if (this.group && this.group.value && this.group.value.deviceTypeId) {
      this.initDeviceModelSelectItems(this.group.value.deviceTypeId);
    }
  }

  private initDeviceModelSelectItems(deviceTypeId: number) {
    if (!this.optionData || !this.optionData.rawDeviceModels) {
      this.deviceModels = [];
      return;
    }

    const sourceData = this.optionData.rawDeviceModels;
    const deviceModels = [];

    for (let i = 0; i < sourceData.length; i++) {
      if (
        !sourceData[i].isActive ||
        sourceData[i].isDeleted ||
        sourceData[i].deviceTypeId !== deviceTypeId
      ) {
        continue;
      }

      const label =
        sourceData[i].brandName +
        (' ' + sourceData[i].name) +
        (' (' + (sourceData[i].model || '') + ')');
      const value = sourceData[i].id;

      deviceModels.push({ label, value });
    }

    this.deviceModels = sortBy(deviceModels, 'label');

    return deviceModels;
  }

  private setDeviceInfo(deviceModelId: number) {
    const deviceModel = this.optionData.rawDeviceModels.find(
      d => d.id === deviceModelId
    );

    if (!deviceModel) {
      return;
    }

    this.group.patchValue({
      brandId: deviceModel.brandId,
      protocolId: deviceModel.protocolId
    });
  }
}
