import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { GridOptions, ColDef, ColGroupDef } from 'ag-grid';
import { BenchmarkTemplate } from '../../models/benchmark-template';

import { BenchmarksBatchService } from '../../services/benchmarks-batch.service';
import { BenchmarkTemplatesService } from '../../services/benchmark-templates.service';
import { SharedService } from '@shared/shared.service';

import * as moment from 'moment';

@Component({
  selector: 'sc-location-benchmark-detail',
  templateUrl: 'location-benchmark-detail.component.html'
  // styleUrls: ['location-benchmark-detail.component.scss']
})
export class LocationBenchmarkDetailComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  gridOptions: GridOptions;
  columns: (ColDef | ColGroupDef)[];
  fetchingState = 0;

  // locationDetails: any;
  benchmarkDetails: any[];
  benchmarkTemplate: BenchmarkTemplate;

  private avg: { [key: string]: any } = {};
  private sum: { [key: string]: any } = {};
  private subscribers: { [key: string]: any } = {};

  constructor(
    private benchmarksBatchService: BenchmarksBatchService,
    private benchmarkTemplatesService: BenchmarkTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.fetchBenchmark();
    this.fetchBenchmarkTemplate(this.data.benchmarkTemplateId);
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchBenchmark() {
    this.fetchingState++;
    const batchId = this.data.batch_id;
    const options = { locationId: this.data.locationId };
    this.subscribers.GET_BENCHMARK = this.benchmarksBatchService
      .getBenchmark(batchId, options)
      .subscribe((result: any) => {
        this.benchmarkDetails = result.data;
        this.createBenchmarkResultTable(result.data);
        this.fetchingState--;
      });
  }

  fetchBenchmarkTemplate(id: number) {
    this.fetchingState++;
    this.subscribers.GET_BENCHMARK_TEMPLATE = this.benchmarkTemplatesService
      .getBenchmarkTemplate(id)
      .subscribe((result: any) => {
        this.benchmarkTemplate = result.data;
        this.fetchingState--;
      });
  }

  getOutputData(benchmarkResult) {
    const infoKeys = [
      'id',
      'batch_id',
      'benchmarkTemplateId',
      'locationId',
      'siteId',
      'companyId',
      'createdAt',
      'createdBy',
      'startDate',
      'endDate'
    ];
    const outputData = [];
    for (let i = 0; i < benchmarkResult.length; i++) {
      const data = {};
      for (const key in benchmarkResult[i]) {
        if (
          benchmarkResult[i].hasOwnProperty(key) &&
          infoKeys.indexOf(key) < 0
        ) {
          data[key] = benchmarkResult[i][key];
          if (typeof data[key] === 'number') {
            this.sum[key] = this.sum[key]
              ? this.sum[key] + data[key]
              : 0 + data[key];
          }
        }
      }
      outputData.push(data);
    }

    // calculate average
    Object.keys(this.sum).forEach(val => {
      this.avg[val] = this.sum[val] / benchmarkResult.length;
    });

    return outputData;
  }

  createBenchmarkResultTable(dataset) {
    const rowData = this.getOutputData(dataset);
    const columnDefs = [
      {
        headerName: 'Start Time',
        colId: 'start_time',
        field: 'start_time',
        valueFormatter: params => this.sharedService.dateFormat(params.value)
      },
      {
        headerName: 'End Time',
        colId: 'end_time',
        field: 'end_time',
        valueFormatter: params => {
          if (params.data.isAvgRow) {
            return 'Average:';
          } else if (params.data.isTotalRow) {
            return 'Total:';
          }
          return this.sharedService.dateFormat(params.value);
        },
        pinnedRowCellRenderer: params => `
          <div style="text-align:right;">
            ${params.valueFormatted || ''}
          </div>
        `
      },
      {
        headerName: 'Duration',
        colId: 'duration',
        field: 'duration',
        valueFormatter: params => {
          const tmp = { hours: 0, minutes: 0, seconds: 0 };
          tmp.hours = params.data.duration / 60 / 60;
          tmp.minutes = (tmp.hours - Math.floor(tmp.hours)) * 60;
          tmp.seconds = (tmp.minutes - Math.floor(tmp.minutes)) * 60;

          return (
            (tmp.hours < 10 ? '0' : '') +
            Math.floor(tmp.hours) +
            ':' +
            (tmp.minutes < 10 ? '0' : '') +
            Math.floor(tmp.minutes) +
            ':' +
            (tmp.seconds < 10 ? '0' : '') +
            Math.floor(tmp.seconds) +
            ' Hours'
          );
        },
        cellRenderer: params => `
          <div style="text-align:right">
            ${params.valueFormatted || ''}
          </div>
        `,
        pinnedRowCellRenderer: params => `
          <div style="text-align:right;color:${params.color};">
            ${params.valueFormatted || ''}
          </div>
        `,
        pinnedRowCellRendererParams: { color: 'blue' }
      }
    ];

    // create columns
    const excludeColumns = ['start_time', 'end_time', 'duration'];
    for (const key in rowData[0]) {
      if (rowData[0].hasOwnProperty(key) && excludeColumns.indexOf(key) < 0) {
        const col: any = {
          headerName: key,
          colId: key,
          field: key
        };

        const keyword = key.replace(/^(start|end)\_/i, '');

        if (
          key !== 'disabling_reason' &&
          this.sharedService.isLHLValueBoolean(keyword) === false
        ) {
          col.valueFormatter = params =>
            this.sharedService.numberFormat(params.value);
          col.cellRenderer = params => {
            let color = params.normal;
            if (params.data.isAvgRow) {
              color = params.avg;
            } else {
              if (+params.valueFormatted > this.avg[params.colDef.colId]) {
                color = params.higherAvg;
              } else if (
                +params.valueFormatted < this.avg[params.colDef.colId]
              ) {
                color = params.lowerAvg;
              }
            }

            return `
                <span style="color:${color}">
                  ${params.valueFormatted || ''}
                </span>
              `;
          };
          col.cellRendererParams = {
            avg: 'blue',
            higherAvg: 'red',
            lowerAvg: 'green',
            normal: 'black'
          };
        }

        columnDefs.push(col);
      }
    }

    this.sum.isTotalRow = true;
    this.avg.isAvgRow = true;

    this.gridOptions = {
      animateRows: false,
      enableColResize: true,
      enableSorting: true,
      floatingFilter: true,
      rowData,
      columnDefs,
      pinnedTopRowData: [this.sum, this.avg]
    };
  }

  get startDate() {
    return this.sharedService.dateFormat(this.benchmarkDetails[0].startDate);
  }

  get endDate() {
    return this.sharedService.dateFormat(this.benchmarkDetails[0].endDate);
  }

  get period() {
    if (
      this.benchmarkDetails[0].startDate &&
      this.benchmarkDetails[0].endDate
    ) {
      const s = moment(this.benchmarkDetails[0].startDate);
      const e = moment(this.benchmarkDetails[0].endDate);
      return s.from(e, true);
    }
  }

  get benchmarkTemplateName() {
    return this.benchmarkTemplate.name;
  }

  get description() {
    return this.benchmarkTemplate.description;
  }
}
