import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { SelectItem, TreeNode } from 'primeng/api';
import { SharedService } from '@shared/shared.service';
import { LocationsService } from '@widgets/locations/services/locations.service';
import { DeviceModelService } from '@widgets/device-models-2/services/device-model.service';
import { DeviceModel } from '@widgets/device-models-2/models/device-model';
import { config } from '@app/config';
import { DeviceProtocol } from '@widgets/device-protocols/models/device-protocol';
import { DeviceProtocolsService } from '@widgets/device-protocols/services/device-protocols.service';
import { DeviceType } from '@widgets/device-types/models/device-type';
import { DevicesCreatorService } from '@app/integrator/services/devices-creator.service';
import { DeviceType as DeviceTypeEnum } from '@app/integrator/enums';
import { DeviceTypesService } from '@widgets/device-types/services/device-types.service';
import { DeviceBrand } from '@widgets/device-brands/models/device-brand';
import { DeviceBrandsService } from '@widgets/device-brands/services/device-brands.service';
import { SCWidgetService } from '@widgets/widget.service';
import { DevicesService } from '@widgets/devices/services/devices.service';
import { Device } from '@app/widgets/devices/models/device';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { DeviceFormService, EXTENDERS } from '@app/integrator/services';
@Component({
  selector: 'sc-devices-creator',
  templateUrl: './devices-creator.component.html',
  styleUrls: ['./devices-creator.component.scss'],
})
export class DevicesCreatorComponent implements OnInit, OnDestroy {
  @Input() config: any;
  @Input() step: number;
  @Input() totalSteps: number;

  deviceForm: FormGroup;
  selectedItems: { [key: string]: SelectItem[] | TreeNode[] } = {};
  fetchingState = 0;
  selectedDeviceType: string;
  siblings: any = {};
  flatLocations: any[];

  private subscribers: { [key: string]: any } = {};
  private siteDevices: Device[] = [];
  private deviceModels: DeviceModel[] = [];
  private deviceBrands: DeviceBrand[] = [];
  private deviceProtocols: DeviceProtocol[] = [];
  private automations: Device[] = [];
  private scaffold: any;
  private deviceTypes: DeviceType[] = [];
  private selectedModel: DeviceModel[] = [];
  private selectedBrand: DeviceBrand[] = [];
  private selectedProtocol: DeviceProtocol[] = [];

  @Output() onSubmit: EventEmitter<object> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onChangeStep: EventEmitter<number> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private locationsService: LocationsService,
    private sharedService: SharedService,
    private deviceModelService: DeviceModelService,
    private deviceProtocolsService: DeviceProtocolsService,
    private deviceCreatorService: DevicesCreatorService,
    private deviceTypesService: DeviceTypesService,
    private deviceBrandsService: DeviceBrandsService,
    private widgetService: SCWidgetService,
    private devicesService: DevicesService,
    private store: Store<fromStore.State>,
    private deviceFormService: DeviceFormService
  ) {}

  ngOnInit() {
    this.initForm();
    this.fetchLocations();
    this.fetchDeviceBrands();
    this.fetchDeviceProtocols();
    this.fetchDeviceModels();
    this.fetchDeviceTypes();
    this.fetchCeosScaffold();
    this.fetchAutomations();

    this.subscribers.devices = this.store.select(fromStore.getAllDevices).subscribe((data) => {
      this.siteDevices = data;
    });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchAutomations() {
    this.fetchingState++;
    const options: any = { siteId: this.sharedService.selectedSite.id };
    this.subscribers.getAutomations = this.devicesService.getCeosDevices(options).subscribe((result: any) => {
      if (result.mappingKeys) {
        this.automations = this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys);
      } else {
        this.automations = result.data;
      }
    });
    this.fetchingState--;
  }

  fetchLocations() {
    this.fetchingState++;
    const options = {
      siteId: this.sharedService.selectedSite.id,
      columns: 'id,idx,description,isActive,isDeleted,parentId',
      tree: true,
    };

    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
      const locations = result.data.filter((d) => d.isActive && !d.isDeleted);
      this.selectedItems.locations = this.sharedService.createMultiSelectItems(locations, 'children', 'id', false);
      this.flatLocations = this.widgetService.flattenTreeRecursively(
        result.mappingKeys ? this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys) : result.data
      );
      this.fetchingState--;
    });
  }

  submit(): void {
    const devices: object[] = [];
    const form = this.deviceForm.getRawValue();
    const type = this.deviceTypes.find((item) => item.key === 'vdh');
    form.locations.map((location) => {
      const locationDetails = this.flatLocations.find(
        //A device can't be created on a floor
        (flatLocation) => flatLocation.id === location && flatLocation.locationTypeId !== 1
      );
      if (locationDetails) {
        let nbDevicesAdded = 0;
        for (let i = 0; i < form.devices.length; i++) {
          let automationId = this.findAutomation(locationDetails, this.selectedModel[i]);
          //Don't check limitation for virtual devices or for extenders
          const hasValidProtocol = !['internal', 'modbus'].includes(this.selectedModel[i].protocol);
          const isExtender = EXTENDERS.includes(this.selectedModel[i].id);
          if (hasValidProtocol && !isExtender) {
            nbDevicesAdded++;
            if (
              automationId &&
              this.deviceFormService.isCeosReachDevicesLimitation(
                automationId,
                this.siteDevices,
                // [this.selectedModel[i].id],
                nbDevicesAdded
              )
            ) {
              automationId = null;
            }
          }
          devices.push({
            automationId,
            brandId: this.selectedBrand[i].id,
            description: `${form.devices[i].name}`,
            deviceModelId: this.selectedModel[i].id,
            deviceTypeId: type.id,
            diffDetectionFactor: null,
            excludeFromLHL: false,
            isActive: true,
            isHidden: false,
            isVirtualDevice: false,
            isVirtualDevicesHolder: true,
            locationId: locationDetails.id,
            locationIdx: locationDetails.idx,
            protocolId: this.selectedProtocol[i].id,
            siteId: this.sharedService.selectedSite.id,
            uniqueId: this.selectedModel[i].unique_id,
            uuid: null,
            properties: form.devices[i].properties.map((prop) => {
              return {
                automationId,
                brandId: prop.brandId,
                description: prop.description,
                deviceModelId: prop.deviceModelId,
                deviceTypeId: prop.deviceTypeId,
                diffDetectionFactor: prop.diffDetectionFactor,
                excludeFromLHL: prop.excludeFromLHL,
                isActive: prop.isActive,
                isHidden: prop.isHidden,
                isVirtualDevice: prop.isVirtualDevice,
                isVirtualDevicesHolder: prop.isVirtualDevicesHolder,
                locationId: locationDetails.id,
                locationIdx: locationDetails.idx,
                protocolId: prop.protocolId,
                siteId: prop.siteId,
                uniqueId: prop.uniqueId,
              };
            }),
          });
        }
      }
    });
    this.onSubmit.emit({ devices, isAutomation: false });
  }

  cancel() {
    this.onCancel.emit();
  }

  onNextClick() {
    this.step++;
    this.onChangeStep.emit(this.step);
  }

  onBackClick() {
    this.step--;
    this.onChangeStep.emit(this.step);
  }

  onDeviceModelSelect(modelId, index) {
    this.devices.at(index).patchValue({
      name: this.deviceModels.find((deviceModel) => deviceModel.id === modelId).display_name,
      deviceModelId: modelId,
    });
    this.getDeviceDetails(index);
    this.createProperties(index);
  }

  getPropertyName(deviceTypeId: number) {
    const type = this.deviceTypes.find((item) => item.id === deviceTypeId);
    if (type) {
      return type.name;
    }
    return '';
  }

  private findAutomation(location: any, deviceModel: DeviceModel): number {
    let locationId: number = null;
    location.locationTypeKey === 'a' ? (locationId = location.parentId) : (locationId = location.id);
    const automation = this.automations.filter((a) => a.isActive && !a.isDeleted && a.locationId === locationId)[0];
    if (automation) {
      // if (!this.deviceFormService.isCeosReachDevicesLimitation(automation.id, this.siteDevices, [deviceModel.id])) {
      switch (deviceModel.library) {
        case 'ozw':
          if (automation.uniqueId === 'sc-ceos') {
            return automation.id;
          } else {
            return null;
          }
        case 'zwavejs':
          if (automation.uniqueId === 'sc-ceos-v2') {
            return automation.id;
          } else {
            return null;
          }
        case 'm2m':
          if (automation.modbusActive) {
            return automation.id;
          } else {
            return null;
          }
        default:
          return automation.id;
      }
    }
    // }
    return null;
  }

  private initForm() {
    this.deviceForm = this.formBuilder.group({
      locations: [null, Validators.required],
      devices: this.formBuilder.array([]),
    });
  }

  private fetchDeviceModels() {
    this.fetchingState++;
    this.subscribers.fetchDevices = this.deviceModelService.getDeviceModels().subscribe((result: any) => {
      this.deviceModels = result.data.filter((item) => item.is_active && !item.is_deleted);
      this.updateDeviceModelSelectItems();
      this.fetchingState--;
    });
  }

  private fetchDeviceBrands() {
    this.fetchingState++;
    this.subscribers.fetchDeviceBrands = this.deviceBrandsService.getDeviceBrands().subscribe((result: any) => {
      this.deviceBrands = result.data.filter((item) => item.isActive && !item.isDeleted);
      this.fetchingState--;
    });
  }

  private fetchDeviceProtocols() {
    this.fetchingState++;
    this.subscribers.fetchDeviceProtocols = this.deviceProtocolsService
      .getDeviceProtocols()
      .subscribe((result: any) => {
        this.deviceProtocols = result.data.filter((item) => item.isActive && !item.isDeleted);
        this.updateDeviceModelSelectItems();
        this.fetchingState--;
      });
  }

  private fetchDeviceTypes() {
    this.fetchingState++;
    this.subscribers.fetchDeviceTypes = this.deviceTypesService.getDeviceTypes().subscribe((result: any) => {
      this.deviceTypes = result.data.filter((item) => item.isActive && !item.isDeleted);
      this.prepareSiblings();
      this.fetchingState--;
    });
  }

  private updateDeviceModelSelectItems() {
    if (!this.deviceProtocols) {
      return;
    }

    this.selectedItems.deviceModels = this.deviceModels
      .filter((item) => !item.is_automation)
      .map((item) => ({
        label: this.getDeviceModelLabel(item),
        value: item.id,
      }))
      //Sort by name
      .sort((x, y) => {
        if (x.label < y.label) {
          return -1;
        }
        if (x.label > y.label) {
          return 1;
        }
        return 0;
      });
  }

  private getDeviceModelLabel(model: DeviceModel) {
    let label = model.product_name;

    if (this.selectedDeviceType === DeviceTypeEnum.Device) {
      const frequencies = config().selectItems.zwaveFrequencies;
      const frequency = frequencies.find((item) => item.value === model.frequency);
      const protocol = this.deviceProtocols.find(
        (item) => item.isActive && !item.isDeleted && item.key === model.protocol
      );

      if (protocol) {
        label += `[${protocol.name}${frequency ? '' : ']'}`;
      }
      if (frequency) {
        label += `${protocol ? ',' : ''} ${frequency.label}]`;
      }
    }

    return label;
  }

  private fetchCeosScaffold() {
    this.fetchingState++;
    this.subscribers.fetchCeosScaffold = this.sharedService.getCeosScaffold().subscribe({
      next: (result: any) => {
        this.scaffold = result.data;
        this.prepareSiblings();
        this.fetchingState--;
      },
    });
  }

  private prepareSiblings() {
    if (!this.deviceTypes || !this.deviceTypes.length || !this.scaffold) {
      return;
    }

    for (const type of this.deviceTypes) {
      const scaffoldType = this.scaffold.device_types[type.key];

      if (scaffoldType && scaffoldType.siblings && scaffoldType.siblings.length && !this.siblings[type.id]) {
        const siblingTypes = this.deviceTypes.filter(
          (item) => [type.key, ...scaffoldType.siblings].indexOf(item.key) >= 0
        );
        this.siblings[type.id] = siblingTypes.map((item) => ({ label: item.name, value: item.id }));
      }
    }
  }

  getDeviceDetails(index) {
    const formValue = this.devices.at(index).value;
    const selectedModel = this.deviceModels.find((item) => item.id === formValue.deviceModelId);
    if (selectedModel) {
      const selectedBrand = this.deviceBrands.find((item) => item.key === selectedModel.brand);
      const selectedProtocol = this.deviceProtocols.find((item) => item.key === selectedModel.protocol);
      this.selectedModel && this.selectedModel[index]
        ? (this.selectedModel[index] = selectedModel)
        : this.selectedModel.push(selectedModel);
      this.selectedBrand && this.selectedBrand[index]
        ? (this.selectedBrand[index] = selectedBrand)
        : this.selectedBrand.push(selectedBrand);
      this.selectedProtocol && this.selectedProtocol[index]
        ? (this.selectedProtocol[index] = selectedProtocol)
        : this.selectedProtocol.push(selectedProtocol);
    }
  }

  private createProperties(index) {
    if (this.selectedModel[index]) {
      const properties = JSON.parse(this.selectedModel[index].properties as string);
      if (properties && properties.length) {
        const vds = [];

        for (const prop of properties) {
          const type = this.deviceTypes.find((item) => item.key === prop.type);

          if (type) {
            const vd: any = {
              description: prop.display_name,
              siteId: this.sharedService.selectedSite.id,
              brandId: this.selectedBrand[index].id,
              protocolId: this.selectedProtocol[index].id,
              deviceModelId: this.selectedModel[index].id,
              deviceTypeId: type.id,
              uniqueId: prop.unique_id,
              diffDetectionFactor: prop.min_diff_threshold,
              excludeFromLHL: false,
              isActive: true,
              isHidden: false,
            };
            vds.push(vd);
          }
        }
        this.deviceCreatorService.patchDeviceProperties(this.devices.at(index) as FormGroup, vds);
      }
    }
  }

  addDevice() {
    for (let i = 0; i < this.devices.length; i++) {
      this.devices.at(i).patchValue({ expanded: false });
    }
    this.devices.push(
      this.formBuilder.group({
        name: [`${this.sharedService.getTranslation('DEVICE')} ${this.devices.length + 1}`, Validators.required],
        expanded: true,
        deviceModelId: [null, Validators.required],
        properties: this.formBuilder.array([]),
      })
    );
  }

  removeDevice(index: number) {
    if (this.devices.length > 0) {
      this.devices.removeAt(index);
    }
  }

  expandDevice(index: number) {
    for (let i = 0; i < this.devices.length; i++) {
      i === index
        ? this.devices.at(index).patchValue({ expanded: !this.devices.at(index).get('expanded').value })
        : this.devices.at(i).patchValue({ expanded: false });
    }
  }

  reduceAllDevices() {
    for (let i = 0; i < this.devices.length; i++) {
      this.devices.at(i).patchValue({ expanded: false });
    }
  }

  get devices(): FormArray {
    return this.deviceForm.get('devices') as FormArray;
  }

  get allowNext() {
    switch (this.step) {
      case 1: {
        if (this.deviceForm.valid && this.devices?.length > 0) {
          return true;
        }
        return false;
      }
      default:
        return true;
    }
  }
}
