import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Rule, RuleTemplate } from '@app/ceos-rule-editor/models';
import { CustomFunction } from '@widgets/custom-functions/models/custom-function';
import { DeviceDetails } from '@widgets/devices/models/device-details';
import { LocationDetails } from '@widgets/locations/models/location-details';
import { Site } from '@widgets/sites/models/site';
import { SiteType } from '@widgets/site-types/models/site-type';
import { CeosRulesService } from '@widgets/rules/services/ceos-rules.service';
import { SharedService } from '@shared/shared.service';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { ReportTemplate } from '@app/widgets/report-templates/interfaces';
import { ReportTemplatesService } from '@widgets/report-templates/services/report-templates.service';

interface CeosRuleDetails extends Rule {
  [key: string]: any;
}

@Component({
  selector: 'sc-ceos-rule-form',
  templateUrl: 'ceos-rule-form.component.html',
  styleUrls: ['ceos-rule-form.component.scss'],
})
export class CeosRuleFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode: boolean;
  errorMessage: string;
  isSubmitting = false;
  fetchingState = 0;

  rule: CeosRuleDetails;
  devices: any[] = [];
  locations: any[] = [];
  rules: CeosRuleDetails[] = [];
  ruleTemplates: RuleTemplate[] = [];
  customFunctions: CustomFunction[] = [];
  customGlobals: any[] = [];
  deviceTypes: any[] = [];
  notificationTemplates: any = [];
  locationProperties: any[] = [];
  scaffold: any = {};
  selectedDevice: DeviceDetails;
  reportTemplates: ReportTemplate[] = [];
  private reportTemplatesService: ReportTemplatesService;

  private currentSiteType: SiteType;
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private ceosRulesService: CeosRulesService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit() {
    this.selectedSite = this.sharedService.selectedSite;
    this.currentSiteType = this.sharedService.currentSiteType;

    // get device details
    this.subscribers.selectedDevice = this.store.select(fromStore.getDeviceDetials).subscribe((result) => {
      if (result) {
        this.selectedDevice = result;

        if (!this.editMode) {
          // patch automation id on create mode
          this.rule = {
            automation_id: this.selectedDevice.id,
          };

          this.getRuleEditorData();
        }
      }
    });

    // check edit mode
    if (this.data && this.data.id) {
      this.editMode = true;
      this.fetchRule(this.data.id);
    }

    this.fetchCeosScaffold();
    this.fetchReportTemplates();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }

  private fetchCeosScaffold() {
    this.fetchingState++;
    this.subscribers.getCeosScaffold = this.sharedService.getCeosScaffold().subscribe({
      next: (result: any) => {
        this.scaffold = result.data;
        this.deviceTypes = Object.keys(this.scaffold.device_types)
          .filter((key) => key !== 'a' && key !== 'vdh')
          .map((key) => ({
            ...this.scaffold.device_types[key],
            key,
          }));
        this.locationProperties = Object.keys(this.scaffold.location_properties).map((key) => ({
          ...this.scaffold.location_properties[key],
          key,
        }));
        this.fetchingState--;
      },
    });
  }

  private getRuleEditorData() {
    this.fetchingState++;
    this.subscribers.getRuleEditorData = this.ceosRulesService
      .getRuleEditorData(this.selectedDevice.idx)
      .subscribe((result: any) => {
        const data = result.data;

        this.notificationTemplates = data.notificationTemplates.filter((d) => {
          const siteIds = d.siteIds || [];
          return d.isActive && !d.isDeleted && (d.isPublic || siteIds.indexOf(this.selectedSite.id) >= 0);
        });

        const allowed = (this.currentSiteType && (this.currentSiteType.ruleTemplates as number[])) || [];
        this.ruleTemplates = data.ruleTemplates.filter(
          (item: any) => !item.is_deleted && allowed.indexOf(item.id) >= 0
        );

        this.customFunctions = data.customFunctions.filter((item: CustomFunction) => !item.is_deleted);
        this.rules = data.rules.filter((item: any) => !item.is_deleted);

        this.locations = data.locations
          .filter((item: LocationDetails) => !item.isDeleted)
          .map((item: LocationDetails) => {
            return {
              automation_id: item.automationId,
              idx: item.idx,
              name: item.description,
              command_allowed: !!item.isSubscribed,
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));

        this.devices = data.devices
          .filter((item: DeviceDetails) => !item.isDeleted)
          .map((item: DeviceDetails) => {
            return {
              automation_id: item.automationId,
              idx: item.idx,
              name: item.description,
              device_type: item.deviceTypeKey,
              categories: null,
              parent_idx: item.parentIdx,
              command_allowed: !!item.isSubscribed,
            };
          });

        this.fetchingState--;
      });
  }

  private fetchReportTemplates() {
    this.fetchingState++;
    this.subscribers.fetchReportTemplates = this.reportTemplatesService.getReportTemplates().subscribe({
      next: (result: any) => {
        this.reportTemplates = result.data.filter((d) => {
          return d.is_active && !d.is_deleted;
        });
        this.fetchingState--;
      },
    });
  }

  private fetchRule(id: number) {
    this.fetchingState++;
    this.subscribers.getCeosRule = this.ceosRulesService.getCeosRule(id).subscribe({
      next: (result: any) => {
        this.rule = result.data;
        this.rule.affected_devices = JSON.parse(result.data.affected_devices as string);
        this.rule.affected_locations = JSON.parse(result.data.affected_locations as string);
        this.rule.commands = JSON.parse(result.data.commands as string);
        this.rule.conditions = JSON.parse(result.data.conditions as string);
        this.fetchingState--;
      },
    });
  }

  submit() {
    this.errorMessage = null;
    this.isSubmitting = true;

    if (this.editMode) {
      this.update(this.rule.id, this.rule);
    } else {
      this.create(this.rule);
    }
  }

  private apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('RULE');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  private apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  private create(data: Rule) {
    this.subscribers.create = this.ceosRulesService.createCeosRule(data).subscribe({
      next: this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'CREATE_FAIL'),
    });
  }

  private update(id: number, data: Rule) {
    this.subscribers.update = this.ceosRulesService.updateCeosRule(id, data).subscribe({
      next: this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
      error: this.apiCallError.bind(this, 'UPDATE_FAIL'),
    });
  }

  delete() {
    this.subscribers.delete = this.ceosRulesService.deleteCeosRule(this.rule.id).subscribe({
      next: this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
      error: this.apiCallError.bind(this, 'DELETE_FAIL'),
    });
  }
}
