import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'custom-form',
  templateUrl: 'custom-form.component.html'
})
export class CustomFormComponent implements OnInit {
  @Input() group: FormGroup;

  constructor() { }

  ngOnInit() {}
}
