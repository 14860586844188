import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { faCaretDown, faCaretRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CustomFunctionFormService } from '../services/custom-function-form.service';

@Component({
  selector: 'sc-function-parameters',
  templateUrl: './function-parameters.component.html',
  styleUrls: ['./function-parameters.component.scss'],
})
export class FunctionParametersComponent implements OnInit {
  addIcon = faPlus;
  arrowIcon = faCaretDown;
  parametersForm: FormArray;
  show = true;

  @Input()
  form: FormGroup;

  @Input()
  dataSources: any = {};

  @Input()
  selectItems: any = {};

  constructor(private customFunctionFormService: CustomFunctionFormService) {}

  ngOnInit(): void {
    this.parametersForm = this.form.get('parameters') as FormArray;
  }

  onToggle() {
    this.show = !this.show;
    if (this.show) {
      this.arrowIcon = faCaretDown;
    } else {
      this.arrowIcon = faCaretRight;
    }
  }

  onAddParameter() {
    this.parametersForm.push(this.customFunctionFormService.initCustomFunctionParameterForm());
  }

  onRemoveParameter(i) {
    this.parametersForm.removeAt(i);
  }
}
