import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserRole } from '../models/user-role';

import { config } from '@app/config';

@Injectable()
export class UserRolesService {
  private apiUrl = config().apiUrl + '/roles';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): UserRole {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    if (typeof newData.roleId !== 'undefined') {
      delete newData.roleId;
    }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  get(id?: number) {
    return this.httpClient.get(this.apiUrl + (id ? '/' + id : ''));
  }

  create(data: UserRole) {
    return this.httpClient.post(this.apiUrl, data);
  }

  update(id: number, data: UserRole) {
    return this.httpClient.patch(this.apiUrl + '/' + id, this.verifyData(data));
  }

  delete(id: number) {
    return this.httpClient.patch(this.apiUrl + '/' + id, {
      isDeleted: true,
      isActive: false
    });
  }
}
