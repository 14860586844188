import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { User } from '../models/user';

import { UsersService } from '../services/users.service';
import { SharedService } from '@shared/shared.service';

import { CustomValidators } from '@sc/form/custom-validators';

import { config } from '@app/config';

@Component({
  selector: 'sc-user-password-form',
  templateUrl: 'user-password-form.component.html',
  styleUrls: ['./user-password-form.component.scss'],
})
export class UserPasswordFormComponent implements OnInit, OnDestroy {
  @Input()
  data: User;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  form: FormGroup;
  errorMessage: string;
  isSubmitting = false;

  private passwordPattern = config().regexPatterns.password;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  initForm() {
    this.form = this.formBuilder.group(
      {
        current: [null, Validators.required],
        new: [
          null,
          Validators.compose([
            // is required
            Validators.required,
            // minimum length of 8 characters
            Validators.minLength(8),
            // password strength
            CustomValidators.pattern(this.passwordPattern, 'passwordStrength'),
          ]),
        ],
        repeat: [null, Validators.required],
      },
      {
        // check is repeat password match new password
        validator: CustomValidators.compare('new', 'repeat', 'passwordMatch'),
      }
    );
  }

  submit() {
    this.isSubmitting = true;
    this.subscribers.CHANGE_PASSWORD = this.usersService.setPassword(this.data.id, this.form.value).subscribe(
      (result: any) => {
        // show notification
        const title = this.sharedService.getTranslation('CHANGE_PASSWORD');
        const message = this.sharedService.getTranslation('SUCCESS');
        this.sharedService.notify(title, message, 'success');

        // close modal and return added data
        this.onClose.emit(result.data);
        // enabled the form submitting
        this.isSubmitting = false;
      },
      (error: any) => {
        // set error message
        this.errorMessage = error;
        // enabled the form submitting
        this.isSubmitting = false;
      }
    );
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
