import { Action } from '@ngrx/store';

import { Widget } from '@app/core2/models/page-section-widget';

// LOAD WIDGETS
export const LOAD_WIDGETS = '[Pages] Load Widgets';
export const LOAD_WIDGETS_FAIL = '[Pages] Load Widgets Fail';
export const LOAD_WIDGETS_SUCCESS = '[Pages] Load Widgets Success';

export class LoadWidgets implements Action {
  readonly type = LOAD_WIDGETS;
}

export class LoadWidgetsFail implements Action {
  readonly type = LOAD_WIDGETS_FAIL;
  constructor(public payload: any) {}
}

export class LoadWidgetsSuccess implements Action {
  readonly type = LOAD_WIDGETS_SUCCESS;
  constructor(public payload: Widget[]) {}
}

// SHOW / HIDE WIDGET FORM
export const HIDE_WIDGET_FORM = '[Pages] Hide Widget Form';
export const SHOW_WIDGET_FORM = '[Pages] Show Widget Form';

export class HideWidgetForm implements Action {
  readonly type = HIDE_WIDGET_FORM;
}

export class ShowWidgetForm implements Action {
  readonly type = SHOW_WIDGET_FORM;
}

// VISUALISE WIDGETS
export const VISUALISE_WIDGETS = '[Pages] Visualise Widgets';

export class VisualiseWidgets implements Action {
  readonly type = VISUALISE_WIDGETS;
  constructor(public payload: Widget[]) {}
}

// SAVE CUSTOM SETTING
export const SAVE_CUSTOM_SETTING = '[Pages] Save Custom Setting';
export const SAVE_CUSTOM_SETTING_FAIL = '[Pages] Save Custom Setting Fail';
export const SAVE_CUSTOM_SETTING_SUCCESS =
  '[Pages] Save Custom Setting Success';

export class SaveCustomSetting implements Action {
  readonly type = SAVE_CUSTOM_SETTING;
  constructor(public payload: Widget) {}
}

export class SaveCustomSettingFail implements Action {
  readonly type = SAVE_CUSTOM_SETTING_FAIL;
  constructor(public payload: any) {}
}

export class SaveCustomSettingSuccess implements Action {
  readonly type = SAVE_CUSTOM_SETTING_SUCCESS;
  constructor(public payload: Widget) {}
}

// ACTION TYPES
export type WidgetsAction =
  | LoadWidgets
  | LoadWidgetsFail
  | LoadWidgetsSuccess
  | HideWidgetForm
  | ShowWidgetForm
  | SaveCustomSetting
  | SaveCustomSettingFail
  | SaveCustomSettingSuccess
  | VisualiseWidgets;
