<div class="report-command container">
  <p-panel header="Essential" [toggleable]="true">
    <div
      class="report-command-body"
      *ngIf="commandForm"
      [formGroup]="commandForm"
    >
      <div class="p-fluid p-formgrid p-grid p-inputtext-sm">
        <div class="p-field p-col-12 p-md-6" *ngIf="isTemplate">
          <label class="input-label" for="name">
            {{ "NAME" | translate }}
          </label>

          <input type="text" pInputText inputId="name" formControlName="name" />
        </div>

        <div class="p-field p-col-12 p-md-6">
          <label class="input-label" for="target">
            {{ "REPORT_TEMPLATE" | translate }}
          </label>

          <p-dropdown
            inputId="target"
            [options]="selectItems?.reportTemplates"
            formControlName="target"
            [placeholder]="'CCP_COMMON_SELECT' | translate"
            [filter]="true"
            appendTo="body"
            [disabled]="!isTemplate"
          ></p-dropdown>

          <div *ngIf="isTemplate">
            <p-checkbox
              inputId="is_target_editable"
              [binary]="true"
              [formControl]="commandForm?.get('is_target_editable')"
              [label]="'EDITABLE' | translate"
            ></p-checkbox>
          </div>
        </div>
      </div>

      <div *ngIf="!isTemplate">
        <label class="input-label">{{ "VARIABLES" | translate }}</label>

        <div *ngFor="let ctrl of variableForm.controls; let i = index">
          <sc-report-variable
            [control]="ctrl"
            [key]="i"
            [dataSources]="dataSources"
            [selectItems]="selectItems"
          ></sc-report-variable>
        </div>
      </div>
    </div>
  </p-panel>
  <p-panel header="Advanced" [toggleable]="true">
    <sc-command-options
      *ngIf="commandForm"
      [form]="commandForm"
      [isTemplate]="isTemplate"
    ></sc-command-options>
  </p-panel>
</div>
