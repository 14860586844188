<p-blockUI [blocked]="editMode" [target]="widgetContainer">
  <div
    class="sc-flex justify-content-center align-items-center"
    [ngStyle]="{ height: '100%' }"
  >
    <div
      class="sc-clickable widget-drag-handler"
      pTooltip="{{ 'MOVE' | translate }} {{ 'WIDGET' | translate }}"
      tooltipPosition="top"
      appendTo="body"
    >
      <i class="white-text fa fa-arrows fa-fw pulse infinite fa-5x"></i>
    </div>

    <div
      class="sc-clickable"
      (click)="deleteWidget.emit()"
      pTooltip="{{ 'DELETE' | translate }} {{ 'WIDGET' | translate }}"
      tooltipPosition="top"
      appendTo="body"
    >
      <i class="white-text fa fa-times fa-fw pulse infinite fa-5x"></i>
    </div>
  </div>
</p-blockUI>

<p-card #widgetContainer styleClass="widget-container">  
  <ng-template scWidget></ng-template>
</p-card>
