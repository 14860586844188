<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'ROOMS_OCCUPANCY_HISTORY' | translate }} -->
        {{ widgetName }}
      </h4>

      <!-- <div class="sc-widget-menu"></div> -->
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body direction-row">
    <div class="sc-flex">
      <plotly-plot
        [data]="chartData"
        [layout]="chartLayout"
        [useResizeHandler]="true"
        [config]="{ displayModeBar: false }"
      ></plotly-plot>
    </div>

    <div class="sc-flex direction-column grow-1 locations-occupancy-infos">
      <div class="sc-flex" *ngFor="let d of lastThreeDaysData">
        <div class="sc-flex grow-2 justify-content-end">
          {{ d?.label | translate: d?.labelParams }}
        </div>

        <div class="sc-flex grow-2 justify-content-end">
          <sc-modal-trigger [modalConfig]="getDetailsModal(d)">
            <div class="sc-clickable">
              {{ d?.value }} {{ "ROOMS" | translate }}
            </div>
          </sc-modal-trigger>
        </div>

        <div class="sc-flex" style="width: 60px">
          <small>&nbsp;({{ d?.percentage }} %) </small>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="sc-widget-footer" *ngIf="detailsModal"> -->
  <!-- <div *ngIf="detailsModal"> -->
  <!-- <sc-modal-trigger [modalConfig]="detailsModal">
      <a class="sc-clickable">
        {{ 'DETAILS' | translate }}
        <i class="fa fa-angle-right"></i>
      </a>
    </sc-modal-trigger> -->
  <!-- </div> -->
  <!-- </div> -->
</ng-template>
