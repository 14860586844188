<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        <!-- {{ 'LIVE_ROOMS_OCCUPANCY' | translate }} -->
        {{ widgetName }}
      </h4>

      <!-- <div class="sc-widget-menu"></div> -->
    </div>

    <ng-container
      *ngIf="isFirstTime; then spinner; else content"
    ></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body">
    <div *ngIf="chartData" class="w-100">
      <plotly-plot
        [data]="chartData"
        [layout]="chartLayout"
        [useResizeHandler]="true"
        [style]="{
          position: 'relative',
          width: widgetWidth,
          height: widgetHeight
        }"
        [config]="{ displayModeBar: false }"
      ></plotly-plot>
    </div>
  </div>
</ng-template>
