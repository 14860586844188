import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SceneCommand, SceneCommandParameter } from '../models/scene';

@Injectable()
export class SceneFormsService {
  constructor(private formBuilder: FormBuilder) {}

  /**
   * Create command form group
   */
  initCommandFormGroup(): FormGroup {
    const formControls = {
      deviceTypeId: null,
      deviceId: [null, Validators.required],
      action: null,
      parameters: this.formBuilder.array([]),
      delay: null
    };

    return this.formBuilder.group(formControls);
  }

  /**
   * Set command form array
   * @param form
   * @param data
   */
  setCommandFormArray(form: FormGroup, data: SceneCommand[]) {
    const formGroups = data.map(item => {
      const fg = this.initCommandFormGroup();
      fg.patchValue(item);
      const itemWithoutParams = Object.assign({}, item);
      delete itemWithoutParams.parameters;
      if (item.parameters && item.parameters.length > 0) {
        const parameterFormGroups = [];
        item.parameters.forEach(parameter => {
          if (parameter) {
            parameterFormGroups.push(this.formBuilder.group(parameter));
          }
        });
        if (parameterFormGroups.length > 0) {
          fg.setControl(
            'parameters',
            this.formBuilder.array(parameterFormGroups)
          );
        }
      }
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('commands', formArray);
  }

  /**
   * Create command parameter form group
   */
  initParameterFormGroup(): FormGroup {
    const formControls = {
      key: null,
      value: null
    };

    return this.formBuilder.group(formControls);
  }

  /**
   * Set command parameter form array
   * @param form
   * @param data
   */
  setParameterFormArray(form: FormGroup, data: SceneCommandParameter[]) {
    const formGroups = data.map(item => {
      const fg = this.initParameterFormGroup();
      fg.patchValue(item);
      return fg;
    });

    const formArray = this.formBuilder.array(formGroups);
    form.setControl('parameters', formArray);
  }

  /**
   * Create scene form group
   */
  initSceneForm(): FormGroup {
    const formControls: any = {
      // sceneId: [null, Validators.required],
      id: { value: null, disabled: true },
      name: [null, Validators.required],
      sceneTemplateId: [null, Validators.required],
      companyId: null,
      siteId: null,
      locationId: null,
      description: null,
      commands: this.formBuilder.array([]),
      isActive: false,
      isDeleted: false
    };

    return this.formBuilder.group(formControls);
  }
}
