import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sc-location-qr-code',
  templateUrl: 'location-qr-code.component.html'
})
export class LocationQrCodeComponent implements OnInit {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  get qrcodeData() {
    return this.data.id + '';
  }
}
