import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ColDef } from 'ag-grid';
import { AppStyleTemplate } from '../models/app-style-template';
import { ModalConfig } from '@sc/modal/modal-config';

import { AppStyleTemplatesService } from '../services/app-style-templates.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '../../_shared/checkbox-cell/checkbox-cell.component';
import { AppStyleTemplateActionCellComponent } from '../app-style-template-action-cell/app-style-template-action-cell.component';

@Component({
  selector: 'sc-app-style-templates-table',
  templateUrl: './app-style-templates-table.component.html'
})
export class AppStyleTemplatesTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: AppStyleTemplate[];
  formConfig: ModalConfig;
  tableTitle = 'APP_STYLE_TEMPLATES';

  private subscribers: { [key: string]: any } = {};

  constructor(
    private appStyleTemplatesService: AppStyleTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.columns = this.createColumns();
    this.formConfig = {
      name: 'AppStyleTemplateForm',
      options: { modalTitle: 'APP_STYLE_TEMPLATE_FORM_TITLE', fullScreen: true }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.dataset = this.appStyleTemplatesService
      .getAppStyleTemplates()
      .subscribe({
        next: (result: any) => {
          this.dataset = result.data;
        }
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'description',
        headerName: 'DESCRIPTION',
        field: 'description'
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        suppressSorting: true,
        suppressFilter: true,
        pinned: 'right',
        cellRendererFramework: AppStyleTemplateActionCellComponent
      }
    ];
  }
}
