import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromStore.State>, private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = req.url;
    const currentRoute = this.router.url;
    const skipped = this.isIgnoreRoute(currentRoute) || this.isIgnoreURL(requestUrl);

    // skip interceptor when call api `auth` and `translate`
    if (skipped) {
      return next.handle(req);
    }

    if (!this.authService.token) {
      this.store.dispatch(new fromStore.Logout());
      return observableThrowError('Invalid Token.');
    }

    // add authorization to request headers
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + this.authService.token),
    });

    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
          return event;
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.store.dispatch(new fromStore.Logout());
            return observableThrowError(err);
          }
          if (err.error && err.error.message) {
            return observableThrowError(err.error.message);
          }
          return observableThrowError(err.error);
        }
        return observableThrowError({
          message: 'something wrong, please contact admin!',
        });
      })
    );
  }

  private isIgnoreURL(requestUrl: string) {
    // list of APIs where token is not needed
    const ignoreURLs = [/\/auth$/, /\/auth2$/, /\/auth\/unblock/, /\/auth\/twoFactor/, /\/translate\/[a-z]{2}$/];

    for (const url of ignoreURLs) {
      if (requestUrl.match(url)) {
        return true;
      }
    }

    return false;
  }

  private isIgnoreRoute(currentRoute: string) {
    // list of routes where token doesn't exist (pre-login)
    const routeWhiteList = ['/signup', '/signup/form'];
    return routeWhiteList.indexOf(currentRoute) >= 0;
  }
}
