<div class="input-code" *ngIf="inputControl">
  <sc-field-label
    *ngIf="label"
    [for]="formField"
    [title]="label"
    [fieldControl]="inputControl"
  ></sc-field-label>

  <div
    class="input-code__input"
    [ngClass]="{ 'input-code__input--error': hasError }"
  >
    <ngx-codemirror
      [formControl]="inputControl"
      [options]="options"
      (cursorActivity)="inputControl?.updateValueAndValidity()"
    ></ngx-codemirror>
  </div>

  <sc-field-error
    *ngIf="hasError"
    [fieldControl]="inputControl"
  ></sc-field-error>
</div>
