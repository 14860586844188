import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'sc-confirm-trigger',
  templateUrl: './confirm-trigger.component.html',
  styleUrls: ['./confirm-trigger.component.scss'],
})
export class SCConfirmTriggerComponent implements OnInit {
  @Input()
  buttonIcon: string;
  @Input()
  buttonLabel: string;
  @Input()
  buttonClass: string;
  @Input()
  disabled: boolean;
  @Input()
  displayInline: boolean;
  @Input()
  icon: string;
  @Input()
  header: string;
  @Input()
  message: string;
  @Input()
  tooltipContent: string;
  @Input()
  tooltipEscape: boolean;
  @Input()
  tooltipPosition: 'top' | 'left' | 'bottom' | 'right';

  @Output()
  onAccept = new EventEmitter();
  @Output()
  onReject = new EventEmitter();

  constructor(private confirmationService: ConfirmationService) {}

  ngOnInit() {
    this.buttonClass = this.buttonClass;
    this.tooltipContent = this.tooltipContent || '';
    this.tooltipEscape = this.tooltipEscape || false;
    this.tooltipPosition = this.tooltipPosition || 'top';
  }

  clickHandler() {
    this.confirmationService.confirm({
      icon: 'fa ' + (this.icon || ''),
      header: this.header || 'Confirmation',
      message: this.message || 'Are you sure that you want to proceed?',
      accept: () => {
        this.onAccept.emit();
      },
      reject: () => {
        this.onReject.emit();
      },
    });
  }
}
