import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

// 3rd-party
import { AgGridModule } from 'ag-grid-angular';
import { DndModule } from '@beyerleinf/ngx-dnd';
import { GridsterModule } from 'angular-gridster2';
import { QRCodeModule } from 'angularx-qrcode';
import { PlotlyViaWindowModule } from 'angular-plotly.js';
import { PrimeModule } from '@app/prime/prime.module';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule, FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

// SC
import { SandcModule } from '@sc/sandc.module';
import { AuthModule } from '@app/auth/auth.module';

import { DependencyWarningComponent } from './dependency-warning/dependency-warning.component';

// services
import { SharedService } from './shared.service';

const MODULES: any[] = [
  // angular
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  DragDropModule,

  // 3rd-party
  TranslateModule,
  AgGridModule,
  DndModule,
  GridsterModule,
  QRCodeModule,
  PrimeModule,
  PlotlyViaWindowModule,
  FontAwesomeModule,

  // sc
  AuthModule,
  SandcModule,
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES],
  declarations: [DependencyWarningComponent],
  entryComponents: [DependencyWarningComponent],
  providers: [SharedService],
})
export class SharedModule {
  constructor(faConfig: FaConfig, library: FaIconLibrary) {
    library.addIconPacks(fas);
    faConfig.defaultPrefix = 'fas';
    faConfig.fixedWidth = true;
  }
}
