<div class="modifier">
  <div class="modifier-header">
    <div class="modifier-header__title">
      <div class="modifier-header__toggle" (click)="onToggle()">
        <fa-icon
          [icon]="show ? 'caret-down' : 'caret-right'"
          [fixedWidth]="true"
        ></fa-icon>
      </div>

      {{ "CCP_RULE_MODIFIERS" | translate }}
    </div>

    <div class="modifier-header__actions">
      <button
        type="button"
        class="modifier-header__action-button"
        (click)="onAddModifier()"
      >
        <fa-icon icon="plus" [fixedWidth]="true"></fa-icon>
      </button>
    </div>
  </div>

  <div
    class="modifier-body"
    [ngClass]="{ 'modifier-body--closed': !show }"
    *ngIf="modifierForm"
  >
    <div *ngFor="let ctrl of modifierForm.controls; let i = index">
      <sc-modifier-item
        [control]="ctrl"
        [modifiers]="modifiers"
        [dataSources]="dataSources"
        [selectItems]="selectItems"
        [isTemplate]="isTemplate"
        (removed)="onRemoveModifier(i)"
      ></sc-modifier-item>
    </div>
  </div>
</div>
