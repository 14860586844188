<div class="location-history">
  <!-- <div class="location-history-header">
    <div class="sc-flex align-center">
      <h4 class="header-title">
        {{ 'HISTORY' | translate }}
      </h4>
    </div>
  </div> -->

  <div class="location-history-body">
    <sc-table
      [columns]="columns"
      [config]="config"
      (afterInit)="afterInitTable($event)"
      (reloadData)="fetchDataset()"
      (displayColumnsChange)="updateVisibleColumns($event)"
      [title]="tableTitle"
      [lazyload]="true"
      [toolbarItems]="toolbarItems"
      [showDateFilter]="true"
      (filterDateChange)="onDateSelect($event)"
      [hideIcon]="true"
    ></sc-table>
  </div>
</div>
