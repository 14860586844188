<div
  *ngIf="fetchingState === 0; else loadingIndicator"
  style="padding: 30px; min-width: 1200px"
>
  <div [ngSwitch]="step">
    <div *ngSwitchCase="1">
      <ng-template [ngTemplateOutlet]="step1"></ng-template>
    </div>
    <div *ngSwitchCase="2">
      <ng-template [ngTemplateOutlet]="step2"></ng-template>
    </div>
  </div>
</div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #step1>
  <div class="px-3 pb-5">
    <p class="step1-description">
      {{ "DEVICE_MODEL_2_STEP_1_TEXT" | translate }}
    </p>

    <ul class="type-selector">
      <li class="type-selector__item" (click)="onSelectDeviceType('a')">
        {{ "AUTOMATION" | translate }}
      </li>

      <li class="type-selector__item" (click)="onSelectDeviceType('d')">
        {{ "CONNECTED_DEVICE" | translate }}
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #step2>
  <div [ngSwitch]="selectedDeviceType">
    <div *ngSwitchCase="'a'">
      <ng-template [ngTemplateOutlet]="automationForm"></ng-template>
    </div>
    <div *ngSwitchCase="'d'">
      <ng-template [ngTemplateOutlet]="deviceForm"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #automationForm>
  <div [formGroup]="form" *ngIf="form">
    <!-- HEADER -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-5">
        <sc-text-input
          controlName="product_name"
          [form]="form"
          label="{{ 'PRODUCT_NAME' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-12 ui-md-7 ui-g-nopad">
        <div class="ui-g-12 ui-md-8">
          <sc-text-input
            controlName="unique_id"
            [form]="form"
            label="{{ 'UNIQUE_ID' | translate }}"
          ></sc-text-input>
        </div>

        <div class="ui-g-12 ui-md-4">
          <sc-field-label
            [fieldControl]="form.controls.is_active"
            [title]="'STATUS' | translate"
          ></sc-field-label>
          <sc-activate-button
            [form]="form"
            field="is_active"
          ></sc-activate-button>
        </div>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-5">
        <sc-text-input
          controlName="display_name"
          [form]="form"
          label="{{ 'DISPLAY_NAME' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-12 ui-md-7 ui-g-nopad">
        <div class="ui-g-12 ui-md-8">
          <sc-dropdown
            controlName="brand"
            [form]="form"
            label="{{ 'BRAND' | translate }}"
            [options]="selectItems?.brands"
          ></sc-dropdown>
        </div>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.is_deleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div
        class="ui-g-6 text-right"
        style="
          display: flex;
          justify-content: flex-end;
          column-gap: 8px;
          padding-top: 16px;
        "
      >
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.is_deleted"
          buttonClass="ui-button-success"
          [disabled]="form.invalid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deviceForm>
  <div [formGroup]="form" *ngIf="form">
    <!-- HEADER -->
    <div class="ui-g">
      <div class="ui-g-12 ui-md-5">
        <sc-text-input
          controlName="product_name"
          [form]="form"
          label="{{ 'PRODUCT_NAME' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-12 ui-md-7 ui-g-nopad">
        <div class="ui-g-12 ui-md-8">
          <sc-text-input
            controlName="unique_id"
            [form]="form"
            label="{{ 'UNIQUE_ID' | translate }}"
          ></sc-text-input>
        </div>

        <div class="ui-g-12 ui-md-4">
          <sc-field-label
            [fieldControl]="form.controls.is_active"
            [title]="'STATUS' | translate"
          ></sc-field-label>
          <sc-activate-button
            [form]="form"
            field="is_active"
          ></sc-activate-button>
        </div>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12 ui-md-5">
        <sc-text-input
          controlName="display_name"
          [form]="form"
          label="{{ 'DISPLAY_NAME' | translate }}"
        ></sc-text-input>
      </div>

      <div class="ui-g-12 ui-md-7 ui-g-nopad">
        <div class="ui-g-12 ui-md-4">
          <sc-dropdown
            controlName="brand"
            [form]="form"
            label="{{ 'BRAND' | translate }}"
            [options]="selectItems?.brands"
          ></sc-dropdown>
        </div>

        <div class="ui-g-12 ui-md-4">
          <sc-dropdown
            controlName="protocol"
            [form]="form"
            label="{{ 'PROTOCOL' | translate }}"
            [options]="selectItems?.protocols"
          ></sc-dropdown>
        </div>

        <div class="ui-g-12 ui-md-4" *ngIf="form.value.protocol === 'zwave'">
          <sc-dropdown
            controlName="frequency"
            [form]="form"
            label="{{ 'FREQUENCY' | translate }}"
            [options]="selectItems?.zwaveFrequencies"
            (actionChange)="onSelectProtocol()"
          ></sc-dropdown>
        </div>
      </div>
    </div>

    <div class="ui-g" style="align-items: flex-start">
      <div class="ui-g-12 ui-md-5" *ngIf="form.value.protocol !== 'internal'">
        <sc-dropdown
          controlName="library"
          [form]="form"
          [label]="'DEVICE_MODEL_LIBRARY' | translate"
          [options]="selectItems?.libraries"
          (actionChange)="onSelectLibrary()"
        ></sc-dropdown>
      </div>
      <sc-modbus-settings-form
        *ngIf="form.value.protocol === 'modbus'"
        [form]="form"
        [selectItems]="selectItems"
      >
      </sc-modbus-settings-form>
    </div>

    <!-- BODY -->
    <div class="device-model-tabs">
      <div class="device-model-tabs__header">
        <button
          *ngFor="let t of tabs"
          pButton
          class="device-model-tabs__button"
          [ngClass]="{
            'device-model-tabs__button--selected': t.value === selectedTab
          }"
          [label]="t.label | translate"
          (click)="selectTab(t.value)"
        ></button>
      </div>

      <div class="device-model-tabs__body">
        <ng-container [ngSwitch]="selectedTab">
          <ng-container *ngSwitchCase="'i'">
            <ng-template [ngTemplateOutlet]="instructionsTab"></ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="'p'">
            <ng-template [ngTemplateOutlet]="propertiesTab"></ng-template>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="ui-g">
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage | translate"></sc-form-error>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="ui-g">
      <div class="ui-g-6">
        <sc-confirm-trigger
          *ngIf="editMode && !formData?.is_deleted"
          buttonIcon="fa fa-trash"
          buttonClass="ui-button-danger"
          buttonLabel="{{ 'DELETE' | translate }}"
          (onAccept)="delete()"
        ></sc-confirm-trigger>
      </div>

      <div
        class="ui-g-6 text-right"
        style="
          display: flex;
          justify-content: flex-end;
          column-gap: 8px;
          padding-top: 16px;
        "
      >
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          (click)="dismissModal('cancel')"
          label="{{ 'CANCEL' | translate }}"
        ></button>

        <sc-submit-button
          *ngIf="!formData?.is_deleted"
          buttonClass="ui-button-success"
          [disabled]="form.invalid || form.pristine"
          [pending]="isSubmitting"
          (submitted)="submit()"
        ></sc-submit-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #instructionsTab>
  <div class="device-model__instructions">
    <sc-instruction-form [form]="instructionsForm"></sc-instruction-form>
  </div>
</ng-template>

<ng-template #propertiesTab>
  <div class="device-model__properties">
    <div class="device-model__properties__action">
      <button pButton [icon]="'fa fa-plus'" (click)="addProperty()"></button>
    </div>

    <ng-container [formGroup]="form" *ngIf="form">
      <ng-container formArrayName="properties" *ngIf="propertiesForm">
        <p-accordion
          [activeIndex]="selectedProperty"
          (onOpen)="onSelectProperty($event, true)"
          (onClose)="onSelectProperty($event, false)"
        >
          <p-accordionTab
            *ngFor="let ctrl of propertiesForm.controls; let i = index"
            [formGroupName]="i"
          >
            <p-header>
              <div class="property-header">
                <div class="property-header__toggle-icon">
                  <i
                    class="fa fa-fw"
                    [ngClass]="{
                      'fa-chevron-down': selectedProperty === i,
                      'fa-chevron-right': selectedProperty !== i
                    }"
                  ></i>
                </div>
                <div class="property-header__title">
                  {{ "PROPERTY" | translate }} {{ i + 1 }}
                </div>
                <div class="property-header__actions">
                  <button
                    pButton
                    class="ui-button-text"
                    [label]="'REMOVE' | translate"
                    (click)="removeProperty(i)"
                  ></button>
                </div>
              </div>
            </p-header>

            <div class="property-body">
              <sc-property-form
                [form]="ctrl"
                [selectItems]="selectItems"
                [selectedProtocol]="selectedProtocol"
                [selectedLibrary]="selectedLibrary"
                [sourceData]="sourceData"
              ></sc-property-form>
            </div>
          </p-accordionTab>
        </p-accordion>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
