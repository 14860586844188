import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private apiUrl = config().apiUrl + '/_integrator';

  constructor(private httpClient: HttpClient) {}

  getTeamMembers(teamId: number) {
    return this.httpClient.get(`${this.apiUrl}/teams/${teamId}/members`);
  }

  updateTeamMember(teamId: number, data: any) {
    return this.httpClient.patch(`${this.apiUrl}/teams/${teamId}/members`, data);
  }

  inviteUser(data: any) {
    return this.httpClient.post(`${this.apiUrl}/inviteUser`, data);
  }

  joinTeam(data: { inviteId: number; status: string }) {
    return this.httpClient.post(`${this.apiUrl}/joinTeam`, data);
  }

  findUserByEmail(email: string) {
    return this.httpClient.get(`${this.apiUrl}/user/${email}/check`)
  }

  cancelIntegratorTeamInvitation(teamId: number, invitedEmail: string) {
    return this.httpClient.post(`${this.apiUrl}/cancelIntegratorTeamInvitation`, {
      team_id: teamId,
      email: invitedEmail,
    });
  }

  removeIntegratorFromTeam(teamId: number, memberId: number) {
    return this.httpClient.delete(`${this.apiUrl}/teams/${teamId}/members/${memberId}`);
  }
}
