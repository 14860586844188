import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Field } from '../models/field.model';
import { SelectItem } from 'primeng/api';

import { FieldsService } from '../services/fields.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-fields-form',
  templateUrl: './fields-form.component.html',
  styleUrls: ['./fields-form.component.scss']
})
export class FieldsFormComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  @Output()
  onClose = new EventEmitter();
  @Output()
  onDismiss = new EventEmitter();

  editMode = false;
  errorMessage: string;
  fetchingState = 0;
  form: FormGroup;
  formData: Field;
  isSubmitting = false;
  selectItems: { [key: string]: SelectItem[] } = {};

  private subscribers: { [key: string]: any } = {};

  constructor(
    private formBuilder: FormBuilder,
    private fieldsService: FieldsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.data) {
      // EDIT MODE
      this.editMode = true;
      // FETCH FIELD
      this.fetchField(this.data.id);
    }

    this.initForm();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchField(id: number) {
    this.fetchingState++;
    this.subscribers.GET_FIELD = this.fieldsService
      .getField(id)
      .subscribe((result: any) => {
        this.formData = result.data;
        this.form.patchValue(this.formData);
        this.fetchingState--;
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: { value: null, disabled: true },
      bulkModificationEnabled: false,
      name: [null, Validators.required],
      table: [null, Validators.required],
      type: [null, Validators.required],
      size: [null, Validators.min(0)],
      defaultValue: null,
      displayType: [null, Validators.required],
      displayConfig: null,
      isActive: false,
      isDeleted: false
    });

    // TODO: find the good place to store them
    this.selectItems.tables = [
      { label: 'devices', value: 'devices' },
      { label: 'locations', value: 'locations' }
    ];

    this.selectItems.types = [
      { label: 'DATETIME', value: 'DATETIME' },
      { label: 'FLOAT', value: 'FLOAT' },
      { label: 'INT', value: 'INT' },
      { label: 'TINYINT', value: 'TINYINT' },
      { label: 'VARCHAR', value: 'VARCHAR' }
    ];

    this.selectItems.displayTypes = [
      { label: 'Checkbox', value: 'checkbox' },
      { label: 'Color', value: 'color' },
      { label: 'Date', value: 'date' },
      { label: 'Number', value: 'number' },
      { label: 'Radio', value: 'radio' },
      { label: 'Selectbox', value: 'selectbox' },
      { label: 'Text', value: 'text' },
      { label: 'Textarea', value: 'textarea' }
    ];
  }

  updateDisplayConfig(event) {
    this.form.get('displayConfig').setValue(event);
  }

  submit() {
    if (this.form.valid) {
      // clear error message
      this.errorMessage = null;
      this.isSubmitting = true;

      const formData = { ...this.form.value };
      if (!this.editMode) {
        this.create(formData);
      } else {
        this.update(formData);
      }
    } else {
      this.errorMessage = 'ERROR_FORM_FIELDS_REQUIRED';
    }
  }

  create(data: Field) {
    this.subscribers.CREATE_FIELD = this.fieldsService
      .createField(data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'CREATE_SUCCESS'),
        this.apiCallError.bind(this, 'CREATE_FAIL')
      );
  }

  update(data: Field) {
    this.subscribers.UPDATE_FIELD = this.fieldsService
      .updateField(this.formData.id, data)
      .subscribe(
        this.apiCallSuccess.bind(this, 'UPDATE_SUCCESS'),
        this.apiCallError.bind(this, 'UPDATE_FAIL')
      );
  }

  delete() {
    this.subscribers.DELETE_FIELD = this.fieldsService
      .deleteField(this.formData.id)
      .subscribe(
        this.apiCallSuccess.bind(this, 'DELETE_SUCCESS'),
        this.apiCallError.bind(this, 'DELETE_FAIL')
      );
  }

  apiCallSuccess(message: string, result: any) {
    // show notification
    const text = this.sharedService.getTranslation(message);
    const title = this.sharedService.getTranslation('FORM_FIELD');
    this.sharedService.notify(title, text, 'success');

    // close the form and return user info
    this.isSubmitting = false;
    this.onClose.emit(result.data);
  }

  apiCallError(message: string, error: any) {
    // display error message and unlock the form
    this.errorMessage = error;
    this.isSubmitting = false;
  }

  get isSelectbox() {
    const displayType = this.form.get('displayType').value;
    return displayType === 'selectbox' ? true : false;
  }

  dismissModal(reason: any) {
    this.onDismiss.emit(reason);
  }
}
