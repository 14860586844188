import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { ConsumptionComponent } from './consumption.component';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [ConsumptionComponent],
  entryComponents: [ConsumptionComponent],
  providers: [],
})
export class ConsumptionModule {}
