<div [formGroup]="enablingMarkerForm" class="ui-g-12 ui-g-nopad">
  <h2>{{ 'ENABLING_MARKER' | translate }}</h2>

  <div class="ui-g-12">
    <h4 class="m-0">{{ 'CONDITIONS' | translate }}</h4>
    <small>{{ 'ENABLING_MARKER_CONDITIONS_DESCRIPTION' | translate }}</small>
  </div>

  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-3">{{ 'PROPERTY' | translate }}</div>
    <div class="ui-g-2">{{ 'OPERATOR' | translate }}</div>
    <div class="ui-g-4">{{ 'VALUE' | translate }}</div>
    <div class="ui-g-2"></div>
    <div class="ui-g-1">
      <button
        pButton
        type="button"
        class="ui-button-success"
        icon="fa fa-plus"
        (click)="add()"
        pTooltip="{{ 'ADD' | translate }}"
        appendTo="body"
      ></button>
    </div>
  </div>

  <div formArrayName="conditions" class="ui-g-12 ui-g-nopad">
    <div *ngFor="let conditionCtrl of conditionsForm.controls; let i=index" [formGroupName]="i" class="ui-g-12 ui-g-nopad">
      <!-- PROPERTY FIELD -->
      <div class="ui-g-3">
        <sc-dropdown
          controlName="property"
          [form]="conditionCtrl"
          label="{{ 'PROPERTY' | translate }}"
          [options]="optionData['enablingMarkerProps']"
          [showLabel]="false"
        ></sc-dropdown>
      </div>
      <!-- /PROPERTY FIELD -->

      <!-- OPERATOR FIELD -->
      <div class="ui-g-2">
        <sc-dropdown
          controlName="operator"
          [form]="conditionCtrl"
          label="{{ 'OPERATOR' | translate }}"
          [options]="allowOperators(i)"
          [showLabel]="false"
        ></sc-dropdown>
      </div>
      <!-- /OPERATOR FIELD -->

      <!-- VALUE FIELD -->
      <div class="ui-g-4">
        <div *ngIf="displayValueField(i)">
          <div *ngIf="locationPropertyMappingValues(i)">
            <sc-dropdown
              controlName="value"
              [form]="conditionCtrl"
              label="{{ 'VALUES' | translate }}"
              [options]="locationPropertyMappingValues(i)"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <div *ngIf="!locationPropertyMappingValues(i)">
            <div [ngSwitch]="locationPropOfForms[i]?.type">
              <div *ngSwitchCase="'number'">
                <sc-number-input
                  controlName="value"
                  [form]="conditionCtrl"
                  label="{{ 'VALUES' | translate }}"
                  [showLabel]="false"
                ></sc-number-input>
              </div>

              <div *ngSwitchDefault>
                <sc-text-input
                  controlName="value"
                  [form]="conditionCtrl"
                  label="{{ 'VALUES' | translate }}"
                  [showLabel]="false"
                ></sc-text-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /VALUE FIELD -->

      <!-- ACTION FIELD -->
      <div class="ui-g-1 ui-g-offset-2">
        <button
          pButton
          type="button"
          class="ui-button-warning"
          icon="fa fa-times"
          (click)="remove(i)"
          pTooltip="{{ 'REMOVE' | translate }}"
          appendTo="body"
        ></button>
      </div>
      <!-- /ACTION FIELD -->
    </div>
  </div>
</div>
