import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { ColDef, GridOptions } from 'ag-grid';
import { DeviceDetails } from '@app/widgets/devices/models/device-details';
import { LazyloadOptions } from '../models/lazyload-options';

import { DeviceHistoryLogsService } from '../services/device-history-logs.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-dhl-table',
  templateUrl: './dhl-table.component.html',
  styleUrls: ['./dhl-table.component.scss']
})
export class DhlTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  table: GridOptions;
  tableTitle: string;
  datasource: any;
  toolbarItems: string[];

  isFetchingDataset = false;
  isFirstTime = true;

  private dateFilter: any;
  private deviceLocationMapping: { [deviceId: string]: string } = {};
  private selectedDevice: DeviceDetails;
  private subscribers: { [key: string]: any } = {};
  private visibleColumns: string[] = [];

  constructor(
    private store: Store<fromStore.State>,
    private deviceHistoryLogsService: DeviceHistoryLogsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Set Table Name
    this.tableTitle = 'DEVICE_HISTORY_LOGS';
    // Set Table Toolbar Items
    this.toolbarItems = ['refresh', 'columns'];
    // Set Table Columns
    this.columns = this.createColumns();
    // Set user's visible columns
    if (
      this.config &&
      this.config.customOptions &&
      this.config.customOptions.visibleColumns
    ) {
      this.updateVisibleColumns(this.config.customOptions.visibleColumns);
    }

    // get device details
    this.subscribers.DEVICE_DETAILS = this.store
      .select(fromStore.getDeviceDetials)
      .subscribe(result => {
        if (result) {
          this.selectedDevice = result;
          this.isFirstTime = true;

          if (this.selectedDevice.children) {
            this.createDeviceLocationMapping(this.selectedDevice.children);
          }

          this.fetchDataset();
        }
      });
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (!this.isFetchingDataset) {
      this.isFetchingDataset = true;
      this.isFirstTime = false;

      this.datasource = {
        getRows: rowParams => {
          const options: LazyloadOptions = {
            filter: rowParams.filterModel,
            sort: rowParams.sortModel,
            offset: rowParams.startRow,
            limit: 100,
            fields: [...this.visibleColumns].join()
          };

          if (this.dateFilter) {
            options.daterange = this.dateFilter;
          }

          this.subscribers.GET_DHL = this.deviceHistoryLogsService
            .getAutomationHistoryLogs(this.selectedDevice.id, options)
            .subscribe((result: any) => {
              const rowData = result.data;
              let lastRow = -1;
              if (rowData.length < 100) {
                lastRow = rowParams.endRow - 100 + rowData.length;
              }
              rowParams.successCallback(rowData, lastRow);
            });
        }
      };

      // SET DATASOURCE IF TABLE READY
      if (this.table && this.table.api) {
        this.table.api.setDatasource(this.datasource);
      }

      this.isFetchingDataset = false;
    }
  }

  onDateSelect(event) {
    this.dateFilter = {
      from: event.from,
      to: event.to
    };
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;

    if (this.datasource) {
      this.table.api.setDatasource(this.datasource);
    }
  }

  updateVisibleColumns(event: string[]) {
    this.visibleColumns = [...event];
  }

  private createColumns() {
    return [
      {
        colId: 'createdAt',
        headerName: 'CREATED_AT',
        field: 'createdAt',
        cellRenderer: 'loadingRenderer',
        suppressFilter: true,
        width: 150,
        valueFormatter: params => this.sharedService.dateFormat(params.value)
      },
      {
        colId: 'value',
        headerName: 'VALUE',
        field: 'formattedValue',
        suppressFilter: true
      },
      {
        colId: 'device',
        headerName: 'DEVICE',
        field: 'device'
      },
      {
        colId: 'location',
        headerName: 'LOCATION',
        valueFormatter: this.getLocation.bind(this),
        valueGetter: this.getLocation.bind(this)
      },
      {
        colId: 'deviceType',
        headerName: 'DEVICE_TYPE',
        field: 'deviceType'
      },
      {
        colId: 'triggeredBy',
        headerName: 'TRIGGERED_BY',
        field: 'triggeredBy',
        suppressFilter: true,
        valueFormatter: this.getTriggeredBy.bind(this),
        valueGetter: this.getTriggeredBy.bind(this)
      },
      {
        colId: 'deviceId',
        headerName: 'ID',
        field: 'deviceId'
      }
    ];
  }

  get showSpinner() {
    return this.isFetchingDataset || this.isFirstTime;
  }

  private createDeviceLocationMapping(devices: DeviceDetails[]) {
    for (let i = 0; i < devices.length; i++) {
      if (devices[i].children) {
        this.createDeviceLocationMapping(devices[i].children);
      }
      this.deviceLocationMapping[devices[i].id] = devices[i].location;
    }
  }

  private getLocation(params) {
    if (!params.data || !params.data.deviceId) {
      return '';
    }
    return this.deviceLocationMapping[params.data.deviceId];
  }

  private getTriggeredBy(params) {
    if (!params.data || !params.data.triggeredBy) {
      return '';
    }
    switch (params.data.triggeredBy.toUpperCase()) {
      case 'H':
        return 'Human';
      case 'R':
        return 'Rule';
      default:
        return '';
    }
  }
}
