import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from '@app/store';
import { environment } from '../environments/environment';

@Component({
  selector: 'sc-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  isInitialDataLoading: boolean;
  isInitialDataLoaded: boolean;

  private currentState: any;
  private isFirstTime = true;
  private componentDestroyed: Subject<any> = new Subject();

  constructor(private store: Store<fromRoot.State>, private translateService: TranslateService) {}

  ngOnInit(): void {
    // force https
    if (environment.production && location.protocol !== 'https:') {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }

    // force load default translation
    this.translateService.getTranslation('en');
    this.translateService.use('fr');

    // restore state from local storage
    this.store.dispatch({ type: '[Store] Sync LocalStorage' });

    // update localstorage state
    this.store.pipe(takeUntil(this.componentDestroyed)).subscribe((state: any) => {
      const auth = state.auth;

      if (auth && auth.verified) {
        if (auth.twoFactorAuthMethod && !auth.twoFactorAuthVerified) {
          return;
        }

        // save state to localstorage
        this.currentState = JSON.stringify(state);
        localStorage.setItem('state', this.currentState);

        // load initial data
        if (this.isFirstTime && !this.isInitialDataLoaded) {
          this.isFirstTime = false;
          this.store.dispatch(new fromRoot.LoadInitalData());
        }
      } else {
        this.isFirstTime = true;
        localStorage.clear();
      }
    });

    // subscribe initial data loading
    this.store.pipe(select(fromRoot.getInitialDataLoading), takeUntil(this.componentDestroyed)).subscribe({
      next: (result) => {
        this.isInitialDataLoading = result;
      },
    });

    // subscribe initial data loaded
    this.store.pipe(select(fromRoot.getInitialDataLoaded), takeUntil(this.componentDestroyed)).subscribe({
      next: (result) => {
        this.isInitialDataLoaded = result;
      },
    });

    // subscribe query params
    this.store.pipe(select(fromRoot.getRouterStateUrlQueryParams), takeUntil(this.componentDestroyed)).subscribe({
      next: (result) => {
        if (!result) {
          return;
        }

        // auto change site
        const siteId = result && result.site && !isNaN(result.site) && +result.site;
        const selectedSiteId =
          this.currentState &&
          this.currentState.site &&
          this.currentState.site.site &&
          this.currentState.site.site.selectedId;

        if (selectedSiteId !== siteId) {
          this.store.dispatch(new fromRoot.SelectSite(siteId));
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}
