import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PropertyManagementSystem } from '../models/property-management-system';

import { config } from '../../../config';

@Injectable()
export class PropertyManagementSystemsService {
  private apiUrl = config().apiUrl + '/propertyManagementSystems';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): PropertyManagementSystem {
    const newData = { ...data };

    if (typeof newData.id !== 'undefined') {
      delete newData.id;
    }
    // if (typeof newData.propertyManagementSystemId !== 'undefined') {
    //   delete newData.propertyManagementSystemId;
    // }
    if (typeof newData.createdAt !== 'undefined') {
      delete newData.createdAt;
    }
    if (typeof newData.createdBy !== 'undefined') {
      delete newData.createdBy;
    }
    if (typeof newData.updatedAt !== 'undefined') {
      delete newData.updatedAt;
    }
    if (typeof newData.updatedBy !== 'undefined') {
      delete newData.updatedBy;
    }

    return newData;
  }

  get(id?: number, filters?: any) {
    let queryParams = '';
    let isFirstParam = true;
    if (filters) {
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          queryParams += (isFirstParam ? '?' : '&') + key + '=' + filters[key];
          if (isFirstParam) {
            isFirstParam = false;
          }
        }
      }
    }
    return this.httpClient.get(
      this.apiUrl + (id ? '/' + id : '') + queryParams
    );
  }

  create(data: PropertyManagementSystem) {
    return this.httpClient.post(this.apiUrl, data);
  }

  update(id: number, data: PropertyManagementSystem) {
    return this.httpClient.patch(this.apiUrl + '/' + id, this.verifyData(data));
  }

  delete(id: number) {
    return this.httpClient.patch(this.apiUrl + '/' + id, {
      isActive: false,
      isDeleted: true
    });
  }
}
