<div [formGroup]="group" class="ui-g ui-g-nopad">
  <div class="ui-g-3" *ngIf="devices.length">
    <sc-dropdown
      controlName="deviceId"
      [form]="group"
      label="{{ 'DEVICE' | translate }}"
      [options]="devices"
      [showLabel]="false"
    ></sc-dropdown>
  </div>

  <div class="ui-g-3" *ngIf="devices.length === 0">
    <small class="red-text">
      No! Devices for DeviceTypeId({{ deviceTypeId }})
    </small>
  </div>

  <div class="ui-g-3">
    <span *ngIf="deviceId"> {{ action }} </span>
  </div>

  <div class="ui-g-5 ui-g-nopad">
    <div *ngIf="deviceId">
      <!-- PARAMETERS FIELD -->
      <div formArrayName="parameters">
        <div
          *ngFor="let parameter of parameterForms.controls; let i = index"
          [formGroupName]="i"
        >
          <scene-command-parameter-form
            [group]="parameter"
            [groupId]="i"
            [devices]="devicesSource"
          ></scene-command-parameter-form>
        </div>
      </div>
    </div>
  </div>

  <div class="ui-g-1">
    <sc-number-input
      controlName="delay"
      [form]="group"
      label="{{ 'DELAY' | translate }}"
      [showLabel]="false"
    ></sc-number-input>
  </div>
</div>
