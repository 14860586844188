import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class CustomAppFormsService {
  constructor(private formBuilder: FormBuilder) {}

  initCustomAppForm(): FormGroup {
    return this.formBuilder.group({
      id: { value: null, disabled: true },
      key: [null, Validators.required],
      name: [null, Validators.required],
      description: null,
      appId: [null, Validators.required],
      appStyleId: null,
      companyId: [null, Validators.required],
      siteId: null,
      isActive: false,
      isDeleted: false
    });
  }
}
