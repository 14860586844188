import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SelectItem, SelectItems, Subscribers } from '../../models';
import { DataSources, RuleCondition, LeftOperandTypes, RightOperandTypes, OperatorTypes } from '../../models';
import { RulesService, UtilsService } from '../../services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sc-right-operand-modal',
  templateUrl: './right-operand-modal.component.html',
  styleUrls: ['./right-operand-modal.component.scss'],
})
export class RightOperandModalComponent implements OnInit, OnDestroy {
  _rightOperandTypes = RightOperandTypes;

  dataSources: DataSources = {};
  form: FormGroup;
  modalTitle: string;
  modalDescription: string;
  operandType: string;
  selectItems: SelectItems = {};
  valueStructure: any;
  isTemplate: boolean;
  isFromScratch: boolean;
  viewMode: string;
  step = 2;
  operandTypes: SelectItem[];
  isNumberValueOnly = false;

  private condition: RuleCondition;
  private leftOperandType: string;
  private structure: any;
  private subscribers: Subscribers = {};

  constructor(
    private modalRef: DynamicDialogRef,
    private modalConfig: DynamicDialogConfig,
    private rulesService: RulesService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (this.modalConfig && this.modalConfig.data) {
      if (this.modalConfig.data.condition) {
        this.condition = this.modalConfig.data.condition;
        this.operandType = this.condition.right.type;
        this.leftOperandType = this.condition.left.type;
        if (!this.operandType) {
          this.setRightOperandType();
        }
        this.checkOperator();
      }

      if (this.modalConfig.data.dataSources) {
        this.dataSources = this.modalConfig.data.dataSources;
      }

      if (this.modalConfig.data.selectItems) {
        this.selectItems = this.modalConfig.data.selectItems;
      }

      if (this.modalConfig.data.isTemplate) {
        this.isTemplate = this.modalConfig.data.isTemplate;
      }

      if (this.modalConfig.data.isFromScratch) {
        this.isFromScratch = this.modalConfig.data.isFromScratch;
      }

      if (this.modalConfig.data.viewMode) {
        this.viewMode = this.modalConfig.data.viewMode;
      }

      if (this.modalConfig.data.structure) {
        if (this.isNumberValueOnly) {
          this.valueStructure = { type: 'numeric', min: 0 };
        } else {
          this.structure = this.modalConfig.data.structure;
          if (this.structure && this.structure.render) {
            this.valueStructure = { ...this.structure.render };
          }
        }
      }
    }

    this.setModalTitle();
    this.setModalDescription();

    if (this.operandType) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.utilsService.clearSubscribers(this.subscribers);
  }

  private initForm() {
    let formValue;
    if (this.form) {
      formValue = this.form.value;
    } else {
      formValue = this.condition.right;
    }

    this.form = this.rulesService.initRightOperandForm(this.operandType, this.isTemplate);

    if (formValue) {
      this.form.patchValue(formValue);

      if (formValue.modifiers) {
        this.rulesService.setModifierForm(this.form, formValue.modifiers);
      }

      if (formValue.variables) {
        this.rulesService.setFunctionVariablesForm(this.form, formValue.variables);
      }
    }

    // subscribe pattern
    if (this.form.get('pattern')) {
      this.subscribers.formFieldPattern = this.form.get('pattern').valueChanges.subscribe((data) => {
        if (data === 'last_day_of_the_month' || data === 'scheduler') {
          this.form.get('value').clearValidators();
        } else {
          this.form.get('value').setValidators(Validators.required);
        }
        this.form.updateValueAndValidity();
      });
    }
  }

  private setModalTitle() {
    let title = '';
    if (this.step === 1) {
      // set modal title of step 1
      switch (this.leftOperandType) {
        case LeftOperandTypes.DeviceValue:
          title = 'CCP_RULE_RIGHT_OPERAND';
          break;
        case LeftOperandTypes.DeviceDateTime:
        case LeftOperandTypes.LocationPropertyDateTime:
          title = 'CCP_RULE_DATETIME_OPERAND';
          break;
        default:
          break;
      }
    } else {
      // set modal title of step 2
      switch (this.operandType) {
        case RightOperandTypes.DatetimeAbsolute:
          title = 'CCP_RULE_ABSOLUTE_DATETIME_OPERAND';
          break;
        case RightOperandTypes.DatetimeRelative:
          title = 'CCP_RULE_RELATIVE_DATETIME_OPERAND';
          break;
        case RightOperandTypes.Device:
          title = 'CCP_RULE_DEVICE_OPERAND';
          break;
        case RightOperandTypes.Value:
          title = 'CCP_RULE_VALUE_OPERAND';
          break;
        default:
          break;
      }
    }

    this.modalTitle = title;
  }

  private setModalDescription() {
    let description = '';
    if (this.step === 1) {
      // set modal description of step 1
      switch (this.leftOperandType) {
        case LeftOperandTypes.DeviceValue:
          description = 'CCP_RULE_RIGHT_OPERAND_DESCRIPTION';
          break;
        case LeftOperandTypes.DeviceDateTime:
        case LeftOperandTypes.LocationPropertyDateTime:
          description = 'CCP_RULE_DATETIME_OPERAND_DESCRIPTION';
          break;
        default:
          break;
      }
      // } else {
      // set modal description of step 2
      //   switch (this.operandType) {
      //     case RightOperandTypes.DatetimeAbsolute:
      //     case RightOperandTypes.DatetimeRelative:
      //     case RightOperandTypes.Device:
      //     case RightOperandTypes.Value:
      //     default:
      //       break;
      //   }
    }

    this.modalDescription = description;
  }

  private setRightOperandType() {
    if (!this.leftOperandType) {
      return;
    }

    switch (this.leftOperandType) {
      case LeftOperandTypes.DeviceValue:
        // can select between device and value
        this.operandTypes = [
          { label: RightOperandTypes.DeviceLabel, value: RightOperandTypes.Device },
          { label: RightOperandTypes.ValueLabel, value: RightOperandTypes.Value },
        ];
        this.step = 1;
        break;

      case LeftOperandTypes.LocationPropertyValue:
      case LeftOperandTypes.FunctionValue:
      case LeftOperandTypes.GlobalValue:
        this.operandType = RightOperandTypes.Value;
        break;

      case LeftOperandTypes.DatetimeNow:
        this.operandType = RightOperandTypes.DatetimeAbsolute;
        break;

      case LeftOperandTypes.DeviceDateTime:
      case LeftOperandTypes.LocationPropertyDateTime:
        // can select between absolute and relative
        this.operandTypes = [
          { label: RightOperandTypes.DatetimeAbsoluteLabel, value: RightOperandTypes.DatetimeAbsolute },
          { label: RightOperandTypes.DatetimeRelativeLabel, value: RightOperandTypes.DatetimeRelative },
        ];
        this.step = 1;
        break;

      default:
        break;
    }

    if (this.operandType) {
      this.condition.right.type = this.operandType;
    }

    this.setModalTitle();
    this.setModalDescription();
  }

  private checkOperator() {
    const operator = this.condition.operator;
    if (operator.type === OperatorTypes.ChangedAtLeastNtimes || operator.type === OperatorTypes.ChangedMaxNtimes) {
      this.operandType = RightOperandTypes.Value;
      this.condition.right.type = this.operandType;
      this.isNumberValueOnly = true;
      this.step = 2;
    }
  }

  onSelectOperandType(event) {
    this.operandType = event;
    this.initForm();
    this.form.get('type').setValue(this.operandType);
    this.step = 2;
    this.setModalTitle();
    this.setModalDescription();
  }

  close() {
    this.modalRef.close();
  }

  onResetClick() {
    this.condition.right = { type: null };
    this.close();
  }

  onOkClick() {
    if (this.form.valid) {
      const formValue = this.form.value;
      this.condition.right = { ...formValue };
      this.close();
    }
  }
}
