<div *ngIf="fetchingState === 0; then content; else loadingIndicator"></div>

<ng-template #loadingIndicator>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <form [formGroup]="form" novalidate>
    <!--Header-->
    <h4 class="mb-0">{{ 'CONFIGURATION' | translate }}</h4>

    <!-- BODY -->
    <div class="ui-g">
      <!-- LOCATION_TEMPLATE FIELD -->
      <div class="ui-g-12 ui-md-2">
        <sc-dropdown
          controlName="locationTemplateId"
          [form]="form"
          label="{{ 'LOCATION_TEMPLATE' | translate }}"
          [options]="selectItems['locationTemplates']"
        ></sc-dropdown>
      </div>

      <!-- PARENT FIELD -->
      <div class="ui-g-12 ui-md-2">
        <sc-dropdown
          controlName="parentId"
          [form]="form"
          label="{{ 'PARENT' | translate }}"
          [options]="selectItems['locations']"
        ></sc-dropdown>
      </div>

      <!-- AMOUNT FIELD -->
      <div class="ui-g-12 ui-md-2">
        <sc-number-input
          controlName="amount"
          [form]="form"
          label="{{ 'AMOUNT' | translate }}"
          min="1"
        ></sc-number-input>
      </div>

      <!-- START_INDEX FIELD -->
      <div class="ui-g-12 ui-md-2">
        <sc-number-input
          controlName="startIndex"
          [form]="form"
          label="{{ 'START_INDEX' | translate }}"
          min="0"
        ></sc-number-input>
      </div>

      <!-- PREFIX FIELD -->
      <div class="ui-g-12 ui-md-2">
        <sc-text-input
          controlName="prefix"
          [form]="form"
          label="{{ 'PREFIX' | translate }}"
        ></sc-text-input>
      </div>

      <!-- GENERATE BUTTON -->
      <div class="ui-g-12 ui-md-2">
        <button
          pButton
          type="button"
          class="ui-button-success mt-3"
          label="{{ 'GENERATE' | translate }}"
          (click)="generate()"
          [disabled]="form.pristine"
        ></button>
      </div>
    </div>

    <div class="sc-horizontal-line"></div>

    <!-- LOCATIONS -->
    <ng-container formArrayName="locations">
      <ng-container
        *ngFor="let location of locationForms.controls; let i = index"
        [formGroupName]="i"
      >
        <sc-location-form
          [form]="location"
          [formId]="i"
          [data]="getDataPassThrough(i)"
          (applyToAllDevices)="updateDevices($event)"
          (applyToAllRules)="updateRules($event)"
          (applyToChildLocationDevices)="updateChildLocationDevices($event)"
          (applyToChildLocationRules)="updateChildLocationRules($event)"
        ></sc-location-form>
      </ng-container>
    </ng-container>

    <div class="ui-g">
      <div class="ui-g-12" *ngIf="errorMessage">
        <sc-form-error [message]="errorMessage|translate"></sc-form-error>
      </div>

      <div class="ui-g-12 text-right">
        <button
          pButton
          type="button"
          class="ui-button-secondary"
          label="{{ 'CANCEL' | translate }}"
          (click)="dismissModal('cancel')"
        ></button>

        <sc-submit-button
          buttonClass="ui-button-success"
          [pending]="isSubmitting"
          (submitted)="submit()"
          [disabled]="!locationForms.valid"
        ></sc-submit-button>
      </div>
    </div>
  </form>
</ng-template>
