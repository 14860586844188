<div class="left-sidebar" [ngClass]="{ collapsed: !expand, overlay: overlay }">
  <div class="left-sidebar-toggler">
    <sc-toggle-button (clicked)="onToggleClicked()"></sc-toggle-button>
  </div>
  <img
    [ngStyle]="{
      content: getLogo(expand)
    }"
    [ngClass]="{
      'logo-full': expand,
      logo: !expand && logoMobile
    }"
  />
  <ul
    class="menu"
    [ngClass]="{
      withoutLogo: !expand && !logoMobile
    }"
  >
    <sc-menu-item
      *ngFor="let m of menu"
      [data]="m"
      [selected]="selectedMenu"
      [sidebarExpanded]="expand"
      (clicked)="onMenuClicked($event)"
      (mouseover)="onHoverMenu()"
    ></sc-menu-item>
  </ul>
</div>
