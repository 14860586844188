import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { SharedService } from '@shared/shared.service';
import { Widget } from '../models/page-section-widget';
import { WidgetCategory } from '@widgets/widget-categories/models/widget-category';
import { PageWidgetModalComponent } from '../page-widget-modal/page-widget-modal.component';
import { SiteType } from '@widgets/site-types/models/site-type';
import { config } from '@app/config';

function generateUniqueWidgetId(widgetId) {
  let uniqueId = widgetId + '-' + window.performance.now() + '-' + Math.floor(Math.random() * 1000);
  return uniqueId;
}

@Component({
  selector: 'sc-page-widget-form',
  templateUrl: './page-widget-form.component.html',
  styleUrls: ['./page-widget-form.component.scss'],
})
export class PageWidgetFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  currentWidgets: Widget[];
  @Input()
  show: boolean;
  @Input()
  widgets: Widget[];
  @Input()
  widgetCategories: WidgetCategory[];
  @Input()
  multisites: boolean;

  @Output()
  done = new EventEmitter();
  @Output()
  cancalled = new EventEmitter();

  categoriedWidgets: any[] = [];
  iconUrlPrefix = config().s3Url + '/';

  private currentSiteType: SiteType;
  private selectedWidgetIds: number[] = [];
  private selectedWidgets = [];
  private subscribers: { [key: string]: any } = {};
  private widgetModal: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentSiteType = this.sharedService.currentSiteType;

    this.setDisplayWidgets();

    // update table content when user selected language changed
    this.subscribers.languageChange = this.translateService.onLangChange.subscribe((event) => {
      this.setDisplayWidgets();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentWidgets && changes.currentWidgets.currentValue) {
      this.setPreSelectedWidgets();
    }
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  private setDisplayWidgets(): void {
    if (!this.widgets || !this.widgets.length || !this.widgetCategories || !this.widgetCategories.length) {
      return;
    }

    const locale = this.sharedService.userLocale;
    const widgets = JSON.parse(JSON.stringify(this.widgets));
    const categoriedWidgets = this.widgetCategories.reduce((prev, curr) => {
      const name = typeof curr.name === 'string' ? JSON.parse(curr.name) : curr.name;
      const description = typeof curr.description === 'string' ? JSON.parse(curr.description) : curr.description;
      prev[curr.id] = {
        name: name ? name[locale] || name.en : curr.key,
        description: description ? description[locale] || description.en : '',
        icon: curr.icon,
        widgets: [],
      };
      return prev;
    }, {});

    // put widget to category
    for (const w of widgets) {
      if ((this.multisites && w.availability === 'MONO') || (!this.multisites && w.availability === 'MULTI')) {
        continue;
      }

      const category = w.categories && w.categories[0];

      if (category && categoriedWidgets[category]) {
        const name = typeof w.name === 'string' ? JSON.parse(w.name) : w.name;
        const description = typeof w.description === 'string' ? JSON.parse(w.description) : w.description;

        categoriedWidgets[category].widgets.push({
          id: w.id,
          name: name ? name[locale] || name.en : w.key,
          description: description ? description[locale] || description.en : '',
          icon: w.icon || categoriedWidgets[category].icon,
        });
      }
    }

    this.categoriedWidgets = Object.keys(categoriedWidgets).map((id) => {
      const category = categoriedWidgets[id];
      category.widgets = category.widgets.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      return category;
    });
  }

  private setPreSelectedWidgets(): void {
    // set pre-selected
    if (this.currentWidgets && this.currentWidgets.length) {
      this.selectedWidgetIds = this.currentWidgets.map((w) => w.widgetId);
    } else {
      this.selectedWidgetIds = [];
    }
  }

  addRemoveWidget(widget): void {
    this.openWidgetModal(widget);
  }

  private openWidgetModal(widget: any, isRemove: boolean = false) {
    this.widgetModal = this.dialogService.open(PageWidgetModalComponent, {
      showHeader: false,
      styleClass: 'flexible no-padding-content',
      data: { widget, isRemove },
    });

    this.widgetModal.onClose.subscribe((result: any) => {
      if (result === true) {
        this.selectedWidgetIds.push(widget.id);
        this.selectedWidgets.push(widget);

        this.submit();
      }
    });
  }

  itemStyle(widgetId): string {
    if (this.selectedWidgetIds.indexOf(widgetId) >= 0) {
      return 'widget-list-item--selected';
    }
    return '';
  }

  private submit(): void {
    let currentWidgets = [];
    for (const currentWidget of this.currentWidgets) {
      currentWidgets.push({ ...currentWidget });
    }

    let selectedWidgets = [];
    if (!selectedWidgets.length) {
      selectedWidgets = [...currentWidgets];
    }

    for (const selectedWidget of this.selectedWidgets) {
      for (const widget of this.widgets) {
        if (selectedWidget.id !== widget.id) {
          continue;
        }

        let w: any = {
          ...widget,
          widgetId: widget.id,
          userId: this.sharedService.userId,
        };
        delete w.id;

        selectedWidgets.push(w);
      }
    }

    selectedWidgets = selectedWidgets.map(function (sw) {
      if ('uniqueId' in sw && sw.uniqueId) {
        return sw;
      }
      return {
        ...sw,
        uniqueId: generateUniqueWidgetId(sw.widgetId),
      };
    });

    selectedWidgets = JSON.parse(JSON.stringify(selectedWidgets));
    selectedWidgets = selectedWidgets.sort((a, b) => {
      if (a.y === null || a.x === null) {
        return 1;
      }
      if (a.y >= b.y && a.x > b.x) {
        return 1;
      }
      return -1;
    });
    this.done.emit(selectedWidgets);
    this.selectedWidgets = [];
  }
}
