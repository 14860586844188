import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { RuleMonitorActionCellComponent } from './rule-monitor-action-cell/rule-monitor-action-cell.component';
import { RuleMonitorTableComponent } from './rule-monitor-table/rule-monitor-table.component';

@NgModule({
  imports: [SharedModule],
  exports: [RuleMonitorTableComponent],
  declarations: [RuleMonitorActionCellComponent, RuleMonitorTableComponent],
  entryComponents: [RuleMonitorActionCellComponent, RuleMonitorTableComponent],
  providers: [],
})
export class RuleMonitorModule {}
