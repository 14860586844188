<div class="ceos-automation-trigger-form">
  <div class="p-grid">
    <div class="p-field p-col-12">
      <strong>
        {{ "ACTION" | translate }}
      </strong>

      <sc-radio-input
        [form]="form"
        controlName="action"
        [options]="actions"
        [showLabel]="false"
        [displayInVertical]="true"
      ></sc-radio-input>
    </div>
  </div>

  <!-- Footer -->
  <div class="p-grid">
    <div class="p-col-8">
      <div *ngIf="isSubmitting">
        <i class="fa fa-spinner fa-spin grey-text"></i>
        <span class="grey-text">
          Command sent. We're waiting for the automation to accept.
        </span>
      </div>

      <div
        *ngIf="
          !isSubmitting && !errorMessage && isSubmitSuccess && commandAcceptedAt
        "
      >
        <i class="fa fa-check green-text"></i>
        <span class="green-text">
          Command accepted at {{ commandAcceptedAt }}
        </span>
      </div>

      <div *ngIf="!isSubmitting && errorMessage && !isSubmitSuccess">
        <i class="fa fa-exclamation-triangle red-text"></i>
        <span class="red-text">
          We could not reach the automation, the command timed out.
        </span>
      </div>
    </div>

    <div class="p-col-4 text-right">
      <button
        pButton
        type="button"
        class="ui-button-secondary"
        label="{{ 'CANCEL' | translate }}"
        (click)="dismissModal()"
      ></button>

      <sc-submit-button
        buttonClass="ui-button-success"
        [pending]="isSubmitting"
        (submitted)="sendLiveRequest()"
        [disabled]="!form.valid"
      ></sc-submit-button>
    </div>
  </div>
</div>
