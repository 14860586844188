import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { ElectricityCostActionCellComponent } from './cost-action-cell/cost-action-cell.component';
import { ElectricityCostFormComponent } from './cost-form/cost-form.component';
import { ElectricityCostsTableComponent } from './costs-table/costs-table.component';

import { ElectricityCostsService } from './services/electricity-costs.service';

const COMPONENTS: any[] = [
  ElectricityCostActionCellComponent,
  ElectricityCostFormComponent,
  ElectricityCostsTableComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [ElectricityCostsService]
})
export class ElectricityCostsModule {}
