import { Action } from '@ngrx/store';

import { User } from '@widgets/users/models/user';

// UPDATE USER
export const UPDATE_USER = '[User] Update User';
export const UPDATE_USER_FAIL = '[User] Update User Fail';
export const UPDATE_USER_SUCCESS = '[User] Update User Success';
export const UPDATE_USER_VARIANT = '[User] Update User Variant';
export const UPDATE_USER_COMPANY = '[User] Update User Company';
export const ADD_USER_TEAMS = '[User] Add User Teams';
export const CLEAR_TEAM_INVITATION = '[User] Remove team invitation';

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;
  constructor(public payload: User) {}
}

export class UpdateUserFail implements Action {
  readonly type = UPDATE_USER_FAIL;
  constructor(public payload: any) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UPDATE_USER_SUCCESS;
  constructor(public payload: User) {}
}

export class UpdateUserVariant implements Action {
  readonly type = UPDATE_USER_VARIANT;
  constructor(public payload: string) {}
}

export class UpdateUserCompany implements Action {
  readonly type = UPDATE_USER_COMPANY;
  constructor(public payload: number) {}
}

export class AddUserTeams implements Action {
  readonly type = ADD_USER_TEAMS;
  constructor(public payload: any[]) {}
}

export class ClearTeamInvitation implements Action {
  readonly type = CLEAR_TEAM_INVITATION;
}

// ACTION TYPES
export type UserAction =
  | UpdateUser
  | UpdateUserFail
  | UpdateUserSuccess
  | UpdateUserVariant
  | UpdateUserCompany
  | AddUserTeams
  | ClearTeamInvitation;
