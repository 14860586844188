<div [formGroup]="group" class="ui-g">
  <!-- CONNECTOR FIELD -->
  <div class="ui-g-1">
    <sc-dropdown
      *ngIf="groupId > 0"
      controlName="connector"
      [form]="group"
      label="{{ 'CONNECTOR' | translate }}"
      [options]="optionData['connectors']"
      [showLabel]="false"
    ></sc-dropdown>
  </div>

  <div class="ui-g-3 ui-g-nopad">
    <!-- SOURCE_TYPE FIELD -->
    <div class="ui-g-6">
      <sc-dropdown
        controlName="sourceType"
        [form]="group"
        label="{{ 'SOURCE_TYPE' | translate }}"
        [options]="optionData['sourceTypes']"
        [showLabel]="false"
      ></sc-dropdown>
    </div>

    <div class="ui-g-6" [ngSwitch]="group.get('sourceType')?.value">
      <!-- DEVICE_TYPE_ID FIELD -->
      <div *ngSwitchCase="'d'">
        <sc-dropdown
          controlName="deviceTypeId"
          [form]="group"
          label="{{ 'DEVICE_TYPE' | translate }}"
          [options]="optionData['deviceTypes']"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <!-- LOCATION_PROPERTY_TYPE_ID FIELD -->
      <div *ngSwitchCase="'l'">
        <sc-dropdown
          controlName="locationPropertyTypeId"
          [form]="group"
          label="{{ 'LOCATION_PROPERTY_TYPE' | translate }}"
          [options]="optionData['locationPropertyTypes']"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <!-- DATETIME_ID FIELD -->
      <div *ngSwitchCase="'dt'">
        <sc-dropdown
          controlName="datetimePropertyTypeId"
          [form]="group"
          label="{{ 'DATETIME_PROPERTY_TYPE' | translate }}"
          [options]="optionData['datetimePropertyTypes']"
          [showLabel]="false"
        ></sc-dropdown>
      </div>

      <div *ngSwitchDefault></div>
    </div>
  </div>

  <!-- OPERATOR FIELD -->
  <div class="ui-g-1">
    <sc-dropdown
      controlName="operator"
      [form]="group"
      label="{{ 'OPERATOR' | translate }}"
      [options]="availableOperators"
      [showLabel]="false"
    ></sc-dropdown>
  </div>

  <!-- REFERENCED FIELD -->
  <div class="ui-g-3 ui-g-nopad">
    <div [ngClass]="{ 'ui-g-6': group.get('sourceType').value !== 'dt', 'ui-g-12': group.get('sourceType').value === 'dt' }">
      <div [ngSwitch]="group.get('sourceType').value">
        <!-- DATETIME_PROPERTY_VALUE FIELD -->
        <div *ngSwitchCase="'dt'">
          <sc-text-input
            controlName="datetimePropertyValue"
            [form]="group"
            label="{{ 'DATETIME_PROPERTY_VALUE' | translate }}"
            [showLabel]="false"
          ></sc-text-input>
        </div>

        <!-- REFERENCED_TYPE FIELD -->
        <div *ngSwitchDefault>
          <sc-dropdown
            controlName="referencedType"
            [form]="group"
            label="{{ 'REFERENCED_TYPE' | translate }}"
            [options]="optionData['referencedTypes']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>
      </div>
    </div>

    <div class="ui-g-6" *ngIf="group.get('referencedType')?.value">
      <div [ngSwitch]="group.get('referencedType').value">
        <!-- REFERENCED_DEVICE_TYPE_ID FIELD -->
        <div *ngSwitchCase="'d'">
          <sc-dropdown
            controlName="referencedDeviceTypeId"
            [form]="group"
            label="{{ 'REFERENCED_DEVICE_TYPE' | translate }}"
            [options]="optionData['deviceTypes']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <!-- REFERENCED_LOCATION_PROPERTY_TYPE_ID FIELD -->
        <div *ngSwitchCase="'l'">
          <sc-dropdown
            controlName="referencedLocationPropertyTypeId"
            [form]="group"
            label="{{ 'REFERENCED_LOCATION_PROPERTY_TYPE' | translate }}"
            [options]="optionData['locationPropertyTypes']"
            [showLabel]="false"
          ></sc-dropdown>
        </div>

        <!-- REFERENCED_VALUE FIELD -->
        <div *ngSwitchCase="'v'">
          <div *ngIf="hasMappingValues">
            <sc-dropdown
              controlName="referencedValue"
              [form]="group"
              label="{{ 'REFERENCED_VALUE' | translate }}"
              [options]="mappingValue"
              [showLabel]="false"
            ></sc-dropdown>
          </div>

          <div *ngIf="!hasMappingValues">
            <!-- <div [ngSwitch]="locationPropertyMapping?.type">
              <div *ngSwitchCase="'number'">
                <sc-number-input
                  controlName="referencedValue"
                  [form]="group"
                  label="{{ 'REFERENCED_VALUE' | translate }}"
                  [min]="locationPropertyMapping.min"
                  [max]="locationPropertyMapping.max"
                  [showLabel]="false"
                ></sc-number-input>
              </div>

              <div *ngSwitchDefault> -->
                <sc-text-input
                  controlName="referencedValue"
                  [form]="group"
                  label="{{ 'REFERENCED_VALUE' | translate }}"
                  [showLabel]="false"
                ></sc-text-input>
              <!-- </div>
            </div> -->
          </div>
        </div>

        <!-- REFERENCED_VALUE_CHANGES FIELD -->
        <div *ngSwitchCase="'vc'">
          <sc-number-input
            controlName="referencedValue"
            [form]="group"
            label="{{ 'REFERENCED_VALUE' | translate }}"
            min="0"
            [showLabel]="false"
          ></sc-number-input>
        </div>

        <!-- <div *ngSwitchDefault></div> -->
      </div>
    </div>
  </div>

  <div class="ui-g-4 ui-g-nopad">
    <!-- PARAMETERS FIELD -->
    <div class="ui-g-4">
      <sc-text-input
        controlName="parameters"
        [form]="group"
        label="{{ 'PARAMETERS' | translate }}"
        [showLabel]="false"
      ></sc-text-input>
    </div>

    <!-- WAS_TRUE_ONE_TIME_IN_PAST_MS FIELD -->
    <div class="ui-g-3">
      <sc-number-input
        *ngIf="isRequiredTrueOneTime"
        controlName="wasTrueOneTimeInPastMs"
        [form]="group"
        label="{{ 'WAS_TRUE_ONE_TIME_IN_PAST_MS' | translate }}"
        [showLabel]="false"
        [min]="0"
      ></sc-number-input>
    </div>

    <!-- EVENT FIELD -->
    <!-- <div class="ui-g-3">
      <sc-dropdown
        controlName="event"
        [form]="group"
        label="{{ 'EVENT' | translate }}"
        [options]="optionData['events']"
        [showLabel]="false"
      ></sc-dropdown>
    </div> -->

    <!-- REQUIRED_VALIDITY_PERIOD -->
    <div class="ui-g-3">
      <sc-number-input
        controlName="requiredValidityPeriod"
        [form]="group"
        label="{{ 'REQUIRED_VALIDITY_PERIOD' | translate }}"
        [showLabel]="false"
        [min]="0"
      ></sc-number-input>
    </div>

    <div class="ui-g-2">
      <button
        pButton
        type="button"
        class="ui-button-warning"
        icon="fa fa-trash"
        (click)="removed.emit()"
        pTooltip="{{ 'REMOVE' | translate }} {{ 'CONDITION' | translate }}"
        appendTo="body"
      ></button>
    </div>
  </div>
</div>
