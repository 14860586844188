<div class="file-dir">
  <ul class="file-list">
    <li
      *ngFor="let f of files"
      class="file"
      [ngClass]="f.styleClass"
      (click)="onSelected(f)"
      (dblclick)="onOpened(f)"
    >
      <span class="file-icon unselectable">
        <span class="fa fa-fw fa-3x" [ngClass]="f.icon || f.collapsedIcon"></span>
      </span>
      <span class="file-name unselectable">{{ f.label }}</span>
    </li>
  </ul>
</div>
