import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DeviceCommandMapping } from '../models/device-command-mapping';

import { config } from '@app/config';

@Injectable()
export class DeviceCommandMappingService {
  private apiUrl = config().apiUrl + '/deviceCommandMappings';

  constructor(private httpClient: HttpClient) {}

  private verifyData(data: any): DeviceCommandMapping {
    const newData = { ...data };

    delete newData.id;
    delete newData.createdAt;
    delete newData.createdBy;
    delete newData.updatedAt;
    delete newData.updatedBy;

    return newData;
  }

  getCommandMapping(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getCommandMappings() {
    return this.httpClient.get(this.apiUrl);
  }

  createCommandMapping(data: DeviceCommandMapping) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateCommandMapping(id: number, data: DeviceCommandMapping) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteCommandMapping(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      isActive: false,
      isDeleted: true
    });
  }
}
