import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Benchmark } from '../models/benchmark';

import { config } from '@app/config';

@Injectable()
export class LocationBenchmarksService {
  private apiUrl = config().apiUrl + '/locations/{locationId}/benchmarks';

  constructor(private httpClient: HttpClient) {}

  private getApiUrl(locationId) {
    return this.apiUrl.replace('{locationId}', locationId);
  }

  /**
   * Get Location Benchmark
   * @param locationId
   * @param benchmarkId
   */
  getBenchmark(locationId: number, benchmarkId: string) {
    let apiUrl = this.getApiUrl(locationId) + '/' + benchmarkId;
    apiUrl += '?sortBy=start_time&orderBy=asc';

    return this.httpClient.get(apiUrl);
  }

  /**
   * Get Location Benchmarks
   * @param locationId
   */
  getBenchmarks(locationId: number, options?: { [key: string]: any }) {
    const apiUrl = this.getApiUrl(locationId);
    let queryParams: any = [];

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        queryParams.push(`${key}=${options[key]}`);
      }
    }

    queryParams = queryParams.length ? '?' + queryParams.join('&') : '';
    return this.httpClient.get(apiUrl + queryParams);
  }

  /**
   * Create Location Benchmark
   * @param data
   * @param debug
   */
  createLocationBenchmark(data: Benchmark, debug?: boolean) {
    const apiUrl = this.getApiUrl(data.locationId) + (debug ? '?debug' : '');
    return this.httpClient.post(apiUrl, data);
  }

  /**
   * Refresh Location Benchmark
   * @param data
   * @param debug
   */
  refreshLocationBenchmark(data: Benchmark) {
    const apiUrl = this.getApiUrl(data.locationId) + '/' + data.benchmarkId;
    return this.httpClient.patch(apiUrl, data);
  }

  /**
   * Delete Location Benchmark
   * @param benchmarkId
   */
  deleteLocationBenchmark(locationId: number, benchmarkId: string) {
    const apiUrl = this.getApiUrl(locationId) + '/' + benchmarkId;
    return this.httpClient.delete(apiUrl);
  }
}
