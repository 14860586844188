import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { ModalConfig } from '@sc/modal/modal-config';

import { BenchmarkTemplatesService } from '../../services/benchmark-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-benchmark-template-action-cell',
  templateUrl: 'benchmark-template-action-cell.component.html'
})
export class BenchmarkTemplateActionCellComponent
  implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  formConfig: ModalConfig;
  subscribers: { [key: string]: any };

  constructor(
    private benchmarkTemplatesService: BenchmarkTemplatesService,
    private sharedService: SharedService
  ) {}

  ngOnDestroy() {
    for (const key in this.subscribers) {
      if (this.subscribers.hasOwnProperty(key) && this.subscribers[key]) {
        this.subscribers[key].unsubscribe();
      }
    }
  }

  agInit(params: any): void {
    this.subscribers = {};
    this.cell = params;
    this.formConfig = {
      name: 'BenchmarkTemplateForm',
      data: this.cell.data,
      options: { modalTitle: 'BENCHMARK_TEMPLATE_FORM_TITLE' }
    };
  }

  refresh(): boolean {
    return false;
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.subscribers.DELETE_DATA = this.benchmarkTemplatesService
      .deleteBenchmarkTemplate(this.cell.data.id)
      .subscribe(
        (result: any) => {
          this.cell.context.parentComponent.updateRow({
            id: this.cell.data.id,
            isActive: false,
            isDeleted: true
          });
          this.sharedService.notify(
            'Benchmark Templates',
            'Delete success!',
            'success'
          );
        },
        (error: any) => {
          this.sharedService.notify(
            'Benchmark Templates',
            'Delete fail!',
            'error'
          );
        }
      );
  }
}
