import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Rule } from '@app/ceos-rule-editor/models';
import { RuleRequestOptions } from '../models/rule-request-options';
import { SharedService } from '@shared/shared.service';
import { config } from '@app/config';

@Injectable()
export class CeosRulesService {
  private apiUrl = config().apiUrl + '/ceosRules';

  constructor(private httpClient: HttpClient, private sharedService: SharedService) {}

  private verifyData(data: any): Rule {
    const newData = { ...data };
    delete newData.id;
    delete newData.idx;
    delete newData.automation_idx;
    delete newData.rule_template_idx;
    delete newData.created_at;
    delete newData.created_by;
    delete newData.updated_at;
    delete newData.updated_by;
    return newData;
  }

  getCeosRule(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getCeosRules(options?: RuleRequestOptions) {
    // if (!options.siteId) {
    //   options.siteId = this.sharedService.selectedSite.id;
    // }
    const queryParams = this.sharedService.urlQueryParamsCreator(options);
    const url = this.apiUrl + queryParams;
    return this.httpClient.get(url);
  }

  createCeosRule(data: Rule) {
    return this.httpClient.post(this.apiUrl, data);
  }

  updateCeosRule(id: number, data: Rule) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, this.verifyData(data));
  }

  deleteCeosRule(id: number) {
    return this.httpClient.patch(`${this.apiUrl}/${id}`, {
      is_active: false,
      is_deleted: true,
    });
  }

  getRuleEditorData(automationIdx: string) {
    const queryParams = this.sharedService.urlQueryParamsCreator({ a: automationIdx });
    const url = `${config().apiUrl}/_backend/rules/editorData${queryParams}`;
    return this.httpClient.get(url);
  }

  getRuleBulkEditorData(options: { automationIdxs?: string[]; siteId?: number } = {}) {
    let queryParams = '';
    if (options.siteId) {
      queryParams = this.sharedService.urlQueryParamsCreator({ s: options.siteId });
    } else if (options.automationIdxs && options.automationIdxs.length) {
      queryParams = this.sharedService.urlQueryParamsCreator({ a: options.automationIdxs.join(',') });
    }
    const url = `${config().apiUrl}/_backend/rules/bulk/editorData${queryParams}`;
    return this.httpClient.get(url);
  }

  updateRuleBulk(rules: Rule[]) {
    const url = `${config().apiUrl}/_backend/rules/bulk`;
    return this.httpClient.post(url, { rules });
  }
}
