import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { BulkModificationField } from '../models/bulk-modification-field.model';

@Injectable()
export class BulkModificationFormService {
  constructor(private formBuilder: FormBuilder) {}

  /**
   * Create Bulk Modification Form
   */
  initBulkModificationForm(): FormGroup {
    return this.formBuilder.group({
      table: [null, Validators.required],
      fields: this.formBuilder.array(
        [this.initBulkModificationFieldForm()],
        Validators.required
      )
    });
  }

  /**
   * Create Bulk Modification Field Form
   */
  initBulkModificationFieldForm(): FormGroup {
    return this.formBuilder.group({
      field: [null, Validators.required],
      value: [null, Validators.required],
      type: [null, Validators.required]
    });
  }

  // /**
  //  * Set Bulk Modification Fields Form Array
  //  * @param form
  //  * @param data
  //  */
  // setBulkModificationFieldFormArray(
  //   form: FormGroup,
  //   data: BulkModificationField[]
  // ) {
  //   const formGroups = data.map(item => {
  //     const fg = this.initBulkModificationFieldFormGroup();
  //     fg.patchValue(item);
  //     return fg;
  //   });
  //   const formArray = this.formBuilder.array(formGroups);
  //   form.setControl('fields', formArray);
  // }
}
