import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomFunctionParameter } from '../models/custom-function-parameter';
import { CustomFunctionParameterTypes } from '../enums/custom-function-parameter-type';

@Injectable()
export class CustomFunctionFormService {
  constructor(private formBuilder: FormBuilder) {}

  initCustomFunctionForm(): FormGroup {
    const fields: any = {
      id: { value: null, disabled: true },
      idx: { value: null, disabled: true },
      name: [null, Validators.required],
      return_type: [null, Validators.required],
      syntax: [null, Validators.required],
      parameters: this.formBuilder.array([]),
      is_active: false,
      is_deleted: false,
    };
    return this.formBuilder.group(fields);
  }

  initCustomFunctionParameterForm(): FormGroup {
    const fields: any = {
      id: [null, Validators.required],
      label: [null, Validators.required],
      type: [null, Validators.required],
      custom_global_idx: null,
      device_idx: null,
      device_type: null,
      location_idx: null,
      property: null,
    };
    return this.formBuilder.group(fields);
  }

  setCustomFunctionParameterForm(form: FormGroup, data: CustomFunctionParameter[]): void {
    const formGroups = data.map((item) => {
      const fg = this.initCustomFunctionParameterForm();
      fg.patchValue(item);
      return fg;
    });
    const formArray = this.formBuilder.array(formGroups);
    form.setControl('parameters', formArray);
    form.updateValueAndValidity();
  }

  setCustomFunctionParameterValidator(form: FormGroup, type: string) {
    form.get('custom_global_idx').clearValidators();
    form.get('custom_global_idx').reset();
    // form.get('device_idx').clearValidators();
    form.get('device_idx').reset();
    form.get('device_type').clearValidators();
    form.get('device_type').reset();
    // form.get('location_idx').clearValidators();
    form.get('location_idx').reset();
    form.get('property').clearValidators();
    form.get('property').reset();

    switch (type) {
      case CustomFunctionParameterTypes.CustomGlobalValue:
        form.get('custom_global_idx').setValidators(Validators.required);
        break;
      case CustomFunctionParameterTypes.LatestDhlValue:
        // form.get('device_idx').setValidators(Validators.required);
        form.get('device_type').setValidators(Validators.required);
        break;
      case CustomFunctionParameterTypes.LatestLhlValue:
        // form.get('location_idx').setValidators(Validators.required);
        form.get('property').setValidators(Validators.required);
        break;
    }

    form.updateValueAndValidity();
  }
}
