import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';

import { ModalConfig } from '@sc/modal/modal-config';
import { Page } from '../models/page';
import { Section } from '../models/page-section';
import { Widget } from '../models/page-section-widget';

@Component({
  selector: 'sc-page-section',
  templateUrl: './page-section.component.html',
  styleUrls: ['./page-section.component.scss']
})
export class PageSectionComponent implements OnInit, OnChanges {
  @Input()
  allowCustom: boolean;
  @Input()
  editMode: boolean;
  @Input()
  pageTemplates: Page[];
  @Input()
  widgets: Widget[];
  @Input()
  sections: Section[];
  @Input()
  selected: Section;

  @Output()
  addSection = new EventEmitter<Section>();
  @Output()
  deleteSection = new EventEmitter<Section>();
  @Output()
  moveSection = new EventEmitter<Section[]>();
  @Output()
  resetSection = new EventEmitter<Section>();
  @Output()
  switchSection = new EventEmitter<Section>();

  displaySections: Section[];
  modalConfig: ModalConfig;

  constructor() {}

  ngOnInit() {
    this.modalConfig = {
      name: 'PageSectionForm',
      data: {
        pageTemplates: this.pageTemplates,
        widgets: this.widgets
      },
      options: {
        modalTitle: 'Add Section'
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sections && changes.sections.currentValue) {
      this.displaySections = [...changes.sections.currentValue];
    }
  }

  onAddSection(event) {
    if (event && event.result) {
      const section = {
        ...event.result,
        position: this.sections.length
      };
      this.addSection.emit(section);
    }
  }

  onSelected(section) {
    if (this.selected.name === section.name) {
      return 'selected';
    }
    return '';
  }
}
