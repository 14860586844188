<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">
        {{ 'DETAILS' | translate }}
      </h4>

      <div class="sc-widget-menu">
        <!-- <button
          pButton
          type="button"
          class="ui-button-secondary"
          icon="fa fa-refresh {{ isFetchingDataset ? 'fa-spin' : '' }}"
          iconPos="left"
          (click)="fetchDetails()"
          [disabled]="isFetchingDataset"
          pTooltip="{{ 'REFRESH' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></button> -->
        <i
          class="fa fa-refresh fa-fw sc-clickable green-text"
          [ngClass]="{'fa-spin': isFetchingDataset}"
          (click)="fetchDetails()"
          pTooltip="{{ 'REFRESH' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        ></i>

        <!-- buttonIcon="fa fa-pencil" -->
        <sc-modal-trigger
          *scHasPermission="'device_u'"
          [modalConfig]="detailFormConfig"
          (onClose)="onUpdateDetailsSuccess($event)"
          pTooltip="{{ 'EDIT' | translate }}"
          tooltipPosition="top"
          appendTo="body"
        >
          <i class="fa fa-pencil fa-fw sc-clickable green-text"></i>
        </sc-modal-trigger>
      </div>
    </div>

    <ng-container *ngIf="isFetchingDataset; then spinner; else content"></ng-container>
  </div>
</div>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body justify-start align-start" style="overflow: auto;">
    <ul class="device-detail-list">
      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'LOCATION' | translate }}
        </div>

        <div class="detail-value">
          <a class="sc-clickable green-text" (click)="gotoLocation()">
            {{ selectedDevice?.location }}
          </a>
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'DEVICE_BRAND' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.deviceBrand }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'DEVICE_MODEL' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.deviceModel }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'DEVICE_TYPE' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.deviceType }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'AUTOMATION_VERSION' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.automationVersion }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'API_KEY' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.apiKey }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'PIN' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.pin }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'IP' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.ip }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'PORT' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.port }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'ANYDESK' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.anydeskId }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'ACTIVE' | translate }}
        </div>

        <div class="detail-value">
          <span
            class="badge"
            [ngClass]="{
              green: selectedDevice?.isActive,
              grey: !selectedDevice?.isActive
            }"
          >
            {{ (selectedDevice?.isActive ? 'YES' : 'NO') | translate }}
          </span>
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'ZWAVE' | translate }}
        </div>

        <div class="detail-value">
          <span
            class="badge"
            [ngClass]="{
              green: selectedDevice?.zwaveStatus,
              grey: !selectedDevice?.zwaveStatus
            }"
          >
            {{ (selectedDevice?.zwaveStatus ? 'YES' : 'NO') | translate }}
          </span>
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'ZWAVE_CONTROLLER_PORT' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.zwaveControllerPort }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'ZIGBEE' | translate }}
        </div>

        <div class="detail-value">
          <span
            class="badge"
            [ngClass]="{
              green: selectedDevice?.zigbeeStatus,
              grey: !selectedDevice?.zigbeeStatus
            }"
          >
            {{ (selectedDevice?.zigbeeStatus ? 'YES' : 'NO') | translate }}
          </span>
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'ZIGBEE_CONTROLLER_PORT' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.zigbeeControllerPort }}
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'DEVICE_ALERT' | translate }}
        </div>

        <div class="detail-value">
          <span
            class="badge"
            [ngClass]="{
              green: selectedDevice?.isDeviceAlertEnabled,
              grey: !selectedDevice?.isDeviceAlertEnabled
            }"
          >
            {{ (selectedDevice?.isDeviceAlertEnabled ? 'YES' : 'NO') | translate }}
          </span>
        </div>
      </li>

      <li class="device-detail-item">
        <div class="detail-label">
          {{ 'DEVICE_ALERT_TIMEOUT' | translate }}
        </div>

        <div class="detail-value">
          {{ selectedDevice?.deviceAlertTimeout }}
          {{ (selectedDevice?.deviceAlertTimeout ? 'MINUTES' : '') | translate }}
        </div>
      </li>
    </ul>
  </div>
</ng-template>
