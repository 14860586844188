import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

import { CeosRuleEditorModule } from '@app/ceos-rule-editor/ceos-rule-editor.module';

import { CommandFormComponent } from './rule-template-form/command-form/command-form.component';
import { ConditionsFormComponent } from './rule-template-form/condition-form/condition-form.component';
import { RuleTemplateActionCellComponent } from './rule-template-action-cell/rule-template-action-cell.component';
import { RuleTemplateFormComponent } from './rule-template-form/rule-template-form.component';
import { RuleTemplatesTableComponent } from './rule-templates-table/rule-templates-table.component';
import { CeosRuleTemplatesTableComponent } from './ceos-rule-templates-table/ceos-rule-templates-table.component';
import { CeosRuleTemplateFormComponent } from './ceos-rule-template-form/ceos-rule-template-form.component';
import { CeosRuleTemplateActionCellComponent } from './ceos-rule-template-action-cell/ceos-rule-template-action-cell.component';

import { RuleTemplateFormsService } from './services/rule-template-form.service';
import { RuleTemplatesService } from './services/rule-templates.service';
import { CeosRuleTemplatesService } from './services/ceos-rule-templates.service';

@NgModule({
  imports: [SharedModule, CeosRuleEditorModule],
  exports: [],
  declarations: [
    CommandFormComponent,
    ConditionsFormComponent,
    RuleTemplateActionCellComponent,
    RuleTemplateFormComponent,
    RuleTemplatesTableComponent,
    CeosRuleTemplatesTableComponent,
    CeosRuleTemplateFormComponent,
    CeosRuleTemplateActionCellComponent,
  ],
  entryComponents: [
    RuleTemplateActionCellComponent,
    RuleTemplateFormComponent,
    RuleTemplatesTableComponent,
    CeosRuleTemplateActionCellComponent,
    CeosRuleTemplateFormComponent,
    CeosRuleTemplatesTableComponent,
  ],
  providers: [RuleTemplateFormsService, RuleTemplatesService, CeosRuleTemplatesService],
})
export class RuleTemplatesModule {}
