import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Device } from '@app/widgets/devices/models/device';

export const PROTOCOLS = [10, 15];
export const EXTENDERS = [89, 102, 113];

@Injectable({
  providedIn: 'root',
})
export class DeviceFormService {
  constructor(private formBuilder: FormBuilder) {}

  initDeviceWizardForm(isAutomation = false): FormGroup {
    const fields: any = {
      floorNumber: [null, Validators.required],
      locationIdx: [null, Validators.required],
      automationId: [null, Validators.required],
      deviceModelId: [null, Validators.required],
      name: [null, Validators.required],
      uuid: [null],
      properties: this.formBuilder.array([]), // properties of device model
    };

    if (isAutomation) {
      fields.automationId = null;
      fields.expectedOnline = null;
      fields.maxSeenMinutesAgo = null;
      fields.operatingMode = 'automation';
      fields.modbusActive = false;
      fields.modbusSettings = this.formBuilder.group({
        buses: this.formBuilder.array([
          this.formBuilder.group({
            method: ['rtu'],
            port: [],
            baudrate: ['19200'],
            parity: ['N'],
            isActive: [true],
            bytesize: [8, [Validators.min(7), Validators.max(8)]],
            stopbits: [1, [Validators.min(0), Validators.max(2)]],
          }),
        ]),
      });
      fields.gatewaySettings = this.formBuilder.group({
        coolautomation: this.formBuilder.array([
          this.formBuilder.group({
            serialNumber: [],
            ip: [],
            port: [],
            restPort: [],
          }),
        ]),
      });
    }

    return this.formBuilder.group(fields);
  }

  initDevicePropertyForm(editMode = false): FormGroup {
    const fields: any = {
      automationId: [null, Validators.required],
      brandId: [null, Validators.required],
      description: [null, Validators.required],
      deviceTypeId: [null, Validators.required],
      deviceModelId: [null, Validators.required],
      protocolId: [null, Validators.required],
      locationIdx: [null, Validators.required],
      locationId: [null, Validators.required],
      siteId: [null, Validators.required],
      uniqueId: [null, Validators.required],
      diffDetectionFactor: null,
      excludeFromLHL: false,
      isActive: false,
      isHidden: false,
      isVirtualDevicesHolder: false,
      isVirtualDevice: true,
      uuid: null,
    };
    if (editMode) {
      fields.id = null;
    }
    return this.formBuilder.group(fields);
  }

  patchDeviceProperties(form: FormGroup, properties: any[], editMode = false) {
    const propertiesForm = form.get('properties') as FormArray;
    propertiesForm.clear();
    for (const property of properties) {
      const propertyForm = this.initDevicePropertyForm(editMode);
      propertyForm.patchValue(property);
      propertiesForm.push(propertyForm);
    }
  }

  isCeosReachDevicesLimitation(automationId: number, devices: Device[], nbAddedDevices: number = 1): boolean {
    const deviceLimit = 20;

    // Check
    const nbDevicesLinked = devices.filter(
      (device) =>
        device.isActive &&
        !device.isDeleted &&
        device.deviceTypeKey === 'vdh' &&
        !PROTOCOLS.includes(device.protocolId) && //Exclude virtual devices based on internal protocol
        device.automationId === automationId &&
        !EXTENDERS.includes(device.deviceModelId) //Exclude extenders
    ).length;
    if (nbDevicesLinked + nbAddedDevices > deviceLimit) {
      return true;
    }
    return false;
  }
}
