import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { ModalConfig } from '@sc/modal/modal-config';
import { Field } from '../models/field.model';

import { FieldsService } from '../services/fields.service';
import { SharedService } from '@shared/shared.service';

import { CheckboxCellComponent } from '@widgets/_shared/checkbox-cell/checkbox-cell.component';
import { FieldActionCellComponent } from '../field-action-cell/field-action-cell.component';

@Component({
  selector: 'sc-fields-overview',
  templateUrl: './fields-overview.component.html',
  styleUrls: ['./fields-overview.component.scss']
})
export class FieldsOverviewComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: Field[];
  columns: any[];
  formConfig: ModalConfig;
  tableTitle: string;

  private subscribers: { [key: string]: any } = {};

  constructor(
    private fieldsService: FieldsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    // Table name
    this.tableTitle = 'FORM_FIELD_TABLE_TITLE';

    // Table Columns
    this.columns = this.createColumns();

    // Create Form Config
    this.formConfig = {
      name: 'FieldsForm',
      options: { modalTitle: 'FORM_FIELD_FORM_TITLE' }
    };

    this.fetchDataset();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.subscribers.GET_FORM_FIELDS = this.fieldsService
      .getFields()
      .subscribe((result: any) => {
        this.dataset = result.data;
      });
  }

  private createColumns() {
    return [
      {
        colId: 'id',
        headerName: 'ID',
        field: 'id'
      },
      {
        colId: 'name',
        headerName: 'NAME',
        field: 'name'
      },
      {
        colId: 'table',
        headerName: 'TABLE',
        field: 'table'
      },
      {
        colId: 'type',
        headerName: 'FORM_FIELD_TYPE',
        field: 'type'
      },
      {
        colId: 'displayType',
        headerName: 'FORM_FIELD_DISPLAY_TYPE',
        field: 'displayType'
      },
      {
        colId: 'defaultValue',
        headerName: 'FORM_FIELD_DEFAULT_VALUE',
        field: 'defaultValue'
      },
      {
        colId: 'size',
        headerName: 'FORM_FIELD_SIZE',
        field: 'size'
      },
      {
        colId: 'bulkModificationEnabled',
        headerName: 'FORM_FIELD_BULK_MODIFICATION',
        field: 'bulkModificationEnabled',
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isActive',
        headerName: 'ACTIVE',
        field: 'isActive',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'isDeleted',
        headerName: 'DELETED',
        field: 'isDeleted',
        filter: 'agNumberColumnFilter',
        suppressFilter: true,
        cellRendererFramework: CheckboxCellComponent
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: FieldActionCellComponent
      }
    ];
  }
}
