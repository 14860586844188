import { LocationsService } from '@widgets/locations/services/locations.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';
import { Site } from '@widgets/sites/models/site';
import { SCWidgetService } from '@widgets/widget.service';
import { SharedService } from '@shared/shared.service';
import { Page } from '@app/core2/models/page';
import { PageService } from '@app/core2/page/page.service';
import { TreeNode } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'sc-locations-occupancy-live',
  templateUrl: './locations-occupancy-live.component.html',
})
export class LocationsOccupancyLiveComponent implements OnInit, OnDestroy {
  // The widget with its configuration
  @Input() config: any;

  isConfigured: boolean = false;

  page: Page;
  selectedSite: Site;

  subscribers: { [key: string]: any } = {};
  intervals: { [key: string]: any } = {};

  chartData: any;
  chartLayout: any;

  isInitialized: boolean = false;
  isFetchingStatistics: boolean = false;
  isFetchingLocations: boolean = false;

  widgetName: string = '';
  defaultWidgetName: string = '';
  customWidgetName: string = '';

  occupiedColor: any;
  defaultOccupiedColor: any;
  customOccupiedColor: any;
  vacantColor: any;
  defaultVacantColor: any;
  customVacantColor: any;
  colors: any[];

  // Config modal
  isConfigModalOpen: boolean = false;
  isConfigModalInitialized: boolean = false;
  useLocationSelection: boolean = false;
  locationsTree: TreeNode[];
  selectedLocations: TreeNode[] = [];

  // Details modal
  isDetailsModalOpen: boolean = false;
  isDetailsModalInitialized: boolean = false;

  // Statistics
  numTotalLocations: number;
  numOccupiedLocations: number;
  numVacantLocations: number;
  occupiedLocations: any[];
  vacantLocations: any[];

  flatLocations: any[];
  nestedLocations: any[];
  currentUser: any;

  constructor(
    private widgetService: SCWidgetService,
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private pageService: PageService,
    private locationsService: LocationsService
  ) {
    this.chartLayout = {
      height: 190,
      width: 190,
      showlegend: false,
      margin: { t: 15, b: 15, l: 15, r: 15 },
      font: {
        family: 'Barlow',
        size: 13,
      },
    };
  }

  ngOnInit() {
    this.initConfig();
    this.fetchInitialData();
    this.checkConfig();
    this.intervals.fetchDataset = setInterval(() => this.fetchStatistics(), 10000);
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearIntervals(this.intervals);
  }

  checkConfig() {
    this.isConfigured = this.useLocationSelection && !this.getLocationIdxsFromCustomPreferences();
  }

  initConfig() {
    this.initWidgetName();
    this.initColors();
    if (this.config?.customPreferences?.useSelection) {
      this.useLocationSelection = this.config.customPreferences.useSelection;
    }
  }

  initWidgetName() {
    this.defaultWidgetName = this.sharedService.getLocaleWidgetName(this.config);
    if (this.config?.customPreferences?.name) {
      this.customWidgetName = this.config.customPreferences.name;
    }
    this.widgetName = this.customWidgetName ? this.customWidgetName : this.defaultWidgetName;
  }

  initColors() {
    this.colors = [
      {
        name: 'Default',
        key: '',
      },
    ].concat(this.widgetService.colors);

    this.defaultOccupiedColor = this.widgetService.getColorByKey(this.widgetService.COLOR_LIGHTGREEN);
    this.defaultVacantColor = this.widgetService.getColorByKey(this.widgetService.COLOR_GREEN);

    if (this.config?.customPreferences?.occupiedColor) {
      this.customOccupiedColor = this.widgetService.getColorByKey(this.config.customPreferences.occupiedColor);
    }

    if (this.config?.customPreferences?.vacantColor) {
      this.customVacantColor = this.widgetService.getColorByKey(this.config.customPreferences.vacantColor);
    }

    this.occupiedColor = this.customOccupiedColor ? this.customOccupiedColor : this.defaultOccupiedColor;
    this.vacantColor = this.customVacantColor ? this.customVacantColor : this.defaultVacantColor;
  }

  fetchInitialData() {
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe({
      next: (site) => {
        this.selectedSite = site;
        this.subscribers.watchPage = this.store.pipe(select(fromStore.getCurrentPage)).subscribe({
          next: (page) => {
            this.page = page;
            this.fetchStatistics();
            this.subscribers.watchUser = this.store.pipe(select(fromStore.getUserState)).subscribe({
              next: (user) => {
                this.currentUser = user;
                this.isInitialized = true;
              },
            });
          },
        });
      },
    });
  }

  fetchLocations() {
    if (this.isFetchingLocations) {
      return;
    }
    this.isFetchingLocations = true;
    const options = {
      siteId: this.selectedSite.id,
      columns: ['id', 'idx', 'locationTypeId', 'isActive', 'isDeleted', 'description'].join(),
      tree: true,
      minimize: true,
    };

    this.subscribers.fetchLocations = this.locationsService.getLocations(options).subscribe((result: any) => {
      this.nestedLocations = result.mappingKeys
        ? this.sharedService.decodeMiminizeObjects(result.data, result.mappingKeys)
        : result.data;
      this.flatLocations = this.widgetService.flattenTreeRecursively(this.nestedLocations);
      this.locationsTree = this.widgetService.nestedDatasetToTreeNode(this.nestedLocations);

      if (this.occupiedLocations) {
        this.occupiedLocations = this.widgetService.patchPathToLocations(this.occupiedLocations, this.flatLocations);
      }

      if (this.vacantLocations) {
        this.vacantLocations = this.widgetService.patchPathToLocations(this.vacantLocations, this.flatLocations);
      }

      this.isDetailsModalInitialized = true;
      this.checkSelectedLocations(this.locationsTree);
      this.isFetchingLocations = false;
    });
  }

  goToLocation(locationId, locationTypeId) {
    let path = [
      this.currentUser.mainRole,
      'sites',
      this.selectedSite.id,
      locationTypeId === 'f' ? 'floors' : 'locations',
      locationId,
    ];
    this.store.dispatch(new fromStore.Go({ path }));
  }

  fetchStatistics() {
    if (this.isFetchingStatistics) {
      return;
    }

    const locationIdxs = this.getLocationIdxsFromCustomPreferences();
    if (this.useLocationSelection && !locationIdxs) {
      return;
    }

    this.isFetchingStatistics = true;

    this.subscribers.fetchDataset = this.widgetService
      .getLocationsOccupancyLiveStats(this.selectedSite.id, locationIdxs, this.useLocationSelection)
      .subscribe(
        (result: any) => {
          this.isFetchingStatistics = false;

          let stats = result.data.stats;
          this.numTotalLocations = stats.total;
          this.numOccupiedLocations = stats.occupied;
          this.numVacantLocations = stats.vacant;

          let occupiedLocations = [];
          let vacantLocations = [];
          for (let location of result.data.locations) {
            if (location.lastChange) {
              location.lastChange = this.sharedService.dateFormat(location.lastChange);
            }

            if (location.isOccupied) {
              occupiedLocations.push(location);
            } else {
              vacantLocations.push(location);
            }
          }

          if (this.flatLocations) {
            occupiedLocations = this.widgetService.patchPathToLocations(occupiedLocations, this.flatLocations);
            vacantLocations = this.widgetService.patchPathToLocations(vacantLocations, this.flatLocations);
          }

          this.occupiedLocations = occupiedLocations;
          this.vacantLocations = vacantLocations;
          this.renderChart(this.numOccupiedLocations, this.numVacantLocations);
        },
        (error: any) => {
          this.isFetchingStatistics = false;
        }
      );
  }

  getLocationIdxsFromCustomPreferences() {
    let locationIdxs: string[] = [];
    if (this.config?.customPreferences?.locations) {
      for (let idx of this.config?.customPreferences?.locations) {
        locationIdxs.push(idx);
      }
    }
    return locationIdxs;
  }

  checkSelectedLocations(locationsTree) {
    const locationIdxs = this.getLocationIdxsFromCustomPreferences();
    if (locationIdxs) {
      this.selectedLocations = this.widgetService.checkSelectedTreeNodes(locationsTree, locationIdxs);
    }
  }

  resetWidgetName() {
    this.customWidgetName = '';
  }

  resetOccupiedColor() {
    this.customOccupiedColor = null;
  }

  resetVacantColor() {
    this.customVacantColor = null;
  }

  openConfigModal() {
    this.fetchLocations();
    this.isConfigModalOpen = true;
  }

  saveConfig() {
    this.isConfigModalOpen = false;

    let selectedLocationIdxs = [];
    for (let sl of this.selectedLocations) {
      selectedLocationIdxs.push(sl.data);
    }

    let { page, sectionIndex } = this.widgetService.setWidgetCustomPreferences(this.page, this.config.uniqueId, {
      name: this.customWidgetName,
      useSelection: this.useLocationSelection,
      locations: selectedLocationIdxs,
      occupiedColor: this.customOccupiedColor?.key,
      vacantColor: this.customVacantColor?.key,
    });

    this.store.dispatch(new fromStore.UpdatePage(page));
    //this.store.dispatch(new fromStore.VisualisePage(page));
    //this.store.dispatch(new fromStore.VisualiseSection(page.sections[sectionIndex]));
    //this.store.dispatch(new fromStore.VisualiseWidgets(page.sections[sectionIndex].widgets));
  }

  openDetailsModal() {
    this.fetchLocations();
    this.isDetailsModalOpen = true;
  }

  renderChart(numOccupied, numVacant) {
    this.chartData = [
      {
        type: 'pie',
        values: [numOccupied, numVacant],
        labels: [this.sharedService.getTranslation('OCCUPIED'), this.sharedService.getTranslation('VACANT')],
        marker: {
          colors: [this.occupiedColor.hex, this.vacantColor.hex],
        },
        textposition: 'inside',
        hole: 0.6,
      },
    ];
  }
}
