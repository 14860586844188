import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import { PageHeaderDirective } from './page-header.directive';

import * as HEADERS from './page-headers';

@Component({
  selector: 'sc-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  page: any;

  @ViewChild(PageHeaderDirective, { static: true })
  scPageHeader: PageHeaderDirective;

  previousPage: any;
  view: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.loadComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.page && changes.page.currentValue) {
      if (!this.previousPage || changes.page.currentValue.name !== this.previousPage.name) {
        this.clearHeaderContent();
        this.previousPage = { ...changes.page.currentValue };
        this.loadComponent();
      }
    }
  }

  ngOnDestroy() {
    this.clearHeaderContent();
  }

  getComponent() {
    if (this.page && this.page.key) {
      return HEADERS[this.page.key];
    }
    return;
  }

  loadComponent() {
    const headerComponent = this.getComponent();
    if (headerComponent) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(headerComponent);

      this.view = this.scPageHeader.viewContainerRef;
      this.clearHeaderContent();
      this.view.createComponent(componentFactory);
    }
  }

  private clearHeaderContent() {
    if (this.view) {
      this.view.clear();
    }
  }
}
