import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ActionItemModal, ActionItemConfirm, ActionItemButton } from '@sc/action-button/action-button.component';
import { ModalConfig } from '@sc/modal/modal-config';
import { ReportTemplatesService } from '../services/report-templates.service';
import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-report-template-action-cell',
  templateUrl: './report-template-action-cell.component.html',
  styleUrls: ['./report-template-action-cell.component.scss'],
})
export class ReportTemplateActionCellComponent implements ICellRendererAngularComp, OnDestroy {
  cell: any;
  formConfig: ModalConfig;
  actions: (ActionItemModal | ActionItemConfirm | ActionItemButton)[];

  private subscribers: any = {};

  constructor(private reportTemplatesService: ReportTemplatesService, private sharedService: SharedService) {}

  agInit(params: any): void {
    this.cell = params;
    this.formConfig = {
      name: 'ReportTemplateForm',
      data: this.cell.data,
      options: { modalTitle: 'REPORT_TEMPLATE_FORM_TITLE' },
    };

    this.actions = [
      {
        key: 'EDIT',
        icon: 'fa fa-pencil',
        type: 'modal',
        modalConfig: this.formConfig,
        onModalClose: this.updateRow.bind(this),
      },
      {
        key: 'DELETE',
        icon: 'fa fa-trash',
        type: 'confirm',
        onConfirm: this.deleteRow.bind(this),
      },
    ];
  }

  refresh(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  updateRow(event) {
    if (event.result) {
      const dataToUpdate = event.result;
      dataToUpdate.id = this.cell.data.id;
      this.cell.context.parentComponent.updateRow(dataToUpdate);
    }
  }

  deleteRow() {
    this.subscribers.delete = this.reportTemplatesService.deleteReportTemplate(this.cell.data.id).subscribe(
      (response: any) => {
        this.cell.context.parentComponent.updateRow({
          id: this.cell.data.id,
          is_active: false,
          is_deleted: true,
        });
        const title = this.sharedService.getTranslation('REPORT_TEMPLATE');
        const text = this.sharedService.getTranslation('DELETE_SUCCESS');
        this.sharedService.notify(title, text, 'success');
      },
      (error: any) => {
        const title = this.sharedService.getTranslation('REPORT_TEMPLATE');
        const text = this.sharedService.getTranslation('DELETE_FAIL');
        this.sharedService.notify(title, text, 'error');
      }
    );
  }
}
