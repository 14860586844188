import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ConfirmationService, TreeNode } from 'primeng/api';

import { FileManagerService } from './file-manager.service';

@Component({
  selector: 'sc-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})

export class FileManagerComponent implements OnInit {
  @Input()
  fileTree: TreeNode[] = [];
  @Input()
  loading: boolean;
  @Input()
  mode: 'browser'|'full';

  @Output()
  copyFile = new EventEmitter<any>();
  @Output()
  deleteFile = new EventEmitter<any>();
  @Output()
  moveFile = new EventEmitter<any>();
  @Output()
  openFile = new EventEmitter<any>();
  @Output()
  renameFile = new EventEmitter<any>();
  @Output()
  selectFile = new EventEmitter<any>();
  @Output()
  createFolder = new EventEmitter<any>();
  @Output()
  uploadFiles = new EventEmitter<any>();

  selectedFile: TreeNode;
  selectedDir: TreeNode;
  showUploadForm = false;

  private copy: TreeNode;
  private move: TreeNode;

  constructor(
    private fileManagerService: FileManagerService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.mode = this.mode || 'full';
  }

  isAllowInCurrentMode() {
    if (this.mode === 'full') {
      return true;
    }
    return false;
  }

  onSelected(file: TreeNode, from?: string) {
    // remove selected class of previous file
    if (this.selectedFile) {
      this.selectedFile.styleClass = '';
    }

    // update selected file
    this.selectedFile = file;
    this.selectedFile.styleClass = 'selected';

    this.selectFile.emit(file);

    // open the folder in the same time if selected from tree
    if (from === 'tree' && this.selectedFile.data && this.selectedFile.data.isDirectory) {
      this.selectedDir = file;
      this.onOpened(file);
    }
  }

  onOpened(file: TreeNode) {
    file.expanded = true;
    this.selectedDir = file;
    const path = this.fileManagerService.getPath(file)[0];
    this.openFile.emit({ file, path });
  }

  onOpenedFromPath(path: string) {
    if (path === '') {
      // go back to home
      this.openFile.emit({ path });
      // clear selected dir
      this.selectedDir = null;
    } else {
      const file = this.fileManagerService.findDir(this.fileTree, path);
      this.selectedDir = file;
      if (file) {
        this.openFile.emit({ file, path });
      }
    }
  }

  onCopy() {
    if (this.loading) {
      return;
    }
    this.copy = null;
    this.move = null;
    if (this.selectedFile) {
      this.copy = this.selectedFile;
    }
  }

  onMove() {
    if (this.loading) {
      return;
    }
    this.copy = null;
    this.move = null;
    if (this.selectedFile) {
      this.move = this.selectedFile;
    }
  }

  onPaste() {
    if (this.loading) {
      return;
    }
    const path = this.fileManagerService.getPath(this.selectedDir)[0];
    let file;
    let action;
    if (this.copy) {
      action = 'c';
      file = this.copy;
    } else if (this.move) {
      action = 'm';
      file = this.move;
    }
    if (file && file.data && file.data.path !== path) {
      if (action === 'c') {
        this.copyFile.emit({
          parentDir: this.selectedDir,
          copyFile: file,
          copyPath: path,
        });
      } else if (action === 'm') {
        this.moveFile.emit({
          parentDir: this.selectedDir,
          moveFile: file,
          movePath: path,
        });
      }
    }
  }

  onDelete() {
    if (this.loading) {
      return;
    }
    return this.confirmationService.confirm({
      header: 'Delete Confirmation',
      message: `Do you want to delete ${this.selectedFile.label}?`,
      accept: () => {
        this.deleteFile.emit({
          parentDir: this.selectedDir,
          deleteFile: this.selectedFile
        });
      }
    });
  }

  onRename(name: string) {
    if (!this.loading) {
      return this.renameFile.emit({
        file: this.selectedFile,
        newFileName: name
      });
    }
  }

  onNewFolder(name: string) {
    if (!this.loading) {
      const path = this.fileManagerService.getPath(this.selectedDir)[0];
      return this.createFolder.emit({
        parentDir: this.selectedDir,
        newFolderName: name,
        newFolderPath: path
      });
    }
  }

  onUpload(event) {
    const path = this.fileManagerService.getPath(this.selectedDir)[0];
    this.uploadFiles.emit({
      parentDir: this.selectedDir,
      uploadFiles: event,
      uploadPath: path
    });
    this.showUploadForm = false;
  }

  getDisplayFiles() {
    if (this.selectedDir && this.selectedDir.children) {
      return this.selectedDir.children;
    } else {
      return this.fileTree;
    }
  }
}
