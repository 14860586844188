<div class="customHeader" (click)="onSortRequested($event)">
  <div
    *ngIf="params.enableMenu"
    #menuButton
    class="customHeaderMenuButton"
    (click)="onMenuClicked($event)"
  >
    <i class="fa {{ params.menuIcon }}"></i>
  </div>

  <div class="customHeaderLabel">
    {{ params.displayName | translate: params.headerNameParams }}

    <div
      *ngIf="params.enableSorting && sortDir !== ''"
      class="customHeaderSortIcon"
    >
      <i class="fa fa-fw" [ngClass]="sortDirIcon()"></i>
    </div>
  </div>
</div>
