import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-condition-form',
  templateUrl: './condition-form.component.html'
})
export class ConditionFormComponent implements OnInit {
  @Input()
  group: FormGroup;
  @Input()
  groupId: any;
  @Input()
  optionData: any;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    if (this.group && this.group.value) {
      this.updateFormControl();
    }
  }

  updateFormControl() {
    switch (this.sourceType) {
      case 'd':
        this.group.controls['deviceId'].setValidators(Validators.required);
        this.group.controls['locationId'].clearValidators();
        this.group.controls['locationId'].reset();
        break;
      case 'l':
        this.group.controls['deviceId'].clearValidators();
        this.group.controls['deviceId'].reset();
        this.group.controls['locationId'].setValidators(Validators.required);
        break;
      default:
        break;
    }
  }

  get locations() {
    if (this.optionData && this.optionData.locations) {
      return this.optionData.locations;
    }
    return [];
  }

  get devices() {
    if (this.optionData && this.optionData.devices && this.deviceTypeId) {
      const devices = this.optionData.devices.filter(
        (d: any) => d.deviceTypeId === this.deviceTypeId
      );
      if (devices && devices.length) {
        return devices;
      }
    }
    return [];
  }

  get refDevices() {
    if (
      this.optionData &&
      this.optionData.devices &&
      this.referencedDeviceTypeId
    ) {
      const devices = this.optionData.devices.filter(
        (d: any) => d.deviceTypeId === this.referencedDeviceTypeId
      );
      if (devices && devices.length) {
        return devices;
      }
    }
    return [];
  }

  // isDeviceTypeRequiredNumValue() {
  //   const keys = ['acsp', 'bl', 'bs', 'hs', 'ts'];
  //   if (this.deviceTypeKey && keys.indexOf(this.deviceTypeKey) >= 0) {
  //     return true;
  //   }
  //   return false;
  // }

  get hasMappingValues() {
    switch (this.sourceType) {
      case 'd':
        return this.mappingValue && this.mappingValue.length ? true : false;

      case 'l':
        return this.mappingValue && this.mappingValue.length ? true : false;

      default:
        return false;
    }
  }

  get mappingValue() {
    switch (this.sourceType) {
      case 'd':
        // console.log('mappingValue:', this.deviceTypeMappingValues);
        return this.deviceTypeMappingValues;

      case 'l':
        // console.log('mappingValue:', this.locationPropertyMappingValues);
        return this.locationPropertyMappingValues;

      default:
        return;
    }
  }

  get deviceTypeKey() {
    if (
      this.optionData &&
      this.optionData.deviceTypesSource &&
      this.deviceTypeId
    ) {
      const deviceType = this.optionData.deviceTypesSource.find(
        d => d.id === this.deviceTypeId
      );
      if (deviceType) {
        return deviceType.key;
      }
    }
    return;
  }

  get deviceTypeMappingValues() {
    if (
      this.optionData &&
      this.optionData.deviceTypesSource &&
      this.deviceTypeId
    ) {
      const deviceType = this.optionData.deviceTypesSource.find(
        d => d.id === this.deviceTypeId
      );
      if (
        deviceType &&
        deviceType.mappingValues &&
        deviceType.mappingValues.length
      ) {
        return this.sharedService.createSelectItems(
          deviceType.mappingValues,
          false,
          'key'
        );
      }
    }
    return;
  }

  get locationPropertyMapping() {
    if (
      this.optionData &&
      this.optionData.locationPropertiesList &&
      this.locationPropertyTypeId
    ) {
      const locationProperty = this.optionData.locationPropertiesList.find(
        d => d.key === this.locationPropertyTypeId
      );
      return locationProperty;
    }
    return;
  }

  get locationPropertyMappingValues() {
    if (this.locationPropertyMapping) {
      switch (this.locationPropertyMapping.type) {
        case 'boolean':
          return this.optionData.booleanOption;

        case 'deviceType':
          if (this.optionData && this.optionData.deviceTypesSource) {
            const deviceType = this.optionData.deviceTypesSource.find(
              d => d.key === this.locationPropertyMapping.deviceType
            );
            if (
              deviceType &&
              deviceType.mappingValues &&
              deviceType.mappingValues.length
            ) {
              return this.sharedService.createSelectItems(
                deviceType.mappingValues,
                false,
                'key'
              );
            }
          }
          break;

        default:
          break;
      }
    }
    return;
  }

  get isRequiredTrueOneTime() {
    if (
      this.group.get('sourceType').value === 'd' &&
      this.group.get('referencedType').value === 'v'
    ) {
      return true;
    }
    return false;
  }

  get sourceType() {
    return this.group.get('sourceType').value;
  }

  get deviceTypeId() {
    return this.group.get('deviceTypeId').value;
  }

  get connector() {
    return this.group.get('connector').value;
  }

  get operator() {
    return this.group.get('operator').value;
  }

  get datetimePropertyTypeId() {
    return this.group.get('datetimePropertyTypeId').value;
  }

  get locationPropertyTypeId() {
    return this.group.get('locationPropertyTypeId').value;
  }

  get referencedLocationPropertyTypeId() {
    return this.group.get('referencedLocationPropertyTypeId').value;
  }

  get referencedDeviceTypeId() {
    return this.group.get('referencedDeviceTypeId').value;
  }
  get referencedType() {
    return this.group.get('referencedType').value;
  }

  get event() {
    return this.group.get('event').value || '(no event)';
  }

  get formBackgroundColor() {
    return this.groupId % 2 !== 0 ? 'grey lighten-4' : '';
  }
}
