<div class="action-menu">
  <ul class="menu-list">
    <ng-container *ngFor="let menu of menus; let i = index">
      <li
        *ngIf="!menu?.hidden"
        class="menu-item"
        (click)="onSelect(menu)"
        [ngClass]="{ 'menu-item--first': i === 0, 'menu-item--disabled': menu?.disabled }"
      >
        {{ menu.label | translate }}
      </li>
    </ng-container>
  </ul>
</div>
