import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { SharedService } from '@shared/shared.service';

@Component({
  selector: 'sc-rule-mass-edit-form',
  templateUrl: './rule-mass-edit-form.component.html'
})
export class RuleMassEditFormComponent implements OnInit, OnDestroy {
  @Input()
  form: FormGroup;
  @Input()
  data: { [key: string]: any };

  @Output()
  applyAllChanged = new EventEmitter<any>();

  applyAll: FormControl;

  private subscribers: { [key: string]: any } = {};
  private timeouts: { [key: string]: any } = {};

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.applyAll = new FormControl();

    // watch applyAll
    this.subscribers.APPLY_ALL = this.applyAll.valueChanges.subscribe(
      this.onFormValueChange.bind(this)
    );

    // watch isActive
    // this.subscribers.ACTIVE = this.form
    //   .get('isActive')
    //   .valueChanges.subscribe(this.onFormValueChange.bind(this));

    // watch startCommands
    this.subscribers.START_COMMANDS = this.form
      .get('startCommands')
      .valueChanges.subscribe(this.onFormValueChange.bind(this));

    // watch endCommands
    this.subscribers.END_COMMANDS = this.form
      .get('endCommands')
      .valueChanges.subscribe(this.onFormValueChange.bind(this));

    // watch startConditions
    this.subscribers.START_CONDITIONS = this.form
      .get('startConditions')
      .valueChanges.subscribe(this.onFormValueChange.bind(this));

    // watch endConditions
    this.subscribers.END_CONDITIONS = this.form
      .get('endConditions')
      .valueChanges.subscribe(this.onFormValueChange.bind(this));
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
    this.sharedService.clearTimeouts(this.timeouts);
  }

  get showApplyAll() {
    return this.data.isFirstOK ? true : false;
  }

  private emitApplyAll() {
    clearTimeout(this.timeouts.APPLY_ALL);
    this.timeouts.APPLY_ALL = setTimeout(() => {
      this.applyAllChanged.emit(this.form.value);
    }, 1000);
  }

  private onFormValueChange(value: any) {
    // if apply all is checked, re-emit the event
    if (this.applyAll.value === true) {
      this.emitApplyAll();
    }
  }
}
