<div
  class="rule-commands"
  [ngClass]="{ 'rule-commands--no-footer': !isTemplate && !isFromScratch }"
>
  <div class="rule-commands-body" *ngIf="commands?.length">
    <sc-command-item
      *ngFor="let command of commands; let i = index"
      [command]="command"
      [dataSources]="dataSources"
      [selectItems]="selectItems"
      [viewMode]="viewMode"
      [isTemplate]="isTemplate"
      [isFromScratch]="isFromScratch"
      [templateCommands]="templateCommands"
      (movedUp)="onCommandMovedUp(i)"
      (movedDown)="onCommandMovedDown(i)"
      (removed)="onCommandRemoved(i)"
    ></sc-command-item>
  </div>

  <!-- *ngIf="isTemplate" -->
  <div class="rule-commands-footer" *ngIf="isTemplate || isFromScratch">
    <sc-circle-button icon="plus" (clicked)="addCommand()"></sc-circle-button>
  </div>
</div>
