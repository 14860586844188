import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/store';

import { GridOptions, ColDef } from 'ag-grid';
import { BenchmarkTemplate } from '../../models/benchmark-template';
import { Benchmark } from '../../models/benchmark';
import { ModalConfig } from '@sc/modal/modal-config';
import { Location } from '@widgets/locations/models/location';

import { BenchmarkTemplatesService } from '../../services/benchmark-templates.service';
import { BenchmarksBatchService } from '../../services/benchmarks-batch.service';
import { SharedService } from '@shared/shared.service';
import { SCTableService } from '@sc/table/table.service';

import { LocationBenchmarkActionCellComponent } from '../location-benchmark-action-cell/location-benchmark-action-cell.component';

@Component({
  selector: 'sc-location-benchmarks-table',
  templateUrl: 'location-benchmarks-table.component.html'
})
export class LocationBenchmarksTableComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  columns: ColDef[];
  dataset: Benchmark[];
  formConfig: ModalConfig;
  analyseFormConfig: ModalConfig;
  tableTitle: string;
  isFetchingDataset: boolean;

  private table: GridOptions;
  private benchmarkTemplates: BenchmarkTemplate[];
  private selectedLocation: Location;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private store: Store<fromStore.State>,
    private benchmarkTemplatesService: BenchmarkTemplatesService,
    private benchmarksService: BenchmarksBatchService,
    private sharedService: SharedService,
    private tableService: SCTableService
  ) {}

  ngOnInit() {
    this.config.gridOptions = {
      floatingFilter: false,
      defaultColDef: {
        autoHeight: true,
        suppressFilter: true,
        suppressMenu: true,
        suppressSorting: true
      }
    };
    // Set new height of widget
    this.config.widgetHeight = this.config.widgetHeight - 25;
    // Set Table Name
    this.tableTitle = 'BENCHMARKS';
    // Set Table Columns
    this.columns = this.createColumns();
    // Benchmark Form
    this.formConfig = {
      name: 'LocationBenchmarkForm',
      options: { modalTitle: 'BENCHMARK_FORM_TITLE' }
    };
    // Analyse Form
    this.analyseFormConfig = {
      name: 'LocationBenchmarkAnalyseForm',
      options: { modalTitle: 'BENCHMARK_ANALYSE_FORM_TITLE' }
    };

    // get location details
    this.subscribers.LOCATION_DETAIL = this.store
      .select(fromStore.getLocationDetials)
      .subscribe(result => {
        if (result) {
          this.selectedLocation = result;
          this.fetchDataset();
        }
      });

    this.fetchBenchmarkTemplates();
  }

  ngOnDestroy() {
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    this.isFetchingDataset = true;
    if (this.selectedLocation) {
      this.subscribers.GET_DATASET = this.benchmarksService
        .getBenchmarks({
          locations: this.selectedLocation.id
        })
        .subscribe((result: any) => {
          this.dataset = result.data;
          this.isFetchingDataset = false;
        });
    }
  }

  fetchBenchmarkTemplates() {
    this.subscribers.GET_BENCHMARK_TEMPLATES = this.benchmarkTemplatesService
      .getBenchmarkTemplates()
      .subscribe((result: any) => {
        this.benchmarkTemplates = result.data;
      });
  }

  afterInitTable(table: GridOptions) {
    this.table = table;
    this.table.context.containerComponent = this;
  }

  deleteRow(id: string) {
    this.tableService.deleteTableRowV2(this.table, id);
  }

  private getBenchmarkTemplateName(params) {
    if (
      this.benchmarkTemplates &&
      params &&
      params.data &&
      params.data.benchmarkTemplateId
    ) {
      const data = this.benchmarkTemplates.find(
        r => r.id === +params.data.benchmarkTemplateId
      );
      return data ? data.name : '';
    }
    return '';
  }

  private createColumns() {
    return [
      {
        colId: 'startDate',
        headerName: 'DATE',
        field: 'start_time',
        sort: 'desc',
        valueFormatter: params =>
          this.sharedService.dateFormat(params.value, 'DD/MM/YYYY')
      },
      {
        colId: 'benchmarkTemplateName',
        headerName: 'BENCHMARK_TEMPLATE',
        valueFormatter: this.getBenchmarkTemplateName.bind(this),
        valueGetter: this.getBenchmarkTemplateName.bind(this)
      },
      {
        colId: 'id',
        headerName: 'ID',
        field: 'batch_id',
        hide: true
      },
      {
        colId: 'benchmarkTemplateId',
        headerName: 'BENCHMARK_TEMPLATE_ID',
        field: 'benchmarkTemplateId',
        hide: true
      },
      {
        colId: 'action',
        headerName: 'ACTION',
        pinned: 'right',
        suppressSorting: true,
        suppressFilter: true,
        cellRendererFramework: LocationBenchmarkActionCellComponent
      }
    ];
  }
}
