import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSources, SelectItem, SelectItems } from '../../models';

@Component({
  selector: 'sc-relative-datetime-operand',
  templateUrl: './relative-datetime-operand.component.html',
  styleUrls: ['./relative-datetime-operand.component.scss'],
})
export class RelativeDatetimeOperandComponent implements OnInit {
  timeUnits: SelectItem[];

  @Input()
  form: FormGroup;

  @Input()
  dataSources: DataSources = {};

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {
    this.timeUnits = [
      { label: 'Seconds', value: 's' },
      { label: 'Minutes', value: 'm' },
      { label: 'Hours', value: 'h' },
    ];

    if (this.form) {
      this.form.get('mode').setValue('ago');
    }
  }
}
