<div class="omnis-editor">
  <div class="omnis-editor-grid">
    <canvas #gridContainer></canvas>
  </div>

  <div class="omnis-editor-settings" [formGroup]="form">
    <div class="omnis-editor-panel">
      <div class="panel-header">
        <h4 class="panel-header-title">Square {{ selectedGridItem?.id }}</h4>
      </div>

      <div class="panel-body">
        <ul class="omnis-setting">
          <li class="omnis-setting-item">
            <div class="setting-label">
              Enabled
            </div>
            <div class="setting-action" *ngIf="selectedGridItem">
              <button
                type="button"
                class="omnis-action-button"
                (click)="toggleGridItem()"
              >
                <i
                  *ngIf="!selectedGridItem?.disabled"
                  class="fas fa-fw fa-check green-text"
                ></i>
                <i
                  *ngIf="selectedGridItem?.disabled"
                  class="fas fa-fw fa-times"
                ></i>
              </button>
            </div>
          </li>

          <li class="omnis-setting-item">
            <div class="setting-label">
              Line:
            </div>
            <div class="setting-value" *ngIf="selectedGridItem">
              <!-- TODO: show line name from id -->
              <!-- {{ selectedGridItem?.line }} -->
              {{ getLineName(selectedGridItem?.line) }}
            </div>
          </li>

          <li class="omnis-setting-item">
            <div class="setting-label">
              Scope:
            </div>
            <div class="setting-value" *ngIf="selectedGridItem">
              <!-- TODO: show scope name from id -->
              <!-- {{ selectedGridItem?.scope }} -->
              {{ getScopeName(selectedGridItem?.scope) }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="omnis-editor-panel">
      <div class="panel-header">
        <h4 class="panel-header-title">
          Lines
        </h4>

        <div class="panel-header-actions">
          <!-- show lines -->
          <button type="button" class="omnis-action-button" (click)="toggleLayer(objectTypes.CountingLine)">
            <i class="fas fa-fw fa-eye" *ngIf="isLineLayerEnabled"></i>
            <i class="fas fa-fw fa-eye-slash" *ngIf="!isLineLayerEnabled"></i>
          </button>
          <!-- add line -->
          <button
            type="button"
            class="omnis-action-button"
            (click)="addLine()"
            [disabled]="!allowAddingLine()"
          >
            <i class="fas fa-fw fa-plus"></i>
          </button>
        </div>
      </div>

      <div class="panel-body">
        <ul class="omnis-setting" formArrayName="lines">
          <li
            class="omnis-setting-item"
            *ngFor="let ctrl of lineForms.controls; let i = index"
            [formGroupName]="i"
          >
            <editable
              [mode]="ctrl?.value?.new ? 'add': 'view'"
              [form]="ctrl"
              field="name"
              [disabled]="isEditingLineInProcess && selectedLine?.id !== ctrl?.value?.id"
              (cancelled)="onCancelLine($event, i)"
              (deleted)="onRemoveLine($event, i)"
              (edit)="onEditLine($event, i)"
              (saved)="onSaveLine($event, i)"
            ></editable>
          </li>
        </ul>
      </div>
    </div>

    <div class="omnis-editor-panel">
      <div class="panel-header">
        <h4 class="panel-header-title">
          Scopes
        </h4>

        <div class="panel-header-actions">
          <!-- show scopes -->
          <button type="button" class="omnis-action-button" (click)="toggleLayer(objectTypes.Scope)">
            <i class="fas fa-fw fa-eye" *ngIf="isScopeLayerEnabled"></i>
            <i class="fas fa-fw fa-eye-slash" *ngIf="!isScopeLayerEnabled"></i>
          </button>
          <!-- add scope -->
          <button
            type="button"
            class="omnis-action-button"
            (click)="addScope()"
            [disabled]="!allowAddingScope()"
          >
            <i class="fas fa-fw fa-plus"></i>
          </button>
        </div>
      </div>

      <div class="panel-body">
        <ul class="omnis-setting" formArrayName="scopes">
          <li
            class="omnis-setting-item"
            *ngFor="let ctrl of scopeForms.controls; let i = index"
            [formGroupName]="i"
          >
            <editable
              [mode]="ctrl?.value?.new ? 'add': 'view'"
              [form]="ctrl"
              field="name"
              [disabled]="isEditingScopeInProcess && selectedScope?.id !== ctrl?.value?.id"
              [canSave]="selectedScopeSquares?.length > 0"
              (cancelled)="onCancelScope($event, i)"
              (deleted)="onRemoveScope($event, i)"
              (edit)="onEditScope($event, i)"
              (saved)="onSaveScope($event, i)"
            ></editable>
          </li>
        </ul>
      </div>
    </div>

    <div>
      <button
        type="button"
        class="omnis-action-button"
        (click)="save()"
      >
        <i class="fas fa-fw fa-save green-text"></i> Save
      </button>
    </div>
  </div>
</div>
