import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SelectItems } from '../../models';

@Component({
  selector: 'sc-rule-blocker',
  templateUrl: './rule-blocker.component.html',
  styleUrls: ['./rule-blocker.component.scss'],
})
export class RuleBlockerComponent implements OnInit {
  actions: any[];

  @Input()
  commandForm: FormGroup;

  @Input()
  selectItems: SelectItems = {};

  @Input()
  isTemplate: boolean;

  constructor() {}

  ngOnInit(): void {
    this.actions = [
      { label: 'Block', value: 'block' },
      { label: 'Unblock', value: 'unblock' },
    ];

    if (this.commandForm) {
      // incase switch from trigger device
      this.commandForm.get('action').enable();

      // required value for this command
      this.commandForm.get('value').setValidators(Validators.required);
      this.commandForm.updateValueAndValidity();
    }
  }
}
