<div class="location-shape-form">
  <div class="shapes-container">
    <div class="shape-item" (click)="selectShape(1)">
      <div class="shape rectangle"></div>
    </div>
    <div class="shape-item" (click)="selectShape(2)">
      <div class="shape irregular"></div>
    </div>
    <div class="shape-item" (click)="selectShape(3)">
      <div class="shape trapezoid"></div>
    </div>
  </div>

  <div class="shapes-container">
    <div class="shape-item" (click)="selectShape(4)">
      <div class="shape parallelogram"></div>
    </div>
    <div class="shape-item" (click)="selectShape(5)">
      <div class="shape hexagon"></div>
    </div>
    <div class="shape-item" (click)="selectShape(6)">
      <div class="shape triangle"></div>
    </div>
  </div>

  <div class="shapes-container">
    <div class="shape-item" (click)="selectShape(7)">
      <div class="shape lshape"></div>
    </div>
    <div class="shape-item" (click)="selectShape(8)">
      <div class="shape tshape"></div>
    </div>
    <div class="shape-item" (click)="selectShape(9)">
      <div class="shape irregular2"></div>
    </div>
  </div>

  <div class="separator">or</div>

  <div class="draw-container">
    <button
      type="button"
      pButton
      label="{{ 'FLOORPLAN_DRAW_FROM_SCRATCH' | translate }}"
      class="ui-button-success draw-button"
      (click)="drawShape()"
    ></button>
  </div>
</div>
