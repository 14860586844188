<p-dialog
  header="Config"
  [(visible)]="isConfigModalOpen"
  appendTo="body"
  [style]="{ width: '80vw', maxWidth: '800px' }"
>
  <form [formGroup]="configForm" class="sc-widget-config__form">
    <div *ngIf="!isConfigModalInitialized">
      <sc-loading-indicator></sc-loading-indicator>
    </div>
    <div *ngIf="isConfigModalInitialized">
      <div class="ui-fluid">
        <div class="p-field p-grid">
          <label for="firstname" class="p-col-12 p-md-3">{{
            "WIDGET_CUSTOM_TITLE" | translate
          }}</label>
          <div class="p-col-12 p-md-9">
            <div class="ui-inputgroup display-flex">
              <input
                type="text"
                pInputText
                [placeholder]="defaultWidgetName"
                formControlName="name"
              />
              <button
                pButton
                type="button"
                icon="pi pi-undo"
                (click)="resetWidgetName()"
              ></button>
            </div>
          </div>
        </div>
        <div class="p-field p-grid p-align-center">
          <label for="lastname" class="p-col-12 p-md-3 p-col-align-start"
            >Mode</label
          >
          <div class="p-col-12 p-md-9">
            <div class="p-field-radiobutton">
              <p-radioButton
                name="selectedUnit"
                [value]="UNIT_CO2"
                inputId="selectedUnit1"
                formControlName="unit"
              ></p-radioButton>
              <label for="selectedUnit1">CO2</label>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                name="selectedUnit"
                [value]="UNIT_COV"
                inputId="selectedUnit2"
                formControlName="unit"
              ></p-radioButton>
              <label for="selectedUnit2">COV</label>
            </div>
          </div>
          init: {{ isInitialized ? "initialized" : "fetching" }} | unit:
          {{ configForm.value.unit }} | filtered devices:
          {{ filteredDevices.length }}
        </div>
        <div class="p-field p-grid">
          <label for="occupiedColor" class="p-col-12 p-md-3 p-col-align-start"
            >Devices</label
          >
          <div class="p-col-12 p-md-9">
            <div class="ui-inputgroup display-flex">
              <p-dropdown
                [options]="filteredDevices"
                formControlName="selectedDevice"
                optionLabel="fullName"
                placeholder="Select a device"
                [filter]="true"
              ></p-dropdown>
              <button
                pButton
                type="button"
                icon="pi pi-plus"
                [disabled]="!configForm.value.selectedDevice.idx"
                (click)="selectDevice()"
              ></button>
            </div>
            <br />
            <div *ngIf="!selectedDevices.value.length">
              Please add a device.
            </div>
            <div formArrayName="selectedDevices">
              <p-table
                [value]="selectedDevices.controls"
                *ngIf="selectedDevices.value.length"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Name</th>
                    <th width="100">Min.</th>
                    <th width="100">Max.</th>
                    <th width="60"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-ctrl let-rowIndex="rowIndex">
                  <tr [formGroupName]="rowIndex">
                    <td>
                      {{ ctrl.value.device.fullName }}
                    </td>
                    <td>
                      <p-inputNumber
                        formControlName="min"
                        size="2"
                      ></p-inputNumber>
                    </td>
                    <td>
                      <p-inputNumber
                        formControlName="max"
                        size="2"
                      ></p-inputNumber>
                    </td>
                    <td>
                      <button
                        pButton
                        icon="pi pi-minus"
                        (click)="unselectDevice(rowIndex)"
                      ></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <p-footer class="air-quality-live__footer">
    <button
      type="button"
      (click)="saveConfig()"
      pButton
      label="{{ 'SUBMIT' | translate }}"
      class="ui-button-success"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  header="{{ widgetName }}"
  [(visible)]="isDetailsModalOpen"
  appendTo="body"
  [style]="{ width: '60vw', maxWidth: '600px' }"
>
  <div *ngIf="!isDetailsModalInitialized">
    <sc-loading-indicator></sc-loading-indicator>
  </div>
  <div *ngIf="isDetailsModalInitialized" style="padding: 0 35px 16px">
    <p-table [value]="deviceStats">
      <ng-template pTemplate="header">
        <tr>
          <th width="150">Device</th>
          <th>Min.</th>
          <th>Max.</th>
          <th>Abs. value</th>
          <th>Rel. value</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-device>
        <tr>
          <td>{{ device.fullName }}</td>
          <td>{{ device.min }}</td>
          <td>{{ device.max }}</td>
          <td>{{ device.value }}</td>
          <td>{{ device.percentage }} %</td>
          <td>{{ device.thresholdName }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>

<ng-template #spinner>
  <sc-loading-indicator></sc-loading-indicator>
</ng-template>

<ng-template #content>
  <div class="sc-widget-body air-quality-body">
    <ng-container *ngIf="!isConfigured()">
      <div class="sc-flex">
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          label="{{ 'WIDGET_CONFIGURATION_REQUIRED' | translate }}"
        ></button>
      </div>
    </ng-container>
    <ng-container *ngIf="isConfigured() && chartData">
      <div class="sc-widget-legend air-quality-widget-legend">
        <ul>
          <li *ngFor="let legendItem of legendItems">
            <span
              class="color"
              [ngStyle]="{ 'background-color': legendItem.color }"
            ></span>
            <span class="label air-quality-label"
              >{{ legendItem.value }} {{ legendItem.label }}</span
            >
          </li>
        </ul>
      </div>
      <div class="air-quality-widget-plotly">
        <plotly-plot
          [data]="chartData"
          [layout]="chartLayout"
          [config]="{ displayModeBar: false }"
        ></plotly-plot>
      </div>
    </ng-container>
  </div>
</ng-template>

<div [ngStyle]="{ 'height.px': config.widgetHeight }">
  <div class="sc-widget">
    <div class="sc-widget-header">
      <h4 class="sc-widget-title">{{ widgetName }}</h4>
      <div class="sc-widget-menu">
        <button
          type="button"
          (click)="openDetailsModal()"
          pButton
          icon="pi pi-eye"
          title="Details"
          [disabled]="!isInitialized"
          class="air-quality-action-btn"
          *ngIf="isInitialized && chartData && isConfigured()"
        ></button>
        <button
          type="button"
          (click)="openConfigModal()"
          pButton
          icon="pi pi-cog"
          title="Config"
          [disabled]="!isInitialized"
          class="air-quality-action-btn"
        ></button>
      </div>
    </div>
    <ng-container
      *ngIf="
        isInitialized && (!isConfigured() || (isConfigured() && chartData));
        then content;
        else spinner
      "
    ></ng-container>
  </div>
</div>
