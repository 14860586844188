import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import * as fromStore from '@app/store';

@Injectable()
export class PageGuard implements CanActivate, CanActivateChild {
  private siteId: number;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private store: Store<fromStore.State>) {
    this.store.pipe(select(fromStore.getSelectedSiteId)).subscribe((siteId) => (this.siteId = siteId));
  }

  canActivate(): Observable<boolean> {
    return this.store
      .pipe(
        select(fromStore.getRouterStateUrlParams),
        switchMap((params) => {
          if (!params.pageParams || isNaN(params.pageParams)) {
            return of(false);
          }

          // this.router.navigate([], {
          //   relativeTo: this.activatedRoute,
          //   queryParams: { site: this.siteId },
          //   // preserve the existing query params in the route
          //   queryParamsHandling: 'merge',
          //   // do not trigger navigation
          //   skipLocationChange: true
          // });

          switch (params.pageId) {
            case 'floorplan':
              return this.checkLocations(true);
            case 'locations':
              return this.checkLocations();
            case 'devices':
              return this.checkDevice(+params.pageParams);
            default:
              return of(true);
          }
        })
      )
      .pipe(
        switchMap((value) => of(value ? true : false)),
        catchError(() => of(false))
      );
  }

  canActivateChild(): Observable<boolean> {
    return this.checkPagesStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkPagesStore(): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.getPagesLoaded),
      // tap(loaded => {
      //   console.log('loaded:', loaded);
      //   // if (!loaded) {
      //   //   this.store.dispatch(new fromStore.LoadPages());
      //   // }
      // }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  checkLocations(force = false): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.getLocationDetials),
      tap((location) => {
        if (!location || force) {
          this.store.dispatch(new fromStore.ResetLocations());
          const options = { siteId: this.siteId };
          this.store.dispatch(new fromStore.LoadLocations(options));
        }
      }),
      filter((location: any) => (location ? true : false)),
      take(1)
    );
  }

  checkDevice(deviceId: number): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.getDeviceDetials),
      tap((device) => {
        if (!device) {
          this.store.dispatch(new fromStore.LoadDevice(deviceId));
        }
      }),
      filter((device: any) => (device ? true : false)),
      take(1)
    );
  }
}
