import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AppStyleDirective } from './app-style/app-style.directive';
import { AppStyleService } from './app-style/app-style.service';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { ErrorLayoutComponent } from './error-layout/error-layout.component';
import { LanguageChooserComponent } from './language-chooser/language-chooser.component';
import { NotificationPanelComponent } from './notification-panel/notification-panel.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { SiteChooserComponent } from './site-chooser/site-chooser.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { WidgetComponent } from './widget/widget.component';
import { WidgetDirective } from './widget/widget.directive';
import { WidgetService } from './widget/widget.service';
import { PageComponent } from './page/page.component';
import { PageGuard } from './page/page.guard';
import { PageService } from './page/page.service';
import { PageBodyComponent } from './page-body/page-body.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageHeaderDirective } from './page-header/page-header.directive';
import { PageSectionComponent } from './page-section/page-section.component';
import { PageSectionFormComponent } from './page-section-form/page-section-form.component';
import { PageEditMenuComponent } from './page-edit-menu/page-edit-menu.component';
import { PageWidgetFormComponent } from './page-widget-form/page-widget-form.component';
import { PageWidgetModalComponent } from './page-widget-modal/page-widget-modal.component';
import { LeftSidebarModule } from './left-sidebar/left-sidebar.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, LeftSidebarModule],
  declarations: [
    AppLayoutComponent,
    AppStyleDirective,
    LoginLayoutComponent,
    ErrorLayoutComponent,
    LanguageChooserComponent,
    NotificationPanelComponent,
    NotificationModalComponent,
    SiteChooserComponent,
    ToolbarComponent,
    UserMenuComponent,
    WidgetComponent,
    WidgetDirective,
    PageComponent,
    PageBodyComponent,
    PageHeaderComponent,
    PageHeaderDirective,
    PageSectionComponent,
    PageSectionFormComponent,
    PageEditMenuComponent,
    PageWidgetFormComponent,
    PageWidgetModalComponent,
    BreadcrumbComponent,
  ],
  entryComponents: [PageSectionFormComponent],
  providers: [AppStyleService, PageGuard, PageService, WidgetService],
})
export class Core2Module {}
