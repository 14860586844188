import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromStore from '@app/store';

import { Site } from '@widgets/sites/models/site';

import { SharedService } from '@shared/shared.service';
import { SCWidgetService } from '@widgets/widget.service';

@Component({
  selector: 'sc-ac-off',
  templateUrl: './ac-off.component.html',
  styleUrls: ['./ac-off.component.scss'],
})
export class ACOffComponent implements OnInit, OnDestroy {
  @Input()
  config: any;

  dataset: { [key: string]: any };
  isFetching: boolean;
  isFirstTime: boolean;
  // widgetData: { [key: string]: any }[];
  // widgetConfig: { [key: string]: any };
  currentPeriod = 'LAST_YEAR';
  widgetData: { [key: string]: any };
  widgetName: string = '';

  private intervals: { [key: string]: any } = {};
  private selectedSite: Site;
  private subscribers: { [key: string]: any } = {};

  constructor(
    private sharedService: SharedService,
    private store: Store<fromStore.State>,
    private widgetService: SCWidgetService
  ) {}

  ngOnInit() {
    this.widgetName = this.sharedService.getLocaleWidgetName(this.config);

    // Get Selected Site
    this.subscribers.watchSite = this.store.pipe(select(fromStore.getSelectedSite)).subscribe((result) => {
      if (result && (!this.selectedSite || result.id !== this.selectedSite.id)) {
        this.selectedSite = result;
        this.isFetching = false;
        this.isFirstTime = true;
        this.fetchDataset();
        // this.intervals.fetchDataset = setInterval(() => this.fetchDataset(), 60000);
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.clearIntervals(this.intervals);
    this.sharedService.clearSubscribes(this.subscribers);
  }

  fetchDataset() {
    if (this.isFetching || !this.selectedSite || !this.selectedSite.id) {
      return;
    }

    this.isFetching = true;
    this.subscribers.fetchDataset = this.widgetService.getACOff(this.selectedSite.id).subscribe(
      (result: any) => {
        this.dataset = result.data;
        this.setWidgetData(this.currentPeriod);
        this.isFetching = false;
        this.isFirstTime = false;
      },
      (error: any) => {
        this.isFetching = false;
      }
    );
  }

  switchPeriod(period: string) {
    this.currentPeriod = period;
    this.setWidgetData(this.currentPeriod);
  }

  periodButtonClass(period: string) {
    if (this.currentPeriod === period) {
      return 'ui-button-success';
    }
    return 'ui-button-secondary';
  }

  private setWidgetData(period: string) {
    const data = this.dataset;
    let current;
    let previous;

    switch (period) {
      case 'TODAY':
        current = data.today.value / 60 / 60;
        previous = data.yesterday.value / 60 / 60;
        break;
      case 'LAST_WEEK':
        current = data.lastWeek.value / 60 / 60;
        previous = data.weekBeforeLastWeek.value / 60 / 60;
        break;
      case 'LAST_MONTH':
        current = data.lastMonth.value / 60 / 60;
        previous = data.monthBeforeLastMonth.value / 60 / 60;
        break;
      case 'LAST_YEAR':
        current = data.lastYear.value / 60 / 60;
        previous = data.yearBeforeLastYear.value / 60 / 60;
        break;
    }

    let acOffHours = current;
    if (acOffHours >= 1000) {
      acOffHours = this.sharedService.numberFormat(acOffHours / 1000, 1) + 'K';
    } else if (acOffHours > 0) {
      acOffHours = this.sharedService.numberFormat(acOffHours, 1);
    }

    this.widgetData = {
      value: acOffHours,
      icon: 'fa-minus',
      color: null,
    };

    if (current > previous) {
      this.widgetData.icon = 'fa-level-up';
      this.widgetData.color = 'success';
    } else if (current < previous) {
      this.widgetData.icon = 'fa-level-down';
      this.widgetData.color = 'danger';
    }
  }
}
